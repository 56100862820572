<template>
  <div id="detailHocBa" class="page page-file file detailHocBa" v-loading.fullscreen.lock="fullscreenLoading">
    <div class="page__title d-flex align-items-center justify-content-between">
      <h1 class="title">Đóng dấu hiệu chỉnh hồ sơ</h1>
    </div>
    <div class="box-upload-file">
      <el-form :rules="rulesSearch" ref="searchForm" :model="searchForm" :hide-required-asterisk="true">

        <el-row :gutter="24">
          <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
            <el-form-item class="d-inline-block" prop="year" label="Năm học">
              <eselect style="width:100%" collapseTags v-model="searchForm.year" :placeholder="'Chọn'"
                       filterable @change="onYearChange" :data="list_nam_hoc" :fields="['name','value']"/>
            </el-form-item>
          </el-col>
          <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
            <el-form-item class="d-inline-block" prop="education" label="Chọn cấp">
              <eselect style="width:100%"  :disabled="list_cap_hoc.length==1" collapseTags v-model="searchForm.education"
                       @change="capHocChange" placeholder="Chọn" filterable :data="list_cap_hoc"
                       :fields="['name','value']"/>
            </el-form-item>
          </el-col>
          <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
            <el-form-item class="d-inline-block" prop="grade" label="Khối học">
              <eselect style="width:100%" collapseTags v-model="searchForm.grade" @change="khoiHocChange"
                       :placeholder="'Chọn khối'" filterable :data="optionGrade"
                       :fields="['label','value']"/>
            </el-form-item>
          </el-col>
          <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
            <el-form-item class="d-inline-block" prop="class" label="Lớp học">
              <eselect style="width:100%" collapseTags v-model="searchForm.class" placeholder="Chọn"
                       filterable :data="danh_sach_lop_hoc" :fields="['tenLop','maLop']"/>
            </el-form-item>
          </el-col>
          <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
            <el-form-item class="d-inline-block" label="Trạng thái">
              <eselect style="width:100%" collapseTags v-model="searchForm.trangThaiHocSinh"
                       :placeholder="'Trạng thái học sinh'"
                       :data="[{label:'Đang học', value:1}, {label:'Chuyển trường', value:3}, {label:'Bỏ học', value:4}]"
                       :fields="['label','value']"/>
            </el-form-item>
          </el-col>
          <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
            <el-form-item class="d-inline-block" label="Từ khoá">
              <el-input style="width:100%" v-model="searchForm.student" clearable
                        placeholder="Mã/Tên học sinh">
              </el-input>
            </el-form-item>
          </el-col>
          <!--                    <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">-->
          <!--                        <el-form-item class="d-inline-block">-->
          <!--                            <el-input style="width:100%" v-model="searchForm.maHS" placeholder="Nhập"></el-input>-->
          <!--                        </el-form-item>-->
          <!--                    </el-col>-->
          <el-col :xs="24" :sm="24" :md="6" :lg="24" :xl="3" class="text-center">
            <el-form-item class="d-inline-block">
              <div style="color:transparent;">Tìm kiếm</div>
              <el-button type="primary" @click="submitSearch('searchForm')">Tìm kiếm</el-button>

            </el-form-item>
            <!-- <el-button type="primary" @click="kyHocBaUsbV2">Đóng dấu</el-button> -->
          </el-col>
        </el-row>
      </el-form>
    </div>
    <el-row :gutter="24">
      <el-col :span="18">
        <div class="text-left">
          <el-tooltip placement="top">
            <div slot="content">Đóng dấu dữ liệu của {{ danhSachBiaChecked.length }} học bạ đã chọn</div>
            <el-button size="mini" :disabled="!danhSachBiaChecked||danhSachBiaChecked.length==0"
                       type="success"
                       @click.prevent="checkKyDuLieu">
              Đóng dấu hiệu chỉnh
            </el-button>
          </el-tooltip>

          <el-button v-if="kieuKyGiaoVien==6&&!tokenSmartCa" size="mini" type="primary"
                     @click.prevent="loginSmartCA">
            Đăng nhập SMARTCA
          </el-button>

        </div>
      </el-col>
      <el-col :span="6" class="text-right">
        <div class="cursor-pointer" v-if="kieuKyGiaoVien">
          <el-tooltip content="Thay đổi kiểu đóng dấu" placement="left">
            <el-button type="primary" :disabled="countTypeSign==1" style="padding: 5px;margin: 0"
                       @click.prevent="showChonKieuKy = true ">
              <img style="width: 30px; height: 20px" :src="getPathImgByType(kieuKyGiaoVien)" alt="">
              Kiểu đóng dấu
            </el-button>
          </el-tooltip>
        </div>
      </el-col>
    </el-row>
    <div class="page__content">
      <div class="listNew mb-5">
        <el-table
            :max-height="maxHeightTable"
            border fit stripe :lazy="true" :data="listHocBa ? listHocBa.list : []" width="100%"
            @selection-change="handleSelectionChange" ref="listFile" v-loading="loading">
          <!--                    <el-table-column type="selection" align="center" width="40">-->
          <!--                    </el-table-column>-->

          <el-table-column
              style="width: 50px"
              width="50"
              align="center" header-align="center">
            <template slot="header" slot-scope="scope">
              <el-checkbox @change="ChonToanBoBia" :disabled="!listHocBa.list||listHocBa.list.length==0"
                           v-model="CheckAll"></el-checkbox>
            </template>
            <template slot-scope="scope">
              <el-checkbox v-if="scope.row.mark==false"
                           @change="ChonMoiBia(scope.row)" v-model="scope.row.check"></el-checkbox>
              <el-checkbox v-else disabled></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column label="STT" style="width: 50px" width="50" type="index" class-name="text-center"
                           :index="indexMethod">
          </el-table-column>
          <el-table-column min-width="120" label="Mã học sinh" align="center" property="maHS">
          </el-table-column>
          <el-table-column label="Họ tên" min-width="160" header-align="center" align="left" property="hoten">
          </el-table-column>
          <el-table-column label="Lớp" property="tenLop" align="center" width="90" class="text-center"
                           style="text-align: center"></el-table-column>

<!--          <el-table-column label="Trạng thái học sinh" min-width="100" align="center">-->
<!--            <template slot-scope="scope">-->
<!--              <div v-if="scope.row.trangThai === 1">Đang học</div>-->
<!--              <div v-if="scope.row.trangThai === 3">Chuyển trường</div>-->
<!--              <div v-if="scope.row.trangThai === 4">Bỏ học</div>-->
<!--            </template>-->
<!--          </el-table-column>-->

          <el-table-column label="Trạng thái" min-width="90" align="left" header-align="center">
            <template slot-scope="scope">
              <div>
                <p>{{ scope.row.moTaTrangThaiHieuTruong }}</p>
              </div>
              <!--                            <div v-if="scope.row.chotBia === true">-->
              <!--                                <div v-if="scope.row.mark === true">Đã đóng dấu</div>-->
              <!--                                <div v-if="scope.row.mark === false">Chưa đóng dấu</div>-->
              <!--                            </div>-->
              <!--                            <div v-else>-->
              <!--                                <div>Chưa chốt bìa</div>-->
              <!--                            </div>-->
            </template>
          </el-table-column>

          <el-table-column align="center" width="80" label="Đóng dấu" class-name="" fixed="right">
            <template slot="header" slot-scope="scope">
              <div style="position: relative">
                Đóng dấu
                <el-tooltip>
                  <div slot="content">
                    Trường hợp không hiển thị nút đóng dấu<br/>
                    1. Chưa chốt bìa học bạ<br/>
                    2. Hiệu trưởng đã đóng dấu
                  </div>
                  <i class="el-icon-info cursor-pointer"
                     style="font-size: 12px;position: absolute;width: 40px;height: 25px;text-align: right;left: 10px;"></i>
                </el-tooltip>
              </div>
            </template>

            <template slot-scope="scope">
              <div v-if="scope.row.mark==false">
                <div v-if="kieuKyGiaoVien != 3">
                  <el-tooltip v-if="!scope.row.mark" content="Đóng dấu" placement="top">
                    <el-button v-if="kieuKyGiaoVien != 4" type="warning" size="mini"
                               @click.prevent="showThongTinKy(scope.row)" icon="el-icon-edit">
                    </el-button>

                    <el-tooltip v-if="kieuKyGiaoVien == 4" content="Đóng dấu" placement="top">
                      <el-button data-option="" size="mini" @click="kyDuLieuMotHocBa(scope.row)"
                                 type="warning" icon="el-icon-edit">
                      </el-button>
                    </el-tooltip>
                  </el-tooltip>
                </div>
                <div v-else>
                  <div v-if="!scope.row.mark">
                    <el-tooltip content="Đóng dấu" placement="top">
                      <el-button data-option="" size="mini" @click="kyHocBaUsbV2(scope.row)"
                                 type="warning" icon="el-icon-edit">
                      </el-button>
                    </el-tooltip>
                  </div>
                </div>
              </div>
              <div v-else>
                <el-tooltip content="Đóng dấu" placement="top">
                  <el-button type="info" size="mini" icon="el-icon-edit"></el-button>
                </el-tooltip>

              </div>
            </template>
          </el-table-column>

          <el-table-column align="center" min-width="130" label="Hành động" class-name="" fixed="right">
            <template slot-scope="scope">
              <div class="btn__table_action">
                <el-tooltip content="Xem bìa học bạ" placement="top" v-if="scope.row.trangThai != 3">
                  <el-button data-option="" size="mini" @click="xemHocBa(scope.row)" type="primary"
                             icon="el-icon-view">
                  </el-button>
                </el-tooltip>

              </div>
            </template>
          </el-table-column>
        </el-table>

        <div class="mt-05" v-if="listHocBa && listHocBa.total">
          <el-row :gutter="20">
            <el-col :span="8">
              <chonSoLuong @chonXongSoLuong="ChonSoLuongBanGhi($event)"></chonSoLuong>
            </el-col>
            <el-col :span="16" class="text-right">
              <small class="pd-r-10">
                <i>Danh sách có {{ listHocBa.total }} học sinh </i>
              </small>
              <pagination v-if="listHocBa" :page-count="getTotalPage(listHocBa.total)"
                          v-model="paginate.page"
                          :initial-page="paginate.page" :click-handler="getHocBa" :prev-text="'Trước'"
                          :next-text="'Sau'" :container-class="'el-pager'" :pageClass="'number'">

              </pagination>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <el-dialog class="popupSuaDiem" title="Xem điểm" :visible.sync="showEditDiem" center>
      <div>
        <component :is="viewPopup" :id="idEdit"></component>
      </div>
      <span slot="footer" class="dialog-footer">
                <el-button @click="detailHocBaClose">Thoát</el-button>
            </span>
    </el-dialog>
    <el-dialog fullscreen title="Bảng điểm tổng hợp cả năm" :visible.sync="showDiemTongKet" center>
      <div>
        <component :is="viewPopupTongKetMon" :id="idDiemTongKet"></component>
      </div>
    </el-dialog>
    <form-otp @closeDialogOtp="closeDialogOtp()" @success="success()" :type="typeKy" :duLieu="duLieuHocBaCanKy"
              :list-hoc-ba="hocbaDatas" :show-dialog-otp="showDialogOtp">
    </form-otp>
    <dialog-alert v-if="kySoStatus" title="Thông báo" :message="kySoStatus.message" :show-dialog="showDialogAlert"
                  @closeDialog="closeDialogAlert()">

    </dialog-alert>

    <dialog-alert v-if="kyUsbStatus" title="Thông báo" :message="kyUsbStatus.message"
                  :show-dialog="showDialogAlertUsb" @closeDialog="closeDialogAlert()">

    </dialog-alert>

    <el-dialog title="Chi tiết" width="60%" center custom-class="thongTinKyTheoMonHoc"
               :visible.sync="hien_thi_thong_tin_ky">
      <el-table :max-height="maxHeight" :row-class-name="tableRowClassName" :data="list_trang_thai_ky_theo_mon">
        <el-table-column label="STT" width="50" align="center" type="index" class-name="text-center"
                         :index="indexMethod">
        </el-table-column>
        <el-table-column property="tenMonHoc" label="Môn học" width="110"></el-table-column>
        <el-table-column property="tenGiaoVien" label="Giáo viên"></el-table-column>
        <el-table-column label="GVCN">
          <template>
            <p>{{ tenGiaoVienChuNhiem }}</p>
          </template>
        </el-table-column>
        <el-table-column align="center" label="Trạng thái" width="100">
          <template slot-scope="scope">
            <div v-if="scope.row.trangThaiXacNhan==1">Đã đóng dấu</div>
            <div v-if="scope.row.trangThaiXacNhan==0">Chưa đóng dấu</div>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog top="5vh" title="Xác nhận đóng dấu tài liệu" width="50%" append-to-body :show-close="false"
               :close-on-click-modal="false" :close-on-press-escape="false" center custom-class="hienThiThoiGianCho"
               :visible.sync="hien_thi_thoi_gian_doi_ky">
      <el-row :gutter="24">
        <el-col :span="24">
          <p>Yêu cầu đóng dấu số đã được gửi về thiết bị di động.</p>
          <p style="white-space: nowrap">Mở
            <span v-if="kieuKyGiaoVien!=2">ứng dụng</span>
            <span v-if="kieuKyGiaoVien==2">thiết bị di động <b>nhập mã PIN</b></span>
            <span v-if="kieuKyGiaoVien==5">MySign</span>
            <span v-if="kieuKyGiaoVien==6">VNPT SmartCA</span>
            và nhấn <b>Xác nhận</b> để đóng dấu tài liệu.</p>
        </el-col>
        <el-col :xs="24" :sm="12">
          <div class="bg-purple-light pt-3">
            <p><b class="pd-r-14">Ứng dụng: </b>
              <span v-if="kieuKyGiaoVien==2">Viettel CA</span>
              <span v-if="kieuKyGiaoVien==5">MySign</span>
              <span v-if="kieuKyGiaoVien==6">VNPT SmartCA</span>
            </p>
            <p style="white-space: nowrap"><b class="pd-r-10">Trạng thái: </b>Đang thực hiện đóng dấu</p>
          </div>
          <div v-if="kieuKyGiaoVien!=2" class="text-center pt-3">
            <p>Thời gian còn lại</p>
            <h4 style="color: #00A65A">{{ getMinutes(timeLeft) }}</h4>
          </div>
          <div>
            <hr/>
            <p v-if="kieuKyGiaoVien==5">
              <span><b style="color: blue">Lưu ý:</b></span>
              Nếu sau 10s không thấy có thông báo kí được gửi tới ứng dụng hãy bấm vào "Tải lại yêu cầu" trên ứng dụng.
              Nếu sau 2 phút vẫn không có yêu cầu kí hãy gọi tới số <b style="color: red">18008000</b> bấm nhánh <b
                style="color: red">1</b> để được trợ
              giúp.</p>
            <p v-if="kieuKyGiaoVien==2">
              <b style="color: blue">Lưu ý:</b>
              Có bất kì lỗi gì về kí hãy gọi số <b style="color: red">18008000</b> bấm nhánh <b style="color: red">1</b>
              để được trợ.
            </p>
            <hr/>
          </div>
          <div v-if="kieuKyGiaoVien!=2">
            <p>Cài đặt ứng dụng tại</p>
          </div>
          <div v-if="kieuKyGiaoVien!=2">
            <el-row :gutter="24">
              <el-col :span="12">
                <img @click.prevent="getAppAndroid()" src="/images/ch_android.png" alt="">
                <img @click.prevent="getAppIos()" src="/images/appstore_ios.png" alt="">
              </el-col>
              <el-col :span="12">
                <img v-if="kieuKyGiaoVien==5" style="width: 80% !important;"
                     src="/images/qr_code_viettel.png"
                     alt="Ảnh qr">
                <img v-if="kieuKyGiaoVien==6" style="width: 80% !important;"
                     src="/images/qr_code_vnpt.png"
                     alt="Ảnh qr">
              </el-col>
            </el-row>
          </div>
          <div v-else class="text-center">
            <img src="/images/loading1.gif" alt="">
            <div class="text-left">
              <p><b>Hệ thống Viettel CA đang thực hiện thao tác đóng dấu, Quý Thầy cô vui lòng chờ trong giây
                lát đến khi hệ thống hoàn thành việc đóng dấu. Trân trọng cảm ơn!</b></p>
            </div>
          </div>
        </el-col>
        <el-col :sm="12" class="hidden-xs-only hide-in-mobile">
          <img src="/images/mobile_ca.png" alt="ảnh">
        </el-col>
      </el-row>
    </el-dialog>
    <el-dialog title="Chọn kiểu đóng dấu" width="40%" center custom-class="thongTinKyTheoMonHoc"
               append-to-body :show-close="false"
               :close-on-click-modal="false" :close-on-press-escape="false"
               :visible.sync="showChonKieuKy">
      <el-row :gutter="24">
        <el-col :span="24" class="text-center">
          <el-radio class="checkTypeSign" v-for="(type,i) in listKieuKy" v-model="kieuKyGiaoVien" :key="i"
                    @change="chonKieuKy()" :label="type.value">
            <img style="width:90px; height: 70px" :src="getPathImg(type)" alt="Ảnh">
          </el-radio>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- <KyHocBaUsb :duLieu="duLieuKyHocBaUsb" :thuchienky="thuc_hien_ky_usb" @load="openLoading"
                @close="kyHocBaUsbXong()"
                @error="closeLoading"></KyHocBaUsb>
    <KyHocBaUsbAll :duLieu="duLieuKyHocBaUsbAll" :thuchienky="thuc_hien_ky_usb_all" @load="openLoading"
                   @close="kyHocBaUsbXong()"
                   @error="closeLoading"></KyHocBaUsbAll> -->
    <KyHocBaUsbAllV2 v-if="show_ky_usb" :thuchienky="thuc_hien_ky_usb_edoc" :key="'bia'" :position="2" :typeKy="'kyscan'"
                     :maLop="searchForm.class"
                     :dulieu="duLieuKyHocBaUsbV2"
                     @done="submitSearch('searchForm')" @close="kyHocBaUsbXong()"></KyHocBaUsbAllV2>
    <ThongTinHocBa :show="ThongTinHocBa" :id_hoc_ba="idHocBa" @close="ThongTinHocBa = false"></ThongTinHocBa>
  </div>

</template>

<script>
import api from '../_helpers/api';
import constant from '../_helpers/constant_api';
import msgType from "../_helpers/constant_msg_type";
import websocket from "../websocket";
import ChiTietHocBa from "./GiaoVien/ChiTietHocBa";
import diemTongKetMon from "./GiaoVien/diemTongKetMon";
import {
  mapActions,
  mapState
} from 'vuex';
import Pagination from "../components/Pagination";
import dialog from "../dialog";
import FormOtp from "../components/FormOtp";
import DialogAlert from "../components/DialogAlert";
import SignUsb from "./SignUsb";
import KyHocBaUsbAll from "./KyBiaHocBaUsbAll";
import KyHocBaUsbAllV2 from "./KyListHocBaUsbAllV2Edoc2";
import KyHocBaUsb from "./KyBiaHocBaUsb";
import ChonKhoiHoc from "../components/ChonKhoiHoc";
import CapHocKhoiHoc from "../components/CapHocKhoiHoc";
import ThongTinHocBa from "../pages/HocBa/ThongTinHocBa";
import ESelectVue from "./Ui/ESelect";
import chonSoLuong from "./Ui/ChonSoLuong";
import {
  teacherService
} from "../_services";
import constant_api from "../api";


export default {
  name: "KyBiaHocBa",
  metaInfo: {
    title: 'Đóng dấu hiệu chỉnh hồ sơ',
    titleTemplate: '',
    meta: [{
      vmid: 'description',
      name: 'description',
      content: ''
    }]
  },
  computed: {
    ...mapState('teacher', [
      "listLop",
      "updateMessage",
      "updateSuccess",
      "kySoStatus",
      "reportUrl",
      "reportStatus",
      "kyUsbStatus"
    ]),
    ...mapState('hocba', [
      'ketQuaHocTapTemp',
      "fileDetail",
      "fileStatus",
      "diemTongKetMon",

    ]),
    ...mapState('account', [
      'user',
      'list_cap_hoc',
      'list_nam_hoc'
    ]),
    ...mapState('until', [
      'window',
    ])
  },
  components: {
    DialogAlert,
    Pagination,
    FormOtp,
    SignUsb,
    ChonKhoiHoc,
    CapHocKhoiHoc,
    KyHocBaUsbAll,
    KyHocBaUsbAllV2,
    chonSoLuong,
    ThongTinHocBa,
    KyHocBaUsb,
    'eselect': ESelectVue,
  },

  data() {

    return {
      tokenSmartCa: null,
      hien_thi_thoi_gian_doi_ky: false,
      demNguoc: null,
      kieuKyGiaoVien: '',
      showChonKieuKy: false,
      timeLeft: 90,
      danhSachBiaChecked: [],
      CheckAll: false,
      listHocBa: '',
      thuc_hien_ky_usb: false,
      thuc_hien_ky_usb_all: false,
      show_ky_usb: false,
      thuc_hien_ky_usb_edoc: false,
      duLieuKyHocBaUsb: {},
      duLieuKyHocBaUsbV2: {},
      duLieuKyHocBaUsbAll: {},
      tenGiaoVienChuNhiem: "",
      loading: false,
      showDialogAlertUsb: false,
      showDialogAlert: false,
      ThongTinHocBa: false,
      typeKy: 2,//đóng dấu bìa
      list_trang_thai_ky_theo_mon: [],
      maxHeight: window.innerHeight,
      maxHeightTable: window.innerHeight,
      hien_thi_thong_tin_ky: false,
      hocbaDatas: [],
      duLieuHocBaCanKy: {},
      showDialogOtp: false,
      listHocBaCheck: [],
      viewPopup: ChiTietHocBa,
      viewPopupTongKetMon: diemTongKetMon,
      idEdit: '',
      idDiemTongKet: {},
      idHocBa: '',
      showEditDiem: false,
      showDiemTongKet: false,
      paginate: {
        start: 0,
        limit: 100,
        page: 1,
      },
      data: [1, 2, 3, 4, 5],
      listKieuKy: [],
      countTypeSign: 0,
      fullscreenLoading: false,
      typeOtpSms: {label: 'SIMCA', value: 1, icon: 'icon_otp_sms.png'},
      typeSimCa: {label: 'SIMCA', value: 2, icon: 'icon_sim_ca.png'},
      typeUsbToken: {label: 'USB TOKEN', value: 4, icon: 'icon_usb_token.png'},
      typeSmartCaVt: {label: 'SMART_CA_VIETTEL', value: 5, icon: 'icon_mysign.png'},
      typeSmartCaVnpt: {label: 'SMART_CA_VNPT', value: 6, icon: 'icon_vnpt_ca.png'},
      optionEducation: [{
        value: 1,
        label: 'Cấp 1'
      },
        {
          value: 2,
          label: 'Cấp 2'
        },
        {
          value: 3,
          label: 'Cấp 3'
        },
        {
          value: 15,
          label: 'Liên cấp'
        },
        {
          value: 145,
          label: 'Liên cấp'
        },
        {
          value: 14,
          label: 'Liên cấp'
        },
        {
          value: 13,
          label: 'Liên cấp'
        },
        {
          value: 451,
          label: 'Liên cấp'
        },
        {
          value: 23,
          label: 'Liên cấp'
        },
        {
          value: 236,
          label: 'Liên cấp'
        },
        {
          value: 36,
          label: 'Liên cấp'
        },
        {
          value: 452,
          label: 'Liên cấp'
        },
        {
          value: 1245,
          label: 'Liên cấp'
        },
        {
          value: 12,
          label: 'Liên cấp'
        },
        {
          value: 123,
          label: 'Liên cấp'
        },
        {
          value: 12345,
          label: 'Liên cấp'
        },
        {
          value: 1235,
          label: 'Liên cấp'
        },
        {
          value: 6,
          label: 'Trung tâm GDTX'
        },
      ],
      optionGrade: [],
      danh_sach_lop_hoc: [],
      khoiCap1: [{
        value: 1,
        label: 'Khối 1'
      },
        {
          value: 2,
          label: 'Khối 2'
        },
        {
          value: 3,
          label: 'Khối 3'
        },
        {
          value: 4,
          label: 'Khối 4'
        },
        {
          value: 5,
          label: 'Khối 5'
        },
      ],
      khoiCap2: [{
        value: 6,
        label: 'Khối 6'
      },
        {
          value: 7,
          label: 'Khối 7'
        },
        {
          value: 8,
          label: 'Khối 8'
        },
        {
          value: 9,
          label: 'Khối 9'
        },
      ],
      khoiCap3: [{
        value: 10,
        label: 'Khối 10'
      },
        {
          value: 11,
          label: 'Khối 11'
        },
        {
          value: 12,
          label: 'Khối 12'
        },

      ],
      optionStudent: [{
        value: '',
        label: 'Học sinh'
      },
        {
          value: 1,
          label: 'HS 1'
        },
        {
          value: 2,
          label: 'HS 2'
        },
        {
          value: 3,
          label: 'HS 3'
        },
      ],
      optionStatus: [{
        value: '',
        label: 'Trạng thái'
      },
        {
          value: 1,
          label: 'Đã đóng dấu'
        },
        {
          value: 2,
          label: 'Đã đóng dấu duyệt'
        },
        {
          value: 3,
          label: 'Chưa đóng dấu'
        },
      ],
      searchForm: {
        year: '',
        education: '',
        grade: '',
        class: '',
        student: '',
        status: '',
        maHS: '',
        trangThaiHocSinh: 1
      },
      rulesSearch: {
        year: [{
          required: true,
          message: 'Bạn chưa chọn năm học',
          trigger: 'blur'
        }],
        grade: [{
          required: true,
          message: 'Chưa chọn khối học',
          trigger: 'blur'
        }],
        class: [{
          required: true,
          message: 'Chưa chọn lớp học',
          trigger: 'blur'
        }],
      }
    }
  },

  methods: {
    getHeightTable() {
      if (this.window.height && this.window.height > 950) {
        return this.window.height - 100
      }
      if (this.window.height && this.window.height > 850) {
        return 800
      }
      if (this.window.height && this.window.height > 750) {
        return 700
      }
      return 650
    },
    getPathImgByType(type) {
      let icon = ''
      if (type == 1) {
        icon = 'icon_otp_sms.png'
      }
      if (type == 2) {
        icon = 'icon_sim_ca.png'
      }
      if (type == 4) {
        icon = 'icon_usb_token.png'
      }
      if (type == 5) {
        icon = 'icon_mysign.png'
      }
      if (type == 6) {
        icon = 'icon_vnpt_ca.png'
      }
      let objIcon = {
        icon: icon
      }
      return this.getPathImg(objIcon)
    },
    chonKieuKy() {
      console.error('Chọn kiểu kỳ:')
      console.error(this.kieuKyGiaoVien)
      localStorage.setItem('kieuKyBiaGiaoVien', this.kieuKyGiaoVien);
      this.showChonKieuKy = false;
    },
    getPathImg(item) {
      let result = window.location.protocol + '//' + window.location.hostname + '/images/' + item.icon;
      return result;
    },
    getKieuKy() {
      console.log('getKieuKy');
      this.fullscreenLoading = true;
      let uri = constant_api.giaovien.getKieuKy;
      let params = {}
      this.listKieuKy = [];
      api.get(uri, params).then(
          response => {
            console.error("Kiểu đóng dấu trả về:")
            console.error(response)
            var kieuKyBiaGiaoVien = localStorage.getItem('kieuKyBiaGiaoVien')
            if (response.data.code == 200) {
              this.countTypeSign = response.data.data.toString().split('').length;
              if (this.countTypeSign > 1) {
                let listKieuKy = response.data.data.toString().split('');
                for (let i = 0; i < listKieuKy.length; i++) {
                  if (listKieuKy[i] == 1) {
                    this.listKieuKy.push(this.typeOtpSms)
                  }
                  if (listKieuKy[i] == 2) {
                    this.listKieuKy.push(this.typeSimCa)
                  }
                  if (listKieuKy[i] == 4) {
                    this.listKieuKy.push(this.typeUsbToken)
                  }
                  if (listKieuKy[i] == 5) {
                    this.listKieuKy.push(this.typeSmartCaVt)
                  }
                  if (listKieuKy[i] == 6) {
                    this.listKieuKy.push(this.typeSmartCaVnpt)
                  }
                }
                console.error(this.listKieuKy)
                if (!kieuKyBiaGiaoVien) {
                  this.showChonKieuKy = true;
                } else {
                  this.kieuKyGiaoVien = parseInt(kieuKyBiaGiaoVien)
                }
              } else {
                this.kieuKyGiaoVien = response.data.data
                console.error(this.kieuKyGiaoVien)

              }
            }
            this.fullscreenLoading = false;
          }
      ).catch((e) => {
        // this.thongBao('success', 'Hệ thống đang cập nhật dữ liệu. Vui lòng chờ trong ít phút.');
        this.fullscreenLoading = false;
      })
    },
    getMinutes(second) {
      if (second <= 0) {
        return '00:00'
      } else {
        let sec = 60;
        let phut = (second - second % sec) / sec;
        let giay = second % sec;
        if (phut < 10) {
          phut = '0' + phut;
        }
        if (giay < 10) {
          giay = '0' + giay
        }
        return phut + ':' + giay;
      }
    },
    objectToQueryString(obj) {
      let str = [];
      for (let p in obj)
        if (obj.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
      return str.join("&");
    },
    setEucation() {
      if (this.user.capHoc < 10) {
        this.searchForm.education = this.user.capHoc;
      } else {
        console.error('Liên cấp')
        console.error(this.user.capHoc)
        let lienCap = JSON.parse(JSON.stringify(this.user.capHoc))
        let arrEdu = lienCap.toString().split('')
        this.searchForm.education = parseInt(arrEdu[0])
      }
    },
    checkTokenVnpt() {
      console.error('checkTokenVnpt')
      if (localStorage.getItem('dataTokenVnptCa')) {
        this.tokenSmartCa = JSON.parse(localStorage.getItem('dataTokenVnptCa'))
        var refresh = this.tokenSmartCa.data.refresh_token;
        console.error(this.tokenSmartCa);
        console.error(this.tokenSmartCa.timeStart)
        console.error(Date.now())
        let longevity = Date.now() - this.tokenSmartCa.timeStart;
        console.error(longevity)
        if (longevity <= 30 * 1000) {
          console.error('Dùng được')
          console.error('Thực hiện đóng dấu')
        } else {
          console.error('Quá 30s')
          this.reFreshToken(refresh);
        }
      }
    },
    reFreshToken(token) {
      console.log('reFreshToken');
      let params = {
        code: token
      }
      let uri = constant.login.reFreshTokenVnpt;
      this.fullscreenLoading = true;
      api.post(uri, params).then(
          response => {
            if (response && response.data.code == 200) {
              let dataTokenVnptCa = {
                timeStart: Date.now(),
                data: response.data.data
              };
              dataTokenVnptCa = JSON.stringify(dataTokenVnptCa)
              localStorage.setItem('dataTokenVnptCa', dataTokenVnptCa)
              this.checkTokenVnpt();
            } else {
              this.thongBao('error', 'Có lỗi. Vui lòng thử lại sau.')
            }
            this.fullscreenLoading = false;
          }
      );
    },
    loginSmartCA() {
      localStorage.setItem('routerLoginSmartCa', 'KyBiaHocBa')
      console.log('loginSmartCa 12');
      let configParams = {
        typeLogin: 'hocBa',
        response_type: 'code',
        redirect_uri: 'https://hocba.edu.vn/smca-login',
        client_id: '4063-637907329184885701.apps.smartcaapi.com',
        scope: 'sign offline_access'
      }

      let query = this.objectToQueryString(configParams);
      var uriRedirect = 'https://gwsca.vnpt.vn/auth/authorize' + "?" + query;
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      setTimeout(() => {
        loading.close();
        window.location.href = uriRedirect
      }, 500)
    },
    kyDuLieuMotHocBa(e) {
      console.log('kyDuLieuMotHocBa')
      console.log(e)
      for (let i = 0; i < this.listHocBa.list.length; i++) {
        if (this.listHocBa.list[i].id == e.id) {
          this.listHocBa.list[i].check = true;
        } else {
          this.listHocBa.list[i].check = false;
        }
      }
      this.kyDuLieuHocBa([e]);
    },
    kyDuLieuHocBa(arr) {
      console.error('Đóng dấu dữ liệu học bạ:')
      console.error(this.kieuKyGiaoVien)
      console.error('123456789098765432234567890-')
      //kieuKyGiaoVien: 4 đóng dấu USB Token; 2: đóng dấu sim CA
      console.log('Đóng dấu dữ liệu học bạ:' + this.kieuKyGiaoVien)
      console.log(arr);
      if (this.kieuKyGiaoVien == 6) {
        this.checkTokenVnpt();
        this.kyHocBaListCloudCAVNPT(arr);
      }
      if (this.kieuKyGiaoVien == 5) {
        console.log("đóng dấu smart ca")
        this.kyHocBaListCloudCA(arr);
      }
      if (this.kieuKyGiaoVien == 4) {
        console.log("đóng dấu usb")
        this.show_ky_usb = true;
        setTimeout(() => {
          this.duLieuKyHocBaUsbV2 = arr;
          this.thuc_hien_ky_usb_edoc = true;
        }, 500)
      }
      if (this.kieuKyGiaoVien == 2) {
        console.log("Đóng dấu số")
        this.kySo(arr)
      }
      if (this.kieuKyGiaoVien == 1) {
        console.log("Đóng dấu bìa")
        this.duLieuHocBaCanKy = {};
        this.duLieuHocBaCanKy.loaiHocBa = msgType.type.sendOtpBiaHocBa;
        this.duLieuHocBaCanKy.maLop = this.searchForm.class;
        let ids = [];
        for (let i = 0; i < arr.length; i++) {
          ids.push(arr[i].id)
        }
        this.hocbaDatas = ids;
        console.error('hocbaDatas')
        console.error(this.hocbaDatas)
        console.error('duLieuHocBaCanKy')
        console.error(this.duLieuHocBaCanKy)
        this.showDialogOtp = true;
        // this.kySo(arr)
      }
    },
    kyHocBaListCloudCAVNPT(listBia) {
      let duLieu = listBia;
      var ids = [];
      for (let i = 0; i < duLieu.length; i++) {
        let obj = {
          id: duLieu[i].id
        }
        ids.push(obj)
      }
      console.log(ids)
      let data = {
        maLop: this.searchForm.class,
        reqs: ids,
        token: this.tokenSmartCa.data.access_token,
        msgType: msgType.type.signCloudCAVNPTKyBia //cloud CA
      }
      console.error('Data đóng dấu:')
      console.error(data)
      this.$confirm('Xác nhận đóng dấu danh sách học bạ đã chọn?', 'Thông báo', {
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy',
      })
          .then(_ => {
            console.log("Gửi:")
            console.log(data)
            websocket.sendMessage(data);
            this.showDialogWait();
          })
          .catch(_ => {
          });
    },
    kyHocBaListCloudCA(listBia) {
      let duLieu = listBia;
      var ids = [];
      for (let i = 0; i < duLieu.length; i++) {
        let obj = {
          id: duLieu[i].id
        }
        ids.push(obj)
      }
      console.log(ids)
      let data = {
        maLop: this.searchForm.class,
        reqs: ids,
        msgType: msgType.type.signCloudCAScanHocBa,
        position:2
      }
      console.error('Data đóng dấu:')
      console.error(data)
      this.$confirm('Xác nhận đóng dấu danh sách học bạ đã chọn?', 'Thông báo', {
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy',
      })
          .then(_ => {
            console.log("Gửi:")
            console.log(data)
            websocket.sendMessage(data);
            this.showDialogWait();
          })
          .catch(_ => {
          });
    },
    showDialogWait() {
      this.hien_thi_thoi_gian_doi_ky = true;
      this.clearTimer()
      this.getTimeDown();
      this.demNguocThoiGian(true);
    },
    clearTimer() {
      if (this.demNguoc) {
        clearInterval(this.demNguoc);
        this.demNguoc = null
      }
    },
    demNguocThoiGian(check) {
      if (this.timeLeft >= 0) {
        this.demNguoc = setInterval(() => {
          this.timeLeft--
        }, 1000)
      } else {
        clearInterval(this.demNguoc);
        this.demNguoc = null
      }
    },
    checkKyDuLieu() {
      try {
        console.error('listHocBa:')
        console.error(this.listHocBa)
        this.mapListHocBaCheck();
        console.log('danhSachBiaChecked')
        console.log(this.danhSachBiaChecked);
        if (!this.danhSachBiaChecked || this.danhSachBiaChecked.length == 0) {
          this.thongBao('error', 'Vui lòng chọn học bạ cần đóng dấu.');
          return;
        } else {
          let ids = this.danhSachBiaChecked.map(a => a);
          console.error('ids:')
          console.error(ids)
          this.kyDuLieuHocBa(ids);
        }
      } catch (e) {
        this.thongBao('success', 'Vui lòng thực hiện lại sau ít phút.')
      }
    },
    ChonToanBoBia() {
      let countCheck = 0;

      if (this.listHocBa.list.length == 0) {
        this.thongBao('error', 'Danh sách bìa học bạ trống');
        return
      }
      if (this.CheckAll) {
        for (let i = 0; i < this.listHocBa.list.length; i++) {
          if (!this.listHocBa.list[i].mark) {
            this.listHocBa.list[i].check = true;
            countCheck += 1;
          }
        }
        if (countCheck == 0) {
          this.thongBao('error', 'Chỉ có thể chọn các học sinh đã chốt và chưa đóng dấu bìa');
          this.CheckAll = false;
        }
        if (countCheck > 0 && countCheck < this.listHocBa.list.length) {
          let msg = 'Đã chọn được ' + countCheck + ' bìa học bạ có thể đóng dấu';
          this.thongBao('success', msg);
        }
      } else {
        for (let i = 0; i < this.listHocBa.list.length; i++) {
          this.listHocBa.list[i].check = false;
        }
      }
      this.mapListHocBaCheck();
    },
    ChonMoiBia(e) {
      console.log(e.check)
      console.log("CHeck")
      let newCheck = e.check;
      for (let i = 0; i < this.listHocBa.list.length; i++) {
        if (this.listHocBa.list[i].id == e.id) {
          if (this.listHocBa.list[i].mark == false) {
            this.listHocBa.list[i].check = newCheck;
          } else {
            this.thongBao('error', 'Học bạ này đã được đóng dấu bìa')
            this.listHocBa.list[i].check = false;
          }
        }
      }
      this.mapListHocBaCheck();
    },
    mapListHocBaCheck() {
      let data = [];
      for (let i = 0; i < this.listHocBa.list.length; i++) {
        if (this.listHocBa.list[i].check == true) {
          data.push(this.listHocBa.list[i])
        }
      }
      this.danhSachBiaChecked = data;
      console.log("Danh sách học bạ check:")
      console.log(this.danhSachBiaChecked)
      if (this.danhSachBiaChecked.length == this.listHocBa.list.length) {
        this.CheckAll = true;
      } else {
        this.CheckAll = false;
      }
    },
    kyHocBaUsbV2(e) {
      console.error("Thông tin đóng dấu");
      this.duLieuKyHocBaUsbV2 = e;
      console.error(this.duLieuKyHocBaUsbV2);
      this.thuc_hien_ky_usb_edoc = true;

    },
    openLoading() {
      console.log("open loading")
      this.fullscreenLoading = true;
    },
    closeLoading() {
      this.thuc_hien_ky_usb_all = false;
      this.thuc_hien_ky_usb = false;
      this.fullscreenLoading = false;
    },
    kyHocBaUsbAll(e) {
      console.log("Đóng dấu học bạ usb all")
      this.duLieuKyHocBaUsbAll = e;
      this.thuc_hien_ky_usb_all = true;
      console.log(e)
    },
    kyHocBaUsbXong() {
      console.log("Hủy toàn bộ thông tin đóng dấu:")
      this.show_ky_usb = false;
      this.fullscreenLoading = false;
      this.thuc_hien_ky_usb_all = false;
      this.thuc_hien_ky_usb = false;
      this.thuc_hien_ky_usb_edoc = false;
      this.duLieuKyHocBaUsb = {};
      // this.getHocBa(this.paginate.page);
    },
    onReceiveMessage(e) {
      let data = e.data;
      if (data.other == msgType.type.signCloudCAScanHocBa ||data.other == msgType.type.signSimCAScanHocBa||data.other == msgType.type.signScanUSBTOKEN) {
        if (data.code == 200) {
          this.getHocBa(this.paginate.page);
          this.fullscreenLoading = false;
          this.thongBao('success', data.msg)
          this.hien_thi_thoi_gian_doi_ky = false;
        } else {
          this.fullscreenLoading = false;
          this.thongBao('error', data.msg)
          this.hien_thi_thoi_gian_doi_ky = false;
        }
      }


    },
    tableRowClassName({
                        row,
                        rowIndex
                      }) {
      console.log("Check trạng thái")
      console.log(row)
      if (row.trangThaiXacNhan == 1) {
        return 'success-row';
      } else {
        return 'warning-row';
      }
      return '';
    },
    xemThongTinKy(e) {
      console.log("Xem thông tin đóng dấu")
      console.log(e);
      this.tenGiaoVienChuNhiem = e.tenGVCN;
      this.list_trang_thai_ky_theo_mon = e.ketQuaHocTaps;
      this.hien_thi_thong_tin_ky = true;
    },
    getClass(e) {
      let result = "";
      if (e == 1) {
        result = "daKy"
      }
      if (e == -1) {
        result = "chuaKy"
      }
      if (e == 0) {
        result = "dangKy"
      }
      return result;
    },
    showThongTinKy(e) {
      console.error('showThongTinKy')
      console.error(this.kieuKyGiaoVien)
      this.CheckAll = false;
      for (let i = 0; i < this.listHocBa.list.length; i++) {
        this.listHocBa.list[i].check = false;
      }
      e.check = true;
      let duLieu = e;
      this.CheckAll = false;
      if (this.kieuKyGiaoVien == 1) {
        console.log("Đóng dấu OTP")
        for (let i = 0; i < this.listHocBa.list.length; i++) {
          if (this.listHocBa.list[i].id != e.id) {
            this.listHocBa.list[i].check = false;
          } else {
            this.listHocBa.list[i].check = true;
          }
        }
        this.openFormOtp(duLieu, 1);
      }
      if (this.kieuKyGiaoVien == 2) {
        console.log("Đóng dấu SIM CA")
        this.kyDuLieuMotHocBa(duLieu)
      }
      if (this.kieuKyGiaoVien == 5) {
        console.log("Đóng dấu Cloud CA")
        this.kyHocBaListCloudCA([duLieu])
      }
      if (this.kieuKyGiaoVien == 6) {
        console.log("Đóng dấu Cloud VNPT")
        this.checkTokenVnpt()
        this.kyHocBaListCloudCAVNPT([duLieu])
      }
    },
    getDiemTongKetMon(e) {
      this.layDiemTongKetMon({
        maLop: e,
      });
      this.idDiemTongKet = this.diemTongKetMon;
      this.showDiemTongKet = true;
    },
    getCurrentMonth() {
      let d = new Date();
      return d.getMonth();
    },
    ...mapActions('teacher', [
      "getListLop",
      "getListHocBa",
      "clear",
      "updateHocBa",
      "kySoHocBa",
      "getReport",

    ]),
    ...mapActions("hocba", [
      "getDetail",
      "saveTempKq",
      "getFile",
      "layDiemTongKetMon",
    ]),

    kyVnpt() {

    },

    indexMethod(index) {
      return (this.paginate.start + index) + 1;
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.listFile.toggleRowSelection(row);
        });
      } else {
        this.$refs.listFile.clearSelection();
      }
    },
    getKhoiHoc() {
      let arr = [];
      this.optionGrade = [];
      for (let i = 0; i < this.list_cap_hoc.length; i++) {
        if (this.list_cap_hoc[i].value == 1) {
          arr = arr.concat(this.khoiCap1)
        }
        if (this.list_cap_hoc[i].value == 2) {
          arr = arr.concat(this.khoiCap2)
        }
        if (this.list_cap_hoc[i].value == 3) {
          arr = arr.concat(this.khoiCap3)
        }
      }
      this.optionGrade = arr;
    },
    closeDialogOtp() {
      // this.toggleSelection();
      this.showDialogOtp = false;
    },

    handleSelectionChange(val) {
      this.listHocBaCheck = val;
    },

    xemBaoCao() {
      this.fullscreenLoading = true;
      this.getReport({
        maLop: this.searchForm.class,
        namHoc: this.searchForm.year ? this.searchForm.year : '',
      })
    },

    detailHocBaClose() {
      this.saveTempKq(null);
      this.showEditDiem = false;
    },
    dongDiemTongKetMon() {
      this.showDiemTongKet = false;
    },

    submitSearch(formName) {
      this.thuc_hien_ky_usb_edoc = false;
      this.listHocBa = [];
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.getHocBa(1)
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    ChonSoLuongBanGhi(e) {
      console.log('ChonSoLuongBanGhi')
      console.log(e)
      this.paginate.limit = e.soluong;
      this.getHocBa(1);
    },
    getHocBa(select) {
      try {
        this.danhSachBiaChecked = [];
        this.CheckAll = false;
        this.loading = true;
        this.showDialogOtp = false;
        this.paginate.start = (select - 1) * this.paginate.limit ? (select - 1) * this.paginate.limit : 0;
        this.paginate.page = select;
        let params = {
          start: this.paginate.start,
          limit: this.paginate.limit,
          maLop: this.searchForm.class,
          namHoc: this.searchForm.year ? this.searchForm.year : '',
          capHoc: this.searchForm.education,
          khoiHoc: this.searchForm.grade,
          tenHS: this.searchForm.student,
          maHS: this.searchForm.maHS,
          trangThaiHocSinh: this.searchForm.trangThaiHocSinh,

        }
        let uri = constant_api.giaovien.dsHocBaDongDauScan;
        console.log('uri: ' + uri)
        this.fullscreenLoading = true;
        api.get(uri, params).then(
            response => {
              console.log("Ds bìa trả về:")
              console.log(response)
              if (response.data.code == 200) {
                let du_lieu = response.data.data;
                let list_bia = du_lieu.list;
                for (let i = 0; i < list_bia.length; i++) {
                  list_bia[i].check = false;
                }
                du_lieu.list = list_bia;
                this.listHocBa = du_lieu;
                this.getTimeDown();
              } else {
                this.thongBao('error', response.data.msg)
              }
              this.fullscreenLoading = false;
              this.loading = false;
            }
        );
        //----------

      } catch (e) {
        this.thongBao('error', 'Vui lòng thao tác lại.')
      }


    },
    getTimeDown() {
      if (this.kieuKyGiaoVien == 5) {
        this.timeLeft = 100;
      }
      if (this.kieuKyGiaoVien == 6) {
        this.timeLeft = 300;
      }
    },
    success() {
      // this.getListHocBa({
      //     start: this.paginate.start,
      //     limit: this.paginate.limit,
      //     maLop: this.searchForm.class,
      //     namHoc: this.searchForm.year ? this.searchForm.year : '',
      //     capHoc: this.searchForm.education,
      //     khoiHoc: this.searchForm.grade,
      //     tenHS: this.searchForm.student,
      //     maHS: this.searchForm.maHS,
      //     trangThai: this.searchForm.status
      // });
      this.getHocBa(1);
      this.showDialogOtp = false;
    },
    onYearChange() {

      this.searchForm.student = '';
      this.searchForm.maHS = '';
      this.searchForm.status = '';
      this.searchForm.grade = '';
      this.searchForm.class = '';
      this.danh_sach_lop_hoc = []
    },
    capHocChange() {
      this.searchForm.student = '';
      this.searchForm.maHS = '';
      this.searchForm.status = '';
      this.searchForm.grade = ''
      this.searchForm.class = '';
      if (!this.searchForm.education) {
        this.getKhoiHoc();
        return;
      } else {
        if (this.searchForm.education == 1) {
          this.optionGrade = this.khoiCap1;
        } else if (this.searchForm.education == 2) {
          this.optionGrade = this.khoiCap2;
        } else {
          this.optionGrade = this.khoiCap3;
        }
      }
    },
    khoiHocChange() {
      this.getlistLopHoc();
      this.searchForm.class = '';

    },

    getTotalPage(number) {
      return Math.ceil(number / (this.paginate.limit));
    },

    getlistLopHoc() {
      this.getListLop({
        namHoc: this.searchForm.year,
        start: 0,
        limit: 999,
        khoiHoc: this.searchForm.grade
      })
    },

    viewFile(id) {
      this.fullscreenLoading = true;
      this.getFile({
        maHocBa: id
      })
    },
    xemHocBa2(e) {
      this.idHocBa = e;
      this.ThongTinHocBa = true;
    },

    xemHocBa(data) {
      console.log("Chi tiết học bạ")
      console.log(data)
      let params = {
        maLop: data.maLop,
        maHS: data.maHS,
        isLoad: false,
        isDau: false,
        isBia: true,
        isKQHT: false,
        isQTHT: true,
        inNamCu: false,
        isAnNhanXet: false,
        isAnh: true
      }
      this.layChiTietFilePDF(params);
    },

    layChiTietFilePDF(e) {
      console.log(e);
      this.fullscreenLoading = true;
      let url = constant.hocba.detailPdf;
      api.post(url, e).then(
          response => {
            this.fullscreenLoading = false;
            console.log("Res chi tiết:")
            console.log(response)
            if (response.data.code == 200) {
              let linkViewFile = response.data.data;
              this.openFancyBox(linkViewFile);
            } else {
              this.thongBao('error', response.data.msg)
            }
          }
      )
    },
    openFancybox(id) {
      this.idEdit = id;
      this.getDetail({
        id: id
      })
      this.showEditDiem = true;
    },
    openFormOtp(e, type) {
      this.duLieuHocBaCanKy = e;
      this.duLieuHocBaCanKy.loaiHocBa = msgType.type.sendOtpBiaHocBa;
      let id = e.id
      this.hocbaDatas = [id];
      console.error('hocbaDatas')
      console.error(this.hocbaDatas)
      console.error('duLieuHocBaCanKy')
      console.error(this.duLieuHocBaCanKy)
      this.showDialogOtp = true;
    },
    kyUsb(id) {

    },
    kySo(arr) {
      try {
        let duLieu = arr;
        var ids = [];
        for (let i = 0; i < duLieu.length; i++) {
          let obj = {
            id: duLieu[i].id
          }
          ids.push(obj)
        }
        console.log(ids)
        let msg = '';
        if (duLieu.length == 1) {
          msg = 'Xác nhận đóng dấu hiệu chỉnh hồ sơ?'
        } else {
          msg = 'Xác nhận đóng dấu hiệu chỉnh hồ sơ của ' + duLieu.length + ' học bạ đã chọn?'
        }
        this.$confirm(msg, 'Thông báo', {
          confirmButtonText: 'Đồng ý',
          cancelButtonText: 'Hủy',
        })
            .then(_ => {
              let data = {
                maLop: this.searchForm.class,
                reqs: ids,
                msgType: msgType.type.signSimCAScanHocBa,
                position:2,
              }
              console.log("Thông tin đóng dấu")
              console.log(data)
              websocket.sendMessage(data);
              this.showDialogWait();
            })
            .catch(_ => {
            });
      } catch (e) {
        this.thongBao('error', 'Vui lòng thử lại sau ít phút')
      }
    },

    kySo2(ids, type) {
      if (type) {
        this.typeKy = type;
      } else {
        this.typeKy = null;
      }
      let data = [];
      ids.map((val) => {
        data.push({
          id: val
        })
        return val;
      });
      dialog.confirmDialog('Xác nhận đóng dấu học bạ', 'Xác nhận', () => {
        this.fullscreenLoading = true;
        this.kySoHocBa({
          reqs: data,
          type: type == 1 ? '' : type
        })
      })
    },
    openFormOtp2(ids) {
      let datas = [];
      let capHoc = null;
      let maGVCN = null;
      let maLop = null;
      for (let i = 0; i < ids.length; i++) {
        if (ids[i].maGVCN) {
          maGVCN = ids[i].maGVCN
        }
        capHoc = ids[i].loaiHocBa
        if (ids[i].maLop) {
          maLop = ids[i].maLop
        }

        if (ids[i].mark == false) {
          datas.push(ids[i].id)
        }
      }
      let duLieuHocBaCanKy = {
        loaiHocBa: capHoc,
        maLop: maLop,
        maGVCN: maGVCN,

      }
      this.duLieuHocBaCanKy = duLieuHocBaCanKy;
      this.hocbaDatas = datas;
      if (this.hocbaDatas.length > 0) {
        this.showDialogOtp = true;
      } else {

        this.thongBao('error', 'Danh sách học bạ đã đóng dấu')
      }
    },
    thongBao(t, e) {
      let msg = "";
      let cl = "";
      if (e) {
        msg = e;
      }
      let type = "success";
      if (t) {
        type = t
      }
      if (type == "success") {
        cl = "dts-noty-success"
      }
      if (type == "warning") {
        cl = "dts-noty-warning"
      }
      if (type == "error") {
        cl = "dts-noty-error"
      }
      if (type == "info") {
        cl = "dts-noty-info"
      }
      this.$message({
        customClass: cl,
        showClose: true,
        message: msg,
        type: t,
        duration: 3000
      });
    },
    openFancyBox(url) {
      $.fancybox.open({
        src: url,
        type: 'iframe',
      });
    },
    checkDuyet(item) {
      let role = this.user.role;
      if (role == 2 || item.role == 2) {
        if (!item.trangThaiXacNhanGVCN && item.trangThaiKyGVCN) {
          return true
        }
      } else if (role == 3) {
        if (item.trangThaiXacNhanGVCN && !item.trangThaiXacNhanHieuTruong) {
          return true;
        }
      }
      return false;
    },
    checkEdit(item) {
      let role = this.user.role;
      if (role == 1) {
        if (item.nguonDuLieu) {
          return true
        }
      } else if (role == 2) {
        if (item.nguonDuLieu) {
          return true;
        }
      }
      return false;
    },

    closeDialogAlert() {
      this.getListHocBa({
        start: this.paginate.start,
        limit: this.paginate.limit,
        maLop: this.searchForm.class,
        namHoc: this.searchForm.year ? this.searchForm.year : '',
        capHoc: this.searchForm.education,
        khoiHoc: this.searchForm.grade,
        tenHS: this.searchForm.student,
        maHS: this.searchForm.maHS,
        trangThai: this.searchForm.status
      });
      this.showDialogAlert = false;
      this.showDialogAlertUsb = false;
    },
    getFullYear() {
      let d = new Date();
      return d.getFullYear();
    },

    checkPluginCallback() {
      console.log('1232331')
    },
    logData(e) {
      console.log(e);

    }
  },

  beforeMount() {
    window.addEventListener('message', this.onReceiveMessage, false);
    if (this.user.role == 1) {
      this.optionStatus = [{
        value: '',
        label: 'Trạng thái'
      },
        {
          value: 0,
          label: 'Chưa đóng dấu'
        },
        {
          value: 1,
          label: 'Đã đóng dấu'
        },
      ];
    }
    if (this.user.role == 2) {
      this.optionStatus = [{
        value: '',
        label: 'Trạng thái'
      },
        {
          value: 2,
          label: 'Giáo viên bộ môn đang đóng dấu'
        },
        {
          value: 3,
          label: 'Giáo viên bộ môn đã đóng dấu'
        },
        {
          value: 4,
          label: 'Giáo viên chủ nhiệm đã đóng dấu'
        },
      ];
    }
    if (this.user.role == 3) {
      this.optionStatus = [{
        value: '',
        label: 'Trạng thái'
      },
        {
          value: 4,
          label: 'GVCN đã đóng dấu'
        },
        {
          value: 5,
          label: 'GVCN chưa đóng dấu'
        },
        {
          value: 6,
          label: 'Hiệu trưởng đã đóng dấu'
        },
      ];
    }
  },

  beforeDestroy() {
    console.log('beforeDestroy đóng dấu bìa học bạ');
    window.removeEventListener('message', this.onReceiveMessage)
    this.show_ky_usb = false;
  },
  mounted() {
    this.maxHeightTable = this.getHeightTable()
    if (localStorage.getItem('dataTokenVnptCa')) {
      this.tokenSmartCa = JSON.parse(localStorage.getItem('dataTokenVnptCa'))
      this.checkTokenVnpt();
    }
    this.getKieuKy();
    this.searchForm.year = this.getFullYear();
    this.searchForm.education = this.user.capHoc;

    let currentMonth = this.getCurrentMonth() + 1;
    if (currentMonth >= 9) {
    } else {
      this.searchForm.year -= 1;
    }
    this.getListHocBa({
      start: 0,
      limit: 0,
      maLop: this.searchForm.class,
      namHoc: this.searchForm.year ? this.searchForm.year : '',
      capHoc: this.searchForm.education,
      khoiHoc: this.searchForm.grade,
      tenHS: this.searchForm.student,
      trangThai: this.searchForm.status
    });

    this.setEucation();
    // this.optionGrade = this.khoiCap1.concat(this.khoiCap2, this.khoiCap3);
    this.getKhoiHoc();
  },
  watch: {
    listLop(val) {
      console.log("Danh sách lớp thay đổi:")
      console.log(val)
      if (val) {
        this.danh_sach_lop_hoc = val.list
      }
    },
    listHocBa(val) {
      if (val) {
        // this.loading = false;
      }
    },
    kySoStatus(val) {
      console.log(val);
      if (val) {
        this.fullscreenLoading = false;
        this.showDialogAlert = true;
        if (val == 'success') {
          console.log('success');
          this.getListHocBa({
            start: this.paginate.start,
            limit: this.paginate.limit,
            maLop: this.searchForm.class,
            namHoc: this.searchForm.year ? this.searchForm.year : '',
            capHoc: this.searchForm.education,
            khoiHoc: this.searchForm.grade,
            tenHS: this.searchForm.student,
            maHS: this.searchForm.maHS,
            trangThai: this.searchForm.status
          });
        }
      }
    },
    kyHocBaStatus(val) {
      if (val == 'success') {
        this.getListHocBa({
          start: this.paginate.start,
          limit: this.paginate.limit,
          maLop: this.searchForm.class,
          namHoc: this.searchForm.year ? this.searchForm.year : '',
          capHoc: this.searchForm.education,
          khoiHoc: this.searchForm.grade,
          tenHS: this.searchForm.student,
          maHS: this.searchForm.maHS,
          trangThai: this.searchForm.status
        });
      }
    },
    kyUsbStatus(val) {
      console.log(val);
      if (val) {
        this.fullscreenLoading = false;
        this.showDialogAlertUsb = true;

      }
    },
    fileDetail(val) {

      if (this.fileStatus == 2) {
        this.fullscreenLoading = false;
        let linkPfd = this.fileDetail + '#toolbar=0';
        this.openFancyBox(linkPfd)
      }
    },

    reportUrl(val) {
      if (this.reportStatus == 2) {
        this.fullscreenLoading = false;
        if (val) {
          this.openFancyBox(this.reportUrl)
        } else {
          dialog.showDialog('Thông báo', 'Không tìm thấy báo cáo')
        }
      }
      if (this.reportStatus == 1) {
        this.fullscreenLoading = false;
        dialog.showDialog('Thông báo', this.reportUrl)
      }
    },
  },

}
</script>

<style scoped>
.btn__table_action button {
  padding: 8px 10px;
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.el-form-item {
  width: 100%
}

p {
  padding: 0;
  margin: 0;
}

.dangKy {
  color: #409EFF;
}

.chuaKy {
  color: #E6A23C;
}

.daKy {
  color: #479e24;
}

.el-dialog.thongTinKyTheoMonHoc {
  min-width: 375px;
}

.el-dialog.el-dialog--center.thongTinKyTheoMonHoc {
  min-width: 375px;
}

.bg-purple-light {
  background: #e5e9f2;
  border-radius: 5px;
  padding: 10px 15px;
}

.el-radio {
  color: #606266;
  cursor: pointer;
  padding: 15px 8px;
  background: whitesmoke;
  border-radius: 8px;
  margin: 10px 15px;
}
</style>