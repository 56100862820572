var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c(
      "div",
      {
        staticClass:
          "page__title d-flex align-items-center justify-content-between",
      },
      [
        _c("h1", { staticClass: "title" }, [_vm._v("Nhập kết quả học tập")]),
        _vm._v(" "),
        _c(
          "el-tooltip",
          {
            staticClass: "item",
            attrs: {
              effect: "dark",
              content: "Hướng dẫn nhập dữ liệu",
              placement: "top",
            },
          },
          [
            _c("a", { attrs: { href: "/files/huong_dan_nhap_du_lieu.docx" } }, [
              _vm._v(" Hướng dẫn nhập dữ liệu"),
            ]),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "page__header" },
      [
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [
            _c(
              "el-col",
              { attrs: { xs: 12, sm: 6, md: 4, lg: 4 } },
              [
                _c("label", [_vm._v("Năm học")]),
                _vm._v(" "),
                _c("eselect", {
                  staticStyle: { width: "100%" },
                  attrs: {
                    collapseTags: "",
                    placeholder: "Chọn",
                    filterable: "",
                    data: _vm.list_nam_hoc,
                    fields: ["name", "value"],
                  },
                  model: {
                    value: _vm.namHoc,
                    callback: function ($$v) {
                      _vm.namHoc = $$v
                    },
                    expression: "namHoc",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-col",
              { attrs: { xs: 12, sm: 6, md: 4, lg: 4 } },
              [
                _c("label", [_vm._v("Cấp học")]),
                _vm._v(" "),
                _c("eselect", {
                  staticStyle: { width: "100%" },
                  attrs: {
                    collapseTags: "",
                    placeholder: "Chọn",
                    filterable: "",
                    data: _vm.list_cap_hoc,
                    fields: ["name", "value"],
                  },
                  model: {
                    value: _vm.capHoc,
                    callback: function ($$v) {
                      _vm.capHoc = $$v
                    },
                    expression: "capHoc",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _vm.capHoc && (_vm.capHoc == 2 || _vm.capHoc == 3)
      ? _c("div", [
          _c(
            "div",
            { staticClass: "page__header" },
            [
              _vm._m(0),
              _vm._v(" "),
              _c("ImportForNhanXet", {
                attrs: {
                  year: _vm.namHoc,
                  "cap-hoc": _vm.capHoc,
                  fileNameError: "danh_sach_loi_nhap_diem_tong_ket_mon",
                  "require-nam-hoc": true,
                  "require-cap-hoc": true,
                  requireImportType: true,
                  "data-type": 11,
                  requireLstHocKy: true,
                  "text-download": "Tải file nhập Điểm tổng kết môn",
                  "require-khoi-hoc": _vm.getRequireKhoiHoc(),
                  "uri-dowload": _vm.downloadDiemTongKet,
                  source: _vm.sourceDiemTongKet,
                  requireKhoiHoc: true,
                  hideClass: false,
                  path:
                    _vm.typeDiemTongKet === 1
                      ? _vm.path
                      : _vm.pathDiemTongKetChung,
                  disabledDownload: _vm.typeDiemTongKet != 2,
                  importType: _vm.typeDiemTongKet,
                  "name-file": "diem-tong-ket",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page__header" },
            [
              _c("h5", { staticClass: "title" }, [
                _vm._v("Điểm thi lại, học lại"),
              ]),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _c("ImportForNhanXet", {
                attrs: {
                  year: _vm.namHoc,
                  "cap-hoc": _vm.capHoc,
                  "require-nam-hoc": true,
                  fileNameError: "danh_sach_loi_nhap_ren_luyen_he",
                  "require-cap-hoc": true,
                  requireImportType: true,
                  "text-download": "Tải file nhập Điểm thi lại, học lại",
                  "data-type": 12,
                  requireKhoiHoc: true,
                  hideClass: false,
                  "uri-dowload": _vm.dowloadRenLuyenHe,
                  source: _vm.sourceRenLuyenHe,
                  path:
                    _vm.typeRenLuyenHe === 1
                      ? _vm.pathRenLuyenHe
                      : _vm.pathRenLuyenHeChung,
                  "name-file": "ren-luyen-he",
                  importType: _vm.typeRenLuyenHe,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page__header" },
            [
              _c("h5", { staticClass: "title" }, [_vm._v("Nghề Phổ Thông")]),
              _vm._v(" "),
              _vm._m(2),
              _vm._v(" "),
              _c("ImportForNhanXet", {
                attrs: {
                  year: _vm.namHoc,
                  "cap-hoc": _vm.capHoc,
                  "require-nam-hoc": true,
                  "require-cap-hoc": true,
                  requireKhoiHoc: true,
                  hideClass: false,
                  "text-download": "Tải file nhập Nghề phổ thông",
                  "data-type": 13,
                  fileNameError: "danh_sach_loi_nhap_nghe_pho_thong",
                  requireImportType: true,
                  "uri-dowload": _vm.dowloadNghePT,
                  source: _vm.sourceNghePT,
                  path:
                    _vm.typeNghePT === 1 ? _vm.pathNghePT : _vm.pathNghePTChung,
                  "name-file": "nghe-pt",
                  importType: _vm.typeNghePT,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.capHoc == 3 &&
                    (_vm.user.maSo == 96 || _vm.user.maSo == 62),
                  expression: "capHoc==3&&(user.maSo==96||user.maSo==62)",
                },
              ],
              staticClass: "page__header",
            },
            [
              _c("h5", { staticClass: "title" }, [_vm._v("Nhận xét môn GDCD")]),
              _vm._v(" "),
              _vm._m(3),
              _vm._v(" "),
              _c("ImportForNhanXet", {
                attrs: {
                  year: _vm.namHoc,
                  isGdcd: true,
                  "cap-hoc": _vm.capHoc,
                  fileNameError: "danh_sach_loi_nhap_nhan_xet_gdcd",
                  "require-nam-hoc": true,
                  "require-cap-hoc": true,
                  requireKhoiHoc: true,
                  hideClass: false,
                  "text-download": "Tải file nhập Nhận xét môn GDCD",
                  requireImportType: true,
                  "uri-dowload":
                    _vm.typeNhanXetGDCD === 1
                      ? _vm.dowloadNhanXetGdcd
                      : _vm.downloadNhanXetGdcdChung,
                  source: _vm.sourceNhanXetGDCD,
                  path:
                    _vm.typeNhanXetGDCD === 1
                      ? _vm.pathNhanXetGDCD
                      : _vm.pathNhanXetGDCDChung,
                  "name-file": "nx-gdcd",
                  importType: _vm.typeNhanXetGDCD,
                },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.capHoc && _vm.capHoc == 1
      ? _c("div", [
          _c(
            "div",
            { staticClass: "page__header" },
            [
              _vm._m(4),
              _vm._v(" "),
              _c("ImportForNhanXet", {
                attrs: {
                  year: _vm.namHoc,
                  "cap-hoc": _vm.capHoc,
                  "require-nam-hoc": true,
                  "require-cap-hoc": true,
                  "data-type": 5,
                  fileNameError: "danh_sach_loi_nhap_so_nhan_xet",
                  requireImportType: true,
                  "uri-dowload": _vm.downloadSoNhanXetChung,
                  source: _vm.source,
                  requireKhoiHoc: true,
                  "text-download": "Tải file nhập Sổ nhận xét",
                  hideClass: false,
                  disabledDownload: false,
                  path:
                    _vm.typeSoNhanXet === 1
                      ? _vm.pathSoNhanXet
                      : _vm.pathSoNhanXetChung,
                  "name-file": "so-nhan-xet",
                  importType: _vm.typeSoNhanXet,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.user.qlnt == 2
            ? _c(
                "div",
                { staticClass: "page__header" },
                [
                  _vm._m(5),
                  _vm._v(" "),
                  _c("ImportForNhanXet", {
                    attrs: {
                      year: _vm.namHoc,
                      "cap-hoc": _vm.capHoc,
                      "require-nam-hoc": true,
                      "data-type": 7,
                      "text-download": "Tải file nhập Điểm tổng kết",
                      "require-cap-hoc": true,
                      requireKhoiHoc: true,
                      fileNameError: "danh_sach_loi_nhap_diem_tong_ket",
                      requireImportType: true,
                      "require-khoi-hoc": _vm.getRequireKhoiHoc(),
                      "uri-dowload": _vm.downloadDiemTongKetC1,
                      source: _vm.sourceDiemTongKet,
                      path:
                        _vm.typeDiemTongKetC1Moet == 1
                          ? _vm.pathDiemtTongKetC1
                          : _vm.pathDiemTongKetC1Chung,
                      disabledDownload: _vm.typeDiemTongKetC1Moet != 2,
                      importType: _vm.typeDiemTongKetC1Moet,
                      hideClass: false,
                      "name-file": "diem-tong-ket",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.user.maSo != 89
            ? _c(
                "div",
                { staticClass: "page__header" },
                [
                  _vm._m(6),
                  _vm._v(" "),
                  _c("ImportForNhanXet", {
                    attrs: {
                      year: _vm.namHoc,
                      "cap-hoc": _vm.capHoc,
                      "require-nam-hoc": true,
                      "data-type": 6,
                      "text-download": "Tải file nhập Năng lực - Phẩm chất",
                      requireKhoiHoc: true,
                      hideClass: false,
                      fileNameError: "danh_sach_loi_nhap_nang_luc_pham_chat",
                      "require-cap-hoc": true,
                      disabledDownload: _vm.typeNLPC != 2,
                      requireImportType: true,
                      "uri-dowload":
                        _vm.typeNLPC == 1
                          ? _vm.dowloadNangLucPhamChat
                          : _vm.downloadNangLucPhamChatChung,
                      source: _vm.sourceNLPC,
                      path:
                        _vm.typeNLPC === 1
                          ? _vm.pathPhamChatNangLuc
                          : _vm.pathPhamChatNangLucChung,
                      "name-file": "nang-luc-pham-chat",
                      importType: _vm.typeNLPC,
                    },
                  }),
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "page__header" },
                [
                  _vm._m(7),
                  _vm._v(" "),
                  _c("ImportForNhanXet", {
                    attrs: {
                      year: _vm.namHoc,
                      "text-download": "Tải file nhập Năng lực - Phẩm chất",
                      "data-type": 6,
                      "cap-hoc": _vm.capHoc,
                      "require-nam-hoc": true,
                      fileNameError: "danh_sach_loi_nhap_nang_luc_pham_chat",
                      "require-cap-hoc": true,
                      disabledDownload: _vm.typeNLPC != 2,
                      requireKhoiHoc: true,
                      hideClass: false,
                      requireImportType: false,
                      "uri-dowload":
                        _vm.typeNLPC == 1
                          ? _vm.dowloadNangLucPhamChat
                          : _vm.downloadNangLucPhamChatChung,
                      source: _vm.sourceNLPC,
                      path: _vm.pathPhamChatNangLucAnGiang,
                      "name-file": "nang-luc-pham-chat",
                      importType: _vm.typeNLPC,
                    },
                  }),
                ],
                1
              ),
          _vm._v(" "),
          _vm.user.qlnt == 1
            ? _c(
                "div",
                { staticClass: "page__header" },
                [
                  _vm._m(8),
                  _vm._v(" "),
                  _c("ImportForNhanXet", {
                    attrs: {
                      year: _vm.namHoc,
                      "cap-hoc": _vm.capHoc,
                      "data-type": 7,
                      "text-download": "Tải file nhập Điểm tổng kết",
                      "require-nam-hoc": true,
                      "require-cap-hoc": true,
                      fileNameError: "danh_sach_loi_nhap_diem_tong_ket",
                      requireImportType: true,
                      requireKhoiHoc: true,
                      hideClass: false,
                      "require-khoi-hoc": _vm.getRequireKhoiHoc(),
                      "uri-dowload": _vm.downloadDiemTongKetC1,
                      source: _vm.sourceDiemTongKet,
                      path:
                        _vm.typeDiemTongKetC1 === 1
                          ? _vm.pathDiemtTongKetC1
                          : _vm.pathDiemTongKetC1Chung,
                      disabledDownload: _vm.typeDiemTongKetC1 != 2,
                      importType: _vm.typeDiemTongKetC1,
                      "name-file": "diem-tong-ket",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page__header" },
            [
              _vm._m(9),
              _vm._v(" "),
              _c("ImportForNhanXet", {
                attrs: {
                  year: _vm.namHoc,
                  "data-type": 8,
                  "cap-hoc": _vm.capHoc,
                  "text-download": "Tải file nhập Chiều cao - Cân nặng",
                  "require-nam-hoc": true,
                  "require-cap-hoc": true,
                  fileNameError: "danh_sach_loi_nhap_chieu_cao_can_nang",
                  requireImportType: true,
                  "uri-dowload":
                    _vm.typeChieuCaoCanNang === 1
                      ? _vm.dowloadChieuCaoCanNang
                      : _vm.downloadChieuCaoCanNangChung,
                  source: _vm.sourceChieuCaoCanNang,
                  requireKhoiHoc: true,
                  hideClass: false,
                  disabledDownload: _vm.typeChieuCaoCanNang != 2,
                  path:
                    _vm.typeChieuCaoCanNang === 1
                      ? _vm.pathChieuCaoCanNang
                      : _vm.pathChieuCaoCanNangChung,
                  importType: _vm.typeChieuCaoCanNang,
                  "name-file": "chieu-cao-can-nang",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page__header" },
            [
              _c("h5", { staticClass: "title" }, [_vm._v("Số ngày nghỉ")]),
              _vm._v(" "),
              _vm._m(10),
              _vm._v(" "),
              _c("ImportForNhanXet", {
                attrs: {
                  "require-cap-hoc": true,
                  "require-nam-hoc": true,
                  "text-download": "Tải file nhập Số ngày nghỉ",
                  requireImportType: true,
                  fileNameError: "danh_sach_loi_nhap_so_ngay_nghi",
                  year: _vm.namHoc,
                  "cap-hoc": _vm.capHoc,
                  "data-type": 9,
                  requireKhoiHoc: true,
                  hideClass: false,
                  "uri-dowload": _vm.dowloadNgayNghi,
                  source: _vm.sourceNgayNghi,
                  path:
                    _vm.typeSoNgayNghi === 1
                      ? _vm.pathNgayNghi
                      : _vm.pathNgayNghiChung,
                  importType: _vm.typeSoNgayNghi,
                  "name-file": "ngay-nghi",
                },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "page__title align-items-center justify-content-between" },
      [
        _c("h2", { staticClass: "title" }, [_vm._v("Điểm tổng kết môn")]),
        _vm._v(" "),
        _c("small", [
          _c("i", [
            _vm._v(
              " (Truy cập Học bạ số -> Nhập kết quả học tập -> Tải file mẫu Điểm tổng kết môn)"
            ),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("small", [
      _c("i", [
        _vm._v(
          " (Truy cập Học bạ số -> Nhập kết quả học tập -> Tải file mẫu Điểm thi lại, học lại)"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("small", [
      _c("i", [
        _vm._v(
          " (Truy cập Học bạ số -> Nhập kết quả học tập -> Tải file mẫu Nghề phổ thông)"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("small", [
      _c("i", [
        _vm._v(
          " (Truy cập Học bạ số -> Nhập kết quả học tập -> Tải file mẫu Nhận xét môn GDCD)"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "page__title align-items-center justify-content-between" },
      [
        _c("h1", { staticClass: "title" }, [_vm._v("Sổ nhận xét")]),
        _vm._v(" "),
        _c("small", [
          _c("i", [
            _vm._v(
              " (Truy cập Học bạ số -> Nhập kết quả học tập -> Tải file mẫu Sổ nhận xét)"
            ),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "page__title align-items-center justify-content-between" },
      [
        _c("h1", { staticClass: "title" }, [_vm._v("Điểm tổng kết")]),
        _vm._v(" "),
        _c("small", [
          _c("i", [
            _vm._v(
              " (Truy cập Học bạ số -> Nhập kết quả học tập -> Tải file Điểm tổng kết)"
            ),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "page__title align-items-center justify-content-between" },
      [
        _c("h1", { staticClass: "title" }, [_vm._v("Năng lực - Phẩm chất")]),
        _vm._v(" "),
        _c("small", [
          _c("i", [
            _vm._v(
              " (Truy cập Học bạ số -> Nhập kết quả học tập -> Tải file Năng lực - Phẩm chất)"
            ),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "page__title align-items-center justify-content-between" },
      [
        _c("h1", { staticClass: "title" }, [_vm._v("Năng lực - Phẩm chất")]),
        _vm._v(" "),
        _c("small", [
          _c("i", [
            _vm._v(
              " (Truy cập Học bạ số -> Nhập kết quả học tập -> Tải file Năng lực - Phẩm chất)"
            ),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "page__title align-items-center justify-content-between" },
      [
        _c("h1", { staticClass: "title" }, [_vm._v("Điểm tổng kết")]),
        _vm._v(" "),
        _c("small", [
          _c("i", [
            _vm._v(
              " (Truy cập Học bạ số -> Nhập kết quả học tập -> Tải file Điểm tổng kết)"
            ),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "page__title align-items-center justify-content-between" },
      [
        _c("h1", { staticClass: "title" }, [_vm._v("Chiều cao - Cân nặng")]),
        _vm._v(" "),
        _c("small", [
          _c("i", [
            _vm._v(
              " (Truy cập Học bạ số -> Nhập kết quả học tập -> Tải file mẫu Chiều cao - Cân nặng)"
            ),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("small", [
      _c("i", [
        _vm._v(
          " (Truy cập Học bạ số -> Nhập kết quả học tập -> Tải file mẫu Số ngày nghỉ)"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }