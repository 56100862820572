<template>
  <div class="page page-file file detailHocBa">
    <div v-loading.fullscreen.lock="fullscreenLoading"
         element-loading-text="Đang đồng bộ..."
         element-loading-spinner="el-icon-loading"
         element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="page__title">
        <h1 class="title">Lịch sử nộp hồ sơ</h1>
      </div>
      <div class="box-upload-file">
        <el-form :rules="rulesSearch" ref="searchForm" :model="searchForm" :hide-required-asterisk="true">
          <el-row :gutter="24">
            <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
              <el-form-item class="d-inline-block" prop="namHoc" label="Năm học">
                <eselect style="width:100%" collapseTags v-model="searchForm.namHoc"
                         placeholder="Chọn" filterable :data="list_nam_hoc"
                         :fields="['name','value']"/>
              </el-form-item>
            </el-col>
            <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
              <el-form-item class="d-inline-block" prop="education" label="Cấp học">
                <eselect :disabled="optionEducation.length==1" style="width: 100%"
                         collapseTags v-model="searchForm.education"
                         @change="capHocChange"
                         :placeholder="'Chọn'"
                         filterable :data="optionEducation" :fields="['name','value']"/>
              </el-form-item>
            </el-col>
            <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
              <el-form-item class="d-inline-block" prop="grade" label="Khối học">
                <eselect style="width:100%" collapseTags v-model="searchForm.grade"
                         @change="khoiHocChange"
                         placeholder="Chọn" filterable :data="optionGrade"
                         :fields="['label','value']"/>
              </el-form-item>
            </el-col>
            <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
              <el-form-item class="d-inline-block" label="Lớp học">
                <eselect style="width: 100%" multiple collapseTags v-model="searchForm.maLops"
                         :placeholder="'Chọn'"
                         filterable :data="danh_sach_lop_hoc" :fields="['name','value']"/>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="16" :md="12" :lg="8" :xl="6">
              <el-form-item class="d-inline-block" label="Thời gian">
                <el-date-picker
                    v-model="searchForm.times"
                    type="datetimerange"
                    align="right"
                    value-format="timestamp"
                    start-placeholder="Từ ngày"
                    :default-time="['00:00:00', '23:59:59']"
                    end-placeholder="Đến ngày">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8"></el-col>
            <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8" >
              <el-form-item style="text-align: center;">
                <label style="display: block; color: transparent" for="">Tìm kiếm</label>
                <el-button type="primary" @click="submitForm('searchForm')">Tìm kiếm</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8"></el-col>
        </el-form>
      </div>
    </div>
    <div class="file--title">
      <h5 class="title">Lịch sử nộp hồ sơ</h5>
    </div>
    <div class="page__content">
      <el-table border fit :lazy="true" :data="list_data?list_data:[]" width="100%"
                ref="listGiaoVien">
        <el-table-column label="STT" style="width: 50px" width="80" type="index" class-name="text-center"
                         :index="indexMethod">
        </el-table-column>
        <el-table-column label="Năm học" property="strNamHoc" header-align="center" align="center"></el-table-column>
        <el-table-column label="Người nộp" property="nguoiNop" header-align="center" align="center"></el-table-column>
        <el-table-column label="Dữ liệu nộp" property="type" header-align="center" align="left"></el-table-column>
        <el-table-column label="Loại nộp" property="loaiNop" header-align="center" align="left"></el-table-column>
        <el-table-column label="Thời gian" class-name="text-center">
          <template slot-scope="scope">
            {{ new Date(scope.row.createdDate) | moment("H:mm:ss DD/MM/YYYY") }}
          </template>
        </el-table-column>
        <el-table-column label="Tên lớp" class-name="text-center">
          <template slot-scope="scope">
            <div v-if="scope.row.tenLops&&scope.row.tenLops.length">
              <span v-for="item in scope.row.tenLops">{{item}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Kết quả" property="ketQua" header-align="center" align="left"></el-table-column>
      </el-table>

      <div class="mt-05" v-if="historySync && historySync.total">
        <el-row :gutter="20">
          <el-col :span="8">
            <chonSoLuong @chonXongSoLuong="ChonSoLuongBanGhi($event)"></chonSoLuong>
          </el-col>
          <el-col :span="16" class="text-right">
            <small class="pd-r-10">
              <i>Danh sách có {{ historySync.total }} bản ghi </i>
            </small>
            <pagination v-if="historySync && historySync.total" :page-count="getTotalPage(historySync.total)"
                        v-model="paginate.page"
                        :initial-page="paginate.page" :click-handler="layDuLieuLichSu" :prev-text="'Trước'"
                        :next-text="'Sau'" :container-class="'el-pager'" :pageClass="'number'">

            </pagination>
          </el-col>
        </el-row>
      </div>
<!--      <div class="text-right mt-2">-->
<!--        <pagination v-if="historySync && historySync.total" :value="paginate.current"-->
<!--                    :page-count="getTotalPage(historySync.total)" :click-handler="layDuLieuLichSu"-->
<!--                    :prev-text="'Trước'"-->
<!--                    :next-text="'Sau'" :container-class="'el-pager'" :pageClass="'number'">-->

<!--        </pagination>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import {
  mapActions,
  mapState
} from 'vuex';
import ESelect from "../components/ESelect";
import constant from "../constant";
import Pagination from "../components/Pagination";
import constant_api from '../_helpers/constant_api';
import api from '../_helpers/api';
import chonSoLuong from "./Ui/ChonSoLuong.vue";

export default {
  name: "LichSuNopHoSo",
  metaInfo: {
    title: 'Lịch sử nộp hồ sơ',
    titleTemplate: '',
    meta: [{
      vmid: '',
      name: '',
      content: ''
    }]
  },
  computed: {
    ...mapState('account',
        [
          'user',
          'list_nam_hoc',
          'nam_hoc_hien_tai',
          'list_cap_hoc',
        ]),
  },
  components: {
    chonSoLuong,
    "eselect": ESelect,
    Pagination,
  },
  data() {
    return {
      paginate: {
        start: 0,
        limit: 100,
        current: 1,
      },
      list_data: [],
      optionEducation: [],
      optionGrade: [],

      khoiCap1: [{
        value: 1,
        label: 'Khối 1'
      },
        {
          value: 2,
          label: 'Khối 2'
        },
        {
          value: 3,
          label: 'Khối 3'
        },
        {
          value: 4,
          label: 'Khối 4'
        },
        {
          value: 5,
          label: 'Khối 5'
        },
      ],
      khoiCap2: [{
        value: 6,
        label: 'Khối 6'
      },
        {
          value: 7,
          label: 'Khối 7'
        },
        {
          value: 8,
          label: 'Khối 8'
        },
        {
          value: 9,
          label: 'Khối 9'
        },
      ],
      khoiCap3: [{
        value: 10,
        label: 'Khối 10'
      },
        {
          value: 11,
          label: 'Khối 11'
        },
        {
          value: 12,
          label: 'Khối 12'
        },
      ],
      historySync: {
        total: 0,
        data: []
      },
      danh_sach_lop_hoc:[],
      fullscreenLoading: false,
      text_load: 'Đang đồng bộ',
      searchForm: {
        times:[],
        namHoc: '',
        education:'',
        grade:'',
        maLops:[]
      },
      rulesSearch: {
        namHoc: [{
          required: true,
          message: 'Chưa chọn năm học',
          trigger: 'change'
        }],
      }
    }
  },
  methods: {
    capHocChange(){
      this.searchForm.grade = '';
      this.searchForm.maLops = [];
      this.getKhoiHoc();
    },
    getKhoiHoc() {
      console.log('getKhoiHoc')
      if (!this.searchForm.education) {
        let arr = [];
        this.optionGrade = [];
        for (let i = 0; i < this.list_cap_hoc.length; i++) {
          if (this.list_cap_hoc[i].value == 1) {
            arr = arr.concat(this.khoiCap1)
          }
          if (this.list_cap_hoc[i].value == 2) {
            arr = arr.concat(this.khoiCap2)
          }
          if (this.list_cap_hoc[i].value == 3) {
            arr = arr.concat(this.khoiCap3)
          }
        }
        this.optionGrade = arr;
      } else {
        if (this.searchForm.education == 1) {
          this.optionGrade = this.khoiCap1;
        } else if (this.searchForm.education == 2) {
          this.optionGrade = this.khoiCap2;
        } else if (this.searchForm.education == 3) {
          this.optionGrade = this.khoiCap3;
        } else {
          let arr = [];
          for (let i = 0; i < this.list_cap_hoc.length; i++) {
            if (this.list_cap_hoc[i].value == 1) {
              arr = arr.concat(this.khoiCap1)
            }
            if (this.list_cap_hoc[i].value == 2) {
              arr = arr.concat(this.khoiCap2)
            }
            if (this.list_cap_hoc[i].value == 3) {
              arr = arr.concat(this.khoiCap3)
            }
          }
          this.optionGrade = arr;
        }
      }
      console.log('optionGrade')
      console.log(this.optionGrade)
    },
    khoiHocChange(){
      console.log('khoiHocChange')
      this.searchForm.maLops = [];
      this.getlistLopHoc();
    },
    getlistLopHoc() {
      console.log('getlistLopHoc')
      let params = {
        start: 0,
        limit: 999,
        khoiHoc: this.searchForm.grade,
        namHoc: this.searchForm.namHoc,
        maTruong: this.user.maTruong,
      }
      console.log(params)
      let url = constant_api.danhMuc.danhSachLopTheoTruong;
      console.log(url)
      this.danh_sach_lop_hoc = [];
      this.loadingScreen = true;
      api.get(url, params).then(
          response => {
            if (response.data.code == 200) {
              let data = response.data.data;
              console.error('Kết quả lấy trường:')
              for (let i = 0; i < data.length; i++) {
                this.danh_sach_lop_hoc.push(
                    {
                      name: data[i].tenLop,
                      value: data[i].maLop
                    }
                )
              }
            } else {
            }
            console.error(this.list_truong_hoc)
            this.loadingScreen = false;
          }
      )
    },
    resetForm(formName) {
      this.$refs[formName].clearValidate();
    },
    indexMethod(index) {
      return (this.paginate.start + index) + 1;
    },
    getTotalPage(number){
      return Math.ceil(number / (this.paginate.limit));
    },
    submitForm(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.layDuLieuLichSu(1);
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    ChonSoLuongBanGhi(e) {
      console.log('ChonSoLuongBanGhi')
      console.log(e)
      this.paginate.limit = e.soluong;
      this.layDuLieuLichSu(1);
    },
    layDuLieuLichSu(select){
      let url = constant_api.school.lichSuNopHoSoLenBo;
      this.fullscreenLoading = true;
      this.paginate.start = (select - 1) * this.paginate.limit ? (select - 1) * this.paginate.limit : 0;
      let params = {
        start: this.paginate.start,
        limit: this.paginate.limit,
        namHoc: this.searchForm.namHoc,
        maTruong: this.user.maTruong,
        maLops: this.searchForm.maLops,
        startTime:this.searchForm.times[0]?this.searchForm.times[0]:'',
        endTime:this.searchForm.times[1]?this.searchForm.times[1]:'',
      }
      api.post(url, params).then(
          response => {
            console.log("Res lấy lịch sử:")
            console.log(response.code)
            if (response.code === 200) {
              console.log('case success')
              this.historySync = response.data.data;
              this.list_data = response.data.data.list;
              console.log('dữ liệu:')
              console.log(this.list_data)
              this.thongBao('success', response.msg)
            } else {
              console.log('case error')
              this.thongBao('error', response.msg)
            }
            this.fullscreenLoading = false;
          }
      ).catch((error) => {
        this.thongBao('success', 'Hệ thống đang cập nhật dữ liệu. Vui lòng chờ trong ít phút.');
        this.fullscreenLoading = false;
      });
    },
    thongBao(t, e) {
      let msg = "";
      let cl = "";
      if (e) {
        msg = e;
      }
      let type = "success";
      if (t) {
        type = t
      }
      if (type == "success") {
        cl = "dts-noty-success"
      }
      if (type == "warning") {
        cl = "dts-noty-warning"
      }
      if (type == "error") {
        cl = "dts-noty-error"
      }
      if (type == "info") {
        cl = "dts-noty-info"
      }
      this.$message({
        dangerouslyUseHTMLString: true,
        customClass: cl,
        showClose: true,
        message: msg,
        type: t,
        duration: 3000
      });
    },
  },
  mounted() {
    console.log("Mounted lịch sử nộp hồ sơ")
    this.searchForm.namHoc = this.nam_hoc_hien_tai;
    this.optionEducation = JSON.parse(JSON.stringify(this.list_cap_hoc));
    this.layDuLieuLichSu(1);
  },
  watch: {}
}
</script>

<style scoped>
.el-form-item {
  width: 100%
}

.resultText {
  white-space: nowrap;
  /*width: 100px;*/
  overflow: hidden;
  text-overflow: ellipsis;
  /*border: 1px solid #000000;*/
}

.showMore {
  background: #409EFF;
  padding: 1px 5px;
  border-radius: 3px;
  color: white;
  font-weight: 700;
}
.el-date-editor--datetimerange.el-input, .el-date-editor--datetimerange.el-input__inner{
  width: 100% !important;
}

</style>