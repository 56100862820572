<template>
    <div>
        <!-- <el-button @click.prevent="signUsb" type="warning" size="mini" icon="el-icon-edit"></el-button> -->
        <div class="col-lg-12" style="height:0">
            <object ref="plugin0" type="application/x-viettelcasigner" width="0" height="0">
            </object>
        </div>
    </div>

</template>

<script>
import websocket from "../websocket";
import {
    mapState,
    mapActions
} from 'vuex';

export default {
    name: "SignUsb",
    props: [
        'duLieu',
        'thuchienky'
    ],
    computed: {
        ...mapState('teacher', [
            'hash',
            'pdfSignature',
            'serialNumber'
        ]),
    },
    data: () => {
        return {
            VtPluginSocket: null,

        }
    },
    methods: {
        onReceiveMessage(e) {
            let data = e.data;

            if (data.other === 10 || data.other === 11) {
                console.log("Thông tin ký trả về từ websocket all:")
                console.log(data)

                if (data.code == 200) {
                    // this.thongBao('success', data.msg)

                    if (data.other == 10) {
                        let plugin = this.$refs.plugin0;
                        this.signHash(data.data.hashBase64, data.data.serialNumber, plugin);
                    }
                    this.close();
                } else {
                    // this.thongBao('error', data.msg);
                    this.$emit('error')
                    this.close();
                }
            }

        },
        ...mapActions('teacher', [
            'getHash',
            'kyUsbHocBa',
            'clearDataKy'
        ]),

        getVersion() {
            return this.$refs.plugin0;
        },

        initPlugin() {
            console.log(this.VtPluginSocket.initPlugin())
            if (!(this.VtPluginSocket.initPlugin()) || this.VtPluginSocket.getVersion() != '1.1.0.0') {
                this.$confirm('Cần cài đặt Viettel-CA Signer Plugin', 'Thông báo', {
                    confirmButtonText: 'Tải xuống',
                    cancelButtonText: 'Để sau',
                })
                    .then(_ => {
                        this.close();
                        window.open("<%=request.getContextPath()%>/ViettelCASigner.msi");
                    })
                    .catch(_ => {
                        console.log("Đóng")
                        this.close();
                    });
                return false;
            }
            return true;
        },

        showErrorMessage(message) {
            console.log("showErrorMessage:")
            console.log(message)
            if (this.VtPluginSocket != undefined) {
                let errorCode = this.VtPluginSocket.getLastErrorCode();

                if (errorCode != undefined || errorCode != null || errorCode != "") {
                    let errorMessage = this.VtPluginSocket.ERROR_CODE[errorCode];
                    if (errorMessage != undefined || errorMessage != null || errorMessage != "") {
                        if (message == undefined || message == null || message == "") {
                            this.$alert("Lỗi: " + errorMessage + " (" + errorCode + ")", 'Thông báo', {
                                confirmButtonText: 'Đóng',
                                callback: action => {
                                    this.$emit('error')
                                }
                            });

                        } else {
                            this.$alert(message + "\nLỗi: " + errorMessage + " (" + errorCode + ")", 'Thông báo', {
                                confirmButtonText: 'Đóng',
                                callback: action => {
                                    this.$emit('error')
                                }
                            });
                        }

                    }
                    return;
                }
                this.$alert('Không thể lấy được mã lỗi', 'Thông báo', {
                    confirmButtonText: 'Đóng',
                    callback: action => {
                        this.$emit('error')
                    }
                });
            } else {
                this.$alert('Không tồn tại đối tượng VtPluginSocket', 'Thông báo', {
                    confirmButtonText: 'Đóng',
                    callback: action => {
                        this.$emit('error')
                    }
                });
            }
        },
        close() {
            console.log("Close")
            this.$emit('close');
        },
        thongBao(t, e) {
            let msg = "";
            let cl = "";
            if (e) {
                msg = e;
            }
            let type = "success";
            if (t) {
                type = t
            }
            if (type == "success") {
                cl = "dts-noty-success"
            }
            if (type == "warning") {
                cl = "dts-noty-warning"
            }
            if (type == "error") {
                cl = "dts-noty-error"
            }
            if (type == "info") {
                cl = "dts-noty-info"
            }
            this.$message({
                customClass: cl,
                showClose: true,
                message: msg,
                type: t,
                duration: 3000
            });
        },


        signUsb() {
            if (!this.initPlugin()) {
                return;
            }

            var certChainBase64 = new String(this.VtPluginSocket.getCertChain());
            if (certChainBase64 == undefined || certChainBase64.length < 500) {
                this.thongBao('error', 'Chọn CTS không thành công')
                this.$emit('error')
                return false;
            }

            let dataHash = {
                id: this.duLieu.id,
                maLop: this.duLieu.maLop,
                certChainBase64: certChainBase64,
                msgType: 10
            };
            console.log('data lấy hash' + dataHash)
            websocket.sendMessage(dataHash);
        },

        signHash(hash, serialNumber, plugin) {
            let signatureBase64 = this.VtPluginSocket.signHash(hash, serialNumber, plugin);
            if (signatureBase64 == null || signatureBase64 == undefined || signatureBase64.trim().length == 0) {
                this.showErrorMessage("Sign Hash không thành công");
                return false;
            }

            this.kyHocBa(signatureBase64);
        },

        kyHocBa(signatureBase64) {
            let data = {
                id: this.duLieu.id,
                signatureBase64: signatureBase64,
                pdfSignature: this.pdfSignature,
                type: this.duLieu.loaiHocBa,
                maLop: this.duLieu.maLop,
                maGVCN: this.duLieu.maGVCN,
                msgType: 11
            };

            websocket.sendMessage(data);
        }
    },

    watch: {
        thuchienky: function (val) {
            if (val) {
                console.log("Thực hiện thao tác ký trong ký học bạ usb all:" + val)
                this.signUsb();
            }
        },
    },

    mounted() {
        console.log("Mounted...")
        window.addEventListener('message', this.onReceiveMessage, false);
        this.clearDataKy();
        this.VtPluginSocket = {
            initPlugin: () => {
                return this.$refs.plugin0.getVersion == null ? false : true;
            },
            getVersion: () => {
                return this.$refs.plugin0.getVersion();
            },
            getFileName: () => {
                return this.$refs.plugin0.getFileName();
            },
            getCert: () => {
                return this.$refs.plugin0.getCert();
            },
            getCertChain: () => {
                return this.$refs.plugin0.getCertChain();
            },
            signHash: function (base64Hash, certSerial, plugin) {
                return plugin.signHash(base64Hash, certSerial);
            }
        };

    }
}
</script>

<style scoped>

</style>