<template>
    <div v-loading="loading.fullScreen" element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 255, 255, 0.8)">
        <el-row :gutter="20">

            <el-col :span="24" style="margin-bottom:0">
                    <h5>Mẫu đã chọn</h5>
            </el-col>
            <el-col v-if="mau_cap_mot" :xs="24" :sm="12">
                <h5>Cấp 1</h5>
                <div class="grid-content bg-purple ">
                    <iframe :src="mau_cap_mot" frameborder="0" width="100%" height="100%"></iframe>
                </div>
            </el-col>
            <el-col v-if="mau_cap_hai" :xs="24" :sm="12">
                <h5>Cấp 2</h5>
                <div class="grid-content bg-purple ">
                    <iframe :src="mau_cap_hai" frameborder="0" width="100%" height="100%"></iframe>
                </div>
            </el-col>
            <el-col v-if="mau_cap_ba" :xs="24" :sm="12">
                <h5>Cấp 3</h5>
                <div class="grid-content bg-purple ">
                    <iframe :src="mau_cap_ba" frameborder="0" width="100%" height="100%"></iframe>
                </div>
            </el-col>
            <el-col v-if="mau_tt_gdtx" :xs="24" :sm="12">
                <h5>Trung tâm GDTX</h5>
                <div class="grid-content bg-purple ">
                    <iframe :src="mau_tt_gdtx" frameborder="0" width="100%" height="100%"></iframe>
                </div>
            </el-col>
        </el-row>
        <hr>
        <div class="d-flex flex-wrap">
            <div class="d-inline-block">
                <h5 class="font-weight-bold m-0">Danh sách các mẫu</h5>
            </div>
        </div>
        <h5>Cấp 1</h5>
        <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :lg="12" v-for="item in list_hoc_ba_cap_mot" :key="item.id">
                <div class="grid-content bg-purple dts-relative">
                    <div class="dts-absolute">
                        <el-button type="primary" @click.prevent="apDungMauHocBa(item)" size="mini">Áp dụng
                        </el-button>
                    </div>
                    <iframe :src="item.linkFile" frameborder="0" width="100%" height="95%"></iframe>
                </div>
            </el-col>
        </el-row>
        <h5>Cấp 2</h5>
        <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :lg="12" v-for="item in list_hoc_ba_cap_hai" :key="item.id">
                <div class="grid-content bg-purple dts-relative">
                    <div class="dts-absolute">
                        <el-button type="primary" @click.prevent="apDungMauHocBa(item)" size="mini">Áp dụng
                        </el-button>
                    </div>
                    <iframe :src="item.linkFile" frameborder="0" width="100%" height="95%"></iframe>
                </div>
            </el-col>
        </el-row>
        <h5>Cấp 3</h5>
        <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :lg="12" v-for="item in list_hoc_ba_cap_ba" :key="item.id">
                <div class="grid-content bg-purple dts-relative">
                    <div class="dts-absolute">
                        <el-button type="primary" @click.prevent="apDungMauHocBa(item)" size="mini">Áp dụng
                        </el-button>
                    </div>
                    <iframe :src="item.linkFile" frameborder="0" width="100%" height="95%"></iframe>
                </div>
            </el-col>
        </el-row>
        <h5>Trung tâm GDTX</h5>
        <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :lg="12" v-for="item in list_hoc_ba_tt_gdtx" :key="item.id">
                <div class="grid-content bg-purple dts-relative">
                    <div class="dts-absolute">
                        <el-button type="primary" @click.prevent="apDungMauHocBa(item)" size="mini">Áp dụng
                        </el-button>
                    </div>
                    <iframe :src="item.linkFile" frameborder="0" width="100%" height="95%"></iframe>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import {
        mapState,
        mapActions
    } from 'vuex';
    import api from '../_helpers/api';
    import constant from '../_helpers/constant_api';
    export default {
        name: "Login",
        metaInfo: {
            title: 'Học bạ số',
            titleTemplate: '%s | Cáu hình mẫu học bạ',
            meta: [{
                vmid: 'description',
                name: 'description',
                content: ''
            }]
        },
        computed: {
            ...mapState('account', ['user']),
        },
        data() {
            return {
                loading: {
                    fullScreen: false,
                },
                list_hoc_ba_cap_mot: [],
                list_hoc_ba_cap_hai: [],
                list_hoc_ba_cap_ba: [],
                list_hoc_ba_tt_gdtx: [],
                mau_cap_mot: '',
                mau_cap_hai: '',
                mau_cap_ba: '',
                mau_tt_gdtx: '',
            }
        },
        mounted() {
            this.getDanhSachMauHocBa();
        },
        methods: {
            apDungMauHocBa(item) {
                this.$confirm('Xác nhận áp dụng mẫu học bạ này?', 'Thông báo', {
                        confirmButtonText: 'Đồng ý',
                        cancelButtonText: 'Hủy',
                    })
                    .then(_ => {
                        console.log("Đồng ý:")
                        console.log(item)

                        this.loading.fullScreen = true;
                        let url = constant.hocba.apDungMau;
                        let params = {
                            ids: [item.id],
                            code: this.user.maSo
                        }
                        api.post(url, params).then(
                            response => {
                                this.loading.fullScreen = false;
                                console.log("Res:")
                                console.log(response)
                                if (response.data.code == 200) {
                                    this.thongBao('success', 'Áp dụng thành công')
                                    this.getDanhSachMauHocBa();

                                } else {

                                    this.thongBao('error', response.data.rd)
                                }
                            }
                        )
                    })
                    .catch(_ => {});
            },
            getDanhSachMauHocBa() {
                this.loading.fullScreen = true;
                let url = constant.hocba.danhSachMau;
                let params = {
                    maSo: this.user.maSo
                }
                api.get(url, params).then(
                    response => {
                        this.loading.fullScreen = false;
                        console.log("Res:")
                        console.log(response)
                        if (response.data.code == 200) {
                            this.list_hoc_ba_cap_mot = response.data.data.mauHocBaTieuHoc;
                            this.list_hoc_ba_cap_hai = response.data.data.mauHocBaTHCS;
                            this.list_hoc_ba_cap_ba = response.data.data.mauHocBaTHPT;
                            this.list_hoc_ba_tt_gdtx = response.data.data.mauHocBaGDTX;
                            this.mau_cap_mot = "";
                            this.mau_cap_hai = "";
                            this.mau_cap_ba = "";
                            this.mau_tt_gdtx = "";
                            let mau_da_chon = response.data.data.mauHocBaDaChon;
                            if (mau_da_chon.length > 0) {
                                for (let i = 0; i < mau_da_chon.length; i++) {
                                    if (mau_da_chon[i].capHoc == 1) {
                                        this.mau_cap_mot = mau_da_chon[i].linkFile
                                    }
                                }
                                for (let i = 0; i < mau_da_chon.length; i++) {
                                    if (mau_da_chon[i].capHoc == 2) {
                                        this.mau_cap_hai = mau_da_chon[i].linkFile
                                    }
                                }
                                for (let i = 0; i < mau_da_chon.length; i++) {
                                    if (mau_da_chon[i].capHoc == 3) {
                                        this.mau_cap_ba = mau_da_chon[i].linkFile
                                    }
                                }
                                for (let i = 0; i < mau_da_chon.length; i++) {
                                    if (mau_da_chon[i].capHoc == 6) {
                                        this.mau_tt_gdtx = mau_da_chon[i].linkFile
                                    }
                                }
                            }

                        } else {}
                    }
                )
            },
            thongBao(t, e) {
                let msg = "";
                let cl = "";
                if (e) {
                    msg = e;
                }
                let type = "success";
                if (t) {
                    type = t
                }
                if (type == "success") {
                    cl = "dts-noty-success"
                }
                if (type == "warning") {
                    cl = "dts-noty-warning"
                }
                if (type == "error") {
                    cl = "dts-noty-error"
                }
                if (type == "info") {
                    cl = "dts-noty-info"
                }
                this.$message({
                    customClass: cl,
                    showClose: true,
                    message: msg,
                    type: t,
                    duration: 3000
                });
            },
        }
    }
</script>

<style scoped>
    .el-col {
        border-radius: 4px;
        margin-bottom: 20px;
    }

    .bg-purple {
        background: #d3dce6;
        padding: 15px;
    }

    .grid-content {
        border-radius: 4px;
        min-height: 90px;
        height: 500px
    }

    .dts-relative {
        position: relative;
    }

    .dts-absolute {
        position: absolute;
        top: 5px;
        right: 5px;
    }
    h5 {
    font-size: 18px;
    padding-left: 10px;
    color: blue;
    font-weight: 700
  }
</style>