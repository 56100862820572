<template>
  <div id="danhSachLop" class="page page-file file detailHocBa" v-loading.fullscreen.lock="fullscreenLoading">
    <div class="page__title d-flex align-items-center justify-content-between">
      <h1 class="title">Danh sách lớp học</h1>
    </div>
    <div class="box-upload-file">
      <el-row :gutter="24" @keyup.enter.native="layDanhSachLopHoc">
        <el-col :xs="24" :sm="12" :md="8" :lg="4">
          <label>Năm học</label>
          <eselect style="width:100%" collapseTags v-model="namHoc" placeholder="Chọn" filterable
                   :data="list_nam_hoc" :fields="['name','value']"/>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :lg="4">
          <label>Cấp học</label>
          <eselect style="width:100%" :disabled="list_cap_hoc.length==1" @change="capHocChange"
                   v-model="capHoc" placeholder="Chọn" filterable
                   :data="list_cap_hoc"
                   :fields="['name','value']"/>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :lg="4">
          <label>Khối học</label>
          <eselect style="width:100%" collapseTags v-model="khoiHoc" placeholder="Chọn" filterable
                   :data="danh_sach_khoi_hoc" :fields="['label','value']"/>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :lg="6">
          <div style="color: transparent">Tìm kiếm</div>
          <el-button class="m-0" @click.prevent="layDanhSachLopHoc" type="primary">Tìm kiếm
          </el-button>
        </el-col>
      </el-row>
    </div>

    <div class="text-right">
      <!--      <el-tooltip placement="top">-->
      <!--        <div slot="content">Xoá dữ liệu của {{ danhSachLopHocCheck.length }} lớp học đã chọn</div>-->
      <!--        <el-button size="mini" :disabled="!danhSachLopHocCheck||danhSachLopHocCheck.length==0" type="danger"-->
      <!--                   @click.prevent="checkXoaDuLieuLopHoc">-->
      <!--          Xoá dữ liệu-->
      <!--        </el-button>-->
      <!--      </el-tooltip>-->
      <!--      <el-tooltip content="Xoá dữ liệu các bản ghi đã chọn" placement="top">-->
      <!--        <div slot="content">Xoá dữ liệu của toàn bộ {{ danh_sach_lop.length }} lớp học trong trường</div>-->
      <!--        <el-button :disabled="!danh_sach_lop||danh_sach_lop.length==0" size="mini" type="danger"-->
      <!--                   @click.prevent="xoaDuLieu([])">-->
      <!--          Xoá toàn bộ-->
      <!--        </el-button>-->
      <!--      </el-tooltip>-->

      <el-tooltip v-if="user.qlnt==2||user.qlnt==1" placement="top">
        <div slot="content">Phân môn</div>
        <el-button type="success" size="mini" @click.prevent="phanCongMon">Phân môn
        </el-button>
      </el-tooltip>

      <el-tooltip v-if="user.qlnt==2||user.qlnt==1" placement="top">
        <div slot="content">Phân công giảng dạy</div>
        <el-button size="mini" type="success" v-if="showPhanCongGiangDay" @click.prevent="phanMonGiangDay">
          Phân công giảng dạy
        </el-button>
      </el-tooltip>
    </div>
    <div class="page__content">
      <div class="listNew mb-5">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-table :max-height="maxHeightTable" fit lazy :data="danh_sach_lop" border
                      style="width: 100%;">

              <!--              <el-table-column fixed width="50"-->
              <!--                               align="center" header-align="center">-->
              <!--                <template slot="header" slot-scope="scope">-->
              <!--                  <el-checkbox @change="ChonToanBoLopHoc" v-model="CheckAllLopHoc"></el-checkbox>-->
              <!--                </template>-->
              <!--                <template slot-scope="scope">-->
              <!--                  <el-checkbox @change="chonMoiLopHoc(scope.row)" v-model="scope.row.check">-->
              <!--                  </el-checkbox>-->
              <!--                </template>-->
              <!--              </el-table-column>-->
              <el-table-column fixed align="center" type="index" label="STT" width="50">
              </el-table-column>
              <el-table-column align="center" width="100" fixed prop="khoiHoc" label="Khối học">
              </el-table-column>
              <el-table-column align="center" width="100" fixed prop="tenLop" label="Tên lớp">
              </el-table-column>
              <!--              <el-table-column align="center" width="150" fixed prop="capHoc" label="Cấp học">-->
              <!--              </el-table-column>-->
              <el-table-column align="center" fixed prop="tenGvcn" label="GVCN">
                <template slot-scope="scope">
                  <div v-if="scope.row">
                    <eselect style="width:100%" size="mini" collapseTags
                             @change="chonGiaoVienChuNhiem(scope.row)" v-model="scope.row.maGvcn"
                             :placeholder="'Chọn'" filterable :data="danh_sach_giao_vien"
                             :fields="['name','value']"/>
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" fixed prop="tenHieuTruong" label="Hiệu trưởng">
                <template slot-scope="scope">
                  <div v-if="scope.row" style="display: inline-flex">
                    <el-select size="mini" class="pr-2" v-model="scope.row.tenHieuTruong" filterable
                               placeholder="Chọn">
                      <el-option
                          v-for="item in danh_sach_hieu_truong"
                          :key="item.tenGV"
                          :label="item.tenGV"
                          :value="item.tenGV">
                      </el-option>
                    </el-select>
                    <el-tooltip content="Cập nhật hiệu trưởng" placement="right">
                      <el-button size="mini" style="padding: 5px 15px" type="warning"
                                 @click.prevent="updateHieuTruong(scope.row)"
                                 icon="el-icon-edit-outline"
                      >
                      </el-button>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" label="Học sinh">
                <template slot-scope="scope">
                  <el-tooltip content="Xem danh sách học sinh" placement="left">
                    <el-button size="mini" @click.prevent="xemDanhSachHocSinh(scope.row)"
                               icon="el-icon-view"
                               type="primary"></el-button>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column type="success" v-if="showPhanCongGiangDay" align="center"
                               label="Phân công giảng dạy">
                <template slot-scope="scope">
                  <el-tooltip content="Xem phân công giảng dạy" placement="left">
                    <el-button size="mini" @click.prevent="xemPhanCongGiangDay(scope.row)"
                               icon="el-icon-view"
                               type="primary">
                    </el-button>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column type="success" align="center"
                               label="Phân công ký thi lại">
                <template slot-scope="scope">
                  <el-tooltip content="Xem phân công ký thi lại" placement="left">
                    <el-button size="mini" @click.prevent="xemPhanCongThiLai(scope.row)"
                               icon="el-icon-view"
                               type="primary">
                    </el-button>
                  </el-tooltip>
                </template>
              </el-table-column>

              <el-table-column align="center" label="Hành động">
                <template slot-scope="scope">
                  <el-tooltip content="Xoá lớp học" placement="left">
                    <el-button size="mini" @click.prevent="xoaDuLieuLopHoc(scope.row)"
                               icon="el-icon-delete"
                               type="danger">
                    </el-button>
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
    </div>
    <div>
      <el-dialog :title="tieuDeHienthi" width="90%" top="5vh" center custom-class="thongTinLopHoc"

                 :close-on-press-escape="false" :close-on-click-modal="false"
                 :visible.sync="hien_thi_thong_tin_pcgd">
        <div class="">
          <el-button type="primary" size="mini" @click.prevent="phanCongLai">Phân công
          </el-button>
        </div>
        <el-table :max-height="maxHeight" header-row-class-name="thongTinLopHoc"
                  :data="danh_sach_phan_cong_giang_day" border>
          <el-table-column width="50"
                           align="center" header-align="center">
            <template slot="header" slot-scope="scope">
              <el-checkbox @change="ChonToanBoGiaoVienPhanCong" v-model="CheckAllGiaoVien"></el-checkbox>
            </template>
            <template slot-scope="scope">
              <el-checkbox @change="chonMoiGiaoVienPhanCong(scope.row)" v-model="scope.row.check">
              </el-checkbox>
            </template>
          </el-table-column>
          <el-table-column label="STT" width="50" align="center" type="index" class-name="text-center">
          </el-table-column>
          <el-table-column align="center" label="Phân công">
            <template slot-scope="scope">
              <eselect style="width:100%"
                       multiple
                       @change="ChonGiaoVienPhanCong(scope.row)" v-model="scope.row.maGiaoVien"
                       :placeholder="'Chọn'" filterable :data="danh_sach_giao_vien"
                       :fields="['name','value']"/>
            </template>
          </el-table-column>
          <el-table-column
              label="Mã giáo viên"
              align="left" header-align="center">
            <template slot-scope="scope">
              <ul>
                <li v-for="item in scope.row.maGiaoVien"><span>{{ item }}</span></li>
              </ul>
            </template>
          </el-table-column>
          <el-table-column
              label="Tên giáo viên"
              align="left" header-align="center">
            <template slot-scope="scope">
              <ul>
                <li v-for="item in scope.row.tenGiaoVien"><span>{{ item }}</span></li>
              </ul>
            </template>
          </el-table-column>
          <el-table-column property="tenMonHoc" header-align="center" align="left"
                           label="Môn học"></el-table-column>
          <el-table-column v-if="isGiangDay" header-align="center" align="left" label="Kỳ học">
            <template slot-scope="scope">
              <eselect style="width:100%"
                       @change="chonHocKyPhanCong(scope.row)" v-model="scope.row.kyHoc"
                       :placeholder="'Chọn'" filterable :data="danh_sach_ky_hoc"
                       :fields="['name','value']"/>
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>
      <el-dialog :title="tieuDeHienthi" top="5vh" center custom-class="thongTinLopHoc"
                 :visible.sync="hien_thi_hoc_sinh">
        <el-row :gutter="24">
          <el-col class="text-right" :span="24">
            <el-button :disabled="listHocSinhCheck.length==0" @click.prevent="xoaDuLieuHocSinh()" size="mini"
                       type="danger">Xoá học sinh
            </el-button>
          </el-col>
          <el-col :span="24">
            <el-table :max-height="maxHeight"
                      :data="danh_sach_hoc_sinh"
                      border>
              <el-table-column label="STT" width="50" align="center" type="index"
                               class-name="text-center">
              </el-table-column>
              <el-table-column
                  style="width: 50px"
                  width="50"
                  align="center" header-align="center">
                <template slot="header" slot-scope="scope">
                  <el-checkbox @change="ChonToanBoHocBaChot(true)"
                               v-model="xoaToanBoHocSinh"></el-checkbox>
                </template>
                <template slot-scope="scope">
                  <el-checkbox @change="chonMoiHocSinhXoa(scope.row)"
                               v-model="scope.row.check"></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column property="maHS" align="center" label="Mã học sinh"></el-table-column>
              <el-table-column align="left" header-align="center" label="Tên học sinh">
                <template slot-scope="scope">
                  <div>
                    <p style="text-transform: capitalize;">{{ getNameHocSinh(scope.row.tenHS) }}</p>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </el-dialog>
      <el-dialog @close="dongPhanCong" title="Phân môn" top="5vh" width="90%" center custom-class="thongTinLopHoc"
                 :close-on-press-escape="false" :close-on-click-modal="false"
                 :visible.sync="hien_thi_phan_mon">
        <div>
          <el-row :gutter="24" class="pd-b-5">
            <el-col :xs="24" :sm="12" :md="8" :lg="4">
              <label>Năm học</label>
              <eselect style="width:100%" collapseTags v-model="namHocPhanMon" :placeholder="'Chọn'"
                       filterable :data="list_nam_hoc" :fields="['name','value']"/>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="4">
              <label>Khối học</label>
              <eselect style="width:100%" collapseTags v-model="khoiHocPhanMon" :placeholder="'Chọn'"
                       filterable :data="danh_sach_khoi_hoc" :fields="['label','value']"/>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="4">
              <el-button type="primary" class="btn-form-search" @click.prevent="timKiemPhanMon">
                Tìm kiếm
              </el-button>
            </el-col>
          </el-row>
        </div>
        <el-table :max-height="maxHeight" fit lazy header-row-class-name="thongTinLopHoc"
                  :data="danh_sach_lop_mon" border style="width: 100%;">
          <el-table-column property="tenLop" fixed align="center" label="Tên lớp"></el-table-column>
          <template v-for="(colName,index) in danh_sach_mon_phan_cong">
            <el-table-column align="center">
              <template slot="header" slot-scope="scope">
                <span>{{ colName.name }}</span>
                <br>
                <el-checkbox class="dts_checkbox " v-model="colName.check"
                             @change="checkAllMon(colName.check,index)">
                </el-checkbox>
              </template>
              <template slot-scope="scope">
                <div>
                  <el-checkbox class="dts_checkbox " @change="checkMon(index)"
                               v-model="scope.row.phanMons[index]">
                  </el-checkbox>
                </div>
              </template>
            </el-table-column>
          </template>
        </el-table>
        <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click.prevent="XacNhanLuuThongTinPhanMon">Lưu lại</el-button>
                </span>
      </el-dialog>
      <el-dialog @close="dongPhanCong" title="Phân công giảng dạy" top="5vh" width="90%" center
                 :close-on-press-escape="false" :close-on-click-modal="false"
                 :visible.sync="hien_thi_phan_cong_giang_day">
        <div>
          <el-row :gutter="24" class="pd-b-5">
            <el-col :xs="24" :sm="12" :md="8" :lg="4">
              <label>Năm học</label>
              <eselect style="width:100%" collapseTags v-model="namHocPhanMon" :placeholder="'Chọn'"
                       filterable :data="list_nam_hoc" :fields="['name','value']"/>
            </el-col>
            <!--                        <el-col :xs="24" :sm="12" :md="8" :lg="4">-->
            <!--                            <label>Khối học</label>-->
            <!--                            <eselect style="width:100%" collapseTags v-model="khoiHocPhanMon" :placeholder="'Chọn'"-->
            <!--                                     filterable :data="danh_sach_khoi_hoc" :fields="['name','value']"/>-->
            <!--                        </el-col>-->
            <el-col :xs="24" :sm="12" :md="8" :lg="4">
              <label>Khối học</label>
              <eselect style="width:100%" collapseTags v-model="khoiHocPhanMon" :placeholder="'Chọn'"
                       filterable :data="danh_sach_khoi_hoc" :fields="['label','value']"/>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6">
              <label>Giáo viên</label>
              <eselect style="width:100%" collapseTags @change="timKiemPhanCongGiangDay"
                       v-model="giaoVienPhanMon"
                       :placeholder="'Chọn'"
                       filterable :data="danh_sach_giao_vien" :fields="['name','value']"/>
            </el-col>
            <!-- <el-col :xs="24" :sm="12" :md="8" :lg="4">
                <el-button type="success" @click.prevent="timKiemPhanCongGiangDay"  >
                    Tìm kiếm
                </el-button>
            </el-col> -->
          </el-row>
        </div>
        <el-table :max-height="maxHeight" fit lazy header-row-class-name="thongTinLopHoc"
                  :data="danh_sach_lop_mon" border style="width: 100%;">
          <el-table-column property="tenLop" fixed align="center" label="Tên lớp"></el-table-column>
          <template v-for="(colName,index) in danh_sach_mon_phan_cong">
            <el-table-column align="center">
              <template slot="header" slot-scope="scope">
                <span>{{ colName.name }}</span>
                <br>
                <el-checkbox class="dts_checkbox " v-model="colName.check"
                             @change="checkAllMon(colName.check,index)">
                </el-checkbox>
              </template>
              <template slot-scope="scope">
                <div>
                  <el-checkbox class="dts_checkbox " @change="checkMon(index)"
                               v-model="scope.row.phanMons[index]">
                  </el-checkbox>
                </div>
              </template>
            </el-table-column>
          </template>
        </el-table>
        <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click.prevent="XacNhanLuuThongTinPhanCongGiangDay">Lưu lại</el-button>
                </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {
  mapActions,
  mapState
} from 'vuex';
import ESelectVue from "./../Ui/ESelect";
import constant from "../../_helpers/constant_api";
import api from "../../_helpers/api";
import constant_api from "../../_helpers/constant_api";

export default {
  name: "DanhSachLopHoc",
  metaInfo: {
    title: 'Danh sách lớp học',
    titleTemplate: '',
    meta: [{
      vmid: 'description',
      name: 'description',
      content: ''
    }]
  },
  components: {
    'eselect': ESelectVue,
  },
  computed: {
    ...mapState('account', [
      'user',
      'list_nam_hoc',
      'list_cap_hoc',
      'nam_hoc_hien_tai',
    ]),
    ...mapState('until', [
      'window',
    ])
  },
  mounted() {
    console.log("Mounted DanhSachLop...")
    this.maxHeight = this.getHeightTable()
    this.getDanhSachHieuTruong();
    this.getListGiaoVien();
    this.maxHeight -= 200;
    this.namHoc = this.nam_hoc_hien_tai;
    this.namHocPhanMon = this.nam_hoc_hien_tai;
    this.mapDanhSachKhoi();
    this.capHoc = this.list_cap_hoc[0].value
  },
  data() {
    return {
      maxHeightTable: window.innerHeight,
      CheckAllLopHoc: false,
      danhSachLopHocCheck: [],
      fullscreenLoading: false,
      showPhanCongGiangDay: false,
      maxHeight: window.innerHeight,
      namHoc: '',
      capHoc: '',
      khoiHoc: '',
      namHocPhanMon: '',
      tieuDeHienthi: '',
      isGiangDay: true,
      isThiLai: false,
      maLop: '',
      danh_sach_mon: [],
      danh_sach_lop: [],
      hien_thi_thong_tin_pcgd: false,
      hien_thi_hoc_sinh: false,
      hien_thi_phan_mon: false,
      hien_thi_phan_cong_giang_day: false,
      xoaToanBoHocSinh: false,
      danh_sach_hoc_sinh_can_xoa: [],
      listHocSinhCheck: [],
      danh_sach_phan_cong_giang_day: [],
      danh_sach_hoc_sinh: [],
      khoiHocPhanMon: '',
      giaoVienPhanMon: '',
      danh_sach_lop_mon: [],
      danh_sach_mon_phan_cong: [],
      danh_sach_khoi_hoc: [],
      khoiCap1: [{
        value: 1,
        label: 'Khối 1'
      },
        {
          value: 2,
          label: 'Khối 2'
        },
        {
          value: 3,
          label: 'Khối 3'
        },
        {
          value: 4,
          label: 'Khối 4'
        },
        {
          value: 5,
          label: 'Khối 5'
        },
      ],
      khoiCap2: [{
        value: 6,
        label: 'Khối 6'
      },
        {
          value: 7,
          label: 'Khối 7'
        },
        {
          value: 8,
          label: 'Khối 8'
        },
        {
          value: 9,
          label: 'Khối 9'
        },
      ],
      khoiCap3: [{
        value: 10,
        label: 'Khối 10'
      },
        {
          value: 11,
          label: 'Khối 11'
        },
        {
          value: 12,
          label: 'Khối 12'
        },

      ],
      CheckAllGiaoVien: false,
      listGiaoVienCheck: [],
      danh_sach_giao_vien: [],
      danh_sach_ky_hoc: [
        {name: 'Học kỳ I', value: 1},
        {name: 'Học kỳ II', value: 2},
        {name: 'Cả năm', value: 3},
      ],
      danh_sach_hieu_truong: [],
      tenHieuTruongMacDinh: '',

    }
  },
  methods: {
    chonMoiHocSinhXoa(e) {
      console.log("chonMoiHocSinhXoa")
      console.log(e.check)
      let newCheck = e.check;
      for (let i = 0; i < this.danh_sach_hoc_sinh.length; i++) {
        if (this.danh_sach_hoc_sinh[i].id == e.id) {
          this.danh_sach_hoc_sinh[i].check = newCheck;
        }
      }
      this.maplistHocSinhCanXoa();
    },
    maplistHocSinhCanXoa() {
      let data = [];
      for (let i = 0; i < this.danh_sach_hoc_sinh.length; i++) {
        if (this.danh_sach_hoc_sinh[i].check == true) {
          data.push(this.danh_sach_hoc_sinh[i])
        }
      }
      this.listHocSinhCheck = data;
      console.log("Danh sách học sinh check:")
      console.log(this.listHocSinhCheck)
      if (this.listHocSinhCheck.length == this.danh_sach_hoc_sinh.length) {
        this.xoaToanBoHocSinh = true;
      } else {
        this.xoaToanBoHocSinh = false;
      }
    },
    xoaDuLieuHocSinh() {
      console.log('xoaDuLieuHocSinh')
      console.log(this.listHocSinhCheck)
      this.$confirm('Lưu ý: Hệ thống sẽ xoá dữ liệu của ' + this.listHocSinhCheck.length + ' học sinh đã chọn?', 'Cảnh báo', {
        cancelButtonText: 'Hủy',
        confirmButtonText: 'Xoá học sinh',
        confirmButtonClass: 'el-button--danger',
      })
          .then(_ => {
            try {
              let dsMa = [];
              for (let i = 0; i < this.listHocSinhCheck.length; i++) {
                dsMa.push(this.listHocSinhCheck[i].maHS)
              }
              let maLop = this.listHocSinhCheck[0].maLop
              let params = {
                maHSs: dsMa,
                maLop: maLop
              }
              console.log('params:')
              console.log(params)
              let uri = constant_api.hocsinh.xoaDuLieuHocSinh;
              this.fullscreenLoading = true;
              api.post(uri, params).then(
                  response => {
                    console.log("Xoá trả về:")
                    console.log(response)
                    if (response.data.code == 200) {
                      this.thongBao('success', response.data.msg);
                      this.layDanhSachLopHoc();
                    } else {
                      this.thongBao('error', response.data.msg)
                    }
                    this.fullscreenLoading = false;
                  }
              );
            } catch (e) {
              this.thongBao('error', 'Vui lòng thử lại sau.');
              this.fullscreenLoading = false;
            }
          })
          .catch(_ => {
          });
    },
    ChonToanBoHocBaChot(checkSuccess) {
      console.error('ChonToanBoHocBaChot:')
      let ds_hoc_sinh = JSON.parse(JSON.stringify(this.danh_sach_hoc_sinh))
      if (this.xoaToanBoHocSinh) {
        console.error('1-1')
        for (let i = 0; i < ds_hoc_sinh.length; i++) {
          ds_hoc_sinh[i].check = true;
          this.danh_sach_hoc_sinh_can_xoa.push(ds_hoc_sinh[i])
        }
      } else {
        for (let i = 0; i < ds_hoc_sinh.length; i++) {
          ds_hoc_sinh[i].check = false;
        }
        this.danh_sach_hoc_sinh_can_xoa = [];
      }
      this.danh_sach_hoc_sinh = JSON.parse(JSON.stringify(ds_hoc_sinh))
      console.log('danh_sach_hoc_sinh_can_xoa:')
      console.log(this.danh_sach_hoc_sinh_can_xoa)
      this.maplistHocSinhCanXoa();
    },
    getHeightTable() {
      if (this.window.height && this.window.height > 950) {
        return this.window.height - 100
      }
      if (this.window.height && this.window.height > 850) {
        return 800
      }
      if (this.window.height && this.window.height > 750) {
        return 700
      }
      return 600
    },
    xoaDuLieuLopHoc(item) {
      let id = item.id;
      let className = item.tenLop
      console.log('id xoá dữ liệu:' + id);
      this.xoaDuLieu([id], className)
    },
    checkXoaDuLieuLopHoc() {
      try {
        this.mapDanhSachLopHocCheck();
        console.log('mapDanhSachLopHocCheck')
        console.log(this.danhSachLopHocCheck);
        if (!this.danhSachLopHocCheck || this.danhSachLopHocCheck.length == 0) {
          this.thongBao('error', 'Vui lòng bổ sung bản ghi cần xoá.');
          return;
        } else {
          let ids = this.danhSachLopHocCheck.map(a => a.maLop);
          this.xoaDuLieu(ids);
        }
      } catch (e) {
        this.thongBao('success', 'Vui lòng thực hiện lại sau ít phút.')
      }
    },
    xoaDuLieu(arr, className) {
      console.log('Xoá của:')
      console.log(arr)
      this.$confirm('Lưu ý: Xoá lớp sẽ xoá dữ liệu lớp học, học sinh, học bạ của lớp ' + className + '?', 'Cảnh báo', {
        cancelButtonText: 'Hủy',
        confirmButtonText: 'Xoá lớp học',
        confirmButtonClass: 'el-button--danger',
      })
          .then(_ => {
            try {
              let params = {
                ids: arr
              }
              if (arr.length == 0) {
                params = {};
              }
              let uri = constant_api.lopHoc.xoaLopHoc;
              this.fullscreenLoading = true;
              api.post(uri, params).then(
                  response => {
                    console.log("Xoá trả về:")
                    console.log(response)
                    if (response.data.code == 200) {
                      this.thongBao('success', 'Xoá dữ liệu thành công');
                      this.layDanhSachLopHoc();
                    } else {
                      this.thongBao('error', response.data.msg)
                    }
                    this.fullscreenLoading = false;
                  }
              );
            } catch (e) {
              this.thongBao('error', 'Vui lòng thử lại sau.');
              this.fullscreenLoading = false;
            }
          })
          .catch(_ => {
          });
    },
    ChonToanBoLopHoc() {
      console.log("ChonToanBoLopHoc")
      let mang = JSON.parse(JSON.stringify(this.danh_sach_lop));
      if (!mang || mang.length == 0) {
        this.thongBao('error', 'Danh sách giáo viên trống')
      }
      if (this.CheckAllLopHoc) {
        for (let i = 0; i < mang.length; i++) {
          mang[i].check = true;
        }
      } else {
        for (let i = 0; i < mang.length; i++) {
          mang[i].check = false;
        }
      }
      this.danh_sach_lop = mang;
      console.log(mang)
      this.mapDanhSachLopHocCheck();
    },
    chonMoiLopHoc(e) {
      e.hasUpdate = true;
      console.log("CHeck")
      let obj = JSON.parse(JSON.stringify(e))
      console.log(obj)
      let newCheck = obj.check;
      for (let i = 0; i < this.danh_sach_lop.length; i++) {
        if (this.danh_sach_lop[i].id == e.id) {
          // this.danh_sach_phan_cong_giang_day[i].check = newCheck;
          e.check = newCheck;
        }
      }
      this.mapDanhSachLopHocCheck();
    },
    mapDanhSachLopHocCheck() {

      let data = [];
      for (let i = 0; i < this.danh_sach_lop.length; i++) {
        if (this.danh_sach_lop[i].check == true) {
          data.push(this.danh_sach_lop[i])
        }
      }
      this.danhSachLopHocCheck = data;
      console.log("Danh sách lớp học check:")
      console.log(this.danhSachLopHocCheck)
      if (this.danhSachLopHocCheck.length == this.danh_sach_lop.length) {
        this.CheckAllLopHoc = true;
      } else {
        this.CheckAllLopHoc = false;
      }
    },
    ChonToanBoGiaoVienPhanCong() {
      console.log("ChonToanBoGiaoVienPhanCong")
      let mang = JSON.parse(JSON.stringify(this.danh_sach_phan_cong_giang_day))
      if (this.CheckAllGiaoVien) {
        for (let i = 0; i < mang.length; i++) {
          mang[i].check = true;
          mang[i].hasUpdate = true;
        }
      } else {
        for (let i = 0; i < mang.length; i++) {
          mang[i].check = false;
          mang[i].hasUpdate = false;
        }
      }
      this.danh_sach_phan_cong_giang_day = mang;
      console.log(this.danh_sach_phan_cong_giang_day)
    },
    chonMoiGiaoVienPhanCong(e) {
      e.hasUpdate = true;
      console.log("CHeck")
      let obj = JSON.parse(JSON.stringify(e))
      console.log(obj)
      let newCheck = obj.check;
      for (let i = 0; i < this.danh_sach_phan_cong_giang_day.length; i++) {
        if (this.danh_sach_phan_cong_giang_day[i].maMonHoc == e.maMonHoc) {
          // this.danh_sach_phan_cong_giang_day[i].check = newCheck;
          e.check = newCheck;
        }
      }
      this.mapDanhSachGiaoVienCheckPhanCong();
    },
    mapDanhSachGiaoVienCheckPhanCong() {

      let data = [];
      for (let i = 0; i < this.danh_sach_phan_cong_giang_day.length; i++) {
        if (this.danh_sach_phan_cong_giang_day[i].check == true) {
          data.push(this.danh_sach_phan_cong_giang_day[i])
        }
      }
      this.listGiaoVienCheck = data;
      console.log("Danh sách giáo viên check:")
      console.log(this.listGiaoVienCheck)
      if (this.listGiaoVienCheck.length == this.danh_sach_phan_cong_giang_day.length) {
        this.CheckAllGiaoVien = true;
      } else {
        this.CheckAllGiaoVien = false;
      }
    },
    phanCongLai() {
      console.log('Phân công lại')
      console.log(this.danh_sach_phan_cong_giang_day)
      let array_update = [];
      let locations_required = '';
      let hasNull = 0;
      for (let i = 0; i < this.danh_sach_phan_cong_giang_day.length; i++) {
        if (this.danh_sach_phan_cong_giang_day[i].hasUpdate && this.danh_sach_phan_cong_giang_day[i].hasUpdate == true) {
          array_update.push(this.danh_sach_phan_cong_giang_day[i]);
          if (!this.danh_sach_phan_cong_giang_day[i].maGiaoVien || this.danh_sach_phan_cong_giang_day[i].maGiaoVien == '') {
            locations_required += this.danh_sach_phan_cong_giang_day[i].tenMonHoc + ', ';
            hasNull += 1;
          }
        }
      }
      let data_update = JSON.parse(JSON.stringify(array_update))
      if (data_update.length == 0) {
        this.thongBao('error', 'Vui lòng chọn giáo viên tương ứng cho từng môn học cần phân công');
        return;
      } else {
        if (hasNull && hasNull > 0) {
          let msg = 'Thiếu thông tin giáo viên ở các bản ghi môn ' + locations_required;
          this.thongBao('error', msg)
        } else {
          console.log("Gọi tới api để xử lý")
          console.log(data_update)
          for (let i = 0; i < data_update.length; i++) {
            data_update[i].maGV = data_update[i].maGiaoVien.join(';');
            data_update[i].tenGV = data_update[i].tenGiaoVien.join(';');
            data_update[i].tenGiaoVien = data_update[i].tenGV
            data_update[i].maGiaoVien = data_update[i].maGV,
                data_update[i].kyHoc = data_update[i].kyHoc
          }
          this.$confirm('Xác nhận lưu lại thông tin đã phân công?', 'Thông báo', {
            confirmButtonText: 'Phân công',
            cancelButtonText: 'Hủy',
            confirmButtonClass: 'el-button--primary'
          })
              .then(_ => {
                this.ChinhSuaPhanCongGiangDay(data_update)
              })
              .catch(_ => {
              });
        }
      }

    },
    ChinhSuaPhanCongGiangDay(arr) {
      let uri = constant.giaovien.chinhSuaPhanCongGiangDay;
      if (this.isThiLai) {
        uri = constant.giaovien.chinhSuaPhanCongThiLai;
      }
      this.fullscreenLoading = true;
      let params = {
        maLop: this.maLop,
        lstGiangDay: arr
      }

      api.post(uri, params).then(
          response => {
            console.log("Chỉnh trả về:")
            console.log(response)
            if (response.data.code == 200) {
              this.thongBao('success', 'Phân công thành công.')
              this.layDanhSachLopHoc();
              this.hien_thi_thong_tin_pcgd = false;

            } else {
              this.thongBao('error', response.data.msg)
            }
            this.fullscreenLoading = false;
          }
      );
    },
    ChonGiaoVienPhanCongDs(e) {
      e.hasUpdate = true;
      try {
        if (e.maGiaoVien) {
          let mgv = e.maGiaoVien;
          let tenGiaoVien = '';
          for (let i = 0; i < this.danh_sach_giao_vien.length; i++) {
            if (mgv == this.danh_sach_giao_vien[i].value) {
              tenGiaoVien = this.danh_sach_giao_vien[i].label
            }
          }
          e.tenGiaoVien = tenGiaoVien;
        } else {
          e.tenGiaoVien = ''
          this.thongBao('error', 'Vui lòng chọn giáo viên.')
        }
      } catch (e) {
        this.thongBao('error', 'Vui lòng thử lại sau.')
      }
    },
    chonHocKyPhanCong(e) {
      e.hasUpdate = true;
    },
    ChonGiaoVienPhanCong(e) {
      e.hasUpdate = true;
      try {
        if (e.maGiaoVien) {
          let mgv = e.maGiaoVien;
          let tenGiaoVien = [];
          for (let i = 0; i < mgv.length; i++) {
            for (let j = 0; j < this.danh_sach_giao_vien.length; j++) {
              if (mgv[i] == this.danh_sach_giao_vien[j].value) {
                tenGiaoVien.push(this.danh_sach_giao_vien[j].label)
              }
            }
          }
          e.tenGiaoVien = tenGiaoVien;
        } else {
          e.tenGiaoVien = []
          this.thongBao('error', 'Vui lòng chọn giáo viên.')
        }
      } catch (e) {
        this.thongBao('error', 'Vui lòng thử lại sau.')
      }
    },
    getNameHocSinh(e) {
      let result = '';
      result = e.toLowerCase();
      return result;
    },
    dongPhanCong() {
      this.giaoVienPhanMon = '';
      this.danh_sach_mon_phan_cong = [];
      this.danh_sach_lop_mon = [];
    },
    phanMonGiangDay() {
      console.log("Phân công giảng dạy");
      this.hien_thi_phan_cong_giang_day = true;
    },
    updateGiaoVienChuNhiem(e) {
      let params = {
        maGV: e.maGvcn,
        maLop: e.maLop,
      }
      let uri = constant.giaovien.capNhatGvcn;
      this.fullscreenLoading = true;
      api.post(uri, params).then(
          response => {
            console.log("Chỉnh trả về:")
            console.log(response)
            if (response.data.code == 200) {
              this.thongBao('success', 'Thao tác thành công')
            } else {

              this.thongBao('error', response.data.msg)
            }
            this.fullscreenLoading = false;
          }
      );
    },
    chonGiaoVienHieuTruong(e) {
      console.log("Chọn giáo viên hiệu trưởng")
    },
    chonGiaoVienChuNhiem(e) {
      let data = e;
      if (e) {
        this.$confirm('Xác nhận phân công giáo viên chủ nhiệm?', 'Thông báo', {
          confirmButtonText: 'Xác nhận',
          cancelButtonText: 'Hủy',
        })
            .then(_ => {
              this.updateGiaoVienChuNhiem(data);
            })
            .catch(_ => {
            });
      }
    },
    getListGiaoVien() {
      let params = {
        start: 0,
        limit: 999,
        maTruong: this.user.maTruong
      }
      let uri = constant.giaovien.listGiaoVien;
      this.fullscreenLoading = true;
      this.danh_sach_giao_vien = [];
      api.get(uri, params).then(
          response => {
            // console.error(this.danh_sach_giao_vien)
            if (response.data.code == 200) {
              let arr = response.data.data.list;
              let dt = [];
              if (arr && arr.length) {
                for (let i = 0; i < arr.length; i++) {
                  let obj = {
                    name: arr[i].tenGV + ' - [' + arr[i].maGV + ']',
                    value: arr[i].maGV,
                    label: arr[i].tenGV
                  }
                  dt.push(obj);
                }
              }
              this.danh_sach_giao_vien = dt;
              this.fullscreenLoading = false;
            } else {
            }
            this.fullscreenLoading = false;
          }
      );
    },
    checkMon(e) {
      this.danh_sach_mon_phan_cong[e].check = false;
      console.log(this.danh_sach_mon_phan_cong);
    },
    checkAllMon(e, index) {
      let data = JSON.parse(JSON.stringify(this.danh_sach_lop_mon));
      data.map(item => {
        item.phanMons[index] = e;
        return item;
      })
      this.danh_sach_lop_mon = data;

    },
    getDanhSachLopPhanCongGiangDay() {
      this.danh_sach_mon_phan_cong = [];
      let params = {
        khoiHoc: this.khoiHocPhanMon,
        namHoc: this.namHocPhanMon,
        maGV: this.giaoVienPhanMon
      }
      let uri = constant.giaovien.dsMonHocPhanCongGiangDay;
      this.fullscreenLoading = true;
      api.get(uri, params).then(
          response => {
            console.log("listTrarVe:")
            console.log(response);
            if (response.data.code == 200) {
              this.danh_sach_lop_mon = response.data.data.xepMonHocLops;
              let mons = response.data.data.monHocs,
                  arr = [];
              for (let i = 0; i < mons.length; i++) {
                let obj = {
                  check: false,
                  name: mons[i]
                }
                arr.push(obj);
              }
              this.danh_sach_mon_phan_cong = arr;
              // this.thongBao('success', 'Lấy dữ liệu thành công.')
              this.fullscreenLoading = false;
            } else {
              this.thongBao('error', response.data.rd)
            }
            this.fullscreenLoading = false;
          }
      );
    },
    getDanhSachLop() {
      this.danh_sach_mon_phan_cong = [];
      let params = {
        khoiHoc: this.khoiHocPhanMon,
        namHoc: this.namHocPhanMon,
        qlnt: this.user.qlnt
      }
      let uri = constant.giaovien.dsMonHocLop;
      this.fullscreenLoading = true;
      api.get(uri, params).then(
          response => {
            console.log("listTrarVe:")
            console.log(response);
            if (response.data.code == 200) {
              this.danh_sach_lop_mon = response.data.data.xepMonHocLops;
              let mons = response.data.data.monHocs,
                  arr = [];
              for (let i = 0; i < mons.length; i++) {
                let obj = {
                  check: false,
                  name: mons[i]
                }
                arr.push(obj);
              }
              this.danh_sach_mon_phan_cong = arr;
              // this.thongBao('success', "Lấy dữ liệu thành công")
              this.fullscreenLoading = false;
            } else {
              this.thongBao('error', response.data.rd)
            }
            this.fullscreenLoading = false;
          }
      );
    },
    timKiemPhanCongGiangDay() {

      if (!this.namHocPhanMon) {
        this.thongBao('error', 'Vui lòng chọn năm học để lấy danh sách lớp.');
        return;
      }
      if (!this.khoiHocPhanMon) {
        this.thongBao('error', 'Vui lòng chọn khối học để lấy danh sách lớp.');
        return;
      }
      if (!this.giaoVienPhanMon) {
        this.thongBao('error', 'Vui lòng chọn giáo viên để lấy danh sách.');
        return;
      }
      this.getDanhSachLopPhanCongGiangDay();
    },
    timKiemPhanMon() {
      if (!this.namHocPhanMon) {
        this.thongBao('error', 'Vui lòng chọn năm học để lấy danh sách lớp.');
        return;
      }
      if (!this.khoiHocPhanMon) {
        this.thongBao('error', 'Vui lòng chọn khối học để lấy danh sách lớp.');
        return;
      }
      this.getDanhSachLop();
    },

    capHocChange() {
      this.khoiHoc = '';
      if (!this.capHoc) {
        this.mapDanhSachKhoi();
        return;
      } else {
        if (this.capHoc == 1) {
          this.danh_sach_khoi_hoc = this.khoiCap1;
        } else if (this.capHoc == 2) {
          this.danh_sach_khoi_hoc = this.khoiCap2;
        } else {
          this.danh_sach_khoi_hoc = this.khoiCap3;
        }
      }
    },
    mapDanhSachKhoi() {
      let arr = [];
      this.danh_sach_khoi_hoc = [];
      for (let i = 0; i < this.list_cap_hoc.length; i++) {
        if (this.list_cap_hoc[i].value == 1) {
          arr = arr.concat(this.khoiCap1)
        }
        if (this.list_cap_hoc[i].value == 2) {
          arr = arr.concat(this.khoiCap2)
        }
        if (this.list_cap_hoc[i].value == 3) {
          arr = arr.concat(this.khoiCap3)
        }
      }
      this.danh_sach_khoi_hoc = arr;
    },
    LuuThongTinPhanCongGiangDay() {
      let du_lieu = JSON.parse(JSON.stringify(this.danh_sach_lop_mon));
      console.log(du_lieu);
      for (let i = 0; i < du_lieu.length; i++) {
        du_lieu[i].maGV = this.giaoVienPhanMon;
        for (let j = 0; j < du_lieu[i].phanMons.length; j++) {
          let check = du_lieu[i].phanMons[j];
          let obj = {
            tenMonHoc: this.danh_sach_mon_phan_cong[j].name,
            check: check
          }
          du_lieu[i].phanMons[j] = obj
        }
      }
      console.error(du_lieu)
      let uri = constant.giaovien.xepMonPhanCongGiangDay;
      this.fullscreenLoading = true;
      api.post(uri, du_lieu).then(
          response => {
            console.log("xếp xong trả về:")
            console.log(response)
            if (response.data.code == 200) {
              this.thongBao('success', 'Phân môn thành công.')
            } else {
              this.thongBao('error', response.data.rd)
            }
            this.fullscreenLoading = false;
          }
      );
    },
    LuuThongTinPhanMon() {
      let du_lieu = JSON.parse(JSON.stringify(this.danh_sach_lop_mon));
      console.log(du_lieu);
      for (let i = 0; i < du_lieu.length; i++) {
        for (let j = 0; j < du_lieu[i].phanMons.length; j++) {
          let check = du_lieu[i].phanMons[j];
          let obj = {
            tenMonHoc: this.danh_sach_mon_phan_cong[j].name,
            check: check
          }
          du_lieu[i].phanMons[j] = obj
        }
      }
      let uri = constant.giaovien.xepMonHocChoLop;
      this.fullscreenLoading = true;
      api.post(uri, du_lieu).then(
          response => {
            console.log("xếp xong trả về:")
            console.log(response)
            if (response.data.code == 200) {
              this.thongBao('success', 'Phân công thành công.')
            } else {
              this.thongBao('error', response.data.rd)
            }
            this.fullscreenLoading = false;
          }
      );
    },
    XacNhanLuuThongTinPhanCongGiangDay() {
      if (!this.giaoVienPhanMon || this.giaoVienPhanMon == '') {
        this.thongBao('error', 'Vui lòng chọn giáo viên');
        return;
      }
      if (!this.danh_sach_lop_mon || this.danh_sach_lop_mon.length == 0) {
        this.thongBao('error', 'Chưa cập nhật thông tin lớp học.');
        return;
      }
      console.log("lưu lại thông tin:")
      console.log(this.danh_sach_lop_mon);
      this.$confirm('Xác nhận lưu lại thông tin?', 'Thông báo', {
        confirmButtonText: 'Lưu lại',
        cancelButtonText: 'Hủy',
      })
          .then(_ => {
            this.LuuThongTinPhanCongGiangDay();
          })
          .catch(_ => {
          });
    },
    XacNhanLuuThongTinPhanMon() {
      if (!this.danh_sach_lop_mon || this.danh_sach_lop_mon.length == 0) {
        this.thongBao('error', 'Vui lòng kiểm tra lại dữ liệu.');
        return;
      }
      console.log("lưu lại thông tin:")
      console.log(this.danh_sach_lop_mon);
      this.$confirm('Xác nhận lưu lại thông tin?', 'Thông báo', {
        confirmButtonText: 'Lưu lại',
        cancelButtonText: 'Hủy',
      })
          .then(_ => {
            this.LuuThongTinPhanMon();
          })
          .catch(_ => {
          });
    },
    phanCongMon() {
      console.log("Phân công môn:")
      this.hien_thi_phan_mon = true;
    },
    xemDanhSachHocSinh(e) {

      this.listHocSinhCheck = [];
      this.danh_sach_hoc_sinh_can_xoa = [];
      this.xoaToanBoHocSinh = false;
      this.tieuDeHienthi = 'Danh sách học sinh lớp ' + e.tenLop;
      if (!e.maLop || e.maLop == '') {
        this.thongBao('error', 'Vui lòng chọn lớp học');
        return;
      }
      let params = {
        maLop: e.maLop,
        start: 0,
        limit: 999
      }
      let uri = constant.hocsinh.getListHS;
      this.fullscreenLoading = true;
      this.danh_sach_hoc_sinh = [];
      api.get(uri, params).then(
          response => {
            this.fullscreenLoading = false;
            console.log("Danh sách học sinh trả về:")
            console.log(response)
            if (response.data.code == 200) {
              if (response.data.data) {
                let ds_hs = response.data.data.list;
                for (let i = 0; i < ds_hs.length; i++) {
                  ds_hs[i].check = false;
                }
                this.danh_sach_hoc_sinh = ds_hs;
                this.hien_thi_hoc_sinh = true;
              } else {
                this.thongBao('error', 'Không tìm thấy dữ liệu phù hợp.')
              }
            } else {
            }
            this.fullscreenLoading = false;
          }
      );

    },
    xemPhanCongGiangDay(e) {
      this.isGiangDay = true;
      this.isThiLai = false;
      console.log("Xem phân công giảng dạy")
      this.maLop = e.maLop;
      this.CheckAllGiaoVien = false;
      console.error("Xem phân công giảng dạy")
      this.tieuDeHienthi = 'Danh sách phân công giảng dạy lớp ' + e.tenLop;
      let mang = JSON.parse(JSON.stringify(e.lstGiangDay));
      if (mang && mang.length) {
        for (let i = 0; i < mang.length; i++) {
          console.log(mang[i])
          let maGv = [];
          let tenGv = [];
          if (mang[i].maGiaoVien) {
            maGv = mang[i].maGiaoVien.split(';');
          }
          if (mang[i].tenGiaoVien) {
            tenGv = mang[i].tenGiaoVien.split(';');
          }
          mang[i].check = false;
          mang[i].maGiaoVien = maGv;
          mang[i].tenGiaoVien = tenGv;
        }
        this.danh_sach_phan_cong_giang_day = mang;
        console.error(this.danh_sach_phan_cong_giang_day);
        this.hien_thi_thong_tin_pcgd = true;
      } else {
        this.thongBao('error', 'Lớp ' + e.tenLop + ' chưa được phân công giảng dạy.')
      }
    },
    xemPhanCongThiLai(e) {
      console.log("Xem phân công ký thi lại")
      console.error(e)
      this.isGiangDay = false;
      this.isThiLai = true;
      this.maLop = e.maLop;
      this.CheckAllGiaoVien = false;
      console.error("Xem phân công giảng dạy")
      this.tieuDeHienthi = 'Danh sách phân công ký thi lại lớp ' + e.tenLop;
      let mang = JSON.parse(JSON.stringify(e.phanCongKyThiLai));
      if (mang && mang.length) {
        for (let i = 0; i < mang.length; i++) {
          console.log(mang[i])
          let maGv = [];
          let tenGv = [];
          if (mang[i].maGiaoVien) {
            maGv = mang[i].maGiaoVien.split(';');
          }
          if (mang[i].tenGiaoVien) {
            tenGv = mang[i].tenGiaoVien.split(';');
          }
          mang[i].check = false;
          mang[i].maGiaoVien = maGv;
          mang[i].tenGiaoVien = tenGv;
        }
        this.danh_sach_phan_cong_giang_day = mang;
        console.error(this.danh_sach_phan_cong_giang_day);
        this.hien_thi_thong_tin_pcgd = true;
      } else {
        this.thongBao('error', 'Lớp ' + e.tenLop + ' chưa được phân công giảng dạy.')
      }
    },
    thongBao(t, e) {
      let msg = "";
      let cl = "";
      if (e) {
        msg = e;
      }
      let type = "success";
      if (t) {
        type = t
      }
      if (type == "success") {
        cl = "dts-noty-success"
      }
      if (type == "warning") {
        cl = "dts-noty-warning"
      }
      if (type == "error") {
        cl = "dts-noty-error"
      }
      if (type == "info") {
        cl = "dts-noty-info"
      }
      this.$message({
        customClass: cl,
        showClose: true,
        message: msg,
        type: t,
        duration: 3000
      });
    },
    updateHieuTruong(e) {
      try {
        console.log("Cập nhật hiệu trưởng:")
        console.log(e)
        this.$confirm('Xác nhận cập nhật thông tin?', 'Thông báo', {
          confirmButtonText: 'Cập nhật',
          cancelButtonText: 'Hủy',
          confirmButtonClass: 'el-button--warning'
        })
            .then(_ => {

              let uri = constant.truong.capNhatHieuTruong;
              let params = {
                id: e.maLop,
                code: e.tenHieuTruong
              }
              this.fullscreenLoading = true;
              api.post(uri, params).then(
                  response => {
                    console.log("Danh sách lớp trả về:")
                    console.log(response)
                    if (response.data.code == 200) {

                      this.thongBao('success', response.data.msg)
                      this.fullscreenLoading = false;
                    } else {

                      this.thongBao('error', response.data.msg)
                      this.fullscreenLoading = false;
                    }
                  }
              );
            })
            .catch(_ => {
            });
      } catch (e) {
        this.thongBao('error', 'Vui lòng thử lại sau')
      }
    },
    getDanhSachHieuTruong() {
      try {
        this.danh_sach_hieu_truong = [];
        let uri = constant.truong.danhSachHieuTruong;
        let params = {}
        this.fullscreenLoading = true;
        api.get(uri, params).then(
            response => {
              console.log("Danh sách hiệu trưởng:")
              console.log(response)
              if (response.data.code == 200) {
                if (response.data.data.length > 0) {
                  this.danh_sach_hieu_truong = response.data.data;
                  this.tenHieuTruongMacDinh = this.danh_sach_hieu_truong[0].tenGV;
                  // this.thongBao('success', 'Lấy dữ liệu thành công');
                } else {
                }
                this.fullscreenLoading = false;
              } else {
                this.thongBao('error', response.data.msg)
                this.fullscreenLoading = false;
              }
              // this.layDanhSachLopHoc2();
            }
        );
      } catch (e) {
        // this.layDanhSachLopHoc2()

      }
    },
    layDanhSachLopHoc() {
      console.log("Lấy danh sách lớp")
      if (this.capHoc != 1 || this.khoiHoc >= 6) {
        this.showPhanCongGiangDay = true;
      } else {
        this.showPhanCongGiangDay = false;
      }
      if (!this.namHoc || this.namHoc == '') {
        this.thongBao('error', 'Thiếu thông tin năm học.');
        return;
      }
      this.hien_thi_hoc_sinh = false;
      this.danh_sach_lop = [];
      let uri = constant.truong.getListLopPcgd;
      let params = {
        namHoc: this.namHoc,
        khoiHoc: this.khoiHoc,
        capHoc: this.capHoc,
      }
      this.fullscreenLoading = true;
      api.get(uri, params).then(
          response => {
            console.log("Danh sách lớp trả về:")
            console.log(response)
            if (response.data.code == 200) {
              if (response.data.data.length > 0) {
                let mang = response.data.data;
                for (let i = 0; i < mang.length; i++) {
                  mang[i].tenHieuTruong = this.tenHieuTruongMacDinh;
                  mang[i].check = false;
                }
                this.danh_sach_lop = mang;
                // this.thongBao('success', 'Lấy dữ liệu thành công');
              } else {
                // this.thongBao('error', 'Không tìm thấy bản ghi nào')
              }
              this.fullscreenLoading = false;
            } else {
              console.log("error")
              console.log(response)
              this.thongBao('error', response.data.msg)
              this.fullscreenLoading = false;
            }
          }
      );
    },
  }
}
</script>
<style scoped>
.el-icon-close {
  color: red;
  font-weight: 900;
}

.el-icon-check {
  color: blue;
  font-weight: 900;
}

.page .el-table table thead tr th {
  background: #6884bf;
}

input[type=checkbox] {
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.2);
  /* IE */
  -moz-transform: scale(1.2);
  /* FF */
  -webkit-transform: scale(1.2);
  /* Safari and Chrome */
  -o-transform: scale(1.2);
  /* Opera */
  transform: scale(1.2);
  padding: 10px;
}

.dts_checkbox {

  /* Double-sized Checkboxes */
  -ms-transform: scale(1.2);
  /* IE */
  -moz-transform: scale(1.2);
  /* FF */
  -webkit-transform: scale(1.2);
  /* Safari and Chrome */
  -o-transform: scale(1.2);
  /* Opera */
  transform: scale(1.2);
  padding: 10px;
}

.pd-b-5 {
  padding-bottom: 5px;
}
</style>