var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page page-file file detailHocBa" }, [
    _c(
      "div",
      {
        staticClass:
          "page__title d-flex align-items-center justify-content-between",
      },
      [
        _c("h1", { staticClass: "title" }, [_vm._v("Nhập dữ liệu lớp học")]),
        _vm._v(" "),
        _c(
          "el-tooltip",
          {
            staticClass: "item",
            attrs: {
              effect: "dark",
              content: "Hướng dẫn nhập dữ liệu",
              placement: "top",
            },
          },
          [
            _c("a", { attrs: { href: "/files/huong_dan_nhap_du_lieu.docx" } }, [
              _vm._v(" Hướng dẫn nhập dữ liệu"),
            ]),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "box-upload-file" },
      [
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [
            _c(
              "el-col",
              { attrs: { xs: 12, sm: 6, md: 4, lg: 4 } },
              [
                _c("label", [_vm._v("Năm học")]),
                _vm._v(" "),
                _c("eselect", {
                  staticStyle: { width: "100%" },
                  attrs: {
                    collapseTags: "",
                    placeholder: "Chọn",
                    filterable: "",
                    data: _vm.list_nam_hoc,
                    fields: ["name", "value"],
                  },
                  model: {
                    value: _vm.namHoc,
                    callback: function ($$v) {
                      _vm.namHoc = $$v
                    },
                    expression: "namHoc",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-col",
              { attrs: { xs: 12, sm: 6, md: 4, lg: 4 } },
              [
                _c("label", [_vm._v("Cấp học")]),
                _vm._v(" "),
                _c("eselect", {
                  staticStyle: { width: "100%" },
                  attrs: {
                    collapseTags: "",
                    placeholder: "Chọn",
                    filterable: "",
                    data: _vm.list_cap_hoc,
                    fields: ["name", "value"],
                  },
                  model: {
                    value: _vm.capHoc,
                    callback: function ($$v) {
                      _vm.capHoc = $$v
                    },
                    expression: "capHoc",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "page__header" },
      [
        _c("h5", { staticClass: "title" }, [_vm._v("Dữ liệu lớp học")]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c("ImportGeneral", {
          attrs: {
            pathSampleFile:
              _vm.typeLopHoc == 1
                ? _vm.pathSampleFile.lopHoc
                : _vm.pathSampleFile.lopHocChung,
            "data-type": 2,
            requireKhoiHoc: false,
            hideClass: true,
            "text-download": "Tải file nhập lớp học",
            fileNameError: "danh_sach_loi_nhap_lop_hoc",
            fileHasData: true,
            linkFileHasData:
              _vm.typeLopHoc === 1
                ? _vm.linkFileHasData
                : _vm.linkFileHasDataChung,
            "require-cap-hoc": true,
            "require-nam-hoc": true,
            "cap-hoc": _vm.capHoc,
            year: _vm.namHoc,
            "template-download": _vm.template,
            source: _vm.sourceLopHoc,
            requireImportType: true,
            path: _vm.typeLopHoc === 1 ? _vm.path : _vm.pathChung,
            disabledDownload: _vm.typeLopHoc != 2 && false,
            importType: _vm.typeLopHoc,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    false
      ? _c("div", [
          _vm.user.qlnt == 1
            ? _c(
                "div",
                { staticClass: "page__header" },
                [
                  _c("h5", { staticClass: "title" }, [
                    _vm._v("Phân công giảng dạy"),
                  ]),
                  _vm._v(" "),
                  _vm._m(1),
                  _vm._v(" "),
                  _c("ImportGeneral", {
                    attrs: {
                      "require-nam-hoc": true,
                      "require-cap-hoc": true,
                      "cap-hoc": _vm.capHoc,
                      year: _vm.namHoc,
                      importType: _vm.typePcgd,
                      "data-type": 4,
                      "text-download": "Tải file nhập phân công giảng dạy",
                      requireKhoiHoc: false,
                      hideClass: true,
                      disabledDownload: true,
                      "template-download": _vm.template,
                      source: _vm.source,
                      path: _vm.path2,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("small", [
      _c("i", [
        _vm._v(
          " (Truy cập Học bạ số -> Nhập dữ liệu lớp học -> Tải file mẫu nhập lớp học)"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("small", [
      _c("i", [
        _vm._v(
          " (Danh sách lớp học -> Chọn cấp học -> Tại cột GVCN chọn GV được phân công -> Tại cột Phân công thực hiện phân công giảng dạy)"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }