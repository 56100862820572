<template>
    <div class="container">
        <div class="d-flex align-items-center justify-content-between">
            <h2>Thông tin trường</h2>
            <el-button type="primary" size="small" @click="showPopUpInfo = true">Cập nhật liên hệ</el-button>
        </div>
        <div v-if="infoSchool">
            <div class="row mb-2">
                <div class="col-sm-2">
                    <div class="pull-right">Tên trường :</div>
                </div>
                <div class="col-sm-10">{{infoSchool.tenTruongHoc}}</div>
            </div>
            <div class="row mb-2">
                <div class="col-sm-2">
                    <div class="pull-right">Email :</div>
                </div>
                <div class="col-sm-10">{{infoSchool.emailLienHe}}</div>
            </div>
            <div class="row mb-2">
                <div class="col-sm-2">
                    <div class="pull-right">Số điện thoại :</div>
                </div>
                <div class="col-sm-10">{{infoSchool.sdtLienHe}}</div>
            </div>
            <div class="row mb-2">
                <div class="col-sm-2">
                    <div class="pull-right">Dấu của trường :</div>
                </div>
                <div class="col-sm-8">
                    <template v-if="infoSchool.pathDau">
                        <el-image :src="infoSchool.pathDau" style="border: 1px solid #aaa"></el-image>
                    </template>
                </div>
                <div class="col-sm-2">
                    <el-button @click.prevent="showPopUp = true" type="primary" size="small">Tải lên dấu</el-button>
                </div>
            </div>
        </div>
        <el-dialog title="Tải lên dấu" :visible.sync="showPopUp" class="uploadSignature">
            <crop-image :style-box="'width: 300px; height: 300px;'" :option="optionCrope" :showCrop="showPopUp"
                @getBase64="getBase64"></crop-image>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showPopUp = false">Hủy</el-button>
                <el-button type="primary" @click="uploadDau">Cập nhật</el-button>
            </span>
        </el-dialog>
        <el-dialog title="Cập nhật thông tin trường" :visible.sync="showPopUpInfo" class="updateInfoTruong">
            <el-form v-if="infoSchool" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px"
                class="demo-ruleForm">
                <el-form-item label="Email" prop="email">
                    <el-input v-model="ruleForm.email = infoSchool.emailLienHe"></el-input>
                </el-form-item>
                <el-form-item label="Số điện thoại" prop="sdt">
                    <el-input v-model="ruleForm.sdt = infoSchool.sdtLienHe"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showPopUpInfo = false">Hủy</el-button>
                <el-button type="primary" @click="updateInfoSubmit('ruleForm')">Xác nhận</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import {
        mapActions,
        mapState
    } from 'vuex';
    import CropImage from "../components/CropImage";
    import dialog from "../dialog";

    export default {
        name: "QuangLyChung",
        metaInfo: {
            title: 'Quản lý chung',
            meta: [{
                vmid: 'description',
                name: 'description',
                content: ''
            }]
        },
        components: {
            CropImage,
        },
        computed: {
            ...mapState('school', [
                "infoSchool",
                "statusUpdate"
            ]),
            ...mapState('account', [
                'user'
            ]),
        },
        methods: {
            ...mapActions('school', [
                "getInfo",
                "updateInfo"
            ]),
            updateInfoSubmit(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.updateInfo({
                            email: this.ruleForm.email,
                            sdt: this.ruleForm.sdt,
                            maTruong: this.infoSchool.id,
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            getBase64(data) {
                this.imageDau = data;
            },
            thongBao(t, e) {
                let msg = "";
                let cl = "";
                if (e) {
                    msg = e;
                }
                let type = "success";
                if (t) {
                    type = t
                }
                if (type == "success") {
                    cl = "dts-noty-success"
                }
                if (type == "warning") {
                    cl = "dts-noty-warning"
                }
                if (type == "error") {
                    cl = "dts-noty-error"
                }
                if (type == "info") {
                    cl = "dts-noty-info"
                }
                this.$message({
                    customClass: cl,
                    showClose: true,
                    message: msg,
                    type: t,
                    duration: 3000
                });
            },
            uploadDau() {
                if (!this.imageDau) {
                    this.thongBao('error', 'Vui lòng xoá nền ảnh trước khi xác nhận');
                    return;
                }
                this.updateInfo({
                    maTruong: this.infoSchool.id,
                    image: this.imageDau,
                });
                this.showPopUp = false
            }
        },
        data() {
            return {
                ruleForm: {
                    email: '',
                    sdt: ''
                },
                rules: {
                    // email: [{
                    //         required: true,
                    //         message: 'Chưa nhập email',
                    //         trigger: 'blur'
                    //     },
                    //     {
                    //         pattern: /^([a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    //         message: 'Không đúng định dạng email',
                    //         trigger: ['blur', 'change']
                    //     }

                    // ],
                    // sdt: [{
                    //         required: true,
                    //         message: 'Chưa nhập số điện thoại',
                    //         trigger: 'blur'
                    //     },

                    //     {
                    //         message: 'Số điện thoại không đúng định dạng',
                    //         pattern: /(84|0[1|2|3|4|5|6|7|8|9])+([0-9]{8})\b/,
                    //         trigger: ['blur', 'change']
                    //     }

                    // ]
                },
                showPopUpInfo: false,
                imageDau: '',
                showPopUp: false,
                optionCrope: {
                    img: '',
                    size: 1,
                    full: true,
                    mode: '300px',
                    outputType: 'png',
                    canMove: true,
                    fixedBox: true,
                    original: false,
                    canMoveBox: false,
                    autoCrop: true,
                    autoCropWidth: 300,
                    autoCropHeight: 300,
                    centerBox: false,
                    high: true,
                    max: 99999,
                    fixed: true,
                    fixedNumber: [1, 1]
                },
            }
        },
        mounted() {
            this.getInfo({
                maTruong: this.user.maTruong,
            });
        },
        watch: {
            statusUpdate(val) {
                if (val) {
                    this.showPopUpInfo = false
                    dialog.showDialog('Thông báo', val.msg, () => {
                        this.getInfo({});
                    });
                }
            }
        }
    }
</script>

<style scoped>

</style>