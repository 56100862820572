<template>
  <div v-loading.fullscreen.lock="loading" :element-loading-text="loadingText"
       element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
    <el-row :gutter="24">
      <el-col :span="24">
        <el-dialog title="Chọn chứng thư số" width="65%" center :visible.sync="show_chon_cts"
                   custom-class="thongTinKyTheoMonHoc"
                   :close-on-press-escape="false" :close-on-click-modal="false"
                   :before-close="closeDialog" class="uploadSignature">
          <el-row :gutter="24">
            <el-col :span="24">
              <el-table fit border :data="danh_sach_cts">
                <el-table-column header-align="center" label="Nguồn">
                  <template slot-scope="scope">
                    {{ getTenCTS(scope.row.certInfo.subjectCN) }}
                  </template>
                </el-table-column>
                <el-table-column header-align="center" label="Số serial">
                  <template slot-scope="scope">
                    {{ scope.row.certInfo.serial }}
                  </template>
                </el-table-column>
                <el-table-column property="validFrom" header-align="center" align="center" label="Ngày hiệu lực"
                >
                  <template slot-scope="scope">
                    {{ scope.row.certInfo.validFrom }}
                  </template>
                </el-table-column>
                <el-table-column property="validTo" header-align="center" align="center" label="Ngày hết hiệu lực">
                  <template slot-scope="scope">
                    <p>{{ scope.row.certInfo.validTo }}</p>
                  </template>
                </el-table-column>
                <el-table-column align="center" width="200" header-align="center" label="Hành động">
                  <template slot-scope="scope">
                    <div>
                      <el-button size="mini" @click.prevent="kyDuLieu(scope.row.certBase64,scope.row.certInfo.issuerCN)"
                                 type="primary">Ký
                      </el-button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
          <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="clickHuyKy">Hủy</el-button>
            </span>
        </el-dialog>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import websocket from "../websocket";
import msgType from "../_helpers/constant_msg_type";
import {
  mapState,
  mapActions
} from 'vuex';
import dialog from "../dialog";
import {
  Message
} from 'element-ui';

export default {
  name: "KyHocBaUsbAllV2",
  props: [
    'position',
    'typeKy',
    'ma_lop',
    'dulieu',
    'caphoc',
    'namhoc',
    'thuchienky',
  ],
  computed: {
    ...mapState('teacher', [
      'pdfSignature'
    ]),
  },
  data: () => {
    return {
      list_res_sign_hash: [],
      EdocPluginSocket: '',
      signatureBase64: '',
      loadingText: 'Đang xử lý',
      loading: false,
      issuerCN: null,
      infoCTS: null,
      hashAlgorithm: 'SHA256',
      certChainBase64: null,
      danh_sach_cts: [],
      show_chon_cts: false,
      typeKyDuLieu: '',
      SerialNumberSend: '',
      hashBase64: ''
    }
  },
  methods: {
    clickHuyKy(){
      this.show_chon_cts = false;
      this.$emit('close')
    },
    closeDialog() {
      console.log('closeDialog')
      this.show_chon_cts = false;
      this.$emit('close')
    },
    kyThanhCong() {
      console.error("Ký thành công, có thể ký lại")
      this.$emit('close')
    },
    huyKy() {
      console.error("Hủy bỏ việc ký để có thể ký lại")
      this.loading = false;
      this.$emit('close')
    },
    getTenCTS(chuoi) {
      var batDau = "CN=";
      var ketThuc = ",";

      var startIndex = chuoi.indexOf(batDau) + batDau.length;
      var endIndex = chuoi.indexOf(ketThuc, startIndex);

      var chuoiCon = endIndex !== -1 ? chuoi.substring(startIndex, endIndex) : chuoi.substring(startIndex);
      return chuoiCon
    },
    onReceiveMessage(e) {
      let data = e.data;
      if (data.other == msgType.type.dongDauSoPhanCongUSB ||data.other == msgType.type.kySoPhanCongUsb || data.other == msgType.type.signScanUSBTOKEN || data.other == msgType.type.signDauQTHTUSBTOKEN || data.other == msgType.type.signUsbHocBa || data.other == msgType.type.kyDauBiahHocBa || data.other == msgType.type.dongDauBiaHocBa || data.other == msgType.type.signQTHTUSBTOKEN || data.other == msgType.type.signDauSoDiemUSBTOKEN || data.other == msgType.type.signKySoDiemUSBTOKEN || data.other == msgType.type.signDauSoDiemUSBTOKEN || data.other == msgType.type.thucHienDongDauBiaAnh || data.other == msgType.type.hieuTruongKyDauBiahHocBa || data.other == msgType.type.signHoSoBangUSBToken) {
        console.error('onReceiveMessage Ký USB All')
        console.error(data)
        if (data.code == 200) {
          if (data.other == 87) {
            console.log('Ký thành công')
            // this.$emit('layXmlThanhCong', data);
            this.kyThanhCong();
          } else {

            if (data.other == 93||data.other == 99) {
              this.thongBao('success', data.msg)
            }
            console.log('Ký thành công 1123')
            this.kyThanhCong();
          }
        } else {
          // this.thongBao('error', data.Message)
          this.huyKy();
        }
        this.loading = false;
      }
      if (data.other == msgType.type.layHashKySoPhanCong ||data.other == msgType.type.layHashDongDauSoPhanCong || data.other == msgType.type.getHashNopHoSoBangUSBToken || data.other == msgType.type.getHashKyQTHTUSBTOKEN || data.other == msgType.type.getHashDauQTHTUSBTOKEN || data.other == msgType.type.getHashHocBa || data.other == msgType.type.getHashKyScanUSBTOKEN || data.other == msgType.type.getHashDauBiahHocBa || data.other == msgType.type.getHashDongDauBiaHocBa || data.other == msgType.type.getHashHieuTruongKyDauBiahHocBa || data.other == msgType.type.getHashDongDauBiaAnh || data.other == msgType.type.getHashKyScanUSBTOKEN || data.other == msgType.type.layHashDongDauSoDiem || data.other == msgType.type.layHashKySoDiem || data.other == msgType.type.getHashNopHoSoBangUSBToken) {
        console.log("Thông tin lấy hash trả về:")
        console.log(data)
        if (data.code == 200 && data.data&&data.data.length) {
          console.log('Lấy thành công')
          this.InsertSignatrue(data.data);//?.....
        } else {
          console.log('Lấy không thành công')
          console.log('Thông báo lấy hash thất bại')
          this.thongBao('error', data.msg?data.msg:(data.Message?data.Message:'Lấy hash thất bại'))
          this.$emit('error')
          this.huyKy();
        }
      }
    },
    async InsertSignatrue(e) {
      var that = this;
      console.log('InsertSignatrue:')
      console.log(that.dulieu)
      that.SerialNumberSend = e[0] ? e[0].serialNumber : null;
      console.log(e)
      let datas = [];
      const testA = await that.getSignatureBase64(e);
      console.log('Có hash trả về')
      console.log(testA)
      if (testA) {
        for (let i = 0; i < e.length; i++) {
          const item = e[i];
          //console.log("event promise")
          //console.log('1')
          let du_lieu_ky = {
            id: item.id,
            signatureBase64: testA[i].signatureBase64,
            key: item.key,
            viTri: item.viTri,
            uuid: item.uuid,
            hashBase64: item.hashBase64,
            base64Digest: item.base64Digest,
            serialNumber: that.SerialNumberSend
          }
          datas.push(du_lieu_ky);

        }
        console.log("Thông tin ký:", datas)
        console.log('Kiểu ký trong InsertSignatrue:' + that.typeKyDuLieu)
        this.loadingText = 'Đang thực hiện ký...'
        let data = {
          msgType: msgType.type.signUsbHocBa,
          maLop: (that.dulieu&&that.dulieu[0])?that.dulieu[0].maLop:'',
          data: datas,
          serialNumber: that.SerialNumberSend,
          issuerCN: that.issuerCN
        }
        if (that.typeKyDuLieu == 'dongDauBia') {
          data.msgType = msgType.type.dongDauBiaHocBa
        }
        if (that.typeKyDuLieu == 'bia') {

          data.msgType = msgType.type.hieuTruongKyDauBiahHocBa
        }
        if (that.typeKyDuLieu == 'dongDauBiaKqht') {
          // data.msgType = msgType.type.thucHienDongDauBiaKqht
          data.msgType = msgType.type.kyDauBiahHocBa
        }
        if (that.typeKyDuLieu == 'dongDauBiaAnh') {
          data.msgType = msgType.type.thucHienDongDauBiaAnh
        }
        if (that.typeKyDuLieu == 'dongDauKqht') {
          data.msgType = msgType.type.dongDauBiaHocBa
        }
        if (that.typeKyDuLieu == 'dauQTHT') {
          data.msgType = msgType.type.signDauQTHTUSBTOKEN
        }
        if (that.typeKyDuLieu == 'kyQTHT') {
          data.msgType = msgType.type.signQTHTUSBTOKEN
        }
        if (that.typeKyDuLieu == 'dongDauSoDiem') {
          data.msgType = msgType.type.signDauSoDiemUSBTOKEN
        }
        if (that.typeKyDuLieu == 'kySoDiem') {
          data.msgType = msgType.type.signKySoDiemUSBTOKEN
        }
        if (that.typeKyDuLieu == 'kyscan') {
          data.msgType = msgType.type.signScanUSBTOKEN;
          data.position = that.position;
        }
        if (that.typeKyDuLieu == 'nopHS') {
          data.msgType = msgType.type.signHoSoBangUSBToken;
          data.position = that.position;
        }
        if (that.typeKyDuLieu == 'nopHSV2') {
          data.msgType = msgType.type.signHoSoBangUSBToken;
          data.position = that.position;
        }
        if (that.typeKyDuLieu == 'kySoPhanCong') {
          data.msgType = msgType.type.kySoPhanCongUsb;
          data.capHoc = that.caphoc;
          data.namHoc = that.namhoc;
          data.position = that.position;
        }
        if (that.typeKyDuLieu == 'dongDauSoPhanCong') {
          data.msgType = msgType.type.dongDauSoPhanCongUSB;
          data.capHoc = that.caphoc;
          data.namHoc = that.namhoc;
          data.position = that.position;
        }
        console.log('Gửi ký:')
        console.log(data)
        websocket.sendMessage(data);
      } else {
        console.log('Không lấy được thông tin CTS')
      }
    },
    async getSignatureBase64(e) {
      console.log('getSignatureBase64')
      console.log(e)
      var serialNumber = e[0].serialNumber || 'null';
      // this.SerialNumberSend = serialNumber;
      // var hashBase64 = e.hashBase64;
      var hashBase64 = e.map(function (obj) {
        return obj.hashBase64;
      });
      var data_sign_hash = [];
      for (let i = 0; i < hashBase64.length; i++) {
        let obj = {
          hashBase64: hashBase64[i]
        }
        data_sign_hash.push(obj)
      }
      console.log(data_sign_hash)


      console.log('hashBase64:')
      console.log(hashBase64)
      const signHash = await this.EdocPluginSocket.signHash(data_sign_hash, serialNumber);
      console.log('response:')
      console.log(signHash)
      return signHash;
    },
    signUsb() {
      this.loadingText = 'Đang lấy thông tin chứng thư số...';
      console.log("Kiểu ký:" + this.typeKyDuLieu)
      this.loading = true;
      let that = this;
      console.log('B1: getCertificateInfo')
      this.EdocPluginSocket.getCertificateInfo(function (response) {
        console.log('response:')
        console.log(response)
        if (response.status == 1) {
          that.danh_sach_cts = response.data;
          that.show_chon_cts = true;
          that.loading = false;
          return;
        }
        if (response.status == 0) {
          that.thongBao('error', response.Message)
          that.loading = false;
          that.huyKy();
          return;
        }
        if (response.status == -105) {
          that.thongBao('error', response.Message)
          that.loading = false;
          that.$confirm('Xác nhận tải và cài đặt ứng dụng?', 'Thông báo', {
            confirmButtonText: 'Đồng ý',
            cancelButtonText: 'Hủy',
          })
              .then(_ => {
                console.log("Tải ứng dụng:")
                window.location.href = 'https://edocapi.smas.edu.vn/Storage/Static/Software/EdocCaPluginSetup.rar';
              })
              .catch(_ => {
              });
          that.huyKy();
          return;
        }


      });

    },
    kyDuLieu(response, issuerCN) {
      console.log('kyDuLieu')
      this.infoCTS = response;
      this.issuerCN = issuerCN;
      console.log('kyDuLieu')
      console.log(response)
      var certChainBase64 = new String(response);
      this.loading = true;
      this.certChainBase64 = certChainBase64;
      if (certChainBase64 == undefined || certChainBase64.length < 500) {
        this.thongBao('error', 'Chọn CTS không thành công')
        this.loading = false;
        this.huyKy();
        return false;
      }
      console.log("B2")
      if (this.typeKyDuLieu != 'nopHSV2' && this.typeKyDuLieu != 'kySoPhanCong'&& this.typeKyDuLieu != 'dongDauSoPhanCong') {
        console.log('Ký dữ liệu thường')
        let ids = [];
        let maLop = "";
        this.dulieu.forEach(item => {
          ids.push({
            id: item.id
          });
          maLop = item.maLop
        })
        let dataHash = {
          reqs: ids,
          certChainBase64: certChainBase64,
          issuerCN: issuerCN,
          maLop: maLop,
          msgType: '',
        };
        // dataHash.msgType = that.getMessageType(that.typeKyDuLieu)
        if (this.typeKyDuLieu == 'bia') {
          dataHash.msgType = msgType.type.getHashHieuTruongKyDauBiahHocBa;
        }
        if (this.typeKyDuLieu == 'hocba') {
          dataHash.msgType = msgType.type.getHashHocBa;
        }
        if (this.typeKyDuLieu == 'dongDauBia') {
          dataHash.msgType = msgType.type.getHashDongDauBiaHocBa;
        }
        if (this.typeKyDuLieu == 'dongDauBiaKqht') {
          dataHash.msgType = msgType.type.getHashDauBiahHocBa;
        }
        if (this.typeKyDuLieu == 'dongDauBiaAnh') {
          dataHash.msgType = msgType.type.getHashDongDauBiaAnh;
        }
        if (this.typeKyDuLieu == 'dongDauKqht') {
          dataHash.msgType = msgType.type.getHashDongDauBiaHocBa;
        }
        if (this.typeKyDuLieu == 'dauQTHT') {
          dataHash.msgType = msgType.type.getHashDauQTHTUSBTOKEN;
        }
        if (this.typeKyDuLieu == 'kyQTHT') {
          dataHash.msgType = msgType.type.getHashKyQTHTUSBTOKEN;
        }
        if (this.typeKyDuLieu == 'kyscan') {
          dataHash.msgType = msgType.type.getHashKyScanUSBTOKEN;
          dataHash.position = this.position;
        }
        if (this.typeKyDuLieu == 'dongDauSoDiem') {
          dataHash.msgType = msgType.type.layHashDongDauSoDiem;
          dataHash.position = this.position;
        }
        if (this.typeKyDuLieu == 'kySoDiem') {
          dataHash.msgType = msgType.type.layHashKySoDiem;
          dataHash.position = this.position;
        }
        if (this.typeKyDuLieu == 'nopHS') {
          dataHash.msgType = msgType.type.getHashNopHoSoBangUSBToken;
          dataHash.position = this.position;
        }
        if (this.typeKyDuLieu == 'nopHSV2') {
          dataHash.msgType = msgType.type.getHashNopHoSoBangUSBToken;
          dataHash.position = this.position;
          dataHash.xml = this.dulieu;
        }
        dataHash.hashAlgorithm = this.hashAlgorithm
        console.log('dataHash');
        console.log(dataHash);
        this.loadingText = 'Đang lấy thông tin chứng thư số...'
        websocket.sendMessage(dataHash);
      } else {
        console.log('Ký nộp hồ sơ hoặc sổ phân công, sổ điểm')
        let dataHash = {
          capHoc: this.caphoc,
          namHoc: this.namhoc,
          certChainBase64: certChainBase64,
          issuerCN: issuerCN,
          msgType: msgType.type.getHashNopHoSoBangUSBToken,
        };
        if (this.typeKyDuLieu == 'kySoPhanCong') {
          dataHash.msgType = msgType.type.layHashKySoPhanCong;
          dataHash.position = this.position;
        }
        if (this.typeKyDuLieu == 'dongDauSoPhanCong') {
          dataHash.msgType = msgType.type.layHashDongDauSoPhanCong;
          dataHash.position = this.position;
        }
        if (this.typeKyDuLieu == 'nopHSV2') {
          dataHash.msgType = msgType.type.getHashNopHoSoBangUSBToken;
          dataHash.position = this.position;
          dataHash.xml = this.dulieu;
        }
        dataHash.hashAlgorithm = this.hashAlgorithm
        console.log('dataHash');
        console.log(dataHash);
        this.loadingText = 'Đang lấy thông tin chứng thư số...'
        // chỗ này call về BE để nó lấy hash rồi trả ra chỗ onMessage đăng ký ở mounted
        websocket.sendMessage(dataHash);
      }
    },

    thongBao(t, e) {
      let msg = "";
      let cl = "";
      if (e) {
        msg = e;
      }
      let type = "success";
      if (t) {
        type = t
      }
      if (type == "success") {
        cl = "dts-noty-success"
      }
      if (type == "warning") {
        cl = "dts-noty-warning"
      }
      if (type == "error") {
        cl = "dts-noty-error"
      }
      if (type == "info") {
        cl = "dts-noty-info"
      }
      this.$message({
        customClass: cl,
        showClose: true,
        message: msg,
        type: t,
        duration: 3000
      });
    },
    getMessageType(typeKy) {
      switch (typeKy) {
        case "bia":
          return msgType.type.getHashHieuTruongKyDauBiahHocBa;
        case "kyQTHT":
          return msgType.type.getHashKyQTHTUSBTOKEN
        default:
          return ""
      }
    }
  },

  watch: {
    dulieu: function (val) {
      if (val) {
        console.log("Dữ liệu ký:", val)
        //console.log('Dữ liệu thay đổi:')
        //this.typeKyDuLieu = JSON.parse(JSON.stringify(this.typeKy))
        //console.log('Kiểu ký:' + this.typeKyDuLieu)
      }
    },
    typeKyDuLieu(val) {
      if (val) {
        console.log("Khi gán type ký xong thì all ky usb")
        this.hashAlgorithm = 'SHA256';
        this.signUsb()
      }
    }

  },
  beforeDestroy() {
    console.log('beforeDestroy')
    window.removeEventListener('message', this.onReceiveMessage)
  },
  beforeRouteLeave(to, from, next) {
    console.error('beforeRouteLeave')
    window.removeEventListener('message', this.onReceiveMessage)
    next();
  },
  mounted() {
    console.error('Mounted trong Ký học Bạ USB V2:')
    console.error('Kiểu ký:' + this.typeKy)
    this.typeKyDuLieu = JSON.parse(JSON.stringify(this.typeKy))
    window.addEventListener('message', this.onReceiveMessage, false);
    var that = this;
    this.EdocPluginSocket = {
      sendMessageToPlugin: function (serviceName, data, callBack) {
        var socket = new WebSocket('ws://localhost:9834/general');
        // Connection opened
        socket.addEventListener('open', function (event) {
          console.log("Open")
          console.log(data)
          socket.send(JSON.stringify(data));
        });
        socket.addEventListener('message', function (event) {
          console.log("message")
          callBack(JSON.parse(event.data));
        });

        // Listen for close connection
        socket.addEventListener('close', function (event) {
          console.log('close')
          var response = {
            status: -105,
            Message: "Chưa cài đặt hoặc chưa chạy EDOC-CA Plugin."
          }
          if (event.code == 1006) {
            callBack(response);
          }
        });
      },

      sendMessageToPluginAsync: async function (serviceName, data) {
        console.log('sendMessageToPluginAsync')
        console.log(serviceName)
        console.log('data')
        console.log(data)
        // data.functionName = serviceName;
        // Create WebSocket connection.
        return new Promise((resolve, reject) => {
          // const socket = new WebSocket('ws://localhost:9834/' + serviceName);
          const socket = new WebSocket('ws://localhost:9834/general');
          // Connection opened
          socket.addEventListener('open', function (event) {
            console.log("send ws:")
            console.log(JSON.stringify(data))
            socket.send(JSON.stringify(data));
          });

          // Listen for messages
          socket.addEventListener('message', function (event) {
            console.log("message")
            resolve(JSON.parse(event.data));
          });

          // Listen for close connection
          socket.addEventListener('close', function (event) {
            console.log('close')
            var response = {
              status: -105,
              Message: "Chưa cài đặt hoặc chưa chạy EDOC-CA Plugin."
            }
            if (event.code == 1006) {
              resolve(response);
            }
          });
        })
      },

      getCertificateInfo: function (callBack) {
        // var functionName = 'get-cert-info';
        var functionName = 'get_all_certs';
        console.log("getCertificateInfo")
        let params = {
          functionName: 'get_all_certs'
        }
        that.EdocPluginSocket.sendMessageToPlugin(null, params, callBack);
      },
      signHash: async function (hashBase64, serialNumber) {
        // var functionName = 'general/sign_hash';
        console.log('signHash 1')
        var functionName = 'sign_hash';
        var data = {
          hashBase64: hashBase64,
          // SerialNumber: serialNumber,
        }
        // var listSign = [data]
        console.log("data signHash ")
        console.log(data)
        const respon = await that.EdocPluginSocket.sendMessageToPluginAsync(functionName, {
          data: {
            listSign: hashBase64,
            certBase64: that.certChainBase64
          },
          uniqueId: '',
          functionName: functionName
        });
        console.log("respon signHash here")
        console.log(respon)
        if (respon.status == 0) {
          console.log('Case = 1')
          if (that.hashAlgorithm == 'SHA256') {
            console.log('Lấy lại hash bằng SHA1')
            that.hashAlgorithm = 'SHA1';
            that.kyDuLieu(that.infoCTS, that.issuerCN)
            //   chuyển qua SHA1
          }else{
            that.thongBao('error', respon.message)
            that.huyKy();
          }
        } else {
          if (this.hashAlgorithm == 'SHA256') {
            console.log('Lấy lại hash')
            this.hashAlgorithm = 'SHA1';
            that.kyDuLieu(that.infoCTS, that.issuerCN)
            //   chuyển qua SHA1
          }
        }
        return respon.data
      }
    }
  }
}
</script>

<style scoped>

</style>