import axios from 'axios';

import {
    authHeader
} from './auth-header'
import LZString from 'lz-string';
import {warning} from "ionicons/icons";
import Logger from "@fortawesome/vue-fontawesome/src/logger";

export default {
    DEFAULT_TIME_OUT: 60000,
    create_params(method, path, params) {
        return {
            "path": path,
            "method": "POST",
            "data": params
        };
    },

    async post(url, params, timeout = this.DEFAULT_TIME_OUT) {
        let baseUri = BASE_URL_API;
        let tm = timeout;
        console.log("POST: " + url);
        console.log("req = " + JSON.stringify(params));
        let config = {
            timeout: tm,
            headers: authHeader()
        };
        // console.log('post header:')
        // console.log(config)
        let pramsSend = {
            path: url,
            method: 'POST',
            data: params
        }

        var compressed = LZString.compressToBase64(JSON.stringify(pramsSend));
        let pr = {
            data: this.randomString(3) + compressed
        }
        // console.error('POST')
        // console.log(LZString.decompressFromBase64(compressed))
        let result
        await axios.post(baseUri + '/' + this.randomString(15), pr, config).then((response) => {
            result = response.data
        });
        let dt = JSON.parse(JSON.stringify(result.data.slice(3)));
        result.data = JSON.parse(LZString.decompressFromBase64(dt))
        return result
    },
    async postSmas(url, params, timeout = this.DEFAULT_TIME_OUT) {
        console.warn('post')
        let baseUri = BASE_URL_API;
        let tm = timeout;
        console.log("POST: " + url);
        console.log("req = " + JSON.stringify(params));
        let config = {
            timeout: tm,
            headers: params
        };
        let pramsSend = {
            path: url,
            method: 'POST',
            data: params
        }

        var compressed = LZString.compressToBase64(JSON.stringify(pramsSend));
        let pr = {
            data: this.randomString(3) + compressed
        }
        console.error('POST')
        console.log(LZString.decompressFromBase64(compressed))
        let result
        await axios.post(baseUri + '/hocbadientu-service/api/login-smas', pr, config).then((response) => {
            result = response.data
        });
        let dt = JSON.parse(JSON.stringify(result.data.slice(3)));
        result.data = JSON.parse(LZString.decompressFromBase64(dt))
        return result
    },
    async postK12(url, params, timeout = this.DEFAULT_TIME_OUT) {
        console.warn('post')
        let baseUri = BASE_URL_API;
        let tm = timeout;
        console.log("POST: " + url);
        console.log("req = " + JSON.stringify(params));
        let config = {
            timeout: tm,
            headers: params
        };
        let pramsSend = {
            path: url,
            method: 'POST', data: params
        }

        var compressed = LZString.compressToBase64(JSON.stringify(pramsSend));
        let pr = {
            data: this.randomString(3) + compressed
        }
        console.error('POST')
        console.log(LZString.decompressFromBase64(compressed))
        let result
        await axios.post(baseUri + '/hocbadientu-service/api/login-k12connect', pr, config).then((response) => {
            result = response.data
        });
        let dt = JSON.parse(JSON.stringify(result.data.slice(3)));
        result.data = JSON.parse(LZString.decompressFromBase64(dt))
        return result
    },
    async postPort(url, params, timeout = this.DEFAULT_TIME_OUT) {
        console.warn('post')
        let baseUri = 'https://hocba-upload.dtsgroup.com.vn'
        let tm = timeout;
        console.log("POST: " + url);
        console.log("req = " + JSON.stringify(params));
        let config = {
            timeout: tm,
            headers: authHeader()
        };
        let result
        await axios.post(baseUri + url, params, config).then((response) => {
            result = response.data
        });
        console.log('response: ')
        console.log(result)
        return result
    },
    async postTestUpload(url, params, timeout = this.DEFAULT_TIME_OUT) {
        console.warn('post')
        let baseUri = 'https://apihocba.dtsgroup.com.vn'
        let tm = timeout;
        console.log("POST: " + url);
        console.log("req = " + JSON.stringify(params));
        let config = {
            timeout: tm,
            headers: authHeader()
        };
        let result
        await axios.post(baseUri + url, params, config).then((response) => {
            result = response.data
        });
        console.log('response: ')
        console.log(result)
        return result
    },
    postLogin(url, params, timeout = this.DEFAULT_TIME_OUT) {
        let tm = timeout;
        console.log("POST " + url);
        console.log("req = " + JSON.stringify(params));
        let data = {
            "path": url,
            "method": "POST",
            "data": params,
            "timeout": timeout
        };
        return this._request(data, tm);
    },

    postSso(url, params, timeout = this.DEFAULT_TIME_OUT) {
        console.warn('postSso')
        let tm = timeout;
        console.log("GET " + url);
        console.log("req = " + JSON.stringify(params));
        let config = {
            timeout: tm,
            headers: authHeader()
        };
        let uri = url;
        return axios.post(uri, params, config)
    },
    async get(url, params, timeout = this.DEFAULT_TIME_OUT) {
        console.warn('get')
        let baseUri = BASE_URL_API;
        let tm = timeout;
        let config = {
            timeout: tm,
            headers: authHeader()
        };
        let query = this.objectToQueryString(params);
        let uri = url + "?" + query;
        let dataSend = {
            path: uri,
            method: 'GET',
            data: ''
        }
        var compressed = LZString.compressToBase64(JSON.stringify(dataSend));
        console.log('Send:')
        console.log(LZString.decompressFromBase64(compressed))
        let pr = {
            data: this.randomString(3) + compressed
        }
        let result
        await axios.post(baseUri + '/' + this.randomString(15), pr, config).then((response) => {
            result = response.data
        });
        let dt = JSON.parse(JSON.stringify(result.data.slice(3)));
        result.data = JSON.parse(LZString.decompressFromBase64(dt))
        return result
    },

    getSso(url, params, timeout = this.DEFAULT_TIME_OUT) {
        let tm = timeout;
        let config = {
            timeout: tm,
            headers: authHeader()
        };

        let query = this.objectToQueryString(params);
        let uri = url + "?" + query;
        return axios.get(uri, config)
    },

    objectToQueryString(obj) {
        let str = [];
        for (let p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    },

    _request(data, tm) {
        let config = {
            timeout: tm,
            headers: authHeader()
        };
        return axios.post("/forward-api", data, config)

    },
    randomString(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }
}