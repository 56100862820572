var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c(
      "div",
      {
        staticClass:
          "page__title d-flex align-items-center justify-content-between",
      },
      [
        _c("h1", { staticClass: "title" }, [_vm._v("Nhập học sinh")]),
        _vm._v(" "),
        _c(
          "el-tooltip",
          {
            staticClass: "item",
            attrs: {
              effect: "dark",
              content: "Hướng dẫn nhập dữ liệu",
              placement: "top",
            },
          },
          [
            _c("a", { attrs: { href: "/files/huong_dan_nhap_du_lieu.docx" } }, [
              _vm._v(" Hướng dẫn nhập dữ liệu"),
            ]),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "page__header" },
      [
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [
            _c(
              "el-col",
              { attrs: { xs: 12, sm: 6, md: 4, lg: 4 } },
              [
                _c("label", [_vm._v("Năm học")]),
                _vm._v(" "),
                _c("eselect", {
                  staticStyle: { width: "100%" },
                  attrs: {
                    collapseTags: "",
                    placeholder: "Chọn",
                    filterable: "",
                    data: _vm.list_nam_hoc,
                    fields: ["name", "value"],
                  },
                  model: {
                    value: _vm.namHoc,
                    callback: function ($$v) {
                      _vm.namHoc = $$v
                    },
                    expression: "namHoc",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-col",
              { attrs: { xs: 12, sm: 6, md: 4, lg: 4 } },
              [
                _c("label", [_vm._v("Cấp học")]),
                _vm._v(" "),
                _c("eselect", {
                  staticStyle: { width: "100%" },
                  attrs: {
                    collapseTags: "",
                    placeholder: "Chọn",
                    filterable: "",
                    data: _vm.list_cap_hoc,
                    fields: ["name", "value"],
                  },
                  on: { change: _vm.getPathFile },
                  model: {
                    value: _vm.capHoc,
                    callback: function ($$v) {
                      _vm.capHoc = $$v
                    },
                    expression: "capHoc",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", [
      _c(
        "div",
        { staticClass: "page__header" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("ImportGeneral", {
            attrs: {
              pathSampleFile:
                _vm.typeHocSinh == 1
                  ? _vm.pathSampleFile.hocSinh
                  : _vm.pathSampleFile.hocSinhChung,
              fileNameError: "danh_sach_loi_nhap_hoc_sinh",
              fileHasData: true,
              linkFileHasData:
                _vm.typeHocSinh === 1
                  ? _vm.linkFileHasData
                  : _vm.linkFileHasDataChung,
              "require-nam-hoc": true,
              year: _vm.namHoc,
              "cap-hoc": _vm.capHoc,
              "template-download": _vm.template,
              source: _vm.source_moet,
              "data-type": 1,
              requireKhoiHoc: true,
              hideClass: false,
              "text-download": "Tải file nhập học sinh",
              disabledDownload: _vm.typeHocSinh != 2 && false,
              requireImportType: true,
              path: _vm.typeHocSinh === 1 && false ? _vm.path : _vm.pathChung,
              importType: _vm.typeHocSinh,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.type == 2 && false
        ? _c(
            "div",
            { staticClass: "page__header" },
            [
              _vm._m(1),
              _vm._v(" "),
              _c("ImportForNhanXetMoet", {
                attrs: {
                  "require-cap-hoc": true,
                  "require-nam-hoc": true,
                  year: _vm.namHoc,
                  "cap-hoc": _vm.capHoc,
                  needClass: true,
                  "data-type": 98,
                  "text-download": "Tải file nhập sổ đăng bộ - ngày vào trường",
                  "uri-dowload": _vm.dowloadHocSinh,
                  source: _vm.source,
                  path: _vm.pathNhanXetHocSinh,
                  "name-file": "so-dang-bo-ngay-vao-truong",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "page__title align-items-center justify-content-between" },
      [
        _c("h1", { staticClass: "title" }, [_vm._v("Dữ liệu học sinh")]),
        _vm._v(" "),
        _c("small", [
          _c("i", [
            _vm._v(
              " (Truy cập Học bạ số -> Nhập học sinh -> Tải file mẫu nhập học sinh)"
            ),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "page__title align-items-center justify-content-between" },
      [
        _c("h1", { staticClass: "title" }, [
          _vm._v("Sổ đăng bộ - Ngày vào trường"),
        ]),
        _vm._v(" "),
        _c("small", [
          _c("i", [
            _vm._v(
              " (Truy cập Học bạ số -> Nhập học sinh -> Tải file mẫu sổ đăng bộ - ngày vào trường)"
            ),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }