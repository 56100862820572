<template>
  <div v-loading.fullscreen.lock="loading" :element-loading-text="loadingText"
       element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
    <el-row :gutter="24">
      <el-col :span="24">
        <el-dialog title="Chọn chứng thư số" width="65%" center :visible.sync="show_chon_cts"
                   custom-class="thongTinKyTheoMonHoc"
                   :close-on-press-escape="false" :close-on-click-modal="false"
                   :before-close="closeDialog" class="uploadSignature">
          <el-row :gutter="24">
            <el-col :span="24">
              <el-table fit border :data="danh_sach_cts">
                <el-table-column header-align="center" label="Nguồn">
                  <template slot-scope="scope">
                    {{ getTenCTS(scope.row.certInfo.subjectCN) }}
                  </template>
                </el-table-column>
                <el-table-column header-align="center" label="Số serial">
                  <template slot-scope="scope">
                    {{ scope.row.certInfo.serial }}
                  </template>
                </el-table-column>
                <el-table-column property="validFrom" header-align="center" align="center" label="Ngày hiệu lực"
                >
                  <template slot-scope="scope">
                    {{ scope.row.certInfo.validFrom }}
                  </template>
                </el-table-column>
                <el-table-column property="validTo" header-align="center" align="center" label="Ngày hết hiệu lực">
                  <template slot-scope="scope">
                    <p>{{ scope.row.certInfo.validTo }}</p>
                  </template>
                </el-table-column>
                <el-table-column align="center" width="200" header-align="center" label="Hành động">
                  <template slot-scope="scope">
                    <div>
                      <el-button size="mini" @click.prevent="kyDuLieu(scope.row)"
                                 type="primary">Sử dụng
                      </el-button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
          <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="show_chon_cts = false">Hủy</el-button>
            </span>
        </el-dialog>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import websocket from "../websocket";
import msgType from "../_helpers/constant_msg_type";
import {
  mapState,
  mapActions
} from 'vuex';
import dialog from "../dialog";
import {
  Message
} from 'element-ui';
import constant_api from "../_helpers/constant_api";
import api from "../_helpers/api";

export default {
  name: "KyHocBaUsbAllV2",
  props: [
    'layCts',
    'nph'
  ],
  computed: {
    ...mapState('teacher', [
      'pdfSignature'
    ]),
  },
  data: () => {
    return {
      list_res_sign_hash: [],
      EdocPluginSocket: '',
      signatureBase64: '',
      loadingText: 'Đang xử lý',
      loading: false,
      issuerCN: null,
      certChainBase64: null,
      danh_sach_cts: [],
      show_chon_cts: false,
      typeKyDuLieu: '',
      SerialNumberSend: '',
      hashBase64: ''
    }
  },
  methods: {
    kyDuLieu(item){
      console.log('kyDuLieu')
      console.log(item)
      let data = item.certInfo;
      let itemUse = {
        validFrom: data.validFrom?data.validFrom.replace(/-/g, "/"):'',
        validTo: data.validTo?data.validTo.replace(/-/g, "/"):'',
        nhaPhatHanh: this.nph,
        serialNumber:data.serial,
        status: 'invalid',
        subject: data.subjectCN,
      }
      this.suDungCTS(itemUse)
    },
    suDungCTS(item) {
      console.log('suDungCTS')
      console.log(item)
      let url = constant_api.giaovien.nopCTSGV;
      let params = JSON.parse(JSON.stringify(item))
      let dataSend = {
        certBO: params,
        type: 4
      }
      console.log(dataSend)
      this.$confirm('Xác nhận cập nhật thông tin chứng thư số?', 'Thông báo', {
        confirmButtonText: 'Cập nhật',
        cancelButtonText: 'Hủy',
        confirmButtonClass: 'el-button--primary'
      })
          .then(_ => {
            console.log('Gửi dữ liệu cập nhật')
            api.post(url, dataSend).then(
                response => {
                  console.log("Res:")
                  console.log(response)
                  if (response.data.code == 200) {
                    this.thongBao('success', response.data.msg)
                    // this.layChungThuSo()
                  } else {
                    this.thongBao("error", response.data.msg)
                  }
                  this.loading = false;

                  this.$emit('done')
                }
            )
          })
          .catch(_ => {
          });
    },
    closeDialog() {
      console.log('closeDialog')
      this.show_chon_cts = false;
    },
    kyThanhCong() {
      console.error("Ký thành công, có thể ký lại")
      this.$emit('close')
    },
    huyKy() {
      console.error("Hủy bỏ việc ký để có thể ký lại")
      this.loading = false;
      this.$emit('close')
    },
    getTenCTS(chuoi) {
      var batDau = "CN=";
      var ketThuc = ",";

      var startIndex = chuoi.indexOf(batDau) + batDau.length;
      var endIndex = chuoi.indexOf(ketThuc, startIndex);

      var chuoiCon = endIndex !== -1 ? chuoi.substring(startIndex, endIndex) : chuoi.substring(startIndex);
      return chuoiCon
    },
    onReceiveMessage(e) {
      let data = e.data;
      if (data.other == msgType.type.signScanUSBTOKEN || data.other == msgType.type.signDauQTHTUSBTOKEN || data.other == msgType.type.signUsbHocBa || data.other == msgType.type.kyDauBiahHocBa || data.other == msgType.type.dongDauBiaHocBa || data.other == msgType.type.signQTHTUSBTOKEN || data.other == msgType.type.signDauSoDiemUSBTOKEN || data.other == msgType.type.signKySoDiemUSBTOKEN || data.other == msgType.type.signDauSoDiemUSBTOKEN || data.other == msgType.type.thucHienDongDauBiaAnh || data.other == msgType.type.hieuTruongKyDauBiahHocBa) {
        console.error('onReceiveMessage Ký USB All')
        console.error(data)
        if (data.code == 200) {
          // this.thongBao('success', data.Message)
          this.kyThanhCong();
        } else {
          // this.thongBao('error', data.Message)
          this.huyKy();
        }
        this.loading = false;
      }
      if (data.other == msgType.type.getHashKyQTHTUSBTOKEN || data.other == msgType.type.getHashDauQTHTUSBTOKEN || data.other == msgType.type.getHashHocBa || data.other == msgType.type.getHashKyScanUSBTOKEN || data.other == msgType.type.getHashDauBiahHocBa || data.other == msgType.type.getHashDongDauBiaHocBa || data.other == msgType.type.getHashHieuTruongKyDauBiahHocBa || data.other == msgType.type.getHashDongDauBiaAnh || data.other == msgType.type.getHashKyScanUSBTOKEN || data.other == msgType.type.layHashDongDauSoDiem || data.other == msgType.type.layHashKySoDiem) {
        console.log("Thông tin lấy hash trả về:")
        console.log(data)
        if (data.code == 200) {
          this.InsertSignatrue(data.data);//?.....
        } else {
          this.thongBao('error', data.Message)
          this.$emit('error')
          this.huyKy();
        }
      }
    },
    async InsertSignatrue(e) {
      var that = this;
      console.log('InsertSignatrue')
      console.log(e)
      let datas = [];
      const testA = await that.getSignatureBase64(e);
      console.log('Có hash trả về')
      console.log(testA)
      for (let i = 0; i < e.length; i++) {
        const item = e[i];
        //console.log("event promise")
        //console.log('1')
        let du_lieu_ky = {
          id: item.id,
          signatureBase64: testA[i].signatureBase64,
          key: item.key,
          viTri: item.viTri,
          hashBase64: item.hashBase64,
          serialNumber: that.SerialNumberSend
        }
        datas.push(du_lieu_ky);

      }
      console.log("Thông tin ký:", datas)
      console.log('Kiểu ký trong InsertSignatrue:' + that.typeKyDuLieu)
      this.loadingText = 'Đang thực hiện ký...'
      let data = {
        msgType: msgType.type.signUsbHocBa,
        maLop: that.dulieu[0].maLop,
        data: datas,
        serialNumber: that.SerialNumberSend,
        issuerCN: that.issuerCN
      }
      if (that.typeKyDuLieu == 'dongDauBia') {
        data.msgType = msgType.type.dongDauBiaHocBa
      }
      if (that.typeKyDuLieu == 'bia') {

        data.msgType = msgType.type.hieuTruongKyDauBiahHocBa
      }
      if (that.typeKyDuLieu == 'dongDauBiaKqht') {
        // data.msgType = msgType.type.thucHienDongDauBiaKqht
        data.msgType = msgType.type.kyDauBiahHocBa
      }
      if (that.typeKyDuLieu == 'dongDauBiaAnh') {
        data.msgType = msgType.type.thucHienDongDauBiaAnh
      }
      if (that.typeKyDuLieu == 'dongDauKqht') {
        data.msgType = msgType.type.dongDauBiaHocBa
      }
      if (that.typeKyDuLieu == 'dauQTHT') {
        data.msgType = msgType.type.signDauQTHTUSBTOKEN
      }
      if (that.typeKyDuLieu == 'kyQTHT') {
        data.msgType = msgType.type.signQTHTUSBTOKEN
      }
      if (that.typeKyDuLieu == 'dongDauSoDiem') {
        data.msgType = msgType.type.signDauSoDiemUSBTOKEN
      }
      if (that.typeKyDuLieu == 'kySoDiem') {
        data.msgType = msgType.type.signKySoDiemUSBTOKEN
      }
      if (that.typeKyDuLieu == 'kyscan') {
        data.msgType = msgType.type.signScanUSBTOKEN;
        data.position = that.position;
      }
      console.log('Gửi ký:')
      console.log(data)
      websocket.sendMessage(data);
    },
    async getSignatureBase64(e) {
      console.log('getSignatureBase64')
      console.log(e)
      var serialNumber = e.serialNumber || 'null';
      this.SerialNumberSend = serialNumber;
      // var hashBase64 = e.hashBase64;
      var hashBase64 = e.map(function (obj) {
        return obj.hashBase64;
      });
      var data_sign_hash = [];
      for (let i = 0; i < hashBase64.length; i++) {
        let obj = {
          hashBase64: hashBase64[i]
        }
        data_sign_hash.push(obj)
      }
      console.log(data_sign_hash)


      console.log('hashBase64:')
      console.log(hashBase64)
      const signHash = await this.EdocPluginSocket.signHash(data_sign_hash, serialNumber);
      console.log('response:')
      console.log(signHash)
      return signHash;
    },
    signUsb() {
      this.loadingText = 'Đang lấy thông tin chứng thư số...';
      this.loading = true;
      let that = this;
      console.log('B1: getCertificateInfo')
      this.EdocPluginSocket.getCertificateInfo(function (response) {
        console.log('response:')
        console.log(response)
        if (response.status == 1) {
          that.danh_sach_cts = response.data;
          that.show_chon_cts = true;
          that.loading = false;
          return;
        }
        if (response.Status == 0) {
          that.thongBao('error', response.Message)
          that.loading = false;
          that.huyKy();
          return;
        }
        if (response.Status == -105) {
          that.thongBao('error', response.Message)
          that.loading = false;
          that.$confirm('Xác nhận tải và cài đặt ứng dụng?', 'Thông báo', {
            confirmButtonText: 'Đồng ý',
            cancelButtonText: 'Hủy',
          })
              .then(_ => {
                console.log("Tải ứng dụng:")
                window.location.href = 'https://edocapi.smas.edu.vn/Storage/Static/Software/EdocCaPluginSetup.rar';
              })
              .catch(_ => {
              });
          that.huyKy();
          return;
        }
        return;
        var certChainBase64 = new String(response.Data.Base64);
        if (certChainBase64 == undefined || certChainBase64.length < 500) {
          that.thongBao('error', 'Chọn CTS không thành công')
          that.loading = false;
          that.huyKy();
          return false;
        }
        console.log("B2")
        let ids = [];
        let maLop = "";
        that.dulieu.forEach(item => {
          ids.push({
            id: item.id
          });
          maLop = item.maLop
        })
        let dataHash = {
          reqs: ids,
          certChainBase64: certChainBase64,
          maLop: maLop,
          msgType: ''
        };
        // dataHash.msgType = that.getMessageType(that.typeKyDuLieu)
        if (that.typeKyDuLieu == 'bia') {
          dataHash.msgType = msgType.type.getHashHieuTruongKyDauBiahHocBa;
        }
        if (that.typeKyDuLieu == 'hocba') {
          dataHash.msgType = msgType.type.getHashHocBa;
        }
        if (that.typeKyDuLieu == 'dongDauBia') {
          dataHash.msgType = msgType.type.getHashDongDauBiaHocBa;
        }
        if (that.typeKyDuLieu == 'dongDauBiaKqht') {
          dataHash.msgType = msgType.type.getHashDauBiahHocBa;
        }
        if (that.typeKyDuLieu == 'dongDauBiaAnh') {
          dataHash.msgType = msgType.type.getHashDongDauBiaAnh;
        }
        if (that.typeKyDuLieu == 'dongDauKqht') {
          dataHash.msgType = msgType.type.getHashDongDauBiaHocBa;
        }
        if (that.typeKyDuLieu == 'dauQTHT') {
          dataHash.msgType = msgType.type.getHashDauQTHTUSBTOKEN;
        }
        if (that.typeKyDuLieu == 'kyQTHT') {
          dataHash.msgType = msgType.type.getHashKyQTHTUSBTOKEN;
        }
        if (that.typeKyDuLieu == 'kyscan') {
          dataHash.msgType = msgType.type.getHashKyScanUSBTOKEN;
          dataHash.position = that.position;
        }
        if (that.typeKyDuLieu == 'dongDauSoDiem') {
          dataHash.msgType = msgType.type.layHashDongDauSoDiem;
          dataHash.position = that.position;
        }
        if (that.typeKyDuLieu == 'kySoDiem') {
          dataHash.msgType = msgType.type.layHashKySoDiem;
          dataHash.position = that.position;
        }
        console.log('dataHash');
        console.log(dataHash);
        that.loadingText = 'Lấy hash...'
        // chỗ này call về BE để nó lấy hash rồi trả ra chỗ onMessage đăng ký ở mounted
        websocket.sendMessage(dataHash);


      });

    },

    thongBao(t, e) {
      let msg = "";
      let cl = "";
      if (e) {
        msg = e;
      }
      let type = "success";
      if (t) {
        type = t
      }
      if (type == "success") {
        cl = "dts-noty-success"
      }
      if (type == "warning") {
        cl = "dts-noty-warning"
      }
      if (type == "error") {
        cl = "dts-noty-error"
      }
      if (type == "info") {
        cl = "dts-noty-info"
      }
      this.$message({
        customClass: cl,
        showClose: true,
        message: msg,
        type: t,
        duration: 3000
      });
    },
    getMessageType(typeKy) {
      switch (typeKy) {
        case "bia":
          return msgType.type.getHashHieuTruongKyDauBiahHocBa;
        case "kyQTHT":
          return msgType.type.getHashKyQTHTUSBTOKEN
        default:
          return ""
      }
    }
  },

  watch: {
    layCts(val) {
      if (val) {
        console.log("Lấy chứng thư số")
        this.signUsb()
      }
    }

  },
  beforeDestroy() {
    console.log('beforeDestroy')
    window.removeEventListener('message', this.onReceiveMessage)
  },
  beforeRouteLeave(to, from, next) {
    console.error('beforeRouteLeave')
    window.removeEventListener('message', this.onReceiveMessage)
    next();
  },
  mounted() {
    console.error('Mounted trong Ký học Bạ USB V2:')
    window.addEventListener('message', this.onReceiveMessage, false);
    var that = this;
    this.EdocPluginSocket = {
      sendMessageToPlugin: function (serviceName, data, callBack) {
        var socket = new WebSocket('ws://localhost:9834/general');
        // Connection opened
        socket.addEventListener('open', function (event) {
          console.log("Open")
          console.log(data)
          socket.send(JSON.stringify(data));
        });
        socket.addEventListener('message', function (event) {
          console.log("message")
          callBack(JSON.parse(event.data));
        });

        // Listen for close connection
        socket.addEventListener('close', function (event) {
          console.log('close')
          var response = {
            Status: -105,
            Message: "Chưa cài đặt hoặc chưa chạy EDOC-CA Plugin."
          }
          if (event.code == 1006) {
            callBack(response);
          }
        });
      },

      sendMessageToPluginAsync: async function (serviceName, data) {
        console.log('sendMessageToPluginAsync')
        console.log(serviceName)
        console.log('data')
        console.log(data)
        // data.functionName = serviceName;
        // Create WebSocket connection.
        return new Promise((resolve, reject) => {
          // const socket = new WebSocket('ws://localhost:9834/' + serviceName);
          const socket = new WebSocket('ws://localhost:9834/general');
          // Connection opened
          socket.addEventListener('open', function (event) {
            console.log("send ws:")
            console.log(data)
            // let pr = {
            //   data:JSON.stringify(data),
            //   funtionName:serviceName
            // }
            console.log(JSON.stringify(data))
            socket.send(JSON.stringify(data));
          });

          // Listen for messages
          socket.addEventListener('message', function (event) {
            console.log("message")
            resolve(JSON.parse(event.data));
          });

          // Listen for close connection
          socket.addEventListener('close', function (event) {
            console.log('close')
            var response = {
              Status: -105,
              Message: "Chưa cài đặt hoặc chưa chạy EDOC-CA Plugin."
            }
            if (event.code == 1006) {
              resolve(response);
            }
          });
        })
      },

      getCertificateInfo: function (callBack) {
        // var functionName = 'get-cert-info';
        var functionName = 'get_all_certs';
        console.log("getCertificateInfo")
        let params = {
          functionName: 'get_all_certs'
        }
        that.EdocPluginSocket.sendMessageToPlugin(null, params, callBack);
      },
      signHash: async function (hashBase64, serialNumber) {
        // var functionName = 'general/sign_hash';
        console.log('signHash 1')
        var functionName = 'sign_hash';
        var data = {
          hashBase64: hashBase64,
          // SerialNumber: serialNumber,
        }
        // var listSign = [data]
        console.log("data signHash ")
        console.log(data)
        const respon = await that.EdocPluginSocket.sendMessageToPluginAsync(functionName, {
          data: {
            listSign: hashBase64,
            certBase64: that.certChainBase64
          },
          uniqueId:'',
          functionName:functionName
        });
        console.log("respon signHash")
        console.log(respon)
        return respon.data
      }
    }
    this.signUsb()
  }
}
</script>

<style scoped>

</style>