<template>
    <div id="baoCaoKy" class="page page-file file detailHocBa" v-loading.fullscreen.lock="fullscreenLoading">
        <div class="page__title d-flex align-items-center justify-content-between">
            <h1 class="title">Quản lý gói cước</h1>
        </div>
        <div class="page__content">
            <div class="listNew mb-5">
            </div>
        </div>
    </div>
</template>
<script>
import {
    mapActions,
    mapState
} from 'vuex';
import ESelectVue from "./Ui/ESelect";
import constant from "../_helpers/constant_api";
import api from "../_helpers/api";
import {authHeader} from "../_helpers/auth-header";
import axios from "axios";
export default {
    name: "BaoCaoKy",
    metaInfo: {
        title: 'Quản lý gói cước',
        titleTemplate: '',
        meta: [{
            vmid: 'description',
            name: 'description',
            content: ''
        }]
    },
    components: {
        'eselect': ESelectVue,
    },
    computed: {
        ...mapState('account', [
            'user',
        ]),
    },
    mounted() {
        this.getDanhSachGoiCuoc();
    },
    data() {
        return {
            fullscreenLoading: false,
        }
    },
    methods: {
        thongBao(t, e) {
            let msg = "";
            let cl = "";
            if (e) {
                msg = e;
            }
            let type = "success";
            if (t) {
                type = t
            }
            if (type == "success") {
                cl = "dts-noty-success"
            }
            if (type == "warning") {
                cl = "dts-noty-warning"
            }
            if (type == "error") {
                cl = "dts-noty-error"
            }
            if (type == "info") {
                cl = "dts-noty-info"
            }
            this.$message({
                customClass: cl,
                showClose: true,
                message: msg,
                type: t,
                duration: 3000
            });
        },
        layDanhSachLopHoc() {
            let uri = constant.truong.getListKy;
            let params = {
                namHoc: this.namHoc
            }
            this.fullscreenLoading = true;
            api.get(uri, params).then(
                response => {
                    this.fullscreenLoading = false;
                    console.log("Danh sách lớp trả về:")
                    console.log(response)
                    if (response.data.code == 200) {
                        this.danh_sach_lop = response.data.data;
                    } else {
                        this.thongBao('error', response.data.msg)
                    }
                }
            );
        },
        getDanhSachGoiCuoc(){
            console.error('getDanhSachGoiCuoc')
            axios
                .get('http://103.226.251.36:55599/open-api/chung-thu-so/get-by-magv?maGiaoVien=GV006')
                .then(response =>{
                    console.error('thông tin:')
                    console.error(response)
                })
        },
    }
}
</script>
<style scoped>
.el-icon-close {
    color: red;
    font-weight: 900;
}
.el-icon-check {
    color: blue;
    font-weight: 900;
}
</style>