<template>
  <div id="danhSachLop" class="page page-file file detailHocBa" v-loading.fullscreen.lock="fullscreenLoading">
    <div class="page__title d-flex align-items-center justify-content-between">
      <h1 class="title">Lịch sử nhập dữ liệu</h1>
    </div>
    <div class="box-upload-file">
      <el-row :gutter="24">
        <el-col :xs="24" :sm="12" :md="8" :lg="6">
            <label>Năm học</label>
          <eselect style="width:100%" collapseTags v-model="namHoc" placeholder="Chọn" filterable
                   :data="list_nam_hoc" :fields="['name','value']" />
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :lg="6">
            <div style="color: transparent">Tìm kiếm</div>
            <el-button class="m-0" @click.prevent="layDanhSachLopHoc" type="primary">Tìm kiếm</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="page__content">
      <div class="listNew mb-5">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-table fit lazy :data="danh_sach_nhap_du_lieu" border style="width: 100%;">
              <!-- <el-table-column fixed align="center" type="index" label="STT" width="50">
              </el-table-column> -->

              <el-table-column label="STT" property="title" style="width: 50px" width="50" type="index"
                               class-name="text-center" :index="indexMethod">
              </el-table-column>
              <!--                            <el-table-column align="left" header-align="center" fixed prop="maTruong" label="Mã trường">-->
              <!--                            </el-table-column>-->
              <!--                            <el-table-column align="left" header-align="center" fixed prop="maPhong" label="Mã phòng">-->
              <!--                            </el-table-column>-->
              <!--                            <el-table-column align="left" header-align="center" fixed prop="maSo" label="Mã sở">-->
              <!--                            </el-table-column>-->

              <el-table-column align="left" header-align="center" prop="nameType" label="Hành động">
              </el-table-column>

              <el-table-column align="center" header-align="center" label="Ngày tạo">
                <template slot-scope="scope">
                  {{ new Date(scope.row.createdDate) | moment("DD/MM/YYYY HH:mm:ss") }}
                </template>
              </el-table-column>
              <el-table-column align="center" header-align="center" label="Chi tiết">
                <template slot-scope="scope">
                  <el-tooltip content="Xem chi tiết" placement="left">
                    <el-button size="mini" @click.prevent="xemChiTiet(scope.row)" type="success"
                               icon="el-icon-view"></el-button>
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
            <div class="text-right mt-2">
              <pagination :page-count="paginate.total" :click-handler="layDanhSachLopHoc"
                          :prev-text="'Trước'" :next-text="'Sau'" :container-class="'el-pager'"
                          :pageClass="'number'">

              </pagination>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div>
      <el-dialog title="Chi tiết nhập dữ liệu" @close="dongModal" :close-on-click-modal="false" top="5vh"
                 width="85%" center custom-class="thongTinLopHoc" :visible.sync="hien_thi_chi_tiet">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane v-for="(item,index) in list_sheet_data" :key="index" :label="item.sheetName"
                       :name="item.sheetName">
            <div>
              <el-table :max-height="maxHeight" fit lazy header-row-class-name="thongTinLopHoc"
                        :data="item.data" border style="width: 100%;">
                <template v-for="(colName,index) in item.list_col">
                  <el-table-column :label="colName.nameCol" align="center">
                    <template slot-scope="scope">
                      <div>
                        {{scope.row[colName.key]}}
                      </div>
                    </template>
                  </el-table-column>
                </template>
              </el-table>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {
  mapActions,
  mapState
} from 'vuex';
import ESelectVue from "./../Ui/ESelect";
import LZString from 'lz-string';
import constant from "../../_helpers/constant_api";
import Pagination from "../../components/Pagination";
import api from "../../_helpers/api";
export default {
  name: "LichSuNhapDuLieu",
  metaInfo: {
    title: 'Lịch sử nhập dữ liệu',
    titleTemplate: '',
    meta: [{
      vmid: 'description',
      name: 'description',
      content: ''
    }]
  },
  components: {
    'eselect': ESelectVue,
    Pagination
  },
  computed: {
    ...mapState('account', [
      'user',
      'list_nam_hoc',
      'nam_hoc_hien_tai'
    ])
  },
  mounted() {
    this.maxHeight -= 250;
    this.namHoc = this.nam_hoc_hien_tai;
    this.layDanhSachLopHoc();
  },
  data() {
    return {

      paginate: {
        start: 0,
        limit: 100,
        total: 0,
      },
      fullscreenLoading: false,
      activeName: '',
      list_sheet_data: [],
      maxHeight: window.innerHeight,
      namHoc: '',
      tieuDeHienthi: '',
      maLop: '',
      danh_sach_mon: [],
      danh_sach_nhap_du_lieu: [],
      hien_thi_chi_tiet: false,
      hien_thi_hoc_sinh: false,
      danh_sach_phan_cong_giang_day: [],
      danh_sach_hoc_sinh: [],
    }
  },
  methods: {
    indexMethod(index) {
      return (this.paginate.start + index) + 1;
    },
    dongModal() {
      console.log("Đóng")
      this.list_sheet_data = [];
      this.activeName = '';
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    xemChiTiet(e) {
      this.fullscreenLoading = true;
      let errors = JSON.parse(LZString.decompressFromBase64(e.data));
      this.fullscreenLoading = false;
      if (errors.length > 0) {
        this.hienThiChiTietFile(errors);
      } else {
        this.thongBao('error', 'Không có thông tin');
        this.fullscreenLoading = false;
      }
    },
    hienThiChiTietFile(e) {
      this.fullscreenLoading = true;
      let data = e;
      console.log(data);
      let alone = [];
      this.activeName = data[0].sheetName;
      if (this.activeName) {
        console.log("Sheet name là:" + this.activeName);
        for (let i = 0; i < data.length; i++) {
          data[i].list_col = this.getNameCol(data[i].data[0])
        }
        this.ganDuLieuData(data);
      } else {
        this.activeName = 'Sheet1';
        let obj = {
          list_col: this.getNameCol(data[0]),
          data: data,
          sheetName: 'Sheet1',
        }
        alone.push(obj);
        this.ganDuLieuData(alone);
      }

    },
    ganDuLieuData(e) {
      let data = e;
      console.log("gán")
      console.log(data)
      for (let i = 0; i < data.length; i++) {
        data[i].data.shift();
      }
      this.list_sheet_data = data;
      setTimeout(() => {
        this.hien_thi_chi_tiet = true;
        this.fullscreenLoading = false
      }, 2500);
    },
    getNameCol(e) {
      let data = Object.values(e);
      let arr = [];
      for (let i = 0; i < data.length; i++) {
        let obj = {
          key: (i + 1).toString(),
          nameCol: data[i]
        }
        arr.push(obj)
      }
      return arr;
    },
    thongBao(t, e) {
      let msg = "";
      let cl = "";
      if (e) {
        msg = e;
      }
      let type = "success";
      if (t) {
        type = t
      }
      if (type == "success") {
        cl = "dts-noty-success"
      }
      if (type == "warning") {
        cl = "dts-noty-warning"
      }
      if (type == "error") {
        cl = "dts-noty-error"
      }
      if (type == "info") {
        cl = "dts-noty-info"
      }
      this.$message({
        customClass: cl,
        showClose: true,
        message: msg,
        type: t,
        duration: 3000
      });
    },

    getTotalPage(number) {
      return Math.ceil(number / (this.paginate.limit));
    },
    layDanhSachLopHoc(select) {
      console.log("hihi")
      console.log(select)
      if (select) {
        let start = (select - 1) * this.paginate.limit ? (select - 1) * this.paginate.limit : 0;
        this.paginate.start = start;
      } else {
        this.paginate.start = 0;
      }
      console.log(this.paginate)
      if (!this.namHoc || this.namHoc == '') {
        this.thongBao('error', 'Thiếu thông tin năm học.');
        return;
      };
      this.danh_sach_nhap_du_lieu = [];
      this.paginate.total = 0;
      let uri = constant.import.lichSuNhapDuLieu;
      let params = {
        namHoc: this.namHoc,
        start: this.paginate.start,
        limit: this.paginate.limit
      }
      this.fullscreenLoading = true;
      api.get(uri, params).then(
          response => {
            this.fullscreenLoading = false;
            console.log("Danh sách lớp trả về:")
            console.log(response)
            if (response.data.code == 200) {
              if (response.data.data.list.length > 0) {
                this.danh_sach_nhap_du_lieu = response.data.data.list;
                this.paginate.total = this.getTotalPage(response.data.data.total);
                // this.thongBao('success', 'Lấy dữ liệu thành công');
              } else {
                // this.thongBao('error', 'Không tìm thấy bản ghi nào')
              }
            } else {
              this.thongBao('error', response.data.msg)
            }
          }
      );
    },
  }
}
</script>
<style scoped>
.el-icon-close {
  color: red;
  font-weight: 900;
}

.el-icon-check {
  color: blue;
  font-weight: 900;
}

.page .el-table table thead tr th {
  background: #6884bf;
}
</style>