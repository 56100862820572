<template>
  <div class="page page-login" v-loading="status.loggingIn" element-loading-text="Loading..."
       element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="container">
      <div class="row vh-100 align-items-center justify-content-center">
        <div class="col-md-4"></div>
        <div class="col-md-4">
          <img src="/images/bg1.png" alt="bg">
          <div class="login-panel card">
            <div class="card-header pb-0 border-0" style="background-color: transparent;">
              <h3 class="panel-title text-center font-weight-bold"
                  style="font-size: 20px; color: #21BEF4;">Đăng nhập</h3>
              <!--                        <p class="m-0 text-center" style="font-size: 14px;">Nhập tài khoản được cấp để đăng nhập hệ thống</p>-->
            </div>
            <div v-if="!showLogin" class="card-body login__form pt-0">
              <div class="row" style="margin-top:15px">
                <div class="selectOptionLogin">
                  <el-button class="btn btn-lg btn-block btn-login" @click.prevent="linkToSSO()"
                             type="danger">Đăng nhập SSO hệ sinh thái giáo dục
                  </el-button>
                </div>
                <div class="selectOptionLogin">
                  <el-button class="btn btn-lg btn-block btn-login" @click.prevent="showSSOHCM()"
                             type="primary">Đăng nhập SSO HCM
                  </el-button>
                </div>
                <div class="selectOptionLogin">
                  <el-button class="btn btn-lg btn-block btn-login" @click="showLogin = true"
                             type="success">Đăng nhập với tài khoản thường
                  </el-button>
                </div>
                <!--                <div class="selectOptionLogin">-->
                <!--                  <el-button class="btn btn-lg btn-block btn-login" @click="redirectToLoginOtp"-->
                <!--                             style="background-color: #00A65A;"-->
                <!--                             type="success">Đăng nhập OTP-->
                <!--                  </el-button>-->
                <!--                </div>-->
              </div>
            </div>
            <el-col :span="24" class="text-center">
              <div style="padding-top:10px">
                <table class="login-footer" style="margin:0 auto">
                  <tbody>
                  <tr>
                    <td>
                      <div class="support-text">
                        <div class="purple">
                          <strong>TƯ VẤN VÀ HỖ TRỢ
                            <strong class="orange f-16">18008000</strong>
                          </strong>
                        </div>
                        <div class="grey">Nhánh 2 (miễn phí)</div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </el-col>
            <div class="card-body login__form pt-0" v-if="showLogin">
              <el-form ref="formLogin" :model="formLogin" :rules="rules">
                <el-form-item>
                  <el-alert style="line-height: 1;" class="animated flipInX text-center"
                            v-bind:title="alert.message" v-if="alert.message" v-bind:type="alert.type"
                            :closable="false">
                  </el-alert>
                </el-form-item>
                <el-form-item prop="email">
                  <el-input name="email" v-model="formLogin.email"
                            @keyup.enter.native="submitForm('formLogin')" placeholder="Tài khoản"
                            title="Tài khoản"></el-input>
                </el-form-item>
                <el-form-item prop="password">
                  <el-input name="password" show-password type="password"
                            @keyup.enter.native="submitForm('formLogin')" v-model="formLogin.password"
                            placeholder="Password" title="Password"></el-input>
                </el-form-item>
                <el-form-item v-if="capCha" prop="capChaValue">
                  <el-row :gutter="0" style="margin-bottom:0">
                    <el-col v-loading="!capCha" :span="8">
                      <img :src="capCha"
                           style="width:100%;height:40px;border:1px solid black;border-radius:4px"
                           alt="Capcha"/>
                    </el-col>
                    <el-col :span="16">
                      <el-input name="capChaValue" placeholder="Nhập capcha"
                                v-model="formLogin.capChaValue" class="input-with-select">
                        <el-button slot="append" @click.prevent="refreshCapCha"
                                   icon="el-icon-refresh"></el-button>
                      </el-input>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-form-item class="m-0">
                  <div class="d-flex justify-content-between">
                    <el-checkbox label="Nhớ mật khẩu" v-model="formLogin.rememberPassword">
                    </el-checkbox>
                  </div>
                  <div>
                    <el-button class="btn btn-lg btn-block btn-login"
                               style="background-color: #00A65A;" type="primary"
                               @click="submitForm('formLogin')">Đăng nhập
                    </el-button>
                  </div>
                  <p class="text-center m-0" style="color: #0C9AE3;">Hoặc</p>
                </el-form-item>
              </el-form>
              <div class="row">
                <!--                <div class="col-md-6">-->
                <!--                  <el-link :href="'/login-otp'" class="d-block" :underline="false">-->
                <!--                    <el-button class="btn btn-lg btn-block btn-login" type="primary">Đăng nhập OTP-->
                <!--                    </el-button>-->
                <!--                  </el-link>-->
                <!--                </div>-->
                <div class="col-md-12">
                  <el-button @click.prevent="linkToSSO()" class="btn btn-lg btn-block" type="danger">
                    Đăng nhập SSO
                  </el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <el-dialog class="" title="Đăng nhập tài khoản SSO" :close-on-click-modal="false"
                     :close-on-press-escape="false" append-to-body center :visible.sync="show_login_sso_hcm">
            <div>
              <el-row :gutter="24">
                <el-col :span="12">
                  <label>Đơn vị</label>
                  <el-select v-model="maPhongHCM"
                             @change="chonDonVi()" filterable clearable placeholder="Chọn đơn vị">
                    <el-option
                        v-for="item in list_phong"
                        :key="item.maDonVi"
                        :label="item.tenDonVi + ' - ['+item.maDonVi+']'"
                        :value="item.maDonVi">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="12">
                  <label>Trường học</label>
                  <el-select v-model="maTruongSSOHCM" filterable clearable placeholder="Chọn trường học">
                    <el-option
                        v-for="item in list_truong"
                        :key="item.maTruong"
                        :label="item.tenTruong + ' - ['+item.maTruong+']'"
                        :value="item.maTruong">
                    </el-option>
                  </el-select>
                </el-col>
              </el-row>
              <div class="text-center mt-3">
                <el-button type="danger" size="mini" @click.prevent="show_login_sso_hcm = false">Đóng
                </el-button>
                <el-button type="primary" size="mini" :disabled="!maTruongSSOHCM" @click.prevent="linkToSSOHCM">Đăng
                  nhập
                </el-button>
              </div>
            </div>

          </el-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../_helpers/api';
import constant_api from '../_helpers/constant_api';
import {
  mapState,
  mapActions
} from 'vuex';

export default {
  name: "Login",
  metaInfo: {
    title: 'Học bạ số',
    titleTemplate: '%s | Đăng nhập',
    meta: [{
      vmid: 'description',
      name: 'description',
      content: ''
    }]
  },
  computed: {
    ...mapState('account', ["status", 'user', 'capCha']),
    ...mapState({
      alert: state => state.alert
    }),
  },
  methods: {
    redirectToLoginOtp() {
      let uri = "login-otp"
      window.location.href = uri;
    },
    linkToSSO2() {
      let uri = "https://sso-edu.viettel.vn/?page=Account.App.login&userId=5f9a6bb662aa4a0d003eceb2"
      window.location.href = uri;
    },
    ...mapActions('account', ['login', 'reGetCapCha']),
    ...mapActions({
      clearAlert: 'alert/clear'
    }),
    refreshCapCha() {
      if (this.formLogin.email) {
        this.reGetCapCha({
          email: this.formLogin.email
        })
      }
    },
    chonDonVi() {
      console.log('chonDonVi')
      this.list_truong = [];
      this.maTruongSSOHCM = '';
      if (this.maPhongHCM) {
        for (let i = 0; i < this.list_truongs.length; i++) {
          if (this.list_truongs[i].maPhong == this.maPhongHCM) {
            this.list_truong = this.list_truongs[i].truongData
          }
        }
        console.log('Danh sách trường của phòng ' + this.maPhongHCM)
        console.log(this.list_truong)
      } else {

      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitted = true;
          const {
            email,
            password,
            rememberPassword,
            capChaValue,
          } = this.formLogin;
          if (email && password) {
            this.login({
              email,
              password,
              rememberPassword,
              capChaValue
            })
          }
        } else {
          return false;
        }
      });
    },
    objectToQueryString(obj) {
      let str = [];
      for (let p in obj)
        if (obj.hasOwnProperty(p)) {
          str.push(p + "=" + obj[p]);
        }
      return str.join("&");
    },
    generateString(length) {
      let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }

      return result;
    },

    thongBao(t, e) {
      let msg = "";
      let cl = "";
      if (e) {
        msg = e;
      }
      let type = "success";
      if (t) {
        type = t
      }
      if (type == "success") {
        cl = "dts-noty-success"
      }
      if (type == "warning") {
        cl = "dts-noty-warning"
      }
      if (type == "error") {
        cl = "dts-noty-error"
      }
      if (type == "info") {
        cl = "dts-noty-info"
      }
      this.$message({
        customClass: cl,
        showClose: true,
        message: msg,
        type: t,
        duration: 3000
      });
    },
    showSSOHCM() {
      console.log('Hiển thị form SSO HCM')
      this.show_login_sso_hcm = true;
    },
    linkToSSOHCM() {
      console.log('linkToSSOHCM')
      this.getTokenHcm(this.maTruongSSOHCM)
      return;
      this.$prompt('Nhập mã trường', 'Đăng nhập hệ sinh thái SSO HCM', {
        confirmButtonText: 'Tiếp tục',
        cancelButtonText: 'Hủy',
        inputErrorMessage: 'Invalid Email',
        inputPlaceholder: 'Nhập mã trường'
      }).then(({value}) => {
        if (value) {
          console.log('Mã trường là: '.value)
          this.getTokenHcm(value)
        } else {
          this.thongBao('error', 'Vui lòng nhập mã trường');
          this.linkToSSOHCM();
        }
      }).catch(() => {
        console.log('Huy')
      });
    },
    getTokenHcm(maTruong) {
      console.log('getTokenHcm')

      let url = '/hocbadientu-service/api/get-token-sso-hcm';
      console.log('url:' + url)
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      api.get(url, {
        maTruong: maTruong
      }).then(
          response => {
            console.log("Res:")
            console.log(response)
            if (response.data.code == 200) {
              console.log(response.data.data)
              window.location.href = response.data.data;
              loading.close();
            } else {
              this.thongBao('error', response.data.msg)
              loading.close();
            }
          }
      )
    },
    linkToSSO() {
      let url = constant_api.login.getCodeSSO;
      console.log('url:' + url)
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      api.get(url, {}).then(
          response => {
            console.log("Res:")
            console.log(response)
            var redirect_uri = location.protocol + '//' + location.host;
            console.log(redirect_uri)
            if (response.data.code == 200) {
              loading.close();
              let code_challenge = response.data.data.code_challenge;
              let code_verifyer = response.data.data.code_verifier;
              localStorage.setItem('code_verifyer', code_verifyer)
              let params = {
                response_type: 'code',
                redirect_uri: redirect_uri,
                client_id: 'hocbadientu_client',
                state: this.generateString(5),
                scope: 'openid profile offline_access esmartup',
                code_challenge: code_challenge,
                code_challenge_method: 'S256',
              };
              console.log(params)
              let query = this.objectToQueryString(params);
              let urlLogin = 'https://id.nentanggiaoduc.edu.vn/connect/authorize?' + query;
              console.log("urlLogin")
              console.log(JSON.stringify(urlLogin))
              loading.close();
              window.location.href = urlLogin;
            } else {
              this.thongBao('error', response.data.msg)
              loading.close();
            }
          }
      )
    }
  },
  data() {
    return {
      show_login_sso_hcm: false,
      maPhongHCM: '',
      maTruongSSOHCM: '',
      list_truong: [],
      list_truongs:[
                     {
                       "maPhong": null,
                       "tenPhong": "Quận 11",
                       "truongData": [
                         {
                           "maTruong": "79000746",
                           "tenTruong": "THPT Nam Kỳ Khởi Nghĩa"
                         },
                         {
                           "maTruong": "79000718",
                           "tenTruong": "THPT Nguyễn Hiền"
                         },
                         {
                           "maTruong": "79000781",
                           "tenTruong": "THPT Trần Nhân Tông"
                         },
                         {
                           "maTruong": "79000768",
                           "tenTruong": "THPT Trần Quang Khải"
                         },
                         {
                           "maTruong": "79000779",
                           "tenTruong": "THPT Trần Quốc Tuấn"
                         },
                         {
                           "maTruong": "790007A2",
                           "tenTruong": "THPT Việt Mỹ Anh"
                         },
                         {
                           "maTruong": "79000904",
                           "tenTruong": "Phân hiệu ĐH Tài Chính - Marketing"
                         },
                         {
                           "maTruong": "79772901",
                           "tenTruong": "TRUNG TÂM GIÁO DỤC THƯỜNG XUYÊN QUẬN 11"
                         },
                         {
                           "maTruong": "79000836",
                           "tenTruong": "TH, THCS và THPT Trương Vĩnh Ký"
                         },
                         {
                           "maTruong": "79000911",
                           "tenTruong": "Trung tâm huấn luyện và thi đấu TDTT"
                         },
                         {
                           "maTruong": "7900004012",
                           "tenTruong": "Trường Giáo dục Chuyên biệt 15 tháng 5 (Quận 11)"
                         }
                       ]
                     },
                     {
                       "maPhong": null,
                       "tenPhong": "Quận 10",
                       "truongData": [
                         {
                           "maTruong": "79000745",
                           "tenTruong": "THPT Nguyễn An Ninh"
                         },
                         {
                           "maTruong": "79000717",
                           "tenTruong": "THPT Nguyễn Du"
                         },
                         {
                           "maTruong": "79000716",
                           "tenTruong": "THPT Nguyễn Khuyến"
                         },
                         {
                           "maTruong": "79771901",
                           "tenTruong": "TT GDTX Q10"
                         },
                         {
                           "maTruong": "79000860",
                           "tenTruong": "THCS và THPT Sương Nguyệt Anh"
                         },
                         {
                           "maTruong": "79000861",
                           "tenTruong": "THCS và THPT Diên Hồng"
                         },
                         {
                           "maTruong": "79000818",
                           "tenTruong": "Tiểu học, THCS và THPT Vạn Hạnh"
                         },
                         {
                           "maTruong": "79000C14",
                           "tenTruong": "Tiểu học, THCS và THPT QT Việt Nam"
                         },
                         {
                           "maTruong": "79000C03",
                           "tenTruong": "Tiểu học, THCS và THPT Việt Úc"
                         },
                         {
                           "maTruong": "79000841",
                           "tenTruong": "THCS và THPT Duy Tân"
                         },
                         {
                           "maTruong": "7900004010",
                           "tenTruong": "Trường Chuyên biệt Quận 10 (Quận 10)"
                         },
                         {
                           "maTruong": "7900004011",
                           "tenTruong": "Trường phổ thông đặc biệt Nguyễn Đình Chiểu (Quận 10)"
                         },
                         {
                           "maTruong": "7900004025",
                           "tenTruong": "Trưởng Chuyên biệt TT Ước Mơ (Quận 10)"
                         }
                       ]
                     },
                     {
                       "maPhong": "785",
                       "tenPhong": "Huyện Bình Chánh",
                       "truongData": [
                         {
                           "maTruong": "79785414",
                           "tenTruong": "TH An Hạ"
                         },
                         {
                           "maTruong": "79785410",
                           "tenTruong": "TH An Phú Tây"
                         },
                         {
                           "maTruong": "79785409",
                           "tenTruong": "TH Bình Chánh"
                         },
                         {
                           "maTruong": "79785401",
                           "tenTruong": "TH Bình Hưng"
                         },
                         {
                           "maTruong": "79785415",
                           "tenTruong": "TH Bình Lợi"
                         },
                         {
                           "maTruong": "79785416",
                           "tenTruong": "TH Cầu Xáng"
                         },
                         {
                           "maTruong": "79785406",
                           "tenTruong": "TH Hưng Long"
                         },
                         {
                           "maTruong": "79785404",
                           "tenTruong": "TH Nguyễn Văn Trân"
                         },
                         {
                           "maTruong": "79785413",
                           "tenTruong": "TH Phạm Văn Hai"
                         },
                         {
                           "maTruong": "79785403",
                           "tenTruong": "TH Phong Phú"
                         },
                         {
                           "maTruong": "79785405",
                           "tenTruong": "TH Qui Đức"
                         },
                         {
                           "maTruong": "79785412",
                           "tenTruong": "TH Tân Kiên"
                         },
                         {
                           "maTruong": "79785419",
                           "tenTruong": "TH Tân Nhựt"
                         },
                         {
                           "maTruong": "79785420",
                           "tenTruong": "TH Tân Nhựt 6"
                         },
                         {
                           "maTruong": "79785407",
                           "tenTruong": "TH Tân Quý Tây"
                         },
                         {
                           "maTruong": "79785408",
                           "tenTruong": "TH Tân Quý Tây 3"
                         },
                         {
                           "maTruong": "79785411",
                           "tenTruong": "TH Tân Túc"
                         },
                         {
                           "maTruong": "79785428",
                           "tenTruong": "TH Trần Nhân Tôn"
                         },
                         {
                           "maTruong": "79785429",
                           "tenTruong": "TH Trần Quốc Toản"
                         },
                         {
                           "maTruong": "79785421",
                           "tenTruong": "TH Vĩnh Lộc 1"
                         },
                         {
                           "maTruong": "79785422",
                           "tenTruong": "TH Vĩnh Lộc 2"
                         },
                         {
                           "maTruong": "79785423",
                           "tenTruong": "TH Vĩnh Lộc A"
                         },
                         {
                           "maTruong": "79785402",
                           "tenTruong": "TH Võ Văn Vân"
                         },
                         {
                           "maTruong": "79785432",
                           "tenTruong": "Tiểu học An Phú Tây 2"
                         },
                         {
                           "maTruong": "79785427",
                           "tenTruong": "Tiểu học Lại Hùng Cường"
                         },
                         {
                           "maTruong": "79785417",
                           "tenTruong": "Tiểu học Lê Minh Xuân 2"
                         },
                         {
                           "maTruong": "79785418",
                           "tenTruong": "Tiểu học Lê Minh Xuân 3"
                         },
                         {
                           "maTruong": "79785430",
                           "tenTruong": "Tiểu học Phạm Hùng"
                         },
                         {
                           "maTruong": "79785431",
                           "tenTruong": "Tiểu học Phong Phú 2"
                         },
                         {
                           "maTruong": "79785424",
                           "tenTruong": "TIỂU HỌC VĨNH LỘC B"
                         },
                         {
                           "maTruong": "79785507",
                           "tenTruong": "THCS Bình Chánh"
                         },
                         {
                           "maTruong": "79785517",
                           "tenTruong": "THCS Đa Phước"
                         },
                         {
                           "maTruong": "79785514",
                           "tenTruong": "THCS Đồng Đen"
                         },
                         {
                           "maTruong": "79785511",
                           "tenTruong": "THCS Gò Xoài"
                         },
                         {
                           "maTruong": "79785504",
                           "tenTruong": "THCS Hưng Long"
                         },
                         {
                           "maTruong": "79785512",
                           "tenTruong": "THCS Lê Minh Xuân"
                         },
                         {
                           "maTruong": "79785501",
                           "tenTruong": "THCS Nguyễn Thái Bình"
                         },
                         {
                           "maTruong": "79785506",
                           "tenTruong": "THCS NGUYỄN VĂN LINH"
                         },
                         {
                           "maTruong": "79785510",
                           "tenTruong": "THCS Phạm Văn Hai"
                         },
                         {
                           "maTruong": "79785502",
                           "tenTruong": "THCS Phong Phú"
                         },
                         {
                           "maTruong": "79785503",
                           "tenTruong": "THCS Qui Đức"
                         },
                         {
                           "maTruong": "79785509",
                           "tenTruong": "THCS Tân Kiên"
                         },
                         {
                           "maTruong": "79785518",
                           "tenTruong": "THCS Tân Nhựt"
                         },
                         {
                           "maTruong": "79785505",
                           "tenTruong": "THCS Tân Quý Tây"
                         },
                         {
                           "maTruong": "79785508",
                           "tenTruong": "THCS Tân Túc"
                         },
                         {
                           "maTruong": "79785515",
                           "tenTruong": "THCS Vĩnh Lộc A"
                         },
                         {
                           "maTruong": "79785516",
                           "tenTruong": "THCS Vĩnh Lộc B"
                         },
                         {
                           "maTruong": "79785519",
                           "tenTruong": "THCS Võ Văn Vân"
                         },
                         {
                           "maTruong": "79785601",
                           "tenTruong": "TiH - THCS Thế Giới Trẻ Em"
                         },
                         {
                           "maTruong": "79000C19",
                           "tenTruong": "TH - THCS - THPT Albert Einstein"
                         },
                         {
                           "maTruong": "79000C06",
                           "tenTruong": "Tiểu học, THCS và THPT Bắc Mỹ"
                         },
                         {
                           "maTruong": "79785205",
                           "tenTruong": "Mẫu giáo Hoa Đào"
                         },
                         {
                           "maTruong": "79785W99",
                           "tenTruong": "MG Bình Minh"
                         },
                         {
                           "maTruong": "79785206",
                           "tenTruong": "MG Hoa Phượng"
                         },
                         {
                           "maTruong": "79785202",
                           "tenTruong": "MG Sen Hồng"
                         },
                         {
                           "maTruong": "79785320",
                           "tenTruong": "MẦM NON BÔNG SEN HỒNG"
                         },
                         {
                           "maTruong": "79785330",
                           "tenTruong": "Mầm non Canada - Việt Nam"
                         },
                         {
                           "maTruong": "79785337",
                           "tenTruong": "Mầm non Hoa Hồng 2"
                         },
                         {
                           "maTruong": "79785343",
                           "tenTruong": "Mầm non Hoa Sen 2"
                         },
                         {
                           "maTruong": "79785344",
                           "tenTruong": "Mầm non Hoa Sen 3"
                         },
                         {
                           "maTruong": "79785338",
                           "tenTruong": "Mầm non Hoa Thiên Lý 2"
                         },
                         {
                           "maTruong": "79785342",
                           "tenTruong": "Mầm non Hoàng Anh 2"
                         },
                         {
                           "maTruong": "79785340",
                           "tenTruong": "Mầm non Hoàng Lam"
                         },
                         {
                           "maTruong": "79785310",
                           "tenTruong": "Mầm non Hướng Dương"
                         },
                         {
                           "maTruong": "79785336",
                           "tenTruong": "Mầm non Hướng Dương 2"
                         },
                         {
                           "maTruong": "79785345",
                           "tenTruong": "Mầm non Quỳnh Hương 2"
                         },
                         {
                           "maTruong": "79785335",
                           "tenTruong": "Mầm non Thủy Tiên 2"
                         },
                         {
                           "maTruong": "79785328",
                           "tenTruong": "Mầm non Trung Sơn"
                         },
                         {
                           "maTruong": "79785341",
                           "tenTruong": "Mầm non Vườn Trẻ Thơ"
                         },
                         {
                           "maTruong": "79785311",
                           "tenTruong": "MN 30-4"
                         },
                         {
                           "maTruong": "79785W91",
                           "tenTruong": "MN An Bình"
                         },
                         {
                           "maTruong": "79785317",
                           "tenTruong": "MN Anh Duy"
                         },
                         {
                           "maTruong": "79785303",
                           "tenTruong": "MN Ba By"
                         },
                         {
                           "maTruong": "79785W72",
                           "tenTruong": "MN Bé Thông Minh"
                         },
                         {
                           "maTruong": "79785327",
                           "tenTruong": "MN Bé Yêu"
                         },
                         {
                           "maTruong": "79785329",
                           "tenTruong": "MN Chú Ong Vàng"
                         },
                         {
                           "maTruong": "797853A1",
                           "tenTruong": "MN Hoa Anh Đào"
                         },
                         {
                           "maTruong": "79785307",
                           "tenTruong": "MN Hoa Hồng"
                         },
                         {
                           "maTruong": "79785W26",
                           "tenTruong": "MN Hoa Hồng Nhỏ"
                         },
                         {
                           "maTruong": "79785W14",
                           "tenTruong": "MN Hoa Hướng Dương"
                         },
                         {
                           "maTruong": "79785201",
                           "tenTruong": "MN Hoa Lan"
                         },
                         {
                           "maTruong": "79785301",
                           "tenTruong": "MN Hoa Mai"
                         },
                         {
                           "maTruong": "79785W09",
                           "tenTruong": "MN Họa Mi"
                         },
                         {
                           "maTruong": "79785334",
                           "tenTruong": "MN Hoa Phượng 1"
                         },
                         {
                           "maTruong": "797853A2",
                           "tenTruong": "MN Hoa Phượng Hồng"
                         },
                         {
                           "maTruong": "79785W52",
                           "tenTruong": "MN Hoa Quỳnh"
                         },
                         {
                           "maTruong": "79785305",
                           "tenTruong": "MN Hoa Sen"
                         },
                         {
                           "maTruong": "79785325",
                           "tenTruong": "MN Hoa Thiên Lý 1"
                         },
                         {
                           "maTruong": "79785318",
                           "tenTruong": "MN Hoa Thiên Tuế"
                         },
                         {
                           "maTruong": "79785WC3",
                           "tenTruong": "MN Hoàn Mỹ"
                         },
                         {
                           "maTruong": "79785309",
                           "tenTruong": "MN Hoàng Anh"
                         },
                         {
                           "maTruong": "79785W33",
                           "tenTruong": "MN Lan Nhi"
                         },
                         {
                           "maTruong": "79785W15",
                           "tenTruong": "MN Minh Thư"
                         },
                         {
                           "maTruong": "79785326",
                           "tenTruong": "MN Nam Mỹ"
                         },
                         {
                           "maTruong": "79785323",
                           "tenTruong": "MN Ngọc Lan"
                         },
                         {
                           "maTruong": "79785W05",
                           "tenTruong": "MN Ngọc Trâm"
                         },
                         {
                           "maTruong": "79785WA0",
                           "tenTruong": "MN Ngôi Sao Việt"
                         },
                         {
                           "maTruong": "79785302",
                           "tenTruong": "MN Phong Lan"
                         },
                         {
                           "maTruong": "79785339",
                           "tenTruong": "MN Quỳnh Anh"
                         },
                         {
                           "maTruong": "79785304",
                           "tenTruong": "MN Quỳnh Hương"
                         },
                         {
                           "maTruong": "79785312",
                           "tenTruong": "MN Sơn Ca"
                         },
                         {
                           "maTruong": "79785319",
                           "tenTruong": "MN Thanh Ngân"
                         },
                         {
                           "maTruong": "79785W17",
                           "tenTruong": "MN Thiên Ân"
                         },
                         {
                           "maTruong": "79785324",
                           "tenTruong": "MN Thủy Tiên 1"
                         },
                         {
                           "maTruong": "79785316",
                           "tenTruong": "MN Tuổi Ngọc"
                         },
                         {
                           "maTruong": "79785W10",
                           "tenTruong": "MN Ty Vy"
                         },
                         {
                           "maTruong": "797853A4",
                           "tenTruong": "MN Uyên Nhi"
                         },
                         {
                           "maTruong": "797853A3",
                           "tenTruong": "MN Việt Mỹ"
                         },
                         {
                           "maTruong": "79785WG5",
                           "tenTruong": "Trường Mầm Non  Mặt Trời Đỏ"
                         },
                         {
                           "maTruong": "79785W75",
                           "tenTruong": "Trường Mầm Non Hương Mai"
                         },
                         {
                           "maTruong": "79785Wk1",
                           "tenTruong": "Trường Mầm non Nắng Mai"
                         },
                         {
                           "maTruong": "79785308",
                           "tenTruong": "Trường Mầm Non Thuỷ Tiên"
                         },
                         {
                           "maTruong": "79785Wk2",
                           "tenTruong": "Trường MN Bé Vui"
                         },
                         {
                           "maTruong": "79785W90",
                           "tenTruong": "Trường MN Bé Xinh"
                         },
                         {
                           "maTruong": "79785WD3",
                           "tenTruong": "Trường MN Hoa Hồng Nhung"
                         },
                         {
                           "maTruong": "79785306",
                           "tenTruong": "Trường MN Hoa Thiên Lý"
                         },
                         {
                           "maTruong": "79785Wm8",
                           "tenTruong": "Lớp Mầm non Bé Thiên Thần"
                         },
                         {
                           "maTruong": "79785Wn1",
                           "tenTruong": "Lớp Mầm non Hoàng Yến"
                         },
                         {
                           "maTruong": "79785Wh5",
                           "tenTruong": "Lớp Mầm Non Thảo My"
                         },
                         {
                           "maTruong": "79785W43",
                           "tenTruong": "Lớp Mẩu Giáo  Anh Thư"
                         },
                         {
                           "maTruong": "79785Wm9",
                           "tenTruong": "Lớp Mẫu Giáo Á Châu 2"
                         },
                         {
                           "maTruong": "79785Wn3",
                           "tenTruong": "Lớp Mẫu giáo Anh Kiệt"
                         },
                         {
                           "maTruong": "79785Wn5",
                           "tenTruong": "Lớp Mẫu giáo Ánh Sao Vàng"
                         },
                         {
                           "maTruong": "79785W94",
                           "tenTruong": "Lớp Mẫu giáo Hồng Sâm"
                         },
                         {
                           "maTruong": "79785Wn2",
                           "tenTruong": "Lớp Mẫu giáo Ka Ly"
                         },
                         {
                           "maTruong": "79785Wm7",
                           "tenTruong": "Lớp Mẫu giáo Ngôi Sao"
                         },
                         {
                           "maTruong": "79785Wn6",
                           "tenTruong": "Lớp Mẫu giáo Tâm Tâm"
                         },
                         {
                           "maTruong": "79785Wn4",
                           "tenTruong": "Lớp Mẫu Giáo Thần Đồng"
                         },
                         {
                           "maTruong": "79785W96",
                           "tenTruong": "Lớp mẫu giáo Thảo Quyên"
                         },
                         {
                           "maTruong": "79785W50",
                           "tenTruong": "Lớp Mẫu Giáo Thỏ Nâu"
                         },
                         {
                           "maTruong": "79785Wq8",
                           "tenTruong": "Lớp MG An Hạ"
                         },
                         {
                           "maTruong": "79785W49",
                           "tenTruong": "Lớp MG Ánh Nhi"
                         },
                         {
                           "maTruong": "79785W84",
                           "tenTruong": "Lớp MG Ánh Sao"
                         },
                         {
                           "maTruong": "79785Wh9",
                           "tenTruong": "Lớp MG Anh Vân"
                         },
                         {
                           "maTruong": "79785Wp1",
                           "tenTruong": "Lớp MG Bé Ngôi Sao"
                         },
                         {
                           "maTruong": "79785Wp8",
                           "tenTruong": "Lớp MG Bình An"
                         },
                         {
                           "maTruong": "79785Wf8",
                           "tenTruong": "Lớp MG Đăng Khoa"
                         },
                         {
                           "maTruong": "79785Wq5",
                           "tenTruong": "Lớp MG Hạnh Phúc"
                         },
                         {
                           "maTruong": "79785WC1",
                           "tenTruong": "Lớp MG Hoa Lài"
                         },
                         {
                           "maTruong": "79785Wp3",
                           "tenTruong": "Lớp MG Hoa Mặt Trời"
                         },
                         {
                           "maTruong": "79785322",
                           "tenTruong": "LỚP MG Hương Dưa"
                         },
                         {
                           "maTruong": "79785Wi8",
                           "tenTruong": "Lớp MG Hương Thảo"
                         },
                         {
                           "maTruong": "79785Wp9",
                           "tenTruong": "Lớp MG Kiều Châu"
                         },
                         {
                           "maTruong": "79785Wq4",
                           "tenTruong": "Lớp MG Kim Anh"
                         },
                         {
                           "maTruong": "79785Wq7",
                           "tenTruong": "Lớp MG Mầm Xanh"
                         },
                         {
                           "maTruong": "79785Wm1",
                           "tenTruong": "Lớp MG Mây Hồng"
                         },
                         {
                           "maTruong": "79785W98",
                           "tenTruong": "Lớp MG Minh Anh"
                         },
                         {
                           "maTruong": "79785Wp7",
                           "tenTruong": "Lớp MG Nắng Vàng"
                         },
                         {
                           "maTruong": "79785Wq6",
                           "tenTruong": "Lớp MG Ngôi Sao Xanh"
                         },
                         {
                           "maTruong": "79785Wh7",
                           "tenTruong": "Lớp MG Nhân Ái"
                         },
                         {
                           "maTruong": "79785Wq2",
                           "tenTruong": "Lớp MG Sao Mai"
                         },
                         {
                           "maTruong": "79785WA4",
                           "tenTruong": "Lớp MG Sóc Nâu"
                         },
                         {
                           "maTruong": "79785Wg6",
                           "tenTruong": "Lớp MG Tân Kiên"
                         },
                         {
                           "maTruong": "79785Wg4",
                           "tenTruong": "Lớp MG Thanh Nghi"
                         },
                         {
                           "maTruong": "79785W83",
                           "tenTruong": "Lớp MG Thế Giới Tuổi Thần Tiên"
                         },
                         {
                           "maTruong": "79785Wq3",
                           "tenTruong": "Lớp MG Thiên An"
                         },
                         {
                           "maTruong": "79785Wq1",
                           "tenTruong": "Lớp MG Thiên Thần Nhí"
                         },
                         {
                           "maTruong": "79785Wi2",
                           "tenTruong": "Lớp MG Trâm Anh"
                         },
                         {
                           "maTruong": "79785W93",
                           "tenTruong": "Lớp MG Trúc Lam"
                         },
                         {
                           "maTruong": "79785W11",
                           "tenTruong": "Lớp MG Tuổi Thơ"
                         },
                         {
                           "maTruong": "79785WD8",
                           "tenTruong": "Lớp MN ABC"
                         },
                         {
                           "maTruong": "79785WD2",
                           "tenTruong": "Lớp MN Ánh Ban Mai"
                         },
                         {
                           "maTruong": "79785WC6",
                           "tenTruong": "Lớp MN Ánh Bình Minh"
                         },
                         {
                           "maTruong": "79785W61",
                           "tenTruong": "Lớp MN Anh Đào"
                         },
                         {
                           "maTruong": "79785W62",
                           "tenTruong": "Lớp MN Ánh Hồng"
                         },
                         {
                           "maTruong": "79785W18",
                           "tenTruong": "Lớp MN Anh Huy"
                         },
                         {
                           "maTruong": "79785Wg1",
                           "tenTruong": "Lớp MN Ánh Mai"
                         },
                         {
                           "maTruong": "79785WB0",
                           "tenTruong": "Lớp MN Ánh Minh"
                         },
                         {
                           "maTruong": "79785W89",
                           "tenTruong": "Lớp MN Anh Việt"
                         },
                         {
                           "maTruong": "79785W41",
                           "tenTruong": "Lớp MN Anh Vy"
                         },
                         {
                           "maTruong": "79785WB3",
                           "tenTruong": "Lớp MN Bảo Ngọc"
                         },
                         {
                           "maTruong": "79785WB5",
                           "tenTruong": "Lớp MN Bảo Thư"
                         },
                         {
                           "maTruong": "79785W34",
                           "tenTruong": "Lớp MN Bé Ngoan"
                         },
                         {
                           "maTruong": "79785WE4",
                           "tenTruong": "Lớp MN Bi Bon"
                         },
                         {
                           "maTruong": "79785W56",
                           "tenTruong": "Lớp MN Bích Chi"
                         },
                         {
                           "maTruong": "79785WB7",
                           "tenTruong": "Lớp MN Bồ Câu Trắng"
                         },
                         {
                           "maTruong": "79785W67",
                           "tenTruong": "Lớp MN Bông Sen"
                         },
                         {
                           "maTruong": "79785W65",
                           "tenTruong": "Lớp MN Búp Măng"
                         },
                         {
                           "maTruong": "79785W28",
                           "tenTruong": "Lớp MN Búp Sen"
                         },
                         {
                           "maTruong": "79785W12",
                           "tenTruong": "Lớp MN Cẩm Tú"
                         },
                         {
                           "maTruong": "79785W95",
                           "tenTruong": "Lớp MN Cánh Cam"
                         },
                         {
                           "maTruong": "79785WC9",
                           "tenTruong": "Lớp MN Cát Tiên"
                         },
                         {
                           "maTruong": "79785Wi9",
                           "tenTruong": "Lớp MN Cầu Vồng"
                         },
                         {
                           "maTruong": "79785WB4",
                           "tenTruong": "Lớp MN Chồi Non"
                         },
                         {
                           "maTruong": "79785WC0",
                           "tenTruong": "Lớp MN Diệu Thương"
                         },
                         {
                           "maTruong": "79785W80",
                           "tenTruong": "Lớp MN Đồ Rê Mí"
                         },
                         {
                           "maTruong": "79785Wm4",
                           "tenTruong": "Lớp MN Duyên Hồng"
                         },
                         {
                           "maTruong": "79785Wg8",
                           "tenTruong": "Lớp MN Gấu Trúc"
                         },
                         {
                           "maTruong": "79785W42",
                           "tenTruong": "Lớp MN Gia Huy"
                         },
                         {
                           "maTruong": "79785W97",
                           "tenTruong": "Lớp MN Hải Âu"
                         },
                         {
                           "maTruong": "79785WB9",
                           "tenTruong": "Lớp MN Hoa Anh Đào"
                         },
                         {
                           "maTruong": "79785WB2",
                           "tenTruong": "Lớp MN Hoa Cát Tường"
                         },
                         {
                           "maTruong": "79785W87",
                           "tenTruong": "Lớp MN Hoa Cúc Vàng"
                         },
                         {
                           "maTruong": "79785WB1",
                           "tenTruong": "Lớp MN Hoa Cúc vàng 2"
                         },
                         {
                           "maTruong": "79785WD5",
                           "tenTruong": "Lớp MN Hoa Mận"
                         },
                         {
                           "maTruong": "79785WD0",
                           "tenTruong": "Lớp MN Họa Mi 2"
                         },
                         {
                           "maTruong": "79785WD4",
                           "tenTruong": "Lớp MN Hoa Phượng Đỏ"
                         },
                         {
                           "maTruong": "79785W03",
                           "tenTruong": "Lớp MN Hoa Sữa"
                         },
                         {
                           "maTruong": "79785Wk3",
                           "tenTruong": "Lớp MN Hoa Vàng"
                         },
                         {
                           "maTruong": "79785W55",
                           "tenTruong": "Lớp MN Hoàng Linh"
                         },
                         {
                           "maTruong": "79785Wg2",
                           "tenTruong": "Lớp MN Hoàng Minh"
                         },
                         {
                           "maTruong": "79785Wh8",
                           "tenTruong": "Lớp MN Hoàng My"
                         },
                         {
                           "maTruong": "79785W46",
                           "tenTruong": "Lớp MN Hồng Cúc"
                         },
                         {
                           "maTruong": "79785Wi1",
                           "tenTruong": "Lớp MN Hồng Phượng"
                         },
                         {
                           "maTruong": "79785Wi3",
                           "tenTruong": "Lớp MN Hương Giang"
                         },
                         {
                           "maTruong": "79785WA2",
                           "tenTruong": "Lớp MN Hương Hướng Dương"
                         },
                         {
                           "maTruong": "79785W27",
                           "tenTruong": "Lớp MN Hương Sen"
                         },
                         {
                           "maTruong": "79785Wg3",
                           "tenTruong": "Lớp MN Hương Xuân"
                         },
                         {
                           "maTruong": "79785W38",
                           "tenTruong": "Lớp MN Kim Ngân"
                         },
                         {
                           "maTruong": "79785W82",
                           "tenTruong": "Lớp MN Kính Vạn Hoa"
                         },
                         {
                           "maTruong": "79785W78",
                           "tenTruong": "Lớp MN Lan Vy"
                         },
                         {
                           "maTruong": "79785W07",
                           "tenTruong": "Lớp MN Liên Hương"
                         },
                         {
                           "maTruong": "79785W64",
                           "tenTruong": "Lớp MN Mai Anh"
                         },
                         {
                           "maTruong": "79785W29",
                           "tenTruong": "Lớp MN Mai Khôi"
                         },
                         {
                           "maTruong": "79785W76",
                           "tenTruong": "Lớp MN Mai Phương"
                         },
                         {
                           "maTruong": "79785WA1",
                           "tenTruong": "Lớp MN Mèo Kity"
                         },
                         {
                           "maTruong": "79785Wm3",
                           "tenTruong": "Lớp MN MicKey"
                         },
                         {
                           "maTruong": "79785Wi7",
                           "tenTruong": "Lớp MN Mơ Ước"
                         },
                         {
                           "maTruong": "79785WD6",
                           "tenTruong": "Lớp MN My My 2"
                         },
                         {
                           "maTruong": "79785W13",
                           "tenTruong": "Lớp MN Nai Ngọc"
                         },
                         {
                           "maTruong": "79785W31",
                           "tenTruong": "Lớp MN Nắng Hồng"
                         },
                         {
                           "maTruong": "79785WB8",
                           "tenTruong": "Lớp MN Nắng Sớm"
                         },
                         {
                           "maTruong": "79785Wm2",
                           "tenTruong": "Lớp MN Ngân Thanh"
                         },
                         {
                           "maTruong": "79785WD1",
                           "tenTruong": "Lớp MN Ngày Mới"
                         },
                         {
                           "maTruong": "79785W32",
                           "tenTruong": "Lớp MN Phúc Nguyên"
                         },
                         {
                           "maTruong": "79785WB6",
                           "tenTruong": "Lớp MN Phương Đông Phương"
                         },
                         {
                           "maTruong": "79785W68",
                           "tenTruong": "Lớp MN Phượng Hồng"
                         },
                         {
                           "maTruong": "79785W51",
                           "tenTruong": "Lớp MN Phượng Vĩ"
                         },
                         {
                           "maTruong": "79785WD7",
                           "tenTruong": "Lớp MN Quỳnh Chi"
                         },
                         {
                           "maTruong": "79785W86",
                           "tenTruong": "Lớp MN Quỳnh Lan"
                         },
                         {
                           "maTruong": "79785Wi6",
                           "tenTruong": "Lớp MN Ru Bi"
                         },
                         {
                           "maTruong": "79785WE3",
                           "tenTruong": "Lớp MN Sắc Màu"
                         },
                         {
                           "maTruong": "79785WE2",
                           "tenTruong": "Lớp MN Sao Kim"
                         },
                         {
                           "maTruong": "79785WC4",
                           "tenTruong": "Lớp MN Sen Việt"
                         },
                         {
                           "maTruong": "79785W47",
                           "tenTruong": "Lớp MN Sơn Nguyên"
                         },
                         {
                           "maTruong": "79785Wm6",
                           "tenTruong": "Lớp MN Song Nhi"
                         },
                         {
                           "maTruong": "79785Wh3",
                           "tenTruong": "Lớp MN Tân Việt Mỹ"
                         },
                         {
                           "maTruong": "79785Wg9",
                           "tenTruong": "Lớp MN Thành Tâm"
                         },
                         {
                           "maTruong": "79785WD9",
                           "tenTruong": "Lớp MN Thảo Nguyên"
                         },
                         {
                           "maTruong": "79785W06",
                           "tenTruong": "Lớp MN Thiên Hoa"
                         },
                         {
                           "maTruong": "79785W44",
                           "tenTruong": "Lớp MN Thiên Nga"
                         },
                         {
                           "maTruong": "79785Wh6",
                           "tenTruong": "Lớp MN Thiên Ngọc"
                         },
                         {
                           "maTruong": "79785W24",
                           "tenTruong": "Lớp MN Thiên Thần"
                         },
                         {
                           "maTruong": "79785W22",
                           "tenTruong": "Lớp MN Thiên Thần Nhỏ"
                         },
                         {
                           "maTruong": "79785W40",
                           "tenTruong": "Lớp MN Thỏ Ngọc"
                         },
                         {
                           "maTruong": "79785WA6",
                           "tenTruong": "Lớp MN Tin Tin"
                         },
                         {
                           "maTruong": "79785W79",
                           "tenTruong": "Lớp MN Trẻ Thơ Việt"
                         },
                         {
                           "maTruong": "79785WC5",
                           "tenTruong": "Lớp MN TuLip"
                         },
                         {
                           "maTruong": "79785W74",
                           "tenTruong": "Lớp MN Tuổi Hồng"
                         },
                         {
                           "maTruong": "79785W73",
                           "tenTruong": "Lớp MN Tuổi Tiên"
                         },
                         {
                           "maTruong": "79785WE0",
                           "tenTruong": "Lớp MN Tường Vy"
                         },
                         {
                           "maTruong": "79785Wh4",
                           "tenTruong": "Lớp MN Ước Mơ"
                         },
                         {
                           "maTruong": "79785W66",
                           "tenTruong": "Lớp MN Vân Anh"
                         },
                         {
                           "maTruong": "79785W60",
                           "tenTruong": "Lớp MN Vàng Anh"
                         },
                         {
                           "maTruong": "79785W25",
                           "tenTruong": "Lớp MN Vành Khuyên"
                         },
                         {
                           "maTruong": "79785W81",
                           "tenTruong": "Lớp MN Việt Anh"
                         },
                         {
                           "maTruong": "79785Wh2",
                           "tenTruong": "Lớp MN Việt Nhật"
                         },
                         {
                           "maTruong": "79785WA3",
                           "tenTruong": "Lớp MN Việt Phúc"
                         },
                         {
                           "maTruong": "79785W85",
                           "tenTruong": "Lớp MN Vĩnh Lộc"
                         },
                         {
                           "maTruong": "79785WA5",
                           "tenTruong": "Lớp MN Vườn Hồng"
                         },
                         {
                           "maTruong": "79785WA7",
                           "tenTruong": "Lớp MN Vườn Thơm"
                         },
                         {
                           "maTruong": "79785W37",
                           "tenTruong": "Lớp MN Yến Vân"
                         },
                         {
                           "maTruong": "79785Wn7",
                           "tenTruong": "Mầm non Mặt Trời Nhỏ"
                         },
                         {
                           "maTruong": "79785Wn9",
                           "tenTruong": "Mẫu giáo Thiên Kim"
                         },
                         {
                           "maTruong": "79785Wp5",
                           "tenTruong": "MG Nụ Cười Hồng"
                         },
                         {
                           "maTruong": "79785Wp4",
                           "tenTruong": "MG Tâm An"
                         },
                         {
                           "maTruong": "79785Wp6",
                           "tenTruong": "MG Tân Bình Minh"
                         },
                         {
                           "maTruong": "79785Wi5",
                           "tenTruong": "MN Á Châu"
                         },
                         {
                           "maTruong": "79785Wf7",
                           "tenTruong": "MN Bi Bi"
                         },
                         {
                           "maTruong": "79785Wf5",
                           "tenTruong": "MN Bông Hoa Nhỏ"
                         },
                         {
                           "maTruong": "79785Wf3",
                           "tenTruong": "MN Khai Trí"
                         },
                         {
                           "maTruong": "79785Wf6",
                           "tenTruong": "MN Lan Anh"
                         },
                         {
                           "maTruong": "79785We5",
                           "tenTruong": "MN Liên Mỹ"
                         },
                         {
                           "maTruong": "79785We8",
                           "tenTruong": "MN Mai Thanh"
                         },
                         {
                           "maTruong": "79785Wf4",
                           "tenTruong": "MN Tấn Phát"
                         },
                         {
                           "maTruong": "79785Wf2",
                           "tenTruong": "MN Trí Việt"
                         },
                         {
                           "maTruong": "79785We6",
                           "tenTruong": "MN Trúc Xanh"
                         },
                         {
                           "maTruong": "79785We9",
                           "tenTruong": "MN Việt Úc"
                         },
                         {
                           "maTruong": "79785Wf1",
                           "tenTruong": "MN Vườn Nai"
                         },
                         {
                           "maTruong": "79785W36",
                           "tenTruong": "Nhom MN Mi Mi"
                         },
                         {
                           "maTruong": "79785Wf9",
                           "tenTruong": "Nhóm Trẻ Gia Ơn"
                         },
                         {
                           "maTruong": "79785WI4",
                           "tenTruong": "Nhóm trẻ Hương Thuỷ"
                         },
                         {
                           "maTruong": "79785We7",
                           "tenTruong": "NTĐL Sao Vàng"
                         },
                         {
                           "maTruong": "79785348",
                           "tenTruong": "MN Tuổi Thơ Kỳ Diệu"
                         },
                         {
                           "maTruong": "79785349",
                           "tenTruong": "MN Ngôi Nhà Trẻ Thơ"
                         },
                         {
                           "maTruong": "79785350",
                           "tenTruong": "MN Hành Tinh Trẻ"
                         },
                         {
                           "maTruong": "79785Wt5",
                           "tenTruong": "Lớp MG Hoàng My 2"
                         },
                         {
                           "maTruong": "79785Wt6",
                           "tenTruong": "Nhóm trẻ Hoàng My 1"
                         },
                         {
                           "maTruong": "79785Wt7",
                           "tenTruong": "LỚP MG Sơn Cát"
                         },
                         {
                           "maTruong": "79785Wt8",
                           "tenTruong": "Lớp MG Hươu Sao"
                         },
                         {
                           "maTruong": "79785Wt9",
                           "tenTruong": "Nhóm trẻ Vườn Mặt Trời"
                         },
                         {
                           "maTruong": "79785Wr1",
                           "tenTruong": "Lớp MG Hoa Cúc"
                         },
                         {
                           "maTruong": "79785Wr2",
                           "tenTruong": "Lớp MG Trí Việt 1"
                         },
                         {
                           "maTruong": "79785Wr3",
                           "tenTruong": "Lớp MG Gấu MíSa"
                         },
                         {
                           "maTruong": "79785Wt4",
                           "tenTruong": "Lớp MG Thiên Anh"
                         },
                         {
                           "maTruong": "79785Ws1",
                           "tenTruong": "Lớp MG Trăng Nhỏ"
                         },
                         {
                           "maTruong": "79785Ws4",
                           "tenTruong": "Lớp MG Thỏ Con"
                         },
                         {
                           "maTruong": "79785Ws9",
                           "tenTruong": "Lớp MG Lá Sen"
                         },
                         {
                           "maTruong": "79785Wt1",
                           "tenTruong": "Lớp MG Ánh Dương"
                         },
                         {
                           "maTruong": "79785Ws3",
                           "tenTruong": "Lớp MG Măng Non"
                         },
                         {
                           "maTruong": "79785Wt2",
                           "tenTruong": "Lớp MG Thông Minh"
                         },
                         {
                           "maTruong": "79785Wt3",
                           "tenTruong": "Nhóm trẻ Hoa Phượng Vĩ"
                         },
                         {
                           "maTruong": "79785Wq9",
                           "tenTruong": "Lớp MG Hải Yến"
                         },
                         {
                           "maTruong": "79785Ws5",
                           "tenTruong": "Lớp MG Hồng Ân"
                         },
                         {
                           "maTruong": "79785Ws7",
                           "tenTruong": "Lớp MG Ngọc Bích"
                         },
                         {
                           "maTruong": "79785347",
                           "tenTruong": "MN Việt Mỹ 3"
                         },
                         {
                           "maTruong": "79785346",
                           "tenTruong": "MN Ngôi Nhà Hạnh Phúc"
                         },
                         {
                           "maTruong": "79785Ws6",
                           "tenTruong": "Lop MG Ngôi Sao Vàng"
                         },
                         {
                           "maTruong": "79785Ws8",
                           "tenTruong": "Lớp MG Khánh Ngân"
                         },
                         {
                           "maTruong": "79785435",
                           "tenTruong": "TH Việt Mỹ"
                         },
                         {
                           "maTruong": "79785434",
                           "tenTruong": "TH Huỳnh Văn Bánh"
                         },
                         {
                           "maTruong": "79785Wr5",
                           "tenTruong": "Lớp MG Vườn Hoa"
                         },
                         {
                           "maTruong": "79785Wu1",
                           "tenTruong": "Lớp MG Hoa Tường Vi"
                         },
                         {
                           "maTruong": "79785352",
                           "tenTruong": "MN Việt Mỹ 4"
                         },
                         {
                           "maTruong": "79785Wu2",
                           "tenTruong": "Lớp MG Thiên An 2"
                         },
                         {
                           "maTruong": "79785Wu3",
                           "tenTruong": "Lớp MG Bé Ngoan 1"
                         },
                         {
                           "maTruong": "79785451",
                           "tenTruong": "MN Vườn Mặt Trời 2"
                         },
                         {
                           "maTruong": "79785Wr4",
                           "tenTruong": "Lớp MG Hoa Nhi Lam"
                         },
                         {
                           "maTruong": "79785436",
                           "tenTruong": "TH Kim Đồng"
                         },
                         {
                           "maTruong": "79785433",
                           "tenTruong": "TH Tân Túc 2"
                         },
                         {
                           "maTruong": "7978501001",
                           "tenTruong": "Nhóm trẻ Thỏ Trắng"
                         },
                         {
                           "maTruong": "7978501003",
                           "tenTruong": "Lớp MG Sen Vàng"
                         },
                         {
                           "maTruong": "7978501004",
                           "tenTruong": "Lớp MG Thiên Đức"
                         },
                         {
                           "maTruong": "7978501005",
                           "tenTruong": "Nhóm trẻ An Hạ 2"
                         },
                         {
                           "maTruong": "79785267",
                           "tenTruong": "Lớp MG Thành Đô"
                         },
                         {
                           "maTruong": "79785254",
                           "tenTruong": "Lớp MG Tia Sáng"
                         },
                         {
                           "maTruong": "79785060",
                           "tenTruong": "Lớp MG Tuổi Thơ Xanh"
                         },
                         {
                           "maTruong": "79785237",
                           "tenTruong": "Lớp MG Vườn Họa Mi"
                         },
                         {
                           "maTruong": "79785003",
                           "tenTruong": "Lớp MG Vườn Họa Mi 2"
                         },
                         {
                           "maTruong": "79785284",
                           "tenTruong": "Lớp Mẫu giáo Hoạ Mi 3"
                         },
                         {
                           "maTruong": "79785467",
                           "tenTruong": "Trường TH Lương Thế Vinh"
                         },
                         {
                           "maTruong": "79785181",
                           "tenTruong": "Lớp MG Ánh Sáng"
                         },
                         {
                           "maTruong": "79785069",
                           "tenTruong": "Lớp MG Cây Con"
                         },
                         {
                           "maTruong": "7978501002",
                           "tenTruong": "Nhóm trẻ Song Nhi 2"
                         },
                         {
                           "maTruong": "7978501006",
                           "tenTruong": "MN Ngôi Sao Tương Lai"
                         },
                         {
                           "maTruong": "7978502001",
                           "tenTruong": "Trường TH Phong Phú 5"
                         },
                         {
                           "maTruong": "79785143",
                           "tenTruong": "Lớp MG Hoa Mặt Trời"
                         },
                         {
                           "maTruong": "79785035",
                           "tenTruong": "Nhóm trẻ Hương Sen"
                         },
                         {
                           "maTruong": "79785072",
                           "tenTruong": "Lớp MG Kim Anh 2"
                         },
                         {
                           "maTruong": "79785128",
                           "tenTruong": "Lớp MG Thần Đồng Việt"
                         },
                         {
                           "maTruong": "79785216",
                           "tenTruong": "Nhóm trẻ Cầu Vồng"
                         },
                         {
                           "maTruong": "7978501008",
                           "tenTruong": "Lớp MG Hoa Anh Đào Nhỏ"
                         },
                         {
                           "maTruong": "7978501009",
                           "tenTruong": "Lớp MG Yến Mai"
                         },
                         {
                           "maTruong": "7978501010",
                           "tenTruong": "Lớp MG Bông Hồng Nhỏ"
                         },
                         {
                           "maTruong": "7978501011",
                           "tenTruong": "Lớp MG Chú Rồng Nhỏ"
                         },
                         {
                           "maTruong": "7978501012",
                           "tenTruong": "Lớp MG Hoa Phượng Vĩ"
                         },
                         {
                           "maTruong": "7978501007",
                           "tenTruong": "MN Tuệ Đức"
                         },
                         {
                           "maTruong": "7978501023",
                           "tenTruong": "Lớp MN  Ngôi Sao Nhỏ"
                         },
                         {
                           "maTruong": "7978501021",
                           "tenTruong": "Lớp MN Em Bé Hạnh Phúc"
                         },
                         {
                           "maTruong": "7978501022",
                           "tenTruong": "Lớp MN Vui Vẻ"
                         },
                         {
                           "maTruong": "7978501017",
                           "tenTruong": "Lớp MN Hoa Anh Đào Nhỏ"
                         },
                         {
                           "maTruong": "7978501018",
                           "tenTruong": "Lớp MG mặt Trời Bé Thơ"
                         },
                         {
                           "maTruong": "7978501019",
                           "tenTruong": "Lớp MN Tường Vy"
                         },
                         {
                           "maTruong": "7978501020",
                           "tenTruong": "Lớp MG Ngân Thanh"
                         },
                         {
                           "maTruong": "7978502002",
                           "tenTruong": "Trường Tiểu học Lê Quý Đôn"
                         },
                         {
                           "maTruong": "7978501013",
                           "tenTruong": "MN Vàng Anh"
                         },
                         {
                           "maTruong": "7978501014",
                           "tenTruong": "Lớp MG Sao Sáng"
                         },
                         {
                           "maTruong": "7978501015",
                           "tenTruong": "Lớp MN Mây Trắng"
                         },
                         {
                           "maTruong": "7978501016",
                           "tenTruong": "Lớp MN Hồng Hạc"
                         },
                         {
                           "maTruong": "7978502003",
                           "tenTruong": "Trường TH Nguyễn Bỉnh Khiêm"
                         },
                         {
                           "maTruong": "7978501034",
                           "tenTruong": "Nhóm trẻ Tulip"
                         },
                         {
                           "maTruong": "7978501025",
                           "tenTruong": "Lớp MN Him Lam"
                         },
                         {
                           "maTruong": "7978501026",
                           "tenTruong": "Lớp MN Cây Con 3"
                         },
                         {
                           "maTruong": "7978501027",
                           "tenTruong": "Lớp MN Phú Hòa"
                         },
                         {
                           "maTruong": "7978501028",
                           "tenTruong": "Lớp MN Mặt Trời"
                         },
                         {
                           "maTruong": "7978501029",
                           "tenTruong": "Lớp MG Ngọc Yến"
                         },
                         {
                           "maTruong": "7978501030",
                           "tenTruong": "Lớp MN An Bình 1"
                         },
                         {
                           "maTruong": "7978501031",
                           "tenTruong": "Lớp MG Hoàng Linh 2"
                         },
                         {
                           "maTruong": "7978501032",
                           "tenTruong": "Lớp MN Ngọc Trúc"
                         },
                         {
                           "maTruong": "7978501033",
                           "tenTruong": "Lớp MN Ngôi Sao Việt"
                         },
                         {
                           "maTruong": "7978501024",
                           "tenTruong": "MN Bình Hưng"
                         },
                         {
                           "maTruong": "7978503001",
                           "tenTruong": "THCS Trung Sơn"
                         },
                         {
                           "maTruong": "7978502004",
                           "tenTruong": "TH Rạch Già"
                         },
                         {
                           "maTruong": "7978501058",
                           "tenTruong": "Lớp MG Hoàng Gia"
                         },
                         {
                           "maTruong": "7978501059",
                           "tenTruong": "Lớp MN Hoa Cát Tường"
                         },
                         {
                           "maTruong": "7978501060",
                           "tenTruong": "Lớp MN Vườn Cổ Tích 2"
                         },
                         {
                           "maTruong": "7978501056",
                           "tenTruong": "Lớp MN Cầu Vồng Nhỏ"
                         },
                         {
                           "maTruong": "7978501057",
                           "tenTruong": "Lớp MN Ngôi Nhà Ong Xinh"
                         },
                         {
                           "maTruong": "7978501055",
                           "tenTruong": "Lớp MN Sao Mai"
                         },
                         {
                           "maTruong": "7978501035",
                           "tenTruong": "Nhóm trẻ Thế giới sáng tạo"
                         },
                         {
                           "maTruong": "7978501036",
                           "tenTruong": "Lớp MN Tuổi Thơ Hồng"
                         },
                         {
                           "maTruong": "7978501037",
                           "tenTruong": "Lớp MG Tương Lai"
                         },
                         {
                           "maTruong": "7978501038",
                           "tenTruong": "Lớp MG Mơ Ước"
                         },
                         {
                           "maTruong": "7978501039",
                           "tenTruong": "MN Đa Phước"
                         },
                         {
                           "maTruong": "7978501040",
                           "tenTruong": "Lớp MN Thiên Ân"
                         },
                         {
                           "maTruong": "7978501041",
                           "tenTruong": "Lớp MN Phước Ân"
                         },
                         {
                           "maTruong": "7978501042",
                           "tenTruong": "Lớp MN Tuổi Thơ Xanh 2"
                         },
                         {
                           "maTruong": "7978501043",
                           "tenTruong": "Lớp MG Ánh Ban Mai"
                         },
                         {
                           "maTruong": "7978501044",
                           "tenTruong": "Lớp MG Làng Sen"
                         },
                         {
                           "maTruong": "7978501045",
                           "tenTruong": "Lớp MG Sen Việt 2"
                         },
                         {
                           "maTruong": "7978501046",
                           "tenTruong": "Lớp MN Ngôi Nhà Ong Xinh 2"
                         },
                         {
                           "maTruong": "7978501047",
                           "tenTruong": "Lớp MN Thiên Phúc 4"
                         },
                         {
                           "maTruong": "7978501049",
                           "tenTruong": "Lớp MN Ngôi Sao Lấp Lánh"
                         },
                         {
                           "maTruong": "7978501050",
                           "tenTruong": "Lớp MN Song Nguyên"
                         },
                         {
                           "maTruong": "7978501051",
                           "tenTruong": "Lớp MN Ngôi Nhà Nhỏ"
                         },
                         {
                           "maTruong": "7978501052",
                           "tenTruong": "Lớp MN Ánh Bình Minh C"
                         },
                         {
                           "maTruong": "7978501053",
                           "tenTruong": "Lớp MN Chú Ong Vàng 1"
                         },
                         {
                           "maTruong": "7978501054",
                           "tenTruong": "Lớp MG Chú Ong Vàng 2"
                         },
                         {
                           "maTruong": "7978503002",
                           "tenTruong": "THCS Nguyễn Hữu Trí"
                         },
                         {
                           "maTruong": "7978501062",
                           "tenTruong": "Lớp MG Tuổi Thần Tiên"
                         },
                         {
                           "maTruong": "7978501061",
                           "tenTruong": "Lớp MN Vương Quốc Sáng Tạo"
                         }
                       ]
                     },
                     {
                       "maPhong": null,
                       "tenPhong": "Quận 12",
                       "truongData": [
                         {
                           "maTruong": "79000804",
                           "tenTruong": "THPT Thạnh Lộc"
                         },
                         {
                           "maTruong": "79000755",
                           "tenTruong": "THPT Trường Chinh"
                         },
                         {
                           "maTruong": "79000771",
                           "tenTruong": "THPT Việt Âu"
                         },
                         {
                           "maTruong": "79000719",
                           "tenTruong": "THPT Võ Trường Toản"
                         },
                         {
                           "maTruong": "79761901",
                           "tenTruong": "TT GDTX Q 12"
                         },
                         {
                           "maTruong": "79000824",
                           "tenTruong": "THCS và THPT Bắc Sơn"
                         },
                         {
                           "maTruong": "79000865",
                           "tenTruong": "THCS và THPT Hoa Lư"
                         },
                         {
                           "maTruong": "79000C12",
                           "tenTruong": "Tiểu học, THCS và THPT Mỹ Việt"
                         },
                         {
                           "maTruong": "79000826",
                           "tenTruong": "THCS và THPT Bạch Đằng"
                         },
                         {
                           "maTruong": "79000791",
                           "tenTruong": "THCS và THPT Phùng Hưng"
                         },
                         {
                           "maTruong": "79000857",
                           "tenTruong": "THCS  VÀ THPT Ngọc Viễn Đông"
                         },
                         {
                           "maTruong": "79000811",
                           "tenTruong": "TH, THCS, THPT Tuệ Đức"
                         },
                         {
                           "maTruong": "79000783",
                           "tenTruong": "THPT Đông Dương"
                         },
                         {
                           "maTruong": "7900004013",
                           "tenTruong": "Trường Chuyên biệt Ánh Dương (Quận 12)"
                         },
                         {
                           "maTruong": "79000854",
                           "tenTruong": "THCS và THPT Lạc Hồng"
                         },
                         {
                           "maTruong": "7900004035",
                           "tenTruong": "THPT Đào Duy Từ"
                         },
                         {
                           "maTruong": "7900003003",
                           "tenTruong": "THCS và THPT Hàn Việt"
                         },
                         {
                           "maTruong": "qitech",
                           "tenTruong": "MN-TH-THCS-THPT Quảng Ích"
                         }
                       ]
                     },
                     {
                       "maPhong": "768",
                       "tenPhong": "Quận Phú Nhuận",
                       "truongData": [
                         {
                           "maTruong": "79768403",
                           "tenTruong": "TH Cao Bá Quát"
                         },
                         {
                           "maTruong": "79768409",
                           "tenTruong": "TH Chí Linh"
                         },
                         {
                           "maTruong": "79768402",
                           "tenTruong": "TH Cổ Loa"
                         },
                         {
                           "maTruong": "79768406",
                           "tenTruong": "TH Đặng Văn Ngữ"
                         },
                         {
                           "maTruong": "79768404",
                           "tenTruong": "TH Đông Ba"
                         },
                         {
                           "maTruong": "79768414",
                           "tenTruong": "TH Hồ Văn Huê"
                         },
                         {
                           "maTruong": "79768412",
                           "tenTruong": "TH Lê Đình Chinh"
                         },
                         {
                           "maTruong": "79768408",
                           "tenTruong": "TH Nguyễn Đình Chính"
                         },
                         {
                           "maTruong": "79768410",
                           "tenTruong": "TH Phạm Ngọc Thạch"
                         },
                         {
                           "maTruong": "79768401",
                           "tenTruong": "TH Sông Lô"
                         },
                         {
                           "maTruong": "79768411",
                           "tenTruong": "TH Trung Nhất"
                         },
                         {
                           "maTruong": "79768407",
                           "tenTruong": "TH Vạn Tường"
                         },
                         {
                           "maTruong": "79768501",
                           "tenTruong": "THCS Cầu Kiệu"
                         },
                         {
                           "maTruong": "79768504",
                           "tenTruong": "THCS Châu Văn Liêm"
                         },
                         {
                           "maTruong": "79768506",
                           "tenTruong": "THCS Đào Duy Anh"
                         },
                         {
                           "maTruong": "79768502",
                           "tenTruong": "THCS Độc Lập"
                         },
                         {
                           "maTruong": "79768503",
                           "tenTruong": "THCS Ngô Tất Tố"
                         },
                         {
                           "maTruong": "79768507",
                           "tenTruong": "THCS Trần Huy Liệu"
                         },
                         {
                           "maTruong": "79000872",
                           "tenTruong": "THCS - THPT Việt Anh"
                         },
                         {
                           "maTruong": "79000828",
                           "tenTruong": "TH - THCS - THPT Quốc Tế"
                         },
                         {
                           "maTruong": "79768X04",
                           "tenTruong": "MG Đa Minh"
                         },
                         {
                           "maTruong": "79768X08",
                           "tenTruong": "MG Họa Mi 1"
                         },
                         {
                           "maTruong": "79768X06",
                           "tenTruong": "MG Họa Mi 14A"
                         },
                         {
                           "maTruong": "79768X01",
                           "tenTruong": "MG Họa Mi 9A"
                         },
                         {
                           "maTruong": "79768201",
                           "tenTruong": "MG Hương Sen"
                         },
                         {
                           "maTruong": "79768X07",
                           "tenTruong": "MG Sơn Ca 5"
                         },
                         {
                           "maTruong": "79768X05",
                           "tenTruong": "MG Việt Mỹ"
                         },
                         {
                           "maTruong": "79768W32",
                           "tenTruong": "Bàn tay nhỏ"
                         },
                         {
                           "maTruong": "79768331",
                           "tenTruong": "CÁNH DIỀU TUỔI THƠ"
                         },
                         {
                           "maTruong": "79768W33",
                           "tenTruong": "Hải Âu bay"
                         },
                         {
                           "maTruong": "79768W30",
                           "tenTruong": "HOA HƯỚNG DƯƠNG"
                         },
                         {
                           "maTruong": "79768317",
                           "tenTruong": "Mầm non Mặt trời hồng"
                         },
                         {
                           "maTruong": "79768W26",
                           "tenTruong": "MẦM NON MỈM CƯỜI"
                         },
                         {
                           "maTruong": "79768316",
                           "tenTruong": "Mầm non Sao Ước Mơ"
                         },
                         {
                           "maTruong": "79768301",
                           "tenTruong": "Mầm Non Sơn Ca 1"
                         },
                         {
                           "maTruong": "79768W01",
                           "tenTruong": "MN Bé Ngôi Sao"
                         },
                         {
                           "maTruong": "79768W04",
                           "tenTruong": "MN Hạnh Phúc"
                         },
                         {
                           "maTruong": "79768W02",
                           "tenTruong": "MN Hoa Sứ"
                         },
                         {
                           "maTruong": "79768W29",
                           "tenTruong": "MN Nắng Hồng"
                         },
                         {
                           "maTruong": "79768W27",
                           "tenTruong": "MN Ngôi nhà kỳ diệu"
                         },
                         {
                           "maTruong": "79768308",
                           "tenTruong": "MN Sơn Ca 10"
                         },
                         {
                           "maTruong": "79768309",
                           "tenTruong": "MN Sơn Ca 11"
                         },
                         {
                           "maTruong": "79768310",
                           "tenTruong": "MN Sơn Ca 12"
                         },
                         {
                           "maTruong": "79768311",
                           "tenTruong": "MN Sơn Ca 14"
                         },
                         {
                           "maTruong": "79768312",
                           "tenTruong": "MN Sơn Ca 15"
                         },
                         {
                           "maTruong": "79768313",
                           "tenTruong": "MN Sơn Ca 17"
                         },
                         {
                           "maTruong": "79768302",
                           "tenTruong": "MN Sơn Ca 2"
                         },
                         {
                           "maTruong": "79768303",
                           "tenTruong": "MN Sơn Ca 3"
                         },
                         {
                           "maTruong": "79768304",
                           "tenTruong": "MN Sơn Ca 4"
                         },
                         {
                           "maTruong": "79768305",
                           "tenTruong": "MN Sơn Ca 5"
                         },
                         {
                           "maTruong": "79768306",
                           "tenTruong": "MN Sơn Ca 7"
                         },
                         {
                           "maTruong": "79768307",
                           "tenTruong": "MN Sơn Ca 8"
                         },
                         {
                           "maTruong": "79768314",
                           "tenTruong": "MN Sơn Ca 9"
                         },
                         {
                           "maTruong": "79768W06",
                           "tenTruong": "MN Thiên Thần Nhỏ"
                         },
                         {
                           "maTruong": "79768W03",
                           "tenTruong": "MN Tuổi Thần Tiên"
                         },
                         {
                           "maTruong": "79768W05",
                           "tenTruong": "MN Vân Anh"
                         },
                         {
                           "maTruong": "79768315",
                           "tenTruong": "MN Vườn Thần Tiên"
                         },
                         {
                           "maTruong": "79768W25",
                           "tenTruong": "Ngôi Sao Của Mẹ"
                         },
                         {
                           "maTruong": "79768W31",
                           "tenTruong": "Thành phố Tuổi thơ"
                         },
                         {
                           "maTruong": "79768W28",
                           "tenTruong": "Vườn Yêu Thương"
                         },
                         {
                           "maTruong": "79768W17",
                           "tenTruong": "Bạn Hữu"
                         },
                         {
                           "maTruong": "79768O03",
                           "tenTruong": "Ban Mai Xanh"
                         },
                         {
                           "maTruong": "79768W14",
                           "tenTruong": "Bé Yêu"
                         },
                         {
                           "maTruong": "79768W13",
                           "tenTruong": "Bé Yêu Ơi"
                         },
                         {
                           "maTruong": "79768W19",
                           "tenTruong": "Bình An"
                         },
                         {
                           "maTruong": "79768W09",
                           "tenTruong": "Bút chì màu"
                         },
                         {
                           "maTruong": "79768W12",
                           "tenTruong": "Cà Rốt"
                         },
                         {
                           "maTruong": "79768W10",
                           "tenTruong": "Hoa Hồng 2"
                         },
                         {
                           "maTruong": "79768W21",
                           "tenTruong": "Hoàn Mỹ"
                         },
                         {
                           "maTruong": "79768L12",
                           "tenTruong": "Lớp Mẫu giáo Măng Non"
                         },
                         {
                           "maTruong": "79768W08",
                           "tenTruong": "Lớp MN Ánh Dương"
                         },
                         {
                           "maTruong": "79768L11",
                           "tenTruong": "Mẫu giáo SÀI GÒN"
                         },
                         {
                           "maTruong": "79768X02",
                           "tenTruong": "MG Họa Mi 9B"
                         },
                         {
                           "maTruong": "79768X10",
                           "tenTruong": "Những ngón tay vui"
                         },
                         {
                           "maTruong": "79768O02",
                           "tenTruong": "NT Nhà của bé"
                         },
                         {
                           "maTruong": "79768O06",
                           "tenTruong": "NT Tuổi Thơ 7A"
                         },
                         {
                           "maTruong": "79768W11",
                           "tenTruong": "Sen Hồng 8A"
                         },
                         {
                           "maTruong": "79768W22",
                           "tenTruong": "Thiên Anh Tài"
                         },
                         {
                           "maTruong": "79768D01",
                           "tenTruong": "Trường Chuyên Biệt Niềm Tin"
                         },
                         {
                           "maTruong": "79768362",
                           "tenTruong": "Mầm non Tesla"
                         },
                         {
                           "maTruong": "79768363",
                           "tenTruong": "Mẫu giáo Rồng Việt"
                         },
                         {
                           "maTruong": "79768X34",
                           "tenTruong": "Học Viện Sài Gòn"
                         },
                         {
                           "maTruong": "79768X03",
                           "tenTruong": "Vườn Yêu Thương"
                         },
                         {
                           "maTruong": "79768202",
                           "tenTruong": "Lớp Mẫu giáo Bàn Tay Xinh"
                         },
                         {
                           "maTruong": "79768318",
                           "tenTruong": "Mầm non Bầu Trời Xanh"
                         },
                         {
                           "maTruong": "79768355",
                           "tenTruong": "Mầm non Học Viện Toàn Cầu"
                         },
                         {
                           "maTruong": "79768181",
                           "tenTruong": "Mầm non Cộng Đồng Tí Hon"
                         },
                         {
                           "maTruong": "79768067",
                           "tenTruong": "MN Hoàng Tử Bé"
                         },
                         {
                           "maTruong": "7976801001",
                           "tenTruong": "Lớp Mẫu giáo Hoa Sen Nhỏ"
                         },
                         {
                           "maTruong": "7976801003",
                           "tenTruong": "Lớp Mẫu giáo Trẻ Sáng Tạo"
                         },
                         {
                           "maTruong": "7976801002",
                           "tenTruong": "Mầm non Thiên Nhiên"
                         },
                         {
                           "maTruong": "7976801004",
                           "tenTruong": "Trường Mầm non Nhà Của Sóc"
                         },
                         {
                           "maTruong": "7976801005",
                           "tenTruong": "Lớp Mẫu giáo Nhà Em"
                         }
                       ]
                     },
                     {
                       "maPhong": "783",
                       "tenPhong": "Huyện Củ Chi",
                       "truongData": [
                         {
                           "maTruong": "79783420",
                           "tenTruong": "TH An Nhơn Đông"
                         },
                         {
                           "maTruong": "79783421",
                           "tenTruong": "TH An Nhơn Tây"
                         },
                         {
                           "maTruong": "79783422",
                           "tenTruong": "TH An Phú 1"
                         },
                         {
                           "maTruong": "79783423",
                           "tenTruong": "TH An Phú 2"
                         },
                         {
                           "maTruong": "79783414",
                           "tenTruong": "TH An Phước"
                         },
                         {
                           "maTruong": "79783433",
                           "tenTruong": "TH Bình Mỹ"
                         },
                         {
                           "maTruong": "79783434",
                           "tenTruong": "TH Bình Mỹ 2"
                         },
                         {
                           "maTruong": "79783432",
                           "tenTruong": "TH Hòa Phú"
                         },
                         {
                           "maTruong": "79783405",
                           "tenTruong": "TH Lê Thị Pha"
                         },
                         {
                           "maTruong": "79783416",
                           "tenTruong": "TH Lê Văn Thế"
                         },
                         {
                           "maTruong": "79783407",
                           "tenTruong": "TH Liên Minh Công Nông"
                         },
                         {
                           "maTruong": "79783418",
                           "tenTruong": "TH Liên Trung"
                         },
                         {
                           "maTruong": "79783406",
                           "tenTruong": "TH Nguyễn Văn Lịch"
                         },
                         {
                           "maTruong": "79783425",
                           "tenTruong": "TH Nhuận Đức"
                         },
                         {
                           "maTruong": "79783426",
                           "tenTruong": "TH Nhuận Đức 2"
                         },
                         {
                           "maTruong": "79783427",
                           "tenTruong": "TH Phạm Văn Cội"
                         },
                         {
                           "maTruong": "79783428",
                           "tenTruong": "TH Phú Hòa Đông"
                         },
                         {
                           "maTruong": "79783429",
                           "tenTruong": "TH Phú Hòa Đông 2"
                         },
                         {
                           "maTruong": "79783424",
                           "tenTruong": "TH Phú Mỹ Hưng"
                         },
                         {
                           "maTruong": "79783412",
                           "tenTruong": "TH Phước Hiệp"
                         },
                         {
                           "maTruong": "79783413",
                           "tenTruong": "TH Phước Thạnh"
                         },
                         {
                           "maTruong": "79783410",
                           "tenTruong": "TH Phước Vĩnh An"
                         },
                         {
                           "maTruong": "79783401",
                           "tenTruong": "TH Tân Phú"
                         },
                         {
                           "maTruong": "79783402",
                           "tenTruong": "TH Tân Phú Trung"
                         },
                         {
                           "maTruong": "79783409",
                           "tenTruong": "TH Tân Thành"
                         },
                         {
                           "maTruong": "79783435",
                           "tenTruong": "TH Tân Thạnh Đông"
                         },
                         {
                           "maTruong": "79783436",
                           "tenTruong": "TH Tân Thạnh Đông 2"
                         },
                         {
                           "maTruong": "79783437",
                           "tenTruong": "TH Tân Thạnh Đông 3"
                         },
                         {
                           "maTruong": "79783430",
                           "tenTruong": "TH Tân Thạnh Tây"
                         },
                         {
                           "maTruong": "79783438",
                           "tenTruong": "TH Tân Thông"
                         },
                         {
                           "maTruong": "79783403",
                           "tenTruong": "TH Tân Thông Hội"
                         },
                         {
                           "maTruong": "79783404",
                           "tenTruong": "TH Tân Tiến"
                         },
                         {
                           "maTruong": "79783415",
                           "tenTruong": "TH Thái Mỹ"
                         },
                         {
                           "maTruong": "79783439",
                           "tenTruong": "TH Thị Trấn 2"
                         },
                         {
                           "maTruong": "79783408",
                           "tenTruong": "TH Thị Trấn Củ Chi"
                         },
                         {
                           "maTruong": "79783411",
                           "tenTruong": "TH Trần Văn Chẩm"
                         },
                         {
                           "maTruong": "79783431",
                           "tenTruong": "TH Trung An"
                         },
                         {
                           "maTruong": "79783417",
                           "tenTruong": "TH Trung Lập Hạ"
                         },
                         {
                           "maTruong": "79783419",
                           "tenTruong": "TH Trung Lập Thượng"
                         },
                         {
                           "maTruong": "79783512",
                           "tenTruong": "THCS An Nhơn Tây"
                         },
                         {
                           "maTruong": "79783513",
                           "tenTruong": "THCS An Phú"
                         },
                         {
                           "maTruong": "79783519",
                           "tenTruong": "THCS Bình Hòa"
                         },
                         {
                           "maTruong": "79783521",
                           "tenTruong": "THCS Hòa Phú"
                         },
                         {
                           "maTruong": "79783509",
                           "tenTruong": "THCS Nguyễn Văn Xơ"
                         },
                         {
                           "maTruong": "79783514",
                           "tenTruong": "THCS Nhuận Đức"
                         },
                         {
                           "maTruong": "79783515",
                           "tenTruong": "THCS Phạm Văn Cội"
                         },
                         {
                           "maTruong": "79783516",
                           "tenTruong": "THCS Phú Hòa Đông"
                         },
                         {
                           "maTruong": "79783520",
                           "tenTruong": "THCS Phú Mỹ Hưng"
                         },
                         {
                           "maTruong": "79783507",
                           "tenTruong": "THCS Phước Hiệp"
                         },
                         {
                           "maTruong": "79783508",
                           "tenTruong": "THCS Phước Thạnh"
                         },
                         {
                           "maTruong": "79783506",
                           "tenTruong": "THCS Phước Vĩnh An"
                         },
                         {
                           "maTruong": "79783522",
                           "tenTruong": "THCS Tân An Hội"
                         },
                         {
                           "maTruong": "79783501",
                           "tenTruong": "THCS Tân Phú Trung"
                         },
                         {
                           "maTruong": "79783518",
                           "tenTruong": "THCS Tân Thạnh Đông"
                         },
                         {
                           "maTruong": "79783523",
                           "tenTruong": "THCS Tân Thạnh Tây"
                         },
                         {
                           "maTruong": "79783502",
                           "tenTruong": "THCS Tân Thông Hội"
                         },
                         {
                           "maTruong": "79783503",
                           "tenTruong": "THCS Tân Tiến"
                         },
                         {
                           "maTruong": "79783504",
                           "tenTruong": "THCS THỊ TRẤN"
                         },
                         {
                           "maTruong": "79783505",
                           "tenTruong": "THCS Thị Trấn 2"
                         },
                         {
                           "maTruong": "79783524",
                           "tenTruong": "THCS Trung An"
                         },
                         {
                           "maTruong": "79783511",
                           "tenTruong": "THCS Trung Lập"
                         },
                         {
                           "maTruong": "79783510",
                           "tenTruong": "THCS Trung Lập Hạ"
                         },
                         {
                           "maTruong": "79783600",
                           "tenTruong": "Tiểu học và THCS Tân  Trung"
                         },
                         {
                           "maTruong": "79783311",
                           "tenTruong": "MN ABC"
                         },
                         {
                           "maTruong": "79783348",
                           "tenTruong": "MN An Nhơn Tây"
                         },
                         {
                           "maTruong": "79783304",
                           "tenTruong": "MN An Phú"
                         },
                         {
                           "maTruong": "79783W53",
                           "tenTruong": "MN Anh Dũng"
                         },
                         {
                           "maTruong": "79783W04",
                           "tenTruong": "MN Bé Thông Minh"
                         },
                         {
                           "maTruong": "79783356",
                           "tenTruong": "MN Bình Mỹ"
                         },
                         {
                           "maTruong": "79783306",
                           "tenTruong": "MN Hoa Hồng"
                         },
                         {
                           "maTruong": "79783310",
                           "tenTruong": "MN Hoa Lan"
                         },
                         {
                           "maTruong": "79783355",
                           "tenTruong": "MN Hòa Phú"
                         },
                         {
                           "maTruong": "79783366",
                           "tenTruong": "MN Hoàng Anh"
                         },
                         {
                           "maTruong": "79783361",
                           "tenTruong": "MN Hoàng Minh Đạo"
                         },
                         {
                           "maTruong": "79783347",
                           "tenTruong": "MN Nhuận Đức"
                         },
                         {
                           "maTruong": "79783302",
                           "tenTruong": "MN Phạm Văn Cội 1"
                         },
                         {
                           "maTruong": "79783349",
                           "tenTruong": "MN Phạm Văn Cội 2"
                         },
                         {
                           "maTruong": "79783350",
                           "tenTruong": "MN Phú Hòa Đông"
                         },
                         {
                           "maTruong": "79783344",
                           "tenTruong": "MN Phước Hiệp"
                         },
                         {
                           "maTruong": "79783357",
                           "tenTruong": "Mầm non Phước Thạnh"
                         },
                         {
                           "maTruong": "79783351",
                           "tenTruong": "MN Phước Vĩnh An"
                         },
                         {
                           "maTruong": "79783362",
                           "tenTruong": "MN Sao Việt"
                         },
                         {
                           "maTruong": "79783308",
                           "tenTruong": "MN Sơn Ca"
                         },
                         {
                           "maTruong": "79783363",
                           "tenTruong": "MN Sư đoàn 9"
                         },
                         {
                           "maTruong": "79783358",
                           "tenTruong": "MN Tân An Hội 1"
                         },
                         {
                           "maTruong": "79783359",
                           "tenTruong": "MN Tân An Hội 2"
                         },
                         {
                           "maTruong": "79783339",
                           "tenTruong": "MN Tân Phú Trung 1"
                         },
                         {
                           "maTruong": "79783340",
                           "tenTruong": "MN Tân Phú Trung 2"
                         },
                         {
                           "maTruong": "79783352",
                           "tenTruong": "MN Tân Thạnh Đông"
                         },
                         {
                           "maTruong": "79783365",
                           "tenTruong": "MN Tân Thạnh Tây"
                         },
                         {
                           "maTruong": "79783305",
                           "tenTruong": "MN Tân Thông Hội 1"
                         },
                         {
                           "maTruong": "79783338",
                           "tenTruong": "MN Tân Thông Hội 2"
                         },
                         {
                           "maTruong": "79783341",
                           "tenTruong": "MN Tân Thông Hội 3"
                         },
                         {
                           "maTruong": "79783342",
                           "tenTruong": "MN Tân Thông Hội 4"
                         },
                         {
                           "maTruong": "79783364",
                           "tenTruong": "MN Tây Bắc"
                         },
                         {
                           "maTruong": "79783345",
                           "tenTruong": "MN Thái Mỹ"
                         },
                         {
                           "maTruong": "79783369",
                           "tenTruong": "MN Thành Danh"
                         },
                         {
                           "maTruong": "79783327",
                           "tenTruong": "MN Thanh Phương"
                         },
                         {
                           "maTruong": "79783343",
                           "tenTruong": "MN Thị Trấn Củ Chi 1"
                         },
                         {
                           "maTruong": "79783307",
                           "tenTruong": "MN Thị Trấn Củ Chi 2"
                         },
                         {
                           "maTruong": "79783301",
                           "tenTruong": "MN Thị Trấn Củ Chi 3"
                         },
                         {
                           "maTruong": "79783309",
                           "tenTruong": "MN Thủy Tiên"
                         },
                         {
                           "maTruong": "79783367",
                           "tenTruong": "MN Trái Tim Thơ"
                         },
                         {
                           "maTruong": "79783W03",
                           "tenTruong": "MN Trúc Xanh"
                         },
                         {
                           "maTruong": "79783354",
                           "tenTruong": "MN Trung An 1"
                         },
                         {
                           "maTruong": "79783303",
                           "tenTruong": "MN Trung An 2"
                         },
                         {
                           "maTruong": "79783346",
                           "tenTruong": "MN Trung Lập Hạ"
                         },
                         {
                           "maTruong": "79783360",
                           "tenTruong": "MN Trung Lập Thượng"
                         },
                         {
                           "maTruong": "79783316",
                           "tenTruong": "MN Tuổi Ngọc"
                         },
                         {
                           "maTruong": "79783368",
                           "tenTruong": "MN Tường Vi"
                         },
                         {
                           "maTruong": "79783W52",
                           "tenTruong": "LMG  Bồ Câu Trắng"
                         },
                         {
                           "maTruong": "79783W23",
                           "tenTruong": "LMG  Vân Anh"
                         },
                         {
                           "maTruong": "79783W13",
                           "tenTruong": "LMG Anh Đức"
                         },
                         {
                           "maTruong": "79783315",
                           "tenTruong": "LMG Ánh Dương"
                         },
                         {
                           "maTruong": "79783W56",
                           "tenTruong": "LMG Ánh Khang"
                         },
                         {
                           "maTruong": "79783W76",
                           "tenTruong": "LMG Ánh Mai"
                         },
                         {
                           "maTruong": "79783W36",
                           "tenTruong": "LMG Ban Mai"
                         },
                         {
                           "maTruong": "79783W98",
                           "tenTruong": "LMG Bé Ngoan"
                         },
                         {
                           "maTruong": "79783W86",
                           "tenTruong": "LMG Bé Xinh"
                         },
                         {
                           "maTruong": "79783W07",
                           "tenTruong": "LMG Bình Minh"
                         },
                         {
                           "maTruong": "79783W63",
                           "tenTruong": "LMG Cát Tường"
                         },
                         {
                           "maTruong": "79783336",
                           "tenTruong": "LMG Con Ong Nhỏ"
                         },
                         {
                           "maTruong": "79783W35",
                           "tenTruong": "LMG Đại Dương"
                         },
                         {
                           "maTruong": "79783W79",
                           "tenTruong": "LMG Hoa Anh Đào"
                         },
                         {
                           "maTruong": "79783W77",
                           "tenTruong": "LMG Hoa Mạ Non"
                         },
                         {
                           "maTruong": "79783W78",
                           "tenTruong": "LMG Hoa Mai"
                         },
                         {
                           "maTruong": "79783W68",
                           "tenTruong": "LMG Hoa Mặt Trời"
                         },
                         {
                           "maTruong": "79783W51",
                           "tenTruong": "LMG Hoa Phượng"
                         },
                         {
                           "maTruong": "79783W48",
                           "tenTruong": "LMG Hoa Quỳnh"
                         },
                         {
                           "maTruong": "79783W80",
                           "tenTruong": "LMG Hoa Sen"
                         },
                         {
                           "maTruong": "79783W95",
                           "tenTruong": "LMG Hoa Thiên Lý"
                         },
                         {
                           "maTruong": "79783W61",
                           "tenTruong": "LMG Hoa ti gôn"
                         },
                         {
                           "maTruong": "79783W84",
                           "tenTruong": "LMG Hoa Trang"
                         },
                         {
                           "maTruong": "79783313",
                           "tenTruong": "LMG Hoàng Dung"
                         },
                         {
                           "maTruong": "79783333",
                           "tenTruong": "LMG Hồng Nhung"
                         },
                         {
                           "maTruong": "79783W75",
                           "tenTruong": "LMG Huệ Anh"
                         },
                         {
                           "maTruong": "79783324",
                           "tenTruong": "LMG Hướng Dương"
                         },
                         {
                           "maTruong": "79783W81",
                           "tenTruong": "LMG Khải Phong"
                         },
                         {
                           "maTruong": "79783W73",
                           "tenTruong": "LMG Kim Đồng"
                         },
                         {
                           "maTruong": "79783W91",
                           "tenTruong": "LMG LyLi"
                         },
                         {
                           "maTruong": "79783W32",
                           "tenTruong": "LMG Mai Vàng"
                         },
                         {
                           "maTruong": "79783W12",
                           "tenTruong": "LMG Măng Non"
                         },
                         {
                           "maTruong": "79783W96",
                           "tenTruong": "LMG Minh An"
                         },
                         {
                           "maTruong": "79783W97",
                           "tenTruong": "LMG Ngôi Sao Mai"
                         },
                         {
                           "maTruong": "79783W69",
                           "tenTruong": "LMG Ngôi Sao Nhỏ"
                         },
                         {
                           "maTruong": "79783W70",
                           "tenTruong": "LMG Như Ý"
                         },
                         {
                           "maTruong": "79783W26",
                           "tenTruong": "LMG Phát Triển"
                         },
                         {
                           "maTruong": "79783332",
                           "tenTruong": "LMG Quốc Dũng"
                         },
                         {
                           "maTruong": "79783W27",
                           "tenTruong": "LMG Quốc Dũng 2"
                         },
                         {
                           "maTruong": "79783W45",
                           "tenTruong": "LMG Quỳnh Anh"
                         },
                         {
                           "maTruong": "79783337",
                           "tenTruong": "LMG Rạng Đông"
                         },
                         {
                           "maTruong": "7978W72",
                           "tenTruong": "LMG Sao Vàng"
                         },
                         {
                           "maTruong": "79783W90",
                           "tenTruong": "LMG Sen Việt"
                         },
                         {
                           "maTruong": "79783W94",
                           "tenTruong": "LMG Sóc Nâu"
                         },
                         {
                           "maTruong": "79783325",
                           "tenTruong": "LMG Thanh Bình"
                         },
                         {
                           "maTruong": "79783317",
                           "tenTruong": "LMG Thanh Lan"
                         },
                         {
                           "maTruong": "79783W18",
                           "tenTruong": "LMG Thanh Minh"
                         },
                         {
                           "maTruong": "79783W16",
                           "tenTruong": "LMG Thảo Ngọc"
                         },
                         {
                           "maTruong": "79783331",
                           "tenTruong": "LMG Thiên Ân"
                         },
                         {
                           "maTruong": "79783W67",
                           "tenTruong": "LMG Thiên Thần"
                         },
                         {
                           "maTruong": "79783W42",
                           "tenTruong": "LMG Thương Hiền"
                         },
                         {
                           "maTruong": "79783W64",
                           "tenTruong": "LMG Trí Việt"
                         },
                         {
                           "maTruong": "79783W30",
                           "tenTruong": "LMG Trúc Phương"
                         },
                         {
                           "maTruong": "79783W09",
                           "tenTruong": "LMG Tuổi Thơ"
                         },
                         {
                           "maTruong": "79783323",
                           "tenTruong": "LMG Văn Lang"
                         },
                         {
                           "maTruong": "79783W74",
                           "tenTruong": "LMG Việt Anh"
                         },
                         {
                           "maTruong": "79783W38",
                           "tenTruong": "LMG Vy Vy"
                         },
                         {
                           "maTruong": "79783W92",
                           "tenTruong": "LMG Xuân Thư"
                         },
                         {
                           "maTruong": "79783W39",
                           "tenTruong": "LMG Yến My"
                         },
                         {
                           "maTruong": "79783W87",
                           "tenTruong": "MG Ánh Tuyết"
                         },
                         {
                           "maTruong": "79783335",
                           "tenTruong": "MN Ánh Dương Samho"
                         },
                         {
                           "maTruong": "79783W88",
                           "tenTruong": "MN Bông Hoa Nhỏ"
                         },
                         {
                           "maTruong": "79783W01",
                           "tenTruong": "MN Búp Măng"
                         },
                         {
                           "maTruong": "79783W15",
                           "tenTruong": "MN Hoa Hồng Nhung"
                         },
                         {
                           "maTruong": "79783319",
                           "tenTruong": "MN Kim Thoa"
                         },
                         {
                           "maTruong": "79783W21",
                           "tenTruong": "MN Phù Đổng"
                         },
                         {
                           "maTruong": "79783330",
                           "tenTruong": "MN Tuổi Thần Tiên"
                         },
                         {
                           "maTruong": "79783W83",
                           "tenTruong": "MN Vườn Họa Mi"
                         },
                         {
                           "maTruong": "79783W24",
                           "tenTruong": "NT Bảo Ngọc"
                         },
                         {
                           "maTruong": "79783W49",
                           "tenTruong": "NT Đôrêmi"
                         },
                         {
                           "maTruong": "79783W66",
                           "tenTruong": "NT Hồng Hạnh"
                         },
                         {
                           "maTruong": "79783W55",
                           "tenTruong": "NT Kim Cương"
                         },
                         {
                           "maTruong": "79783W85",
                           "tenTruong": "NT Mặt Trời Nhỏ"
                         },
                         {
                           "maTruong": "79783W25",
                           "tenTruong": "NT Nắng Mới"
                         },
                         {
                           "maTruong": "79783W44",
                           "tenTruong": "NT Ngôi Sao"
                         },
                         {
                           "maTruong": "79783W99",
                           "tenTruong": "NT Sao Mai"
                         },
                         {
                           "maTruong": "79783W89",
                           "tenTruong": "NT Sen Hồng"
                         },
                         {
                           "maTruong": "79783w58",
                           "tenTruong": "NT Thần Đồng Việt"
                         },
                         {
                           "maTruong": "79783W33",
                           "tenTruong": "NT Thanh Ngân"
                         },
                         {
                           "maTruong": "79783W17",
                           "tenTruong": "NT Thanh Tâm"
                         },
                         {
                           "maTruong": "79783W28",
                           "tenTruong": "NT Tia Nắng"
                         },
                         {
                           "maTruong": "79783312",
                           "tenTruong": "NT Trìu Ân"
                         },
                         {
                           "maTruong": "79783W59",
                           "tenTruong": "NT Ước Mơ"
                         },
                         {
                           "maTruong": "79783W65",
                           "tenTruong": "NT Yến Nhi"
                         },
                         {
                           "maTruong": "78793LB8",
                           "tenTruong": "Nhóm trẻ Thảo Quyên"
                         },
                         {
                           "maTruong": "79783LB9",
                           "tenTruong": "Lớp Mẫu Giáo Mèo Ngoan"
                         },
                         {
                           "maTruong": "79783LC0",
                           "tenTruong": "Nhóm trẻ Đăng Khoa"
                         },
                         {
                           "maTruong": "79783LC1",
                           "tenTruong": "Lớp Mẫu giáo Bông Sen Hồng"
                         },
                         {
                           "maTruong": "79783LC2",
                           "tenTruong": "Lớp Mẫu giáo Hồng Ân"
                         },
                         {
                           "maTruong": "79783LC3",
                           "tenTruong": "Lớp Mẫu giáo Hoa Bách Hợp"
                         },
                         {
                           "maTruong": "79783LC4",
                           "tenTruong": "Mẫu giáo Thanh Hằng"
                         },
                         {
                           "maTruong": "79783LC5",
                           "tenTruong": "Lớp Mẫu giáo Nụ Cười Hồng"
                         },
                         {
                           "maTruong": "79783499",
                           "tenTruong": "TH Nguyễn Thị Lắng"
                         },
                         {
                           "maTruong": "79783LB7",
                           "tenTruong": "Lớp Mẫu Giáo Hạt đậu nhỏ"
                         },
                         {
                           "maTruong": "79783LB6",
                           "tenTruong": "Lớp Mẫu giáo Ước Mơ Xanh"
                         },
                         {
                           "maTruong": "79783LA0",
                           "tenTruong": "Lớp Mẫu Giáo Vân Anh 2"
                         },
                         {
                           "maTruong": "79783LA2",
                           "tenTruong": "Lớp Mẫu giáo Hạnh Phúc"
                         },
                         {
                           "maTruong": "79783LA3",
                           "tenTruong": "Lớp Mẫu giáo Hoa Quỳnh Anh"
                         },
                         {
                           "maTruong": "79783LA4",
                           "tenTruong": "Lớp Mẫu Giáo Vườn Hồng"
                         },
                         {
                           "maTruong": "79783LA5",
                           "tenTruong": "Lớp Mẫu Giáo Ánh Sao"
                         },
                         {
                           "maTruong": "79783LA6",
                           "tenTruong": "Lớp Mẫu Giáo Thiện Ân"
                         },
                         {
                           "maTruong": "79783LA7",
                           "tenTruong": "Lớp Mẫu giáo Con Ong Vàng"
                         },
                         {
                           "maTruong": "79783LA8",
                           "tenTruong": "Lớp Mẫu Giáo Ánh Bình Minh"
                         },
                         {
                           "maTruong": "79783LA9",
                           "tenTruong": "Lớp Mẫu giáo Hoa Mai Trắng"
                         },
                         {
                           "maTruong": "79783LB0",
                           "tenTruong": "Nhóm trẻ Cẩm Nhung"
                         },
                         {
                           "maTruong": "79783LB1",
                           "tenTruong": "Nhóm trẻ Huy Hoàng"
                         },
                         {
                           "maTruong": "79783LB2",
                           "tenTruong": "Nhóm trẻ Bé Thơ"
                         },
                         {
                           "maTruong": "797832A1",
                           "tenTruong": "Lớp Mẫu Giáo Mặt Trời Hồng"
                         },
                         {
                           "maTruong": "79783LB3",
                           "tenTruong": "Nhóm trẻ Thiên Thanh"
                         },
                         {
                           "maTruong": "79783LB4",
                           "tenTruong": "Lớp Mẫu Giáo Vàng Anh"
                         },
                         {
                           "maTruong": "79783LB5",
                           "tenTruong": "Lớp Mẫu Giáo Hương Sen"
                         },
                         {
                           "maTruong": "79783LD6",
                           "tenTruong": "Lớp Mẫu giáo Ban Mai Xanh"
                         },
                         {
                           "maTruong": "79783LD7",
                           "tenTruong": "Nhóm trẻ Bảo Thy"
                         },
                         {
                           "maTruong": "79783LD8",
                           "tenTruong": "Lớp Mẫu giáo Sao Ban Mai"
                         },
                         {
                           "maTruong": "79783LE7",
                           "tenTruong": "Nhóm trẻ Trâm Anh"
                         },
                         {
                           "maTruong": "79783LE6",
                           "tenTruong": "Lớp Mẫu Giáo Sao Mai Hồng"
                         },
                         {
                           "maTruong": "79783LD9",
                           "tenTruong": "Lớp Mẫu giáo 59"
                         },
                         {
                           "maTruong": "79783LE0",
                           "tenTruong": "Nhóm trẻ Oanh Vàng"
                         },
                         {
                           "maTruong": "79783LE1",
                           "tenTruong": "Lớp Mẫu Giáo Ngôi Sao Xinh"
                         },
                         {
                           "maTruong": "79783LE2",
                           "tenTruong": "Lớp Mẫu giáo Hoàng Yến"
                         },
                         {
                           "maTruong": "79783LE3",
                           "tenTruong": "Lớp Mẫu giáo Bắc Hà"
                         },
                         {
                           "maTruong": "79783LE4",
                           "tenTruong": "Lớp Mẫu giáo An Nhiên"
                         },
                         {
                           "maTruong": "79783LE5",
                           "tenTruong": "Lớp Mẫu giáo Búp Măng Non"
                         },
                         {
                           "maTruong": "79783LD2",
                           "tenTruong": "Lớp Mẫu giáo Sắc Cầu Vồng"
                         },
                         {
                           "maTruong": "79783LD3",
                           "tenTruong": "Lớp Mẫu giáo Tân Quy"
                         },
                         {
                           "maTruong": "79783LD4",
                           "tenTruong": "Nhóm trẻ Hải Âu"
                         },
                         {
                           "maTruong": "79783LD5",
                           "tenTruong": "Lớp Mẫu giáo Bé Yêu"
                         },
                         {
                           "maTruong": "79783371",
                           "tenTruong": "Mầm non Ánh Ánh Dương"
                         },
                         {
                           "maTruong": "79783372",
                           "tenTruong": "Mầm non Anh Đào"
                         },
                         {
                           "maTruong": "79783370",
                           "tenTruong": "Mầm non Nguyễn Thị Dậu"
                         },
                         {
                           "maTruong": "79783LC6",
                           "tenTruong": "Lớp Mẫu giáo CVK"
                         },
                         {
                           "maTruong": "79783LC7",
                           "tenTruong": "Lớp Mẫu giáo Đức Huy"
                         },
                         {
                           "maTruong": "79783LC8",
                           "tenTruong": "Lớp Mẫu giáo Dâu Tây"
                         },
                         {
                           "maTruong": "79783LC9",
                           "tenTruong": "Lớp Mẫu giáo Hoa Nắng"
                         },
                         {
                           "maTruong": "79783LD0",
                           "tenTruong": "Lớp Mẫu giáo Thùy Dương"
                         },
                         {
                           "maTruong": "79783LD1",
                           "tenTruong": "Lớp Mẫu giáo Trẻ Thơ"
                         },
                         {
                           "maTruong": "79783130",
                           "tenTruong": "Lớp Mẫu giáo Ngọc Mi"
                         },
                         {
                           "maTruong": "79783124",
                           "tenTruong": "Lớp Mẫu giáo Con Cưng"
                         },
                         {
                           "maTruong": "7978301001",
                           "tenTruong": "Lớp Mẫu giáo Thông Minh"
                         },
                         {
                           "maTruong": "7978301002",
                           "tenTruong": "Lớp Mẫu giáo Nắng Hồng"
                         },
                         {
                           "maTruong": "7978301003",
                           "tenTruong": "Lớp Mẫu giáo Mặt Trời"
                         },
                         {
                           "maTruong": "7978301004",
                           "tenTruong": "Lớp Mẫu giáo Cô Tiên Xanh"
                         },
                         {
                           "maTruong": "7978301005",
                           "tenTruong": "Lớp Mẫu giáo Tiến Đạt"
                         },
                         {
                           "maTruong": "79783024",
                           "tenTruong": "Lớp Mẫu giáo Bé Ngôi Sao"
                         },
                         {
                           "maTruong": "79783158",
                           "tenTruong": "Lớp Mẫu giáo Nắng Sài Gòn"
                         },
                         {
                           "maTruong": "79783062",
                           "tenTruong": "Lớp Mẫu giáo Gia Phúc"
                         },
                         {
                           "maTruong": "79783205",
                           "tenTruong": "Nhóm trẻ Tương Lai"
                         },
                         {
                           "maTruong": "7978301016",
                           "tenTruong": "Lớp Mẫu giáo Nụ Cười Trẻ Thơ"
                         },
                         {
                           "maTruong": "7978301017",
                           "tenTruong": "Lớp Mẫu giáo Minh Anh"
                         },
                         {
                           "maTruong": "7978301012",
                           "tenTruong": "Lớp Mẫu giáo Cát Cánh Xanh"
                         },
                         {
                           "maTruong": "7978301013",
                           "tenTruong": "Lớp Mẫu giáo Giấc mơ nhỏ bé"
                         },
                         {
                           "maTruong": "7978301014",
                           "tenTruong": "Lớp Mẫu giáo Gia Hưng"
                         },
                         {
                           "maTruong": "7978301015",
                           "tenTruong": "Lớp Mẫu giáo Khánh Linh"
                         },
                         {
                           "maTruong": "7978302001",
                           "tenTruong": "TH Huỳnh Văn Cọ"
                         },
                         {
                           "maTruong": "7978301008",
                           "tenTruong": "Lớp Mẫu Giáo Việt Đức"
                         },
                         {
                           "maTruong": "7978301009",
                           "tenTruong": "Lớp Mẫu Giáo Thanh Trúc"
                         },
                         {
                           "maTruong": "7978301010",
                           "tenTruong": "Lớp Mẫu Giáo Khả Hân"
                         },
                         {
                           "maTruong": "7978301011",
                           "tenTruong": "Lớp Mẫu Giáo Hoà Bình"
                         },
                         {
                           "maTruong": "7978301006",
                           "tenTruong": "Lớp Mẫu Giáo Thảo My"
                         },
                         {
                           "maTruong": "7978301007",
                           "tenTruong": "Lớp Mẫu Giáo Như Quỳnh"
                         },
                         {
                           "maTruong": "7978301021",
                           "tenTruong": "Trường Mầm non Việt Đức Phát"
                         },
                         {
                           "maTruong": "7978301018",
                           "tenTruong": "Lớp Mẫu giáo Bảy Sắc Cầu Vồng"
                         },
                         {
                           "maTruong": "7978301020",
                           "tenTruong": "Lớp Mẫu giáo Hoàng Gia"
                         },
                         {
                           "maTruong": "7978301022",
                           "tenTruong": "Lớp Mẫu giáo ABC"
                         }
                       ]
                     },
                     {
                       "maPhong": "786",
                       "tenPhong": "Huyện Nhà Bè",
                       "truongData": [
                         {
                           "maTruong": "79786411",
                           "tenTruong": "TH Bùi Thanh Khiết"
                         },
                         {
                           "maTruong": "79786402",
                           "tenTruong": "TH Bùi Văn Ba"
                         },
                         {
                           "maTruong": "79786407",
                           "tenTruong": "TH Dương Văn Lịch"
                         },
                         {
                           "maTruong": "79786403",
                           "tenTruong": "TH Lâm Văn Bền"
                         },
                         {
                           "maTruong": "79786412",
                           "tenTruong": "TH Lê Lợi"
                         },
                         {
                           "maTruong": "79786410",
                           "tenTruong": "TH Lê Quang Định"
                         },
                         {
                           "maTruong": "79786413",
                           "tenTruong": "TH Lê Văn Lương"
                         },
                         {
                           "maTruong": "79786405",
                           "tenTruong": "TH Nguyễn Bình"
                         },
                         {
                           "maTruong": "79786404",
                           "tenTruong": "TH Nguyễn Trực"
                         },
                         {
                           "maTruong": "79786408",
                           "tenTruong": "TH Nguyễn Văn Tạo"
                         },
                         {
                           "maTruong": "79786409",
                           "tenTruong": "TH Tạ Uyên"
                         },
                         {
                           "maTruong": "79786401",
                           "tenTruong": "TH Trần Thị Ngọc Hân"
                         },
                         {
                           "maTruong": "79786406",
                           "tenTruong": "TH Trang Tấn Khương"
                         },
                         {
                           "maTruong": "79786505",
                           "tenTruong": "THCS Hai Bà Trưng"
                         },
                         {
                           "maTruong": "79786503",
                           "tenTruong": "THCS Hiệp Phước"
                         },
                         {
                           "maTruong": "79786507",
                           "tenTruong": "THCS Lê Thành Công"
                         },
                         {
                           "maTruong": "79786502",
                           "tenTruong": "THCS Lê Văn Hưu"
                         },
                         {
                           "maTruong": "79786501",
                           "tenTruong": "THCS Nguyễn Bỉnh Khiêm"
                         },
                         {
                           "maTruong": "79786504",
                           "tenTruong": "THCS Nguyễn Văn Quỳ"
                         },
                         {
                           "maTruong": "79786506",
                           "tenTruong": "THCS Phước Lộc"
                         },
                         {
                           "maTruong": "79786321",
                           "tenTruong": "MN Bầu Trời Xanh"
                         },
                         {
                           "maTruong": "79786320",
                           "tenTruong": "MN Bé Vui"
                         },
                         {
                           "maTruong": "79786316",
                           "tenTruong": "MN Bé Xinh"
                         },
                         {
                           "maTruong": "79786W16",
                           "tenTruong": "MN BiBi"
                         },
                         {
                           "maTruong": "79786322",
                           "tenTruong": "MN Chú Ong Nhỏ Phúc Long"
                         },
                         {
                           "maTruong": "79786304",
                           "tenTruong": "MN Đồng Xanh"
                         },
                         {
                           "maTruong": "79786W12",
                           "tenTruong": "MN Hoa Hồng"
                         },
                         {
                           "maTruong": "79786309",
                           "tenTruong": "MN Hoa Hướng Dương"
                         },
                         {
                           "maTruong": "79786306",
                           "tenTruong": "MN Họa Mi"
                         },
                         {
                           "maTruong": "79786W04",
                           "tenTruong": "MN Hoa Quỳnh"
                         },
                         {
                           "maTruong": "79786308",
                           "tenTruong": "MN Hướng Dương"
                         },
                         {
                           "maTruong": "79786303",
                           "tenTruong": "MN Mạ Non"
                         },
                         {
                           "maTruong": "79786325",
                           "tenTruong": "MN Mầm Xanh"
                         },
                         {
                           "maTruong": "79786312",
                           "tenTruong": "MN Misa 2"
                         },
                         {
                           "maTruong": "79786W11",
                           "tenTruong": "MN Nam Sơn"
                         },
                         {
                           "maTruong": "79786310",
                           "tenTruong": "MN Ngôi Nhà Mơ Ước"
                         },
                         {
                           "maTruong": "79786W01",
                           "tenTruong": "MN Phượng Hồng"
                         },
                         {
                           "maTruong": "79786318",
                           "tenTruong": "MN QT Sài Gòn Liên Kết"
                         },
                         {
                           "maTruong": "79786305",
                           "tenTruong": "Mầm non Sao Mai"
                         },
                         {
                           "maTruong": "79786302",
                           "tenTruong": "MN Sơn Ca"
                         },
                         {
                           "maTruong": "79786326",
                           "tenTruong": "MN Thế Giới Trẻ Thơ"
                         },
                         {
                           "maTruong": "79786301",
                           "tenTruong": "MN Thị trấn Nhà Bè"
                         },
                         {
                           "maTruong": "79786327",
                           "tenTruong": "MN Thiên Thần Nhỏ"
                         },
                         {
                           "maTruong": "79786324",
                           "tenTruong": "MN Tuổi Hoa"
                         },
                         {
                           "maTruong": "79786311",
                           "tenTruong": "MN Tuổi Ngọc"
                         },
                         {
                           "maTruong": "79786323",
                           "tenTruong": "MN Vàng Anh"
                         },
                         {
                           "maTruong": "79786307",
                           "tenTruong": "MN Vành Khuyên"
                         },
                         {
                           "maTruong": "79786L15",
                           "tenTruong": "Lớp MG Anh Đào"
                         },
                         {
                           "maTruong": "79786O02",
                           "tenTruong": "Lớp MG Ánh Dương"
                         },
                         {
                           "maTruong": "79786X04",
                           "tenTruong": "Lớp MG Bông Sen"
                         },
                         {
                           "maTruong": "79786L06",
                           "tenTruong": "Lớp MG Cầu Vồng"
                         },
                         {
                           "maTruong": "79786W18",
                           "tenTruong": "Lớp MG Chú Ong Nhỏ"
                         },
                         {
                           "maTruong": "79786W05",
                           "tenTruong": "Lớp MG Hoa Mai"
                         },
                         {
                           "maTruong": "79786X01",
                           "tenTruong": "Lớp MG Mặt Trời Nhỏ"
                         },
                         {
                           "maTruong": "79786L10",
                           "tenTruong": "Lớp MG Minh Hằng"
                         },
                         {
                           "maTruong": "79786X05",
                           "tenTruong": "Lớp MG Ngôi Sao"
                         },
                         {
                           "maTruong": "79786O01",
                           "tenTruong": "Lớp MG Nụ Cười Hồng"
                         },
                         {
                           "maTruong": "79786L04",
                           "tenTruong": "Lớp MG Sao Mai"
                         },
                         {
                           "maTruong": "79786L12",
                           "tenTruong": "Lớp MG Sao Việt"
                         },
                         {
                           "maTruong": "79786X03",
                           "tenTruong": "Lớp MG Thần Đồng"
                         },
                         {
                           "maTruong": "79786L09",
                           "tenTruong": "Lớp MG Việt Mỹ Liên Kết"
                         },
                         {
                           "maTruong": "79786W19",
                           "tenTruong": "Lớp MN Ánh Linh"
                         },
                         {
                           "maTruong": "79786W08",
                           "tenTruong": "Lớp MN Bam Bi"
                         },
                         {
                           "maTruong": "79786W25",
                           "tenTruong": "Lớp MN Ban Mai"
                         },
                         {
                           "maTruong": "79786W17",
                           "tenTruong": "Lớp MN Bé Ngoan"
                         },
                         {
                           "maTruong": "79786W20",
                           "tenTruong": "Lớp MN Gấu Trúc"
                         },
                         {
                           "maTruong": "79786W26",
                           "tenTruong": "Lớp MN Miền Cổ Tích"
                         },
                         {
                           "maTruong": "79786W06",
                           "tenTruong": "Lớp MN Mimosa"
                         },
                         {
                           "maTruong": "79786W03",
                           "tenTruong": "Lớp MN My My"
                         },
                         {
                           "maTruong": "79786W28",
                           "tenTruong": "Lớp MN Nam Mỹ"
                         },
                         {
                           "maTruong": "79786W15",
                           "tenTruong": "Lớp MN Nắng Mai 1"
                         },
                         {
                           "maTruong": "79786W02",
                           "tenTruong": "Lớp MN Thiên Thần Nhỏ"
                         },
                         {
                           "maTruong": "79786W10",
                           "tenTruong": "Lớp MN Thỏ Ngọc"
                         },
                         {
                           "maTruong": "79786W09",
                           "tenTruong": "Lớp MN Tuổi Thần Tiên"
                         },
                         {
                           "maTruong": "79786X02",
                           "tenTruong": "Lớp MN Tuổi Thơ"
                         },
                         {
                           "maTruong": "79786W29",
                           "tenTruong": "NT Anh Quốc"
                         },
                         {
                           "maTruong": "79786W07",
                           "tenTruong": "NT Búp Măng"
                         },
                         {
                           "maTruong": "79786L07",
                           "tenTruong": "NT Hoa Mặt Trời"
                         },
                         {
                           "maTruong": "79786L13",
                           "tenTruong": "NT Khai Trí"
                         },
                         {
                           "maTruong": "79786L11",
                           "tenTruong": "NT Khu Rừng Vui"
                         },
                         {
                           "maTruong": "79786317",
                           "tenTruong": "NT Phương Mai"
                         },
                         {
                           "maTruong": "79786L03",
                           "tenTruong": "NT Sao Khuê"
                         },
                         {
                           "maTruong": "79786W22",
                           "tenTruong": "NT Sen Hồng"
                         },
                         {
                           "maTruong": "79786319",
                           "tenTruong": "NT Thỏ Bông"
                         },
                         {
                           "maTruong": "79786314",
                           "tenTruong": "NT Tuổi Thơ Rực Rỡ"
                         },
                         {
                           "maTruong": "TMNCC",
                           "tenTruong": "Trường MN Con Cưng"
                         },
                         {
                           "maTruong": "79786414",
                           "tenTruong": "TH Nguyễn Việt Hồng"
                         },
                         {
                           "maTruong": "79786508",
                           "tenTruong": "THCS Nguyễn Thị Hương"
                         },
                         {
                           "maTruong": "MN-1",
                           "tenTruong": "Búp Bê Ngoan"
                         },
                         {
                           "maTruong": "79786328",
                           "tenTruong": "MN Việt Nga"
                         },
                         {
                           "maTruong": "79786L24",
                           "tenTruong": "NT Mây Xanh"
                         },
                         {
                           "maTruong": "79786329",
                           "tenTruong": "MN Con Cưng"
                         },
                         {
                           "maTruong": "79786L21",
                           "tenTruong": "Lớp MG Đom Đóm Xanh"
                         },
                         {
                           "maTruong": "79786L19",
                           "tenTruong": "Lớp MG Vườn Ươm"
                         },
                         {
                           "maTruong": "79786W14",
                           "tenTruong": "NT Búp Bê Ngoan"
                         },
                         {
                           "maTruong": "79786300",
                           "tenTruong": "MN Tuổi Thần Tiên"
                         },
                         {
                           "maTruong": "79786L05",
                           "tenTruong": "NT Bình Minh"
                         },
                         {
                           "maTruong": "79786L27",
                           "tenTruong": "Lớp MG Hoa Nắng"
                         },
                         {
                           "maTruong": "79786L28",
                           "tenTruong": "Lớp MG Hoa Sữa"
                         },
                         {
                           "maTruong": "79786L23",
                           "tenTruong": "NT ABC"
                         },
                         {
                           "maTruong": "79786L29",
                           "tenTruong": "Lớp MG Măng Non"
                         },
                         {
                           "maTruong": "79786331",
                           "tenTruong": "MN Hoàng Anh"
                         },
                         {
                           "maTruong": "79786330",
                           "tenTruong": "MN Vùng Đất Trẻ Thơ"
                         },
                         {
                           "maTruong": "79786L16",
                           "tenTruong": "NT Ngôi Sao Nhỏ"
                         },
                         {
                           "maTruong": "79786L18",
                           "tenTruong": "Lớp MG Bé Thông Minh"
                         },
                         {
                           "maTruong": "79786L35",
                           "tenTruong": "Lớp MG Huỳnh Gia"
                         },
                         {
                           "maTruong": "79786L17",
                           "tenTruong": "Lớp MG Cát Tiên"
                         },
                         {
                           "maTruong": "79786L20",
                           "tenTruong": "Lớp MG Chú Ong Vàng"
                         },
                         {
                           "maTruong": "79786L26",
                           "tenTruong": "Lớp MG Tuổi Thơ Rực Rỡ 2"
                         },
                         {
                           "maTruong": "79786L25",
                           "tenTruong": "NT Hoa Sen"
                         },
                         {
                           "maTruong": "79786L32",
                           "tenTruong": "Lớp MG Gia Bảo"
                         },
                         {
                           "maTruong": "79786L30",
                           "tenTruong": "Lớp MG Thiên Thần"
                         },
                         {
                           "maTruong": "79786L31",
                           "tenTruong": "Lớp MG Phương Mỹ"
                         },
                         {
                           "maTruong": "79786L33",
                           "tenTruong": "Lớp MG Khánh Ngân"
                         },
                         {
                           "maTruong": "79786L34",
                           "tenTruong": "Lớp MG Hoa Sen Hồng"
                         },
                         {
                           "maTruong": "79786L37",
                           "tenTruong": "Lớp MG Mặt Trời Bé Con"
                         },
                         {
                           "maTruong": "79786L39",
                           "tenTruong": "Lớp MG Hoa Mặt Trời 2"
                         },
                         {
                           "maTruong": "79786X06",
                           "tenTruong": "Lớp MG Ánh Bình Minh"
                         },
                         {
                           "maTruong": "89786332",
                           "tenTruong": "MN Ngôi Sao Hưng Phát"
                         },
                         {
                           "maTruong": "79786L38",
                           "tenTruong": "Lớp MG Thăng Long"
                         },
                         {
                           "maTruong": "79786332",
                           "tenTruong": "MN Ngôi Sao Hưng Phát"
                         },
                         {
                           "maTruong": "79786338",
                           "tenTruong": "MN Tuổi Thơ"
                         },
                         {
                           "maTruong": "79786333",
                           "tenTruong": "MN Hoa Lan"
                         },
                         {
                           "maTruong": "79786334",
                           "tenTruong": "MN Hoa Sen"
                         },
                         {
                           "maTruong": "79786335",
                           "tenTruong": "MN Cầu Vồng 1"
                         },
                         {
                           "maTruong": "79786336",
                           "tenTruong": "MN Xanh"
                         },
                         {
                           "maTruong": "79786337",
                           "tenTruong": "MN Thái Hoàng Gia"
                         },
                         {
                           "maTruong": "79786X07",
                           "tenTruong": "Lớp MG Ánh Sao"
                         },
                         {
                           "maTruong": "79786X08",
                           "tenTruong": "Lớp MG Hạnh Phúc Trẻ Thơ"
                         },
                         {
                           "maTruong": "79786X09",
                           "tenTruong": "Lớp MG Nhà Của Bé"
                         },
                         {
                           "maTruong": "79786X10",
                           "tenTruong": "Lớp MG Trẻ Thơ"
                         },
                         {
                           "maTruong": "79786X11",
                           "tenTruong": "Lớp MG Bé Yêu"
                         },
                         {
                           "maTruong": "79786X12",
                           "tenTruong": "Lớp MG Huỳnh Gia 2"
                         },
                         {
                           "maTruong": "79786339",
                           "tenTruong": "MN Trí Công"
                         },
                         {
                           "maTruong": "79786340",
                           "tenTruong": "MN Sao Việt"
                         },
                         {
                           "maTruong": "79786X13",
                           "tenTruong": "Lớp MG Hồng Phát"
                         },
                         {
                           "maTruong": "79786344",
                           "tenTruong": "MN Tinh Hoa"
                         },
                         {
                           "maTruong": "79786342",
                           "tenTruong": "MN Chú Ong Nhỏ"
                         },
                         {
                           "maTruong": "79786343",
                           "tenTruong": "MN Thái Hoàng Gia 2"
                         },
                         {
                           "maTruong": "79786341",
                           "tenTruong": "MN Ước Mơ"
                         },
                         {
                           "maTruong": "7978601006",
                           "tenTruong": "NT Kiều Vân"
                         },
                         {
                           "maTruong": "7978601001",
                           "tenTruong": "MN Hoa Anh Đào Nhỏ"
                         },
                         {
                           "maTruong": "7978601002",
                           "tenTruong": "Lớp MN Hạnh Phúc"
                         },
                         {
                           "maTruong": "7978601003",
                           "tenTruong": "Lớp MG An An"
                         },
                         {
                           "maTruong": "7978601004",
                           "tenTruong": "Lớp MN Bé Yêu"
                         },
                         {
                           "maTruong": "7978601005",
                           "tenTruong": "Lớp MN Cánh Cam"
                         },
                         {
                           "maTruong": "7978602001",
                           "tenTruong": "TH Nguyễn Hồng Thế"
                         },
                         {
                           "maTruong": "7978601007",
                           "tenTruong": "MN Phần Lan Nhỏ - Cơ sở Nam Sài Gòn"
                         },
                         {
                           "maTruong": "7978601008",
                           "tenTruong": "Lớp MN Vườn Mặt Trời"
                         },
                         {
                           "maTruong": "7978601016",
                           "tenTruong": "Lớp MN  Cầu Vồng 1"
                         },
                         {
                           "maTruong": "7978601010",
                           "tenTruong": "Lớp MN Tuổi Thơ Rực Rỡ 2"
                         },
                         {
                           "maTruong": "7978601011",
                           "tenTruong": "Lớp MN Khủng Long Con"
                         },
                         {
                           "maTruong": "7978601012",
                           "tenTruong": "Lớp MN Thế Giới Mặt Trời"
                         },
                         {
                           "maTruong": "7978601013",
                           "tenTruong": "Lớp MN Sài Gòn An Phước"
                         },
                         {
                           "maTruong": "7978601014",
                           "tenTruong": "Lớp MN Vườn Nắng"
                         },
                         {
                           "maTruong": "7978601015",
                           "tenTruong": "Lớp MN Vườn Tuổi Thơ"
                         },
                         {
                           "maTruong": "7978601018",
                           "tenTruong": "Lớp MN Sao Nhỏ"
                         },
                         {
                           "maTruong": "7978601020",
                           "tenTruong": "Lớp MN Cầu Vồng 2"
                         },
                         {
                           "maTruong": "7978601019",
                           "tenTruong": "Lớp MN Hoa Quỳnh"
                         },
                         {
                           "maTruong": "7978601025",
                           "tenTruong": "Lớp MN Bé Vui 3"
                         },
                         {
                           "maTruong": "7978601022",
                           "tenTruong": "Lớp MN Trẻ Thơ Hạnh Phúc"
                         },
                         {
                           "maTruong": "7978601023",
                           "tenTruong": "Lớp MN Mặt Trời Bé Thơ"
                         },
                         {
                           "maTruong": "7978601024",
                           "tenTruong": "Lớp MN Đôi Tay Nhỏ"
                         },
                         {
                           "maTruong": "7978601026",
                           "tenTruong": "MN Mỹ Việt"
                         },
                         {
                           "maTruong": "7978601027",
                           "tenTruong": "Lớp MN Mặt Trời Hạnh Phúc"
                         },
                         {
                           "maTruong": "7978601028",
                           "tenTruong": "Lớp MN Việt Mỹ"
                         },
                         {
                           "maTruong": "7978601029",
                           "tenTruong": "Lớp MN Chồi Xanh"
                         },
                         {
                           "maTruong": "7978601030",
                           "tenTruong": "Lớp MN Hoa Cầu Vồng"
                         }
                       ]
                     },
                     {
                       "maPhong": null,
                       "tenPhong": "Quận Tân Phú",
                       "truongData": [
                         {
                           "maTruong": "790007C2",
                           "tenTruong": "THPT Lê Trọng Tấn"
                         },
                         {
                           "maTruong": "79000862",
                           "tenTruong": "THPT Minh Đức"
                         },
                         {
                           "maTruong": "79000785",
                           "tenTruong": "THPT Nhân Việt"
                         },
                         {
                           "maTruong": "79000727",
                           "tenTruong": "THPT Tân Bình"
                         },
                         {
                           "maTruong": "79000758",
                           "tenTruong": "THPT Tây Thạnh"
                         },
                         {
                           "maTruong": "79000863",
                           "tenTruong": "THPT Thành Nhân"
                         },
                         {
                           "maTruong": "79000799",
                           "tenTruong": "THPT Trần Cao Vân"
                         },
                         {
                           "maTruong": "79000729",
                           "tenTruong": "THPT Trần Phú"
                         },
                         {
                           "maTruong": "790007A0",
                           "tenTruong": "THPT Trần Quốc Toản"
                         },
                         {
                           "maTruong": "79000915",
                           "tenTruong": "ĐH Công Nghiệp Thực phẩm Tp.HCM"
                         },
                         {
                           "maTruong": "79767901",
                           "tenTruong": "TT GDTX Q Tân Phú"
                         },
                         {
                           "maTruong": "79000701",
                           "tenTruong": "THPT Bùi Thị Xuân"
                         },
                         {
                           "maTruong": "790007A4",
                           "tenTruong": "THCS và THPT Nam Việt"
                         },
                         {
                           "maTruong": "79000830",
                           "tenTruong": "THCS và THPT Trí Đức"
                         },
                         {
                           "maTruong": "79000834",
                           "tenTruong": "THCS và THPT Nhân Văn"
                         },
                         {
                           "maTruong": "79000856",
                           "tenTruong": "THCS và THPT Khai Minh"
                         },
                         {
                           "maTruong": "79000812",
                           "tenTruong": "THCS và THPT Nguyễn Bỉnh Khiêm"
                         },
                         {
                           "maTruong": "79000912",
                           "tenTruong": "Trung tâm Giáo dục phổ thông Đại học Công nghiệp Thực phẩm"
                         },
                         {
                           "maTruong": "79767601",
                           "tenTruong": "Tiểu học, THCS và THPT Hòa Bình"
                         },
                         {
                           "maTruong": "79000767",
                           "tenTruong": "Tiểu học, THCS và THPT Quốc Văn Sài Gòn"
                         },
                         {
                           "maTruong": "79000822",
                           "tenTruong": "THCS-THPT Hồng Đức"
                         },
                         {
                           "maTruong": "79000858",
                           "tenTruong": "Tiểu học, THCS và THPT Tân Phú"
                         },
                         {
                           "maTruong": "79000868",
                           "tenTruong": "THCS và THPT ĐINH TIÊN HOÀNG"
                         },
                         {
                           "maTruong": "79000888",
                           "tenTruong": "TH - THCS - THPT Lê Thánh Tông"
                         },
                         {
                           "maTruong": "7900004023",
                           "tenTruong": "Trường Chuyên biệt Bình Minh (Tân Phú)"
                         },
                         {
                           "maTruong": "7900004002",
                           "tenTruong": "THPT Đông Á"
                         },
                         {
                           "maTruong": "79000798",
                           "tenTruong": "THPT Vĩnh Viễn"
                         },
                         {
                           "maTruong": "790007B5",
                           "tenTruong": "THPT Thủ Khoa Huân"
                         }
                       ]
                     },
                     {
                       "maPhong": "767",
                       "tenPhong": "Quận Tân Phú",
                       "truongData": [
                         {
                           "maTruong": "79767411",
                           "tenTruong": "TH Âu Cơ"
                         },
                         {
                           "maTruong": "79767421",
                           "tenTruong": "TH Đinh Bộ Lĩnh"
                         },
                         {
                           "maTruong": "79767401",
                           "tenTruong": "TH Đoàn Thị Điểm"
                         },
                         {
                           "maTruong": "79767407",
                           "tenTruong": "TH Duy Tân"
                         },
                         {
                           "maTruong": "79767417",
                           "tenTruong": "TH Hiệp Tân"
                         },
                         {
                           "maTruong": "79767409",
                           "tenTruong": "TH Hồ Văn Cường"
                         },
                         {
                           "maTruong": "79767410",
                           "tenTruong": "TH Huỳnh Văn Chính"
                         },
                         {
                           "maTruong": "79767402",
                           "tenTruong": "TH Lê Lai"
                         },
                         {
                           "maTruong": "79767422",
                           "tenTruong": "TH Lê Thánh Tông"
                         },
                         {
                           "maTruong": "79767405",
                           "tenTruong": "TH Lê Văn Tám"
                         },
                         {
                           "maTruong": "79767406",
                           "tenTruong": "TH Phan Chu Trinh"
                         },
                         {
                           "maTruong": "79767420",
                           "tenTruong": "TH Tân Hóa"
                         },
                         {
                           "maTruong": "79767413",
                           "tenTruong": "TH Tân Hương"
                         },
                         {
                           "maTruong": "79767416",
                           "tenTruong": "TH Tân Sơn Nhì"
                         },
                         {
                           "maTruong": "79767419",
                           "tenTruong": "TH Tân Thới"
                         },
                         {
                           "maTruong": "79767403",
                           "tenTruong": "TH Tô Vĩnh Diện"
                         },
                         {
                           "maTruong": "79767408",
                           "tenTruong": "TH Võ Thị Sáu"
                         },
                         {
                           "maTruong": "79767501",
                           "tenTruong": "THCS Đặng Trần Côn"
                         },
                         {
                           "maTruong": "79767504",
                           "tenTruong": "THCS Đồng Khởi"
                         },
                         {
                           "maTruong": "79767512",
                           "tenTruong": "THCS HOÀNG DIỆU"
                         },
                         {
                           "maTruong": "79767505",
                           "tenTruong": "THCS Hùng Vương"
                         },
                         {
                           "maTruong": "79767506",
                           "tenTruong": "THCS Lê Anh Xuân"
                         },
                         {
                           "maTruong": "79767502",
                           "tenTruong": "THCS Lê Lợi"
                         },
                         {
                           "maTruong": "79767511",
                           "tenTruong": "THCS Nguyễn Huệ"
                         },
                         {
                           "maTruong": "79767507",
                           "tenTruong": "THCS Phan Bội Châu"
                         },
                         {
                           "maTruong": "79767508",
                           "tenTruong": "THCS Tân Thới Hòa"
                         },
                         {
                           "maTruong": "79767509",
                           "tenTruong": "THCS Thoại Ngọc Hầu"
                         },
                         {
                           "maTruong": "79767513",
                           "tenTruong": "THCS Tôn Thất Tùng"
                         },
                         {
                           "maTruong": "79767510",
                           "tenTruong": "THCS Trần Quang Khải"
                         },
                         {
                           "maTruong": "79767503",
                           "tenTruong": "THCS Võ Thành Trang"
                         },
                         {
                           "maTruong": "79767418",
                           "tenTruong": "TiH - THCS Hồng Ngọc"
                         },
                         {
                           "maTruong": "79000868",
                           "tenTruong": "THCS - THPT Đinh Tiên Hoàng"
                         },
                         {
                           "maTruong": "79000822",
                           "tenTruong": "THCS - THPT Hồng Đức"
                         },
                         {
                           "maTruong": "79000856",
                           "tenTruong": "THCS - THPT Khai Minh"
                         },
                         {
                           "maTruong": "79000834",
                           "tenTruong": "THCS - THPT Nhân Văn"
                         },
                         {
                           "maTruong": "79000858",
                           "tenTruong": "THCS - THPT Tân Phú"
                         },
                         {
                           "maTruong": "79000830",
                           "tenTruong": "THCS - THPT Trí Đức"
                         },
                         {
                           "maTruong": "790007A4",
                           "tenTruong": "THCS ,THPT Nam Việt"
                         },
                         {
                           "maTruong": "79767601",
                           "tenTruong": "TH - THCS - THPT Hòa Bình"
                         },
                         {
                           "maTruong": "79000767",
                           "tenTruong": "Tiểu học, THCS và THPT Quốc Văn Sài Gòn"
                         },
                         {
                           "maTruong": "79767327",
                           "tenTruong": "MG Tuổi Hồng"
                         },
                         {
                           "maTruong": "79767W46",
                           "tenTruong": "MN Mai Hồng"
                         },
                         {
                           "maTruong": "79767352",
                           "tenTruong": "Mầm Non Hải Yến"
                         },
                         {
                           "maTruong": "79767302",
                           "tenTruong": "Mầm non Hoa Hồng"
                         },
                         {
                           "maTruong": "79767338",
                           "tenTruong": "Mầm non Hoa Lan"
                         },
                         {
                           "maTruong": "79767306",
                           "tenTruong": "Mầm non Hoàng Anh"
                         },
                         {
                           "maTruong": "797671A0",
                           "tenTruong": "MẦM NON Misa"
                         },
                         {
                           "maTruong": "79767316",
                           "tenTruong": "MN ABC"
                         },
                         {
                           "maTruong": "79767319",
                           "tenTruong": "MN Ánh Sáng"
                         },
                         {
                           "maTruong": "79767328",
                           "tenTruong": "MN ATY"
                         },
                         {
                           "maTruong": "79767340",
                           "tenTruong": "MN Bảo Bảo"
                         },
                         {
                           "maTruong": "79767303",
                           "tenTruong": "MN Bông Sen"
                         },
                         {
                           "maTruong": "79767358",
                           "tenTruong": "MN Cây Táo"
                         },
                         {
                           "maTruong": "79767315",
                           "tenTruong": "MN Đức Minh"
                         },
                         {
                           "maTruong": "79767304",
                           "tenTruong": "MN Hoa Anh Đào"
                         },
                         {
                           "maTruong": "79767314",
                           "tenTruong": "MN Hoà Bình"
                         },
                         {
                           "maTruong": "79767W16",
                           "tenTruong": "MN Hoa Mai Lan"
                         },
                         {
                           "maTruong": "79767312",
                           "tenTruong": "MN Họa Mi"
                         },
                         {
                           "maTruong": "79767354",
                           "tenTruong": "MN Hướng Dương"
                         },
                         {
                           "maTruong": "79767320",
                           "tenTruong": "MN Khôi Nguyên"
                         },
                         {
                           "maTruong": "79767329",
                           "tenTruong": "MN Mặt Trời Nhỏ"
                         },
                         {
                           "maTruong": "79767W36",
                           "tenTruong": "MN Minh Quang"
                         },
                         {
                           "maTruong": "79767W25",
                           "tenTruong": "MN Minh Trí"
                         },
                         {
                           "maTruong": "79767356",
                           "tenTruong": "MN Nam Long"
                         },
                         {
                           "maTruong": "79767W65",
                           "tenTruong": "MN Ngôi Nhà Hạnh Phúc"
                         },
                         {
                           "maTruong": "79767343",
                           "tenTruong": "MN Ngôi Sao"
                         },
                         {
                           "maTruong": "79767313",
                           "tenTruong": "MN Nguyễn Thị Tú"
                         },
                         {
                           "maTruong": "79767310",
                           "tenTruong": "MN Nhiêu Lộc"
                         },
                         {
                           "maTruong": "79767305",
                           "tenTruong": "MN Phượng Hồng"
                         },
                         {
                           "maTruong": "79767323",
                           "tenTruong": "MN Quỳnh Anh"
                         },
                         {
                           "maTruong": "79767308",
                           "tenTruong": "MN Rạng Đông"
                         },
                         {
                           "maTruong": "79767359",
                           "tenTruong": "MN Sơn Định 2"
                         },
                         {
                           "maTruong": "79767W76",
                           "tenTruong": "MN Táo Hồng"
                         },
                         {
                           "maTruong": "79767336",
                           "tenTruong": "MN Tây Thạnh"
                         },
                         {
                           "maTruong": "79767W33",
                           "tenTruong": "MN Thần Đồng"
                         },
                         {
                           "maTruong": "79767322",
                           "tenTruong": "MN Thanh Tâm"
                         },
                         {
                           "maTruong": "79767W51",
                           "tenTruong": "MN Thanh Tâm 2"
                         },
                         {
                           "maTruong": "79767W08",
                           "tenTruong": "MN Thiên Ân"
                         },
                         {
                           "maTruong": "79767309",
                           "tenTruong": "MN Thiên Lý"
                         },
                         {
                           "maTruong": "79767325",
                           "tenTruong": "MN Thiên Thần"
                         },
                         {
                           "maTruong": "79767307",
                           "tenTruong": "MN Thủy Tiên"
                         },
                         {
                           "maTruong": "79767339",
                           "tenTruong": "MN Tinh Tú"
                         },
                         {
                           "maTruong": "79767318",
                           "tenTruong": "MN Trí Đức 1"
                         },
                         {
                           "maTruong": "79767326",
                           "tenTruong": "MN Trí Đức 2"
                         },
                         {
                           "maTruong": "79767355",
                           "tenTruong": "MN Tuổi Thơ"
                         },
                         {
                           "maTruong": "79767341",
                           "tenTruong": "MN Vàng Anh"
                         },
                         {
                           "maTruong": "79767324",
                           "tenTruong": "MN Việt Mỹ"
                         },
                         {
                           "maTruong": "79767335",
                           "tenTruong": "MN Việt Mỹ Úc"
                         },
                         {
                           "maTruong": "79767353",
                           "tenTruong": "MNTT Ban Mai"
                         },
                         {
                           "maTruong": "79767351",
                           "tenTruong": "MNTT Phương Đông"
                         },
                         {
                           "maTruong": "79767350",
                           "tenTruong": "MNTT Sơn Ca"
                         },
                         {
                           "maTruong": "79767WF7",
                           "tenTruong": "Ngôi Nhà Hạnh Phúc 2"
                         },
                         {
                           "maTruong": "79767W52",
                           "tenTruong": "Trường Mầm Non Ánh Dương"
                         },
                         {
                           "maTruong": "79767W19",
                           "tenTruong": "Trường mầm non Mẫu Tâm"
                         },
                         {
                           "maTruong": "79767357",
                           "tenTruong": "Trường MN ABI 2"
                         },
                         {
                           "maTruong": "79767W21",
                           "tenTruong": "LỚP MẪU GIÁO  LINH AN"
                         },
                         {
                           "maTruong": "79767WC2",
                           "tenTruong": "LỚP MẪU GIÁO  NGÔI SAO"
                         },
                         {
                           "maTruong": "79767WD8",
                           "tenTruong": "lớp mẫu giáo  Thiên Thần Việt"
                         },
                         {
                           "maTruong": "79767WE3",
                           "tenTruong": "LỚP MẪU GIÁO CHỒI NON"
                         },
                         {
                           "maTruong": "79767WE5",
                           "tenTruong": "Lớp Mẫu Giáo Hồng Ngọc"
                         },
                         {
                           "maTruong": "79767WE9",
                           "tenTruong": "Lớp Mẫu giáo Khang Phú"
                         },
                         {
                           "maTruong": "79767WA6",
                           "tenTruong": "Lớp Mẫu giáo Song Hương"
                         },
                         {
                           "maTruong": "79767W39",
                           "tenTruong": "Lớp Mẫu Gíao Thúy Quỳnh"
                         },
                         {
                           "maTruong": "79767WB4",
                           "tenTruong": "Lớp MG Bé Xinh"
                         },
                         {
                           "maTruong": "79767LG2",
                           "tenTruong": "LỚP MG CHIẾC LÁ NHỎ 2"
                         },
                         {
                           "maTruong": "79767WB3",
                           "tenTruong": "Lớp MG Gia Linh"
                         },
                         {
                           "maTruong": "79767WE6",
                           "tenTruong": "Lớp MG Hoa Sữa"
                         },
                         {
                           "maTruong": "79767WG1",
                           "tenTruong": "Lớp MG Thiên Hồng 2"
                         },
                         {
                           "maTruong": "79767WB5",
                           "tenTruong": "MG Tuổi Thần Tiên"
                         },
                         {
                           "maTruong": "79767WD4",
                           "tenTruong": "MN An Bình"
                         },
                         {
                           "maTruong": "79767WC4",
                           "tenTruong": "MN Bam Bi"
                         },
                         {
                           "maTruong": "79767W99",
                           "tenTruong": "MN Bảo Ngọc"
                         },
                         {
                           "maTruong": "79767WC3",
                           "tenTruong": "MN Bảo Trâm"
                         },
                         {
                           "maTruong": "79767WC8",
                           "tenTruong": "MN Bé Ngoan 1"
                         },
                         {
                           "maTruong": "79767WE8",
                           "tenTruong": "MN Bi Bo"
                         },
                         {
                           "maTruong": "79767WB6",
                           "tenTruong": "MN Bông Hồng Nhỏ"
                         },
                         {
                           "maTruong": "79767WB7",
                           "tenTruong": "MN Khải Tâm"
                         },
                         {
                           "maTruong": "79767WB1",
                           "tenTruong": "MN Minh Nhân"
                         },
                         {
                           "maTruong": "79767WD6",
                           "tenTruong": "MN Nam Á"
                         },
                         {
                           "maTruong": "79767WD1",
                           "tenTruong": "MN Thiên Hựu"
                         },
                         {
                           "maTruong": "79767WC7",
                           "tenTruong": "MN Xuân Phong"
                         },
                         {
                           "maTruong": "79767WE4",
                           "tenTruong": "MNTT BaBy"
                         },
                         {
                           "maTruong": "79767WD9",
                           "tenTruong": "MNTT Thiên Nhiên"
                         },
                         {
                           "maTruong": "79767W07",
                           "tenTruong": "NHÓM NHÀ TRẺ THIÊN AN"
                         },
                         {
                           "maTruong": "79767WE1",
                           "tenTruong": "Nhóm Trẻ Chiếc Lá Nhỏ"
                         },
                         {
                           "maTruong": "79767W80",
                           "tenTruong": "Nhóm Trẻ Chim Non"
                         },
                         {
                           "maTruong": "79767WC5",
                           "tenTruong": "Nhóm trẻ Đăng Khoa"
                         },
                         {
                           "maTruong": "79767W44",
                           "tenTruong": "NTGĐ Ánh Mai"
                         },
                         {
                           "maTruong": "79767W53",
                           "tenTruong": "NTGĐ Ánh Ngọc"
                         },
                         {
                           "maTruong": "79767W56",
                           "tenTruong": "NTGĐ Bảo Trân"
                         },
                         {
                           "maTruong": "79767W20",
                           "tenTruong": "NTGĐ Bé By"
                         },
                         {
                           "maTruong": "79767WA5",
                           "tenTruong": "NTGĐ Bé Yêu"
                         },
                         {
                           "maTruong": "79767W93",
                           "tenTruong": "NTGĐ Bình Minh"
                         },
                         {
                           "maTruong": "79767W69",
                           "tenTruong": "NTGĐ Bông Hồng"
                         },
                         {
                           "maTruong": "79767W59",
                           "tenTruong": "NTGĐ Búp Sen Hồng"
                         },
                         {
                           "maTruong": "797671A1",
                           "tenTruong": "NTGĐ Đan Vy"
                         },
                         {
                           "maTruong": "79767W35",
                           "tenTruong": "NTGĐ Diễm Hằng"
                         },
                         {
                           "maTruong": "79767W05",
                           "tenTruong": "NTGĐ ĐôRêMi"
                         },
                         {
                           "maTruong": "79767WA3",
                           "tenTruong": "NTGĐ Đức Hạnh"
                         },
                         {
                           "maTruong": "797671A2",
                           "tenTruong": "NTGĐ Gấu Trúc"
                         },
                         {
                           "maTruong": "79767W84",
                           "tenTruong": "NTGĐ Hải Âu"
                         },
                         {
                           "maTruong": "79767W86",
                           "tenTruong": "NTGĐ Hiệp Tân"
                         },
                         {
                           "maTruong": "79767W45",
                           "tenTruong": "NTGĐ Hoa Bi Bi"
                         },
                         {
                           "maTruong": "79767W73",
                           "tenTruong": "NTGĐ Hoa Hồng Nhỏ"
                         },
                         {
                           "maTruong": "79767W48",
                           "tenTruong": "NTGĐ Hoa Huệ"
                         },
                         {
                           "maTruong": "79767W71",
                           "tenTruong": "NTGĐ Hoa Hướng Dương"
                         },
                         {
                           "maTruong": "79767WF3",
                           "tenTruong": "NTGĐ Hoa Mai"
                         },
                         {
                           "maTruong": "79767W95",
                           "tenTruong": "NTGĐ Hoa Sen"
                         },
                         {
                           "maTruong": "79767W47",
                           "tenTruong": "NTGĐ Hoàng Hạc"
                         },
                         {
                           "maTruong": "79767W18",
                           "tenTruong": "NTGĐ Hoàng Mai"
                         },
                         {
                           "maTruong": "79767W87",
                           "tenTruong": "NTGĐ Hòang Yến"
                         },
                         {
                           "maTruong": "79767W22",
                           "tenTruong": "NTGĐ Hồng Ân"
                         },
                         {
                           "maTruong": "79767W13",
                           "tenTruong": "NTGĐ Hồng Nhung"
                         },
                         {
                           "maTruong": "79767W24",
                           "tenTruong": "NTGĐ Hương Sen"
                         },
                         {
                           "maTruong": "79767W68",
                           "tenTruong": "NTGĐ Khai Tâm"
                         },
                         {
                           "maTruong": "79767W41",
                           "tenTruong": "NTGĐ Khánh Hà"
                         },
                         {
                           "maTruong": "79767W63",
                           "tenTruong": "NTGĐ Liên Hoa"
                         },
                         {
                           "maTruong": "79767W89",
                           "tenTruong": "NTGĐ Mi Mi"
                         },
                         {
                           "maTruong": "79767W28",
                           "tenTruong": "NTGĐ Minh Huy"
                         },
                         {
                           "maTruong": "79767W01",
                           "tenTruong": "NTGĐ Minh Nguyệt"
                         },
                         {
                           "maTruong": "79767W32",
                           "tenTruong": "NTGĐ Minh Tuyết"
                         },
                         {
                           "maTruong": "79767W42",
                           "tenTruong": "NTGĐ Minh Uyên"
                         },
                         {
                           "maTruong": "79767W92",
                           "tenTruong": "NTGĐ Nai By"
                         },
                         {
                           "maTruong": "79767W83",
                           "tenTruong": "NTGĐ Nắng Hồng"
                         },
                         {
                           "maTruong": "79767W88",
                           "tenTruong": "NTGĐ Nắng Mai"
                         },
                         {
                           "maTruong": "79767W70",
                           "tenTruong": "NTGĐ Phương Uyên"
                         },
                         {
                           "maTruong": "79767W09",
                           "tenTruong": "NTGĐ Quỳnh Anh"
                         },
                         {
                           "maTruong": "79767W66",
                           "tenTruong": "NTGĐ Quỳnh Hương"
                         },
                         {
                           "maTruong": "79767W15",
                           "tenTruong": "NTGĐ Quỳnh My"
                         },
                         {
                           "maTruong": "79767W37",
                           "tenTruong": "NTGĐ Quỳnh Tiên"
                         },
                         {
                           "maTruong": "79767W38",
                           "tenTruong": "NTGĐ Sao Mai"
                         },
                         {
                           "maTruong": "79767W61",
                           "tenTruong": "NTGĐ Sao Sáng"
                         },
                         {
                           "maTruong": "79767W74",
                           "tenTruong": "NTGĐ Sơn Ca"
                         },
                         {
                           "maTruong": "79767W31",
                           "tenTruong": "NTGĐ Sơn Định"
                         },
                         {
                           "maTruong": "79767198",
                           "tenTruong": "NTGĐ Su Su"
                         },
                         {
                           "maTruong": "79767W02",
                           "tenTruong": "NTGĐ Thành Nhân"
                         },
                         {
                           "maTruong": "79767W64",
                           "tenTruong": "NTGĐ Thào Nhi"
                         },
                         {
                           "maTruong": "79767WF1",
                           "tenTruong": "NTGĐ Thiên Hồng"
                         },
                         {
                           "maTruong": "79767W78",
                           "tenTruong": "NTGĐ Thiên Nga"
                         },
                         {
                           "maTruong": "79767W75",
                           "tenTruong": "NTGĐ Thiên Phúc"
                         },
                         {
                           "maTruong": "79767W29",
                           "tenTruong": "NTGĐ Thiên Thần Nhỏ"
                         },
                         {
                           "maTruong": "79767W58",
                           "tenTruong": "NTGĐ Thiên Thanh"
                         },
                         {
                           "maTruong": "79767W67",
                           "tenTruong": "NTGĐ Thủy Trúc"
                         },
                         {
                           "maTruong": "79767W40",
                           "tenTruong": "NTGĐ Tí Hon"
                         },
                         {
                           "maTruong": "79767W34",
                           "tenTruong": "NTGĐ Trân Châu"
                         },
                         {
                           "maTruong": "79767W04",
                           "tenTruong": "NTGĐ Trung Hiếu"
                         },
                         {
                           "maTruong": "79767W11",
                           "tenTruong": "NTGĐ Tuổi Ngọc"
                         },
                         {
                           "maTruong": "79767WF4",
                           "tenTruong": "NTGĐ Tuổi Xanh"
                         },
                         {
                           "maTruong": "79767W85",
                           "tenTruong": "NTGĐ Tường Vy"
                         },
                         {
                           "maTruong": "79767W90",
                           "tenTruong": "NTGĐ Út Cưng"
                         },
                         {
                           "maTruong": "79767WF6",
                           "tenTruong": "NTGĐ Việt Mỹ 2"
                         },
                         {
                           "maTruong": "79767WF2",
                           "tenTruong": "NTGĐ Xuân Mai"
                         },
                         {
                           "maTruong": "79767WE7",
                           "tenTruong": "NTMG Quang Thái"
                         },
                         {
                           "maTruong": "79767WF9",
                           "tenTruong": "Quang Thái 2"
                         },
                         {
                           "maTruong": "79767248",
                           "tenTruong": "Mầm non Hoa Mặt Trời"
                         },
                         {
                           "maTruong": "79767363",
                           "tenTruong": "Lớp MG Mi Sa 2"
                         },
                         {
                           "maTruong": "79767361",
                           "tenTruong": "Lớp MG Bay"
                         },
                         {
                           "maTruong": "79767362",
                           "tenTruong": "Lớp MG Bé Hạnh Phúc"
                         },
                         {
                           "maTruong": "79767247",
                           "tenTruong": "MG Hồng Ân"
                         },
                         {
                           "maTruong": "79767360",
                           "tenTruong": "MN Mặt Trời Nhỏ 2"
                         },
                         {
                           "maTruong": "79767249",
                           "tenTruong": "MN Nam Long 2"
                         },
                         {
                           "maTruong": "79767WG2",
                           "tenTruong": "Lớp MG Hoa Hướng Dương 1"
                         },
                         {
                           "maTruong": "79767WG3",
                           "tenTruong": "Lớp MG A TY 2"
                         },
                         {
                           "maTruong": "79767WG4",
                           "tenTruong": "Lớp MG Ước Mơ Của Trẻ"
                         },
                         {
                           "maTruong": "79767WG5",
                           "tenTruong": "Lớp MG Tân Việt Mỹ"
                         },
                         {
                           "maTruong": "79767364",
                           "tenTruong": "Mầm non Bé Gấu"
                         },
                         {
                           "maTruong": "79767365",
                           "tenTruong": "Lớp Mẫu Giáo Ngôi Nhà Hạnh Phúc 3"
                         },
                         {
                           "maTruong": "79767366",
                           "tenTruong": "Lớp Mẫu Giáo Hành Trình Hạnh Phúc"
                         },
                         {
                           "maTruong": "79767367",
                           "tenTruong": "Lớp Mẫu Giáo Thành Phố Tuổi Thơ"
                         },
                         {
                           "maTruong": "79767514",
                           "tenTruong": "THCS NGUYỄN TRÃI"
                         },
                         {
                           "maTruong": "79767369",
                           "tenTruong": "Mầm non Cát Tường"
                         },
                         {
                           "maTruong": "79767101",
                           "tenTruong": "Nhóm trẻ Bé Hạnh Phúc"
                         },
                         {
                           "maTruong": "79767065",
                           "tenTruong": "Lớp mẫu giáo Hoa Hướng Dương 2"
                         },
                         {
                           "maTruong": "79767086",
                           "tenTruong": "Lớp mẫu giáo Ngôi Nhà Bé Thơ"
                         },
                         {
                           "maTruong": "79767231",
                           "tenTruong": "Lớp mẫu giáo Hoa Hướng Dương 3"
                         },
                         {
                           "maTruong": "7976701001",
                           "tenTruong": "Lớp Mầm non Việt Anh"
                         },
                         {
                           "maTruong": "7976701002",
                           "tenTruong": "Lớp Mầm non Trẻ Thơ"
                         },
                         {
                           "maTruong": "7976701003",
                           "tenTruong": "Lớp Mầm non Thiên Thần Bé Thơ"
                         },
                         {
                           "maTruong": "7976701004",
                           "tenTruong": "Lớp Mầm non Những Ngón Tay Vui"
                         },
                         {
                           "maTruong": "7976701005",
                           "tenTruong": "Lớp MG Cây Táo 2"
                         },
                         {
                           "maTruong": "7976701008",
                           "tenTruong": "Lớp MG Cá Chép Nhỏ"
                         },
                         {
                           "maTruong": "7976701007",
                           "tenTruong": "Lớp Mầm non Hoa Hướng Dương 1"
                         },
                         {
                           "maTruong": "79767270",
                           "tenTruong": "Lớp MG Em Bé Hoàng Gia"
                         },
                         {
                           "maTruong": "79767176",
                           "tenTruong": "Lớp Mầm non Ánh Bình Minh"
                         },
                         {
                           "maTruong": "79767066",
                           "tenTruong": "Lớp Mầm non Hành Trình Hạnh Phúc 2"
                         },
                         {
                           "maTruong": "79767031",
                           "tenTruong": "Lớp Mầm non Trẻ Tài Năng"
                         },
                         {
                           "maTruong": "79767118",
                           "tenTruong": "Nhóm trẻ Ngôi Nhà Hạnh Phúc 3"
                         },
                         {
                           "maTruong": "7976701010",
                           "tenTruong": "Lớp MN Vườn Trẻ Em"
                         },
                         {
                           "maTruong": "79767368",
                           "tenTruong": "Mầm non Trạng Nguyên"
                         },
                         {
                           "maTruong": "7976701024",
                           "tenTruong": "Lớp MN Vòng Nguyệt Quế"
                         },
                         {
                           "maTruong": "7976701025",
                           "tenTruong": "Lớp MN Thiên Phúc"
                         },
                         {
                           "maTruong": "7976701026",
                           "tenTruong": "Lớp Mầm non Ngôi Nhà Nhỏ"
                         },
                         {
                           "maTruong": "7976701027",
                           "tenTruong": "Lớp Mẫu giáo Ngôi Nhà Trên Cây"
                         },
                         {
                           "maTruong": "7976701028",
                           "tenTruong": "Lớp Mẫu giáo Cầu Vòng"
                         },
                         {
                           "maTruong": "7976701029",
                           "tenTruong": "Lớp Mẫu giáo Vườn Trẻ Thơ"
                         },
                         {
                           "maTruong": "7976701030",
                           "tenTruong": "Nhóm trẻ Hồng Ngọc 2"
                         },
                         {
                           "maTruong": "7976701019",
                           "tenTruong": "Nhóm Trẻ Bảo Yến"
                         },
                         {
                           "maTruong": "7976701011",
                           "tenTruong": "Lớp MN Mây Trắng"
                         },
                         {
                           "maTruong": "7976701012",
                           "tenTruong": "Lớp MN Đô Rê Mi 2"
                         },
                         {
                           "maTruong": "7976701013",
                           "tenTruong": "Lớp MG Trái Đất Xanh"
                         },
                         {
                           "maTruong": "7976701014",
                           "tenTruong": "Lớp MN Phương Minh"
                         },
                         {
                           "maTruong": "7976701015",
                           "tenTruong": "Lớp MG Phần Lan Nhỏ"
                         },
                         {
                           "maTruong": "7976701016",
                           "tenTruong": "Lớp MG Làng Nắng"
                         },
                         {
                           "maTruong": "7976701021",
                           "tenTruong": "Lớp MN Hoa Mai Lan"
                         },
                         {
                           "maTruong": "7976701022",
                           "tenTruong": "Lớp MG Hoa Mai Lan 2"
                         },
                         {
                           "maTruong": "7976701023",
                           "tenTruong": "Nhóm Trẻ Trẻ Tài Năng 2"
                         },
                         {
                           "maTruong": "7976701020",
                           "tenTruong": "Lớp MN Dãy Núi Xanh"
                         },
                         {
                           "maTruong": "7976701006",
                           "tenTruong": "Nhóm trẻ  Cây Táo"
                         },
                         {
                           "maTruong": "79767092",
                           "tenTruong": "Lớp Mầm non Bay 2"
                         },
                         {
                           "maTruong": "7976701033",
                           "tenTruong": "Lớp Mầm non Tinh Anh"
                         },
                         {
                           "maTruong": "7976701035",
                           "tenTruong": "Lớp Mầm non Ngôi Nhà Trẻ Thơ"
                         },
                         {
                           "maTruong": "7976701036",
                           "tenTruong": "Lớp Mầm non Học Viện Hoa Kỳ"
                         },
                         {
                           "maTruong": "7976701041",
                           "tenTruong": "Lớp Mầm non Phương Minh 2"
                         },
                         {
                           "maTruong": "7976701042",
                           "tenTruong": "Lớp Mầm non Cúc Ban Mai"
                         },
                         {
                           "maTruong": "7976701043",
                           "tenTruong": "Lớp Mầm non Bi Bo 2"
                         },
                         {
                           "maTruong": "7976701044",
                           "tenTruong": "Lớp Mẫu giáo Ngôi Nhà Bé Thơ"
                         }
                       ]
                     },
                     {
                       "maPhong": "774",
                       "tenPhong": "Huyện Bình Chánh",
                       "truongData": [
                         {
                           "maTruong": "79774404",
                           "tenTruong": "TH Bàu Sen"
                         }
                       ]
                     },
                     {
                       "maPhong": null,
                       "tenPhong": "Huyện Hóc Môn",
                       "truongData": [
                         {
                           "maTruong": "79000753",
                           "tenTruong": "THPT Bà Điểm"
                         },
                         {
                           "maTruong": "79000740",
                           "tenTruong": "THPT Lý Thường Kiệt"
                         },
                         {
                           "maTruong": "79000739",
                           "tenTruong": "THPT Nguyễn Hữu Cầu"
                         },
                         {
                           "maTruong": "79000761",
                           "tenTruong": "THPT Nguyễn Hữu Tiến"
                         },
                         {
                           "maTruong": "79000760",
                           "tenTruong": "THPT Nguyễn Văn Cừ"
                         },
                         {
                           "maTruong": "790007B1",
                           "tenTruong": "THPT Phạm Văn Sáng"
                         },
                         {
                           "maTruong": "79784901",
                           "tenTruong": "TT GDTX H Hóc Môn"
                         },
                         {
                           "maTruong": "79000918",
                           "tenTruong": "TT GDTX Thanh Niên Xung Phong"
                         },
                         {
                           "maTruong": "790007C6",
                           "tenTruong": "THPT Hồ Thị Bi"
                         },
                         {
                           "maTruong": "79000785",
                           "tenTruong": "TH, THCS, THPT Tre Việt"
                         }
                       ]
                     },
                     {
                       "maPhong": "774",
                       "tenPhong": "Quận 5",
                       "truongData": [
                         {
                           "maTruong": "79774411",
                           "tenTruong": "TH Chính Nghĩa"
                         },
                         {
                           "maTruong": "79774406",
                           "tenTruong": "TH Chương Dương"
                         },
                         {
                           "maTruong": "79774401",
                           "tenTruong": "TH Hàm Tử"
                         },
                         {
                           "maTruong": "79774416",
                           "tenTruong": "TH Hùng Vương"
                         },
                         {
                           "maTruong": "79774407",
                           "tenTruong": "TH Huỳnh Kiến Hoa"
                         },
                         {
                           "maTruong": "79774403",
                           "tenTruong": "TH Huỳnh Mẫn Đạt"
                         },
                         {
                           "maTruong": "79774414",
                           "tenTruong": "TH Lê Đình Chinh"
                         },
                         {
                           "maTruong": "79774405",
                           "tenTruong": "TH Lê Văn Tám"
                         },
                         {
                           "maTruong": "79774410",
                           "tenTruong": "TH Lý Cảnh Hớn"
                         },
                         {
                           "maTruong": "79774412",
                           "tenTruong": "TH Minh Đạo"
                         },
                         {
                           "maTruong": "79774415",
                           "tenTruong": "TH Nguyễn Đức Cảnh"
                         },
                         {
                           "maTruong": "79774413",
                           "tenTruong": "TH Nguyễn Viết Xuân"
                         },
                         {
                           "maTruong": "79774408",
                           "tenTruong": "TH Phạm Hồng Thái"
                         },
                         {
                           "maTruong": "79774402",
                           "tenTruong": "TH Trần Bình Trọng"
                         },
                         {
                           "maTruong": "79774409",
                           "tenTruong": "TH Trần Quốc Toản"
                         },
                         {
                           "maTruong": "79774424",
                           "tenTruong": "TH Văn Lang"
                         },
                         {
                           "maTruong": "79774501",
                           "tenTruong": "THCS Ba Đình"
                         },
                         {
                           "maTruong": "79774503",
                           "tenTruong": "THCS Hồng Bàng"
                         },
                         {
                           "maTruong": "79774502",
                           "tenTruong": "THCS Kim Đồng"
                         },
                         {
                           "maTruong": "79774505",
                           "tenTruong": "THCS Lý Phong"
                         },
                         {
                           "maTruong": "79774506",
                           "tenTruong": "THCS Mạch Kiếm Hùng"
                         },
                         {
                           "maTruong": "79774504",
                           "tenTruong": "THCS Trần Bội Cơ"
                         },
                         {
                           "maTruong": "79774601",
                           "tenTruong": "THCS Văn Lang"
                         },
                         {
                           "maTruong": "79000814",
                           "tenTruong": "THCS và THPT An Đông"
                         },
                         {
                           "maTruong": "79000855",
                           "tenTruong": "THCS, THPT Quang Trung Nguyễn Huệ"
                         },
                         {
                           "maTruong": "79774507",
                           "tenTruong": "THPT Trần Hữu Trang"
                         },
                         {
                           "maTruong": "79000853",
                           "tenTruong": "Trung học thực hành Sài Gòn"
                         },
                         {
                           "maTruong": "79774201",
                           "tenTruong": "MG 2B"
                         },
                         {
                           "maTruong": "79774206",
                           "tenTruong": "MG Mai Anh"
                         },
                         {
                           "maTruong": "79774207",
                           "tenTruong": "MG Văn Lang"
                         },
                         {
                           "maTruong": "79774309",
                           "tenTruong": "MN  8"
                         },
                         {
                           "maTruong": "79774301",
                           "tenTruong": "MN 1"
                         },
                         {
                           "maTruong": "79774311",
                           "tenTruong": "MN 10"
                         },
                         {
                           "maTruong": "79774312",
                           "tenTruong": "MN 11"
                         },
                         {
                           "maTruong": "79774313",
                           "tenTruong": "MN 12"
                         },
                         {
                           "maTruong": "79774314",
                           "tenTruong": "MN 13"
                         },
                         {
                           "maTruong": "79774202",
                           "tenTruong": "MN 14A"
                         },
                         {
                           "maTruong": "79774316",
                           "tenTruong": "MN 14B"
                         },
                         {
                           "maTruong": "79774302",
                           "tenTruong": "MN 2A"
                         },
                         {
                           "maTruong": "79774304",
                           "tenTruong": "MN 3"
                         },
                         {
                           "maTruong": "79774305",
                           "tenTruong": "MN 4"
                         },
                         {
                           "maTruong": "79774306",
                           "tenTruong": "MN 5A"
                         },
                         {
                           "maTruong": "79774307",
                           "tenTruong": "MN 5B"
                         },
                         {
                           "maTruong": "79774308",
                           "tenTruong": "MN 6"
                         },
                         {
                           "maTruong": "79774310",
                           "tenTruong": "MN 9"
                         },
                         {
                           "maTruong": "79774W01",
                           "tenTruong": "MN An Điềm"
                         },
                         {
                           "maTruong": "79774335",
                           "tenTruong": "MN Anh Hoa Sài Gòn"
                         },
                         {
                           "maTruong": "79774324",
                           "tenTruong": "MN Hoa Mai"
                         },
                         {
                           "maTruong": "79774318",
                           "tenTruong": "MN Họa Mi 1"
                         },
                         {
                           "maTruong": "79774203",
                           "tenTruong": "MN Họa Mi 2"
                         },
                         {
                           "maTruong": "79774204",
                           "tenTruong": "MN Họa Mi 3"
                         },
                         {
                           "maTruong": "79774332",
                           "tenTruong": "MN Ngôi Nhà Trí Tuệ"
                         },
                         {
                           "maTruong": "79774303",
                           "tenTruong": "MN Ngôi Nhà Tư Duy"
                         },
                         {
                           "maTruong": "79774317",
                           "tenTruong": "MN Sơn Ca"
                         },
                         {
                           "maTruong": "79774326",
                           "tenTruong": "MN Tuổi Thơ"
                         },
                         {
                           "maTruong": "79774322",
                           "tenTruong": "MN Vàng Anh"
                         },
                         {
                           "maTruong": "79774328",
                           "tenTruong": "MN Việt Trung"
                         },
                         {
                           "maTruong": "79774X03",
                           "tenTruong": "MG Thiên Lộc"
                         },
                         {
                           "maTruong": "79774X01",
                           "tenTruong": "MG Thiên Phúc"
                         },
                         {
                           "maTruong": "79774O02",
                           "tenTruong": "NT  An Bình"
                         },
                         {
                           "maTruong": "79774L03",
                           "tenTruong": "NT Hạnh Phúc"
                         },
                         {
                           "maTruong": "79774O04",
                           "tenTruong": "NT Hoa Hồng"
                         },
                         {
                           "maTruong": "79774O03",
                           "tenTruong": "NT Hoa Phượng"
                         },
                         {
                           "maTruong": "79774O06",
                           "tenTruong": "NT Mặt Trời Đỏ"
                         },
                         {
                           "maTruong": "79774O05",
                           "tenTruong": "NT Thiên Thần Nhỏ"
                         },
                         {
                           "maTruong": "79774L04",
                           "tenTruong": "NT Vành Khuyên"
                         },
                         {
                           "maTruong": "79774404",
                           "tenTruong": "TH Bàu Sen"
                         },
                         {
                           "maTruong": "79774W02",
                           "tenTruong": "MN Thiên Thần Nhỏ"
                         },
                         {
                           "maTruong": "79774290",
                           "tenTruong": "Nhóm trẻ Ban Mai"
                         },
                         {
                           "maTruong": "7977401001",
                           "tenTruong": "MN Ngôi Nhà Sao Sáng"
                         }
                       ]
                     },
                     {
                       "maPhong": "775",
                       "tenPhong": "Quận 6",
                       "truongData": [
                         {
                           "maTruong": "79775416",
                           "tenTruong": "TH Bình Tiên"
                         },
                         {
                           "maTruong": "79775410",
                           "tenTruong": "TH Châu Văn Liêm"
                         },
                         {
                           "maTruong": "79775408",
                           "tenTruong": "TH Chi Lăng"
                         },
                         {
                           "maTruong": "79775423",
                           "tenTruong": "TH Đặng Nguyên Cẩn"
                         },
                         {
                           "maTruong": "79775407",
                           "tenTruong": "TH Him Lam"
                         },
                         {
                           "maTruong": "79775420",
                           "tenTruong": "TH Hùng Vương"
                         },
                         {
                           "maTruong": "79775404",
                           "tenTruong": "TH Kim Đồng"
                         },
                         {
                           "maTruong": "79775411",
                           "tenTruong": "TH Lam Sơn"
                         },
                         {
                           "maTruong": "79775412",
                           "tenTruong": "TH Lê Văn Tám"
                         },
                         {
                           "maTruong": "79775406",
                           "tenTruong": "TH Nguyễn Huệ"
                         },
                         {
                           "maTruong": "79775415",
                           "tenTruong": "TH Nguyễn Thiện Thuật"
                         },
                         {
                           "maTruong": "79775422",
                           "tenTruong": "TH Nguyễn Văn Luông"
                         },
                         {
                           "maTruong": "79775413",
                           "tenTruong": "TH Nhật Tảo"
                         },
                         {
                           "maTruong": "79775401",
                           "tenTruong": "TH Phạm Văn Chí"
                         },
                         {
                           "maTruong": "79775403",
                           "tenTruong": "TH Phú Định"
                         },
                         {
                           "maTruong": "79775405",
                           "tenTruong": "TH Phù Đổng"
                         },
                         {
                           "maTruong": "79775409",
                           "tenTruong": "TH Phú Lâm"
                         },
                         {
                           "maTruong": "79775402",
                           "tenTruong": "TH Trương Công Định"
                         },
                         {
                           "maTruong": "79775418",
                           "tenTruong": "TH Vĩnh Xuyên"
                         },
                         {
                           "maTruong": "79775414",
                           "tenTruong": "TH Võ Văn Tần"
                         },
                         {
                           "maTruong": "79775508",
                           "tenTruong": "THCS Bình Tây"
                         },
                         {
                           "maTruong": "79775505",
                           "tenTruong": "THCS Đoàn Kết"
                         },
                         {
                           "maTruong": "79775503",
                           "tenTruong": "THCS Hậu Giang"
                         },
                         {
                           "maTruong": "79775510",
                           "tenTruong": "THCS Hoàng Lê Kha"
                         },
                         {
                           "maTruong": "79775507",
                           "tenTruong": "THCS Lam Sơn"
                         },
                         {
                           "maTruong": "79775501",
                           "tenTruong": "THCS Nguyễn Đức Cảnh"
                         },
                         {
                           "maTruong": "79775506",
                           "tenTruong": "THCS Nguyễn Văn Luông"
                         },
                         {
                           "maTruong": "79775504",
                           "tenTruong": "THCS Phạm Đình Hổ"
                         },
                         {
                           "maTruong": "79775502",
                           "tenTruong": "THCS Phú Định"
                         },
                         {
                           "maTruong": "79775509",
                           "tenTruong": "THCS Văn Thân"
                         },
                         {
                           "maTruong": "79775421",
                           "tenTruong": "Tiểu học và THCS QT Mỹ Úc"
                         },
                         {
                           "maTruong": "79000866",
                           "tenTruong": "THCS - THPT Đào Duy Anh"
                         },
                         {
                           "maTruong": "79775312",
                           "tenTruong": "Mầm Non Rạng Đông 11"
                         },
                         {
                           "maTruong": "79775307",
                           "tenTruong": "Mầm non Rạng Đông 5A"
                         },
                         {
                           "maTruong": "79775334",
                           "tenTruong": "Mẫu Giáo Hồng Phúc"
                         },
                         {
                           "maTruong": "79775333",
                           "tenTruong": "Mẫu giáo Tuyết Sao"
                         },
                         {
                           "maTruong": "79775317",
                           "tenTruong": "MN Ánh Dương"
                         },
                         {
                           "maTruong": "79775349",
                           "tenTruong": "MN Cầu Vồng"
                         },
                         {
                           "maTruong": "79775345",
                           "tenTruong": "MN Chìa Khóa Vàng"
                         },
                         {
                           "maTruong": "79775343",
                           "tenTruong": "MN Dân Kiệt"
                         },
                         {
                           "maTruong": "79775329",
                           "tenTruong": "MN Đô Rê Mon"
                         },
                         {
                           "maTruong": "79775W02",
                           "tenTruong": "MN Hoa Mặt Trời"
                         },
                         {
                           "maTruong": "79775W21",
                           "tenTruong": "MN Họa Mi"
                         },
                         {
                           "maTruong": "79775331",
                           "tenTruong": "MN Hoàng Mai"
                         },
                         {
                           "maTruong": "79775348",
                           "tenTruong": "MN Kỹ Năng Sống"
                         },
                         {
                           "maTruong": "79775330",
                           "tenTruong": "MN Mi Sa"
                         },
                         {
                           "maTruong": "79775328",
                           "tenTruong": "MN Mỹ Cát"
                         },
                         {
                           "maTruong": "79775W25",
                           "tenTruong": "MN Nai Vàng"
                         },
                         {
                           "maTruong": "79775347",
                           "tenTruong": "MN Ngôi Sao"
                         },
                         {
                           "maTruong": "79775350",
                           "tenTruong": "MN Quốc Anh"
                         },
                         {
                           "maTruong": "79775302",
                           "tenTruong": "MN Rạng Đông 1"
                         },
                         {
                           "maTruong": "79775311",
                           "tenTruong": "MN Rạng Đông 10"
                         },
                         {
                           "maTruong": "79775336",
                           "tenTruong": "MN Rạng Đông 11A"
                         },
                         {
                           "maTruong": "79775313",
                           "tenTruong": "MN Rạng Đông 12"
                         },
                         {
                           "maTruong": "79775314",
                           "tenTruong": "MN Rạng Đông 13"
                         },
                         {
                           "maTruong": "79775315",
                           "tenTruong": "MN Rạng Đông 14"
                         },
                         {
                           "maTruong": "79775303",
                           "tenTruong": "MN Rạng Đông 2"
                         },
                         {
                           "maTruong": "79775304",
                           "tenTruong": "MN Rạng Đông 3"
                         },
                         {
                           "maTruong": "79775305",
                           "tenTruong": "MN Rạng Đông 4"
                         },
                         {
                           "maTruong": "79775306",
                           "tenTruong": "MN Rạng Đông 5"
                         },
                         {
                           "maTruong": "79775308",
                           "tenTruong": "MN Rạng Đông 6"
                         },
                         {
                           "maTruong": "79775324",
                           "tenTruong": "MN Rạng Đông 6A"
                         },
                         {
                           "maTruong": "79775325",
                           "tenTruong": "MN Rạng Đông 7"
                         },
                         {
                           "maTruong": "79775346",
                           "tenTruong": "MN Rạng Đông 8"
                         },
                         {
                           "maTruong": "79775310",
                           "tenTruong": "MN Rạng Đông 9"
                         },
                         {
                           "maTruong": "79775309",
                           "tenTruong": "MN Rạng Đông 9A"
                         },
                         {
                           "maTruong": "79775301",
                           "tenTruong": "MN Rạng Đông Quận 6"
                         },
                         {
                           "maTruong": "79775335",
                           "tenTruong": "MN Sao Vàng"
                         },
                         {
                           "maTruong": "79775337",
                           "tenTruong": "MN Táo Hồng"
                         },
                         {
                           "maTruong": "79775W27",
                           "tenTruong": "MN TT Bình Minh"
                         },
                         {
                           "maTruong": "79775W26",
                           "tenTruong": "MN TT Sóc Nâu"
                         },
                         {
                           "maTruong": "79775319",
                           "tenTruong": "MN Tuổi Hồng"
                         },
                         {
                           "maTruong": "79775342",
                           "tenTruong": "MN Việt"
                         },
                         {
                           "maTruong": "79775344",
                           "tenTruong": "MN Việt 2"
                         },
                         {
                           "maTruong": "79775327",
                           "tenTruong": "MN Việt Hoa"
                         },
                         {
                           "maTruong": "79775L59",
                           "tenTruong": "Lớp Mẫu giáo Bé Thông Minh"
                         },
                         {
                           "maTruong": "79775W06",
                           "tenTruong": "LỚP MẪU GIÁO BÚP BÊ"
                         },
                         {
                           "maTruong": "79775W04",
                           "tenTruong": "LỚP MẪU GIÁO HƯƠNG THƠ"
                         },
                         {
                           "maTruong": "79775L61",
                           "tenTruong": "Lớp Mẫu giáo Sao Mai"
                         },
                         {
                           "maTruong": "79775L60",
                           "tenTruong": "Lớp Mẫu giáo Sao Việt"
                         },
                         {
                           "maTruong": "79775W17",
                           "tenTruong": "LỚP MG Thủy Tiên"
                         },
                         {
                           "maTruong": "79775W11",
                           "tenTruong": "MN  Tuổi Thần Tiên"
                         },
                         {
                           "maTruong": "79775W52",
                           "tenTruong": "MN Bình Phú"
                         },
                         {
                           "maTruong": "79775W49",
                           "tenTruong": "MN Bút Chì Màu"
                         },
                         {
                           "maTruong": "79775W53",
                           "tenTruong": "MN Hoa Cúc"
                         },
                         {
                           "maTruong": "79775W50",
                           "tenTruong": "MN Hoàng Yến 2"
                         },
                         {
                           "maTruong": "79775L56",
                           "tenTruong": "MN Thỏ Ngọc"
                         },
                         {
                           "maTruong": "79775W48",
                           "tenTruong": "MN Trúc Xanh"
                         },
                         {
                           "maTruong": "79775W13",
                           "tenTruong": "MN TT Bảo Nhi 1"
                         },
                         {
                           "maTruong": "79775W39",
                           "tenTruong": "MN TT Bé Ngoan"
                         },
                         {
                           "maTruong": "79775W01",
                           "tenTruong": "MN TT Bi Bi"
                         },
                         {
                           "maTruong": "79775W29",
                           "tenTruong": "MN TT Bi Bo"
                         },
                         {
                           "maTruong": "79775W07",
                           "tenTruong": "MN TT Bình Phước"
                         },
                         {
                           "maTruong": "79775W19",
                           "tenTruong": "MN TT Đôrêmi"
                         },
                         {
                           "maTruong": "79775W37",
                           "tenTruong": "MN TT Gấu Trúc"
                         },
                         {
                           "maTruong": "79775W03",
                           "tenTruong": "MN TT Hoa Hồng"
                         },
                         {
                           "maTruong": "79775W12",
                           "tenTruong": "MN TT Hoa Phượng"
                         },
                         {
                           "maTruong": "79775W36",
                           "tenTruong": "MN TT Hoa Phượng2"
                         },
                         {
                           "maTruong": "79775W15",
                           "tenTruong": "MN TT Hoàng Yến"
                         },
                         {
                           "maTruong": "79775W35",
                           "tenTruong": "MN TT Hươu Cao Cổ"
                         },
                         {
                           "maTruong": "79775W28",
                           "tenTruong": "MN TT Minh Tuấn"
                         },
                         {
                           "maTruong": "79775W41",
                           "tenTruong": "MN TT Ngôi Sao Sáng"
                         },
                         {
                           "maTruong": "79775W42",
                           "tenTruong": "MN TT Su Su"
                         },
                         {
                           "maTruong": "79775W18",
                           "tenTruong": "MN TT Tuổi Thơ 11"
                         },
                         {
                           "maTruong": "79775W38",
                           "tenTruong": "Nhóm Trẻ Thiên Tài Nhỏ"
                         },
                         {
                           "maTruong": "79775W46",
                           "tenTruong": "NTDL  Hoa Ti Gôn"
                         },
                         {
                           "maTruong": "79775L62",
                           "tenTruong": "Lớp MG Nam Việt"
                         },
                         {
                           "maTruong": "79775352",
                           "tenTruong": "MN Thiên Anh"
                         },
                         {
                           "maTruong": "79775351",
                           "tenTruong": "MN Cánh Én Tuổi Thơ"
                         },
                         {
                           "maTruong": "79775353",
                           "tenTruong": "MN Cỏ Bốn Lá"
                         },
                         {
                           "maTruong": "79775354",
                           "tenTruong": "MN Nam Mỹ"
                         },
                         {
                           "maTruong": "79775L63",
                           "tenTruong": "Lớp Mẫu giáo Chú Hà Mã"
                         },
                         {
                           "maTruong": "79775L64",
                           "tenTruong": "Lớp Mẫu Giáo Ngôi Nhà Bút Chì"
                         },
                         {
                           "maTruong": "79775201",
                           "tenTruong": "MN Vườn Khám Phá"
                         },
                         {
                           "maTruong": "79775194",
                           "tenTruong": "LMG Bình Phước"
                         },
                         {
                           "maTruong": "79775299",
                           "tenTruong": "NT Hoàng Yến"
                         },
                         {
                           "maTruong": "79775035",
                           "tenTruong": "MG Hồng Phúc"
                         },
                         {
                           "maTruong": "7977501001",
                           "tenTruong": "LỚP MG HOA HƯỚNG DƯƠNG"
                         },
                         {
                           "maTruong": "7977501002",
                           "tenTruong": "Lớp Mẫu giáo Sơn Ca"
                         },
                         {
                           "maTruong": "7977501003",
                           "tenTruong": "Lớp Mầm non ABC"
                         },
                         {
                           "maTruong": "7977501004",
                           "tenTruong": "Lớp Mầm non Bánh Táo"
                         },
                         {
                           "maTruong": "7977501005",
                           "tenTruong": "Lớp Mẫu giáo Thế Hệ Mới"
                         },
                         {
                           "maTruong": "7977501006",
                           "tenTruong": "MN Vườn Thiên Thần"
                         },
                         {
                           "maTruong": "7977501007",
                           "tenTruong": "MN Khu Vườn Trí Tuệ"
                         },
                         {
                           "maTruong": "7977503002",
                           "tenTruong": "THCS Nguyễn Thái Bình"
                         },
                         {
                           "maTruong": "7977501008",
                           "tenTruong": "Lớp MN Bé Vui"
                         },
                         {
                           "maTruong": "7977501009",
                           "tenTruong": "Lớp MN Ngôi Nhà Bút Chì"
                         },
                         {
                           "maTruong": "7977501010",
                           "tenTruong": "Lớp MG C4"
                         },
                         {
                           "maTruong": "7977501011",
                           "tenTruong": "Lớp MN Những Tia Nắng Nhỏ"
                         },
                         {
                           "maTruong": "7977501012",
                           "tenTruong": "MN Bé Vui Đến Trường"
                         },
                         {
                           "maTruong": "7977503001",
                           "tenTruong": "THCS Quốc Trí"
                         }
                       ]
                     },
                     {
                       "maPhong": null,
                       "tenPhong": "Quận Bình Tân",
                       "truongData": [
                         {
                           "maTruong": "79000805",
                           "tenTruong": "THPT An Lạc"
                         },
                         {
                           "maTruong": "790007A6",
                           "tenTruong": "THPT Bình Hưng Hòa"
                         },
                         {
                           "maTruong": "790007A7",
                           "tenTruong": "THPT Bình Tân"
                         },
                         {
                           "maTruong": "79000793",
                           "tenTruong": "THPT Nguyễn Hữu Cảnh"
                         },
                         {
                           "maTruong": "79000769",
                           "tenTruong": "THPT Vĩnh Lộc"
                         },
                         {
                           "maTruong": "79777901",
                           "tenTruong": "TTGD NGHỀ NGHIỆP - GDTX BÌNH TÂN"
                         },
                         {
                           "maTruong": "79000815",
                           "tenTruong": "THCS, THPT  Ngôi Sao"
                         },
                         {
                           "maTruong": "79000838",
                           "tenTruong": "THCS, THPT Phan Châu Trinh"
                         },
                         {
                           "maTruong": "79000781",
                           "tenTruong": "THPT Trần Nhân Tông"
                         },
                         {
                           "maTruong": "79000774",
                           "tenTruong": "Tiểu học, THCS và THPT Chu Văn An"
                         },
                         {
                           "maTruong": "79777418",
                           "tenTruong": "Tiểu học, THCS và THPT Ngôi Sao Nhỏ"
                         },
                         {
                           "maTruong": "79000877",
                           "tenTruong": "Tiểu học, THCS và THPT Trí Tuệ Việt"
                         },
                         {
                           "maTruong": "79000788",
                           "tenTruong": "THPT Phú Lâm"
                         },
                         {
                           "maTruong": "7900004019",
                           "tenTruong": "Trường Chuyên biệt Bình Tân (Bình Tân)"
                         }
                       ]
                     },
                     {
                       "maPhong": "770",
                       "tenPhong": "Quận 3",
                       "truongData": [
                         {
                           "maTruong": "79770403",
                           "tenTruong": "TH Kỳ Đồng"
                         },
                         {
                           "maTruong": "79770405",
                           "tenTruong": "TH Lê Chí Trực"
                         },
                         {
                           "maTruong": "79770406",
                           "tenTruong": "TH Lương Định Của"
                         },
                         {
                           "maTruong": "79770404",
                           "tenTruong": "TH Mê Linh"
                         },
                         {
                           "maTruong": "79770407",
                           "tenTruong": "TH Nguyễn Sơn Hà"
                         },
                         {
                           "maTruong": "79770408",
                           "tenTruong": "TH Nguyễn Thái Sơn"
                         },
                         {
                           "maTruong": "79770409",
                           "tenTruong": "TH Nguyễn Thanh Tuyền"
                         },
                         {
                           "maTruong": "79770410",
                           "tenTruong": "TH Nguyễn Thi"
                         },
                         {
                           "maTruong": "79770411",
                           "tenTruong": "TH Nguyễn Thiện Thuật"
                         },
                         {
                           "maTruong": "79770412",
                           "tenTruong": "TH Nguyễn Việt Hồng"
                         },
                         {
                           "maTruong": "79770417",
                           "tenTruong": "TH Trần Quang Diệu"
                         },
                         {
                           "maTruong": "79770418",
                           "tenTruong": "TH Trần Quốc Thảo"
                         },
                         {
                           "maTruong": "79770419",
                           "tenTruong": "TH Trần Văn Đang"
                         },
                         {
                           "maTruong": "79770420",
                           "tenTruong": "TH Trương Quyền"
                         },
                         {
                           "maTruong": "79770413",
                           "tenTruong": "TiH Phan Đình Phùng"
                         },
                         {
                           "maTruong": "79770414",
                           "tenTruong": "Trường Tiểu học Phan Văn Hân"
                         },
                         {
                           "maTruong": "79770509",
                           "tenTruong": "THCS Bạch Đằng"
                         },
                         {
                           "maTruong": "79770501",
                           "tenTruong": "THCS Bàn Cờ"
                         },
                         {
                           "maTruong": "79770502",
                           "tenTruong": "THCS Colette"
                         },
                         {
                           "maTruong": "79770503",
                           "tenTruong": "THCS Đoàn Thị Điểm"
                         },
                         {
                           "maTruong": "79770504",
                           "tenTruong": "THCS Hai Bà Trưng"
                         },
                         {
                           "maTruong": "79770510",
                           "tenTruong": "THCS Kiến Thiết"
                         },
                         {
                           "maTruong": "79770505",
                           "tenTruong": "THCS Lê Lợi"
                         },
                         {
                           "maTruong": "79770506",
                           "tenTruong": "THCS Lê Quý Đôn"
                         },
                         {
                           "maTruong": "79770507",
                           "tenTruong": "THCS Lương Thế Vinh"
                         },
                         {
                           "maTruong": "79770511",
                           "tenTruong": "THCS Phan Sào Nam"
                         },
                         {
                           "maTruong": "79770508",
                           "tenTruong": "THCS THĂNG LONG"
                         },
                         {
                           "maTruong": "79000812",
                           "tenTruong": "THCS - THPT Nguyễn Bỉnh Khiêm"
                         },
                         {
                           "maTruong": "79770601",
                           "tenTruong": "TH - THCS - THPT Tây Úc"
                         },
                         {
                           "maTruong": "79770206",
                           "tenTruong": "MG Mai Anh"
                         },
                         {
                           "maTruong": "79770205",
                           "tenTruong": "MG Thiên Thanh"
                         },
                         {
                           "maTruong": "79770201",
                           "tenTruong": "MGDL  Hoàng Anh"
                         },
                         {
                           "maTruong": "79770313",
                           "tenTruong": "MẦM NON 11"
                         },
                         {
                           "maTruong": "79770315",
                           "tenTruong": "Mầm Non 13"
                         },
                         {
                           "maTruong": "79770316",
                           "tenTruong": "Mầm Non 14"
                         },
                         {
                           "maTruong": "79770303",
                           "tenTruong": "MẦM NON 3"
                         },
                         {
                           "maTruong": "79770323",
                           "tenTruong": "MẦM NON DÂN LẬP BÌNH MINH"
                         },
                         {
                           "maTruong": "79770354",
                           "tenTruong": "Mầm Non Tuệ Đức"
                         },
                         {
                           "maTruong": "79770301",
                           "tenTruong": "MN 1"
                         },
                         {
                           "maTruong": "79770312",
                           "tenTruong": "MN 10"
                         },
                         {
                           "maTruong": "79770335",
                           "tenTruong": "MN 12"
                         },
                         {
                           "maTruong": "79770317",
                           "tenTruong": "MN 14A"
                         },
                         {
                           "maTruong": "79770302",
                           "tenTruong": "MN 2"
                         },
                         {
                           "maTruong": "79770318",
                           "tenTruong": "MN 4"
                         },
                         {
                           "maTruong": "79770304",
                           "tenTruong": "MN 4A"
                         },
                         {
                           "maTruong": "79770305",
                           "tenTruong": "MN 5"
                         },
                         {
                           "maTruong": "79770306",
                           "tenTruong": "MN 6"
                         },
                         {
                           "maTruong": "79770319",
                           "tenTruong": "MN 7"
                         },
                         {
                           "maTruong": "79770308",
                           "tenTruong": "MN 7A"
                         },
                         {
                           "maTruong": "79770310",
                           "tenTruong": "MN 8"
                         },
                         {
                           "maTruong": "79770311",
                           "tenTruong": "MN 9"
                         },
                         {
                           "maTruong": "79770332",
                           "tenTruong": "MN Ánh Dương"
                         },
                         {
                           "maTruong": "79770342",
                           "tenTruong": "MN Bé Ong Sài Gòn"
                         },
                         {
                           "maTruong": "79770327",
                           "tenTruong": "MN Hải Yến"
                         },
                         {
                           "maTruong": "79770341",
                           "tenTruong": "MN Hạt Đậu Nhỏ"
                         },
                         {
                           "maTruong": "79770322",
                           "tenTruong": "MN Hoa Mai"
                         },
                         {
                           "maTruong": "79770345",
                           "tenTruong": "MN Học Viện Đầu Tiên"
                         },
                         {
                           "maTruong": "79770349",
                           "tenTruong": "MN Học Viện Toàn Cầu"
                         },
                         {
                           "maTruong": "79770350",
                           "tenTruong": "MN Hu Ray"
                         },
                         {
                           "maTruong": "79770353",
                           "tenTruong": "MN Ngôi Nhà Bé Thơ"
                         },
                         {
                           "maTruong": "79770348",
                           "tenTruong": "MN Ngôi Nhà Trẻ Thơ"
                         },
                         {
                           "maTruong": "79770331",
                           "tenTruong": "MN Phát Triển Châu Á"
                         },
                         {
                           "maTruong": "79770347",
                           "tenTruong": "MN QT TP Tuổi Thơ"
                         },
                         {
                           "maTruong": "79770346",
                           "tenTruong": "MN Quốc Tế Ngôi Sao Sáng"
                         },
                         {
                           "maTruong": "79770326",
                           "tenTruong": "MN Sân Lá Cọ"
                         },
                         {
                           "maTruong": "79770W13",
                           "tenTruong": "MN Sóc Nâu"
                         },
                         {
                           "maTruong": "79770204",
                           "tenTruong": "MN Sương Mai"
                         },
                         {
                           "maTruong": "79770351",
                           "tenTruong": "MN Tây Úc"
                         },
                         {
                           "maTruong": "79770334",
                           "tenTruong": "MN Thần Đồng Đất Việt"
                         },
                         {
                           "maTruong": "79770352",
                           "tenTruong": "MN Thần Đồng Việt"
                         },
                         {
                           "maTruong": "79770330",
                           "tenTruong": "MN Thiên Ân"
                         },
                         {
                           "maTruong": "79770343",
                           "tenTruong": "MN Trẻ Em Toàn Cầu"
                         },
                         {
                           "maTruong": "79770333",
                           "tenTruong": "MN TT Thiên Phước"
                         },
                         {
                           "maTruong": "79770307",
                           "tenTruong": "MN Tuổi Thơ 6A"
                         },
                         {
                           "maTruong": "79770320",
                           "tenTruong": "MN Tuổi Thơ 7"
                         },
                         {
                           "maTruong": "79770321",
                           "tenTruong": "MN Tuổi Thơ 8"
                         },
                         {
                           "maTruong": "79770340",
                           "tenTruong": "MN Việt Úc"
                         },
                         {
                           "maTruong": "79770L20",
                           "tenTruong": "Lớp MG Vườn Tuổi Thơ"
                         },
                         {
                           "maTruong": "79770W14",
                           "tenTruong": "MNTT Hoa Mai"
                         },
                         {
                           "maTruong": "79770L21",
                           "tenTruong": "Nhóm trẻ Ban Mai Xanh"
                         },
                         {
                           "maTruong": "79770W03",
                           "tenTruong": "NHÓM TRẺ PHƯƠNG HỒNG"
                         },
                         {
                           "maTruong": "79770W19",
                           "tenTruong": "NT Hạnh Phúc"
                         },
                         {
                           "maTruong": "79770W18",
                           "tenTruong": "NT Hoa Mặt Trời"
                         },
                         {
                           "maTruong": "79770W04",
                           "tenTruong": "NTGĐ 157 Hai Bà Trưng"
                         },
                         {
                           "maTruong": "79770W08",
                           "tenTruong": "NTGĐ 16/6 Trần Quang Diệu"
                         },
                         {
                           "maTruong": "79770W06",
                           "tenTruong": "NTGĐ 195/94 Trần Văn Đang"
                         },
                         {
                           "maTruong": "79770W07",
                           "tenTruong": "NTGĐ 339/33 Lê Văn Sỹ"
                         },
                         {
                           "maTruong": "79770W01",
                           "tenTruong": "NTGĐ 575/21"
                         },
                         {
                           "maTruong": "79770W09",
                           "tenTruong": "NTGĐ Hoa Hồng"
                         },
                         {
                           "maTruong": "79770W17",
                           "tenTruong": "NTGĐ Hoa Tình Thương"
                         },
                         {
                           "maTruong": "79770W05",
                           "tenTruong": "NTGĐ Hương Sen"
                         },
                         {
                           "maTruong": "79770W10",
                           "tenTruong": "NTGĐ Vĩnh khang 351/42 Lê Văn Sỹ"
                         },
                         {
                           "maTruong": "79770356",
                           "tenTruong": "MN Fosco"
                         },
                         {
                           "maTruong": "79770357",
                           "tenTruong": "MN Bé Gấu 4"
                         },
                         {
                           "maTruong": "79770358",
                           "tenTruong": "MN Bàn Tay Thiên Thần"
                         },
                         {
                           "maTruong": "79770359",
                           "tenTruong": "MN TTC Cao Cấp Sài Gòn"
                         },
                         {
                           "maTruong": "79770355",
                           "tenTruong": "MN Thiên Nhiên"
                         },
                         {
                           "maTruong": "79770360",
                           "tenTruong": "MN Thế Giới Mặt Trời"
                         },
                         {
                           "maTruong": "79770361",
                           "tenTruong": "MN Tổ Ong Vàng"
                         },
                         {
                           "maTruong": "79770W20",
                           "tenTruong": "Nhóm trẻ Làng Nắng"
                         },
                         {
                           "maTruong": "79770182",
                           "tenTruong": "Nhóm trẻ Ca Heo Con"
                         },
                         {
                           "maTruong": "79770422",
                           "tenTruong": "TH Thực hành Đại học Sài Gòn"
                         },
                         {
                           "maTruong": "79770423",
                           "tenTruong": "TH Quốc tế Fosco"
                         },
                         {
                           "maTruong": "7977001001",
                           "tenTruong": "Lớp Mẫu Giáo Nhà của Bé"
                         },
                         {
                           "maTruong": "7977001002",
                           "tenTruong": "Lớp Mẫu Giáo Hoa Tình Thương"
                         },
                         {
                           "maTruong": "7977001004",
                           "tenTruong": "Lớp MN Những Đứa Trẻ"
                         },
                         {
                           "maTruong": "7977001005",
                           "tenTruong": "Lớp MN Dãy Núi Xanh"
                         },
                         {
                           "maTruong": "7977002001",
                           "tenTruong": "TH Việt Nam Tinh Hoa"
                         },
                         {
                           "maTruong": "7977001003",
                           "tenTruong": "Mầm Non Quận 3"
                         },
                         {
                           "maTruong": "7977003002",
                           "tenTruong": "TH và THCS Tây Úc"
                         },
                         {
                           "maTruong": "7977003001",
                           "tenTruong": "TH và THCS Sydney"
                         },
                         {
                           "maTruong": "7977001006",
                           "tenTruong": "Lớp MN Học Viện Toàn Cầu"
                         },
                         {
                           "maTruong": "7977001007",
                           "tenTruong": "MN Bari"
                         }
                       ]
                     },
                     {
                       "maPhong": "773",
                       "tenPhong": "Quận 4",
                       "truongData": [
                         {
                           "maTruong": "79773417",
                           "tenTruong": "TH Anh Việt Mỹ"
                         },
                         {
                           "maTruong": "79773408",
                           "tenTruong": "TH Bạch Đằng"
                         },
                         {
                           "maTruong": "79773409",
                           "tenTruong": "TH Bến Cảng"
                         },
                         {
                           "maTruong": "79773402",
                           "tenTruong": "TH Đoàn Thị Điểm"
                         },
                         {
                           "maTruong": "79773411",
                           "tenTruong": "TH Đống Đa"
                         },
                         {
                           "maTruong": "79773406",
                           "tenTruong": "TH Lý Nhơn"
                         },
                         {
                           "maTruong": "79773401",
                           "tenTruong": "TH Nguyễn Huệ 1"
                         },
                         {
                           "maTruong": "79773403",
                           "tenTruong": "TH Nguyễn Huệ 3"
                         },
                         {
                           "maTruong": "79773415",
                           "tenTruong": "TH Nguyễn Thái Bình"
                         },
                         {
                           "maTruong": "79773414",
                           "tenTruong": "TH Nguyễn Trường Tộ"
                         },
                         {
                           "maTruong": "79773416",
                           "tenTruong": "TH Nguyễn Văn Trỗi"
                         },
                         {
                           "maTruong": "79773413",
                           "tenTruong": "TH Tăng Bạt Hổ B"
                         },
                         {
                           "maTruong": "79773404",
                           "tenTruong": "TH Vĩnh Hội"
                         },
                         {
                           "maTruong": "79773412",
                           "tenTruong": "Trường Chuyên biệt 1 tháng 6"
                         },
                         {
                           "maTruong": "79773410",
                           "tenTruong": "TH Xóm Chiếu"
                         },
                         {
                           "maTruong": "79773405",
                           "tenTruong": "Trường TH Đặng Trần Côn"
                         },
                         {
                           "maTruong": "79773506",
                           "tenTruong": "THCS Chi Lăng"
                         },
                         {
                           "maTruong": "79773507",
                           "tenTruong": "THCS Khánh Hội A"
                         },
                         {
                           "maTruong": "79773501",
                           "tenTruong": "THCS Nguyễn Huệ"
                         },
                         {
                           "maTruong": "79773502",
                           "tenTruong": "THCS Quang Trung"
                         },
                         {
                           "maTruong": "79773503",
                           "tenTruong": "THCS Tăng Bạt Hổ A"
                         },
                         {
                           "maTruong": "79773504",
                           "tenTruong": "THCS Vân Đồn"
                         },
                         {
                           "maTruong": "79773O01",
                           "tenTruong": "MG TT Savio"
                         },
                         {
                           "maTruong": "79773201",
                           "tenTruong": "MG Tuổi Xanh 16"
                         },
                         {
                           "maTruong": "79773307",
                           "tenTruong": "MN 10"
                         },
                         {
                           "maTruong": "79773308",
                           "tenTruong": "MN 12"
                         },
                         {
                           "maTruong": "79773311",
                           "tenTruong": "MN 14"
                         },
                         {
                           "maTruong": "79773312",
                           "tenTruong": "MN 15"
                         },
                         {
                           "maTruong": "79773313",
                           "tenTruong": "MN 18"
                         },
                         {
                           "maTruong": "79773301",
                           "tenTruong": "MN 2"
                         },
                         {
                           "maTruong": "79773302",
                           "tenTruong": "MN 3"
                         },
                         {
                           "maTruong": "79773304",
                           "tenTruong": "MN 6"
                         },
                         {
                           "maTruong": "79773305",
                           "tenTruong": "MN 8"
                         },
                         {
                           "maTruong": "79773306",
                           "tenTruong": "MN 9"
                         },
                         {
                           "maTruong": "79773309",
                           "tenTruong": "MN BC Sao Mai 12"
                         },
                         {
                           "maTruong": "79773310",
                           "tenTruong": "MN BC Sao Mai 13"
                         },
                         {
                           "maTruong": "79773314",
                           "tenTruong": "MN Cảng"
                         },
                         {
                           "maTruong": "79773O08",
                           "tenTruong": "MN Chú gấu nhỏ"
                         },
                         {
                           "maTruong": "79773W01",
                           "tenTruong": "MN Khánh Hội"
                         },
                         {
                           "maTruong": "79773o45",
                           "tenTruong": "MN Mặt Trời Nhỏ"
                         },
                         {
                           "maTruong": "79773O19",
                           "tenTruong": "MN Ngôi Nhà Ong"
                         },
                         {
                           "maTruong": "79773303",
                           "tenTruong": "MN Nguyễn Tất Thành"
                         },
                         {
                           "maTruong": "79773o44",
                           "tenTruong": "MN Thiên Thần Nhỏ"
                         },
                         {
                           "maTruong": "79773315",
                           "tenTruong": "MN Việt Mỹ"
                         },
                         {
                           "maTruong": "79773O14",
                           "tenTruong": "Lớp ABC"
                         },
                         {
                           "maTruong": "79773o22",
                           "tenTruong": "Lớp Anh Việt Mỹ"
                         },
                         {
                           "maTruong": "79773O04",
                           "tenTruong": "Lớp Baby"
                         },
                         {
                           "maTruong": "79773O03",
                           "tenTruong": "Lớp Họa Mi"
                         },
                         {
                           "maTruong": "79773O13",
                           "tenTruong": "Lớp Rosa"
                         },
                         {
                           "maTruong": "79773O05",
                           "tenTruong": "MN SƠN CA"
                         },
                         {
                           "maTruong": "79773O11",
                           "tenTruong": "Lớp Tuổi Ngọc"
                         },
                         {
                           "maTruong": "79773O02",
                           "tenTruong": "Lớp Tuổi Xanh 3"
                         },
                         {
                           "maTruong": "79773O17",
                           "tenTruong": "Lớp Vàng Anh"
                         },
                         {
                           "maTruong": "79773O21",
                           "tenTruong": "Nhóm Baby"
                         },
                         {
                           "maTruong": "79773O24",
                           "tenTruong": "Nhóm Khoa Minh"
                         },
                         {
                           "maTruong": "79773O20",
                           "tenTruong": "Nhóm Phường 14"
                         },
                         {
                           "maTruong": "79773o43",
                           "tenTruong": "Nhóm Sóc Nâu"
                         },
                         {
                           "maTruong": "79773O46",
                           "tenTruong": "Nhóm Hạnh Phúc"
                         },
                         {
                           "maTruong": "79773O48",
                           "tenTruong": "MN Baby"
                         },
                         {
                           "maTruong": "79773219",
                           "tenTruong": "Lớp Ngôi Nhà Bí Đỏ"
                         },
                         {
                           "maTruong": "79773019",
                           "tenTruong": "Nhóm Thiên Thần"
                         },
                         {
                           "maTruong": "7977302001",
                           "tenTruong": "TH Kinh Bắc"
                         }
                       ]
                     },
                     {
                       "maPhong": "761",
                       "tenPhong": "Quận 12",
                       "truongData": [
                         {
                           "maTruong": "79761403",
                           "tenTruong": "TH Hà Huy Giáp"
                         },
                         {
                           "maTruong": "79761413",
                           "tenTruong": "TH Hồ Văn Thanh"
                         },
                         {
                           "maTruong": "79761414",
                           "tenTruong": "TH Kim Đồng"
                         },
                         {
                           "maTruong": "79761411",
                           "tenTruong": "TH Lê Văn Thọ"
                         },
                         {
                           "maTruong": "79761422",
                           "tenTruong": "TH Lý Tự Trọng"
                         },
                         {
                           "maTruong": "79761404",
                           "tenTruong": "TH Nguyễn Du"
                         },
                         {
                           "maTruong": "79761405",
                           "tenTruong": "TH Nguyễn Khuyến"
                         },
                         {
                           "maTruong": "79761421",
                           "tenTruong": "TH Nguyễn Thái Bình"
                         },
                         {
                           "maTruong": "79761420",
                           "tenTruong": "TH Nguyễn Thị Định"
                         },
                         {
                           "maTruong": "79761409",
                           "tenTruong": "TH Nguyễn Thị Minh Khai"
                         },
                         {
                           "maTruong": "79761415",
                           "tenTruong": "TH Nguyễn Trãi"
                         },
                         {
                           "maTruong": "79761408",
                           "tenTruong": "TH Nguyễn Văn Thệ"
                         },
                         {
                           "maTruong": "79761401",
                           "tenTruong": "TH Phạm Văn Chiêu"
                         },
                         {
                           "maTruong": "79761402",
                           "tenTruong": "TH Quang Trung"
                         },
                         {
                           "maTruong": "79761407",
                           "tenTruong": "TH Quới Xuân"
                         },
                         {
                           "maTruong": "79761419",
                           "tenTruong": "TH Thần Đồng"
                         },
                         {
                           "maTruong": "79761410",
                           "tenTruong": "TH Thuận Kiều"
                         },
                         {
                           "maTruong": "79761412",
                           "tenTruong": "TH Trần Quang Cơ"
                         },
                         {
                           "maTruong": "79761406",
                           "tenTruong": "TH Trần Văn Ơn"
                         },
                         {
                           "maTruong": "79761418",
                           "tenTruong": "TH Trương Định"
                         },
                         {
                           "maTruong": "79761423",
                           "tenTruong": "TH Võ Thị Sáu"
                         },
                         {
                           "maTruong": "79761416",
                           "tenTruong": "TH Võ Văn Tần"
                         },
                         {
                           "maTruong": "79761501",
                           "tenTruong": "THCS An Phú Đông"
                         },
                         {
                           "maTruong": "79761513",
                           "tenTruong": "THCS Hà Huy Tập"
                         },
                         {
                           "maTruong": "79761504",
                           "tenTruong": "THCS Lương Thế Vinh"
                         },
                         {
                           "maTruong": "79761502",
                           "tenTruong": "THCS Nguyễn An Ninh"
                         },
                         {
                           "maTruong": "79761505",
                           "tenTruong": "THCS Nguyễn Ảnh Thủ"
                         },
                         {
                           "maTruong": "79761512",
                           "tenTruong": "THCS Nguyễn Chí Thanh"
                         },
                         {
                           "maTruong": "79761507",
                           "tenTruong": "THCS Nguyễn Hiền"
                         },
                         {
                           "maTruong": "79761509",
                           "tenTruong": "THCS Nguyễn Huệ"
                         },
                         {
                           "maTruong": "79761511",
                           "tenTruong": "THCS Nguyễn Trung Trực"
                         },
                         {
                           "maTruong": "79761506",
                           "tenTruong": "THCS Nguyễn Vĩnh Nghiệp"
                         },
                         {
                           "maTruong": "79761503",
                           "tenTruong": "THCS Phan Bội Châu"
                         },
                         {
                           "maTruong": "79761510",
                           "tenTruong": "THCS Trần Hưng Đạo"
                         },
                         {
                           "maTruong": "79761508",
                           "tenTruong": "THCS Trần Quang Khải"
                         },
                         {
                           "maTruong": "79000824",
                           "tenTruong": "THCS - THPT Bắc Sơn"
                         },
                         {
                           "maTruong": "79000826",
                           "tenTruong": "THCS - THPT Bạch Đằng"
                         },
                         {
                           "maTruong": "79000865",
                           "tenTruong": "THCS - THPT Hoa Lư"
                         },
                         {
                           "maTruong": "79000854",
                           "tenTruong": "THCS - THPT Lạc Hồng"
                         },
                         {
                           "maTruong": "79000857",
                           "tenTruong": "THCS và THPT Ngọc Viễn Đông"
                         },
                         {
                           "maTruong": "79000C12",
                           "tenTruong": "TH - THCS - THPT Mỹ Việt"
                         },
                         {
                           "maTruong": "79000811",
                           "tenTruong": "Tiểu học, THCS và THPT Tuệ Đức"
                         },
                         {
                           "maTruong": "79761201",
                           "tenTruong": "MG Bông Sen"
                         },
                         {
                           "maTruong": "79761207",
                           "tenTruong": "MG Hoạ Mi 2"
                         },
                         {
                           "maTruong": "79761202",
                           "tenTruong": "MG Sơn Ca 1"
                         },
                         {
                           "maTruong": "79761W44",
                           "tenTruong": "MN 19 tháng 5"
                         },
                         {
                           "maTruong": "79761324",
                           "tenTruong": "MN Anh Đào"
                         },
                         {
                           "maTruong": "79761W53",
                           "tenTruong": "MN Anh Đức"
                         },
                         {
                           "maTruong": "79761W22",
                           "tenTruong": "MN Ánh Hồng"
                         },
                         {
                           "maTruong": "79761208",
                           "tenTruong": "MN Ánh Sáng"
                         },
                         {
                           "maTruong": "79761W02",
                           "tenTruong": "MN Ban Mai"
                         },
                         {
                           "maTruong": "79761WD2",
                           "tenTruong": "MN Bảo Thư 2"
                         },
                         {
                           "maTruong": "79761203",
                           "tenTruong": "MN Bé Ngoan"
                         },
                         {
                           "maTruong": "79761352",
                           "tenTruong": "MN Bí Ngô"
                         },
                         {
                           "maTruong": "79761209",
                           "tenTruong": "MN Bình Minh"
                         },
                         {
                           "maTruong": "79761301",
                           "tenTruong": "MN Bông Hồng"
                         },
                         {
                           "maTruong": "79761334",
                           "tenTruong": "MN Bông Sen Xanh"
                         },
                         {
                           "maTruong": "79761348",
                           "tenTruong": "Mn Con Đường Tuệ Đức"
                         },
                         {
                           "maTruong": "79761337",
                           "tenTruong": "MN Đông Phương"
                         },
                         {
                           "maTruong": "79761339",
                           "tenTruong": "MN Đức Quỳnh"
                         },
                         {
                           "maTruong": "79761333",
                           "tenTruong": "MN Đức Tài"
                         },
                         {
                           "maTruong": "79761336",
                           "tenTruong": "MN Duy Nhật Tân"
                         },
                         {
                           "maTruong": "79761W92",
                           "tenTruong": "MN Duy Phương 2"
                         },
                         {
                           "maTruong": "79761W49",
                           "tenTruong": "MN Gấu Trắng"
                         },
                         {
                           "maTruong": "79761Wk4",
                           "tenTruong": "MN Gia Anh"
                         },
                         {
                           "maTruong": "79761340",
                           "tenTruong": "Mn Hiệp Thành"
                         },
                         {
                           "maTruong": "79761345",
                           "tenTruong": "MN Hoa Đào"
                         },
                         {
                           "maTruong": "79761307",
                           "tenTruong": "MN Hoa Hồng"
                         },
                         {
                           "maTruong": "79761323",
                           "tenTruong": "MN Hoa Mai 2"
                         },
                         {
                           "maTruong": "79761318",
                           "tenTruong": "MN Hoạ Mi 1"
                         },
                         {
                           "maTruong": "79761322",
                           "tenTruong": "MN Hoa Thiên Lý"
                         },
                         {
                           "maTruong": "79761W66",
                           "tenTruong": "MN Hoa Ti Gôn"
                         },
                         {
                           "maTruong": "79761310",
                           "tenTruong": "MN Hoàng Anh 2"
                         },
                         {
                           "maTruong": "79761326",
                           "tenTruong": "MN Hoàng Lan Anh"
                         },
                         {
                           "maTruong": "79761314",
                           "tenTruong": "MN Hoàng Yến"
                         },
                         {
                           "maTruong": "79761351",
                           "tenTruong": "MN Hồng Lam"
                         },
                         {
                           "maTruong": "79761305",
                           "tenTruong": "MN Hồng Yến"
                         },
                         {
                           "maTruong": "79761344",
                           "tenTruong": "MN Hy Vọng"
                         },
                         {
                           "maTruong": "79761335",
                           "tenTruong": "MN Khương Đức"
                         },
                         {
                           "maTruong": "79761327",
                           "tenTruong": "MN Mặt Trời Bé con 3"
                         },
                         {
                           "maTruong": "79761320",
                           "tenTruong": "MN Minh Quang"
                         },
                         {
                           "maTruong": "79761347",
                           "tenTruong": "MN Ngọc Lan"
                         },
                         {
                           "maTruong": "79761W23",
                           "tenTruong": "MN Ngọc Thủy"
                         },
                         {
                           "maTruong": "79761343",
                           "tenTruong": "MN Ngôi Nhà Trẻ Thơ"
                         },
                         {
                           "maTruong": "79761wq5",
                           "tenTruong": "MN Ngôi Sao Nhí"
                         },
                         {
                           "maTruong": "79761W57",
                           "tenTruong": "MN Phù Đổng"
                         },
                         {
                           "maTruong": "79761319",
                           "tenTruong": "MN Quang Trung"
                         },
                         {
                           "maTruong": "79761W82",
                           "tenTruong": "MN Sao Mai 2"
                         },
                         {
                           "maTruong": "79761WB1",
                           "tenTruong": "MN Sóc Bông"
                         },
                         {
                           "maTruong": "79761303",
                           "tenTruong": "MN Sơn Ca"
                         },
                         {
                           "maTruong": "79761316",
                           "tenTruong": "MN Sơn Ca 2"
                         },
                         {
                           "maTruong": "79761304",
                           "tenTruong": "MN Sơn Ca 3"
                         },
                         {
                           "maTruong": "79761302",
                           "tenTruong": "MN Sơn Ca 4"
                         },
                         {
                           "maTruong": "79761315",
                           "tenTruong": "MN Sơn Ca 5"
                         },
                         {
                           "maTruong": "79761317",
                           "tenTruong": "MN Sơn Ca 6"
                         },
                         {
                           "maTruong": "79761325",
                           "tenTruong": "MN Sơn Ca 7"
                         },
                         {
                           "maTruong": "79761328",
                           "tenTruong": "MN Sơn Ca 8"
                         },
                         {
                           "maTruong": "79761329",
                           "tenTruong": "MN Sơn Ca 9"
                         },
                         {
                           "maTruong": "79761346",
                           "tenTruong": "Mn TÂn Á Châu"
                         },
                         {
                           "maTruong": "79761342",
                           "tenTruong": "MN Tây Mỹ"
                         },
                         {
                           "maTruong": "79761330",
                           "tenTruong": "MN Thần Đồng Đất Việt"
                         },
                         {
                           "maTruong": "79761349",
                           "tenTruong": "MN Thần Đồng Việt 2"
                         },
                         {
                           "maTruong": "79761W58",
                           "tenTruong": "MN Thiên Ân"
                         },
                         {
                           "maTruong": "79761312",
                           "tenTruong": "MN Tuổi Ngọc"
                         },
                         {
                           "maTruong": "79761W13",
                           "tenTruong": "MN Tường Vân"
                         },
                         {
                           "maTruong": "79761306",
                           "tenTruong": "MN Vàng Anh"
                         },
                         {
                           "maTruong": "79761308",
                           "tenTruong": "MN Vành Khuyên"
                         },
                         {
                           "maTruong": "79761311",
                           "tenTruong": "MN Việt Anh"
                         },
                         {
                           "maTruong": "79761W56",
                           "tenTruong": "MN Việt Mỹ"
                         },
                         {
                           "maTruong": "79761331",
                           "tenTruong": "MN Xứ Thần Tiên"
                         },
                         {
                           "maTruong": "79761W15",
                           "tenTruong": "1 Tháng 6"
                         },
                         {
                           "maTruong": "79761W96",
                           "tenTruong": "Á Châu"
                         },
                         {
                           "maTruong": "79761W78",
                           "tenTruong": "An Thịnh"
                         },
                         {
                           "maTruong": "79761W41",
                           "tenTruong": "Ánh Bình Minh"
                         },
                         {
                           "maTruong": "79761W42",
                           "tenTruong": "Ánh Bình Minh 2"
                         },
                         {
                           "maTruong": "79761WA1",
                           "tenTruong": "Anh Dũng"
                         },
                         {
                           "maTruong": "79761W46",
                           "tenTruong": "Anh Hương"
                         },
                         {
                           "maTruong": "79761WI6",
                           "tenTruong": "Anh Huy"
                         },
                         {
                           "maTruong": "79761wx2",
                           "tenTruong": "Anh Huy 2"
                         },
                         {
                           "maTruong": "79761wm3",
                           "tenTruong": "Anh Kim Đồng"
                         },
                         {
                           "maTruong": "79761W37",
                           "tenTruong": "Ánh Mai"
                         },
                         {
                           "maTruong": "79761wn9",
                           "tenTruong": "Ánh Mặt Trời"
                         },
                         {
                           "maTruong": "79761wy1",
                           "tenTruong": "Ánh Mặt Trời 2"
                         },
                         {
                           "maTruong": "79761W68",
                           "tenTruong": "Ánh Sao"
                         },
                         {
                           "maTruong": "79761wq3",
                           "tenTruong": "Anh Tài"
                         },
                         {
                           "maTruong": "79761W45",
                           "tenTruong": "Anh Thư"
                         },
                         {
                           "maTruong": "79761W28",
                           "tenTruong": "Ấu Thơ"
                         },
                         {
                           "maTruong": "79761wn3",
                           "tenTruong": "B&B"
                         },
                         {
                           "maTruong": "79761W40",
                           "tenTruong": "Ba By"
                         },
                         {
                           "maTruong": "79761W50",
                           "tenTruong": "Ba By 2"
                         },
                         {
                           "maTruong": "79761WE4",
                           "tenTruong": "Bách Khoa"
                         },
                         {
                           "maTruong": "79761WJ3",
                           "tenTruong": "Ban Mai Xanh"
                         },
                         {
                           "maTruong": "79761Wj8",
                           "tenTruong": "Bảo Ngọc"
                         },
                         {
                           "maTruong": "79761W14",
                           "tenTruong": "Bảo Thư"
                         },
                         {
                           "maTruong": "79761wn5",
                           "tenTruong": "Bảo Tiên"
                         },
                         {
                           "maTruong": "79761WN2",
                           "tenTruong": "Bảo Trân"
                         },
                         {
                           "maTruong": "79761wx6",
                           "tenTruong": "Bầu Trời Việt"
                         },
                         {
                           "maTruong": "79761wr5",
                           "tenTruong": "Bé Hạnh Phúc"
                         },
                         {
                           "maTruong": "79761wl9",
                           "tenTruong": "Bé Ngôi Sao"
                         },
                         {
                           "maTruong": "79761ws5",
                           "tenTruong": "Bé Ngôi Sao 2"
                         },
                         {
                           "maTruong": "79761WI9",
                           "tenTruong": "Bé Thơ"
                         },
                         {
                           "maTruong": "79761W69",
                           "tenTruong": "Bé Yêu"
                         },
                         {
                           "maTruong": "79761wz5",
                           "tenTruong": "Bé Yêu 2"
                         },
                         {
                           "maTruong": "79761W34",
                           "tenTruong": "Bích Đình"
                         },
                         {
                           "maTruong": "79761WC2",
                           "tenTruong": "Búp Bê"
                         },
                         {
                           "maTruong": "79761WB6",
                           "tenTruong": "Búp Măng Non"
                         },
                         {
                           "maTruong": "79761wr1",
                           "tenTruong": "Búp Măng Xanh"
                         },
                         {
                           "maTruong": "79761Wk6",
                           "tenTruong": "Cao Minh"
                         },
                         {
                           "maTruong": "79761W99",
                           "tenTruong": "Cát Tường"
                         },
                         {
                           "maTruong": "79761WH7",
                           "tenTruong": "Chim Họa Mi"
                         },
                         {
                           "maTruong": "79761WE7",
                           "tenTruong": "Chim Non"
                         },
                         {
                           "maTruong": "79761wn7",
                           "tenTruong": "Cỏ Mùa Xuân"
                         },
                         {
                           "maTruong": "79761W01",
                           "tenTruong": "Cỏ non"
                         },
                         {
                           "maTruong": "79761WJ0",
                           "tenTruong": "Đăng Khôi"
                         },
                         {
                           "maTruong": "79761WJ2",
                           "tenTruong": "Dâu Tây"
                         },
                         {
                           "maTruong": "79761W47",
                           "tenTruong": "Đình Khánh"
                         },
                         {
                           "maTruong": "79761WA7",
                           "tenTruong": "Do Re Mi"
                         },
                         {
                           "maTruong": "79761WI3",
                           "tenTruong": "Đô Rê Mon"
                         },
                         {
                           "maTruong": "79761wq4",
                           "tenTruong": "Đức Anh"
                         },
                         {
                           "maTruong": "79761WB0",
                           "tenTruong": "Đức Hạnh"
                         },
                         {
                           "maTruong": "79761W35",
                           "tenTruong": "Duy Thanh"
                         },
                         {
                           "maTruong": "79761wy2",
                           "tenTruong": "Fi Gô"
                         },
                         {
                           "maTruong": "79761WD1",
                           "tenTruong": "Gấu Con"
                         },
                         {
                           "maTruong": "79761WG1",
                           "tenTruong": "Gấu Mi Sa"
                         },
                         {
                           "maTruong": "79761ww8",
                           "tenTruong": "Gấu Trúc"
                         },
                         {
                           "maTruong": "79761WG8",
                           "tenTruong": "Gia Phúc"
                         },
                         {
                           "maTruong": "79761WA3",
                           "tenTruong": "Hải Âu"
                         },
                         {
                           "maTruong": "79761WD5",
                           "tenTruong": "Hải Yến"
                         },
                         {
                           "maTruong": "79761WC6",
                           "tenTruong": "Hoa Anh Đào"
                         },
                         {
                           "maTruong": "79761WC1",
                           "tenTruong": "Hoa Bé Ngoan"
                         },
                         {
                           "maTruong": "79761WJ7",
                           "tenTruong": "Hoa cúc"
                         },
                         {
                           "maTruong": "79761wm1",
                           "tenTruong": "Hoa Đăng"
                         },
                         {
                           "maTruong": "79761wl8",
                           "tenTruong": "Hoa Hồng 4"
                         },
                         {
                           "maTruong": "79761Wt1",
                           "tenTruong": "Hoa Hồng 5"
                         },
                         {
                           "maTruong": "79761WF2",
                           "tenTruong": "Hoa Hồng Nhỏ"
                         },
                         {
                           "maTruong": "79761WN1",
                           "tenTruong": "Hoa Hồng Nhung"
                         },
                         {
                           "maTruong": "79761WG0",
                           "tenTruong": "Hoa Hướng Dương"
                         },
                         {
                           "maTruong": "79761WG5",
                           "tenTruong": "Hoa Lan"
                         },
                         {
                           "maTruong": "79761W10",
                           "tenTruong": "Hoa Mai 1"
                         },
                         {
                           "maTruong": "79761W80",
                           "tenTruong": "Hoa Mai Anh"
                         },
                         {
                           "maTruong": "79761wpq0",
                           "tenTruong": "Hoa Mặt Trời"
                         },
                         {
                           "maTruong": "79761wx9",
                           "tenTruong": "Hoa Nắng"
                         },
                         {
                           "maTruong": "79761WH2",
                           "tenTruong": "Hoa Ngọc Lan"
                         },
                         {
                           "maTruong": "79761W83",
                           "tenTruong": "Hoa Phượng"
                         },
                         {
                           "maTruong": "79761W09",
                           "tenTruong": "Hoa Phượng Đỏ 1"
                         },
                         {
                           "maTruong": "79761ww7",
                           "tenTruong": "Hoa Quỳnh"
                         },
                         {
                           "maTruong": "79761W43",
                           "tenTruong": "Hoa Sen 1"
                         },
                         {
                           "maTruong": "79761WC8",
                           "tenTruong": "Hoa Sữa"
                         },
                         {
                           "maTruong": "79761ws1",
                           "tenTruong": "Hoa Thủy Tiên"
                         },
                         {
                           "maTruong": "79761wz8",
                           "tenTruong": "Hoa Trà Mi"
                         },
                         {
                           "maTruong": "79761WF5",
                           "tenTruong": "Hoàn Mỹ"
                         },
                         {
                           "maTruong": "79761W61",
                           "tenTruong": "Hoàng Anh 1"
                         },
                         {
                           "maTruong": "79761WE0",
                           "tenTruong": "Hoàng Oanh"
                         },
                         {
                           "maTruong": "79761ww5",
                           "tenTruong": "Hoàng Vy"
                         },
                         {
                           "maTruong": "79761wp8",
                           "tenTruong": "Hồng Mai"
                         },
                         {
                           "maTruong": "79761WL0",
                           "tenTruong": "Hồng Ngọc"
                         },
                         {
                           "maTruong": "79761wp0",
                           "tenTruong": "Hồng Nguyên"
                         },
                         {
                           "maTruong": "79761W12",
                           "tenTruong": "Hồng Nhung"
                         },
                         {
                           "maTruong": "79761W16",
                           "tenTruong": "Hồng Nhung 2"
                         },
                         {
                           "maTruong": "79761wy9",
                           "tenTruong": "Hưng Ngân"
                         },
                         {
                           "maTruong": "79761W21",
                           "tenTruong": "Hướng Dương"
                         },
                         {
                           "maTruong": "79761wr8",
                           "tenTruong": "Hướng Dương Xinh"
                         },
                         {
                           "maTruong": "79761W33",
                           "tenTruong": "Hương Hồng"
                         },
                         {
                           "maTruong": "79761WB2",
                           "tenTruong": "Hương Sen"
                         },
                         {
                           "maTruong": "79761WA0",
                           "tenTruong": "Hưu Cao Cổ"
                         },
                         {
                           "maTruong": "79761WG7",
                           "tenTruong": "Kha Ly"
                         },
                         {
                           "maTruong": "79761wn8",
                           "tenTruong": "Khai Sáng"
                         },
                         {
                           "maTruong": "79761WC3",
                           "tenTruong": "Khánh Linh"
                         },
                         {
                           "maTruong": "79761wm0",
                           "tenTruong": "Khôi Nguyên"
                         },
                         {
                           "maTruong": "79761xy4",
                           "tenTruong": "Kim Đồng"
                         },
                         {
                           "maTruong": "79761WD8",
                           "tenTruong": "KiTy"
                         },
                         {
                           "maTruong": "79761wq7",
                           "tenTruong": "Lạc Hồng"
                         },
                         {
                           "maTruong": "79761wx0",
                           "tenTruong": "Lạc Việt"
                         },
                         {
                           "maTruong": "79761W36",
                           "tenTruong": "Lan Anh"
                         },
                         {
                           "maTruong": "79761W93",
                           "tenTruong": "Mai Khôi"
                         },
                         {
                           "maTruong": "79761Wr4",
                           "tenTruong": "Mai Khôi 2"
                         },
                         {
                           "maTruong": "79761W91",
                           "tenTruong": "Mai Phương"
                         },
                         {
                           "maTruong": "79761W05",
                           "tenTruong": "Măng Non"
                         },
                         {
                           "maTruong": "79761WB3",
                           "tenTruong": "Mặt Trời Nhỏ"
                         },
                         {
                           "maTruong": "79761wr2",
                           "tenTruong": "Mặt Trời Tí Hon"
                         },
                         {
                           "maTruong": "79761WC7",
                           "tenTruong": "Mây Hồng"
                         },
                         {
                           "maTruong": "79761W52",
                           "tenTruong": "Mi Mi"
                         },
                         {
                           "maTruong": "79761w88",
                           "tenTruong": "Minh Anh"
                         },
                         {
                           "maTruong": "79761WE2",
                           "tenTruong": "Minh Châu"
                         },
                         {
                           "maTruong": "79761WF1",
                           "tenTruong": "Minh Hiếu"
                         },
                         {
                           "maTruong": "79761wn4",
                           "tenTruong": "Minh Hoàng"
                         },
                         {
                           "maTruong": "79761WG3",
                           "tenTruong": "Minh Ngọc"
                         },
                         {
                           "maTruong": "79761W87",
                           "tenTruong": "Minh Thư"
                         },
                         {
                           "maTruong": "79761WW1",
                           "tenTruong": "Minh Thư 2"
                         },
                         {
                           "maTruong": "79761W38",
                           "tenTruong": "Minh Trang"
                         },
                         {
                           "maTruong": "79761W63",
                           "tenTruong": "Minh Trang 2"
                         },
                         {
                           "maTruong": "79761WD6",
                           "tenTruong": "Minh Trí"
                         },
                         {
                           "maTruong": "79761WD7",
                           "tenTruong": "MN Bé Xu Ka"
                         },
                         {
                           "maTruong": "79761W19",
                           "tenTruong": "MN Hoài Anh"
                         },
                         {
                           "maTruong": "79761wz2",
                           "tenTruong": "Mỹ Đức"
                         },
                         {
                           "maTruong": "79761W64",
                           "tenTruong": "MN Mỹ Sài Gòn"
                         },
                         {
                           "maTruong": "79761wn6",
                           "tenTruong": "Nai Vàng"
                         },
                         {
                           "maTruong": "79761WC0",
                           "tenTruong": "Nai Vàng Nhỏ"
                         },
                         {
                           "maTruong": "79761WB7",
                           "tenTruong": "Nắng Hồng"
                         },
                         {
                           "maTruong": "79761WF3",
                           "tenTruong": "Nắng Mai"
                         },
                         {
                           "maTruong": "79761W03",
                           "tenTruong": "Nay Bi"
                         },
                         {
                           "maTruong": "79761ww3",
                           "tenTruong": "Ngọc Chi"
                         },
                         {
                           "maTruong": "79761Wk3",
                           "tenTruong": "Ngôi nhà của bé"
                         },
                         {
                           "maTruong": "79761W32",
                           "tenTruong": "Ngôi Sao"
                         },
                         {
                           "maTruong": "79761we5",
                           "tenTruong": "Ngôi Sao Nhỏ"
                         },
                         {
                           "maTruong": "79761wq9",
                           "tenTruong": "Ngôi Sao Việt"
                         },
                         {
                           "maTruong": "79761Wz4",
                           "tenTruong": "Ngôi sao Việt 1"
                         },
                         {
                           "maTruong": "79761ww4",
                           "tenTruong": "Ngôi Sao Xanh"
                         },
                         {
                           "maTruong": "79761WF9",
                           "tenTruong": "Ngôi Sao Xinh"
                         },
                         {
                           "maTruong": "79761WH0",
                           "tenTruong": "Nhà Của Bé"
                         },
                         {
                           "maTruong": "79761wx3",
                           "tenTruong": "Nhà Gấu Bộng"
                         },
                         {
                           "maTruong": "79761WH5",
                           "tenTruong": "Nhật Minh"
                         },
                         {
                           "maTruong": "79761WA5",
                           "tenTruong": "Nhật Tân"
                         },
                         {
                           "maTruong": "79761WI0",
                           "tenTruong": "Nhật Tân 3"
                         },
                         {
                           "maTruong": "79761WB4",
                           "tenTruong": "Như Ý"
                         },
                         {
                           "maTruong": "79761Wj9",
                           "tenTruong": "Nụ Cười Trẻ Thơ"
                         },
                         {
                           "maTruong": "79761W29",
                           "tenTruong": "Nụ Hồng"
                         },
                         {
                           "maTruong": "79761WJ6",
                           "tenTruong": "Phương Đông"
                         },
                         {
                           "maTruong": "79761wz9",
                           "tenTruong": "Phương Đông 1"
                         },
                         {
                           "maTruong": "79761wp3",
                           "tenTruong": "Phương Ngọc"
                         },
                         {
                           "maTruong": "79761W48",
                           "tenTruong": "Phương Thuý 2"
                         },
                         {
                           "maTruong": "79761WD0",
                           "tenTruong": "Phương Vy"
                         },
                         {
                           "maTruong": "79761wy5",
                           "tenTruong": "Quốc Bảo"
                         },
                         {
                           "maTruong": "79761WJ1",
                           "tenTruong": "Quỳnh Giao"
                         },
                         {
                           "maTruong": "79761ww2",
                           "tenTruong": "Quỳnh Hương"
                         },
                         {
                           "maTruong": "79761wz1",
                           "tenTruong": "Quỳnh Mai"
                         },
                         {
                           "maTruong": "79761wl7",
                           "tenTruong": "Sao Biển"
                         },
                         {
                           "maTruong": "79761ww9",
                           "tenTruong": "Sao Việt"
                         },
                         {
                           "maTruong": "79761W86",
                           "tenTruong": "Tài Năng Việt 1"
                         },
                         {
                           "maTruong": "79761WI4",
                           "tenTruong": "Sen Việt"
                         },
                         {
                           "maTruong": "79761wp9",
                           "tenTruong": "Sinh Đôi"
                         },
                         {
                           "maTruong": "79761WG9",
                           "tenTruong": "Sóc Nhỏ"
                         },
                         {
                           "maTruong": "79761wx5",
                           "tenTruong": "Su Hào"
                         },
                         {
                           "maTruong": "79761W25",
                           "tenTruong": "Sương Mai"
                         },
                         {
                           "maTruong": "79761W94",
                           "tenTruong": "Tài Năng Việt"
                         },
                         {
                           "maTruong": "79761wx4",
                           "tenTruong": "Tâm Sao Việt"
                         },
                         {
                           "maTruong": "79761W98",
                           "tenTruong": "Tân Chánh Hiệp"
                         },
                         {
                           "maTruong": "79761wx7",
                           "tenTruong": "Tấn Lộc"
                         },
                         {
                           "maTruong": "79761wz3",
                           "tenTruong": "Tân Việt Mỹ"
                         },
                         {
                           "maTruong": "79761wm4",
                           "tenTruong": "Táo Đỏ"
                         },
                         {
                           "maTruong": "79761WA9",
                           "tenTruong": "Táo Hồng"
                         },
                         {
                           "maTruong": "79761wy6",
                           "tenTruong": "Táo Xanh"
                         },
                         {
                           "maTruong": "79761wp5",
                           "tenTruong": "Thái Bình"
                         },
                         {
                           "maTruong": "79761WI1",
                           "tenTruong": "Thái Sơn"
                         },
                         {
                           "maTruong": "79761W20",
                           "tenTruong": "Thần Đồng"
                         },
                         {
                           "maTruong": "79761WF8",
                           "tenTruong": "Thần Đồng 2"
                         },
                         {
                           "maTruong": "79761Wk1",
                           "tenTruong": "Thần Đồng Nhỏ"
                         },
                         {
                           "maTruong": "79761WD3",
                           "tenTruong": "Thần Đồng Việt"
                         },
                         {
                           "maTruong": "79761W67",
                           "tenTruong": "Thanh Ngân"
                         },
                         {
                           "maTruong": "79761wx1",
                           "tenTruong": "Thanh Ngân 1"
                         },
                         {
                           "maTruong": "79761WE6",
                           "tenTruong": "Thanh Tâm"
                         },
                         {
                           "maTruong": "79761Wk8",
                           "tenTruong": "Thanh Thanh"
                         },
                         {
                           "maTruong": "79761wr6",
                           "tenTruong": "Thảo Nguyên"
                         },
                         {
                           "maTruong": "79761WH1",
                           "tenTruong": "Thảo Vy"
                         },
                         {
                           "maTruong": "79761WB8",
                           "tenTruong": "Thiên Ấn"
                         },
                         {
                           "maTruong": "79761wq2",
                           "tenTruong": "Thiên Ấn 1"
                         },
                         {
                           "maTruong": "79761wy7",
                           "tenTruong": "Thiên Hy"
                         },
                         {
                           "maTruong": "79761ws3",
                           "tenTruong": "Thiên Kim"
                         },
                         {
                           "maTruong": "79761WH8",
                           "tenTruong": "Thiên Kim 2"
                         },
                         {
                           "maTruong": "79761W79",
                           "tenTruong": "Thiên Lộc"
                         },
                         {
                           "maTruong": "79761W75",
                           "tenTruong": "Thiên Nga"
                         },
                         {
                           "maTruong": "79761WF0",
                           "tenTruong": "Thiên Phúc"
                         },
                         {
                           "maTruong": "79761WG6",
                           "tenTruong": "Thiên Tài Nhò"
                         },
                         {
                           "maTruong": "79761W65",
                           "tenTruong": "Thiên Thần"
                         },
                         {
                           "maTruong": "79761W24",
                           "tenTruong": "Thiên Thần Nhỏ"
                         },
                         {
                           "maTruong": "79761ww0",
                           "tenTruong": "Thiên Thần Nhỏ 2"
                         },
                         {
                           "maTruong": "79761WJ4",
                           "tenTruong": "Thiên Thần Việt"
                         },
                         {
                           "maTruong": "79761WI7",
                           "tenTruong": "Thỏ Trắng"
                         },
                         {
                           "maTruong": "79761WF4",
                           "tenTruong": "Thục Anh"
                         },
                         {
                           "maTruong": "79761wq1",
                           "tenTruong": "THÙY DƯƠNG"
                         },
                         {
                           "maTruong": "79761W31",
                           "tenTruong": "Thuỷ Tiên"
                         },
                         {
                           "maTruong": "79761wq6",
                           "tenTruong": "Tiến Đạt"
                         },
                         {
                           "maTruong": "79761WJ5",
                           "tenTruong": "Tin Tin"
                         },
                         {
                           "maTruong": "79761WB5",
                           "tenTruong": "Trân Châu 1"
                         },
                         {
                           "maTruong": "79761W18",
                           "tenTruong": "Trang Thơ"
                         },
                         {
                           "maTruong": "79761WC4",
                           "tenTruong": "Trí Thanh"
                         },
                         {
                           "maTruong": "79761wp2",
                           "tenTruong": "Trí Tuệ Việt"
                         },
                         {
                           "maTruong": "79761W74",
                           "tenTruong": "Trúc Xanh"
                         },
                         {
                           "maTruong": "79761W30",
                           "tenTruong": "Trùng Dương"
                         },
                         {
                           "maTruong": "79761W06",
                           "tenTruong": "Tuổi Hồng"
                         },
                         {
                           "maTruong": "79761WG4",
                           "tenTruong": "Tuổi Thần Tiên"
                         },
                         {
                           "maTruong": "79761WE8",
                           "tenTruong": "Tuổi Thần Tiên 2"
                         },
                         {
                           "maTruong": "79761W60",
                           "tenTruong": "Tuổi Thơ"
                         },
                         {
                           "maTruong": "79761wl3",
                           "tenTruong": "Tuổi Thơ Thạch Thảo"
                         },
                         {
                           "maTruong": "79761wr7",
                           "tenTruong": "Tuổi Thơ Việt"
                         },
                         {
                           "maTruong": "79761W72",
                           "tenTruong": "Tuổi Tiên"
                         },
                         {
                           "maTruong": "79761W59",
                           "tenTruong": "Tuổi Xanh"
                         },
                         {
                           "maTruong": "79761WE1",
                           "tenTruong": "Tương Lai"
                         },
                         {
                           "maTruong": "79761W62",
                           "tenTruong": "Tuyết Anh"
                         },
                         {
                           "maTruong": "79761wy8",
                           "tenTruong": "Úc Châu"
                         },
                         {
                           "maTruong": "79761wz7",
                           "tenTruong": "Ước Mơ"
                         },
                         {
                           "maTruong": "79761wq0",
                           "tenTruong": "Việt Đông Dương"
                         },
                         {
                           "maTruong": "79761W55",
                           "tenTruong": "Việt Đức"
                         },
                         {
                           "maTruong": "79761WD4",
                           "tenTruong": "Vườn Cổ Tích"
                         },
                         {
                           "maTruong": "79761wr9",
                           "tenTruong": "Vườn Họa Mi"
                         },
                         {
                           "maTruong": "79761W70",
                           "tenTruong": "Vườn Hồng"
                         },
                         {
                           "maTruong": "79761Wk9",
                           "tenTruong": "Vườn Nắng Mai"
                         },
                         {
                           "maTruong": "79761wr3",
                           "tenTruong": "Vườn Sóc"
                         },
                         {
                           "maTruong": "79761ws2",
                           "tenTruong": "Vườn Ươm"
                         },
                         {
                           "maTruong": "79761wt2",
                           "tenTruong": "Xuân Phong"
                         },
                         {
                           "maTruong": "79761wl6",
                           "tenTruong": "XuÂn Sang"
                         },
                         {
                           "maTruong": "79761WA2",
                           "tenTruong": "Xuân Xanh"
                         },
                         {
                           "maTruong": "79761WC5",
                           "tenTruong": "Xuân Xanh 2"
                         },
                         {
                           "maTruong": "79761wz6",
                           "tenTruong": "Xuân Xanh 4"
                         },
                         {
                           "maTruong": "12HD04",
                           "tenTruong": "THCS-THPT Nam Việt"
                         },
                         {
                           "maTruong": "79761WM5",
                           "tenTruong": "Khai Trí"
                         },
                         {
                           "maTruong": "79761WM6",
                           "tenTruong": "Ngôi Nhà Thứ 2"
                         },
                         {
                           "maTruong": "79761WP6",
                           "tenTruong": "Thiên Kim 3"
                         },
                         {
                           "maTruong": "79761WS7",
                           "tenTruong": "Toàn Tâm"
                         },
                         {
                           "maTruong": "79761WU5",
                           "tenTruong": "Mẹ và Bé"
                         },
                         {
                           "maTruong": "79761WU7",
                           "tenTruong": "Mặt Trời Hồng"
                         },
                         {
                           "maTruong": "79761WUO",
                           "tenTruong": "Trí Tuệ"
                         },
                         {
                           "maTruong": "7976WAA0",
                           "tenTruong": "An Khang"
                         },
                         {
                           "maTruong": "7976WAA1",
                           "tenTruong": "Việt Phương"
                         },
                         {
                           "maTruong": "7976WAA2",
                           "tenTruong": "Ánh Mặt Trời 1"
                         },
                         {
                           "maTruong": "7976WAA3",
                           "tenTruong": "Anh Việt Mỹ"
                         },
                         {
                           "maTruong": "7976WAA5",
                           "tenTruong": "Bình An"
                         },
                         {
                           "maTruong": "7976WAA6",
                           "tenTruong": "Bông Sen Hồng"
                         },
                         {
                           "maTruong": "7976WAA7",
                           "tenTruong": "Đông Phương 1"
                         },
                         {
                           "maTruong": "7976WAA8",
                           "tenTruong": "Đông Phương 2"
                         },
                         {
                           "maTruong": "7976WAA9",
                           "tenTruong": "Gia Hân"
                         },
                         {
                           "maTruong": "7976WAB0",
                           "tenTruong": "Hải Châu"
                         },
                         {
                           "maTruong": "7976WAB2",
                           "tenTruong": "Hoa Hồng Đỏ"
                         },
                         {
                           "maTruong": "7976WAB4",
                           "tenTruong": "Kha Ly 1"
                         },
                         {
                           "maTruong": "7976WAB5",
                           "tenTruong": "Mai Vàng"
                         },
                         {
                           "maTruong": "7976WAB6",
                           "tenTruong": "Ngọc Hiền"
                         },
                         {
                           "maTruong": "7976WAB8",
                           "tenTruong": "Ngọc Oanh"
                         },
                         {
                           "maTruong": "7976WAB9",
                           "tenTruong": "Ngôi Nhà Tuổi Thơ"
                         },
                         {
                           "maTruong": "7976WAC0",
                           "tenTruong": "Nhân Ái"
                         },
                         {
                           "maTruong": "7976WAC2",
                           "tenTruong": "Phương Nam"
                         },
                         {
                           "maTruong": "7976WAC6",
                           "tenTruong": "Tâm Đức"
                         },
                         {
                           "maTruong": "7976WAD3",
                           "tenTruong": "Việt Úc"
                         },
                         {
                           "maTruong": "7976WAD4",
                           "tenTruong": "Bảo Thoa"
                         },
                         {
                           "maTruong": "7976WAD5",
                           "tenTruong": "Hoàng Lan"
                         },
                         {
                           "maTruong": "79761w334",
                           "tenTruong": "MN Ánh Sáng"
                         },
                         {
                           "maTruong": "79761514",
                           "tenTruong": "THCS Trần Phú "
                         },
                         {
                           "maTruong": "70123015",
                           "tenTruong": "TH Nam Việt"
                         },
                         {
                           "maTruong": "79761WZ01",
                           "tenTruong": "Ngôi Nhà Sáng Tạo"
                         },
                         {
                           "maTruong": "7976Ww05",
                           "tenTruong": "Thiên Thần Nhỏ"
                         },
                         {
                           "maTruong": "79761Waa1",
                           "tenTruong": "Sao Việt Úc"
                         },
                         {
                           "maTruong": "79761waa2",
                           "tenTruong": "Xuân Mai"
                         },
                         {
                           "maTruong": "79761waa3",
                           "tenTruong": "Sao Ước Mơ"
                         },
                         {
                           "maTruong": "79761waa4",
                           "tenTruong": "Gấu Trúc 2"
                         },
                         {
                           "maTruong": "79761waa5",
                           "tenTruong": "Minh Thiện"
                         },
                         {
                           "maTruong": "79761Waa6",
                           "tenTruong": "VƯỜN TRẺ THƠ"
                         },
                         {
                           "maTruong": "79761Waa7",
                           "tenTruong": "Ngôi Sao Sáng"
                         },
                         {
                           "maTruong": "79761waa8",
                           "tenTruong": "Khanh Minh"
                         },
                         {
                           "maTruong": "79761353",
                           "tenTruong": "MN Ánh Sao Mai"
                         },
                         {
                           "maTruong": "79761Wab0",
                           "tenTruong": "Bông Hoa Nhỏ"
                         },
                         {
                           "maTruong": "79761Wab1",
                           "tenTruong": "Thỏ Trắng Xinh"
                         },
                         {
                           "maTruong": "79761Wab2",
                           "tenTruong": "Tuổi Xanh"
                         },
                         {
                           "maTruong": "79761Wab3",
                           "tenTruong": "Tuệ Đức"
                         },
                         {
                           "maTruong": "79761Wb4a",
                           "tenTruong": "Tô Ký"
                         },
                         {
                           "maTruong": "79761Wab5",
                           "tenTruong": "Mỹ Đức 2"
                         },
                         {
                           "maTruong": "79761Wab6",
                           "tenTruong": "Trẻ Sáng Tạo 2"
                         },
                         {
                           "maTruong": "79761Wab7",
                           "tenTruong": "Bình An 2"
                         },
                         {
                           "maTruong": "79761Wab8",
                           "tenTruong": "Lam Sơn"
                         },
                         {
                           "maTruong": "79761wab9",
                           "tenTruong": "Sinh Đôi 2"
                         },
                         {
                           "maTruong": "79761Wac0",
                           "tenTruong": "Anh Việt "
                         },
                         {
                           "maTruong": "79761Wab4",
                           "tenTruong": "Hạt Đậu Nhỏ"
                         },
                         {
                           "maTruong": "79761Wac3",
                           "tenTruong": "Chuột Túi"
                         },
                         {
                           "maTruong": "79761350",
                           "tenTruong": "MN Mai Vàng"
                         },
                         {
                           "maTruong": "7976WAA4",
                           "tenTruong": "Bích Trúc"
                         },
                         {
                           "maTruong": "7976WAB3",
                           "tenTruong": "Hoa Việt"
                         },
                         {
                           "maTruong": "7976WAC1",
                           "tenTruong": "Như Tâm"
                         },
                         {
                           "maTruong": "7976WAC3",
                           "tenTruong": "Quang Vinh"
                         },
                         {
                           "maTruong": "7976WAC4",
                           "tenTruong": "Sóc Nâu"
                         },
                         {
                           "maTruong": "7976WAC7",
                           "tenTruong": "Thảo Xanh"
                         },
                         {
                           "maTruong": "7976WAC9",
                           "tenTruong": "Trân Châu 2"
                         },
                         {
                           "maTruong": "7976WAD0",
                           "tenTruong": "Trẻ Sáng Tạo"
                         },
                         {
                           "maTruong": "7976WAD1",
                           "tenTruong": "Vân Sơn"
                         },
                         {
                           "maTruong": "7976WAD7",
                           "tenTruong": "MN Mỹ Sài Gòn"
                         },
                         {
                           "maTruong": "7976WAD8",
                           "tenTruong": "MN Gia Đình Nhỏ"
                         },
                         {
                           "maTruong": "79761WU6",
                           "tenTruong": "Nắng Xuân"
                         },
                         {
                           "maTruong": "7976101001",
                           "tenTruong": "Ngôi Nhà Tuổi Thơ 1"
                         },
                         {
                           "maTruong": "7976101002",
                           "tenTruong": "Ngôi Nhà Sáng Tạo 2"
                         },
                         {
                           "maTruong": "7976101003",
                           "tenTruong": "Quả Chanh Vàng"
                         },
                         {
                           "maTruong": "7976101004",
                           "tenTruong": "Bảo An"
                         },
                         {
                           "maTruong": "7976101005",
                           "tenTruong": "Vườn Yêu Thương"
                         },
                         {
                           "maTruong": "7976101006",
                           "tenTruong": "Hạnh Nguyên"
                         },
                         {
                           "maTruong": "7976101007",
                           "tenTruong": "Bé Xinh 2"
                         },
                         {
                           "maTruong": "7976101008",
                           "tenTruong": "Hoa Mặt Trời"
                         },
                         {
                           "maTruong": "7976101009",
                           "tenTruong": "Tấn Lộc 2"
                         },
                         {
                           "maTruong": "7976101010",
                           "tenTruong": "Vườn Sóc 1"
                         },
                         {
                           "maTruong": "7976101011",
                           "tenTruong": "Thu Quỳnh"
                         },
                         {
                           "maTruong": "7976101012",
                           "tenTruong": "Tâm sao Việt "
                         },
                         {
                           "maTruong": "7976101013",
                           "tenTruong": "Song Ân"
                         },
                         {
                           "maTruong": "79761424",
                           "tenTruong": "TH Võ Thị Thừa"
                         },
                         {
                           "maTruong": "79761030",
                           "tenTruong": "Thành phố Tuổi thơ"
                         },
                         {
                           "maTruong": "79761163",
                           "tenTruong": "Bé Khỏe"
                         },
                         {
                           "maTruong": "79761077",
                           "tenTruong": "Sao Biển"
                         },
                         {
                           "maTruong": "79761239",
                           "tenTruong": "Chồi Xanh"
                         },
                         {
                           "maTruong": "79761250",
                           "tenTruong": "Phương Minh"
                         },
                         {
                           "maTruong": "79761149",
                           "tenTruong": "Thị Trấn Nhỏ"
                         },
                         {
                           "maTruong": "79761276",
                           "tenTruong": "Ngôi sao Mới"
                         },
                         {
                           "maTruong": "79761299",
                           "tenTruong": "Ngôi Sao Nhí 2"
                         },
                         {
                           "maTruong": "79761292",
                           "tenTruong": "Phúc Thịnh"
                         },
                         {
                           "maTruong": "79761056",
                           "tenTruong": "Nhân Văn"
                         },
                         {
                           "maTruong": "79761256",
                           "tenTruong": "Vườn Mặt Trời"
                         },
                         {
                           "maTruong": "79761258",
                           "tenTruong": "MN Hoa Đỗ Quyên"
                         },
                         {
                           "maTruong": "79761108",
                           "tenTruong": "MN Hoa Phong Lan"
                         },
                         {
                           "maTruong": "79761145",
                           "tenTruong": "Thảo Nguyên"
                         },
                         {
                           "maTruong": "79761478",
                           "tenTruong": "TH Tre Việt"
                         },
                         {
                           "maTruong": "79761W00",
                           "tenTruong": "Ánh Dương"
                         },
                         {
                           "maTruong": "79761Wac2",
                           "tenTruong": "Tấn Lộc"
                         },
                         {
                           "maTruong": "7976101019",
                           "tenTruong": "Sao Khuê"
                         },
                         {
                           "maTruong": "7976101020",
                           "tenTruong": "Thiên Đức"
                         },
                         {
                           "maTruong": "7976101021",
                           "tenTruong": "Việt Âu 1"
                         },
                         {
                           "maTruong": "7976101022",
                           "tenTruong": "Tinh Hoa Nhí"
                         },
                         {
                           "maTruong": "7976101014",
                           "tenTruong": "Sao Trí Việt"
                         },
                         {
                           "maTruong": "7976101015",
                           "tenTruong": "Hàn Việt"
                         },
                         {
                           "maTruong": "7976101016",
                           "tenTruong": "Trí Tuệ Việt 2"
                         },
                         {
                           "maTruong": "7976101017",
                           "tenTruong": "Khủng Long Con"
                         },
                         {
                           "maTruong": "7976101018",
                           "tenTruong": "Minh Thư"
                         },
                         {
                           "maTruong": "7976102001",
                           "tenTruong": "Trường Tiểu học Tuệ Đức"
                         },
                         {
                           "maTruong": "7976101023",
                           "tenTruong": "Ngôi Sao Mới 2"
                         },
                         {
                           "maTruong": "7976AWD6",
                           "tenTruong": "MN Anh Mỹ"
                         },
                         {
                           "maTruong": "7976101038",
                           "tenTruong": "Tâm Sao Việt"
                         },
                         {
                           "maTruong": "7976101039",
                           "tenTruong": "Tâm Trí Đức"
                         },
                         {
                           "maTruong": "7976101040",
                           "tenTruong": "Hoa Trạng Nguyên"
                         },
                         {
                           "maTruong": "7976101041",
                           "tenTruong": "Lá Xanh"
                         },
                         {
                           "maTruong": "7976101042",
                           "tenTruong": "Ngọc Tâm"
                         },
                         {
                           "maTruong": "7976101043",
                           "tenTruong": "Ngôi Sao"
                         },
                         {
                           "maTruong": "7976101044",
                           "tenTruong": "Ong Vàng"
                         },
                         {
                           "maTruong": "7976101045",
                           "tenTruong": "Quả Chanh Vàng 2"
                         },
                         {
                           "maTruong": "7976101046",
                           "tenTruong": "Su Hào"
                         },
                         {
                           "maTruong": "7976101047",
                           "tenTruong": "Thỏ Ngọc"
                         },
                         {
                           "maTruong": "7976101048",
                           "tenTruong": "Tuổi Thần Tiên 1"
                         },
                         {
                           "maTruong": "7976101049",
                           "tenTruong": "Trẻ Trí Tuệ"
                         },
                         {
                           "maTruong": "7976101050",
                           "tenTruong": "Bé Vàng"
                         },
                         {
                           "maTruong": "7976101051",
                           "tenTruong": "Vĩnh Tân"
                         },
                         {
                           "maTruong": "7976101052",
                           "tenTruong": "Hoàng Bảo Ngọc"
                         },
                         {
                           "maTruong": "7976101053",
                           "tenTruong": "Ngôi Nhà Hạnh Phúc"
                         },
                         {
                           "maTruong": "7976101054",
                           "tenTruong": "Hoa Phượng Thới An"
                         },
                         {
                           "maTruong": "7976101055",
                           "tenTruong": "Mặt Trời Vui Nhộn"
                         },
                         {
                           "maTruong": "7976101056",
                           "tenTruong": "Thiên An"
                         },
                         {
                           "maTruong": "7976101057",
                           "tenTruong": "Thiên Phước"
                         },
                         {
                           "maTruong": "7976101058",
                           "tenTruong": "Diệu Phước"
                         },
                         {
                           "maTruong": "7976101059",
                           "tenTruong": "Dâu Tây"
                         },
                         {
                           "maTruong": "7976101060",
                           "tenTruong": "Hoa Thiên Lý 1"
                         },
                         {
                           "maTruong": "7976101061",
                           "tenTruong": "Hoa Thiên Lý 2"
                         },
                         {
                           "maTruong": "7976101062",
                           "tenTruong": "Hoa Thiên Lý 3"
                         },
                         {
                           "maTruong": "7976101025",
                           "tenTruong": "Ngôi Nhà Tâm An"
                         },
                         {
                           "maTruong": "7976101026",
                           "tenTruong": "Hạt Đậu Nhỏ 1"
                         },
                         {
                           "maTruong": "7976101027",
                           "tenTruong": "Bé Ong"
                         },
                         {
                           "maTruong": "7976101028",
                           "tenTruong": "Hoa Bằng Lăng"
                         },
                         {
                           "maTruong": "7976101029",
                           "tenTruong": "Con Cưng"
                         },
                         {
                           "maTruong": "7976101030",
                           "tenTruong": "Gia Phúc"
                         },
                         {
                           "maTruong": "7976101031",
                           "tenTruong": "Lá"
                         },
                         {
                           "maTruong": "7976101032",
                           "tenTruong": "Minh Quân"
                         },
                         {
                           "maTruong": "7976101033",
                           "tenTruong": "Thiên Lộc 2"
                         },
                         {
                           "maTruong": "7976101034",
                           "tenTruong": "Phương Minh 3"
                         },
                         {
                           "maTruong": "7976101035",
                           "tenTruong": "Vành Khuyên"
                         },
                         {
                           "maTruong": "7976101036",
                           "tenTruong": "Việt Âu Tâm Đức"
                         },
                         {
                           "maTruong": "7976101024",
                           "tenTruong": "MN 1 tháng 4"
                         },
                         {
                           "maTruong": "7976102002",
                           "tenTruong": "TH Nguyễn An Khương"
                         },
                         {
                           "maTruong": "7976103001",
                           "tenTruong": "THCS Tô Ngọc Vân"
                         },
                         {
                           "maTruong": "7976101063",
                           "tenTruong": "Ngôi Nhà Xanh"
                         },
                         {
                           "maTruong": "7976101064",
                           "tenTruong": "Tuổi Tiên"
                         },
                         {
                           "maTruong": "7976101065",
                           "tenTruong": "Ngôi Sao Nhỏ"
                         },
                         {
                           "maTruong": "7976101037",
                           "tenTruong": "Nhà Của Bé"
                         },
                         {
                           "maTruong": "7976101066",
                           "tenTruong": "Quốc Việt"
                         },
                         {
                           "maTruong": "7976101067",
                           "tenTruong": "Vườn Hoạ Mi"
                         },
                         {
                           "maTruong": "7976101068",
                           "tenTruong": "Tài Năng Nhí"
                         },
                         {
                           "maTruong": "7976101069",
                           "tenTruong": "Thiên Phúc"
                         },
                         {
                           "maTruong": "7976101070",
                           "tenTruong": "Mỹ Đức 3"
                         },
                         {
                           "maTruong": "7976101071",
                           "tenTruong": "Ngôi Nhà Hạnh Phúc 2"
                         },
                         {
                           "maTruong": "7976101082",
                           "tenTruong": "Bé Si Mi"
                         },
                         {
                           "maTruong": "7976103002",
                           "tenTruong": "TH và THCS Tài Năng Trẻ Châu Á"
                         },
                         {
                           "maTruong": "7976101090",
                           "tenTruong": "Nhật Minh"
                         },
                         {
                           "maTruong": "7976101091",
                           "tenTruong": "Hoa Sen"
                         },
                         {
                           "maTruong": "7976101092",
                           "tenTruong": "Cánh Diều Việt Âu"
                         },
                         {
                           "maTruong": "7976101093",
                           "tenTruong": "Nhà Của Minh"
                         },
                         {
                           "maTruong": "7976101094",
                           "tenTruong": "Biển Xanh"
                         },
                         {
                           "maTruong": "7976101095",
                           "tenTruong": "Ánh Dương 2"
                         },
                         {
                           "maTruong": "7976101096",
                           "tenTruong": "Khánh An"
                         },
                         {
                           "maTruong": "7976101097",
                           "tenTruong": "Tia Nắng Nhỏ"
                         },
                         {
                           "maTruong": "7976101098",
                           "tenTruong": "Tương Lai Mới"
                         },
                         {
                           "maTruong": "7976101099",
                           "tenTruong": "Mặt Trời Tuổi Thơ"
                         },
                         {
                           "maTruong": "7976101100",
                           "tenTruong": "Tâm Đức"
                         },
                         {
                           "maTruong": "7976101101",
                           "tenTruong": "Trẻ Sáng Tạo 3"
                         },
                         {
                           "maTruong": "7976101102",
                           "tenTruong": "Hươu Vàng"
                         },
                         {
                           "maTruong": "7976101103",
                           "tenTruong": "Tuệ Tâm"
                         },
                         {
                           "maTruong": "7976101104",
                           "tenTruong": "Hành Tinh Trẻ Thơ"
                         },
                         {
                           "maTruong": "7976101105",
                           "tenTruong": "Lan Hy"
                         },
                         {
                           "maTruong": "7976101106",
                           "tenTruong": "Cầu Vồng"
                         },
                         {
                           "maTruong": "7976101107",
                           "tenTruong": "Sao Khuê 2"
                         },
                         {
                           "maTruong": "7976101108",
                           "tenTruong": "Ngôi Sao Nhỏ 2"
                         },
                         {
                           "maTruong": "7976101109",
                           "tenTruong": "Ngôi Sao Nhỏ 1"
                         },
                         {
                           "maTruong": "7976101083",
                           "tenTruong": "Bồ Công Anh"
                         },
                         {
                           "maTruong": "7976101084",
                           "tenTruong": "Cánh Diều Vàng"
                         },
                         {
                           "maTruong": "7976101085",
                           "tenTruong": "Hoa Cọ Vàng"
                         },
                         {
                           "maTruong": "7976101086",
                           "tenTruong": "Trẻ Thông Minh"
                         },
                         {
                           "maTruong": "7976101087",
                           "tenTruong": "Việt Âu 3"
                         },
                         {
                           "maTruong": "7976101088",
                           "tenTruong": "Vườn Ong"
                         },
                         {
                           "maTruong": "7976101089",
                           "tenTruong": "Chú Bồ Câu"
                         },
                         {
                           "maTruong": "7976101072",
                           "tenTruong": "Ngôi Sao Sáng"
                         },
                         {
                           "maTruong": "7976101073",
                           "tenTruong": "Hoàng Gia"
                         },
                         {
                           "maTruong": "7976101074",
                           "tenTruong": "Chong Chóng Tre"
                         },
                         {
                           "maTruong": "7976101075",
                           "tenTruong": "Ngôi Nhà Trẻ Thơ"
                         },
                         {
                           "maTruong": "7976101076",
                           "tenTruong": "Vườn Tuổi Thơ"
                         },
                         {
                           "maTruong": "7976101077",
                           "tenTruong": "Hoà Giang"
                         },
                         {
                           "maTruong": "7976101078",
                           "tenTruong": "Úc Châu 3"
                         },
                         {
                           "maTruong": "7976101079",
                           "tenTruong": "Gia Đình Vườn Sóc"
                         },
                         {
                           "maTruong": "7976101080",
                           "tenTruong": "Minh Lan"
                         },
                         {
                           "maTruong": "7976101081",
                           "tenTruong": "Việt Đức"
                         },
                         {
                           "maTruong": "7976102005",
                           "tenTruong": "TH Trần Quốc Toản"
                         },
                         {
                           "maTruong": "7976102006",
                           "tenTruong": "TH Song Minh"
                         },
                         {
                           "maTruong": "7976102004",
                           "tenTruong": "TH Việt Âu"
                         }
                       ]
                     },
                     {
                       "maPhong": "776",
                       "tenPhong": "Quận 8",
                       "truongData": [
                         {
                           "maTruong": "79776422",
                           "tenTruong": "Hoàng Minh Đạo"
                         },
                         {
                           "maTruong": "79776409",
                           "tenTruong": "TH An Phong"
                         },
                         {
                           "maTruong": "79776403",
                           "tenTruong": "TH Âu Dương Lân"
                         },
                         {
                           "maTruong": "79776406",
                           "tenTruong": "TH Bông Sao"
                         },
                         {
                           "maTruong": "79776408",
                           "tenTruong": "TH Bùi Minh Trực"
                         },
                         {
                           "maTruong": "79776416",
                           "tenTruong": "TH Hồng Đức"
                         },
                         {
                           "maTruong": "79776411",
                           "tenTruong": "TH Hưng Phú"
                         },
                         {
                           "maTruong": "79776418",
                           "tenTruong": "TH Lưu Hữu Phước"
                         },
                         {
                           "maTruong": "79776421",
                           "tenTruong": "TH Lý Nhân Tông"
                         },
                         {
                           "maTruong": "79776413",
                           "tenTruong": "TH Lý Thái Tổ"
                         },
                         {
                           "maTruong": "79776420",
                           "tenTruong": "TH Nguyễn Công Trứ"
                         },
                         {
                           "maTruong": "79776417",
                           "tenTruong": "TH Nguyễn Nhược Thị"
                         },
                         {
                           "maTruong": "79776401",
                           "tenTruong": "TH Nguyễn Trực"
                         },
                         {
                           "maTruong": "79776419",
                           "tenTruong": "TH Nguyễn Trung Ngạn"
                         },
                         {
                           "maTruong": "79776407",
                           "tenTruong": "TH Phan Đăng Lưu"
                         },
                         {
                           "maTruong": "79776405",
                           "tenTruong": "TH Thái Hưng"
                         },
                         {
                           "maTruong": "79776410",
                           "tenTruong": "TH Trần Danh Lâm"
                         },
                         {
                           "maTruong": "79776415",
                           "tenTruong": "TH Trần Nguyên Hãn"
                         },
                         {
                           "maTruong": "79776414",
                           "tenTruong": "TH Tuy Lý Vương"
                         },
                         {
                           "maTruong": "79776404",
                           "tenTruong": "TH Vàm Cỏ Đông"
                         },
                         {
                           "maTruong": "79776402",
                           "tenTruong": "Tiểu học  Rạch Ông"
                         },
                         {
                           "maTruong": "79776509",
                           "tenTruong": "THCS Bình An"
                         },
                         {
                           "maTruong": "79776510",
                           "tenTruong": "THCS Bình Đông"
                         },
                         {
                           "maTruong": "79776503",
                           "tenTruong": "THCS Chánh Hưng"
                         },
                         {
                           "maTruong": "79776501",
                           "tenTruong": "THCS Dương Bá Trạc"
                         },
                         {
                           "maTruong": "79776502",
                           "tenTruong": "THCS Khánh Bình"
                         },
                         {
                           "maTruong": "79776508",
                           "tenTruong": "THCS Lê Lai"
                         },
                         {
                           "maTruong": "79776511",
                           "tenTruong": "THCS Lý Thánh Tông"
                         },
                         {
                           "maTruong": "79776505",
                           "tenTruong": "THCS Phan Đăng Lưu"
                         },
                         {
                           "maTruong": "79776512",
                           "tenTruong": "THCS Phú Lợi"
                         },
                         {
                           "maTruong": "79776504",
                           "tenTruong": "THCS Sương Nguyệt Anh"
                         },
                         {
                           "maTruong": "79776506",
                           "tenTruong": "THCS Trần Danh Ninh"
                         },
                         {
                           "maTruong": "79776507",
                           "tenTruong": "THCS Tùng Thiện Vương"
                         },
                         {
                           "maTruong": "79000F03",
                           "tenTruong": "THPT Chuyên Năng khiếu TDTT Nguyễn Thị Định"
                         },
                         {
                           "maTruong": "79776319",
                           "tenTruong": "MẦM NON 26"
                         },
                         {
                           "maTruong": "79776326",
                           "tenTruong": "Mầm non Bông Sen"
                         },
                         {
                           "maTruong": "79776W08",
                           "tenTruong": "MN 162"
                         },
                         {
                           "maTruong": "79776311",
                           "tenTruong": "MN 19/5"
                         },
                         {
                           "maTruong": "79776333",
                           "tenTruong": "MN Ánh Dương"
                         },
                         {
                           "maTruong": "79776315",
                           "tenTruong": "MN Anh Việt"
                         },
                         {
                           "maTruong": "79776W14",
                           "tenTruong": "MN Bảo Nguyên"
                         },
                         {
                           "maTruong": "79776310",
                           "tenTruong": "MN Bé Ngoan"
                         },
                         {
                           "maTruong": "79776335",
                           "tenTruong": "MN Be Thong Minh"
                         },
                         {
                           "maTruong": "79776317",
                           "tenTruong": "MN Bi Bi"
                         },
                         {
                           "maTruong": "79776302",
                           "tenTruong": "MN Bình Minh"
                         },
                         {
                           "maTruong": "79776X08",
                           "tenTruong": "MN Bình Thuận"
                         },
                         {
                           "maTruong": "79776309",
                           "tenTruong": "MN Bông Hồng"
                         },
                         {
                           "maTruong": "79776351",
                           "tenTruong": "MN Búp Sen Hồng"
                         },
                         {
                           "maTruong": "79776327",
                           "tenTruong": "MN Gia Đình Hạnh Phúc"
                         },
                         {
                           "maTruong": "79776320",
                           "tenTruong": "MN Hòa Bình"
                         },
                         {
                           "maTruong": "79776307",
                           "tenTruong": "MN Hoạ Mi"
                         },
                         {
                           "maTruong": "79776353",
                           "tenTruong": "MN Hoa Phượng"
                         },
                         {
                           "maTruong": "797763a1",
                           "tenTruong": "MN Hoa Tigon 2"
                         },
                         {
                           "maTruong": "79776313",
                           "tenTruong": "MN Hoàng Mai 1"
                         },
                         {
                           "maTruong": "79776314",
                           "tenTruong": "MN Hoàng Mai 3"
                         },
                         {
                           "maTruong": "79776308",
                           "tenTruong": "MN Kim Đồng"
                         },
                         {
                           "maTruong": "79776330",
                           "tenTruong": "MN Mặt Trời Bé Con"
                         },
                         {
                           "maTruong": "79776305",
                           "tenTruong": "MN Nắng Mai"
                         },
                         {
                           "maTruong": "79776323",
                           "tenTruong": "MN Sao Mai"
                         },
                         {
                           "maTruong": "79776W50",
                           "tenTruong": "MN Sen Vàng"
                         },
                         {
                           "maTruong": "79776306",
                           "tenTruong": "MN Sơn Ca"
                         },
                         {
                           "maTruong": "79776332",
                           "tenTruong": "MN Thiên Anh"
                         },
                         {
                           "maTruong": "79776325",
                           "tenTruong": "MN Thỏ Ngọc"
                         },
                         {
                           "maTruong": "79776331",
                           "tenTruong": "MN Tuệ Đức"
                         },
                         {
                           "maTruong": "79776303",
                           "tenTruong": "MN Tuổi Hoa"
                         },
                         {
                           "maTruong": "79776312",
                           "tenTruong": "MN Tuổi Ngọc"
                         },
                         {
                           "maTruong": "79776324",
                           "tenTruong": "MN Tuổi Thơ"
                         },
                         {
                           "maTruong": "79776304",
                           "tenTruong": "MN Vàng Anh"
                         },
                         {
                           "maTruong": "79776203",
                           "tenTruong": "MN Vành Khuyên"
                         },
                         {
                           "maTruong": "79776318",
                           "tenTruong": "MN Việt Hoa"
                         },
                         {
                           "maTruong": "79776329",
                           "tenTruong": "MN Việt Mỹ"
                         },
                         {
                           "maTruong": "79776301",
                           "tenTruong": "MN Việt Nhi"
                         },
                         {
                           "maTruong": "79776322",
                           "tenTruong": "MN Vườn Hồng"
                         },
                         {
                           "maTruong": "79776352",
                           "tenTruong": "Trường MN Ngôi Sao"
                         },
                         {
                           "maTruong": "79776X01",
                           "tenTruong": "LMG 39/12"
                         },
                         {
                           "maTruong": "79776L30",
                           "tenTruong": "LMG Ánh Dương 2"
                         },
                         {
                           "maTruong": "79776L03",
                           "tenTruong": "LMG Anh Khôi"
                         },
                         {
                           "maTruong": "79776A00",
                           "tenTruong": "LMG Ánh Mai"
                         },
                         {
                           "maTruong": "79776L37",
                           "tenTruong": "LMG Ánh Sao"
                         },
                         {
                           "maTruong": "79776W31",
                           "tenTruong": "LMG Anh Thư"
                         },
                         {
                           "maTruong": "79776W03",
                           "tenTruong": "LMG BaBy"
                         },
                         {
                           "maTruong": "79776W32",
                           "tenTruong": "LMG Bảo Minh"
                         },
                         {
                           "maTruong": "79776X05",
                           "tenTruong": "LMG Bình An"
                         },
                         {
                           "maTruong": "79776O03",
                           "tenTruong": "LMG Bình Đăng"
                         },
                         {
                           "maTruong": "79776X04",
                           "tenTruong": "LMG BÌNH THÁI"
                         },
                         {
                           "maTruong": "79776W34",
                           "tenTruong": "LMG Cát Tường"
                         },
                         {
                           "maTruong": "79776X03",
                           "tenTruong": "LMG Gấu Con"
                         },
                         {
                           "maTruong": "79776O08",
                           "tenTruong": "LMG Hải Âu"
                         },
                         {
                           "maTruong": "79776W36",
                           "tenTruong": "LMG Hải Vân"
                         },
                         {
                           "maTruong": "79776W35",
                           "tenTruong": "LMG Hải Yến"
                         },
                         {
                           "maTruong": "79776W09",
                           "tenTruong": "LMG Hạnh Dung"
                         },
                         {
                           "maTruong": "79776L41",
                           "tenTruong": "LMG Heo vàng"
                         },
                         {
                           "maTruong": "79776O09",
                           "tenTruong": "LMG Hoa Phượng Đỏ"
                         },
                         {
                           "maTruong": "79776123",
                           "tenTruong": "LMG Hoa Thiên Lý"
                         },
                         {
                           "maTruong": "79776W10",
                           "tenTruong": "LMG Hoa Tigôn"
                         },
                         {
                           "maTruong": "79776129",
                           "tenTruong": "LMG Hoàng Oanh"
                         },
                         {
                           "maTruong": "79776W12",
                           "tenTruong": "LMG Hoàng Oanh 2"
                         },
                         {
                           "maTruong": "79776L40",
                           "tenTruong": "LMG Hoàng Oanh 4"
                         },
                         {
                           "maTruong": "79776O07",
                           "tenTruong": "LMG Hướng Dương"
                         },
                         {
                           "maTruong": "79776X07",
                           "tenTruong": "LMG Khai Tâm"
                         },
                         {
                           "maTruong": "79776128",
                           "tenTruong": "LMG Khôi Nguyên"
                         },
                         {
                           "maTruong": "79776316",
                           "tenTruong": "LMG Lan Anh"
                         },
                         {
                           "maTruong": "79776L05",
                           "tenTruong": "LMG Mặt Trời"
                         },
                         {
                           "maTruong": "79776L35",
                           "tenTruong": "LMG Minh Viên"
                         },
                         {
                           "maTruong": "79776X13",
                           "tenTruong": "LMG Misa"
                         },
                         {
                           "maTruong": "79776O11",
                           "tenTruong": "LMG Nai Ngọc"
                         },
                         {
                           "maTruong": "79776W02",
                           "tenTruong": "LMG Nam Mỹ"
                         },
                         {
                           "maTruong": "79776W13",
                           "tenTruong": "LMG Ngôi Sao Nhỏ"
                         },
                         {
                           "maTruong": "79776W23",
                           "tenTruong": "LMG Ngôi Sao Việt"
                         },
                         {
                           "maTruong": "79776L07",
                           "tenTruong": "LMG Phúc Bình An"
                         },
                         {
                           "maTruong": "79776W38",
                           "tenTruong": "LMG Sao Ánh Dương"
                         },
                         {
                           "maTruong": "79776X02",
                           "tenTruong": "LMG Sen Hồng"
                         },
                         {
                           "maTruong": "79776L04",
                           "tenTruong": "LMG Thần Đồng Việt"
                         },
                         {
                           "maTruong": "79776L21",
                           "tenTruong": "LMG Thiên Phúc"
                         },
                         {
                           "maTruong": "79776X10",
                           "tenTruong": "LMG Thiên thần Nhỏ"
                         },
                         {
                           "maTruong": "79776W25",
                           "tenTruong": "LMG Thỏ Bạch"
                         },
                         {
                           "maTruong": "79776X11",
                           "tenTruong": "LMG Tuổi Hồng"
                         },
                         {
                           "maTruong": "79776L33",
                           "tenTruong": "Lớp mẫu giáo Trí Đức"
                         },
                         {
                           "maTruong": "79776L32",
                           "tenTruong": "Nhóm trẻ Tình Mẹ"
                         },
                         {
                           "maTruong": "79776W07",
                           "tenTruong": "NT 375"
                         },
                         {
                           "maTruong": "79776W01",
                           "tenTruong": "NT Cô Hoa"
                         },
                         {
                           "maTruong": "79776O05",
                           "tenTruong": "NT Cô Là"
                         },
                         {
                           "maTruong": "79776L31",
                           "tenTruong": "NT Hải Âu 2"
                         },
                         {
                           "maTruong": "79776W15",
                           "tenTruong": "NT Hoa Cau"
                         },
                         {
                           "maTruong": "79776W21",
                           "tenTruong": "NT Hoa Qùynh"
                         },
                         {
                           "maTruong": "79776W29",
                           "tenTruong": "NT Hoàng Oanh 3"
                         },
                         {
                           "maTruong": "79776W11",
                           "tenTruong": "NT Hoàng Yến"
                         },
                         {
                           "maTruong": "79776L06",
                           "tenTruong": "NT Khai Trí"
                         },
                         {
                           "maTruong": "79776W41",
                           "tenTruong": "NT Măng Non"
                         },
                         {
                           "maTruong": "79776L39",
                           "tenTruong": "NT Mặt Trời 2"
                         },
                         {
                           "maTruong": "79776127",
                           "tenTruong": "NT Minh Anh"
                         },
                         {
                           "maTruong": "79776L38",
                           "tenTruong": "NT Nam Anh"
                         },
                         {
                           "maTruong": "79776W40",
                           "tenTruong": "NT Ngôi Sao Nhỏ 2"
                         },
                         {
                           "maTruong": "79776124",
                           "tenTruong": "NT Nụ Cười"
                         },
                         {
                           "maTruong": "79776O06",
                           "tenTruong": "NT Phú Lợi"
                         },
                         {
                           "maTruong": "79776O10",
                           "tenTruong": "NT Rạng Đông"
                         },
                         {
                           "maTruong": "79776L34",
                           "tenTruong": "NT Sao Ánh Dương 2"
                         },
                         {
                           "maTruong": "79776L36",
                           "tenTruong": "NT Sen Hồng 2"
                         },
                         {
                           "maTruong": "79776X36",
                           "tenTruong": "NT Sóc Nâu"
                         },
                         {
                           "maTruong": "79776W28",
                           "tenTruong": "NT Thiên Nga"
                         },
                         {
                           "maTruong": "79776W22",
                           "tenTruong": "NT Ti Ti"
                         },
                         {
                           "maTruong": "79776O04",
                           "tenTruong": "NT Trúc Anh"
                         },
                         {
                           "maTruong": "79776O02",
                           "tenTruong": "LMG Mây Hồng"
                         },
                         {
                           "maTruong": "79776W30",
                           "tenTruong": "NT Yêu Bé"
                         },
                         {
                           "maTruong": "79776370",
                           "tenTruong": "MN Mặt Trời"
                         },
                         {
                           "maTruong": "79776355",
                           "tenTruong": "MN Hoa Trà My 3"
                         },
                         {
                           "maTruong": "79776356",
                           "tenTruong": "MN Hoa Trà My 4"
                         },
                         {
                           "maTruong": "79776388",
                           "tenTruong": "MN Mặt Trời 3"
                         },
                         {
                           "maTruong": "79776358",
                           "tenTruong": "MN Đa Đa"
                         },
                         {
                           "maTruong": "79776201",
                           "tenTruong": "LMG Nguyên Đạt"
                         },
                         {
                           "maTruong": "79776202",
                           "tenTruong": "LMG Hoa Hướng Dương"
                         },
                         {
                           "maTruong": "79776101",
                           "tenTruong": "NT Ngôi Nhà Vui Nhộn"
                         },
                         {
                           "maTruong": "79776102",
                           "tenTruong": "LMG Măng non 2"
                         },
                         {
                           "maTruong": "79776298",
                           "tenTruong": "LMG Bầu Trời"
                         },
                         {
                           "maTruong": "79776206",
                           "tenTruong": "LMG Thái Dương"
                         },
                         {
                           "maTruong": "79776360",
                           "tenTruong": "MN Bầu Trời Xanh"
                         },
                         {
                           "maTruong": "79776208",
                           "tenTruong": "LMG Phúc Ân"
                         },
                         {
                           "maTruong": "79776361",
                           "tenTruong": "MN Ánh Bình Minh"
                         },
                         {
                           "maTruong": "79776362",
                           "tenTruong": "MN Con Yêu"
                         },
                         {
                           "maTruong": "79776392",
                           "tenTruong": "MN Việt Đức"
                         },
                         {
                           "maTruong": "79776393",
                           "tenTruong": "MN Việt Úc"
                         },
                         {
                           "maTruong": "79776389",
                           "tenTruong": "MN Ngày Vui"
                         },
                         {
                           "maTruong": "79776299",
                           "tenTruong": "LMG Hoàng Oanh 5"
                         },
                         {
                           "maTruong": "79776425",
                           "tenTruong": "TH Khai Nguyên"
                         },
                         {
                           "maTruong": "79776424",
                           "tenTruong": "TH Đông Nam Á"
                         },
                         {
                           "maTruong": "79776X41",
                           "tenTruong": "Nhóm trẻ Hoa Mặt Trời"
                         },
                         {
                           "maTruong": "79776030",
                           "tenTruong": "MN Việt Mỹ 2"
                         },
                         {
                           "maTruong": "79776009",
                           "tenTruong": "MN Bé Gấu"
                         },
                         {
                           "maTruong": "79776X38",
                           "tenTruong": "Lớp Mẫu giáo Bắc Âu"
                         },
                         {
                           "maTruong": "79776032",
                           "tenTruong": "Lớp Mẫu giáo Hải Âu 3"
                         },
                         {
                           "maTruong": "79776017",
                           "tenTruong": "Lớp Mẫu giáo Tuệ Tâm"
                         },
                         {
                           "maTruong": "7977601001",
                           "tenTruong": "Lớp Mẫu giáo Thiên Hà Xanh"
                         },
                         {
                           "maTruong": "7977601002",
                           "tenTruong": "Lớp Mẫu giáo Đô Rê Mon"
                         },
                         {
                           "maTruong": "7977601003",
                           "tenTruong": "Lớp Mẫu giáo Ngôi Nhà Tuổi Thơ"
                         },
                         {
                           "maTruong": "7977601004",
                           "tenTruong": "Nhóm trẻ Trái Tim Nhỏ"
                         },
                         {
                           "maTruong": "7977601005",
                           "tenTruong": "Lớp Mẫu giáo Trái Tim Nhỏ 2"
                         },
                         {
                           "maTruong": "7977601006",
                           "tenTruong": "MN Tổ Ong Vàng"
                         },
                         {
                           "maTruong": "7977602001",
                           "tenTruong": "Tiểu học Việt Mỹ"
                         },
                         {
                           "maTruong": "7977601008",
                           "tenTruong": "LMG Hoa Anh Đào Nhỏ 2"
                         },
                         {
                           "maTruong": "7977601009",
                           "tenTruong": "NT Hoa Anh Đào Nhỏ"
                         },
                         {
                           "maTruong": "7977601010",
                           "tenTruong": "NT Trái Tim Nhỏ"
                         },
                         {
                           "maTruong": "7977601012",
                           "tenTruong": "NT Vườn Ươm Tương Lai"
                         },
                         {
                           "maTruong": "7977601013",
                           "tenTruong": "NT Cá Chép Đỏ"
                         },
                         {
                           "maTruong": "7977601014",
                           "tenTruong": "LMG Cá Chép Đỏ 2"
                         },
                         {
                           "maTruong": "7977601015",
                           "tenTruong": "LMG Ngôi Nhà Trẻ Thơ"
                         },
                         {
                           "maTruong": "7977603001",
                           "tenTruong": "THCS Khai Nguyên"
                         },
                         {
                           "maTruong": "7977601016",
                           "tenTruong": "Lớp Mầm non Hoàng Oanh 2"
                         },
                         {
                           "maTruong": "7977601017",
                           "tenTruong": "Lớp Mầm non Trái Tim Nhỏ"
                         },
                         {
                           "maTruong": "7977601018",
                           "tenTruong": "Lớp Mầm non Hành Tinh Xanh"
                         },
                         {
                           "maTruong": "7977601019",
                           "tenTruong": "Nhóm trẻ Vườn Ươm Tương Lai"
                         },
                         {
                           "maTruong": "7977601020",
                           "tenTruong": "Lớp Mầm non Thiên Phúc 3"
                         },
                         {
                           "maTruong": "7977601021",
                           "tenTruong": "Lớp Mẫu giáo Lan Chi"
                         },
                         {
                           "maTruong": "7977601022",
                           "tenTruong": "MN Hoa Trà My IV"
                         },
                         {
                           "maTruong": "7977601023",
                           "tenTruong": "Mầm non Hoa Trà My III"
                         },
                         {
                           "maTruong": "7977601024",
                           "tenTruong": "Lớp Mầm non Ngôi Nhà Cầu Vồng"
                         },
                         {
                           "maTruong": "7977601025",
                           "tenTruong": "Lớp Mầm non Mặt Trời 2"
                         },
                         {
                           "maTruong": "7977601026",
                           "tenTruong": "Lớp Mầm non Mặt Trời 4"
                         },
                         {
                           "maTruong": "7977601027",
                           "tenTruong": "Lớp Mầm non Thiên Hà Xanh 2"
                         },
                         {
                           "maTruong": "7977601029",
                           "tenTruong": "Lớp Mầm non Hưng Anh"
                         },
                         {
                           "maTruong": "7977601028",
                           "tenTruong": "Lớp Mầm non Học Viện Đầu Tiên"
                         },
                         {
                           "maTruong": "79776X39",
                           "tenTruong": "Lớp Mẫu giáo Thiên Anh"
                         },
                         {
                           "maTruong": "7977601033",
                           "tenTruong": "Lớp Mầm non Cá Heo Thông Minh"
                         },
                         {
                           "maTruong": "7977601030",
                           "tenTruong": "Lớp Mầm non Heo Vàng"
                         },
                         {
                           "maTruong": "7977601031",
                           "tenTruong": "Lớp Mầm non Sắc Màu"
                         },
                         {
                           "maTruong": "7977601032",
                           "tenTruong": "Lớp Mầm non Gia Đình Hạnh Phúc 2"
                         },
                         {
                           "maTruong": "7977601035",
                           "tenTruong": "Lớp Mầm non Ước Mơ Xanh"
                         },
                         {
                           "maTruong": "7977601036",
                           "tenTruong": "Lớp Mầm non Búp Măng Non"
                         },
                         {
                           "maTruong": "7977601037",
                           "tenTruong": "Lớp Mẫu giáo Hương Thơ"
                         },
                         {
                           "maTruong": "7977601038",
                           "tenTruong": "Lớp Mẫu giáo Nai Ngọc"
                         }
                       ]
                     },
                     {
                       "maPhong": "778",
                       "tenPhong": "Quận 7",
                       "truongData": [
                         {
                           "maTruong": "79778417",
                           "tenTruong": "TH Đặng Thùy Trâm"
                         },
                         {
                           "maTruong": "79778409",
                           "tenTruong": "TH Đinh Bộ Lĩnh"
                         },
                         {
                           "maTruong": "79778415",
                           "tenTruong": "TH Kim Đồng"
                         },
                         {
                           "maTruong": "79778411",
                           "tenTruong": "TH Lê Anh Xuân"
                         },
                         {
                           "maTruong": "79778402",
                           "tenTruong": "TH Lê Quý Đôn"
                         },
                         {
                           "maTruong": "79778408",
                           "tenTruong": "TH Lê Văn Tám"
                         },
                         {
                           "maTruong": "79778404",
                           "tenTruong": "TH Lương Thế Vinh"
                         },
                         {
                           "maTruong": "79778416",
                           "tenTruong": "TH Nam Sài Gòn"
                         },
                         {
                           "maTruong": "79778413",
                           "tenTruong": "TH Nguyễn Thị Định"
                         },
                         {
                           "maTruong": "79778410",
                           "tenTruong": "TH Nguyễn Văn Hưởng"
                         },
                         {
                           "maTruong": "79778401",
                           "tenTruong": "TH Phan Huy Thực"
                         },
                         {
                           "maTruong": "79778407",
                           "tenTruong": "TH Phù Đổng"
                         },
                         {
                           "maTruong": "79778412",
                           "tenTruong": "TH Phú Mỹ"
                         },
                         {
                           "maTruong": "79778403",
                           "tenTruong": "TH Tân Quy"
                         },
                         {
                           "maTruong": "79778406",
                           "tenTruong": "TH Tân Thuận"
                         },
                         {
                           "maTruong": "79778405",
                           "tenTruong": "TH Trần Quốc Toản"
                         },
                         {
                           "maTruong": "79778418",
                           "tenTruong": "TH Võ Thị Sáu"
                         },
                         {
                           "maTruong": "79778504",
                           "tenTruong": "THCS BC Nguyễn Hiền"
                         },
                         {
                           "maTruong": "79778505",
                           "tenTruong": "THCS Hoàng Quốc Việt"
                         },
                         {
                           "maTruong": "79778502",
                           "tenTruong": "THCS Huỳnh Tấn Phát"
                         },
                         {
                           "maTruong": "79778503",
                           "tenTruong": "THCS Nguyễn Hữu Thọ"
                         },
                         {
                           "maTruong": "79778506",
                           "tenTruong": "THCS Nguyễn Thị Thập"
                         },
                         {
                           "maTruong": "79778507",
                           "tenTruong": "THCS Phạm Hữu Lầu"
                         },
                         {
                           "maTruong": "79778501",
                           "tenTruong": "THCS Trần Quốc Tuấn"
                         },
                         {
                           "maTruong": "79000850",
                           "tenTruong": "THCS - THPT Đinh Thiện Lý"
                         },
                         {
                           "maTruong": "79000849",
                           "tenTruong": "THCS - THPT Đức Trí"
                         },
                         {
                           "maTruong": "79000851",
                           "tenTruong": "THCS - THPT Sao Việt"
                         },
                         {
                           "maTruong": "79000820",
                           "tenTruong": "THPT Nam Sài Gòn"
                         },
                         {
                           "maTruong": "79000C05",
                           "tenTruong": "THPT Quốc tế Canada"
                         },
                         {
                           "maTruong": "79778214",
                           "tenTruong": "MG Bình Minh"
                         },
                         {
                           "maTruong": "79778210",
                           "tenTruong": "MG Hướng Dương"
                         },
                         {
                           "maTruong": "79778203",
                           "tenTruong": "MG Măng Mon"
                         },
                         {
                           "maTruong": "79778359",
                           "tenTruong": "MG Ngôi Sao Sáng"
                         },
                         {
                           "maTruong": "79778213",
                           "tenTruong": "MG Tắc Rỗi"
                         },
                         {
                           "maTruong": "79778202",
                           "tenTruong": "MG Tân Phú"
                         },
                         {
                           "maTruong": "79778349",
                           "tenTruong": "Mầm non Nụ Cười"
                         },
                         {
                           "maTruong": "79778301",
                           "tenTruong": "MN 19/5"
                         },
                         {
                           "maTruong": "79778302",
                           "tenTruong": "MN 30/4"
                         },
                         {
                           "maTruong": "79778336",
                           "tenTruong": "MN ABC"
                         },
                         {
                           "maTruong": "79778343",
                           "tenTruong": "MN Bách Việt"
                         },
                         {
                           "maTruong": "79778326",
                           "tenTruong": "MN Ban Mai"
                         },
                         {
                           "maTruong": "79778355",
                           "tenTruong": "MN Bầu Trời Xanh"
                         },
                         {
                           "maTruong": "79778334",
                           "tenTruong": "MN Bé ngoan"
                         },
                         {
                           "maTruong": "79778317",
                           "tenTruong": "MN Bình Thuận"
                         },
                         {
                           "maTruong": "79778331",
                           "tenTruong": "MN Bông Hoa Nhỏ"
                         },
                         {
                           "maTruong": "79778358",
                           "tenTruong": "MN Bước Vui"
                         },
                         {
                           "maTruong": "79778329",
                           "tenTruong": "MN Chim Cánh Cụt"
                         },
                         {
                           "maTruong": "79778332",
                           "tenTruong": "MN Chú Ong Nhỏ"
                         },
                         {
                           "maTruong": "79778360",
                           "tenTruong": "MN Chú Voi Con"
                         },
                         {
                           "maTruong": "79778W16",
                           "tenTruong": "MN Chuyện Nhỏ"
                         },
                         {
                           "maTruong": "79778351",
                           "tenTruong": "MN Con Mèo Vàng"
                         },
                         {
                           "maTruong": "79778369",
                           "tenTruong": "MN Dâu Tây Đỏ"
                         },
                         {
                           "maTruong": "79778350",
                           "tenTruong": "MN Gấu Trúc"
                         },
                         {
                           "maTruong": "79778315",
                           "tenTruong": "MN Hoa Hồng"
                         },
                         {
                           "maTruong": "79778354",
                           "tenTruong": "MN Hoa Phượng Đỏ"
                         },
                         {
                           "maTruong": "79778338",
                           "tenTruong": "MN Hoa Sen"
                         },
                         {
                           "maTruong": "79778364",
                           "tenTruong": "MN Hoa Sữa"
                         },
                         {
                           "maTruong": "79778260",
                           "tenTruong": "MN Hoa Thủy Tiên"
                         },
                         {
                           "maTruong": "79778W03",
                           "tenTruong": "MN Hoa Ti Gôn"
                         },
                         {
                           "maTruong": "79778325",
                           "tenTruong": "MN Hoàng Anh"
                         },
                         {
                           "maTruong": "79778327",
                           "tenTruong": "MN Hoàng Gia"
                         },
                         {
                           "maTruong": "79778348",
                           "tenTruong": "MN Hồng Ân"
                         },
                         {
                           "maTruong": "79778320",
                           "tenTruong": "MN HỒNG LAN"
                         },
                         {
                           "maTruong": "79778W39",
                           "tenTruong": "MN Khôi Nguyên"
                         },
                         {
                           "maTruong": "79778368",
                           "tenTruong": "MN Khu Chế Xuất Tân Thuận"
                         },
                         {
                           "maTruong": "79778361",
                           "tenTruong": "MN Mai Vàng"
                         },
                         {
                           "maTruong": "79778308",
                           "tenTruong": "MN MaiKa"
                         },
                         {
                           "maTruong": "79778318",
                           "tenTruong": "MN Mặt Trời Bé Con"
                         },
                         {
                           "maTruong": "79778341",
                           "tenTruong": "MN Minh Phú"
                         },
                         {
                           "maTruong": "79778333",
                           "tenTruong": "MN Mỹ Phước"
                         },
                         {
                           "maTruong": "79778W20",
                           "tenTruong": "MN Nam Long"
                         },
                         {
                           "maTruong": "79778324",
                           "tenTruong": "MN Ngôi Nhà Mơ Ước"
                         },
                         {
                           "maTruong": "79778W44",
                           "tenTruong": "MN Ngôi Nhà Trẻ Thơ"
                         },
                         {
                           "maTruong": "79778353",
                           "tenTruong": "MN Ngôi Sao Nhỏ"
                         },
                         {
                           "maTruong": "79778340",
                           "tenTruong": "MN Ngôi Sao Tuổi Thơ"
                         },
                         {
                           "maTruong": "79778321",
                           "tenTruong": "MN Ngôi Sao Việt"
                         },
                         {
                           "maTruong": "79778O06",
                           "tenTruong": "MN Nhật Minh"
                         },
                         {
                           "maTruong": "79778371",
                           "tenTruong": "MN Phú Mỹ"
                         },
                         {
                           "maTruong": "79778316",
                           "tenTruong": "MN Phú Thuận"
                         },
                         {
                           "maTruong": "79778309",
                           "tenTruong": "MN Sao Mai"
                         },
                         {
                           "maTruong": "79778365",
                           "tenTruong": "MN Sen Hồng"
                         },
                         {
                           "maTruong": "79778307",
                           "tenTruong": "MN Sơn Ca"
                         },
                         {
                           "maTruong": "79778304",
                           "tenTruong": "MN Sương Mai"
                         },
                         {
                           "maTruong": "79778314",
                           "tenTruong": "MN Tân Hưng"
                         },
                         {
                           "maTruong": "79778312",
                           "tenTruong": "MN Tân Kiểng"
                         },
                         {
                           "maTruong": "79778303",
                           "tenTruong": "MN Tân Mỹ"
                         },
                         {
                           "maTruong": "79778306",
                           "tenTruong": "MN Tân Phong"
                         },
                         {
                           "maTruong": "79778305",
                           "tenTruong": "MN Tân Quy"
                         },
                         {
                           "maTruong": "79778261",
                           "tenTruong": "MN Thành Phố Tuổi Thơ"
                         },
                         {
                           "maTruong": "79778344",
                           "tenTruong": "MN Thế Giới Tuổi Thơ"
                         },
                         {
                           "maTruong": "79778356",
                           "tenTruong": "MN Thiên Thần Kỷ Nguyên"
                         },
                         {
                           "maTruong": "79778328",
                           "tenTruong": "MN Thiên Thần Nhỏ"
                         },
                         {
                           "maTruong": "79778335",
                           "tenTruong": "MN Tuổi Ngọc"
                         },
                         {
                           "maTruong": "79778370",
                           "tenTruong": "MN Tuổi Thơ Kỳ Diệu"
                         },
                         {
                           "maTruong": "79778322",
                           "tenTruong": "MN Ước Mơ"
                         },
                         {
                           "maTruong": "79778347",
                           "tenTruong": "MN Việt Nam Canada"
                         },
                         {
                           "maTruong": "79778346",
                           "tenTruong": "MN Việt Úc"
                         },
                         {
                           "maTruong": "79778345",
                           "tenTruong": "MN Vùng Đất Trẻ Thơ"
                         },
                         {
                           "maTruong": "79778342",
                           "tenTruong": "Trường mầm non Mèo Con"
                         },
                         {
                           "maTruong": "79778W19",
                           "tenTruong": "Lớp Mẫu Giáo  Hoa Anh Đào"
                         },
                         {
                           "maTruong": "79778W41",
                           "tenTruong": "Lớp Mẫu Giáo Bông Sen"
                         },
                         {
                           "maTruong": "79778W22",
                           "tenTruong": "Lớp Mẫu giáo Chim Non"
                         },
                         {
                           "maTruong": "79778O07",
                           "tenTruong": "Lớp Mẫu Giáo Sóc Nhí"
                         },
                         {
                           "maTruong": "79778L11",
                           "tenTruong": "Lớp Mẫu Giáo Tin Tin"
                         },
                         {
                           "maTruong": "79778W47",
                           "tenTruong": "Lớp MG Cánh Diều"
                         },
                         {
                           "maTruong": "79778O04",
                           "tenTruong": "Lớp MG Chim Sâu"
                         },
                         {
                           "maTruong": "79778W23",
                           "tenTruong": "Lớp MG Dâu Tây"
                         },
                         {
                           "maTruong": "79778W12",
                           "tenTruong": "Lớp MG Diễm Phúc"
                         },
                         {
                           "maTruong": "79778W24",
                           "tenTruong": "Lớp MG Đô Rê Mon"
                         },
                         {
                           "maTruong": "79778W25",
                           "tenTruong": "Lớp MG Gia Bảo"
                         },
                         {
                           "maTruong": "79778W26",
                           "tenTruong": "Lớp MG Hạnh Phúc"
                         },
                         {
                           "maTruong": "79778W31",
                           "tenTruong": "Lớp MG HuGo"
                         },
                         {
                           "maTruong": "79778L08",
                           "tenTruong": "Lớp MG Thế Giới Trẻ Thơ"
                         },
                         {
                           "maTruong": "79778L10",
                           "tenTruong": "Lớp MG Thỏ Hồng"
                         },
                         {
                           "maTruong": "79778W04",
                           "tenTruong": "Lớp MG Thỏ Trắng"
                         },
                         {
                           "maTruong": "79778W38",
                           "tenTruong": "Lớp MG Ti Ti"
                         },
                         {
                           "maTruong": "79778W18",
                           "tenTruong": "Lớp MG Tương Lai"
                         },
                         {
                           "maTruong": "79778L09",
                           "tenTruong": "Lớp MG Vườn Cổ Tích"
                         },
                         {
                           "maTruong": "79778W45",
                           "tenTruong": "Lớp MG Xuân Lan"
                         },
                         {
                           "maTruong": "79778X02",
                           "tenTruong": "MG Chú Vẹt Xanh"
                         },
                         {
                           "maTruong": "79778X03",
                           "tenTruong": "MG Đức Tâm"
                         },
                         {
                           "maTruong": "79778X01",
                           "tenTruong": "MG Mầm Xanh"
                         },
                         {
                           "maTruong": "79778X04",
                           "tenTruong": "MG Phú Xuân"
                         },
                         {
                           "maTruong": "79778262",
                           "tenTruong": "MG Thiên Thần"
                         },
                         {
                           "maTruong": "79778L12",
                           "tenTruong": "Nhóm trẻ Dâu Tằm"
                         },
                         {
                           "maTruong": "79778W28",
                           "tenTruong": "Nhóm trẻ Hoa Mặt Trời"
                         },
                         {
                           "maTruong": "79778W14",
                           "tenTruong": "Nhóm Trẻ Thỏ Ngọc"
                         },
                         {
                           "maTruong": "79778O02",
                           "tenTruong": "NT 1/6"
                         },
                         {
                           "maTruong": "79778O05",
                           "tenTruong": "NT Nhà Của Bé"
                         },
                         {
                           "maTruong": "79778375",
                           "tenTruong": "MN Thế Giới Xanh"
                         },
                         {
                           "maTruong": "79778376",
                           "tenTruong": "MN Vòng Tròn Nụ Cười Hạnh Phúc"
                         },
                         {
                           "maTruong": "79778373",
                           "tenTruong": "MN Học Viện Thiên Thần"
                         },
                         {
                           "maTruong": "79778374",
                           "tenTruong": "MN Ánh Bình Minh"
                         },
                         {
                           "maTruong": "79778L65",
                           "tenTruong": "Lớp MG Cánh Sen Hồng"
                         },
                         {
                           "maTruong": "79778L64",
                           "tenTruong": "Lớp MG Những Đứa Trẻ Vui Vẻ"
                         },
                         {
                           "maTruong": "79778L66",
                           "tenTruong": "Lớp MG Nắng Mai"
                         },
                         {
                           "maTruong": "79778L68",
                           "tenTruong": "Nhóm trẻ Họa Mi"
                         },
                         {
                           "maTruong": "79778L70",
                           "tenTruong": "Lớp MG Phương Thảo"
                         },
                         {
                           "maTruong": "79778L71",
                           "tenTruong": "Lớp MG Mỹ Đức"
                         },
                         {
                           "maTruong": "79778L63",
                           "tenTruong": "Lớp MG Vàng Anh"
                         },
                         {
                           "maTruong": "79778L69",
                           "tenTruong": "Lớp MG Bé Yêu"
                         },
                         {
                           "maTruong": "79778L72",
                           "tenTruong": "Nhóm trẻ Búp Sen Hồng"
                         },
                         {
                           "maTruong": "79778420",
                           "tenTruong": "Tiểu học Phú Thuận"
                         },
                         {
                           "maTruong": "79778377",
                           "tenTruong": "MG EMASI Nam Long"
                         },
                         {
                           "maTruong": "79778378",
                           "tenTruong": "MN Khủng Long Nhỏ"
                         },
                         {
                           "maTruong": "79778379",
                           "tenTruong": "MN TTC Sài Gòn 3"
                         },
                         {
                           "maTruong": "79778380",
                           "tenTruong": "MN Nhật Minh"
                         },
                         {
                           "maTruong": "79778381",
                           "tenTruong": "MN Khai Minh Trí"
                         },
                         {
                           "maTruong": "79778382",
                           "tenTruong": "MN Hành Tinh Xanh"
                         },
                         {
                           "maTruong": "79778X05",
                           "tenTruong": "LMG Ngôi Nhà Hạnh Phúc"
                         },
                         {
                           "maTruong": "79778383",
                           "tenTruong": "MN Hoa Mai"
                         },
                         {
                           "maTruong": "79778W48",
                           "tenTruong": "Lớp MG Cà Chua Đỏ"
                         },
                         {
                           "maTruong": "79778384",
                           "tenTruong": "MN Cỏ Ba Lá"
                         },
                         {
                           "maTruong": "79778385",
                           "tenTruong": "MN Ngôi Nhà Thứ Hai"
                         },
                         {
                           "maTruong": "79778386",
                           "tenTruong": "MN Vườn Nắng"
                         },
                         {
                           "maTruong": "79778W49",
                           "tenTruong": "Lớp MG Mai Việt"
                         },
                         {
                           "maTruong": "79778387",
                           "tenTruong": "MN Quốc tế Khai Sáng"
                         },
                         {
                           "maTruong": "79778104",
                           "tenTruong": "Lớp MG Lá Phong Đỏ"
                         },
                         {
                           "maTruong": "79778176",
                           "tenTruong": "MN Xanh Lá"
                         },
                         {
                           "maTruong": "79778239",
                           "tenTruong": "LMG Ánh Mai"
                         },
                         {
                           "maTruong": "79778049",
                           "tenTruong": "MN Ngôi Sao Hạnh Phúc"
                         },
                         {
                           "maTruong": "79778455",
                           "tenTruong": "TH Sao Việt"
                         },
                         {
                           "maTruong": "79778419",
                           "tenTruong": "TH Phạm Hữu Lầu"
                         },
                         {
                           "maTruong": "7977801006",
                           "tenTruong": "Nhóm trẻ Liên Việt"
                         },
                         {
                           "maTruong": "7977801008",
                           "tenTruong": "Lớp MN Gà Con"
                         },
                         {
                           "maTruong": "7977801009",
                           "tenTruong": "Lớp MN Vườn Ươm Tương Lai"
                         },
                         {
                           "maTruong": "7977801010",
                           "tenTruong": "Lớp MN Anh Nhi Hạnh"
                         },
                         {
                           "maTruong": "7977801001",
                           "tenTruong": "Lớp MG Bé Ong Sài Gòn"
                         },
                         {
                           "maTruong": "7977801002",
                           "tenTruong": "Lớp MG Thiên Phước"
                         },
                         {
                           "maTruong": "7977801005",
                           "tenTruong": "Nhóm trẻ Làng Nắng"
                         },
                         {
                           "maTruong": "7977801007",
                           "tenTruong": "Lớp MN Thế giới Trẻ Thơ Bảo Nguyên"
                         },
                         {
                           "maTruong": "7977801020",
                           "tenTruong": "MN Nhóc Trùm"
                         },
                         {
                           "maTruong": "7977801018",
                           "tenTruong": "MN OZORA Nhật Bản"
                         },
                         {
                           "maTruong": "7977801019",
                           "tenTruong": "MN Tân Phong 2"
                         },
                         {
                           "maTruong": "7977801015",
                           "tenTruong": "Lớp MN 123"
                         },
                         {
                           "maTruong": "7977801013",
                           "tenTruong": "Lớp MN Ngôi Nhà Của Bé"
                         },
                         {
                           "maTruong": "7977801012",
                           "tenTruong": "Lớp MN Biển Xanh"
                         },
                         {
                           "maTruong": "7977801021",
                           "tenTruong": "Lớp Mầm non Voi con"
                         },
                         {
                           "maTruong": "7977801016",
                           "tenTruong": "Lớp MG Minh Anh"
                         },
                         {
                           "maTruong": "7977801003",
                           "tenTruong": "Lớp MG Minh Phúc"
                         },
                         {
                           "maTruong": "7977801014",
                           "tenTruong": "Lớp MN Nhà Của Bé"
                         },
                         {
                           "maTruong": "7977801025",
                           "tenTruong": "LMG Ánh Dương"
                         },
                         {
                           "maTruong": "7977801026",
                           "tenTruong": "LMG Khu Vườn Cổ Tích"
                         },
                         {
                           "maTruong": "7977801022",
                           "tenTruong": "LMG Ngôi Nhà Cầu Vồng"
                         },
                         {
                           "maTruong": "7977801023",
                           "tenTruong": "LMG Tuổi Hồng"
                         },
                         {
                           "maTruong": "7977801035",
                           "tenTruong": "Lớp MN Rồng Con 1"
                         },
                         {
                           "maTruong": "7977801030",
                           "tenTruong": "Lớp MG Thỏ Con"
                         },
                         {
                           "maTruong": "7977801031",
                           "tenTruong": "Lớp MN Lớp Học Đầu Tiên"
                         },
                         {
                           "maTruong": "7977801032",
                           "tenTruong": "Lớp MN Cầu Vồng 3"
                         },
                         {
                           "maTruong": "7977801033",
                           "tenTruong": "Lớp MN Việt Âu"
                         },
                         {
                           "maTruong": "7977801034",
                           "tenTruong": "Lớp MN Nam Việt"
                         },
                         {
                           "maTruong": "7977801028",
                           "tenTruong": "Lớp MN Ong Nhỏ"
                         },
                         {
                           "maTruong": "7977802001",
                           "tenTruong": "TH Việt Nhật"
                         },
                         {
                           "maTruong": "7977801027",
                           "tenTruong": "MN Việt Nhật"
                         },
                         {
                           "maTruong": "7977801036",
                           "tenTruong": "Lớp MN Trẻ Sáng Tạo"
                         },
                         {
                           "maTruong": "7977801037",
                           "tenTruong": "Lớp MN An Phước"
                         },
                         {
                           "maTruong": "7977801038",
                           "tenTruong": "Lớp MG Tuổi Thần Tiên"
                         },
                         {
                           "maTruong": "7977801039",
                           "tenTruong": "Lớp MN Thiên Nhiên"
                         },
                         {
                           "maTruong": "7977801042",
                           "tenTruong": "Lớp MN Những Đứa Trẻ Vui Vẻ"
                         },
                         {
                           "maTruong": "7977801043",
                           "tenTruong": "Lớp MN Xanh"
                         },
                         {
                           "maTruong": "7977801044",
                           "tenTruong": "Lớp MN Thế Giới Mới"
                         },
                         {
                           "maTruong": "7977801045",
                           "tenTruong": "LMN Ngôi Nhà Cầu Vồng"
                         },
                         {
                           "maTruong": "7977801046",
                           "tenTruong": "LMN Phong Phú"
                         },
                         {
                           "maTruong": "7977801047",
                           "tenTruong": "LMN Cánh Diều Tuổi Thơ"
                         },
                         {
                           "maTruong": "7977801048",
                           "tenTruong": "Lớp MG Chú Kiến Nhỏ"
                         },
                         {
                           "maTruong": "7977801049",
                           "tenTruong": "Lớp MN Trẻ Sáng Tạo 2"
                         },
                         {
                           "maTruong": "7977801050",
                           "tenTruong": "Lớp MN Ngôi Sao Xanh"
                         },
                         {
                           "maTruong": "7977801051",
                           "tenTruong": "Nhà Của Con"
                         },
                         {
                           "maTruong": "7977801052",
                           "tenTruong": "Lớp MN Hát Ca"
                         },
                         {
                           "maTruong": "7977801053",
                           "tenTruong": "Lớp MN Hạt Đậu Nhỏ"
                         },
                         {
                           "maTruong": "7977801054",
                           "tenTruong": "Lớp MN Đồng Dao"
                         }
                       ]
                     },
                     {
                       "maPhong": null,
                       "tenPhong": "Huyện Cần Giờ",
                       "truongData": [
                         {
                           "maTruong": "79000786",
                           "tenTruong": "THPT An Nghĩa"
                         },
                         {
                           "maTruong": "79000762",
                           "tenTruong": "THPT Bình Khánh"
                         },
                         {
                           "maTruong": "79000763",
                           "tenTruong": "THPT Cần Thạnh"
                         },
                         {
                           "maTruong": "79787901",
                           "tenTruong": "TT GDTX H Cần Giờ"
                         },
                         {
                           "maTruong": "79000876",
                           "tenTruong": "THCS và THPT Thạnh An"
                         },
                         {
                           "maTruong": "7900004005",
                           "tenTruong": "Trường Chuyên biệt Cần Thạnh (Cần Giờ)"
                         }
                       ]
                     },
                     {
                       "maPhong": null,
                       "tenPhong": "Quận Phú Nhuận",
                       "truongData": [
                         {
                           "maTruong": "79000766",
                           "tenTruong": "THPT Quốc tế Việt Úc"
                         },
                         {
                           "maTruong": "79000749",
                           "tenTruong": "THPT Hàn Thuyên"
                         },
                         {
                           "maTruong": "79000726",
                           "tenTruong": "THPT Phú Nhuận"
                         },
                         {
                           "maTruong": "79768901",
                           "tenTruong": "TT GDNN - GDTX Q Phú Nhuận"
                         },
                         {
                           "maTruong": "79000773",
                           "tenTruong": "THPT Hưng Đạo"
                         },
                         {
                           "maTruong": "79000872",
                           "tenTruong": "THCS và THPT Việt Anh"
                         },
                         {
                           "maTruong": "79000828",
                           "tenTruong": "Tiểu học, THCS và THPT Quốc Tế"
                         },
                         {
                           "maTruong": "79000810",
                           "tenTruong": "THCS và THPT Hồng Hà"
                         },
                         {
                           "maTruong": "7900004021",
                           "tenTruong": "Trường Giáo dục Chuyên biệt Niềm Tin (Phú Nhuận)"
                         },
                         {
                           "maTruong": "7900004001",
                           "tenTruong": "THPT Quốc tế Việt Úc "
                         }
                       ]
                     },
                     {
                       "maPhong": null,
                       "tenPhong": "Quận Tân Bình",
                       "truongData": [
                         {
                           "maTruong": "790007B4",
                           "tenTruong": "THPT Hai Bà Trưng"
                         },
                         {
                           "maTruong": "79000728",
                           "tenTruong": "THPT Nguyễn Chí Thanh"
                         },
                         {
                           "maTruong": "79000747",
                           "tenTruong": "THPT Nguyễn Thái Bình"
                         },
                         {
                           "maTruong": "79000730",
                           "tenTruong": "THPT Nguyễn Thượng Hiền"
                         },
                         {
                           "maTruong": "790007B5",
                           "tenTruong": "THPT Thủ Khoa Huân"
                         },
                         {
                           "maTruong": "79000798",
                           "tenTruong": "THPT Vĩnh Viễn"
                         },
                         {
                           "maTruong": "79766901",
                           "tenTruong": "TRUNG TÂM  GDNN - GDTX QUẬN TÂN BÌNH"
                         },
                         {
                           "maTruong": "79000831",
                           "tenTruong": "THCS và THPT Thái Bình"
                         },
                         {
                           "maTruong": "79000835",
                           "tenTruong": "THCS và THPT Nguyễn Khuyến"
                         },
                         {
                           "maTruong": "79000829",
                           "tenTruong": "THCS và THPT  Việt Thanh"
                         },
                         {
                           "maTruong": "79000C07",
                           "tenTruong": "Tiểu học, THCS và THPT Thái Bình Dương"
                         },
                         {
                           "maTruong": "79000832",
                           "tenTruong": "TH - THCS - THPT Thanh Bình"
                         },
                         {
                           "maTruong": "79000859",
                           "tenTruong": "THCS - THPT Bác Ái"
                         },
                         {
                           "maTruong": "79000848",
                           "tenTruong": "Tiểu học, THCS và THPT Việt Mỹ"
                         },
                         {
                           "maTruong": "7900004022",
                           "tenTruong": "TT HTPT GDHN quận Tân Bình (Tân Bình)"
                         }
                       ]
                     },
                     {
                       "maPhong": null,
                       "tenPhong": "Thành Phố Thủ Đức",
                       "truongData": [
                         {
                           "maTruong": "79000707",
                           "tenTruong": "Phổ thông Năng khiếu thể thao Olympic"
                         },
                         {
                           "maTruong": "79000715",
                           "tenTruong": "THPT Nguyễn Huệ"
                         },
                         {
                           "maTruong": "79000703",
                           "tenTruong": "THPT Giồng Ông Tố"
                         },
                         {
                           "maTruong": "79000759",
                           "tenTruong": "THPT Long Trường"
                         },
                         {
                           "maTruong": "79000752",
                           "tenTruong": "THPT Phước Long"
                         },
                         {
                           "maTruong": "79000731",
                           "tenTruong": "THPT Thủ Đức"
                         },
                         {
                           "maTruong": "79000732",
                           "tenTruong": "THPT Nguyễn Hữu Huân"
                         },
                         {
                           "maTruong": "79000733",
                           "tenTruong": "THPT Tam Phú"
                         },
                         {
                           "maTruong": "790007A1",
                           "tenTruong": "THPT Bách Việt"
                         },
                         {
                           "maTruong": "79000765",
                           "tenTruong": "THPT Hiệp Bình"
                         },
                         {
                           "maTruong": "79000780",
                           "tenTruong": "THPT An Dương Vương"
                         },
                         {
                           "maTruong": "79000813",
                           "tenTruong": "Tiểu học, THCS và THPT Ngô Thời Nhiệm"
                         },
                         {
                           "maTruong": "79000802",
                           "tenTruong": "THPT Thủ Thiêm"
                         },
                         {
                           "maTruong": "790007A9",
                           "tenTruong": "THPT Nguyễn Văn Tăng"
                         },
                         {
                           "maTruong": "790007B2",
                           "tenTruong": "THPT Đào Sơn Tây"
                         },
                         {
                           "maTruong": "790007C3",
                           "tenTruong": "THPT Linh Trung"
                         },
                         {
                           "maTruong": "790007C4",
                           "tenTruong": "THPT Dương Văn Thì"
                         },
                         {
                           "maTruong": "790007C5",
                           "tenTruong": "THPT Bình Chiểu"
                         },
                         {
                           "maTruong": "79762901",
                           "tenTruong": "TT GDNN - GDTX Quận Thủ Đức"
                         },
                         {
                           "maTruong": "79763901",
                           "tenTruong": "TT GDNN-GDTX Quận 9"
                         },
                         {
                           "maTruong": "79000C04",
                           "tenTruong": "Song ngữ Quốc tế Horizon"
                         },
                         {
                           "maTruong": "79000914",
                           "tenTruong": "TT GDTX Gia Định"
                         },
                         {
                           "maTruong": "79000873",
                           "tenTruong": "THCS và THPT Hoa Sen"
                         },
                         {
                           "maTruong": "79000C13",
                           "tenTruong": "Tiểu học, THCS và THPT Emasi Vạn Phúc"
                         },
                         {
                           "maTruong": "79769901",
                           "tenTruong": "TT GDNN-GDTX Quận 2"
                         },
                         {
                           "maTruong": "7900004024",
                           "tenTruong": "Trường Giáo dục Chuyên biệt Thảo Điền (Thủ Đức)"
                         },
                         {
                           "maTruong": "79000835",
                           "tenTruong": "THCS - THPT Nguyễn Khuyến"
                         },
                         {
                           "maTruong": "7900001008",
                           "tenTruong": "Mầm non Quốc tế E-Maison"
                         },
                         {
                           "maTruong": "7900001010",
                           "tenTruong": "Trường Quốc Tế Dạy Bằng Tiếng Anh"
                         },
                         {
                           "maTruong": "7900001015",
                           "tenTruong": "Trường Quốc Tế Châu Âu"
                         },
                         {
                           "maTruong": "79000D14",
                           "tenTruong": "Trường Quốc Tế Dạy Bằng Tiếng Anh"
                         },
                         {
                           "maTruong": "79000Q11",
                           "tenTruong": "Trường Quốc Tế Châu Âu "
                         },
                         {
                           "maTruong": "79000U10",
                           "tenTruong": "Trường Quốc Tế Úc"
                         },
                         {
                           "maTruong": "qitech",
                           "tenTruong": "MN-TH-THCS-THPT Quảng Ích"
                         },
                         {
                           "maTruong": "79769353",
                           "tenTruong": "MN Trẻ em quốc tế Smartkids"
                         }
                       ]
                     },
                     {
                       "maPhong": null,
                       "tenPhong": "Quận Gò Vấp",
                       "truongData": [
                         {
                           "maTruong": "79000789",
                           "tenTruong": "THPT Đào Duy Từ"
                         },
                         {
                           "maTruong": "79000783",
                           "tenTruong": "THPT Đông Dương"
                         },
                         {
                           "maTruong": "79000724",
                           "tenTruong": "THPT Gò Vấp"
                         },
                         {
                           "maTruong": "79000777",
                           "tenTruong": "THPT Lý Thái Tổ"
                         },
                         {
                           "maTruong": "79000725",
                           "tenTruong": "THPT Nguyễn Công Trứ"
                         },
                         {
                           "maTruong": "79000748",
                           "tenTruong": "THPT Nguyễn Trung Trực"
                         },
                         {
                           "maTruong": "79000764",
                           "tenTruong": "THPT Trần Hưng Đạo"
                         },
                         {
                           "maTruong": "79000772",
                           "tenTruong": "THPT Việt Nhật"
                         },
                         {
                           "maTruong": "79000912",
                           "tenTruong": "BTVH ĐH Công Nghiệp"
                         },
                         {
                           "maTruong": "79764901",
                           "tenTruong": "TT GDTX Q Gò Vấp"
                         },
                         {
                           "maTruong": "79000915",
                           "tenTruong": "Khoa Khoa học cơ bản trường Đại học Công nghiệp"
                         },
                         {
                           "maTruong": "79000C09",
                           "tenTruong": "Tiểu học, THCS và THPT Nguyễn Tri Phương"
                         },
                         {
                           "maTruong": "79000810",
                           "tenTruong": "THCS và THPT Hồng Hà"
                         },
                         {
                           "maTruong": "79000825",
                           "tenTruong": "THCS và THPT Phạm Ngũ Lão"
                         },
                         {
                           "maTruong": "79000852",
                           "tenTruong": "TH, THCS và THPT Nam Mỹ"
                         },
                         {
                           "maTruong": "79000C01",
                           "tenTruong": "PTDL Hermann Gmeiner"
                         },
                         {
                           "maTruong": "7900004020",
                           "tenTruong": "Trường Giáo dục Chuyên biệt Hy Vọng (Gò Vấp)"
                         },
                         {
                           "maTruong": "7900004003",
                           "tenTruong": "Tiểu học, THCS và THPT Sài Gòn Gia Định"
                         }
                       ]
                     },
                     {
                       "maPhong": null,
                       "tenPhong": "Thành phố Thủ Đức",
                       "truongData": [
                         {
                           "maTruong": "7900001008",
                           "tenTruong": "Mầm non Quốc tế E-Maison"
                         },
                         {
                           "maTruong": "7900001010",
                           "tenTruong": "Trường Quốc Tế Dạy Bằng Tiếng Anh"
                         },
                         {
                           "maTruong": "7900001015",
                           "tenTruong": "Trường Quốc Tế Châu Âu"
                         },
                         {
                           "maTruong": "79000715",
                           "tenTruong": "THPT Nguyễn Huệ"
                         },
                         {
                           "maTruong": "79000731",
                           "tenTruong": "THPT Thủ Đức"
                         },
                         {
                           "maTruong": "79000732",
                           "tenTruong": "THPT Nguyễn Hữu Huân"
                         },
                         {
                           "maTruong": "79000733",
                           "tenTruong": "THPT Tam Phú"
                         },
                         {
                           "maTruong": "79000707",
                           "tenTruong": "Phổ thông Năng khiếu thể thao Olympic"
                         },
                         {
                           "maTruong": "79000703",
                           "tenTruong": "THPT Giồng Ông Tố"
                         },
                         {
                           "maTruong": "7900004024",
                           "tenTruong": "Trường Giáo dục Chuyên biệt Thảo Điền (Thủ Đức)"
                         },
                         {
                           "maTruong": "79000765",
                           "tenTruong": "THPT Hiệp Bình"
                         },
                         {
                           "maTruong": "79000752",
                           "tenTruong": "THPT Phước Long"
                         },
                         {
                           "maTruong": "79000759",
                           "tenTruong": "THPT Long Trường"
                         },
                         {
                           "maTruong": "79000780",
                           "tenTruong": "THPT An Dương Vương"
                         },
                         {
                           "maTruong": "790007A1",
                           "tenTruong": "THPT Bách Việt"
                         },
                         {
                           "maTruong": "790007A9",
                           "tenTruong": "THPT Nguyễn Văn Tăng"
                         },
                         {
                           "maTruong": "790007B2",
                           "tenTruong": "THPT Đào Sơn Tây"
                         },
                         {
                           "maTruong": "790007C3",
                           "tenTruong": "THPT Linh Trung"
                         },
                         {
                           "maTruong": "790007C4",
                           "tenTruong": "THPT Dương Văn Thì"
                         },
                         {
                           "maTruong": "790007C5",
                           "tenTruong": "THPT Bình Chiểu"
                         },
                         {
                           "maTruong": "79000813",
                           "tenTruong": "TH, THCS và THPT Ngô Thời Nhiệm"
                         },
                         {
                           "maTruong": "79000802",
                           "tenTruong": "THPT Thủ Thiêm"
                         },
                         {
                           "maTruong": "79000835",
                           "tenTruong": "THCS và THPT Nguyễn Khuyến"
                         },
                         {
                           "maTruong": "79000914",
                           "tenTruong": "TT GDTX Gia Định"
                         },
                         {
                           "maTruong": "79000D14",
                           "tenTruong": "Trường Quốc Tế Dạy Bằng Tiếng Anh"
                         },
                         {
                           "maTruong": "79000T10",
                           "tenTruong": "Trường Quốc Tế Thành Phố Hồ Chí Minh"
                         },
                         {
                           "maTruong": "79000U10",
                           "tenTruong": "Trường Quốc Tế Úc"
                         },
                         {
                           "maTruong": "79762901",
                           "tenTruong": "TT GDNN-GDTX Thành phố Thủ Đức"
                         },
                         {
                           "maTruong": "79769353",
                           "tenTruong": "MN Trẻ em quốc tế Smartkids"
                         }
                       ]
                     },
                     {
                       "maPhong": null,
                       "tenPhong": "Quận Bình Thạnh",
                       "truongData": [
                         {
                           "maTruong": "79000827",
                           "tenTruong": "THPT Đông Đô"
                         },
                         {
                           "maTruong": "79000722",
                           "tenTruong": "THPT Gia Định"
                         },
                         {
                           "maTruong": "79000750",
                           "tenTruong": "THPT Hoàng Hoa Thám"
                         },
                         {
                           "maTruong": "79000773",
                           "tenTruong": "THPT Hưng Đạo"
                         },
                         {
                           "maTruong": "79000723",
                           "tenTruong": "THPT Phan Đăng Lưu"
                         },
                         {
                           "maTruong": "79000720",
                           "tenTruong": "THPT Thanh Đa"
                         },
                         {
                           "maTruong": "790007B0",
                           "tenTruong": "THPT Trần Văn Giàu"
                         },
                         {
                           "maTruong": "79000721",
                           "tenTruong": "THPT Võ Thị Sáu"
                         },
                         {
                           "maTruong": "79765901",
                           "tenTruong": "TT GDTX Q Bình Thạnh"
                         },
                         {
                           "maTruong": "79000905",
                           "tenTruong": "TT GDTX Tôn Đức Thắng"
                         },
                         {
                           "maTruong": "790007B9",
                           "tenTruong": "Tiểu học, THCS và THPT Mùa Xuân"
                         },
                         {
                           "maTruong": "79000874",
                           "tenTruong": "Tiểu học, THCS và THPT Vinschool"
                         },
                         {
                           "maTruong": "79000C10",
                           "tenTruong": "Tiểu học, THCS và THPT Anh Quốc"
                         },
                         {
                           "maTruong": "79000893",
                           "tenTruong": "THPT Sài Gòn"
                         },
                         {
                           "maTruong": "7900004026",
                           "tenTruong": "Trường Giáo dục Chuyên biệt Anh Minh (Bình Thạnh)"
                         },
                         {
                           "maTruong": "7900004027",
                           "tenTruong": "Trường Giáo dục Chuyên biệt Khai Trí (Bình Thạnh)"
                         },
                         {
                           "maTruong": "7900004028",
                           "tenTruong": "Trường Giáo dục Chuyên biệt Gia Định (Bình Thạnh)"
                         },
                         {
                           "maTruong": "7900004029",
                           "tenTruong": "Trường Chuyên biệt Khiếm Thính Hy Vọng (Bình Thạnh)"
                         },
                         {
                           "maTruong": "7900004030",
                           "tenTruong": "TT HTPT GDHN Nhân Văn (Bình Thạnh)"
                         },
                         {
                           "maTruong": "7900004031",
                           "tenTruong": "TT HTPT GDHN Hoa Cúc Trắng (Bình Thạnh)"
                         },
                         {
                           "maTruong": "7900002002",
                           "tenTruong": "Tiểu học Dãy Núi Xanh - Bình Thạnh"
                         },
                         {
                           "maTruong": "7900001001",
                           "tenTruong": "Mầm non Dãy Núi Xanh - Bình Thạnh"
                         },
                         {
                           "maTruong": "7900002001",
                           "tenTruong": "Lớp Giáo dục chuyên biệt Tuổi Ngọc (Bình Thạnh)"
                         },
                         {
                           "maTruong": "79222814",
                           "tenTruong": "TH, THCS, THPT Quốc tế Sài Gòn Pearl"
                         }
                       ]
                     },
                     {
                       "maPhong": "787",
                       "tenPhong": "Huyện Cần Giờ",
                       "truongData": [
                         {
                           "maTruong": "79787401",
                           "tenTruong": "TH An Nghĩa"
                         },
                         {
                           "maTruong": "79787402",
                           "tenTruong": "TH An Thới Đông"
                         },
                         {
                           "maTruong": "79787404",
                           "tenTruong": "TH Bình Khánh"
                         },
                         {
                           "maTruong": "79787405",
                           "tenTruong": "TH Bình Mỹ"
                         },
                         {
                           "maTruong": "79787406",
                           "tenTruong": "TH Bình Phước"
                         },
                         {
                           "maTruong": "79787407",
                           "tenTruong": "TH Bình Thạnh"
                         },
                         {
                           "maTruong": "79787408",
                           "tenTruong": "TH Cần Thạnh"
                         },
                         {
                           "maTruong": "79787403",
                           "tenTruong": "TH Doi Lầu"
                         },
                         {
                           "maTruong": "79787409",
                           "tenTruong": "TH Đồng Hòa"
                         },
                         {
                           "maTruong": "79787410",
                           "tenTruong": "TH Hòa Hiệp"
                         },
                         {
                           "maTruong": "79787411",
                           "tenTruong": "TH Long Thạnh"
                         },
                         {
                           "maTruong": "79787412",
                           "tenTruong": "TH Lý Nhơn"
                         },
                         {
                           "maTruong": "79787413",
                           "tenTruong": "TH Tam Thôn Hiệp"
                         },
                         {
                           "maTruong": "79787414",
                           "tenTruong": "TH Thạnh An"
                         },
                         {
                           "maTruong": "79787415",
                           "tenTruong": "TH Vàm Sát"
                         },
                         {
                           "maTruong": "79787501",
                           "tenTruong": "THCS An Thới Đông"
                         },
                         {
                           "maTruong": "79787506",
                           "tenTruong": "THCS Bình Khánh"
                         },
                         {
                           "maTruong": "79787507",
                           "tenTruong": "THCS Cần Thạnh"
                         },
                         {
                           "maTruong": "79787508",
                           "tenTruong": "THCS Doi Lầu"
                         },
                         {
                           "maTruong": "79787502",
                           "tenTruong": "THCS Long Hòa"
                         },
                         {
                           "maTruong": "79787503",
                           "tenTruong": "THCS Lý Nhơn"
                         },
                         {
                           "maTruong": "79787504",
                           "tenTruong": "THCS Tam Thôn Hiệp"
                         },
                         {
                           "maTruong": "79787505",
                           "tenTruong": "THCS Thạnh An"
                         },
                         {
                           "maTruong": "79787201",
                           "tenTruong": "MN An Thới Đông"
                         },
                         {
                           "maTruong": "79787303",
                           "tenTruong": "Mn Bình An"
                         },
                         {
                           "maTruong": "79787302",
                           "tenTruong": "MN Bình Khánh"
                         },
                         {
                           "maTruong": "79787305",
                           "tenTruong": "MN Bình Minh"
                         },
                         {
                           "maTruong": "79787301",
                           "tenTruong": "MN Cần Thạnh"
                         },
                         {
                           "maTruong": "79787203",
                           "tenTruong": "MN Cần Thạnh 2"
                         },
                         {
                           "maTruong": "79787304",
                           "tenTruong": "MN Doi Lầu"
                         },
                         {
                           "maTruong": "79787306",
                           "tenTruong": "MN Đồng Tranh"
                         },
                         {
                           "maTruong": "79787204",
                           "tenTruong": "MN Long Hòa"
                         },
                         {
                           "maTruong": "79787205",
                           "tenTruong": "MN Lý Nhơn"
                         },
                         {
                           "maTruong": "79787206",
                           "tenTruong": "MN Tam Thôn Hiệp"
                         },
                         {
                           "maTruong": "79787207",
                           "tenTruong": "MN Thạnh An"
                         },
                         {
                           "maTruong": "79787416",
                           "tenTruong": "TH Cần Thạnh 2"
                         }
                       ]
                     },
                     {
                       "maPhong": "772",
                       "tenPhong": "Quận 11",
                       "truongData": [
                         {
                           "maTruong": "79772406",
                           "tenTruong": "TH Âu Cơ"
                         },
                         {
                           "maTruong": "79772421",
                           "tenTruong": "TH Bình Thới"
                         },
                         {
                           "maTruong": "79772415",
                           "tenTruong": "TH Đại Thành"
                         },
                         {
                           "maTruong": "79772407",
                           "tenTruong": "TH Đề THám"
                         },
                         {
                           "maTruong": "79772409",
                           "tenTruong": "TH Hàn Hải Nguyên"
                         },
                         {
                           "maTruong": "79772404",
                           "tenTruong": "TH Hòa Bình"
                         },
                         {
                           "maTruong": "79772401",
                           "tenTruong": "TH Hưng Việt"
                         },
                         {
                           "maTruong": "79772408",
                           "tenTruong": "TH Lạc Long Quân"
                         },
                         {
                           "maTruong": "79772417",
                           "tenTruong": "TH Lê Đình Chinh"
                         },
                         {
                           "maTruong": "79772410",
                           "tenTruong": "TH Nguyễn Bá Ngọc"
                         },
                         {
                           "maTruong": "79772402",
                           "tenTruong": "TH Nguyễn Thi"
                         },
                         {
                           "maTruong": "79772423",
                           "tenTruong": "TH Nguyễn Thị Nhỏ"
                         },
                         {
                           "maTruong": "79772403",
                           "tenTruong": "TH Phạm Văn Hai"
                         },
                         {
                           "maTruong": "79772414",
                           "tenTruong": "TH Phú Thọ"
                         },
                         {
                           "maTruong": "79772412",
                           "tenTruong": "TH Phùng Hưng"
                         },
                         {
                           "maTruong": "79772413",
                           "tenTruong": "TH Quyết Thắng"
                         },
                         {
                           "maTruong": "79772411",
                           "tenTruong": "TH Thái Phiên"
                         },
                         {
                           "maTruong": "79772405",
                           "tenTruong": "TH Trần Văn Ơn"
                         },
                         {
                           "maTruong": "79772416",
                           "tenTruong": "TH Trưng Trắc"
                         },
                         {
                           "maTruong": "79772422",
                           "tenTruong": "TH Việt Mỹ"
                         },
                         {
                           "maTruong": "79772420",
                           "tenTruong": "TH Việt Mỹ Úc"
                         },
                         {
                           "maTruong": "79772505",
                           "tenTruong": "THCS Chu Văn An"
                         },
                         {
                           "maTruong": "79772509",
                           "tenTruong": "THCS Hậu Giang"
                         },
                         {
                           "maTruong": "79772506",
                           "tenTruong": "THCS Lê Anh Xuân"
                         },
                         {
                           "maTruong": "79772502",
                           "tenTruong": "THCS Lê Quý Đôn"
                         },
                         {
                           "maTruong": "79772508",
                           "tenTruong": "THCS Lữ Gia"
                         },
                         {
                           "maTruong": "79772503",
                           "tenTruong": "THCS Nguyễn Huệ"
                         },
                         {
                           "maTruong": "79772504",
                           "tenTruong": "THCS Nguyễn Minh Hoàng"
                         },
                         {
                           "maTruong": "79772501",
                           "tenTruong": "THCS Nguyễn Văn Phú"
                         },
                         {
                           "maTruong": "79772507",
                           "tenTruong": "THCS Phú Thọ"
                         },
                         {
                           "maTruong": "79772511",
                           "tenTruong": "THCS Việt Mỹ"
                         },
                         {
                           "maTruong": "79000836",
                           "tenTruong": "TH - THCS - THPT Trương Vĩnh Ký"
                         },
                         {
                           "maTruong": "79772308",
                           "tenTruong": "MẦM NON 10"
                         },
                         {
                           "maTruong": "79772305",
                           "tenTruong": "Mầm Non Phường 6"
                         },
                         {
                           "maTruong": "79772344",
                           "tenTruong": "Mầm Non Việt Anh"
                         },
                         {
                           "maTruong": "79772325",
                           "tenTruong": "MN Ánh Dương"
                         },
                         {
                           "maTruong": "79772336",
                           "tenTruong": "MN BAMBI HỒNG"
                         },
                         {
                           "maTruong": "7977MN35",
                           "tenTruong": "MN Bé Gấu"
                         },
                         {
                           "maTruong": "79772321",
                           "tenTruong": "MN Hoa Hướng Dương"
                         },
                         {
                           "maTruong": "7977MN36",
                           "tenTruong": "MN Hoa Mặt Trời"
                         },
                         {
                           "maTruong": "79772318",
                           "tenTruong": "MN Họa Mi"
                         },
                         {
                           "maTruong": "7977MN37",
                           "tenTruong": "MN Học Viện Hoa Kỳ"
                         },
                         {
                           "maTruong": "79772339",
                           "tenTruong": "MN Hương Sen"
                         },
                         {
                           "maTruong": "79772329",
                           "tenTruong": "MN Khai Minh"
                         },
                         {
                           "maTruong": "79772319",
                           "tenTruong": "MN Lữ Gia"
                         },
                         {
                           "maTruong": "79772320",
                           "tenTruong": "MN Minh Anh"
                         },
                         {
                           "maTruong": "79772317",
                           "tenTruong": "MN Phú Bình"
                         },
                         {
                           "maTruong": "79772301",
                           "tenTruong": "MN Phường 1"
                         },
                         {
                           "maTruong": "79772203",
                           "tenTruong": "MN Phường 11"
                         },
                         {
                           "maTruong": "79772204",
                           "tenTruong": "MN Phường 12"
                         },
                         {
                           "maTruong": "79772309",
                           "tenTruong": "MN Phường 13"
                         },
                         {
                           "maTruong": "79772310",
                           "tenTruong": "MN Phường 14"
                         },
                         {
                           "maTruong": "79772311",
                           "tenTruong": "MN Phường 15"
                         },
                         {
                           "maTruong": "79772312",
                           "tenTruong": "MN Phường 16"
                         },
                         {
                           "maTruong": "79772302",
                           "tenTruong": "MN Phường 2"
                         },
                         {
                           "maTruong": "79772201",
                           "tenTruong": "MN Phường 3"
                         },
                         {
                           "maTruong": "79772303",
                           "tenTruong": "MN Phường 4"
                         },
                         {
                           "maTruong": "79772304",
                           "tenTruong": "MN Phường 5"
                         },
                         {
                           "maTruong": "79772202",
                           "tenTruong": "MN Phường 7"
                         },
                         {
                           "maTruong": "79772306",
                           "tenTruong": "MN Phường 8"
                         },
                         {
                           "maTruong": "79772307",
                           "tenTruong": "MN Phường 9"
                         },
                         {
                           "maTruong": "79772313",
                           "tenTruong": "MN Quận 11"
                         },
                         {
                           "maTruong": "79772322",
                           "tenTruong": "MN Quốc tế Mỹ Úc"
                         },
                         {
                           "maTruong": "79772343",
                           "tenTruong": "MN Táo Đỏ"
                         },
                         {
                           "maTruong": "79772330",
                           "tenTruong": "MN Táo Hồng"
                         },
                         {
                           "maTruong": "79772340",
                           "tenTruong": "MN Táo Vàng"
                         },
                         {
                           "maTruong": "79772315",
                           "tenTruong": "MN Tuổi Thơ 369"
                         },
                         {
                           "maTruong": "79772354",
                           "tenTruong": "MN Việt Đức"
                         },
                         {
                           "maTruong": "79772341",
                           "tenTruong": "MN Việt Mỹ"
                         },
                         {
                           "maTruong": "79772W13",
                           "tenTruong": "LMG Gia Bảo"
                         },
                         {
                           "maTruong": "79772337",
                           "tenTruong": "LNT HOA SEN"
                         },
                         {
                           "maTruong": "79772327",
                           "tenTruong": "Lớp MN Vàng Anh"
                         },
                         {
                           "maTruong": "79772328",
                           "tenTruong": "Mẫu Giáo  Mai Anh"
                         },
                         {
                           "maTruong": "79772L29",
                           "tenTruong": "MG Thiên Thần Bé Thơ"
                         },
                         {
                           "maTruong": "79772W05",
                           "tenTruong": "MN ABC"
                         },
                         {
                           "maTruong": "79772W01",
                           "tenTruong": "MN Bam Bi"
                         },
                         {
                           "maTruong": "79772W09",
                           "tenTruong": "MN Bam Bo"
                         },
                         {
                           "maTruong": "79772314",
                           "tenTruong": "MN Bình Thới"
                         },
                         {
                           "maTruong": "79772331",
                           "tenTruong": "MN Đô rê mi"
                         },
                         {
                           "maTruong": "79772326",
                           "tenTruong": "MN Hồng Nhung"
                         },
                         {
                           "maTruong": "79772W04",
                           "tenTruong": "MN Ngôi Sao Nhỏ"
                         },
                         {
                           "maTruong": "79772335",
                           "tenTruong": "NT Táo Xanh"
                         },
                         {
                           "maTruong": "79772332",
                           "tenTruong": "NT Thủy Trúc"
                         },
                         {
                           "maTruong": "79772W11",
                           "tenTruong": "NT Tí Hon"
                         },
                         {
                           "maTruong": "79772333",
                           "tenTruong": "NT Văn Thành Kiệt"
                         },
                         {
                           "maTruong": "7977MN38",
                           "tenTruong": "MN Quốc tế Mỹ Úc"
                         },
                         {
                           "maTruong": "7977NT01",
                           "tenTruong": "Lớp NT Quỳnh Anh"
                         },
                         {
                           "maTruong": "7977NT04",
                           "tenTruong": "Lớp NT Dâu Tây"
                         },
                         {
                           "maTruong": "7977MN39",
                           "tenTruong": "MN Thành phố Tuổi thơ"
                         },
                         {
                           "maTruong": "7977MN40",
                           "tenTruong": "MN Việt Mỹ Anh"
                         },
                         {
                           "maTruong": "79772W14",
                           "tenTruong": "Lớp Mẫu Giáo Hạnh Phúc"
                         },
                         {
                           "maTruong": "79772W15",
                           "tenTruong": "MN Ngôi Nhà Sao Sáng"
                         },
                         {
                           "maTruong": "79772273",
                           "tenTruong": "Lớp Mẫu giáo Thế Giới Ngày Mai"
                         },
                         {
                           "maTruong": "7977201002",
                           "tenTruong": "Trường Mầm non Thế Giới Ngày Mai"
                         },
                         {
                           "maTruong": "7977201001",
                           "tenTruong": "Lớp Mẫu Giáo Mai Anh"
                         }
                       ]
                     },
                     {
                       "maPhong": "769",
                       "tenPhong": "Quận 2",
                       "truongData": [
                         {
                           "maTruong": "79769412",
                           "tenTruong": "TH An Bình"
                         },
                         {
                           "maTruong": "79769401",
                           "tenTruong": "TH An Khánh"
                         },
                         {
                           "maTruong": "79769403",
                           "tenTruong": "TH An Phú"
                         },
                         {
                           "maTruong": "79769404",
                           "tenTruong": "TH Giồng Ông Tố"
                         },
                         {
                           "maTruong": "79769405",
                           "tenTruong": "TH Huỳnh Văn Ngỡi"
                         },
                         {
                           "maTruong": "79769410",
                           "tenTruong": "TH Lương Thế Vinh"
                         },
                         {
                           "maTruong": "79769406",
                           "tenTruong": "TH Mỹ Thủy"
                         },
                         {
                           "maTruong": "79769409",
                           "tenTruong": "TH Nguyễn Hiền"
                         },
                         {
                           "maTruong": "79769407",
                           "tenTruong": "TH Nguyễn Văn Trỗi"
                         },
                         {
                           "maTruong": "79769408",
                           "tenTruong": "TH Thạnh Mỹ lợi"
                         },
                         {
                           "maTruong": "79769411",
                           "tenTruong": "Tiểu học và Trung học cơ sở Tuệ Đức"
                         },
                         {
                           "maTruong": "79769501",
                           "tenTruong": "THCS An Phú"
                         },
                         {
                           "maTruong": "79769507",
                           "tenTruong": "THCS Bình An"
                         },
                         {
                           "maTruong": "79769510",
                           "tenTruong": "THCS Cát Lái"
                         },
                         {
                           "maTruong": "79769502",
                           "tenTruong": "THCS Giồng Ông Tố"
                         },
                         {
                           "maTruong": "79769506",
                           "tenTruong": "THCS Lương Định Của"
                         },
                         {
                           "maTruong": "79769508",
                           "tenTruong": "THCS Nguyễn Thị Định"
                         },
                         {
                           "maTruong": "79769503",
                           "tenTruong": "THCS Nguyễn Văn Trỗi"
                         },
                         {
                           "maTruong": "79769504",
                           "tenTruong": "THCS Thạnh Mỹ Lợi"
                         },
                         {
                           "maTruong": "79769509",
                           "tenTruong": "THCS Trần Quốc Toản"
                         },
                         {
                           "maTruong": "79000C04",
                           "tenTruong": "Song ngữ Quốc tế Horizon"
                         },
                         {
                           "maTruong": "79769205",
                           "tenTruong": "MGTT Cỏ Non"
                         },
                         {
                           "maTruong": "79769116",
                           "tenTruong": "MN  ABC"
                         },
                         {
                           "maTruong": "79769301",
                           "tenTruong": "MN 19/5"
                         },
                         {
                           "maTruong": "79769352",
                           "tenTruong": "MN An Bình"
                         },
                         {
                           "maTruong": "79769314",
                           "tenTruong": "MN An Phú"
                         },
                         {
                           "maTruong": "79769W11",
                           "tenTruong": "MN Bầu Trời Xanh"
                         },
                         {
                           "maTruong": "79769303",
                           "tenTruong": "MN Bình An"
                         },
                         {
                           "maTruong": "79769304",
                           "tenTruong": "MN Bình Trưng Đông"
                         },
                         {
                           "maTruong": "79769305",
                           "tenTruong": "MN Cát Lái"
                         },
                         {
                           "maTruong": "79769340",
                           "tenTruong": "MN Chú Ong Vui Vẻ"
                         },
                         {
                           "maTruong": "79769360",
                           "tenTruong": "MN Hoa Diên Vỹ"
                         },
                         {
                           "maTruong": "79769302",
                           "tenTruong": "MN Hoa Hồng"
                         },
                         {
                           "maTruong": "79769306",
                           "tenTruong": "MN Hoạ Mi"
                         },
                         {
                           "maTruong": "79769358",
                           "tenTruong": "MN Học Viện Sài Gòn"
                         },
                         {
                           "maTruong": "79769114",
                           "tenTruong": "MN Khang Phú"
                         },
                         {
                           "maTruong": "79769328",
                           "tenTruong": "MN Khôi Nguyên"
                         },
                         {
                           "maTruong": "79769307",
                           "tenTruong": "MN Măng Non"
                         },
                         {
                           "maTruong": "79769W12",
                           "tenTruong": "MN Mặt Trời Hồng"
                         },
                         {
                           "maTruong": "79769334",
                           "tenTruong": "MN Miền Trẻ Thơ"
                         },
                         {
                           "maTruong": "79769320",
                           "tenTruong": "MN Ngôi Nhà ETON"
                         },
                         {
                           "maTruong": "79769348",
                           "tenTruong": "MN Ngôi Nhà Trẻ Thơ"
                         },
                         {
                           "maTruong": "79769341",
                           "tenTruong": "MN Sao Việt"
                         },
                         {
                           "maTruong": "79769313",
                           "tenTruong": "MN Sen Hồng"
                         },
                         {
                           "maTruong": "79769324",
                           "tenTruong": "MN Sơn Ca"
                         },
                         {
                           "maTruong": "79769326",
                           "tenTruong": "MN Song ngữ Quốc tế Horizon"
                         },
                         {
                           "maTruong": "79769337",
                           "tenTruong": "MN Thái Bình"
                         },
                         {
                           "maTruong": "79769315",
                           "tenTruong": "MN Thạnh Mỹ Lợi"
                         },
                         {
                           "maTruong": "79769350",
                           "tenTruong": "MN Thành Phố Nhỏ"
                         },
                         {
                           "maTruong": "79769355",
                           "tenTruong": "MN Thành Phố Tuổi Thơ"
                         },
                         {
                           "maTruong": "79769308",
                           "tenTruong": "MN Thảo Điền"
                         },
                         {
                           "maTruong": "79769327",
                           "tenTruong": "MN Trẻ Em Sài Gòn"
                         },
                         {
                           "maTruong": "79769718",
                           "tenTruong": "MN Tuệ Đức"
                         },
                         {
                           "maTruong": "79769361",
                           "tenTruong": "MN Tuổi Thơ Kỳ Diệu"
                         },
                         {
                           "maTruong": "79769331",
                           "tenTruong": "MN Vành Khuyên"
                         },
                         {
                           "maTruong": "79769344",
                           "tenTruong": "MN Viên Ngọc Sáng"
                         },
                         {
                           "maTruong": "79769310",
                           "tenTruong": "MN Vườn Hồng"
                         },
                         {
                           "maTruong": "79769321",
                           "tenTruong": "MNTT Ánh Cầu Vồng"
                         },
                         {
                           "maTruong": "79769W18",
                           "tenTruong": "MNTT Bé Hạnh Phúc"
                         },
                         {
                           "maTruong": "79769346",
                           "tenTruong": "MNTT Bé Yêu"
                         },
                         {
                           "maTruong": "79769311",
                           "tenTruong": "MNTT Bình Minh"
                         },
                         {
                           "maTruong": "79769323",
                           "tenTruong": "MNTT Chân Xinh"
                         },
                         {
                           "maTruong": "79769338",
                           "tenTruong": "MNTT Chuột Túi Thông Minh"
                         },
                         {
                           "maTruong": "79769362",
                           "tenTruong": "MNTT ĐÔNG BẮC"
                         },
                         {
                           "maTruong": "79769333",
                           "tenTruong": "MNTT Đông Sài Gòn"
                         },
                         {
                           "maTruong": "79769336",
                           "tenTruong": "MNTT Én Nhỏ"
                         },
                         {
                           "maTruong": "79769335",
                           "tenTruong": "MNTT Hoa Anh Đào"
                         },
                         {
                           "maTruong": "79769354",
                           "tenTruong": "MNTT Hoa Bồ Công Anh"
                         },
                         {
                           "maTruong": "79769357",
                           "tenTruong": "MNTT Hoa Hướng Dương"
                         },
                         {
                           "maTruong": "79769329",
                           "tenTruong": "MNTT Hoa Phúc Lộc"
                         },
                         {
                           "maTruong": "79769343",
                           "tenTruong": "MNTT Hừng Đông"
                         },
                         {
                           "maTruong": "79769359",
                           "tenTruong": "MNTT Mặt Trời Nhỏ"
                         },
                         {
                           "maTruong": "79769325",
                           "tenTruong": "MNTT Montessori"
                         },
                         {
                           "maTruong": "79769356",
                           "tenTruong": "MNTT Mỹ Đức"
                         },
                         {
                           "maTruong": "79769332",
                           "tenTruong": "MNTT Nam Mỹ"
                         },
                         {
                           "maTruong": "79769W03",
                           "tenTruong": "MNTT Ngôi Sao"
                         },
                         {
                           "maTruong": "79769364",
                           "tenTruong": "MNTT Nhóc Trùm"
                         },
                         {
                           "maTruong": "79769339",
                           "tenTruong": "MNTT Nụ Cười Hồng"
                         },
                         {
                           "maTruong": "79769312",
                           "tenTruong": "MNTT Tân Đông"
                         },
                         {
                           "maTruong": "79769330",
                           "tenTruong": "MNTT Thần Đồng"
                         },
                         {
                           "maTruong": "79769365",
                           "tenTruong": "MNTT Thành Phố Tuổi Hoa"
                         },
                         {
                           "maTruong": "79769110",
                           "tenTruong": "MNTT Thiên Ân"
                         },
                         {
                           "maTruong": "79769363",
                           "tenTruong": "MNTT Thiên Nhiên"
                         },
                         {
                           "maTruong": "79769104",
                           "tenTruong": "MNTT Tuổi Thơ"
                         },
                         {
                           "maTruong": "79769319",
                           "tenTruong": "MNTT Tương Lai"
                         },
                         {
                           "maTruong": "79769347",
                           "tenTruong": "MNTT Úc Châu"
                         },
                         {
                           "maTruong": "79769322",
                           "tenTruong": "MNTT Việt Mỹ"
                         },
                         {
                           "maTruong": "79769317",
                           "tenTruong": "MNTT Việt Phương"
                         },
                         {
                           "maTruong": "79769366",
                           "tenTruong": "MNTT Vịt Con"
                         },
                         {
                           "maTruong": "79769121",
                           "tenTruong": "MNTT Vịt Donal"
                         },
                         {
                           "maTruong": "79769345",
                           "tenTruong": "MNTT Yêu Con"
                         },
                         {
                           "maTruong": "79769208",
                           "tenTruong": "MG Bé Tài Năng"
                         },
                         {
                           "maTruong": "79769207",
                           "tenTruong": "MG Cát Minh"
                         },
                         {
                           "maTruong": "79769209",
                           "tenTruong": "MG Cầu Vồng May Mắn"
                         },
                         {
                           "maTruong": "79769111",
                           "tenTruong": "MG Phượng Hồng"
                         },
                         {
                           "maTruong": "79769106",
                           "tenTruong": "MG Thuận Thảo"
                         },
                         {
                           "maTruong": "79769206",
                           "tenTruong": "MGTT Chào Bạn Nhỏ"
                         },
                         {
                           "maTruong": "79769113",
                           "tenTruong": "MGTT Thanh Bình"
                         },
                         {
                           "maTruong": "79769W20",
                           "tenTruong": "NT   Hoa Mặt Trời"
                         },
                         {
                           "maTruong": "79769L87",
                           "tenTruong": "NT  HOA PHƯỢNG"
                         },
                         {
                           "maTruong": "79769W25",
                           "tenTruong": "NT  Ước Mơ"
                         },
                         {
                           "maTruong": "79769L88",
                           "tenTruong": "NT 1/6"
                         },
                         {
                           "maTruong": "79769W26",
                           "tenTruong": "NT Ánh Sao"
                         },
                         {
                           "maTruong": "79769W16",
                           "tenTruong": "NT Bé Ngoan"
                         },
                         {
                           "maTruong": "79769L78",
                           "tenTruong": "NT Bé Thông Minh"
                         },
                         {
                           "maTruong": "79769025",
                           "tenTruong": "NT Cà Chua Bi"
                         },
                         {
                           "maTruong": "79769W08",
                           "tenTruong": "NT Gia An"
                         },
                         {
                           "maTruong": "79769L79",
                           "tenTruong": "NT Gia Phú"
                         },
                         {
                           "maTruong": "79769W09",
                           "tenTruong": "NT Hải Âu"
                         },
                         {
                           "maTruong": "79769101",
                           "tenTruong": "NT Hạnh Phúc"
                         },
                         {
                           "maTruong": "79769105",
                           "tenTruong": "NT Hoa Cúc"
                         },
                         {
                           "maTruong": "79769117",
                           "tenTruong": "NT Hoa Mai"
                         },
                         {
                           "maTruong": "79769123",
                           "tenTruong": "NT Hoa Nắng"
                         },
                         {
                           "maTruong": "79769477",
                           "tenTruong": "NT Hoa Sữa"
                         },
                         {
                           "maTruong": "79769L81",
                           "tenTruong": "NT Hồng Ân"
                         },
                         {
                           "maTruong": "79769L89",
                           "tenTruong": "NT Hồng Nhung"
                         },
                         {
                           "maTruong": "79769108",
                           "tenTruong": "NT Ngọc Diệp"
                         },
                         {
                           "maTruong": "79769W02",
                           "tenTruong": "NT Ngôi Nhà Hạnh Phúc"
                         },
                         {
                           "maTruong": "79769L85",
                           "tenTruong": "NT Ngôi Nhà Tuổi Thơ"
                         },
                         {
                           "maTruong": "79769L10",
                           "tenTruong": "NT Ngôi Sao Xanh"
                         },
                         {
                           "maTruong": "79769L83",
                           "tenTruong": "NT Nụ Cười Xinh"
                         },
                         {
                           "maTruong": "79769L80",
                           "tenTruong": "NT Quả Táo Đỏ"
                         },
                         {
                           "maTruong": "79769W21",
                           "tenTruong": "NT Rạng Đông"
                         },
                         {
                           "maTruong": "79769W10",
                           "tenTruong": "NT Sao Mai"
                         },
                         {
                           "maTruong": "79769L86",
                           "tenTruong": "NT Thảo Uyên"
                         },
                         {
                           "maTruong": "79769W15",
                           "tenTruong": "NT Thiên Thần Nhỏ"
                         },
                         {
                           "maTruong": "79769L90",
                           "tenTruong": "NT Thỏ Trắng"
                         },
                         {
                           "maTruong": "79769W22",
                           "tenTruong": "NT Tuổi Hồng"
                         },
                         {
                           "maTruong": "79769102",
                           "tenTruong": "NT Vàng Anh"
                         },
                         {
                           "maTruong": "79769L91",
                           "tenTruong": "NT Việt Anh"
                         },
                         {
                           "maTruong": "79769413",
                           "tenTruong": "TiH Bình Trưng Đông"
                         },
                         {
                           "maTruong": "79769390",
                           "tenTruong": "MN Tài Năng Tuổi Thơ"
                         },
                         {
                           "maTruong": "79769601",
                           "tenTruong": "Chuyên biệt Thảo Điền"
                         },
                         {
                           "maTruong": "79769373",
                           "tenTruong": "MN Bé Ong Sài Gòn"
                         },
                         {
                           "maTruong": "79769353",
                           "tenTruong": "MN Trẻ em quốc tế Smartkids"
                         },
                         {
                           "maTruong": "79769371",
                           "tenTruong": "MN Nhà Bé Vui"
                         },
                         {
                           "maTruong": "79769L13",
                           "tenTruong": "NT Hoa Yêu Thương"
                         },
                         {
                           "maTruong": "79769370",
                           "tenTruong": "MN Chuồn Chuồn Kim"
                         },
                         {
                           "maTruong": "79769372",
                           "tenTruong": "MN Nhà Của Bé"
                         },
                         {
                           "maTruong": "79769L11",
                           "tenTruong": "NT Anna"
                         },
                         {
                           "maTruong": "79769L12",
                           "tenTruong": "NT Én Vàng"
                         },
                         {
                           "maTruong": "79769L14",
                           "tenTruong": "NT Nhà Cà Rốt"
                         },
                         {
                           "maTruong": "79769367",
                           "tenTruong": "MN Hành Tinh Trẻ"
                         },
                         {
                           "maTruong": "79769368",
                           "tenTruong": "MN Cỏ 3 Lá"
                         },
                         {
                           "maTruong": "79769369",
                           "tenTruong": "MN Tre Xanh"
                         },
                         {
                           "maTruong": "79769L15",
                           "tenTruong": "MG Trẻ Tài Năng"
                         },
                         {
                           "maTruong": "79769L16",
                           "tenTruong": "NT Nắng Bang Mai"
                         },
                         {
                           "maTruong": "79769710",
                           "tenTruong": "MN Vườn Trẻ Thơ"
                         },
                         {
                           "maTruong": "79769711",
                           "tenTruong": "MN Bàn Tay Thiên Thần"
                         },
                         {
                           "maTruong": "79769712",
                           "tenTruong": "MN Vương Quốc KiKo"
                         },
                         {
                           "maTruong": "79769801",
                           "tenTruong": "MN Vùng Đất Tuổi Thơ"
                         },
                         {
                           "maTruong": "79769814",
                           "tenTruong": "MG Tuổi thần tiên"
                         },
                         {
                           "maTruong": "79769815",
                           "tenTruong": "NT Bảo Hân"
                         },
                         {
                           "maTruong": "79769802",
                           "tenTruong": "MG Hoa Xuyến Chi"
                         },
                         {
                           "maTruong": "79769803",
                           "tenTruong": "MN Bước Chân Tuổi Thơ"
                         },
                         {
                           "maTruong": "79769381",
                           "tenTruong": "MN Ngôi Nhà Vui Nhộn"
                         },
                         {
                           "maTruong": "79769701",
                           "tenTruong": "MN Việt Úc"
                         },
                         {
                           "maTruong": "79769822",
                           "tenTruong": "MN Global Ecokids"
                         },
                         {
                           "maTruong": "79769843",
                           "tenTruong": "MG Ngôi Sao Sài Gòn"
                         },
                         {
                           "maTruong": "79769399",
                           "tenTruong": "MN Hoa Sen"
                         },
                         {
                           "maTruong": "79769827",
                           "tenTruong": "NT Gấu Misa"
                         },
                         {
                           "maTruong": "79769829",
                           "tenTruong": "MG Ngôi Sao Sáng"
                         },
                         {
                           "maTruong": "79769830",
                           "tenTruong": "NT Tổ Ong Vàng"
                         },
                         {
                           "maTruong": "79769831",
                           "tenTruong": "MG Mai Cham"
                         },
                         {
                           "maTruong": "79769833",
                           "tenTruong": "MN Hướng Dương Sài Gòn"
                         },
                         {
                           "maTruong": "79769835",
                           "tenTruong": "MN Lâm An"
                         },
                         {
                           "maTruong": "79769836",
                           "tenTruong": "MN Trẻ Em Anfa"
                         },
                         {
                           "maTruong": "79769837",
                           "tenTruong": "MN Ngôi Nhà Nhỏ"
                         },
                         {
                           "maTruong": "79769839",
                           "tenTruong": "MN Vương Quốc Tí Hon"
                         },
                         {
                           "maTruong": "79769816",
                           "tenTruong": "NT Ngôi Sao Nhỏ"
                         },
                         {
                           "maTruong": "79769817",
                           "tenTruong": "Banh và Bi"
                         },
                         {
                           "maTruong": "79769818",
                           "tenTruong": "NT Ánh Bình Minh"
                         },
                         {
                           "maTruong": "79769820",
                           "tenTruong": "MN QT Pháp Việt Thiên Thần"
                         },
                         {
                           "maTruong": "79769821",
                           "tenTruong": "MN Phần Lan"
                         }
                       ]
                     },
                     {
                       "maPhong": "771",
                       "tenPhong": "Quận 10",
                       "truongData": [
                         {
                           "maTruong": "79771415",
                           "tenTruong": "TH Bắc Hải"
                         },
                         {
                           "maTruong": "79771409",
                           "tenTruong": "TH Điện Biên"
                         },
                         {
                           "maTruong": "79771407",
                           "tenTruong": "TH Dương Minh Châu"
                         },
                         {
                           "maTruong": "79771401",
                           "tenTruong": "TH Hồ Thị Kỷ"
                         },
                         {
                           "maTruong": "79771412",
                           "tenTruong": "TH Hoàng Diệu"
                         },
                         {
                           "maTruong": "79771414",
                           "tenTruong": "TH Lê Đình Chinh"
                         },
                         {
                           "maTruong": "79771413",
                           "tenTruong": "TH Lê Thị Riêng"
                         },
                         {
                           "maTruong": "79771406",
                           "tenTruong": "TH Nguyễn Chí Thanh"
                         },
                         {
                           "maTruong": "79771408",
                           "tenTruong": "TH Nhật Tảo"
                         },
                         {
                           "maTruong": "79771410",
                           "tenTruong": "TH Thiên Hộ Dương"
                         },
                         {
                           "maTruong": "79771416",
                           "tenTruong": "TH Tô Hiến Thành"
                         },
                         {
                           "maTruong": "79771402",
                           "tenTruong": "TH Trần Nhân Tôn"
                         },
                         {
                           "maTruong": "79771405",
                           "tenTruong": "TH Trần Quang Cơ"
                         },
                         {
                           "maTruong": "79771420",
                           "tenTruong": "TH Trần Văn Kiểu"
                         },
                         {
                           "maTruong": "79771411",
                           "tenTruong": "TH Triệu Thị Trinh"
                         },
                         {
                           "maTruong": "79771403",
                           "tenTruong": "TH Trương Định"
                         },
                         {
                           "maTruong": "79771417",
                           "tenTruong": "TH Võ Trường Toản"
                         },
                         {
                           "maTruong": "79771504",
                           "tenTruong": "THCS Lạc Hồng"
                         },
                         {
                           "maTruong": "79771507",
                           "tenTruong": "THCS Cách Mạng Tháng Tám"
                         },
                         {
                           "maTruong": "79771501",
                           "tenTruong": "THCS Hoàng Văn Thụ"
                         },
                         {
                           "maTruong": "79771502",
                           "tenTruong": "THCS Nguyễn Tri Phương"
                         },
                         {
                           "maTruong": "79771503",
                           "tenTruong": "THCS Nguyễn Văn Tố"
                         },
                         {
                           "maTruong": "79771505",
                           "tenTruong": "THCS Trần Phú"
                         },
                         {
                           "maTruong": "79000841",
                           "tenTruong": "THCS - THPT Duy Tân"
                         },
                         {
                           "maTruong": "79000861",
                           "tenTruong": "THCS-THPT Diên Hồng"
                         },
                         {
                           "maTruong": "79000860",
                           "tenTruong": "THCS-THPT Sương Nguyệt Anh"
                         },
                         {
                           "maTruong": "79000818",
                           "tenTruong": "TH - THCS - THPT Vạn Hạnh"
                         },
                         {
                           "maTruong": "79000C03",
                           "tenTruong": "TH, THCS, THPT Việt Úc"
                         },
                         {
                           "maTruong": "79771323",
                           "tenTruong": "Cỏ Ba Lá"
                         },
                         {
                           "maTruong": "79771301",
                           "tenTruong": "Mầm non 19/5"
                         },
                         {
                           "maTruong": "79771302",
                           "tenTruong": "Mầm Non 2/9"
                         },
                         {
                           "maTruong": "79771341",
                           "tenTruong": "MẦM NON ÁNH SAO"
                         },
                         {
                           "maTruong": "79771348",
                           "tenTruong": "MẦM NON KHAI TRÍ"
                         },
                         {
                           "maTruong": "79771346",
                           "tenTruong": "Mầm non Tương Lai"
                         },
                         {
                           "maTruong": "79771342",
                           "tenTruong": "Mầm non Vạn An"
                         },
                         {
                           "maTruong": "79771324",
                           "tenTruong": "MG Thanh Tâm"
                         },
                         {
                           "maTruong": "79771325",
                           "tenTruong": "MN 12A"
                         },
                         {
                           "maTruong": "79771344",
                           "tenTruong": "MN Bình Minh"
                         },
                         {
                           "maTruong": "79771335",
                           "tenTruong": "MN Con Mèo Vàng"
                         },
                         {
                           "maTruong": "79771321",
                           "tenTruong": "MN Khải Tâm"
                         },
                         {
                           "maTruong": "79771322",
                           "tenTruong": "MN Lan Anh"
                         },
                         {
                           "maTruong": "79771303",
                           "tenTruong": "MN Măng Non I"
                         },
                         {
                           "maTruong": "79771304",
                           "tenTruong": "MN Măng Non II"
                         },
                         {
                           "maTruong": "79771305",
                           "tenTruong": "MN Măng Non III"
                         },
                         {
                           "maTruong": "79771345",
                           "tenTruong": "MN Ngôi Sao Việt"
                         },
                         {
                           "maTruong": "79771306",
                           "tenTruong": "MN Phường 1"
                         },
                         {
                           "maTruong": "79771315",
                           "tenTruong": "MN Phường 10"
                         },
                         {
                           "maTruong": "79771316",
                           "tenTruong": "MN Phường 11"
                         },
                         {
                           "maTruong": "79771318",
                           "tenTruong": "MN Phường 13"
                         },
                         {
                           "maTruong": "79771319",
                           "tenTruong": "MN Phường 14"
                         },
                         {
                           "maTruong": "79771333",
                           "tenTruong": "MN Phường 15A"
                         },
                         {
                           "maTruong": "79771334",
                           "tenTruong": "MN Phường 15B"
                         },
                         {
                           "maTruong": "79771307",
                           "tenTruong": "MN Phường 2"
                         },
                         {
                           "maTruong": "79771308",
                           "tenTruong": "MN Phường 3"
                         },
                         {
                           "maTruong": "79771309",
                           "tenTruong": "MN Phường 4"
                         },
                         {
                           "maTruong": "79771310",
                           "tenTruong": "MN Phường 5"
                         },
                         {
                           "maTruong": "79771311",
                           "tenTruong": "MN Phường 6"
                         },
                         {
                           "maTruong": "79771312",
                           "tenTruong": "MN Phường 7"
                         },
                         {
                           "maTruong": "79771313",
                           "tenTruong": "MN Phường 8"
                         },
                         {
                           "maTruong": "79771314",
                           "tenTruong": "MN Phường 9"
                         },
                         {
                           "maTruong": "79771320",
                           "tenTruong": "MN Sài Gòn"
                         },
                         {
                           "maTruong": "79771329",
                           "tenTruong": "MN Sóc Nâu"
                         },
                         {
                           "maTruong": "79771343",
                           "tenTruong": "MN Việt Úc"
                         },
                         {
                           "maTruong": "79771L30",
                           "tenTruong": "Trường Mầm non Vườn Ươm Tương Lai"
                         },
                         {
                           "maTruong": "79771331",
                           "tenTruong": "Trường Mầm non Yêu con"
                         },
                         {
                           "maTruong": "79771328",
                           "tenTruong": "LỚP MG12B"
                         },
                         {
                           "maTruong": "79771200",
                           "tenTruong": "Ngôi nhà trẻ thơ"
                         },
                         {
                           "maTruong": "79771100",
                           "tenTruong": "Nhóm trẻ Hạnh phúc"
                         },
                         {
                           "maTruong": "79771L32",
                           "tenTruong": "Nhóm trẻ Mai Anh"
                         },
                         {
                           "maTruong": "79771L31",
                           "tenTruong": "Nhóm trẻ Sao Việt Mỹ"
                         },
                         {
                           "maTruong": "79771O04",
                           "tenTruong": "NT Minh Khuê"
                         },
                         {
                           "maTruong": "79771O03",
                           "tenTruong": "NT Sao Mai Vàng"
                         },
                         {
                           "maTruong": "79771350",
                           "tenTruong": "Trường Mầm non Sài Gòn Montessori"
                         },
                         {
                           "maTruong": "79771351",
                           "tenTruong": "Trường Mầm non Thành phố Tuổi THơ"
                         },
                         {
                           "maTruong": "79771352",
                           "tenTruong": "Trường Mầm non Global Ecokids"
                         },
                         {
                           "maTruong": "79771347",
                           "tenTruong": "Anh Nhi Hạnh"
                         },
                         {
                           "maTruong": "79771330",
                           "tenTruong": "Vườn Thiên Thần"
                         },
                         {
                           "maTruong": "7977103001",
                           "tenTruong": "THCS Hòa Hưng"
                         },
                         {
                           "maTruong": "79771284",
                           "tenTruong": "MN Chuồn Chuồn Kim"
                         },
                         {
                           "maTruong": "79771066",
                           "tenTruong": "NT Khủng Long Con"
                         },
                         {
                           "maTruong": "7977102001",
                           "tenTruong": "Trường Tiểu học - Trung học cơ sở Pennschool"
                         },
                         {
                           "maTruong": "7977101001",
                           "tenTruong": "Trường Mầm non Thực Hành"
                         },
                         {
                           "maTruong": "7977101002",
                           "tenTruong": "MN Vườn Tuổi Thơ"
                         }
                       ]
                     },
                     {
                       "maPhong": "784",
                       "tenPhong": "Huyện Hóc Môn",
                       "truongData": [
                         {
                           "maTruong": "79784408",
                           "tenTruong": "TH ẤP ĐÌNH"
                         },
                         {
                           "maTruong": "79784419",
                           "tenTruong": "TH BÙI VĂN NGỮ"
                         },
                         {
                           "maTruong": "79784410",
                           "tenTruong": "TH CẦU XÁNG"
                         },
                         {
                           "maTruong": "79784417",
                           "tenTruong": "TH DƯƠNG CÔNG KHI"
                         },
                         {
                           "maTruong": "79784415",
                           "tenTruong": "TH HOÀNG HOA THÁM"
                         },
                         {
                           "maTruong": "79784412",
                           "tenTruong": "TH LÝ CHÍNH THẮNG 2"
                         },
                         {
                           "maTruong": "79784407",
                           "tenTruong": "TH MỸ HÒA"
                         },
                         {
                           "maTruong": "79784405",
                           "tenTruong": "TH MỸ HUỀ"
                         },
                         {
                           "maTruong": "79784406",
                           "tenTruong": "TH NAM KỲ KHỞI NGHĨA"
                         },
                         {
                           "maTruong": "79784414",
                           "tenTruong": "TH NGÃ BA GIỒNG"
                         },
                         {
                           "maTruong": "79784401",
                           "tenTruong": "TH NGUYỄN AN NINH"
                         },
                         {
                           "maTruong": "79784426",
                           "tenTruong": "TH NGUYỄN THỊ NUÔI"
                         },
                         {
                           "maTruong": "79784413",
                           "tenTruong": "TH NHỊ TÂN"
                         },
                         {
                           "maTruong": "79784403",
                           "tenTruong": "TH TAM ĐÔNG"
                         },
                         {
                           "maTruong": "79784404",
                           "tenTruong": "TH TAM ĐÔNG 2"
                         },
                         {
                           "maTruong": "79784409",
                           "tenTruong": "TH TÂN HIỆP"
                         },
                         {
                           "maTruong": "79784422",
                           "tenTruong": "TH TÂN XUÂN"
                         },
                         {
                           "maTruong": "79784411",
                           "tenTruong": "TH TÂY BẮC LÂN"
                         },
                         {
                           "maTruong": "79784402",
                           "tenTruong": "TH THỚI TAM"
                         },
                         {
                           "maTruong": "79784416",
                           "tenTruong": "TH THỚI THẠNH"
                         },
                         {
                           "maTruong": "79784425",
                           "tenTruong": "TH TRẦN VĂN DANH"
                         },
                         {
                           "maTruong": "79784424",
                           "tenTruong": "TH TRẦN VĂN MƯỜI"
                         },
                         {
                           "maTruong": "79784420",
                           "tenTruong": "TH TRƯƠNG VĂN NGÀI"
                         },
                         {
                           "maTruong": "79784418",
                           "tenTruong": "TH VÕ VĂN THẶNG"
                         },
                         {
                           "maTruong": "79784423",
                           "tenTruong": "TH XUÂN THỚI THƯỢNG"
                         },
                         {
                           "maTruong": "79784508",
                           "tenTruong": "THCS ĐẶNG CÔNG BỈNH"
                         },
                         {
                           "maTruong": "79784509",
                           "tenTruong": "THCS ĐỖ VĂN DẬY"
                         },
                         {
                           "maTruong": "79784506",
                           "tenTruong": "THCS ĐÔNG THẠNH"
                         },
                         {
                           "maTruong": "79784504",
                           "tenTruong": "THCS LÝ CHÍNH THẮNG 1"
                         },
                         {
                           "maTruong": "79784501",
                           "tenTruong": "THCS NGUYỄN AN KHƯƠNG"
                         },
                         {
                           "maTruong": "79784507",
                           "tenTruong": "THCS NGUYỄN HỒNG ĐÀO"
                         },
                         {
                           "maTruong": "79784510",
                           "tenTruong": "THCS PHAN CÔNG HỚN"
                         },
                         {
                           "maTruong": "79784503",
                           "tenTruong": "THCS TAM ĐÔNG 1"
                         },
                         {
                           "maTruong": "79784511",
                           "tenTruong": "THCS TÂN XUÂN"
                         },
                         {
                           "maTruong": "79784502",
                           "tenTruong": "THCS THỊ TRẤN"
                         },
                         {
                           "maTruong": "79784513",
                           "tenTruong": "THCS TÔ KÝ"
                         },
                         {
                           "maTruong": "79784505",
                           "tenTruong": "THCS TRUNG MỸ TÂY 1"
                         },
                         {
                           "maTruong": "79784512",
                           "tenTruong": "THCS XUÂN THỚI THƯỢNG"
                         },
                         {
                           "maTruong": "79784204",
                           "tenTruong": "MG BÉ NGOAN 1"
                         },
                         {
                           "maTruong": "79784206",
                           "tenTruong": "MG BÔNG SEN 1"
                         },
                         {
                           "maTruong": "79784323",
                           "tenTruong": "MN 19/8"
                         },
                         {
                           "maTruong": "79784324",
                           "tenTruong": "MN 2/9"
                         },
                         {
                           "maTruong": "79784308",
                           "tenTruong": "MN 23/11"
                         },
                         {
                           "maTruong": "79784305",
                           "tenTruong": "MN BÀ ĐIỂM"
                         },
                         {
                           "maTruong": "79784303",
                           "tenTruong": "MN BÉ NGOAN"
                         },
                         {
                           "maTruong": "79784205",
                           "tenTruong": "MN BÉ NGOAN 3"
                         },
                         {
                           "maTruong": "79784306",
                           "tenTruong": "MN BÔNG SEN"
                         },
                         {
                           "maTruong": "79784307",
                           "tenTruong": "MN HƯỚNG DƯƠNG"
                         },
                         {
                           "maTruong": "79784301",
                           "tenTruong": "MN NHỊ XUÂN"
                         },
                         {
                           "maTruong": "79784302",
                           "tenTruong": "MN SƠN CA"
                         },
                         {
                           "maTruong": "79784207",
                           "tenTruong": "MN SƠN CA 3"
                         },
                         {
                           "maTruong": "79784201",
                           "tenTruong": "MN TÂN HIỆP"
                         },
                         {
                           "maTruong": "79784314",
                           "tenTruong": "MN TÂN HÒA"
                         },
                         {
                           "maTruong": "79784304",
                           "tenTruong": "MN TÂN XUÂN"
                         },
                         {
                           "maTruong": "79784325",
                           "tenTruong": "MN XUÂN THỚI ĐÔNG"
                         },
                         {
                           "maTruong": "79784309",
                           "tenTruong": "MNDL RẠNG ĐÔNG"
                         },
                         {
                           "maTruong": "79784319",
                           "tenTruong": "MNTT BỒ CÂU TRẮNG"
                         },
                         {
                           "maTruong": "79784318",
                           "tenTruong": "MNTT HÒA BÌNH"
                         },
                         {
                           "maTruong": "79784317",
                           "tenTruong": "MNTT KHAI TRÍ"
                         },
                         {
                           "maTruong": "79784310",
                           "tenTruong": "MNTT MINH ĐỨC"
                         },
                         {
                           "maTruong": "79784315",
                           "tenTruong": "MNTT SAO MAI"
                         },
                         {
                           "maTruong": "79784320",
                           "tenTruong": "MNTT THIÊN ĐỨC"
                         },
                         {
                           "maTruong": "79784316",
                           "tenTruong": "MNTT TOÀN MỸ"
                         },
                         {
                           "maTruong": "79784O29",
                           "tenTruong": "LMG  ÁNH SAO 2"
                         },
                         {
                           "maTruong": "79784Y05",
                           "tenTruong": "LMG  KHÁNH AN"
                         },
                         {
                           "maTruong": "79784WA9",
                           "tenTruong": "LMG  MINH TÂM 2"
                         },
                         {
                           "maTruong": "79784W70",
                           "tenTruong": "LMG  RẠNG ĐÔNG 3"
                         },
                         {
                           "maTruong": "79784WB0",
                           "tenTruong": "LMG  THIÊN THẦN VIỆT"
                         },
                         {
                           "maTruong": "79784W95",
                           "tenTruong": "LMG  VƯỜN HỒNG"
                         },
                         {
                           "maTruong": "79784W73",
                           "tenTruong": "LMG 15/10"
                         },
                         {
                           "maTruong": "79784O11",
                           "tenTruong": "LMG 19/6"
                         },
                         {
                           "maTruong": "79784P08",
                           "tenTruong": "LMG Á CHÂU"
                         },
                         {
                           "maTruong": "79784W23",
                           "tenTruong": "LMG ÁI TÂM"
                         },
                         {
                           "maTruong": "79784P05",
                           "tenTruong": "LMG AN THỊNH"
                         },
                         {
                           "maTruong": "79784W67",
                           "tenTruong": "LMG ÁNH DƯƠNG"
                         },
                         {
                           "maTruong": "79784WC1",
                           "tenTruong": "LMG ÁNH HOA HỒNG"
                         },
                         {
                           "maTruong": "79784W03",
                           "tenTruong": "LMG ÁNH HỒNG 2"
                         },
                         {
                           "maTruong": "79784Y27",
                           "tenTruong": "LMG ANH KHÔI"
                         },
                         {
                           "maTruong": "79784WC2",
                           "tenTruong": "LMG ÁNH MẶT TRỜI"
                         },
                         {
                           "maTruong": "79784P17",
                           "tenTruong": "LMG ANH NGUYÊN"
                         },
                         {
                           "maTruong": "79784O15",
                           "tenTruong": "LMG ÁNH SAO"
                         },
                         {
                           "maTruong": "79784WC3",
                           "tenTruong": "LMG ẤU THƠ"
                         },
                         {
                           "maTruong": "79784Z03",
                           "tenTruong": "LMG BAN MAI"
                         },
                         {
                           "maTruong": "79784Y31",
                           "tenTruong": "LMG BẢO HÂN"
                         },
                         {
                           "maTruong": "79784WA4",
                           "tenTruong": "LMG BẢO LONG"
                         },
                         {
                           "maTruong": "79784O26",
                           "tenTruong": "LMG BẢO MY 2"
                         },
                         {
                           "maTruong": "79784Y15",
                           "tenTruong": "LMG BẢO NGỌC"
                         },
                         {
                           "maTruong": "79784W74",
                           "tenTruong": "LMG BẢO NHƯ"
                         },
                         {
                           "maTruong": "79784P07",
                           "tenTruong": "LMG BÉ NGÔI SAO"
                         },
                         {
                           "maTruong": "79784W53",
                           "tenTruong": "LMG BÉ YÊU"
                         },
                         {
                           "maTruong": "79784P14",
                           "tenTruong": "LMG BÍ NGÔ"
                         },
                         {
                           "maTruong": "79784O14",
                           "tenTruong": "LMG BÍCH HIỆP"
                         },
                         {
                           "maTruong": "79784O09",
                           "tenTruong": "LMG BÌNH AN"
                         },
                         {
                           "maTruong": "79784W82",
                           "tenTruong": "LMG BÌNH HOA"
                         },
                         {
                           "maTruong": "79784W04",
                           "tenTruong": "LMG BÌNH MINH"
                         },
                         {
                           "maTruong": "79784X14",
                           "tenTruong": "LMG BÔNG HỒNG"
                         },
                         {
                           "maTruong": "79784X06",
                           "tenTruong": "LMG BÔNG HUỆ"
                         },
                         {
                           "maTruong": "79784W48",
                           "tenTruong": "LMG BÚP SEN"
                         },
                         {
                           "maTruong": "79784WB5",
                           "tenTruong": "LMG BÚT CHÌ MÀU"
                         },
                         {
                           "maTruong": "79784WC4",
                           "tenTruong": "LMG CẦU VỒNG"
                         },
                         {
                           "maTruong": "79784X15",
                           "tenTruong": "LMG CỎ NON"
                         },
                         {
                           "maTruong": "79784W87",
                           "tenTruong": "LMG ĐỒ RÊ MÍ 2"
                         },
                         {
                           "maTruong": "79784W93",
                           "tenTruong": "LMG ĐÔNG LÂN"
                         },
                         {
                           "maTruong": "79784O12",
                           "tenTruong": "LMG ĐỨC LƯƠNG"
                         },
                         {
                           "maTruong": "79784W28",
                           "tenTruong": "LMG GẤU TRÚC"
                         },
                         {
                           "maTruong": "79784WC5",
                           "tenTruong": "LMG HÀ MY"
                         },
                         {
                           "maTruong": "79784P03",
                           "tenTruong": "LMG HẢI ÂU"
                         },
                         {
                           "maTruong": "79784WD10",
                           "tenTruong": "LMG HẢI MINH"
                         },
                         {
                           "maTruong": "79784Y13",
                           "tenTruong": "LMG HẢI YẾN"
                         },
                         {
                           "maTruong": "79784W40",
                           "tenTruong": "LMG HẰNG NGA"
                         },
                         {
                           "maTruong": "79784Y23",
                           "tenTruong": "LMG HIỀN HẬU"
                         },
                         {
                           "maTruong": "79784Y17",
                           "tenTruong": "LMG HOA ANH ĐÀO"
                         },
                         {
                           "maTruong": "79784Y30",
                           "tenTruong": "LMG HOA BÉ NGOAN"
                         },
                         {
                           "maTruong": "79784W43",
                           "tenTruong": "LMG HOA CÚC"
                         },
                         {
                           "maTruong": "79784Z11",
                           "tenTruong": "LMG HOA HỒNG NHỎ"
                         },
                         {
                           "maTruong": "79784WA5",
                           "tenTruong": "LMG HOA HƯỚNG DƯƠNG"
                         },
                         {
                           "maTruong": "79784O06",
                           "tenTruong": "LMG HOA LÀI"
                         },
                         {
                           "maTruong": "79784W62",
                           "tenTruong": "LMG HOA LAN"
                         },
                         {
                           "maTruong": "79784W75",
                           "tenTruong": "LMG HOA MẶT TRỜI"
                         },
                         {
                           "maTruong": "79784W19",
                           "tenTruong": "LMG HỌA MI"
                         },
                         {
                           "maTruong": "79784W57",
                           "tenTruong": "LMG HOA PHƯỢNG"
                         },
                         {
                           "maTruong": "79784Y19",
                           "tenTruong": "LMG HOA PHƯỢNG ĐỎ"
                         },
                         {
                           "maTruong": "79784Y10",
                           "tenTruong": "LMG HOA QUỲNH"
                         },
                         {
                           "maTruong": "79784X01",
                           "tenTruong": "LMG HOA SEN"
                         },
                         {
                           "maTruong": "79784O18",
                           "tenTruong": "LMG HOA SEN 2"
                         },
                         {
                           "maTruong": "79784WD3",
                           "tenTruong": "LMG HOA THIÊN LÝ"
                         },
                         {
                           "maTruong": "79784WD7",
                           "tenTruong": "LMG HOA THỦY"
                         },
                         {
                           "maTruong": "79784O10",
                           "tenTruong": "LMG HOA TI GÔN"
                         },
                         {
                           "maTruong": "79784W98",
                           "tenTruong": "LMG HOA TI GÔN 2"
                         },
                         {
                           "maTruong": "79784Z17",
                           "tenTruong": "LMG HOA TRÚC"
                         },
                         {
                           "maTruong": "79784Y04",
                           "tenTruong": "LMG HOÀI AN"
                         },
                         {
                           "maTruong": "79784P15",
                           "tenTruong": "LMG HOÀN MỸ"
                         },
                         {
                           "maTruong": "79784W92",
                           "tenTruong": "LMG HOÀNG THƯ"
                         },
                         {
                           "maTruong": "79784WB7",
                           "tenTruong": "LMG HỒNG ÂN"
                         },
                         {
                           "maTruong": "79784Y28",
                           "tenTruong": "LMG HỒNG ANH"
                         },
                         {
                           "maTruong": "79784X13",
                           "tenTruong": "LMG HỒNG HẠC"
                         },
                         {
                           "maTruong": "79784W33",
                           "tenTruong": "LMG HỒNG HẠNH"
                         },
                         {
                           "maTruong": "79784WD8",
                           "tenTruong": "LMG HỒNG PHÚC"
                         },
                         {
                           "maTruong": "79784W25",
                           "tenTruong": "LMG HƯỚNG DƯƠNG 2"
                         },
                         {
                           "maTruong": "79784W65",
                           "tenTruong": "LMG HUY SƠN"
                         },
                         {
                           "maTruong": "79784Y32",
                           "tenTruong": "LMG KHAI MINH"
                         },
                         {
                           "maTruong": "79784Y26",
                           "tenTruong": "LMG KHAI SÁNG"
                         },
                         {
                           "maTruong": "79784y08",
                           "tenTruong": "LMG KHÁNH NGUYÊN"
                         },
                         {
                           "maTruong": "79784W97",
                           "tenTruong": "LMG KHÁNH TÂM"
                         },
                         {
                           "maTruong": "79784Y33",
                           "tenTruong": "LMG KIM ĐỒNG"
                         },
                         {
                           "maTruong": "79784W32",
                           "tenTruong": "LMG KIM YẾN"
                         },
                         {
                           "maTruong": "79784O01",
                           "tenTruong": "LMG LA SI ĐỐ"
                         },
                         {
                           "maTruong": "79784W52",
                           "tenTruong": "LMG MAI ANH ĐÀO"
                         },
                         {
                           "maTruong": "79784W56",
                           "tenTruong": "LMG MAI VÀNG"
                         },
                         {
                           "maTruong": "79784Z15",
                           "tenTruong": "LMG MẦM XANH"
                         },
                         {
                           "maTruong": "79784Y01",
                           "tenTruong": "LMG MĂNG NON XANH"
                         },
                         {
                           "maTruong": "79784W31",
                           "tenTruong": "LMG MẶT TRỜI NHỎ"
                         },
                         {
                           "maTruong": "79784X07",
                           "tenTruong": "LMG MÂY HỒNG"
                         },
                         {
                           "maTruong": "79784W79",
                           "tenTruong": "LMG MÂY TRẮNG"
                         },
                         {
                           "maTruong": "79784Z07",
                           "tenTruong": "LMG MINH CÚC"
                         },
                         {
                           "maTruong": "79784WB9",
                           "tenTruong": "LMG MINH NHẬT"
                         },
                         {
                           "maTruong": "79784W81",
                           "tenTruong": "LMG MINH TÂM"
                         },
                         {
                           "maTruong": "79784W94",
                           "tenTruong": "LMG MY MY"
                         },
                         {
                           "maTruong": "79784W76",
                           "tenTruong": "LMG NABY"
                         },
                         {
                           "maTruong": "79784Y21",
                           "tenTruong": "LMG NAI NGỌC 2"
                         },
                         {
                           "maTruong": "79784X09",
                           "tenTruong": "LMG NAM HƯNG"
                         },
                         {
                           "maTruong": "79784Z05",
                           "tenTruong": "LMG NGÀY MỚI"
                         },
                         {
                           "maTruong": "79784WC6",
                           "tenTruong": "LMG NGÔI NHÀ HẠNH PHÚC"
                         },
                         {
                           "maTruong": "79784W85",
                           "tenTruong": "LMG NGÔI SAO NHÍ"
                         },
                         {
                           "maTruong": "79784Y14",
                           "tenTruong": "LMG NHẬT LINH"
                         },
                         {
                           "maTruong": "79784Y11",
                           "tenTruong": "LMG PHÙ ĐỔNG"
                         },
                         {
                           "maTruong": "79784Y18",
                           "tenTruong": "LMG PHÙ ĐỔNG VIỆT"
                         },
                         {
                           "maTruong": "79784P16",
                           "tenTruong": "LMG PHÚC THỊNH"
                         },
                         {
                           "maTruong": "79784W83",
                           "tenTruong": "LMG PHƯƠNG ANH"
                         },
                         {
                           "maTruong": "797840Y06",
                           "tenTruong": "LMG PHƯƠNG ANH 2"
                         },
                         {
                           "maTruong": "79784WC7",
                           "tenTruong": "LMG PHƯỢNG ĐỎ"
                         },
                         {
                           "maTruong": "79784W15",
                           "tenTruong": "LMG PHƯƠNG DUYÊN"
                         },
                         {
                           "maTruong": "79784W54",
                           "tenTruong": "LMG PHƯƠNG LAN 2"
                         },
                         {
                           "maTruong": "79784WC8",
                           "tenTruong": "LMG PHƯƠNG NAM"
                         },
                         {
                           "maTruong": "79784W91",
                           "tenTruong": "LMG PHƯƠNG QUỲNH"
                         },
                         {
                           "maTruong": "79784Z10",
                           "tenTruong": "LMG QUỲNH LAN"
                         },
                         {
                           "maTruong": "79784Z14",
                           "tenTruong": "LMG QUỲNH TRÂM"
                         },
                         {
                           "maTruong": "79784W69",
                           "tenTruong": "LMG RẠNG ĐÔNG 2"
                         },
                         {
                           "maTruong": "79784Y25",
                           "tenTruong": "LMG SẮC MÀU"
                         },
                         {
                           "maTruong": "79784WC10",
                           "tenTruong": "LMG SẮC MÀU TRẺ THƠ"
                         },
                         {
                           "maTruong": "79784Y03",
                           "tenTruong": "LMG SAO SÁNG"
                         },
                         {
                           "maTruong": "79784P06",
                           "tenTruong": "LMG SAO VIỆT"
                         },
                         {
                           "maTruong": "79784WB6",
                           "tenTruong": "LMG SEN HỒNG"
                         },
                         {
                           "maTruong": "79784y09",
                           "tenTruong": "LMG SÓC NÂU"
                         },
                         {
                           "maTruong": "79784Z04",
                           "tenTruong": "LMG SÓC NÂU VÀNG"
                         },
                         {
                           "maTruong": "79784O20",
                           "tenTruong": "LMG SƠN CA 2"
                         },
                         {
                           "maTruong": "79784W18",
                           "tenTruong": "LMG SONG MAI"
                         },
                         {
                           "maTruong": "79784WA0",
                           "tenTruong": "LMG SU SU"
                         },
                         {
                           "maTruong": "79784O07",
                           "tenTruong": "LMG TÂM ĐỨC"
                         },
                         {
                           "maTruong": "79784O02",
                           "tenTruong": "LMG TÂN MINH"
                         },
                         {
                           "maTruong": "79784WB3",
                           "tenTruong": "LMG TÂN MỸ"
                         },
                         {
                           "maTruong": "79784O22",
                           "tenTruong": "LMG THẦN ĐỒNG"
                         },
                         {
                           "maTruong": "79784WC11",
                           "tenTruong": "LMG THẦN ĐỒNG ĐẤT VIỆT"
                         },
                         {
                           "maTruong": "79784Z02",
                           "tenTruong": "LMG THẦN MẶT TRỜI"
                         },
                         {
                           "maTruong": "79784WB2",
                           "tenTruong": "LMG THÀNH PHÚC"
                         },
                         {
                           "maTruong": "79784Z08",
                           "tenTruong": "LMG THẢO MI"
                         },
                         {
                           "maTruong": "79784W01",
                           "tenTruong": "LMG THẢO NGUYÊN 1"
                         },
                         {
                           "maTruong": "79784X03",
                           "tenTruong": "LMG THẢO NGUYÊN 2"
                         },
                         {
                           "maTruong": "79784W49",
                           "tenTruong": "LMG THIÊN ÂN"
                         },
                         {
                           "maTruong": "79784P10",
                           "tenTruong": "LMG THIÊN HƯƠNG"
                         },
                         {
                           "maTruong": "79784W84",
                           "tenTruong": "LMG THIÊN PHÚ"
                         },
                         {
                           "maTruong": "79784Z12",
                           "tenTruong": "LMG THIÊN PHÚ 2"
                         },
                         {
                           "maTruong": "79784W55",
                           "tenTruong": "LMG THIÊN PHÚC"
                         },
                         {
                           "maTruong": "79784W78",
                           "tenTruong": "LMG THIÊN THẦN"
                         },
                         {
                           "maTruong": "79784WC12",
                           "tenTruong": "LMG THIÊN THẦN VIỆT 2"
                         },
                         {
                           "maTruong": "79784O03",
                           "tenTruong": "LMG THIÊN XUÂN"
                         },
                         {
                           "maTruong": "79784Z06",
                           "tenTruong": "LMG THỎ BÔNG"
                         },
                         {
                           "maTruong": "79784WD5",
                           "tenTruong": "LMG THỎ HỒNG"
                         },
                         {
                           "maTruong": "79784W27",
                           "tenTruong": "LMG THỎ NGỌC"
                         },
                         {
                           "maTruong": "79784WD1",
                           "tenTruong": "LMG THỎ TRẮNG"
                         },
                         {
                           "maTruong": "79784WA7",
                           "tenTruong": "LMG THÚY QUỲNH"
                         },
                         {
                           "maTruong": "79784P13",
                           "tenTruong": "LMG THỦY TIÊN"
                         },
                         {
                           "maTruong": "79784WC13",
                           "tenTruong": "LMG TOÀN THẮNG"
                         },
                         {
                           "maTruong": "79784O27",
                           "tenTruong": "LMG TRÂM ANH"
                         },
                         {
                           "maTruong": "79784W47",
                           "tenTruong": "LMG TRÍ ĐỨC"
                         },
                         {
                           "maTruong": "79784O23",
                           "tenTruong": "LMG TRÚC GIANG"
                         },
                         {
                           "maTruong": "79784WA6",
                           "tenTruong": "LMG TRUNG CHÁNH"
                         },
                         {
                           "maTruong": "79784WD4",
                           "tenTruong": "LMG TRUNG HÒA"
                         },
                         {
                           "maTruong": "79784W59",
                           "tenTruong": "LMG TUỔI HỒNG"
                         },
                         {
                           "maTruong": "79784W06",
                           "tenTruong": "LMG TUỔI NGỌC 2"
                         },
                         {
                           "maTruong": "79784W11",
                           "tenTruong": "LMG TUỔI NGỌC 3"
                         },
                         {
                           "maTruong": "79784O04",
                           "tenTruong": "LMG TUỔI THẦN TIÊN"
                         },
                         {
                           "maTruong": "79784WD9",
                           "tenTruong": "LMG TUỔI THƠ"
                         },
                         {
                           "maTruong": "79784W41",
                           "tenTruong": "LMG TƯƠNG LAI 2"
                         },
                         {
                           "maTruong": "79784Y20",
                           "tenTruong": "LMG TƯỜNG LAM"
                         },
                         {
                           "maTruong": "79784WD2",
                           "tenTruong": "LMG ƯỚC MƠ"
                         },
                         {
                           "maTruong": "79784WC14",
                           "tenTruong": "LMG ƯỚC MƠ CỦA BÉ"
                         },
                         {
                           "maTruong": "79784W50",
                           "tenTruong": "LMG VÂN AN"
                         },
                         {
                           "maTruong": "79784W08",
                           "tenTruong": "LMG VÀNG ANH"
                         },
                         {
                           "maTruong": "79784W89",
                           "tenTruong": "LMG VÀNH KHUYÊN 2"
                         },
                         {
                           "maTruong": "79784P12",
                           "tenTruong": "LMG VIỆT ĐỨC"
                         },
                         {
                           "maTruong": "79784WD11",
                           "tenTruong": "LMG VUI ĐẾN TRƯỜNG"
                         },
                         {
                           "maTruong": "79784P09",
                           "tenTruong": "LMG VƯỜN HỌA MI"
                         },
                         {
                           "maTruong": "79784WB8",
                           "tenTruong": "LMG VƯỜN TRẺ THƠ"
                         },
                         {
                           "maTruong": "79784Y22",
                           "tenTruong": "LMG VƯỜN TRẺ THƠ 2"
                         },
                         {
                           "maTruong": "79784O28",
                           "tenTruong": "LMG YẾN NHI"
                         },
                         {
                           "maTruong": "79784P02",
                           "tenTruong": "NT ANH ĐỨC"
                         },
                         {
                           "maTruong": "79784W42",
                           "tenTruong": "NT ÁNH TUYẾT"
                         },
                         {
                           "maTruong": "79784WA3",
                           "tenTruong": "NT ANH VIỆT"
                         },
                         {
                           "maTruong": "79784W51",
                           "tenTruong": "NT BABY"
                         },
                         {
                           "maTruong": "79784Y29",
                           "tenTruong": "NT BẢO TRÂM"
                         },
                         {
                           "maTruong": "79784W71",
                           "tenTruong": "NT BI BO"
                         },
                         {
                           "maTruong": "79784W63",
                           "tenTruong": "NT ĐỒ RÊ Mí"
                         },
                         {
                           "maTruong": "79784Y07",
                           "tenTruong": "NT ĐỒNG DAO"
                         },
                         {
                           "maTruong": "79784Y12",
                           "tenTruong": "NT HIỀN NHÂN"
                         },
                         {
                           "maTruong": "79784Z13",
                           "tenTruong": "NT HOA ÁNH DƯƠNG"
                         },
                         {
                           "maTruong": "79784WC15",
                           "tenTruong": "NT HOA SEN VIỆT"
                         },
                         {
                           "maTruong": "79784W61",
                           "tenTruong": "NT HOÀNG OANH"
                         },
                         {
                           "maTruong": "79784WD6",
                           "tenTruong": "NT KHẢI MINH"
                         },
                         {
                           "maTruong": "79784O05",
                           "tenTruong": "NT KHAI TÂM"
                         },
                         {
                           "maTruong": "79784Y24",
                           "tenTruong": "NT LAM HỒNG"
                         },
                         {
                           "maTruong": "79784W60",
                           "tenTruong": "NT MAI ANH"
                         },
                         {
                           "maTruong": "79784O08",
                           "tenTruong": "NT MAI HOA"
                         },
                         {
                           "maTruong": "79784W38",
                           "tenTruong": "NT MINH KHÔI"
                         },
                         {
                           "maTruong": "79784W80",
                           "tenTruong": "NT MINH NGỌC"
                         },
                         {
                           "maTruong": "79784Y02",
                           "tenTruong": "NT NẮNG BAN MAI"
                         },
                         {
                           "maTruong": "79784W07",
                           "tenTruong": "NT PHƯỢNG HOÀNG"
                         },
                         {
                           "maTruong": "79784P04",
                           "tenTruong": "NT THẦN ĐỒNG VIỆT"
                         },
                         {
                           "maTruong": "79784W72",
                           "tenTruong": "NT THANH BÌNH"
                         },
                         {
                           "maTruong": "79784W96",
                           "tenTruong": "NT THÀNH HUY"
                         },
                         {
                           "maTruong": "79784Z09",
                           "tenTruong": "NT THANH YẾN"
                         },
                         {
                           "maTruong": "79784Z01",
                           "tenTruong": "NT THẢO VÂN"
                         },
                         {
                           "maTruong": "79784Y34",
                           "tenTruong": "NT TÍ HON"
                         },
                         {
                           "maTruong": "79784O13",
                           "tenTruong": "NT YẾN YẾN"
                         },
                         {
                           "maTruong": "79784xtd1",
                           "tenTruong": "LMG THANH NGÂN"
                         },
                         {
                           "maTruong": "79784xtd2",
                           "tenTruong": "LMG HƯƠNG SEN"
                         },
                         {
                           "maTruong": "79784ttr1",
                           "tenTruong": "LMG ANH ĐÀO"
                         },
                         {
                           "maTruong": "79784ttr2",
                           "tenTruong": "LMG THẦN ĐỒNG VIỆT"
                         },
                         {
                           "maTruong": "7978dth1",
                           "tenTruong": "LMG NẮNG XUÂN"
                         },
                         {
                           "maTruong": "79784ttr3",
                           "tenTruong": "LMG TƯ DUY"
                         },
                         {
                           "maTruong": "79784dt1",
                           "tenTruong": "LMG Ngôi Nhà Tuổi Thơ"
                         },
                         {
                           "maTruong": "79784dt2",
                           "tenTruong": "LMG Gia Hân"
                         },
                         {
                           "maTruong": "79784dt3",
                           "tenTruong": "LMG Bồ Câu"
                         },
                         {
                           "maTruong": "79784dt4",
                           "tenTruong": "LMG PHƯƠNG MAI"
                         },
                         {
                           "maTruong": "79784we1",
                           "tenTruong": "LMG TRẠNG NGUYÊN"
                         },
                         {
                           "maTruong": "79784ww1",
                           "tenTruong": "LMG PHÚC ANH"
                         },
                         {
                           "maTruong": "79784txu1",
                           "tenTruong": "LMG ĐẠT GIA"
                         },
                         {
                           "maTruong": "79784ttn1",
                           "tenTruong": "LMG DÂU TÂY"
                         },
                         {
                           "maTruong": "79784ttt1",
                           "tenTruong": "NT VIỆT HOA"
                         },
                         {
                           "maTruong": "79784ww2",
                           "tenTruong": "LMG MINH HẰNG"
                         },
                         {
                           "maTruong": "79784ww3",
                           "tenTruong": "LMG MẶT TRỜI ĐỎ"
                         },
                         {
                           "maTruong": "79784ww4",
                           "tenTruong": "LMG THIÊN NGỌC"
                         },
                         {
                           "maTruong": "79784WG5",
                           "tenTruong": "LMG ANH KHOA"
                         },
                         {
                           "maTruong": "79784WG9",
                           "tenTruong": "LMG TUỆ ANH"
                         },
                         {
                           "maTruong": "79784WG10",
                           "tenTruong": "LMG BÉ XINH"
                         },
                         {
                           "maTruong": "79784WG13",
                           "tenTruong": "LMG BÉ THÔNG MINH"
                         },
                         {
                           "maTruong": "79784WG14",
                           "tenTruong": "LMG NHẬT MỸ"
                         },
                         {
                           "maTruong": "79784WG15",
                           "tenTruong": "LMG VƯỜN TRẺ THƠ 3"
                         },
                         {
                           "maTruong": "79784WG16",
                           "tenTruong": "LMG NỤ CƯỜI VIỆT"
                         },
                         {
                           "maTruong": "79784thi1",
                           "tenTruong": "LMG HOA MAI VÀNG"
                         },
                         {
                           "maTruong": "79784trc1",
                           "tenTruong": "LMG ĐỨC THIỆN"
                         },
                         {
                           "maTruong": "79784trc2",
                           "tenTruong": "LMG LAN NHI"
                         },
                         {
                           "maTruong": "79784trc3",
                           "tenTruong": "LMG QUỲNH NHƯ"
                         },
                         {
                           "maTruong": "79784WE14",
                           "tenTruong": "LMG Mặt Trời Hồng"
                         },
                         {
                           "maTruong": "79784421",
                           "tenTruong": "TH Nhị Xuân"
                         },
                         {
                           "maTruong": "79784WH01",
                           "tenTruong": "LMG NHÂN NGHĨA"
                         },
                         {
                           "maTruong": "79784WH02",
                           "tenTruong": "LMG BÔNG MAI"
                         },
                         {
                           "maTruong": "79784WH05",
                           "tenTruong": "LMG VIỆT ĐỨC 2"
                         },
                         {
                           "maTruong": "79784WH06",
                           "tenTruong": "LMG HOA THIÊN"
                         },
                         {
                           "maTruong": "79784WH07",
                           "tenTruong": "LMG MINH ÂN"
                         },
                         {
                           "maTruong": "79784326",
                           "tenTruong": "MN XUÂN THỚI THƯỢNG"
                         },
                         {
                           "maTruong": "79784514",
                           "tenTruong": "THCS NGUYỄN VĂN BỨA"
                         },
                         {
                           "maTruong": "79784WK1",
                           "tenTruong": "LMG TRẺ TRÍ TUỆ"
                         },
                         {
                           "maTruong": "79784WK2",
                           "tenTruong": "LMG THÀNH NHÂN"
                         },
                         {
                           "maTruong": "79784WK3",
                           "tenTruong": "LMG ÁNH BAN MAI"
                         },
                         {
                           "maTruong": "79784WK4",
                           "tenTruong": "NT MINH NHỰT"
                         },
                         {
                           "maTruong": "79784WK5",
                           "tenTruong": "LMG BÚP SEN HỒNG"
                         },
                         {
                           "maTruong": "79784WK6",
                           "tenTruong": "LMG HOÀNG ANH"
                         },
                         {
                           "maTruong": "79784WK7",
                           "tenTruong": "LMG NHÀ BÍ NGÔ"
                         },
                         {
                           "maTruong": "79784WK8",
                           "tenTruong": "LMG SẮC MÀU 1"
                         },
                         {
                           "maTruong": "79784WK9",
                           "tenTruong": "LMG THIÊN PHÚC 2"
                         },
                         {
                           "maTruong": "79784WK11",
                           "tenTruong": "LMG HƯƠNG THIÊN LÝ"
                         },
                         {
                           "maTruong": "79784WK12",
                           "tenTruong": "LMG TÀI ĐỨC"
                         },
                         {
                           "maTruong": "79784WK13",
                           "tenTruong": "LMG TÂM SAO VIỆT"
                         },
                         {
                           "maTruong": "79784WK14",
                           "tenTruong": "LMG HƯƠNG THIÊN LÝ 2"
                         },
                         {
                           "maTruong": "79784WK10",
                           "tenTruong": "LMG THIÊN AN"
                         },
                         {
                           "maTruong": "79784WJ01",
                           "tenTruong": "LMG TÂM TRÍ"
                         },
                         {
                           "maTruong": "79784WJ02",
                           "tenTruong": "LMG MẶT TRỜI BÉ THƠ"
                         },
                         {
                           "maTruong": "79784WJ03",
                           "tenTruong": "LMG THỚI TỨ"
                         },
                         {
                           "maTruong": "79784WJ04",
                           "tenTruong": "LMG NGÔI NHÀ NHỎ"
                         },
                         {
                           "maTruong": "79784WJ05",
                           "tenTruong": "NT MINH QUANG"
                         },
                         {
                           "maTruong": "'79784WJ06",
                           "tenTruong": "LMG THẢO NGUYÊN 1"
                         },
                         {
                           "maTruong": "79784WJ08",
                           "tenTruong": "LMG VƯỜN TRẺ THƠ 1"
                         },
                         {
                           "maTruong": "79784WJ09",
                           "tenTruong": "LMG SONG PHƯƠNG"
                         },
                         {
                           "maTruong": "79784WK15",
                           "tenTruong": "LMG TRÚC XANH"
                         },
                         {
                           "maTruong": "79784094",
                           "tenTruong": "MN MỸ HÒA"
                         },
                         {
                           "maTruong": "79784515",
                           "tenTruong": "THCS ĐẶNG THÚC VỊNH"
                         },
                         {
                           "maTruong": "79784001",
                           "tenTruong": "LMG KHÁNH PHƯỢNG"
                         },
                         {
                           "maTruong": "79784018",
                           "tenTruong": "LMG HOA VIỆT"
                         },
                         {
                           "maTruong": "79784235",
                           "tenTruong": "LMG THIÊN THẦN 1"
                         },
                         {
                           "maTruong": "79784099",
                           "tenTruong": "LMG BÉ HẢO"
                         },
                         {
                           "maTruong": "79784083",
                           "tenTruong": "NT HẠNH PHÚC"
                         },
                         {
                           "maTruong": "79784254",
                           "tenTruong": "LMG HOA HỒNG NHỎ 3"
                         },
                         {
                           "maTruong": "79784105",
                           "tenTruong": "LMG TUỔI HỒNG"
                         },
                         {
                           "maTruong": "7978401002",
                           "tenTruong": "LMG VƯƠNG QUỐC TRẺ THƠ"
                         },
                         {
                           "maTruong": "7978401003",
                           "tenTruong": "LMG HOÀNG OANH 1"
                         },
                         {
                           "maTruong": "7978401004",
                           "tenTruong": "LMG ÁNH HỒNG 3"
                         },
                         {
                           "maTruong": "7978401005",
                           "tenTruong": "NT ANH KHÔI 2"
                         },
                         {
                           "maTruong": "7978401006",
                           "tenTruong": "LMG BÚP MĂNG NON"
                         },
                         {
                           "maTruong": "7978401001",
                           "tenTruong": "LMG TIẾN ĐẠT"
                         },
                         {
                           "maTruong": "7978403001",
                           "tenTruong": "THCS BÙi VĂN THỦ"
                         },
                         {
                           "maTruong": "7978401007",
                           "tenTruong": "LMG BIN BIN"
                         },
                         {
                           "maTruong": "7978401009",
                           "tenTruong": "LMN THIÊN Ý"
                         },
                         {
                           "maTruong": "7978401010",
                           "tenTruong": "LMG AN THỊNH"
                         },
                         {
                           "maTruong": "7978401011",
                           "tenTruong": "LMG TRIỆU ÁNH DƯƠNG"
                         },
                         {
                           "maTruong": "7978401012",
                           "tenTruong": "LMG TÀI ĐỨC 1"
                         },
                         {
                           "maTruong": "7978401013",
                           "tenTruong": "LMN SONG MAI 2"
                         },
                         {
                           "maTruong": "7978401014",
                           "tenTruong": "NT VƯƠNG QUỐC TÍ HON"
                         },
                         {
                           "maTruong": "7978401015",
                           "tenTruong": "LMG HẠNH PHÚC TUỔI THƠ"
                         },
                         {
                           "maTruong": "79784WJ06",
                           "tenTruong": "LMG THẢO NGUYÊN 1"
                         },
                         {
                           "maTruong": "7978401028",
                           "tenTruong": "LMG BÚP MĂNG NON"
                         },
                         {
                           "maTruong": "7978401029",
                           "tenTruong": "LMG VIỆT ĐỨC 2"
                         },
                         {
                           "maTruong": "7978401016",
                           "tenTruong": "LMG HOÀNG NGÂN"
                         },
                         {
                           "maTruong": "7978401017",
                           "tenTruong": "LMN NHÀ XANH"
                         },
                         {
                           "maTruong": "7978401018",
                           "tenTruong": "LMG NGÔI NHÀ TRẺ THƠ"
                         },
                         {
                           "maTruong": "7978401019",
                           "tenTruong": "LMG NGỌC NGÀ"
                         },
                         {
                           "maTruong": "7978401020",
                           "tenTruong": "LMG HOA CỎ MAY"
                         },
                         {
                           "maTruong": "7978401021",
                           "tenTruong": "NT HOÀNG GIA"
                         },
                         {
                           "maTruong": "7978401022",
                           "tenTruong": "NT TUỔI NGỌC 1"
                         },
                         {
                           "maTruong": "7978401023",
                           "tenTruong": "LMG TUỔI NGỌC 3"
                         },
                         {
                           "maTruong": "7978401024",
                           "tenTruong": "NT THẾ GIỚI TRẺ THƠ"
                         },
                         {
                           "maTruong": "7978401025",
                           "tenTruong": "LMG THẾ GIỚI ƯỚC MƠ"
                         },
                         {
                           "maTruong": "7978401026",
                           "tenTruong": "LMG NAM HƯNG 1"
                         },
                         {
                           "maTruong": "7978401027",
                           "tenTruong": "LMN THIÊN XUÂN 1"
                         },
                         {
                           "maTruong": "7978401035",
                           "tenTruong": "NT HẠNH PHÚC"
                         },
                         {
                           "maTruong": "7978401030",
                           "tenTruong": "LMG NGÔI NHÀ ÁNH SÁNG"
                         },
                         {
                           "maTruong": "7978401031",
                           "tenTruong": "LMN HOÀNG PHÚC"
                         },
                         {
                           "maTruong": "7978401032",
                           "tenTruong": "LMG NGÔI NHÀ NHỎ"
                         },
                         {
                           "maTruong": "7978401033",
                           "tenTruong": "LMG HẠT ĐẬU NHỎ"
                         },
                         {
                           "maTruong": "7978401034",
                           "tenTruong": "LMN HỒNG NHUNG"
                         },
                         {
                           "maTruong": "7978401048",
                           "tenTruong": "LMN AN"
                         },
                         {
                           "maTruong": "7978401049",
                           "tenTruong": "LMG NGÔI SAO"
                         },
                         {
                           "maTruong": "7978401050",
                           "tenTruong": "LMG NGÔI NHÀ VIỆT HOÀNG"
                         },
                         {
                           "maTruong": "7978401051",
                           "tenTruong": "LMN BÔNG SEN XANH"
                         },
                         {
                           "maTruong": "7978401052",
                           "tenTruong": "LMN VIỆT ÂU"
                         },
                         {
                           "maTruong": "7978401053",
                           "tenTruong": "NT THẾ GIỚI DIỆU KỲ 1"
                         },
                         {
                           "maTruong": "7978401054",
                           "tenTruong": "LMG THẾ GIỚI DIỆU KỲ 1"
                         },
                         {
                           "maTruong": "7978401055",
                           "tenTruong": "LMG THẾ GIỚI DIỆU KỲ 2"
                         },
                         {
                           "maTruong": "7978401037",
                           "tenTruong": "LMG CẦU VỒNG TUỔI THƠ"
                         },
                         {
                           "maTruong": "7978401038",
                           "tenTruong": "LMN KHU VƯỜN TRẺ THƠ"
                         },
                         {
                           "maTruong": "7978401039",
                           "tenTruong": "NT ANH KHÔI 2"
                         },
                         {
                           "maTruong": "7978401040",
                           "tenTruong": "LMN DẤU CHÂN TUỔI THƠ"
                         },
                         {
                           "maTruong": "7978401041",
                           "tenTruong": "LMN LAM HỒNG 2"
                         },
                         {
                           "maTruong": "7978401042",
                           "tenTruong": "LMG ÁNH DƯƠNG"
                         },
                         {
                           "maTruong": "7978401043",
                           "tenTruong": "LMN SẮC MÀU 2"
                         },
                         {
                           "maTruong": "7978401044",
                           "tenTruong": "LMG THỎ NGỌC 2"
                         },
                         {
                           "maTruong": "7978401045",
                           "tenTruong": "LMN SAO SÁNG 2"
                         },
                         {
                           "maTruong": "7978401046",
                           "tenTruong": "LMG CÁNH DIỀU VÀNG"
                         },
                         {
                           "maTruong": "7978401036",
                           "tenTruong": "MN CÚC HỌA MI"
                         }
                       ]
                     },
                     {
                       "maPhong": "763",
                       "tenPhong": "Quận 9",
                       "truongData": [
                         {
                           "maTruong": "79763414",
                           "tenTruong": "TH Bùi Văn Mới"
                         },
                         {
                           "maTruong": "79763404",
                           "tenTruong": "TH Đinh Tiên Hoàng"
                         },
                         {
                           "maTruong": "79763408",
                           "tenTruong": "TH Hiệp Phú"
                         },
                         {
                           "maTruong": "79763419",
                           "tenTruong": "TH Lê Văn Việt"
                         },
                         {
                           "maTruong": "79763401",
                           "tenTruong": "TH Long Bình"
                         },
                         {
                           "maTruong": "79763413",
                           "tenTruong": "TH Long Phước"
                         },
                         {
                           "maTruong": "79763407",
                           "tenTruong": "TH Long Thạnh Mỹ"
                         },
                         {
                           "maTruong": "79763409",
                           "tenTruong": "TH Nguyễn Minh Quang"
                         },
                         {
                           "maTruong": "79763420",
                           "tenTruong": "TH Nguyễn Văn Bá"
                         },
                         {
                           "maTruong": "79763405",
                           "tenTruong": "TH Phú Hữu"
                         },
                         {
                           "maTruong": "79763402",
                           "tenTruong": "TH Phước Bình"
                         },
                         {
                           "maTruong": "79763403",
                           "tenTruong": "TH Tạ Uyên"
                         },
                         {
                           "maTruong": "79763417",
                           "tenTruong": "TH Tân Phú"
                         },
                         {
                           "maTruong": "79763415",
                           "tenTruong": "TH Trường Thạnh"
                         },
                         {
                           "maTruong": "79763411",
                           "tenTruong": "TH Trương Văn Thành"
                         },
                         {
                           "maTruong": "79763406",
                           "tenTruong": "TH Võ Văn Hát"
                         },
                         {
                           "maTruong": "79763410",
                           "tenTruong": "Tiểu học  Phước Thạnh"
                         },
                         {
                           "maTruong": "79763412",
                           "tenTruong": "Tiểu Học Phong Phú"
                         },
                         {
                           "maTruong": "79763507",
                           "tenTruong": "THCS Đặng Tấn Tài"
                         },
                         {
                           "maTruong": "79763503",
                           "tenTruong": "THCS Hoa Lư"
                         },
                         {
                           "maTruong": "79763502",
                           "tenTruong": "THCS Hưng Bình"
                         },
                         {
                           "maTruong": "79763511",
                           "tenTruong": "THCS Long Bình"
                         },
                         {
                           "maTruong": "79763506",
                           "tenTruong": "THCS Long Phước"
                         },
                         {
                           "maTruong": "79763505",
                           "tenTruong": "THCS Long Trường"
                         },
                         {
                           "maTruong": "79763508",
                           "tenTruong": "THCS Phú Hữu"
                         },
                         {
                           "maTruong": "79763501",
                           "tenTruong": "THCS Phước Bình"
                         },
                         {
                           "maTruong": "79763509",
                           "tenTruong": "THCS Tân Phú"
                         },
                         {
                           "maTruong": "79763512",
                           "tenTruong": "THCS Tăng Nhơn Phú B"
                         },
                         {
                           "maTruong": "79763504",
                           "tenTruong": "THCS Trần Quốc Toản"
                         },
                         {
                           "maTruong": "79763510",
                           "tenTruong": "THCS Trường Thạnh"
                         },
                         {
                           "maTruong": "79000873",
                           "tenTruong": "THCS - THPT Hoa Sen"
                         },
                         {
                           "maTruong": "79000813",
                           "tenTruong": "TH - THCS - THPT Ngô Thời Nhiệm"
                         },
                         {
                           "maTruong": "79763205",
                           "tenTruong": "MGTT Mai Hoa"
                         },
                         {
                           "maTruong": "79763206",
                           "tenTruong": "MGTT Sao Mai"
                         },
                         {
                           "maTruong": "79763303",
                           "tenTruong": "Mầm non Hiệp Phú"
                         },
                         {
                           "maTruong": "79763337",
                           "tenTruong": "MNTT Mẹ Yêu Con"
                         },
                         {
                           "maTruong": "79763X04",
                           "tenTruong": "MNTT Ban Mai"
                         },
                         {
                           "maTruong": "79763317",
                           "tenTruong": "MNTT Bình Minh"
                         },
                         {
                           "maTruong": "79763331",
                           "tenTruong": "MNTT Cậu Bé Gỗ"
                         },
                         {
                           "maTruong": "79763338",
                           "tenTruong": "MNTT Con Mèo Vàng"
                         },
                         {
                           "maTruong": "79763339",
                           "tenTruong": "MN Công Nghệ Cao"
                         },
                         {
                           "maTruong": "79763316",
                           "tenTruong": "MNTT Hoa Hồng Đỏ"
                         },
                         {
                           "maTruong": "79763312",
                           "tenTruong": "MN Hoa Lan"
                         },
                         {
                           "maTruong": "79763321",
                           "tenTruong": "MNTT Hoa Mai"
                         },
                         {
                           "maTruong": "79763305",
                           "tenTruong": "MN Hoa Sen"
                         },
                         {
                           "maTruong": "79763333",
                           "tenTruong": "MNTT Hoa Thương"
                         },
                         {
                           "maTruong": "79763W03",
                           "tenTruong": "MNTT Hoàng Lê"
                         },
                         {
                           "maTruong": "79763308",
                           "tenTruong": "MN Hoàng Yến"
                         },
                         {
                           "maTruong": "79763319",
                           "tenTruong": "MNTT Kiều Đàm"
                         },
                         {
                           "maTruong": "79763301",
                           "tenTruong": "MN Long Bình"
                         },
                         {
                           "maTruong": "79763202",
                           "tenTruong": "MN Long Phước"
                         },
                         {
                           "maTruong": "79763336",
                           "tenTruong": "MN Long Sơn"
                         },
                         {
                           "maTruong": "79763340",
                           "tenTruong": "MN Long Thạnh Mỹ"
                         },
                         {
                           "maTruong": "79763313",
                           "tenTruong": "MN Long Trường"
                         },
                         {
                           "maTruong": "79763W02",
                           "tenTruong": "MNTT Nai Vàng"
                         },
                         {
                           "maTruong": "79763324",
                           "tenTruong": "MNTT Ngô Thời Nhiệm"
                         },
                         {
                           "maTruong": "79763309",
                           "tenTruong": "MN Phong Phú"
                         },
                         {
                           "maTruong": "79763304",
                           "tenTruong": "MN Phước Bình"
                         },
                         {
                           "maTruong": "79763315",
                           "tenTruong": "MNTT Rồng Vàng"
                         },
                         {
                           "maTruong": "79763318",
                           "tenTruong": "MNTT Sóc Nâu"
                         },
                         {
                           "maTruong": "79763307",
                           "tenTruong": "MN Sơn Ca"
                         },
                         {
                           "maTruong": "79763335",
                           "tenTruong": "MN Tạ Uyên"
                         },
                         {
                           "maTruong": "79763302",
                           "tenTruong": "MN Tân Phú"
                         },
                         {
                           "maTruong": "79763310",
                           "tenTruong": "MNTT Thanh Lịch"
                         },
                         {
                           "maTruong": "79763332",
                           "tenTruong": "MNTT Thiện Mỹ"
                         },
                         {
                           "maTruong": "79763320",
                           "tenTruong": "MNTT Thỏ Ngọc"
                         },
                         {
                           "maTruong": "79763314",
                           "tenTruong": "MN Trường Thạnh"
                         },
                         {
                           "maTruong": "79763326",
                           "tenTruong": "MN Tuổi Hoa"
                         },
                         {
                           "maTruong": "79763322",
                           "tenTruong": "MN Tuổi Hồng"
                         },
                         {
                           "maTruong": "79763323",
                           "tenTruong": "MN Tuổi Ngọc"
                         },
                         {
                           "maTruong": "79763203",
                           "tenTruong": "MN Tuổi Thơ"
                         },
                         {
                           "maTruong": "79763306",
                           "tenTruong": "MN Vàng Anh"
                         },
                         {
                           "maTruong": "79763O03",
                           "tenTruong": "MNTT Việt Duy"
                         },
                         {
                           "maTruong": "79763345",
                           "tenTruong": "MNTT Á Châu"
                         },
                         {
                           "maTruong": "79763341",
                           "tenTruong": "MNTT ABC"
                         },
                         {
                           "maTruong": "79763344",
                           "tenTruong": "MNTT Hoa Trà My"
                         },
                         {
                           "maTruong": "79763343",
                           "tenTruong": "MNTT Miền Trẻ Thơ"
                         },
                         {
                           "maTruong": "79763346",
                           "tenTruong": "MNTT Ngôi Sao"
                         },
                         {
                           "maTruong": "79763334",
                           "tenTruong": "MNTT Sao Sáng"
                         },
                         {
                           "maTruong": "79763342",
                           "tenTruong": "MNTT Thăng Long A"
                         },
                         {
                           "maTruong": "79763L58",
                           "tenTruong": "NT Á Châu LTM"
                         },
                         {
                           "maTruong": "79763W41",
                           "tenTruong": "NT ABC"
                         },
                         {
                           "maTruong": "79763O13",
                           "tenTruong": "Lớp mẫu giáo Ánh Bình Minh"
                         },
                         {
                           "maTruong": "79763L45",
                           "tenTruong": "Lớp MG Ánh Sao (LB)"
                         },
                         {
                           "maTruong": "79763W44",
                           "tenTruong": "LỚP MG Ánh Sao 2"
                         },
                         {
                           "maTruong": "79763W48",
                           "tenTruong": "NT Anh Việt"
                         },
                         {
                           "maTruong": "79763L56",
                           "tenTruong": "Lớp MG Ban Mai Xanh"
                         },
                         {
                           "maTruong": "79763W22",
                           "tenTruong": "Lớp MG Bảo Ngọc"
                         },
                         {
                           "maTruong": "79763W21",
                           "tenTruong": "Lớp MG Bé Ngoan"
                         },
                         {
                           "maTruong": "79763W53",
                           "tenTruong": "NT Bình Minh"
                         },
                         {
                           "maTruong": "79763L43",
                           "tenTruong": "Lớp MG Bồ Câu Trắng"
                         },
                         {
                           "maTruong": "79763W07",
                           "tenTruong": "Lớp MG Búp bê xinh"
                         },
                         {
                           "maTruong": "79763W09",
                           "tenTruong": "Lớp MG Búp Măng Non"
                         },
                         {
                           "maTruong": "79763L44",
                           "tenTruong": "Lớp MG Cầu Vồng Tuổi Thơ"
                         },
                         {
                           "maTruong": "79763W45",
                           "tenTruong": "Lớp MG Cô và Bé"
                         },
                         {
                           "maTruong": "79763W14",
                           "tenTruong": "Lớp MG Đô Rê Mi"
                         },
                         {
                           "maTruong": "79763L59",
                           "tenTruong": "Lớp MG Gấu Anh"
                         },
                         {
                           "maTruong": "79763W15",
                           "tenTruong": "Lớp MG Gấu MiSa"
                         },
                         {
                           "maTruong": "79763W06",
                           "tenTruong": "Lớp MG Gia Hân"
                         },
                         {
                           "maTruong": "79763W32",
                           "tenTruong": "Lớp MG Hoa Anh Đào"
                         },
                         {
                           "maTruong": "79763L57",
                           "tenTruong": "Lớp MG Hoa Huệ Trắng"
                         },
                         {
                           "maTruong": "79763W40",
                           "tenTruong": "Lớp MG Hoa Thiên Phú"
                         },
                         {
                           "maTruong": "79763W50",
                           "tenTruong": "Lớp MG Hoa Trúc"
                         },
                         {
                           "maTruong": "79763L61",
                           "tenTruong": "Lớp MG Hồng Ân"
                         },
                         {
                           "maTruong": "79763X02",
                           "tenTruong": "Lớp MG Kiểu Mẫu"
                         },
                         {
                           "maTruong": "79763W26",
                           "tenTruong": "NT Long Thiện Mỹ"
                         },
                         {
                           "maTruong": "79763O23",
                           "tenTruong": "MNTT Mai Anh"
                         },
                         {
                           "maTruong": "79763W49",
                           "tenTruong": "Lớp MG Mai Anh 2"
                         },
                         {
                           "maTruong": "79763W08",
                           "tenTruong": "Lớp MG Mai ka"
                         },
                         {
                           "maTruong": "79763W51",
                           "tenTruong": "Lớp MG Mai Yến"
                         },
                         {
                           "maTruong": "79763L54",
                           "tenTruong": "Lớp MG Minh Châu"
                         },
                         {
                           "maTruong": "79763W36",
                           "tenTruong": "MNTT Minh Ngọc"
                         },
                         {
                           "maTruong": "79763W34",
                           "tenTruong": "NT Nam Mỹ 2"
                         },
                         {
                           "maTruong": "79763W13",
                           "tenTruong": "Lớp MG Nắng Mai"
                         },
                         {
                           "maTruong": "79763L48",
                           "tenTruong": "Lớp MG Ngôi Sao Mới"
                         },
                         {
                           "maTruong": "79763L42",
                           "tenTruong": "Lớp MG Gấu Panda"
                         },
                         {
                           "maTruong": "79763O19",
                           "tenTruong": "NT Phú Hữu"
                         },
                         {
                           "maTruong": "79763W46",
                           "tenTruong": "Lớp MG Rồng Vàng"
                         },
                         {
                           "maTruong": "79763O16",
                           "tenTruong": "Lớp MG Sao Sáng"
                         },
                         {
                           "maTruong": "79763O14",
                           "tenTruong": "Lớp MG Sen Hồng"
                         },
                         {
                           "maTruong": "79763W47",
                           "tenTruong": "Lớp MG Song Như"
                         },
                         {
                           "maTruong": "79763L49",
                           "tenTruong": "Lớp MG Tâm Đức"
                         },
                         {
                           "maTruong": "79763138",
                           "tenTruong": "Lớp MG Thần Đồng Việt"
                         },
                         {
                           "maTruong": "79763L55",
                           "tenTruong": "Lớp MG Thăng Long"
                         },
                         {
                           "maTruong": "79763O21",
                           "tenTruong": "Lớp MG  Thanh Thảo"
                         },
                         {
                           "maTruong": "79763W35",
                           "tenTruong": "Lớp MG Thảo Nguyên Xanh"
                         },
                         {
                           "maTruong": "79763W33",
                           "tenTruong": "Lớp MG Thiên Ái"
                         },
                         {
                           "maTruong": "79763W05",
                           "tenTruong": "Lớp MG Thiên Ân"
                         },
                         {
                           "maTruong": "79763W17",
                           "tenTruong": "Lớp MG Thiên Thần"
                         },
                         {
                           "maTruong": "79763139",
                           "tenTruong": "Lớp MG Thiên Thần Bé Nhỏ"
                         },
                         {
                           "maTruong": "79763L50",
                           "tenTruong": "NT Thỏ Hồng"
                         },
                         {
                           "maTruong": "79763W43",
                           "tenTruong": "Lớp MG Thỏ Trắng"
                         },
                         {
                           "maTruong": "79763W04",
                           "tenTruong": "Lớp MG Titi"
                         },
                         {
                           "maTruong": "79763W38",
                           "tenTruong": "Lớp MG Trăng Non"
                         },
                         {
                           "maTruong": "79763L51",
                           "tenTruong": "Lớp MG Trúc Xinh"
                         },
                         {
                           "maTruong": "79763O05",
                           "tenTruong": "NT Tuổi Thơ 2"
                         },
                         {
                           "maTruong": "79763W18",
                           "tenTruong": "Lớp MG Xuân Mai"
                         },
                         {
                           "maTruong": "79763V01",
                           "tenTruong": "Lớp MG Yêu Trẻ"
                         },
                         {
                           "maTruong": "79763L66",
                           "tenTruong": "Lớp MG Nam Long"
                         },
                         {
                           "maTruong": "79763L67",
                           "tenTruong": "Lớp MG Nụ Cười Hồng"
                         },
                         {
                           "maTruong": "79763L68",
                           "tenTruong": "Lớp MG Mặt Trời Đỏ"
                         },
                         {
                           "maTruong": "79763W37",
                           "tenTruong": "Lớp MG Ngôi Sao Sáng"
                         },
                         {
                           "maTruong": "79763L63",
                           "tenTruong": "NT Hoa Bưởi"
                         },
                         {
                           "maTruong": "V19032018",
                           "tenTruong": "MG Nụ Cười Trẻ Thơ"
                         },
                         {
                           "maTruong": "V042018",
                           "tenTruong": "Lớp MG Gấu Con"
                         },
                         {
                           "maTruong": "79763L64",
                           "tenTruong": "NT Hoa Thủy Tiên"
                         },
                         {
                           "maTruong": "V16062018",
                           "tenTruong": "Lớp MG Tài Năng Á Châu"
                         },
                         {
                           "maTruong": "V160620181",
                           "tenTruong": "NT 151"
                         },
                         {
                           "maTruong": "V18062018",
                           "tenTruong": "NT 34"
                         },
                         {
                           "maTruong": "V06172018",
                           "tenTruong": "Lớp MG Bảo Minh"
                         },
                         {
                           "maTruong": "V10102018",
                           "tenTruong": "MNTT Thiên Ân Phúc 3"
                         },
                         {
                           "maTruong": "V11102018",
                           "tenTruong": "MNTT Vương Quốc Tí Hon"
                         },
                         {
                           "maTruong": "V12102018",
                           "tenTruong": "MNTT Kim Đồng"
                         },
                         {
                           "maTruong": "V13102018",
                           "tenTruong": "MNTT Tuệ Đức"
                         },
                         {
                           "maTruong": "V15102018",
                           "tenTruong": "MNTT Hoa Diên Vỹ"
                         },
                         {
                           "maTruong": "V16102018",
                           "tenTruong": "MNTT Hoàng Kim"
                         },
                         {
                           "maTruong": "V17102018",
                           "tenTruong": "MNTT Bầu Trời Xanh"
                         },
                         {
                           "maTruong": "V19102018",
                           "tenTruong": "Lớp MG Sen Việt"
                         },
                         {
                           "maTruong": "V20102018",
                           "tenTruong": "NT Tuyết Vân"
                         },
                         {
                           "maTruong": "V21102018",
                           "tenTruong": "Lớp MG Chim Sơn Ca"
                         },
                         {
                           "maTruong": "V23102018",
                           "tenTruong": "Lớp MG Hoa Mai"
                         },
                         {
                           "maTruong": "V24102018",
                           "tenTruong": "NT Hoa Bưởi"
                         },
                         {
                           "maTruong": "V26102018",
                           "tenTruong": "Lớp MG Họa Mi"
                         },
                         {
                           "maTruong": "V27102018",
                           "tenTruong": "Lớp MG Ánh Dương"
                         },
                         {
                           "maTruong": "V28102018",
                           "tenTruong": "Lớp MG Tuổi Thần Tiên"
                         },
                         {
                           "maTruong": "V29102018",
                           "tenTruong": "Lớp MG Phương Đông"
                         },
                         {
                           "maTruong": "V14062019",
                           "tenTruong": "Lớp MG Nắng Ban Mai"
                         },
                         {
                           "maTruong": "V10112018",
                           "tenTruong": "Lớp Mẫu Giáo Chuông Vàng"
                         },
                         {
                           "maTruong": "V22112018",
                           "tenTruong": "Lớp MG Bé Yêu"
                         },
                         {
                           "maTruong": "V15112018",
                           "tenTruong": "Lớp MG Em Bé Hạnh Phúc"
                         },
                         {
                           "maTruong": "V02112018",
                           "tenTruong": "Lớp MG Chào Bé Yêu"
                         },
                         {
                           "maTruong": "V30102018",
                           "tenTruong": "NT Kỳ Anh"
                         },
                         {
                           "maTruong": "79763513",
                           "tenTruong": "THCS Hiệp Phú"
                         },
                         {
                           "maTruong": "79763L89",
                           "tenTruong": "Lớp MG Minh Anh"
                         },
                         {
                           "maTruong": "79763L90",
                           "tenTruong": "Lớp MG Vườn Kiwi"
                         },
                         {
                           "maTruong": "79763O20",
                           "tenTruong": "Lớp Nhà Trẻ Con Cưng"
                         },
                         {
                           "maTruong": "79763L91",
                           "tenTruong": "Lớp MG Gấu Trúc"
                         },
                         {
                           "maTruong": "79763L92",
                           "tenTruong": "Lớp MG Sao Việt IOC"
                         },
                         {
                           "maTruong": "79763O22",
                           "tenTruong": "Lớp NT Cá Mập Con"
                         },
                         {
                           "maTruong": "79763L93",
                           "tenTruong": "Lớp MG Nhân Phú"
                         },
                         {
                           "maTruong": "79763L94",
                           "tenTruong": "Lớp MG Ngôi Sao Nhỏ"
                         },
                         {
                           "maTruong": "79763L95",
                           "tenTruong": "Lớp MG Ngôi Sao Việt"
                         },
                         {
                           "maTruong": "79763L96",
                           "tenTruong": "Lớp MG Mầm Non Việt"
                         },
                         {
                           "maTruong": "79763L97",
                           "tenTruong": "Lớp MG Việt Úc"
                         },
                         {
                           "maTruong": "79763L98",
                           "tenTruong": "Lớp MG Khang An"
                         },
                         {
                           "maTruong": "79763W54",
                           "tenTruong": "Lớp MG Thiên Nga"
                         },
                         {
                           "maTruong": "79763354",
                           "tenTruong": "MNTT Thiên Phúc"
                         },
                         {
                           "maTruong": "79763355",
                           "tenTruong": "MNTT Thiên Ân Phúc 4"
                         },
                         {
                           "maTruong": "79763356",
                           "tenTruong": "Mầm Non Mặt Trời Á Châu"
                         },
                         {
                           "maTruong": "79763357",
                           "tenTruong": "MNTT Diệu Kỳ"
                         },
                         {
                           "maTruong": "79763359",
                           "tenTruong": "MNTT Hồ Ngọc Cẩn"
                         },
                         {
                           "maTruong": "79763360",
                           "tenTruong": "MNTT Ngôi Nhà Nhỏ"
                         },
                         {
                           "maTruong": "79763L69",
                           "tenTruong": "Lớp MG Chào Bé Yêu"
                         },
                         {
                           "maTruong": "79763L70",
                           "tenTruong": "Lớp MG Gấu Con"
                         },
                         {
                           "maTruong": "79763L71",
                           "tenTruong": "Lớp MG Bảo Minh"
                         },
                         {
                           "maTruong": "79763347",
                           "tenTruong": "MNTT Thiên Ân Phúc 3"
                         },
                         {
                           "maTruong": "79763L72",
                           "tenTruong": "Lớp MG Chuông Vàng"
                         },
                         {
                           "maTruong": "79763348",
                           "tenTruong": "MNTT Vương Quốc Tí Hon"
                         },
                         {
                           "maTruong": "79763349",
                           "tenTruong": "MNTT Kim Đồng"
                         },
                         {
                           "maTruong": "79763350",
                           "tenTruong": "MNTT Tuệ Đức"
                         },
                         {
                           "maTruong": "79763L73",
                           "tenTruong": "Lớp MG Nắng Ban Mai"
                         },
                         {
                           "maTruong": "79763351",
                           "tenTruong": "MNTT Hoa Diên Vỹ"
                         },
                         {
                           "maTruong": "79763L74",
                           "tenTruong": "Lớp MG Em Bé Hạnh Phúc"
                         },
                         {
                           "maTruong": "79763L75",
                           "tenTruong": "Lớp MG Tài Năng Á Châu"
                         },
                         {
                           "maTruong": "79763L76",
                           "tenTruong": "NT 151"
                         },
                         {
                           "maTruong": "79763352",
                           "tenTruong": "MNTT Hoàng Kim"
                         },
                         {
                           "maTruong": "79763353",
                           "tenTruong": "MNTT Bầu Trời Xanh"
                         },
                         {
                           "maTruong": "79763L77",
                           "tenTruong": "Lớp MG Nụ Cười Trẻ Thơ"
                         },
                         {
                           "maTruong": "79763L78",
                           "tenTruong": "Lớp MG Sen Việt"
                         },
                         {
                           "maTruong": "79763L79",
                           "tenTruong": "NT Tuyết Vân"
                         },
                         {
                           "maTruong": "79763L80",
                           "tenTruong": "Lớp MG Chim Sơn Ca"
                         },
                         {
                           "maTruong": "79763L81",
                           "tenTruong": "Lớp MG Bé Yêu"
                         },
                         {
                           "maTruong": "79763L82",
                           "tenTruong": "Lớp MG Hoa Mai"
                         },
                         {
                           "maTruong": "79763L83",
                           "tenTruong": "NT Hoa Bưởi"
                         },
                         {
                           "maTruong": "79763L84",
                           "tenTruong": "Lớp MG Họa Mi"
                         },
                         {
                           "maTruong": "79763L85",
                           "tenTruong": "Lớp MG Ánh Dương"
                         },
                         {
                           "maTruong": "79763L86",
                           "tenTruong": "Lớp MG Tuổi Thần Tiên"
                         },
                         {
                           "maTruong": "79763L87",
                           "tenTruong": "Lớp MG Phương Đông"
                         },
                         {
                           "maTruong": "79763L88",
                           "tenTruong": "NT Kỳ Anh"
                         },
                         {
                           "maTruong": "79763421",
                           "tenTruong": "TH Trần Thị Bưởi"
                         }
                       ]
                     },
                     {
                       "maPhong": "760",
                       "tenPhong": "Quận 1",
                       "truongData": [
                         {
                           "maTruong": "79760424",
                           "tenTruong": "TH Châu Á Thái Bình Dương"
                         },
                         {
                           "maTruong": "79760406",
                           "tenTruong": "TH Chương Dương"
                         },
                         {
                           "maTruong": "79760403",
                           "tenTruong": "TH Đinh Tiên Hoàng"
                         },
                         {
                           "maTruong": "79760415",
                           "tenTruong": "TH Đuốc Sống"
                         },
                         {
                           "maTruong": "79760401",
                           "tenTruong": "TH Hoà Bình"
                         },
                         {
                           "maTruong": "79760402",
                           "tenTruong": "TH Kết Đoàn"
                         },
                         {
                           "maTruong": "79760408",
                           "tenTruong": "TH Khai Minh"
                         },
                         {
                           "maTruong": "79760404",
                           "tenTruong": "TH Lê Ngọc Hân"
                         },
                         {
                           "maTruong": "79760418",
                           "tenTruong": "TH Lương Thế Vinh"
                         },
                         {
                           "maTruong": "79760414",
                           "tenTruong": "TH Nguyễn Bỉnh Khiêm"
                         },
                         {
                           "maTruong": "79760416",
                           "tenTruong": "TH Nguyễn Huệ"
                         },
                         {
                           "maTruong": "79760410",
                           "tenTruong": "TH Nguyễn Thái Bình"
                         },
                         {
                           "maTruong": "79760413",
                           "tenTruong": "TH Phan Văn Trị"
                         },
                         {
                           "maTruong": "79760407",
                           "tenTruong": "TH Trần Hưng Đạo"
                         },
                         {
                           "maTruong": "79760411",
                           "tenTruong": "TH Trần Khánh Dư"
                         },
                         {
                           "maTruong": "79760412",
                           "tenTruong": "TH Trần Quang Khải"
                         },
                         {
                           "maTruong": "79760409",
                           "tenTruong": "Trường Tiểu học  Nguyễn Thái Học"
                         },
                         {
                           "maTruong": "79760501",
                           "tenTruong": "THCS Chu Văn An"
                         },
                         {
                           "maTruong": "79760508",
                           "tenTruong": "THCS Đồng Khởi"
                         },
                         {
                           "maTruong": "79760507",
                           "tenTruong": "THCS Đức Trí"
                         },
                         {
                           "maTruong": "79760509",
                           "tenTruong": "THCS Huỳnh Khương Ninh"
                         },
                         {
                           "maTruong": "79760503",
                           "tenTruong": "THCS Minh Đức"
                         },
                         {
                           "maTruong": "79760504",
                           "tenTruong": "THCS Nguyễn Du Q1"
                         },
                         {
                           "maTruong": "79760502",
                           "tenTruong": "THCS Trần Văn Ơn"
                         },
                         {
                           "maTruong": "79760505",
                           "tenTruong": "THCS Văn Lang"
                         },
                         {
                           "maTruong": "79760506",
                           "tenTruong": "THCS Võ Trường Toản"
                         },
                         {
                           "maTruong": "79000809",
                           "tenTruong": "THCS - THPT Đăng Khoa"
                         },
                         {
                           "maTruong": "79000846",
                           "tenTruong": "THCS -THPT Châu Á Thái Bình Dương"
                         },
                         {
                           "maTruong": "79000F02",
                           "tenTruong": "THPT chuyên Trần Đại Nghĩa"
                         },
                         {
                           "maTruong": "79000869",
                           "tenTruong": "THPT Lương Thế Vinh"
                         },
                         {
                           "maTruong": "79000871",
                           "tenTruong": "THPT Năng Khiếu TDTT"
                         },
                         {
                           "maTruong": "79760423",
                           "tenTruong": "TH - THCS - THPT Úc Châu"
                         },
                         {
                           "maTruong": "79760419",
                           "tenTruong": "TH, THCS và THPT Quốc tế Á Châu"
                         },
                         {
                           "maTruong": "79761341",
                           "tenTruong": "Mầm non Chuồn Chuồn Kim"
                         },
                         {
                           "maTruong": "79760318",
                           "tenTruong": "Mầm Non Ngôi Nhà Nhỏ"
                         },
                         {
                           "maTruong": "79771340",
                           "tenTruong": "Mầm Non Quốc Tế Thế Giới Trẻ Thơ"
                         },
                         {
                           "maTruong": "79760313",
                           "tenTruong": "MN 20/10"
                         },
                         {
                           "maTruong": "79760312",
                           "tenTruong": "MN 30/4"
                         },
                         {
                           "maTruong": "79760321",
                           "tenTruong": "MN BaBy"
                         },
                         {
                           "maTruong": "79760305",
                           "tenTruong": "MN Bé Ngoan"
                         },
                         {
                           "maTruong": "mnbenthanh1",
                           "tenTruong": "MN Bến Thành"
                         },
                         {
                           "maTruong": "79760310",
                           "tenTruong": "MN Cô Giang"
                         },
                         {
                           "maTruong": "79760324",
                           "tenTruong": "MN Công dân Quốc tế"
                         },
                         {
                           "maTruong": "79760319",
                           "tenTruong": "MN DL Sa Pa"
                         },
                         {
                           "maTruong": "79760343",
                           "tenTruong": "MN Đông Bắc"
                         },
                         {
                           "maTruong": "79760320",
                           "tenTruong": "MN Gấu Trúc"
                         },
                         {
                           "maTruong": "79760309",
                           "tenTruong": "MN HOA LAN"
                         },
                         {
                           "maTruong": "79760306",
                           "tenTruong": "MN Hoa Lư"
                         },
                         {
                           "maTruong": "79760303",
                           "tenTruong": "MN Hoa Quỳnh"
                         },
                         {
                           "maTruong": "79760344",
                           "tenTruong": "MN Học Viện Sài Gòn"
                         },
                         {
                           "maTruong": "79760302",
                           "tenTruong": "MN Lê Thị Riêng"
                         },
                         {
                           "maTruong": "79760301",
                           "tenTruong": "MN Nguyễn Cư Trinh"
                         },
                         {
                           "maTruong": "79760307",
                           "tenTruong": "MN Nguyễn Thái Bình"
                         },
                         {
                           "maTruong": "79760311",
                           "tenTruong": "MN Phạm Ngũ Lão"
                         },
                         {
                           "maTruong": "79771339",
                           "tenTruong": "MN Quốc tế Ngôi Sao Sáng"
                         },
                         {
                           "maTruong": "79760326",
                           "tenTruong": "MN Sân Lá Cọ"
                         },
                         {
                           "maTruong": "79760304",
                           "tenTruong": "MN Tân Định"
                         },
                         {
                           "maTruong": "79760308",
                           "tenTruong": "MN Tuổi Hồng"
                         },
                         {
                           "maTruong": "79760325",
                           "tenTruong": "MN Tuổi Thơ"
                         },
                         {
                           "maTruong": "79760341",
                           "tenTruong": "Lớp Mầm non Ban Mai"
                         },
                         {
                           "maTruong": "79760W05",
                           "tenTruong": "Lớp Mẫu giáo Cầu Kho"
                         },
                         {
                           "maTruong": "79760W03",
                           "tenTruong": "Lớp Mẫu giáo Hoa Hồng"
                         },
                         {
                           "maTruong": "79760W07",
                           "tenTruong": "Lớp Mẫu giáo Huyện Sỹ"
                         },
                         {
                           "maTruong": "79760W04",
                           "tenTruong": "Lớp Mẫu giáo Mai Anh"
                         },
                         {
                           "maTruong": "79760W02",
                           "tenTruong": "Lớp Mẫu giáo Tôn Thất Tùng"
                         },
                         {
                           "maTruong": "79760W44",
                           "tenTruong": "Nhóm Trẻ Bồ Câu Nhỏ"
                         },
                         {
                           "maTruong": "79760315",
                           "tenTruong": "MN Bến Thành"
                         },
                         {
                           "maTruong": "79760W08",
                           "tenTruong": "Lớp mẫu giáo tư thục Đông Bắc"
                         },
                         {
                           "maTruong": "79760263",
                           "tenTruong": "Mầm non Vinschool"
                         },
                         {
                           "maTruong": "7976001001",
                           "tenTruong": "Lớp mầm non độc lập Trẻ Thơ"
                         },
                         {
                           "maTruong": "7976001002",
                           "tenTruong": "Lớp mầm non độc lập Công Dân Quốc Tế"
                         },
                         {
                           "maTruong": "7976001003",
                           "tenTruong": "Lớp mẫu giáo Phần Lan Nhỏ"
                         },
                         {
                           "maTruong": "7976001004",
                           "tenTruong": "Lớp mầm non độc lập Vườn Thiên Thần"
                         }
                       ]
                     },
                     {
                       "maPhong": null,
                       "tenPhong": "Quận Thủ Đức",
                       "truongData": [
                         {
                           "maTruong": "79000780",
                           "tenTruong": "THPT An Dương Vương"
                         },
                         {
                           "maTruong": "790007A1",
                           "tenTruong": "THPT Bách Việt"
                         },
                         {
                           "maTruong": "790007B2",
                           "tenTruong": "THPT Đào Sơn Tây"
                         },
                         {
                           "maTruong": "79000765",
                           "tenTruong": "THPT Hiệp Bình"
                         },
                         {
                           "maTruong": "79000732",
                           "tenTruong": "THPT Nguyễn Hữu Huân"
                         },
                         {
                           "maTruong": "79000733",
                           "tenTruong": "THPT Tam Phú"
                         },
                         {
                           "maTruong": "79000731",
                           "tenTruong": "THPT Thủ Đức"
                         },
                         {
                           "maTruong": "79000914",
                           "tenTruong": "TT GDTX Gia Định"
                         },
                         {
                           "maTruong": "79762901",
                           "tenTruong": "TT GDTX Q Thủ Đức"
                         },
                         {
                           "maTruong": "790007C3",
                           "tenTruong": "THPT Linh Trung"
                         },
                         {
                           "maTruong": "79000707",
                           "tenTruong": "Phổ thông Năng khiếu Thể thao Olympic"
                         },
                         {
                           "maTruong": "790007C5",
                           "tenTruong": "THPT Bình Chiểu"
                         },
                         {
                           "maTruong": "79000C13",
                           "tenTruong": "Tiểu học, THCS và THPT Emasi Vạn Phúc"
                         }
                       ]
                     },
                     {
                       "maPhong": null,
                       "tenPhong": "Quận 7",
                       "truongData": [
                         {
                           "maTruong": "79000711",
                           "tenTruong": "THPT Lê Thánh Tôn"
                         },
                         {
                           "maTruong": "79000803",
                           "tenTruong": "THPT Ngô Quyền"
                         },
                         {
                           "maTruong": "79000754",
                           "tenTruong": "THPT Tân Phong"
                         },
                         {
                           "maTruong": "79778311",
                           "tenTruong": "MN Nam Sài Gòn"
                         },
                         {
                           "maTruong": "79778901",
                           "tenTruong": "TT GDTX Q7"
                         },
                         {
                           "maTruong": "79000849",
                           "tenTruong": "THCS và THPT Đức Trí"
                         },
                         {
                           "maTruong": "79000850",
                           "tenTruong": "THCS và THPT Đinh Thiện Lý"
                         },
                         {
                           "maTruong": "79000851",
                           "tenTruong": "THCS và THPT Sao Việt"
                         },
                         {
                           "maTruong": "79000820",
                           "tenTruong": "THPT Nam Sài Gòn"
                         },
                         {
                           "maTruong": "79000C05",
                           "tenTruong": "Tiểu học, THCS và THPT Quốc tế Canada"
                         },
                         {
                           "maTruong": "79000C11",
                           "tenTruong": "Tiểu học, THCS và THPT Emasi Nam Long"
                         },
                         {
                           "maTruong": "79000C15",
                           "tenTruong": "Tiểu học, THCS và THPT Hoàng Gia"
                         },
                         {
                           "maTruong": "79000839",
                           "tenTruong": "Trường Quốc tế Việt Nam - Phần Lan"
                         },
                         {
                           "maTruong": "79000846",
                           "tenTruong": "THCS và THPT Châu Á Thái Bình Dương"
                         },
                         {
                           "maTruong": "7977801017",
                           "tenTruong": "MN ASC"
                         },
                         {
                           "maTruong": "79778387",
                           "tenTruong": "MN Quốc tế Khai Sáng"
                         },
                         {
                           "maTruong": "79000M20",
                           "tenTruong": "Trường Đài Bắc tại Thành phố Hồ Chí Minh"
                         },
                         {
                           "maTruong": "79000N10",
                           "tenTruong": "Trường Quốc tế Nam Sài Gòn"
                         }
                       ]
                     },
                     {
                       "maPhong": null,
                       "tenPhong": "Quận 8",
                       "truongData": [
                         {
                           "maTruong": "79000712",
                           "tenTruong": "THPT Lương Văn Can"
                         },
                         {
                           "maTruong": "79000713",
                           "tenTruong": "THPT Ngô Gia Tự"
                         },
                         {
                           "maTruong": "79000794",
                           "tenTruong": "THPT Nguyễn Văn Linh"
                         },
                         {
                           "maTruong": "79000714",
                           "tenTruong": "THPT Tạ Quang Bửu"
                         },
                         {
                           "maTruong": "790007B8",
                           "tenTruong": "THPT Võ Văn Kiệt"
                         },
                         {
                           "maTruong": "79776901",
                           "tenTruong": "TT GDNN - GDTX Q8"
                         },
                         {
                           "maTruong": "79000F03",
                           "tenTruong": "THPT Chuyên Năng khiếu TDTT Nguyễn Thị Định"
                         },
                         {
                           "maTruong": "7900004018",
                           "tenTruong": "Trường Chuyên biệt Hy Vọng (Quận 8)"
                         }
                       ]
                     },
                     {
                       "maPhong": null,
                       "tenPhong": "Quận 9",
                       "truongData": [
                         {
                           "maTruong": "79000759",
                           "tenTruong": "THPT Long Trường"
                         },
                         {
                           "maTruong": "79000715",
                           "tenTruong": "THPT Nguyễn Huệ"
                         },
                         {
                           "maTruong": "790007A9",
                           "tenTruong": "THPT Nguyễn Văn Tăng"
                         },
                         {
                           "maTruong": "79000752",
                           "tenTruong": "THPT Phước Long"
                         },
                         {
                           "maTruong": "79763901",
                           "tenTruong": "TRUNG TÂM GIÁO DỤC THƯỜNG XUYÊN QUẬN 9"
                         },
                         {
                           "maTruong": "79000813",
                           "tenTruong": "Tiểu học, THCS và THPT Ngô Thời Nhiệm"
                         },
                         {
                           "maTruong": "79000873",
                           "tenTruong": "THCS và THPT Hoa Sen"
                         },
                         {
                           "maTruong": "790007C4",
                           "tenTruong": "THPT Dương Văn Thì"
                         }
                       ]
                     },
                     {
                       "maPhong": "NULL",
                       "tenPhong": "Quận Tân Bình",
                       "truongData": [
                         {
                           "maTruong": "7900001006",
                           "tenTruong": "Mầm non Dãy Núi Xanh - Tân Bình"
                         }
                       ]
                     },
                     {
                       "maPhong": null,
                       "tenPhong": "Quận 3",
                       "truongData": [
                         {
                           "maTruong": "79000705",
                           "tenTruong": "THPT Lê Quý Đôn"
                         },
                         {
                           "maTruong": "79000795",
                           "tenTruong": "THPT Lê Thị Hồng Gấm"
                         },
                         {
                           "maTruong": "79000743",
                           "tenTruong": "THPT Marie Curie"
                         },
                         {
                           "maTruong": "79000796",
                           "tenTruong": "THPT Nguyễn Thị Diệu"
                         },
                         {
                           "maTruong": "79000704",
                           "tenTruong": "THPT Nguyễn Thị Minh Khai"
                         },
                         {
                           "maTruong": "79000301",
                           "tenTruong": "MN Thành Phố"
                         },
                         {
                           "maTruong": "79000909",
                           "tenTruong": "Trung tâm Dạy nghề cho người tàn tật"
                         },
                         {
                           "maTruong": "79770901",
                           "tenTruong": "TRUNG TÂM GDTX QUẬN 3"
                         },
                         {
                           "maTruong": "79770702",
                           "tenTruong": "Tiểu học, THCS và THPT Úc Châu"
                         },
                         {
                           "maTruong": "79770601",
                           "tenTruong": "Tiểu học, THCS và THPT Tây Úc"
                         },
                         {
                           "maTruong": "7900004014",
                           "tenTruong": "TT HTPT GDHN cho Người Khuyết tật (Quận 3)"
                         },
                         {
                           "maTruong": "7900004015",
                           "tenTruong": "Trường Chuyên biệt Tương Lại (Quận 3)"
                         }
                       ]
                     },
                     {
                       "maPhong": "777",
                       "tenPhong": "Quận Bình Tân",
                       "truongData": [
                         {
                           "maTruong": "79777401",
                           "tenTruong": "THCL An Lạc 1"
                         },
                         {
                           "maTruong": "79777402",
                           "tenTruong": "THCL An Lạc 2"
                         },
                         {
                           "maTruong": "79777403",
                           "tenTruong": "THCL An Lạc 3"
                         },
                         {
                           "maTruong": "79777422",
                           "tenTruong": "THCL Bình Hưng Hòa"
                         },
                         {
                           "maTruong": "79777404",
                           "tenTruong": "THCL Bình Hưng Hòa 1"
                         },
                         {
                           "maTruong": "79777411",
                           "tenTruong": "THCL Bình Long"
                         },
                         {
                           "maTruong": "79777414",
                           "tenTruong": "THCL Bình Tân"
                         },
                         {
                           "maTruong": "79777405",
                           "tenTruong": "THCL Bình Thuận"
                         },
                         {
                           "maTruong": "79777406",
                           "tenTruong": "THCL Bình Trị 1"
                         },
                         {
                           "maTruong": "79777409",
                           "tenTruong": "THCL Bình Trị 2"
                         },
                         {
                           "maTruong": "79777413",
                           "tenTruong": "THCL Bình Trị Đông"
                         },
                         {
                           "maTruong": "79777410",
                           "tenTruong": "THCL Bình Trị Đông A"
                         },
                         {
                           "maTruong": "79777416",
                           "tenTruong": "THCL Kim Đồng"
                         },
                         {
                           "maTruong": "79777426",
                           "tenTruong": "THCL Lạc Hồng"
                         },
                         {
                           "maTruong": "79777412",
                           "tenTruong": "THCL Lê Công Phép"
                         },
                         {
                           "maTruong": "79777425",
                           "tenTruong": "THCL Lê Quý Đôn"
                         },
                         {
                           "maTruong": "79777419",
                           "tenTruong": "THCL Lê Trọng Tấn"
                         },
                         {
                           "maTruong": "79777408",
                           "tenTruong": "THCL Ngô Quyền"
                         },
                         {
                           "maTruong": "79777420",
                           "tenTruong": "THCL Phù Đổng"
                         },
                         {
                           "maTruong": "79777407",
                           "tenTruong": "THCL Tân Tạo"
                         },
                         {
                           "maTruong": "79777415",
                           "tenTruong": "THCL Tân Tạo A"
                         },
                         {
                           "maTruong": "79777423",
                           "tenTruong": "THCL Trần Văn Ơn"
                         },
                         {
                           "maTruong": "79777417",
                           "tenTruong": "THTT Trí Tuệ Việt"
                         },
                         {
                           "maTruong": "79777501",
                           "tenTruong": "THCSCL An Lạc"
                         },
                         {
                           "maTruong": "79777503",
                           "tenTruong": "THCSCL Bình Hưng Hòa"
                         },
                         {
                           "maTruong": "79777505",
                           "tenTruong": "THCSCL Bình Tân"
                         },
                         {
                           "maTruong": "79777504",
                           "tenTruong": "THCSCL Bình Trị Đông"
                         },
                         {
                           "maTruong": "79777508",
                           "tenTruong": "THCSCL Bình Trị Đông A"
                         },
                         {
                           "maTruong": "79777502",
                           "tenTruong": "THCSCL Hồ Văn Long"
                         },
                         {
                           "maTruong": "79777511",
                           "tenTruong": "THCSCL Huỳnh Văn Nghệ"
                         },
                         {
                           "maTruong": "79777506",
                           "tenTruong": "THCSCL Lê Tấn Bê"
                         },
                         {
                           "maTruong": "79777509",
                           "tenTruong": "THCSCL Lý Thường Kiệt"
                         },
                         {
                           "maTruong": "79777512",
                           "tenTruong": "THCSCL Nguyễn Trãi"
                         },
                         {
                           "maTruong": "79777507",
                           "tenTruong": "THCSCL Tân Tạo"
                         },
                         {
                           "maTruong": "79777513",
                           "tenTruong": "THCSCL Tân Tạo A"
                         },
                         {
                           "maTruong": "79777510",
                           "tenTruong": "THCSCL Trần Quốc Toản"
                         },
                         {
                           "maTruong": "79777514",
                           "tenTruong": "THCSTT Trí Tuệ Việt"
                         },
                         {
                           "maTruong": "79000815",
                           "tenTruong": "THCS - THPT Ngôi Sao"
                         },
                         {
                           "maTruong": "79000838",
                           "tenTruong": "Trường THCS, THPT Phan Châu Trinh"
                         },
                         {
                           "maTruong": "79000774",
                           "tenTruong": "TH - THCS - THPT Chu Văn An"
                         },
                         {
                           "maTruong": "79777418",
                           "tenTruong": "Tiểu học, THCS và THPT Ngôi Sao Nhỏ"
                         },
                         {
                           "maTruong": "79777301",
                           "tenTruong": "MNCL 19 tháng 5"
                         },
                         {
                           "maTruong": "79777359",
                           "tenTruong": "MNCL 20-10"
                         },
                         {
                           "maTruong": "79777355",
                           "tenTruong": "MNCL 30-4"
                         },
                         {
                           "maTruong": "79777312",
                           "tenTruong": "MNCL Ánh Mai"
                         },
                         {
                           "maTruong": "79777313",
                           "tenTruong": "MNCL Ánh Sao"
                         },
                         {
                           "maTruong": "79777356",
                           "tenTruong": "MNCL Bình Trị Đông"
                         },
                         {
                           "maTruong": "79777362",
                           "tenTruong": "MNCL Bình Trị Đông B"
                         },
                         {
                           "maTruong": "79777310",
                           "tenTruong": "MNCL Cẩm Tú"
                         },
                         {
                           "maTruong": "79777364",
                           "tenTruong": "MNCL Đỗ Quyên"
                         },
                         {
                           "maTruong": "79777306",
                           "tenTruong": "MNCL Hoa Cúc"
                         },
                         {
                           "maTruong": "79777309",
                           "tenTruong": "MNCL Hoa Đào"
                         },
                         {
                           "maTruong": "79777303",
                           "tenTruong": "MNCL Hoa Hồng"
                         },
                         {
                           "maTruong": "79777314",
                           "tenTruong": "MNCL Hoa Phượng"
                         },
                         {
                           "maTruong": "79777363",
                           "tenTruong": "MNCL Hoa Phượng Vỹ"
                         },
                         {
                           "maTruong": "79777308",
                           "tenTruong": "MNCL Hoàng Anh"
                         },
                         {
                           "maTruong": "79777307",
                           "tenTruong": "MNCL Hương Sen"
                         },
                         {
                           "maTruong": "79777361",
                           "tenTruong": "MNCL Mai Vàng"
                         },
                         {
                           "maTruong": "79777305",
                           "tenTruong": "MNCL Sen Hồng"
                         },
                         {
                           "maTruong": "79777311",
                           "tenTruong": "MNCL Tân Tạo"
                         },
                         {
                           "maTruong": "79777360",
                           "tenTruong": "MNCL Tân Tạo A"
                         },
                         {
                           "maTruong": "79777302",
                           "tenTruong": "MNCL Thủy Tiên"
                         },
                         {
                           "maTruong": "79777378",
                           "tenTruong": "MNCL Trúc Đào"
                         },
                         {
                           "maTruong": "79777LR7",
                           "tenTruong": "MNTT An An"
                         },
                         {
                           "maTruong": "79777315",
                           "tenTruong": "MNTT An Lạc"
                         },
                         {
                           "maTruong": "797773R8",
                           "tenTruong": "MNTT An Nông"
                         },
                         {
                           "maTruong": "79777316",
                           "tenTruong": "MNTT Ánh Dương"
                         },
                         {
                           "maTruong": "79777317",
                           "tenTruong": "MNTT ÁNH HỒNG"
                         },
                         {
                           "maTruong": "79777WA5",
                           "tenTruong": "MNTT Anh Sao"
                         },
                         {
                           "maTruong": "79777LO1",
                           "tenTruong": "MNTT Anh Thư"
                         },
                         {
                           "maTruong": "79777319",
                           "tenTruong": "MNTT Anh Việt"
                         },
                         {
                           "maTruong": "79777320",
                           "tenTruong": "MNTT Anpha Miền nam"
                         },
                         {
                           "maTruong": "79777321",
                           "tenTruong": "MNTT Bảo Ngọc"
                         },
                         {
                           "maTruong": "79777W16",
                           "tenTruong": "MNTT Bé Xinh"
                         },
                         {
                           "maTruong": "79777322",
                           "tenTruong": "MNTT Bi Bi"
                         },
                         {
                           "maTruong": "79777354",
                           "tenTruong": "MNTT Bình Minh"
                         },
                         {
                           "maTruong": "79777200",
                           "tenTruong": "MNTT Chiếc Lá Nhỏ"
                         },
                         {
                           "maTruong": "79777323",
                           "tenTruong": "MNTT Chim Non"
                         },
                         {
                           "maTruong": "79777370",
                           "tenTruong": "MNTT Chuỗi Ngọc"
                         },
                         {
                           "maTruong": "79777376",
                           "tenTruong": "MNTT Đất Việt"
                         },
                         {
                           "maTruong": "79777W27",
                           "tenTruong": "MNTT Đông Nam Á"
                         },
                         {
                           "maTruong": "79777375",
                           "tenTruong": "MNTT Hải My"
                         },
                         {
                           "maTruong": "79777324",
                           "tenTruong": "MNTT Hoa Hồng Nhỏ"
                         },
                         {
                           "maTruong": "79777325",
                           "tenTruong": "MNTT Hoa Lan"
                         },
                         {
                           "maTruong": "79777326",
                           "tenTruong": "MNTT Hoa Mai"
                         },
                         {
                           "maTruong": "79777327",
                           "tenTruong": "MNTT Hoa Sen"
                         },
                         {
                           "maTruong": "79777328",
                           "tenTruong": "MNTT Hoa Thiên Lý"
                         },
                         {
                           "maTruong": "79777353",
                           "tenTruong": "MNTT Hoàng Gia"
                         },
                         {
                           "maTruong": "79777329",
                           "tenTruong": "MNTT Hồng Phúc"
                         },
                         {
                           "maTruong": "79777331",
                           "tenTruong": "MNTT Hương Mai"
                         },
                         {
                           "maTruong": "79777332",
                           "tenTruong": "MNTT KHAI MINH"
                         },
                         {
                           "maTruong": "79777333",
                           "tenTruong": "MNTT Kim Anh"
                         },
                         {
                           "maTruong": "79777WA4",
                           "tenTruong": "MNTT Kim Đồng"
                         },
                         {
                           "maTruong": "79777357",
                           "tenTruong": "MNTT Kim Đồng Anh"
                         },
                         {
                           "maTruong": "79777334",
                           "tenTruong": "MNTT Lê Thành"
                         },
                         {
                           "maTruong": "79777335",
                           "tenTruong": "MNTT Mai Anh"
                         },
                         {
                           "maTruong": "79777336",
                           "tenTruong": "MNTT Mặt Trời Nhỏ"
                         },
                         {
                           "maTruong": "79777337",
                           "tenTruong": "MNTT Nam Hùng Vương"
                         },
                         {
                           "maTruong": "79777365",
                           "tenTruong": "MNTT Ngọc Quỳnh"
                         },
                         {
                           "maTruong": "79777371",
                           "tenTruong": "MNTT Ngôi Nhà Trẻ Thơ"
                         },
                         {
                           "maTruong": "79777358",
                           "tenTruong": "MNTT Ngôi Sao"
                         },
                         {
                           "maTruong": "79777338",
                           "tenTruong": "MNTT Ngôi Sao Nhỏ"
                         },
                         {
                           "maTruong": "79777368",
                           "tenTruong": "MNTT Ngôi Sao Sáng"
                         },
                         {
                           "maTruong": "79777366",
                           "tenTruong": "MNTT Ngôi Sao Tuổi Thơ"
                         },
                         {
                           "maTruong": "79777339",
                           "tenTruong": "MNTT Nguyên Ngọc"
                         },
                         {
                           "maTruong": "79777340",
                           "tenTruong": "MNTT Phượng Hoàng"
                         },
                         {
                           "maTruong": "79777341",
                           "tenTruong": "MNTT Phượng Hồng"
                         },
                         {
                           "maTruong": "79777342",
                           "tenTruong": "MNTT Rạng Đông"
                         },
                         {
                           "maTruong": "79777343",
                           "tenTruong": "MNTT Sóc Nâu"
                         },
                         {
                           "maTruong": "79777344",
                           "tenTruong": "MNTT Sơn Ca"
                         },
                         {
                           "maTruong": "79777377",
                           "tenTruong": "MNTT Tây Thạnh 2"
                         },
                         {
                           "maTruong": "79777345",
                           "tenTruong": "MNTT Thần Đồng"
                         },
                         {
                           "maTruong": "79777346",
                           "tenTruong": "MNTT Thanh Tâm"
                         },
                         {
                           "maTruong": "79777369",
                           "tenTruong": "MNTT Thế Giới Mới"
                         },
                         {
                           "maTruong": "79777347",
                           "tenTruong": "MNTT Trí Tuệ Việt"
                         },
                         {
                           "maTruong": "79777348",
                           "tenTruong": "MNTT Trúc Xanh"
                         },
                         {
                           "maTruong": "79777373",
                           "tenTruong": "MNTT Tuổi Hoa"
                         },
                         {
                           "maTruong": "79777349",
                           "tenTruong": "MNTT Tuổi Thần Tiên"
                         },
                         {
                           "maTruong": "79777367",
                           "tenTruong": "MNTT Tuổi Thơ Việt"
                         },
                         {
                           "maTruong": "79777350",
                           "tenTruong": "MNTT Vàng Anh"
                         },
                         {
                           "maTruong": "79777351",
                           "tenTruong": "MNTT Việt Đức"
                         },
                         {
                           "maTruong": "79777W98",
                           "tenTruong": "MNTT Việt Mỹ"
                         },
                         {
                           "maTruong": "79777372",
                           "tenTruong": "MNTT Việt Mỹ Úc"
                         },
                         {
                           "maTruong": "79777374",
                           "tenTruong": "MNTT Việt Việt Đức"
                         },
                         {
                           "maTruong": "79777352",
                           "tenTruong": "MNTT Vy Vy"
                         },
                         {
                           "maTruong": "79777W01",
                           "tenTruong": "NTĐL 1 tháng 6"
                         },
                         {
                           "maTruong": "79777LH8",
                           "tenTruong": "NTĐL Á Châu"
                         },
                         {
                           "maTruong": "79777LR2",
                           "tenTruong": "NTĐL ABC"
                         },
                         {
                           "maTruong": "79777LC3",
                           "tenTruong": "NTĐL An Bình"
                         },
                         {
                           "maTruong": "79777W02",
                           "tenTruong": "NTĐL An Khang"
                         },
                         {
                           "maTruong": "79777W03",
                           "tenTruong": "NTĐL An lạc A"
                         },
                         {
                           "maTruong": "79777LS6",
                           "tenTruong": "NTĐL An Tâm"
                         },
                         {
                           "maTruong": "79777LF4",
                           "tenTruong": "NTĐL Ánh Ban Mai"
                         },
                         {
                           "maTruong": "79777W04",
                           "tenTruong": "NTĐL Anh Đào"
                         },
                         {
                           "maTruong": "79777WB2",
                           "tenTruong": "NTĐL Ánh Dương Hồng"
                         },
                         {
                           "maTruong": "79777LK7",
                           "tenTruong": "NTĐL Ánh Mặt Trời"
                         },
                         {
                           "maTruong": "79777LQ1",
                           "tenTruong": "NTĐL Anh Mỹ"
                         },
                         {
                           "maTruong": "79777LR5",
                           "tenTruong": "NTĐL Ánh Nắng Vàng"
                         },
                         {
                           "maTruong": "79777LR8",
                           "tenTruong": "NTĐL Ánh Nguyệt"
                         },
                         {
                           "maTruong": "79777W07",
                           "tenTruong": "NTĐL Anh Trí"
                         },
                         {
                           "maTruong": "79777W08",
                           "tenTruong": "NTĐL Anh Vũ"
                         },
                         {
                           "maTruong": "79777LP9",
                           "tenTruong": "NTĐL Asuka"
                         },
                         {
                           "maTruong": "79777W09",
                           "tenTruong": "NTĐL Ban Mai"
                         },
                         {
                           "maTruong": "79777W10",
                           "tenTruong": "NTĐL Bảo Hân"
                         },
                         {
                           "maTruong": "79777LS8",
                           "tenTruong": "NTĐL Bảo Long"
                         },
                         {
                           "maTruong": "79777W11",
                           "tenTruong": "NTĐL Bảo Nhi"
                         },
                         {
                           "maTruong": "79777W12",
                           "tenTruong": "NTĐL Bảo Thiện"
                         },
                         {
                           "maTruong": "79777LI1",
                           "tenTruong": "NTĐL Bảo Trâm"
                         },
                         {
                           "maTruong": "79777LO6",
                           "tenTruong": "NTĐL Bảo Trân"
                         },
                         {
                           "maTruong": "79777LS4",
                           "tenTruong": "NTĐL Bầu Trời Xanh"
                         },
                         {
                           "maTruong": "79777W13",
                           "tenTruong": "NTĐL Bé By"
                         },
                         {
                           "maTruong": "79777W14",
                           "tenTruong": "NTĐL Bé Cưng"
                         },
                         {
                           "maTruong": "79777W15",
                           "tenTruong": "NTĐL Bé Ngoan"
                         },
                         {
                           "maTruong": "79777LC4",
                           "tenTruong": "NTĐL Bé Thông Minh"
                         },
                         {
                           "maTruong": "79777W17",
                           "tenTruong": "NTĐL Bé Yêu"
                         },
                         {
                           "maTruong": "79777LH0",
                           "tenTruong": "NTĐL Bé Yêu Su Su"
                         },
                         {
                           "maTruong": "79777LC5",
                           "tenTruong": "NTĐL Bí Ngô"
                         },
                         {
                           "maTruong": "79777LC6",
                           "tenTruong": "NTĐL Bin Bin"
                         },
                         {
                           "maTruong": "79777W18",
                           "tenTruong": "NTĐL Bình An"
                         },
                         {
                           "maTruong": "79777LQ8",
                           "tenTruong": "NTĐL Bông Hồng"
                         },
                         {
                           "maTruong": "79777W19",
                           "tenTruong": "NTĐL Búp Bê Ngoan"
                         },
                         {
                           "maTruong": "79777W20",
                           "tenTruong": "NTĐL Búp Bê Xinh"
                         },
                         {
                           "maTruong": "79777W22",
                           "tenTruong": "NTĐL Búp Măng"
                         },
                         {
                           "maTruong": "79777LC7",
                           "tenTruong": "NTĐL Búp Măng Non"
                         },
                         {
                           "maTruong": "79777W23",
                           "tenTruong": "NTĐL BÚP SEN"
                         },
                         {
                           "maTruong": "79777W24",
                           "tenTruong": "NTĐL Búp Sen Hồng"
                         },
                         {
                           "maTruong": "79777LC8",
                           "tenTruong": "NTĐL Búp Sen Vàng"
                         },
                         {
                           "maTruong": "79777LH1",
                           "tenTruong": "NTĐL Bút Bi Xanh"
                         },
                         {
                           "maTruong": "79777W25",
                           "tenTruong": "NTĐL Cá Vàng"
                         },
                         {
                           "maTruong": "79777W26",
                           "tenTruong": "NTĐL Cánh Én Tuổi Thơ"
                         },
                         {
                           "maTruong": "79777LJ5",
                           "tenTruong": "NTĐL CẦU VỒNG NHỎ"
                         },
                         {
                           "maTruong": "79777LS7",
                           "tenTruong": "NTĐL Chim Sơn Ca"
                         },
                         {
                           "maTruong": "79777LO5",
                           "tenTruong": "NTĐL Chú Ve Con"
                         },
                         {
                           "maTruong": "79777LB9",
                           "tenTruong": "NTĐL Cỏ Non"
                         },
                         {
                           "maTruong": "79777LQ4",
                           "tenTruong": "NTĐL Cô Và Bé"
                         },
                         {
                           "maTruong": "79777LK8",
                           "tenTruong": "NTĐL Con Kiến Vàng"
                         },
                         {
                           "maTruong": "79777LQ2",
                           "tenTruong": "NTĐL Con Kiến Vàng Nhỏ"
                         },
                         {
                           "maTruong": "79777LP8",
                           "tenTruong": "NTĐL Dâu Tây"
                         },
                         {
                           "maTruong": "79777LC9",
                           "tenTruong": "NTĐL Diệu Phương"
                         },
                         {
                           "maTruong": "79777LK2",
                           "tenTruong": "NTĐL Đô Rê Mí"
                         },
                         {
                           "maTruong": "79777LP2",
                           "tenTruong": "NTĐL Đô rê mon"
                         },
                         {
                           "maTruong": "79777LR1",
                           "tenTruong": "NTĐL Đồng Nhi"
                         },
                         {
                           "maTruong": "79777LI3",
                           "tenTruong": "NTĐL Du Dương"
                         },
                         {
                           "maTruong": "79777LR3",
                           "tenTruong": "NTĐL Đức Lan"
                         },
                         {
                           "maTruong": "79777LS5",
                           "tenTruong": "NTĐL Đức Tâm"
                         },
                         {
                           "maTruong": "79777LL6",
                           "tenTruong": "NTĐL Duyên Hồng"
                         },
                         {
                           "maTruong": "79777LL0",
                           "tenTruong": "NTĐL Gấu Trắng"
                         },
                         {
                           "maTruong": "79777W29",
                           "tenTruong": "NTĐL Gấu Trúc"
                         },
                         {
                           "maTruong": "79777LJ4",
                           "tenTruong": "NTDL Hà Vy"
                         },
                         {
                           "maTruong": "79777W30",
                           "tenTruong": "NTĐL Hải Tuyền"
                         },
                         {
                           "maTruong": "79777W31",
                           "tenTruong": "NTĐL Hải Yến"
                         },
                         {
                           "maTruong": "79777LM9",
                           "tenTruong": "NTĐL Hằng Nga"
                         },
                         {
                           "maTruong": "79777LD0",
                           "tenTruong": "NTĐL Hào Quang"
                         },
                         {
                           "maTruong": "79777LS3",
                           "tenTruong": "NTĐL Hiền Hậu"
                         },
                         {
                           "maTruong": "79777LD1",
                           "tenTruong": "NTĐL Hoa Anh Đào"
                         },
                         {
                           "maTruong": "79777LD2",
                           "tenTruong": "NTĐL Hoa Bé Ngoan"
                         },
                         {
                           "maTruong": "79777W33",
                           "tenTruong": "NTĐL Hòa Bình"
                         },
                         {
                           "maTruong": "79777LQ0",
                           "tenTruong": "NTĐL Hoa Bình Minh"
                         },
                         {
                           "maTruong": "79777LH4",
                           "tenTruong": "NTĐL Hoa Cát Tường"
                         },
                         {
                           "maTruong": "79777LO7",
                           "tenTruong": "NTĐL Hoa Cúc Xanh"
                         },
                         {
                           "maTruong": "79777LC1",
                           "tenTruong": "NTĐL Hoa Đất"
                         },
                         {
                           "maTruong": "79777LO8",
                           "tenTruong": "NTĐL Hoa Hồng Phượng"
                         },
                         {
                           "maTruong": "79777LD3",
                           "tenTruong": "NTĐL Hoa Hướng Dương"
                         },
                         {
                           "maTruong": "79777LD5",
                           "tenTruong": "NTĐL Hoa Lưu Ly"
                         },
                         {
                           "maTruong": "79777LI2",
                           "tenTruong": "NTĐL Hoa Mai Hồng"
                         },
                         {
                           "maTruong": "79777LG3",
                           "tenTruong": "NTĐL Hoa Mai Trắng"
                         },
                         {
                           "maTruong": "79777W35",
                           "tenTruong": "NTĐL Hoa Mặt Trời"
                         },
                         {
                           "maTruong": "79777W36",
                           "tenTruong": "NTĐL Họa Mi"
                         },
                         {
                           "maTruong": "79777LO0",
                           "tenTruong": "NTĐL Hoa Phượng Hồng"
                         },
                         {
                           "maTruong": "79777W38",
                           "tenTruong": "NTĐL Hoa Sữa"
                         },
                         {
                           "maTruong": "79777W39",
                           "tenTruong": "NTĐL Hoa Thủy"
                         },
                         {
                           "maTruong": "79777LG4",
                           "tenTruong": "NTĐL Hoa Thủy Tiên"
                         },
                         {
                           "maTruong": "79777LF7",
                           "tenTruong": "NTĐL Hoa Trà My"
                         },
                         {
                           "maTruong": "79777LH5",
                           "tenTruong": "NTĐL Hoa Vàng Anh"
                         },
                         {
                           "maTruong": "79777W40",
                           "tenTruong": "NTĐL Hoàn Mỹ"
                         },
                         {
                           "maTruong": "79777W41",
                           "tenTruong": "NTĐL Hoàng An"
                         },
                         {
                           "maTruong": "79777LD7",
                           "tenTruong": "NTĐL Hoàng Hạc"
                         },
                         {
                           "maTruong": "79777LB8",
                           "tenTruong": "NTĐL Hoàng Linh"
                         },
                         {
                           "maTruong": "79777WB1",
                           "tenTruong": "NTĐL Hoàng My"
                         },
                         {
                           "maTruong": "79777LI6",
                           "tenTruong": "NTĐL Hoàng Nhi"
                         },
                         {
                           "maTruong": "79777W42",
                           "tenTruong": "NTĐL Hoàng Thy"
                         },
                         {
                           "maTruong": "79777LG1",
                           "tenTruong": "NTĐL Hoàng Trang"
                         },
                         {
                           "maTruong": "79777W43",
                           "tenTruong": "NTĐL Hồng Ân"
                         },
                         {
                           "maTruong": "79777LJ0",
                           "tenTruong": "NTĐL Hồng Anh"
                         },
                         {
                           "maTruong": "79777W44",
                           "tenTruong": "NTĐL Hồng Đức"
                         },
                         {
                           "maTruong": "79777LM0",
                           "tenTruong": "NTĐL Hồng Khiêm"
                         },
                         {
                           "maTruong": "79777LL5",
                           "tenTruong": "NTĐL Hồng Minh"
                         },
                         {
                           "maTruong": "79777LM2",
                           "tenTruong": "NTĐL Hồng Ngân"
                         },
                         {
                           "maTruong": "79777W45",
                           "tenTruong": "NTĐL Hồng Nhung"
                         },
                         {
                           "maTruong": "79777W46",
                           "tenTruong": "NTĐL Hồng Phượng"
                         },
                         {
                           "maTruong": "79777LL9",
                           "tenTruong": "NTĐL Hồng Xuyến"
                         },
                         {
                           "maTruong": "79777LB4",
                           "tenTruong": "NTĐL Hồng Yến"
                         },
                         {
                           "maTruong": "79777LK3",
                           "tenTruong": "NTĐL Hu gô"
                         },
                         {
                           "maTruong": "79777WA2",
                           "tenTruong": "NTĐL Hướng Dương"
                         },
                         {
                           "maTruong": "79777LI0",
                           "tenTruong": "NTĐL Hương Lài"
                         },
                         {
                           "maTruong": "79777LL8",
                           "tenTruong": "NTĐL Hương Quỳnh"
                         },
                         {
                           "maTruong": "79777LN8",
                           "tenTruong": "NTĐL Huy Hoàng"
                         },
                         {
                           "maTruong": "79777LG2",
                           "tenTruong": "NTĐL Huyền Trang"
                         },
                         {
                           "maTruong": "79777W47",
                           "tenTruong": "NTĐL Huyền Vy"
                         },
                         {
                           "maTruong": "79777LD8",
                           "tenTruong": "NTĐL Khai Trí"
                         },
                         {
                           "maTruong": "79777LL2",
                           "tenTruong": "NTĐL Kiều Châu"
                         },
                         {
                           "maTruong": "79777LG8",
                           "tenTruong": "NTĐL Kim Nhung"
                         },
                         {
                           "maTruong": "79777WA6",
                           "tenTruong": "NTĐL Kim Phụng"
                         },
                         {
                           "maTruong": "79777LJ2",
                           "tenTruong": "NTĐL Kim Thi"
                         },
                         {
                           "maTruong": "79777LD9",
                           "tenTruong": "NTĐL Lá Xanh"
                         },
                         {
                           "maTruong": "79777LI5",
                           "tenTruong": "NTĐL Lạc Quang"
                         },
                         {
                           "maTruong": "79777LK9",
                           "tenTruong": "NTĐL Lam Hồng"
                         },
                         {
                           "maTruong": "79777W49",
                           "tenTruong": "NTĐL Liên Khu"
                         },
                         {
                           "maTruong": "79777W50",
                           "tenTruong": "NTĐL Linh Chi"
                         },
                         {
                           "maTruong": "79777LS2",
                           "tenTruong": "NTĐL Lộc Sen"
                         },
                         {
                           "maTruong": "79777LJ1",
                           "tenTruong": "NTĐL Lucky ABC"
                         },
                         {
                           "maTruong": "79777LM4",
                           "tenTruong": "NTĐL Ly Ly"
                         },
                         {
                           "maTruong": "79777LM6",
                           "tenTruong": "NTĐL Mai Hồng"
                         },
                         {
                           "maTruong": "79777W51",
                           "tenTruong": "NTĐL Mai Lan"
                         },
                         {
                           "maTruong": "79777WA8",
                           "tenTruong": "NTĐL Mai Quỳnh"
                         },
                         {
                           "maTruong": "79777LN2",
                           "tenTruong": "NTĐL Mầm non Việt"
                         },
                         {
                           "maTruong": "79777LH2",
                           "tenTruong": "NTĐL Mầm Non Xanh"
                         },
                         {
                           "maTruong": "79777W52",
                           "tenTruong": "NTĐL Mầm Xanh"
                         },
                         {
                           "maTruong": "79777W53",
                           "tenTruong": "NTĐL Măng Non"
                         },
                         {
                           "maTruong": "79777LK6",
                           "tenTruong": "NTĐL Mặt Trời Bé Con"
                         },
                         {
                           "maTruong": "79777LI4",
                           "tenTruong": "NTĐL Mặt Trời Đỏ"
                         },
                         {
                           "maTruong": "79777LL3",
                           "tenTruong": "NTĐL Mẫu Đơn"
                         },
                         {
                           "maTruong": "79777W55",
                           "tenTruong": "NTĐL Mây Hồng"
                         },
                         {
                           "maTruong": "79777LE0",
                           "tenTruong": "NTĐL Mimi"
                         },
                         {
                           "maTruong": "79777W56",
                           "tenTruong": "NTĐL Mimosa"
                         },
                         {
                           "maTruong": "79777W57",
                           "tenTruong": "NTĐL Minh Đức"
                         },
                         {
                           "maTruong": "79777LI9",
                           "tenTruong": "NTĐL Minh Khang"
                         },
                         {
                           "maTruong": "79777LI7",
                           "tenTruong": "NTĐL Minh Phú"
                         },
                         {
                           "maTruong": "79777LF2",
                           "tenTruong": "NTĐL Minh Phúc"
                         },
                         {
                           "maTruong": "79777LI8",
                           "tenTruong": "NTĐL Minh Thái"
                         },
                         {
                           "maTruong": "79777LQ9",
                           "tenTruong": "NTĐL Minh Trí"
                         },
                         {
                           "maTruong": "79777LM1",
                           "tenTruong": "NTĐL Minh Trúc"
                         },
                         {
                           "maTruong": "79777LF9",
                           "tenTruong": "NTĐL My Anh"
                         },
                         {
                           "maTruong": "79777LO9",
                           "tenTruong": "NTĐL Mỹ Tiên"
                         },
                         {
                           "maTruong": "79777LN6",
                           "tenTruong": "NTĐL Nai Ngọc"
                         },
                         {
                           "maTruong": "79777W58",
                           "tenTruong": "NTĐL Nai Vàng"
                         },
                         {
                           "maTruong": "79777LR0",
                           "tenTruong": "NTĐL Nắng Hồng"
                         },
                         {
                           "maTruong": "79777W59",
                           "tenTruong": "NTĐL Nắng Mai"
                         },
                         {
                           "maTruong": "79777LN5",
                           "tenTruong": "NTĐL Nắng Vàng"
                         },
                         {
                           "maTruong": "79777W60",
                           "tenTruong": "NTĐL Ngọc Hiền"
                         },
                         {
                           "maTruong": "79777W61",
                           "tenTruong": "NTĐL Ngọc Lan"
                         },
                         {
                           "maTruong": "79777LE2",
                           "tenTruong": "NTĐL Ngọc Thủy"
                         },
                         {
                           "maTruong": "79777LN7",
                           "tenTruong": "NTĐL Ngôi Nhà Hạnh Phúc"
                         },
                         {
                           "maTruong": "79777LH9",
                           "tenTruong": "NTĐL Ngôi Nhà Xanh"
                         },
                         {
                           "maTruong": "79777LB5",
                           "tenTruong": "NTĐL Ngôi Sao Việt (BTĐ)"
                         },
                         {
                           "maTruong": "79777W62",
                           "tenTruong": "NTĐL Ngôi Sao Việt (TT)"
                         },
                         {
                           "maTruong": "79777LL4",
                           "tenTruong": "NTĐL Ngôi Sao Xanh"
                         },
                         {
                           "maTruong": "79777LR9",
                           "tenTruong": "NTĐL Nguyên Bảo"
                         },
                         {
                           "maTruong": "79777LS9",
                           "tenTruong": "NTĐL Nhã Ca"
                         },
                         {
                           "maTruong": "79777W63",
                           "tenTruong": "NTĐL Nhật Anh"
                         },
                         {
                           "maTruong": "79777LH7",
                           "tenTruong": "NTĐL Nhất Lan"
                         },
                         {
                           "maTruong": "79777WA7",
                           "tenTruong": "NTĐL Nhật Minh"
                         },
                         {
                           "maTruong": "79777LJ7",
                           "tenTruong": "NTĐl Như Ngọc"
                         },
                         {
                           "maTruong": "79777W64",
                           "tenTruong": "NTĐL NHƯ Ý"
                         },
                         {
                           "maTruong": "79777LJ3",
                           "tenTruong": "NTĐL Ong Vàng"
                         },
                         {
                           "maTruong": "79777LQ7",
                           "tenTruong": "NTĐL Phước An"
                         },
                         {
                           "maTruong": "79777LG9",
                           "tenTruong": "NTĐL Phương An"
                         },
                         {
                           "maTruong": "79777LM5",
                           "tenTruong": "NTĐL Phương Nam"
                         },
                         {
                           "maTruong": "79777W65",
                           "tenTruong": "NTĐL Phương Quỳnh"
                         },
                         {
                           "maTruong": "79777LN4",
                           "tenTruong": "NTĐL Phượng Rubi"
                         },
                         {
                           "maTruong": "79777LQ6",
                           "tenTruong": "NTĐL Phương Trâm"
                         },
                         {
                           "maTruong": "79777W66",
                           "tenTruong": "NTĐL Phượng Vỹ"
                         },
                         {
                           "maTruong": "79777LP1",
                           "tenTruong": "NTĐL Quang Minh"
                         },
                         {
                           "maTruong": "79777LE4",
                           "tenTruong": "NTĐL Quỳnh Như"
                         },
                         {
                           "maTruong": "79777LC0",
                           "tenTruong": "NTĐL Ru Tơ"
                         },
                         {
                           "maTruong": "79777W69",
                           "tenTruong": "NTĐL RuBy"
                         },
                         {
                           "maTruong": "79777LR6",
                           "tenTruong": "NTĐL Sao Biển"
                         },
                         {
                           "maTruong": "79777W70",
                           "tenTruong": "NTĐL Sao Kim"
                         },
                         {
                           "maTruong": "79777W71",
                           "tenTruong": "NTĐL Sao Mai"
                         },
                         {
                           "maTruong": "79777LR4",
                           "tenTruong": "NTĐL Sao Sáng"
                         },
                         {
                           "maTruong": "79777W72",
                           "tenTruong": "NTĐL Sao Vàng"
                         },
                         {
                           "maTruong": "79777W73",
                           "tenTruong": "NTĐL Sao Việt"
                         },
                         {
                           "maTruong": "79777W74",
                           "tenTruong": "NTĐL Sen Non"
                         },
                         {
                           "maTruong": "79777LH3",
                           "tenTruong": "NTĐL Sóc Con"
                         },
                         {
                           "maTruong": "79777W76",
                           "tenTruong": "NTĐL Song Hương"
                         },
                         {
                           "maTruong": "79777LE5",
                           "tenTruong": "NTĐL Song Nghi"
                         },
                         {
                           "maTruong": "79777W77",
                           "tenTruong": "NTĐL Su Su"
                         },
                         {
                           "maTruong": "79777LN0",
                           "tenTruong": "NTĐL Tài Năng Việt"
                         },
                         {
                           "maTruong": "79777LE6",
                           "tenTruong": "NTĐL Tâm Tâm Đức"
                         },
                         {
                           "maTruong": "79777LK0",
                           "tenTruong": "NTĐL Tân Việt Úc"
                         },
                         {
                           "maTruong": "79777W78",
                           "tenTruong": "NTĐL Táo Hồng"
                         },
                         {
                           "maTruong": "79777LL1",
                           "tenTruong": "NTĐL Thần Đồng Việt"
                         },
                         {
                           "maTruong": "79777LN1",
                           "tenTruong": "NTĐL Thần Đồng Việt Mỹ"
                         },
                         {
                           "maTruong": "79777W79",
                           "tenTruong": "NTĐL Thanh Bình"
                         },
                         {
                           "maTruong": "79777W80",
                           "tenTruong": "NTĐL Thanh Mỹ"
                         },
                         {
                           "maTruong": "79777LT0",
                           "tenTruong": "NTĐL Thanh Ngân"
                         },
                         {
                           "maTruong": "79777LP4",
                           "tenTruong": "NTĐL Thanh Ngọc"
                         },
                         {
                           "maTruong": "79777LM7",
                           "tenTruong": "NTĐL Thanh Thanh"
                         },
                         {
                           "maTruong": "79777WB0",
                           "tenTruong": "NTĐL Thanh Thư"
                         },
                         {
                           "maTruong": "79777LG7",
                           "tenTruong": "NTĐL Thảo Hiền"
                         },
                         {
                           "maTruong": "79777LS1",
                           "tenTruong": "NTĐL Thảo Linh"
                         },
                         {
                           "maTruong": "79777LK4",
                           "tenTruong": "NTĐL Thảo Nguyên"
                         },
                         {
                           "maTruong": "79777LG6",
                           "tenTruong": "NTĐL Thảo Quỳnh"
                         },
                         {
                           "maTruong": "79777W82",
                           "tenTruong": "NTĐL Thảo Vy"
                         },
                         {
                           "maTruong": "79777LO2",
                           "tenTruong": "NTĐL Thiên Ân"
                         },
                         {
                           "maTruong": "79777LE7",
                           "tenTruong": "NTĐL Thiên Hoa"
                         },
                         {
                           "maTruong": "79777W83",
                           "tenTruong": "NTĐL Thiên Nga"
                         },
                         {
                           "maTruong": "79777LL7",
                           "tenTruong": "NTĐL Thiên Nga Trắng"
                         },
                         {
                           "maTruong": "79777W84",
                           "tenTruong": "NTĐL Thiên Ngọc"
                         },
                         {
                           "maTruong": "79777W85",
                           "tenTruong": "NTĐL Thiên Phúc"
                         },
                         {
                           "maTruong": "79777W86",
                           "tenTruong": "NTĐL Thiên Thần"
                         },
                         {
                           "maTruong": "79777W87",
                           "tenTruong": "NTĐL Thiên Thần Nhỏ"
                         },
                         {
                           "maTruong": "79777LE1",
                           "tenTruong": "NTĐL Thiên Thần Việt"
                         },
                         {
                           "maTruong": "79777LQ3",
                           "tenTruong": "NTĐL Thiên Thiên Cát"
                         },
                         {
                           "maTruong": "79777LC2",
                           "tenTruong": "NTĐL Thiên Thiên Phúc"
                         },
                         {
                           "maTruong": "79777LF5",
                           "tenTruong": "NTĐL Thỏ Bông"
                         },
                         {
                           "maTruong": "79777LM8",
                           "tenTruong": "NTĐL Thỏ Nâu"
                         },
                         {
                           "maTruong": "79777LO3",
                           "tenTruong": "NTĐL Thỏ Ngọc"
                         },
                         {
                           "maTruong": "79777W88",
                           "tenTruong": "NTĐL Thỏ Trắng"
                         },
                         {
                           "maTruong": "79777W89",
                           "tenTruong": "NTĐL Thục Nguyên"
                         },
                         {
                           "maTruong": "79777LF6",
                           "tenTruong": "NTĐL Thùy Dương"
                         },
                         {
                           "maTruong": "79777LH6",
                           "tenTruong": "NTĐL Ti Ti"
                         },
                         {
                           "maTruong": "79777W90",
                           "tenTruong": "NTĐL Tin Tin"
                         },
                         {
                           "maTruong": "79777LS0",
                           "tenTruong": "NTĐL Tinh Khôi"
                         },
                         {
                           "maTruong": "79777LF8",
                           "tenTruong": "NTĐL Tỉnh Lộ 10"
                         },
                         {
                           "maTruong": "79777LP3",
                           "tenTruong": "NTĐL Trăng Nhỏ"
                         },
                         {
                           "maTruong": "79777W91",
                           "tenTruong": "NTĐL Trẻ Thơ Việt"
                         },
                         {
                           "maTruong": "79777LN9",
                           "tenTruong": "NTĐL Trí Tuệ"
                         },
                         {
                           "maTruong": "79777LK1",
                           "tenTruong": "NTĐL Trúc Xinh"
                         },
                         {
                           "maTruong": "79777LJ9",
                           "tenTruong": "NTĐL Tuệ Mỹ"
                         },
                         {
                           "maTruong": "79777W92",
                           "tenTruong": "NTĐL Tuổi Hồng"
                         },
                         {
                           "maTruong": "79777W93",
                           "tenTruong": "NTDL Tuổi Ngọc"
                         },
                         {
                           "maTruong": "79777W94",
                           "tenTruong": "NTĐL Tuổi Thơ"
                         },
                         {
                           "maTruong": "79777LP6",
                           "tenTruong": "NTĐL Tuổi Thơ Hồng"
                         },
                         {
                           "maTruong": "79777LQ5",
                           "tenTruong": "NTĐL Tuổi Tiên"
                         },
                         {
                           "maTruong": "79777LE8",
                           "tenTruong": "NTĐL Tuổi Xanh"
                         },
                         {
                           "maTruong": "79777LN3",
                           "tenTruong": "NTĐL Tương Lai Việt (BHHB)"
                         },
                         {
                           "maTruong": "79777LK5",
                           "tenTruong": "NTĐL Tương Lai Việt (TT)"
                         },
                         {
                           "maTruong": "79777LE9",
                           "tenTruong": "NTĐL Ty By"
                         },
                         {
                           "maTruong": "79777LF3",
                           "tenTruong": "NTĐL Ước Mơ"
                         },
                         {
                           "maTruong": "79777LP5",
                           "tenTruong": "NTĐL Ước Mơ Xanh"
                         },
                         {
                           "maTruong": "79777LB6",
                           "tenTruong": "NTĐL Ươm Mầm"
                         },
                         {
                           "maTruong": "79777LP7",
                           "tenTruong": "NTĐL Ươm Mầm Xanh"
                         },
                         {
                           "maTruong": "79777LF0",
                           "tenTruong": "NTĐL Việt Anh"
                         },
                         {
                           "maTruong": "79777LF1",
                           "tenTruong": "NTĐL Việt Anh Nhật"
                         },
                         {
                           "maTruong": "79777W97",
                           "tenTruong": "NTĐL Việt Bắc Mỹ"
                         },
                         {
                           "maTruong": "79777W99",
                           "tenTruong": "NTĐL Vy Vân"
                         },
                         {
                           "maTruong": "79777WA9",
                           "tenTruong": "NTĐL Vy Vân Nhi"
                         },
                         {
                           "maTruong": "79777LO4",
                           "tenTruong": "NTĐL Xuân Hồng"
                         },
                         {
                           "maTruong": "797773S1",
                           "tenTruong": "MNTT Trúc Việt"
                         },
                         {
                           "maTruong": "797773R9",
                           "tenTruong": "MNTT Thành Phố Tuổi Thơ"
                         },
                         {
                           "maTruong": "79777LU8",
                           "tenTruong": "NLĐL Thảo Nguyên Xanh"
                         },
                         {
                           "maTruong": "79777LU9",
                           "tenTruong": "NTĐL Thỏ Xinh"
                         },
                         {
                           "maTruong": "79777LT7",
                           "tenTruong": "NTĐL Phương Việt"
                         },
                         {
                           "maTruong": "79777LU2",
                           "tenTruong": "NTĐL Sư Tử Con"
                         },
                         {
                           "maTruong": "79777TXA",
                           "tenTruong": "NTĐL Táo Xanh"
                         },
                         {
                           "maTruong": "79777HPH",
                           "tenTruong": "NTĐL Hạnh Phúc"
                         },
                         {
                           "maTruong": "79777BCT",
                           "tenTruong": "NTĐL Bồ Câu Trắng"
                         },
                         {
                           "maTruong": "79777GPD",
                           "tenTruong": "MNTT Gấu Panda"
                         },
                         {
                           "maTruong": "79777106",
                           "tenTruong": "MNTT 1-6"
                         },
                         {
                           "maTruong": "79777bab",
                           "tenTruong": "NTĐL Baby"
                         },
                         {
                           "maTruong": "79777LNG",
                           "tenTruong": "NTĐL LÁ NGỌC"
                         },
                         {
                           "maTruong": "79777TVM",
                           "tenTruong": "MNTT Tân Việt Mỹ"
                         },
                         {
                           "maTruong": "79777NMY",
                           "tenTruong": "MNTT Nam Mỹ"
                         },
                         {
                           "maTruong": "79777DQY",
                           "tenTruong": "NTĐL Duy Quỳnh"
                         },
                         {
                           "maTruong": "797773S0",
                           "tenTruong": "MNTT Hoa Hướng Dương Ehome3"
                         },
                         {
                           "maTruong": "79777LT9",
                           "tenTruong": "NTĐL Ngôi Nhà Thiên Thần"
                         },
                         {
                           "maTruong": "79777LU4",
                           "tenTruong": "NTĐL Bút Chì Màu"
                         },
                         {
                           "maTruong": "79777LU5",
                           "tenTruong": "NTĐL Mái Nhà Xanh"
                         },
                         {
                           "maTruong": "79777LT1",
                           "tenTruong": "NTĐL Tương Lai Xanh"
                         },
                         {
                           "maTruong": "79777LU1",
                           "tenTruong": "NTĐL Tân Mai"
                         },
                         {
                           "maTruong": "79777LT2",
                           "tenTruong": "NTĐL Con Mèo Vàng"
                         },
                         {
                           "maTruong": "79777LU7",
                           "tenTruong": "NTĐL Nhật Đoan"
                         },
                         {
                           "maTruong": "79777LT6",
                           "tenTruong": "NTĐL Hương Nhi"
                         },
                         {
                           "maTruong": "79777LT4",
                           "tenTruong": "NTĐL Kim Hoàng"
                         },
                         {
                           "maTruong": "79777LV0",
                           "tenTruong": "NTĐL Chíp bông"
                         },
                         {
                           "maTruong": "79777LU0",
                           "tenTruong": "NTĐL Sóc Nâu Con"
                         },
                         {
                           "maTruong": "79777LT8",
                           "tenTruong": "NTĐL Nụ Hồng"
                         },
                         {
                           "maTruong": "797773S2",
                           "tenTruong": "MNTT Vườn Tuổi Thơ"
                         },
                         {
                           "maTruong": "79777LT3",
                           "tenTruong": "NTĐL Hải Oanh"
                         },
                         {
                           "maTruong": "797773S4",
                           "tenTruong": "MNCL Thiên Tuế"
                         },
                         {
                           "maTruong": "797773S5",
                           "tenTruong": "MNCL Cát Đằng"
                         },
                         {
                           "maTruong": "797773S6",
                           "tenTruong": "MNTT Ngôi Sao Đỏ"
                         },
                         {
                           "maTruong": "797773S7",
                           "tenTruong": "MNTT Nam Hùng Vương 2"
                         },
                         {
                           "maTruong": "797773S8",
                           "tenTruong": "MNTT Quốc tế Thế giới Trẻ Thơ"
                         },
                         {
                           "maTruong": "797773S9",
                           "tenTruong": "MNTT Bước Chân Đầu Tiên"
                         },
                         {
                           "maTruong": "79777LV1",
                           "tenTruong": "NTĐL Ái Nhi"
                         },
                         {
                           "maTruong": "79777LV2",
                           "tenTruong": "NTĐL Hoa Vàng"
                         },
                         {
                           "maTruong": "79777LV4",
                           "tenTruong": "NTĐL Hai Ngôi Sao Nhỏ"
                         },
                         {
                           "maTruong": "79777LV5",
                           "tenTruong": "NTĐL Mickey"
                         },
                         {
                           "maTruong": "79777LV6",
                           "tenTruong": "NTĐL Bé Vui"
                         },
                         {
                           "maTruong": "79777LV7",
                           "tenTruong": "NTĐL Gấu Bông"
                         },
                         {
                           "maTruong": "79777LV8",
                           "tenTruong": "NTĐL Sao Mai Vàng"
                         },
                         {
                           "maTruong": "797773S3",
                           "tenTruong": "MNCL HỒNG NGỌC"
                         },
                         {
                           "maTruong": "79777HNA",
                           "tenTruong": "MNTT Hoa Nắng"
                         },
                         {
                           "maTruong": "79777CKV",
                           "tenTruong": "MNTT Chìa Khóa Vàng"
                         },
                         {
                           "maTruong": "79777BTT",
                           "tenTruong": "NTDL Bé Tin Tin"
                         },
                         {
                           "maTruong": "79777GTT",
                           "tenTruong": "NTĐL Góc Trẻ Thơ"
                         },
                         {
                           "maTruong": "79777NMH",
                           "tenTruong": "NTĐL Nắng Mai Hồng"
                         },
                         {
                           "maTruong": "79777HLA",
                           "tenTruong": "NTĐL Hoa Lài"
                         },
                         {
                           "maTruong": "79777379",
                           "tenTruong": "MNTT Bay"
                         },
                         {
                           "maTruong": "79777MDU",
                           "tenTruong": "NTĐL Mỹ Đức"
                         },
                         {
                           "maTruong": "79777PHM",
                           "tenTruong": "NTĐL Phương My"
                         },
                         {
                           "maTruong": "79777HVA",
                           "tenTruong": "NTĐL Hươu Vàng"
                         },
                         {
                           "maTruong": "79777NCV",
                           "tenTruong": "NTĐL Nụ Cười Vàng"
                         },
                         {
                           "maTruong": "79777THD",
                           "tenTruong": "NTĐL Thùy Dung"
                         },
                         {
                           "maTruong": "79777TTU",
                           "tenTruong": "MNTT Tinh Tú"
                         },
                         {
                           "maTruong": "79777MYT",
                           "tenTruong": "MNTT Học viện Miền yêu thương"
                         },
                         {
                           "maTruong": "79777THT",
                           "tenTruong": "MNTT Thanh Thư"
                         },
                         {
                           "maTruong": "79777DMI",
                           "tenTruong": "NTĐL Đức Minh"
                         },
                         {
                           "maTruong": "79777MOU",
                           "tenTruong": "NTĐL Mơ ước"
                         },
                         {
                           "maTruong": "79777THN",
                           "tenTruong": "NTĐL Thiện Nhân"
                         },
                         {
                           "maTruong": "79777DOC",
                           "tenTruong": "NTĐL Đô ca"
                         },
                         {
                           "maTruong": "79777VAA",
                           "tenTruong": "NTĐL Vân Anh"
                         },
                         {
                           "maTruong": "79777BHP",
                           "tenTruong": "NTĐL Bé Hạnh Phúc"
                         },
                         {
                           "maTruong": "79777NHY",
                           "tenTruong": "NTĐL Nhật Hy"
                         },
                         {
                           "maTruong": "7977701001",
                           "tenTruong": "MNTT Trúc Việt 2"
                         },
                         {
                           "maTruong": "7977701002",
                           "tenTruong": "NTĐL Thanh Xuân"
                         },
                         {
                           "maTruong": "7977701003",
                           "tenTruong": "NTĐL Tân Việt Nhật"
                         },
                         {
                           "maTruong": "7977701004",
                           "tenTruong": "NTĐL Rạng Đông (BHHA)"
                         },
                         {
                           "maTruong": "7977701006",
                           "tenTruong": "NTĐL Mây Vàng"
                         },
                         {
                           "maTruong": "7977701007",
                           "tenTruong": "NTĐL Vương Quốc Trẻ Thơ"
                         },
                         {
                           "maTruong": "7977701008",
                           "tenTruong": "MNTT Câu lạc bộ Trẻ Thơ"
                         },
                         {
                           "maTruong": "7977701009",
                           "tenTruong": "MNTT Sao Mai Vàng"
                         },
                         {
                           "maTruong": "79777SVI",
                           "tenTruong": "NTĐL Sen Việt"
                         },
                         {
                           "maTruong": "79777427",
                           "tenTruong": "THCL Bùi Hữu Nghĩa"
                         },
                         {
                           "maTruong": "79777173",
                           "tenTruong": "NTĐL Hoa Sen Vàng"
                         },
                         {
                           "maTruong": "79777515",
                           "tenTruong": "THCSCL Lạc Long Quân"
                         },
                         {
                           "maTruong": "79777078",
                           "tenTruong": "NTĐL Hoa Trạng Nguyên"
                         },
                         {
                           "maTruong": "79777161",
                           "tenTruong": "NTĐL Nhân Hà"
                         },
                         {
                           "maTruong": "79777241",
                           "tenTruong": "NTĐL Ánh Minh"
                         },
                         {
                           "maTruong": "79777224",
                           "tenTruong": "NTĐL Thiên Ý"
                         },
                         {
                           "maTruong": "79777221",
                           "tenTruong": "NTĐL Vườn Tài Năng"
                         },
                         {
                           "maTruong": "79777128",
                           "tenTruong": "NTĐL Ban Mai Xanh"
                         },
                         {
                           "maTruong": "79777265",
                           "tenTruong": "MNTT Phú Sĩ"
                         },
                         {
                           "maTruong": "79777445",
                           "tenTruong": "THTT Lá Xanh"
                         },
                         {
                           "maTruong": "7977701016",
                           "tenTruong": "NTĐL Hoa Mai Vàng"
                         },
                         {
                           "maTruong": "7977701021",
                           "tenTruong": "Lớp Mầm non Minh Tâm"
                         },
                         {
                           "maTruong": "7977701010",
                           "tenTruong": "NTĐL Vườn Ước Mơ"
                         },
                         {
                           "maTruong": "7977701011",
                           "tenTruong": "NTĐL Hoàng Gia Phú Lâm"
                         },
                         {
                           "maTruong": "7977701012",
                           "tenTruong": "NTĐL An Đức"
                         },
                         {
                           "maTruong": "7977701013",
                           "tenTruong": "NTĐL Hoàng Gia II"
                         },
                         {
                           "maTruong": "7977701014",
                           "tenTruong": "NTĐL Hoa Mặt Trời"
                         },
                         {
                           "maTruong": "7977701015",
                           "tenTruong": "NTĐL Anh Cầu Vồng"
                         },
                         {
                           "maTruong": "7977701018",
                           "tenTruong": "NTĐL Minh Đức"
                         },
                         {
                           "maTruong": "7977701019",
                           "tenTruong": "NTĐL Chú Ong Nhỏ"
                         },
                         {
                           "maTruong": "7977701020",
                           "tenTruong": "NTĐL Khánh Vy"
                         },
                         {
                           "maTruong": "7977702001",
                           "tenTruong": "Trường Tiểu học Lương Thế Vinh"
                         },
                         {
                           "maTruong": "7977701017",
                           "tenTruong": "NTĐL Ngôi Nhà Nhỏ"
                         },
                         {
                           "maTruong": "79777137",
                           "tenTruong": "MNTT Việt Mỹ 2"
                         },
                         {
                           "maTruong": "79777229",
                           "tenTruong": "MNTT Hoa Nhi Lam"
                         },
                         {
                           "maTruong": "7977701055",
                           "tenTruong": "NTĐL Trẻ Thơ"
                         },
                         {
                           "maTruong": "7977701056",
                           "tenTruong": "NTĐL Hoa Sen Nhỏ"
                         },
                         {
                           "maTruong": "7977701057",
                           "tenTruong": "NTĐL Hương Vy"
                         },
                         {
                           "maTruong": "7977701058",
                           "tenTruong": "NTĐL Việt Úc"
                         },
                         {
                           "maTruong": "7977702002",
                           "tenTruong": "Trường Tiểu học Kiến Tạo"
                         },
                         {
                           "maTruong": "7977701054",
                           "tenTruong": "NTĐL Ánh Dương"
                         },
                         {
                           "maTruong": "7977701022",
                           "tenTruong": "NTĐL Bầu Trời Xanh"
                         },
                         {
                           "maTruong": "7977701032",
                           "tenTruong": "NLĐL Tương lai tươi sáng"
                         },
                         {
                           "maTruong": "7977701033",
                           "tenTruong": "MNTT Hoàng Gia"
                         },
                         {
                           "maTruong": "7977701034",
                           "tenTruong": "NTĐL Liên Khu 2"
                         },
                         {
                           "maTruong": "7977701035",
                           "tenTruong": "NTĐL Hồng Phúc"
                         },
                         {
                           "maTruong": "7977701037",
                           "tenTruong": "NTĐL Âu Việt"
                         },
                         {
                           "maTruong": "7977701038",
                           "tenTruong": "NTĐL Vườn Cổ Tích"
                         },
                         {
                           "maTruong": "7977701042",
                           "tenTruong": "NTĐL Ánh Trăng"
                         },
                         {
                           "maTruong": "7977701043",
                           "tenTruong": "NTĐL Cửa Sổ Vàng"
                         },
                         {
                           "maTruong": "7977701044",
                           "tenTruong": "NTĐL Anh Khôi"
                         },
                         {
                           "maTruong": "7977701045",
                           "tenTruong": "NTĐL Bon Bon"
                         },
                         {
                           "maTruong": "7977701046",
                           "tenTruong": "NTĐL Sóc Nâu"
                         },
                         {
                           "maTruong": "7977701047",
                           "tenTruong": "NTĐL Nhất Lan"
                         },
                         {
                           "maTruong": "7977701048",
                           "tenTruong": "NTĐL Ngọc Vy"
                         },
                         {
                           "maTruong": "7977701049",
                           "tenTruong": "NTĐL Ngôi Nhà Trẻ Thơ"
                         },
                         {
                           "maTruong": "7977701050",
                           "tenTruong": "NTĐL Cánh Én Tuổi Thơ"
                         },
                         {
                           "maTruong": "7977701052",
                           "tenTruong": "NTĐL Ánh Thái Dương"
                         },
                         {
                           "maTruong": "7977701053",
                           "tenTruong": "NTĐL Bầu Trời Ước Mơ"
                         },
                         {
                           "maTruong": "7977701031",
                           "tenTruong": "Trường Mầm non Tân Việt Mỹ II"
                         },
                         {
                           "maTruong": "7977701023",
                           "tenTruong": "Lớp Mầm non ABC"
                         },
                         {
                           "maTruong": "7977701025",
                           "tenTruong": "NTĐL Mặt Trời Trẻ Thơ"
                         },
                         {
                           "maTruong": "7977701026",
                           "tenTruong": "NTĐL Cherry"
                         },
                         {
                           "maTruong": "7977701027",
                           "tenTruong": "NTĐL Ngày Mới"
                         },
                         {
                           "maTruong": "7977701028",
                           "tenTruong": "NTĐL Ngôi Sao Xinh"
                         },
                         {
                           "maTruong": "7977701029",
                           "tenTruong": "NTĐL Bé Hạnh Phúc"
                         },
                         {
                           "maTruong": "7977701030",
                           "tenTruong": "NTĐL Ngôi Nhà Tuổi Thơ"
                         },
                         {
                           "maTruong": "79777BTR",
                           "tenTruong": "NTĐL Bé Trăng"
                         },
                         {
                           "maTruong": "79777MIA",
                           "tenTruong": "NTĐL Minh Anh"
                         },
                         {
                           "maTruong": "7977703002",
                           "tenTruong": "THCS Bình Trị Đông B"
                         },
                         {
                           "maTruong": "7977702003",
                           "tenTruong": "Trường Tiểu học quốc tế song ngữ Mỹ Việt"
                         },
                         {
                           "maTruong": "7977702004",
                           "tenTruong": "TH Nguyễn Công Trứ"
                         },
                         {
                           "maTruong": "7977702005",
                           "tenTruong": "TH Trần Cao Vân"
                         },
                         {
                           "maTruong": "7977702006",
                           "tenTruong": "TH Đinh Công Tráng"
                         },
                         {
                           "maTruong": "7977702007",
                           "tenTruong": "TH Huỳnh Thúc Kháng"
                         },
                         {
                           "maTruong": "7977702008",
                           "tenTruong": "TH Hoàng Văn Thụ"
                         },
                         {
                           "maTruong": "7977701087",
                           "tenTruong": "LMN Cánh én tuổi thơ"
                         },
                         {
                           "maTruong": "7977701088",
                           "tenTruong": "LMN Thảo Hiếu"
                         },
                         {
                           "maTruong": "7977701089",
                           "tenTruong": "LMG Hoàng Dung"
                         },
                         {
                           "maTruong": "7977701090",
                           "tenTruong": "LMG Vành Khuyên"
                         },
                         {
                           "maTruong": "7977701091",
                           "tenTruong": "LMN Sáng Trí"
                         },
                         {
                           "maTruong": "7977701092",
                           "tenTruong": "LMG Thiên Nga trắng"
                         },
                         {
                           "maTruong": "7977701093",
                           "tenTruong": "LMN Hoa Hướng Dương"
                         },
                         {
                           "maTruong": "7977701094",
                           "tenTruong": "LMN Khu Vườn Nhỏ"
                         },
                         {
                           "maTruong": "7977701095",
                           "tenTruong": "LMN Táo Yêu Thương"
                         },
                         {
                           "maTruong": "7977701096",
                           "tenTruong": "LMG Anh Mỹ"
                         },
                         {
                           "maTruong": "7977701097",
                           "tenTruong": "LMN Dê Con"
                         },
                         {
                           "maTruong": "7977701098",
                           "tenTruong": "LMG Hoa Cúc"
                         },
                         {
                           "maTruong": "7977701099",
                           "tenTruong": "LMN Vàng Anh"
                         },
                         {
                           "maTruong": "7977701100",
                           "tenTruong": "MNTT Mặt Trời Trẻ Thơ"
                         },
                         {
                           "maTruong": "7977701101",
                           "tenTruong": "LMG Bé Yêu Hạnh Phúc"
                         },
                         {
                           "maTruong": "7977701102",
                           "tenTruong": "LMN Thần Tượng Nhí"
                         },
                         {
                           "maTruong": "7977703001",
                           "tenTruong": "THCS TT VSchool"
                         },
                         {
                           "maTruong": "7977701105",
                           "tenTruong": "LMN Thiên Phúc"
                         },
                         {
                           "maTruong": "7977701106",
                           "tenTruong": "LMG Hoàng Mai"
                         },
                         {
                           "maTruong": "7977701107",
                           "tenTruong": "LMN Ánh Sáng"
                         },
                         {
                           "maTruong": "7977701108",
                           "tenTruong": "LMN Nụ Hồng"
                         },
                         {
                           "maTruong": "7977701104",
                           "tenTruong": "MNTT Đại Dương Xanh"
                         },
                         {
                           "maTruong": "7977701068",
                           "tenTruong": "LMG Ân Điển"
                         },
                         {
                           "maTruong": "7977701069",
                           "tenTruong": "LMN Trí Tuệ"
                         },
                         {
                           "maTruong": "7977701070",
                           "tenTruong": "LMN Nô Bi Ta"
                         },
                         {
                           "maTruong": "7977701071",
                           "tenTruong": "LMN Ngôi Sao Toàn Cầu"
                         },
                         {
                           "maTruong": "7977701072",
                           "tenTruong": "LMN Á Châu (BTĐ)"
                         },
                         {
                           "maTruong": "7977701073",
                           "tenTruong": "LMN Hoa Thiên Phúc"
                         },
                         {
                           "maTruong": "7977701074",
                           "tenTruong": "LMN Là Vì Em"
                         },
                         {
                           "maTruong": "7977701075",
                           "tenTruong": "LMN Việt Anh"
                         },
                         {
                           "maTruong": "7977701076",
                           "tenTruong": "LMN Mặt Trời Đỏ"
                         },
                         {
                           "maTruong": "7977701077",
                           "tenTruong": "LMN Vườn Trẻ Thơ"
                         },
                         {
                           "maTruong": "7977701078",
                           "tenTruong": "LMG Trí Đức"
                         },
                         {
                           "maTruong": "7977701079",
                           "tenTruong": "LMN Tài Năng Nhí"
                         },
                         {
                           "maTruong": "7977701080",
                           "tenTruong": "LMN Ngôi Sao Tuổi Thơ"
                         },
                         {
                           "maTruong": "7977701081",
                           "tenTruong": "LMN Mèo Kitty"
                         },
                         {
                           "maTruong": "7977701082",
                           "tenTruong": "LMN Sư Tử Nhỏ"
                         },
                         {
                           "maTruong": "7977701083",
                           "tenTruong": "LMG Vườn Yêu Thương"
                         },
                         {
                           "maTruong": "7977701084",
                           "tenTruong": "LMG Hằng Nga"
                         },
                         {
                           "maTruong": "7977701085",
                           "tenTruong": "LMG Thần Đồng Việt Mỹ"
                         },
                         {
                           "maTruong": "7977701086",
                           "tenTruong": "LMN Ngôi Nhà Bé Thơ"
                         },
                         {
                           "maTruong": "7977701061",
                           "tenTruong": "LMN Mặt Trời Vàng"
                         },
                         {
                           "maTruong": "7977701062",
                           "tenTruong": "LMN Cá Heo Nhỏ"
                         },
                         {
                           "maTruong": "7977701063",
                           "tenTruong": "LMG Mộc Nhiên"
                         },
                         {
                           "maTruong": "7977701064",
                           "tenTruong": "LMN Á Châu (TT)"
                         },
                         {
                           "maTruong": "7977701065",
                           "tenTruong": "LMG Chim Non"
                         },
                         {
                           "maTruong": "7977701066",
                           "tenTruong": "LMN Cánh Diều"
                         },
                         {
                           "maTruong": "7977701067",
                           "tenTruong": "LMN Miền Tuổi Thơ"
                         },
                         {
                           "maTruong": "7977701036",
                           "tenTruong": "LMN Cối Xay Gió"
                         },
                         {
                           "maTruong": "7977701039",
                           "tenTruong": "LMG LaVi"
                         },
                         {
                           "maTruong": "7977701040",
                           "tenTruong": "LMG Bảo Lam"
                         },
                         {
                           "maTruong": "7977701060",
                           "tenTruong": "LMN Kim Nhung"
                         }
                       ]
                     },
                     {
                       "maPhong": null,
                       "tenPhong": "Huyện Nhà Bè",
                       "truongData": [
                         {
                           "maTruong": "790007B7",
                           "tenTruong": "THPT Dương Văn Dương"
                         },
                         {
                           "maTruong": "79786701",
                           "tenTruong": "THPT Long Thới"
                         },
                         {
                           "maTruong": "79000784",
                           "tenTruong": "THPT Phước Kiển"
                         },
                         {
                           "maTruong": "79786901",
                           "tenTruong": "TT GDTX H Nhà Bè"
                         },
                         {
                           "maTruong": "7900001020",
                           "tenTruong": "Mầm Non Ánh Sao"
                         },
                         {
                           "maTruong": "7900004032",
                           "tenTruong": "Tiểu học, THCS và THPT Quốc tế Mỹ"
                         },
                         {
                           "maTruong": "7900004034",
                           "tenTruong": "TH, THCS và THPT Khải Hoàn - Nam Sài Gòn"
                         }
                       ]
                     },
                     {
                       "maPhong": null,
                       "tenPhong": "Quận 4",
                       "truongData": [
                         {
                           "maTruong": "79000842",
                           "tenTruong": "THPT Nguyễn Hữu Thọ"
                         },
                         {
                           "maTruong": "79000706",
                           "tenTruong": "THPT Nguyễn Trãi"
                         },
                         {
                           "maTruong": "79773901",
                           "tenTruong": "TRUNG TÂM GIÁO DỤC NGHỀ NGHIỆP - GIÁO DỤC THƯỜNG XUYÊN QUẬN 4"
                         },
                         {
                           "maTruong": "7900004008",
                           "tenTruong": "Trường Chuyên biệt 1 Tháng 6 (Quận 4)"
                         },
                         {
                           "maTruong": "7900005005",
                           "tenTruong": "TT GDNN-GDTX QUẬN 4( LỚP LIÊN KẾT DẠY VĂN HOÁ CHO TRƯỜNG TRUNG CẤP, CAO ĐẲNG)"
                         }
                       ]
                     },
                     {
                       "maPhong": null,
                       "tenPhong": "Quận 5",
                       "truongData": [
                         {
                           "maTruong": "79000776",
                           "tenTruong": "Phổ thông Năng Khiếu"
                         },
                         {
                           "maTruong": "79000F01",
                           "tenTruong": "THPT chuyên Lê Hồng Phong"
                         },
                         {
                           "maTruong": "79000708",
                           "tenTruong": "THPT Hùng Vương"
                         },
                         {
                           "maTruong": "79000751",
                           "tenTruong": "THPT Thăng Long"
                         },
                         {
                           "maTruong": "79000744",
                           "tenTruong": "THPT Trần Khai Nguyên"
                         },
                         {
                           "maTruong": "790007A5",
                           "tenTruong": "THPT Văn Lang"
                         },
                         {
                           "maTruong": "79000775",
                           "tenTruong": "Trung học Thực hành - ĐHSP"
                         },
                         {
                           "maTruong": "79000901",
                           "tenTruong": "TT GDTX Chu Văn An"
                         },
                         {
                           "maTruong": "79774901",
                           "tenTruong": "TT GDTX Q5"
                         },
                         {
                           "maTruong": "79000903",
                           "tenTruong": "TT GDTX Tiếng Hoa"
                         },
                         {
                           "maTruong": "79774507",
                           "tenTruong": "THPT Trần Hữu Trang"
                         },
                         {
                           "maTruong": "79000855",
                           "tenTruong": "THCS và THPT Quang Trung Nguyễn Huệ"
                         },
                         {
                           "maTruong": "79000814",
                           "tenTruong": "THCS và THPT An Đông"
                         },
                         {
                           "maTruong": "79000853",
                           "tenTruong": "Trung học thực hành Sài Gòn"
                         },
                         {
                           "maTruong": "79000B08",
                           "tenTruong": "TT GDTX Chu Văn An"
                         },
                         {
                           "maTruong": "7900004016",
                           "tenTruong": "Trường Chuyên biệt Tương Lai (Quận 5)"
                         },
                         {
                           "maTruong": "79000812",
                           "tenTruong": "THCS và THPT Nguyễn Bỉnh Khiêm"
                         }
                       ]
                     },
                     {
                       "maPhong": null,
                       "tenPhong": "Quận 6",
                       "truongData": [
                         {
                           "maTruong": "79000710",
                           "tenTruong": "THPT Bình Phú"
                         },
                         {
                           "maTruong": "79000709",
                           "tenTruong": "THPT Mạc Đĩnh Chi"
                         },
                         {
                           "maTruong": "790007A8",
                           "tenTruong": "THPT Nguyễn Tất Thành"
                         },
                         {
                           "maTruong": "79000875",
                           "tenTruong": "THPT Phạm Phú Thứ"
                         },
                         {
                           "maTruong": "79000788",
                           "tenTruong": "THPT Phú Lâm"
                         },
                         {
                           "maTruong": "79000797",
                           "tenTruong": "THPT Quốc Trí"
                         },
                         {
                           "maTruong": "79775901",
                           "tenTruong": "TT GDTX Q6"
                         },
                         {
                           "maTruong": "79000816175",
                           "tenTruong": "THCS-THPT Phan Bội Châu"
                         },
                         {
                           "maTruong": "79000866",
                           "tenTruong": "THCS và THPT Đào Duy Anh"
                         },
                         {
                           "maTruong": "79000816",
                           "tenTruong": "THCS-THPT Phan Bội Châu"
                         },
                         {
                           "maTruong": "7900004017",
                           "tenTruong": "Trường Chuyên biệt Hy vọng (Quận 6)"
                         }
                       ]
                     },
                     {
                       "maPhong": null,
                       "tenPhong": "Quận 1",
                       "truongData": [
                         {
                           "maTruong": "79000742",
                           "tenTruong": "THPT Ten Lơ Man"
                         },
                         {
                           "maTruong": "79000702",
                           "tenTruong": "THPT Trưng Vương"
                         },
                         {
                           "maTruong": "79760328",
                           "tenTruong": "MN 19/5 Thành phố"
                         },
                         {
                           "maTruong": "79000920",
                           "tenTruong": "BTVH Nhạc viện Tp Hồ Chí Minh"
                         },
                         {
                           "maTruong": "79000911",
                           "tenTruong": "BTVH TT HUẤN LUYỆN VÀ THI ĐẤU TDTT Tp Hồ Chí Minh"
                         },
                         {
                           "maTruong": "79000902",
                           "tenTruong": "TT GDTX Lê Quý Đôn"
                         },
                         {
                           "maTruong": "79760901",
                           "tenTruong": "TT GDTX Q1"
                         },
                         {
                           "maTruong": "79000701",
                           "tenTruong": "THPT Bùi Thị Xuân"
                         },
                         {
                           "maTruong": "79760419",
                           "tenTruong": "Tiểu học, THCS và THPT Quốc tế Á Châu"
                         },
                         {
                           "maTruong": "79000F02",
                           "tenTruong": "THPT chuyên Trần Đại Nghĩa"
                         },
                         {
                           "maTruong": "79000869",
                           "tenTruong": "THPT Lương Thế Vinh"
                         },
                         {
                           "maTruong": "79000871",
                           "tenTruong": "THPT NK Thể dục Thể thao"
                         },
                         {
                           "maTruong": "79000846",
                           "tenTruong": "THCS và THPT Châu Á Thái Bình Dương"
                         },
                         {
                           "maTruong": "79000809",
                           "tenTruong": "THCS và THPT Đăng Khoa"
                         },
                         {
                           "maTruong": "7900004009",
                           "tenTruong": "Trường Giáo dục Chuyên biệt Tương Lai (Quận 1)"
                         },
                         {
                           "maTruong": "7900004036",
                           "tenTruong": "THCS và THPT Trần Đại Nghĩa"
                         }
                       ]
                     },
                     {
                       "maPhong": null,
                       "tenPhong": "Quận 2",
                       "truongData": [
                         {
                           "maTruong": "79000703",
                           "tenTruong": "THPT Giồng Ông Tố"
                         },
                         {
                           "maTruong": "79000802",
                           "tenTruong": "THPT Thủ Thiêm"
                         },
                         {
                           "maTruong": "79769901",
                           "tenTruong": "TT GDTX Q2"
                         }
                       ]
                     },
                     {
                       "maPhong": "762",
                       "tenPhong": "Quận Thủ Đức",
                       "truongData": [
                         {
                           "maTruong": "79762429",
                           "tenTruong": "Nguyễn Văn Nở"
                         },
                         {
                           "maTruong": "79762410",
                           "tenTruong": "TH Bình Chiểu"
                         },
                         {
                           "maTruong": "79762415",
                           "tenTruong": "TH Bình Quới"
                         },
                         {
                           "maTruong": "79762404",
                           "tenTruong": "TH Bình Triệu"
                         },
                         {
                           "maTruong": "79762405",
                           "tenTruong": "TH Đặng Thị Rành"
                         },
                         {
                           "maTruong": "79762416",
                           "tenTruong": "TH Đặng Văn Bất"
                         },
                         {
                           "maTruong": "79762423",
                           "tenTruong": "TH Đào Sơn Tây"
                         },
                         {
                           "maTruong": "79762427",
                           "tenTruong": "TH Đỗ Tấn Phong"
                         },
                         {
                           "maTruong": "79762419",
                           "tenTruong": "TH Hiệp Bình Chánh"
                         },
                         {
                           "maTruong": "79762406",
                           "tenTruong": "TH Hiệp Bình Phước"
                         },
                         {
                           "maTruong": "79762412",
                           "tenTruong": "TH Hoàng Diệu"
                         },
                         {
                           "maTruong": "79762417",
                           "tenTruong": "TH Linh Đông"
                         },
                         {
                           "maTruong": "79762414",
                           "tenTruong": "TH Linh Tây"
                         },
                         {
                           "maTruong": "79762402",
                           "tenTruong": "TH Lương Thế Vinh"
                         },
                         {
                           "maTruong": "79762401",
                           "tenTruong": "TH Nguyễn Trung Trực"
                         },
                         {
                           "maTruong": "79762418",
                           "tenTruong": "TH Nguyễn Văn Banh"
                         },
                         {
                           "maTruong": "79762422",
                           "tenTruong": "TH Nguyễn Văn Lịch"
                         },
                         {
                           "maTruong": "79762428",
                           "tenTruong": "TH Nguyễn Văn Tây"
                         },
                         {
                           "maTruong": "79762413",
                           "tenTruong": "TH Nguyễn Văn Triết"
                         },
                         {
                           "maTruong": "79762408",
                           "tenTruong": "TH Tam Bình"
                         },
                         {
                           "maTruong": "79762407",
                           "tenTruong": "TH Thái Văn Lung"
                         },
                         {
                           "maTruong": "79762409",
                           "tenTruong": "TH Trần Văn Vân"
                         },
                         {
                           "maTruong": "79762421",
                           "tenTruong": "TH Trương Văn Hải"
                         },
                         {
                           "maTruong": "79762420",
                           "tenTruong": "TH Từ Đức"
                         },
                         {
                           "maTruong": "79762411",
                           "tenTruong": "TH Xuân Hiệp"
                         },
                         {
                           "maTruong": "79762510",
                           "tenTruong": "THCS Bình Chiểu"
                         },
                         {
                           "maTruong": "79762511",
                           "tenTruong": "THCS Bình Thọ"
                         },
                         {
                           "maTruong": "79762504",
                           "tenTruong": "THCS Hiệp Bình"
                         },
                         {
                           "maTruong": "79762501",
                           "tenTruong": "THCS Lê Quí Đôn"
                         },
                         {
                           "maTruong": "79762512",
                           "tenTruong": "THCS Lê Văn Việt"
                         },
                         {
                           "maTruong": "79762509",
                           "tenTruong": "THCS Linh Đông"
                         },
                         {
                           "maTruong": "79762508",
                           "tenTruong": "THCS Linh Trung"
                         },
                         {
                           "maTruong": "79762503",
                           "tenTruong": "THCS Ngô Chí Quốc"
                         },
                         {
                           "maTruong": "79762514",
                           "tenTruong": "THCS Nguyễn Văn Bá"
                         },
                         {
                           "maTruong": "79762506",
                           "tenTruong": "THCS Tam Bình"
                         },
                         {
                           "maTruong": "79762505",
                           "tenTruong": "THCS Thái Văn Lung"
                         },
                         {
                           "maTruong": "79762515",
                           "tenTruong": "THCS Trường Thọ"
                         },
                         {
                           "maTruong": "79762502",
                           "tenTruong": "THCS Trương Văn Ngư"
                         },
                         {
                           "maTruong": "79762507",
                           "tenTruong": "THCS Xuân Trường"
                         },
                         {
                           "maTruong": "79000707",
                           "tenTruong": "Phổ thông Năng khiếu Thể thao Olympic"
                         },
                         {
                           "maTruong": "79762223",
                           "tenTruong": "MG Ánh Hồng"
                         },
                         {
                           "maTruong": "79762221",
                           "tenTruong": "MG Hạnh Mai"
                         },
                         {
                           "maTruong": "79762224",
                           "tenTruong": "MG Hoa Cúc"
                         },
                         {
                           "maTruong": "79762215",
                           "tenTruong": "MG Linh Ân"
                         },
                         {
                           "maTruong": "79762217",
                           "tenTruong": "MG Mai Khôi"
                         },
                         {
                           "maTruong": "79762213",
                           "tenTruong": "MN Huệ Trắng"
                         },
                         {
                           "maTruong": "79762384",
                           "tenTruong": "An Lộc"
                         },
                         {
                           "maTruong": "79762386",
                           "tenTruong": "Ánh Dương"
                         },
                         {
                           "maTruong": "79762WB7",
                           "tenTruong": "Bách Việt"
                         },
                         {
                           "maTruong": "79762378",
                           "tenTruong": "Hải Yến"
                         },
                         {
                           "maTruong": "79762WB5",
                           "tenTruong": "Hoa Ngọc Lan"
                         },
                         {
                           "maTruong": "79762377",
                           "tenTruong": "Hoa Sen"
                         },
                         {
                           "maTruong": "79762385",
                           "tenTruong": "Hoàng Ngọc"
                         },
                         {
                           "maTruong": "79762WB6",
                           "tenTruong": "Học Viện Sài Gòn"
                         },
                         {
                           "maTruong": "79762376",
                           "tenTruong": "Hương Nắng Hồng"
                         },
                         {
                           "maTruong": "79762311",
                           "tenTruong": "Mầm Non Hoa Phượng"
                         },
                         {
                           "maTruong": "79762383",
                           "tenTruong": "MẦM NON VƯỜN TUỔI THƠ"
                         },
                         {
                           "maTruong": "79762370",
                           "tenTruong": "Mẫu giáo Mai Tâm"
                         },
                         {
                           "maTruong": "79762105",
                           "tenTruong": "MN  Hải Ha"
                         },
                         {
                           "maTruong": "79762147",
                           "tenTruong": "MN  Vàng Anh 1"
                         },
                         {
                           "maTruong": "79762346",
                           "tenTruong": "MN 20/11"
                         },
                         {
                           "maTruong": "79762342",
                           "tenTruong": "MN An Bình"
                         },
                         {
                           "maTruong": "79762398",
                           "tenTruong": "MN Bầu Trời Xanh"
                         },
                         {
                           "maTruong": "79762362",
                           "tenTruong": "MN Bé Xinh"
                         },
                         {
                           "maTruong": "797623W7",
                           "tenTruong": "MN Bi Bi"
                         },
                         {
                           "maTruong": "79762151",
                           "tenTruong": "MN Bich Trâm"
                         },
                         {
                           "maTruong": "79762308",
                           "tenTruong": "MN Bình Chiểu"
                         },
                         {
                           "maTruong": "79762304",
                           "tenTruong": "MN Bình Thọ"
                         },
                         {
                           "maTruong": "79762363",
                           "tenTruong": "MN Birong Sài Gòn"
                         },
                         {
                           "maTruong": "79762372",
                           "tenTruong": "MN Bồ Công Anh"
                         },
                         {
                           "maTruong": "79762144",
                           "tenTruong": "MN Bông Sen"
                         },
                         {
                           "maTruong": "79762351",
                           "tenTruong": "MN Bông Sen Xanh"
                         },
                         {
                           "maTruong": "79762301",
                           "tenTruong": "MN Búp Sen Hồng"
                         },
                         {
                           "maTruong": "79762364",
                           "tenTruong": "MN Cánh Thiên Thần"
                         },
                         {
                           "maTruong": "79762356",
                           "tenTruong": "MN Đông Dương"
                         },
                         {
                           "maTruong": "79762352",
                           "tenTruong": "MN Gấu Trúc"
                         },
                         {
                           "maTruong": "79762353",
                           "tenTruong": "MN Gia Huy"
                         },
                         {
                           "maTruong": "79762367",
                           "tenTruong": "MN Hải Âu"
                         },
                         {
                           "maTruong": "79762325",
                           "tenTruong": "MN Hạnh Phúc"
                         },
                         {
                           "maTruong": "79762302",
                           "tenTruong": "MN Hiệp Bình Chánh"
                         },
                         {
                           "maTruong": "79762306",
                           "tenTruong": "MN Hiệp Bình Chánh II"
                         },
                         {
                           "maTruong": "79762307",
                           "tenTruong": "MN Hiệp Bình Chánh III"
                         },
                         {
                           "maTruong": "79762303",
                           "tenTruong": "MN Hiệp Bình Phước"
                         },
                         {
                           "maTruong": "79762338",
                           "tenTruong": "MN Hiếu Trung"
                         },
                         {
                           "maTruong": "797623W8",
                           "tenTruong": "MN Hoa Bình Minh"
                         },
                         {
                           "maTruong": "79762393",
                           "tenTruong": "MN Hoa Đào"
                         },
                         {
                           "maTruong": "79762W75",
                           "tenTruong": "MN Hoa Hồng Nhỏ"
                         },
                         {
                           "maTruong": "79762358",
                           "tenTruong": "MN Hoa Mai"
                         },
                         {
                           "maTruong": "79762345",
                           "tenTruong": "MN Hoa Mặt Trời"
                         },
                         {
                           "maTruong": "797623W9",
                           "tenTruong": "MN Họa Mi"
                         },
                         {
                           "maTruong": "79762W05",
                           "tenTruong": "MN Hoa Quỳnh"
                         },
                         {
                           "maTruong": "79762348",
                           "tenTruong": "MN Hoa Xuân"
                         },
                         {
                           "maTruong": "79762394",
                           "tenTruong": "MN Hoàng Yến"
                         },
                         {
                           "maTruong": "79762211",
                           "tenTruong": "MN Hồng Ân"
                         },
                         {
                           "maTruong": "79762222",
                           "tenTruong": "MN Hướng Dương"
                         },
                         {
                           "maTruong": "79762W83",
                           "tenTruong": "MN Hướng Dương Vàng"
                         },
                         {
                           "maTruong": "79762112",
                           "tenTruong": "MN Hương Lan"
                         },
                         {
                           "maTruong": "79762317",
                           "tenTruong": "MN Hương Sen"
                         },
                         {
                           "maTruong": "79762369",
                           "tenTruong": "MN Khai Tâm"
                         },
                         {
                           "maTruong": "79762329",
                           "tenTruong": "MN Khánh Hỷ"
                         },
                         {
                           "maTruong": "79762312",
                           "tenTruong": "MN Khiết Tâm"
                         },
                         {
                           "maTruong": "79762331",
                           "tenTruong": "MN Kiểu Mẫu"
                         },
                         {
                           "maTruong": "79762315",
                           "tenTruong": "MN Linh Chiểu"
                         },
                         {
                           "maTruong": "79762347",
                           "tenTruong": "MN Linh Tây"
                         },
                         {
                           "maTruong": "79762319",
                           "tenTruong": "MN Linh Trung"
                         },
                         {
                           "maTruong": "79762309",
                           "tenTruong": "MN Linh Xuân"
                         },
                         {
                           "maTruong": "79762389",
                           "tenTruong": "MN Mai An"
                         },
                         {
                           "maTruong": "79762220",
                           "tenTruong": "MN Mai Anh"
                         },
                         {
                           "maTruong": "79762149",
                           "tenTruong": "MN Mai Vàng"
                         },
                         {
                           "maTruong": "79762323",
                           "tenTruong": "MN Măng Non"
                         },
                         {
                           "maTruong": "79762371",
                           "tenTruong": "MN Mặt Trời Đỏ"
                         },
                         {
                           "maTruong": "79762354",
                           "tenTruong": "MN Mặt Trời Hồng"
                         },
                         {
                           "maTruong": "79762392",
                           "tenTruong": "MN Minh Thư"
                         },
                         {
                           "maTruong": "79762357",
                           "tenTruong": "MN Mỹ An"
                         },
                         {
                           "maTruong": "79762327",
                           "tenTruong": "MN Ngọc Lan"
                         },
                         {
                           "maTruong": "79762340",
                           "tenTruong": "MN Ngôi Nhà Hạnh Phúc"
                         },
                         {
                           "maTruong": "79762396",
                           "tenTruong": "MN Ngôi Sao Sáng"
                         },
                         {
                           "maTruong": "79762313",
                           "tenTruong": "MN Nhân ái"
                         },
                         {
                           "maTruong": "79762341",
                           "tenTruong": "MN Nhật Tân"
                         },
                         {
                           "maTruong": "79762126",
                           "tenTruong": "MN Sao Vui"
                         },
                         {
                           "maTruong": "79762204",
                           "tenTruong": "MN Sen Hồng"
                         },
                         {
                           "maTruong": "79762374",
                           "tenTruong": "MN Sơn Ca"
                         },
                         {
                           "maTruong": "79762397",
                           "tenTruong": "MN Sông Xanh"
                         },
                         {
                           "maTruong": "79762316",
                           "tenTruong": "MN Tam Bình"
                         },
                         {
                           "maTruong": "79762368",
                           "tenTruong": "MN Tâm Nhi"
                         },
                         {
                           "maTruong": "79762320",
                           "tenTruong": "MN Tam Phú"
                         },
                         {
                           "maTruong": "79762391",
                           "tenTruong": "MN Thanh Bình"
                         },
                         {
                           "maTruong": "79762322",
                           "tenTruong": "MN Thanh Tâm"
                         },
                         {
                           "maTruong": "79762W81",
                           "tenTruong": "MN Thế Giới Trẻ Em"
                         },
                         {
                           "maTruong": "79762334",
                           "tenTruong": "MN Thiên Ân"
                         },
                         {
                           "maTruong": "79762133",
                           "tenTruong": "MN Thiên Ca"
                         },
                         {
                           "maTruong": "79762153",
                           "tenTruong": "MN Thiên Thần Nhỏ"
                         },
                         {
                           "maTruong": "79762W77",
                           "tenTruong": "MN Thỏ Trắng"
                         },
                         {
                           "maTruong": "79762326",
                           "tenTruong": "MN Thủ Đức"
                         },
                         {
                           "maTruong": "79762395",
                           "tenTruong": "MN Thực Ngiệm"
                         },
                         {
                           "maTruong": "79762324",
                           "tenTruong": "MN Trinh Vương"
                         },
                         {
                           "maTruong": "79762349",
                           "tenTruong": "MN Trúc Xanh"
                         },
                         {
                           "maTruong": "79762310",
                           "tenTruong": "MN Trường Thọ"
                         },
                         {
                           "maTruong": "79762332",
                           "tenTruong": "MN Tuổi Ngọc"
                         },
                         {
                           "maTruong": "79762359",
                           "tenTruong": "MN Tuổi Tiên"
                         },
                         {
                           "maTruong": "79762333",
                           "tenTruong": "MN Tương Lai"
                         },
                         {
                           "maTruong": "79762335",
                           "tenTruong": "MN Tường Vy"
                         },
                         {
                           "maTruong": "79762361",
                           "tenTruong": "MN Vầng Dương"
                         },
                         {
                           "maTruong": "79762305",
                           "tenTruong": "MN Vành Khuyên"
                         },
                         {
                           "maTruong": "79762373",
                           "tenTruong": "MN Việt Anh"
                         },
                         {
                           "maTruong": "79762399",
                           "tenTruong": "MN Việt Đông Dương"
                         },
                         {
                           "maTruong": "79762344",
                           "tenTruong": "MN Việt Mỹ"
                         },
                         {
                           "maTruong": "79762328",
                           "tenTruong": "MN Việt Mỹ Sài Gòn"
                         },
                         {
                           "maTruong": "79762390",
                           "tenTruong": "MN Vườn Hồng"
                         },
                         {
                           "maTruong": "79762216",
                           "tenTruong": "MNHoàng Anh"
                         },
                         {
                           "maTruong": "79762382",
                           "tenTruong": "Sài Gòn"
                         },
                         {
                           "maTruong": "79762380",
                           "tenTruong": "Sao Ngoan"
                         },
                         {
                           "maTruong": "79762381",
                           "tenTruong": "Thần Đồng"
                         },
                         {
                           "maTruong": "79762388",
                           "tenTruong": "Trường Mầm non Sao Vàng"
                         },
                         {
                           "maTruong": "79762321",
                           "tenTruong": "Trường MN Hoa Lan"
                         },
                         {
                           "maTruong": "79762W73",
                           "tenTruong": "1 tháng 6"
                         },
                         {
                           "maTruong": "79762W72",
                           "tenTruong": "ABC"
                         },
                         {
                           "maTruong": "79762161",
                           "tenTruong": "Ánh Linh"
                         },
                         {
                           "maTruong": "79762163",
                           "tenTruong": "Ánh Sáng"
                         },
                         {
                           "maTruong": "79762162",
                           "tenTruong": "Ánh Sao"
                         },
                         {
                           "maTruong": "79762W59",
                           "tenTruong": "Âu Cơ"
                         },
                         {
                           "maTruong": "79762164",
                           "tenTruong": "Bam Bi"
                         },
                         {
                           "maTruong": "79762W62",
                           "tenTruong": "Bé Thơ"
                         },
                         {
                           "maTruong": "79762172",
                           "tenTruong": "Bông Hoa Nhỏ"
                         },
                         {
                           "maTruong": "79762173",
                           "tenTruong": "Chim Vành Anh"
                         },
                         {
                           "maTruong": "79762W04",
                           "tenTruong": "Đa Phước Minh"
                         },
                         {
                           "maTruong": "79762178",
                           "tenTruong": "Dương Đông"
                         },
                         {
                           "maTruong": "79763185",
                           "tenTruong": "Hoa Minh"
                         },
                         {
                           "maTruong": "79762W80",
                           "tenTruong": "Hoa Phượng Đỏ"
                         },
                         {
                           "maTruong": "79762174",
                           "tenTruong": "Hoàng Kim"
                         },
                         {
                           "maTruong": "79762168",
                           "tenTruong": "Hương Quế"
                         },
                         {
                           "maTruong": "79762176",
                           "tenTruong": "Hy Vọng"
                         },
                         {
                           "maTruong": "79762118",
                           "tenTruong": "LMG Phượng Hồng"
                         },
                         {
                           "maTruong": "79762W94",
                           "tenTruong": "LMG Thiên Phú"
                         },
                         {
                           "maTruong": "79762WA0",
                           "tenTruong": "Lớp mẫu giáo Bửu Ngọc"
                         },
                         {
                           "maTruong": "79762125",
                           "tenTruong": "Lớp Mẫu Giáo Thiên Thần"
                         },
                         {
                           "maTruong": "79762W98",
                           "tenTruong": "Lớp MG Ánh Mai"
                         },
                         {
                           "maTruong": "79762W66",
                           "tenTruong": "Mai Ka"
                         },
                         {
                           "maTruong": "79762W64",
                           "tenTruong": "Mặt Trời Mới"
                         },
                         {
                           "maTruong": "79762177",
                           "tenTruong": "Mặt Trời Nhỏ"
                         },
                         {
                           "maTruong": "79762143",
                           "tenTruong": "MG Hoa Hồng"
                         },
                         {
                           "maTruong": "79762WA4",
                           "tenTruong": "MN Băng Băng"
                         },
                         {
                           "maTruong": "79762WA7",
                           "tenTruong": "MN Bảo Châu"
                         },
                         {
                           "maTruong": "79762WA5",
                           "tenTruong": "MN Dâu Tây"
                         },
                         {
                           "maTruong": "79762WA3",
                           "tenTruong": "MN Hoa Mộc Lan"
                         },
                         {
                           "maTruong": "79762WA6",
                           "tenTruong": "MN Ong Vàng"
                         },
                         {
                           "maTruong": "79762WB1",
                           "tenTruong": "MN Thế Giới Trẻ Thơ"
                         },
                         {
                           "maTruong": "79762W61",
                           "tenTruong": "Ngọc Phúc"
                         },
                         {
                           "maTruong": "79762W76",
                           "tenTruong": "Nhà Thiên Thần"
                         },
                         {
                           "maTruong": "79762W63",
                           "tenTruong": "Nhị Hồng"
                         },
                         {
                           "maTruong": "79762WB2",
                           "tenTruong": "Nhóm trẻ  Yến Nhi"
                         },
                         {
                           "maTruong": "79762W86",
                           "tenTruong": "Nhóm trẻ Bingo"
                         },
                         {
                           "maTruong": "79762166",
                           "tenTruong": "Nhóm trẻ Hoàng Oanh"
                         },
                         {
                           "maTruong": "79762W84",
                           "tenTruong": "Nhóm trẻ Mimosa"
                         },
                         {
                           "maTruong": "79762W02",
                           "tenTruong": "Nhóm trẻ Thiên An"
                         },
                         {
                           "maTruong": "79762365",
                           "tenTruong": "NT  Phượng Hoàng"
                         },
                         {
                           "maTruong": "79762194",
                           "tenTruong": "NT An Ý"
                         },
                         {
                           "maTruong": "79762103",
                           "tenTruong": "NT Anh Đào"
                         },
                         {
                           "maTruong": "79762181",
                           "tenTruong": "NT Ánh Mặt Trời"
                         },
                         {
                           "maTruong": "79762196",
                           "tenTruong": "NT Anh Tâm"
                         },
                         {
                           "maTruong": "79762107",
                           "tenTruong": "NT Anh Việt"
                         },
                         {
                           "maTruong": "79762201",
                           "tenTruong": "NT Bảo Hân"
                         },
                         {
                           "maTruong": "79762128",
                           "tenTruong": "NT Bé Ngoan"
                         },
                         {
                           "maTruong": "79762134",
                           "tenTruong": "NT Bình Minh"
                         },
                         {
                           "maTruong": "79762200",
                           "tenTruong": "NT Búp Măng Non"
                         },
                         {
                           "maTruong": "79762121",
                           "tenTruong": "NT Chim Non"
                         },
                         {
                           "maTruong": "79762155",
                           "tenTruong": "NT Đại Việt Mỹ"
                         },
                         {
                           "maTruong": "79762148",
                           "tenTruong": "NT Đức Trí"
                         },
                         {
                           "maTruong": "79762L93",
                           "tenTruong": "NT Duy Khang"
                         },
                         {
                           "maTruong": "79762154",
                           "tenTruong": "NT Gia Nghi"
                         },
                         {
                           "maTruong": "79762L94",
                           "tenTruong": "NT Hoa An"
                         },
                         {
                           "maTruong": "79762137",
                           "tenTruong": "NT Họa Mi (HBP)"
                         },
                         {
                           "maTruong": "79762190",
                           "tenTruong": "NT Hoa Nắng"
                         },
                         {
                           "maTruong": "79762197",
                           "tenTruong": "NT Hoàng Hạc"
                         },
                         {
                           "maTruong": "79762199",
                           "tenTruong": "NT Hồng Ánh"
                         },
                         {
                           "maTruong": "79762129",
                           "tenTruong": "NT Hồng Phúc"
                         },
                         {
                           "maTruong": "79762145",
                           "tenTruong": "NT Huyền Trâm"
                         },
                         {
                           "maTruong": "79762205",
                           "tenTruong": "NT Khai Minh"
                         },
                         {
                           "maTruong": "79762192",
                           "tenTruong": "NT Mai Hoa"
                         },
                         {
                           "maTruong": "79762195",
                           "tenTruong": "NT Ngọc Hà"
                         },
                         {
                           "maTruong": "79762WB4",
                           "tenTruong": "NT Ngọc Linh"
                         },
                         {
                           "maTruong": "79762127",
                           "tenTruong": "NT Ngôi Sao Nhỏ"
                         },
                         {
                           "maTruong": "79762198",
                           "tenTruong": "NT Phương Mi"
                         },
                         {
                           "maTruong": "79762193",
                           "tenTruong": "NT Sao Việt"
                         },
                         {
                           "maTruong": "79762202",
                           "tenTruong": "NT Sao Việt Mỹ"
                         },
                         {
                           "maTruong": "79762131",
                           "tenTruong": "NT Sơn Ca"
                         },
                         {
                           "maTruong": "79762146",
                           "tenTruong": "NT Thanh Trúc"
                         },
                         {
                           "maTruong": "79762139",
                           "tenTruong": "NT Thảo Vy"
                         },
                         {
                           "maTruong": "79762124",
                           "tenTruong": "NT Thiện Nhân"
                         },
                         {
                           "maTruong": "79762122",
                           "tenTruong": "NT Tin Tin"
                         },
                         {
                           "maTruong": "79762203",
                           "tenTruong": "NT Tuổi Xinh"
                         },
                         {
                           "maTruong": "79762156",
                           "tenTruong": "ntSao Mai"
                         },
                         {
                           "maTruong": "79762WA2",
                           "tenTruong": "Nụ Hồng"
                         },
                         {
                           "maTruong": "79762170",
                           "tenTruong": "Phúc Ân"
                         },
                         {
                           "maTruong": "79762W85",
                           "tenTruong": "Phương Nhật"
                         },
                         {
                           "maTruong": "79762187",
                           "tenTruong": "Sắc Màu"
                         },
                         {
                           "maTruong": "79762180",
                           "tenTruong": "Sóc Nâu"
                         },
                         {
                           "maTruong": "79762W99",
                           "tenTruong": "Tâm Sen"
                         },
                         {
                           "maTruong": "79762182",
                           "tenTruong": "Thảo Nguyên"
                         },
                         {
                           "maTruong": "79762183",
                           "tenTruong": "Thiên Nhi"
                         },
                         {
                           "maTruong": "79762179",
                           "tenTruong": "Thiên Phúc"
                         },
                         {
                           "maTruong": "79762175",
                           "tenTruong": "Thiên Ý"
                         },
                         {
                           "maTruong": "79762W82",
                           "tenTruong": "Thủy Tiên"
                         },
                         {
                           "maTruong": "79762W74",
                           "tenTruong": "Trúc Xinh"
                         },
                         {
                           "maTruong": "79762167",
                           "tenTruong": "Tuổi Thơ"
                         },
                         {
                           "maTruong": "79762W79",
                           "tenTruong": "Uyên Nhi"
                         },
                         {
                           "maTruong": "79762160",
                           "tenTruong": "Vân Phong"
                         },
                         {
                           "maTruong": "797623X5",
                           "tenTruong": "MN Sắc Màu Tuổi Thơ"
                         },
                         {
                           "maTruong": "79762LA8",
                           "tenTruong": "LMG Ánh Bình Minh"
                         },
                         {
                           "maTruong": "79762LB0",
                           "tenTruong": "LMG Trí Tuệ Việt"
                         },
                         {
                           "maTruong": "797621A7",
                           "tenTruong": "LMG Em Bé Hạnh Phúc"
                         },
                         {
                           "maTruong": "797621W4",
                           "tenTruong": "LMG Hằng Nga"
                         },
                         {
                           "maTruong": "797621W2",
                           "tenTruong": "LMG Khang Nguyên"
                         },
                         {
                           "maTruong": "797621A1",
                           "tenTruong": "LMG Nắng Ban Mai"
                         },
                         {
                           "maTruong": "79762LB3",
                           "tenTruong": "LMG Nắng Hồng"
                         },
                         {
                           "maTruong": "797621W1",
                           "tenTruong": "LMG Ngọc Đạt"
                         },
                         {
                           "maTruong": "797621W3",
                           "tenTruong": "LMG Thiên Hương"
                         },
                         {
                           "maTruong": "797621W5",
                           "tenTruong": "NT Gấu Bông"
                         },
                         {
                           "maTruong": "797623X0",
                           "tenTruong": "MN Hoa Hồng"
                         },
                         {
                           "maTruong": "797623X1",
                           "tenTruong": "MN Bến Thành"
                         },
                         {
                           "maTruong": "797623X2",
                           "tenTruong": "MN Thế Giới Xanh"
                         },
                         {
                           "maTruong": "797623X3",
                           "tenTruong": "MN Mặt Trời Bé Con"
                         },
                         {
                           "maTruong": "797623X4",
                           "tenTruong": "MN Mây Hồng"
                         },
                         {
                           "maTruong": "79762101",
                           "tenTruong": "MN Thành Phố Tuổi Thơ"
                         },
                         {
                           "maTruong": "79762102",
                           "tenTruong": "MN Học Viện Hoa Kỳ"
                         },
                         {
                           "maTruong": "79762516",
                           "tenTruong": "THCS Dương Văn Thì"
                         },
                         {
                           "maTruong": "79762104",
                           "tenTruong": "LMG Hoa Trạng Nguyên"
                         },
                         {
                           "maTruong": "79762106",
                           "tenTruong": "LMG Hừng Đông"
                         },
                         {
                           "maTruong": "79762109",
                           "tenTruong": "MN Hoa Diên Vĩ"
                         },
                         {
                           "maTruong": "79762110",
                           "tenTruong": "MN Mặt Trời"
                         },
                         {
                           "maTruong": "79762111",
                           "tenTruong": "MN Mặt Trời Nhỏ"
                         },
                         {
                           "maTruong": "79762113",
                           "tenTruong": "MN Ngôi Nhà Xanh"
                         },
                         {
                           "maTruong": "79762114",
                           "tenTruong": "MN Thanh Thúy"
                         },
                         {
                           "maTruong": "79762115",
                           "tenTruong": "MN Sao Mai"
                         },
                         {
                           "maTruong": "7976AS12",
                           "tenTruong": "MN Hoa Mặt Trời"
                         },
                         {
                           "maTruong": "79769999",
                           "tenTruong": "MN Lá Phong Việt"
                         },
                         {
                           "maTruong": "79769988",
                           "tenTruong": "MN Tuổi Hồng"
                         },
                         {
                           "maTruong": "79769990",
                           "tenTruong": "NT Hồng Hà"
                         },
                         {
                           "maTruong": "79762300",
                           "tenTruong": "MN Tuệ Đức"
                         }
                       ]
                     },
                     {
                       "maPhong": null,
                       "tenPhong": "Huyện Bình Chánh",
                       "truongData": [
                         {
                           "maTruong": "79000741",
                           "tenTruong": "THPT Bình Chánh"
                         },
                         {
                           "maTruong": "79000807",
                           "tenTruong": "THPT Đa Phước"
                         },
                         {
                           "maTruong": "79000806",
                           "tenTruong": "THPT Lê Minh Xuân"
                         },
                         {
                           "maTruong": "790007C1",
                           "tenTruong": "THPT Năng khiếu TDTT Huyện Bình Chánh"
                         },
                         {
                           "maTruong": "790007B3",
                           "tenTruong": "THPT Tân Túc"
                         },
                         {
                           "maTruong": "790007B6",
                           "tenTruong": "THPT Vĩnh Lộc B"
                         },
                         {
                           "maTruong": "79785901",
                           "tenTruong": "TT GDNN-GDTX Huyện Bình Chánh"
                         },
                         {
                           "maTruong": "79000C19",
                           "tenTruong": "Tiểu học, THCS và THPT Albert Einstein"
                         },
                         {
                           "maTruong": "79000C06",
                           "tenTruong": "TiH - THCS - THPT QUỐC TẾ BẮC MỸ"
                         },
                         {
                           "maTruong": "790007C7",
                           "tenTruong": "THPT Phong Phú"
                         },
                         {
                           "maTruong": "7900004006",
                           "tenTruong": "TT.HTPTGDHN Bình Chánh (Bình Chánh)"
                         },
                         {
                           "maTruong": "79000S10",
                           "tenTruong": "Trường Quốc Tế Singapore"
                         },
                         {
                           "maTruong": "79000V100",
                           "tenTruong": "Trường QT Anh Việt - Tp. Hồ Chí Minh"
                         }
                       ]
                     },
                     {
                       "maPhong": "762",
                       "tenPhong": "Thành Phố Thủ Đức",
                       "truongData": [
                         {
                           "maTruong": "79762510",
                           "tenTruong": "THCS Bình Chiểu"
                         },
                         {
                           "maTruong": "79762511",
                           "tenTruong": "THCS Bình Thọ"
                         },
                         {
                           "maTruong": "79762512",
                           "tenTruong": "THCS Lê Văn Việt"
                         },
                         {
                           "maTruong": "79762514",
                           "tenTruong": "THCS Nguyễn Văn Bá"
                         },
                         {
                           "maTruong": "79762515",
                           "tenTruong": "THCS Trường Thọ"
                         },
                         {
                           "maTruong": "79762L93",
                           "tenTruong": "LMG Duy Khang"
                         },
                         {
                           "maTruong": "79762LB0",
                           "tenTruong": "LMG Trí Tuệ Việt"
                         },
                         {
                           "maTruong": "79762LB1",
                           "tenTruong": "LMG Tuổi Thơ Tài Năng"
                         },
                         {
                           "maTruong": "79762LB3",
                           "tenTruong": "LMG Nắng Hồng"
                         },
                         {
                           "maTruong": "79762W02",
                           "tenTruong": "LMG Thiên An"
                         },
                         {
                           "maTruong": "79762W04",
                           "tenTruong": "LMG Đa Phước Minh"
                         },
                         {
                           "maTruong": "79762W05",
                           "tenTruong": "MN Hoa Quỳnh"
                         },
                         {
                           "maTruong": "79762W61",
                           "tenTruong": "LMG Ngọc Phúc"
                         },
                         {
                           "maTruong": "79762W62",
                           "tenTruong": "NT Bé Thơ"
                         },
                         {
                           "maTruong": "79762W66",
                           "tenTruong": "MN Mai Ka"
                         },
                         {
                           "maTruong": "79762W72",
                           "tenTruong": "LMG ABC"
                         },
                         {
                           "maTruong": "79762W73",
                           "tenTruong": "LMG 1 tháng 6"
                         },
                         {
                           "maTruong": "79762W74",
                           "tenTruong": "LMG Trúc Xinh"
                         },
                         {
                           "maTruong": "79762W75",
                           "tenTruong": "MN Hoa Hồng Nhỏ"
                         },
                         {
                           "maTruong": "79762W76",
                           "tenTruong": "NT Nhà Thiên Thần"
                         },
                         {
                           "maTruong": "79762W77",
                           "tenTruong": "MN Thỏ Trắng 3"
                         },
                         {
                           "maTruong": "79762W79",
                           "tenTruong": "NT Uyên Nhi"
                         },
                         {
                           "maTruong": "79762W80",
                           "tenTruong": "LMG Hoa Phượng Đỏ"
                         },
                         {
                           "maTruong": "79762W81",
                           "tenTruong": "MN Thế Giới Trẻ Em"
                         },
                         {
                           "maTruong": "79762W82",
                           "tenTruong": "LMG Nụ Cười"
                         },
                         {
                           "maTruong": "79762W83",
                           "tenTruong": "MN Hướng Dương Vàng TĐ"
                         },
                         {
                           "maTruong": "79762W84",
                           "tenTruong": "LMG Mimosa"
                         },
                         {
                           "maTruong": "79762W85",
                           "tenTruong": "LMG Phương Nhật"
                         },
                         {
                           "maTruong": "79762W99",
                           "tenTruong": "NT Tâm Sen"
                         },
                         {
                           "maTruong": "79762WA3",
                           "tenTruong": "LMG  Hoa Mộc Lan"
                         },
                         {
                           "maTruong": "79762WA4",
                           "tenTruong": "LMG Băng Băng"
                         },
                         {
                           "maTruong": "79762WA5",
                           "tenTruong": "NT Dâu Tây"
                         },
                         {
                           "maTruong": "79762WA6",
                           "tenTruong": "LMG Ong Vàng"
                         },
                         {
                           "maTruong": "79762WA7",
                           "tenTruong": "LMG Bảo Châu"
                         },
                         {
                           "maTruong": "79762WB1",
                           "tenTruong": "LMG Ngôi Nhà Trẻ Thơ"
                         },
                         {
                           "maTruong": "79762WB5",
                           "tenTruong": "MN Hoa Ngọc Lan"
                         },
                         {
                           "maTruong": "79762WB6",
                           "tenTruong": "MN Học Viện Sài Gòn"
                         },
                         {
                           "maTruong": "79762WB7",
                           "tenTruong": "MN Bách Việt"
                         },
                         {
                           "maTruong": "79763138",
                           "tenTruong": "LMG Thần Đồng Việt"
                         },
                         {
                           "maTruong": "79763139",
                           "tenTruong": "NT Thiên Thần Bé Nhỏ"
                         },
                         {
                           "maTruong": "79763202",
                           "tenTruong": "MN Long Phước"
                         },
                         {
                           "maTruong": "79763203",
                           "tenTruong": "MN Tuổi Thơ KV2"
                         },
                         {
                           "maTruong": "79763205",
                           "tenTruong": "MG Mai Hoa"
                         },
                         {
                           "maTruong": "79763206",
                           "tenTruong": "MG Sao Mai"
                         },
                         {
                           "maTruong": "79763301",
                           "tenTruong": "MN Long Bình"
                         },
                         {
                           "maTruong": "79763302",
                           "tenTruong": "MN Tân Phú"
                         },
                         {
                           "maTruong": "79763303",
                           "tenTruong": "MN Hiệp Phú"
                         },
                         {
                           "maTruong": "79763304",
                           "tenTruong": "MN Phước Bình"
                         },
                         {
                           "maTruong": "79763305",
                           "tenTruong": "MN Hoa Sen"
                         },
                         {
                           "maTruong": "79763306",
                           "tenTruong": "MN Vàng Anh"
                         },
                         {
                           "maTruong": "79763307",
                           "tenTruong": "MN Sơn Ca 2"
                         },
                         {
                           "maTruong": "79763308",
                           "tenTruong": "MN Hoàng Yến 2"
                         },
                         {
                           "maTruong": "79763309",
                           "tenTruong": "MN Phong Phú"
                         },
                         {
                           "maTruong": "79763310",
                           "tenTruong": "MN Thanh Lịch"
                         },
                         {
                           "maTruong": "79763312",
                           "tenTruong": "MN Hoa Lan"
                         },
                         {
                           "maTruong": "79763313",
                           "tenTruong": "MN Long Trường"
                         },
                         {
                           "maTruong": "79763314",
                           "tenTruong": "MN Trường Thạnh"
                         },
                         {
                           "maTruong": "79763315",
                           "tenTruong": "MN Rồng Vàng"
                         },
                         {
                           "maTruong": "79763316",
                           "tenTruong": "MN Hoa Hồng Đỏ"
                         },
                         {
                           "maTruong": "79763317",
                           "tenTruong": "MN Bình Minh"
                         },
                         {
                           "maTruong": "79763318",
                           "tenTruong": "MN Sóc Nâu"
                         },
                         {
                           "maTruong": "79763319",
                           "tenTruong": "MN Kiều Đàm"
                         },
                         {
                           "maTruong": "79763320",
                           "tenTruong": "MN Thỏ Ngọc"
                         },
                         {
                           "maTruong": "79763321",
                           "tenTruong": "MN Hoa Mai"
                         },
                         {
                           "maTruong": "79763322",
                           "tenTruong": "MN Tuổi Hồng"
                         },
                         {
                           "maTruong": "79763323",
                           "tenTruong": "MN Tuổi Ngọc"
                         },
                         {
                           "maTruong": "79763324",
                           "tenTruong": "MN Ngô Thời Nhiệm"
                         },
                         {
                           "maTruong": "79763326",
                           "tenTruong": "MN Tuổi Hoa"
                         },
                         {
                           "maTruong": "79763331",
                           "tenTruong": "MN Cậu Bé Gỗ"
                         },
                         {
                           "maTruong": "79763332",
                           "tenTruong": "MN Thiện Mỹ"
                         },
                         {
                           "maTruong": "79763333",
                           "tenTruong": "MN Hoa Thương"
                         },
                         {
                           "maTruong": "79763334",
                           "tenTruong": "MN Sao Sáng"
                         },
                         {
                           "maTruong": "79763335",
                           "tenTruong": "MN Tạ Uyên"
                         },
                         {
                           "maTruong": "79763336",
                           "tenTruong": "MN Long Sơn"
                         },
                         {
                           "maTruong": "79763337",
                           "tenTruong": "MN Mẹ Yêu Con"
                         },
                         {
                           "maTruong": "79763338",
                           "tenTruong": "MN Con Mèo Vàng"
                         },
                         {
                           "maTruong": "79763339",
                           "tenTruong": "MN Công Nghệ Cao"
                         },
                         {
                           "maTruong": "79763340",
                           "tenTruong": "MN Long Thạnh Mỹ"
                         },
                         {
                           "maTruong": "79763341",
                           "tenTruong": "MN ABC"
                         },
                         {
                           "maTruong": "79763342",
                           "tenTruong": "MN Thăng Long A"
                         },
                         {
                           "maTruong": "79763343",
                           "tenTruong": "MN Miền Trẻ Thơ"
                         },
                         {
                           "maTruong": "79763344",
                           "tenTruong": "MN Hoa Trà My"
                         },
                         {
                           "maTruong": "79763345",
                           "tenTruong": "MN Á Châu"
                         },
                         {
                           "maTruong": "79763346",
                           "tenTruong": "MN Ngôi Sao"
                         },
                         {
                           "maTruong": "79763401",
                           "tenTruong": "TH Long Bình"
                         },
                         {
                           "maTruong": "79763402",
                           "tenTruong": "TH Phước Bình"
                         },
                         {
                           "maTruong": "79763403",
                           "tenTruong": "TH Tạ Uyên"
                         },
                         {
                           "maTruong": "79763404",
                           "tenTruong": "TH Đinh Tiên Hoàng"
                         },
                         {
                           "maTruong": "79763405",
                           "tenTruong": "TH Phú Hữu"
                         },
                         {
                           "maTruong": "79763406",
                           "tenTruong": "TH Võ Văn Hát"
                         },
                         {
                           "maTruong": "79763407",
                           "tenTruong": "TH Long Thạnh Mỹ"
                         },
                         {
                           "maTruong": "79763410",
                           "tenTruong": "TH Phước Thạnh"
                         },
                         {
                           "maTruong": "79763411",
                           "tenTruong": "TH Trương Văn Thành"
                         },
                         {
                           "maTruong": "79763412",
                           "tenTruong": "TH Phong Phú"
                         },
                         {
                           "maTruong": "79763413",
                           "tenTruong": "TH Long Phước"
                         },
                         {
                           "maTruong": "79763415",
                           "tenTruong": "TH Trường Thạnh"
                         },
                         {
                           "maTruong": "79763417",
                           "tenTruong": "TH Tân Phú"
                         },
                         {
                           "maTruong": "79763419",
                           "tenTruong": "TH Lê Văn Việt"
                         },
                         {
                           "maTruong": "79763420",
                           "tenTruong": "TH Nguyễn Văn Bá"
                         },
                         {
                           "maTruong": "79763501",
                           "tenTruong": "THCS Phước Bình"
                         },
                         {
                           "maTruong": "79763502",
                           "tenTruong": "THCS Hưng Bình"
                         },
                         {
                           "maTruong": "79763503",
                           "tenTruong": "THCS Hoa Lư"
                         },
                         {
                           "maTruong": "79763504",
                           "tenTruong": "THCS Trần Quốc Toản"
                         },
                         {
                           "maTruong": "79763505",
                           "tenTruong": "THCS Long Trường"
                         },
                         {
                           "maTruong": "79763506",
                           "tenTruong": "THCS Long Phước"
                         },
                         {
                           "maTruong": "79763507",
                           "tenTruong": "THCS Đặng Tấn Tài"
                         },
                         {
                           "maTruong": "79763508",
                           "tenTruong": "THCS Phú Hữu"
                         },
                         {
                           "maTruong": "79763509",
                           "tenTruong": "THCS Tân Phú"
                         },
                         {
                           "maTruong": "79763510",
                           "tenTruong": "THCS Trường Thạnh"
                         },
                         {
                           "maTruong": "79763511",
                           "tenTruong": "THCS Long Bình"
                         },
                         {
                           "maTruong": "79763512",
                           "tenTruong": "THCS Tăng Nhơn Phú B"
                         },
                         {
                           "maTruong": "79763L42",
                           "tenTruong": "LMG Gấu Panda"
                         },
                         {
                           "maTruong": "79763L43",
                           "tenTruong": "LMG Bồ Câu Trắng"
                         },
                         {
                           "maTruong": "79763L45",
                           "tenTruong": "LMG Ánh Sao KV2"
                         },
                         {
                           "maTruong": "79763L48",
                           "tenTruong": "LMG Ngôi Sao Mới"
                         },
                         {
                           "maTruong": "79763L49",
                           "tenTruong": "LMG Tâm Đức"
                         },
                         {
                           "maTruong": "79763L50",
                           "tenTruong": "NT Thỏ Hồng"
                         },
                         {
                           "maTruong": "79763L54",
                           "tenTruong": "LMG Minh Châu"
                         },
                         {
                           "maTruong": "79763L55",
                           "tenTruong": "LMG Thăng Long"
                         },
                         {
                           "maTruong": "79763L56",
                           "tenTruong": "NT Ban Mai Xanh"
                         },
                         {
                           "maTruong": "79763L57",
                           "tenTruong": "LMG Hoa Huệ Trắng"
                         },
                         {
                           "maTruong": "79763L59",
                           "tenTruong": "LMG Gấu Anh"
                         },
                         {
                           "maTruong": "79763L66",
                           "tenTruong": "LMG Nam Long"
                         },
                         {
                           "maTruong": "79763L67",
                           "tenTruong": "LMG Nụ Cười Hồng"
                         },
                         {
                           "maTruong": "79763L68",
                           "tenTruong": "LMG Mặt Trời Đỏ"
                         },
                         {
                           "maTruong": "79763O03",
                           "tenTruong": "MN Việt Duy"
                         },
                         {
                           "maTruong": "79763O05",
                           "tenTruong": "NT Tuổi Thơ 2"
                         },
                         {
                           "maTruong": "79763O13",
                           "tenTruong": "LMG Ánh Bình Minh"
                         },
                         {
                           "maTruong": "79763O14",
                           "tenTruong": "LMG Sen Hồng"
                         },
                         {
                           "maTruong": "79763O19",
                           "tenTruong": "NT Phú Hữu"
                         },
                         {
                           "maTruong": "79763O21",
                           "tenTruong": "LMG Thanh Thảo"
                         },
                         {
                           "maTruong": "79763V01",
                           "tenTruong": "LMG Yêu Trẻ"
                         },
                         {
                           "maTruong": "79763W02",
                           "tenTruong": "MN Nai Vàng"
                         },
                         {
                           "maTruong": "79763W03",
                           "tenTruong": "MN Hoàng Lê"
                         },
                         {
                           "maTruong": "79763W05",
                           "tenTruong": "LMG Thiên Ân"
                         },
                         {
                           "maTruong": "79763W06",
                           "tenTruong": "LMG Gia Hân"
                         },
                         {
                           "maTruong": "79763W09",
                           "tenTruong": "LMG Búp Măng Non"
                         },
                         {
                           "maTruong": "79763W13",
                           "tenTruong": "LMG Nắng Mai"
                         },
                         {
                           "maTruong": "79763W14",
                           "tenTruong": "LMG Đô Rê Mi"
                         },
                         {
                           "maTruong": "79763W15",
                           "tenTruong": "LMG Gấu MiSa"
                         },
                         {
                           "maTruong": "79763W17",
                           "tenTruong": "LMG Thiên Thần"
                         },
                         {
                           "maTruong": "79763W18",
                           "tenTruong": "Lớp MG Xuân Mai"
                         },
                         {
                           "maTruong": "79763W21",
                           "tenTruong": "LMG Bé Ngoan"
                         },
                         {
                           "maTruong": "79763W22",
                           "tenTruong": "LMG Bảo Ngọc"
                         },
                         {
                           "maTruong": "79763W26",
                           "tenTruong": "NT Long Thiện Mỹ"
                         },
                         {
                           "maTruong": "79763W32",
                           "tenTruong": "LMG Hoa Anh Đào"
                         },
                         {
                           "maTruong": "79763W33",
                           "tenTruong": "LMG Thiên Ái"
                         },
                         {
                           "maTruong": "79763W34",
                           "tenTruong": "NT Nam Mỹ 2"
                         },
                         {
                           "maTruong": "79763W36",
                           "tenTruong": "MN Minh Ngọc"
                         },
                         {
                           "maTruong": "79763W37",
                           "tenTruong": "LMG Ngôi Sao Sáng"
                         },
                         {
                           "maTruong": "79763W40",
                           "tenTruong": "LMG Hoa Thiên Phú"
                         },
                         {
                           "maTruong": "79763W41",
                           "tenTruong": "NT ABC"
                         },
                         {
                           "maTruong": "79763W43",
                           "tenTruong": "LMG Thỏ Trắng"
                         },
                         {
                           "maTruong": "79763W44",
                           "tenTruong": "LMG Ánh Sao 2"
                         },
                         {
                           "maTruong": "79763W45",
                           "tenTruong": "LMG Cô và Bé"
                         },
                         {
                           "maTruong": "79763W47",
                           "tenTruong": "MN Song Như"
                         },
                         {
                           "maTruong": "79763W49",
                           "tenTruong": "LMG Mai Anh 2"
                         },
                         {
                           "maTruong": "79763W51",
                           "tenTruong": "NT MAI Yến"
                         },
                         {
                           "maTruong": "79763W53",
                           "tenTruong": "NT Bình Minh"
                         },
                         {
                           "maTruong": "79763X04",
                           "tenTruong": "MN Ban Mai"
                         },
                         {
                           "maTruong": "79769843",
                           "tenTruong": "MG Ngôi Sao Sài Gòn"
                         },
                         {
                           "maTruong": "79769399",
                           "tenTruong": "MN Hoa Sen 1"
                         },
                         {
                           "maTruong": "79769826",
                           "tenTruong": "MN An Nhiên"
                         },
                         {
                           "maTruong": "79769827",
                           "tenTruong": "NT Gấu Misa"
                         },
                         {
                           "maTruong": "79769828",
                           "tenTruong": "LMG Khu Vườn Xanh"
                         },
                         {
                           "maTruong": "79769829",
                           "tenTruong": "LMG Ngôi Sao Sáng KV1"
                         },
                         {
                           "maTruong": "79769830",
                           "tenTruong": "NT Tổ Ong Vàng"
                         },
                         {
                           "maTruong": "79769831",
                           "tenTruong": "LMG Mai Cham"
                         },
                         {
                           "maTruong": "79769833",
                           "tenTruong": "MN Hướng Dương Sài Gòn"
                         },
                         {
                           "maTruong": "79769835",
                           "tenTruong": "MN Lâm An"
                         },
                         {
                           "maTruong": "79769836",
                           "tenTruong": "MN Trẻ Em Alpha"
                         },
                         {
                           "maTruong": "79769837",
                           "tenTruong": "MN Ngôi Nhà Nhỏ"
                         },
                         {
                           "maTruong": "79769838",
                           "tenTruong": "MN Ngôi Sao Tuổi Thơ"
                         },
                         {
                           "maTruong": "79769839",
                           "tenTruong": "MN Vương Quốc Tí Hon KV1"
                         },
                         {
                           "maTruong": "79769822",
                           "tenTruong": "MN Global Ecokids"
                         },
                         {
                           "maTruong": "79763L90",
                           "tenTruong": "LMG Vườn Kiwi"
                         },
                         {
                           "maTruong": "79763O20",
                           "tenTruong": "NT Con Cưng"
                         },
                         {
                           "maTruong": "79763L92",
                           "tenTruong": "LMG Sao Việt IOC"
                         },
                         {
                           "maTruong": "79763O22",
                           "tenTruong": "NT Cá Mập Con"
                         },
                         {
                           "maTruong": "79763L93",
                           "tenTruong": "LMG Nhân Phú"
                         },
                         {
                           "maTruong": "79763L94",
                           "tenTruong": "LMG Ngôi Sao Nhỏ"
                         },
                         {
                           "maTruong": "79763L95",
                           "tenTruong": "LMG Ngôi Sao Việt"
                         },
                         {
                           "maTruong": "79763L96",
                           "tenTruong": "LMG Mầm Non Việt"
                         },
                         {
                           "maTruong": "79763L99",
                           "tenTruong": "LMG Nắng Sớm"
                         },
                         {
                           "maTruong": "79763W54",
                           "tenTruong": "LMG Thiên Nga"
                         },
                         {
                           "maTruong": "79763354",
                           "tenTruong": "MN Thiên Phúc"
                         },
                         {
                           "maTruong": "79763355",
                           "tenTruong": "MN Thiên Ân Phúc 4"
                         },
                         {
                           "maTruong": "79763356",
                           "tenTruong": "MN Mặt Trời Á Châu"
                         },
                         {
                           "maTruong": "79763357",
                           "tenTruong": "MN Diệu Kỳ"
                         },
                         {
                           "maTruong": "79763358",
                           "tenTruong": "MN Thiên Ân Phúc 5"
                         },
                         {
                           "maTruong": "79763359",
                           "tenTruong": "MN Hồ Ngọc Cẩn"
                         },
                         {
                           "maTruong": "79769514",
                           "tenTruong": "TH eSchool"
                         },
                         {
                           "maTruong": "79769414",
                           "tenTruong": "TH Kiến Tạo"
                         },
                         {
                           "maTruong": "79769251",
                           "tenTruong": "MN Ánh Dương KV1"
                         },
                         {
                           "maTruong": "7976AS12",
                           "tenTruong": "MN Hoa Mặt Trời"
                         },
                         {
                           "maTruong": "79769999",
                           "tenTruong": "MN Lá Phong Việt"
                         },
                         {
                           "maTruong": "79769988",
                           "tenTruong": "MN Tuổi Hồng Linh Đông"
                         },
                         {
                           "maTruong": "79769990",
                           "tenTruong": "NT Hồng Hà"
                         },
                         {
                           "maTruong": "79762130",
                           "tenTruong": "NT Ngôi Sao Xanh KV3"
                         },
                         {
                           "maTruong": "79762300",
                           "tenTruong": "MN Tuệ Đức Võ Văn Ngân"
                         },
                         {
                           "maTruong": "79762032",
                           "tenTruong": "MN Thỏ Ngọc KV3"
                         },
                         {
                           "maTruong": "79762061",
                           "tenTruong": "MN Ngôi Nhà Hu Gô"
                         },
                         {
                           "maTruong": "79762049",
                           "tenTruong": "NT Tháp Hồng"
                         },
                         {
                           "maTruong": "79762006",
                           "tenTruong": "NT Việt Ý"
                         },
                         {
                           "maTruong": "79762127",
                           "tenTruong": "LMG Thủy Trúc"
                         },
                         {
                           "maTruong": "79762081",
                           "tenTruong": "LMG Tí Bo"
                         },
                         {
                           "maTruong": "79762020",
                           "tenTruong": "LMG Trăng Vàng"
                         },
                         {
                           "maTruong": "79762010",
                           "tenTruong": "LMG Việt Âu"
                         },
                         {
                           "maTruong": "79763L69",
                           "tenTruong": "LMG Chào Bé Yêu"
                         },
                         {
                           "maTruong": "79763L70",
                           "tenTruong": "LMG Gấu Con"
                         },
                         {
                           "maTruong": "79763L71",
                           "tenTruong": "LMG Bảo Minh"
                         },
                         {
                           "maTruong": "79763347",
                           "tenTruong": "MN Thiên Ân Phúc 3"
                         },
                         {
                           "maTruong": "79763L72",
                           "tenTruong": "LMG Chuông Vàng"
                         },
                         {
                           "maTruong": "79763348",
                           "tenTruong": "MN Vương Quốc Tí Hon"
                         },
                         {
                           "maTruong": "79763349",
                           "tenTruong": "MN Kim Đồng"
                         },
                         {
                           "maTruong": "79763350",
                           "tenTruong": "MN Tuệ Đức KV2"
                         },
                         {
                           "maTruong": "79763L73",
                           "tenTruong": "LMG Nắng Ban Mai"
                         },
                         {
                           "maTruong": "79763351",
                           "tenTruong": "MN Hoa Diên Vĩ"
                         },
                         {
                           "maTruong": "79763L74",
                           "tenTruong": "LMG Em Bé Hạnh Phúc"
                         },
                         {
                           "maTruong": "79763L75",
                           "tenTruong": "LMG Tài Năng Á Châu"
                         },
                         {
                           "maTruong": "79763L76",
                           "tenTruong": "NT 151"
                         },
                         {
                           "maTruong": "79763352",
                           "tenTruong": "MN Hoàng Kim"
                         },
                         {
                           "maTruong": "79763353",
                           "tenTruong": "MN Bầu Trời Xanh KV2"
                         },
                         {
                           "maTruong": "79763L77",
                           "tenTruong": "LMG Nụ Cười Trẻ Thơ"
                         },
                         {
                           "maTruong": "79763L78",
                           "tenTruong": "LMG Sen Việt"
                         },
                         {
                           "maTruong": "79763L79",
                           "tenTruong": "NT Tuyết Vân"
                         },
                         {
                           "maTruong": "79763L80",
                           "tenTruong": "LMG Chim Sơn Ca"
                         },
                         {
                           "maTruong": "79763L81",
                           "tenTruong": "LMG Bé Yêu"
                         },
                         {
                           "maTruong": "79763L82",
                           "tenTruong": "LMG Hoa Mai"
                         },
                         {
                           "maTruong": "79763L84",
                           "tenTruong": "LMG Họa Mi"
                         },
                         {
                           "maTruong": "79763L85",
                           "tenTruong": "LMG Ánh Dương"
                         },
                         {
                           "maTruong": "79763L86",
                           "tenTruong": "LMG Tuổi Thần Tiên"
                         },
                         {
                           "maTruong": "79763L87",
                           "tenTruong": "LMG Phương Đông"
                         },
                         {
                           "maTruong": "79763L88",
                           "tenTruong": "NT Kỳ Anh"
                         },
                         {
                           "maTruong": "79763422",
                           "tenTruong": "TH Phạm Văn Chính"
                         },
                         {
                           "maTruong": "79769816",
                           "tenTruong": "NT Ngôi Sao Nhỏ"
                         },
                         {
                           "maTruong": "79769817",
                           "tenTruong": "MN Banh và Bi"
                         },
                         {
                           "maTruong": "79769818",
                           "tenTruong": "NT Ánh Bình Minh"
                         },
                         {
                           "maTruong": "79762424",
                           "tenTruong": "TH Linh Chiểu"
                         },
                         {
                           "maTruong": "79762516",
                           "tenTruong": "THCS Dương Văn Thì"
                         },
                         {
                           "maTruong": "79762104",
                           "tenTruong": "LMG Hoa Trạng Nguyên"
                         },
                         {
                           "maTruong": "79762106",
                           "tenTruong": "LMG Hừng Đông"
                         },
                         {
                           "maTruong": "79762109",
                           "tenTruong": "MN Hoa Diên Vĩ Phạm Văn Đồng"
                         },
                         {
                           "maTruong": "79762110",
                           "tenTruong": "MN Mặt Trời"
                         },
                         {
                           "maTruong": "79762111",
                           "tenTruong": "MN Mặt Trời Nhỏ 3"
                         },
                         {
                           "maTruong": "79762113",
                           "tenTruong": "MN Ngôi Nhà Xanh"
                         },
                         {
                           "maTruong": "79763513",
                           "tenTruong": "THCS Hiệp Phú"
                         },
                         {
                           "maTruong": "79763L89",
                           "tenTruong": "LMG Minh Anh"
                         },
                         {
                           "maTruong": "79762114",
                           "tenTruong": "MN Thanh Thúy"
                         },
                         {
                           "maTruong": "79762115",
                           "tenTruong": "MN Sao Mai"
                         },
                         {
                           "maTruong": "79769025",
                           "tenTruong": "NT Cà Chua Bi"
                         },
                         {
                           "maTruong": "79769101",
                           "tenTruong": "NT Hạnh Phúc"
                         },
                         {
                           "maTruong": "79769102",
                           "tenTruong": "LMG Vàng Anh"
                         },
                         {
                           "maTruong": "79769104",
                           "tenTruong": "MN Tuổi Thơ"
                         },
                         {
                           "maTruong": "79769106",
                           "tenTruong": "LMG Thuận Thảo"
                         },
                         {
                           "maTruong": "79769108",
                           "tenTruong": "LMG Ngọc Diệp"
                         },
                         {
                           "maTruong": "79769110",
                           "tenTruong": "MN Thiên Ân"
                         },
                         {
                           "maTruong": "79769111",
                           "tenTruong": "LMG Phượng Hồng KV1"
                         },
                         {
                           "maTruong": "79769113",
                           "tenTruong": "LMG Thanh Bình"
                         },
                         {
                           "maTruong": "79769116",
                           "tenTruong": "MN ABC KV1"
                         },
                         {
                           "maTruong": "79769117",
                           "tenTruong": "LMG Hoa Mai KV1"
                         },
                         {
                           "maTruong": "79769123",
                           "tenTruong": "NT Hoa Nắng"
                         },
                         {
                           "maTruong": "79769205",
                           "tenTruong": "MN Cỏ Non"
                         },
                         {
                           "maTruong": "79769206",
                           "tenTruong": "MN Chào Bạn Nhỏ"
                         },
                         {
                           "maTruong": "79769207",
                           "tenTruong": "LMG Cát Minh"
                         },
                         {
                           "maTruong": "79769209",
                           "tenTruong": "MN Hạt Đậu Nhỏ"
                         },
                         {
                           "maTruong": "79769301",
                           "tenTruong": "MN 19/5"
                         },
                         {
                           "maTruong": "79769302",
                           "tenTruong": "MN Hoa Hồng"
                         },
                         {
                           "maTruong": "79769303",
                           "tenTruong": "MN Bình An"
                         },
                         {
                           "maTruong": "79769304",
                           "tenTruong": "MN Bình Trưng Đông"
                         },
                         {
                           "maTruong": "79769305",
                           "tenTruong": "MN Cát Lái"
                         },
                         {
                           "maTruong": "79769306",
                           "tenTruong": "MN Hoạ Mi"
                         },
                         {
                           "maTruong": "79769307",
                           "tenTruong": "MN Măng Non"
                         },
                         {
                           "maTruong": "79769308",
                           "tenTruong": "MN Thảo Điền"
                         },
                         {
                           "maTruong": "79769310",
                           "tenTruong": "MN Vườn Hồng"
                         },
                         {
                           "maTruong": "79769311",
                           "tenTruong": "MN Bình Minh"
                         },
                         {
                           "maTruong": "79769312",
                           "tenTruong": "MN Tân Đông"
                         },
                         {
                           "maTruong": "79769313",
                           "tenTruong": "MN Sen Hồng"
                         },
                         {
                           "maTruong": "79769314",
                           "tenTruong": "MN An Phú"
                         },
                         {
                           "maTruong": "79769315",
                           "tenTruong": "MN Thạnh Mỹ Lợi"
                         },
                         {
                           "maTruong": "79769319",
                           "tenTruong": "MN Tương Lai"
                         },
                         {
                           "maTruong": "79769321",
                           "tenTruong": "MN Ánh Cầu Vồng"
                         },
                         {
                           "maTruong": "79769323",
                           "tenTruong": "MN Chân Xinh"
                         },
                         {
                           "maTruong": "79769324",
                           "tenTruong": "MN Sơn Ca"
                         },
                         {
                           "maTruong": "79769325",
                           "tenTruong": "MN Montessori"
                         },
                         {
                           "maTruong": "79769327",
                           "tenTruong": "MN Trẻ Em Sài Gòn"
                         },
                         {
                           "maTruong": "79769328",
                           "tenTruong": "MN Khôi Nguyên"
                         },
                         {
                           "maTruong": "79769329",
                           "tenTruong": "MN Việt Mỹ Sài Gòn"
                         },
                         {
                           "maTruong": "79769330",
                           "tenTruong": "MN Thần Đồng"
                         },
                         {
                           "maTruong": "79769331",
                           "tenTruong": "MN Vành Khuyên 1"
                         },
                         {
                           "maTruong": "79769333",
                           "tenTruong": "MN Đông Sài Gòn"
                         },
                         {
                           "maTruong": "79769334",
                           "tenTruong": "MN Miền Trẻ Thơ KV1"
                         },
                         {
                           "maTruong": "79769335",
                           "tenTruong": "MN Hoa Anh Đào"
                         },
                         {
                           "maTruong": "79769336",
                           "tenTruong": "MN Én Nhỏ"
                         },
                         {
                           "maTruong": "79769337",
                           "tenTruong": "MN Thái Bình"
                         },
                         {
                           "maTruong": "79769338",
                           "tenTruong": "MN Chuột Túi Thông Minh"
                         },
                         {
                           "maTruong": "79769339",
                           "tenTruong": "MN Nụ Cười Hồng"
                         },
                         {
                           "maTruong": "79769340",
                           "tenTruong": "MN Chú Ong Vui Vẻ"
                         },
                         {
                           "maTruong": "79769341",
                           "tenTruong": "MN Sao Việt"
                         },
                         {
                           "maTruong": "79769343",
                           "tenTruong": "MN Hừng Đông"
                         },
                         {
                           "maTruong": "79769344",
                           "tenTruong": "MN Viên Ngọc Sáng"
                         },
                         {
                           "maTruong": "79769345",
                           "tenTruong": "MN Yêu Con"
                         },
                         {
                           "maTruong": "79769346",
                           "tenTruong": "MN Bé Yêu"
                         },
                         {
                           "maTruong": "79769347",
                           "tenTruong": "MN Úc Châu"
                         },
                         {
                           "maTruong": "79769348",
                           "tenTruong": "MN Ngôi Nhà Trẻ Thơ"
                         },
                         {
                           "maTruong": "79769352",
                           "tenTruong": "MN An Bình"
                         },
                         {
                           "maTruong": "79769353",
                           "tenTruong": "MN Trẻ em quốc tế Smartkids"
                         },
                         {
                           "maTruong": "79769354",
                           "tenTruong": "MN Hoa Bồ Công Anh"
                         },
                         {
                           "maTruong": "79769355",
                           "tenTruong": "MN Thành Phố Tuổi Thơ"
                         },
                         {
                           "maTruong": "79769356",
                           "tenTruong": "MN Mỹ Đức"
                         },
                         {
                           "maTruong": "79769357",
                           "tenTruong": "MN Hoa Hướng Dương"
                         },
                         {
                           "maTruong": "79769359",
                           "tenTruong": "MN Mặt Trời Nhỏ KV1"
                         },
                         {
                           "maTruong": "79769360",
                           "tenTruong": "MN Hoa Diên Vĩ"
                         },
                         {
                           "maTruong": "79769361",
                           "tenTruong": "MN Tuổi Thơ Kỳ Diệu"
                         },
                         {
                           "maTruong": "79769362",
                           "tenTruong": "MN ĐÔNG BẮC"
                         },
                         {
                           "maTruong": "79769363",
                           "tenTruong": "MN Thiên Nhiên"
                         },
                         {
                           "maTruong": "79769364",
                           "tenTruong": "MN Nhóc Trùm"
                         },
                         {
                           "maTruong": "79769365",
                           "tenTruong": "MN Thành Phố Tuổi Hoa"
                         },
                         {
                           "maTruong": "79769366",
                           "tenTruong": "MN Vịt Con"
                         },
                         {
                           "maTruong": "79769367",
                           "tenTruong": "MN Hành Tinh Trẻ"
                         },
                         {
                           "maTruong": "79769368",
                           "tenTruong": "MN Cỏ 3 Lá"
                         },
                         {
                           "maTruong": "79769369",
                           "tenTruong": "MN Tre Xanh"
                         },
                         {
                           "maTruong": "79769370",
                           "tenTruong": "MN Chuồn Chuồn Kim"
                         },
                         {
                           "maTruong": "79769371",
                           "tenTruong": "MN Nhà Bé Vui"
                         },
                         {
                           "maTruong": "79769372",
                           "tenTruong": "MN Nhà Của Bé"
                         },
                         {
                           "maTruong": "79769373",
                           "tenTruong": "MN Bé Ong Sài Gòn"
                         },
                         {
                           "maTruong": "79769381",
                           "tenTruong": "MN Ngôi Nhà Vui Nhộn"
                         },
                         {
                           "maTruong": "79769390",
                           "tenTruong": "MN Tài Năng Tuổi Thơ"
                         },
                         {
                           "maTruong": "79769401",
                           "tenTruong": "TH An Khánh"
                         },
                         {
                           "maTruong": "79769404",
                           "tenTruong": "TH Giồng Ông Tố"
                         },
                         {
                           "maTruong": "79769405",
                           "tenTruong": "TH Huỳnh Văn Ngỡi"
                         },
                         {
                           "maTruong": "79769406",
                           "tenTruong": "TH Mỹ Thủy"
                         },
                         {
                           "maTruong": "79769407",
                           "tenTruong": "TH Nguyễn Văn Trỗi"
                         },
                         {
                           "maTruong": "79769409",
                           "tenTruong": "TH Nguyễn Hiền"
                         },
                         {
                           "maTruong": "79769410",
                           "tenTruong": "TH Lương Thế Vinh 1"
                         },
                         {
                           "maTruong": "79769411",
                           "tenTruong": "TH - THCS Tuệ Đức"
                         },
                         {
                           "maTruong": "79769412",
                           "tenTruong": "TH An Bình"
                         },
                         {
                           "maTruong": "79769413",
                           "tenTruong": "TH Bình Trưng Đông"
                         },
                         {
                           "maTruong": "79769477",
                           "tenTruong": "NT Hoa Sữa"
                         },
                         {
                           "maTruong": "79769501",
                           "tenTruong": "THCS An Phú"
                         },
                         {
                           "maTruong": "79769503",
                           "tenTruong": "THCS Nguyễn Văn Trỗi"
                         },
                         {
                           "maTruong": "79769504",
                           "tenTruong": "THCS Thạnh Mỹ Lợi"
                         },
                         {
                           "maTruong": "79769506",
                           "tenTruong": "THCS Lương Định Của"
                         },
                         {
                           "maTruong": "79769507",
                           "tenTruong": "THCS Bình An"
                         },
                         {
                           "maTruong": "79769508",
                           "tenTruong": "THCS Nguyễn Thị Định"
                         },
                         {
                           "maTruong": "79769510",
                           "tenTruong": "THCS Cát Lái"
                         },
                         {
                           "maTruong": "79769701",
                           "tenTruong": "MN Việt Úc"
                         },
                         {
                           "maTruong": "79769710",
                           "tenTruong": "MN Vườn Trẻ Thơ"
                         },
                         {
                           "maTruong": "79769711",
                           "tenTruong": "MN Bàn Tay Thiên Thần"
                         },
                         {
                           "maTruong": "79769712",
                           "tenTruong": "MN Vương Quốc KiKo"
                         },
                         {
                           "maTruong": "79769718",
                           "tenTruong": "MN Tuệ Đức KV1"
                         },
                         {
                           "maTruong": "79769801",
                           "tenTruong": "MN Vùng Đất Tuổi Thơ"
                         },
                         {
                           "maTruong": "79769802",
                           "tenTruong": "LMG Hoa Xuyến Chi"
                         },
                         {
                           "maTruong": "79769803",
                           "tenTruong": "MN Bước Chân Tuổi Thơ"
                         },
                         {
                           "maTruong": "79769814",
                           "tenTruong": "LMG Tuổi thần tiên KV1"
                         },
                         {
                           "maTruong": "79769815",
                           "tenTruong": "NT Bảo Hân"
                         },
                         {
                           "maTruong": "79769L10",
                           "tenTruong": "NT Ngôi Sao Xanh"
                         },
                         {
                           "maTruong": "79769L12",
                           "tenTruong": "NT Én Vàng"
                         },
                         {
                           "maTruong": "79769L13",
                           "tenTruong": "NT Hoa Yêu Thương"
                         },
                         {
                           "maTruong": "79769L14",
                           "tenTruong": "NT Nhà Cà Rốt"
                         },
                         {
                           "maTruong": "79769L15",
                           "tenTruong": "LMG Trẻ Tài Năng"
                         },
                         {
                           "maTruong": "79769L81",
                           "tenTruong": "NT Hồng Ân"
                         },
                         {
                           "maTruong": "79769L83",
                           "tenTruong": "MN Nụ Cười Xinh"
                         },
                         {
                           "maTruong": "79769L86",
                           "tenTruong": "MG Uyên Thảo"
                         },
                         {
                           "maTruong": "79769L87",
                           "tenTruong": "LMG Hoa Phượng"
                         },
                         {
                           "maTruong": "79769L88",
                           "tenTruong": "MN 1 Tháng 6"
                         },
                         {
                           "maTruong": "79769L89",
                           "tenTruong": "LMG Hồng Nhung"
                         },
                         {
                           "maTruong": "79769L90",
                           "tenTruong": "MG Thỏ Trắng"
                         },
                         {
                           "maTruong": "79769L91",
                           "tenTruong": "NT Việt Anh"
                         },
                         {
                           "maTruong": "79769W02",
                           "tenTruong": "NT Ngôi Nhà Hạnh Phúc"
                         },
                         {
                           "maTruong": "79769W03",
                           "tenTruong": "MN Ngôi Sao"
                         },
                         {
                           "maTruong": "79769W08",
                           "tenTruong": "MG Gia An"
                         },
                         {
                           "maTruong": "79769W09",
                           "tenTruong": "NT Hải Âu"
                         },
                         {
                           "maTruong": "79769W10",
                           "tenTruong": "LMG Sao Mai"
                         },
                         {
                           "maTruong": "79769W11",
                           "tenTruong": "MN Bầu Trời Xanh"
                         },
                         {
                           "maTruong": "79769W12",
                           "tenTruong": "MN Mặt Trời Hồng"
                         },
                         {
                           "maTruong": "79769W15",
                           "tenTruong": "MN Thiên Thần Nhỏ Kv1"
                         },
                         {
                           "maTruong": "79769W16",
                           "tenTruong": "NT Bé Ngoan"
                         },
                         {
                           "maTruong": "79769W21",
                           "tenTruong": "LMG Rạng Đông"
                         },
                         {
                           "maTruong": "79769W25",
                           "tenTruong": "NT  Ước Mơ"
                         },
                         {
                           "maTruong": "79769W26",
                           "tenTruong": "LMG Ánh Sao KV1"
                         },
                         {
                           "maTruong": "79762101",
                           "tenTruong": "MN Thành Phố Tuổi Thơ"
                         },
                         {
                           "maTruong": "79762102",
                           "tenTruong": "MN Học Viện Hoa Kỳ"
                         },
                         {
                           "maTruong": "79762105",
                           "tenTruong": "MN Hải Hà"
                         },
                         {
                           "maTruong": "79762112",
                           "tenTruong": "MN Hương Lan"
                         },
                         {
                           "maTruong": "79762118",
                           "tenTruong": "LMG Phượng Hồng"
                         },
                         {
                           "maTruong": "79762121",
                           "tenTruong": "NT Chim Non"
                         },
                         {
                           "maTruong": "79762122",
                           "tenTruong": "LMG Tin Tin"
                         },
                         {
                           "maTruong": "79762125",
                           "tenTruong": "LMG Thiên Thần KV3"
                         },
                         {
                           "maTruong": "79762126",
                           "tenTruong": "MN Sao Vui"
                         },
                         {
                           "maTruong": "79762128",
                           "tenTruong": "LMG Bé Ngoan KV3"
                         },
                         {
                           "maTruong": "79762129",
                           "tenTruong": "LMG Hồng Phúc"
                         },
                         {
                           "maTruong": "79762133",
                           "tenTruong": "MN Thiên Ca"
                         },
                         {
                           "maTruong": "79762134",
                           "tenTruong": "LMG Bình Minh"
                         },
                         {
                           "maTruong": "79762137",
                           "tenTruong": "LMG Họa Mi KV3"
                         },
                         {
                           "maTruong": "79762144",
                           "tenTruong": "MN Bông Sen"
                         },
                         {
                           "maTruong": "79762145",
                           "tenTruong": "NT Huyền Trâm"
                         },
                         {
                           "maTruong": "79762147",
                           "tenTruong": "MN Vàng Anh Trường Thọ"
                         },
                         {
                           "maTruong": "79762148",
                           "tenTruong": "LMG Đức Trí"
                         },
                         {
                           "maTruong": "79762149",
                           "tenTruong": "MN Mai Vàng"
                         },
                         {
                           "maTruong": "79762151",
                           "tenTruong": "MN Bích Trâm"
                         },
                         {
                           "maTruong": "79762153",
                           "tenTruong": "MN Thiên Thần Nhỏ"
                         },
                         {
                           "maTruong": "79762155",
                           "tenTruong": "LMG Đại Việt Mỹ"
                         },
                         {
                           "maTruong": "79762160",
                           "tenTruong": "LMG Vân Phong"
                         },
                         {
                           "maTruong": "79762161",
                           "tenTruong": "NT Ánh Linh"
                         },
                         {
                           "maTruong": "79762162",
                           "tenTruong": "LMG Ánh Sao KV3"
                         },
                         {
                           "maTruong": "79762163",
                           "tenTruong": "LMG Ánh Sáng"
                         },
                         {
                           "maTruong": "79762167",
                           "tenTruong": "LMG Tuổi Thơ"
                         },
                         {
                           "maTruong": "79762168",
                           "tenTruong": "NT Hương Quế"
                         },
                         {
                           "maTruong": "79762170",
                           "tenTruong": "NT Phúc Ân"
                         },
                         {
                           "maTruong": "79762172",
                           "tenTruong": "NT Bông Hoa Nhỏ"
                         },
                         {
                           "maTruong": "79762173",
                           "tenTruong": "LMG Chim Vàng Anh"
                         },
                         {
                           "maTruong": "79762174",
                           "tenTruong": "LMG Hoàng Kim"
                         },
                         {
                           "maTruong": "79762175",
                           "tenTruong": "LMG Thiên Ý"
                         },
                         {
                           "maTruong": "79762176",
                           "tenTruong": "LMG Hy Vọng"
                         },
                         {
                           "maTruong": "79762178",
                           "tenTruong": "NT Dương Đông"
                         },
                         {
                           "maTruong": "79762179",
                           "tenTruong": "NT Thiên Phúc"
                         },
                         {
                           "maTruong": "79762180",
                           "tenTruong": "LMG Sóc Nâu"
                         },
                         {
                           "maTruong": "79762182",
                           "tenTruong": "NT Thảo Nguyên"
                         },
                         {
                           "maTruong": "79762183",
                           "tenTruong": "LMG Thiên Nhi"
                         },
                         {
                           "maTruong": "79762190",
                           "tenTruong": "LMG Hoa Nắng KV3"
                         },
                         {
                           "maTruong": "79762192",
                           "tenTruong": "NT Mai Hoa"
                         },
                         {
                           "maTruong": "79762193",
                           "tenTruong": "NT Sao Việt"
                         },
                         {
                           "maTruong": "79762194",
                           "tenTruong": "LMG An Ý"
                         },
                         {
                           "maTruong": "79762197",
                           "tenTruong": "LMG Hoàng Hạc"
                         },
                         {
                           "maTruong": "79762198",
                           "tenTruong": "NT Phương Mi"
                         },
                         {
                           "maTruong": "797621A7",
                           "tenTruong": "LMG Em Bé Hạnh Phúc KV3"
                         },
                         {
                           "maTruong": "797621W1",
                           "tenTruong": "LMG Ngọc Đạt"
                         },
                         {
                           "maTruong": "797621W2",
                           "tenTruong": "LMG Khang Nguyên"
                         },
                         {
                           "maTruong": "797621W3",
                           "tenTruong": "LMG Thiên Hương"
                         },
                         {
                           "maTruong": "797621W4",
                           "tenTruong": "LMG Hằng Nga"
                         },
                         {
                           "maTruong": "797621W5",
                           "tenTruong": "NT Gấu Bông"
                         },
                         {
                           "maTruong": "79762200",
                           "tenTruong": "NT Búp Măng Non"
                         },
                         {
                           "maTruong": "79762201",
                           "tenTruong": "LMG Bảo Hân"
                         },
                         {
                           "maTruong": "79762202",
                           "tenTruong": "LMG Sao Việt Mỹ KV3"
                         },
                         {
                           "maTruong": "79762203",
                           "tenTruong": "LMG Tuổi Xinh"
                         },
                         {
                           "maTruong": "79762204",
                           "tenTruong": "MN Sen Hồng 3"
                         },
                         {
                           "maTruong": "79762211",
                           "tenTruong": "MN Hồng Ân"
                         },
                         {
                           "maTruong": "79762213",
                           "tenTruong": "MN Huệ Trắng"
                         },
                         {
                           "maTruong": "79762215",
                           "tenTruong": "MG Linh Ân"
                         },
                         {
                           "maTruong": "79762216",
                           "tenTruong": "MN Hoàng Anh"
                         },
                         {
                           "maTruong": "79762217",
                           "tenTruong": "MG Mai Khôi"
                         },
                         {
                           "maTruong": "79762220",
                           "tenTruong": "MN Mai Anh"
                         },
                         {
                           "maTruong": "79762221",
                           "tenTruong": "MG Hạnh Mai"
                         },
                         {
                           "maTruong": "79762222",
                           "tenTruong": "MN Hướng Dương"
                         },
                         {
                           "maTruong": "79762223",
                           "tenTruong": "MG Ánh Hồng"
                         },
                         {
                           "maTruong": "79762224",
                           "tenTruong": "MG Hoa Cúc"
                         },
                         {
                           "maTruong": "79762301",
                           "tenTruong": "MN Búp Sen Hồng"
                         },
                         {
                           "maTruong": "79762302",
                           "tenTruong": "MN Hiệp Bình Chánh"
                         },
                         {
                           "maTruong": "79762303",
                           "tenTruong": "MN Hiệp Bình Phước"
                         },
                         {
                           "maTruong": "79762304",
                           "tenTruong": "MN Bình Thọ"
                         },
                         {
                           "maTruong": "79762305",
                           "tenTruong": "MN Vành Khuyên 3"
                         },
                         {
                           "maTruong": "79762306",
                           "tenTruong": "MN Hiệp Bình Chánh II"
                         },
                         {
                           "maTruong": "79762307",
                           "tenTruong": "MN Hiệp Bình Chánh III"
                         },
                         {
                           "maTruong": "79762308",
                           "tenTruong": "MN Bình Chiểu"
                         },
                         {
                           "maTruong": "79762309",
                           "tenTruong": "MN Linh Xuân"
                         },
                         {
                           "maTruong": "79762310",
                           "tenTruong": "MN Trường Thọ"
                         },
                         {
                           "maTruong": "79762311",
                           "tenTruong": "MN Hoa Phượng"
                         },
                         {
                           "maTruong": "79762312",
                           "tenTruong": "MN Khiết Tâm"
                         },
                         {
                           "maTruong": "79762313",
                           "tenTruong": "MN Nhân Ái"
                         },
                         {
                           "maTruong": "79762315",
                           "tenTruong": "MN Linh Chiểu"
                         },
                         {
                           "maTruong": "79762316",
                           "tenTruong": "MN Tam Bình"
                         },
                         {
                           "maTruong": "79762317",
                           "tenTruong": "MN Hương Sen"
                         },
                         {
                           "maTruong": "79762319",
                           "tenTruong": "MN Linh Trung"
                         },
                         {
                           "maTruong": "79762320",
                           "tenTruong": "MN Tam Phú"
                         },
                         {
                           "maTruong": "79762321",
                           "tenTruong": "MN Học Viện Trí Tuệ"
                         },
                         {
                           "maTruong": "79762322",
                           "tenTruong": "MN Thanh Tâm"
                         },
                         {
                           "maTruong": "79762323",
                           "tenTruong": "MN Măng Non 3"
                         },
                         {
                           "maTruong": "79762324",
                           "tenTruong": "MN Trinh Vương"
                         },
                         {
                           "maTruong": "79762325",
                           "tenTruong": "MN Hạnh Phúc"
                         },
                         {
                           "maTruong": "79762326",
                           "tenTruong": "MN Thủ Đức"
                         },
                         {
                           "maTruong": "79762327",
                           "tenTruong": "MN Ngọc Lan"
                         },
                         {
                           "maTruong": "79762328",
                           "tenTruong": "MN Việt Mỹ Sài Gòn "
                         },
                         {
                           "maTruong": "79762329",
                           "tenTruong": "MN Khánh Hỷ"
                         },
                         {
                           "maTruong": "79762331",
                           "tenTruong": "MN Kiểu Mẫu"
                         },
                         {
                           "maTruong": "79762332",
                           "tenTruong": "MN Tuổi Ngọc 3"
                         },
                         {
                           "maTruong": "79762334",
                           "tenTruong": "MN Thiên Ân 3"
                         },
                         {
                           "maTruong": "79762338",
                           "tenTruong": "MN Hiếu Trung"
                         },
                         {
                           "maTruong": "79762340",
                           "tenTruong": "MN Ngôi Nhà Hạnh Phúc Linh Đông"
                         },
                         {
                           "maTruong": "79762341",
                           "tenTruong": "MN Nhật Tân"
                         },
                         {
                           "maTruong": "79762342",
                           "tenTruong": "MN Du Sinh "
                         },
                         {
                           "maTruong": "79762344",
                           "tenTruong": "MN Việt Mỹ"
                         },
                         {
                           "maTruong": "79762346",
                           "tenTruong": "MN 20/11"
                         },
                         {
                           "maTruong": "79762347",
                           "tenTruong": "MN Linh Tây"
                         },
                         {
                           "maTruong": "79762348",
                           "tenTruong": "MN Hoa Xuân"
                         },
                         {
                           "maTruong": "79762349",
                           "tenTruong": "MN Trúc Xanh"
                         },
                         {
                           "maTruong": "79762351",
                           "tenTruong": "MN Bông Sen Xanh"
                         },
                         {
                           "maTruong": "79762352",
                           "tenTruong": "MN Gấu Trúc"
                         },
                         {
                           "maTruong": "79762353",
                           "tenTruong": "MN Gia Huy"
                         },
                         {
                           "maTruong": "79762354",
                           "tenTruong": "MN Mỹ Phước"
                         },
                         {
                           "maTruong": "79762356",
                           "tenTruong": "MN Đông Dương"
                         },
                         {
                           "maTruong": "79762357",
                           "tenTruong": "MN Mỹ An"
                         },
                         {
                           "maTruong": "79762358",
                           "tenTruong": "MN Hoa Mai"
                         },
                         {
                           "maTruong": "79762359",
                           "tenTruong": "MN Tuổi Tiên"
                         },
                         {
                           "maTruong": "79762361",
                           "tenTruong": "MN Vầng Dương"
                         },
                         {
                           "maTruong": "79762362",
                           "tenTruong": "MN Bé Xinh"
                         },
                         {
                           "maTruong": "79762363",
                           "tenTruong": "MN Birrong Sài Gòn"
                         },
                         {
                           "maTruong": "79762364",
                           "tenTruong": "MN Cánh Thiên Thần"
                         },
                         {
                           "maTruong": "79762365",
                           "tenTruong": "LMG Phượng Hoàng"
                         },
                         {
                           "maTruong": "79762367",
                           "tenTruong": "MN Hải Âu"
                         },
                         {
                           "maTruong": "79762368",
                           "tenTruong": "MN Tâm Nhi"
                         },
                         {
                           "maTruong": "79762369",
                           "tenTruong": "MN Khai Tâm"
                         },
                         {
                           "maTruong": "79762370",
                           "tenTruong": "MG Mai Tâm"
                         },
                         {
                           "maTruong": "79762371",
                           "tenTruong": "MN Mặt Trời Đỏ"
                         },
                         {
                           "maTruong": "79762372",
                           "tenTruong": "MN Bồ Công Anh"
                         },
                         {
                           "maTruong": "79762373",
                           "tenTruong": "MN Việt Anh"
                         },
                         {
                           "maTruong": "79762374",
                           "tenTruong": "MN Sơn Ca 3"
                         },
                         {
                           "maTruong": "79762376",
                           "tenTruong": "MN Hương Nắng Hồng"
                         },
                         {
                           "maTruong": "79762377",
                           "tenTruong": "MN Hoa Sen Bình Chiểu"
                         },
                         {
                           "maTruong": "79762378",
                           "tenTruong": "MN Hải Yến"
                         },
                         {
                           "maTruong": "79762380",
                           "tenTruong": "MN Sao Ngoan"
                         },
                         {
                           "maTruong": "79762381",
                           "tenTruong": "MN Thần Đồng 3"
                         },
                         {
                           "maTruong": "79762382",
                           "tenTruong": "MN Sài Gòn"
                         },
                         {
                           "maTruong": "79762383",
                           "tenTruong": "MN Vườn Tuổi Thơ"
                         },
                         {
                           "maTruong": "79762384",
                           "tenTruong": "MN An Lộc"
                         },
                         {
                           "maTruong": "79762385",
                           "tenTruong": "MN Hoàng Ngọc"
                         },
                         {
                           "maTruong": "79762386",
                           "tenTruong": "MN Ánh Dương 3"
                         },
                         {
                           "maTruong": "79762388",
                           "tenTruong": "MN Sao Vàng"
                         },
                         {
                           "maTruong": "79762389",
                           "tenTruong": "MN Mai An"
                         },
                         {
                           "maTruong": "79762391",
                           "tenTruong": "MN Thanh Bình"
                         },
                         {
                           "maTruong": "79762393",
                           "tenTruong": "MN Hoa Đào"
                         },
                         {
                           "maTruong": "79762394",
                           "tenTruong": "MN Hoàng Yến 3"
                         },
                         {
                           "maTruong": "79762395",
                           "tenTruong": "MN CS Thí Điểm Giữ Trẻ"
                         },
                         {
                           "maTruong": "79762396",
                           "tenTruong": "MN Ngôi Sao Sáng "
                         },
                         {
                           "maTruong": "79762397",
                           "tenTruong": "MN Sông Xanh"
                         },
                         {
                           "maTruong": "79762398",
                           "tenTruong": "MN Bầu Trời Xanh 3"
                         },
                         {
                           "maTruong": "79762399",
                           "tenTruong": "MN Việt Đông Dương"
                         },
                         {
                           "maTruong": "797623W7",
                           "tenTruong": "MN Bi Bi"
                         },
                         {
                           "maTruong": "797623W8",
                           "tenTruong": "MN Hoa Bình Minh"
                         },
                         {
                           "maTruong": "797623W9",
                           "tenTruong": "MN Họa Mi 3"
                         },
                         {
                           "maTruong": "797623X0",
                           "tenTruong": "MN Hoa Hồng 3"
                         },
                         {
                           "maTruong": "797623X1",
                           "tenTruong": "MN Bến Thành"
                         },
                         {
                           "maTruong": "797623X2",
                           "tenTruong": "MN Thế Giới Xanh TĐ"
                         },
                         {
                           "maTruong": "797623X3",
                           "tenTruong": "MN Mặt Trời Bé Con"
                         },
                         {
                           "maTruong": "797623X4",
                           "tenTruong": "MN Mây Hồng"
                         },
                         {
                           "maTruong": "797623X5",
                           "tenTruong": "MN Sắc Màu Tuổi Thơ"
                         },
                         {
                           "maTruong": "79762402",
                           "tenTruong": "TH Lương Thế Vinh"
                         },
                         {
                           "maTruong": "79762404",
                           "tenTruong": "TH Bình Triệu"
                         },
                         {
                           "maTruong": "79762405",
                           "tenTruong": "TH Đặng Thị Rành"
                         },
                         {
                           "maTruong": "79762406",
                           "tenTruong": "TH Hiệp Bình Phước"
                         },
                         {
                           "maTruong": "79762407",
                           "tenTruong": "TH Thái Văn Lung"
                         },
                         {
                           "maTruong": "79762408",
                           "tenTruong": "TH Tam Bình"
                         },
                         {
                           "maTruong": "79762409",
                           "tenTruong": "TH Trần Văn Vân"
                         },
                         {
                           "maTruong": "79762410",
                           "tenTruong": "TH Bình Chiểu"
                         },
                         {
                           "maTruong": "79762411",
                           "tenTruong": "TH Xuân Hiệp"
                         },
                         {
                           "maTruong": "79762412",
                           "tenTruong": "TH Hoàng Diệu"
                         },
                         {
                           "maTruong": "79762413",
                           "tenTruong": "TH Nguyễn Văn Triết"
                         },
                         {
                           "maTruong": "79762414",
                           "tenTruong": "TH Linh Tây"
                         },
                         {
                           "maTruong": "79762415",
                           "tenTruong": "TH Bình Quới"
                         },
                         {
                           "maTruong": "79762416",
                           "tenTruong": "TH Đặng Văn Bất"
                         },
                         {
                           "maTruong": "79762417",
                           "tenTruong": "TH Linh Đông"
                         },
                         {
                           "maTruong": "79762420",
                           "tenTruong": "TH Từ Đức"
                         },
                         {
                           "maTruong": "79762421",
                           "tenTruong": "TH Trương Văn Hải"
                         },
                         {
                           "maTruong": "79762422",
                           "tenTruong": "TH Nguyễn Văn Lịch"
                         },
                         {
                           "maTruong": "79762423",
                           "tenTruong": "TH Đào Sơn Tây"
                         },
                         {
                           "maTruong": "79762427",
                           "tenTruong": "TH Đỗ Tấn Phong"
                         },
                         {
                           "maTruong": "79762429",
                           "tenTruong": "TH Nguyễn Văn Nở"
                         },
                         {
                           "maTruong": "79762501",
                           "tenTruong": "THCS Lê Quý Đôn"
                         },
                         {
                           "maTruong": "79762502",
                           "tenTruong": "THCS Trương Văn Ngư"
                         },
                         {
                           "maTruong": "79762503",
                           "tenTruong": "THCS Ngô Chí Quốc"
                         },
                         {
                           "maTruong": "79762504",
                           "tenTruong": "THCS Hiệp Bình"
                         },
                         {
                           "maTruong": "79762505",
                           "tenTruong": "THCS Thái Văn Lung"
                         },
                         {
                           "maTruong": "79762506",
                           "tenTruong": "THCS Tam Bình"
                         },
                         {
                           "maTruong": "79762507",
                           "tenTruong": "THCS Xuân Trường"
                         },
                         {
                           "maTruong": "79762508",
                           "tenTruong": "THCS Linh Trung"
                         },
                         {
                           "maTruong": "79762509",
                           "tenTruong": "THCS Linh Đông"
                         },
                         {
                           "maTruong": "7976201060",
                           "tenTruong": "LMG Hạnh phúc nhà mình"
                         },
                         {
                           "maTruong": "7976201052",
                           "tenTruong": "LMG Trẻ Em Mới"
                         },
                         {
                           "maTruong": "7976201055",
                           "tenTruong": "NT Hoa Mùa Xuân"
                         },
                         {
                           "maTruong": "79763038",
                           "tenTruong": "NT Chú Ta Tu Nhỏ"
                         },
                         {
                           "maTruong": "79763119",
                           "tenTruong": "LMG Ngôi Sao Phương Nam"
                         },
                         {
                           "maTruong": "79763071",
                           "tenTruong": "NT Bé Thông Minh"
                         },
                         {
                           "maTruong": "79763167",
                           "tenTruong": "NT Hoàng Oanh"
                         },
                         {
                           "maTruong": "79763196",
                           "tenTruong": "LMG Hạt Đậu Nhỏ"
                         },
                         {
                           "maTruong": "79763219",
                           "tenTruong": "LMG Lê Minh"
                         },
                         {
                           "maTruong": "79763134",
                           "tenTruong": "LMG Hoa Mai Vàng"
                         },
                         {
                           "maTruong": "79763012",
                           "tenTruong": "LMG Hoa Thiên"
                         },
                         {
                           "maTruong": "79763187",
                           "tenTruong": "LMG Ngôi Nhà Nhỏ"
                         },
                         {
                           "maTruong": "79763212",
                           "tenTruong": "LMG Sao Tuổi Thơ"
                         },
                         {
                           "maTruong": "79763198",
                           "tenTruong": "NT Vườn Trẻ Thơ"
                         },
                         {
                           "maTruong": "79763132",
                           "tenTruong": "LMG Cầu Vồng"
                         },
                         {
                           "maTruong": "79763103",
                           "tenTruong": "LMG Yêu Con"
                         },
                         {
                           "maTruong": "79763239",
                           "tenTruong": "LMG Hoa Cọ Vàng"
                         },
                         {
                           "maTruong": "79763423",
                           "tenTruong": "TH Khai Nguyên"
                         },
                         {
                           "maTruong": "79763143",
                           "tenTruong": "MN Cocoon Kén Nhỏ"
                         },
                         {
                           "maTruong": "79762283",
                           "tenTruong": "NT Hòa Bình"
                         },
                         {
                           "maTruong": "79762040",
                           "tenTruong": "NT Cánh Diều Vàng"
                         },
                         {
                           "maTruong": "79762264",
                           "tenTruong": "LMG Tương Lai Việt"
                         },
                         {
                           "maTruong": "79762003",
                           "tenTruong": "LMG Cầu Vòng KV3"
                         },
                         {
                           "maTruong": "79762294",
                           "tenTruong": "LMG Bí Ngô"
                         },
                         {
                           "maTruong": "79769016",
                           "tenTruong": "MN Con Đường Tuệ Đức"
                         },
                         {
                           "maTruong": "79769225",
                           "tenTruong": "MN Con Đường Tuệ Đức"
                         },
                         {
                           "maTruong": "79769105",
                           "tenTruong": "MN Kiến Tạo"
                         },
                         {
                           "maTruong": "7976201003",
                           "tenTruong": "LMG Những Ngôi Sao Nhỏ"
                         },
                         {
                           "maTruong": "7976201004",
                           "tenTruong": "LMG Thuận Thiên"
                         },
                         {
                           "maTruong": "7976201005",
                           "tenTruong": "LMG Ngôi Nhà Hạnh Phúc"
                         },
                         {
                           "maTruong": "7976201006",
                           "tenTruong": "LMG Bé Cưng"
                         },
                         {
                           "maTruong": "7976201007",
                           "tenTruong": "LMG Khu Vườn Kiwi"
                         },
                         {
                           "maTruong": "7976201008",
                           "tenTruong": "LMG Sao Việt Mỹ"
                         },
                         {
                           "maTruong": "7976201009",
                           "tenTruong": "LMG Mai Anh Đào"
                         },
                         {
                           "maTruong": "7976201010",
                           "tenTruong": "NT Ngôi Nhà Tuổi Thơ"
                         },
                         {
                           "maTruong": "7976201012",
                           "tenTruong": "LMG Hoa Hướng Dương"
                         },
                         {
                           "maTruong": "7976201013",
                           "tenTruong": "LMG Hoàng Long"
                         },
                         {
                           "maTruong": "7976201014",
                           "tenTruong": "NT Lê Quý Đôn"
                         },
                         {
                           "maTruong": "7976201015",
                           "tenTruong": "NT Hoa Bình Minh"
                         },
                         {
                           "maTruong": "7976201016",
                           "tenTruong": "NT Kỳ Diệu"
                         },
                         {
                           "maTruong": "7976201017",
                           "tenTruong": "NT Đậu Nhỏ"
                         },
                         {
                           "maTruong": "7976201018",
                           "tenTruong": "LMG Làng Hạnh Phúc"
                         },
                         {
                           "maTruong": "7976201019",
                           "tenTruong": "LMG Hươu Cao Cổ"
                         },
                         {
                           "maTruong": "7976201020",
                           "tenTruong": "NT Sao Việt Nam Long"
                         },
                         {
                           "maTruong": "7976201021",
                           "tenTruong": "NT Ước Mơ Xanh"
                         },
                         {
                           "maTruong": "7976201022",
                           "tenTruong": "NT Vườn Ươm Nụ Cười"
                         },
                         {
                           "maTruong": "7976201023",
                           "tenTruong": "LMG Siêu Nhóc"
                         },
                         {
                           "maTruong": "7976201024",
                           "tenTruong": "LMG Lan Ngọc"
                         },
                         {
                           "maTruong": "7976201025",
                           "tenTruong": "LMG Ngôi Sao May Mắn"
                         },
                         {
                           "maTruong": "7976201026",
                           "tenTruong": "LMG Rồng Vàng"
                         },
                         {
                           "maTruong": "7976201027",
                           "tenTruong": "LMG Hoa Hồng"
                         },
                         {
                           "maTruong": "7976201028",
                           "tenTruong": "LMG Mai Anh Hai"
                         },
                         {
                           "maTruong": "7976201030",
                           "tenTruong": "LMG Minh Trang"
                         },
                         {
                           "maTruong": "7976201031",
                           "tenTruong": "LMG Hoa Nắng KV2"
                         },
                         {
                           "maTruong": "7976201033",
                           "tenTruong": "NT Nụ Cười Đô Rê Mon"
                         },
                         {
                           "maTruong": "7976201034",
                           "tenTruong": "NT ABC Khang An"
                         },
                         {
                           "maTruong": "7976201035",
                           "tenTruong": "LMG Bông Cỏ"
                         },
                         {
                           "maTruong": "7976201036",
                           "tenTruong": "LMG EQ"
                         },
                         {
                           "maTruong": "7976201037",
                           "tenTruong": "LMG Trường Xanh"
                         },
                         {
                           "maTruong": "7976201038",
                           "tenTruong": "LMG Ngôi Nhà Vui Vẻ"
                         },
                         {
                           "maTruong": "7976201039",
                           "tenTruong": "LMG Thế Giới Trẻ Thơ"
                         },
                         {
                           "maTruong": "7976201040",
                           "tenTruong": "LMG Sao Vàng"
                         },
                         {
                           "maTruong": "7976201041",
                           "tenTruong": "LMG Vịt Con"
                         },
                         {
                           "maTruong": "7976201042",
                           "tenTruong": "MN Bách Hân"
                         },
                         {
                           "maTruong": "7976201044",
                           "tenTruong": "LMG Trí Tuệ"
                         },
                         {
                           "maTruong": "7976201045",
                           "tenTruong": "LMG Phúc An"
                         },
                         {
                           "maTruong": "7976201046",
                           "tenTruong": "LMG Tâm An"
                         },
                         {
                           "maTruong": "7976201047",
                           "tenTruong": "MN Totto-Chan"
                         },
                         {
                           "maTruong": "7976201001",
                           "tenTruong": "MN Trẻ Thơ"
                         },
                         {
                           "maTruong": "7976201048",
                           "tenTruong": "LMG Bí Ngô"
                         },
                         {
                           "maTruong": "7976201049",
                           "tenTruong": "LMG Tương Lai Việt"
                         },
                         {
                           "maTruong": "7976201050",
                           "tenTruong": "NT Sài Gòn Nhỏ"
                         },
                         {
                           "maTruong": "7976201002",
                           "tenTruong": "MN Thế Giới Xanh"
                         },
                         {
                           "maTruong": "7976201051",
                           "tenTruong": "NT Cánh Diều Vàng"
                         },
                         {
                           "maTruong": "7976202001",
                           "tenTruong": "TH Nguyễn Thị Tư"
                         },
                         {
                           "maTruong": "7976201029",
                           "tenTruong": "MN Nhật Bản Thảo ĐIền"
                         },
                         {
                           "maTruong": "7976201056",
                           "tenTruong": "MN Thế Giới Trẻ Thơ"
                         },
                         {
                           "maTruong": "7976201057",
                           "tenTruong": "NT Nhà Ong Vàng"
                         },
                         {
                           "maTruong": "7976201011",
                           "tenTruong": "NT Việt Mỹ"
                         },
                         {
                           "maTruong": "7976201043",
                           "tenTruong": "LMG Chích Chòe Bông"
                         },
                         {
                           "maTruong": "7976201062",
                           "tenTruong": "MN Chân Trời Trẻ Thơ"
                         },
                         {
                           "maTruong": "7976201058",
                           "tenTruong": "LMG Tân Mai"
                         },
                         {
                           "maTruong": "7976201059",
                           "tenTruong": "LMG Ngọc Nhi"
                         },
                         {
                           "maTruong": "7976201061",
                           "tenTruong": "LMG Khai Minh Trí"
                         },
                         {
                           "maTruong": "79769819",
                           "tenTruong": "NT Mầm non Xanh"
                         },
                         {
                           "maTruong": "79769403",
                           "tenTruong": "TH An Phú"
                         },
                         {
                           "maTruong": "79769502",
                           "tenTruong": "THCS Giồng Ông Tố"
                         },
                         {
                           "maTruong": "79769509",
                           "tenTruong": "THCS Trần Quốc Toản 1"
                         },
                         {
                           "maTruong": "79762401",
                           "tenTruong": "TH Nguyễn Trung Trực"
                         },
                         {
                           "maTruong": "79762418",
                           "tenTruong": "TH Nguyễn Văn Banh"
                         },
                         {
                           "maTruong": "79763409",
                           "tenTruong": "TH Nguyễn Minh Quang"
                         },
                         {
                           "maTruong": "7976201103",
                           "tenTruong": "LMG Mùa Xuân"
                         },
                         {
                           "maTruong": "7976201104",
                           "tenTruong": "LMN Nhà Sông Montessori"
                         },
                         {
                           "maTruong": "7976201105",
                           "tenTruong": "NT Toàn Cầu"
                         },
                         {
                           "maTruong": "7976201106",
                           "tenTruong": "LMG Bông Hoa Nhỏ"
                         },
                         {
                           "maTruong": "7976201090",
                           "tenTruong": "MN An Khánh "
                         },
                         {
                           "maTruong": "7976201091",
                           "tenTruong": "LMN Những Ngôi Sao Xinh"
                         },
                         {
                           "maTruong": "7976201092",
                           "tenTruong": "LMN Ong Xinh Xinh"
                         },
                         {
                           "maTruong": "7976201093",
                           "tenTruong": "LMN Hạt Mầm"
                         },
                         {
                           "maTruong": "7976201094",
                           "tenTruong": "LMN Tài Năng Nhí ABC"
                         },
                         {
                           "maTruong": "7976201095",
                           "tenTruong": "LMN Cà Chua Đỏ"
                         },
                         {
                           "maTruong": "7976201096",
                           "tenTruong": "LMG Ta Tu"
                         },
                         {
                           "maTruong": "7976201097",
                           "tenTruong": "LMG Hoa"
                         },
                         {
                           "maTruong": "7976201098",
                           "tenTruong": "LMG Cỏ"
                         },
                         {
                           "maTruong": "7976201099",
                           "tenTruong": "NT Chích bông"
                         },
                         {
                           "maTruong": "7976201100",
                           "tenTruong": "LMN Tâm Phúc"
                         },
                         {
                           "maTruong": "7976201101",
                           "tenTruong": "MN Toàn Cầu"
                         },
                         {
                           "maTruong": "7976201102",
                           "tenTruong": "LMN Thiện An"
                         },
                         {
                           "maTruong": "7976201085",
                           "tenTruong": "LMN Trái Đất Xanh"
                         },
                         {
                           "maTruong": "7976201086",
                           "tenTruong": "MN Chú Ong Nhỏ"
                         },
                         {
                           "maTruong": "7976201087",
                           "tenTruong": "LMN Viên Ngọc Sáng"
                         },
                         {
                           "maTruong": "7976201088",
                           "tenTruong": "LMN Nắng Mới"
                         },
                         {
                           "maTruong": "7976201089",
                           "tenTruong": "MN Rạch Chiếc"
                         },
                         {
                           "maTruong": "7976201084",
                           "tenTruong": "LMG Vuông Tròn"
                         },
                         {
                           "maTruong": "7976201082",
                           "tenTruong": "LMG Cầu Vồng KV2"
                         },
                         {
                           "maTruong": "7976201081",
                           "tenTruong": "LMN Cá Chép Đỏ"
                         },
                         {
                           "maTruong": "7976202002",
                           "tenTruong": "TH E-School-Thạnh Mỹ Lợi"
                         },
                         {
                           "maTruong": "7976201063",
                           "tenTruong": "NT Hồng Kim"
                         },
                         {
                           "maTruong": "7976201064",
                           "tenTruong": "LMG Mặt Trời Hồng"
                         },
                         {
                           "maTruong": "7976201065",
                           "tenTruong": "LMG Viên Kẹo Nhỏ"
                         },
                         {
                           "maTruong": "7976201066",
                           "tenTruong": "LMG Bé Yêu Vui Cười"
                         },
                         {
                           "maTruong": "7976201072",
                           "tenTruong": "LMG Hoa Bi"
                         },
                         {
                           "maTruong": "7976201067",
                           "tenTruong": "LMN Mặt Trời Trẻ Thơ"
                         },
                         {
                           "maTruong": "7976201068",
                           "tenTruong": "LMG Kiến Con"
                         },
                         {
                           "maTruong": "7976201069",
                           "tenTruong": "NT Ngôi Nhà Hướng Dương"
                         },
                         {
                           "maTruong": "7976201070",
                           "tenTruong": "MN VinSchool"
                         },
                         {
                           "maTruong": "7976201071",
                           "tenTruong": "NT Tuệ Tâm"
                         },
                         {
                           "maTruong": "7976201083",
                           "tenTruong": "LMG Quả Cầu Nhỏ"
                         },
                         {
                           "maTruong": "7976201073",
                           "tenTruong": "NT Kiến An"
                         },
                         {
                           "maTruong": "7976201074",
                           "tenTruong": "LMN Khu Vườn Việt Nam"
                         },
                         {
                           "maTruong": "7976201075",
                           "tenTruong": "LMN Cây To"
                         },
                         {
                           "maTruong": "7976201076",
                           "tenTruong": "NT Nắng Vàng"
                         },
                         {
                           "maTruong": "7976201077",
                           "tenTruong": "NT Bong Bóng Nhỏ"
                         },
                         {
                           "maTruong": "7976201078",
                           "tenTruong": "LMG Hoa Bé Ngoan"
                         },
                         {
                           "maTruong": "7976201079",
                           "tenTruong": "LMG Những Chú Thợ Săn Nhí"
                         },
                         {
                           "maTruong": "7976201080",
                           "tenTruong": "LMG Khu Rừng Diệu Kỳ"
                         },
                         {
                           "maTruong": "7976201032",
                           "tenTruong": "LMG Bảo Minh"
                         },
                         {
                           "maTruong": "7976201054",
                           "tenTruong": "MN Học Viện Đầu Tiên"
                         },
                         {
                           "maTruong": "79762428",
                           "tenTruong": "TH Nguyễn Văn Tây"
                         },
                         {
                           "maTruong": "79769408",
                           "tenTruong": "TH Thạnh Mỹ lợi"
                         },
                         {
                           "maTruong": "79763007",
                           "tenTruong": "NT Bút Chì"
                         },
                         {
                           "maTruong": "79763421",
                           "tenTruong": "TH Trần Thị Bưởi"
                         },
                         {
                           "maTruong": "79763L91",
                           "tenTruong": "LMG Gấu Trúc"
                         }
                       ]
                     },
                     {
                       "maPhong": "764",
                       "tenPhong": "Quận Gò Vấp",
                       "truongData": [
                         {
                           "maTruong": "79764411",
                           "tenTruong": "TH An Hội"
                         },
                         {
                           "maTruong": "79764410",
                           "tenTruong": "TH Chi Lăng"
                         },
                         {
                           "maTruong": "79764404",
                           "tenTruong": "TH Hanh Thông"
                         },
                         {
                           "maTruong": "79764419",
                           "tenTruong": "TH Hoàng Văn Thụ"
                         },
                         {
                           "maTruong": "79764408",
                           "tenTruong": "TH Kim Đồng"
                         },
                         {
                           "maTruong": "79764412",
                           "tenTruong": "TH Lam Sơn"
                         },
                         {
                           "maTruong": "79764423",
                           "tenTruong": "TH Lê Đức Thọ"
                         },
                         {
                           "maTruong": "79764413",
                           "tenTruong": "TH Lê Thị Hồng Gấm"
                         },
                         {
                           "maTruong": "79764424",
                           "tenTruong": "TH Lê Văn Thọ"
                         },
                         {
                           "maTruong": "79764414",
                           "tenTruong": "TH Lương Thế Vinh"
                         },
                         {
                           "maTruong": "79764409",
                           "tenTruong": "TH Nguyễn Thị Minh Khai"
                         },
                         {
                           "maTruong": "79764401",
                           "tenTruong": "TH Nguyễn Thượng Hiền"
                         },
                         {
                           "maTruong": "79764405",
                           "tenTruong": "TH Nguyễn Viết Xuân"
                         },
                         {
                           "maTruong": "79764403",
                           "tenTruong": "TH Phạm Ngũ Lão"
                         },
                         {
                           "maTruong": "79764416",
                           "tenTruong": "TH Phan Chu Trinh"
                         },
                         {
                           "maTruong": "79764418",
                           "tenTruong": "TH Trần Quang Khải"
                         },
                         {
                           "maTruong": "79764407",
                           "tenTruong": "TH Trần Quốc Toản"
                         },
                         {
                           "maTruong": "79764402",
                           "tenTruong": "TH Trần Văn Ơn"
                         },
                         {
                           "maTruong": "79764422",
                           "tenTruong": "TH Việt Mỹ"
                         },
                         {
                           "maTruong": "79764417",
                           "tenTruong": "TH Võ Thị Sáu"
                         },
                         {
                           "maTruong": "79764415",
                           "tenTruong": "TIỂU HỌC LÊ HOÀN"
                         },
                         {
                           "maTruong": "79764421",
                           "tenTruong": "Tiểu học Lê Quý Đôn"
                         },
                         {
                           "maTruong": "79764420",
                           "tenTruong": "Tiểu học Tư thục Nhựt Tân"
                         },
                         {
                           "maTruong": "79764508",
                           "tenTruong": "THCS An Nhơn"
                         },
                         {
                           "maTruong": "79764503",
                           "tenTruong": "THCS Gò Vấp"
                         },
                         {
                           "maTruong": "79764515",
                           "tenTruong": "THCS Huỳnh Văn Nghệ"
                         },
                         {
                           "maTruong": "79764512",
                           "tenTruong": "THCS Lý Tự Trọng"
                         },
                         {
                           "maTruong": "79764502",
                           "tenTruong": "THCS Nguyễn Du"
                         },
                         {
                           "maTruong": "79764513",
                           "tenTruong": "THCS Nguyễn Trãi"
                         },
                         {
                           "maTruong": "79764509",
                           "tenTruong": "THCS Nguyễn Văn Nghi"
                         },
                         {
                           "maTruong": "79764501",
                           "tenTruong": "THCS Nguyễn Văn Trỗi"
                         },
                         {
                           "maTruong": "79764505",
                           "tenTruong": "THCS Phạm Văn Chiêu"
                         },
                         {
                           "maTruong": "79764507",
                           "tenTruong": "THCS Phan Tây Hồ"
                         },
                         {
                           "maTruong": "79764516",
                           "tenTruong": "THCS Phan Văn Trị"
                         },
                         {
                           "maTruong": "79764504",
                           "tenTruong": "THCS Quang Trung"
                         },
                         {
                           "maTruong": "79764514",
                           "tenTruong": "THCS Tân Sơn"
                         },
                         {
                           "maTruong": "79764511",
                           "tenTruong": "THCS Thông Tây Hội"
                         },
                         {
                           "maTruong": "79764510",
                           "tenTruong": "THCS Trường Sơn"
                         },
                         {
                           "maTruong": "79000810",
                           "tenTruong": "THCS - THPT Hồng Hà"
                         },
                         {
                           "maTruong": "79000825",
                           "tenTruong": "THCS và THPT Phạm Ngũ Lão"
                         },
                         {
                           "maTruong": "79000791",
                           "tenTruong": "THCS và THPT Phùng Hưng"
                         },
                         {
                           "maTruong": "79000C01",
                           "tenTruong": "PTDL HERMANN GMEINER"
                         },
                         {
                           "maTruong": "79000C09",
                           "tenTruong": "TH - THCS - THPT Nguyễn Tri Phương"
                         },
                         {
                           "maTruong": "79764217",
                           "tenTruong": "MẪU GIÁO SOS"
                         },
                         {
                           "maTruong": "79764206",
                           "tenTruong": "MG Duy An"
                         },
                         {
                           "maTruong": "79764216",
                           "tenTruong": "MG Hoàng Mai"
                         },
                         {
                           "maTruong": "79764202",
                           "tenTruong": "MG Khiết Tâm"
                         },
                         {
                           "maTruong": "79764210",
                           "tenTruong": "MG Mai Anh"
                         },
                         {
                           "maTruong": "79764212",
                           "tenTruong": "MG Mai Hương"
                         },
                         {
                           "maTruong": "79764205",
                           "tenTruong": "MG Mai Khôi"
                         },
                         {
                           "maTruong": "79764204",
                           "tenTruong": "MG Mỹ Sơn"
                         },
                         {
                           "maTruong": "79764218",
                           "tenTruong": "MG Phúc An"
                         },
                         {
                           "maTruong": "79764201",
                           "tenTruong": "MG Sao Mai"
                         },
                         {
                           "maTruong": "79764W29",
                           "tenTruong": "Mầm Non Xuân Hiề̀n"
                         },
                         {
                           "maTruong": "79764207",
                           "tenTruong": "MG Đức Tuấn"
                         },
                         {
                           "maTruong": "79764323",
                           "tenTruong": "MN 1/6"
                         },
                         {
                           "maTruong": "79764330",
                           "tenTruong": "MN An Lộc"
                         },
                         {
                           "maTruong": "79764356",
                           "tenTruong": "MN An Nhơn"
                         },
                         {
                           "maTruong": "79764318",
                           "tenTruong": "MN Anh Đào"
                         },
                         {
                           "maTruong": "79764336",
                           "tenTruong": "MN Bầu Trời Xanh"
                         },
                         {
                           "maTruong": "79764335",
                           "tenTruong": "MN Đại Việt Mỹ"
                         },
                         {
                           "maTruong": "79764333",
                           "tenTruong": "MN Dâu Tây"
                         },
                         {
                           "maTruong": "79764326",
                           "tenTruong": "MN Dế Mèn"
                         },
                         {
                           "maTruong": "79764339",
                           "tenTruong": "MN Hạnh Thông Tây"
                         },
                         {
                           "maTruong": "79764303",
                           "tenTruong": "MN Hoa Hồng"
                         },
                         {
                           "maTruong": "79764305",
                           "tenTruong": "MN Hoa Lan"
                         },
                         {
                           "maTruong": "79764325",
                           "tenTruong": "MN Hoa Mai"
                         },
                         {
                           "maTruong": "79764329",
                           "tenTruong": "MN Hoa Mặt Trời"
                         },
                         {
                           "maTruong": "79764307",
                           "tenTruong": "MN Họa Mi"
                         },
                         {
                           "maTruong": "79764357",
                           "tenTruong": "MN Hoa Phượng Đỏ"
                         },
                         {
                           "maTruong": "79764302",
                           "tenTruong": "MN Hoa Quỳnh"
                         },
                         {
                           "maTruong": "79764314",
                           "tenTruong": "MN Hoa Sen"
                         },
                         {
                           "maTruong": "79764W53",
                           "tenTruong": "MN Hoa Thanh Bình"
                         },
                         {
                           "maTruong": "79764315",
                           "tenTruong": "MN Hoàng Yến"
                         },
                         {
                           "maTruong": "79764215",
                           "tenTruong": "MN Hồng Ân"
                         },
                         {
                           "maTruong": "79764354",
                           "tenTruong": "MN Hồng Hạnh"
                         },
                         {
                           "maTruong": "79764306",
                           "tenTruong": "MN Hồng Nhung"
                         },
                         {
                           "maTruong": "79764311",
                           "tenTruong": "MN Hướng Dương"
                         },
                         {
                           "maTruong": "79764319",
                           "tenTruong": "MN Hương Sen"
                         },
                         {
                           "maTruong": "79764W18",
                           "tenTruong": "MN Mèo Kitty"
                         },
                         {
                           "maTruong": "79764337",
                           "tenTruong": "MN Miền Trẻ Thơ"
                         },
                         {
                           "maTruong": "79764341",
                           "tenTruong": "MN Mỹ Sài Gòn"
                         },
                         {
                           "maTruong": "79764316",
                           "tenTruong": "MN Ngọc Lan"
                         },
                         {
                           "maTruong": "79764338",
                           "tenTruong": "MN Ngôi Nhà Trẻ Thơ"
                         },
                         {
                           "maTruong": "79764304",
                           "tenTruong": "MN Nhật Quỳnh"
                         },
                         {
                           "maTruong": "79764W44",
                           "tenTruong": "MN Phi Thuyền Apollo"
                         },
                         {
                           "maTruong": "79764317",
                           "tenTruong": "MN Quỳnh Hương"
                         },
                         {
                           "maTruong": "79764313",
                           "tenTruong": "MN Sen Hồng"
                         },
                         {
                           "maTruong": "79764301",
                           "tenTruong": "MN Sóc Nâu"
                         },
                         {
                           "maTruong": "79764308",
                           "tenTruong": "MN Sơn Ca"
                         },
                         {
                           "maTruong": "79764334",
                           "tenTruong": "MN Táo Đỏ"
                         },
                         {
                           "maTruong": "79764W47",
                           "tenTruong": "MN Thần Đồng"
                         },
                         {
                           "maTruong": "79764W37",
                           "tenTruong": "MN Thần Mặt Trời"
                         },
                         {
                           "maTruong": "79764320",
                           "tenTruong": "MN Thế Giới Trẻ Em"
                         },
                         {
                           "maTruong": "79764355",
                           "tenTruong": "MN Thế Giới Trẻ Thơ"
                         },
                         {
                           "maTruong": "79764324",
                           "tenTruong": "MN Thiên Ân Phúc 2"
                         },
                         {
                           "maTruong": "79764322",
                           "tenTruong": "MN Thiên Thanh"
                         },
                         {
                           "maTruong": "79764309",
                           "tenTruong": "MN Thủy Tiên"
                         },
                         {
                           "maTruong": "79764331",
                           "tenTruong": "MN Tinh Tú"
                         },
                         {
                           "maTruong": "79764W54",
                           "tenTruong": "MN Tư thục Bé XuKa"
                         },
                         {
                           "maTruong": "79764W20",
                           "tenTruong": "MN Tư Thục Mạ Non"
                         },
                         {
                           "maTruong": "79764321",
                           "tenTruong": "MN Tuổi Thần Tiên"
                         },
                         {
                           "maTruong": "79764312",
                           "tenTruong": "MN Tường Vi"
                         },
                         {
                           "maTruong": "79764373",
                           "tenTruong": "MN Vạn An"
                         },
                         {
                           "maTruong": "79764310",
                           "tenTruong": "MN Vàng Anh"
                         },
                         {
                           "maTruong": "79764327",
                           "tenTruong": "MN Vành Khuyên"
                         },
                         {
                           "maTruong": "79764332",
                           "tenTruong": "MN Việt Mỹ"
                         },
                         {
                           "maTruong": "79764L72",
                           "tenTruong": "MN Vườn Cổ Tích"
                         },
                         {
                           "maTruong": "79764W24",
                           "tenTruong": "MNTT Việt Úc"
                         },
                         {
                           "maTruong": "79764W85",
                           "tenTruong": "TRƯỜNG MẦM NON Bé Ngôi Sao"
                         },
                         {
                           "maTruong": "79764WB8",
                           "tenTruong": "Bé Mai Ka"
                         },
                         {
                           "maTruong": "79764WB7",
                           "tenTruong": "Mẫu giáo Cánh Diều"
                         },
                         {
                           "maTruong": "79764W19",
                           "tenTruong": "MG 19/5"
                         },
                         {
                           "maTruong": "79764W02",
                           "tenTruong": "MG ABC"
                         },
                         {
                           "maTruong": "79764X22",
                           "tenTruong": "MG Ánh Cầu Vồng"
                         },
                         {
                           "maTruong": "79764L75",
                           "tenTruong": "MG Ánh Dương"
                         },
                         {
                           "maTruong": "79764X02",
                           "tenTruong": "MG Ánh Hồng"
                         },
                         {
                           "maTruong": "79764X04",
                           "tenTruong": "MG Ánh Sao"
                         },
                         {
                           "maTruong": "79764O01",
                           "tenTruong": "MG BB"
                         },
                         {
                           "maTruong": "79764W23",
                           "tenTruong": "MG Bé Hạnh Phúc"
                         },
                         {
                           "maTruong": "79764L77",
                           "tenTruong": "MG Bình An"
                         },
                         {
                           "maTruong": "79764X15",
                           "tenTruong": "MG Bông Hoa Nhỏ"
                         },
                         {
                           "maTruong": "79764X09",
                           "tenTruong": "MG Bút Chì Màu"
                         },
                         {
                           "maTruong": "79764W65",
                           "tenTruong": "MG Chú Kiến Nhỏ"
                         },
                         {
                           "maTruong": "79764L80",
                           "tenTruong": "MG Chú Ong Nhỏ"
                         },
                         {
                           "maTruong": "79764X17",
                           "tenTruong": "MG Chuồn Chuồn Ớt"
                         },
                         {
                           "maTruong": "79764X32",
                           "tenTruong": "MG Chuột MicKey"
                         },
                         {
                           "maTruong": "79764L74",
                           "tenTruong": "MG Đại Dương"
                         },
                         {
                           "maTruong": "79764X13",
                           "tenTruong": "MG Đồng Dao"
                         },
                         {
                           "maTruong": "79764X34",
                           "tenTruong": "MG Gấu MiSa"
                         },
                         {
                           "maTruong": "79764X21",
                           "tenTruong": "MG Hiền MInh"
                         },
                         {
                           "maTruong": "79764X03",
                           "tenTruong": "MG Hương Thủy"
                         },
                         {
                           "maTruong": "79764L76",
                           "tenTruong": "MG Hương Tường Vi"
                         },
                         {
                           "maTruong": "79764X23",
                           "tenTruong": "MG Hươu Vàng"
                         },
                         {
                           "maTruong": "79764X27",
                           "tenTruong": "MG Khai Tâm"
                         },
                         {
                           "maTruong": "79764X30",
                           "tenTruong": "MG Khai Trí"
                         },
                         {
                           "maTruong": "79764W12",
                           "tenTruong": "MG Khang Anh"
                         },
                         {
                           "maTruong": "79764X07",
                           "tenTruong": "MG Măng Non"
                         },
                         {
                           "maTruong": "79764L02",
                           "tenTruong": "MG Minh Khang"
                         },
                         {
                           "maTruong": "79764X16",
                           "tenTruong": "MG Minh Tân"
                         },
                         {
                           "maTruong": "79764L81",
                           "tenTruong": "MG Nam Mỹ"
                         },
                         {
                           "maTruong": "79764L79",
                           "tenTruong": "MG Nắng Xuân"
                         },
                         {
                           "maTruong": "79764X31",
                           "tenTruong": "MG Ngôi Nhà Nhỏ"
                         },
                         {
                           "maTruong": "79764X25",
                           "tenTruong": "MG Ngôi Nhà Xanh"
                         },
                         {
                           "maTruong": "79764X24",
                           "tenTruong": "MG Ngôi Sao"
                         },
                         {
                           "maTruong": "79764X05",
                           "tenTruong": "MG Ngôi Sao Nhỏ"
                         },
                         {
                           "maTruong": "79764X01",
                           "tenTruong": "MG Ngôi Sao Việt"
                         },
                         {
                           "maTruong": "79764X10",
                           "tenTruong": "MG Nhật Hạ"
                         },
                         {
                           "maTruong": "79764W58",
                           "tenTruong": "MG Nhật Vy"
                         },
                         {
                           "maTruong": "79764WC2",
                           "tenTruong": "MG Nhựt Tân"
                         },
                         {
                           "maTruong": "79764L71",
                           "tenTruong": "MG Quả Chanh Vàng"
                         },
                         {
                           "maTruong": "79764X35",
                           "tenTruong": "MG Sao Việt Úc"
                         },
                         {
                           "maTruong": "79764L69",
                           "tenTruong": "MG Sóc Nhỏ"
                         },
                         {
                           "maTruong": "79764W69",
                           "tenTruong": "MG Song Giang"
                         },
                         {
                           "maTruong": "79764L70",
                           "tenTruong": "MG Song Hạ"
                         },
                         {
                           "maTruong": "79764X14",
                           "tenTruong": "MG Tân Á Châu"
                         },
                         {
                           "maTruong": "79764L67",
                           "tenTruong": "MG Thần Tượng Nhí"
                         },
                         {
                           "maTruong": "79764L83",
                           "tenTruong": "MG Thảo Trang"
                         },
                         {
                           "maTruong": "79764W63",
                           "tenTruong": "MG Thiên An"
                         },
                         {
                           "maTruong": "79764X20",
                           "tenTruong": "MG Thiên Phúc"
                         },
                         {
                           "maTruong": "79764X33",
                           "tenTruong": "MG Thiên Thần Nhỏ"
                         },
                         {
                           "maTruong": "79764L82",
                           "tenTruong": "MG Trí Đức"
                         },
                         {
                           "maTruong": "79764L66",
                           "tenTruong": "MG Trí Tuệ"
                         },
                         {
                           "maTruong": "79764X08",
                           "tenTruong": "MG Trúc Giang"
                         },
                         {
                           "maTruong": "79764W11",
                           "tenTruong": "MG Tuổi Thơ"
                         },
                         {
                           "maTruong": "79764W39",
                           "tenTruong": "MG Vàng Anh"
                         },
                         {
                           "maTruong": "79764X28",
                           "tenTruong": "MG Việt Âu"
                         },
                         {
                           "maTruong": "79764L78",
                           "tenTruong": "MG Việt Đức"
                         },
                         {
                           "maTruong": "79764X29",
                           "tenTruong": "MG Việt Mỹ Nhật"
                         },
                         {
                           "maTruong": "79764L73",
                           "tenTruong": "MG Việt Mỹ Úc"
                         },
                         {
                           "maTruong": "79764W74",
                           "tenTruong": "MG Yến Chi"
                         },
                         {
                           "maTruong": "79764W64",
                           "tenTruong": "MG Yến Nhi"
                         },
                         {
                           "maTruong": "79764W27",
                           "tenTruong": "MN Á Châu"
                         },
                         {
                           "maTruong": "79764W32",
                           "tenTruong": "MN BaBy"
                         },
                         {
                           "maTruong": "79764W05",
                           "tenTruong": "MN Bé Thơ"
                         },
                         {
                           "maTruong": "79764W28",
                           "tenTruong": "MN Bé Xinh"
                         },
                         {
                           "maTruong": "79764WA7",
                           "tenTruong": "MN Búp Bê Xinh"
                         },
                         {
                           "maTruong": "79764W42",
                           "tenTruong": "MN Búp Sen Hồng"
                         },
                         {
                           "maTruong": "79764W51",
                           "tenTruong": "MN Diệu Thảo"
                         },
                         {
                           "maTruong": "79764WA6",
                           "tenTruong": "MN Gấu Yêu"
                         },
                         {
                           "maTruong": "79764W89",
                           "tenTruong": "MN Hạt Đậu Nhỏ"
                         },
                         {
                           "maTruong": "79764W96",
                           "tenTruong": "MN Hoa Bé Ngoan"
                         },
                         {
                           "maTruong": "79764W50",
                           "tenTruong": "MN Hoa Đào"
                         },
                         {
                           "maTruong": "79764W61",
                           "tenTruong": "MN Hoa Hồng Nhỏ"
                         },
                         {
                           "maTruong": "79764WA1",
                           "tenTruong": "MN Hoa Hướng Dương"
                         },
                         {
                           "maTruong": "79764W06",
                           "tenTruong": "MN Họa Mi"
                         },
                         {
                           "maTruong": "79764W01",
                           "tenTruong": "MN Hoa Phượng"
                         },
                         {
                           "maTruong": "79764W80",
                           "tenTruong": "MN Hoa Tiên"
                         },
                         {
                           "maTruong": "79764W90",
                           "tenTruong": "MN Hoa Tường Vi"
                         },
                         {
                           "maTruong": "79764W07",
                           "tenTruong": "MN Hướng Dương"
                         },
                         {
                           "maTruong": "79764WB3",
                           "tenTruong": "MN Lan Hương"
                         },
                         {
                           "maTruong": "79764W87",
                           "tenTruong": "MN Mặt Trời Bé Con"
                         },
                         {
                           "maTruong": "79764W66",
                           "tenTruong": "MN Mẹ và Bé"
                         },
                         {
                           "maTruong": "79764W62",
                           "tenTruong": "MN Nắng Hồng"
                         },
                         {
                           "maTruong": "79764W70",
                           "tenTruong": "MN Nắng Mai"
                         },
                         {
                           "maTruong": "79764W22",
                           "tenTruong": "MN Ngọc Ánh"
                         },
                         {
                           "maTruong": "79764WA2",
                           "tenTruong": "MN Nhật Nam"
                         },
                         {
                           "maTruong": "79764X11",
                           "tenTruong": "MN Rạng Đông"
                         },
                         {
                           "maTruong": "79764WA8",
                           "tenTruong": "MN Sao Nhỏ"
                         },
                         {
                           "maTruong": "79764W67",
                           "tenTruong": "MN Sao Việt"
                         },
                         {
                           "maTruong": "79764W92",
                           "tenTruong": "MN Sao Việt Mỹ"
                         },
                         {
                           "maTruong": "79764W13",
                           "tenTruong": "MN Thần Đồng Việt"
                         },
                         {
                           "maTruong": "79764W93",
                           "tenTruong": "MN Thiên Ấn"
                         },
                         {
                           "maTruong": "79764W21",
                           "tenTruong": "MN Thiên Thần"
                         },
                         {
                           "maTruong": "79764W40",
                           "tenTruong": "MN Thỏ Trắng"
                         },
                         {
                           "maTruong": "79764W04",
                           "tenTruong": "MN Thương Thương"
                         },
                         {
                           "maTruong": "79764WB1",
                           "tenTruong": "MN Trúc Đào"
                         },
                         {
                           "maTruong": "79764W68",
                           "tenTruong": "MN Trúc Xanh"
                         },
                         {
                           "maTruong": "79764W48",
                           "tenTruong": "MN Tuổi Hồng"
                         },
                         {
                           "maTruong": "79764W03",
                           "tenTruong": "MN Tuổi Ngọc"
                         },
                         {
                           "maTruong": "79764W55",
                           "tenTruong": "MN Vành Khuyên 17"
                         },
                         {
                           "maTruong": "79764W83",
                           "tenTruong": "MN Xứ Thần Tiên"
                         },
                         {
                           "maTruong": "79764WB6",
                           "tenTruong": "Nhóm trẻ Bé Thiên Tài"
                         },
                         {
                           "maTruong": "79764W91",
                           "tenTruong": "Nhóm Trẻ Sơn Ca Vàng"
                         },
                         {
                           "maTruong": "79764O05",
                           "tenTruong": "NT Hươu Cao Cổ"
                         },
                         {
                           "maTruong": "79764O02",
                           "tenTruong": "NT Nụ Cười Trẻ Thơ"
                         },
                         {
                           "maTruong": "79764W35",
                           "tenTruong": "NT Thanh Nga"
                         },
                         {
                           "maTruong": "79764O83",
                           "tenTruong": "NT Thảo Nguyên"
                         },
                         {
                           "maTruong": "79764W10",
                           "tenTruong": "NT Thỏ Ngọc"
                         },
                         {
                           "maTruong": "79764O82",
                           "tenTruong": "NT Xuân Quỳnh"
                         },
                         {
                           "maTruong": "79764219",
                           "tenTruong": "Lớp MG Nhà Của Bé"
                         },
                         {
                           "maTruong": "79764220",
                           "tenTruong": "Lớp MG Nhật Nam"
                         },
                         {
                           "maTruong": "79764340",
                           "tenTruong": "Trường MN Trăng Non"
                         },
                         {
                           "maTruong": "79764203",
                           "tenTruong": "Lớp MG Hươu Sao"
                         },
                         {
                           "maTruong": "79764208",
                           "tenTruong": "Lớp MG Minh Tân"
                         },
                         {
                           "maTruong": "79764101",
                           "tenTruong": "Nhóm trẻ Minh Tân"
                         },
                         {
                           "maTruong": "79764342",
                           "tenTruong": "Trường MN Ngôi Sao Xinh"
                         },
                         {
                           "maTruong": "79764328",
                           "tenTruong": "Trường MN Học Viện Tuổi Thơ"
                         },
                         {
                           "maTruong": "79764349",
                           "tenTruong": "Trường MN 3 Ngọn Nến"
                         },
                         {
                           "maTruong": "79764105",
                           "tenTruong": "Nhóm trẻ Thanh Nga"
                         },
                         {
                           "maTruong": "79764361",
                           "tenTruong": "Trường MN Mẹ Yêu"
                         },
                         {
                           "maTruong": "79764213",
                           "tenTruong": "Trường MG Thiên Ân"
                         },
                         {
                           "maTruong": "79764254",
                           "tenTruong": "Lớp MG Khang Phú 3"
                         },
                         {
                           "maTruong": "79764102",
                           "tenTruong": "Nhóm trẻ Thiên Thần Nhí"
                         },
                         {
                           "maTruong": "79764103",
                           "tenTruong": "Nhóm trẻ Bé Thỏ"
                         },
                         {
                           "maTruong": "79764255",
                           "tenTruong": "Lớp MG Âu Việt"
                         },
                         {
                           "maTruong": "79764104",
                           "tenTruong": "Nhóm trẻ Bồ Câu Nhỏ"
                         },
                         {
                           "maTruong": "79764256",
                           "tenTruong": "Lớp MG Tuổi Ngọc"
                         },
                         {
                           "maTruong": "79764358",
                           "tenTruong": "Trường MN Chào Ngày Mới"
                         },
                         {
                           "maTruong": "79764360",
                           "tenTruong": "Trường MN Bé Gấu 2"
                         },
                         {
                           "maTruong": "79764242",
                           "tenTruong": "Lớp MG Thần Đồng Nhỏ"
                         },
                         {
                           "maTruong": "79764243",
                           "tenTruong": "Lớp MG Ngôi Sao Việt"
                         },
                         {
                           "maTruong": "79764244",
                           "tenTruong": "Lớp MG Nhà Của Mẹ"
                         },
                         {
                           "maTruong": "79764246",
                           "tenTruong": "Lớp MG Trẻ Thơ"
                         },
                         {
                           "maTruong": "79764247",
                           "tenTruong": "Lớp MG Học Viện Hoa Kỳ"
                         },
                         {
                           "maTruong": "79764249",
                           "tenTruong": "Lớp MG Thần Đồng Việt"
                         },
                         {
                           "maTruong": "7976251",
                           "tenTruong": "Lớp MG Lá Phong"
                         },
                         {
                           "maTruong": "79764252",
                           "tenTruong": "Lớp MG Sao Mỹ Pháp"
                         },
                         {
                           "maTruong": "79764253",
                           "tenTruong": "Lớp MG Sao Mỹ"
                         },
                         {
                           "maTruong": "7976470",
                           "tenTruong": "Lớp MG Thiên Thần Nhỏ 2"
                         },
                         {
                           "maTruong": "79764365",
                           "tenTruong": "Trường MN Thành Phố Tuổi Thơ"
                         },
                         {
                           "maTruong": "7976466",
                           "tenTruong": "Trường MN Bắc Mỹ"
                         },
                         {
                           "maTruong": "7976467",
                           "tenTruong": "Trường MN Ngôi Sao Tuổi Thơ"
                         },
                         {
                           "maTruong": "7976469",
                           "tenTruong": "Trường MN Xuân Hiền"
                         },
                         {
                           "maTruong": "7942638",
                           "tenTruong": "Trường MN Global Ecokids"
                         },
                         {
                           "maTruong": "7976432",
                           "tenTruong": "Lớp MG Thỏ Bông"
                         },
                         {
                           "maTruong": "7976488",
                           "tenTruong": "Lớp MG Hoa Hồng Nhỏ"
                         },
                         {
                           "maTruong": "7976499",
                           "tenTruong": "Lớp MG Nụ Cười Trẻ Thơ"
                         },
                         {
                           "maTruong": "79764248",
                           "tenTruong": "Nhóm trẻ Thủy Trúc"
                         },
                         {
                           "maTruong": "79764359",
                           "tenTruong": "Trường MN Vườn Tuổi Thơ"
                         },
                         {
                           "maTruong": "79764259",
                           "tenTruong": "Lớp MG Trí Tâm"
                         },
                         {
                           "maTruong": "79764X39",
                           "tenTruong": "Lớp MG Ong Vàng"
                         },
                         {
                           "maTruong": "79764X06",
                           "tenTruong": "Lớp Mẫu Giáo Thanh Ngọc"
                         },
                         {
                           "maTruong": "79764425",
                           "tenTruong": "TH Toàn Trí"
                         },
                         {
                           "maTruong": "79764121",
                           "tenTruong": "Nhóm trẻ Chồi Non"
                         },
                         {
                           "maTruong": "79764221",
                           "tenTruong": "Lớp MG Hoa Trà My"
                         },
                         {
                           "maTruong": "79764224",
                           "tenTruong": "Lớp MG An Tâm"
                         },
                         {
                           "maTruong": "79764364",
                           "tenTruong": "Trường MN Việt Đức"
                         },
                         {
                           "maTruong": "79764263",
                           "tenTruong": "Lớp MG Ngôi Sao Xanh"
                         },
                         {
                           "maTruong": "79764222",
                           "tenTruong": "Lớp MG Ngôi nhà Tí Nị"
                         },
                         {
                           "maTruong": "79764223",
                           "tenTruong": "Lớp MG Anh Hân"
                         },
                         {
                           "maTruong": "79764225",
                           "tenTruong": "Lớp MG Hoa Anh Thảo"
                         },
                         {
                           "maTruong": "79764226",
                           "tenTruong": "Lớp MG Ngôi Sao Sáng"
                         },
                         {
                           "maTruong": "79764362",
                           "tenTruong": "Trường MN Phú Sĩ"
                         },
                         {
                           "maTruong": "79764056",
                           "tenTruong": "Trường MN Tâm Toàn Cầu"
                         },
                         {
                           "maTruong": "79764276",
                           "tenTruong": "Trường MN Nguyên Hồng"
                         },
                         {
                           "maTruong": "79764129",
                           "tenTruong": "Lớp MG Minh Khang"
                         },
                         {
                           "maTruong": "79764109",
                           "tenTruong": "Lớp MG Ánh Dương"
                         },
                         {
                           "maTruong": "79764267",
                           "tenTruong": "Lớp MG Lá Ngọc"
                         },
                         {
                           "maTruong": "7976401001",
                           "tenTruong": "Lớp MG Nắng Xuân"
                         },
                         {
                           "maTruong": "7976401002",
                           "tenTruong": "Lớp MG Thiên Lý"
                         },
                         {
                           "maTruong": "7976401003",
                           "tenTruong": "Trường MN Phần Lan Nhỏ"
                         },
                         {
                           "maTruong": "7976401009",
                           "tenTruong": "MN Sun Shine"
                         },
                         {
                           "maTruong": "7976401011",
                           "tenTruong": "Nhóm Trẻ Tí Hon"
                         },
                         {
                           "maTruong": "7976401012",
                           "tenTruong": "Mầm Non Quốc Anh"
                         },
                         {
                           "maTruong": "7976401005",
                           "tenTruong": "Trường MG Phần Lan Nhỏ"
                         },
                         {
                           "maTruong": "7976401015",
                           "tenTruong": "Trường MN Mèo Min"
                         },
                         {
                           "maTruong": "7976401004",
                           "tenTruong": "Trường MN Việt Đức"
                         },
                         {
                           "maTruong": "7976401006",
                           "tenTruong": "Mầm Non Nguyên Hồng"
                         },
                         {
                           "maTruong": "7976401007",
                           "tenTruong": "LMG Ánh Bình Minh"
                         },
                         {
                           "maTruong": "7976401008",
                           "tenTruong": "LMG Sao Mỹ"
                         },
                         {
                           "maTruong": "7976402001",
                           "tenTruong": "TH Nam Việt"
                         },
                         {
                           "maTruong": "7976401014",
                           "tenTruong": "LMG Chào Ngày Mới"
                         },
                         {
                           "maTruong": "7976401023",
                           "tenTruong": "Trường mầm non Việt Anh"
                         },
                         {
                           "maTruong": "7976401020",
                           "tenTruong": "Trường MN Ngôi Nhà Hoa Cúc"
                         },
                         {
                           "maTruong": "7976401021",
                           "tenTruong": "Trường MN Mai Vàng"
                         },
                         {
                           "maTruong": "7976401018",
                           "tenTruong": "Mầm Non 16"
                         },
                         {
                           "maTruong": "7976401017",
                           "tenTruong": "MN Khôi Nguyên"
                         },
                         {
                           "maTruong": "7976401019",
                           "tenTruong": "Lớp MG Viên Gạch Nhỏ"
                         },
                         {
                           "maTruong": "7976401016",
                           "tenTruong": "Dãy Núi Xanh"
                         },
                         {
                           "maTruong": "7976401022",
                           "tenTruong": "Lớp mầm non Trăng Xanh"
                         },
                         {
                           "maTruong": "7976401024",
                           "tenTruong": "Lớp mẫu giáo Hươu Sao"
                         },
                         {
                           "maTruong": "7976401026",
                           "tenTruong": "Lớp Mầm Non  Dâu Tây"
                         },
                         {
                           "maTruong": "7976401049",
                           "tenTruong": "Lớp MN Chú Kiến Nhỏ"
                         },
                         {
                           "maTruong": "7976401044",
                           "tenTruong": "Lớp MN Gấu Trúc"
                         },
                         {
                           "maTruong": "7976401045",
                           "tenTruong": "Trường MN Những Con Ong Bận Rộn"
                         },
                         {
                           "maTruong": "7976401046",
                           "tenTruong": "Trường MN Sao Sáng"
                         },
                         {
                           "maTruong": "7976401047",
                           "tenTruong": "Lớp MN Trái Tim Nhỏ"
                         },
                         {
                           "maTruong": "7976401048",
                           "tenTruong": "Lớp MN Làng Nắng"
                         },
                         {
                           "maTruong": "7976401027",
                           "tenTruong": "Lớp MN Thống Nhất"
                         },
                         {
                           "maTruong": "7976401028",
                           "tenTruong": "Lớp MG Cà Rốt"
                         },
                         {
                           "maTruong": "7976401029",
                           "tenTruong": "Lớp MG Gia Hào"
                         },
                         {
                           "maTruong": "7976401030",
                           "tenTruong": "Lớp MG Bình Minh"
                         },
                         {
                           "maTruong": "7976401031",
                           "tenTruong": "Lớp MG Tân Á Châu"
                         },
                         {
                           "maTruong": "7976401032",
                           "tenTruong": "Lớp MN Hoa Trà Mi"
                         },
                         {
                           "maTruong": "7976401033",
                           "tenTruong": "Lớp MN Linh Lam"
                         },
                         {
                           "maTruong": "7976401034",
                           "tenTruong": "Lớp Mầm Non Bé Hạnh Phúc"
                         },
                         {
                           "maTruong": "7976401035",
                           "tenTruong": "Lớp MN Học Viện Xanh"
                         },
                         {
                           "maTruong": "7976401036",
                           "tenTruong": "Lớp MN Thế Giới Ngày Mai"
                         },
                         {
                           "maTruong": "7976401037",
                           "tenTruong": "Trường MN Ngôi Nhà Bé con"
                         },
                         {
                           "maTruong": "7976401038",
                           "tenTruong": "Lớp MN Hồng Hạnh"
                         },
                         {
                           "maTruong": "7976401039",
                           "tenTruong": "Lớp MN Phần Lan"
                         },
                         {
                           "maTruong": "7976401040",
                           "tenTruong": "Lớp MN Khu Vườn Hạnh Phúc"
                         },
                         {
                           "maTruong": "7976401041",
                           "tenTruong": "Lớp MN Nụ Cười"
                         },
                         {
                           "maTruong": "7976401042",
                           "tenTruong": "Lớp MG Ước Mơ"
                         },
                         {
                           "maTruong": "7976401043",
                           "tenTruong": "Lớp MN Bé Thỏ"
                         }
                       ]
                     },
                     {
                       "maPhong": "766",
                       "tenPhong": "Quận Tân Bình",
                       "truongData": [
                         {
                           "maTruong": "79766TB1",
                           "tenTruong": "Dân lập Thanh Bình"
                         },
                         {
                           "maTruong": "79766408",
                           "tenTruong": "TH Bạch Đằng"
                         },
                         {
                           "maTruong": "79766411",
                           "tenTruong": "TH Bành Văn Trân"
                         },
                         {
                           "maTruong": "79766420",
                           "tenTruong": "TH Cách Mạng Tháng Tám"
                         },
                         {
                           "maTruong": "79766409",
                           "tenTruong": "TH Chi Lăng"
                         },
                         {
                           "maTruong": "79766410",
                           "tenTruong": "TH Đống Đa"
                         },
                         {
                           "maTruong": "79766406",
                           "tenTruong": "TH Hoàng Văn Thụ"
                         },
                         {
                           "maTruong": "79766419",
                           "tenTruong": "TH Lạc Long Quân"
                         },
                         {
                           "maTruong": "79766417",
                           "tenTruong": "TH LÊ THỊ HỒNG GẤM"
                         },
                         {
                           "maTruong": "79766401",
                           "tenTruong": "TH Lê Văn Sĩ"
                         },
                         {
                           "maTruong": "79766414",
                           "tenTruong": "TH Lý Thường Kiệt"
                         },
                         {
                           "maTruong": "79766415",
                           "tenTruong": "TH Ngọc Hồi"
                         },
                         {
                           "maTruong": "79766421",
                           "tenTruong": "TH Nguyễn Khuyến"
                         },
                         {
                           "maTruong": "79766403",
                           "tenTruong": "TH Nguyễn Thanh Tuyền"
                         },
                         {
                           "maTruong": "79766432",
                           "tenTruong": "TH Nguyễn Văn Kịp"
                         },
                         {
                           "maTruong": "79766418",
                           "tenTruong": "TH Nguyễn Văn Trỗi"
                         },
                         {
                           "maTruong": "79766407",
                           "tenTruong": "TH Phạm Văn Hai"
                         },
                         {
                           "maTruong": "79766416",
                           "tenTruong": "TH Phú Thọ Hoà"
                         },
                         {
                           "maTruong": "79766425",
                           "tenTruong": "TH Sơn Cang"
                         },
                         {
                           "maTruong": "79766402",
                           "tenTruong": "TH Tân Sơn Nhất"
                         },
                         {
                           "maTruong": "79766426",
                           "tenTruong": "TH Tân Trụ"
                         },
                         {
                           "maTruong": "79766435",
                           "tenTruong": "TH Thân Nhân Trung"
                         },
                         {
                           "maTruong": "79766423",
                           "tenTruong": "TH Trần Quốc Toản"
                         },
                         {
                           "maTruong": "79766413",
                           "tenTruong": "TH Trần Văn Ơn"
                         },
                         {
                           "maTruong": "79766434",
                           "tenTruong": "TH TT Lương Thế Vinh"
                         },
                         {
                           "maTruong": "79766424",
                           "tenTruong": "TH Yên Thế"
                         },
                         {
                           "maTruong": "79766405",
                           "tenTruong": "Tiểu học Bình Giã"
                         },
                         {
                           "maTruong": "79766422",
                           "tenTruong": "TiH Trần Quốc Tuấn"
                         },
                         {
                           "maTruong": "79766508",
                           "tenTruong": "THCS Âu Lạc"
                         },
                         {
                           "maTruong": "79766507",
                           "tenTruong": "THCS Hoàng Hoa Thám"
                         },
                         {
                           "maTruong": "79766505",
                           "tenTruong": "THCS Lý Thường Kiệt"
                         },
                         {
                           "maTruong": "79766511",
                           "tenTruong": "THCS Ngô Quyền"
                         },
                         {
                           "maTruong": "79766501",
                           "tenTruong": "THCS Ngô Sĩ Liên"
                         },
                         {
                           "maTruong": "79766503",
                           "tenTruong": "THCS Nguyễn Gia Thiều"
                         },
                         {
                           "maTruong": "79766510",
                           "tenTruong": "THCS Phạm Ngọc Thạch"
                         },
                         {
                           "maTruong": "79766504",
                           "tenTruong": "THCS Quang Trung"
                         },
                         {
                           "maTruong": "79766509",
                           "tenTruong": "THCS Tân Bình"
                         },
                         {
                           "maTruong": "79766502",
                           "tenTruong": "THCS Trần Văn Đang"
                         },
                         {
                           "maTruong": "79766513",
                           "tenTruong": "THCS Trần Văn Quang"
                         },
                         {
                           "maTruong": "79766512",
                           "tenTruong": "THCS Trường Chinh"
                         },
                         {
                           "maTruong": "79766506",
                           "tenTruong": "THCS Võ Văn Tần"
                         },
                         {
                           "maTruong": "79000829",
                           "tenTruong": "THCS - THPT  Việt Thanh"
                         },
                         {
                           "maTruong": "79000859",
                           "tenTruong": "THCS - THPT Bác Ái"
                         },
                         {
                           "maTruong": "79000835",
                           "tenTruong": "THCS - THPT Nguyễn Khuyến"
                         },
                         {
                           "maTruong": "79000831",
                           "tenTruong": "THCS - THPT Thái Bình"
                         },
                         {
                           "maTruong": "79000C07",
                           "tenTruong": "TH - THCS - THPT Thái Bình Dương"
                         },
                         {
                           "maTruong": "79000832",
                           "tenTruong": "TH, THCS  - THPT Thanh Bình"
                         },
                         {
                           "maTruong": "79766201",
                           "tenTruong": "Mẫu giáo Dân lập Bông Hồng"
                         },
                         {
                           "maTruong": "79766202",
                           "tenTruong": "Mẫu Giáo Tư Thục Hương Hồng"
                         },
                         {
                           "maTruong": "79766WC1",
                           "tenTruong": "Gấu Trúc"
                         },
                         {
                           "maTruong": "79766345",
                           "tenTruong": "Mầm Non  Bé Ngôi Sao"
                         },
                         {
                           "maTruong": "79766303",
                           "tenTruong": "Mầm Non 2"
                         },
                         {
                           "maTruong": "79766313",
                           "tenTruong": "MẦM NON 8"
                         },
                         {
                           "maTruong": "79766WA2",
                           "tenTruong": "Mầm non Alpha (T.A.K)"
                         },
                         {
                           "maTruong": "797663D7",
                           "tenTruong": "Mầm non Hoàng Tử Bé"
                         },
                         {
                           "maTruong": "797663E0",
                           "tenTruong": "Mầm Non Tesla"
                         },
                         {
                           "maTruong": "79766W08",
                           "tenTruong": "Mầm non Thần Đồng Việt"
                         },
                         {
                           "maTruong": "797663D8",
                           "tenTruong": "Mầm non Thành Phố Tuổi Thơ"
                         },
                         {
                           "maTruong": "79766WF3",
                           "tenTruong": "Mầm Non Tư Thục Hoa Hồng"
                         },
                         {
                           "maTruong": "797663E3",
                           "tenTruong": "Mầm non Vạn Hạnh"
                         },
                         {
                           "maTruong": "797663D9",
                           "tenTruong": "Mầm Non Vũ Trụ Xanh"
                         },
                         {
                           "maTruong": "79766W71",
                           "tenTruong": "MN  Hiền Minh"
                         },
                         {
                           "maTruong": "79766301",
                           "tenTruong": "MN 1"
                         },
                         {
                           "maTruong": "79766316",
                           "tenTruong": "MN 10"
                         },
                         {
                           "maTruong": "79766317",
                           "tenTruong": "MN 10A"
                         },
                         {
                           "maTruong": "79766318",
                           "tenTruong": "MN 11"
                         },
                         {
                           "maTruong": "79766322",
                           "tenTruong": "MN 12"
                         },
                         {
                           "maTruong": "79766319",
                           "tenTruong": "MN 13"
                         },
                         {
                           "maTruong": "79766320",
                           "tenTruong": "MN 14"
                         },
                         {
                           "maTruong": "79766329",
                           "tenTruong": "MN 15"
                         },
                         {
                           "maTruong": "79766302",
                           "tenTruong": "MN 1A"
                         },
                         {
                           "maTruong": "79766305",
                           "tenTruong": "MN 3"
                         },
                         {
                           "maTruong": "79766307",
                           "tenTruong": "MN 4"
                         },
                         {
                           "maTruong": "79766309",
                           "tenTruong": "MN 5"
                         },
                         {
                           "maTruong": "79766310",
                           "tenTruong": "MN 6"
                         },
                         {
                           "maTruong": "79766311",
                           "tenTruong": "MN 7"
                         },
                         {
                           "maTruong": "79766321",
                           "tenTruong": "MN 9"
                         },
                         {
                           "maTruong": "79766WE5",
                           "tenTruong": "MN Á Âu"
                         },
                         {
                           "maTruong": "79766352",
                           "tenTruong": "MN An Hòa"
                         },
                         {
                           "maTruong": "79766342",
                           "tenTruong": "MN Ánh Sáng"
                         },
                         {
                           "maTruong": "79766327",
                           "tenTruong": "MN Anh Thư"
                         },
                         {
                           "maTruong": "79766333",
                           "tenTruong": "MN BamBi-K300"
                         },
                         {
                           "maTruong": "79766349",
                           "tenTruong": "MN Bảo Ngọc"
                         },
                         {
                           "maTruong": "79766324",
                           "tenTruong": "MN Bàu Cát"
                         },
                         {
                           "maTruong": "79766353",
                           "tenTruong": "MN Bé Hạnh Phúc"
                         },
                         {
                           "maTruong": "797663D6",
                           "tenTruong": "MN Bé Tài Năng"
                         },
                         {
                           "maTruong": "79766W21",
                           "tenTruong": "MN Bích Trúc"
                         },
                         {
                           "maTruong": "79766W12",
                           "tenTruong": "MN Chuột Túi Thông Minh"
                         },
                         {
                           "maTruong": "79766W68",
                           "tenTruong": "MN Gấu Bông"
                         },
                         {
                           "maTruong": "79766347",
                           "tenTruong": "MN Họa Mi"
                         },
                         {
                           "maTruong": "79766372",
                           "tenTruong": "MN Học viện Sài gòn"
                         },
                         {
                           "maTruong": "79766341",
                           "tenTruong": "MN Hươu Vàng"
                         },
                         {
                           "maTruong": "79766W02",
                           "tenTruong": "MN I Vy"
                         },
                         {
                           "maTruong": "79766304",
                           "tenTruong": "MN Kim Đồng"
                         },
                         {
                           "maTruong": "79766350",
                           "tenTruong": "MN Lá Xanh"
                         },
                         {
                           "maTruong": "79766354",
                           "tenTruong": "MN MiMon"
                         },
                         {
                           "maTruong": "79766332",
                           "tenTruong": "MN Minh Quang"
                         },
                         {
                           "maTruong": "79766337",
                           "tenTruong": "MN Nam Mỹ"
                         },
                         {
                           "maTruong": "79766343",
                           "tenTruong": "MN Ngôi Sao"
                         },
                         {
                           "maTruong": "79766348",
                           "tenTruong": "MN Phú Hòa"
                         },
                         {
                           "maTruong": "79766323",
                           "tenTruong": "MN Quận"
                         },
                         {
                           "maTruong": "79766331",
                           "tenTruong": "MN Quốc tế Sài gòn"
                         },
                         {
                           "maTruong": "79766306",
                           "tenTruong": "MN Sao Sáng"
                         },
                         {
                           "maTruong": "79766308",
                           "tenTruong": "MN Tân Sơn Nhất"
                         },
                         {
                           "maTruong": "79766336",
                           "tenTruong": "MN Thần Đồng"
                         },
                         {
                           "maTruong": "79766351",
                           "tenTruong": "MN Thanh Mai"
                         },
                         {
                           "maTruong": "79766346",
                           "tenTruong": "MN Thanh Vy"
                         },
                         {
                           "maTruong": "79766WD5",
                           "tenTruong": "MN Thế Giới Thiên Thần"
                         },
                         {
                           "maTruong": "79766338",
                           "tenTruong": "MN Trẻ Thơ"
                         },
                         {
                           "maTruong": "79766330",
                           "tenTruong": "MN Trúc Huy"
                         },
                         {
                           "maTruong": "79766335",
                           "tenTruong": "MN TT Thiên Thần Nhỏ"
                         },
                         {
                           "maTruong": "79766314",
                           "tenTruong": "MN Tuổi Hồng"
                         },
                         {
                           "maTruong": "79766W19",
                           "tenTruong": "MN Tuổi Thơ"
                         },
                         {
                           "maTruong": "79766312",
                           "tenTruong": "MN Tuổi Xanh"
                         },
                         {
                           "maTruong": "79766315",
                           "tenTruong": "MN Vườn Hồng"
                         },
                         {
                           "maTruong": "79766W07",
                           "tenTruong": "Sơn Ca (P2)"
                         },
                         {
                           "maTruong": "79766Wh1",
                           "tenTruong": "Trường Mầm non ABI"
                         },
                         {
                           "maTruong": "79766WE4",
                           "tenTruong": "Trường mầm non Ánh Sao"
                         },
                         {
                           "maTruong": "797663E2",
                           "tenTruong": "TRƯỜNG MẦM NON HỌC VIỆN THIÊN THẦN NHỎ"
                         },
                         {
                           "maTruong": "797663E1",
                           "tenTruong": "Trường Mầm Non Ngôi Sao Xinh"
                         },
                         {
                           "maTruong": "79766W52",
                           "tenTruong": "Trường Mầm Non Việt Anh"
                         },
                         {
                           "maTruong": "79766WD8",
                           "tenTruong": "Việt Mỹ"
                         },
                         {
                           "maTruong": "79766WG4",
                           "tenTruong": "Ánh Minh"
                         },
                         {
                           "maTruong": "79766L06",
                           "tenTruong": "Gấu Bu"
                         },
                         {
                           "maTruong": "79766WG2",
                           "tenTruong": "Hoa Lan 2"
                         },
                         {
                           "maTruong": "79766W09",
                           "tenTruong": "LỚP  MGĐL  KIRIN"
                         },
                         {
                           "maTruong": "79766WD2",
                           "tenTruong": "Lớp Anh Đào"
                         },
                         {
                           "maTruong": "79766WB8",
                           "tenTruong": "Lớp Ánh Dương"
                         },
                         {
                           "maTruong": "79766W65",
                           "tenTruong": "Lớp Anh Tú 1"
                         },
                         {
                           "maTruong": "79766W66",
                           "tenTruong": "Lớp Anh Tú 2"
                         },
                         {
                           "maTruong": "79766W06",
                           "tenTruong": "Lớp BaBiLắc"
                         },
                         {
                           "maTruong": "79766XB8",
                           "tenTruong": "Lớp Ban Mai"
                         },
                         {
                           "maTruong": "79766WE3",
                           "tenTruong": "Lớp Bảo Hương"
                         },
                         {
                           "maTruong": "79766WF7",
                           "tenTruong": "Lớp Bé Ngoan"
                         },
                         {
                           "maTruong": "79766W28",
                           "tenTruong": "Lớp Bé Thơ"
                         },
                         {
                           "maTruong": "79766WD9",
                           "tenTruong": "Lớp Bé Xinh"
                         },
                         {
                           "maTruong": "79766LC0",
                           "tenTruong": "Lớp Bé Xinh 2"
                         },
                         {
                           "maTruong": "79766WC8",
                           "tenTruong": "Lớp Bí Ngô"
                         },
                         {
                           "maTruong": "79766W56",
                           "tenTruong": "Lớp Bình Minh"
                         },
                         {
                           "maTruong": "79766WB3",
                           "tenTruong": "Lớp Búp Măng Non"
                         },
                         {
                           "maTruong": "79766WF0",
                           "tenTruong": "Lớp Búp Sen Hồng"
                         },
                         {
                           "maTruong": "79766WC6",
                           "tenTruong": "Lớp Cát Tường"
                         },
                         {
                           "maTruong": "79766W11",
                           "tenTruong": "Lớp Chong Chóng Nhỏ"
                         },
                         {
                           "maTruong": "79766W01",
                           "tenTruong": "Lớp Đoan Trang (P1)"
                         },
                         {
                           "maTruong": "79766W13",
                           "tenTruong": "Lớp Fami"
                         },
                         {
                           "maTruong": "79766LC9",
                           "tenTruong": "Lớp Fami 2"
                         },
                         {
                           "maTruong": "79766WF6",
                           "tenTruong": "Lớp Gấu Trắng"
                         },
                         {
                           "maTruong": "79766WA7",
                           "tenTruong": "Lớp Hạnh Phúc"
                         },
                         {
                           "maTruong": "79766LC4",
                           "tenTruong": "Lớp Hạnh Phúc 2"
                         },
                         {
                           "maTruong": "79766L08",
                           "tenTruong": "Lớp Hạt Đậu Nhỏ"
                         },
                         {
                           "maTruong": "79766W43",
                           "tenTruong": "Lớp Hoa Cúc"
                         },
                         {
                           "maTruong": "79766W75",
                           "tenTruong": "Lớp Hoa Hồng"
                         },
                         {
                           "maTruong": "79766W92",
                           "tenTruong": "Lớp Hoa Hồng 2"
                         },
                         {
                           "maTruong": "79766WF8",
                           "tenTruong": "Lớp Hoa Hồng Nhỏ"
                         },
                         {
                           "maTruong": "79766WB0",
                           "tenTruong": "Lớp Hoa Hướng Dương"
                         },
                         {
                           "maTruong": "79766W39",
                           "tenTruong": "Lớp Hoa Lan"
                         },
                         {
                           "maTruong": "79766W20",
                           "tenTruong": "Lớp Hoa Lan"
                         },
                         {
                           "maTruong": "79766WA1",
                           "tenTruong": "Lớp Hoa Mặt Trời"
                         },
                         {
                           "maTruong": "79766LC1",
                           "tenTruong": "Lớp Hoa Mặt Trời 2"
                         },
                         {
                           "maTruong": "79766W47",
                           "tenTruong": "Lớp Hoa Phượng Đỏ"
                         },
                         {
                           "maTruong": "79766W80",
                           "tenTruong": "Lớp Hoàng Anh"
                         },
                         {
                           "maTruong": "79766LC5",
                           "tenTruong": "Lớp Hoàng Hoa"
                         },
                         {
                           "maTruong": "79766WB5",
                           "tenTruong": "Lớp Hoàng Oanh"
                         },
                         {
                           "maTruong": "79766W87",
                           "tenTruong": "Lớp Hoàng Yến"
                         },
                         {
                           "maTruong": "79766W69",
                           "tenTruong": "Lớp Lan Thảo"
                         },
                         {
                           "maTruong": "79766W85",
                           "tenTruong": "Lớp Lan Vi"
                         },
                         {
                           "maTruong": "79766W73",
                           "tenTruong": "Lớp Lê Hà"
                         },
                         {
                           "maTruong": "79766W79",
                           "tenTruong": "Lớp Liên Anh"
                         },
                         {
                           "maTruong": "79766W53",
                           "tenTruong": "Lớp Mai Vàng"
                         },
                         {
                           "maTruong": "79766LK3",
                           "tenTruong": "Lớp Mầm Xanh"
                         },
                         {
                           "maTruong": "79766L15",
                           "tenTruong": "Lớp Mặt trời mới"
                         },
                         {
                           "maTruong": "79766LK4",
                           "tenTruong": "Lớp Mẫu Giáo Độc Lập  Baby"
                         },
                         {
                           "maTruong": "79766W31",
                           "tenTruong": "Lớp Mẫu Giáo Độc Lập An Khương"
                         },
                         {
                           "maTruong": "79766W55",
                           "tenTruong": "Lớp Mẩu Giáo Độc Lập Bé Yêu"
                         },
                         {
                           "maTruong": "79766XA9",
                           "tenTruong": "Lớp mẫu giáo độc lập Cầu Vồng"
                         },
                         {
                           "maTruong": "79766L13",
                           "tenTruong": "Lớp Mẫu Giáo Độc Lập Chồi Non"
                         },
                         {
                           "maTruong": "79766W89",
                           "tenTruong": "LỚP MẪU GIÁO ĐỘC LẬP HOA SEN"
                         },
                         {
                           "maTruong": "79766W60",
                           "tenTruong": "Lớp mẩu giáo độc lập Hồng Hà"
                         },
                         {
                           "maTruong": "79766W64",
                           "tenTruong": "Lớp Mẫu Giáo Độc Lập Hồng Phúc"
                         },
                         {
                           "maTruong": "79766WG5",
                           "tenTruong": "LỚP MẪU GIÁO ĐỘC LẬP MAI- KA"
                         },
                         {
                           "maTruong": "79766200",
                           "tenTruong": "Lớp Mẫu Giáo Độc Lập Sóc Nâu"
                         },
                         {
                           "maTruong": "79766WE2",
                           "tenTruong": "Lớp mẫu giáo độc lập Thỏ Ngọc"
                         },
                         {
                           "maTruong": "79766W36",
                           "tenTruong": "Lớp Mẫu Giáo Lê Vân"
                         },
                         {
                           "maTruong": "79766MK1",
                           "tenTruong": "Lớp Mẫu Giáo Mai Khôi"
                         },
                         {
                           "maTruong": "79766W05",
                           "tenTruong": "Lớp MG Á Châu"
                         },
                         {
                           "maTruong": "79766W10",
                           "tenTruong": "Lớp MG Độc Lập Anh Duy"
                         },
                         {
                           "maTruong": "79766TS1",
                           "tenTruong": "Lớp MG Thảo Sương"
                         },
                         {
                           "maTruong": "79766W76",
                           "tenTruong": "Lớp MGĐL Hồng Hạnh"
                         },
                         {
                           "maTruong": "79766XA7",
                           "tenTruong": "Lớp MGĐL RẠNG ĐÔNG"
                         },
                         {
                           "maTruong": "79766WD7",
                           "tenTruong": "Lớp Minh Anh"
                         },
                         {
                           "maTruong": "79766LC8",
                           "tenTruong": "Lớp Minh Vy 2"
                         },
                         {
                           "maTruong": "79766W77",
                           "tenTruong": "Lớp Misa"
                         },
                         {
                           "maTruong": "79766LK2",
                           "tenTruong": "Lớp Misa 2"
                         },
                         {
                           "maTruong": "79766W34",
                           "tenTruong": "Lớp Mỹ Duyên"
                         },
                         {
                           "maTruong": "79766W35",
                           "tenTruong": "Lớp Mỹ Trang"
                         },
                         {
                           "maTruong": "79766W17",
                           "tenTruong": "Lớp Nam Hòa"
                         },
                         {
                           "maTruong": "79766203",
                           "tenTruong": "Lớp Nét Hoa"
                         },
                         {
                           "maTruong": "79766LC3",
                           "tenTruong": "Lớp Ngọc Thủy"
                         },
                         {
                           "maTruong": "79766L14",
                           "tenTruong": "Lớp Ngôi Nhà Trẻ Thơ"
                         },
                         {
                           "maTruong": "79766WC7",
                           "tenTruong": "Lớp Ngôi Sao Nhỏ"
                         },
                         {
                           "maTruong": "79766L05",
                           "tenTruong": "Lớp Ngôi sao xanh"
                         },
                         {
                           "maTruong": "79766L12",
                           "tenTruong": "Lớp Ngôi sao xanh 2"
                         },
                         {
                           "maTruong": "79766WF2",
                           "tenTruong": "Lớp Nhật Vy"
                         },
                         {
                           "maTruong": "79766WA0",
                           "tenTruong": "Lớp Như Ý"
                         },
                         {
                           "maTruong": "79766WB6",
                           "tenTruong": "Lớp Những Chú Ong"
                         },
                         {
                           "maTruong": "79766W03",
                           "tenTruong": "Lớp Nụ Hồng"
                         },
                         {
                           "maTruong": "79766W74",
                           "tenTruong": "Lớp Phương Anh"
                         },
                         {
                           "maTruong": "79766L11",
                           "tenTruong": "Lớp Phương Nga"
                         },
                         {
                           "maTruong": "79766WD6",
                           "tenTruong": "Lớp Quỳnh Nga"
                         },
                         {
                           "maTruong": "79766W63",
                           "tenTruong": "Lớp Quỳnh Trang"
                         },
                         {
                           "maTruong": "79766LB9",
                           "tenTruong": "Lớp Sa Kê 2"
                         },
                         {
                           "maTruong": "79766WA4",
                           "tenTruong": "Lớp Sakê"
                         },
                         {
                           "maTruong": "79766W26",
                           "tenTruong": "Lớp Sao Mai"
                         },
                         {
                           "maTruong": "79766WB9",
                           "tenTruong": "Lớp Sao Mai Vàng"
                         },
                         {
                           "maTruong": "79766LK5",
                           "tenTruong": "Lớp Sao Việt"
                         },
                         {
                           "maTruong": "79766W42",
                           "tenTruong": "Lớp Sơn Ca (P11)"
                         },
                         {
                           "maTruong": "79766W58",
                           "tenTruong": "Lớp Sơn Ca (P13)"
                         },
                         {
                           "maTruong": "79766W82",
                           "tenTruong": "Lớp Sơn Ca (P15)"
                         },
                         {
                           "maTruong": "79766WD4",
                           "tenTruong": "Lớp Tâm Đức"
                         },
                         {
                           "maTruong": "79766W67",
                           "tenTruong": "Lớp Tân Sơn Nhì"
                         },
                         {
                           "maTruong": "79766W72",
                           "tenTruong": "Lớp Táo Xanh"
                         },
                         {
                           "maTruong": "79766L10",
                           "tenTruong": "Lớp Thiên Thần"
                         },
                         {
                           "maTruong": "79766WE0",
                           "tenTruong": "Lớp Thiên Thần Việt"
                         },
                         {
                           "maTruong": "79766W98",
                           "tenTruong": "Lớp Thiên Thần Xanh"
                         },
                         {
                           "maTruong": "79766100",
                           "tenTruong": "Lớp Thỏ Ngọc"
                         },
                         {
                           "maTruong": "79766W88",
                           "tenTruong": "Lớp Tí Hon"
                         },
                         {
                           "maTruong": "79766WB7",
                           "tenTruong": "Lớp Trương Công Định"
                         },
                         {
                           "maTruong": "79766W23",
                           "tenTruong": "Lớp Tuổi Hoa"
                         },
                         {
                           "maTruong": "79766W50",
                           "tenTruong": "Lớp Tuổi Thần Tiên"
                         },
                         {
                           "maTruong": "79766W59",
                           "tenTruong": "Lớp Tuổi Tiên"
                         },
                         {
                           "maTruong": "79766WB2",
                           "tenTruong": "Lớp Tuổi Tiên 2"
                         },
                         {
                           "maTruong": "79766W30",
                           "tenTruong": "Lớp Tuyết Mai"
                         },
                         {
                           "maTruong": "79766W46",
                           "tenTruong": "Lớp Vàng Anh"
                         },
                         {
                           "maTruong": "79766W16",
                           "tenTruong": "Lớp Vành Khuyên (P5)"
                         },
                         {
                           "maTruong": "79766XB1",
                           "tenTruong": "Lớp Việt Nhật"
                         },
                         {
                           "maTruong": "79766L04",
                           "tenTruong": "Lớp Viết Tuấn"
                         },
                         {
                           "maTruong": "79766WD0",
                           "tenTruong": "Lớp Vũ Anh"
                         },
                         {
                           "maTruong": "79766L09",
                           "tenTruong": "Lớp Yến Chi"
                         },
                         {
                           "maTruong": "79766WG6",
                           "tenTruong": "Mai Duyên"
                         },
                         {
                           "maTruong": "79766W61",
                           "tenTruong": "Mẫu Giáo Hương Quỳnh"
                         },
                         {
                           "maTruong": "79766L07",
                           "tenTruong": "Nét Hoa"
                         },
                         {
                           "maTruong": "79766LK7",
                           "tenTruong": "Nhóm Hạnh Phúc 3"
                         },
                         {
                           "maTruong": "79766102",
                           "tenTruong": "Nhóm Hoa Mai"
                         },
                         {
                           "maTruong": "79766101",
                           "tenTruong": "Nhóm Hương Quỳnh"
                         },
                         {
                           "maTruong": "79766W95",
                           "tenTruong": "Nhóm Lớp NT Ngọc Ánh"
                         },
                         {
                           "maTruong": "79766XB7",
                           "tenTruong": "Nhóm Nhà trẻ độc lập Hồng Hạnh 2"
                         },
                         {
                           "maTruong": "79766W40",
                           "tenTruong": "Nhóm Trẻ Chí Tâm"
                         },
                         {
                           "maTruong": "79766LK6",
                           "tenTruong": "Nhóm trẻ Hoàng Mai"
                         },
                         {
                           "maTruong": "79766W32",
                           "tenTruong": "Nhóm trẻ Hồng Ân"
                         },
                         {
                           "maTruong": "79766WC3",
                           "tenTruong": "Nhom Trẻ Kirin 2"
                         },
                         {
                           "maTruong": "79766WF4",
                           "tenTruong": "Nhóm Trẻ Mai Anh"
                         },
                         {
                           "maTruong": "79766XB5",
                           "tenTruong": "Trường mầm non Mây Trắng"
                         },
                         {
                           "maTruong": "79766W38",
                           "tenTruong": "Nhóm trẻ NGÔI SAO MỚI"
                         },
                         {
                           "maTruong": "79766WC5",
                           "tenTruong": "Nhom tre Tân Phước"
                         },
                         {
                           "maTruong": "79766W90",
                           "tenTruong": "Nhom tre Thỏ Trắng"
                         },
                         {
                           "maTruong": "79766XB6",
                           "tenTruong": "Nhóm Trẻ Tí Hon 2"
                         },
                         {
                           "maTruong": "79766XB2",
                           "tenTruong": "nhóm trẻ Vàng Khuyên (P13)"
                         },
                         {
                           "maTruong": "79766L02",
                           "tenTruong": "Phương Nam"
                         },
                         {
                           "maTruong": "79766LC7",
                           "tenTruong": "Quỳnh Hương"
                         },
                         {
                           "maTruong": "79766Wh2",
                           "tenTruong": "Sương Mai"
                         },
                         {
                           "maTruong": "79766WG7",
                           "tenTruong": "Trí Tâm"
                         },
                         {
                           "maTruong": "79766W44",
                           "tenTruong": "Vườn Yêu Thương"
                         },
                         {
                           "maTruong": "79766LC6",
                           "tenTruong": "Xuân Thảo"
                         },
                         {
                           "maTruong": "797663E4",
                           "tenTruong": "Mầm non Họa Mi"
                         },
                         {
                           "maTruong": "79766L18",
                           "tenTruong": "Lớp Ngọc Anh"
                         },
                         {
                           "maTruong": "79766L16",
                           "tenTruong": "NT Xuân Nhi"
                         },
                         {
                           "maTruong": "79766L21",
                           "tenTruong": "Lớp \tKhu vườn cổ tích"
                         },
                         {
                           "maTruong": "79766L19",
                           "tenTruong": "Nhóm Thiên Ân"
                         },
                         {
                           "maTruong": "79766994",
                           "tenTruong": "LMG Song Lê"
                         },
                         {
                           "maTruong": "79766991",
                           "tenTruong": "LMG Ngôi Nhà Xanh"
                         },
                         {
                           "maTruong": "79766992",
                           "tenTruong": "LMG Sao Nhí"
                         },
                         {
                           "maTruong": "79766993",
                           "tenTruong": "LMG Thiên Ân"
                         },
                         {
                           "maTruong": "79766L17",
                           "tenTruong": "LMG Ngôi nhà của bé"
                         },
                         {
                           "maTruong": "79766L20",
                           "tenTruong": "LMG Nhân Ái"
                         },
                         {
                           "maTruong": "79766L30",
                           "tenTruong": "LMG Chú Kiến Nhỏ"
                         },
                         {
                           "maTruong": "79766L31",
                           "tenTruong": "LMG Thiên Nhiên"
                         },
                         {
                           "maTruong": "79766L50",
                           "tenTruong": "Lớp mẫu giáo Vườn Trẻ Thơ"
                         },
                         {
                           "maTruong": "79766L36",
                           "tenTruong": "LMG Mặt Trời Bé Con"
                         },
                         {
                           "maTruong": "79766L37",
                           "tenTruong": "MN Tổ Ong Vàng"
                         },
                         {
                           "maTruong": "79766w97",
                           "tenTruong": "LMG Cây Con"
                         },
                         {
                           "maTruong": "79766w96",
                           "tenTruong": "LMG Mẹ Yêu"
                         },
                         {
                           "maTruong": "79766w04",
                           "tenTruong": "LMG Nắng Mai"
                         },
                         {
                           "maTruong": "79766W22",
                           "tenTruong": "LMG Bánh Táo"
                         },
                         {
                           "maTruong": "79766WB1",
                           "tenTruong": "NT Nét Hoa"
                         },
                         {
                           "maTruong": "79766w33",
                           "tenTruong": "LMG Hoàng Mai 2"
                         },
                         {
                           "maTruong": "79766w37",
                           "tenTruong": "LMG Khu Vườn Cổ Tích"
                         },
                         {
                           "maTruong": "79766w41",
                           "tenTruong": "LMG Đức Tài"
                         },
                         {
                           "maTruong": "79766wD3",
                           "tenTruong": "NT Tâm Đức 2"
                         },
                         {
                           "maTruong": "79766we6",
                           "tenTruong": "Mầm non Mỹ Úc"
                         },
                         {
                           "maTruong": "79766wd10",
                           "tenTruong": "NT Hoa Yêu Thương"
                         },
                         {
                           "maTruong": "79766L35",
                           "tenTruong": "NT Tổ Ong Vàng"
                         },
                         {
                           "maTruong": "79766L51",
                           "tenTruong": "LMG MIỀN YÊU THƯƠNG"
                         },
                         {
                           "maTruong": "79766L52",
                           "tenTruong": "LMG NGÔI NHÀ SAO SÁNG"
                         },
                         {
                           "maTruong": "79766256",
                           "tenTruong": "MN Tư thục Trẻ Thơ"
                         },
                         {
                           "maTruong": "79766254",
                           "tenTruong": "Nhóm trẻ Thiên Nam"
                         },
                         {
                           "maTruong": "79766162",
                           "tenTruong": "LMG Bé Vàng"
                         },
                         {
                           "maTruong": "79766514",
                           "tenTruong": "TH, THCS Tesla"
                         },
                         {
                           "maTruong": "7976601001",
                           "tenTruong": "LMG Cá Mập Vàng"
                         },
                         {
                           "maTruong": "7976601002",
                           "tenTruong": "LMG Chú Chim Hạnh Phúc"
                         },
                         {
                           "maTruong": "7976601003",
                           "tenTruong": "LMG Chắp Cánh Tương Lai"
                         },
                         {
                           "maTruong": "7976601004",
                           "tenTruong": "LMG Ngôi Nhà Mặt Trời"
                         },
                         {
                           "maTruong": "7976601005",
                           "tenTruong": "LMG Sông Thương"
                         },
                         {
                           "maTruong": "7976601006",
                           "tenTruong": "LMG Chú Ong Xanh"
                         },
                         {
                           "maTruong": "7976601007",
                           "tenTruong": "LMG Hừng Đông"
                         },
                         {
                           "maTruong": "79766125",
                           "tenTruong": "LMG Sài Gòn"
                         },
                         {
                           "maTruong": "79766S10",
                           "tenTruong": "TH Phan Huy Ích"
                         },
                         {
                           "maTruong": "7976601011",
                           "tenTruong": "LMG Hồng Hà"
                         },
                         {
                           "maTruong": "7976601012",
                           "tenTruong": "LMG Trẻ Sáng Tạo"
                         },
                         {
                           "maTruong": "7976601013",
                           "tenTruong": "NT Trẻ Sáng Tạo 1"
                         },
                         {
                           "maTruong": "7976601014",
                           "tenTruong": "LMG Thần Đồng Việt Mỹ"
                         },
                         {
                           "maTruong": "7976601008",
                           "tenTruong": "LMG Hoa Tường Vy"
                         },
                         {
                           "maTruong": "7976601009",
                           "tenTruong": "LMG Ngôi Nhà Hoa Hồng"
                         },
                         {
                           "maTruong": "7976601010",
                           "tenTruong": "LMG Ươm Mầm Trí Tuệ"
                         },
                         {
                           "maTruong": "7976601018",
                           "tenTruong": "LMG Ngôi Nhà Nhỏ - P2"
                         },
                         {
                           "maTruong": "7976601021",
                           "tenTruong": "LMG Ngôi nhà Trẻ Thơ (P2)"
                         },
                         {
                           "maTruong": "7976601019",
                           "tenTruong": "LMG Hải Dương Xanh"
                         },
                         {
                           "maTruong": "7976601020",
                           "tenTruong": "NT Hoa Sữa"
                         },
                         {
                           "maTruong": "7976601015",
                           "tenTruong": "LMG Trẻ Sáng Tạo 2 - P04"
                         },
                         {
                           "maTruong": "7976601016",
                           "tenTruong": "MN Hươu Vàng"
                         },
                         {
                           "maTruong": "7976601022",
                           "tenTruong": "LMG Ngôi Sao Sáng"
                         },
                         {
                           "maTruong": "7976601023",
                           "tenTruong": "LMG Gia Hưng"
                         },
                         {
                           "maTruong": "7976601024",
                           "tenTruong": "LMG Trí Tâm"
                         },
                         {
                           "maTruong": "7976601025",
                           "tenTruong": "LMG Thỏ Con"
                         },
                         {
                           "maTruong": "7976601026",
                           "tenTruong": "NT Hoàng Hoa"
                         },
                         {
                           "maTruong": "7976601027",
                           "tenTruong": "LMG Ngôi Nhà Sáng Tạo"
                         },
                         {
                           "maTruong": "7976601028",
                           "tenTruong": "LMG Nhà Thiên Thần"
                         },
                         {
                           "maTruong": "7976601029",
                           "tenTruong": "LMG Việt My"
                         },
                         {
                           "maTruong": "7976601030",
                           "tenTruong": "LMG Việt Ánh"
                         },
                         {
                           "maTruong": "79766515",
                           "tenTruong": "THCS Mạc Đĩnh Chi"
                         },
                         {
                           "maTruong": "7976601042",
                           "tenTruong": "LMG Sương Mai"
                         },
                         {
                           "maTruong": "7976601043",
                           "tenTruong": "LMG Độc Lập Việt Đức"
                         },
                         {
                           "maTruong": "7976601044",
                           "tenTruong": "LMG Hoàng Yến 2"
                         },
                         {
                           "maTruong": "7976601045",
                           "tenTruong": "LMG Ban Mai Xanh"
                         },
                         {
                           "maTruong": "7976601048",
                           "tenTruong": "LMG Tuổi Tiên"
                         },
                         {
                           "maTruong": "7976601046",
                           "tenTruong": "LMG ĐL Cúc Ban Mai"
                         },
                         {
                           "maTruong": "7976601039",
                           "tenTruong": "NT Bầu Trời Xanh"
                         },
                         {
                           "maTruong": "7976601040",
                           "tenTruong": "LMG Lá Xanh"
                         },
                         {
                           "maTruong": "7976601035",
                           "tenTruong": "LMG Hoa Nắng"
                         },
                         {
                           "maTruong": "7976601031",
                           "tenTruong": "LMG Hoa Hạnh Phúc"
                         },
                         {
                           "maTruong": "7976601032",
                           "tenTruong": "LMG Vùng Đất Tuổi Thơ"
                         },
                         {
                           "maTruong": "7976601033",
                           "tenTruong": "LMG Ngôi Nhà Dấu Yêu"
                         },
                         {
                           "maTruong": "7976601036",
                           "tenTruong": "NT Sóc Nâu 2"
                         },
                         {
                           "maTruong": "7976601038",
                           "tenTruong": "LMG Sa Kê 2"
                         }
                       ]
                     },
                     {
                       "maPhong": null,
                       "tenPhong": "Huyện Củ Chi",
                       "truongData": [
                         {
                           "maTruong": "79000736",
                           "tenTruong": "THPT An Nhơn Tây"
                         },
                         {
                           "maTruong": "79000756",
                           "tenTruong": "THPT Phú Hòa"
                         },
                         {
                           "maTruong": "79000735",
                           "tenTruong": "THPT Quang Trung"
                         },
                         {
                           "maTruong": "79000757",
                           "tenTruong": "THPT Tân Thông Hội"
                         },
                         {
                           "maTruong": "79000738",
                           "tenTruong": "THPT Trung Lập"
                         },
                         {
                           "maTruong": "79000737",
                           "tenTruong": "THPT Trung Phú"
                         },
                         {
                           "maTruong": "79000734",
                           "tenTruong": "Trường THPT Củ Chi"
                         },
                         {
                           "maTruong": "79783901",
                           "tenTruong": "TT GDTX H Củ Chi"
                         },
                         {
                           "maTruong": "79000B01",
                           "tenTruong": "NDTE Khuyết tật Củ Chi"
                         },
                         {
                           "maTruong": "7900004007",
                           "tenTruong": "Trường NDTE Khuyết tật Củ Chi (Củ Chi)"
                         },
                         {
                           "maTruong": "79000827",
                           "tenTruong": "THPT Chiến Thắng"
                         }
                       ]
                     },
                     {
                       "maPhong": "762",
                       "tenPhong": "Thành phố Thủ Đức",
                       "truongData": [
                         {
                           "maTruong": "79762003",
                           "tenTruong": "LMG Cầu Vòng KV3"
                         },
                         {
                           "maTruong": "79762006",
                           "tenTruong": "NT Việt Ý"
                         },
                         {
                           "maTruong": "79762010",
                           "tenTruong": "LMN Việt Âu"
                         },
                         {
                           "maTruong": "7976201001",
                           "tenTruong": "MN Trẻ Thơ"
                         },
                         {
                           "maTruong": "7976201002",
                           "tenTruong": "MN Thế Giới Xanh"
                         },
                         {
                           "maTruong": "7976201003",
                           "tenTruong": "LMG Những Ngôi Sao Nhỏ"
                         },
                         {
                           "maTruong": "7976201004",
                           "tenTruong": "LMG Thuận Thiên"
                         },
                         {
                           "maTruong": "7976201005",
                           "tenTruong": "LMG Ngôi Nhà Hạnh Phúc"
                         },
                         {
                           "maTruong": "7976201006",
                           "tenTruong": "LMG Bé Cưng"
                         },
                         {
                           "maTruong": "7976201007",
                           "tenTruong": "LMG Khu Vườn Kiwi"
                         },
                         {
                           "maTruong": "7976201008",
                           "tenTruong": "LMG Sao Việt Mỹ KV2"
                         },
                         {
                           "maTruong": "7976201009",
                           "tenTruong": "LMG Mai Anh Đào"
                         },
                         {
                           "maTruong": "7976201010",
                           "tenTruong": "NT Ngôi Nhà Tuổi Thơ"
                         },
                         {
                           "maTruong": "7976201011",
                           "tenTruong": "NT Việt Mỹ"
                         },
                         {
                           "maTruong": "7976201012",
                           "tenTruong": "LMG Hoa Hướng Dương"
                         },
                         {
                           "maTruong": "7976201013",
                           "tenTruong": "LMG Hoàng Long"
                         },
                         {
                           "maTruong": "7976201015",
                           "tenTruong": "NT Hoa Bình Minh"
                         },
                         {
                           "maTruong": "7976201017",
                           "tenTruong": "NT Đậu Nhỏ"
                         },
                         {
                           "maTruong": "7976201018",
                           "tenTruong": "LMG Làng Hạnh Phúc"
                         },
                         {
                           "maTruong": "7976201019",
                           "tenTruong": "LMG Hươu Cao Cổ"
                         },
                         {
                           "maTruong": "7976201020",
                           "tenTruong": "NT Sao Việt Nam Long"
                         },
                         {
                           "maTruong": "7976201021",
                           "tenTruong": "NT Ước Mơ Xanh"
                         },
                         {
                           "maTruong": "7976201022",
                           "tenTruong": "NT Vườn Ươm Nụ Cười"
                         },
                         {
                           "maTruong": "7976201023",
                           "tenTruong": "LMG Siêu Nhóc"
                         },
                         {
                           "maTruong": "7976201024",
                           "tenTruong": "LMG Lan Ngọc"
                         },
                         {
                           "maTruong": "7976201025",
                           "tenTruong": "LMG Ngôi Sao May Mắn"
                         },
                         {
                           "maTruong": "7976201026",
                           "tenTruong": "LMG Rồng Vàng"
                         },
                         {
                           "maTruong": "7976201027",
                           "tenTruong": "LMG Hoa Hồng"
                         },
                         {
                           "maTruong": "7976201028",
                           "tenTruong": "LMG Mai Anh Hai"
                         },
                         {
                           "maTruong": "7976201030",
                           "tenTruong": "LMG Minh Trang"
                         },
                         {
                           "maTruong": "7976201031",
                           "tenTruong": "LMG Hoa Nắng"
                         },
                         {
                           "maTruong": "7976201032",
                           "tenTruong": "LMG Bảo Minh"
                         },
                         {
                           "maTruong": "7976201033",
                           "tenTruong": "NT Nụ Cười Đô Rê Mon"
                         },
                         {
                           "maTruong": "7976201034",
                           "tenTruong": "NT ABC Khang An"
                         },
                         {
                           "maTruong": "7976201035",
                           "tenTruong": "LMG Bông Cỏ"
                         },
                         {
                           "maTruong": "7976201036",
                           "tenTruong": "LMG EQ"
                         },
                         {
                           "maTruong": "7976201037",
                           "tenTruong": "LMG Trường Xanh"
                         },
                         {
                           "maTruong": "7976201038",
                           "tenTruong": "LMG Ngôi Nhà Vui Vẻ"
                         },
                         {
                           "maTruong": "7976201039",
                           "tenTruong": "LMG Thế Giới Trẻ Thơ"
                         },
                         {
                           "maTruong": "7976201040",
                           "tenTruong": "LMG Ngôi Nhà Bút Chì"
                         },
                         {
                           "maTruong": "7976201041",
                           "tenTruong": "LMG Vịt Con"
                         },
                         {
                           "maTruong": "7976201042",
                           "tenTruong": "MN Bách Hân"
                         },
                         {
                           "maTruong": "7976201043",
                           "tenTruong": "LMG Chích Chòe Bông"
                         },
                         {
                           "maTruong": "7976201044",
                           "tenTruong": "LMG Trí Tuệ"
                         },
                         {
                           "maTruong": "7976201046",
                           "tenTruong": "LMN Tâm An"
                         },
                         {
                           "maTruong": "7976201047",
                           "tenTruong": "MN Totto-Chan"
                         },
                         {
                           "maTruong": "7976201048",
                           "tenTruong": "LMG Bí Ngô"
                         },
                         {
                           "maTruong": "7976201049",
                           "tenTruong": "LMG Tương Lai Việt"
                         },
                         {
                           "maTruong": "7976201050",
                           "tenTruong": "LMN Sài Gòn Nhỏ"
                         },
                         {
                           "maTruong": "7976201051",
                           "tenTruong": "NT Cánh Diều Vàng"
                         },
                         {
                           "maTruong": "7976201052",
                           "tenTruong": "LMG Trẻ Em Mới"
                         },
                         {
                           "maTruong": "7976201054",
                           "tenTruong": "MN Học Viện Đầu Tiên"
                         },
                         {
                           "maTruong": "7976201055",
                           "tenTruong": "NT Hoa Mùa Xuân"
                         },
                         {
                           "maTruong": "7976201056",
                           "tenTruong": "MN Thế Giới Trẻ Thơ"
                         },
                         {
                           "maTruong": "7976201057",
                           "tenTruong": "NT Nhà Ong Vàng"
                         },
                         {
                           "maTruong": "7976201058",
                           "tenTruong": "LMG Tân Mai"
                         },
                         {
                           "maTruong": "7976201059",
                           "tenTruong": "LMG Ngọc Nhi"
                         },
                         {
                           "maTruong": "7976201060",
                           "tenTruong": "LMN Hạnh Phúc Nhà Mình"
                         },
                         {
                           "maTruong": "7976201061",
                           "tenTruong": "LMG Khai Minh Trí"
                         },
                         {
                           "maTruong": "7976201062",
                           "tenTruong": "MN Chân Trời Trẻ Thơ"
                         },
                         {
                           "maTruong": "7976201063",
                           "tenTruong": "NT Hồng Kim"
                         },
                         {
                           "maTruong": "7976201065",
                           "tenTruong": "LMN Viên Kẹo Nhỏ"
                         },
                         {
                           "maTruong": "7976201066",
                           "tenTruong": "LMG Bé Yêu Vui Cười"
                         },
                         {
                           "maTruong": "7976201067",
                           "tenTruong": "LMN Mặt Trời Trẻ Thơ"
                         },
                         {
                           "maTruong": "7976201068",
                           "tenTruong": "LMG Kiến Con"
                         },
                         {
                           "maTruong": "7976201069",
                           "tenTruong": "NT Ngôi Nhà Hướng Dương"
                         },
                         {
                           "maTruong": "7976201070",
                           "tenTruong": "MN VinSchool"
                         },
                         {
                           "maTruong": "7976201071",
                           "tenTruong": "NT Tuệ Tâm"
                         },
                         {
                           "maTruong": "7976201072",
                           "tenTruong": "LMN Hoa Bi"
                         },
                         {
                           "maTruong": "7976201073",
                           "tenTruong": "NT Kiến An"
                         },
                         {
                           "maTruong": "7976201074",
                           "tenTruong": "LMN Khu Vườn Việt Nam"
                         },
                         {
                           "maTruong": "7976201075",
                           "tenTruong": "LMN Cây To"
                         },
                         {
                           "maTruong": "7976201076",
                           "tenTruong": "NT Nắng Vàng"
                         },
                         {
                           "maTruong": "7976201077",
                           "tenTruong": "NT Bong Bóng Nhỏ"
                         },
                         {
                           "maTruong": "7976201078",
                           "tenTruong": "LMG Hoa Bé Ngoan"
                         },
                         {
                           "maTruong": "7976201079",
                           "tenTruong": "LMG Những Chú Thợ Săn Nhí"
                         },
                         {
                           "maTruong": "7976201080",
                           "tenTruong": "LMG Khu Rừng Diệu Kỳ"
                         },
                         {
                           "maTruong": "7976201081",
                           "tenTruong": "LMN Cá Chép Đỏ"
                         },
                         {
                           "maTruong": "7976201082",
                           "tenTruong": "LMG Cầu Vồng"
                         },
                         {
                           "maTruong": "7976201083",
                           "tenTruong": "LMG Quả Cầu Nhỏ"
                         },
                         {
                           "maTruong": "7976201084",
                           "tenTruong": "LMG Vuông Tròn"
                         },
                         {
                           "maTruong": "7976201085",
                           "tenTruong": "LMN Trái Đất Xanh"
                         },
                         {
                           "maTruong": "7976201086",
                           "tenTruong": "MN Chú Ong Nhỏ"
                         },
                         {
                           "maTruong": "7976201087",
                           "tenTruong": "LMN Viên Ngọc Sáng"
                         },
                         {
                           "maTruong": "7976201088",
                           "tenTruong": "LMN Nắng Mới"
                         },
                         {
                           "maTruong": "7976201091",
                           "tenTruong": "LMN Những Ngôi Sao Xinh"
                         },
                         {
                           "maTruong": "7976201092",
                           "tenTruong": "LMN Ong Xinh Xinh"
                         },
                         {
                           "maTruong": "7976201093",
                           "tenTruong": "LMN Hạt Mầm"
                         },
                         {
                           "maTruong": "7976201094",
                           "tenTruong": "LMN Tài Năng Nhí ABC"
                         },
                         {
                           "maTruong": "7976201095",
                           "tenTruong": "LMN Cà Chua Đỏ"
                         },
                         {
                           "maTruong": "7976201096",
                           "tenTruong": "LMG Ta Tu"
                         },
                         {
                           "maTruong": "7976201097",
                           "tenTruong": "LMG Hoa"
                         },
                         {
                           "maTruong": "7976201098",
                           "tenTruong": "LMG Cỏ"
                         },
                         {
                           "maTruong": "7976201099",
                           "tenTruong": "NT Chích bông"
                         },
                         {
                           "maTruong": "7976201100",
                           "tenTruong": "LMN Tâm Phúc"
                         },
                         {
                           "maTruong": "7976201102",
                           "tenTruong": "LMN Thiện An"
                         },
                         {
                           "maTruong": "7976201103",
                           "tenTruong": "LMG Mùa Xuân"
                         },
                         {
                           "maTruong": "7976201104",
                           "tenTruong": "LMN Nhà Sông Montessori"
                         },
                         {
                           "maTruong": "7976201105",
                           "tenTruong": "NT Toàn Cầu"
                         },
                         {
                           "maTruong": "7976201106",
                           "tenTruong": "LMG Bông Hoa Nhỏ"
                         },
                         {
                           "maTruong": "79762020",
                           "tenTruong": "LMG Trăng Vàng"
                         },
                         {
                           "maTruong": "7976202001",
                           "tenTruong": "TH Nguyễn Thị Tư"
                         },
                         {
                           "maTruong": "7976202002",
                           "tenTruong": "TH E-School-Thạnh Mỹ Lợi"
                         },
                         {
                           "maTruong": "79762032",
                           "tenTruong": "MN Thỏ Ngọc KV3"
                         },
                         {
                           "maTruong": "79762049",
                           "tenTruong": "NT Tháp Hồng"
                         },
                         {
                           "maTruong": "79762061",
                           "tenTruong": "MN Ngôi Nhà Hu Gô"
                         },
                         {
                           "maTruong": "79762081",
                           "tenTruong": "LMG Tí Bo"
                         },
                         {
                           "maTruong": "79762101",
                           "tenTruong": "MN Thành Phố Tuổi Thơ"
                         },
                         {
                           "maTruong": "79762104",
                           "tenTruong": "LMG Hoa Trạng Nguyên"
                         },
                         {
                           "maTruong": "79762105",
                           "tenTruong": "MN Hải Hà"
                         },
                         {
                           "maTruong": "79762106",
                           "tenTruong": "LMG Hừng Đông"
                         },
                         {
                           "maTruong": "79762109",
                           "tenTruong": "MN Hoa Diên Vĩ Phạm Văn Đồng"
                         },
                         {
                           "maTruong": "79762110",
                           "tenTruong": "MN Mặt Trời"
                         },
                         {
                           "maTruong": "79762111",
                           "tenTruong": "MN Mặt Trời Nhỏ 3"
                         },
                         {
                           "maTruong": "79762113",
                           "tenTruong": "MN Ngôi Nhà Xanh"
                         },
                         {
                           "maTruong": "79762114",
                           "tenTruong": "MN Thanh Thúy"
                         },
                         {
                           "maTruong": "79762115",
                           "tenTruong": "MN Sao Mai"
                         },
                         {
                           "maTruong": "79762118",
                           "tenTruong": "LMN Phượng Hồng"
                         },
                         {
                           "maTruong": "79762126",
                           "tenTruong": "MN Sao Vui"
                         },
                         {
                           "maTruong": "79762127",
                           "tenTruong": "LMG Thủy Trúc"
                         },
                         {
                           "maTruong": "79762128",
                           "tenTruong": "LMG Bé Ngoan KV3"
                         },
                         {
                           "maTruong": "79762129",
                           "tenTruong": "LMN Hồng Phúc"
                         },
                         {
                           "maTruong": "79762130",
                           "tenTruong": "NT Ngôi Sao Xanh KV3"
                         },
                         {
                           "maTruong": "79762133",
                           "tenTruong": "MN Thiên Ca"
                         },
                         {
                           "maTruong": "79762134",
                           "tenTruong": "LMG Bình Minh"
                         },
                         {
                           "maTruong": "79762137",
                           "tenTruong": "LMG Họa Mi KV3"
                         },
                         {
                           "maTruong": "79762144",
                           "tenTruong": "MN Bông Sen"
                         },
                         {
                           "maTruong": "79762145",
                           "tenTruong": "NT Huyền Trâm"
                         },
                         {
                           "maTruong": "79762147",
                           "tenTruong": "MN Vàng Anh Trường Thọ"
                         },
                         {
                           "maTruong": "79762149",
                           "tenTruong": "MN Mai Vàng"
                         },
                         {
                           "maTruong": "79762151",
                           "tenTruong": "MN Bích Trâm"
                         },
                         {
                           "maTruong": "79762153",
                           "tenTruong": "MN Thiên Thần Nhỏ"
                         },
                         {
                           "maTruong": "79762160",
                           "tenTruong": "LMG Vân Phong"
                         },
                         {
                           "maTruong": "79762161",
                           "tenTruong": "NT Ánh Linh"
                         },
                         {
                           "maTruong": "79762162",
                           "tenTruong": "LMG Ánh Sao KV3"
                         },
                         {
                           "maTruong": "79762163",
                           "tenTruong": "LMG Ánh Sáng"
                         },
                         {
                           "maTruong": "79762168",
                           "tenTruong": "LMN Hương Quế"
                         },
                         {
                           "maTruong": "79762170",
                           "tenTruong": "NT Phúc Ân"
                         },
                         {
                           "maTruong": "79762173",
                           "tenTruong": "LMG Chim Vàng Anh"
                         },
                         {
                           "maTruong": "79762174",
                           "tenTruong": "LMG Hoàng Kim"
                         },
                         {
                           "maTruong": "79762175",
                           "tenTruong": "LMG Thiên Ý"
                         },
                         {
                           "maTruong": "79762183",
                           "tenTruong": "LMG Thiên Nhi"
                         },
                         {
                           "maTruong": "79762192",
                           "tenTruong": "NT Mai Hoa"
                         },
                         {
                           "maTruong": "79762193",
                           "tenTruong": "NT Sao Việt"
                         },
                         {
                           "maTruong": "79762194",
                           "tenTruong": "LMG An Ý"
                         },
                         {
                           "maTruong": "79762197",
                           "tenTruong": "LMG Hoàng Hạc"
                         },
                         {
                           "maTruong": "79762198",
                           "tenTruong": "NT Phương Mi"
                         },
                         {
                           "maTruong": "797621A7",
                           "tenTruong": "LMG Em Bé Hạnh Phúc"
                         },
                         {
                           "maTruong": "797621W1",
                           "tenTruong": "LMG Ngọc Đạt"
                         },
                         {
                           "maTruong": "797621W2",
                           "tenTruong": "LMG Khang Nguyên"
                         },
                         {
                           "maTruong": "797621W3",
                           "tenTruong": "LMG Thiên Hương"
                         },
                         {
                           "maTruong": "79762201",
                           "tenTruong": "LMG Bảo Hân"
                         },
                         {
                           "maTruong": "79762202",
                           "tenTruong": "LMG Sao Việt Mỹ KV3"
                         },
                         {
                           "maTruong": "79762203",
                           "tenTruong": "LMG Tuổi Xinh"
                         },
                         {
                           "maTruong": "79762204",
                           "tenTruong": "MN Sen Hồng 3"
                         },
                         {
                           "maTruong": "79762211",
                           "tenTruong": "MN Hồng Ân"
                         },
                         {
                           "maTruong": "79762213",
                           "tenTruong": "MN Huệ Trắng"
                         },
                         {
                           "maTruong": "79762215",
                           "tenTruong": "MG Linh Ân"
                         },
                         {
                           "maTruong": "79762216",
                           "tenTruong": "MN Hoàng Anh"
                         },
                         {
                           "maTruong": "79762217",
                           "tenTruong": "MG Mai Khôi"
                         },
                         {
                           "maTruong": "79762220",
                           "tenTruong": "MN Mai Anh"
                         },
                         {
                           "maTruong": "79762221",
                           "tenTruong": "MG Hạnh Mai"
                         },
                         {
                           "maTruong": "79762222",
                           "tenTruong": "MN Hướng Dương"
                         },
                         {
                           "maTruong": "79762223",
                           "tenTruong": "MG Ánh Hồng"
                         },
                         {
                           "maTruong": "79762224",
                           "tenTruong": "MG Hoa Cúc"
                         },
                         {
                           "maTruong": "79762283",
                           "tenTruong": "LMN Hòa Bình"
                         },
                         {
                           "maTruong": "79762300",
                           "tenTruong": "MN Tuệ Đức Võ Văn Ngân"
                         },
                         {
                           "maTruong": "79762301",
                           "tenTruong": "MN Búp Sen Hồng"
                         },
                         {
                           "maTruong": "79762302",
                           "tenTruong": "MN Hiệp Bình Chánh"
                         },
                         {
                           "maTruong": "79762303",
                           "tenTruong": "MN Hiệp Bình Phước"
                         },
                         {
                           "maTruong": "79762304",
                           "tenTruong": "MN Bình Thọ"
                         },
                         {
                           "maTruong": "79762305",
                           "tenTruong": "MN Vành Khuyên 3"
                         },
                         {
                           "maTruong": "79762306",
                           "tenTruong": "MN Hiệp Bình Chánh II"
                         },
                         {
                           "maTruong": "79762307",
                           "tenTruong": "MN Hiệp Bình Chánh III"
                         },
                         {
                           "maTruong": "79762308",
                           "tenTruong": "MN Bình Chiểu"
                         },
                         {
                           "maTruong": "79762309",
                           "tenTruong": "MN Linh Xuân"
                         },
                         {
                           "maTruong": "79762310",
                           "tenTruong": "MN Trường Thọ"
                         },
                         {
                           "maTruong": "79762311",
                           "tenTruong": "MN Hoa Phượng"
                         },
                         {
                           "maTruong": "79762312",
                           "tenTruong": "MN Khiết Tâm"
                         },
                         {
                           "maTruong": "79762313",
                           "tenTruong": "MN Nhân Ái"
                         },
                         {
                           "maTruong": "79762315",
                           "tenTruong": "MN Linh Chiểu"
                         },
                         {
                           "maTruong": "79762316",
                           "tenTruong": "MN Tam Bình"
                         },
                         {
                           "maTruong": "79762317",
                           "tenTruong": "MN Hương Sen"
                         },
                         {
                           "maTruong": "79762319",
                           "tenTruong": "MN Linh Trung"
                         },
                         {
                           "maTruong": "79762320",
                           "tenTruong": "MN Tam Phú"
                         },
                         {
                           "maTruong": "79762321",
                           "tenTruong": "MN Học Viện Trí Tuệ"
                         },
                         {
                           "maTruong": "79762322",
                           "tenTruong": "MN Thanh Tâm"
                         },
                         {
                           "maTruong": "79762323",
                           "tenTruong": "MN Măng Non 3"
                         },
                         {
                           "maTruong": "79762324",
                           "tenTruong": "MN Trinh Vương"
                         },
                         {
                           "maTruong": "79762325",
                           "tenTruong": "MN Hạnh Phúc"
                         },
                         {
                           "maTruong": "79762326",
                           "tenTruong": "MN Thủ Đức"
                         },
                         {
                           "maTruong": "79762327",
                           "tenTruong": "MN Ngọc Lan"
                         },
                         {
                           "maTruong": "79762328",
                           "tenTruong": "MN Việt Mỹ Sài Gòn KV3"
                         },
                         {
                           "maTruong": "79762329",
                           "tenTruong": "MN Khánh Hỷ"
                         },
                         {
                           "maTruong": "79762331",
                           "tenTruong": "MN Kiểu Mẫu"
                         },
                         {
                           "maTruong": "79762332",
                           "tenTruong": "MN Tuổi Ngọc 3"
                         },
                         {
                           "maTruong": "79762334",
                           "tenTruong": "MN Thiên Ân 3"
                         },
                         {
                           "maTruong": "79762338",
                           "tenTruong": "MN Hiếu Trung"
                         },
                         {
                           "maTruong": "79762340",
                           "tenTruong": "MN Ngôi Nhà Hạnh Phúc Linh Đông"
                         },
                         {
                           "maTruong": "79762341",
                           "tenTruong": "MN Nhật Tân"
                         },
                         {
                           "maTruong": "79762342",
                           "tenTruong": "MN Du Sinh"
                         },
                         {
                           "maTruong": "79762344",
                           "tenTruong": "MN Việt Mỹ"
                         },
                         {
                           "maTruong": "79762346",
                           "tenTruong": "MN 20/11"
                         },
                         {
                           "maTruong": "79762347",
                           "tenTruong": "MN Linh Tây"
                         },
                         {
                           "maTruong": "79762348",
                           "tenTruong": "MN Hoa Xuân"
                         },
                         {
                           "maTruong": "79762349",
                           "tenTruong": "MN Trúc Xanh"
                         },
                         {
                           "maTruong": "79762351",
                           "tenTruong": "MN Bông Sen Xanh"
                         },
                         {
                           "maTruong": "79762352",
                           "tenTruong": "MN Gấu Trúc"
                         },
                         {
                           "maTruong": "79762353",
                           "tenTruong": "MN Gia Huy"
                         },
                         {
                           "maTruong": "79762354",
                           "tenTruong": "MN Mỹ Phước"
                         },
                         {
                           "maTruong": "79762356",
                           "tenTruong": "MN Đông Dương"
                         },
                         {
                           "maTruong": "79762357",
                           "tenTruong": "MN Mỹ An"
                         },
                         {
                           "maTruong": "79762358",
                           "tenTruong": "MN Hoa Mai"
                         },
                         {
                           "maTruong": "79762359",
                           "tenTruong": "MN Tuổi Tiên"
                         },
                         {
                           "maTruong": "79762361",
                           "tenTruong": "MN Vầng Dương"
                         },
                         {
                           "maTruong": "79762363",
                           "tenTruong": "MN Birrong Sài Gòn"
                         },
                         {
                           "maTruong": "79762364",
                           "tenTruong": "MN Cánh Thiên Thần"
                         },
                         {
                           "maTruong": "79762365",
                           "tenTruong": "LMG Phượng Hoàng"
                         },
                         {
                           "maTruong": "79762367",
                           "tenTruong": "MN Hải Âu"
                         },
                         {
                           "maTruong": "79762368",
                           "tenTruong": "MN Tâm Nhi"
                         },
                         {
                           "maTruong": "79762369",
                           "tenTruong": "MN Khai Tâm"
                         },
                         {
                           "maTruong": "79762370",
                           "tenTruong": "MG Mai Tâm"
                         },
                         {
                           "maTruong": "79762371",
                           "tenTruong": "MN Mặt Trời Đỏ"
                         },
                         {
                           "maTruong": "79762372",
                           "tenTruong": "MN Bồ Công Anh"
                         },
                         {
                           "maTruong": "79762373",
                           "tenTruong": "MN Việt Anh"
                         },
                         {
                           "maTruong": "79762374",
                           "tenTruong": "MN Sơn Ca 3"
                         },
                         {
                           "maTruong": "79762376",
                           "tenTruong": "MN Hương Nắng Hồng"
                         },
                         {
                           "maTruong": "79762377",
                           "tenTruong": "MN Hoa Sen Bình Chiểu"
                         },
                         {
                           "maTruong": "79762378",
                           "tenTruong": "MN Hải Yến"
                         },
                         {
                           "maTruong": "79762380",
                           "tenTruong": "MN Sao Ngoan"
                         },
                         {
                           "maTruong": "79762382",
                           "tenTruong": "MN Sài Gòn"
                         },
                         {
                           "maTruong": "79762384",
                           "tenTruong": "MN An Lộc"
                         },
                         {
                           "maTruong": "79762385",
                           "tenTruong": "MN Hoàng Ngọc"
                         },
                         {
                           "maTruong": "79762388",
                           "tenTruong": "MN Sao Vàng"
                         },
                         {
                           "maTruong": "79762389",
                           "tenTruong": "MN Mai An"
                         },
                         {
                           "maTruong": "79762391",
                           "tenTruong": "MN Thanh Bình"
                         },
                         {
                           "maTruong": "79762393",
                           "tenTruong": "MN Hoa Đào"
                         },
                         {
                           "maTruong": "79762394",
                           "tenTruong": "MN Hoàng Yến 3"
                         },
                         {
                           "maTruong": "79762395",
                           "tenTruong": "MN CS Thí Điểm Giữ Trẻ"
                         },
                         {
                           "maTruong": "79762396",
                           "tenTruong": "MN Ngôi Sao Sáng"
                         },
                         {
                           "maTruong": "79762397",
                           "tenTruong": "MN Sông Xanh"
                         },
                         {
                           "maTruong": "79762398",
                           "tenTruong": "MN Bầu Trời Xanh 3"
                         },
                         {
                           "maTruong": "79762399",
                           "tenTruong": "MN Việt Đông Dương"
                         },
                         {
                           "maTruong": "797623W7",
                           "tenTruong": "MN Bi Bi"
                         },
                         {
                           "maTruong": "797623W8",
                           "tenTruong": "MN Hoa Bình Minh"
                         },
                         {
                           "maTruong": "797623W9",
                           "tenTruong": "MN Họa Mi 3"
                         },
                         {
                           "maTruong": "797623X0",
                           "tenTruong": "MN Hoa Hồng 3"
                         },
                         {
                           "maTruong": "797623X1",
                           "tenTruong": "MN Bến Thành"
                         },
                         {
                           "maTruong": "797623X2",
                           "tenTruong": "MN Thế Giới Xanh TĐ"
                         },
                         {
                           "maTruong": "797623X3",
                           "tenTruong": "MN Mặt Trời Bé Con"
                         },
                         {
                           "maTruong": "797623X4",
                           "tenTruong": "MN Mây Hồng"
                         },
                         {
                           "maTruong": "797623X5",
                           "tenTruong": "MN Sắc Màu Tuổi Thơ"
                         },
                         {
                           "maTruong": "79762401",
                           "tenTruong": "TH Nguyễn Trung Trực"
                         },
                         {
                           "maTruong": "79762402",
                           "tenTruong": "TH Lương Thế Vinh"
                         },
                         {
                           "maTruong": "79762404",
                           "tenTruong": "TH Bình Triệu"
                         },
                         {
                           "maTruong": "79762405",
                           "tenTruong": "TH Đặng Thị Rành"
                         },
                         {
                           "maTruong": "79762406",
                           "tenTruong": "TH Hiệp Bình Phước"
                         },
                         {
                           "maTruong": "79762407",
                           "tenTruong": "TH Thái Văn Lung"
                         },
                         {
                           "maTruong": "79762408",
                           "tenTruong": "TH Tam Bình"
                         },
                         {
                           "maTruong": "79762409",
                           "tenTruong": "TH Trần Văn Vân"
                         },
                         {
                           "maTruong": "79762410",
                           "tenTruong": "TH Bình Chiểu"
                         },
                         {
                           "maTruong": "79762411",
                           "tenTruong": "TH Xuân Hiệp"
                         },
                         {
                           "maTruong": "79762412",
                           "tenTruong": "TH Hoàng Diệu"
                         },
                         {
                           "maTruong": "79762413",
                           "tenTruong": "TH Nguyễn Văn Triết"
                         },
                         {
                           "maTruong": "79762414",
                           "tenTruong": "TH Linh Tây"
                         },
                         {
                           "maTruong": "79762415",
                           "tenTruong": "TH Bình Quới"
                         },
                         {
                           "maTruong": "79762416",
                           "tenTruong": "TH Đặng Văn Bất"
                         },
                         {
                           "maTruong": "79762417",
                           "tenTruong": "TH Linh Đông"
                         },
                         {
                           "maTruong": "79762418",
                           "tenTruong": "TH Nguyễn Văn Banh"
                         },
                         {
                           "maTruong": "79762419",
                           "tenTruong": "TH Hiệp Bình Chánh"
                         },
                         {
                           "maTruong": "79762420",
                           "tenTruong": "TH Từ Đức"
                         },
                         {
                           "maTruong": "79762421",
                           "tenTruong": "TH Trương Văn Hải"
                         },
                         {
                           "maTruong": "79762422",
                           "tenTruong": "TH Nguyễn Văn Lịch"
                         },
                         {
                           "maTruong": "79762423",
                           "tenTruong": "TH Đào Sơn Tây"
                         },
                         {
                           "maTruong": "79762424",
                           "tenTruong": "TH Linh Chiểu"
                         },
                         {
                           "maTruong": "79762427",
                           "tenTruong": "TH Đỗ Tấn Phong"
                         },
                         {
                           "maTruong": "79762428",
                           "tenTruong": "TH Nguyễn Văn Tây"
                         },
                         {
                           "maTruong": "79762429",
                           "tenTruong": "TH Nguyễn Văn Nở"
                         },
                         {
                           "maTruong": "79762501",
                           "tenTruong": "THCS Lê Quý Đôn"
                         },
                         {
                           "maTruong": "79762502",
                           "tenTruong": "THCS Trương Văn Ngư"
                         },
                         {
                           "maTruong": "79762503",
                           "tenTruong": "THCS Ngô Chí Quốc"
                         },
                         {
                           "maTruong": "79762504",
                           "tenTruong": "THCS Hiệp Bình"
                         },
                         {
                           "maTruong": "79762505",
                           "tenTruong": "THCS Thái Văn Lung"
                         },
                         {
                           "maTruong": "79762506",
                           "tenTruong": "THCS Tam Bình"
                         },
                         {
                           "maTruong": "79762507",
                           "tenTruong": "THCS Xuân Trường"
                         },
                         {
                           "maTruong": "79762508",
                           "tenTruong": "THCS Linh Trung"
                         },
                         {
                           "maTruong": "79762509",
                           "tenTruong": "THCS Linh Đông"
                         },
                         {
                           "maTruong": "79762510",
                           "tenTruong": "THCS Bình Chiểu"
                         },
                         {
                           "maTruong": "79762511",
                           "tenTruong": "THCS Bình Thọ"
                         },
                         {
                           "maTruong": "79762512",
                           "tenTruong": "THCS Lê Văn Việt"
                         },
                         {
                           "maTruong": "79762514",
                           "tenTruong": "THCS Nguyễn Văn Bá"
                         },
                         {
                           "maTruong": "79762515",
                           "tenTruong": "THCS Trường Thọ"
                         },
                         {
                           "maTruong": "79762516",
                           "tenTruong": "THCS Dương Văn Thì"
                         },
                         {
                           "maTruong": "79762L93",
                           "tenTruong": "LMG Duy Khang"
                         },
                         {
                           "maTruong": "79762LB1",
                           "tenTruong": "LMG Tuổi Thơ Tài Năng"
                         },
                         {
                           "maTruong": "79762W02",
                           "tenTruong": "LMG Thiên An"
                         },
                         {
                           "maTruong": "79762W05",
                           "tenTruong": "MN Hoa Quỳnh"
                         },
                         {
                           "maTruong": "79762W62",
                           "tenTruong": "NT Bé Thơ"
                         },
                         {
                           "maTruong": "79762W66",
                           "tenTruong": "MN Mai Ka"
                         },
                         {
                           "maTruong": "79762W72",
                           "tenTruong": "LMG ABC"
                         },
                         {
                           "maTruong": "79762W73",
                           "tenTruong": "LMG 1 tháng 6"
                         },
                         {
                           "maTruong": "79762W74",
                           "tenTruong": "LMG Trúc Xinh"
                         },
                         {
                           "maTruong": "79762W75",
                           "tenTruong": "MN Hoa Hồng Nhỏ"
                         },
                         {
                           "maTruong": "79762W76",
                           "tenTruong": "NT Nhà Thiên Thần"
                         },
                         {
                           "maTruong": "79762W79",
                           "tenTruong": "NT Uyên Nhi"
                         },
                         {
                           "maTruong": "79762W80",
                           "tenTruong": "LMG Hoa Phượng Đỏ"
                         },
                         {
                           "maTruong": "79762W81",
                           "tenTruong": "MN Thế Giới Trẻ Em"
                         },
                         {
                           "maTruong": "79762W83",
                           "tenTruong": "MN Hướng Dương Vàng TĐ"
                         },
                         {
                           "maTruong": "79762W85",
                           "tenTruong": "LMG Phương Nhật"
                         },
                         {
                           "maTruong": "79762WA3",
                           "tenTruong": "LMG Hoa Mộc Lan"
                         },
                         {
                           "maTruong": "79762WA4",
                           "tenTruong": "LMG Băng Băng"
                         },
                         {
                           "maTruong": "79762WA6",
                           "tenTruong": "LMG Ong Vàng"
                         },
                         {
                           "maTruong": "79762WA7",
                           "tenTruong": "LMG Bảo Châu"
                         },
                         {
                           "maTruong": "79762WB1",
                           "tenTruong": "LMG Ngôi Nhà Trẻ Thơ"
                         },
                         {
                           "maTruong": "79762WB6",
                           "tenTruong": "MN Học Viện Sài Gòn"
                         },
                         {
                           "maTruong": "79763007",
                           "tenTruong": "NT Bút Chì"
                         },
                         {
                           "maTruong": "79763012",
                           "tenTruong": "LMG Hoa Thiên"
                         },
                         {
                           "maTruong": "79763038",
                           "tenTruong": "NT Chú Ta Tu Nhỏ"
                         },
                         {
                           "maTruong": "79763071",
                           "tenTruong": "NT Bé Thông Minh"
                         },
                         {
                           "maTruong": "79763103",
                           "tenTruong": "LMG Yêu Con KV2"
                         },
                         {
                           "maTruong": "79763119",
                           "tenTruong": "LMG Ngôi Sao Phương Nam"
                         },
                         {
                           "maTruong": "79763134",
                           "tenTruong": "LMG Hoa Mai Vàng"
                         },
                         {
                           "maTruong": "79763138",
                           "tenTruong": "LMG Thần Đồng Việt"
                         },
                         {
                           "maTruong": "79763143",
                           "tenTruong": "MN Cocoon Kén Nhỏ"
                         },
                         {
                           "maTruong": "79763167",
                           "tenTruong": "NT Hoàng Oanh"
                         },
                         {
                           "maTruong": "79763187",
                           "tenTruong": "LMG Ngôi Nhà Nhỏ"
                         },
                         {
                           "maTruong": "79763196",
                           "tenTruong": "LMG Hạt Đậu Nhỏ"
                         },
                         {
                           "maTruong": "79763198",
                           "tenTruong": "NT Vườn Trẻ Thơ"
                         },
                         {
                           "maTruong": "79763202",
                           "tenTruong": "MN Long Phước"
                         },
                         {
                           "maTruong": "79763203",
                           "tenTruong": "MN Tuổi Thơ KV2"
                         },
                         {
                           "maTruong": "79763205",
                           "tenTruong": "MG Mai Hoa"
                         },
                         {
                           "maTruong": "79763212",
                           "tenTruong": "LMG Sao Tuổi Thơ"
                         },
                         {
                           "maTruong": "79763239",
                           "tenTruong": "LMG Hoa Cọ Vàng"
                         },
                         {
                           "maTruong": "79763301",
                           "tenTruong": "MN Long Bình"
                         },
                         {
                           "maTruong": "79763302",
                           "tenTruong": "MN Tân Phú"
                         },
                         {
                           "maTruong": "79763303",
                           "tenTruong": "MN Hiệp Phú"
                         },
                         {
                           "maTruong": "79763304",
                           "tenTruong": "MN Phước Bình"
                         },
                         {
                           "maTruong": "79763305",
                           "tenTruong": "MN Hoa Sen"
                         },
                         {
                           "maTruong": "79763306",
                           "tenTruong": "MN Vàng Anh"
                         },
                         {
                           "maTruong": "79763307",
                           "tenTruong": "MN Sơn Ca 2"
                         },
                         {
                           "maTruong": "79763308",
                           "tenTruong": "MN Hoàng Yến 2"
                         },
                         {
                           "maTruong": "79763309",
                           "tenTruong": "MN Phong Phú"
                         },
                         {
                           "maTruong": "79763310",
                           "tenTruong": "MN Thanh Lịch"
                         },
                         {
                           "maTruong": "79763312",
                           "tenTruong": "MN Hoa Lan"
                         },
                         {
                           "maTruong": "79763313",
                           "tenTruong": "MN Long Trường"
                         },
                         {
                           "maTruong": "79763314",
                           "tenTruong": "MN Trường Thạnh"
                         },
                         {
                           "maTruong": "79763315",
                           "tenTruong": "MN Rồng Vàng"
                         },
                         {
                           "maTruong": "79763316",
                           "tenTruong": "MN Hoa Hồng Đỏ"
                         },
                         {
                           "maTruong": "79763317",
                           "tenTruong": "MN Bình Minh 2"
                         },
                         {
                           "maTruong": "79763318",
                           "tenTruong": "MN Sóc Nâu - PB"
                         },
                         {
                           "maTruong": "79763319",
                           "tenTruong": "MN Kiều Đàm"
                         },
                         {
                           "maTruong": "79763320",
                           "tenTruong": "MN Thỏ Ngọc"
                         },
                         {
                           "maTruong": "79763321",
                           "tenTruong": "MN Hoa Mai @"
                         },
                         {
                           "maTruong": "79763322",
                           "tenTruong": "MN Tuổi Hồng"
                         },
                         {
                           "maTruong": "79763323",
                           "tenTruong": "MN Tuổi Ngọc"
                         },
                         {
                           "maTruong": "79763324",
                           "tenTruong": "MN Ngô Thời Nhiệm"
                         },
                         {
                           "maTruong": "79763326",
                           "tenTruong": "MN Tuổi Hoa"
                         },
                         {
                           "maTruong": "79763332",
                           "tenTruong": "MN Thiện Mỹ"
                         },
                         {
                           "maTruong": "79763333",
                           "tenTruong": "MN Hoa Thương"
                         },
                         {
                           "maTruong": "79763334",
                           "tenTruong": "MN Sao Sáng"
                         },
                         {
                           "maTruong": "79763335",
                           "tenTruong": "MN Tạ Uyên"
                         },
                         {
                           "maTruong": "79763336",
                           "tenTruong": "MN Long Sơn"
                         },
                         {
                           "maTruong": "79763337",
                           "tenTruong": "MN Mẹ Yêu Con"
                         },
                         {
                           "maTruong": "79763338",
                           "tenTruong": "MN Con Mèo Vàng"
                         },
                         {
                           "maTruong": "79763339",
                           "tenTruong": "MN Công Nghệ Cao"
                         },
                         {
                           "maTruong": "79763340",
                           "tenTruong": "MN Long Thạnh Mỹ"
                         },
                         {
                           "maTruong": "79763341",
                           "tenTruong": "MN ABC"
                         },
                         {
                           "maTruong": "79763342",
                           "tenTruong": "MN Thăng Long A"
                         },
                         {
                           "maTruong": "79763343",
                           "tenTruong": "MN Miền Trẻ Thơ 2"
                         },
                         {
                           "maTruong": "79763344",
                           "tenTruong": "MN Hoa Trà My"
                         },
                         {
                           "maTruong": "79763345",
                           "tenTruong": "MN Á Châu"
                         },
                         {
                           "maTruong": "79763346",
                           "tenTruong": "MN Ngôi Sao 2"
                         },
                         {
                           "maTruong": "79763347",
                           "tenTruong": "MN Thiên Ân Phúc 3"
                         },
                         {
                           "maTruong": "79763348",
                           "tenTruong": "MN Vương Quốc Tí Hon 2"
                         },
                         {
                           "maTruong": "79763349",
                           "tenTruong": "MN Kim Đồng"
                         },
                         {
                           "maTruong": "79763350",
                           "tenTruong": "MN Tuệ Đức-  Fuji"
                         },
                         {
                           "maTruong": "79763351",
                           "tenTruong": "MN Hoa Diên Vĩ - Gia Hòa"
                         },
                         {
                           "maTruong": "79763352",
                           "tenTruong": "MN Hoàng Kim 9 Giàu"
                         },
                         {
                           "maTruong": "79763353",
                           "tenTruong": "MN Bầu Trời Xanh KV2"
                         },
                         {
                           "maTruong": "79763354",
                           "tenTruong": "MN Thiên Phúc"
                         },
                         {
                           "maTruong": "79763355",
                           "tenTruong": "MN Thiên Ân Phúc 4"
                         },
                         {
                           "maTruong": "79763356",
                           "tenTruong": "MN Mặt Trời Á Châu"
                         },
                         {
                           "maTruong": "79763357",
                           "tenTruong": "MN Diệu Kỳ"
                         },
                         {
                           "maTruong": "79763358",
                           "tenTruong": "MN Vườn Ánh Sao"
                         },
                         {
                           "maTruong": "79763359",
                           "tenTruong": "MN Hồ Ngọc Cẩn"
                         },
                         {
                           "maTruong": "79763401",
                           "tenTruong": "TH Long Bình"
                         },
                         {
                           "maTruong": "79763402",
                           "tenTruong": "TH Phước Bình"
                         },
                         {
                           "maTruong": "79763403",
                           "tenTruong": "TH Tạ Uyên"
                         },
                         {
                           "maTruong": "79763404",
                           "tenTruong": "TH Đinh Tiên Hoàng"
                         },
                         {
                           "maTruong": "79763405",
                           "tenTruong": "TH Phú Hữu"
                         },
                         {
                           "maTruong": "79763406",
                           "tenTruong": "TH Võ Văn Hát"
                         },
                         {
                           "maTruong": "79763407",
                           "tenTruong": "TH Long Thạnh Mỹ"
                         },
                         {
                           "maTruong": "79763409",
                           "tenTruong": "TH Nguyễn Minh Quang"
                         },
                         {
                           "maTruong": "79763410",
                           "tenTruong": "TH Phước Thạnh"
                         },
                         {
                           "maTruong": "79763411",
                           "tenTruong": "TH Trương Văn Thành"
                         },
                         {
                           "maTruong": "79763412",
                           "tenTruong": "TH Phong Phú"
                         },
                         {
                           "maTruong": "79763413",
                           "tenTruong": "TH Long Phước"
                         },
                         {
                           "maTruong": "79763414",
                           "tenTruong": "TH Bùi Văn Mới"
                         },
                         {
                           "maTruong": "79763415",
                           "tenTruong": "TH Trường Thạnh"
                         },
                         {
                           "maTruong": "79763417",
                           "tenTruong": "TH Tân Phú"
                         },
                         {
                           "maTruong": "79763419",
                           "tenTruong": "TH Lê Văn Việt"
                         },
                         {
                           "maTruong": "79763420",
                           "tenTruong": "TH Nguyễn Văn Bá"
                         },
                         {
                           "maTruong": "79763421",
                           "tenTruong": "TH Trần Thị Bưởi"
                         },
                         {
                           "maTruong": "79763422",
                           "tenTruong": "TH Phạm Văn Chính"
                         },
                         {
                           "maTruong": "79763423",
                           "tenTruong": "TH Khai Nguyên"
                         },
                         {
                           "maTruong": "79763501",
                           "tenTruong": "THCS Phước Bình"
                         },
                         {
                           "maTruong": "79763502",
                           "tenTruong": "THCS Hưng Bình"
                         },
                         {
                           "maTruong": "79763503",
                           "tenTruong": "THCS Hoa Lư"
                         },
                         {
                           "maTruong": "79763504",
                           "tenTruong": "THCS Trần Quốc Toản"
                         },
                         {
                           "maTruong": "79763505",
                           "tenTruong": "THCS Long Trường"
                         },
                         {
                           "maTruong": "79763506",
                           "tenTruong": "THCS Long Phước"
                         },
                         {
                           "maTruong": "79763507",
                           "tenTruong": "THCS Đặng Tấn Tài"
                         },
                         {
                           "maTruong": "79763508",
                           "tenTruong": "THCS Phú Hữu"
                         },
                         {
                           "maTruong": "79763509",
                           "tenTruong": "THCS Tân Phú"
                         },
                         {
                           "maTruong": "79763510",
                           "tenTruong": "THCS Trường Thạnh"
                         },
                         {
                           "maTruong": "79763511",
                           "tenTruong": "THCS Long Bình"
                         },
                         {
                           "maTruong": "79763512",
                           "tenTruong": "THCS Tăng Nhơn Phú B"
                         },
                         {
                           "maTruong": "79763513",
                           "tenTruong": "THCS Hiệp Phú"
                         },
                         {
                           "maTruong": "79763L42",
                           "tenTruong": "LMG Gấu Panda"
                         },
                         {
                           "maTruong": "79763L43",
                           "tenTruong": "LMG Bồ Câu Trắng"
                         },
                         {
                           "maTruong": "79763L45",
                           "tenTruong": "LMG Ánh Sao KV2"
                         },
                         {
                           "maTruong": "79763L49",
                           "tenTruong": "LMG Tâm Đức"
                         },
                         {
                           "maTruong": "79763L54",
                           "tenTruong": "LMG Minh Châu"
                         },
                         {
                           "maTruong": "79763L55",
                           "tenTruong": "LMG Thăng Long"
                         },
                         {
                           "maTruong": "79763L56",
                           "tenTruong": "NT Ban Mai Xanh"
                         },
                         {
                           "maTruong": "79763L57",
                           "tenTruong": "LMG Hoa Huệ Trắng"
                         },
                         {
                           "maTruong": "79763L59",
                           "tenTruong": "LMG Gấu Anh"
                         },
                         {
                           "maTruong": "79763L66",
                           "tenTruong": "LMG Nam Long"
                         },
                         {
                           "maTruong": "79763L67",
                           "tenTruong": "LMG Nụ Cười Hồng"
                         },
                         {
                           "maTruong": "79763L68",
                           "tenTruong": "LMG Mặt Trời Đỏ"
                         },
                         {
                           "maTruong": "79763L69",
                           "tenTruong": "NT Chào Bé Yêu"
                         },
                         {
                           "maTruong": "79763L70",
                           "tenTruong": "LMG Gấu Con"
                         },
                         {
                           "maTruong": "79763L72",
                           "tenTruong": "LMG Chuông Vàng"
                         },
                         {
                           "maTruong": "79763L73",
                           "tenTruong": "LMG Nắng Ban Mai"
                         },
                         {
                           "maTruong": "79763L74",
                           "tenTruong": "LMG Vườn Ươm Hạnh Phúc KV2"
                         },
                         {
                           "maTruong": "79763L75",
                           "tenTruong": "LMG Tài Năng Á Châu"
                         },
                         {
                           "maTruong": "79763L76",
                           "tenTruong": "NT 151"
                         },
                         {
                           "maTruong": "79763L77",
                           "tenTruong": "LMG Nụ Cười Trẻ Thơ"
                         },
                         {
                           "maTruong": "79763L78",
                           "tenTruong": "LMG Sen Việt"
                         },
                         {
                           "maTruong": "79763L79",
                           "tenTruong": "NT Tuyết Vân"
                         },
                         {
                           "maTruong": "79763L81",
                           "tenTruong": "LMG Sam Biển"
                         },
                         {
                           "maTruong": "79763L82",
                           "tenTruong": "LMG Hoa Mai"
                         },
                         {
                           "maTruong": "79763L84",
                           "tenTruong": "LMG Họa Mi KV2"
                         },
                         {
                           "maTruong": "79763L85",
                           "tenTruong": "LMG Ánh Dương"
                         },
                         {
                           "maTruong": "79763L86",
                           "tenTruong": "LMG Tuổi Thần Tiên"
                         },
                         {
                           "maTruong": "79763L87",
                           "tenTruong": "LMG Phương Đông"
                         },
                         {
                           "maTruong": "79763L88",
                           "tenTruong": "NT Kỳ Anh"
                         },
                         {
                           "maTruong": "79763L89",
                           "tenTruong": "LMG Minh Anh"
                         },
                         {
                           "maTruong": "79763L90",
                           "tenTruong": "LMG Vườn Kiwi"
                         },
                         {
                           "maTruong": "79763L91",
                           "tenTruong": "LMG Gấu Trúc"
                         },
                         {
                           "maTruong": "79763L92",
                           "tenTruong": "LMG Sao Việt IOC"
                         },
                         {
                           "maTruong": "79763L93",
                           "tenTruong": "LMG Nhân Phú"
                         },
                         {
                           "maTruong": "79763L94",
                           "tenTruong": "LMG Ngôi Sao Nhỏ"
                         },
                         {
                           "maTruong": "79763L95",
                           "tenTruong": "LMG Ngôi Sao Việt"
                         },
                         {
                           "maTruong": "79763L96",
                           "tenTruong": "LMG Mầm Non Việt"
                         },
                         {
                           "maTruong": "79763L99",
                           "tenTruong": "LMG Nắng Sớm"
                         },
                         {
                           "maTruong": "79763O03",
                           "tenTruong": "MN Việt Duy"
                         },
                         {
                           "maTruong": "79763O05",
                           "tenTruong": "LMG Tuổi Thơ 2"
                         },
                         {
                           "maTruong": "79763O14",
                           "tenTruong": "LMG Sen Hồng"
                         },
                         {
                           "maTruong": "79763O19",
                           "tenTruong": "NT Phú Hữu"
                         },
                         {
                           "maTruong": "79763O20",
                           "tenTruong": "NT Con Cưng"
                         },
                         {
                           "maTruong": "79763O21",
                           "tenTruong": "LMG Thanh Thảo"
                         },
                         {
                           "maTruong": "79763O22",
                           "tenTruong": "NT Cá Mập Con"
                         },
                         {
                           "maTruong": "79763V01",
                           "tenTruong": "LMG Yêu Trẻ"
                         },
                         {
                           "maTruong": "79763W02",
                           "tenTruong": "MN Nai Vàng"
                         },
                         {
                           "maTruong": "79763W03",
                           "tenTruong": "MN Hoàng Lê"
                         },
                         {
                           "maTruong": "79763W05",
                           "tenTruong": "LMG Thiên Ân"
                         },
                         {
                           "maTruong": "79763W06",
                           "tenTruong": "LMG Gia Hân"
                         },
                         {
                           "maTruong": "79763W09",
                           "tenTruong": "LMG Búp Măng Non"
                         },
                         {
                           "maTruong": "79763W13",
                           "tenTruong": "LMG Nắng Mai"
                         },
                         {
                           "maTruong": "79763W14",
                           "tenTruong": "LMN Đô Rê Mi"
                         },
                         {
                           "maTruong": "79763W15",
                           "tenTruong": "LMN Gấu MiSa"
                         },
                         {
                           "maTruong": "79763W17",
                           "tenTruong": "LMG Thiên Thần"
                         },
                         {
                           "maTruong": "79763W26",
                           "tenTruong": "NT Long Thiện Mỹ"
                         },
                         {
                           "maTruong": "79763W32",
                           "tenTruong": "LMG Hoa Anh Đào"
                         },
                         {
                           "maTruong": "79763W34",
                           "tenTruong": "NT Nam Mỹ 2"
                         },
                         {
                           "maTruong": "79763W36",
                           "tenTruong": "MN Minh Ngọc"
                         },
                         {
                           "maTruong": "79763W37",
                           "tenTruong": "LMG Ngôi Sao Sáng"
                         },
                         {
                           "maTruong": "79763W40",
                           "tenTruong": "LMG Hoa Thiên Phú"
                         },
                         {
                           "maTruong": "79763W43",
                           "tenTruong": "LMG Thỏ Trắng KV2"
                         },
                         {
                           "maTruong": "79763W44",
                           "tenTruong": "NT Ánh Sao 2"
                         },
                         {
                           "maTruong": "79763W45",
                           "tenTruong": "LMG Cô và Bé"
                         },
                         {
                           "maTruong": "79763W47",
                           "tenTruong": "MN Song Như"
                         },
                         {
                           "maTruong": "79763W49",
                           "tenTruong": "NT Mai Anh"
                         },
                         {
                           "maTruong": "79763W51",
                           "tenTruong": "NT Mai Yến"
                         },
                         {
                           "maTruong": "79763W53",
                           "tenTruong": "LMG Bình Minh Nguyên"
                         },
                         {
                           "maTruong": "79763W54",
                           "tenTruong": "LMG Thiên Nga"
                         },
                         {
                           "maTruong": "79763X04",
                           "tenTruong": "MN Ban Mai"
                         },
                         {
                           "maTruong": "7976201107",
                           "tenTruong": "LMN Bàn Chân Nhỏ"
                         },
                         {
                           "maTruong": "7976201108",
                           "tenTruong": "LMN Chú Ngựa Con"
                         },
                         {
                           "maTruong": "7976201109",
                           "tenTruong": "MN Những Con Ong Bận Rộn"
                         },
                         {
                           "maTruong": "7976201110",
                           "tenTruong": "MN Tam Đa"
                         },
                         {
                           "maTruong": "7976201111",
                           "tenTruong": "MN Phước Long B"
                         },
                         {
                           "maTruong": "7976201112",
                           "tenTruong": "LMG Chiếc Ô Xinh"
                         },
                         {
                           "maTruong": "7976203001",
                           "tenTruong": "TH-THCS Kiến Tạo"
                         },
                         {
                           "maTruong": "7976201114",
                           "tenTruong": "MN Việt Úc Khang Điền"
                         },
                         {
                           "maTruong": "7976201115",
                           "tenTruong": "LMN Hiền Tài"
                         },
                         {
                           "maTruong": "7976201116",
                           "tenTruong": "LMN Chú Voi Con"
                         },
                         {
                           "maTruong": "7976201117",
                           "tenTruong": "LMG Không Gian Xanh"
                         },
                         {
                           "maTruong": "7976201118",
                           "tenTruong": "LMG Chú Ong Vàng"
                         },
                         {
                           "maTruong": "7976201119",
                           "tenTruong": "LMG Nai vàng 2"
                         },
                         {
                           "maTruong": "7976201120",
                           "tenTruong": "MN Vinschool Grand Park 1"
                         },
                         {
                           "maTruong": "7976201121",
                           "tenTruong": "LMN Cầu Vồng Trẻ Thơ"
                         },
                         {
                           "maTruong": "7976201122",
                           "tenTruong": "LMN Simba"
                         },
                         {
                           "maTruong": "7976201123",
                           "tenTruong": "LMG Đại Dương Xanh"
                         },
                         {
                           "maTruong": "7976203002",
                           "tenTruong": "THCS Khai Nguyên"
                         },
                         {
                           "maTruong": "7976203003",
                           "tenTruong": "TH và THCS Tâm Tuệ Đức"
                         },
                         {
                           "maTruong": "7976201129",
                           "tenTruong": "LMN Búp Măng non"
                         },
                         {
                           "maTruong": "7976201128",
                           "tenTruong": "LMG Khu Vườn Nhỏ"
                         },
                         {
                           "maTruong": "7976201089",
                           "tenTruong": "MN Rạch Chiếc"
                         },
                         {
                           "maTruong": "7976201090",
                           "tenTruong": "MN An Khánh"
                         },
                         {
                           "maTruong": "7976201113",
                           "tenTruong": "LMN Bạch Tuyết"
                         },
                         {
                           "maTruong": "7976201124",
                           "tenTruong": "LMG Hoa Ưu Đàm"
                         },
                         {
                           "maTruong": "7976201125",
                           "tenTruong": "LMN Quốc Đảo Tí Hon"
                         },
                         {
                           "maTruong": "7976201126",
                           "tenTruong": "LMN Những Tài Năng Châu Á"
                         },
                         {
                           "maTruong": "7976201127",
                           "tenTruong": "LMN Trái Đất Xanh 3"
                         },
                         {
                           "maTruong": "79769016",
                           "tenTruong": "MN Con Đường Tuệ Đức"
                         },
                         {
                           "maTruong": "79769101",
                           "tenTruong": "NT Hạnh Phúc"
                         },
                         {
                           "maTruong": "79769104",
                           "tenTruong": "MN Tuổi Thơ 1"
                         },
                         {
                           "maTruong": "79769105",
                           "tenTruong": "MN Kiến Tạo"
                         },
                         {
                           "maTruong": "79769108",
                           "tenTruong": "LMG Ngọc Diệp"
                         },
                         {
                           "maTruong": "79769110",
                           "tenTruong": "MN Thiên Ân 1"
                         },
                         {
                           "maTruong": "79769111",
                           "tenTruong": "LMG Phượng Hồng KV1"
                         },
                         {
                           "maTruong": "79769113",
                           "tenTruong": "LMG Thanh Bình"
                         },
                         {
                           "maTruong": "79769116",
                           "tenTruong": "MN ABC Trẻ Thơ"
                         },
                         {
                           "maTruong": "79769117",
                           "tenTruong": "LMG Hoa Mai KV1"
                         },
                         {
                           "maTruong": "79769205",
                           "tenTruong": "MN Cỏ Non"
                         },
                         {
                           "maTruong": "79769206",
                           "tenTruong": "MN Chào Bạn Nhỏ"
                         },
                         {
                           "maTruong": "79769207",
                           "tenTruong": "LMG Cát Minh"
                         },
                         {
                           "maTruong": "79769209",
                           "tenTruong": "MN Hạt Đậu Nhỏ-Citi Bella 1"
                         },
                         {
                           "maTruong": "79769251",
                           "tenTruong": "MN Ngôi Nhà Ánh Dương"
                         },
                         {
                           "maTruong": "79769301",
                           "tenTruong": "MN 19/5"
                         },
                         {
                           "maTruong": "79769302",
                           "tenTruong": "MN Hoa Hồng 1"
                         },
                         {
                           "maTruong": "79769303",
                           "tenTruong": "MN Bình An"
                         },
                         {
                           "maTruong": "79769304",
                           "tenTruong": "MN Bình Trưng Đông"
                         },
                         {
                           "maTruong": "79769305",
                           "tenTruong": "MN Cát Lái"
                         },
                         {
                           "maTruong": "79769306",
                           "tenTruong": "MN Hoạ Mi"
                         },
                         {
                           "maTruong": "79769307",
                           "tenTruong": "MN Măng Non"
                         },
                         {
                           "maTruong": "79769308",
                           "tenTruong": "MN Thảo Điền"
                         },
                         {
                           "maTruong": "79769310",
                           "tenTruong": "MN Vườn Hồng"
                         },
                         {
                           "maTruong": "79769311",
                           "tenTruong": "MN Bình Minh 1"
                         },
                         {
                           "maTruong": "79769312",
                           "tenTruong": "MN Tân Đông"
                         },
                         {
                           "maTruong": "79769313",
                           "tenTruong": "MN Sen Hồng 1"
                         },
                         {
                           "maTruong": "79769314",
                           "tenTruong": "MN An Phú"
                         },
                         {
                           "maTruong": "79769315",
                           "tenTruong": "MN Thạnh Mỹ Lợi"
                         },
                         {
                           "maTruong": "79769321",
                           "tenTruong": "MN Ánh Cầu Vồng"
                         },
                         {
                           "maTruong": "79769323",
                           "tenTruong": "MN Chân Xinh"
                         },
                         {
                           "maTruong": "79769324",
                           "tenTruong": "MN Sơn Ca 1"
                         },
                         {
                           "maTruong": "79769325",
                           "tenTruong": "MN Montessori"
                         },
                         {
                           "maTruong": "79769327",
                           "tenTruong": "MN Trẻ Em Sài Gòn"
                         },
                         {
                           "maTruong": "79769328",
                           "tenTruong": "MN Khôi Nguyên"
                         },
                         {
                           "maTruong": "79769330",
                           "tenTruong": "MN Thần Đồng"
                         },
                         {
                           "maTruong": "79769331",
                           "tenTruong": "MN Vành Khuyên 1"
                         },
                         {
                           "maTruong": "79769333",
                           "tenTruong": "MN Đông Sài Gòn"
                         },
                         {
                           "maTruong": "79769334",
                           "tenTruong": "MN Miền Trẻ Thơ 1 (KV1)"
                         },
                         {
                           "maTruong": "79769336",
                           "tenTruong": "MN Én Nhỏ"
                         },
                         {
                           "maTruong": "79769337",
                           "tenTruong": "MN Thái Bình"
                         },
                         {
                           "maTruong": "79769338",
                           "tenTruong": "MN Chuột Túi Thông Minh"
                         },
                         {
                           "maTruong": "79769339",
                           "tenTruong": "MN Nụ Cười Hồng 1"
                         },
                         {
                           "maTruong": "79769340",
                           "tenTruong": "MN Chú Ong Vui Vẻ"
                         },
                         {
                           "maTruong": "79769341",
                           "tenTruong": "MN Sao Việt"
                         },
                         {
                           "maTruong": "79769343",
                           "tenTruong": "MN Mùa Xuân Aurora"
                         },
                         {
                           "maTruong": "79769345",
                           "tenTruong": "MN Yêu Con"
                         },
                         {
                           "maTruong": "79769346",
                           "tenTruong": "MN Học Viện UP"
                         },
                         {
                           "maTruong": "79769347",
                           "tenTruong": "MN Úc Châu"
                         },
                         {
                           "maTruong": "79769352",
                           "tenTruong": "MN An Bình"
                         },
                         {
                           "maTruong": "79769354",
                           "tenTruong": "MN Hoa Anh Đào An Phú"
                         },
                         {
                           "maTruong": "79769355",
                           "tenTruong": "MN Thành Phố Tuổi Thơ - Thảo Điền"
                         },
                         {
                           "maTruong": "79769356",
                           "tenTruong": "MN Mỹ Đức"
                         },
                         {
                           "maTruong": "79769357",
                           "tenTruong": "MN Hoa Hướng Dương 1"
                         },
                         {
                           "maTruong": "79769359",
                           "tenTruong": "MN Mặt Trời Nhỏ 1 KV1"
                         },
                         {
                           "maTruong": "79769360",
                           "tenTruong": "MN Hoa Diên Vĩ  - Thảo Điền"
                         },
                         {
                           "maTruong": "79769361",
                           "tenTruong": "MN Tuổi Thơ Kỳ Diệu"
                         },
                         {
                           "maTruong": "79769362",
                           "tenTruong": "MN Đông Bắc"
                         },
                         {
                           "maTruong": "79769363",
                           "tenTruong": "MN Thiên Nhiên"
                         },
                         {
                           "maTruong": "79769365",
                           "tenTruong": "MN Thành Phố Tuổi Hoa"
                         },
                         {
                           "maTruong": "79769366",
                           "tenTruong": "MN Vịt Con"
                         },
                         {
                           "maTruong": "79769367",
                           "tenTruong": "MN Hành Tinh Trẻ"
                         },
                         {
                           "maTruong": "79769368",
                           "tenTruong": "MN Cỏ 3 Lá"
                         },
                         {
                           "maTruong": "79769369",
                           "tenTruong": "MN Tre Xanh"
                         },
                         {
                           "maTruong": "79769370",
                           "tenTruong": "MN Chuồn Chuồn Kim"
                         },
                         {
                           "maTruong": "79769371",
                           "tenTruong": "MN Nhà Bé Vui"
                         },
                         {
                           "maTruong": "79769372",
                           "tenTruong": "MN Nhà Của Bé"
                         },
                         {
                           "maTruong": "79769373",
                           "tenTruong": "MN Bé Ong Sài Gòn"
                         },
                         {
                           "maTruong": "79769390",
                           "tenTruong": "MN Tài Năng Tuổi Thơ"
                         },
                         {
                           "maTruong": "79769399",
                           "tenTruong": "MN Hoa Sen 1"
                         },
                         {
                           "maTruong": "79769401",
                           "tenTruong": "TH An Khánh"
                         },
                         {
                           "maTruong": "79769403",
                           "tenTruong": "TH An Phú"
                         },
                         {
                           "maTruong": "79769404",
                           "tenTruong": "TH Giồng Ông Tố"
                         },
                         {
                           "maTruong": "79769405",
                           "tenTruong": "TH Huỳnh Văn Ngỡi"
                         },
                         {
                           "maTruong": "79769406",
                           "tenTruong": "TH Mỹ Thủy"
                         },
                         {
                           "maTruong": "79769407",
                           "tenTruong": "TH Nguyễn Văn Trỗi"
                         },
                         {
                           "maTruong": "79769408",
                           "tenTruong": "TH Thạnh Mỹ lợi"
                         },
                         {
                           "maTruong": "79769409",
                           "tenTruong": "TH Nguyễn Hiền"
                         },
                         {
                           "maTruong": "79769410",
                           "tenTruong": "TH Lương Thế Vinh 1"
                         },
                         {
                           "maTruong": "79769411",
                           "tenTruong": "TH và THCS Tuệ Đức"
                         },
                         {
                           "maTruong": "79769412",
                           "tenTruong": "TH An Bình"
                         },
                         {
                           "maTruong": "79769413",
                           "tenTruong": "TH Bình Trưng Đông"
                         },
                         {
                           "maTruong": "79769414",
                           "tenTruong": "TH và THCS Kiến Tạo"
                         },
                         {
                           "maTruong": "79769477",
                           "tenTruong": "NT Hoa Sữa"
                         },
                         {
                           "maTruong": "79769501",
                           "tenTruong": "THCS An Phú"
                         },
                         {
                           "maTruong": "79769502",
                           "tenTruong": "THCS Giồng Ông Tố"
                         },
                         {
                           "maTruong": "79769503",
                           "tenTruong": "THCS Nguyễn Văn Trỗi"
                         },
                         {
                           "maTruong": "79769504",
                           "tenTruong": "THCS Thạnh Mỹ Lợi"
                         },
                         {
                           "maTruong": "79769506",
                           "tenTruong": "THCS Lương Định Của"
                         },
                         {
                           "maTruong": "79769507",
                           "tenTruong": "THCS Bình An"
                         },
                         {
                           "maTruong": "79769508",
                           "tenTruong": "THCS Nguyễn Thị Định"
                         },
                         {
                           "maTruong": "79769509",
                           "tenTruong": "THCS Trần Quốc Toản 1"
                         },
                         {
                           "maTruong": "79769510",
                           "tenTruong": "THCS Cát Lái"
                         },
                         {
                           "maTruong": "79769514",
                           "tenTruong": "TH eSchool"
                         },
                         {
                           "maTruong": "79769701",
                           "tenTruong": "MN Việt Úc"
                         },
                         {
                           "maTruong": "79769710",
                           "tenTruong": "MN Vườn Trẻ Thơ"
                         },
                         {
                           "maTruong": "79769712",
                           "tenTruong": "MN Vương Quốc KiKo"
                         },
                         {
                           "maTruong": "79769718",
                           "tenTruong": "MN Tuệ Đức KV1"
                         },
                         {
                           "maTruong": "79769801",
                           "tenTruong": "MN Vùng Đất Tuổi Thơ"
                         },
                         {
                           "maTruong": "79769802",
                           "tenTruong": "LMN Hoa Xuyến Chi"
                         },
                         {
                           "maTruong": "79769803",
                           "tenTruong": "MN Bước Chân Tuổi Thơ"
                         },
                         {
                           "maTruong": "79769814",
                           "tenTruong": "LMN Tuổi Thần Tiên"
                         },
                         {
                           "maTruong": "79769815",
                           "tenTruong": "NT Bảo Hân"
                         },
                         {
                           "maTruong": "79769816",
                           "tenTruong": "NT Ngôi Sao Nhỏ"
                         },
                         {
                           "maTruong": "79769817",
                           "tenTruong": "MN Banh và Bi"
                         },
                         {
                           "maTruong": "79769819",
                           "tenTruong": "NT Mầm Non Xanh"
                         },
                         {
                           "maTruong": "79769822",
                           "tenTruong": "MN Global Ecokids"
                         },
                         {
                           "maTruong": "79769826",
                           "tenTruong": "MN An Nhiên"
                         },
                         {
                           "maTruong": "79769827",
                           "tenTruong": "NT Gấu Misa"
                         },
                         {
                           "maTruong": "79769829",
                           "tenTruong": "LMG Ngôi Sao Sáng KV1"
                         },
                         {
                           "maTruong": "79769831",
                           "tenTruong": "LMG Mai Cham"
                         },
                         {
                           "maTruong": "79769833",
                           "tenTruong": "MN Hướng Dương Sài Gòn"
                         },
                         {
                           "maTruong": "79769835",
                           "tenTruong": "MN Lâm An"
                         },
                         {
                           "maTruong": "79769836",
                           "tenTruong": "MN Trẻ Em Alpha"
                         },
                         {
                           "maTruong": "79769837",
                           "tenTruong": "MN Ngôi Nhà Nhỏ"
                         },
                         {
                           "maTruong": "79769838",
                           "tenTruong": "MN Ngôi Sao Tuổi Thơ"
                         },
                         {
                           "maTruong": "79769839",
                           "tenTruong": "MN Vương Quốc Tí Hon KV1"
                         },
                         {
                           "maTruong": "79769988",
                           "tenTruong": "MN Tuổi Hồng Linh Đông"
                         },
                         {
                           "maTruong": "79769990",
                           "tenTruong": "NT Hồng Hà"
                         },
                         {
                           "maTruong": "79769999",
                           "tenTruong": "MN Lá Phong Việt"
                         },
                         {
                           "maTruong": "79769L10",
                           "tenTruong": "NT Ngôi Sao Xanh KV1"
                         },
                         {
                           "maTruong": "79769L13",
                           "tenTruong": "NT Hoa Yêu Thương"
                         },
                         {
                           "maTruong": "79769L14",
                           "tenTruong": "NT Nhà Cà Rốt"
                         },
                         {
                           "maTruong": "79769L15",
                           "tenTruong": "LMG Trẻ Tài Năng"
                         },
                         {
                           "maTruong": "79769L83",
                           "tenTruong": "MN Nụ Cười Xinh"
                         },
                         {
                           "maTruong": "79769L87",
                           "tenTruong": "LMG Hoa Phượng"
                         },
                         {
                           "maTruong": "79769L88",
                           "tenTruong": "MN 1 Tháng 6-Cát Lái"
                         },
                         {
                           "maTruong": "79769L89",
                           "tenTruong": "LMG Hồng Nhung"
                         },
                         {
                           "maTruong": "79769L90",
                           "tenTruong": "LMG Thỏ Trắng KV1"
                         },
                         {
                           "maTruong": "79769L91",
                           "tenTruong": "NT Việt Anh"
                         },
                         {
                           "maTruong": "79769W03",
                           "tenTruong": "MN Ngôi Sao 1"
                         },
                         {
                           "maTruong": "79769W08",
                           "tenTruong": "LMG Gia An"
                         },
                         {
                           "maTruong": "79769W11",
                           "tenTruong": "MN Bầu Trời Xanh 1"
                         },
                         {
                           "maTruong": "79769W12",
                           "tenTruong": "MN Mặt Trời Hồng - Cát Lái"
                         },
                         {
                           "maTruong": "79769W15",
                           "tenTruong": "MN Những Thiên Thần Nhỏ KV1"
                         },
                         {
                           "maTruong": "79769W21",
                           "tenTruong": "LMG Rạng Đông"
                         },
                         {
                           "maTruong": "79769W26",
                           "tenTruong": "LMG Ánh Sao KV1"
                         },
                         {
                           "maTruong": "7976AS12",
                           "tenTruong": "MN Hoa Mặt Trời"
                         },
                         {
                           "maTruong": "7976201154",
                           "tenTruong": "LMN Ngôi nhà Ong xinh"
                         },
                         {
                           "maTruong": "7976201155",
                           "tenTruong": "LMN Tổ Chim"
                         },
                         {
                           "maTruong": "7976201157",
                           "tenTruong": "LMG Khu Vườn Tuổi Thơ"
                         },
                         {
                           "maTruong": "7976201158",
                           "tenTruong": "LMN Cá Chép Đỏ Long Bình"
                         },
                         {
                           "maTruong": "7976201159",
                           "tenTruong": "LMN Cầu Vồng Bé Thơ"
                         },
                         {
                           "maTruong": "7976201152",
                           "tenTruong": "LMN Tài Năng Tí Hon"
                         },
                         {
                           "maTruong": "7976201153",
                           "tenTruong": "LMN Tài Năng"
                         },
                         {
                           "maTruong": "7976201135",
                           "tenTruong": "LMG Thiên Thần Tý Hon"
                         },
                         {
                           "maTruong": "7976201130",
                           "tenTruong": "LMN Không Gian Trẻ Thơ"
                         },
                         {
                           "maTruong": "7976201131",
                           "tenTruong": "LMN Tri Thức"
                         },
                         {
                           "maTruong": "7976201132",
                           "tenTruong": "LMN Xanh An Phú"
                         },
                         {
                           "maTruong": "7976201133",
                           "tenTruong": "LMN Ngôi Nhà Bé Con"
                         },
                         {
                           "maTruong": "7976201134",
                           "tenTruong": "LMN Hoa Bách Hợp"
                         },
                         {
                           "maTruong": "7976203004",
                           "tenTruong": "THCS Ngôi Trường Em Thạnh Mỹ Lợi"
                         },
                         {
                           "maTruong": "7976201136",
                           "tenTruong": "LMN Trí Việt"
                         },
                         {
                           "maTruong": "7976201137",
                           "tenTruong": "LMN Tương Lai"
                         },
                         {
                           "maTruong": "7976201138",
                           "tenTruong": "LMN Việt - Sing"
                         },
                         {
                           "maTruong": "7976201139",
                           "tenTruong": "LMN Sao Vui Vạn Phúc"
                         },
                         {
                           "maTruong": "7976201140",
                           "tenTruong": "LMN Cánh Diều"
                         },
                         {
                           "maTruong": "7976201141",
                           "tenTruong": "LMG Chú Gà Con"
                         },
                         {
                           "maTruong": "7976201142",
                           "tenTruong": "MN Đồi Xanh"
                         },
                         {
                           "maTruong": "7976201143",
                           "tenTruong": "MN Toàn Cầu"
                         },
                         {
                           "maTruong": "7976201144",
                           "tenTruong": "LMN Bánh Táo"
                         },
                         {
                           "maTruong": "7976201145",
                           "tenTruong": "LMN Ngôi Nhà Ánh Trăng"
                         },
                         {
                           "maTruong": "7976201146",
                           "tenTruong": "LMN Ngôi Nhà Sao Sáng"
                         },
                         {
                           "maTruong": "7976201147",
                           "tenTruong": "LMN Việt Đức"
                         },
                         {
                           "maTruong": "7976201148",
                           "tenTruong": "LMN Nanana"
                         },
                         {
                           "maTruong": "7976201149",
                           "tenTruong": "LMN Vương Quốc Tuổi Thơ"
                         },
                         {
                           "maTruong": "7976201150",
                           "tenTruong": "LMN Đôi Cánh Nhỏ"
                         },
                         {
                           "maTruong": "7976201151",
                           "tenTruong": "LMN Mặt Trời Nhỏ"
                         },
                         {
                           "maTruong": "7976201160",
                           "tenTruong": "LMN Minh Tú"
                         },
                         {
                           "maTruong": "7976201161",
                           "tenTruong": "LMN Sao Vui Vạn Phúc"
                         },
                         {
                           "maTruong": "7976201162",
                           "tenTruong": "LMN Trẻ Thơ"
                         }
                       ]
                     },
                     {
                       "maPhong": "765",
                       "tenPhong": "Quận Bình Thạnh",
                       "truongData": [
                         {
                           "maTruong": "79765423",
                           "tenTruong": "TH Bạch Đằng"
                         },
                         {
                           "maTruong": "79765406",
                           "tenTruong": "TH Bế Văn Đàn"
                         },
                         {
                           "maTruong": "79765408",
                           "tenTruong": "TH Bình Hoà"
                         },
                         {
                           "maTruong": "79765410",
                           "tenTruong": "TH Bình Lợi Trung"
                         },
                         {
                           "maTruong": "79765429",
                           "tenTruong": "TH Bình Quới Tây"
                         },
                         {
                           "maTruong": "79765419",
                           "tenTruong": "TH Chu Văn An"
                         },
                         {
                           "maTruong": "79765417",
                           "tenTruong": "TH Cửu Long"
                         },
                         {
                           "maTruong": "79765418",
                           "tenTruong": "TH Đống Đa"
                         },
                         {
                           "maTruong": "79765403",
                           "tenTruong": "TH Hà Huy Tập"
                         },
                         {
                           "maTruong": "79765413",
                           "tenTruong": "TH Hồng Hà"
                         },
                         {
                           "maTruong": "79765402",
                           "tenTruong": "TH Lam Sơn"
                         },
                         {
                           "maTruong": "79765412",
                           "tenTruong": "TH Lê Đình Chinh"
                         },
                         {
                           "maTruong": "79765428",
                           "tenTruong": "TH Ngôi Nhà Thông Thái"
                         },
                         {
                           "maTruong": "79765405",
                           "tenTruong": "TH Nguyễn Bá Ngọc"
                         },
                         {
                           "maTruong": "79765411",
                           "tenTruong": "TH Nguyễn Đình Chiểu"
                         },
                         {
                           "maTruong": "79765416",
                           "tenTruong": "TH Nguyễn Trọng Tuyển"
                         },
                         {
                           "maTruong": "79765407",
                           "tenTruong": "TH Phan Văn Trị"
                         },
                         {
                           "maTruong": "79765414",
                           "tenTruong": "TH Phù Đổng"
                         },
                         {
                           "maTruong": "79765420",
                           "tenTruong": "TH Tầm Vu"
                         },
                         {
                           "maTruong": "79765421",
                           "tenTruong": "TH Thanh Đa"
                         },
                         {
                           "maTruong": "79765415",
                           "tenTruong": "TH Thạnh Mỹ Tây"
                         },
                         {
                           "maTruong": "79765401",
                           "tenTruong": "TH Tô Vĩnh Diện"
                         },
                         {
                           "maTruong": "79765409",
                           "tenTruong": "TH Trần Quang Vinh"
                         },
                         {
                           "maTruong": "79765426",
                           "tenTruong": "TH Việt Mỹ"
                         },
                         {
                           "maTruong": "79765404",
                           "tenTruong": "TH Yên Thế"
                         },
                         {
                           "maTruong": "79765511",
                           "tenTruong": "THCS  Trương Công Định"
                         },
                         {
                           "maTruong": "79765512",
                           "tenTruong": "THCS BC Yên Thế"
                         },
                         {
                           "maTruong": "79765505",
                           "tenTruong": "THCS Bình Lợi Trung"
                         },
                         {
                           "maTruong": "79765515",
                           "tenTruong": "THCS Bình Quới Tây"
                         },
                         {
                           "maTruong": "79765514",
                           "tenTruong": "THCS Cù Chính Lan"
                         },
                         {
                           "maTruong": "79765508",
                           "tenTruong": "THCS Cửu Long"
                         },
                         {
                           "maTruong": "79765513",
                           "tenTruong": "THCS Điện Biên"
                         },
                         {
                           "maTruong": "79765509",
                           "tenTruong": "THCS Đống Đa"
                         },
                         {
                           "maTruong": "79765502",
                           "tenTruong": "THCS Hà Huy Tập"
                         },
                         {
                           "maTruong": "79765501",
                           "tenTruong": "THCS Lam Sơn"
                         },
                         {
                           "maTruong": "79765506",
                           "tenTruong": "THCS Lê Văn Tám"
                         },
                         {
                           "maTruong": "79765507",
                           "tenTruong": "THCS Phú Mỹ"
                         },
                         {
                           "maTruong": "79765504",
                           "tenTruong": "THCS Rạng Đông"
                         },
                         {
                           "maTruong": "79765510",
                           "tenTruong": "THCS Thanh Đa"
                         },
                         {
                           "maTruong": "79765503",
                           "tenTruong": "Trường THCS Nguyễn Văn Bé"
                         },
                         {
                           "maTruong": "79000874",
                           "tenTruong": "TH, THCS & THPT Vinschool"
                         },
                         {
                           "maTruong": "790007B9",
                           "tenTruong": "TiH, THCS và THPT Mùa Xuân"
                         },
                         {
                           "maTruong": "79765207",
                           "tenTruong": "Mẫu Giáo Thiên Thần"
                         },
                         {
                           "maTruong": "79765204",
                           "tenTruong": "MG Ánh Sáng"
                         },
                         {
                           "maTruong": "79765202",
                           "tenTruong": "MG Bông Hồng"
                         },
                         {
                           "maTruong": "79765206",
                           "tenTruong": "MG Hoa Anh Đào"
                         },
                         {
                           "maTruong": "79765201",
                           "tenTruong": "MG Mai Hoa"
                         },
                         {
                           "maTruong": "79765203",
                           "tenTruong": "MG Minh Tâm"
                         },
                         {
                           "maTruong": "79765205",
                           "tenTruong": "MG Việt Anh"
                         },
                         {
                           "maTruong": "79765343",
                           "tenTruong": "Học Viện sài Gòn"
                         },
                         {
                           "maTruong": "79765307",
                           "tenTruong": "Mầm Non 11A"
                         },
                         {
                           "maTruong": "79765320",
                           "tenTruong": "Mầm Non 25A"
                         },
                         {
                           "maTruong": "79765330",
                           "tenTruong": "Mầm non Bến Thànnh"
                         },
                         {
                           "maTruong": "79765209",
                           "tenTruong": "Mầm non Họa Mi"
                         },
                         {
                           "maTruong": "79765332",
                           "tenTruong": "Mầm Non Kim Đồng"
                         },
                         {
                           "maTruong": "79765336",
                           "tenTruong": "Mầm Non Việt Úc"
                         },
                         {
                           "maTruong": "79765301",
                           "tenTruong": "MN 1"
                         },
                         {
                           "maTruong": "79765308",
                           "tenTruong": "MN 11B"
                         },
                         {
                           "maTruong": "79765309",
                           "tenTruong": "MN 12"
                         },
                         {
                           "maTruong": "79765310",
                           "tenTruong": "MN 13"
                         },
                         {
                           "maTruong": "79765311",
                           "tenTruong": "MN 14"
                         },
                         {
                           "maTruong": "79765312",
                           "tenTruong": "MN 15"
                         },
                         {
                           "maTruong": "79765313",
                           "tenTruong": "MN 15 B"
                         },
                         {
                           "maTruong": "79765314",
                           "tenTruong": "MN 17"
                         },
                         {
                           "maTruong": "79765315",
                           "tenTruong": "MN 19"
                         },
                         {
                           "maTruong": "79765302",
                           "tenTruong": "MN 2"
                         },
                         {
                           "maTruong": "79765329",
                           "tenTruong": "MN 2/9"
                         },
                         {
                           "maTruong": "79765316",
                           "tenTruong": "MN 21"
                         },
                         {
                           "maTruong": "79765317",
                           "tenTruong": "MN 22"
                         },
                         {
                           "maTruong": "79765318",
                           "tenTruong": "MN 24A"
                         },
                         {
                           "maTruong": "79765319",
                           "tenTruong": "MN 24B"
                         },
                         {
                           "maTruong": "79765321",
                           "tenTruong": "MN 25B"
                         },
                         {
                           "maTruong": "79765322",
                           "tenTruong": "MN 26"
                         },
                         {
                           "maTruong": "79765323",
                           "tenTruong": "MN 27"
                         },
                         {
                           "maTruong": "79765324",
                           "tenTruong": "MN 28"
                         },
                         {
                           "maTruong": "79765303",
                           "tenTruong": "MN 3"
                         },
                         {
                           "maTruong": "79765304",
                           "tenTruong": "MN 5"
                         },
                         {
                           "maTruong": "79765305",
                           "tenTruong": "MN 6"
                         },
                         {
                           "maTruong": "79765325",
                           "tenTruong": "MN 7A"
                         },
                         {
                           "maTruong": "79765306",
                           "tenTruong": "MN 7B"
                         },
                         {
                           "maTruong": "79765334",
                           "tenTruong": "MN An pha"
                         },
                         {
                           "maTruong": "79765337",
                           "tenTruong": "MN Ánh Sao"
                         },
                         {
                           "maTruong": "79765338",
                           "tenTruong": "MN Chú Bồ Nông"
                         },
                         {
                           "maTruong": "79765326",
                           "tenTruong": "MN Hoa Hồng"
                         },
                         {
                           "maTruong": "79765344",
                           "tenTruong": "MN Hồng Nhi"
                         },
                         {
                           "maTruong": "79765345",
                           "tenTruong": "MN Khu Vườn Trí tuệ"
                         },
                         {
                           "maTruong": "79765327",
                           "tenTruong": "MN Mai Linh"
                         },
                         {
                           "maTruong": "79765333",
                           "tenTruong": "MN Mai Thôn"
                         },
                         {
                           "maTruong": "79765339",
                           "tenTruong": "MN Thành phố Tuổi Thơ"
                         },
                         {
                           "maTruong": "79765328",
                           "tenTruong": "MN Thiên Anh"
                         },
                         {
                           "maTruong": "79765335",
                           "tenTruong": "MN Việt Mỹ Úc"
                         },
                         {
                           "maTruong": "79765341",
                           "tenTruong": "TRƯỜNG MẦM NON MỶ ĐỨC"
                         },
                         {
                           "maTruong": "79765331",
                           "tenTruong": "Trường Mầm non Văn Thánh Bắc"
                         },
                         {
                           "maTruong": "79765342",
                           "tenTruong": "Vinschool"
                         },
                         {
                           "maTruong": "79765L51",
                           "tenTruong": "Trường MN 3 Ngọn Nến"
                         },
                         {
                           "maTruong": "79765L53",
                           "tenTruong": "Hạnh Phúc"
                         },
                         {
                           "maTruong": "79765X24",
                           "tenTruong": "Lớp Mẫu giáo Nai Bi"
                         },
                         {
                           "maTruong": "79765L55",
                           "tenTruong": "Lớp MG Mỹ Việt Úc"
                         },
                         {
                           "maTruong": "79765W31",
                           "tenTruong": "Lớp MGTT Mặt Trời Mới"
                         },
                         {
                           "maTruong": "79765L45",
                           "tenTruong": "Lóp MN Apolo P12"
                         },
                         {
                           "maTruong": "79765L56",
                           "tenTruong": "Lớp MN Gấu Trúc"
                         },
                         {
                           "maTruong": "79765X04",
                           "tenTruong": "Lớp MNTT 1 tháng 6"
                         },
                         {
                           "maTruong": "79765X05",
                           "tenTruong": "Lớp MNTT 26A"
                         },
                         {
                           "maTruong": "79765X06",
                           "tenTruong": "Lớp MNTT 27C"
                         },
                         {
                           "maTruong": "79765W09",
                           "tenTruong": "Lớp MNTT 7 Sắc Cầu Vòng"
                         },
                         {
                           "maTruong": "79765W32",
                           "tenTruong": "Lớp MNTT B1 Trường Sa"
                         },
                         {
                           "maTruong": "79765X11",
                           "tenTruong": "Lớp MNTT Búp Bê"
                         },
                         {
                           "maTruong": "79765X39",
                           "tenTruong": "Lớp MNTT Cà Rốt"
                         },
                         {
                           "maTruong": "79765X12",
                           "tenTruong": "Lớp MNTT Hoa Cúc"
                         },
                         {
                           "maTruong": "79765X13",
                           "tenTruong": "Lớp MNTT Hoa Cúc Trắng"
                         },
                         {
                           "maTruong": "79765X14",
                           "tenTruong": "Lớp MNTT Hoa Mai 1"
                         },
                         {
                           "maTruong": "79765W35",
                           "tenTruong": "Lớp MNTT Hoa Mai 2"
                         },
                         {
                           "maTruong": "79765W39",
                           "tenTruong": "Lớp MNTT Hoa Ngọc Lan"
                         },
                         {
                           "maTruong": "79765X17",
                           "tenTruong": "Lớp MNTT Hoa Sữa"
                         },
                         {
                           "maTruong": "79765W33",
                           "tenTruong": "Lớp MNTT Hoa Tulip"
                         },
                         {
                           "maTruong": "79765X18",
                           "tenTruong": "Lớp MNTT Hoàng Anh"
                         },
                         {
                           "maTruong": "79765W16",
                           "tenTruong": "Lớp MNTT Hồng Ân"
                         },
                         {
                           "maTruong": "79765X19",
                           "tenTruong": "Lớp MNTT Hướng Dương"
                         },
                         {
                           "maTruong": "79765X21",
                           "tenTruong": "Lớp MNTT Lôc An"
                         },
                         {
                           "maTruong": "79765W38",
                           "tenTruong": "Lớp MNTT Mầm Xanh"
                         },
                         {
                           "maTruong": "79765X22",
                           "tenTruong": "Lớp MNTT Mi Sa"
                         },
                         {
                           "maTruong": "79765W37",
                           "tenTruong": "Lớp MNTT Mickey"
                         },
                         {
                           "maTruong": "79765W17",
                           "tenTruong": "Lớp MNTT Nắng Ban Mai"
                         },
                         {
                           "maTruong": "79765W21",
                           "tenTruong": "Lớp MNTT Nắng Hồng"
                         },
                         {
                           "maTruong": "79765L46",
                           "tenTruong": "Lớp MNTT Nêmô"
                         },
                         {
                           "maTruong": "79765X25",
                           "tenTruong": "Lớp MNTT Ngọc Bảo"
                         },
                         {
                           "maTruong": "79765W13",
                           "tenTruong": "Lớp Mẫu Giáo Ngọc Thanh"
                         },
                         {
                           "maTruong": "79765W19",
                           "tenTruong": "Lớp MNTT Ngôi Nhà Trẻ Thơ"
                         },
                         {
                           "maTruong": "79765W30",
                           "tenTruong": "Trường Mầm Non Ngôi Sao"
                         },
                         {
                           "maTruong": "79765W15",
                           "tenTruong": "Lớp MNTT Ngôi Sao Nhí"
                         },
                         {
                           "maTruong": "79765W27",
                           "tenTruong": "Nhóm trẻ Nhà Bé Yêu"
                         },
                         {
                           "maTruong": "79765W28",
                           "tenTruong": "Lớp Mẫu Giáo Nhà Bé Yêu"
                         },
                         {
                           "maTruong": "79765W40",
                           "tenTruong": "Lớp MNTT Nhà Của Bé"
                         },
                         {
                           "maTruong": "79765X26",
                           "tenTruong": "Lớp MNTT Nhật Vy"
                         },
                         {
                           "maTruong": "79765X27",
                           "tenTruong": "Lớp MNTT Phương Dung"
                         },
                         {
                           "maTruong": "79765X28",
                           "tenTruong": "Lớp MNTT Sao Mai"
                         },
                         {
                           "maTruong": "79765X29",
                           "tenTruong": "Lớp MNTT Sơn Ca"
                         },
                         {
                           "maTruong": "79765X03",
                           "tenTruong": "Lop MNTT Song Ngọc"
                         },
                         {
                           "maTruong": "79765X30",
                           "tenTruong": "Lớp MNTT Su Su"
                         },
                         {
                           "maTruong": "79765X31",
                           "tenTruong": "Lớp MNTT Thanh Xuân"
                         },
                         {
                           "maTruong": "79765X32",
                           "tenTruong": "Lớp MNTT Thiên Ân"
                         },
                         {
                           "maTruong": "79765W36",
                           "tenTruong": "Lớp MNTT Thiên Phúc"
                         },
                         {
                           "maTruong": "79765W22",
                           "tenTruong": "Nhóm trẻ Thiên Thần Nhỏ"
                         },
                         {
                           "maTruong": "79765X34",
                           "tenTruong": "Lớp MNTT Tuổi Hồng"
                         },
                         {
                           "maTruong": "79765X37",
                           "tenTruong": "Lớp MNTT Tuổi Thơ P25"
                         },
                         {
                           "maTruong": "79765X35",
                           "tenTruong": "Lớp MNTT Tuổi Thơ P5"
                         },
                         {
                           "maTruong": "79765W25",
                           "tenTruong": "Lớp MNTT Việt Đức"
                         },
                         {
                           "maTruong": "79765X38",
                           "tenTruong": "Lớp MNTT Việt Nhi"
                         },
                         {
                           "maTruong": "79765W06",
                           "tenTruong": "MG Hoa Lan"
                         },
                         {
                           "maTruong": "79765211",
                           "tenTruong": "MG Mầm Xanh 2"
                         },
                         {
                           "maTruong": "79765L58",
                           "tenTruong": "Mg Múp Míp"
                         },
                         {
                           "maTruong": "79765L14",
                           "tenTruong": "MG Vườn Yêu Thương"
                         },
                         {
                           "maTruong": "79765O09",
                           "tenTruong": "MGTT 14B"
                         },
                         {
                           "maTruong": "79765W01",
                           "tenTruong": "MGTT 17A"
                         },
                         {
                           "maTruong": "79765W02",
                           "tenTruong": "MGTT 17B"
                         },
                         {
                           "maTruong": "79765X01",
                           "tenTruong": "MGTT Mai Khôi"
                         },
                         {
                           "maTruong": "79765W05",
                           "tenTruong": "MGTT Phước An"
                         },
                         {
                           "maTruong": "79765W07",
                           "tenTruong": "MGTT Thanh Tâm"
                         },
                         {
                           "maTruong": "79765X08",
                           "tenTruong": "MGTT Việt Mỹ"
                         },
                         {
                           "maTruong": "79765L48",
                           "tenTruong": "MNTT Hoa SIm"
                         },
                         {
                           "maTruong": "79765L47",
                           "tenTruong": "MNTT Hoa Thủy Tiên"
                         },
                         {
                           "maTruong": "79765L54",
                           "tenTruong": "MNTT Sắc màu"
                         },
                         {
                           "maTruong": "79765W42",
                           "tenTruong": "MNTT Sao Vàng"
                         },
                         {
                           "maTruong": "79765L52",
                           "tenTruong": "MNTT Trúc anh"
                         },
                         {
                           "maTruong": "79765W41",
                           "tenTruong": "MNTT Tuổi Thần Tiên"
                         },
                         {
                           "maTruong": "79765L57",
                           "tenTruong": "nhóm trẻ Hoa Sen"
                         },
                         {
                           "maTruong": "79765L12",
                           "tenTruong": "NHóm trẻ Mickey 2"
                         },
                         {
                           "maTruong": "79765O18",
                           "tenTruong": "NT 14C"
                         },
                         {
                           "maTruong": "79765O01",
                           "tenTruong": "NT Bé By"
                         },
                         {
                           "maTruong": "79765L15",
                           "tenTruong": "NT Hạt Đậu Nhỏ"
                         },
                         {
                           "maTruong": "79765O15",
                           "tenTruong": "NT Hoa Nắng"
                         },
                         {
                           "maTruong": "79765O03",
                           "tenTruong": "NT Hoa Quyên"
                         },
                         {
                           "maTruong": "79765O11",
                           "tenTruong": "NT Lan Anh"
                         },
                         {
                           "maTruong": "79765L13",
                           "tenTruong": "NT Mầm Xanh 2"
                         },
                         {
                           "maTruong": "79765L16",
                           "tenTruong": "NT Nam Việt"
                         },
                         {
                           "maTruong": "79765O12",
                           "tenTruong": "NT Thương Thương"
                         },
                         {
                           "maTruong": "79765L49",
                           "tenTruong": "Việt Đức 2"
                         },
                         {
                           "maTruong": "79765L50",
                           "tenTruong": "Việt Đức 3"
                         },
                         {
                           "maTruong": "79765W43",
                           "tenTruong": "MN Vinschool - P7"
                         },
                         {
                           "maTruong": "79765W56",
                           "tenTruong": "Lớp Mẫu Giáo Hooray"
                         },
                         {
                           "maTruong": "79765W55",
                           "tenTruong": "Mầm Non Tổ Ong Vàng"
                         },
                         {
                           "maTruong": "79765W52",
                           "tenTruong": "Lớp Mẫu Giáo Bảo Minh"
                         },
                         {
                           "maTruong": "79765W53",
                           "tenTruong": "Lớp Mẫu Giáo Nụ Cười"
                         },
                         {
                           "maTruong": "79765W54",
                           "tenTruong": "Nhóm trẻ Cầu Vòng"
                         },
                         {
                           "maTruong": "79765W57",
                           "tenTruong": "Lớp Mẫu Giáo 17B"
                         },
                         {
                           "maTruong": "79765W45",
                           "tenTruong": "MN Khu Vườn Trí Tuệ"
                         },
                         {
                           "maTruong": "79765W47",
                           "tenTruong": "Trường Mầm non Bé Tài Năng"
                         },
                         {
                           "maTruong": "79765W48",
                           "tenTruong": "Nhóm trẻ Tuổi Thơ"
                         },
                         {
                           "maTruong": "79765W49",
                           "tenTruong": "Mầm Non Quốc tế Banh và Bi"
                         },
                         {
                           "maTruong": "79765W50",
                           "tenTruong": "Mầm non Vinschool - LP"
                         },
                         {
                           "maTruong": "79765W51",
                           "tenTruong": "Mầm non Tây Úc"
                         },
                         {
                           "maTruong": "79765L59",
                           "tenTruong": "NT Bé Ngoan"
                         },
                         {
                           "maTruong": "79765W46",
                           "tenTruong": "Nhóm trẻ Sao Vàng"
                         },
                         {
                           "maTruong": "79765x40",
                           "tenTruong": "Nhóm trẻ Việt Đức"
                         },
                         {
                           "maTruong": "79765155",
                           "tenTruong": "Trường Mầm non Thiên Nam"
                         },
                         {
                           "maTruong": "79765252",
                           "tenTruong": "Trường Mầm non Ngôi Làng Vui Vẻ"
                         },
                         {
                           "maTruong": "79765225",
                           "tenTruong": "Trường Mầm non Kỳ Lân"
                         },
                         {
                           "maTruong": "79765191",
                           "tenTruong": "Lớp Mẫu giáo Hoa Tu Líp"
                         },
                         {
                           "maTruong": "79765253",
                           "tenTruong": "Lớp Mẫu giáo Người Bạn Nhỏ"
                         },
                         {
                           "maTruong": "79765247",
                           "tenTruong": "Lớp Mẫu giáo Cỏ Hồng"
                         },
                         {
                           "maTruong": "79765298",
                           "tenTruong": "Lớp Mẫu giáo Làng Nắng"
                         },
                         {
                           "maTruong": "79765208",
                           "tenTruong": "Lớp Mẫu giáo Ánh Trăng"
                         },
                         {
                           "maTruong": "79765042",
                           "tenTruong": "Lớp Mẫu giáo Cô Cú Nhỏ"
                         },
                         {
                           "maTruong": "79765220",
                           "tenTruong": "Lớp Mẫu giáo Sắc Màu"
                         },
                         {
                           "maTruong": "79765026",
                           "tenTruong": "Lớp Mẫu giáo Ngôi Sao Sáng"
                         },
                         {
                           "maTruong": "79765018",
                           "tenTruong": "Lớp Mẫu giáo Sao Mai"
                         },
                         {
                           "maTruong": "79765095",
                           "tenTruong": "Nhóm trẻ Sao Mai"
                         },
                         {
                           "maTruong": "79765126",
                           "tenTruong": "Lớp Mẫu giáo Alpha"
                         },
                         {
                           "maTruong": "79765013",
                           "tenTruong": "Lớp Mẫu giáo Hoa Hướng Dương 5"
                         },
                         {
                           "maTruong": "7976501006",
                           "tenTruong": "Lớp mẫu giáo Hoa Hướng Dương"
                         },
                         {
                           "maTruong": "7976501001",
                           "tenTruong": "Lớp Mầm non Bí Ngô"
                         },
                         {
                           "maTruong": "7976501002",
                           "tenTruong": "Lớp Mầm non Nhà Bé Yêu"
                         },
                         {
                           "maTruong": "7976501003",
                           "tenTruong": "Lớp Mầm non Ánh Dương"
                         },
                         {
                           "maTruong": "7976501004",
                           "tenTruong": "Lớp Mầm non Vườn Yêu Thương"
                         },
                         {
                           "maTruong": "7976501005",
                           "tenTruong": "Lớp Mầm non Trẻ Thơ"
                         },
                         {
                           "maTruong": "7976501024",
                           "tenTruong": "Lớp Mầm Non Múp Míp"
                         },
                         {
                           "maTruong": "7976501020",
                           "tenTruong": "Lớp Mầm Non Bay"
                         },
                         {
                           "maTruong": "7976501022",
                           "tenTruong": "Lớp Mầm Non Song Hạ"
                         },
                         {
                           "maTruong": "7976501023",
                           "tenTruong": "Lớp Mầm Non Vườn Trẻ Thơ"
                         },
                         {
                           "maTruong": "7976501010",
                           "tenTruong": "Lớp Mầm non Sao Biển"
                         },
                         {
                           "maTruong": "7976501011",
                           "tenTruong": "Lớp Mầm non Mickey"
                         },
                         {
                           "maTruong": "7976501012",
                           "tenTruong": "Lớp Mầm non Sao Vàng"
                         },
                         {
                           "maTruong": "7976501013",
                           "tenTruong": "Lớp Mầm non Ngọc Bảo"
                         },
                         {
                           "maTruong": "7976501015",
                           "tenTruong": "Lớp Mầm non Hooray"
                         },
                         {
                           "maTruong": "7976501016",
                           "tenTruong": "Lớp Mầm non Ngôi Nhà Bé Yêu"
                         },
                         {
                           "maTruong": "7976501017",
                           "tenTruong": "Lớp MG Ngôi Nhà Mơ Ước"
                         },
                         {
                           "maTruong": "7976501018",
                           "tenTruong": "Lớp MG Sài Gòn Nhỏ"
                         },
                         {
                           "maTruong": "7976501009",
                           "tenTruong": "Lớp Mần non Ngôi Nhà Sao Sáng"
                         },
                         {
                           "maTruong": "7976501032",
                           "tenTruong": "Lớp Mẫu giáo Cherry"
                         },
                         {
                           "maTruong": "7976501030",
                           "tenTruong": "Lớp Mầm non Ngôi Sao Sáng"
                         },
                         {
                           "maTruong": "7976501031",
                           "tenTruong": "Lớp Mầm non Thanh Xuân"
                         },
                         {
                           "maTruong": "7976501027",
                           "tenTruong": "Lớp Mầm Non Bảo Mi"
                         },
                         {
                           "maTruong": "7976501028",
                           "tenTruong": "Lớp Mầm Non Nhà Của Bé"
                         },
                         {
                           "maTruong": "7976501029",
                           "tenTruong": "Trường MN 2-9"
                         },
                         {
                           "maTruong": "7976501025",
                           "tenTruong": "Lớp Mầm Non Ánh Trăng Vàng"
                         },
                         {
                           "maTruong": "7976501026",
                           "tenTruong": "Lớp Mầm Non Nhà Của Bé"
                         },
                         {
                           "maTruong": "7976501035",
                           "tenTruong": "Lớp Mầm non Vương Quốc Kẹo Ngọt"
                         },
                         {
                           "maTruong": "7976501036",
                           "tenTruong": "Lớp Mầm non Sao Mai"
                         },
                         {
                           "maTruong": "7976501037",
                           "tenTruong": "Lớp Mầm non Vườn Cổ Tích"
                         }
                       ]
                     }
                   ],
      list_phong: [{
        "maDonVi": "797",
        "tenDonVi": "Huyện Cần Giờ"
      }, {
        "maDonVi": "770",
        "tenDonVi": "Quận 3"
      }, {
        "maDonVi": "771",
        "tenDonVi": "Quận 10"
      }, {
        "maDonVi": "772",
        "tenDonVi": "Quận 11"
      }, {
        "maDonVi": "773",
        "tenDonVi": "Quận 4"
      }, {
        "maDonVi": "774",
        "tenDonVi": "Quận 5"
      }, {
        "maDonVi": "775",
        "tenDonVi": "Quận 6"
      }, {
        "maDonVi": "776",
        "tenDonVi": "Quận 8"
      }, {
        "maDonVi": "777",
        "tenDonVi": "Quận Bình Tân"
      }, {
        "maDonVi": "778",
        "tenDonVi": "Quận 7"
      }, {
        "maDonVi": "766",
        "tenDonVi": "Quận Tân Bình"
      }, {
        "maDonVi": "760",
        "tenDonVi": "Quận 1"
      }, {
        "maDonVi": "761",
        "tenDonVi": "Quận 12"
      }, {
        "maDonVi": "783",
        "tenDonVi": "Huyện Củ Chi"
      }, {
        "maDonVi": "762",
        "tenDonVi": "Thành phố Thủ Đức"
      }, {
        "maDonVi": "784",
        "tenDonVi": "Huyện Hóc Môn"
      }, {
        "maDonVi": "763",
        "tenDonVi": "Quận 9"
      }, {
        "maDonVi": "785",
        "tenDonVi": "Huyện Bình Chánh"
      }, {
        "maDonVi": "764",
        "tenDonVi": "Quận Gò Vấp"
      }, {
        "maDonVi": "786",
        "tenDonVi": "Huyện Nhà Bè"
      }, {
        "maDonVi": "765",
        "tenDonVi": "Quận Bình Thạnh"
      }, {
        "maDonVi": "787",
        "tenDonVi": "Huyện Cần Giờ"
      }, {
        "maDonVi": "766",
        "tenDonVi": "Quận Tân Bình"
      }, {
        "maDonVi": "767",
        "tenDonVi": "Quận Tân Phú"
      }, {
        "maDonVi": "768",
        "tenDonVi": "Quận Phú Nhuận"
      }, {
        "maDonVi": "769",
        "tenDonVi": "Quận 2"
      }],
      checkLogin: false,
      showLogin: false,
      rememberPassword: true,
      isLogin: false,
      submitted: false,
      capChaValue: "",
      formLogin: {
        email: '',
        password: '',
        rememberPassword: true,
        capChaValue: ""
      },
      rules: {
        email: [{
          required: true,
          message: 'Email là bắt buộc.',
          trigger: 'change'
        },
          // { type: 'email', message: 'Không đúng định dạng email', trigger: ['blur', 'change'] },
        ],
        password: [{
          required: true,
          message: 'Mật khẩu là bắt buộc.',
          trigger: 'change'
        },
          {
            min: 8,
            message: 'Mật khẩu chứa ít nhất 8 ký tự.',
            trigger: 'change'
          },
          {
            max: 20,
            message: 'Mật khẩu chứa tối đa 12 ký tự.',
            trigger: 'change'
          },
        ],
        capChaValue: [{
          required: true,
          message: 'Mã captcha là bắt buộc.',
          trigger: 'change'
        },
          // { type: 'email', message: 'Không đúng định dạng email', trigger: ['blur', 'change'] },
        ],
      }
    }
  },
  mounted() {
    return;
    this.checkLogin = this.$route.query.check;
    console.log("check" + this.checkLogin)
    if (this.checkLogin) {
      this.showLogin = true;
    }
    sessionStorage.clear()
    window.localStorage.removeItem('userSso');
    window.localStorage.removeItem('id_token');
    window.localStorage.removeItem('user');
    window.localStorage.removeItem('uriConnectWebsocket');
    window.localStorage.removeItem('messInfoOtpCode');
    window.localStorage.removeItem('OtpCode');
    window.localStorage.removeItem('dataTokenVnptCa');
    window.localStorage.removeItem('routerLoginSmartCa');
    window.localStorage.removeItem('kieuKyHocBaGiaoVien');
    window.localStorage.removeItem('kieuKyBiaGiaoVien');
    if (localStorage.getItem('remember') && localStorage.getItem('remember') != '' && localStorage.getItem(
        'remember') != null) {
      this.formLogin.email = (JSON.parse(localStorage.getItem('remember'))).email;
      this.formLogin.password = (JSON.parse(localStorage.getItem('remember'))).password;
    }
    if (localStorage.getItem('rememberCheck') != null) {
      this.formLogin.rememberPassword = true;
    } else {
      this.formLogin.rememberPassword = false;
    }
  }
}
</script>

<style scoped>
.selectOptionLogin {
  width: 100%;
  padding: 5px 15px;
}


.icon-support {
  width: 40px;
  height: 50px;
  background-size: cover;
  background-image: url('https://smas.edu.vn/Content/images/icon-support.png');
}

.icon {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 5px;
  vertical-align: middle;
}

.support-text {
  display: inline-block;
  vertical-align: middle;
  padding-left: 15px;
}

.purple {
  color: #027185;
}

.orange {
  color: #F5A623;
}

.viettel-group {
  color: white;
  /* font-weight:bold */
  font-size: 14px
}
</style>