<template>
  <div id="detailHocBa" class="page page-file file detailHocBa"
       v-loading.fullscreen.lock="fullscreenLoading"
       element-loading-text="Đang xử lý.."
       element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="page__title d-flex align-items-center justify-content-between">
      <h1 class="title">Quản lý học bạ</h1>
    </div>
    <div class="box-upload-file">
      <el-form :rules="rulesSearch" ref="searchForm" :model="searchForm" :hide-required-asterisk="true">
        <el-row :gutter="24">
          <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
            <el-form-item class="d-inline-block" prop="education" label="Cấp học">
              <eselect style="width:100%" :disabled="list_cap_hoc.length==1" collapseTags v-model="searchForm.education"
                       @change="capHocChange" placeholder="Chọn" filterable :data="list_cap_hoc"
                       :fields="['name','value']"/>
            </el-form-item>
          </el-col>
          <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
            <el-form-item class="d-inline-block" prop="year" label="Năm học">
              <eselect style="width:100%" collapseTags v-model="searchForm.year" placeholder="Chọn"
                       @change="namHocChange" filterable :data="list_nam_hoc" :fields="['name','value']"/>
            </el-form-item>
          </el-col>
          <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
            <el-form-item class="d-inline-block" label="Khối học">
              <eselect style="width:100%" collapseTags v-model="searchForm.grade" @change="khoiHocChange"
                       placeholder="Chọn" filterable :data="optionGrade"
                       :fields="['label','value']"/>
            </el-form-item>
          </el-col>
          <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
            <el-form-item class="d-inline-block" label="Lớp học">
              <eselect style="width:100%" collapseTags v-model="searchForm.class" placeholder="Chọn"
                       filterable :data="danh_sach_lop_hoc" :fields="['tenLop','maLop']"/>
            </el-form-item>
          </el-col>
          <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
            <el-form-item class="d-inline-block" label="Trạng thái">
              <eselect style="width:100%" collapseTags v-model="searchForm.trangThaiHocSinh"
                       :placeholder="'Trạng thái học sinh'"
                       :data="[{label:'Đang học', value:1}, {label:'Chuyển trường', value:3}, {label:'Bỏ học', value:4}]"
                       :fields="['label','value']"/>
            </el-form-item>
          </el-col>

          <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
            <el-form-item class="d-inline-block" label="Từ khoá">
              <el-input v-model="searchForm.student" clearable placeholder="Nhập"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="6" :lg="24" :xl="3" style="text-align: center">
            <el-form-item class="d-inline-block">
              <div style="color: transparent">Tìm kiếm</div>
              <el-button class="m-0" type="primary"
                         @click="submitSearch('searchForm')">Tìm kiếm
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <el-row :gutter="20">
      <el-col :span="24">
        <div class="text-right">
          <el-button type="primary" @click.prevent="checkShowUploadBia(1)" class="mb-1" size="small"><i
              class="el-icon-upload2"></i> Upload file dữ liệu
          </el-button>
          <el-button type="success" @click.prevent="getHashUploadFileScan()" class="mb-1" size="small"><i
              class="el-icon-printer"></i> Scan học bạ
          </el-button>
          <el-button v-if="user.qlnt!=0" type="success" @click="updateMutileImage" class="mb-1" size="small">
            <i
                class="el-icon-upload2"></i> Cập nhật nhiều ảnh
          </el-button>
        </div>
      </el-col>
    </el-row>

    <div class="page__content" ref="page_content">
      <div class="listNew mb-5">
        <el-table border fit :lazy="true" :data="listHs ? listHs.list : []" width="100%"
                  :max-height="maxHeight"
                  @selection-change="handleSelectionChange" ref="listFile">
          <el-table-column label="STT" style="width: 50px" width="50" type="index" class-name="text-center"
                           :index="indexMethod">
          </el-table-column>
          <el-table-column header-align="center" width="80" height="80" label="Ảnh">
            <template slot-scope="scope">
              <div class="avatar">
                <img :src="scope.row.avatar ? scope.row.avatar+'?t='+getTime() : defaultAvatar" alt=""/>
                <el-tooltip content="Xóa ảnh" placement="top">
                  <a class="remove-avatar" v-if="scope.row.removeAvatar" href=""
                     @click.prevent="removeAvatar(scope.row)"><i class="ion ion-ios-close"></i></a>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label="Mã học sinh" property="maHS" align="center">
          </el-table-column>
          <el-table-column header-align="center" label="Số đăng bộ" property="soDangBo" align="center">
          </el-table-column>
          <el-table-column header-align="center" label="Họ và tên" property="tenHS"></el-table-column>
          <el-table-column header-align="center" label="Lớp" property="tenLop" align="center">
          </el-table-column>
          <!--                    <el-table-column header-align="center" label="Ngày vào trường" property="ngayKyBia" align="center">-->
          <!--                    </el-table-column>-->
          <el-table-column header-align="center" label="Trạng thái học sinh" align="center">
            <template slot-scope="scope">
              <div v-html="getTrangThaiHocSinh(scope.row.trangThai)"></div>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label="Trạng thái học bạ" align="center">
            <template slot-scope="scope">
              <div v-html="checkHocBa(scope.row)"></div>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label="Hành động" class-name="" align="center">
            <template slot-scope="scope">
              <div class="btn__table_action ">
                <el-upload class="upload-demo d-inline-block mr-1" ref="upload" :show-file-list="false"
                           action="/" :on-change="uploadAvatar" accept=".png,.jpg,.jpeg"
                           :auto-upload="false">

                  <el-tooltip v-if="user.qlnt!=0" content="Tải lên ảnh học sinh" placement="top">
                    <el-button size="mini" type="success"
                               icon="el-icon-upload"
                               @click.prevent="hocSinhSelect = scope.row">
                    </el-button>
                  </el-tooltip>
                </el-upload>

                <el-tooltip content="Xem chi tiết" placement="top">
                  <el-button
                      size="mini"
                      @click="showDialogInfoHocBa(scope.row,scope.$index)" type="primary"
                      icon="el-icon-view">
                  </el-button>
                </el-tooltip>
                <el-tooltip content="Chỉnh sửa thông tin" placement="top">
                  <el-button
                      style="margin-left: 4px"
                      size="mini"
                      @click="hienThiCapNhatNgayVaoTruong(scope.row)" type="warning"
                      icon="el-icon-edit">
                  </el-button>
                </el-tooltip>
                <el-tooltip v-if="false" content="Đồng bộ dữ liệu" placement="top">
                  <el-button
                      style="margin-left: 4px"
                      size="mini"
                      @click="dongBoDuLieu(scope.row)" type="success"
                      icon="el-icon-sort">
                  </el-button>
                </el-tooltip>

                <!--                                <el-tooltip content="Thêm học bạ" placement="top" v-if="statusHocba(scope.row)"-->
                <!--                                            class="mb-1">-->
                <!--                                    <el-button icon="el-icon-plus" @click="addHocBa(scope.row)" class="mb-1"-->
                <!--                                               type="primary">-->
                <!--                                    </el-button>-->
                <!--                                </el-tooltip>-->
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div v-if="listHs && listHs.total" class="mt-2">
          <el-row :gutter="20">
            <el-col :span="8">
              <chonSoLuong @chonXongSoLuong="ChonSoLuongBanGhi($event)"></chonSoLuong>
            </el-col>
            <el-col :span="16" class="text-right">
              <small class="pd-r-10">
                <i>Danh sách có {{ listHs.total }} học bạ </i>
              </small>
              <pagination :value="paginate.current"
                          :page-count="getTotalPage(listHs.total)" :click-handler="getHocBa"
                          :prev-text="'Trước'"
                          :next-text="'Sau'" :container-class="'el-pager'" :pageClass="'number'">

              </pagination>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>

    <el-dialog title="Thông tin học bạ" append-to-body class="info-hocba" :visible.sync="showInfoHocba" center>
      <el-table fit border :lazy="true" :data="listHocBa" width="100%">
        <el-table-column label="STT" style="width: 50px" width="50" type="index" class-name="text-center"
                         :index="indexMethod">
        </el-table-column>
        <el-table-column label="Họ và tên" property="hoten" class-name="text-center">
        </el-table-column>
        <el-table-column label="Lớp" property="tenLop" width="70" class-name="text-center"></el-table-column>
        <el-table-column label="GVCN" property="tenGVCN" class-name="text-center"></el-table-column>
        <el-table-column label="Năm học" property="" class-name="text-center">
          <template slot-scope="scope">
            {{ scope.row.namHoc }} - {{ scope.row.namHoc + 1 }}
          </template>
        </el-table-column>
        <!--        <el-table-column label="File bìa ký số" class-name="text-center">-->
        <!--          <template slot-scope="scope">-->
        <!--            <a v-if="scope.row.biaHocBa" :href="scope.row.biaHocBa.linkView" target="_blank">Xem file</a>-->
        <!--          </template>-->
        <!--        </el-table-column>-->
        <!--        <el-table-column label="File học bạ ký số" class-name="text-center">-->
        <!--          <template slot-scope="scope">-->
        <!--            <a v-if="scope.row.linkFilePdf" :href="scope.row.linkFilePdf" target="_blank">Xem file</a>-->
        <!--          </template>-->
        <!--        </el-table-column>-->

        <el-table-column label="Hành động" class-name="text-center">
          <template slot-scope="scope">
            <div class="btn__table_action">
              <!--              <UploadImageBase64 @click.native="dataSelect = scope.row"-->
              <!--                                 style="width: 100px; cursor: pointer;" disable-preview class="upload-thumb mb-1"-->
              <!--                                 image-class="v1-image" input-class="v1-image" @size-exceeded="onSizeExceeded"-->
              <!--                                 placeholder="Tải lên bìa học bạ" @load="onBiaLoad">-->
              <!--                <template v-slot:showButton>-->
              <!--                  <el-tooltip content="Tải lên bìa học bạ" placement="top">-->
              <!--                    <el-button icon="el-icon-upload2"></el-button>-->
              <!--                  </el-tooltip>-->
              <!--                </template>-->
              <!--              </UploadImageBase64>-->

              <!--              <UploadImageBase64 @click.native="dataSelect = scope.row.id"-->
              <!--                                 style="width: 100px; cursor: pointer;" disable-preview class="upload-thumb mb-1"-->
              <!--                                 image-class="v1-image" input-class="v1-image" :max-size="customImageMaxSize"-->
              <!--                                 @size-exceeded="onSizeExceeded" placeholder="Tải lên kqht" @load="onLoad">-->
              <!--                <template v-slot:showButton>-->
              <!--                  <el-tooltip content="Tải lên kqht" placement="top">-->
              <!--                    <el-button icon="el-icon-upload"></el-button>-->
              <!--                  </el-tooltip>-->

              <!--                </template>-->
              <!--              </UploadImageBase64>-->

              <el-tooltip content="Xem học bạ" placement="top">
                <el-button @click="viewFile(scope.row)" class="mb-1" icon="ion ion-md-eye" plain>
                </el-button>
              </el-tooltip>
              <!--                            <el-tooltip content="Phân quyền sửa" placement="top">-->
              <!--                                <el-button class="mb-1" icon="el-icon-edit" @click="permissionEdit(scope.row)">-->
              <!--                                </el-button>-->
              <!--                            </el-tooltip>-->
              <el-tooltip v-if="scope.row.nguonDuLieu" content="Sửa học bạ" placement="top">
                <el-button class="mb-1" type="primary" icon="el-icon-edit" @click="suaHocBa(scope.row)">
                </el-button>

              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="closeDialogCreate()">Đóng</el-button>
            </span>
    </el-dialog>

    <ThongTinHocBa :show="thongTinHocBa" :id_hoc_ba="idHocBa" @close="thongTinHocBa = false"></ThongTinHocBa>
    <el-dialog title="Thêm học bạ" :visible.sync="showAddHocBa" append-to-body :before-close="closeDialogCreate"
               center class="add-hocba">
      <them-hoc-ba @close="closeDialogCreate()">

      </them-hoc-ba>
    </el-dialog>
    <el-dialog title="Sửa học bạ" :visible.sync="showEditHocBa" append-to-body center class="edit-hocba">
      <sua-hoc-ba @close="closeDialogEdit()"></sua-hoc-ba>
    </el-dialog>
    <el-dialog title="Tải lên ảnh học sinh" append-to-body center custom-class="add-mutile-image"
               :visible.sync="showUpdateMutile">
      <el-upload ref="uploadMutile" class="upload-demo text-center" drag action="/" :file-list="fileList"
                 :on-error="showError" :on-change="uploadMutilAvatarChange" :on-remove="uploadMutilAvatarChange"
                 accept=".png,.jpg,.jpeg" :auto-upload="false" multiple>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">Kéo thả hoặc <em>click ở đây</em></div>
        <div class="el-upload__tip" slot="tip">
          Bạn hãy chọn tất cả ảnh của lớp (định dạng png,jpg). Lưu ý: đặt tên file là mã học sinh.<br>
          Hệ thống sẽ tự động upload tất cả ảnh học sinh có tên file trùng với mã học sinh tương ứng.
        </div>
      </el-upload>
      <div class="text-center">
        <el-button size="small" type="primary" @click.prevent="uploadMutilAvatar">Tải ảnh lên</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="titleUploadFile" append-to-body center custom-class="add-mutile-image"
               :visible.sync="showUploadBia">
      <el-upload ref="uploadBia" class="upload-demo text-center" drag action="/"
                 :file-list="fileList"
                 :on-change="uploadFile" :on-remove="uploadFile" :accept="typeUpload==1?'.pdf,.xlsx,.xls':'.zip,.zar'"
                 :auto-upload="false">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">Kéo thả hoặc <em>click ở đây</em></div>
        <div class="el-upload__tip" slot="tip">
          <span v-if="typeUpload==1">
            Qúy thầy cô vui lòng tải file excel mẫu xuống và nhập lại file lên hệ thống!
            <br>
            Lưu ý: Có thể nhập cả lớp hoặc từng học sinh để nhập vào file.
          </span>
          <span v-else>
            Hướng dẫn nhập file scan: <br>
            Bước 1: Đặt tên file scan là mã học sinh tương ứng mã Học bạ số (HS1010101999) <br>
            Bước 2: Nén file scan thành file zip <br>
            Bước 3: Nhập file zip lên Học bạ số <br>
            Lưu ý: Trong TH tải một học sinh lên có thể không cần nén file zip dùng trực tiếp file pdf đã scan
          </span>
        </div>
      </el-upload>
      <div class="text-center mt-3">
        <el-button v-if="typeUpload==1" :disabled="disabledDownLoad" size="small" type="success"
                   @click="downLoadTemplate(1)"> Tải xuống file mẫu
        </el-button>
        <el-button size="small" :disabled="checkStatusImportFile() " :type="typeUpload==2?'success':'primary'"
                   @click.prevent="uploadBiaHocBa">Tải lên dữ liệu
        </el-button>
      </div>
    </el-dialog>

    <el-dialog title="File Scan trang học bạ" append-to-body center custom-class="add-mutile-image"
               :visible.sync="showUploadHocBa">
      <el-upload ref="uploadFileHocBa" class="upload-demo text-center" drag action="/"
                 :on-change="uploadMutilAvatarChange" :on-remove="uploadMutilAvatarChange" accept=".pdf"
                 :auto-upload="false" multiple>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">Kéo thả hoặc <em>click ở đây</em></div>
        <div class="el-upload__tip" slot="tip">
          Bạn hãy chọn tất cả file học bạ của lớp (định dạng pdf). Lưu ý: đặt tên file là mã học sinh.<br>
          Hệ thống sẽ tự động upload tất cả file học sinh có tên file trùng với mã học sinh tương ứng.<br>
          Lưu ý: Nếu hệ thống đã sinh file pdf, upload từ ngoài vào hệ thống sẽ thay bằng file upload.
        </div>
      </el-upload>
      <div class="text-center">
        <el-button size="small" type="primary" @click.prevent="uploadFileHocBa">Tải lên</el-button>
      </div>
    </el-dialog>


    <el-dialog class="popupPhanQuyenBoMon" title="Phân quyền sửa học bạ" append-to-body center
               :visible.sync="showPermissionEdit">
      <el-tabs type="card">
        <el-tab-pane v-if="user.capHoc > 1" label="Giáo viên bộ môn">
          <div class="page">
            <h4>Danh sách giáo viên bộ môn</h4>

            <el-table :data="lstMonDetail.ketQuaHocTaps" border class="table-custom" style="width: 100%">
              <el-table-column align="center" prop="tenMonHoc" label="Tên môn học">
              </el-table-column>
              <el-table-column header-align="center" prop="tenGiaoVien" label="Tên giáo viên">
              </el-table-column>
              <el-table-column align="center" label="Trạng thái">
                <template slot-scope="scope">
                  {{ scope.row.trangThaiXacNhan == 1 ? 'Đã ký' : 'Chưa ký' }}
                </template>
              </el-table-column>
              <el-table-column align="center" prop="ghiChu" label="Ghi chú">
              </el-table-column>
              <el-table-column align="center" label="Hành động">
                <template slot-scope="scope">
                  <el-tooltip content="Phân quyền" effect="dark" placement="top">
                    <el-button size="small" type="danger" @click="phanQuyenBoMon(scope.row)"><i
                        class="el-icon-edit"></i></el-button>

                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Giáo viên chủ nhiệm">
          <div class="text-center">
            <div><label>Giáo viên chủ nhiệm:</label> {{ lstMonDetail.tenGVCN }} - Lớp :
              {{ lstMonDetail.tenLop }}
            </div>
            <el-input type="textarea" :rows="4" placeholder="Nhập lý do" v-model="lyDoGVCN">
            </el-input>
            <el-button type="primary" class="mt-2" @click="submitPermissionGVCN">Phân quyền</el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Hiệu trưởng">
          <div class="text-center">
            <div><label>Hiệu trưởng:</label> {{ lstMonDetail.tenHieuTruong }}</div>

            <el-input type="textarea" :rows="4" placeholder="Nhập lý do" v-model="lydoHieuTruong">
            </el-input>
            <el-button type="primary" class="mt-2" @click="submitPermissionHieuTruong">Phân quyền
            </el-button>
          </div>
        </el-tab-pane>

      </el-tabs>
    </el-dialog>

    <el-dialog class="" title="Phân quyền cho bộ môn" append-to-body center :visible.sync="showPopUpBoMon">
      <div>
        <div>
          <label>Tên giáo viên:</label> {{ dataBomon.tenGiaoVien }} - <label>Môn học:</label>
          {{ dataBomon.tenMonHoc }}
        </div>
        <el-input type="textarea" :rows="5" placeholder="Nhập lý do" v-model="lyDoBoMon">
        </el-input>
        <div class="text-center mt-3">
          <el-button type="primary" @click.prevent="submitPermissionBoMon">Xác nhận</el-button>
        </div>
      </div>

    </el-dialog>
    <el-dialog class="" title="Chỉnh sửa thông tin" :close-on-click-modal="false"
               :close-on-press-escape="false" append-to-body center :visible.sync="show_update_ngay_vao_truong">
      <div>
        <el-row :gutter="24">
          <el-col :span="12">
            <label>Tên học sinh</label>
            <el-input disabled v-model="infoUpdate.tenHS"></el-input>
          </el-col>
          <el-col :span="12">
            <label>Mã học sinh</label>
            <el-input disabled v-model="infoUpdate.maHS"></el-input>
          </el-col>
          <el-col :span="12" class="mt-3">
            <label>Địa danh ký <span style="color: red">*</span></label>
            <el-input
                maxlength="50"
                placeholder="Nhập địa danh ký"
                show-word-limit clearable v-model="infoUpdate.tenDiaDanhKyBia"></el-input>
          </el-col>
          <el-col :span="12" class="mt-3">
            <label>Ngày ký lý lịch học sinh <span style="color: red">*</span></label>
            <el-date-picker
                style="width: 100%;"
                format="dd/MM/yyyy"
                v-model="infoUpdate.ngayKyBia"
                value-format="dd/MM/yyyy"
                type="date"
                placeholder="dd/mm/yyyy">
            </el-date-picker>
          </el-col>
          <el-col :span="12" class="mt-3">
            <label>Hậu tố sổ đăng bộ</label>
            <el-input
                placeholder="Nhập hậu tố sổ đăng bộ" clearable v-model="infoUpdate.hauToSoDangBo"></el-input>
          </el-col>
          <el-col :span="12" class="mt-3">
            <label>Trạng thái</label>
            <eselect filterable style="width:100%" collapseTags v-model="infoUpdate.trangThai"
                     :placeholder="'Trạng thái học sinh'"
                     @change="sua_trang_thai = true;"
                     :data="[{label:'Đang học', value:1}, {label:'Chuyển trường', value:3}, {label:'Bỏ học', value:4}]"
                     :fields="['label','value']"/>
          </el-col>
        </el-row>
        <div class="text-center mt-5">
          <el-button type="default" @click.prevent="show_update_ngay_vao_truong=false">Đóng</el-button>
          <el-button type="warning" @click.prevent="chinhSuaNgayKyBia()">Chỉnh sửa</el-button>
        </div>
      </div>

    </el-dialog>
    <!-- <KyHocBa></KyHocBa> -->

    <dialog-alert title="Thông báo" :message="messageAlert" :show-dialog="showDialogAlert"
                  @closeDialog="closeDialogAlert"></dialog-alert>
  </div>
</template>

<script>
import KyHocBa from "./Ui/kyHocBa";
import ChiTietHocBa from "./GiaoVien/ChiTietHocBa";
import {
  mapActions,
  mapState
} from 'vuex';
import Pagination from "../components/Pagination";
import dialog from "../dialog";
import FormOtp from "../components/FormOtp";
import UploadImageBase64 from "../components/UploadImageBase64";
import ThemHocBa from "./ThemHocBa";
import SuaHocBa from "./SuaHocBa";
import DialogAlert from "../components/DialogAlert";
import SelectNamHoc from "../components/SelectNamHoc";
import ChonKhoiHoc from "../components/ChonKhoiHoc";
import ThongTinHocBa from "../pages/HocBa/ThongTinHocBa";
import ESelectVue from "./Ui/ESelect";
import chonSoLuong from "./Ui/ChonSoLuong";
import constant_api from "../_helpers/constant_api";
import constant from "../_helpers/constant_define";
import api from "../_helpers/api";
import LZString from 'lz-string';
import XLSX from 'xlsx';
import ExcelJS from 'exceljs';
import {
  hocbaService
} from "../_services";
import fa from "element-ui/src/locale/lang/fa";


export default {
  name: "QuanLyHocBa",
  metaInfo: {
    title: 'Quản lý học bạ',
    titleTemplate: '',
    meta: [{
      vmid: 'description',
      name: 'description',
      content: ''
    }]
  },
  computed: {
    ...mapState('teacher', [
      "listLop",
      "updateMessage",
      "updateSuccess",
      "uploadStatus"
    ]),
    ...mapState('hocba', [
      'ketQuaHocTapTemp',
      "fileDetail",
      "fileStatus"

    ]),
    ...mapState('account', [
      'user',
      'list_nam_hoc',
      'list_cap_hoc',
      'nam_hoc_hien_tai',
    ]),
    ...mapState('until', [
      'window',
    ])
  },
  components: {
    DialogAlert,
    SuaHocBa,
    chonSoLuong,
    Pagination,
    FormOtp,
    UploadImageBase64,
    ThemHocBa,
    SelectNamHoc,
    ChonKhoiHoc,
    ThongTinHocBa,
    'eselect': ESelectVue,
    'KyHocBa': KyHocBa,
  },

  data() {
    return {
      maxHeight: '',
      list_limit_paginate: constant.list_limit_paginate,
      fileList: [],
      showUpdateMutile: false,
      showUploadBia: false,
      hashCodeUpload: '',
      typeUpload: '',
      showUploadHocBa: false,
      hocSinhSelect: '',
      defaultAvatar: '/images/avatar.jpg',
      showDialogAlert: false,
      messageAlert: '',
      danh_sach_lop_hoc: [],
      listHocBa: [],
      show_update_ngay_vao_truong: false,
      infoUpdate: {},
      sua_trang_thai: false,
      showEditHocBa: false,
      showAddHocBa: false,
      showInfoHocba: false,
      thongTinHocBa: false,
      dataSelect: '',
      idHocBa: '',
      customImageMaxSize: 2,
      typeKy: null,
      hocbaDatas: [],
      showDialogOtp: false,
      listHocBaCheck: [],
      viewPopup: ChiTietHocBa,
      idEdit: '',
      showEditDiem: false,
      paginate: {
        start: 0,
        limit: 100,
        current: 1,
      },
      data: [1, 2, 3, 4, 5],
      listHs: [],
      base64: '',
      listRows: [],
      workbook: '',
      worksheet: '',
      fullscreenLoading: false,
      disabledDownLoad: false,
      titleUploadFile: 'Tải lên dữ liệu',
      infoUploadFileZip: {
        fileZip: null,
        chunks: null,
        chunkSize: null
      },
      dataFormScanBia: [],
      optionEducation: [{
        value: 1,
        label: 'Cấp 1'
      },
        {
          value: 2,
          label: 'Cấp 2'
        },
        {
          value: 3,
          label: 'Cấp 3'
        },
        {
          value: 15,
          label: 'Liên cấp'
        },
        {
          value: 145,
          label: 'Liên cấp'
        },
        {
          value: 14,
          label: 'Liên cấp'
        },
        {
          value: 13,
          label: 'Liên cấp'
        },
        {
          value: 451,
          label: 'Liên cấp'
        },
        {
          value: 23,
          label: 'Liên cấp'
        },
        {
          value: 236,
          label: 'Liên cấp'
        },
        {
          value: 36,
          label: 'Liên cấp'
        },
        {
          value: 452,
          label: 'Liên cấp'
        },
        {
          value: 1245,
          label: 'Liên cấp'
        },
        {
          value: 12,
          label: 'Liên cấp'
        },
        {
          value: 123,
          label: 'Liên cấp'
        },
        {
          value: 12345,
          label: 'Liên cấp'
        },
        {
          value: 1235,
          label: 'Liên cấp'
        },
        {
          value: 6,
          label: 'Trung tâm GDTX'
        },
      ],
      optionGrade: [],
      khoiCap1: [{
        value: 1,
        label: 'Khối 1'
      },
        {
          value: 2,
          label: 'Khối 2'
        },
        {
          value: 3,
          label: 'Khối 3'
        },
        {
          value: 4,
          label: 'Khối 4'
        },
        {
          value: 5,
          label: 'Khối 5'
        },
      ],
      khoiCap2: [{
        value: 6,
        label: 'Khối 6'
      },
        {
          value: 7,
          label: 'Khối 7'
        },
        {
          value: 8,
          label: 'Khối 8'
        },
        {
          value: 9,
          label: 'Khối 9'
        },
      ],
      khoiCap3: [{
        value: 10,
        label: 'Khối 10'
      },
        {
          value: 11,
          label: 'Khối 11'
        },
        {
          value: 12,
          label: 'Khối 12'
        },

      ],
      optionStudent: [{
        value: '',
        label: 'Học sinh'
      },
        {
          value: 1,
          label: 'HS 1'
        },
        {
          value: 2,
          label: 'HS 2'
        },
        {
          value: 3,
          label: 'HS 3'
        },
      ],
      optionStatus: [{
        value: '',
        label: 'Trạng thái'
      },
        {
          value: 1,
          label: 'Đã ký'
        },
        {
          value: 2,
          label: 'Đã ký duyệt'
        },
        {
          value: 3,
          label: 'Chưa ký'
        },
      ],

      searchForm: {
        year: '',
        education: '',
        grade: '',
        class: '',
        student: '',
        status: '',
        trangThaiHocSinh: 1
      },
      rulesSearch: {
        grade: [{
          required: true,
          message: 'Bạn chưa chọn khối học',
          trigger: 'change'
        }],
        class: [{
          required: true,
          message: 'Bạn chưa chọn lớp học',
          trigger: 'change'
        }],
      },
      itemEdit: '',
      indexSelect: null,
      dataUpload: [],
      showPermissionEdit: false,
      lstMonDetail: '',
      lyDoGVCN: '',
      dataPermission: '',
      lydoHieuTruong: '',
      dataBomon: '',
      showPopUpBoMon: false,
      lyDoBoMon: '',
      duLieuUploadMutilFile: '',
    }
  },
  methods: {
    downLoadTemplate: function (step) {

      console.log("Lấy danh sách học bạ:")
      let params = {
        maTruong: this.user.maTruong,
        namHoc: this.searchForm.year,
        maLop: this.searchForm.class
      }
      try {
        let uri = constant_api.hocsinh.downLoadTemplateMauHocSinhScan;
        this.fullscreenLoading = true;
        api.post(uri, params).then(
            response => {
              console.log("Tải file mẫu trả về:")
              console.log(response);
              if (response.data && response.data.code == 200) {
                window.open(response.data.data, '_blank');
              } else {
                this.thongBao('error', 'Không tìm thấy dữ liệu')
              }
              this.fullscreenLoading = false;
            }
        ).catch((e) => {
          // this.thongBao('success', 'Hệ thống đang cập nhật dữ liệu. Vui lòng chờ trong ít phút.');
          this.fullscreenLoading = false;
        })

      } catch (e) {
        this.thongBao('error', 'Vui lòng thao tác lại.')
      }
      if (step == 1) {
        // window.open('/files/common/scan_hoc_ba_v2.xlsx', '_blank');
      }
    },
    checkStatusImportFile() {
      let result = false;
      if (this.typeUpload == 1 && !this.base64) {
        result = true;
      }
      if (this.typeUpload == 2 && this.infoUploadFileZip.chunks == null) {
        result = true;
      }
      return result;
    },
    getHeightTable() {
      if (this.window.height && this.window.height > 950) {
        return this.window.height - 100
      }
      if (this.window.height && this.window.height > 850) {
        return 800
      }
      if (this.window.height && this.window.height > 750) {
        return 700
      }
      return 600
    },

    ChonSoLuongBanGhi(e) {
      console.log('ChonSoLuongBanGhi');
      console.log(e)
      this.showDialogOtp = false;
      this.paginate.start = 0;
      this.paginate.limit = e.soluong;
      this.paginate.current = 1;
      this.getDataCurenPage();
    },
    getTime() {
      var d = new Date();
      var n = d.getTime();
      return n;
    },
    namHocChange() {
      this.searchForm.student = '';
      this.searchForm.status = '';
      this.searchForm.grade = ''
      this.searchForm.class = ''
    },

    LopHocChange() {
      this.getlistHocSinh();
    },
    submitPermissionHieuTruong() {
      if (!this.lydoHieuTruong) {
        this.$alert('Vui lòng nhập lý do', 'Thông báo', {
          confirmButtonText: 'Đóng',
          type: 'warning',
          center: true,
          callback: action => {

          }
        });
        return;
      }

      let params = {
        type: 3,
        ghiChu: this.lydoHieuTruong,
        id: this.dataPermission.id,
      }


      this.$confirm('Xác nhận phân quyền cho hiệu trưởng?', 'Warning', {
        confirmButtonText: 'Xác nhận',
        cancelButtonText: 'Hủy',
        center: true,
        type: 'warning'
      }).then(() => {
        hocbaService.permission(params).then(res => {
          if (res.data.code == 200) {
            this.showPermissionEdit = false;

            let params = {
              id: this.dataPermission.id
            }
            hocbaService.getDetail(params).then(res => {
              if (res.data.code == 200) {
                this.lstMonDetail = res.data.data;
                this.lyDoGVCN = this.lstMonDetail.ghiChuGVCN ? this
                    .lstMonDetail.ghiChuGVCN : '';
                this.lydoHieuTruong = this.lstMonDetail.ghiChuHieuTruong ?
                    this.lstMonDetail.ghiChuHieuTruong : '';
              }
            }).catch((e) => {

            })

            this.thongBao('success', res.data.msg);
          } else {
            this.thongBao('error', res.data.msg);
          }

        }).catch((e) => {
          this.thongBao('warning', 'Lỗi kết nối API')
        })
      }).catch(() => {

      });

    },


    submitPermissionGVCN() {
      if (!this.lyDoGVCN) {
        this.$alert('Vui lòng nhập lý do', 'Thông báo', {
          confirmButtonText: 'Đóng',
          type: 'warning',
          center: true,
          callback: action => {

          }
        });
        return;
      }

      let params = {
        type: 2,
        ghiChu: this.lyDoGVCN,
        id: this.dataPermission.id,
      }

      this.$confirm('Xác nhận phân quyền cho GVCN?', 'Warning', {
        confirmButtonText: 'Xác nhận',
        cancelButtonText: 'Hủy',
        center: true,
        type: 'warning'
      }).then(() => {
        hocbaService.permission(params).then(res => {
          if (res.data.code == 200) {
            this.showPermissionEdit = false;

            let params = {
              id: this.dataPermission.id
            }
            hocbaService.getDetail(params).then(res => {
              if (res.data.code == 200) {
                this.lstMonDetail = res.data.data;
                this.lyDoGVCN = this.lstMonDetail.ghiChuGVCN ? this
                    .lstMonDetail.ghiChuGVCN : '';
                this.lydoHieuTruong = this.lstMonDetail.ghiChuHieuTruong ?
                    this.lstMonDetail.ghiChuHieuTruong : '';
              }
            }).catch((e) => {

            })

            this.thongBao('success', res.data.msg);
          } else {
            this.thongBao('error', res.data.msg);
          }

        }).catch((e) => {
          this.thongBao('warning', 'Lỗi kết nối API')
        })
      }).catch(() => {

      });


    },


    submitPermissionBoMon() {
      if (!this.lyDoBoMon) {
        this.$alert('Vui lòng nhập lý do', 'Thông báo', {
          confirmButtonText: 'Đóng',
          type: 'warning',
          center: true,
          callback: action => {

          }
        });
        return;
      }

      let params = {
        type: 1,
        ghiChu: this.lyDoBoMon,
        maMonHoc: this.dataBomon.maMonHoc,
        id: this.dataPermission.id,
      }

      this.$confirm('Xác nhận phân quyền cho GV bộ môn?', 'Warning', {
        confirmButtonText: 'Xác nhận',
        cancelButtonText: 'Hủy',
        center: true,
        type: 'warning'
      }).then(() => {
        hocbaService.permission(params).then(res => {
          if (res.data.code == 200) {
            this.showPopUpBoMon = false;

            let params = {
              id: this.dataPermission.id
            }
            hocbaService.getDetail(params).then(res => {
              if (res.data.code == 200) {
                this.lstMonDetail = res.data.data;
                this.lyDoGVCN = this.lstMonDetail.ghiChuGVCN ? this
                    .lstMonDetail.ghiChuGVCN : '';
                this.lydoHieuTruong = this.lstMonDetail.ghiChuHieuTruong ?
                    this.lstMonDetail.ghiChuHieuTruong : '';
              }
            }).catch((e) => {

            })

            this.thongBao('success', res.data.msg);
          } else {
            this.thongBao('error', res.data.msg);
          }

        }).catch((e) => {
          this.thongBao('warning', 'Lỗi kết nối API')
        })
      }).catch(() => {

      });


    },

    thongBao(t, e) {
      let msg = "";
      let cl = "";
      if (e) {
        msg = e;
      }
      let type = "success";
      if (t) {
        type = t
      }
      if (type == "success") {
        cl = "dts-noty-success"
      }
      if (type == "warning") {
        cl = "dts-noty-warning"
      }
      if (type == "error") {
        cl = "dts-noty-error"
      }
      if (type == "info") {
        cl = "dts-noty-info"
      }
      this.$message({
        customClass: cl,
        dangerouslyUseHTMLString: true,
        showClose: true,
        message: msg,
        type: t,
        duration: 3000
      });
    },

    phanQuyenBoMon(data) {
      this.lyDoBoMon = data.ghiChu ? data.ghiChu : '';
      this.dataBomon = data;
      this.showPopUpBoMon = true;
    },

    showError(err, file, fileList) {
      console.log(err, file, fileList)

    },

    permissionEdit(data) {
      this.dataPermission = data;
      this.lyDoGVCN = '';
      this.lydoHieuTruong = '';
      let params = {
        id: data.id
      }
      hocbaService.getDetail(params).then(res => {
        if (res.data.code == 200) {
          this.lstMonDetail = res.data.data;
          this.lyDoGVCN = this.lstMonDetail.ghiChuGVCN ? this.lstMonDetail.ghiChuGVCN : '';
          this.lydoHieuTruong = this.lstMonDetail.ghiChuHieuTruong ? this.lstMonDetail
              .ghiChuHieuTruong : '';
        }
      }).catch((e) => {

      })
      this.showPermissionEdit = true;
    },


    getHashUploadFileScan() {
      this.infoUploadFileZip.chunks = null;
      console.log('getHashUploadFileScan:')
      let uri = constant_api.hocsinh.getHashUpLoadFileScan;
      api.get(uri, {}).then(
          response => {
            console.log('reponse get hash:')
            console.log(response)
            if (response && response.data.code == 200) {
              this.hashCodeUpload = response.data.data
              // this.thongBao('success', 'Chỉnh sửa dữ liệu thành công')
              this.checkShowUploadBia(2);
            } else {
              this.thongBao('error', 'Hệ thống bận. Vui lòng thử lại sau ít phút.')
            }
            console.log('hashCodeUpload:')
            console.log(this.hashCodeUpload)
          }
      ).catch((e) => {
      })
    },
    checkShowUploadBia(type) {
      this.typeUpload = type;
      this.titleUploadFile = 'Upload file dữ liệu lớp học'
      this.base64 = null
      if (this.typeUpload == 2) {
        this.titleUploadFile = 'Tải file scan học bạ'
      }
      if (!this.searchForm.class) {
        this.$alert('Vui lòng chọn lớp học để thực hiện chức năng.', 'Thông báo', {
          type: 'warning',
          confirmButtonText: 'Đóng',
          center: true
        })
        this.showUploadBia = false;
      } else {
        this.showUploadBia = true;
      }
      if (this.$refs.uploadBia) {
        this.$refs.uploadBia.clearFiles();
      }
    },

    checkShowUploadKQHT() {
      if (!this.searchForm.class) {
        this.$alert('Vui lòng chọn lớp học để thực hiện chức năng.', 'Thông báo', {
          type: 'warning',
          confirmButtonText: 'Đóng',
          center: true
        })
        this.showUploadHocBa = false;
      } else {
        this.showUploadHocBa = true;
      }
    },

    uploadFileHocBa() {
      if (!this.dataUpload.length) {
        this.$alert('Chưa có file nào được chọn.', 'Thông báo', {
          type: 'warning',
          confirmButtonText: 'Đóng',
          center: true
        })
        return;
      }

      this.fullscreenLoading = true;

      let params = {
        data: LZString.compressToBase64(JSON.stringify(this.dataUpload)),
        maLop: this.searchForm.class,
        namHoc: this.searchForm.year
      }

      let uri = constant_api.hocsinh.uploadMultiFileKQHTPdf;

      api.post(uri, params).then(data => {
        if (data.data.code === 200) {
          this.$message({
            customClass: 'dts-noty-success',
            showClose: true,
            message: data.data.msg,
            type: 'success',
            duration: 3000
          });
          this.getDataCurenPage();
        } else {
          this.$message({
            customClass: 'dts-noty-error',
            showClose: true,
            message: data.data.msg,
            type: 'error',
            duration: 3000
          });
        }
        this.$refs.uploadFileHocBa.clearFiles();
        this.fullscreenLoading = false;
        this.showUploadHocBa = false
      }).catch((e) => {
        this.$message({
          customClass: 'dts-noty-error',
          showClose: true,
          message: 'Lỗi kết nối',
          type: 'error',
          duration: 3000
        });
        this.$refs.uploadFileHocBa.clearFiles();
        this.fullscreenLoading = false;
        this.showUploadHocBa = false
      })
    },

    uploadBiaHocBaBak() {
      if (!this.dataUpload.length) {
        this.$alert('Chưa có file nào được chọn.', 'Thông báo', {
          type: 'warning',
          confirmButtonText: 'Đóng',
          center: true
        })
        return;
      }

      this.fullscreenLoading = true;
      var dataForm = new FormData()

      dataForm.append('capHoc', this.searchForm.education)
      if (this.typeUpload == 2) {
        dataForm.append('namHoc', this.searchForm.year)
        dataForm.append('capHoc', this.searchForm.education)
        dataForm.append('maLop', this.searchForm.class)
        dataForm.append('khoiHoc', this.searchForm.grade)
      }
      if (this.dataFormScanBia && this.dataFormScanBia.length) {
        Array
            .from(Array(this.dataFormScanBia.length).keys())
            .map(x => {
              dataForm.append('file', this.dataFormScanBia[x], this.dataFormScanBia[x].name)
            })
      }
      let uri = constant_api.hocsinh.uploadScanBiaHocBa;
      if (this.typeUpload == 1) {
        uri = constant_api.hocsinh.uploadDuLieuMauScan;
      }
      api.post(uri, dataForm).then(
          response => {
            console.log('reponse upload:')
            console.log(response)
            if (response && response.data.rc == 0) {
              this.thongBao('success', 'Chỉnh sửa dữ liệu thành công')
            } else {
              this.thongBao('error', 'Hệ thống bận. Vui lòng thử lại sau ít phút.')
            }
            this.fullscreenLoading = false;
          }
      ).catch((e) => {
      })

      return;
      let params = {
        data: LZString.compressToBase64(JSON.stringify(this.dataUpload)),
        maLop: this.searchForm.class,
        namHoc: this.searchForm.year
      }

      // let uri = constant_api.hocsinh.uploadMultiFileBiaPdf;

      api.post(uri, params).then(data => {
        if (data.data.code === 200) {
          this.$message({
            customClass: 'dts-noty-success',
            showClose: true,
            message: data.data.msg,
            type: 'success',
            duration: 3000
          });
          this.getDataCurenPage();
        } else {
          this.$message({
            customClass: 'dts-noty-error',
            showClose: true,
            message: data.data.msg,
            type: 'error',
            duration: 3000
          });
        }
        this.$refs.uploadBia.clearFiles();
        this.fullscreenLoading = false;
        this.showUploadBia = false
      }).catch((e) => {
        this.$message({
          customClass: 'dts-noty-error',
          showClose: true,
          message: 'Lỗi kết nối',
          type: 'error',
          duration: 3000
        });
        this.$refs.uploadBia.clearFiles();
        this.fullscreenLoading = false;
        this.showUploadBia = false
      })
    },
    uploadBiaHocBa() {
      console.log('uploadBiaHocBa')
      if (this.typeUpload == 1) {
        this.uploadBiaFileMau();
      }
      if (this.typeUpload == 2) {
        this.uploadMutilFile();
      }
    },
    async readAsArrayBuffer(arr) {
      console.log('readAsArrayBuffer')
      this.fullscreenLoading = true;
      let totalIndex = [];
      for (let i = 0; i < arr.length; i++) {
        console.log('for:' + (i + 1))
        let paramsData = {
          index: i + 1,
          total: arr.length,
          data: arr[i],
          hash: this.hashCodeUpload,
          capHoc: this.searchForm.education,
          namHoc: this.searchForm.year,
          khoiHoc: this.searchForm.grade,
          maLop: this.searchForm.class,
        }
        await this.uploadFileBia(paramsData)

      }
      this.fullscreenLoading = false;
      this.thongBao('success', 'Upload file scan thành công')
      console.log('Hoàn thành')
    },
    async uploadFileBia(dataParams) {
      console.log('uploadFileBia')
      console.log(dataParams)
      let uri = constant_api.hocsinh.uploadScanBiaHocBa;
      await api.post(uri, dataParams).then(
          response => {
            console.log('reponse upload:')
            console.log(response)
            if (response && response.data.rc == 0) {
              // this.thongBao('success', 'Chỉnh sửa dữ liệu thành công')
            } else {
              // this.thongBao('error', 'Hệ thống bận. Vui lòng thử lại sau ít phút.')
            }
          }
      ).catch((e) => {
      })
    },
    splitBase64String(base64String, chunkSize) {
      const result = [];
      let currentIndex = 0;

      while (currentIndex < base64String.length) {
        result.push(base64String.substring(currentIndex, currentIndex + chunkSize));
        currentIndex += chunkSize;
      }

      return result;
    },
    uploadBiaFileMau() {
      console.log('uploadBiaHocBa')
      if (!this.base64) {
        this.$alert('Chưa có file nào được chọn.', 'Thông báo', {
          type: 'warning',
          confirmButtonText: 'Đóng',
          center: true
        })
        return;
      }
      let dataUpload = {
        data: this.base64,
      };
      let uri = constant_api.hocsinh.uploadScanBiaHocBa;
      if (this.typeUpload == 1) {
        uri = constant_api.hocsinh.uploadDuLieuMauScan;
      }
      this.fullscreenLoading = true;
      this.disabledDownLoad = false;
      api.post(uri, dataUpload).then(
          response => {
            console.log('reponse upload:')
            console.log(response)
            this.$refs.uploadBia.clearFiles();
            if (response && response.data.code == 200) {
              console.log('case success')
              this.thongBao('success', 'Upload file thành công')
              this.showUploadBia = false;
            } else {
              console.log('case error')
              this.thongBao('error', response.data.msg)
              this.createBaoLoi(response.data.data);
            }
            this.base64 = null;
            this.fullscreenLoading = false;
          }
      ).catch((e) => {
      })
    },
    createBaoLoi(data) {
      console.log('createBaoLoi')
      let errors = JSON.parse(LZString.decompressFromBase64(data));
      console.log('createBaoLoi')
      console.log(errors)
      let wb = new ExcelJS.Workbook();
      let sheet = wb.addWorksheet(this.workbook.SheetNames[0]);
      console.log('sheet')
      console.log(sheet)
      let r;
      console.error('listRows:')
      console.log(this.listRows)
      let merges = [];
      if (this.worksheet['!merges'])
        console.log('Case 1')
      console.log('worksheet:')
      console.log(this.worksheet['!merges'])
        this.worksheet['!merges'].forEach((range) => {
          var cell_range = XLSX.utils.encode_cell({
            c: range.s.c,
            r: range.s.r
          }) + ':' + XLSX.utils.encode_cell({
            c: range.e.c,
            r: range.e.r
          });
          console.log('cell_range:')
          console.log(cell_range)
          sheet.mergeCells(cell_range);
          merges.push(cell_range);
        });
      sheet.columns.forEach((col, index) => {
        if (this.worksheet['!cols'] && this.worksheet['!cols'][index])
          col.width = this.worksheet['!cols'][index].width;
      });
      var that = this;
      wb.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = 'Loi_import' + '.xlsx';
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    },
    uploadMutilAvatar() {

      this.fullscreenLoading = true;
      if (!this.dataUpload.length) {
        this.$alert('Chưa có file nào được chọn.', 'Thông báo', {
          type: 'warning',
          confirmButtonText: 'Đóng',
          center: true
        })
        return;
      }
      setTimeout(() => {
        this.duLieuUploadMutilFile = LZString.compressToBase64(JSON.stringify(this.dataUpload))
      }, 500)


    },

    readAsDataURL(name, file) {
      return new Promise((resolve, reject) => {
        let fr = new FileReader();

        fr.readAsDataURL(file);

        fr.onload = (e) => {
          resolve({
            id: name,
            data: e.target.result
          });
        };


      });
    },

    uploadFile(file, fileList) {
      this.disabledDownLoad = false;
      fileList = [];
      // this.$refs.uploadBia.clearFiles();
      console.log('uploadFile')
      console.log(fileList)
      console.log('fileList:')
      console.log(this.fileList)
      let allowedExtensions = /(\.xlsx|\.xls)$/i;
      if (this.typeUpload == 1) {
        if (!allowedExtensions.exec(file.name)) {
          this.$alert('Vui lòng chỉ tải lên file định dạng excel', 'Thông báo', {
            confirmButtonText: 'Đóng',
            type: 'error',
            callback: action => {
              this.$refs.uploadBia.clearFiles();
            }
          });
          return false;
        }
      } else {
        allowedExtensions = /(\.zip|\.zar)$/i;
        if (!allowedExtensions.exec(file.name)) {
          this.$alert('Vui lòng chỉ tải lên file định dạng file.zip', 'Thông báo', {
            confirmButtonText: 'Đóng',
            type: 'error',
            callback: action => {
              this.$refs.uploadBia.clearFiles();
            }
          });
          return false;
        }
      }
      this.fullscreenLoading = true;
      if (this.typeUpload == 1) {
        console.log('Case upload mẫu')
        let reader = new FileReader();
        reader.readAsArrayBuffer(file.raw);
        reader.onloadstart = (e) => {
        }
        reader.onload = (e) => {
          let data = new Uint8Array(e.target.result);
          this.builData(data);
          this.fullscreenLoading = false;
        }
      }
      if (this.typeUpload == 2) {
        console.log('case upload file bìa')
        const fileZip = event.target.files[0];
        const chunkSize = 3 * 1024 * 1024; // Kích thước của mỗi phần (1MB)
        const chunks = Math.ceil(fileZip.size / chunkSize); // Số lượng phần
        console.log('Số lượng')
        console.log(chunks)
        this.infoUploadFileZip.fileZip = fileZip;
        this.infoUploadFileZip.chunks = chunks;
        this.infoUploadFileZip.chunkSize = chunkSize;
        this.fullscreenLoading = false;
      }
    },
    async uploadMutilFile() {
      console.log('uploadMutilFile')
      this.fullscreenLoading = true;
      let fileZip = this.infoUploadFileZip.fileZip;
      let chunks = this.infoUploadFileZip.chunks;
      let chunkSize = this.infoUploadFileZip.chunkSize;
      let responUpload = null;
      let lastIndex = JSON.parse(JSON.stringify(chunks - 1))
      for (let i = 0; i < chunks; i++) {
        let start = i * chunkSize;
        let end = Math.min(start + chunkSize, fileZip.size);
        let chunk = fileZip.slice(start, end);
        let dataForm = new FormData()
        let index = JSON.parse(JSON.stringify(i))
        dataForm.append('file', chunk)
        dataForm.append('namHoc', this.searchForm.year)
        dataForm.append('capHoc', this.searchForm.education)
        dataForm.append('maLop', this.searchForm.class)
        dataForm.append('khoiHoc', this.searchForm.grade)
        dataForm.append('hashReq', this.hashCodeUpload)
        dataForm.append('maTruong', this.user.maTruong)
        dataForm.append('index', i)
        dataForm.append('total', chunks)
        let uri = constant_api.hocsinh.uploadScanBiaHocBa;
        this.disabledDownLoad = false;
        await api.postPort(uri, dataForm).then(
            response => {
              if (index == lastIndex) {
                responUpload = response;
              }

            }
        ).catch((e) => {
        })

      }
      this.fullscreenLoading = false;
      this.$refs.uploadBia.clearFiles();
      this.infoUploadFileZip.fileZip = null;
      this.infoUploadFileZip.chunks = null;
      this.infoUploadFileZip.chunkSize = null;
      if (responUpload.code == 200) {
        this.thongBao('success', 'Upload file scan thành công')
        this.showUploadBia = false;
      } else {
        this.thongBao('error', responUpload.msg)
      }
    },
    builData(data) {
      console.log('builData')
      console.log(data)
      this.workbook = XLSX.read(data, {
        type: 'array',
        cellDates: true,
        cellStyles: true
      });
      this.worksheet = this.workbook.Sheets[this.workbook.SheetNames[0]];
      this.listRows = this.sheet2Arr(this.worksheet, this.workbook);
      let params = [];
      this.listRows.forEach((row, index) => {
        let p = {};
        row.forEach((value, index) => {
          p[index + 1] = value.toString();
        });
        params.push(p);
      });
      console.log(params);
      this.base64 = LZString.compressToBase64(JSON.stringify(params))
      console.log('Base64 là:')
      this.disabledDownLoad = true;
      console.log(this.base64)
      this.fullscreenLoading = false;
    },
    sheet2Arr(sheet, workbook) {
      let result = [];
      let row;
      let rowNum;
      let colNum;
      let range = XLSX.utils.decode_range(sheet['!ref']) ? XLSX.utils.decode_range(sheet['!ref']) : 0;
      for (rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
        row = [];
        for (colNum = range.s.c; colNum <= range.e.c; colNum++) {
          var nextCell = sheet[
              XLSX.utils.encode_cell({
                r: rowNum,
                c: colNum
              })
              ];
          if (typeof nextCell === 'undefined') {
            row.push('');
          } else {
            if (nextCell.v instanceof Date) {
              let dateMode = workbook.Workbook.WBProps.date1904;
              let result = XLSX.SSF.format('DD/MM/YYYY', nextCell.v, {
                date1904: dateMode
              });
              row.push(result);
            } else {
              if (typeof nextCell.v === 'undefined') {
                row.push('');
              } else {
                row.push(nextCell.v);
              }
            }
          }
        }
        result.push(row);
      }
      return result;
    },
    uploadMutilAvatarChange(file, fileList) {
      this.dataUpload = [];
      if (fileList.length > 100) {
        this.$alert('Số lượng file đã vượt quá giới hạn cho phép là 100.', 'Thông báo', {
          type: 'warning',
          confirmButtonText: 'Đóng',
          center: true
        })
        return;
      }
      if (fileList.length) {
        let readers = [];
        this.fullscreenLoading = true
        fileList.forEach(item => {
          readers.push(this.readAsDataURL(item.name, item.raw));
          this.dataFormScanBia.push(item.raw)
        })
        Promise.all(readers).then((values) => {
          this.dataUpload = values;
          this.fullscreenLoading = false;
        });
      }
    },

    updateMutileImage() {
      this.showUpdateMutile = true;
    },
    dongBoDuLieu(hocsinh) {
      let uri = constant_api.school.dongBo;
      let params = {
        maHSs: [hocsinh.maHS],
        loaiDongBo: 4,
        namHoc: this.searchForm.year,
        maTruongs: [this.user.maTruong],
        maSo: this.user.maSo,
        maPhong: this.user.maPhong,
        nguoiDongBo: this.user.displayName,
        capHoc: this.searchForm.education,
      }
      this.$confirm('Xác nhận đồng bộ lại dữ liệu của học sinh?', 'Thông báo', {
        confirmButtonText: 'Đồng bộ',
        cancelButtonText: 'Hủy',
        confirmButtonClass: 'el-button--success',
      })
          .then(_ => {
            this.fullscreenLoading = true
            api.post(uri, params).then(data => {
              if (data.data.code === 200) {
                this.thongBao('success', data.data.msg)
                this.getDataCurenPage();
              } else {
                this.thongBao('error', data.data.msg)
              }
              this.fullscreenLoading = false
            }).catch((e) => {
              this.thongBao('error', 'Lỗi kết nối. Vui lòng thử lại')
            })
          })
          .catch(_ => {
          });

    },
    removeAvatar(hocsinh) {
      let uri = constant_api.hocsinh.removeAvatar;
      let params = {
        id: hocsinh.maHS,
        capHoc: hocsinh.capHoc
      }
      this.$confirm('Xác nhận xoá ảnh học sinh?', 'Thông báo', {
        confirmButtonText: 'Xoá ảnh',
        cancelButtonText: 'Hủy',
        confirmButtonClass: 'el-button--danger'
      })
          .then(_ => {
            api.post(uri, params).then(data => {
              if (data.data.code === 200) {
                this.$message({
                  customClass: 'dts-noty-success',
                  showClose: true,
                  message: data.data.msg,
                  type: 'success',
                  duration: 3000
                });
                this.getDataCurenPage();
              } else {
                this.$message({
                  customClass: 'dts-noty-error',
                  showClose: true,
                  message: data.data.msg,
                  type: 'error',
                  duration: 3000
                });
              }
            }).catch((e) => {
              this.$message({
                customClass: 'dts-noty-error',
                showClose: true,
                message: 'Lỗi kết nối',
                type: 'error',
                duration: 3000
              });
            })
          })
          .catch(_ => {
          });

    },

    uploadAvatarHocSinh(id, avatar) {
      let uri = constant_api.hocsinh.uploadAvatar;
      let params = {
        id: id,
        capHoc: this.searchForm.education,
        data: avatar
      }
      api.post(uri, params).then(data => {
        if (data.data.code === 200) {
          this.$message({
            customClass: 'dts-noty-success',
            showClose: true,
            message: data.data.msg,
            type: 'success',
            duration: 3000
          });
          this.getDataCurenPage();
        } else {
          this.$message({
            customClass: 'dts-noty-error',
            showClose: true,
            message: data.data.msg,
            type: 'error',
            duration: 3000
          });
        }
      }).catch((e) => {
        this.$message({
          customClass: 'dts-noty-error',
          showClose: true,
          message: 'Lỗi kết nối',
          type: 'error',
          duration: 3000
        });
      })
    },

    uploadAvatar(file) {
      let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      if (!allowedExtensions.exec(file.name)) {
        this.thongBao('error', 'Vui lòng chọn file ảnh.')
        return false;
      }
      let reader = new FileReader()
      reader.readAsDataURL(file.raw);
      reader.onload = (e) => {
        let data;
        if (typeof e.target.result === 'object') {
          data = window.URL.createObjectURL(new Blob([e.target.result]))
        } else {
          data = e.target.result
        }
        if (data) {
          this.uploadAvatarHocSinh(this.hocSinhSelect.maHS, data)
        }
      }
    },

    xemHocBa(e) {
      this.idHocBa = e;
      this.thongTinHocBa = true;
    },

    getCurrentMonth() {
      let d = new Date();
      return d.getMonth();
    },
    ...mapActions('teacher', [
      "clear",
      "updateHocBa",
      "uploadHocBa",
      "uploadFileBiaHocBa",
    ]),
    ...mapActions("hocba", [
      "getDetail",
      "saveTempKq",
      "getFile",
      "setDataHocSinh"
    ]),
    getDataCurenPage() {
      let params = {
        start: this.paginate.start,
        limit: this.paginate.limit,
        maLop: this.searchForm.class,
        namHoc: this.searchForm.year ? this.searchForm.year : '',
        capHoc: this.searchForm.education,
        khoiHoc: this.searchForm.grade,
        maHS: this.searchForm.student,
        trangThaiHocSinh: this.searchForm.trangThaiHocSinh,
        maSo: this.user.maSo,
        maPhong: this.user.maPhong,
        maTruong: this.user.maTruong,
      }
      this.getListHocBa(params);
    },
    getListHocBa(params) {
      console.log("Lấy danh sách học bạ:")
      console.log(params)

      try {
        let uri = constant_api.hocsinh.getListHS;
        console.log('uri: ' + uri)
        this.fullscreenLoading = true;
        api.get(uri, params).then(
            response => {
              console.log("Lấy ds học bạ trả về:")
              console.log(response);
              if (response.data.code == 200) {
                this.listHs = response.data.data;
                if (!this.listHs.list || this.listHs.list.length == 0) {
                  this.thongBao('error', 'Không tìm thấy dữ liệu')
                } else {
                  // this.thongBao('success', 'Lấy dữ liệu thành công')
                  const el = this.$refs.page_content;
                  if (el) {
                    el.scrollIntoView({behavior: "smooth"});
                  }
                }
              } else {
                this.thongBao('error', 'Không tìm thấy dữ liệu')
              }
              this.fullscreenLoading = false;
            }
        ).catch((e) => {
          // this.thongBao('success', 'Hệ thống đang cập nhật dữ liệu. Vui lòng chờ trong ít phút.');
          this.fullscreenLoading = false;
        })

      } catch (e) {
        this.thongBao('error', 'Vui lòng thao tác lại.')
      }
    },
    closeDialogAlert() {
      let select = this.listHs.list[this.indexSelect];
      this.listHocBa = select.hocBas ? select.hocBas : [];
      console.log(this.listHs);
      this.showDialogAlert = false;
    },
    chinhSuaNgayKyBia() {
      if (!this.infoUpdate.tenDiaDanhKyBia || this.infoUpdate.tenDiaDanhKyBia == '') {
        this.thongBao('error', 'Thiếu thông tin địa danh ký.')
        return;
      }
      if (!this.infoUpdate.ngayKyBia || this.infoUpdate.ngayKyBia == '') {
        this.thongBao('error', 'Thiếu thông tin ngày ký lý lịch.')
        return;
      }

      this.$confirm('Xác nhận chỉnh sửa thông tin học sinh: ' + this.infoUpdate.tenHS + ' - [' + this.infoUpdate.maHS + ']?', 'Thông báo', {
        confirmButtonText: 'Chỉnh sửa',
        cancelButtonText: 'Hủy',
        confirmButtonClass: 'el-button--warning',
      })
          .then(_ => {
            let uri = constant_api.hocsinh.capNhatNgayKyBia;
            if (this.sua_trang_thai) {
              uri = constant_api.hocsinh.suaTrangThai;
            }
            let params = {
              maLop: this.searchForm.class,
              hauToSoDangBo: this.infoUpdate.hauToSoDangBo,
              capHoc: this.infoUpdate.capHoc,
              maHS: this.infoUpdate.maHS,
              tenDiaDanhKyBia: this.infoUpdate.tenDiaDanhKyBia,
              trangThai: this.infoUpdate.trangThai,
              thoiGianVaoTruong: this.infoUpdate.ngayKyBia
            }
            this.fullscreenLoading = true;
            api.post(uri, params).then(
                response => {
                  console.log("Lấy ds học bạ trả về:")
                  console.log(response);
                  if (response.data.code == 200) {
                    this.thongBao('success', response.data.msg)
                    this.show_update_ngay_vao_truong = false;
                    this.infoUpdate = {};
                    this.getDataCurenPage();
                  } else {
                    this.thongBao('error', response.data.msg)
                  }
                  this.fullscreenLoading = false;
                  this.sua_trang_thai = false;
                }
            ).catch((e) => {
              this.fullscreenLoading = false;
            })
          })
          .catch(_ => {
          });

    },
    hienThiCapNhatNgayVaoTruong(item) {
      this.show_update_ngay_vao_truong = true;
      this.infoUpdate = JSON.parse(JSON.stringify(item));

    },
    showDialogInfoHocBa(item, index) {
      this.listHocBa = [];
      this.indexSelect = index;
      if (item && item.hocBas && item.hocBas.length > 0) {
        this.listHocBa = item.hocBas;
        this.showInfoHocba = true;
      } else {
        this.thongBao('error', 'Chưa có thông tin chi tiết')
      }
    },
    showDialogInfoHocBaLangSon(item, index) {
      console.log("Xem chi tiết học bạ Lạng Sơn")
      this.listHocBa = [];
      if (item && item.hocBas && item.hocBas.length > 0 && false) {
        this.listHocBa = item.hocBas;
        this.showInfoHocba = true;
      } else {
        this.thongBao('error', 'Chưa có thông tin chi tiết')
      }
      // let select = this.listHs.list[this.indexSelect];
      // this.listHocBa = select.hocBas ? select.hocBas : [];
      console.log(this.listHocBa);
      console.log('****')
      console.log(item)
    },
    addHocBa(data) {
      this.setDataHocSinh(data);
      // this.$router.push({name : 'ThemHocBa'})
      this.showAddHocBa = true
    },

    suaHocBa(data) {
      this.setDataHocSinh(data);
      this.showEditHocBa = true;
    },
    closeDialogEdit() {
      this.getDataCurenPage();

      this.showEditHocBa = false;
    },
    closeDialogCreate() {
      this.getDataCurenPage();
      this.showInfoHocba = false;
      this.showAddHocBa = false;
      this.thongTinHocBa = false;
    },
    onSizeExceeded(size) {
      const h = this.$createElement;
      this.$notify.error({
        title: 'Thông báo',
        message: `Vượt quá giới hạn ${this.customImageMaxSize}Mb!`
      });
    },

    onLoad(dataUri) {
      this.uploadHocBa({
        data: dataUri,
        id: this.dataSelect
      });
      console.log(this.dataSelect);
    },

    onBiaLoad(dataUri) {
      this.uploadFileBiaHocBa({
        data: dataUri,
        id: this.dataSelect.maHS,
        capHoc: this.dataSelect.loaiHocBa,
        namHoc: this.dataSelect.namHoc
      });
      console.log(this.dataSelect);
    },

    indexMethod(index) {
      return (this.paginate.start + index) + 1;
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.listFile.toggleRowSelection(row);
        });
      } else {
        this.$refs.listFile.clearSelection();
      }
    },
    getKhoiHoc() {
      this.optionGrade = [];
      if (this.searchForm.education == 1) {
        this.optionGrade = this.khoiCap1;
      } else if (this.searchForm.education == 2) {
        this.optionGrade = this.khoiCap2;
      } else if (this.searchForm.education == 452) {
        this.optionGrade = this.khoiCap2;
      } else if (this.searchForm.education == 3) {
        this.optionGrade = this.khoiCap3;
      } else if (this.searchForm.education == 36) {
        this.optionGrade = this.khoiCap3;
      } else if (this.searchForm.education == 12) {
        this.optionGrade = this.khoiCap1.concat(this.khoiCap2)
      } else if (this.searchForm.education == 23) {
        this.optionGrade = this.khoiCap2.concat(this.khoiCap3)
      } else if (this.searchForm.education == 236) {
        this.optionGrade = this.khoiCap2.concat(this.khoiCap3)
      } else if (this.searchForm.education == 13) {
        this.optionGrade = this.khoiCap1.concat(this.khoiCap3)
      } else if (this.searchForm.education == 1245) {
        this.optionGrade = this.khoiCap1.concat(this.khoiCap2)
      } else if (this.searchForm.education == 123) {
        this.optionGrade = this.khoiCap1.concat(this.khoiCap2).concat(this.khoiCap3)
      } else if (this.searchForm.education == 12345) {
        this.optionGrade = this.khoiCap1.concat(this.khoiCap2).concat(this.khoiCap3)
      } else if (this.searchForm.education == 1235) {
        this.optionGrade = this.khoiCap1.concat(this.khoiCap2).concat(this.khoiCap3)
      } else if (this.searchForm.education == 145) {
        this.optionGrade = this.khoiCap1
      } else if (this.searchForm.education == 14) {
        this.optionGrade = this.khoiCap1
      } else if (this.searchForm.education == 451) {
        this.optionGrade = this.khoiCap1
      } else {
        this.optionGrade = this.khoiCap1.concat(this.khoiCap2, this.khoiCap3)
      }
    },
    closeDialogOtp() {
      this.toggleSelection();
      this.showDialogOtp = false;
    },

    handleSelectionChange(val) {
      this.listHocBaCheck = val;
    },

    detailHocBaClose() {
      this.saveTempKq(null);
      this.showEditDiem = false;
    },
    saveHocBa() {
      this.showEditDiem = false
      if (!this.ketQuaHocTapTemp) {
        this.showEditDiem = false
      } else {
        this.updateHocBa({
          id: this.idEdit,
          ketQuaHocTaps: this.ketQuaHocTapTemp
        });
      }
    },
    submitSearch(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getHocBa(1)
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    getHocBa(select = 1) {
      this.showDialogOtp = false;
      let start = (select - 1) * this.paginate.limit ? (select - 1) * this.paginate.limit : 0;
      this.paginate.start = start;
      this.paginate.current = select;
      this.getDataCurenPage();
    },
    capHocChange() {
      console.log("CHọn cấp học")
      this.searchForm.student = '';
      this.searchForm.status = '';
      this.searchForm.grade = '';
      this.getKhoiHoc();
    },
    khoiHocChange() {
      this.searchForm.class = '';
      this.getlistLopHoc();
    },

    getTotalPage(number) {
      return Math.ceil(number / (this.paginate.limit));
    },

    getlistLopHoc() {
      let params = {
        start: 0,
        limit: 999,
        khoiHoc: this.searchForm.grade,
        namHoc: this.searchForm.year,
      }
      console.log(params)

      try {
        let uri = constant_api.hocsinh.getListLop;
        if (this.user.role == 1) {
          uri = constant_api.hocsinh.getListLopGvcn
        }
        console.log('uri: ' + uri)
        this.fullscreenLoading = true;
        this.danh_sach_lop_hoc = [];
        api.get(uri, params).then(
            response => {
              console.log("Lấy ds học bạ trả về:")
              console.log(response.data.data.list);
              if (response.data.code == 200) {
                this.danh_sach_lop_hoc = response.data.data.list;
                if (this.danh_sach_lop_hoc.length == 0) {
                  this.thongBao('error', 'Không tìm thấy danh sách lớp')
                }
              } else {
                this.thongBao('error', 'Không tìm thấy danh sách lớp')
              }
              this.fullscreenLoading = false;
            }
        ).catch((e) => {
          // this.thongBao('success', 'Hệ thống đang cập nhật dữ liệu. Vui lòng chờ trong ít phút.');
          this.fullscreenLoading = false;
        })

      } catch (e) {
        this.thongBao('error', 'Vui lòng thao tác lại.')
      }
      // this.getListLop({
      //   start: 0,
      //   limit: 999,
      //   khoiHoc: this.searchForm.grade,
      //   namHoc: this.searchForm.year,
      //   // lopHoc : this.searchForm.class
      // })
    },

    viewFile(item) {
      console.log('view:')
      console.log(item)
      let params = {
        "id":item.id,
        "maLop": item.maLop,
        "maHS": item.maHS,
        "isLoad": false,
        "userId": this.user.maTruong,
        "isDau": false,
        "isBia": false,
        "isQTHT": true,
        "isKQHT": false,
        "inNamCu": false,
        "isAnChuKy": false,
        "isInChanTrang": false,
        "isInRiengBia": false,
        "inDienChinhSach": false,
        "isAnAnhGVCN": false,
        "isAnNhanXet": false,
        "isInRiengThongTinHocSinh": false,
        "isInHocBaTrang": false,
        "inTrang": null,
        "isAnAnhHieuTruong": false,
        "isAnSoTrang": 0
      }
      this.fullscreenLoading = true;
      this.getFile(params)
    },

    openFancybox(id) {
      this.idEdit = id;
      this.getDetail({
        id: id
      })
      this.showEditDiem = true;
    },
    openFormOtp(id, type) {
      if (type) {
        this.typeKy = type;
      } else {
        this.typeKy = null;
      }
      this.hocbaDatas = [id];
      this.showDialogOtp = true;
    },
    openFormOtp2(ids, type) {
      if (type) {
        this.typeKy = type;
      } else {
        this.typeKy = null;
      }
      let datas = [];
      ids.map(item => {
        datas.push(item.id)
        return item;
      })
      this.hocbaDatas = datas;
      this.showDialogOtp = true;
    },
    openFancyBox(url) {
      $.fancybox.open({
        src: url,
        type: 'iframe',
      });
    },
    checkDuyet(item) {
      let role = this.user.role;
      if (role == 2) {
        if (item.trangThaiKyGVCN) {
          return true
        }
      } else if (role == 3) {
        if (item.trangThaiXacNhanGVCN) {
          return true;
        }
      }
      return false;
    },
    checkEdit(item) {
      let role = this.user.role;
      if (role == 1) {
        if (item.nguonDuLieu) {
          return true
        }
      } else if (role == 2) {
        if (item.nguonDuLieu) {
          return true;
        }
      }
      return false;
    },
    viewAddHocBa() {
      this.$router.push({
        name: "ThemHocBa"
      })
    },
    getFullYear() {
      let d = new Date();
      return d.getFullYear();
    },
    checkHocBa(item) {
      let html = '';
      if (!item.lops.length) {
        html = 'Đủ học bạ các năm.' + '<br/>';
      } else {
        html = '<div>Thiếu học bạ</div>';
        item.lops.map(o => {
          html += '<div>- Năm học: ' + o.namHoc + '. Lớp : ' + o.tenLop + '</div>'
          return o;
        });
      }

      if (item.thieuBiaHocBa == true) {
        // html = html + "Thiếu bìa học bạ"
      }

      return html;
    },
    getTrangThaiHocSinh(status) {
      if (status == 1) {
        return "Đang học";
      }

      if (status == 3) {
        return "Chuyển trường";
      }

      if (status == 4) {
        return "Bỏ học";
      }
      return "Trạng thái khác";
    },
    statusHocba(item) {
      return item.lops.length;
    },

  },
  mounted() {
    console.log('mount quản lý học bạ:')
    this.maxHeight = this.getHeightTable()
    this.searchForm.year = this.nam_hoc_hien_tai
    console.log('list_cap_hoc:')
    console.log(this.list_cap_hoc)
    if (this.list_cap_hoc.length > 1) {
      console.log('case 1')
      this.searchForm.education = this.list_cap_hoc[0].value
    } else {
      console.log('case 2')
      this.searchForm.education = this.list_cap_hoc[0].value
    }
    console.log(this.searchForm.education)
    this.getKhoiHoc();

  },
  watch: {
    duLieuUploadMutilFile(val) {
      if (val) {
        let params = {
          data: this.duLieuUploadMutilFile,
          capHoc: this.searchForm.education
        }

        let uri = constant_api.hocsinh.uploadMultile;
        api.post(uri, params).then(data => {
          if (data.data.code === 200) {
            this.$message({
              customClass: 'dts-noty-success',
              showClose: true,
              message: data.data.msg,
              type: 'success',
              duration: 3000
            });
            this.fullscreenLoading = false;
            this.duLieuUploadMutilFile = '';
            this.getDataCurenPage();
          } else {
            this.duLieuUploadMutilFile = '';
            this.$message({
              customClass: 'dts-noty-error',
              showClose: true,
              message: data.data.msg,
              type: 'error',
              duration: 3000
            });

            this.fullscreenLoading = false;
          }
          this.$refs.uploadMutile.clearFiles();
          this.showUpdateMutile = false
        }).catch((e) => {
          this.$message({
            customClass: 'dts-noty-error',
            showClose: true,
            message: 'Lỗi kết nối',
            type: 'error',
            duration: 3000
          });
          this.$refs.uploadMutile.clearFiles();
          this.showUpdateMutile = false
        })
      }
    },
    // listLop(val) {
    //   console.log("Danh sách lớp thay đổi:")
    //   console.log(val)
    //   if (val) {
    //     this.danh_sach_lop_hoc = val.list
    //   }
    // },
    updateSuccess(val) {
      if (val == 'success') {
        dialog.showDialog('Thông báo', this.updateMessage, () => {
          this.saveTempKq(null);
          this.showEditDiem = false;
          this.clear();
        })
      } else if (val == 'failure') {
        dialog.showDialog('Thông báo', this.updateMessage, () => {
          this.saveTempKq(null);
          this.showEditDiem = false;
          this.clear();
        })
      }
    },
    fileDetail(val) {
      if (this.fileStatus == 2) {
        this.fullscreenLoading = false;
        let linkPfd = this.fileDetail + '#toolbar=0';
        this.openFancyBox(linkPfd)
      }
    },
    uploadStatus(val) {
      if (val) {
        this.getDataCurenPage();
        this.messageAlert = val.message;
        this.showDialogAlert = true;

      }
    },
  }
}
</script>

<style scoped>
.btn__table_action button {
  padding: 8px 10px;
}

.el-form-item {
  width: 100%
}
</style>