var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true, lock: true },
        },
      ],
      staticClass: "page page-file file detailHocBa",
      attrs: { id: "detailHocBa" },
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "box-upload-file" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { xs: 12, sm: 6, md: 4, lg: 4 } },
                [
                  _c("label", [_vm._v("Cấp học")]),
                  _vm._v(" "),
                  _c("eselect", {
                    attrs: {
                      disabled: _vm.list_cap_hoc.length == 1,
                      collapseTags: "",
                      placeholder: "Chọn",
                      filterable: "",
                      data: _vm.list_cap_hoc,
                      fields: ["name", "value"],
                    },
                    model: {
                      value: _vm.searchForm.capHoc,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchForm, "capHoc", $$v)
                      },
                      expression: "searchForm.capHoc",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { xs: 12, sm: 6, md: 4, lg: 4 } },
                [
                  _c("label", [_vm._v("Mã giáo viên")]),
                  _vm._v(" "),
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "Nhập..." },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.submitSearch("searchForm")
                      },
                    },
                    model: {
                      value: _vm.searchForm.maGV,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchForm, "maGV", $$v)
                      },
                      expression: "searchForm.maGV",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { xs: 12, sm: 6, md: 4, lg: 4 } },
                [
                  _c("label", [_vm._v("Tên giáo viên")]),
                  _vm._v(" "),
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "Nhập..." },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.submitSearch("searchForm")
                      },
                    },
                    model: {
                      value: _vm.searchForm.tenGV,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchForm, "tenGV", $$v)
                      },
                      expression: "searchForm.tenGV",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { xs: 12, sm: 6, md: 4, lg: 4 } },
                [
                  _c("div", { staticStyle: { color: "transparent" } }, [
                    _vm._v("Tìm kiếm"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "m-0",
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.getGiaoVien(1)
                        },
                      },
                    },
                    [_vm._v("Tìm kiếm\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          false
            ? _c(
                "el-form",
                {
                  ref: "searchForm",
                  attrs: {
                    inline: "",
                    "label-position": "top",
                    model: _vm.searchForm,
                    "hide-required-asterisk": true,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { xs: 12, sm: 6, md: 4, lg: 4, label: "Cấp học" },
                    },
                    [
                      _c("eselect", {
                        attrs: {
                          disabled: _vm.list_cap_hoc.length == 1,
                          collapseTags: "",
                          placeholder: "Chọn",
                          filterable: "",
                          data: _vm.list_cap_hoc,
                          fields: ["name", "value"],
                        },
                        model: {
                          value: _vm.searchForm.capHoc,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "capHoc", $$v)
                          },
                          expression: "searchForm.capHoc",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        xs: 12,
                        sm: 6,
                        md: 4,
                        lg: 4,
                        label: "Mã giáo viên",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "Mã giáo viên" },
                        on: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.submitSearch("searchForm")
                          },
                        },
                        model: {
                          value: _vm.searchForm.maGV,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "maGV", $$v)
                          },
                          expression: "searchForm.maGV",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        xs: 12,
                        sm: 6,
                        md: 4,
                        lg: 4,
                        label: "Tên giáo viên",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "Tên giáo viên" },
                        on: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.submitSearch("searchForm")
                          },
                        },
                        model: {
                          value: _vm.searchForm.tenGV,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "tenGV", $$v)
                          },
                          expression: "searchForm.tenGV",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-top": "40px" },
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submitSearch("searchForm")
                            },
                          },
                        },
                        [_vm._v("Tìm kiếm\n        ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              false
                ? _c("eselect", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      size: "small",
                      collapseTags: "",
                      placeholder: "Bản ghi/trang",
                      filterable: "",
                      data: _vm.list_limit_paginate,
                      fields: ["name", "value"],
                    },
                    on: {
                      change: function ($event) {
                        return _vm.submitSearch("searchForm")
                      },
                    },
                    model: {
                      value: _vm.paginate.limit,
                      callback: function ($$v) {
                        _vm.$set(_vm.paginate, "limit", $$v)
                      },
                      expression: "paginate.limit",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "text-right" },
        [
          _c(
            "el-tooltip",
            { attrs: { placement: "top" } },
            [
              _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                _vm._v(
                  "Nộp CTS của " +
                    _vm._s(_vm.danhSachGiaoVienCheck.length) +
                    " giáo viên đã chọn"
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    disabled:
                      !_vm.danhSachGiaoVienCheck ||
                      _vm.danhSachGiaoVienCheck.length == 0,
                    type: "success",
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.showDanhSachGiaoVienXoa($event)
                    },
                  },
                },
                [_vm._v("\n       Nộp chứng thư số\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "page__content" }, [
        _c(
          "div",
          { staticClass: "listNew mb-5" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loadingTable,
                    expression: "loadingTable",
                  },
                ],
                ref: "listGiaoVien",
                staticStyle: { width: "100%" },
                attrs: {
                  fit: "",
                  border: "",
                  lazy: true,
                  data: _vm.listGiaoVien ? _vm.listGiaoVien.list : [],
                  "max-height": _vm.maxHeight,
                },
                on: { "selection-change": _vm.handleSelectionChange },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    width: "50",
                    align: "center",
                    "header-align": "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function (scope) {
                        return [
                          _c("el-checkbox", {
                            on: { change: _vm.ChonToanBoGiaoVien },
                            model: {
                              value: _vm.CheckAllGiaoVien,
                              callback: function ($$v) {
                                _vm.CheckAllGiaoVien = $$v
                              },
                              expression: "CheckAllGiaoVien",
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-checkbox", {
                            on: {
                              change: function ($event) {
                                return _vm.chonMoiGiaoVien(scope.row)
                              },
                            },
                            model: {
                              value: scope.row.check,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "check", $$v)
                              },
                              expression: "scope.row.check",
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "STT",
                    width: "50",
                    type: "index",
                    "class-name": "text-center",
                    index: _vm.indexMethod,
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "Mã giáo viên",
                    property: "maGV",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(scope.row.maGV) +
                              "\n            "
                          ),
                          scope.row.linkChuKy
                            ? _c("div", [
                                _c("iframe", {
                                  staticStyle: { display: "contents" },
                                  attrs: {
                                    height: "1",
                                    width: "1",
                                    src: scope.row.linkChuKy,
                                  },
                                }),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    "header-align": "center",
                    label: "Tên giáo viên",
                    property: "tenGV",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    "header-align": "center",
                    label: "Email",
                    property: "email",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    label: "Số điện thoại",
                    property: "soDienThoai",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    align: "left",
                    "header-align": "center",
                    label: "Chức vụ",
                    property: "tenGV",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.getLoaiCB(scope.row.chucVu)) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    align: "left",
                    "header-align": "center",
                    label: "Trạng thái",
                    property: "trangThaiChuKy",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.getTrangThaiGV(scope.row.trangThai)) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { "header-align": "center", label: "Chữ ký" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.linkChuKy && scope.row.linkChuKy != null
                            ? _c(
                                "div",
                                [
                                  _c("el-image", {
                                    attrs: { src: scope.row.linkChuKy },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.xemAnh(scope.row.linkChuKy)
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    fixed: "right",
                    align: "left",
                    "header-align": "center",
                    "min-width": "100",
                    label: "Hành động",
                    property: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            { staticClass: "btn__table_action" },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "ml-1 mb-1",
                                  attrs: {
                                    content: "Tải lên chữ ký",
                                    placement: "top",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    staticClass: "mb-1",
                                    attrs: {
                                      size: "mini",
                                      type: "success",
                                      icon: "el-icon-upload",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.viewUpload(scope.row)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              scope.row.trangThaiChuKy == -1
                                ? _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "ml-1 mb-1",
                                      attrs: {
                                        content: "Duyệt chữ ký",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c("el-button", {
                                        staticClass: "mb-1",
                                        attrs: {
                                          size: "mini",
                                          icon: "el-icon-check",
                                          type: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.updateStatusChuKy(
                                              scope.row,
                                              1
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.trangThaiChuKy == 1
                                ? _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "ml-1 mb-1",
                                      attrs: {
                                        content: "Từ chối duyệt chữ ký",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c("el-button", {
                                        staticClass: "mb-1 ",
                                        attrs: {
                                          size: "mini",
                                          icon: "el-icon-close",
                                          type: "danger",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.updateStatusChuKy(
                                              scope.row,
                                              2
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "ml-1 mb-1",
                                  attrs: {
                                    content: "Cập nhật thông tin",
                                    placement: "top",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    staticClass: "mb-1",
                                    attrs: {
                                      size: "mini",
                                      type: "warning",
                                      icon: "el-icon-edit-outline",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.updateInfo(scope.row)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.listGiaoVien && _vm.listGiaoVien.total
              ? _c(
                  "div",
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c("chonSoLuong", {
                              attrs: { show: _vm.paginate.limit },
                              on: {
                                chonXongSoLuong: function ($event) {
                                  return _vm.ChonSoLuongBanGhi($event)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { staticClass: "text-right", attrs: { span: 16 } },
                          [
                            _c("small", { staticClass: "pd-r-10" }, [
                              _c("i", [
                                _vm._v(
                                  "Danh sách có " +
                                    _vm._s(_vm.listGiaoVien.total) +
                                    " giáo viên "
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _vm.listGiaoVien && _vm.listGiaoVien.total
                              ? _c("pagination", {
                                  attrs: {
                                    value: _vm.paginate.current,
                                    "page-count": _vm.getTotalPage(
                                      _vm.listGiaoVien.total
                                    ),
                                    "click-handler": _vm.getGiaoVien,
                                    "prev-text": "Trước",
                                    "next-text": "Sau",
                                    "container-class": "el-pager",
                                    pageClass: "number",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "uploadSignature",
          attrs: {
            title: "Tải lên chữ ký",
            center: "",
            visible: _vm.dialogUploadSignatureVisible,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            "custom-class": "thongTinKyTheoMonHoc",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogUploadSignatureVisible = $event
            },
          },
        },
        [
          _vm.dialogUploadSignatureVisible
            ? _c("crop-image", {
                attrs: {
                  "style-box": "width: 400px; height: 250px;",
                  option: _vm.optionCrope,
                  showCrop: _vm.dialogUploadSignatureVisible,
                },
                on: {
                  goStep: _vm.goStep,
                  uploadLuon: _vm.uploadLuon,
                  getBase64: _vm.getBase64,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogUploadSignatureVisible = false
                    },
                  },
                },
                [_vm._v("Hủy")]
              ),
              _vm._v(" "),
              _vm.step == 3
                ? _c(
                    "el-button",
                    {
                      attrs: { disabled: !_vm.imageSignature, type: "primary" },
                      on: { click: _vm.uploadSignature },
                    },
                    [_vm._v("Xác nhận")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "uploadSignature",
          attrs: {
            title: "Cập nhật thông tin cán bộ - [" + _vm.editData.maGV + "]",
            top: "10vh",
            center: "",
            visible: _vm.showEdit,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            "custom-class": "themMoiGiaoVien thongTinKyTheoMonHoc",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showEdit = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "upDateForm",
              attrs: { model: _vm.editData, rules: _vm.rules },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { staticClass: "mb-2", attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Họ tên", prop: "tenGV" } },
                        [
                          _c("el-input", {
                            attrs: { clearable: "", placeholder: "Nhập" },
                            model: {
                              value: _vm.editData.tenGV,
                              callback: function ($$v) {
                                _vm.$set(_vm.editData, "tenGV", $$v)
                              },
                              expression: "editData.tenGV",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "mb-2", attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Email", prop: "email" } },
                        [
                          _c("el-input", {
                            attrs: { clearable: "", placeholder: "Nhập" },
                            model: {
                              value: _vm.editData.email,
                              callback: function ($$v) {
                                _vm.$set(_vm.editData, "email", $$v)
                              },
                              expression: "editData.email",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "mb-2", attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Số điện thoại",
                            prop: "soDienThoai",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { clearable: "", placeholder: "Nhập" },
                            model: {
                              value: _vm.editData.soDienThoai,
                              callback: function ($$v) {
                                _vm.$set(_vm.editData, "soDienThoai", $$v)
                              },
                              expression: "editData.soDienThoai",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "mb-2", attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Chức vụ" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "w-100",
                              attrs: { clearable: "", placeholder: "Chọn" },
                              model: {
                                value: _vm.editData.chucVu,
                                callback: function ($$v) {
                                  _vm.$set(_vm.editData, "chucVu", $$v)
                                },
                                expression: "editData.chucVu",
                              },
                            },
                            _vm._l(_vm.chucVuGv, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "mb-2", attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Cấp học" } },
                        [
                          _c("eselect", {
                            staticClass: "w-100",
                            attrs: {
                              clearable: "",
                              multiple: "",
                              placeholder: "Chọn",
                              filterable: "",
                              data: _vm.list_cap_hoc,
                              fields: ["name", "value"],
                            },
                            model: {
                              value: _vm.editData.capHocs,
                              callback: function ($$v) {
                                _vm.$set(_vm.editData, "capHocs", $$v)
                              },
                              expression: "editData.capHocs",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "mb-2", attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Trạng thái" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "w-100",
                              attrs: { clearable: "", placeholder: "Chọn" },
                              model: {
                                value: _vm.editData.trangThai,
                                callback: function ($$v) {
                                  _vm.$set(_vm.editData, "trangThai", $$v)
                                },
                                expression: "editData.trangThai",
                              },
                            },
                            _vm._l(_vm.statusGv, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "mb-2", attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Loại chữ ký số" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "w-100",
                              attrs: {
                                clearable: "",
                                multiple: "",
                                placeholder: "Chọn",
                              },
                              on: { change: _vm.chonKieuKy },
                              model: {
                                value: _vm.editData.typeSign,
                                callback: function ($$v) {
                                  _vm.$set(_vm.editData, "typeSign", $$v)
                                },
                                expression: "editData.typeSign",
                              },
                            },
                            _vm._l(_vm.list_type_sign, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.checkInArray(6, _vm.editData.typeSign)
                    ? _c(
                        "el-col",
                        { staticClass: "mb-2", attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Tài khoản SmartCA VNPT" } },
                            [
                              _c("el-input", {
                                attrs: { clearable: "", placeholder: "Nhập" },
                                model: {
                                  value: _vm.editData.usernameCAVNPT,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.editData,
                                      "usernameCAVNPT",
                                      $$v
                                    )
                                  },
                                  expression: "editData.usernameCAVNPT",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.checkInArray(5, _vm.editData.typeSign)
                    ? _c(
                        "el-col",
                        { staticClass: "mb-2", attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Tài khoản SmartCA Viettel (MySign)",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { clearable: "", placeholder: "Nhập" },
                                model: {
                                  value: _vm.editData.usernameCAVT,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.editData, "usernameCAVT", $$v)
                                  },
                                  expression: "editData.usernameCAVT",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.checkInArray(4, _vm.editData.typeSign)
                    ? _c(
                        "el-col",
                        { staticClass: "mb-2", attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Số Serial Chứng Thư Số" } },
                            [
                              _c("el-input", {
                                attrs: { clearable: "", placeholder: "Nhập" },
                                model: {
                                  value: _vm.editData.serialNumberUsbToken,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.editData,
                                      "serialNumberUsbToken",
                                      $$v
                                    )
                                  },
                                  expression: "editData.serialNumberUsbToken",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "mb-2", attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Khối đóng dấu" } },
                        [
                          _c("eselect", {
                            attrs: {
                              multiple: "",
                              collapseTags: "",
                              placeholder: "Chọn",
                              filterable: "",
                              data: _vm.danh_sach_khoi_hoc,
                              fields: ["label", "value"],
                            },
                            model: {
                              value: _vm.editData.khoiHocDongDau,
                              callback: function ($$v) {
                                _vm.$set(_vm.editData, "khoiHocDongDau", $$v)
                              },
                              expression: "editData.khoiHocDongDau",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "pt-5 text-center" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.resetForm("upDateForm")
                        },
                      },
                    },
                    [_vm._v("Đóng")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "warning" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("upDateForm")
                        },
                      },
                    },
                    [_vm._v("Cập nhật")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Ảnh chữ ký",
            width: "30%",
            center: "",
            visible: _vm.hienThiAnh,
            "custom-class": "thongTinKyTheoMonHoc",
          },
          on: {
            "update:visible": function ($event) {
              _vm.hienThiAnh = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "demo-image__lazy" },
            [
              _c("el-image", {
                staticStyle: { width: "100%" },
                attrs: { src: _vm.linkAnh, title: "Ảnh chữ ký", lazy: "" },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Danh sách giáo viên nộp chứng thư số",
            center: "",
            visible: _vm.show_list_delete,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.show_list_delete = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.danhSachGiaoVienCheck, border: "" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  align: "center",
                  label: "STT",
                  width: "50",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { align: "center", prop: "maGV", label: "Mã giáo viên" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  "header-align": "center",
                  align: "left",
                  prop: "tenGV",
                  label: "Tên giáo viên",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.show_list_delete = false
                    },
                  },
                },
                [_vm._v("Đóng")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "success" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.checkXoaDuLieuGiaoVien($event)
                    },
                  },
                },
                [_vm._v("Nộp dữ liệu")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "uploadSignature",
          attrs: {
            title: "Thêm mới thông tin cán bộ",
            top: "10vh",
            center: "",
            visible: _vm.showAdd,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            "custom-class": "themMoiGiaoVien",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showAdd = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addDataForm",
              attrs: { model: _vm.addNewData, rules: _vm.rules },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Họ tên", prop: "tenGV" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "Nhập họ tên..." },
                            model: {
                              value: _vm.addNewData.tenGV,
                              callback: function ($$v) {
                                _vm.$set(_vm.addNewData, "tenGV", $$v)
                              },
                              expression: "addNewData.tenGV",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Email", prop: "email" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "Nhập email..." },
                            model: {
                              value: _vm.addNewData.email,
                              callback: function ($$v) {
                                _vm.$set(_vm.addNewData, "email", $$v)
                              },
                              expression: "addNewData.email",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Số điện thoại",
                            prop: "soDienThoai",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "Nhập số điện thoại..." },
                            model: {
                              value: _vm.addNewData.soDienThoai,
                              callback: function ($$v) {
                                _vm.$set(_vm.addNewData, "soDienThoai", $$v)
                              },
                              expression: "addNewData.soDienThoai",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Chức vụ" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "w-100",
                              attrs: { placeholder: "Chọn chức vụ" },
                              model: {
                                value: _vm.addNewData.chucVu,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addNewData, "chucVu", $$v)
                                },
                                expression: "addNewData.chucVu",
                              },
                            },
                            _vm._l(_vm.chucVuGv, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Cấp học" } },
                        [
                          _c("eselect", {
                            staticClass: "w-100",
                            attrs: {
                              multiple: "",
                              placeholder: "Chọn",
                              filterable: "",
                              data: _vm.list_cap_hoc,
                              fields: ["name", "value"],
                            },
                            model: {
                              value: _vm.addNewData.capHocs,
                              callback: function ($$v) {
                                _vm.$set(_vm.addNewData, "capHocs", $$v)
                              },
                              expression: "addNewData.capHocs",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Trạng thái" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "w-100",
                              attrs: { placeholder: "Chọn trạng thái" },
                              model: {
                                value: _vm.addNewData.trangThai,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addNewData, "trangThai", $$v)
                                },
                                expression: "addNewData.trangThai",
                              },
                            },
                            _vm._l(_vm.statusGv, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Kiểu ký" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "w-100",
                              attrs: { placeholder: "--Chọn kiểu ký--" },
                              model: {
                                value: _vm.addNewData.typeSign,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addNewData, "typeSign", $$v)
                                },
                                expression: "addNewData.typeSign",
                              },
                            },
                            _vm._l(
                              [
                                { label: "SIMCA", value: 2 },
                                { label: "USB TOKEN", value: 4 },
                              ],
                              function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Mật khẩu", prop: "passWord" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "Nhập mật khẩu...",
                              "show-password": "",
                            },
                            model: {
                              value: _vm.addNewData.passWord,
                              callback: function ($$v) {
                                _vm.$set(_vm.addNewData, "passWord", $$v)
                              },
                              expression: "addNewData.passWord",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Nhập lại mật khẩu",
                            prop: "rePassWord",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              "show-password": "",
                              placeholder: "Nhập lại mật khấu...",
                            },
                            model: {
                              value: _vm.addNewData.rePassWord,
                              callback: function ($$v) {
                                _vm.$set(_vm.addNewData, "rePassWord", $$v)
                              },
                              expression: "addNewData.rePassWord",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { staticClass: "text-center pt-5" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("addDataForm")
                        },
                      },
                    },
                    [_vm._v("Thêm mới")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.showAdd = false
                        },
                      },
                    },
                    [_vm._v("Đóng")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "page__title d-flex align-items-center justify-content-between",
      },
      [_c("h1", { staticClass: "title" }, [_vm._v("Danh sách cán bộ")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "file--title" }, [
      _c("h5", { staticClass: "title" }, [_vm._v("Danh sách giáo viên")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }