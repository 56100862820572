import util from "../../util";

export default function auth ({ next, store }){
    // let token = localStorage.getItem('token');
    let tokenCookie = util.getCookie('token');
    let token = sessionStorage.getItem("token");
    if(!token &&!tokenCookie){
        console.log('Không có token')
        return next({
            name: 'Login'
        })
    }
    console.log('Có token')
    return next()
}
