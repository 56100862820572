var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.spinning,
          expression: "spinning",
          modifiers: { fullscreen: true, lock: true },
        },
      ],
      class:
        "wrapper " +
        (_vm.menuOpen ? "menu-open" : "menu-close") +
        " " +
        (_vm.menuMobileOpen ? "mobile-open" : "mobile-close"),
      attrs: {
        "element-loading-text": "Đang tải dữ liệu...",
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "rgba(0, 0, 0, 0.8)",
      },
    },
    [
      _c(
        "div",
        { staticClass: "sidebar desktop" },
        [
          _c(
            "el-aside",
            { staticClass: "navbar__full", staticStyle: { width: "250px" } },
            [
              _c(
                "div",
                {
                  staticClass: "menu-together",
                  staticStyle: {
                    position: "fixed",
                    top: "60px",
                    left: "225px",
                  },
                },
                [
                  _c(
                    "el-link",
                    {
                      attrs: { underline: false },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.menuTogether(!_vm.menuOpen)
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "circle-1" }, [
                        _c("span", { staticClass: "circle-2 " }, [
                          _vm.menuOpen
                            ? _c("i", { staticClass: "el-icon-d-arrow-left" })
                            : _c("i", { staticClass: "el-icon-d-arrow-right" }),
                        ]),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("user-info", { on: { loadPage: _vm.loadLaiTrang } }),
              _vm._v(" "),
              _c("desktop", {
                attrs: { open: true },
                on: { loadPage: _vm.loadLaiTrang },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-aside",
            { staticClass: "navbar__icon", staticStyle: { width: "78px" } },
            [
              _c(
                "div",
                {
                  staticClass: "menu-together",
                  staticStyle: { position: "fixed", top: "60px", left: "53px" },
                },
                [
                  _c(
                    "el-link",
                    {
                      attrs: { underline: false },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.menuTogether(!_vm.menuOpen)
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "circle-1" }, [
                        _c("span", { staticClass: "circle-2 " }, [
                          _vm.menuOpen
                            ? _c("i", { staticClass: "el-icon-d-arrow-left" })
                            : _c("i", { staticClass: "el-icon-d-arrow-right" }),
                        ]),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("user-info", { on: { loadPage: _vm.loadLaiTrang } }),
              _vm._v(" "),
              _c("desktop", {
                attrs: { open: false },
                on: { loadPage: _vm.loadLaiTrang },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "sidebar mobile" },
        [
          _c(
            "div",
            {
              staticClass: "overlay",
              on: {
                click: function ($event) {
                  if ($event.target !== $event.currentTarget) {
                    return null
                  }
                  return _vm.openMobile(false)
                },
              },
            },
            [
              _c(
                "el-link",
                {
                  staticClass: "mobile--close",
                  attrs: { underline: false },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.openMobile(false)
                    },
                  },
                },
                [_c("i", { staticClass: "el-icon-circle-close" })]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-aside",
            { staticClass: "navbar__full", staticStyle: { width: "250px" } },
            [
              _c("user-info", { on: { loadPage: _vm.loadLaiTrangMobile } }),
              _vm._v(" "),
              _c("desktop", {
                attrs: { open: true },
                on: { loadPage: _vm.loadLaiTrangMobile },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-container",
        { staticClass: "wrapper-content" },
        [
          _c("el-header", { staticClass: "header" }, [_c("Header")], 1),
          _vm._v(" "),
          false
            ? _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "marquee",
                        {
                          staticStyle: { "padding-top": "15px" },
                          attrs: { behavior: "", direction: "" },
                        },
                        [
                          _c("b", { staticStyle: { color: "blue" } }, [
                            _vm._v("Thông báo:"),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              "Nội dung file kí XML khối 1.2.3.4 có cập nhật lại trường dữ liệu đảm bảo tra cứu dữ liệu sau này\n            tiện lợi hơn, Quý thầy cô vui lòng kí lại học bạ đã được ký trước thời điểm 20h00 ngày 24/05/2024. Tính năng\n            ký sẽ được mở lại vào lúc 20h00 ngày 24/05/2024. Trân trọng cảm ơn!"
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "fade", mode: "out-in" } },
            [_vm.showContent ? _vm._t("default") : _vm._e()],
            2
          ),
          _vm._v(" "),
          _c(
            "el-footer",
            { staticStyle: { background: "whitesmoke" } },
            [
              _c(
                "el-col",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { staticClass: "text-center", attrs: { span: 24 } },
                    [
                      _c("span", { staticClass: "copy-right" }, [
                        _vm._v(
                          "\n            © Copyright 2022. Hệ thống Học bạ số. Phát triển bởi Viettel\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "table",
                          {
                            staticClass: "login-footer",
                            staticStyle: { margin: "0 auto" },
                          },
                          [
                            _c("tbody", [
                              _c("tr", [
                                _c("td", [
                                  _c("div", { staticClass: "support-text" }, [
                                    _c("div", { staticClass: "purple" }, [
                                      _c("strong", [
                                        _c("span", { staticClass: "fw-400" }, [
                                          _vm._v("TƯ VẤN VÀ HỖ TRỢ"),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "strong",
                                          { staticClass: "orange f-16" },
                                          [_vm._v("18008000")]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("i", { staticClass: "grey" }, [
                                        _vm._v("Nhánh 2 (miễn phí)"),
                                      ]),
                                    ]),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-dialog",
                {
                  staticClass: "updateInfoTruong",
                  attrs: {
                    title: "Đổi mật khẩu",
                    width: "50%",
                    visible: _vm.show_update_pass,
                    "show-close": false,
                    "close-on-press-escape": false,
                    "close-on-click-modal": false,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.show_update_pass = $event
                    },
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { "margin-bottom": "30px" },
                          attrs: { span: 24 },
                        },
                        [
                          _c("p", [
                            _c("b", { staticStyle: { color: "blue" } }, [
                              _vm._v("Thông báo:"),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(
                                "Bạn đang sử dụng mật khẩu mặc định. Vui lòng thay đổi mật khẩu để đảm bảo an toàn thông tin."
                              ),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("label", [_vm._v("Mật khẩu mới")]),
                          _vm._v(" "),
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              "show-password": "",
                              placeholder: "Nhập",
                            },
                            model: {
                              value: _vm.matKhau.moi,
                              callback: function ($$v) {
                                _vm.$set(_vm.matKhau, "moi", $$v)
                              },
                              expression: "matKhau.moi",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("label", [_vm._v("Xác nhận mật khẩu mới")]),
                          _vm._v(" "),
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              "show-password": "",
                              placeholder: "Nhập ",
                            },
                            model: {
                              value: _vm.matKhau.nhapLai,
                              callback: function ($$v) {
                                _vm.$set(_vm.matKhau, "nhapLai", $$v)
                              },
                              expression: "matKhau.nhapLai",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "warning" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.xacNhanThayDoiMatKhau()
                            },
                          },
                        },
                        [_vm._v("Lưu")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }