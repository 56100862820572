var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-input",
    {
      attrs: {
        type: _vm.showPassword ? "text" : "password",
        clearable: "",
        placeholder: _vm.placeholder,
      },
      on: { change: _vm.handleChange },
      model: {
        value: _vm.modelValue,
        callback: function ($$v) {
          _vm.modelValue = $$v
        },
        expression: "modelValue",
      },
    },
    [
      _c("font-awesome-icon", {
        attrs: { slot: "suffix", icon: _vm.showPassword ? "eye-slash" : "eye" },
        on: { click: _vm.togglePasswordVisibility },
        slot: "suffix",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }