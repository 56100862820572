var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-steps",
        { attrs: { active: _vm.active } },
        [
          _c("el-step", { attrs: { title: "Bước 1", icon: "el-icon-upload" } }),
          _vm._v(" "),
          _c("el-step", { attrs: { title: "Bước 2", icon: "el-icon-edit" } }),
          _vm._v(" "),
          _c("el-step", {
            attrs: { title: "Bước 3", icon: "el-icon-picture" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.active === 1,
              expression: "active === 1",
            },
          ],
        },
        [
          _c("el-col", { attrs: { span: 11 } }),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 2 } }),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 11 } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { staticClass: "text-right", attrs: { span: 24 } },
                    [
                      _c("ol", { staticClass: "taiLieu" }, [
                        _c("li", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "images/huong_dan_upload_anh.png",
                                target: "_blank",
                                title: "Ảnh chữ ký mẫu",
                              },
                            },
                            [
                              _vm._v(
                                "Ảnh\n                                hướng dẫn"
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { staticClass: "text-center", attrs: { span: 24 } },
            [
              _c(
                "el-upload",
                {
                  staticClass: "upload-demo",
                  attrs: {
                    "show-file-list": false,
                    "auto-upload": false,
                    drag: "",
                    action: "/",
                    "on-change": _vm.uploadFile,
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-upload" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "el-upload__text" }, [
                    _vm._v("Kéo thả file ở đây hoặc "),
                    _c("em", [_vm._v("click để upload")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip",
                    },
                    [_vm._v("Chỉ upload file định dạng ảnh")]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.active === 2
        ? _c(
            "div",
            { staticClass: "cut", style: _vm.styleBox },
            [
              _c("vue-cropper", {
                ref: "cropper",
                attrs: {
                  img: _vm.option.img,
                  "output-size": _vm.option.size,
                  "output-type": _vm.option.outputType,
                  info: true,
                  full: _vm.option.full,
                  fixed: _vm.option.fixed,
                  "fixed-number": _vm.option.fixedNumber,
                  "can-move": _vm.option.canMove,
                  "can-move-box": _vm.option.canMoveBox,
                  "fixed-box": _vm.option.fixedBox,
                  original: _vm.option.original,
                  mode: _vm.option.mode,
                  "auto-crop": _vm.option.autoCrop,
                  "auto-crop-width": _vm.option.autoCropWidth,
                  "auto-crop-height": _vm.option.autoCropHeight,
                  "center-box": _vm.option.centerBox,
                  high: _vm.option.high,
                  mode: "cover",
                  "max-img-size": _vm.option.max,
                },
                on: {
                  "real-time": _vm.realTime,
                  "img-load": _vm.imgLoad,
                  "crop-moving": _vm.cropMoving,
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt-3" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 15 } },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "1" },
                              model: {
                                value: _vm.typeImage,
                                callback: function ($$v) {
                                  _vm.typeImage = $$v
                                },
                                expression: "typeImage",
                              },
                            },
                            [_vm._v("Ảnh chưa xoá nền")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "2" },
                              model: {
                                value: _vm.typeImage,
                                callback: function ($$v) {
                                  _vm.typeImage = $$v
                                },
                                expression: "typeImage",
                              },
                            },
                            [_vm._v("Ảnh đã xoá nền")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 9 } },
                        [
                          _vm.typeImage == 1
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    disabled:
                                      _vm.typeImage != 1 && _vm.typeImage != 2,
                                    type: "primary",
                                  },
                                  on: { click: _vm.next },
                                },
                                [_vm._v("Tiếp theo")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.typeImage == 2
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    disabled:
                                      _vm.typeImage != 1 && _vm.typeImage != 2,
                                    type: "primary",
                                  },
                                  on: { click: _vm.emitDataImg },
                                },
                                [_vm._v("Xác nhận")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.typeImage
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    disabled:
                                      _vm.typeImage != 1 && _vm.typeImage != 2,
                                    type: "primary",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        Chọn loại ảnh"
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.active === 3,
              expression: "active === 3",
            },
          ],
          staticClass: "step3",
        },
        [
          _c(
            "div",
            {
              staticClass: "box1 bd ",
              class: { flashBorder: _vm.alertNeed },
              attrs: { id: "boxCanvas1" },
            },
            [
              _c("div", [
                _c("canvas", {
                  attrs: { id: "signId" },
                  on: { click: _vm.canvasClick },
                }),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "box2 " }, [
            _c(
              "div",
              { staticClass: "mb-2 btn_remove" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "mini" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.deleteBackground($event)
                      },
                    },
                  },
                  [_vm._v("Xóa nền\n                ")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", [
              _c("div", { staticClass: "display-color" }, [
                _c("span", { staticClass: "mr-2" }, [
                  _vm._v("Mã màu : " + _vm._s(_vm.chooseColor)),
                ]),
                _vm._v(" "),
                _c("span", {
                  style:
                    "border: 1px solid #666; display:inline-block;width: 50px; height: 20px;" +
                    "background-color:" +
                    _vm.chooseColor,
                }),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "box3 bd", attrs: { id: "boxCanvas2" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                style: {
                  display: "inline-block",
                  width: _vm.previews.w + "px",
                  height: _vm.previews.h + "px",
                  overflow: "hidden",
                  "margin-tops": "5px",
                },
                attrs: {
                  "element-loading-text": "Đang xoá nền...",
                  "element-loading-spinner": "el-icon-loading",
                  "element-loading-background": "rgba(0, 0, 0, 0.8)",
                },
              },
              [
                _c("img", {
                  style: _vm.dataBase64,
                  attrs: { src: _vm.dataBase64_remove, alt: "prev" },
                }),
              ]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }