var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading.fullscreen.lock",
            value: _vm.loadingScreen,
            expression: "loadingScreen",
            modifiers: { fullscreen: true, lock: true },
          },
        ],
        staticClass:
          "page__title d-flex align-items-center justify-content-between",
      },
      [_c("h1", { staticClass: "title" }, [_vm._v("Báo cáo trạng thái")])]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "box-upload-file" },
      [
        _c(
          "el-row",
          { attrs: { gutter: 24 } },
          [
            _c(
              "el-col",
              { attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 } },
              [
                _c("label", [_vm._v("Năm học")]),
                _vm._v(" "),
                _c("eselect", {
                  staticStyle: { width: "100%" },
                  attrs: {
                    collapseTags: "",
                    placeholder: "Chọn",
                    filterable: "",
                    data: _vm.list_nam_hoc,
                    fields: ["name", "value"],
                  },
                  model: {
                    value: _vm.dataSearch.namHoc,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataSearch, "namHoc", $$v)
                    },
                    expression: "dataSearch.namHoc",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-col",
              { attrs: { xs: 12, sm: 8, md: 6, lg: 6, xl: 6 } },
              [
                _c("label", [_vm._v("Đơn vị")]),
                _vm._v(" "),
                _c("eselect", {
                  staticStyle: { width: "100%" },
                  attrs: {
                    collapseTags: "",
                    placeholder: "Chọn",
                    filterable: "",
                    data: _vm.list_don_vi,
                    fields: ["name", "value"],
                  },
                  on: {
                    change: function ($event) {
                      return _vm.chonDonVi()
                    },
                  },
                  model: {
                    value: _vm.dataSearch.donVi,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataSearch, "donVi", $$v)
                    },
                    expression: "dataSearch.donVi",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-col",
              { attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 } },
              [
                _c("label", [_vm._v("Cấp học")]),
                _vm._v(" "),
                _c("eselect", {
                  staticStyle: { width: "100%" },
                  attrs: {
                    collapseTags: "",
                    placeholder: "Chọn",
                    filterable: "",
                    data: _vm.list_cap_hoc,
                    fields: ["name", "value"],
                  },
                  on: {
                    change: function ($event) {
                      return _vm.chonDonVi()
                    },
                  },
                  model: {
                    value: _vm.dataSearch.capHoc,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataSearch, "capHoc", $$v)
                    },
                    expression: "dataSearch.capHoc",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-col",
              { attrs: { xs: 12, sm: 8, md: 6, lg: 6, xl: 6 } },
              [
                _c("label", [_vm._v("Trường học")]),
                _vm._v(" "),
                _c("eselect", {
                  staticStyle: { width: "100%" },
                  attrs: {
                    collapseTags: "",
                    placeholder: "Chọn",
                    filterable: "",
                    data: _vm.list_truong_hoc,
                    fields: ["name", "value"],
                  },
                  model: {
                    value: _vm.dataSearch.truongHoc,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataSearch, "truongHoc", $$v)
                    },
                    expression: "dataSearch.truongHoc",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "page__header" }),
    _vm._v(" "),
    _c("div", { staticClass: "page__container" }, [
      _c("div", { staticClass: "page__content" }, [
        _c(
          "div",
          { staticClass: "listNew mb-5" },
          [
            _c(
              "el-table",
              {
                attrs: {
                  "max-height": _vm.maxHeightTable,
                  border: "",
                  fit: "",
                  stripe: "",
                  lazy: true,
                  data: _vm.list_bao_cao ? _vm.list_bao_cao : [],
                  width: "100%",
                },
              },
              [
                _c("el-table-column", {
                  staticStyle: { "text-align": "center" },
                  attrs: {
                    label: "Mã báo cáo",
                    property: "maBaoCao",
                    align: "center",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "Tên báo cáo",
                    "header-align": "center",
                    align: "left",
                    property: "tenBaoCao",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "Hành động",
                    "header-align": "center",
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: "Tải báo cáo",
                                placement: "left",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "success", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.xemBaoCao(scope.row, 1)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                    Tải xuống\n                                "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: "Xem báo cáo",
                                placement: "left",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.xemBaoCao(scope.row, 2)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                    Xem trước\n                                "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }