<template>
    <div class="page">
        <div class="page__title d-flex align-items-center justify-content-between"
             v-loading.fullscreen.lock="loadingScreen">
            <h1 class="title">Báo cáo dữ liệu giáo viên</h1>
        </div>
        <div class="box-upload-file">
            <el-row :gutter="24">
                <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
                    <label>Năm học</label>
                    <eselect style="width: 100%" collapseTags v-model="dataSearch.namHoc" :placeholder="'Chọn'"
                             filterable :data="list_nam_hoc" :fields="['name','value']"/>
                </el-col>
                <el-col v-if="user.role!=4"  :xs="12" :sm="8" :md="6" :lg="6" :xl="6">
                    <label>Đơn vị</label>
                    <eselect style="width: 100%" :disabled="user.role!=7" collapseTags @change="chonDonVi()" v-model="dataSearch.donVi"
                             :placeholder="'Chọn'"
                             filterable :data="list_don_vi" :fields="['name','value']"/>
                </el-col>
                <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
                    <label>Cấp học</label>
                    <eselect :disabled="list_cap_hoc.length==1" style="width: 100%" @change="chonDonVi()" collapseTags v-model="dataSearch.capHoc"
                             :placeholder="'Chọn'"
                             filterable :data="list_cap_hoc" :fields="['name','value']"/>
                </el-col>
                <el-col  v-if="user.role!=4" :xs="12" :sm="8" :md="6" :lg="6" :xl="6">
                    <label>Trường học</label>
                    <eselect style="width: 100%" collapseTags v-model="dataSearch.truongHoc" :placeholder="'Chọn'"
                             filterable :data="list_truong_hoc" :fields="['name','value']"/>
                </el-col>
<!--                <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="4" class=" lg">-->
<!--                    <div style="color: transparent">Tìm kiếm</div>-->
<!--                    <el-button class="m-0" type="primary" @click.prevent="getDataReport()">Tìm kiếm</el-button>-->
<!--                </el-col>-->

                <el-col v-if="user.role==4" :xs="12" :sm="8" :md="6" :lg="4" :xl="4" class=" lg">
                    <div style="color: transparent">Tìm kiếm</div>
                    <el-button class="m-0" type="primary" @click.prevent="getDataReport()">Tìm kiếm</el-button>
                </el-col>
                <el-col v-else :xs="4" :sm="4" :md="4" :lg="4" :xl="4" class=" lg" >
                    <div style="color: transparent">Tìm kiếm</div>
                    <el-button class="m-0" type="primary" @click.prevent="getDataReport()">Tìm kiếm</el-button>
                </el-col>
            </el-row>
        </div>
        <div class="page__header">
        </div>
        <div class="page__container">
            <div class="page__content">
                <div class="listNew mb-5">
                    <div v-if="linkViewReport">
                        <div class="text-right mb-1">
                            <a :href="linkDownloadReport" title="Tải xuống">
                                <el-button type="success" size="mini">Tải xuống</el-button>
                            </a>
                        </div>
                        <iframe style="width:100%; height:100vh" :src="linkViewReport"></iframe>
                    </div>
                    <div v-else class="text-center">
                        <p>Chưa có dữ liệu báo cáo</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    mapActions,
    mapState
} from 'vuex';
import api from "../../_helpers/api";
import constant from "../../_helpers/constant_api";
import ESelectVue from "../Ui/ESelect";

export default {
    name: "BaoCaoDuLieuGiaoVien",
    metaInfo: {
        title: 'Báo cáo dữ liệu giáo viên',
        meta: [
            {vmid: 'description', name: 'description', content: ''}
        ],
    },
    components: {
        'eselect': ESelectVue,
    },
    data() {
        return {
            maxHeightTable: window.innerHeight,
            list_don_vi: [],
            list_truong_hoc: [],
            loadingScreen: false,
            linkViewReport:'',
            linkDownloadReport:'',
            dataSearch: {
                namHoc: '',
                donVi: '',
                capHoc: '',
                truongHoc: ''
            },
            loading: {
                trangThaiCapNhatHocBa: false,
                trangThaiChotDuLieuHocBa: false,
                trangThaiThongKeKyGvcn: false,
                trangThaiThongKeKyHieuTruong: false,
                trangThaiThongKeDongDauHieuTruong: false,
            },
            duLieu: {
                trangThaiCapNhatHocBa: {},
                trangThaiChotDuLieuHocBa: {},
                trangThaiThongKeKyGvcn: {},
                trangThaiThongKeKyHieuTruong: {},
                trangThaiThongKeDongDauHieuTruong: {},
            }
        }
    },
    computed: {
        ...mapState('account', [
            'user',
            'nam_hoc_hien_tai',
            'list_nam_hoc',
            'list_cap_hoc',
        ]),
    },
    mounted() {
        console.error('Thông tin:')
        console.error(this.user)
        this.getDanhSachDonVi();
        console.error(this.list_cap_hoc)
        console.log('Cấp học')
        this.dataSearch.namHoc = this.nam_hoc_hien_tai

        if(this.list_cap_hoc.length==1){
            this.dataSearch.capHoc = this.list_cap_hoc[0].value
        }
        if(this.user.role==6){
            this.dataSearch.donVi = this.user.maPhong;
        }
        this.getDataReport();
    },
    methods: {
        getTime() {
            var d = new Date();
            var n = d.getTime();
            return n;
        },
        xemBaoCao(e, type) {
            console.error('Xem báo cáo:')
            console.error(e)
            let params = {
                maSo: this.user.maSo,
                maPhong: this.dataSearch.donVi,
                capHoc: this.dataSearch.capHoc,
                namHoc: this.dataSearch.namHoc
            }
            let url = e.pathApi
            this.loadingScreen = true;
            api.get(url, params).then(
                response => {
                    console.error('Lấy excel trả về:')
                    if (response.data.code == 200) {
                        let linkDowndLoad = response.data.data
                        if (type == 1) {
                            window.open(linkDowndLoad, '_blank');
                        } else {
                            let urlReport = 'https://view.officeapps.live.com/op/view.aspx?src=' + linkDowndLoad + '?t=' + this.getTime();
                            window.open(urlReport, '_blank');
                        }
                    } else {
                        this.thongBao('error', response.data.msg)
                    }
                    console.error(this.list_truong_hoc)
                    this.loadingScreen = false;
                }
            )
        },
        getDataReport(){
            console.log('getDataReport')
            console.log(this.dataSearch)
            let params = {
                maSo: this.user.maSo,
                maPhong: this.dataSearch.donVi,
                maTruong: this.dataSearch.truongHoc,
                namHoc: this.dataSearch.namHoc,
                capHoc:this.dataSearch.capHoc
            }
            let url = constant.report.duLieuGiaoVien;
            this.loadingScreen = true;
            this.linkViewReport = '';
            this.linkDownloadReport = '';
            api.get(url, params).then(
                response => {
                    console.log('Dữ liệu báo cáo')
                    console.log(response)
                    if (response.data.code == 200&&response.data.data) {
                        this.linkDownloadReport = response.data.data;
                        let urlReport = 'https://view.officeapps.live.com/op/view.aspx?src=' + response.data.data + '?t=' + this.getTime();
                        this.linkViewReport = urlReport;
                        console.log('Link xem là:')
                        console.log(this.linkViewReport)
                    } else {
                        this.thongBao('error',response.data.msg)
                    }
                    setTimeout(()=>{
                        this.loadingScreen = false;
                    },1000)
                }
            )
        },
        thongBao(t, e) {
            let msg = "";
            let cl = "";
            if (e) {
                msg = e;
            }
            let type = "success";
            if (t) {
                type = t
            }
            if (type == "success") {
                cl = "dts-noty-success"
            }
            if (type == "warning") {
                cl = "dts-noty-warning"
            }
            if (type == "error") {
                cl = "dts-noty-error"
            }
            if (type == "info") {
                cl = "dts-noty-info"
            }
            this.$message({
                dangerouslyUseHTMLString: true,
                customClass: cl,
                showClose: true,
                message: msg,
                type: t,
                duration: 3000
            });
        },
        chonDonVi() {
            this.dataSearch.truongHoc = '';
            this.list_truong_hoc = [];
            this.getDanhSachTruongHoc();
        },
        getDanhSachTruongHoc() {
            let params = {
                maPhong: this.dataSearch.donVi,
                capHoc: this.dataSearch.capHoc,
            }
            let url = constant.danhMuc.danhSachTruong;
            this.list_truong_hoc = [];
            this.loadingScreen = true;
            api.get(url, params).then(
                response => {
                    if (response.data.code == 200) {
                        let data = response.data.data;
                        for (let i = 0; i < data.length; i++) {
                            this.list_truong_hoc.push(
                                {
                                    name: data[i].tenTruongHoc + ' - [' + data[i].maTruongHoc + ']',
                                    value: data[i].maTruongHoc
                                }
                            )
                        }

                    } else {
                    }
                    console.error(this.list_truong_hoc)
                    this.loadingScreen = false;
                }
            )
        },
        getDanhSachDonVi() {
            let params = {
                maSo: this.user.maSo,
            }
            let url = constant.danhMuc.danhSachDonVi;
            this.list_don_vi = [];
            this.loadingScreen = true;
            api.get(url, params).then(
                response => {
                    if (response.data.code == 200) {
                        let data = response.data.data;
                        for (let i = 0; i < data.length; i++) {
                            this.list_don_vi.push(
                                {
                                    name: data[i].tenDonVi.replace("Giáo dục và Đào tạo", "GD&ĐT") + ' - [' + data[i].maDonVi + ']',
                                    value: data[i].maDonVi
                                }
                            )
                        }

                    } else {
                    }
                    console.error(this.list_don_vi)
                    this.loadingScreen = false;
                }
            )
        },
    }
}
</script>

<style scoped>

</style>