var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "otp__form",
      attrs: {
        title: "Xác nhận ký",
        "destroy-on-close": true,
        "close-on-click-modal": false,
        center: "",
        "before-close": _vm.closeOtpModal,
        visible: _vm.hien_thi,
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.hien_thi = $event
        },
      },
    },
    [
      _c(
        "el-row",
        { ref: "formOtp", staticClass: "text-center", attrs: { gutter: 24 } },
        [
          _vm.kyHocBaStatus != "success" && _vm.kyHocBaMes
            ? _c("el-alert", {
                attrs: { title: _vm.kyHocBaMes, center: "", type: "error" },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("p", { staticClass: "text-center" }, [
            _vm._v("\n      " + _vm._s(_vm.messInfo) + "\n    "),
          ]),
          _vm._v(" "),
          !_vm.messInfo
            ? _c("p", { staticClass: "text-center" }, [
                _vm._v("\n      Nhập mã OTP\n    "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-col",
            { staticStyle: { "padding-bottom": "15px" }, attrs: { span: 24 } },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.otpCode,
                    expression: "otpCode",
                  },
                ],
                staticClass: "login__otp form-control text-center",
                staticStyle: { "font-size": "15px" },
                attrs: { type: "number", name: "otp" },
                domProps: { value: _vm.otpCode },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    $event.preventDefault()
                    return _vm.XacNhanKy($event)
                  },
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.otpCode = $event.target.value
                  },
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 24 } }, [
            _c(
              "div",
              { staticClass: "text-center" },
              [
                _c(
                  "el-button",
                  {
                    staticClass:
                      "d-flex w-100 align-items-center justify-content-center text-uppercase",
                    staticStyle: { "margin-bottom": "15px" },
                    attrs: {
                      disabled: !_vm.otpCode,
                      type: "primary",
                      round: "round",
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.XacNhanKy($event)
                      },
                    },
                  },
                  [_vm._v("\n          Xác nhận ký\n        ")]
                ),
                _vm._v(" "),
                _vm.loiKy.code == 501
                  ? _c("div", {}, [
                      _vm._v("\n          Mã OTP đã hết hiệu lực?\n          "),
                      _c(
                        "span",
                        [
                          !_vm.getOtpClicked
                            ? _c(
                                "el-link",
                                {
                                  staticClass: "text-uppercase",
                                  attrs: { underline: false },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.layOtpKy()
                                    },
                                  },
                                },
                                [_vm._v("Gửi\n              lại\n            ")]
                              )
                            : _c(
                                "el-link",
                                {
                                  staticClass: "text-uppercase",
                                  attrs: {
                                    underline: false,
                                    type: "danger",
                                    disabled: "",
                                  },
                                },
                                [
                                  _vm._v(
                                    "Gửi lại (" +
                                      _vm._s(_vm.timeleft) +
                                      "\n              giây)"
                                  ),
                                ]
                              ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "alert-otp",
          attrs: {
            title: "Thông báo",
            center: "",
            "before-close": _vm.closeDialogAlert,
            "close-on-click-modal": false,
            visible: _vm.showDialogAlert,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialogAlert = $event
            },
          },
        },
        [
          _c("div", { staticClass: "text-center mb-2" }, [
            _vm._v("\n      " + _vm._s(_vm.kyHocBaMes) + "\n    "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.closeDialogAlert },
                },
                [_vm._v("Đóng")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }