<template>
    <div id="detailHocBa" class="page page-file file detailHocBa">
        <div class="box-upload-file">
            <div class="d-flex flex-wrap">
                <div class="d-inline-block pl-3 pr-3 pb-3">
                    <h5 class="font-weight-bold m-0">Phân công ký thay</h5>
                </div>
            </div>
            <el-form :rules="rulesSearch" ref="searchForm" :model="searchForm" :hide-required-asterisk="true">
                <el-row :gutter="24">
                    <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
                        <el-form-item class="d-inline-block" prop="year" label="Năm học">
                            <eselect style="width:100%" collapseTags v-model="searchForm.year" placeholder="Chọn"
                                     filterable :data="list_nam_hoc" :fields="['name','value']"/>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
                        <el-form-item class="d-inline-block" prop="education" label="Cấp học">
                            <eselect style="width:100%" disabled collapseTags v-model="searchForm.education"
                                     @change="capHocChange" placeholder="Chọn" filterable :data="optionEducation"
                                     :fields="['label','value']"/>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
                        <el-form-item class="d-inline-block" prop="grade" label="Khối học">
                            <eselect style="width:100%" collapseTags v-model="searchForm.grade"
                                     placeholder="Chọn" filterable :data="optionGrade"
                                     :fields="['label','value']"/>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
                        <el-form-item class="d-inline-block" prop="type" label="Loại phân công">
                            <eselect style="width:100%" collapseTags v-model="searchForm.type"
                                     :placeholder="'Loại phân công'"
                                     filterable :data="[{type : 2,label : 'GVCN'},{type : 1,label : 'GVBM'}]"
                                     :fields="['label','type']"/>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="8" :md="6" :lg="4" :xl="3" class="text-left">
                        <div style="color: transparent">Tìm kiếm</div>
                        <el-button class="m-0" type="primary" @click="submitSearch('searchForm')">Tìm kiếm
                        </el-button>
                    </el-col>
                </el-row>

            </el-form>

        </div>
        <div class="page__header">
            <div class="file--title">
                <h5 class="title">Danh sách ký thay</h5>
            </div>
            <div class="page__content table-custom">
                <div class="listNew mb-5"
                     v-loading="loading"
                     element-loading-text="Loading..."
                     element-loading-spinner="el-icon-loading"
                     element-loading-background="rgba(0, 0, 0, 0.8)"
                >

                    <div v-if="typeShow === 2" class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                            <tr>
                                <th scope="col" rowspan="2" class="text-center">STT</th>
                                <th scope="col" rowspan="2" class="text-center">Năm học</th>
                                <th scope="col" rowspan="2" class="text-center">Lớp</th>
                                <th scope="col" rowspan="2" class="text-center">GVCN</th>
                                <th scope="col" rowspan="2" class="text-center">Giáo viên ký thay</th>
                                <th scope="col" rowspan="2" class="text-center">Lý do</th>
                                <th scope="col" rowspan="2" class="text-center">Hành động</th>
                            </tr>

                            </thead>
                            <tbody>
                            <template v-if="lstKyThay.length">
                                <template v-for="(o,i) in lstKyThay">
                                    <tr class="text-center">
                                        <td scope="row">{{ indexMethod(i) }}</td>
                                        <td> {{ o.namHoc }} - {{ o.namHoc + 1 }}</td>
                                        <td>{{ o.tenLop }}</td>
                                        <td>{{ o.tenChuNhiem }}</td>
                                        <td>{{ o.tenGvcn }}</td>
                                        <td>{{ o.lyDoKyThay }}</td>
                                        <td>
                                            <div class="btn__table_action">
                                                <el-button size="mini" @click="showPhanCong(o)"
                                                           type="primary"
                                                           icon="">
                                                    Phân công ký
                                                </el-button>
                                                <el-button size="mini" @click="removePhanCong(o.maLop)"
                                                           type="danger"
                                                           icon="">
                                                    Huỷ phân công ký
                                                </el-button>
                                            </div>
                                        </td>
                                    </tr>
                                </template>

                            </template>
                            <template v-else>
                                <tr>
                                    <td colspan="20"><p class="text-center">Chưa có dữ liệu</p></td>
                                </tr>
                            </template>
                            </tbody>
                        </table>
                    </div>
                    <div v-if="typeShow === 1" class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                            <tr>
                                <th scope="col" rowspan="2" class="text-center">STT</th>
                                <th scope="col" rowspan="2" class="text-center">Năm học</th>
                                <th scope="col" rowspan="2" class="text-center">Lớp</th>
                                <th scope="col" rowspan="2" class="text-center">GVCN</th>
                                <th scope="col" colspan="5" class="text-center">Ký thay giáo viên bộ môn</th>
                                <th scope="col" rowspan="2" class="text-center">Hành động</th>
                            </tr>
                            <tr>
                                <th scope="col" class="text-center">Môn học</th>
                                <th scope="col" class="text-center">Giáo viên giảng dạy</th>
                                <th scope="col" class="text-center">Giáo viên ký thay</th>
                                <th scope="col" class="text-center">Lý do</th>
                                <th scope="col" class="text-center">Hành động</th>
                            </tr>
                            </thead>
                            <tbody>
                            <template v-if="lstKyThay.length">
                                <template v-for="(o,i) in lstKyThay">
                                    <tr v-for="(item,index) in o.lstGiangDay" class="text-center">
                                        <td v-if="index === 0" scope="row" :rowspan="o.lstGiangDay.length">
                                            {{ indexMethod(i) }}
                                        </td>
                                        <td v-if="index === 0" :rowspan="o.lstGiangDay.length"> {{ o.namHoc }} -
                                            {{ o.namHoc + 1 }}
                                        </td>
                                        <td v-if="index === 0" :rowspan="o.lstGiangDay.length">{{ o.tenLop }}</td>
                                        <td v-if="index === 0" :rowspan="o.lstGiangDay.length">{{ o.tenChuNhiem }}</td>
                                        <td>
                                            {{ item.tenMonHoc }}
                                        </td>
                                        <td>
                                            {{ item.tenGV }}
                                        </td>
                                        <td>
                                            {{ item.tenGiaoVien }}
                                        </td>
                                        <td>
                                            {{ item.lyDoKyThay }}
                                        </td>
                                        <td>
                                            <div class="text-center">
                                                <el-tooltip class="item" effect="dark" content="Xóa ký thay"
                                                            placement="right">
                                                    <el-button type="danger" icon="el-icon-delete"
                                                               @click.prevent="removePhanCong(o.maLop,item.maGiaoVien,item.maMonHoc)"
                                                               size="mini">
                                                    </el-button>
                                                </el-tooltip>
                                            </div>
                                        </td>
                                        <td v-if="index === 0" :rowspan="o.lstGiangDay.length">
                                            <div class="btn__table_action">
                                                <el-button size="mini" @click="showPhanCong(o)"
                                                           type="primary"
                                                           icon="">
                                                    Phân công
                                                </el-button>
                                                <el-button size="mini" @click="removePhanCong(o.maLop)"
                                                           type="danger"
                                                           icon="">
                                                    Xóa
                                                </el-button>
                                            </div>
                                        </td>
                                    </tr>
                                </template>

                            </template>
                            <template v-else>
                                <tr>
                                    <td colspan="20"><p class="text-center">Chưa có dữ liệu</p></td>
                                </tr>
                            </template>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="text-right mt-2">
                    <pagination v-if="lstKyThay.length" :page-count="getTotalPage(total)" v-model="paginate.page"
                                :initial-page="paginate.page" :click-handler="getPhanCongKy" :prev-text="'Trước'"
                                :next-text="'Sau'"
                                :container-class="'el-pager'" :pageClass="'number'">

                    </pagination>
                </div>
            </div>
        </div>

        <el-dialog custom-class="updateInfoTruong" center :title="titleKyThay" :visible.sync="showPopUpPhanCong"
                   :before-close="handleClose">

            <span slot="footer" class="dialog-footer">

                <el-button @click="resetForm('ruleForm');showPopUpPhanCong = false;" size="mini">Đóng</el-button>
                <el-button @click="submitForm('ruleForm')" size="mini" type="primary">Phân công ký</el-button>
            </span>

            <el-form :model="phanCongForm" :rules="typeShow === 2 ? rulesPhanCongGVCN : rulesPhanCongGVBM"
                     ref="ruleForm" label-width="150px" class="demo-ruleForm">
                <el-form-item label="Chọn giáo viên" prop="giaoVien">
                    <el-select :filterable="true" v-model="phanCongForm.giaoVien" @change="onChangeGiaoVien">
                        <el-option label="--Chọn giáo viên--" value=""></el-option>
                        <el-option v-for="(gv,index) in lstGiaoVien" :value="gv.maGV" :key="index"
                                   :label="gv.tenGV"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="typeShow === 1" label="Môn học ký thay" prop="lstMonHoc">
                    <el-checkbox-group v-model="phanCongForm.lstMonHoc">
                        <el-checkbox v-for="(item,index) in lstMonHoc" :key="index" :label="item.maMonHoc">
                            {{ item.tenMonHoc }}
                        </el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="Lý do" prop="lyDo">
                    <el-input v-model="phanCongForm.lyDo"
                              type="textarea"
                              :autosize="{ minRows: 4, maxRows: 8}"
                              placeholder="Nhập lý do">

                    </el-input>
                </el-form-item>
            </el-form>

        </el-dialog>

    </div>
</template>

<script>
import {
    mapActions,
    mapState
} from 'vuex';
import Pagination from "../components/Pagination";
import ESelectVue from "./Ui/ESelect";
import ChonKhoiHoc from "../components/ChonKhoiHoc";
import CapHocKhoiHoc from "../components/CapHocKhoiHoc";
import constant_api from "../_helpers/constant_api";
import api from "../_helpers/api";


export default {
    name: "QuanLyKyThay",
    metaInfo: {
        title: 'Phân công ký thay',
        titleTemplate: '',
        meta: [{
            vmid: 'description',
            name: 'description',
            content: ''
        }]
    },
    components: {

        Pagination,
        ChonKhoiHoc,
        CapHocKhoiHoc,
        'eselect': ESelectVue,
    },
    computed: {

        ...mapState('account', [
            'user',
            'list_nam_hoc'
        ])
    },
    data() {
        return {
            ruleForm: {
                name: '',
                region: '',
                date1: '',
                date2: '',
                delivery: false,
                type: [],
                resource: '',
                desc: ''
            },

            lstGiaoVien: [],
            titleKyThay: '',
            dataSelect: null,
            showPopUpPhanCong: false,
            typeShow: 2,

            lstKyThay: [],
            loading: false,
            paginate: {
                start: 0,
                limit: 100,
                page: 1,
            },
            total: 0,
            fullscreenLoading: false,
            optionEducation: [{
                value: 1,
                label: 'Cấp 1'
            },
                {
                    value: 2,
                    label: 'Cấp 2'
                },
                {
                    value: 3,
                    label: 'Cấp 3'
                },
                {value: 15, label: 'Liên cấp'},
                {value: 145, label: 'Liên cấp'},
                {value: 14, label: 'Liên cấp'},
                {value: 13, label: 'Liên cấp'},
                {value: 451, label: 'Liên cấp'},
                {value: 23, label: 'Liên cấp'},
                {value: 236, label: 'Liên cấp'},
                {value: 36, label: 'Liên cấp'},
                {value: 452, label: 'Liên cấp'},
                {value: 1245, label: 'Liên cấp'},
                {value: 12, label: 'Liên cấp'},
                {value: 123, label: 'Liên cấp'},
                {value: 12345, label: 'Liên cấp'},
                {value: 1235, label: 'Liên cấp'},
            ],
            optionGrade: [],
            danh_sach_lop_hoc: [],
            khoiCap1: [{
                value: 1,
                label: 'Khối 1'
            },
                {
                    value: 2,
                    label: 'Khối 2'
                },
                {
                    value: 3,
                    label: 'Khối 3'
                },
                {
                    value: 4,
                    label: 'Khối 4'
                },
                {
                    value: 5,
                    label: 'Khối 5'
                },
            ],
            khoiCap2: [{
                value: 6,
                label: 'Khối 6'
            },
                {
                    value: 7,
                    label: 'Khối 7'
                },
                {
                    value: 8,
                    label: 'Khối 8'
                },
                {
                    value: 9,
                    label: 'Khối 9'
                },
            ],
            khoiCap3: [{
                value: 10,
                label: 'Khối 10'
            },
                {
                    value: 11,
                    label: 'Khối 11'
                },
                {
                    value: 12,
                    label: 'Khối 12'
                },

            ],
            lstMonHoc: [],
            phanCongForm: {
                lyDo: '',
                type: '',
                giaoVien: '',
                lstMonHoc: []
            },
            rulesPhanCongGVCN: {
                giaoVien: [{
                    required: true,
                    message: 'Bạn chưa chọn giáo viên',
                    trigger: 'change'
                }],
                lyDo: [{
                    required: true,
                    message: 'Bạn chưa thêm lý do',
                    trigger: 'change'
                }],
            },
            rulesPhanCongGVBM: {
                giaoVien: [{
                    required: true,
                    message: 'Bạn chưa chọn giáo viên',
                    trigger: 'change'
                }],
                lstMonHoc: [{
                    required: true,
                    message: 'Bạn chưa chọn môn học',
                    trigger: 'change'
                }],
                lyDo: [{
                    required: true,
                    message: 'Bạn chưa thêm lý do',
                    trigger: 'change  '
                }],
            },
            searchForm: {
                year: '',
                education: '',
                grade: '',
                type: '',
                student: '',
                status: '',
                maHS: ''
            },
            rulesSearch: {
                year: [{
                    required: true,
                    message: 'Bạn chưa chọn năm học',
                    trigger: 'change'
                }],
                grade: [{
                    required: true,
                    message: 'Chưa chọn khối học',
                    trigger: 'change'
                }],
                type: [{
                    required: true,
                    message: 'Chưa chọn loại phân công',
                    trigger: 'change'
                }],
            }
        }
    },
    methods: {
        handleClose() {
            this.resetForm('ruleForm');
            this.showPopUpPhanCong = false;
        },

        onChangeGiaoVien() {
            if (this.phanCongForm.giaoVien) {
                let uri = '/hocbadientu-service/api/giao-vien/danh-sach-mon-hoc-theo-giao-vien';
                let params = {
                    maLop: this.dataSelect.maLop,
                    maGV: this.phanCongForm.giaoVien,

                };
                api.get(uri, params).then(
                    res => {
                        if (res.data.code == 200) {
                            this.phanCongForm.lstMonHoc = [];
                            res.data.data.map(item => {
                                if (item.phanCong) {
                                    this.phanCongForm.lstMonHoc.push(item.maMonHoc)
                                }
                                return item;
                            })
                        } else {

                        }
                        this.loading = false;
                    }
                ).catch((e) => {
                    this.loading = false;
                })
            }
        },

        phanCongGvcn() {
            let uri = '/hocbadientu-service/api/giao-vien/phan-cong-ky-thay';
            let params = {
                maLop: this.dataSelect.maLop,
                maGV: this.phanCongForm.giaoVien,
                type: this.typeShow,
                lyDo: this.phanCongForm.lyDo

            };
            api.post(uri, params).then(
                res => {

                    if (res.data.code == 200) {
                        this.showPopUpPhanCong = false;
                        this.getPhanCongKy();
                        this.thongBao("success", 'Đã phân giáo viên thành công')
                    } else {
                        this.thongBao("error", res.data.msg)
                    }
                    this.loading = false;
                }
            ).catch((e) => {
                this.loading = false;
            })
        },

        phanCongGvbm() {
            let uri = '/hocbadientu-service/api/giao-vien/phan-cong-ky-thay';
            let params = {
                maLop: this.dataSelect.maLop,
                maGV: this.phanCongForm.giaoVien,
                type: this.typeShow,
                lyDo: this.phanCongForm.lyDo,
                maMonHocs: this.phanCongForm.lstMonHoc
            };
            api.post(uri, params).then(
                res => {

                    if (res.data.code == 200) {
                        this.showPopUpPhanCong = false;
                        this.getPhanCongKy();
                        this.thongBao("success", 'Đã phân giáo viên thành công')
                    } else {
                        this.thongBao("error", res.data.msg)
                    }
                    this.loading = false;
                }
            ).catch((e) => {
                this.loading = false;
            })
        },

        submitForm(formName) {
            this.loading = true;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.typeShow === 2) {
                        this.phanCongGvcn()
                    } else {
                        this.phanCongGvbm()
                    }
                } else {
                    this.loading = false;
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },

        removePhanCong(maLop, maGv = null, maMonHoc = null) {
            this.loading = true;
            let uri = '/hocbadientu-service/api/giao-vien/xoa-phan-cong-ky-thay';
            let params = {
                type: this.typeShow,
                maLop: maLop,
            };
            if (maGv && maMonHoc) {
                params.maGV = maGv;
                params.maMonHocs = [maMonHoc]
            }
            api.post(uri, params).then(
                res => {

                    if (res.data.code == 200) {

                        this.getPhanCongKy();
                        this.thongBao("success", 'Đã huỷ phân công ký thành công')
                    } else {
                        this.thongBao("error", res.data.msg)
                    }
                    this.loading = false;
                }
            ).catch((e) => {
                this.loading = false;
            })
        },

        getListMonHoc() {
            let uri = '/hocbadientu-service/api/giao-vien/danh-sach-mon-hoc-theo-giao-vien';
            let params = {
                maLop: '',
                maGV: ''
            };
            api.get(uri, params).then(
                res => {

                }
            ).catch((e) => {

            })
        },

        getPhanCongKy(select) {
            this.paginate.start = (select - 1) * this.paginate.limit ? (select - 1) * this.paginate.limit : 0;
            this.paginate.page = select;

            let uri = '/hocbadientu-service/api/giao-vien/danh-sach-giao-vien-ky-thay';
            let params = {
                namHoc: this.searchForm.year,
                khoiHoc: this.searchForm.grade,
                start: this.paginate.start,
                limit: this.paginate.limit

            };
            api.get(uri, params).then(
                res => {

                    if (res.data.code == 200) {
                        this.total = res.data.data.total;
                        this.lstKyThay = res.data.data.list;
                        this.typeShow = this.searchForm.type;
                    } else {
                        this.lstKyThay = []
                    }
                    this.loading = false;
                }
            ).catch((e) => {
                this.loading = false;
            })
        },

        showPhanCong(data) {

            this.phanCongForm.lstMonHoc = [];
            this.phanCongForm.lyDo = '';
            this.phanCongForm.giaoVien = '';
            this.dataSelect = data;
            if (this.typeShow === 1) {
                this.lstMonHoc = [];
                let lstMon = data.lstGiangDay;
                lstMon.map(item => {
                    this.lstMonHoc.push({
                        tenMonHoc: item.tenMonHoc,
                        maMonHoc: item.maMonHoc
                    })
                    return item;
                })
            }
            this.titleKyThay = 'Phân công ký thay lớp ' + data.tenLop;
            this.showPopUpPhanCong = true;

        },
        indexMethod(index) {
            return (this.paginate.start + index) + 1;
        },
        thongBao(t, e) {
            let msg = "";
            let cl = "";
            if (e) {
                msg = e;
            }
            let type = "success";
            if (t) {
                type = t
            }
            if (type == "success") {
                cl = "dts-noty-success"
            }
            if (type == "warning") {
                cl = "dts-noty-warning"
            }
            if (type == "error") {
                cl = "dts-noty-error"
            }
            if (type == "info") {
                cl = "dts-noty-info"
            }
            this.$message({
                customClass: cl,
                showClose: true,
                message: msg,
                type: t,
                duration: 3000
            });
        },
        getListGiaoVien() {

            let param = {
                start: 0,
                limit: 2000,
                // maLop: this.searchForm.class,
                // namHoc: this.searchForm.year ? this.searchForm.year : '',
                // capHoc: this.searchForm.education,
                // khoiHoc: this.searchForm.grade,
                // type : this.searchForm.type
            };

            let uri = '/hocbadientu-service/api/giao-vien/danh-sach-giao-vien-truong-hoc';
            api.get(uri, param).then(
                response => {

                    if (response.data.code == 200) {
                        if (response.data.data) {
                            this.lstGiaoVien = response.data.data
                        }
                    } else {
                        this.lstGiaoVien = [];
                    }
                }
            ).catch((e) => {

            });
        },
        submitSearch(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.loading = true;
                    this.getPhanCongKy();
                } else {
                    this.loading = false;
                    console.log('error submit!!');
                    return false;
                }
            });
        },

        getFullYear() {
            let d = new Date();
            return d.getFullYear();
        },
        khoiHocChange() {
            // this.getlistLopHoc();
            // this.searchForm.class = '';

        },
        getTotalPage(number) {
            return Math.ceil(number / (this.paginate.limit));
        },
        getlistLopHoc() {
            let url = constant_api.giaovien.listLop;
            let params = {
                start: 0,
                limit: 999,
                khoiHoc: this.searchForm.grade
            }
            this.fullscreenLoading = true;
            api.get(url, params).then(
                response => {
                    this.fullscreenLoading = false;
                    console.log("Res:")
                    console.log(response)
                    if (response.data.code == 200) {
                        this.danh_sach_lop_hoc = response.data.data.list;
                    } else {
                        this.thongBao("error", 'Không lấy được danh sách lớp')
                    }
                }
            )
        },
        capHocChange() {
            this.searchForm.student = '';
            this.searchForm.maHS = '';
            this.searchForm.status = '';
            this.searchForm.grade = ''
            if (!this.searchForm.education) {
                this.optionGrade = this.khoiCap1.concat(this.khoiCap2, this.khoiCap3)
                return;
            } else {
                if (this.searchForm.education == 1) {
                    this.optionGrade = this.khoiCap1;
                } else if (this.searchForm.education == 2) {
                    this.optionGrade = this.khoiCap2;
                } else {
                    this.optionGrade = this.khoiCap3;
                }
            }
        },
        getKhoiHoc() {
            if (this.user.capHoc == 1) {
                this.optionGrade = this.khoiCap1;
            } else if (this.user.capHoc == 2) {
                this.optionGrade = this.khoiCap2;
            } else if (this.user.capHoc == 3) {
                this.optionGrade = this.khoiCap3;
            } else {
                this.optionGrade = this.khoiCap1.concat(this.khoiCap2, this.khoiCap3)
            }
        },
        getCurrentMonth() {
            let d = new Date();
            return d.getMonth();
        },

    },
    mounted() {

        this.getListGiaoVien();
        this.searchForm.year = this.getFullYear();
        this.searchForm.education = this.user.capHoc;

        let currentMonth = this.getCurrentMonth() + 1;
        if (currentMonth >= 9) {
        } else {
            this.searchForm.year -= 1;
        }
        this.getKhoiHoc();
    },
}
</script>

<style scoped>

</style>