<template>
    <div class="page">
        <div class="page__title d-flex align-items-center justify-content-between"
             v-loading.fullscreen.lock="loadingScreen">
            <h1 class="title">Báo cáo ký</h1>
        </div>
        <div class="box-upload-file">
            <el-row :gutter="24">
                <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
                    <label>Năm học</label>
                    <eselect style="width: 100%" collapseTags v-model="dataSearch.namHoc" :placeholder="'Chọn'"
                             filterable :data="list_nam_hoc" :fields="['name','value']"/>
                </el-col>
                <el-col v-if="user.role!=4"  :xs="12" :sm="8" :md="6" :lg="6" :xl="6">
                    <label>Đơn vị</label>
                    <eselect style="width: 100%" :disabled="user.role!=7" collapseTags @change="chonDonVi()" v-model="dataSearch.donVi"
                             :placeholder="'Chọn'"
                             filterable :data="list_don_vi" :fields="['name','value']"/>
                </el-col>
                <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
                    <label>Cấp học</label>
                    <eselect :disabled="list_cap_hoc.length==1" style="width: 100%" @change="chonDonVi()" collapseTags v-model="dataSearch.capHoc"
                             :placeholder="'Chọn'"
                             filterable :data="list_cap_hoc" :fields="['name','value']"/>
                </el-col>
                <el-col v-if="user.role!=4"  :xs="12" :sm="8" :md="6" :lg="6" :xl="6">
                    <label>Trường học</label>
                    <eselect style="width: 100%" collapseTags v-model="dataSearch.truongHoc" :placeholder="'Chọn'"
                             filterable :data="list_truong_hoc" :fields="['name','value']"/>
                </el-col>
                <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
                    <label>Khối học</label>
                    <eselect style="width:100%" collapseTags v-model="dataSearch.khoiHoc" placeholder="Chọn" filterable
                             @change="chonKhoiHoc()"
                             :data="danh_sach_khoi_hoc" :fields="['label','value']"/>
                </el-col>
                <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
                    <label>Lớp học</label>
                    <eselect style="width:100%" collapseTags v-model="dataSearch.lopHoc" placeholder="Chọn" filterable
                             :data="danh_sach_lop_hoc" :fields="['tenLop','maLop']"/>
                </el-col>
<!--                <el-col :xs="4" :sm="4" :md="4" :lg="2" :xl="2" class=" lg" >-->
<!--                </el-col>-->
                <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="8" class=" lg">
                  <div style="color: transparent">Xem báo cáo</div>
                    <el-button style="margin-right:10px"  type="primary" @click.prevent="viewBaoCao()">Xem báo cáo</el-button>
                    <el-button  type="primary" @click.prevent="getDataReport()">Tải xuống</el-button>
                </el-col>
<!--                <el-col v-else :xs="24" :sm="24" :md="6" :lg="6" :xl="24" class="text-center lg" >-->
<!--                  <div style="color: transparent">Xem báo cáo</div>-->
<!--                  <el-button class="m-0" type="primary" @click.prevent="viewBaoCao()">Xem báo cáo</el-button>-->
<!--                    <div style="color: transparent">Tìm kiếm</div>-->
<!--                    <el-button class="m-0" type="primary" @click.prevent="getDataReport()">Tải xuống</el-button>-->
<!--                </el-col>-->
            </el-row>
        </div>
        <div class="page__header mt-5">
            <div class="listNew mb-5">
                <PreviewBaoCao :data-view="dataView" name="Báo cáo ký"></PreviewBaoCao>
            </div>
        </div>

    </div>
</template>

<script>
import {
    mapActions,
    mapState
} from 'vuex';
import api from "../../_helpers/api";
import constant from "../../_helpers/constant_api";
import ESelectVue from "../Ui/ESelect";
import constant_api from "../../_helpers/constant_api";
import LZString from "lz-string";
import ExcelJS from "exceljs";
import PreviewBaoCao from "../../components/PreviewBaoCao";

export default {
    name: "baoCaoKy",
    metaInfo: {
        title: 'Báo cáo ký',
        meta: [
            {vmid: 'description', name: 'description', content: ''}
        ],
    },
    components: {
        'eselect': ESelectVue,
        PreviewBaoCao
    },
    data() {
        return {
            dataView:'',
            maxHeightTable: window.innerHeight,
            list_don_vi: [],
            danh_sach_khoi_hoc: [],
            list_truong_hoc: [],
            danh_sach_lop_hoc: [],
            loadingScreen: false,
            linkViewReport: '',
            linkDownloadReport: '',
            dataSearch: {
                namHoc: '',
                donVi: '',
                capHoc: '',
                truongHoc: '',
                khoiHoc: '',
                lopHoc: '',
            },
            khoiCap1: [{
                value: 1,
                label: 'Khối 1'
            },
                {
                    value: 2,
                    label: 'Khối 2'
                },
                {
                    value: 3,
                    label: 'Khối 3'
                },
                {
                    value: 4,
                    label: 'Khối 4'
                },
                {
                    value: 5,
                    label: 'Khối 5'
                },
            ],
            khoiCap2: [{
                value: 6,
                label: 'Khối 6'
            },
                {
                    value: 7,
                    label: 'Khối 7'
                },
                {
                    value: 8,
                    label: 'Khối 8'
                },
                {
                    value: 9,
                    label: 'Khối 9'
                },
            ],
            khoiCap3: [{
                value: 10,
                label: 'Khối 10'
            },
                {
                    value: 11,
                    label: 'Khối 11'
                },
                {
                    value: 12,
                    label: 'Khối 12'
                },

            ],
        }
    },
    computed: {
        ...mapState('account', [
            'user',
            'nam_hoc_hien_tai',
            'list_nam_hoc',
            'list_cap_hoc',
        ]),
    },
    mounted() {
        console.error('Thông tin:')
        console.error(this.user)
        this.getDanhSachDonVi();
        console.error(this.list_cap_hoc)
        console.log('Cấp học')
        this.dataSearch.namHoc = this.nam_hoc_hien_tai;
        if(this.list_cap_hoc.length==1){
            this.dataSearch.capHoc = this.list_cap_hoc[0].value
        }
        this.mapDanhSachKhoi()
        if(this.user.role==6){
            this.dataSearch.donVi = this.user.maPhong;
        }
        // this.getDataReport();
    },
    methods: {

        mapDanhSachKhoi() {
            let arr = [];
            this.danh_sach_khoi_hoc = [];
            for (let i = 0; i < this.list_cap_hoc.length; i++) {
                if (this.list_cap_hoc[i].value == 1) {
                    arr = arr.concat(this.khoiCap1)
                }
                if (this.list_cap_hoc[i].value == 2) {
                    arr = arr.concat(this.khoiCap2)
                }
                if (this.list_cap_hoc[i].value == 3) {
                    arr = arr.concat(this.khoiCap3)
                }
            }
            this.danh_sach_khoi_hoc = arr;
        },
        capHocChange() {
            this.dataSearch.khoiHoc = '';
            if (!this.dataSearch.capHoc) {
                this.mapDanhSachKhoi();
                return;
            } else {
                if (this.dataSearch.capHoc == 1) {
                    this.danh_sach_khoi_hoc = this.khoiCap1;
                } else if (this.dataSearch.capHoc == 2) {
                    this.danh_sach_khoi_hoc = this.khoiCap2;
                } else {
                    this.danh_sach_khoi_hoc = this.khoiCap3;
                }
            }
        },
        getTime() {
            var d = new Date();
            var n = d.getTime();
            return n;
        },
        xemBaoCao(e, type) {
            console.error('Xem báo cáo:')
            console.error(e)
            let params = {
                maSo: this.user.maSo,
                maPhong: this.dataSearch.donVi,
                capHoc: this.dataSearch.capHoc,
                namHoc: this.dataSearch.namHoc
            }
            let url = e.pathApi
            this.loadingScreen = true;
            api.get(url, params).then(
                response => {
                    console.error('Lấy excel trả về:')
                    if (response.data.code == 200) {
                        let linkDowndLoad = response.data.data
                        if (type == 1) {
                            window.open(linkDowndLoad, '_blank');
                        } else {
                            let urlReport = 'https://view.officeapps.live.com/op/view.aspx?src=' + linkDowndLoad + '?t=' + this.getTime();
                            window.open(urlReport, '_blank');
                        }
                    } else {
                        this.thongBao('error', response.data.msg)
                    }
                    console.error(this.list_truong_hoc)
                    this.loadingScreen = false;
                }
            )
        },
        async viewExcel(data) {

            let duLieu = JSON.parse(LZString.decompressFromBase64(data));

            return  duLieu;
        },
        viewBaoCao(){

            let params = {
                maSo: this.user.maSo,
                maPhong: this.dataSearch.donVi,
                maTruong: this.dataSearch.truongHoc,
                namHoc: this.dataSearch.namHoc,
                khoiHoc: this.dataSearch.khoiHoc,
                maLop: this.dataSearch.lopHoc,
                capHoc:this.dataSearch.capHoc
            }
            let url = constant.report.duLieuKy;
            this.loadingScreen = true;
            this.linkViewReport = '';
            this.linkDownloadReport = '';
            api.get(url, params).then(
                async response => {

                    console.log(response)
                    if (response.data.code == 200 && response.data.data) {
                        const data = await this.viewExcel(response.data.data)
                        this.dataView = data
                    } else {
                        this.thongBao('error', response.data.msg)
                    }
                    setTimeout(() => {
                        this.loadingScreen = false;
                    }, 1000)
                }
            )
        },
        getDataReport() {
            console.log('getDataReport')
            console.log(this.dataSearch)
            let params = {
                maSo: this.user.maSo,
                maPhong: this.dataSearch.donVi,
                maTruong: this.dataSearch.truongHoc,
                namHoc: this.dataSearch.namHoc,
                khoiHoc: this.dataSearch.khoiHoc,
                maLop: this.dataSearch.lopHoc,
                capHoc:this.dataSearch.capHoc
            }
            let url = constant.report.duLieuKy;
            this.loadingScreen = true;
            this.linkViewReport = '';
            this.linkDownloadReport = '';
            api.get(url, params).then(
                response => {
                    console.log('Dữ liệu báo cáo')
                    console.log(response)
                    if (response.data.code == 200 && response.data.data) {
                        // this.linkDownloadReport = response.data.data
                        // let urlReport = 'https://view.officeapps.live.com/op/view.aspx?src=' + response.data.data + '?t=' + this.getTime();
                        // this.linkViewReport = urlReport;
                        // console.log('Link xem là:')
                        // console.log(this.linkViewReport)
                      this.handleExportExcel(response.data.data);
                    } else {
                        this.thongBao('error', response.data.msg)
                    }
                    setTimeout(() => {
                        this.loadingScreen = false;
                    }, 1000)
                }
            )
        },
      handleExportExcel(data) {
        console.log('handleExportExcel')
        let duLieu = JSON.parse(LZString.decompressFromBase64(data));
        console.log(duLieu)
        let merge_heaer = duLieu.merge;
        let array_data = duLieu.data ?? [];
        let wb = new ExcelJS.Workbook();
        array_data.forEach((ws) => {
          console.log('ws:')
          console.log(ws)
          let sheet = wb.addWorksheet(ws.sheetName, {
            pageSetup: {
              paperSize: 9,
              fitToPage: true,
              fitToHeight: 0,
              fitToWidth: 1,
            },
          });

          let arrHeader = [];
          let maxRowSpan = 1;
          console.log('ws 2:')
          console.log(ws)
          ws.header.forEach((item) => {
            console.log('item')
            console.log(item)
            let arrRows = item.map((c) => c);
            arrHeader.push(arrRows);

            // Track the maximum rowspan
            // item.forEach((c) => {
            //   if (c.rowspan && c.rowspan > maxRowSpan) {
            //     maxRowSpan = c.rowspan;
            //   }
            // });
          });

          // Add header rows to the sheet
          sheet.addRows(arrHeader);

          // Set the height of the header rows
          for (let i = 1; i <= maxRowSpan; i++) {
            sheet.getRow(i).height = 50; // Adjust this value to set the desired height
          }
          console.log('B2')
          // Handle merging cells for colspan and rowspan
          ws.header.forEach((item, rowIndex) => {
            console.log('1. item')
            console.log(item)
            item.forEach((c, colIndex) => {
              console.log('2.c')
              console.log(c)
              let startRow = rowIndex + 1;
              let startCol = colIndex + 1;
              let endRow = startRow + (c.rowspan ? c.rowspan - 1 : 0);
              let endCol = startCol + (c.colspan ? c.colspan - 1 : 0);

              // if (startRow !== endRow || startCol !== endCol) {
              // sheet.mergeCells(startRow, startCol, endRow, endCol);
              // }

              let cell = sheet.getCell(startRow, startCol);
              cell.font = {name: "Times New Roman", size: 13, bold: true};
              cell.alignment = {vertical: "middle", horizontal: "center"};
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: {argb: "FFDDDDDD"},
              };
              cell.border = {
                top: {style: "thin"},
                left: {style: "thin"},
                bottom: {style: "thin"},
                right: {style: "thin"},
              };
            });
          });

          // Add data rows to the sheet
          console.log('B3')
          console.log(ws)
          let dataStartRow = maxRowSpan + 1; // Determine where the data rows start
          let du_lieu_build = ws.success??[];
          du_lieu_build.forEach((item) => {
            console.log('3.item')
            console.log(item)
            let arrRow = item.map((c) => c);
            console.log('arrRow')
            console.log(arrRow)
            sheet.addRow(arrRow);
          });

          // Set the height for each data row and adjust column widths based on the maximum length of content in each column
          sheet.eachRow({includeEmpty: true}, (row, rowNumber) => {
            if (rowNumber >= dataStartRow) {
              // Skip the header rows
              row.height = 30; // Adjust this value to set the desired height for data rows

              row.eachCell({includeEmpty: true}, (cell) => {
                if (cell.value) {
                  const cellLength = cell.value.toString().length;
                  const currentCol = sheet.getColumn(cell.col);
                  // Set minimum column width for columns except the first column
                  const minWidth = cell.col === 1 ? undefined : 30;

                  // Adjust column width based on content
                  if (minWidth !== undefined) {
                    if (!currentCol.width || currentCol.width < minWidth) {
                      currentCol.width = minWidth;
                    }
                  }
                  if (cellLength > currentCol.width) {
                    currentCol.width = cellLength;
                  }
                }
              });
            }
          });

          // Format all rows (including header and data rows)
          sheet.eachRow((row) => {
            row.eachCell((cell) => {
              cell.font = {name: "Times New Roman", size: 13};
              cell.alignment = {vertical: "middle", horizontal: "center"};
              cell.border = {
                top: {style: "thin"},
                left: {style: "thin"},
                bottom: {style: "thin"},
                right: {style: "thin"},
              };
            });
          });
          merge_heaer.forEach((c, colIndex) => {
            console.log('merge_heaer')
            console.log(c)
            sheet.mergeCells((c.firstRow), (c.firstCol+1), (c.lastRow), (c.lastCol+1));
          });
        });

        // Save the workbook
        wb.xlsx.writeBuffer().then((data) => {
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.download = duLieu.fileName + ".xlsx";
          anchor.click();
          window.URL.revokeObjectURL(url);
        });
      },
        thongBao(t, e) {
            let msg = "";
            let cl = "";
            if (e) {
                msg = e;
            }
            let type = "success";
            if (t) {
                type = t
            }
            if (type == "success") {
                cl = "dts-noty-success"
            }
            if (type == "warning") {
                cl = "dts-noty-warning"
            }
            if (type == "error") {
                cl = "dts-noty-error"
            }
            if (type == "info") {
                cl = "dts-noty-info"
            }
            this.$message({
                dangerouslyUseHTMLString: true,
                customClass: cl,
                showClose: true,
                message: msg,
                type: t,
                duration: 3000
            });
        },
        chonKhoiHoc() {
            this.dataSearch.lopHoc = '';
            this.getlistLopHoc();
        },
        getlistLopHoc() {
            if (!this.dataSearch.namHoc) {
                this.thongBao('error', 'Vui lòng bổ sung thông tin năm học.')
                return;
            }
            let params = {
                start: 0,
                limit: 999,
                khoiHoc: this.dataSearch.khoiHoc,
                namHoc: this.dataSearch.namHoc,
                maTruong: this.dataSearch.truongHoc,
            }
            console.log(params)

            try {
                let uri = constant_api.hocsinh.getListLop;
                console.log('uri: ' + uri)
                this.loadingScreen = true;
                this.danh_sach_lop_hoc = [];
                api.get(uri, params).then(
                    response => {
                        console.log("Lấy ds học bạ trả về:")
                        console.log(response.data.data.list);
                        if (response.data.code == 200) {
                            this.danh_sach_lop_hoc = response.data.data.list;
                            if (this.danh_sach_lop_hoc.length == 0) {
                                this.thongBao('error', 'Không tìm thấy danh sách lớp')
                            }
                        } else {
                            this.thongBao('error', 'Không tìm thấy danh sách lớp')
                        }
                        this.loadingScreen = false;
                    }
                ).catch((e) => {
                    this.loadingScreen = false;
                })

            } catch (e) {
                this.thongBao('error', 'Vui lòng thao tác lại.')
            }
        },
        chonDonVi() {
            this.dataSearch.truongHoc = '';
            this.list_truong_hoc = [];
            if(this.dataSearch.donVi){
              this.getDanhSachTruongHoc();
              this.capHocChange();
            }
        },
        getDanhSachTruongHoc() {
            let params = {
                maPhong: this.dataSearch.donVi,
                capHoc: this.dataSearch.capHoc,
            }
            let url = constant.danhMuc.danhSachTruong;
            this.list_truong_hoc = [];
            this.loadingScreen = true;
            api.get(url, params).then(
                response => {
                    if (response.data.code == 200) {
                        let data = response.data.data;
                        for (let i = 0; i < data.length; i++) {
                            this.list_truong_hoc.push(
                                {
                                    name: data[i].tenTruongHoc + ' - [' + data[i].maTruongHoc + ']',
                                    value: data[i].maTruongHoc
                                }
                            )
                        }

                    } else {
                    }
                    console.error(this.list_truong_hoc)
                    this.loadingScreen = false;
                }
            )
        },
        getDanhSachDonVi() {
            let params = {
                maSo: this.user.maSo,
            }
            let url = constant.danhMuc.danhSachDonVi;
            this.list_don_vi = [];
            this.loadingScreen = true;
            api.get(url, params).then(
                response => {
                    if (response.data.code == 200) {
                        let data = response.data.data;
                        for (let i = 0; i < data.length; i++) {
                            this.list_don_vi.push(
                                {
                                    name: data[i].tenDonVi.replace("Giáo dục và Đào tạo", "GD&ĐT") + ' - [' + data[i].maDonVi + ']',
                                    value: data[i].maDonVi
                                }
                            )
                        }

                    } else {
                    }
                    console.error(this.list_don_vi)
                    this.loadingScreen = false;
                }
            )
        },
    }
}
</script>

<style scoped>

</style>