<template>
    <div class="page">
        <div class="page__title d-flex align-items-center justify-content-between">
            <h1 class="title">Nhập học sinh</h1>
          <el-tooltip class="item" effect="dark" content="Hướng dẫn nhập dữ liệu" placement="top">
            <a href="/files/huong_dan_nhap_du_lieu.docx"> Hướng dẫn nhập dữ liệu</a>
          </el-tooltip>
        </div>
        <div class="page__header">
            <el-row :gutter="20">
                <el-col :xs="12" :sm="6" :md="4" :lg="4">
                    <label>Năm học</label>
                    <eselect style="width:100%" collapseTags v-model="namHoc" placeholder="Chọn" filterable
                             :data="list_nam_hoc" :fields="['name','value']"/>
                </el-col>
                <el-col :xs="12" :sm="6" :md="4" :lg="4">
                    <label>Cấp học</label>
                    <eselect style="width:100%" collapseTags v-model="capHoc" @change="getPathFile"
                             placeholder="Chọn" filterable :data="list_cap_hoc" :fields="['name','value']"/>
                </el-col>
            </el-row>
        </div>
        <div>
            <div class="page__header">
                <div class="page__title align-items-center justify-content-between">
                    <h1 class="title">Dữ liệu học sinh</h1>
                  <small><i> (Truy cập Học bạ số -> Nhập học sinh -> Tải file mẫu nhập học sinh)</i></small>
<!--                    <p v-if="user.qlnt==1"><i style="font-size:12px">(Truy cập vnedu -> Quản lý lớp học -> Nhập xuất ->-->
<!--                        Xuất ra excel học sinh toàn trường mẫu 1)</i></p>-->
<!--                    <p v-if="user.qlnt==2"><i style="font-size:12px">(Truy cập moet -> Học sinh -> Hồ sơ học sinh ->-->
<!--                        Nhập danh sách học sinh từ excel -> Tải file mẫu kèm dữ liệu)</i></p>-->
                </div>
<!--                <el-row :gutter="20">-->
<!--                    <el-col :xs="12" :sm="6" :md="4" :lg="4">-->
<!--                        <label>Phương thức nhập (<i style="color: red;">*</i>)</label>-->
<!--                        <eselect class="mb-2" size="small" style="width:100%" collapseTags placeholder="Chọn"-->
<!--                                 v-model="typeHocSinh" filterable-->
<!--                                 :data="this.user.qlnt==1? listPhuongThucNhapVnEdu : listPhuongThucNhapMoet"-->
<!--                                 :fields="['name','value']"/>-->
<!--                    </el-col>-->
<!--                </el-row>-->

                <ImportGeneral :pathSampleFile="typeHocSinh == 1? pathSampleFile.hocSinh : pathSampleFile.hocSinhChung"
                               :fileNameError="'danh_sach_loi_nhap_hoc_sinh'" :fileHasData="true"
                               :linkFileHasData="typeHocSinh === 1? linkFileHasData : linkFileHasDataChung"
                               :require-nam-hoc="true" :year="namHoc" :cap-hoc="capHoc" :template-download="template"
                               :source="source_moet"
                               :data-type="1"
                               :requireKhoiHoc="true"
                               :hideClass="false"
                               :text-download="'Tải file nhập học sinh'"
                               :disabledDownload="typeHocSinh!=2&&false"
                               :requireImportType="true" :path="typeHocSinh === 1&&false? path : pathChung"
                               :importType="typeHocSinh"></ImportGeneral>
            </div>
            <div class="page__header" v-if="type==2&&false">
                <div class="page__title align-items-center justify-content-between">
                    <h1 class="title">Sổ đăng bộ - Ngày vào trường</h1>
                  <small><i> (Truy cập Học bạ số -> Nhập học sinh -> Tải file mẫu sổ đăng bộ - ngày vào trường)</i></small>
<!--                    <p v-if="user.qlnt==2"><i style="font-size:12px">(Tải dữ liệu học sinh trên phần mềm học bạ điện-->
<!--                        tử)</i></p>-->
                </div>
                <ImportForNhanXetMoet :require-cap-hoc="true" :require-nam-hoc="true" :year="namHoc" :cap-hoc="capHoc"
                                      :needClass="true"
                                      :data-type="98"
                                      :text-download="'Tải file nhập sổ đăng bộ - ngày vào trường'"
                                      :uri-dowload="dowloadHocSinh" :source="source" :path="pathNhanXetHocSinh"
                                      name-file="so-dang-bo-ngay-vao-truong"></ImportForNhanXetMoet>
            </div>
            <!--            <div class="page__header">-->
            <!--                <div class="page__title d-flex align-items-center justify-content-between">-->
            <!--                    <h2 class="title">Dữ liệu học sinh chứa mã Moet</h2>-->
            <!--                </div>-->
            <!--                <ImportManySheet2 :require-nam-hoc="true" :year="namHoc" :cap-hoc="capHoc" :template-download="template" :source="source" :path="path2"></ImportManySheet2>-->
            <!--            </div>-->
        </div>
        <!--        <div v-if="capHoc == 1">-->
        <!--            <div class="page__header">-->
        <!--                <div class="page__title d-flex align-items-center justify-content-between">-->
        <!--                    <h1 class="title">Chiều cao - Cân nặng</h1>-->
        <!--                </div>-->
        <!--                <ImportForNhanXet-->
        <!--                        :year="namHoc"-->
        <!--                        :cap-hoc="capHoc"-->
        <!--                        :require-nam-hoc="true"-->
        <!--                        :require-cap-hoc="true"-->
        <!--                        :uri-dowload="dowloadChieuCaoCanNang" :source="source" :path="pathChieuCaoCanNang"-->
        <!--                        name-file="chieu-cao-can-nang"></ImportForNhanXet>-->
        <!--            </div>-->
        <!--        </div>-->
    </div>
</template>
<script>
//
//import ImportGeneral from "./ImportGeneral";
//import ImportForNhanXetMoet from "./ImportForNhanXetMoet";
import ImportGeneral from "./ImportChung.vue";
import ImportForNhanXetMoet from "./ImportChung";
import constant_api from "../../_helpers/constant_api";
import ESelect from "../../components/ESelect";
import {
    mapState
} from "vuex";
import ImportForNhanXet from "./ImportForNhanXet";

export default {
    name: "ImportHocSinh",
    metaInfo: {
        title: 'Nhập học sinh',
        meta: [{
            vmid: 'description',
            name: 'description',
            content: ''
        }],
    },
    components: {
        ImportForNhanXetMoet,
        ImportGeneral,
        'eselect': ESelect,
        ImportForNhanXet
    },
    computed: {
        ...mapState('account',
            [
                "user",
                'list_nam_hoc',
                'list_cap_hoc',
                'nam_hoc_hien_tai',
            ]),
    },
    methods: {
        getListCapHoc() {
            if (this.user && this.user.capHoc) {
                let ch = this.user.capHoc.toString();
                let cap_hoc = ch.split('');
                this.capHoc = parseInt(cap_hoc[0]);
            }
        },
        getPathFile() {
            this.pathSampleFile.hocSinhChung = '/files/common/hoc_sinh.xlsx';
            if (this.user.qlnt == 1) {
                this.pathSampleFile.hocSinh = '/files/vnedu/hoc_sinh_vnedu.xls?t=12';
            }
            if (this.user.qlnt == 2) {
                if (this.capHoc == 1) {
                    this.pathSampleFile.hocSinh = '/files/moet/hoc_sinh_c1_moet.xls';
                } else if (this.capHoc == 2) {
                    this.pathSampleFile.hocSinh = '/files/moet/hoc_sinh_c2_moet.xls';
                } else {
                    this.pathSampleFile.hocSinh = '/files/moet/hoc_sinh_moet_c3.xls';
                }
            }
            console.log('Mẫu:')
            console.log(this.pathSampleFile)
        }
    },
    mounted() {
        this.getListCapHoc();
        this.namHoc = this.nam_hoc_hien_tai;
        if (this.user) {
            this.type = this.user.qlnt;
            this.getPathFile();
        }
    },
    data() {
        return {
            pathSampleFile: {
                hocSinh: '',
                hocSinhChung: ''
            },
            dowloadChieuCaoCanNang: constant_api.download.chieuCaoCanNang,
            pathNhanXetHocSinh: constant_api.import.nhanXetHocSinh,
            dowloadHocSinh: constant_api.download.soDangBoHocSinh,
            linkFileHasData: constant_api.download.linkFileHasData.hocSinh,
            linkFileHasDataChung: constant_api.download.linkFileHasData.hocSinhChung,
            namHoc: '',
            capHoc: '',
            type: '',
            template: [{
                label: 'VNEDU',
                type: 1,
                url: ''
            },],
            source: [{
                type: 1,
                label: 'VNEDU',
                rowHeader: 7
            }, {
                type: 2,
                label: 'Mẫu Học bạ số',
                rowHeader: 1
            }],
            source_moet: [{
                type: 1,
                label: 'MOET',
                rowHeader: 1
            }, {
                type: 2,
                label: 'Mẫu Học bạ số',
                rowHeader: 1
            }],
            path: constant_api.import.hocSinh,
            pathChung: constant_api.import.hocSinhChung,
            path2: constant_api.import.hocSinhChuaMaMoet,
            pathChieuCaoCanNang: constant_api.import.chieuCaoCanNang,
            typeHocSinh: '',
            listPhuongThucNhapVnEdu: [
                {
                    'name': 'Mẫu VNEDU',
                    'value': 1
                },
                {
                    'name': 'Mẫu Học bạ số',
                    'value': 2
                }
            ],
            listPhuongThucNhapMoet: [
                {
                    'name': 'Mẫu MOET',
                    'value': 1
                },
                {
                    'name': 'Mẫu Học bạ số',
                    'value': 2
                }
            ]
        }
    }
}
</script>
<style scoped>

.title {
    margin-bottom: 0;
}
</style>