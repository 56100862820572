<template>
  <div>
    <div ref="pdfViewer">
      <iframe style="width: 100%; height: 100vh; min-height: 600px" :src="urlPdf"></iframe>
    </div>
  </div>
</template>
<script>
import jsPDF from 'jspdf';
import {times} from './../../../../../fonts/times-normal';
import {timesB} from './../../../../../fonts/times-bold';
import {timesItalic} from './../../../../../fonts/times-italic';
import 'jspdf-autotable';

export default {
  props: ['item'],
  data() {
    return {
      thongTinKqht: [],
      dataForm: {
        schoolName: ''
      },
      urlPdf: ''
    }
  },
  mounted() {
    console.log('mounted view xem học bạ:')
    console.log(JSON.stringify(this.item))
    this.generatePDF();
  },
  methods: {
    generatePDF() {
      console.log('generatePDF')
      const doc = new jsPDF('p', 'mm', 'a4');
      doc.addFileToVFS("Times-normal.ttf", times);
      doc.addFileToVFS("Times-bold.ttf", timesB);
      doc.addFileToVFS("Times-italic.ttf", timesItalic);
      doc.addFont("Times-normal.ttf", "VNTimes", "normal");
      doc.addFont("Times-bold.ttf", "TimesBold", "normal");
      doc.addFont("Times-italic.ttf", "VNTimesItalic", "normal");
      // Tạo đường viền cho trang đầu tiên
      doc.setFontSize(12);
      doc.setFont("VNTimes")
      console.log('Xem học bạ của ' + this.item.length + 'bản ghi')
      for (let j = 0; j < this.item.length; j++) {
        console.log('file pdf thứ ' + j)
        let moiHocBa = JSON.parse(JSON.stringify(this.item[j]));
        console.log('moiHocBa')
        console.log(moiHocBa)
        let totalPages = 4;
        let dulieuRender = moiHocBa.KQHT ? moiHocBa.KQHT : {}
        for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
          this.addContentToPage(doc, pageNumber, dulieuRender);

          console.log('Ad page')
          console.log('j:' + j)
          console.log('pageNumber: ' + pageNumber)
          if (pageNumber <= totalPages) {
            doc.addPage();
          }
        }
      }
      const totalPages = doc.internal.getNumberOfPages();
      console.log('Tổng trang:' + totalPages)
      doc.deletePage(totalPages);//xóa trang cuoois (trang trắng)
      const pdfDataUrl = doc.output('dataurl');
      this.displayPDF(pdfDataUrl);
    },
    addContentToPage(doc, pageNumber, duLieuRender) {
      let duLieu = null;
      if (Array.isArray(duLieuRender)) {
        duLieu = duLieuRender[0]
      } else {
        duLieu = duLieuRender
      }
      console.log('dulieuRender:')
      console.log(duLieu)
      console.log('Kiểu')
      console.log(Array.isArray(duLieu))
      console.log('addContentToPage' + pageNumber)
      let pageWidth = doc.internal.pageSize.getWidth();
      if (pageNumber == 1) {
        doc.setFont("TimesBold")
        const marginTop = 10; // 1,5cm
        const marginLeft = 15; // 2cm
        const marginRight = doc.internal.pageSize.getWidth() - 10; // 1cm từ lề phải
        const marginBottom = doc.internal.pageSize.getHeight() - 10; // 1cm từ lề dưới

        doc.setLineWidth(0.3);
        doc.line(marginLeft, marginTop, marginRight, marginTop); // Đường viền trên
        doc.line(marginLeft, marginTop, marginLeft, marginBottom); // Đường viền trái
        doc.line(marginRight, marginTop, marginRight, marginBottom); // Đường viền phải
        doc.line(marginLeft, marginBottom, marginRight, marginBottom); // Đường viền dưới

        doc.setLineWidth(0.6);
        doc.line(marginLeft + 2, marginTop + 2, marginRight - 2, marginTop + 2); // Đường viền trên
        doc.line(marginLeft + 2, marginTop + 2, marginLeft + 2, marginBottom - 2); // Đường viền trái
        doc.line(marginRight - 2, marginTop + 2, marginRight - 2, marginBottom - 2); // Đường viền phải
        doc.line(marginLeft + 2, marginBottom - 2, marginRight - 2, marginBottom - 2); // Đường viền dưới

        let textHocBa = "BỘ GIÁO DỤC VÀ ĐÀO TẠO";
        let fontSize = 16;
        let textWidth = doc.getStringUnitWidth(textHocBa) * fontSize / doc.internal.scaleFactor;
        doc.setFontSize(fontSize);
        doc.text(textHocBa, (pageWidth - textWidth) / 2, 20);
        fontSize = 40;
        doc.setFontSize(fontSize);
        textHocBa = "HỌC BẠ";
        textWidth = doc.getStringUnitWidth(textHocBa) * fontSize / doc.internal.scaleFactor;
        doc.text(textHocBa, (pageWidth - textWidth) / 2, 90);
        fontSize = 25;
        doc.setFontSize(fontSize);
        textHocBa = "TIỂU HỌC";
        textWidth = doc.getStringUnitWidth(textHocBa) * fontSize / doc.internal.scaleFactor;
        doc.text(textHocBa, (pageWidth - textWidth) / 2, 110);
        // Họ tên học sinh
        fontSize = 13;
        doc.setFontSize(fontSize);
        doc.setFont("VNTimes")
        doc.text('Họ và tên học sinh:', 24, 190);
        doc.setFont("TimesBold")
        doc.text(duLieu.hoten ? duLieu.hoten : '', 64, 190);
        doc.setFont("VNTimes")

        doc.text('Lớp:', 24, 200);
        doc.setFont("TimesBold")
        doc.text(duLieu.tenLop ? duLieu.tenLop : '', 34, 200);
        doc.setFont("VNTimes")

        doc.text('Trường:', 24, 210);
        doc.setFont("TimesBold")
        doc.text(duLieu.tenTruong ? duLieu.tenTruong : '', 40, 210);
        doc.setFont("VNTimes")

        doc.text('Năm học:', 24, 220);
        doc.setFont("TimesBold")
        doc.text(duLieu.namHoc ? (duLieu.namHoc + ' - ' + (duLieu.namHoc + 1)) : '', 45, 220);
        doc.setFont("VNTimes")

        doc.text('Xã (Phường, Thị trấn):', 24, 230);
        doc.setFont("TimesBold")
        doc.text(duLieu.tenXa ? duLieu.tenXa : '', 70, 230);
        doc.setFont("VNTimes")

        doc.text('Huyện (Thành phố, Thị xã):', 24, 240);
        doc.setFont("TimesBold")
        doc.text(duLieu.tenHuyen ? duLieu.tenHuyen : '', 80, 240);
        doc.setFont("VNTimes")

        doc.text('Tỉnh (Thành phố):', 24, 250);
        doc.setFont("TimesBold")
        doc.text(duLieu.tenTinh, 62, 250);
        doc.setFont("VNTimes")
        // doc.rect(14, 9, doc.internal.pageSize.width - 23, doc.internal.pageSize.height - 23);
      } else if (pageNumber == 2) {
        let textHocBa = "HỌC BẠ";
        let fontSize = 30;
        doc.setFont("TimesBold")
        let textWidth = doc.getStringUnitWidth(textHocBa) * fontSize / doc.internal.scaleFactor;
        doc.setFontSize(fontSize);
        doc.text(textHocBa, (pageWidth - textWidth) / 2, 25);
        doc.setFont("VNTimes")
        doc.setFontSize(13);

        doc.text('Họ và tên học sinh:', 14, 35);
        doc.setFont("TimesBold")
        doc.text(duLieu.hoten ? duLieu.hoten : '', 54, 35);
        doc.setFont("VNTimes")
        doc.text('Giới tính:', 130, 35);
        doc.setFont("TimesBold")
        doc.text(duLieu.gioiTinh == 1 ? 'Nam' : 'Nữ', 150, 35);
        doc.setFont("VNTimes")

        doc.text('Ngày, tháng, năm sinh:', 14, 45);
        doc.setFont("TimesBold")
        doc.text(duLieu.ngaySinh ? duLieu.ngaySinh : '', 60, 45);
        doc.setFont("VNTimes")
        doc.text('Dân tộc:', 100, 45);
        doc.setFont("TimesBold")
        doc.text(duLieu.tenDanToc ? duLieu.tenDanToc : '', 120, 45);
        doc.setFont("VNTimes")
        doc.text('Quốc tịch:', 150, 45);
        doc.setFont("TimesBold")
        doc.text(duLieu.tenQuocTich ? duLieu.tenQuocTich : '', 170, 45);

        doc.setFont("VNTimes")
        doc.text('Mã số định danh cá nhân:', 14, 55);
        doc.setFont("TimesBold")
        doc.text(duLieu.maDinhDanhCongDan ? duLieu.maDinhDanhCongDan : '', 65, 55);

        doc.setFont("VNTimes")
        doc.text('Nơi sinh:', 14, 65);
        doc.setFont("TimesBold")
        doc.text(duLieu.noiSinh ? duLieu.noiSinh : '', 35, 65);


        doc.setFont("VNTimes")
        doc.text('Quê quán:', 14, 75);
        doc.setFont("TimesBold")
        doc.text(duLieu.queQuan ? duLieu.queQuan : '', 35, 75);

        doc.setFont("VNTimes")
        doc.text('Nơi ở hiện nay:', 14, 85);
        doc.setFont("TimesBold")
        doc.text(duLieu.noiOHienNay ? duLieu.noiOHienNay : '', 45, 85);

        doc.setFont("VNTimes")
        doc.text('Họ và tên cha:', 14, 95);
        doc.setFont("TimesBold")
        doc.text(duLieu.hoTenCha ? duLieu.hoTenCha : '', 44, 95);

        doc.setFont("VNTimes")
        doc.text('Họ và tên mẹ:', 14, 105);
        doc.setFont("TimesBold")
        doc.text(duLieu.hoTenMe ? duLieu.hoTenMe : '', 44, 105);

        doc.setFont("VNTimes")
        doc.text('Người giám hộ (nếu có):......', 14, 115);
        doc.setFont("TimesBold")
        doc.text(duLieu.nguoiGiamHo ? duLieu.nguoiGiamHo: '', 60, 117);

        doc.setFont("VNTimes")
        doc.text('Mã số tra cứu học bạ (UUID):', 14, 125);
        doc.setFont("TimesBold")
        doc.text(duLieu.maSoHocBa ? duLieu.maSoHocBa : '', 75, 125);

        doc.setFont("VNTimesItalic")
        doc.setFontSize(11);
        doc.text('(Mã tra cứu học bạ UUID sử dụng để tra cứu học bạ số trên cổng tra cứu Học bạ số - Bộ GDĐT):', 14, 135);
        doc.setFontSize(13);
        doc.setFont("VNTimes")
        doc.text('Người ký học bạ:', 14, 145);
        doc.setFont("TimesBold")
        doc.text(duLieu.tenNguoiKyBia ? duLieu.tenNguoiKyBia : '', 50, 145);
        doc.setFont("VNTimes")
        doc.text('Chức vụ của người ký học bạ:', 100, 145);
        doc.setFont("TimesBold")
        doc.text(duLieu.tenChucVu ? duLieu.tenChucVu : '', 160, 145);

        doc.setFont("VNTimes")
        doc.text('Ngày ký học bạ:', 14, 155);
        doc.setFont("TimesBold")
        doc.text(duLieu.ngayKyBia ? duLieu.ngayKyBia : '', 45, 155);
      } else if (pageNumber == 3) {
        const marginTop = 10; // 1,5cm
        const marginLeft = 15; // 2cm
        const marginRight = doc.internal.pageSize.getWidth() - 10; // 1cm từ lề phải
        const marginBottom = doc.internal.pageSize.getHeight() - 10; // 1cm từ lề dưới

        doc.setLineWidth(0.3);
        doc.line(marginLeft, marginTop, marginRight, marginTop); // Đường viền trên
        doc.line(marginLeft, marginTop, marginLeft, marginBottom); // Đường viền trái
        doc.line(marginRight, marginTop, marginRight, marginBottom); // Đường viền phải
        doc.line(marginLeft, marginBottom, marginRight, marginBottom); // Đường viền dưới

        doc.setLineWidth(0.6);
        doc.line(marginLeft + 2, marginTop + 2, marginRight - 2, marginTop + 2); // Đường viền trên
        doc.line(marginLeft + 2, marginTop + 2, marginLeft + 2, marginBottom - 2); // Đường viền trái
        doc.line(marginRight - 2, marginTop + 2, marginRight - 2, marginBottom - 2); // Đường viền phải
        doc.line(marginLeft + 2, marginBottom - 2, marginRight - 2, marginBottom - 2); // Đường viền dưới

        doc.setFont("TimesBold")
        doc.setFontSize(13);
        doc.text("1. Kết quả các môn học và hoạt động giáo dục", 20, 25);

        let header = [
          [{content: 'Môn học và hoạt động giáo dục', colSpan: 1}, {
            content: "Mức đạt được",
            colSpan: 1
          }, {textAlign: 'center', content: "Điểm kiểm tra", colSpan: 1}, {content: "Nhận xét", colSpan: 1,}],
        ]
        let body = [
          [{content: 'Tiếng Việt', colSpan: 1}, {
            content: duLieu.tvDgtx ? duLieu.tvDgtx : '',
            colSpan: 1
          }, {content: duLieu.tvDiem ? duLieu.tvDiem : '', colSpan: 1}, {
            content: duLieu.nhanXet ? duLieu.nhanXet : '',
            rowSpan: 15
          }],
          [{content: 'Toán', colSpan: 1}, {
            content: duLieu.toDgtx ? duLieu.toDgtx : '',
            colSpan: 1
          }, {content: duLieu.toDiem ? duLieu.toDiem : '', colSpan: 1}],
          [{content: 'Ngoại ngữ', colSpan: 1}, {
            content: duLieu.nnDgtx ? duLieu.nnDgtx : '',
            colSpan: 1
          }, {content: duLieu.nnDiem ? duLieu.nnDiem : '', colSpan: 1}],
          [{content: 'Lịch sử và Địa lý', colSpan: 1}, {
            content: duLieu.ldDgtx ? duLieu.ldDgtx : '',
            colSpan: 1
          }, {content: duLieu.ldDiem ? duLieu.ldDiem : '', colSpan: 1}],
          [{content: 'Khoa học', colSpan: 1}, {
            content: duLieu.khDgtx ? duLieu.khDgtx : '',
            colSpan: 1
          }, {content: duLieu.khDiem ? duLieu.khDiem : '', colSpan: 1}], [{
            content: 'Tin học và Công nghệ (Tin học)',
            colSpan: 1
          }, {
            content: duLieu.cnDgtx ? duLieu.cnDgtx : '',
            colSpan: 1
          }, {content: duLieu.cnDiem ? duLieu.cnDiem : '', colSpan: 1}],
          [{content: 'Tin học và Công nghệ (Công nghệ)', colSpan: 1}, {
            content: duLieu.thDgtx ? duLieu.thDgtx : '',
            colSpan: 1
          }, {content: duLieu.thDiem ? duLieu.thDiem : '', colSpan: 1}],

          [{content: 'Đạo đức', colSpan: 1}, {content: duLieu.ddDgtx ? duLieu.ddDgtx : '', colSpan: 1}, {
            content: '',
            rowSpan: 7
          }],
          [{content: 'Tự nhiên và Xã hội', colSpan: 1}, {content: duLieu.txDgtx ? duLieu.txDgtx : '', colSpan: 1}],
          [{content: 'Giáo dục thể chất', colSpan: 1}, {content: duLieu.tdDgtx ? duLieu.tdDgtx : '', colSpan: 1}],
          [{content: 'Nghệ thuật (Âm nhạc)', colSpan: 1}, {content: duLieu.anDgtx ? duLieu.anDgtx : '', colSpan: 1}],
          [{content: 'Nghệ thuật (Mĩ thuật)', colSpan: 1}, {content: duLieu.mtDgtx ? duLieu.mtDgtx : '', colSpan: 1}],
          [{content: 'Hoạt động trải nghiệm', colSpan: 1}, {
            content: duLieu.hdtnDgtx ? duLieu.hdtnDgtx : '',
            colSpan: 1
          }],
          [{content: 'Tiếng dân tộc', colSpan: 1}, {content: duLieu.dtDgtx ? duLieu.dtDgtx : '', colSpan: 1}],
        ]

        let columnStyles = {
          0: {
            halign: 'center',
            valign: 'middle',
            fontSize: 13,
            cellWidth: 50,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 2}
          },
          1: {
            halign: 'center',
            textAlign: 'center',
            fontSize: 13,
            cellPadding: {top: 4, right: 4, bottom: 4, left: 4},
            valign: 'middle',
            font: 'TimesBold',
            cellWidth: 40
          },
          2: {
            textAlign: 'center',
            halign: 'center', fontSize: 13, cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: 'middle',
            font: 'TimesBold',
            cellWidth: 30
          },
          3: {
            halign: 'left', fontSize: 13, cellPadding: {top: 1, right: 1, bottom: 1, left: 1}, valign: 'middle',
            cellWidth: 57
          },
        }
        let options = {
          margin: {left: 19}, // Khoảng cách giữa lề trái của trang và bảng
        };
        doc.autoTable({
          tableLineColor: [70, 76, 70],
          columnStyles: columnStyles,
          head: header,
          body: body,
          startY: 35,
          ...options,
          theme: 'grid',
          headStyles: {
            fillColor: [255, 255, 255],
            textColor: [45, 45, 45],
            font: 'TimesBold',
            fontStyle: 'normal',
            lineWidth: 0.1,
            halign: 'center',
            valign: 'middle',
            fontSize: 13,
            cellPadding: 1
          },
          styles: {
            textColor: [45, 45, 45],
            lineColor: [70, 76, 70],
            font: 'TimesBold',
            fontStyle: 'normal',
          },
          bodyStyles: {
            textColor: [45, 45, 45],
            lineColor: [70, 76, 70],
            font: 'VNTimes',
            fontStyle: 'normal',
            cellPadding: 2,
            fontSize: 13
          }
        })
      } else if (pageNumber == 4) {
        doc.setFont("TimesBold")
        doc.setFontSize(13);
        doc.text("2. Những phẩm chất chủ yếu", 15, 20);
        // Phẩm chất chủ yếu
        let header = [
          [{content: 'Phẩm chất', colSpan: 1}, {content: "Mức đạt được", colSpan: 1}, {
            content: "Nhận xét",
            colSpan: 1,
          }],
        ]
        let body = [
          [{content: 'Yêu nước', colSpan: 1}, {
            content: duLieu.pcYeuNuoc ? duLieu.pcYeuNuoc : '',
            colSpan: 1
          }, {content: duLieu.nxPhamChatChuYeu ? duLieu.nxPhamChatChuYeu : '', rowSpan: 5}],
          [{content: 'Nhân ái', colSpan: 1}, {content: duLieu.pcNhanAi ? duLieu.pcNhanAi : '', colSpan: 1}],
          [{content: 'Chăm chỉ', colSpan: 1}, {content: duLieu.pcChamChi ? duLieu.pcChamChi : '', colSpan: 1}],
          [{content: 'Trung thực', colSpan: 1}, {content: duLieu.pcTrungThuc ? duLieu.pcTrungThuc : '', colSpan: 1}],
          [{content: 'Trách nhiệm', colSpan: 1}, {content: duLieu.pcTrachNhiem ? duLieu.pcTrachNhiem : '', colSpan: 1}],
        ]

        let columnStyles = {
          0: {
            halign: 'center',
            valign: 'middle',
            fontSize: 13,
            cellWidth: 60,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 2}
          },
          1: {
            halign: 'center',
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: 'middle',
            font: 'TimesBold',
            cellWidth: 30
          },
          2: {
            halign: 'left', fontSize: 13, cellPadding: {top: 4, right: 4, bottom: 4, left: 4}, valign: 'middle',
            cellWidth: 95
          },
        }
        doc.autoTable({
          tableLineColor: [70, 76, 70],
          columnStyles: columnStyles,
          head: header,
          body: body,
          startY: 25,
          theme: 'grid',
          headStyles: {
            fillColor: [255, 255, 255],
            textColor: [45, 45, 45],
            font: 'TimesBold',
            fontStyle: 'normal',
            lineWidth: 0.1,
            halign: 'center',
            valign: 'middle',
            fontSize: 13,
            cellPadding: 1
          },
          styles: {
            textColor: [45, 45, 45],
            lineColor: [70, 76, 70],
            font: 'TimesBold',
            fontStyle: 'normal',
          },
          bodyStyles: {
            textColor: [45, 45, 45],
            lineColor: [70, 76, 70],
            font: 'VNTimes',
            fontStyle: 'normal',
            cellPadding: 2,
            fontSize: 13
          }
        })
        //   Năng lực cốt lõi

        doc.setFont("TimesBold")
        doc.setFontSize(13);
        doc.text("3. Những năng lực cốt lõi", 15, 78);
        doc.text("3.1. Những năng lực chung", 15, 83);
        header = [
          [{content: 'Năng lực', colSpan: 1}, {content: "Mức đạt được", colSpan: 1}, {
            content: "Nhận xét",
            colSpan: 1,
          }],
        ]
        body = [
          [{content: 'Tự chủ và tự học', colSpan: 1}, {
            content: duLieu.nlTuChuTuHoc ? duLieu.nlTuChuTuHoc : '',
            colSpan: 1
          }, {content: duLieu.nxNangLucChung ? duLieu.nxNangLucChung : '', rowSpan: 3}],
          [{
            content: 'Giao tiếp và hợp tác',
            colSpan: 1
          }, {content: duLieu.nlGiaoTiepHopTac ? duLieu.nlGiaoTiepHopTac : '', colSpan: 1}],
          [{
            content: 'Giải quyết vất đề và sáng tạo',
            colSpan: 1
          }, {content: duLieu.nlGquyetVdeSangTao ? duLieu.nlGquyetVdeSangTao : '', colSpan: 1}],
        ]
        columnStyles = {
          0: {
            halign: 'center',
            valign: 'middle',
            fontSize: 13,
            cellWidth: 60,
            cellPadding: {top: 3, right: 3, bottom: 3, left: 3}
          },
          1: {
            halign: 'center',
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: 'middle',
            cellWidth: 30,
            font: 'TimesBold',
          },
          2: {
            halign: 'left', fontSize: 13, cellPadding: {top: 2, right: 2, bottom:2, left: 2}, valign: 'middle',
            cellWidth: 95
          },
        }
        doc.autoTable({
          tableLineColor: [70, 76, 70],
          columnStyles: columnStyles,
          head: header,
          body: body,
          startY: 85,
          theme: 'grid',
          headStyles: {
            fillColor: [255, 255, 255],
            textColor: [45, 45, 45],
            font: 'TimesBold',
            fontStyle: 'normal',
            lineWidth: 0.1,
            halign: 'center',
            valign: 'middle',
            fontSize: 13,
            cellPadding: 1
          },
          styles: {
            textColor: [45, 45, 45],
            lineColor: [70, 76, 70],
            font: 'TimesBold',
            fontStyle: 'normal',
          },
          bodyStyles: {
            textColor: [45, 45, 45],
            lineColor: [70, 76, 70],
            font: 'VNTimes',
            fontStyle: 'normal',
            cellPadding: 2,
            fontSize: 13
          }
        })
        //   Năng lực đặc thù

        doc.text("3.2. Những năng lực đặc thù", 15, 140);
        header = [
          [{content: 'Năng lực', colSpan: 1}, {content: "Mức đạt được", colSpan: 1}, {
            content: "Nhận xét",
            colSpan: 1,
          }],
        ]
        body = [
          [{content: 'Ngôn ngữ', colSpan: 1}, {
            content: duLieu.nlNgonNgu ? duLieu.nlNgonNgu : '',
            colSpan: 1
          }, {content: duLieu.nxNangLucDacThu ? duLieu.nxNangLucDacThu : '', rowSpan: 7}],
          [{content: 'Tính toán', colSpan: 1}, {content: duLieu.nlTinhToan ? duLieu.nlTinhToan : '', colSpan: 1}],
          [{content: 'Khoa học', colSpan: 1}, {content: duLieu.nlKhoaHoc ? duLieu.nlKhoaHoc : '', colSpan: 1}],
          [{content: 'Công nghệ', colSpan: 1}, {content: duLieu.nlCongNghe ? duLieu.nlCongNghe : '', colSpan: 1}],
          [{content: 'Tin học', colSpan: 1}, {content: duLieu.nlTinHoc ? duLieu.nlTinHoc : '', colSpan: 1}],
          [{content: 'Thẩm mĩ', colSpan: 1}, {content: duLieu.nlThamMi ? duLieu.nlThamMi : '', colSpan: 1}],
          [{content: 'Thể chất', colSpan: 1}, {content: duLieu.nlTheChat ? duLieu.nlTheChat : '', colSpan: 1}],
        ]
        columnStyles = {
          0: {
            halign: 'center',
            valign: 'middle',
            fontSize: 13,
            cellWidth: 60,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 2}
          },
          1: {
            halign: 'center',
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: 'middle',
            font: 'TimesBold',
            cellWidth: 30
          },
          2: {
            halign: 'left', fontSize: 13, cellPadding: {top: 4, right: 4, bottom: 4, left: 4}, valign: 'middle',
            cellWidth: 95
          },
        }
        doc.autoTable({
          tableLineColor: [70, 76, 70],
          columnStyles: columnStyles,
          head: header,
          body: body,
          startY: 142,
          theme: 'grid',
          headStyles: {
            fillColor: [255, 255, 255],
            textColor: [45, 45, 45],
            font: 'TimesBold',
            fontStyle: 'normal',
            lineWidth: 0.1,
            halign: 'center',
            valign: 'middle',
            fontSize: 13,
            cellPadding: 1
          },
          styles: {
            textColor: [45, 45, 45],
            lineColor: [70, 76, 70],
            font: 'TimesBold',
            fontStyle: 'normal',
          },
          bodyStyles: {
            textColor: [45, 45, 45],
            lineColor: [70, 76, 70],
            font: 'VNTimes',
            fontStyle: 'normal',
            cellPadding: 2,
            fontSize: 13
          }
        })
        //   Đánh giá kết quả

        doc.setFontSize(13);
        doc.setFont("TimesBold")
        doc.text("4. Đánh giá kết quả giáo dục:", 15, 208);
        doc.setFont("VNTimes")
        doc.text(duLieu.danhGiaKetQuaGiaoDuc ? duLieu.danhGiaKetQuaGiaoDuc : '', 75, 208);

        doc.setFont("TimesBold")
        doc.text("5. Khen thưởng:", 15, 223);
        doc.setFont("VNTimes")
        doc.text(duLieu.noiDungKhenThuong ? duLieu.noiDungKhenThuong : '', 50, 223);

        doc.setFont("TimesBold")
        doc.text("6. Hoàn thành chương trình lớp học/chương trình tiểu học:", 15, 238);
        doc.setFont("VNTimes")
        doc.text(duLieu.hoanThanhChuongTrinh ? duLieu.hoanThanhChuongTrinh : '', 15, 245);
      }
    },
    displayPDF(pdfDataUrl) {
      console.log('displayPDF')
      console.log(pdfDataUrl)
      this.urlPdf = pdfDataUrl;
      // window.open(this.urlPdf, '_blank', 'noopener,noreferrer');
      return;
      // Hiển thị tệp PDF trong một thẻ iframe
      const iframe = document.createElement('iframe');
      iframe.src = pdfDataUrl;
      iframe.width = '100%';
      iframe.height = '600px';

      // Xóa nội dung cũ trong div
      const pdfViewer = document.getElementById('pdfViewer');
      pdfViewer.innerHTML = '';
      pdfViewer.appendChild(iframe);
    }
  }
}
</script>
<style>
</style>