<template>
  <div id="detailHocBa" class="page page-file file detailHocBa" v-loading.fullscreen.lock="fullscreenLoading">

    <div class="page__title d-flex align-items-center justify-content-between">
      <h1 class="title">Danh sách cán bộ</h1>
    </div>
    <div class="box-upload-file">
      <el-row :gutter="24">
        <el-col :xs="12" :sm="6" :md="4" :lg="4">
          <label>Cấp học</label>
          <eselect :disabled="list_cap_hoc.length==1" collapseTags
                   v-model="searchForm.capHoc" :placeholder="'Chọn'" filterable :data="list_cap_hoc"
                   :fields="['name','value']"/>
        </el-col>
        <el-col :xs="12" :sm="6" :md="4" :lg="4">
          <label>Mã giáo viên</label>
          <el-input clearable v-model="searchForm.maGV"
                    v-on:keyup.enter="submitSearch('searchForm')" placeholder="Nhập..."></el-input>
        </el-col>
        <el-col :xs="12" :sm="6" :md="4" :lg="4">
          <label>Tên giáo viên</label>
          <el-input clearable v-model="searchForm.tenGV"
                    v-on:keyup.enter="submitSearch('searchForm')" placeholder="Nhập..."></el-input>
        </el-col>
        <el-col :xs="12" :sm="6" :md="4" :lg="4">
          <div style="color: transparent">Tìm kiếm</div>
          <el-button class="m-0" @click="getGiaoVien(1)" type="primary">Tìm kiếm
          </el-button>
        </el-col>
      </el-row>
      <el-form v-if="false" ref="searchForm" inline label-position="top" :model="searchForm"
               :hide-required-asterisk="true">
        <el-form-item :xs="12" :sm="6" :md="4" :lg="4" label="Cấp học">
          <eselect :disabled="list_cap_hoc.length==1" collapseTags
                   v-model="searchForm.capHoc" placeholder="Chọn" filterable :data="list_cap_hoc"
                   :fields="['name','value']"/>
        </el-form-item>
        <el-form-item :xs="12" :sm="6" :md="4" :lg="4" label="Mã giáo viên">
          <el-input clearable v-model="searchForm.maGV"
                    v-on:keyup.enter="submitSearch('searchForm')" placeholder="Mã giáo viên"></el-input>
        </el-form-item>

        <el-form-item :xs="12" :sm="6" :md="4" :lg="4" label="Tên giáo viên">
          <el-input clearable v-model="searchForm.tenGV"
                    v-on:keyup.enter="submitSearch('searchForm')" placeholder="Tên giáo viên"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button style="margin-top: 40px" @click="submitSearch('searchForm')" type="primary">Tìm kiếm
          </el-button>
        </el-form-item>
      </el-form>

    </div>
    <div class="file--title">
      <h5 class="title">Danh sách giáo viên</h5>
    </div>
    <el-row :gutter="20">
      <el-col :span="4">
        <eselect v-if="false" style="width:100%" :size="'small'" collapseTags
                 @change="submitSearch('searchForm')" v-model="paginate.limit"
                 :placeholder="'Bản ghi/trang'" filterable :data="list_limit_paginate"
                 :fields="['name','value']"/>
      </el-col>
    </el-row>
    <div class="text-right">
      <el-tooltip placement="top">
        <div slot="content">Nộp CTS của {{ danhSachGiaoVienCheck.length }} giáo viên đã chọn</div>
        <el-button size="mini" :disabled="!danhSachGiaoVienCheck||danhSachGiaoVienCheck.length==0" type="success"
                   @click.prevent="showDanhSachGiaoVienXoa">
         Nộp chứng thư số
        </el-button>
      </el-tooltip>
    </div>
    <div class="page__content">
      <div class="listNew mb-5">
        <el-table
            fit
            border :lazy="true" :data="listGiaoVien ? listGiaoVien.list : []" style="width: 100%;"
            :max-height="maxHeight"
            @selection-change="handleSelectionChange" ref="listGiaoVien" v-loading="loadingTable">
          <el-table-column width="50"
                           align="center" header-align="center">
            <template slot="header" slot-scope="scope">
              <el-checkbox @change="ChonToanBoGiaoVien" v-model="CheckAllGiaoVien"></el-checkbox>
            </template>
            <template slot-scope="scope">
              <el-checkbox @change="chonMoiGiaoVien(scope.row)" v-model="scope.row.check">
              </el-checkbox>
            </template>
          </el-table-column>
          <el-table-column label="STT" width="50" type="index" class-name="text-center" :index="indexMethod">
          </el-table-column>
          <el-table-column align="center" label="Mã giáo viên" property="maGV">
            <template slot-scope="scope">
              {{ scope.row.maGV }}
              <div v-if="scope.row.linkChuKy">
                <iframe style="display:contents" height="1" width="1"
                        :src="scope.row.linkChuKy"></iframe>
              </div>
            </template>
          </el-table-column>
          <el-table-column header-align="center"  label="Tên giáo viên" property="tenGV">
          </el-table-column>
          <el-table-column header-align="center" label="Email" property="email"></el-table-column>
          <el-table-column align="center"  label="Số điện thoại" property="soDienThoai">
          </el-table-column>
          <el-table-column align="left" header-align="center"  label="Chức vụ" property="tenGV">
            <template slot-scope="scope">
              {{ getLoaiCB(scope.row.chucVu) }}
            </template>
          </el-table-column>
          <el-table-column align="left" header-align="center" label="Trạng thái"
                           property="trangThaiChuKy">
            <template slot-scope="scope">
              {{ getTrangThaiGV(scope.row.trangThai) }}
            </template>
          </el-table-column>
          <el-table-column  header-align="center" label="Chữ ký">
            <template slot-scope="scope">
              <div v-if="scope.row.linkChuKy&&scope.row.linkChuKy!=null">
                <el-image @click.prevent="xemAnh(scope.row.linkChuKy)"
                          :src="scope.row.linkChuKy">
                </el-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column fixed="right" align="left" header-align="center" min-width="100" label="Hành động"
                           property="">
            <template slot-scope="scope">
              <div class="btn__table_action">
                <el-tooltip content="Tải lên chữ ký" placement="top" class="ml-1 mb-1">
                  <el-button size="mini" @click="viewUpload(scope.row)" class="mb-1"
                             type="success"
                             icon="el-icon-upload"
                  >
                  </el-button>
                </el-tooltip>
                <el-tooltip v-if="scope.row.trangThaiChuKy==-1" content="Duyệt chữ ký" placement="top"
                            class="ml-1 mb-1">
                  <el-button size="mini" @click.prevent="updateStatusChuKy(scope.row,1)"
                             class="mb-1"
                             icon="el-icon-check"
                             type="primary">
                  </el-button>
                </el-tooltip>
                <el-tooltip v-if="scope.row.trangThaiChuKy==1" content="Từ chối duyệt chữ ký"
                            placement="top" class="ml-1 mb-1">
                  <el-button size="mini" @click.prevent="updateStatusChuKy(scope.row,2)"
                             class="mb-1 "
                             icon="el-icon-close"
                             type="danger">
                  </el-button>
                </el-tooltip>
                <el-tooltip content="Cập nhật thông tin" placement="top" class="ml-1 mb-1">
                  <el-button size="mini" @click="updateInfo(scope.row)" class="mb-1"
                             type="warning"
                             icon="el-icon-edit-outline"
                  >
                  </el-button>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div v-if="listGiaoVien && listGiaoVien.total">
          <el-row :gutter="20">
            <el-col :span="8">
              <chonSoLuong :show="paginate.limit" @chonXongSoLuong="ChonSoLuongBanGhi($event)"></chonSoLuong>
            </el-col>
            <el-col :span="16" class="text-right">
              <small class="pd-r-10">
                <i>Danh sách có {{ listGiaoVien.total }} giáo viên </i>
              </small>
              <pagination v-if="listGiaoVien && listGiaoVien.total" :value="paginate.current"
                          :page-count="getTotalPage(listGiaoVien.total)" :click-handler="getGiaoVien"
                          :prev-text="'Trước'" :next-text="'Sau'" :container-class="'el-pager'"
                          :pageClass="'number'">
              </pagination>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <el-dialog title="Tải lên chữ ký" center :visible.sync="dialogUploadSignatureVisible"
               :close-on-press-escape="false" :close-on-click-modal="false"
               custom-class="thongTinKyTheoMonHoc" class="uploadSignature">
      <crop-image v-if="dialogUploadSignatureVisible" :style-box="'width: 400px; height: 250px;'" @goStep="goStep"
                  :option="optionCrope" :showCrop="dialogUploadSignatureVisible" @uploadLuon="uploadLuon"
                  @getBase64="getBase64"></crop-image>
      <span slot="footer" class="dialog-footer">
                <el-button @click="dialogUploadSignatureVisible = false">Hủy</el-button>
                <el-button :disabled="!imageSignature" type="primary" v-if="step == 3" @click="uploadSignature">Xác nhận</el-button>
            </span>
    </el-dialog>
    <el-dialog :title="'Cập nhật thông tin cán bộ - [' + editData.maGV +']'" top="10vh" center
               :visible.sync="showEdit"
               :close-on-press-escape="false" :close-on-click-modal="false"
               custom-class="themMoiGiaoVien thongTinKyTheoMonHoc"
               class="uploadSignature">
      <el-form ref="upDateForm" :model="editData" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="12" class="mb-2">
            <el-form-item label="Họ tên" prop="tenGV">
              <el-input clearable placeholder="Nhập" v-model="editData.tenGV"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="mb-2">
            <el-form-item label="Email" prop="email">
              <el-input clearable placeholder="Nhập" v-model="editData.email"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="mb-2">
            <el-form-item label="Số điện thoại" prop="soDienThoai">
              <el-input clearable placeholder="Nhập"
                        v-model="editData.soDienThoai"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="mb-2">
            <el-form-item label="Chức vụ">
              <el-select clearable class="w-100" v-model="editData.chucVu" placeholder="Chọn">
                <el-option v-for="(item,index) in chucVuGv" :label="item.label" :value="item.value"
                           :key="index"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="mb-2">
            <el-form-item label="Cấp học">
              <eselect clearable class="w-100" multiple v-model="editData.capHocs"
                       placeholder="Chọn"
                       filterable
                       :data="list_cap_hoc" :fields="['name','value']"/>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="mb-2">
            <el-form-item label="Trạng thái">
              <el-select clearable class="w-100" v-model="editData.trangThai"
                         placeholder="Chọn">
                <el-option v-for="(item,index) in statusGv" :label="item.label" :value="item.value"
                           :key="index"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="mb-2">
            <el-form-item label="Loại chữ ký số">
              <el-select clearable class="w-100" @change="chonKieuKy" multiple v-model="editData.typeSign"
                         placeholder="Chọn">
                <el-option
                    v-for="(item,index) in list_type_sign"
                    :label="item.label" :value="item.value"
                    :key="index"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="mb-2" v-if="checkInArray(6,editData.typeSign)">
            <el-form-item label="Tài khoản SmartCA VNPT">
              <el-input clearable placeholder="Nhập"
                        v-model="editData.usernameCAVNPT"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="mb-2" v-if="checkInArray(5,editData.typeSign)">
            <el-form-item label="Tài khoản SmartCA Viettel (MySign)">
              <el-input clearable placeholder="Nhập"
                        v-model="editData.usernameCAVT"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="mb-2" v-if="checkInArray(4,editData.typeSign)">
            <el-form-item label="Số Serial Chứng Thư Số">
              <el-input clearable placeholder="Nhập"
                        v-model="editData.serialNumberUsbToken"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="mb-2">
            <el-form-item label="Khối đóng dấu">
              <eselect multiple collapseTags
                       v-model="editData.khoiHocDongDau" placeholder="Chọn" filterable :data="danh_sach_khoi_hoc"
                       :fields="['label','value']"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item class="pt-5 text-center">
          <el-button size="mini" @click="resetForm('upDateForm')">Đóng</el-button>
          <el-button size="mini" type="warning" @click="submitForm('upDateForm')">Cập nhật</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="Ảnh chữ ký" width="30%" center :visible.sync="hienThiAnh" custom-class="thongTinKyTheoMonHoc">
      <div class="demo-image__lazy">
        <el-image :src="linkAnh" style="width:100%" title="Ảnh chữ ký" lazy></el-image>
      </div>
    </el-dialog>
    <el-dialog title="Danh sách giáo viên nộp chứng thư số" center :visible.sync="show_list_delete"
               :close-on-press-escape="false" :close-on-click-modal="false">
      <el-table
          :data="danhSachGiaoVienCheck"
          border
          style="width: 100%">
        <el-table-column
            type="index"
            align="center"
            label="STT"
            width="50">
        </el-table-column>
        <el-table-column
            align="center"
            prop="maGV"
            label="Mã giáo viên">
        </el-table-column>
        <el-table-column
            header-align="center"
            align="left"
            prop="tenGV"
            label="Tên giáo viên">
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
            <el-button size="mini" @click="show_list_delete = false">Đóng</el-button>
            <el-button size="mini" type="success" @click.prevent="checkXoaDuLieuGiaoVien">Nộp dữ liệu</el-button>
          </span>
    </el-dialog>
    <el-dialog title="Thêm mới thông tin cán bộ" top="10vh" center :visible.sync="showAdd"
               :close-on-press-escape="false" :close-on-click-modal="false"
               custom-class="themMoiGiaoVien"
               class="uploadSignature">
      <el-form ref="addDataForm" :model="addNewData" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="Họ tên" prop="tenGV">
              <el-input placeholder="Nhập họ tên..." v-model="addNewData.tenGV"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Email" prop="email">
              <el-input placeholder="Nhập email..." v-model="addNewData.email"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Số điện thoại" prop="soDienThoai">
              <el-input placeholder="Nhập số điện thoại..." v-model="addNewData.soDienThoai"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Chức vụ">
              <el-select class="w-100" v-model="addNewData.chucVu" placeholder="Chọn chức vụ">
                <el-option v-for="(item,index) in chucVuGv" :label="item.label" :value="item.value"
                           :key="index"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Cấp học">
              <eselect class="w-100" multiple v-model="addNewData.capHocs" placeholder="Chọn"
                       filterable
                       :data="list_cap_hoc" :fields="['name','value']"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Trạng thái">
              <el-select class="w-100" v-model="addNewData.trangThai" placeholder="Chọn trạng thái">
                <el-option v-for="(item,index) in statusGv" :label="item.label" :value="item.value"
                           :key="index"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Kiểu ký">
              <el-select class="w-100" v-model="addNewData.typeSign" placeholder="--Chọn kiểu ký--">
                <el-option
                    v-for="(item,index) in [{label:'SIMCA',value:2},{label:'USB TOKEN',value:4}]"
                    :label="item.label" :value="item.value"
                    :key="index"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Mật khẩu" prop="passWord">
              <el-input placeholder="Nhập mật khẩu..." show-password
                        v-model="addNewData.passWord"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Nhập lại mật khẩu" prop="rePassWord">
              <el-input show-password placeholder="Nhập lại mật khấu..."
                        v-model="addNewData.rePassWord"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item class="text-center pt-5">
          <el-button type="primary" @click="submitForm('addDataForm')">Thêm mới</el-button>
          <el-button @click="showAdd = false">Đóng</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import {
  mapState,
  mapActions
} from 'vuex';
import Pagination from "../components/Pagination";
import {
  VueCropper
} from 'vue-cropper';
import CropImage from "../components/CropImage";
import dialog from "../dialog";
import DialogAlert from "../components/DialogAlert";
import api from "../_helpers/api";
import constant_api from "../_helpers/constant_api";
import constant from "../_helpers/constant_define";
import ESelect from "../components/ESelect";
import chonSoLuong from "../pages/Ui/ChonSoLuong";
export default {
  name: "QuanLyGiaoVien",
  metaInfo: {
    title: 'Danh sách cán bộ',
    meta: [{
      vmid: 'description',
      name: 'description',
      content: ''
    }]
  },
  components: {
    chonSoLuong,
    Pagination,
    VueCropper,
    CropImage,
    DialogAlert,
    'eselect': ESelect,
  },
  computed: {
    ...mapState('teacher', [
      "fileSignature",
      "duyetChuKyStatus",
      "duyetChuKyMess",
    ]),
    ...mapState('account', [
      'user',
      'list_nam_hoc',
      'list_cap_hoc',
      'nam_hoc_hien_tai',
    ]),
    ...mapState('until', [
      'window',
    ])
  },
  watch: {
    fileSignature(val) {
      if (val) {
        this.thongBao('success', val.msg)
        this.getDataInCurentPage();
      }
    },

  },
  data() {
    return {
      maxHeight: window.innerHeight,
      showAdd: false,
      listGiaoVien: {},
      step: 1,
      linkAnh: '',
      hienThiAnh: false,
      rules: {
        tenGV: [{
          required: true,
          message: 'Vui lòng nhập họ tên',
          trigger: 'blur'
        },],
        passWord: [{
          required: true,
          message: 'Vui lòng nhập mật khẩu',
          trigger: 'blur'
        },],
        rePassWord: [{
          required: true,
          message: 'Vui lòng nhập lại mật khẩu',
          trigger: 'blur'
        }, {
          required: true,
          message: 'Vui lòng nhập lại đúng mật khẩu',
          trigger: 'blur',
        },],
        list_phong:[],
        // email: [{
        //     required: true,
        //     message: 'Chưa nhập email',
        //     trigger: 'blur'
        // },
        //     {
        //         pattern: /^([a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        //         message: 'Không đúng định dạng email',
        //         trigger: ['blur', 'change']
        //     }
        // ],
        // soDienThoai: [{
        //     message: 'Số điện thoại không đúng định dạng',
        //     pattern: /(84|0[1|2|3|4|5|6|7|8|9])+([0-9]{8})\b/,
        //     trigger: ['blur', 'change']
        // }]

      },

      statusGv: [{
        value: 0,
        label: 'Chọn trạng thái'
      },
        {
          value: 1,
          label: 'Đang làm việc'
        },
        {
          value: 2,
          label: 'Chuyển trường'
        },
        {
          value: 3,
          label: 'Đã nghỉ'
        },
      ],
      chucVuGv: [{
        value: 1,
        label: 'Hiệu trưởng'
      },
        {
          value: 2,
          label: 'Phó hiệu trưởng'
        },
        {
          value: 40,
          label: 'Giáo viên'
        },
        {
          value: 6,
          label: 'Nhân viên khác'
        },
        {
          value: 8,
          label: 'Tổ trưởng chuyên môn'
        },
        {
          value: 9,
          label: 'Tổ phó chuyên môn'
        },
        {
          value: 14,
          label: 'Nhân viên thư viện'
        },
        {
          value: 15,
          label: 'Nhân viên thiết bị'
        },
        {
          value: 3,
          label: 'Nhân viên y tế'
        },
        {
          value: 4,
          label: 'Nhân viên thí nghiệm'
        },
        {
          value: 5,
          label: 'Nhân viên kỹ thuật nghiệp vụ'
        },
        {
          value: 10,
          label: 'Nhân viên kế toán'
        },
        {
          value: 11,
          label: 'Nhân viên văn thư'
        },
        {
          value: 7,
          label: 'Nhân viên bảo vệ'
        },
        {
          value: 17,
          label: 'Phó giám đốc'
        },
        {
          value: 16,
          label: 'Giám đốc'
        },
        {
          value: 18,
          label: 'Trợ giảng ngôn ngữ L1'
        },
        {
          value: 19,
          label: 'Trợ giảng ngôn ngữ L2'
        },
        {
          value: 12,
          label: 'Nhân viên cấp dưỡng'
        },
        {
          value: 13,
          label: 'Nhân viên bảo mẫu'
        },
        {
          value: 20,
          label: 'Nhân viên thủ quỹ'
        },
        {
          value: 21,
          label: 'Nhân viên công nghệ thông tin'
        },
        {
          value: 22,
          label: 'Nhân viên hỗ trợ GD khuyết tật'
        },
        {
          value: 23,
          label: 'Nhân viên giáo vụ'
        },

      ],
      capHocs: [{
        value: 1,
        label: 'Cấp 1'
      },
        {
          value: 2,
          label: 'Cấp 2'
        },
        {
          value: 3,
          label: 'Cấp 3'
        },
      ],
      list_type_sign: [
        {label: 'SIMCA', value: 2},
        {label: 'USB TOKEN', value: 4},
        {label: 'SMART_CA_VIETTEL', value: 5},
        {label: 'SMART_CA_VNPT', value: 6},
        {label: 'VGCA', value: 7},
      ],
      danh_sach_khoi_hoc: [],
      khoiCap1: [{
        value: 1,
        label: 'Khối 1'
      },
        {
          value: 2,
          label: 'Khối 2'
        },
        {
          value: 3,
          label: 'Khối 3'
        },
        {
          value: 4,
          label: 'Khối 4'
        },
        {
          value: 5,
          label: 'Khối 5'
        },
      ],
      khoiCap2: [{
        value: 6,
        label: 'Khối 6'
      },
        {
          value: 7,
          label: 'Khối 7'
        },
        {
          value: 8,
          label: 'Khối 8'
        },
        {
          value: 9,
          label: 'Khối 9'
        },
      ],
      khoiCap3: [{
        value: 10,
        label: 'Khối 10'
      },
        {
          value: 11,
          label: 'Khối 11'
        },
        {
          value: 12,
          label: 'Khối 12'
        },

      ],
      editData: {
        email: '',
        tenGV: '',
        soDienThoai: '',
        chucVu: '',
        trangThai: '',
        typeSign: [],
        usernameCAVT: '',
        usernameCAVNPT: '',
        serialNumberUsbToken: '',
        khoiDongDau: '',
      },
      addNewData: {
        email: '',
        tenGV: '',
        soDienThoai: '',
        chucVu: '',
        trangThai: '',
        typeSign: [],
      },
      showEdit: false,
      loadingTable: false,
      showDialogAlert: false,
      imageSignature: '',
      messageAlert: '',
      listHocBaCheck: [],
      searchForm: {
        maGV: '',
        tenGV: '',
        capHoc: '',
      },
      rulesSearch: {
        maGV: [{
          required: true,
          message: 'Chưa nhập mã giáo viên',
          trigger: 'blur'
        }],
        tenGV: [{
          required: true,
          message: 'Chưa nhập mã tên viên',
          trigger: 'blur'
        }],
      },
      list_limit_paginate: constant.list_limit_paginate,
      paginate: {
        start: 0,
        limit: 50,
        current: 1,
      },
      fullscreenLoading: false,
      show_list_delete: false,
      selectedGV: '',
      dialogUploadSignatureVisible: false,
      option: {
        img: 'base64',
        size: 1,
        outputType: 'png'
      },
      optionCrope: {
        img: '',
        size: 1,
        full: true,
        outputType: 'png',
        canMove: true,
        fixedBox: true,
        original: false,
        canMoveBox: false,
        autoCrop: true,
        autoCropWidth: 200,
        autoCropHeight: 150,
        centerBox: false,
        high: true,
        max: 99999,
        fixed: true,
        fixedNumber: [20, 9],
      },
      CheckAllGiaoVien: false,
      danhSachGiaoVienCheck: []
    }
  },
  mounted() {
    this.maxHeight = this.getHeightTable()
    // this.loadingTable = true;
    if (this.list_cap_hoc.length >= 2) {
      this.searchForm.capHoc = ''
    } else {
      this.searchForm.capHoc = this.list_cap_hoc[0].value
    }
    this.mapDanhSachKhoi();
    this.getListGiaoVien({
      start: this.paginate.start,
      limit: this.paginate.limit,
      maTruong: this.user.maTruong,
      maGV: this.searchForm.maGV,
      tenGV: this.searchForm.tenGV,
      capHoc: this.searchForm.capHoc,
    })
  },
  methods: {
    mapDanhSachKhoi() {
      let arr = [];
      this.danh_sach_khoi_hoc = [];
      for (let i = 0; i < this.list_cap_hoc.length; i++) {
        if (this.list_cap_hoc[i].value == 1) {
          arr = arr.concat(this.khoiCap1)
        }
        if (this.list_cap_hoc[i].value == 2) {
          arr = arr.concat(this.khoiCap2)
        }
        if (this.list_cap_hoc[i].value == 3) {
          arr = arr.concat(this.khoiCap3)
        }
      }
      this.danh_sach_khoi_hoc = arr;
      console.error('Danh sách khối học:')
      console.error(this.danh_sach_khoi_hoc)
    },
    getHeightTable() {
      if (this.window.height && this.window.height > 950) {
        return this.window.height - 100
      }
      if (this.window.height && this.window.height > 850) {
        return 800
      }
      if (this.window.height && this.window.height > 750) {
        return 700
      }
      return 600
    },

    checkInArray(item, array) {
      return array.includes(item)
    },
    chonKieuKy() {
      if (!this.editData.typeSign.includes(5)) {
        this.editData.usernameCAVT = '';
      }
    },
    ChonSoLuongBanGhi(e) {
      this.danhSachGiaoVienCheck = [];
      this.CheckAllGiaoVien = false;
      this.loadingTable = true;
      this.paginate.start = 0;
      this.paginate.limit = e.soluong;
      this.paginate.current = 1;
      this.getListGiaoVien({
        start: this.paginate.start,
        limit: this.paginate.limit,
        tenGV: this.searchForm.tenGV,
        maGV: this.searchForm.maGV,
        capHoc: this.searchForm.capHoc,
        maTruong: this.user.maTruong,
      });
    },
    getDataInCurentPage() {
      this.getListGiaoVien({
        start: this.paginate.start,
        limit: this.paginate.limit,
        tenGV: this.searchForm.tenGV,
        maGV: this.searchForm.maGV,
        capHoc: this.searchForm.capHoc,
        maTruong: this.user.maTruong,
      });
    },
    showThemMoiGiaoVien() {
      this.showAdd = true;
    },
    getListGiaoVien(e) {
      this.show_list_delete = false;
      this.CheckAllGiaoVien = false;
      try {
        let uri = constant_api.giaovien.listGiaoVien;
        this.fullscreenLoading = true;
        api.get(uri, e).then(
            response => {
              if (response.data.code == 200) {
                let dt = response.data.data;
                let arr = response.data.data.list;
                if (arr && arr.length) {
                  for (let i = 0; i < arr.length; i++) {
                    arr[i].check = false;
                    if (arr[i].linkChuKy && arr[i].linkChuKy != null) {
                      arr[i].linkChuKy += '?t=' + this.getTime();
                    }
                  }
                  // this.thongBao('success', 'Lấy dữ liệu thành công.')
                }
                dt.list = arr;
                this.listGiaoVien = dt;
                if (!arr || arr.length == 0) {
                  // this.thongBao('error', 'Không tìm thấy dữ liệu.')
                }
              } else {
                this.thongBao('error', response.data.msg)
              }
              this.loadingTable = false;
              this.fullscreenLoading = false;
            }
        );
      } catch (e) {
        this.loadingTable = false;
        this.fullscreenLoading = false;
        this.thongBao('error', 'Vui lòng thử lại sau')
      }
    },
    showDanhSachGiaoVienXoa() {
      this.show_list_delete = true;
    },
    checkXoaDuLieuGiaoVien() {
      try {
        this.mapDanhSachGiaoVienCheck();
        console.log('checkXoaDuLieuGiaoVien')
        console.log(this.danhSachGiaoVienCheck);
        if (!this.danhSachGiaoVienCheck || this.danhSachGiaoVienCheck.length == 0) {
          this.thongBao('error', 'Vui lòng bổ sung bản ghi cần xoá.');
          return;
        } else {
          let ids = this.danhSachGiaoVienCheck.map(a => a.id);
          this.xoaDuLieu(ids);
        }
      } catch (e) {
        this.thongBao('success', 'Vui lòng thực hiện lại sau ít phút.')
      }
    },
    xoaDuLieu(arr) {
      console.log('Xoá của:')
      console.log(arr)
       let msg = 'Xác nhận nộp dữ liệu của ' + arr.length + ' giáo viên đã chọn?'

      this.$confirm(msg, 'Cảnh báo', {
        confirmButtonText: 'Xác nhận nộp CTS',
        cancelButtonText: 'Hủy',
        confirmButtonClass: 'el-button--success'
      })
          .then(_ => {
            try {
              let params = {
                ids: arr
              }
              if (arr.length == 0) {
                params = {};
              }
              let uri = constant_api.giaovien.nopCTS;
              this.fullscreenLoading = true;
              api.post(uri, params).then(
                  response => {
                    console.log("Xoá trả về:")
                    console.log(response)
                    if (response.data.code == 200) {
                      this.thongBao('success', 'Xoá dữ liệu thành công');
                      this.getDataInCurentPage()
                    } else {
                      this.thongBao('error', response.data.msg)
                    }
                    this.fullscreenLoading = false;
                  }
              );
            } catch (e) {
              this.thongBao('error', 'Vui lòng thử lại sau.')
            }
          })
          .catch(_ => {
          });
    },
    ChonToanBoGiaoVien() {
      console.log("ChonToanBoGiaoVien")
      let mang = JSON.parse(JSON.stringify(this.listGiaoVien.list));
      if (!mang || mang.length == 0) {
        this.thongBao('error', 'Danh sách giáo viên trống')
      }
      if (this.CheckAllGiaoVien) {
        for (let i = 0; i < mang.length; i++) {
          mang[i].check = true;
        }
      } else {
        for (let i = 0; i < mang.length; i++) {
          mang[i].check = false;
        }
      }
      this.listGiaoVien.list = mang;
      this.mapDanhSachGiaoVienCheck();
      console.log(mang)
    },
    chonMoiGiaoVien(e) {
      e.hasUpdate = true;
      console.log("CHeck")
      let obj = JSON.parse(JSON.stringify(e))
      console.log(obj)
      let newCheck = obj.check;
      for (let i = 0; i < this.listGiaoVien.list.length; i++) {
        if (this.listGiaoVien.list[i].maGV == e.maGV) {
          // this.danh_sach_phan_cong_giang_day[i].check = newCheck;
          e.check = newCheck;
        }
      }
      this.mapDanhSachGiaoVienCheck();
    },
    mapDanhSachGiaoVienCheck() {

      let data = [];
      for (let i = 0; i < this.listGiaoVien.list.length; i++) {
        if (this.listGiaoVien.list[i].check == true) {
          data.push(this.listGiaoVien.list[i])
        }
      }
      this.danhSachGiaoVienCheck = data;
      console.log("Danh sách giáo viên check:")
      console.log(this.danhSachGiaoVienCheck)
      if (this.danhSachGiaoVienCheck.length == this.listGiaoVien.list.length) {
        this.CheckAllGiaoVien = true;
      } else {
        this.CheckAllGiaoVien = false;
      }
    },
    goStep(step) {
      this.step = step;
    },
    xemAnh(e) {
      if (e) {

        this.linkAnh = e
        // this.linkAnh = e + '?tg=' + this.getTime();
        console.log(this.linkAnh)
        console.log("linkAnh")
        this.hienThiAnh = true;
      } else {
        this.thongBao('error', 'Giáo viên chưa upload ảnh chữ ký')
      }
    },
    getTime() {
      var d = new Date();
      var n = d.getTime();
      return n;
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let capHoc = '';
          let uri = '';
          let params = {};
          if (formName == 'upDateForm') {
            uri = constant_api.giaovien.upDateInfo;
            if (this.editData.capHocs) {
              this.editData.capHocs.sort();
              console.error(this.editData.capHocs)
              for (let i = 0; i < this.editData.capHocs.length; i++) {
                capHoc += this.editData.capHocs[i]
              }
            }
            capHoc = parseInt(capHoc)
            params = {
              id: this.editData.id,
              tenGV: this.editData.tenGV,
              email: this.editData.email,
              soDienThoai: this.editData.soDienThoai,
              trangThai: this.editData.trangThai,
              chucVu: this.editData.chucVu,
              typeSign: this.editData.typeSign.join(''),
              usernameCAVT: this.editData.usernameCAVT,
              usernameCAVNPT: this.editData.usernameCAVNPT,
              serialNumberUsbToken: this.editData.serialNumberUsbToken,
              khoiHocDongDau: this.editData.khoiHocDongDau,
              capHoc: capHoc,
            }
          }
          if (formName == 'addDataForm') {
            if (this.addNewData.passWord != this.addNewData.rePassWord) {
              this.thongBao('error', 'Vui lòng nhập lại đúng mật khẩu.')
              return
            }
            uri = constant_api.giaovien.addInfo;
            if (this.addNewData.capHocs) {
              this.addNewData.capHocs.sort();
              console.error(this.addNewData.capHocs)
              for (let i = 0; i < this.addNewData.capHocs.length; i++) {
                capHoc += this.addNewData.capHocs[i]
              }
            }
            capHoc = parseInt(capHoc)
            params = {
              tenGV: this.addNewData.tenGV,
              email: this.addNewData.email,
              soDienThoai: this.addNewData.soDienThoai,
              trangThai: this.addNewData.trangThai,
              chucVu: this.addNewData.chucVu,
              typeSign: this.addNewData.typeSign.join(''),
              password: this.addNewData.passWord,
              capHoc: capHoc,
            }
          }
          this.$confirm('Xác nhận chỉnh sửa thông tin cán bộ?', 'Thông báo', {
            confirmButtonText: 'Cập nhật',
            cancelButtonText: 'Hủy',
            confirmButtonClass: 'el-button--warning'
          })
              .then(_ => {
                this.loadingTable = true;
                this.fullscreenLoading = true;
                api.post(uri, params).then(data => {
                  if (data.data.code == 200) {
                    this.thongBao('success', 'Cập nhật thông tin thành công');
                    this.showEdit = false;
                    this.showAdd = false;
                  } else {
                    this.thongBao('error', data.data.msg)
                  }
                  this.loadingTable = false;
                  this.fullscreenLoading = false;
                  this.getDataInCurentPage();
                }).catch((e) => {
                  this.fullscreenLoading = false;
                  this.loadingTable = false;
                  this.thongBao('error', 'Vui lòng thử lại sau ít phút')
                  this.getDataInCurentPage();
                })
              })
              .catch(_ => {
              });

        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.showEdit = false;
      this.editData = {
        email: '',
        tenGV: '',
        soDienThoai: '',
        chucVu: '',
        trangThai: '',
        typeSign: '',
        serialNumberUsbToken: '',
        capHocs: [],
      };
    },

    updateInfo(data) {
      let duLieu = JSON.parse(JSON.stringify(data));
      let num = duLieu.capHoc
      if (num) {
        let capHocs = String(num).split("").map((num) => {
          return Number(num)
        })
        duLieu.capHocs = capHocs
      } else {
        duLieu.capHocs = [];
      }
      console.log("Dữ liệu chỉnh sửa:")
      console.log(duLieu);
      this.editData = duLieu;
      this.editData.typeSign = duLieu.typeSign.toString().split('');
      for (let i = 0; i < this.editData.typeSign.length; i++) {
        this.editData.typeSign[i] = parseInt(this.editData.typeSign[i])
      }
      this.showEdit = true;
    },

    getTrangThaiGV(e) {
      switch (e) {
        case 1:
          return 'Đang làm việc';
        case 2:
          return 'Chuyển trường';
        case 3:
          return 'Đã nghỉ'
        default:
          return ''
      }
    },

    getTrangThai(e) {
      let result = "";
      if (e == 1) {
        result = "Đã chốt"
      } else if (e == -1) {
        result = "Chưa chốt"
      } else if (e == 2) {
        result = "Không duyệt"
      } else {
        result = "Chưa upload"
      }
      return result
    },
    ...mapActions('teacher', [
      "uploadFileSignature",
    ]),

    duyetChuKyCacGiaoVienDaChon(e) {
      console.log("duyetChuKyCacGiaoVienDaChon");
      try {
        let params = e
        let uri = constant_api.giaovien.duyetChuKy;
        this.fullscreenLoading = true;
        api.post(uri, params).then(
            response => {
              console.log("duyệt trả về:")
              console.log(response)
              if (response.data.code == 200) {
                if (params.trangThai == 1) {
                  this.thongBao('success', 'Duyệt chữ ký thành công');
                } else {
                  this.thongBao('success', 'Hủy duyệt chữ ký thành công');

                }

                this.getDataInCurentPage()
              } else {
                this.thongBao('error', response.data.msg)
              }
              this.fullscreenLoading = false;
            }
        );
      } catch (e) {
        this.thongBao('error', 'Vui lòng thử lại sau.')
      }

    },
    handleSelectionChange(val) {
      console.log("Danh sách học bạ:")
      console.log(val)
      this.listHocBaCheck = val;
    },
    updateStatusChuKy(a, b) {
      this.listHocBaCheck = [a];
      this.layDanhSachIdGiaoVienDaChon(b)
    },
    layDanhSachIdGiaoVienDaChon(a) {
      console.log("Duyệt danh sách chữ ký đã chọn")
      if (this.listHocBaCheck.length == 0) {
        this.messageAlert = "Vui lòng chọn giáo viên muốn duyệt";
        this.showDialogAlert = true;
        return;
      } else {
        console.log("Duyệt chữ ký đã chọn:");
        let listIdHocBaCheck = [];
        for (let i = 0; i < this.listHocBaCheck.length; i++) {
          listIdHocBaCheck.push(this.listHocBaCheck[i].id);
        }
        if (a == 1) {
          let params = {
            ids: listIdHocBaCheck,
            trangThai: 1,
          }
          this.$confirm('Xác nhận duyệt chữ ký đã chọn?', 'Thông báo', {
            confirmButtonText: 'Duyệt chữ ký',
            cancelButtonText: 'Hủy',
            confirmButtonClass: 'el-button--success'
          })
              .then(_ => {
                this.fullscreenLoading = true;
                this.duyetChuKyCacGiaoVienDaChon(params)
              })
              .catch(_ => {
              });
        }
        if (a == 2) {
          let params = {
            ids: listIdHocBaCheck,
            trangThai: 2,
          }
          this.$confirm('Xác nhận hủy duyệt chữ ký đã chọn?', 'Thông báo', {
            confirmButtonText: 'Huỷ duyệt',
            cancelButtonText: 'Hủy',
            confirmButtonClass: 'el-button--danger',
          })
              .then(_ => {
                this.fullscreenLoading = true;
                this.duyetChuKyCacGiaoVienDaChon(params)
              })
              .catch(_ => {
              });
        }
      }
    },
    getBase64(data) {
      this.imageSignature = data;
    },

    uploadLuon(data) {
      this.imageSignature = data;
      this.uploadSignature();
    },
    viewUpload(gv) {
      this.imageSignature = null;
      this.selectedGV = gv;
      this.dialogUploadSignatureVisible = true
    },
    thongBao(t, e) {
      let msg = "";
      let cl = "";
      if (e) {
        msg = e;
      }
      let type = "success";
      if (t) {
        type = t
      }
      if (type == "success") {
        cl = "dts-noty-success"
      }
      if (type == "warning") {
        cl = "dts-noty-warning"
      }
      if (type == "error") {
        cl = "dts-noty-error"
      }
      if (type == "info") {
        cl = "dts-noty-info"
      }
      this.$message({
        customClass: cl,
        showClose: true,
        message: msg,
        type: t,
        duration: 3000
      });
    },
    uploadSignature() {
      if (!this.imageSignature) {
        this.thongBao('error', 'Vui lòng xoá nền ảnh trước khi xác nhận');
        return;
      }
      let params = {
        base64: this.imageSignature,
        giaovien: {
          giaovien: this.selectedGV,
        }
      }
      this.uploadFileSignature(params)
      this.dialogUploadSignatureVisible = false;
    },

    getTotalPage(number) {
      return Math.ceil(number / (this.paginate.limit));
    },
    getLoaiTrangThaiChuKy(type) {
      if (type == 1) {
        return "Đã duyệt"
      } else if (type == 2) {
        return "Từ chối duyệt"
      } else {
        return "Chờ xử lý"
      }
    },
    getLoaiCB(type) {
      if (type == 1) {
        return 'Hiệu trưởng';
      } else if (type == 2) {
        return 'Phó hiệu trưởng';
      } else if (type == 8) {
        return 'Tổ trưởng chuyên môn';
      } else if (type == 9) {
        return 'Tổ phó chuyên môn';
      } else if (type == 16) {
        return 'Giám đốc';
      } else if (type == 17) {
        return 'Phó giám đốc';
      } else if (type == 14) {
        return 'Nhân viên thư viện';
      } else if (type == 15) {
        return 'Nhân viên thiết bị';
      } else if (type == 3) {
        return 'Nhân viên y tế';
      } else if (type == 6) {
        return 'Nhân viên khác';
      } else if (type == 4) {
        return 'Nhân viên thí nghiệm';
      } else if (type == 5) {
        return 'Nhân viên kỹ thuật nghiệp vụ';
      } else if (type == 10) {
        return 'Nhân viên kế toán';
      } else if (type == 11) {
        return 'Nhân viên văn thư';
      } else if (type == 7) {
        return 'Nhân viên bảo vệ';
      } else if (type == 18) {
        return 'Trợ giảng ngôn ngữ L1';
      } else if (type == 19) {
        return 'Trợ giảng ngôn ngữ L2';
      } else if (type == 12) {
        return 'Nhân viên cấp dưỡng';
      } else if (type == 13) {
        return 'Nhân viên bảo mẫu';
      } else if (type == 20) {
        return 'Nhân viên thủ quỹ';
      } else if (type == 21) {
        return 'Nhân viên công nghệ thông tin';
      } else if (type == 22) {
        return 'Nhân viên hỗ trợ GD khuyết tật';
      } else if (type == 23) {
        return 'Nhân viên giáo vụ';
      } else if (type == 31) {
        return 'Nấu ăn';
      } else if (type == 26) {
        return 'Trưởng phòng';
      } else if (type == 27) {
        return 'Phó trưởng phòng';
      } else if (type == 32) {
        return 'Tổ trưởng';
      } else if (type == 33) {
        return 'Tổ phó';
      } else if (type == 34) {
        return 'Chuyên viên';
      } else if (type == 35) {
        return 'Nhân viên lái xe';
      } else if (type == 36) {
        return 'Cấp THPT';
      } else if (type == 28) {
        return 'Cấp THCS';
      } else if (type == 29) {
        return 'Cấp tiểu học';
      } else if (type == 30) {
        return 'Cấp mầm non';
      } else if (type == 40) {
        return 'Giáo viên';
      } else {
        return '';
      }
    },

    indexMethod(index) {
      return (this.paginate.start + index) + 1;
    },

    submitSearch(formName) {
      this.getGiaoVien(1)
    },

    getGiaoVien(select = 1) {
      this.danhSachGiaoVienCheck = [];
      this.CheckAllGiaoVien = false;
      this.loadingTable = true;
      let start = (select - 1) * this.paginate.limit ? (select - 1) * this.paginate.limit : 0;
      this.paginate.start = start;
      this.paginate.current = select;
      this.getListGiaoVien({
        start: this.paginate.start,
        limit: this.paginate.limit,
        tenGV: this.searchForm.tenGV,
        maGV: this.searchForm.maGV,
        capHoc: this.searchForm.capHoc,
        maTruong: this.user.maTruong,
      });
    },
  },
}
</script>

<style scoped>
.el-select {
  width: 100%;
}

.el-form-item__label {
  line-height: 25px;
}

.el-form-item {
  margin-bottom: 10px !important;
}

.mb-2 {
  margin-bottom: 10px !important;
}
</style>