<template>
  <div v-loading.fullscreen.lock="loading" :element-loading-text="loadingText"
       element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)"></div>
</template>

<script>
import websocket from "../websocket";
import msgType from "../_helpers/constant_msg_type";
import {
  mapState,
  mapActions
} from 'vuex';
import dialog from "../dialog";
import {
  Message
} from 'element-ui';

export default {
  name: "KyHocBaUsbAllV2",
  props: [
    'position',
    'typeKy',
    'ma_lop',
    'dulieu',
    'thuchienky',
  ],
  computed: {
    ...mapState('teacher', [
      'pdfSignature'
    ]),
  },
  data: () => {
    return {
      list_res_sign_hash: [],
      EdocPluginSocket: '',
      signatureBase64: '',
      loadingText: 'Đang xử lý',
      loading: false,
      typeKyDuLieu: '',
      SerialNumberSend: ''
    }
  },
  methods: {
    kyThanhCong() {
      console.error("Ký thành công, có thể ký lại")
      this.$emit('close')
    },
    huyKy() {
      console.error("Hủy bỏ việc ký để có thể ký lại")
      this.loading = false;
      this.$emit('close')
    },
    onReceiveMessage(e) {
      let data = e.data;
      if (data.other == msgType.type.signScanUSBTOKEN || data.other == msgType.type.signDauQTHTUSBTOKEN || data.other == msgType.type.signUsbHocBa || data.other == msgType.type.kyDauBiahHocBa || data.other == msgType.type.dongDauBiaHocBa || data.other == msgType.type.signQTHTUSBTOKEN ||data.other== msgType.type.signDauSoDiemUSBTOKEN||data.other== msgType.type.signKySoDiemUSBTOKEN||data.other== msgType.type.signDauSoDiemUSBTOKEN|| data.other == msgType.type.thucHienDongDauBiaAnh || data.other == msgType.type.hieuTruongKyDauBiahHocBa) {
        console.error('onReceiveMessage Ký USB All')
        console.error(data)
        if (data.code == 200) {
          this.thongBao('success', data.Message)
          this.kyThanhCong();
        } else {
          this.thongBao('error', data.Message)
          this.huyKy();
        }
        this.loading = false;
      }
      if (data.other == msgType.type.getHashKyQTHTUSBTOKEN || data.other == msgType.type.getHashDauQTHTUSBTOKEN || data.other == msgType.type.getHashHocBa || data.other == msgType.type.getHashKyScanUSBTOKEN || data.other == msgType.type.getHashDauBiahHocBa || data.other == msgType.type.getHashDongDauBiaHocBa || data.other == msgType.type.getHashHieuTruongKyDauBiahHocBa || data.other == msgType.type.getHashDongDauBiaAnh || data.other == msgType.type.getHashKyScanUSBTOKEN|| data.other == msgType.type.layHashDongDauSoDiem|| data.other == msgType.type.layHashKySoDiem) {
        console.log("Thông tin lấy hash trả về:")
        console.log(data)
        if (data.code == 200) {
          this.InsertSignatrue(data.data);//?.....
        } else {
          this.thongBao('error', data.Message)
          this.$emit('error')
          this.huyKy();
        }
      }
    },
    async InsertSignatrue(e) {
      var that = this;
      console.log('InsertSignatrue')
      let datas = [];
      for (let i = 0; i < e.length; i++) {
        const item = e[i];
        //console.log("event promise")
        //console.log('1')
        const testA = await that.getSignatureBase64(item);
        let du_lieu_ky = {
          id: item.id,
          signatureBase64: testA,
          key: item.key,
          viTri: item.viTri,
          serialNumber: that.SerialNumberSend
        }
        datas.push(du_lieu_ky);

      }
      console.log("Thông tin ký:", datas)
      console.log('Kiểu ký trong InsertSignatrue:' + that.typeKyDuLieu)
      this.loadingText = 'Đang thực hiện ký...'
      let data = {
        msgType: msgType.type.signUsbHocBa,
        maLop: that.dulieu[0].maLop,
        data: datas,
        serialNumber: that.SerialNumberSend
      }
      if (that.typeKyDuLieu == 'dongDauBia') {
        data.msgType = msgType.type.dongDauBiaHocBa
      }
      if (that.typeKyDuLieu == 'bia') {

        data.msgType = msgType.type.hieuTruongKyDauBiahHocBa
      }
      if (that.typeKyDuLieu == 'dongDauBiaKqht') {
        // data.msgType = msgType.type.thucHienDongDauBiaKqht
        data.msgType = msgType.type.kyDauBiahHocBa
      }
      if (that.typeKyDuLieu == 'dongDauBiaAnh') {
        data.msgType = msgType.type.thucHienDongDauBiaAnh
      }
      if (that.typeKyDuLieu == 'dongDauKqht') {
        data.msgType = msgType.type.dongDauBiaHocBa
      }
      if (that.typeKyDuLieu == 'dauQTHT') {
        data.msgType = msgType.type.signDauQTHTUSBTOKEN
      }
      if (that.typeKyDuLieu == 'kyQTHT') {
        data.msgType = msgType.type.signQTHTUSBTOKEN
      }
      if (that.typeKyDuLieu == 'dongDauSoDiem') {
        data.msgType = msgType.type.signDauSoDiemUSBTOKEN
      }
      if (that.typeKyDuLieu == 'kySoDiem') {
        data.msgType = msgType.type.signKySoDiemUSBTOKEN
      }
      if (that.typeKyDuLieu == 'kyscan') {
        data.msgType = msgType.type.signScanUSBTOKEN;
        data.position = that.position;
      }
      websocket.sendMessage(data);
    },
    async getSignatureBase64(e) {
      var serialNumber = e.serialNumber || 'null';
      this.SerialNumberSend = serialNumber;
      var hashBase64 = e.hashBase64;
      const signHash = await this.EdocPluginSocket.signHash(hashBase64, serialNumber);
      return signHash;
    },
    signUsb() {
      this.loadingText = 'Đang lấy thông tin chứng thư số...';
      console.log("Kiểu ký:" + this.typeKyDuLieu)
      this.loading = true;
      let that = this;
      console.log('B1: getCertificateInfo')
      this.EdocPluginSocket.getCertificateInfo(function (response) {
        if (response.Status == 0) {
          that.thongBao('error', response.Message)
          that.loading = false;
          that.huyKy();
          return;
        }
        if (response.Status == -105) {
          that.thongBao('error', response.Message)
          that.loading = false;
          that.$confirm('Xác nhận tải và cài đặt ứng dụng?', 'Thông báo', {
            confirmButtonText: 'Đồng ý',
            cancelButtonText: 'Hủy',
          })
              .then(_ => {
                console.log("Tải ứng dụng:")
                window.location.href = '/images/EDOC-CA Plugin Setup.msi';
              })
              .catch(_ => {
              });
          that.huyKy();
          return;
        }

        var certChainBase64 = new String(response.Data.Base64);
        if (certChainBase64 == undefined || certChainBase64.length < 500) {
          that.thongBao('error', 'Chọn CTS không thành công')
          that.loading = false;
          that.huyKy();
          return false;
        }
        console.log("B2")
        let ids = [];
        let maLop = "";
        that.dulieu.forEach(item => {
          ids.push({
            id: item.id
          });
          maLop = item.maLop
        })
        let dataHash = {
          reqs: ids,
          certChainBase64: certChainBase64,
          maLop: maLop,
          msgType: ''
        };
        // dataHash.msgType = that.getMessageType(that.typeKyDuLieu)
        if (that.typeKyDuLieu == 'bia') {
          dataHash.msgType = msgType.type.getHashHieuTruongKyDauBiahHocBa;
        }
        if (that.typeKyDuLieu == 'hocba') {
          dataHash.msgType = msgType.type.getHashHocBa;
        }
        if (that.typeKyDuLieu == 'dongDauBia') {
          dataHash.msgType = msgType.type.getHashDongDauBiaHocBa;
        }
        if (that.typeKyDuLieu == 'dongDauBiaKqht') {
          dataHash.msgType = msgType.type.getHashDauBiahHocBa;
        }
        if (that.typeKyDuLieu == 'dongDauBiaAnh') {
          dataHash.msgType = msgType.type.getHashDongDauBiaAnh;
        }
        if (that.typeKyDuLieu == 'dongDauKqht') {
          dataHash.msgType = msgType.type.getHashDongDauBiaHocBa;
        }
        if (that.typeKyDuLieu == 'dauQTHT') {
          dataHash.msgType = msgType.type.getHashDauQTHTUSBTOKEN;
        }
        if (that.typeKyDuLieu == 'kyQTHT') {
          dataHash.msgType = msgType.type.getHashKyQTHTUSBTOKEN;
        }
        if (that.typeKyDuLieu == 'kyscan') {
          dataHash.msgType = msgType.type.getHashKyScanUSBTOKEN;
          dataHash.position = that.position;
        }
        if (that.typeKyDuLieu == 'dongDauSoDiem') {
          dataHash.msgType = msgType.type.layHashDongDauSoDiem;
          dataHash.position = that.position;
        }
        if (that.typeKyDuLieu == 'kySoDiem') {
          dataHash.msgType = msgType.type.layHashKySoDiem;
          dataHash.position = that.position;
        }
        console.log('dataHash');
        console.log(dataHash);
        that.loadingText = 'Lấy hash...'
        // chỗ này call về BE để nó lấy hash rồi trả ra chỗ onMessage đăng ký ở mounted
        websocket.sendMessage(dataHash);


      });

    },

    thongBao(t, e) {
      let msg = "";
      let cl = "";
      if (e) {
        msg = e;
      }
      let type = "success";
      if (t) {
        type = t
      }
      if (type == "success") {
        cl = "dts-noty-success"
      }
      if (type == "warning") {
        cl = "dts-noty-warning"
      }
      if (type == "error") {
        cl = "dts-noty-error"
      }
      if (type == "info") {
        cl = "dts-noty-info"
      }
      this.$message({
        customClass: cl,
        showClose: true,
        message: msg,
        type: t,
        duration: 3000
      });
    },
    getMessageType(typeKy) {
      switch (typeKy) {
        case "bia":
          return msgType.type.getHashHieuTruongKyDauBiahHocBa;
        case "kyQTHT":
          return msgType.type.getHashKyQTHTUSBTOKEN
        default:
          return ""
      }
    }
  },

  watch: {
    dulieu: function (val) {
      if (val) {
        console.log("Dữ liệu ký:", val)
        //console.log('Dữ liệu thay đổi:')
        //this.typeKyDuLieu = JSON.parse(JSON.stringify(this.typeKy))
        //console.log('Kiểu ký:' + this.typeKyDuLieu)
      }
    },
    typeKyDuLieu(val) {
      if (val) {
        console.log("Khi gán type ký xong thì all ky usb")
        this.signUsb()
      }
    }

  },
  beforeDestroy() {
    console.log('beforeDestroy')
    window.removeEventListener('message', this.onReceiveMessage)
  },
  beforeRouteLeave(to, from, next) {
    console.error('beforeRouteLeave')
    window.removeEventListener('message', this.onReceiveMessage)
    next();
  },
  mounted() {
    console.error('Mounted trong Ký học Bạ USB V2:')
    console.error('Kiểu ký:' + this.typeKy)
    this.typeKyDuLieu = JSON.parse(JSON.stringify(this.typeKy))
    window.addEventListener('message', this.onReceiveMessage, false);
    var that = this;
    this.EdocPluginSocket = {

      sendMessageToPlugin: function (serviceName, data, callBack) {
        var socket = new WebSocket('ws://localhost:5987/' + serviceName);
        // Connection opened
        socket.addEventListener('open', function (event) {
          console.log("Open")
          socket.send(data);
        });
        socket.addEventListener('message', function (event) {
          console.log("message")
          callBack(JSON.parse(event.data));
        });

        // Listen for close connection
        socket.addEventListener('close', function (event) {
          console.log('close')
          var response = {
            Status: -105,
            Message: "Chưa cài đặt hoặc chưa chạy EDOC-CA Plugin."
          }
          if (event.code == 1006) {
            callBack(response);
          }
        });
      },

      sendMessageToPluginAsync: async function (serviceName, data) {
        // Create WebSocket connection.
        return new Promise((resolve, reject) => {
          const socket = new WebSocket('ws://localhost:5987/' + serviceName);
          // Connection opened
          socket.addEventListener('open', function (event) {
            console.log("Open")
            socket.send(data);
          });

          // Listen for messages
          socket.addEventListener('message', function (event) {
            console.log("message")

            resolve(JSON.parse(event.data));
          });

          // Listen for close connection
          socket.addEventListener('close', function (event) {
            console.log('close')
            var response = {
              Status: -105,
              Message: "Chưa cài đặt hoặc chưa chạy EDOC-CA Plugin."
            }
            if (event.code == 1006) {
              resolve(response);
            }
          });
        })
      },

      getCertificateInfo: function (callBack) {
        var functionName = 'get-cert-info';
        console.log("getCertificateInfo")
        that.EdocPluginSocket.sendMessageToPlugin(functionName, null, callBack);
      },
      signHash: async function (hashBase64, serialNumber) {
        var functionName = 'sign-hash';
        var data = {
          DataHash: hashBase64,
          SerialNumber: serialNumber
        }
        console.log("data signHash ")
        console.log(data)
        const respon = await that.EdocPluginSocket.sendMessageToPluginAsync(functionName, JSON.stringify(data));
        console.log("respon signHash")
        console.log(respon)
        return respon.Data
      }
    }
  }
}
</script>

<style scoped>

</style>