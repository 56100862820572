import Axios from "axios";
import api from "../api";
import restApi from "../_helpers/api";


export const LoginSsoService = {
    detectCode,
    detectCodeV2,
    loginSso,
    getSsoInfo,
    getSsoInfoV2,
    getSsoInfoSmas,
    getInfoK12,
}

function detectCodeV2(data) {
    let code_verifier = localStorage.getItem("code_verifyer");
    console.log('detectCode');
    console.log(code_verifier);
    let uri = api.sso.getInfoV2;
    let params = {
        grant_type: "authorization_code",
        code: data,
        // client_id:"5f9a6bb662aa4a0d003eceb2",
        // client_secret:"hbdt#$%",
        // redirect_uri:"https://hocbadientu.csdl.edu.vn",
        codeVerifier: code_verifier,
    };
    return restApi.post(uri, params);
}

function detectCode(data) {
    let uri = api.sso.getInfo;
    var redirect_uri = location.protocol + '//' + location.host;
    let params = {
        grant_type: "authorization_code",
        code: data,
        client_id: "5f9a6bb662aa4a0d003eceb2",
        client_secret: "hbdt#$%",
        redirect_uri: redirect_uri,
    };
    return restApi.post(uri, params);
}

function getSsoInfo(token) {
    let uri = api.sso.login;
    let params = {
        code: token,
    };
    return restApi.post(uri, params);
}

function getSsoInfoV2(token) {
    let uri = api.sso.loginV2;
    let params = {
        code: token,
    };
    return restApi.post(uri, params);
}

function getSsoInfoSmas(token) {
    let uri = api.sso.loginSmas;
    let params = {
        token: token,
    };
    return restApi.postSmas(uri, params);
}
function getInfoK12(token) {
    let uri = api.sso.loginK12;
    console.log('getInfoK12')
    let params = {
        token: token,
    };
    return restApi.postK12(uri, params);
}

function loginSso(data) {
    let uri = api.sso.login
    return restApi.post(uri, data);
}
