<template>
    <div class="d-flex w-100 header__nav">
        <div class="d-flex align-items-center w-100">
            <div class="menu-icon-mobile">
                <el-link :underline="false" @click.prevent="setMenuMobile(true)">
                    <i class="ion ion-md-menu"></i>
                </el-link>

            </div>
            <div class="logo">
                <img src="/images/logo.png" alt="logo">
                Học bạ số
            </div>
            <div class="right ml-auto">
              <div style="display: none">
                <el-tooltip class="item" effect="dark" content="Tính năng đang trong quá trình phát triển" placement="top">
                  <div class="notify">
                    <el-badge is-dot class="item" style="padding: 0 5px">
                      <div class="notify--square cursor-pointer">
                        <i class="el-icon-message-solid" type="primary"></i>
                      </div>
                    </el-badge>
                  </div>
                </el-tooltip>
              </div>

            </div>
        </div>
    </div>

</template>

<script>
    import {
        mapMutations,
        mapActions,
        mapState
    } from 'vuex';

    export default {
        name: "Header",
        computed: {
            ...mapState('account',
                [
                    'user',
                    'profile'
                ]),
            ...mapState('until',
                [
                    "menuMobileOpen"

                ]),
        },
        data() {
            return {

            };
        },
        methods: {
            ...mapMutations("until",[
                "setMenuMobile"
            ]),

        },
        mounted() {

        }
    }
</script>

<style scoped>

</style>