export default {
    hocsinh: {
        getHocBa: '/hocbadientu-service/api/hoc-sinh/danh-sach-hoc-ba',
        request: '/hocbadientu-service/api/hoc-sinh/yeu-cau',
        listRequest: '/hocbadientu-service/api/hoc-sinh/danh-sach-yeu-cau',
        getListHS: '/hocbadientu-service/api/hoc-sinh/danh-sach-hoc-sinh',
        getListHocSinh: '/hocbadientu-service/api/hoc-sinh/thong-tin-hoc-sinh',
        listHocBa: '/hocbadientu-service/api/hoc-sinh/lay-hoc-ba',
        info: '/hocbadientu-service/api/hoc-sinh/thong-tin-ca-nhan'
    },
    bieudo: {
        trangThaiTheoLop: '/hocbadientu-service/api/giao-vien/thong-ke-theo-giao-vien',
        trangThaiTheoGiaoVien: '/hocbadientu-service/api/giao-vien/thong-ke-trang-thai-gvcn',
        trangThaiTheoMonHoc: '/hocbadientu-service/api/giao-vien/thong-ke-theo-mon-hoc',
        trangThaiTheoLopMonHoc: '/hocbadientu-service/api/giao-vien/thong-ke-theo-lop-hoc',
        tongQuan: '/hocbadientu-service/api/giao-vien/thong-ke-tong-quan',
        tongQuanHieuTruong: '/hocbadientu-service/api/giao-vien/thong-ke-trang-thai-hieu-truong',
        trangThaiHocBaTheoLop: '/hocbadientu-service/api/giao-vien/thong-ke-chi-tiet-trang-thai-hieu-truong',
        chiTietTrangThaiHocBaTheoGVCN: '/hocbadientu-service/api/giao-vien/thong-ke-chi-tiet-hoc-ba-hoc-sinh',
        hocBaHieuTruong: '/hocbadientu-service/api/giao-vien/thong-ke-hoc-ba-hoc-sinh',
        hocBaGvcn: '/hocbadientu-service/api/giao-vien/thong-ke-hb-hs-theo-gvcn',
    },
    configKy: {
        layDanhSachCauHinhTheoPhong: '/hocbadientu-service/api/admin/danh-sach-truong',
        layDanhSachCauHinhTheoSo: '/hocbadientu-service/api/admin/danh-sach-phong-gd',
        danhSachCauHinh: '/hocbadientu-service/api/admin/danh-sach-cau-hinh-so',
        themMoiCauHinhSo: '/hocbadientu-service/api/admin/tao-cau-hinh-su-dung',
        chinhSuaKieuKy: '/hocbadientu-service/api/admin/sua-cau-hinh-su-dung',
        xoaCauHinhSo: '/hocbadientu-service/api/admin/xoa-cau-hinh-su-dung',
        getDanhSachSo: '/hocbadientu-service/api/admin/danh-sach-so-gd',
        getConfigKy: '/hocbadientu-service/api/so-giao-duc/lay-cau-hinh-thoi-gian',
        createConfigKy: '/hocbadientu-service/api/so-giao-duc/tao-cau-hinh-thoi-gian',
        updateConfigKy: '/hocbadientu-service/api/so-giao-duc/cap-nhat-cau-hinh-thoi-gian',
        updateConfig2: '/hocbadientu-service/api/so-giao-duc/cap-nhat-cau-hinh-thoi-gian-phong-truong',
    },
    report: {
        getReport: '/hocbadientu-service/api/so-giao-duc/lay-bao-cao-tinh-trang-ky',
        getReportPhong: '/hocbadientu-service/api/so-giao-duc/lay-bao-cao-tinh-trang-ky-theo-phong',
        getReportKyLai: '/hocbadientu-service/api/so-giao-duc/lay-bao-cao-tinh-trang-ky-lai',
        getReportKyLaiPhong: '/hocbadientu-service/api/so-giao-duc/lay-bao-cao-tinh-trang-ky-lai-theo-phong'
    },
    login: {
        login: '/hocbadientu-service/api/login',
        loginOtp: '/hocbadientu-service/api/send-otp',
        confirmOtp: '/hocbadientu-service/api/confirm-otp',
        checkTokenHS: '/hocbadientu-service/api/checkTokenHS',
        checkTokenGV: '/hocbadientu-service/api/checkTokenGV',
        getCapcha: '/hocbadientu-service/api/captcha',
    },
    truong: {
        getTinh: '/hocbadientu-service/api/truong/danh-sach-tinh',
        listTruong: '/hocbadientu-service/api/truong/danh-sach-truong'
    },
    hocba: {
        getDetail: '/hocbadientu-service/api/hoc-ba/detail',
        detail: '/hocbadientu-service/api/hoc-ba/lay-hoc-ba',
        getFile: '/hocbadientu-view-service/api/hoc-ba/xem-hoc-ba',
        hocBaDetail: '/hocbadientu-service/api/hoc-ba/xem-hoc-ba-theo-cap-hoc',
        createHocBaTieuHoc: '/hocbadientu-service/api/hoc-ba/tao-hoc-ba-tieu-hoc',
        createHocBaTrungHoc: '/hocbadientu-service/api/hoc-ba/tao-hoc-ba-trung-hoc',
        updateHocBaTieuHoc: '/hocbadientu-service/api/hoc-ba/update-hoc-ba-tieu-hoc',
        updateHocBaTrungHoc: '/hocbadientu-service/api/hoc-ba/update-hoc-ba-trung-hoc',
        listHocba: '/hocbadientu-service/api/hoc-ba/danh-sach-hoc-ba',
        layDiemTongKet: '/hocbadientu-service/api/giao-vien/danh-sach-ket-qua-hoc-tap',
        detailPdf: '/hocbadientu-view-service/api/hoc-ba/xem-hoc-ba-theo-ma-lop',
        permission: '/hocbadientu-service/api/hoc-ba/update',
        getHistory: '/hocbadientu-service/api/hoc-ba/danh-sach-loi-gen-file'
    },
    giaovien: {
        getKieuKy: '/hocbadientu-service/api/giao-vien/kieu-ky-giao-vien',
        xemBaoCao: '/hocbadientu-service/api/giao-vien/bao-cao',
        listHocba: '/hocbadientu-service/api/giao-vien/danh-sach-hoc-ba',
        danhSachChungChi: '/hocbadientu-ws-service/api/ws/get-certificates',
        listLop: '/hocbadientu-service/api/giao-vien/danh-sach-lop',
        listLopChuNhiem: '/hocbadientu-service/api/giao-vien/danh-sach-lop-chu-nhiem',
        listRequestNop: '/hocbadientu-service/api/giao-vien/danh-sach-yeu-cau-nop',
        listRequestRut: '/hocbadientu-service/api/giao-vien/danh-sach-yeu-cau-rut',
        updateHocBa: '/hocbadientu-service/api/giao-vien/cap-nhat-hoc-ba',
        kyHocBa: '/hocbadientu-service/api/giao-vien/ky-hoc-ba',
        pheDuyetNop: '/hocbadientu-service/api/giao-vien/phe-duyet-yeu-cau-nop',
        pheDuyetRut: '/hocbadientu-service/api/giao-vien/phe-duyet-yeu-cau-rut',
        getOtp: '/hocbadientu-service/api/giao-vien/send-otp',
        phanLop: '/hocbadientu-service/api/giao-vien/phan-lop',
        uploadHocBa: '/hocbadientu-service/api/hoc-ba/upload/file',
        uploadBiaHocBa: '/hocbadientu-service/api/hoc-ba/upload/file-bia',
        kySoHocBa: '/hocbadientu-service/api/giao-vien/ky-hoc-ba-not-ca',
        getHashUsb: '/hocbadientu-service/api/giao-vien/hash-file',
        kyUsbHocBa: '/hocbadientu-service/api/giao-vien/ky-usb-token',
        listGiaoVien: '/hocbadientu-service/api/giao-vien/danh-sach-giao-vien',
        uploadfile: '/hocbadientu-service/api/giao-vien/image',
        updateSignature: '/hocbadientu-service/api/giao-vien/cap-nhat-chu-ky',
        info: '/hocbadientu-service/api/giao-vien/thong-tin-ca-nhan',
        duyetChuKy: '/hocbadientu-service/api/giao-vien/duyet-chu-ky',
        dsBiaHocBa: '/hocbadientu-service/api/giao-vien/danh-sach-bia-hoc-ba',
        dsHocBaScan: '/hocbadientu-service/api/hoc-ba/danh-sach-hoc-ba-scan',
        dsHocBaDongDauScan: '/hocbadientu-service/api/hoc-ba/danh-sach-hoc-ba-scan-dong-dau',
        dsDongDauQuaTrinhHocTap:'/hocbadientu-service/api/hoc-ba/danh-sach-bia-dong-dau-qtht',
        dsKyQuaTrinhHocTap:'/hocbadientu-service/api/hoc-ba/danh-sach-bia-ky-qtht',
        dsKySoDiem:'/hocbadientu-view-service/api/so-diem/danh-sach-lop-ky-so-diem',
        dsDongDauSoDiem:'/hocbadientu-view-service/api/so-diem/danh-sach-lop-dong-dau-so-diem',
    },
    school: {
        listPgd: '/hocbadientu-service/api/truong/danh-sach-phong',
        listSchool: '/hocbadientu-service/api/truong/danh-sach-truong',
        infoSchool: '/hocbadientu-service/api/truong/get',
        updateInfoTruong: '/hocbadientu-service/api/truong/update',
        updateInfoChuKy: '/hocbadientu-service/api/truong/image',
        dongBo: '/hocbadientu-service/api/hoc-ba/dong-bo-du-lieu',
        historySync: '/hocbadientu-service/api/hoc-ba/lich-su-dong-bo-du-lieu',
        getListNamHoc: '/hocbadientu-service/api/truong/danh-sach-thoi-gian-nam-hoc',
        addNamHoc: '/hocbadientu-service/api/truong/thoi-gian-nam-hoc',
        updateNamHoc: '/hocbadientu-service/api/truong/cap-nhat-thoi-gian-nam-hoc',
        deleteNamHoc: '/hocbadientu-service/api/truong/xoa-thoi-gian-nam-hoc',
    },
    sso: {
        url: '/hocbadientu-service/api/truong/danh-sach-phong',
        login: '/hocbadientu-service/api/receive-sso-token',
        loginV2: '/hocbadientu-service/api/receive-sso-token-v2',
        getInfo: '/hocbadientu-service/api/access-token-sso',
        getInfoV2: '/hocbadientu-service/api/access-token-sso-v2',
        loginSmas: '/hocbadientu-service/api/login-smas',
        loginK12: '/hocbadientu-service/api/login-k12connect',
        // getInfo: 'https://sso-edu.viettel.vn/api/Core/OAuth/User/me',
        detect: 'https://sso-edu.viettel.vn/api/Core/OAuth/AuthCode/accessToken'
    },
    general: {},
    test: {
        url: '/hocbadientu-service/api/truong/danh-sach-tinh'
    }
};
