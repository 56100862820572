<template>
    <div v-loading.fullscreen.lock="loading" :element-loading-text="loadingText"
         element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)"></div>
</template>

<script>
import websocket from "../websocket";
import {
    mapState,
    mapActions
} from 'vuex';
import dialog from "../dialog";
import {
    Message
} from 'element-ui';

export default {
    name: "KyHocBaUsbAllV2",
    props: [
        'typeKy',
        'dulieu',
        'thuchienky',
    ],
    computed: {
        ...mapState('teacher', [
            'pdfSignature'
        ]),
    },
    data: () => {
        return {
            EdocPluginSocket: '',
            signatureBase64: '',
            loadingText: 'Đang xử lý',
            loading: false,
        }
    },
    methods: {
        kyThanhCong() {
            console.log("Ký thành công, có thể ký lại")
            this.$emit('done')
        },
        huyKy() {
            console.log("Hủy bỏ việc ký để có thể ký lại")
            this.loading = false;
            this.$emit('close')
        },
        onReceiveMessage(e) {
            let data = e.data;
            if (data.other == 7) {
                console.log("Thông tin ký trả về từ websocket: 7")
                console.log(data)
                if (data.code == 200) {
                    this.InsertSignatrue(data.data);
                } else {
                    this.thongBao('error', data.msg)
                    this.huyKy();
                }
            }
            if (data.other == 8) {
                if (data.code == 200) {
                    this.thongBao('success', 'Ký bìa học bạ thành công')
                    this.kyThanhCong();
                } else {
                    this.thongBao('error', data.msg)
                    this.huyKy();
                }
                this.loading = false;
            }
            if (data.other == 4) {
                if (data.code == 200) {
                    this.thongBao('success', 'Ký học bạ thành công')
                    this.kyThanhCong();
                } else {
                    this.thongBao('error', data.msg)
                    this.huyKy();
                }
                this.loading = false;
            }
            if (data.other == 3) {
                if (data.code == 200) {
                    this.InsertSignatrue(data.data);
                } else {
                    this.thongBao('error', data.msg)
                    this.huyKy();
                }
                this.loading = false;
            }
        },
        InsertSignatrue(e) {
            console.log(e);
            let that = this;
            var serialNumber = e.serialNumber;
            var hashBase64 = e.hashBase64;
            this.EdocPluginSocket.signHash(hashBase64, serialNumber, function (response) {
                console.error("signHash")
                console.error(response)
                if (response.Status == 0) {
                    that.thongBao('error', response.Message);
                    that.huyKy();
                    return;
                }
                that.signatureBase64 = new String(response.Data);
                if (that.signatureBase64 == null || that.signatureBase64 == undefined || that
                    .signatureBase64.trim().length == 0) {
                    that.thongBao('error', 'Sign Hash không thành công')
                    return false;
                } else {
                    // that.thongBao('success',response.Message)
                    that.kyHocBa(that.signatureBase64);
                }
            });
        },

        kyHocBa(signatureBase64) {
            this.loadingText = 'Đang thực hiện ký...'
            console.log("kyHocBa signatureBase64");
            console.log(this.dulieu);
            let data;
            data = {
                id: this.dulieu.id,
                signatureBase64: signatureBase64,
                pdfSignature: '',
                type: 1,
                maLop: this.dulieu.maLop,
                maGVCN: '',
                msgType: ''
            };
            if (this.typeKy == 'bia') {
                data.msgType = 8;
                data.type = 1;
            }
            if (this.typeKy == 'hocba') {
                data.msgType = 4;
                data.type = this.dulieu.loaiHocBa;
                data.maGVCN = this.dulieu.maGVCN;
            }
            if (this.typeKy == 'dongDauKqht') {
                data.msgType = 4;
            }
            console.log("Thông tin ký")
            console.log(data)
            websocket.sendMessage(data);
            // this.kyUsbHocBa(data);
        },
        signUsb() {
            this.loadingText = 'Đang lấy thông tin chứng thư số...';
            console.log("Kiểu ký:" + this.typeKy)
            this.loading = true;
            console.log('B1')
            let that = this;
            this.EdocPluginSocket.getCertificateInfo(function (response) {
                console.error("signUsb")
                console.error(response)
                if (response.Status == 0) {
                    that.thongBao('error', response.Message)
                    that.loading = false;
                    that.huyKy();
                    return;
                }
                if (response.Status == -105) {
                    that.thongBao('error', response.Message)
                    that.loading = false;
                    that.$confirm('Xác nhận tải và cài đặt ứng dụng?', 'Thông báo', {
                        confirmButtonText: 'Đồng ý',
                        cancelButtonText: 'Hủy',
                    })
                        .then(_ => {
                            console.log("Tải ứng dụng:")
                            window.location.href = '/images/EDOC-CA Plugin Setup.msi';
                        })
                        .catch(_ => {
                        });
                    that.huyKy();
                    return;
                }

                var certChainBase64 = new String(response.Data.Base64);
                if (certChainBase64 == undefined || certChainBase64.length < 500) {
                    that.thongBao('error', 'Chọn CTS không thành công')
                    that.loading = false;
                    that.huyKy();
                    return false;
                }
                console.log("B2")
                let dataHash = {
                    id: that.dulieu.id,
                    maLop: that.dulieu.maLop,
                    certChainBase64: certChainBase64,
                    msgType: ''
                };

                if (that.typeKy == 'bia') {
                    dataHash.msgType = 7;
                }
                if (that.typeKy == 'hocba') {
                    dataHash.msgType = 3;
                }
                console.log('data');
                console.log(dataHash);

                that.loadingText = 'Lấy hash...'
                websocket.sendMessage(dataHash);


            });

        },

        thongBao(t, e) {
            let msg = "";
            let cl = "";
            if (e) {
                msg = e;
            }
            let type = "success";
            if (t) {
                type = t
            }
            if (type == "success") {
                cl = "dts-noty-success"
            }
            if (type == "warning") {
                cl = "dts-noty-warning"
            }
            if (type == "error") {
                cl = "dts-noty-error"
            }
            if (type == "info") {
                cl = "dts-noty-info"
            }
            this.$message({
                customClass: cl,
                showClose: true,
                message: msg,
                type: t,
                duration: 3000
            });
        },

    },

    watch: {
        thuchienky: function (val) {
            if (val) {
                console.error("Thực hiện thao tác ký Usb:" + val)
                this.signUsb();
            }
        }
    },

    mounted() {
        window.addEventListener('message', this.onReceiveMessage, false);
        var that = this;
        this.EdocPluginSocket = {
            sendMessageToPlugin: function (serviceName, data, callBack) {
                // Create WebSocket connection.
                const socket = new WebSocket('ws://localhost:5987/' + serviceName);

                // Connection opened
                socket.addEventListener('open', function (event) {
                    socket.send(data);
                });

                // Listen for messages
                socket.addEventListener('message', function (event) {
                    callBack(JSON.parse(event.data));
                });

                // Listen for close connection
                socket.addEventListener('close', function (event) {
                    var response = {
                        Status: -105,
                        Message: "Chưa cài đặt hoặc chưa chạy EDOC-CA Plugin."
                    }
                    if (event.code == 1006) {
                        callBack(response);
                    }
                });
            },
            getCertificateInfo: function (callBack) {
                var functionName = 'get-cert-info';
                console.log("getCertificateInfo")
                that.EdocPluginSocket.sendMessageToPlugin(functionName, null, callBack);
            },
            signHash: function (hashBase64, serialNumber, callBack) {
                var functionName = 'sign-hash';
                var data = {
                    DataHash: hashBase64,
                    SerialNumber: serialNumber
                }
                that.EdocPluginSocket.sendMessageToPlugin(functionName, JSON.stringify(data), callBack);
            }
        }
    }
}
</script>

<style scoped>

</style>