var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true, lock: true },
        },
      ],
      staticClass: "page page-file file detailHocBa",
      attrs: { id: "listConfig" },
    },
    [
      _c("div", { staticClass: "box-upload-file" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "pl-3" },
          [
            _c(
              "el-row",
              { attrs: { gutter: 24 } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c("eselect", {
                      staticStyle: { width: "100%" },
                      attrs: {
                        collapseTags: "",
                        multiple: "",
                        placeholder: "Chọn Sở Giáo dục và Đào tạo",
                        filterable: "",
                        data: _vm.list_so,
                        fields: ["name", "value"],
                      },
                      model: {
                        value: _vm.maDonVi,
                        callback: function ($$v) {
                          _vm.maDonVi = $$v
                        },
                        expression: "maDonVi",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 4 } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.submitSearch()
                          },
                        },
                      },
                      [_vm._v("Thêm mới")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page__content" },
        [
          _c(
            "el-table",
            {
              staticClass: "configSo--phongCustom",
              attrs: {
                "expand-row-keys": _vm.expandDanhSachPhong,
                border: "",
                "max-height": "1000",
                fit: "",
                stripe: "",
                "row-key": "maSo",
                data: _vm.list_ma_so,
              },
              on: { "expand-change": _vm.expandChangeSo },
            },
            [
              _c("el-table-column", {
                attrs: { type: "expand" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: props.row.loading,
                                expression: "props.row.loading",
                              },
                            ],
                            staticClass: "configSo--truongCustom",
                            attrs: {
                              border: "",
                              fit: "",
                              "max-height": "600",
                              "element-loading-text": "Đang lấy dữ liệu...",
                              "element-loading-spinner": "el-icon-loading",
                              "element-loading-background":
                                "rgba(255, 255, 255, 0.8)",
                              data: props.row.list_phong,
                            },
                            on: { "expand-change": _vm.expandChangePhong },
                          },
                          [
                            _c("el-table-column", {
                              attrs: { type: "expand" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (truong) {
                                      return [
                                        _c(
                                          "el-table",
                                          {
                                            staticClass: "configSo--truong",
                                            attrs: {
                                              border: "",
                                              fit: "",
                                              "max-height": "600",
                                              data: truong.row.listTruong,
                                            },
                                          },
                                          [
                                            _c("el-table-column", {
                                              staticStyle: { width: "60px" },
                                              attrs: {
                                                "header-align": "center",
                                                label: "STT",
                                                width: "60",
                                                type: "index",
                                                "class-name": "text-center",
                                                index: _vm.indexMethod,
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("el-table-column", {
                                              attrs: {
                                                "header-align": "center",
                                                label: "Tên sở",
                                                property: "tenSo",
                                                width: "300",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("el-table-column", {
                                              attrs: {
                                                "header-align": "center",
                                                label: "Tên phòng",
                                                property: "tenPhong",
                                                width: "300",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("el-table-column", {
                                              attrs: {
                                                "header-align": "center",
                                                label: "Tên trường học",
                                                property: "tenTruong",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("el-table-column", {
                                              attrs: {
                                                "header-align": "center",
                                                label: "Thao Tác",
                                                width: "300",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (moiTruong) {
                                                      return [
                                                        moiTruong.row
                                                          .trangThai == 1
                                                          ? _c(
                                                              "el-tooltip",
                                                              {
                                                                staticClass:
                                                                  "item",
                                                                attrs: {
                                                                  effect:
                                                                    "dark",
                                                                  content:
                                                                    "Xóa",
                                                                  placement:
                                                                    "left-end",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-button",
                                                                  {
                                                                    attrs: {
                                                                      type: "danger",
                                                                      icon: "el-icon-delete",
                                                                      size: "mini",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          return _vm.ConfirmXoaCauHinhChoTruong(
                                                                            moiTruong.row
                                                                          )
                                                                        },
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        moiTruong.row
                                                          .trangThai == 0
                                                          ? _c(
                                                              "el-tooltip",
                                                              {
                                                                staticClass:
                                                                  "item",
                                                                attrs: {
                                                                  effect:
                                                                    "dark",
                                                                  content:
                                                                    "Thêm",
                                                                  placement:
                                                                    "left-end",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-button",
                                                                  {
                                                                    attrs: {
                                                                      type: "primary",
                                                                      icon: "el-icon-circle-plus-outline",
                                                                      size: "mini",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          return _vm.ConfirmThemCauHinhChoTruong(
                                                                            moiTruong.row
                                                                          )
                                                                        },
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        moiTruong.row
                                                          .trangThai == 1
                                                          ? _c(
                                                              "el-tooltip",
                                                              {
                                                                staticClass:
                                                                  "item",
                                                                attrs: {
                                                                  effect:
                                                                    "dark",
                                                                  content:
                                                                    "Chỉnh sửa kiểu ký",
                                                                  placement:
                                                                    "left-end",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-button",
                                                                  {
                                                                    attrs: {
                                                                      type: "warning",
                                                                      icon: "el-icon-edit-outline",
                                                                      size: "mini",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          return _vm.ChinhSuaKieuKy(
                                                                            moiTruong.row,
                                                                            3
                                                                          )
                                                                        },
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              staticStyle: { width: "60px" },
                              attrs: {
                                "header-align": "center",
                                label: "STT",
                                width: "60",
                                type: "index",
                                "class-name": "text-center",
                                index: _vm.indexMethod,
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                "header-align": "center",
                                label: "Tên sở",
                                property: "tenSo",
                                width: "300",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                "header-align": "center",
                                label: "Tên Phòng",
                                property: "tenPhong",
                                width: "300",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                "header-align": "center",
                                label: "Tên trường học",
                                property: "name",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                "header-align": "center",
                                width: "300",
                                label: "Thao Tác",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.trangThai == 1
                                          ? _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  effect: "dark",
                                                  content: "Xóa",
                                                  placement: "left-end",
                                                },
                                              },
                                              [
                                                _c("el-button", {
                                                  attrs: {
                                                    type: "danger",
                                                    icon: "el-icon-delete",
                                                    size: "mini",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      return _vm.ConfirmXoaCauHinhChoPhong(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        scope.row.trangThai == 0
                                          ? _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  effect: "dark",
                                                  content: "Thêm",
                                                  placement: "left-end",
                                                },
                                              },
                                              [
                                                _c("el-button", {
                                                  attrs: {
                                                    type: "primary",
                                                    icon: "el-icon-circle-plus-outline",
                                                    size: "mini",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      return _vm.ConfirmThemCauHinhChoPhong(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        scope.row.trangThai == 1
                                          ? _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  effect: "dark",
                                                  content: "Chỉnh sửa kiểu ký",
                                                  placement: "left-end",
                                                },
                                              },
                                              [
                                                _c("el-button", {
                                                  attrs: {
                                                    type: "warning",
                                                    icon: "el-icon-edit-outline",
                                                    size: "mini",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      return _vm.ChinhSuaKieuKy(
                                                        scope.row,
                                                        2
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                staticStyle: { width: "60px" },
                attrs: {
                  label: "STT",
                  width: "60",
                  type: "index",
                  "class-name": "text-center",
                  index: _vm.indexMethod,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  "header-align": "center",
                  label: "Tên sở",
                  property: "tenSo",
                  width: "300",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  "header-align": "center",
                  label: "Tên phòng",
                  width: "300",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { "header-align": "center", label: "Tên trường" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  "header-align": "center",
                  width: "300",
                  label: "Thao tác",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.trangThai == 1
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "Xóa",
                                  placement: "left-end",
                                },
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    type: "danger",
                                    icon: "el-icon-delete",
                                    size: "mini",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.ConfirmXoaCauHinhTheoSo(
                                        scope.row.id
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.trangThai == 1
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "Chỉnh sửa kiểu ký",
                                  placement: "left-end",
                                },
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    type: "warning",
                                    icon: "el-icon-edit-outline",
                                    size: "mini",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.ChinhSuaKieuKy(scope.row, 1)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "el-dialog",
            {
              attrs: {
                width: "40%",
                title: "Kiểu Ký",
                top: "5vh",
                "custom-class": "thongTinKyTheoMonHoc",
                visible: _vm.hien_thi_chinh_sua_kieu_ky,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.hien_thi_chinh_sua_kieu_ky = $event
                },
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24, type: "flex" } },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "dts-relative" }, [
                      _c("div", { staticClass: "dts-absolute" }, [
                        _c("p", [_vm._v("Giáo viên")]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "dts-radio" },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.kieuKyGiaoVien,
                                callback: function ($$v) {
                                  _vm.kieuKyGiaoVien = $$v
                                },
                                expression: "kieuKyGiaoVien",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("Ký OTP"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v("Ký SIM CA"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 3 } }, [
                                _vm._v("Ký USB Token All"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 4 } }, [
                                _vm._v("Ký Plugin Edoc"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 5 } }, [
                                _vm._v("CLOUD_CA_VIETTEL"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 6 } }, [
                                _vm._v("CLOUD_CA_VNPT"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "dts-relative" }, [
                      _c("div", { staticClass: "dts-absolute" }, [
                        _c("p", [_vm._v("Hiệu trưởng")]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "dts-radio" },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.kieuKyHieuTruong,
                                callback: function ($$v) {
                                  _vm.kieuKyHieuTruong = $$v
                                },
                                expression: "kieuKyHieuTruong",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("Ký OTP"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v("Ký SIM CA"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 3 } }, [
                                _vm._v("Ký USB Token All"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 4 } }, [
                                _vm._v("Ký Plugin Edoc"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 5 } }, [
                                _vm._v("CLOUD_CA_VIETTEL"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 6 } }, [
                                _vm._v("CLOUD_CA_VNPT"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.suaTruong,
                      expression: "suaTruong",
                    },
                  ],
                  attrs: { gutter: 24 },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("label", [_vm._v("MobileCa")]),
                      _vm._v(" "),
                      _c("el-input", {
                        attrs: {
                          size: "mini",
                          placeholder: "Nhập",
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.thongTinTruong.mobileCA,
                          callback: function ($$v) {
                            _vm.$set(_vm.thongTinTruong, "mobileCA", $$v)
                          },
                          expression: "thongTinTruong.mobileCA",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("label", [_vm._v("mobileOTP")]),
                      _vm._v(" "),
                      _c("el-input", {
                        attrs: {
                          size: "mini",
                          placeholder: "Nhập",
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.thongTinTruong.mobileOTP,
                          callback: function ($$v) {
                            _vm.$set(_vm.thongTinTruong, "mobileOTP", $$v)
                          },
                          expression: "thongTinTruong.mobileOTP",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("label", [_vm._v("Mật khẩu")]),
                      _vm._v(" "),
                      _c("el-input", {
                        attrs: {
                          size: "mini",
                          placeholder: "Nhập",
                          autocomplete: "off",
                          "show-password": "",
                        },
                        model: {
                          value: _vm.thongTinTruong.passwordDemo,
                          callback: function ($$v) {
                            _vm.$set(_vm.thongTinTruong, "passwordDemo", $$v)
                          },
                          expression: "thongTinTruong.passwordDemo",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("label", [_vm._v("Path chữ ký")]),
                      _vm._v(" "),
                      _c("el-input", {
                        attrs: {
                          size: "mini",
                          placeholder: "Nhập",
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.thongTinTruong.pathChuKyDemo,
                          callback: function ($$v) {
                            _vm.$set(_vm.thongTinTruong, "pathChuKyDemo", $$v)
                          },
                          expression: "thongTinTruong.pathChuKyDemo",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("label", [_vm._v("Thời hạn")]),
                      _vm._v(" "),
                      _c("el-input", {
                        attrs: {
                          size: "mini",
                          placeholder: "Nhập",
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.thongTinTruong.thoiHanDemo,
                          callback: function ($$v) {
                            _vm.$set(_vm.thongTinTruong, "thoiHanDemo", $$v)
                          },
                          expression: "thongTinTruong.thoiHanDemo",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("label", [_vm._v("QLNT")]),
                      _vm._v(" "),
                      _c("eselect", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          size: "mini",
                          collapseTags: "",
                          placeholder: "Chọn",
                          filterable: "",
                          data: _vm.list_qlnt,
                          fields: ["name", "value"],
                        },
                        model: {
                          value: _vm.thongTinTruong.qlnt,
                          callback: function ($$v) {
                            _vm.$set(_vm.thongTinTruong, "qlnt", $$v)
                          },
                          expression: "thongTinTruong.qlnt",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("label", [_vm._v("Tài khoản CSDLN")]),
                      _vm._v(" "),
                      _c("el-input", {
                        attrs: {
                          size: "mini",
                          placeholder: "Nhập",
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.thongTinTruong.usernameCSDLN,
                          callback: function ($$v) {
                            _vm.$set(_vm.thongTinTruong, "usernameCSDLN", $$v)
                          },
                          expression: "thongTinTruong.usernameCSDLN",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("label", [_vm._v("Mật khẩu CSDLN")]),
                      _vm._v(" "),
                      _c("el-input", {
                        attrs: {
                          size: "mini",
                          placeholder: "Nhập",
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.thongTinTruong.passwordCSDLN,
                          callback: function ($$v) {
                            _vm.$set(_vm.thongTinTruong, "passwordCSDLN", $$v)
                          },
                          expression: "thongTinTruong.passwordCSDLN",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "padding-top": "16px" },
                      attrs: { span: 12 },
                    },
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.thongTinTruong.demo,
                            callback: function ($$v) {
                              _vm.$set(_vm.thongTinTruong, "demo", $$v)
                            },
                            expression: "thongTinTruong.demo",
                          },
                        },
                        [_vm._v("demo")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          _vm.hien_thi_chinh_sua_kieu_ky = false
                        },
                      },
                    },
                    [_vm._v("Đóng")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "primary" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.confimChinhSuaCauHinhKy($event)
                        },
                      },
                    },
                    [_vm._v("Chỉnh sửa")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex flex-wrap" }, [
      _c("div", { staticClass: "d-inline-block pl-3 pr-3 pb-3" }, [
        _c("h5", { staticClass: "font-weight-bold m-0" }, [
          _vm._v("Danh Sách Sở Giáo dục và Đào tạo"),
        ]),
      ]),
      _vm._v(" "),
      _c("div"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "file--title" }, [
      _c("h5", { staticClass: "title" }, [_vm._v("Danh sách cấu hình")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }