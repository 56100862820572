var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page page-file file detailHocBa" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading.fullscreen.lock",
              value: _vm.fullscreenLoading,
              expression: "fullscreenLoading",
              modifiers: { fullscreen: true, lock: true },
            },
          ],
          attrs: {
            "element-loading-text": "Đang tải dữ liệu...",
            "element-loading-spinner": "el-icon-loading",
            "element-loading-background": "rgba(0, 0, 0, 0.8)",
          },
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page__header" },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 12, sm: 12, md: 6, lg: 4 } },
                    [
                      _c("label", [_vm._v("Năm học:")]),
                      _vm._v(" "),
                      _c("eselect", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          collapseTags: "",
                          placeholder: "Chọn",
                          filterable: "",
                          data: _vm.list_nam_hoc,
                          fields: ["name", "value"],
                        },
                        model: {
                          value: _vm.searchForm.namHoc,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "namHoc", $$v)
                          },
                          expression: "searchForm.namHoc",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xs: 12, sm: 12, md: 6, lg: 4 } },
                    [
                      _c("label", [_vm._v("Cấp học:")]),
                      _vm._v(" "),
                      _c("eselect", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          disabled: _vm.list_cap_hoc.length == 1,
                          collapseTags: "",
                          placeholder: "Chọn",
                          filterable: "",
                          data: _vm.list_cap_hoc,
                          fields: ["name", "value"],
                        },
                        model: {
                          value: _vm.searchForm.capHoc,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "capHoc", $$v)
                          },
                          expression: "searchForm.capHoc",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xs: 12, sm: 12, md: 6, lg: 4 } },
                    [
                      _c("div", { staticStyle: { color: "transparent" } }, [
                        _vm._v("Tìm kiếm"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "m-0",
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.getData()
                            },
                          },
                        },
                        [_vm._v("Tìm kiếm\n            ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page__content" },
        [
          _c(
            "el-table",
            {
              ref: "listGiaoVien",
              attrs: {
                border: "",
                fit: "",
                lazy: true,
                data: _vm.list_data ? _vm.list_data : [],
                width: "100%",
              },
            },
            [
              _c("el-table-column", {
                staticStyle: { width: "50px" },
                attrs: {
                  label: "STT",
                  width: "80",
                  type: "index",
                  "class-name": "text-center",
                  index: _vm.indexMethod,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "Năm học",
                  "header-align": "center",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _c("p", [
                            _vm._v(
                              _vm._s(scope.row.namHoc) +
                                "-" +
                                _vm._s(scope.row.namHoc + 1)
                            ),
                          ]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "Cấp học",
                  property: "capHoc",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "Khối học",
                  property: "khoiHoc",
                  "class-name": "text-center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "Người ký",
                  "header-align": "center",
                  align: "left",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.giaoVienPhanCong
                          ? _c("div", [
                              scope.row.giaoVienPhanCong.tenGV
                                ? _c("p", [
                                    _vm._v(
                                      "Họ tên: " +
                                        _vm._s(scope.row.giaoVienPhanCong.tenGV)
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.giaoVienPhanCong.chucVu
                                ? _c("p", [
                                    _vm._v(
                                      "Chức vụ:\n                " +
                                        _vm._s(
                                          scope.row.giaoVienPhanCong.chucVu == 1
                                            ? "Hiệu trưởng"
                                            : "Phó hiệu trưởng"
                                        )
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "Tiêu đề ký",
                  property: "tieuDeXacNhan",
                  "class-name": "text-center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "Hậu tố sổ đăng bộ",
                  property: "hauToSoDangBo",
                  "class-name": "text-center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "Hành động",
                  "header-align": "center",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "warning" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.showUpdate(scope.row)
                                  },
                                },
                              },
                              [_vm._v("Chỉnh sửa\n              ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Cập nhật thông tin trang bìa",
            width: "50%",
            center: "",
            "custom-class": "thongTinKyTheoMonHoc",
            "append-to-body": "",
            "show-close": true,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            visible: _vm.show_update,
          },
          on: {
            "update:visible": function ($event) {
              _vm.show_update = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("label", [_vm._v("Hậu tố sổ đăng bộ:\n          ")]),
                  _vm._v(" "),
                  _c("el-input", {
                    attrs: { type: "text", placeholder: "Nhập..." },
                    model: {
                      value: _vm.infoKy.hauToSoDangBo,
                      callback: function ($$v) {
                        _vm.$set(_vm.infoKy, "hauToSoDangBo", $$v)
                      },
                      expression: "infoKy.hauToSoDangBo",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("label", [
                    _vm._v("Người ký: "),
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("*"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("eselect", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      collapseTags: "",
                      placeholder: "Chọn",
                      filterable: "",
                      data: _vm.list_giao_vien_ky,
                      fields: ["name", "value"],
                    },
                    model: {
                      value: _vm.infoKy.giaoVien,
                      callback: function ($$v) {
                        _vm.$set(_vm.infoKy, "giaoVien", $$v)
                      },
                      expression: "infoKy.giaoVien",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { staticStyle: { "padding-top": "15px" }, attrs: { span: 12 } },
                [
                  _c(
                    "label",
                    [
                      _vm._v("Tiêu đề ký: "),
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(" "),
                      _c("el-tooltip", { attrs: { placement: "top" } }, [
                        _c(
                          "div",
                          { attrs: { slot: "content" }, slot: "content" },
                          [
                            _vm._v("Ghi rõ: Chức danh ký hoặc ký thay"),
                            _c("br"),
                            _vm._v(
                              "\n                Ví dụ: Hiệu trưởng\n                "
                            ),
                            _c("br"),
                            _vm._v(
                              "\n                KT Hiệu trưởng\n                "
                            ),
                            _c("br"),
                            _vm._v(
                              "\n                Phó hiệu trưởng\n              "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("small", [
                          _c("i", { staticStyle: { color: "blue" } }, [
                            _vm._v("Hướng dẫn"),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-input", {
                    staticClass: "d-flex",
                    staticStyle: { height: "40px !important" },
                    attrs: { type: "textarea", placeholder: "Nhập..." },
                    model: {
                      value: _vm.infoKy.tieuDeXacNhan,
                      callback: function ($$v) {
                        _vm.$set(_vm.infoKy, "tieuDeXacNhan", $$v)
                      },
                      expression: "infoKy.tieuDeXacNhan",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.show_update = false
                    },
                  },
                },
                [_vm._v("Đóng")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "warning" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.confirmUpdateData()
                    },
                  },
                },
                [_vm._v("Chỉnh sủa")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page__title" }, [
      _c("h1", { staticClass: "title" }, [_vm._v("Cấu hình ký bìa")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "file--title" }, [
      _c("h5", { staticClass: "title" }, [_vm._v("Danh sách cấu hình")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }