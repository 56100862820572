<template>
    <div id="listConfig" class="page page-file file detailHocBa" v-loading.fullscreen.lock="fullscreenLoading">
        <div class="box-upload-file">
            <div class="d-flex flex-wrap">
                <div class="d-inline-block pl-3 pr-3 pb-3">
                    <h5 class="font-weight-bold m-0">Cấu hình ký học bạ</h5>
                </div>
                <div>

                </div>
            </div>
            <div class="pl-3">
                Năm học:
                <!--                <el-date-picker style="width: 150px" v-model="year" type="year" placeholder="Năm học"-->
                <!--                                value-format="yyyy">-->
                <!--                </el-date-picker>-->
                <SelectNamHoc v-model="year"/>
                <el-button type="primary" @click="submitSearch()">Tìm kiếm</el-button>
            </div>
        </div>
        <div class="">
            <div class="file--title">
                <div class="">
                    <h5 class="title">
                        Chi tiết cấu hình
                    </h5>
                </div>
                <el-button type="primary" @click="showSetting=true" v-if="this.user.role==7" size="mini">Cấu hình thời
                    gian
                </el-button>
            </div>
        </div>
        <div class="page__content">
            <el-card shadow="never" class="mb-3">
                <div class="mt-2 mb-2" v-if="listConfigKy">
                    <div>Tên đơn vị : {{ listConfigKy.tenSo }}</div>
                    <div>Năm học : {{ listConfigKy.strNamHoc }}</div>
                    <div><label>Ngày chốt ký học bạ:</label>
                        <span v-if="!editSo">{{ new Date(listConfigKy.thoiGian) | moment("DD/MM/YYYY") }}</span>
                        <span v-if="editSo">
                         <el-date-picker v-if="" :picker-options="pickerOptions" style="width: 150px"
                                         v-model="ngayChotSo" format="dd/MM/yyyy" type="date"
                                         placeholder="Ngày chốt ký" value-format="timestamp">
                         </el-date-picker>
                        <el-button type="success" @click="" size="mini" title="Xác nhận" v-on:click="updateSoAction"
                                   icon="el-icon-check"></el-button>
                        <el-button type="danger" icon="el-icon-close" @click="" size="mini" title="Hủy"
                                   v-on:click="editSo = false"></el-button>
                    </span>
                        <el-button v-if="!editSo && this.user.role == 7" type="primary" @click="" size="mini"
                                   title="Sửa ngày chốt"
                                   v-on:click="editSo = true, ngayChotSo = listConfigKy.thoiGian"><i
                            class="el-icon-edit"></i></el-button>
                    </div>
                </div>
            </el-card>


            <el-table
                v-if="listConfigKy"
                class="configSo--phong"
                border
                height="600"
                fit stripe
                row-key="maPhong"
                :data="listConfigKy.phongGiaoDucCauHinhs">
                <el-table-column type="expand">
                    <template slot-scope="props">
                        <el-table
                            class="configSo--truong"
                            border
                            fit
                            height="300"
                            :data="props.row.truongHocCauHinhs">
                            <el-table-column label="STT" style="width: 60px" width="60" type="index"
                                             class-name="text-center"
                                             :index="indexMethod">
                            </el-table-column>
                            <el-table-column
                                label="Tên Phòng GD"
                                property="tenPhong"
                                width="400"
                            >
                            </el-table-column>
                            <el-table-column
                                label="Mã trường"
                                property="maTruongHoc"
                                width="200"
                            >
                            </el-table-column>
                            <el-table-column
                                label="Tên trường học"
                                property="tenTruongHoc"
                                width="400"
                            >
                            </el-table-column>
                            <el-table-column
                                label="Ngày chốt ký"
                            >
                                <template slot-scope="scope">
                                    <UpdateNgayChot @updateChild="fullscreenLoading=true" :type="2"
                                                    :id="listConfigKy.id" :info="scope.row"></UpdateNgayChot>
                                </template>
                            </el-table-column>

                        </el-table>

                    </template>
                </el-table-column>
                <el-table-column label="STT" style="width: 60px" width="60" type="index" class-name="text-center"
                                 :index="indexMethod">
                </el-table-column>
                <el-table-column
                    label="Tên Phòng GD"
                    property="tenPhong"
                    width="400"
                >
                </el-table-column>
                <el-table-column
                    label="Mã trường học"
                    width="200"

                >
                </el-table-column>
                <el-table-column
                    label="Tên trường học"
                    width="400"

                >
                </el-table-column>
                <el-table-column
                    label="Ngày chốt ký"

                >
                    <template slot-scope="scope">
                        <UpdateNgayChot @updateChild="fullscreenLoading=true" :type="1" :id="listConfigKy.id"
                                        :info="scope.row"></UpdateNgayChot>
                    </template>
                </el-table-column>

            </el-table>
        </div>
        <el-dialog
            center
            class="showSetting"
            title="Cấu hình lịch chốt ký"
            :visible.sync="showSetting">
            <div>
                <el-form :model="createData" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
                    <el-form-item label="Năm học" prop="namHocCreate">
                        <SelectNamHoc v-model="createData.namHocCreate"/>
                    </el-form-item>

                    <el-form-item label="Thời hạn chốt ký:" prop="ngayChotKy">
                        <el-date-picker v-if="" :picker-options="pickerOptions"
                                        v-model="createData.ngayChotKy" format="dd/MM/yyyy" type="date"
                                        placeholder="Ngày chốt ký" value-format="timestamp">
                        </el-date-picker>
                    </el-form-item>
                </el-form>

            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showSetting = false">Hủy</el-button>
                <el-button type="primary" @click="createConfigAction('ruleForm')">Xác nhận</el-button>
              </span>
        </el-dialog>
    </div>
</template>

<script>

    import {mapState, mapActions, mapMutations} from 'vuex';
    import dialog from "../../dialog";
    import UpdateNgayChot from "./UpdateNgayChot";
    import SelectNamHoc from "../../components/SelectNamHoc";

    export default {
        name: "DanhSachCauHinh",
        components: {
            UpdateNgayChot,
            SelectNamHoc
        },
        data() {
            return {
                fullscreenLoading: true,
                year: '',
                editSo: false,
                ngayChotSo: '',

                pickerOptions: {
                    disabledDate(time) {
                        var date = new Date();
                        date.setDate(date.getDate() - 3)

                        return time.getTime() < date;
                    },
                },
                openItem: '',
                showSetting: false,

                createData: {
                    namHocCreate: '',
                    ngayChotKy: '',
                },
                rules: {
                    ngayChotKy: [
                        {required: true, message: 'Chưa chọn ngày chốt ký', trigger: 'change'}
                    ],
                    namHocCreate: [
                        {required: true, message: 'Chưa chọn năm học', trigger: 'change'}
                    ],
                }
            }
        },
        computed: {
            ...mapState("account", [
                "user"
            ]),
            ...mapState("configKyModule", [
                "listConfigKy",
                "updateStatus",
                "createStatus"
            ]),
        },
        methods: {
            ...mapActions("configKyModule", [
                "getConfigKy",
                "updateConfig",
                "createConfig"
            ]),
            ...mapMutations("configKyModule", [
                "setOpenID"
            ]),
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            createConfigAction(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.fullscreenLoading = true
                        this.showSetting = false;
                        this.createConfig({
                            maSo: this.user.maSo,
                            nguoiTao: this.user.displayName,
                            thoiGian: this.createData.ngayChotKy,
                            namHoc: this.createData.namHocCreate
                        })
                    } else {
                        return false;
                    }
                });
            },

            updateChild() {
                this.fullscreenLoading = true;
            },

            spanPgd({row, column, rowIndex, columnIndex}) {
                console.log(row);
                if (columnIndex === 1) {
                    if (rowIndex % row.tongSoTruong === 0) {
                        return {
                            rowspan: row.tongSoTruong,
                            colspan: 1
                        };
                    } else {
                        return {
                            rowspan: 0,
                            colspan: 0
                        };
                    }
                }
            },
            indexMethod(index) {
                return (index) + 1;
            },
            getFullYear() {
                let d = new Date();
                return d.getFullYear();
            },
            submitSearch() {
                this.getConfigKy({
                    maSo: this.user.maSo,
                    namHoc: this.year
                })
            },
            updateSoAction() {
                if (!this.ngayChotSo) {
                    dialog.showDialog('Thông báo chưa chọn ngày chốt cho sở');
                    return;
                }
                this.fullscreenLoading = true;
                this.listConfigKy.thoiGian = this.ngayChotSo;
                this.listConfigKy.nguoiTao = this.user.displayName;
                console.log(this.listConfigKy)
                this.updateConfig(this.listConfigKy);
            }
        },
        async mounted() {
            this.year = this.getFullYear();

            this.namHocCreate = this.getFullYear();

            let maPhong = '';
            if (this.user.role == 6)
                maPhong = this.user.maPhong;

            await this.getConfigKy({
                maSo: this.user.maSo,
                namHoc: this.year,
                maPhong: maPhong
            });
            this.fullscreenLoading = false;
        },
        watch: {
            updateStatus(val) {
                if (val) {
                    this.fullscreenLoading = false;
                    this.setOpenID(null);

                    let maPhong = '';
                    if (this.user.role == 6)
                        maPhong = this.user.maPhong;


                    this.getConfigKy({
                        maSo: this.user.maSo,
                        namHoc: this.year,
                        maPhong: maPhong
                    });
                    this.editSo = false;
                    dialog.showDialog('Thông báo', val.msg, () => {

                    })
                }
            },
            createStatus(val) {
                if (val) {
                    this.fullscreenLoading = false;
                    this.resetForm('ruleForm');
                    this.getConfigKy({
                        maSo: this.user.maSo,
                        namHoc: this.year
                    });
                    dialog.showDialog('Thông báo', val.msg, () => {

                    })
                }
            }
        }
    }
</script>

<style scoped>

</style>
