var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        _vm.layout,
        { tag: "component" },
        [
          _c(
            "transition",
            { attrs: { name: "fade", mode: "out-in" } },
            [_c("router-view", { key: _vm.$route.fullPath })],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Thông báo",
            width: "30%",
            center: "",
            visible: _vm.show_redirect,
          },
          on: {
            "update:visible": function ($event) {
              _vm.show_redirect = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "demo-image__lazy" },
            [
              _c("p", [_vm._v(_vm._s(_vm.textMes))]),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-row",
                    { staticClass: "text-center pt-3", attrs: { span: 24 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "success" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.RedirectPageInfo()
                            },
                          },
                        },
                        [_vm._v("Cập nhật")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }