<template>
  <div id="detailHocBa" class="page page-file file detailHocBa">
    <div class="box-upload-file">
      <div class="d-flex flex-wrap">
        <div class="d-inline-block pl-3 pr-3 pb-3">
          <h5 class="font-weight-bold m-0">Tool mở trường</h5>
        </div>
      </div>
    </div>
    <el-row :gutter="24">
      <el-col :span="24">
        <label for="">Hành động</label>
        <eselect style="width:100%" collapseTags v-model="optionAction" placeholder="Chọn" filterable :data="list_action" :fields="['name','value']"/>
      </el-col>
      <el-col :span="24">
        <el-upload ref="uploadBia" class="upload-demo text-center" drag action="/"
                   :file-list="fileList"
                   :before-upload="beforeUpload"
                   :on-change="uploadFile" :on-remove="uploadFile" accept="'.pdf,.xlsx,.xls'"
                   :auto-upload="false">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">Kéo thả hoặc <em>click ở đây</em></div>
          <!--          <div class="el-upload__tip" slot="tip">-->
          <!--         -->
          <!--          </div>-->
        </el-upload>
      </el-col>
      <el-col :span="24" class="text-center">
        <el-button type="primary" :disabled="!this.base64Upload||!this.optionAction" @click.prevent="uploadFileToSv">Tải lên</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {
  mapActions,
  mapState
} from 'vuex';
import ESelectVue from "./Ui/ESelect";
import constant_api from "../_helpers/constant_api";
import api from "../_helpers/api";
import constant from "../_helpers/constant_api";
import eselect from "./Ui/ESelect.vue";


export default {
  name: "ToolMoTruong",
  metaInfo: {
    title: 'Tool mở truờng',
    titleTemplate: '',
    meta: [{
      vmid: 'description',
      name: 'description',
      content: ''
    }]
  },
  components: {eselect},
  computed: {

    ...mapState('account', [
      'user',
      'list_nam_hoc'
    ])
  },
  data() {
    return {
      optionAction:1,
      list_action:[
        {name:'Mở trường',value:1},
        {name:'Khóa trường',value:2},
      ],
      fileList: [],
      base64Upload: null
    }
  },
  methods: {
    beforeUpload() {
      console.log('beforeUpload')
      this.fileList = [];
      fullscreenLoading:false
    },
    uploadFile(file, fileList) {
      console.log('uploadFile')
      console.log(fileList)
      console.log('fileList:')
      console.log('file:')
      console.log(file)
      this.fileList = JSON.parse(JSON.stringify([fileList[fileList.length - 1]]))
      this.base64Upload = null;
      this.$refs.uploadBia.clearFiles();
      // console.log(this.fileList)
      let allowedExtensions = /(\.xlsx|\.xls)$/i;
      if (!allowedExtensions.exec(file.name)) {
        this.$alert('Vui lòng chỉ tải lên file định dạng excel', 'Thông báo', {
          confirmButtonText: 'Đóng',
          type: 'error',
          callback: action => {
            this.$refs.uploadBia.clearFiles();
          }
        });
        return false;
      }
      let reader = new FileReader()
      reader.readAsDataURL(file.raw);
      reader.onload = (e) => {
        let data;
        if (typeof e.target.result === 'object') {
          data = window.URL.createObjectURL(new Blob([e.target.result]))
        } else {
          data = e.target.result
        }
        console.log('data upload:')
        console.log(data)
        this.base64Upload = data;
      }
    },
    uploadFileToSv() {
      console.log(this.base64Upload)
      let url = constant.import.moTruongBangFile;
      let params = {
        data:this.base64Upload,
        option:this.optionAction
      }
      api.post(url, params).then(data => {
        this.fullscreenLoading = false;
        if (data.data.code === 200) {
          this.thongBao('success', data.data.msg)
        } else {
          this.thongBao('error', data.data.msg)
        }
      }).catch((e) => {
        this.fullscreenLoading = false;
        this.thongBao('error', "Lỗi kết nối API");
      })
    },

    thongBao(t, e) {
      let msg = "";
      let cl = "";
      if (e) {
        msg = e;
      }
      let type = "success";
      if (t) {
        type = t
      }
      if (type == "success") {
        cl = "dts-noty-success"
      }
      if (type == "warning") {
        cl = "dts-noty-warning"
      }
      if (type == "error") {
        cl = "dts-noty-error"
      }
      if (type == "info") {
        cl = "dts-noty-info"
      }
      this.$message({
        customClass: cl,
        showClose: true,
        message: msg,
        type: t,
        duration: 5000
      });
    },
  },
  mounted() {

  },
}
</script>

<style scoped>

</style>