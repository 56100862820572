<template>
  <div>
    <div ref="pdfViewer">
      <iframe style="width: 100%; height: 100vh; min-height: 600px" :src="urlPdf"></iframe>
    </div>
  </div>
</template>
<script>
import jsPDF from 'jspdf';
import {times} from './../../../../../fonts/times-normal';
import {timesB} from './../../../../../fonts/times-bold';
import 'jspdf-autotable';

export default {
  props: ['item'],
  data() {
    return {
      thongTinHocBaBia: {
        anhTuQLNT: '',
        quaTrinhHocTaps: [
          {namHoc: '', tenLop: '', tenTruong: ''}
        ],

      },
      thongTinKqht: [],
      dataForm: {
        schoolName: ''
      },
      urlPdf: ''
    }
  },
  mounted() {
    console.log('mounted view xem học bạ:')
    console.log(JSON.stringify(this.item))
    this.generatePDF();
    // if (this.item)
    //   this.thongTinHocBaBia = this.item.BIA;
    // this.thongTinKqht = this.item.KQHT;
    // console.log('thongTinKqht')
    // console.log(this.thongTinKqht)
  },
  methods: {
    generatePDF() {
      console.log('generatePDF')
      // Tạo một đối tượng jsPDF với kích thước giấy A4 khổ dọc (210mm x 297mm)
      const doc = new jsPDF('p', 'mm', 'a4');

      doc.addFileToVFS("Times-normal.ttf", times);
      doc.addFileToVFS("Times-bold.ttf", timesB);
      doc.addFont("Times-normal.ttf", "VNTimes", "normal");
      doc.addFont("Times-bold.ttf", "TimesBold", "normal");
      doc.addFont("Times-italic.ttf", "VNTimes", "italic");
      // Tạo đường viền cho trang đầu tiên

      doc.setFontSize(12);
      doc.setFont("VNTimes")

      // Tạo nội dung PDF với ký tự UTF-8
      //
      // // Đổ dữ liệu từ file JSON vào từng trang
      // for (let i = 0; i < 5; i++) {
      //   if (i > 0) {
      //     doc.addPage();
      //   }
      //
      //   // Đổ dữ liệu từ file JSON
      //   const data = jsonData[i].content;
      //   doc.text(data, 20, 20);
      // }
      // Số trang cần tạo
      const totalPages = 5;
      // Vòng lặp để tạo nội dung trên từng trang
      for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
        // Thêm nội dung vào trang hiện tại
        this.addContentToPage(doc, pageNumber);
        // Kiểm tra nếu không phải trang cuối cùng, thêm một trang mới
        if (pageNumber < totalPages) {
          doc.addPage();
        }
      }

      // Chuyển đổi tài liệu PDF thành dữ liệu URL
      const pdfDataUrl = doc.output('dataurl');

      // Hiển thị PDF trên giao diện
      this.displayPDF(pdfDataUrl);
    },
    addContentToPage(doc, pageNumber) {
      console.log('addContentToPage' + pageNumber)
      let pageWidth = doc.internal.pageSize.getWidth();
      if (pageNumber == 1) {
        //Gắn dữ liệu cho trang 1
        doc.setLineWidth(1);
        doc.text('TRƯỜNG: PHỔ THÔNG DTNT THCS & THPT YÊN LẬP', 20, 20);
        doc.text('Huyện/Quận/Thị xã/Thành phố: Yên Lập', 20, 30);
        doc.text('Tỉnh/Thành phố: Phú Thọ', 20, 40);
        let textHocBa = "HỌC BẠ";
        let fontSize = 90;
        let textWidth = doc.getStringUnitWidth(textHocBa) * fontSize / doc.internal.scaleFactor;
        doc.setFontSize(fontSize);
        doc.text(textHocBa, (pageWidth - textWidth) / 2, 110);
        // Cấp học học bạ
        textHocBa = "TRUNG HỌC CƠ SỞ";
        fontSize = 20;
        doc.setFontSize(fontSize);
        textWidth = doc.getStringUnitWidth(textHocBa) * fontSize / doc.internal.scaleFactor;
        doc.text(textHocBa, (pageWidth - textWidth) / 2, 125);
        // Họ tên học sinh
        textHocBa = "Họ và tên học sinh";
        fontSize = 14;
        doc.setFontSize(fontSize);
        textWidth = doc.getStringUnitWidth(textHocBa) * fontSize / doc.internal.scaleFactor;
        doc.text(textHocBa, (pageWidth - textWidth) / 2, 190);
        // Họ tên học sinh
        textHocBa = "PHÙNG THỊ PHƯƠNG ANH";
        fontSize = 14;
        doc.setFontSize(fontSize);
        textWidth = doc.getStringUnitWidth(textHocBa) * fontSize / doc.internal.scaleFactor;
        doc.text(textHocBa, (pageWidth - textWidth) / 2, 200);
        // Số sổ đăng bộ
        textHocBa = 'Sổ đăng bộ PCGD: 01/2021/THCS/THCS';
        fontSize = 16;
        doc.setFontSize(fontSize);
        textWidth = doc.getStringUnitWidth(textHocBa) * fontSize / doc.internal.scaleFactor;
        doc.text(textHocBa, (pageWidth - textWidth) / 2, 280);

        doc.rect(10, 10, doc.internal.pageSize.width - 20, doc.internal.pageSize.height - 20);
      } else if (pageNumber == 2) {
        let textHocBa = "HƯỚNG DẪN SỬ DỤNG HỌC BẠ";
        let fontSize = 20;
        let textWidth = doc.getStringUnitWidth(textHocBa) * fontSize / doc.internal.scaleFactor;
        doc.setFontSize(fontSize);
        doc.text(textHocBa, (pageWidth - textWidth) / 2, 15);
        //   1. Quy định chung
        doc.setFontSize(13);
        let lines;
        let contentWidth = 190;

        doc.setFont("TimesBold")
        doc.text('1. Quy định chung', 10, 30);
        doc.setFont("VNTimes")
        lines = doc.splitTextToSize('- Học bạ học sinh được nhà trường quản lý và bảo quản trong trường; đầu năm học, cuối học kỳ, cuối năm học, được bàn giao cho giáo viên chủ nhiệm lớp để thực hiện việc ghi vào Học bạ và thu lại sau khi đã hoàn thành.', contentWidth); // Chia đoạn văn bản thành các dòng nhỏ
        doc.text(lines, 10, 40);
        lines = doc.splitTextToSize('- Nội dung trang 1 phải được ghi đầy đủ khi xác lập Học bạ; Hiệu trưởng ký, đóng dấu xác nhận quá trình học tập từng năm học từ lớp 6 đến lớp 9.', contentWidth); // Chia đoạn văn bản thành các dòng nhỏ
        doc.text(lines, 10, 58);
        // 2. Giáo viên môn học
        doc.setFont("TimesBold")
        doc.text('2. Giáo viên môn học', 10, 75);
        doc.setFont("VNTimes")
        lines = doc.splitTextToSize('- Ghi điểm trung bình môn học hoặc mức đánh giá kết quả học tập theo môn học từng học kì, cả năm học; nhận xét sự tiến bộ, ưu điểm nổi bật, hạn chế chủ yếu (nếu có) của học sinh..', contentWidth); // Chia đoạn văn bản thành các dòng nhỏ
        doc.text(lines, 10, 83);
        lines = doc.splitTextToSize('- Khi sửa chữa (nếu có), dùng bút mực đỏ gạch ngang nội dung cũ, ghi nội dung mới vào phía trên bên phải vị trí ghi nội dung cũ, ký xác nhận về việc sửa chữa bên cạnh nội dung đã sửa.', contentWidth); // Chia đoạn văn bản thành các dòng nhỏ
        doc.text(lines, 10, 98);
        // 3. Giáo viên chủ nhiệm
        doc.setFont("TimesBold")
        doc.text('3. Giáo viên môn học', 10, 115);
        doc.setFont("VNTimes")
        lines = doc.splitTextToSize('- Tiếp nhận và bàn giao lại Học bạ học sinh với văn phòng nhà trường.', contentWidth); // Chia đoạn văn bản thành các dòng nhỏ
        doc.text(lines, 10, 123);
        lines = doc.splitTextToSize('- Đôn đốc việc ghi vào Học bạ điểm trung bình môn học hoặc mức đánh giá kết quả học tập của học sinh của giáo viên môn học', contentWidth); // Chia đoạn văn bản thành các dòng nhỏ
        doc.text(lines, 10, 130);
        lines = doc.splitTextToSize('- Ghi đầy đủ các nội dung trên trang 1, nội dung ở phần đầu các trang tiếp theo, nhận xét kết quả rèn luyện và học tập của học sinh theo từng năm học.', contentWidth - 2); // Chia đoạn văn bản thành các dòng nhỏ
        doc.text(lines, 10, 143);
        lines = doc.splitTextToSize('- Ghi kết quả tổng hợp đánh giá; mức đánh giá lại môn học hoặc rèn luyện thêm trong kì nghỉ hè (nếu có); đánh giá mức độ hoàn thành nhiệm vụ đối với học sinh phải rèn luyện thêm trong kì nghỉ hè (nếu có).', contentWidth - 2); // Chia đoạn văn bản thành các dòng nhỏ
        doc.text(lines, 10, 156);
        lines = doc.splitTextToSize('- Ghi nhận xét sự tiến bộ, ưu điểm nổi bật, hạn chế chủ yếu và những biểu hiện nổi bật của học sinh trong quá trình rèn luyện và học tập; những vấn đề cần quan tâm giúp đỡ thêm trong quá trình rèn luyện và học tập.', contentWidth - 2); // Chia đoạn văn bản thành các dòng nhỏ
        doc.text(lines, 10, 176);
        //   4. Hiệu trưởng
        doc.setFont("TimesBold")
        doc.text('4. Hiệu trưởng', 10, 195);
        doc.setFont("VNTimes")
        lines = doc.splitTextToSize('- Phê duyệt Học bạ của học sinh khi kết thúc năm học.', contentWidth); // Chia đoạn văn bản thành các dòng nhỏ
        doc.text(lines, 10, 203);
        lines = doc.splitTextToSize('- Kiểm tra việc quản lý, bảo quản, ghi Học bạ.', contentWidth); // Chia đoạn văn bản thành các dòng nhỏ
        doc.text(lines, 10, 210);

      } else if (pageNumber == 3) {

        doc.setFont("TimesBold")
        let fontSize = 13;
        let textHocBa = "CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM";
        let textWidth = doc.getStringUnitWidth(textHocBa) * fontSize / doc.internal.scaleFactor;
        doc.setFontSize(fontSize);
        doc.text(textHocBa, (pageWidth - textWidth) / 2, 12);
        fontSize = 13;
        textHocBa = "Độc lập - Tự do - Hạnh phúc";
        textWidth = doc.getStringUnitWidth(textHocBa) * fontSize / doc.internal.scaleFactor;
        doc.setFontSize(fontSize);
        doc.text(textHocBa, (pageWidth - textWidth) / 2, 18);

        fontSize = 40;
        doc.setFontSize(fontSize);
        textHocBa = "HỌC BẠ";
        textWidth = doc.getStringUnitWidth(textHocBa) * fontSize / doc.internal.scaleFactor;
        doc.text(textHocBa, (pageWidth - textWidth) / 2, 45);

        fontSize = 18;
        doc.setFontSize(fontSize);
        textHocBa = "TRUNG HỌC CƠ SỞ";
        textWidth = doc.getStringUnitWidth(textHocBa) * fontSize / doc.internal.scaleFactor;
        doc.text(textHocBa, (pageWidth - textWidth) / 2, 55);

        fontSize = 13;
        doc.setFontSize(fontSize);

        doc.setFont("VNTimes")
        doc.text('Họ và tên:', 15, 85);
        doc.setFont("TimesBold")
        doc.text('PHÙNG THỊ PHƯƠNG ANH', 45, 85);
        doc.setFont("VNTimes")
        doc.text('Giới tính:', 130, 85);
        doc.text('Nữ', 150, 85);

        doc.text('Ngày sinh:', 15, 93);
        doc.text('27/02/2006', 45, 93);
        doc.text('Dân tộc:', 130, 93);
        doc.text('Dao', 150, 93);

        doc.text('Nơi sinh:', 15, 101);
        doc.text('Bệnh viện Yên Lập', 45, 101);

        doc.text('Đối tượng (Con liệt sĩ, con thương binh,...):', 15, 109);
        doc.text('Có cha mẹ thuộc diện hộ nghèo theo quy định', 100, 109);

        doc.text('Chỗ ở hiện tại:', 15, 117);
        doc.text('Khu Bằng-Trung Sơn - Yên Lập - Phú Thọ', 45, 117);

        doc.text('Họ và tên cha:', 15, 125);
        doc.text('Phùng Văn Thọ', 45, 125);
        doc.text('Nghề nghiệp:', 130, 125);
        doc.text('Làm ruộng', 160, 125);

        doc.text('Họ và tên mẹ:', 15, 133);
        doc.text('Triệu Thị Hoa', 45, 133);
        doc.text('Nghề nghiệp:', 130, 133);
        doc.text('Làm ruộng', 160, 133);

        doc.text('Họ và tên người giám hộ:', 15, 141);
        doc.text('Triệu Thị Hoa', 65, 141);
        doc.text('Nghề nghiệp:', 130, 141);
        doc.text('Làm ruộng', 160, 141);

        doc.text('Yên Lập, ngày 15 tháng 7 năm 2023', 125, 150);
        doc.text('HIỆU TRƯỞNG', 145, 158);
        doc.text('(Ký, ghi rõ họ tên và đóng dấu)', 135, 166);
        doc.text('Nguyễn Thị Minh Nguyệt', 140, 210);

        fontSize = 13;
        textHocBa = "QUÁ TRÌNH HỌC TẬP";
        textWidth = doc.getStringUnitWidth(textHocBa) * fontSize / doc.internal.scaleFactor;
        doc.text(textHocBa, (pageWidth - textWidth) / 2, 230);
        //   Quá trình học tập

        let header = [
          [{content: 'Năm học', colSpan: 1}, {content: "Lớp", colSpan: 1}, {
            content: "Tên trường, tỉnh/thành phố",
            colSpan: 1
          }],
        ]
        let body = [
          [{content: '2021-2022', colSpan: 1}, {
            content: "1A",
            colSpan: 1
          }, {content: "Phổ Thông DTNT THCS & THPT Yên Lập - Phú Thọ", colSpan: 1}],
          [{content: '2022-2023', colSpan: 1}, {
            content: "2A",
            colSpan: 1
          }, {content: "Phổ Thông DTNT THCS & THPT Yên Lập - Phú Thọ", colSpan: 1}],
          [{content: '2023-2024', colSpan: 1}, {
            content: "3A",
            colSpan: 1
          }, {content: "Phổ Thông DTNT THCS & THPT Yên Lập - Phú Thọ", colSpan: 1}],
          [{content: '2024-2025', colSpan: 1}, {
            content: "4A",
            colSpan: 1
          }, {content: "Phổ Thông DTNT THCS & THPT Yên Lập - Phú Thọ", colSpan: 1}],
          [{content: '2025-2026', colSpan: 1}, {
            content: "5A",
            colSpan: 1
          }, {content: "Phổ Thông DTNT THCS & THPT Yên Lập - Phú Thọ", colSpan: 1}],
        ]

        let columnStyles = {
          0: {
            halign: 'center',
            valign: 'middle',
            fontSize: 13,
            cellWidth: 40,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1}
          },
          1: {
            halign: 'center',
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: 'middle',
            cellWidth: 20
          },
          2: {halign: 'left', fontSize: 13, cellPadding: {top: 1, right: 1, bottom: 1, left: 10}, valign: 'middle'},
        }
        doc.autoTable({
          tableLineColor: [70, 76, 70],
          columnStyles: columnStyles,
          head: header,
          body: body,
          startY: 235,
          theme: 'grid',
          headStyles: {
            fillColor: [255, 255, 255],
            textColor: [45, 45, 45],
            font: 'TimesBold',
            fontStyle: 'normal',
            lineWidth: 0.1,
            halign: 'center',
            valign: 'middle',
            fontSize: 13,
            cellPadding: 1
          },
          styles: {
            textColor: [45, 45, 45],
            lineColor: [70, 76, 70],
            font: 'TimesBold',
            fontStyle: 'normal',
          },
          bodyStyles: {
            textColor: [45, 45, 45],
            lineColor: [70, 76, 70],
            font: 'VNTimes',
            fontStyle: 'normal',
            cellPadding: 1,
            fontSize: 13
          }
        })

      } else if (pageNumber == 4) {

        const marginTop = 20; // 1,5cm
        const marginLeft = 14; // 2cm
        const marginRight = doc.internal.pageSize.getWidth() - 10; // 1cm từ lề phải
        const marginBottom = doc.internal.pageSize.getHeight() - 10; // 1cm từ lề dưới
        doc.setLineWidth(0.6);
        doc.line(marginLeft, marginTop, marginRight, marginTop); // Đường viền trên
        doc.line(marginLeft, marginTop, marginLeft, marginBottom); // Đường viền trái
        doc.line(marginRight, marginTop, marginRight, marginBottom); // Đường viền phải
        doc.line(marginLeft, marginBottom, marginRight, marginBottom); // Đường viền dưới

        doc.setFont("TimesBold")
        doc.setFontSize(13);
        doc.text("Họ và tên:    PHÙNG THỊ PHƯƠNG ANH", 15, 15);
        doc.text("Lớp:    6A", 120, 15);
        doc.text("Năm học:    2020-2021", 150, 15);


        let header = [
          [{content: 'Môn học/Hoạt động giáo dục', rowSpan: 2}, {
            content: "Điểm trung bình môn hoặc mức đánh giá",
            colSpan: 3
          }, {
            content: "Điểm trung bình môn học hoặc mức đánh giá lại, rèn luyện thêm trong kỳ nghỉ hè (nếu có)",
            rowSpan: 2
          }, {
            content: 'Nhận xét sự tiến bộ, ưu điểm nổi bật, hạn chế chủ yếu (nếu có) và chữ ký của giáo viên môn học',
            rowSpan: 2
          }],
          [{content: 'H Kỳ I', rowSpan: 1}, {content: 'H Kỳ II', rowSpan: 1}, {content: 'CN', rowSpan: 1},],
        ]
        let body = [
          [{content: 'Toán', colSpan: 1}, {content: "T", colSpan: 1}, {content: "7.3", colSpan: 1}, {
            content: "6.5",
            colSpan: 1
          }, {content: "", colSpan: 1}, {content: "Phạm Hương Lan", colSpan: 1}],
          [{content: 'Ngữ Văn', colSpan: 1}, {content: "T", colSpan: 1}, {content: "7.3", colSpan: 1}, {
            content: "6.5",
            colSpan: 1
          }, {content: "", colSpan: 1}, {content: "Phạm Hương Lan", colSpan: 1}],
          [{content: 'Tiếng Anh', colSpan: 1}, {content: "T", colSpan: 1}, {
            content: "7.3",
            colSpan: 1
          }, {content: "6.5", colSpan: 1}, {content: "", colSpan: 1}, {content: "Phạm Hương Lan", colSpan: 1}],
          [{content: 'GDCD', colSpan: 1}, {content: "T", colSpan: 1}, {content: "7.3", colSpan: 1}, {
            content: "6.5",
            colSpan: 1
          }, {content: "", colSpan: 1}, {content: "Phạm Hương Lan", colSpan: 1}],
          [{content: 'Lịch sử và Địa lý', colSpan: 1}, {content: "T", colSpan: 1}, {
            content: "7.3",
            colSpan: 1
          }, {content: "6.5", colSpan: 1}, {content: "", colSpan: 1}, {content: "Phạm Hương Lan", colSpan: 1}],
          [{content: 'Khoa học tự nhiên', colSpan: 1}, {content: "T", colSpan: 1}, {
            content: "7.3",
            colSpan: 1
          }, {content: "6.5", colSpan: 1}, {content: "", colSpan: 1}, {content: "Phạm Hương Lan", colSpan: 1}],
          [{content: 'Công nghệ', colSpan: 1}, {content: "T", colSpan: 1}, {
            content: "7.3",
            colSpan: 1
          }, {content: "6.5", colSpan: 1}, {content: "", colSpan: 1}, {content: "Phạm Hương Lan", colSpan: 1}],
          [{content: 'Tin học', colSpan: 1}, {content: "T", colSpan: 1}, {content: "7.3", colSpan: 1}, {
            content: "6.5",
            colSpan: 1
          }, {content: "", colSpan: 1}, {content: "Phạm Hương Lan", colSpan: 1}],
          [{content: 'Thể dục', colSpan: 1}, {content: "T", colSpan: 1}, {content: "7.3", colSpan: 1}, {
            content: "6.5",
            colSpan: 1
          }, {content: "", colSpan: 1}, {content: "Phạm Hương Lan", colSpan: 1}],
          [{content: 'Âm nhạc', colSpan: 1}, {content: "T", colSpan: 1}, {content: "7.3", colSpan: 1}, {
            content: "6.5",
            colSpan: 1
          }, {content: "", colSpan: 1}, {content: "Phạm Hương Lan", colSpan: 1}],
          [{content: 'Mỹ thuật', colSpan: 1}, {content: "T", colSpan: 1}, {content: "7.3", colSpan: 1}, {
            content: "6.5",
            colSpan: 1
          }, {content: "", colSpan: 1}, {content: "Phạm Hương Lan", colSpan: 1}],
          [{content: 'Hoạt động trải nghiệm', colSpan: 1}, {content: "T", colSpan: 1}, {
            content: "7.3",
            colSpan: 1
          }, {content: "6.5", colSpan: 1}, {content: "", colSpan: 1}, {content: "Phạm Hương Lan", colSpan: 1}],
          [{content: 'Nội dung giáo dục của địa phương', colSpan: 1}, {content: "T", colSpan: 1}, {
            content: "7.3",
            colSpan: 1
          }, {content: "6.5", colSpan: 1}, {content: "", colSpan: 1}, {content: "Phạm Hương Lan ", colSpan: 1}],
        ]

        let columnStyles = {
          0: {
            halign: 'center',
            valign: 'middle',
            fontSize: 13,
            cellWidth: 35.8,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1}
          },
          1: {
            halign: 'center',
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: 'middle',
            cellWidth: 20
          },
          2: {
            halign: 'center',
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: 'middle',
            cellWidth: 20
          },
          3: {
            halign: 'center',
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: 'middle',
            cellWidth: 20
          },
          4: {
            halign: 'center',
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: 'middle',
            cellWidth: 30
          },
          5: {
            halign: 'left',
            fontSize: 13,
            cellPadding: {top: 3, right: 3, bottom: 3, left: 15},
            valign: 'middle',
            cellWidth: 60
          },
        }
        doc.autoTable({
          tableLineColor: [70, 76, 70],
          columnStyles: columnStyles,
          head: header,
          body: body,
          startY: 20,
          theme: 'grid',
          headStyles: {
            fillColor: [255, 255, 255],
            textColor: [45, 45, 45],
            font: 'TimesBold',
            fontStyle: 'normal',
            lineWidth: 0.1,
            halign: 'center',
            valign: 'middle',
            fontSize: 13,
            cellPadding: 1
          },
          styles: {
            textColor: [45, 45, 45],
            lineColor: [70, 76, 70],
            font: 'TimesBold',
            fontStyle: 'normal',
          },
          bodyStyles: {
            textColor: [45, 45, 45],
            lineColor: [70, 76, 70],
            font: 'VNTimes',
            fontStyle: 'normal',
            cellPadding: 1,
            fontSize: 13
          }
        })
        let tableLength = doc.lastAutoTable.finalY;
        doc.setFont("VNTimes")
        doc.text("Trong bảng này có sửa chữa ở: Không chỗ, thuộc môn học, hoạt động giáo dục: ", 15, tableLength + 10);
        //   Ký
        // Đoạn văn bản đầu tiên
        let text1 = "Xác nhận của Giáo viên chủ nhiệm";
        let text1Width = doc.getTextWidth(text1); // Độ dài của đoạn văn bản 1
        let text1X = doc.internal.pageSize.width / 4 - text1Width / 2; // Vị trí căn giữa theo chiều ngang (1/2 bên trái)
        doc.text(text1, text1X, tableLength + 20);
        text1 = "(Ký và ghi rõ họ tên)";
        text1Width = doc.getTextWidth(text1); // Độ dài của đoạn văn bản 1
        text1X = doc.internal.pageSize.width / 4 - text1Width / 2; // Vị trí căn giữa theo chiều ngang (1/2 bên trái)
        doc.text(text1, text1X, tableLength + 25);

        text1 = "Trần Thu Thủy";
        text1Width = doc.getTextWidth(text1); // Độ dài của đoạn văn bản 1
        text1X = doc.internal.pageSize.width / 4 - text1Width / 2; // Vị trí căn giữa theo chiều ngang (1/2 bên trái)
        doc.text(text1, text1X, tableLength + 65);

// Đoạn văn bản thứ hai
        let text2 = "(Ký, ghi rõ họ tên và đóng dấu)";
        let text2Width = doc.getTextWidth(text2); // Độ dài của đoạn văn bản 2
        let text2X = doc.internal.pageSize.width * 3 / 4 - text2Width / 2; // Vị trí căn giữa theo chiều ngang (1/2 bên trái)
        doc.text(text2, text2X, tableLength + 20);

        text2 = "Xác nhận của Hiệu trưởng";
        text2Width = doc.getTextWidth(text2); // Độ dài của đoạn văn bản 2
        text2X = doc.internal.pageSize.width * 3 / 4 - text2Width / 2; // Vị trí căn giữa theo chiều ngang (1/2 bên trái)
        doc.text(text2, text2X, tableLength + 25);

        text2 = "Lê Thành Trung";
        text2Width = doc.getTextWidth(text2); // Độ dài của đoạn văn bản 2
        text2X = doc.internal.pageSize.width * 3 / 4 - text2Width / 2; // Vị trí căn giữa theo chiều ngang (1/2 bên trái)
        doc.text(text2, text2X, tableLength + 65);
      } else if (pageNumber == 5) {
        let marginTop = 20; // 1,5cm
        let marginLeft = 14; // 2cm
        let marginRight = doc.internal.pageSize.getWidth() - 10; // 1cm từ lề phải
        let marginBottom = doc.internal.pageSize.getHeight() - 10; // 1cm từ lề dưới
        doc.setLineWidth(0.6);
        doc.line(marginLeft, marginTop, marginRight, marginTop); // Đường viền trên
        doc.line(marginLeft, marginTop, marginLeft, marginBottom); // Đường viền trái
        doc.line(marginRight, marginTop, marginRight, marginBottom); // Đường viền phải
        doc.line(marginLeft, marginBottom, marginRight, marginBottom); // Đường viền dưới

        doc.setFont("TimesBold")
        doc.setFontSize(13);
        doc.text("Họ và tên:    PHÙNG THỊ PHƯƠNG ANH", 15, 15);
        doc.text("Lớp:    6A", 120, 15);
        doc.text("Năm học:    2020-2021", 150, 15);


        let header = [
          [{content: 'Học kỳ', rowSpan: 2}, {
            content: "Mức đánh giá",
            colSpan: 2
          }, {
            content: "Tổng số buổi nghỉ học cả năm học",
            rowSpan: 2
          }, {
            content: 'Mức đánh giá sau đánh giá lại môn học hoặc rèn luyện trong kì nghỉ hè (nếu có)',
            colSpan: 2
          }],
          [{content: 'Kết quả rèn luyện', rowSpan: 1}, {
            content: 'Kết quả học tập',
            rowSpan: 1
          }, {content: 'Kết quả rèn luyện', rowSpan: 1}, {content: 'Kết quả học tập', rowSpan: 1}],

        ]
        let body = [
          [{content: 'Học kỳ I', colSpan: 1}, {content: "Tốt", colSpan: 1}, {content: "Khá", colSpan: 1}, {
            content: "",
            rowSpan: 3
          }, {content: "", rowSpan: 3}, {content: "Phạm Hương Lan", rowSpan: 3}],
          [{content: 'Học kỳ II', colSpan: 1}, {content: "T", colSpan: 1}, {content: "7.3", colSpan: 1}],
          [{content: 'Cả năm', colSpan: 1}, {content: "T", colSpan: 1}, {content: "7.3", colSpan: 1}],
        ]

        let columnStyles = {
          0: {
            halign: 'center',
            valign: 'middle',
            fontSize: 13,
            cellWidth: 35.8,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1}
          },
          1: {
            halign: 'center',
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: 'middle',
            cellWidth: 20
          },
          2: {
            halign: 'center',
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: 'middle',
            cellWidth: 20
          },
          3: {
            halign: 'center',
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: 'middle',
            cellWidth: 20
          },
          4: {
            halign: 'center',
            fontSize: 13,
            cellPadding: {top: 1, right: 1, bottom: 1, left: 1},
            valign: 'middle',
            cellWidth: 30
          },
          5: {
            halign: 'left',
            fontSize: 13,
            cellPadding: {top: 3, right: 3, bottom: 3, left: 15},
            valign: 'middle',
            cellWidth: 60
          },
        }
        doc.autoTable({
          tableLineColor: [70, 76, 70],
          columnStyles: columnStyles,
          head: header,
          body: body,
          startY: 20,
          theme: 'grid',
          headStyles: {
            fillColor: [255, 255, 255],
            textColor: [45, 45, 45],
            font: 'TimesBold',
            fontStyle: 'normal',
            lineWidth: 0.1,
            halign: 'center',
            valign: 'middle',
            fontSize: 13,
            cellPadding: 1
          },
          styles: {
            textColor: [45, 45, 45],
            lineColor: [70, 76, 70],
            font: 'TimesBold',
            fontStyle: 'normal',
          },
          bodyStyles: {
            textColor: [45, 45, 45],
            lineColor: [70, 76, 70],
            font: 'VNTimes',
            fontStyle: 'normal',
            cellPadding: 1,
            fontSize: 13
          }
        })
        let tableLength = doc.lastAutoTable.finalY;
        doc.setFont("VNTimes")
        doc.text("- Nếu là lớp cuối cấp thì ghi: Hoàn thành hay không hoàn thành chương trình trung học cơ sở: ", 15, tableLength + 5);
        doc.text("- Chứng chỉ (nếu có): Không", 15, tableLength + 15);
        doc.text("- Loại:", 150, tableLength + 15);
        doc.text(" - Kết quả tham gia các cuộc thi (nếu có): Không", 15, tableLength + 25);
        doc.text(" - Khen thưởng (nếu có): Không", 15, tableLength + 35);

        doc.setLineWidth(0.5);
        doc.line(marginLeft, tableLength + 40, marginRight, tableLength + 40); // Đường viền trên

        let fontSize = 13;
        doc.setFontSize(fontSize);
        let textHocBa = "KẾT QUẢ RÈN LUYỆN TRONG KÌ NGHỈ HÈ";
        let textWidth = doc.getStringUnitWidth(textHocBa) * fontSize / doc.internal.scaleFactor;
        doc.text(textHocBa, (pageWidth - textWidth) / 2, tableLength + 45);
        textHocBa = "(Nếu có)";
        textWidth = doc.getStringUnitWidth(textHocBa) * fontSize / doc.internal.scaleFactor;
        doc.text(textHocBa, (pageWidth - textWidth) / 2, tableLength + 50);


        doc.line(marginLeft, tableLength + 65, marginRight, tableLength + 65); // Đường viền trên
        textHocBa = "NHẬN XÉT CỦA GIÁO VIÊN CHỦ NHIỆM";
        textWidth = doc.getStringUnitWidth(textHocBa) * fontSize / doc.internal.scaleFactor;
        doc.text(textHocBa, (pageWidth - textWidth) / 2, tableLength + 70);
        doc.text('Ngoan, có ý thức tổ chức kỷ luật, có tinh thần trách nhiệm, hoàn thành tốt các công việc được giao.', 15, tableLength + 75);

        let text2 = "(Ký và ghi rõ họ tên)";
        let text2Width = doc.getTextWidth(text2); // Độ dài của đoạn văn bản 2
        let text2X = doc.internal.pageSize.width * 3 / 4 - text2Width / 2; // Vị trí căn giữa theo chiều ngang (1/2 bên trái)
        doc.text(text2, text2X, tableLength + 90);
        text2 = "Trần Bình Trọng";
        text2Width = doc.getTextWidth(text2); // Độ dài của đoạn văn bản 2
        text2X = doc.internal.pageSize.width * 3 / 4 - text2Width / 2; // Vị trí căn giữa theo chiều ngang (1/2 bên trái)
        doc.text(text2, text2X, tableLength + 120);
        doc.line(marginLeft, tableLength + 125, marginRight, tableLength + 125); // Đường viền trên

        textHocBa = "PHÊ DUYỆT CỦA HIỆU TRƯỞNG";
        textWidth = doc.getStringUnitWidth(textHocBa) * fontSize / doc.internal.scaleFactor;
        doc.text(textHocBa, (pageWidth - textWidth) / 2, tableLength + 130);
        doc.text('Nội dung phê duyệt của hiệu trưởng', 15, tableLength + 155);
        text2 = "Yên Lập, Ngày 15 tháng 7 năm 2024";
        text2Width = doc.getTextWidth(text2); // Độ dài của đoạn văn bản 2
        text2X = doc.internal.pageSize.width * 3 / 4 - text2Width / 2; // Vị trí căn giữa theo chiều ngang (1/2 bên trái)
        doc.text(text2, text2X, tableLength + 135);
        text2 = "HIỆU TRƯỞNG";
        text2Width = doc.getTextWidth(text2); // Độ dài của đoạn văn bản 2
        text2X = doc.internal.pageSize.width * 3 / 4 - text2Width / 2; // Vị trí căn giữa theo chiều ngang (1/2 bên trái)
        doc.text(text2, text2X, tableLength + 145);
        text2 = "(Ký, ghi rõ họ tên và đóng dấu)";
        text2Width = doc.getTextWidth(text2); // Độ dài của đoạn văn bản 2
        text2X = doc.internal.pageSize.width * 3 / 4 - text2Width / 2; // Vị trí căn giữa theo chiều ngang (1/2 bên trái)
        doc.text(text2, text2X, tableLength + 155);
        text2 = "Nguyễn Thị Minh Nguyệt";
        text2Width = doc.getTextWidth(text2); // Độ dài của đoạn văn bản 2
        text2X = doc.internal.pageSize.width * 3 / 4 - text2Width / 2; // Vị trí căn giữa theo chiều ngang (1/2 bên trái)
        doc.text(text2, text2X, tableLength + 195);

      }


      // // Ghi số trang hiện tại trên footer
      // const totalPages = doc.internal.getNumberOfPages();
      // doc.setFontSize(10);
      // doc.text(`Page ${pageNumber} of ${totalPages}`, 10, doc.internal.pageSize.height - 10);
    },
    displayPDF(pdfDataUrl) {
      console.log('displayPDF')
      console.log(pdfDataUrl)
      this.urlPdf = pdfDataUrl;
      // window.open(this.urlPdf, '_blank', 'noopener,noreferrer');
      return;
      // Hiển thị tệp PDF trong một thẻ iframe
      const iframe = document.createElement('iframe');
      iframe.src = pdfDataUrl;
      iframe.width = '100%';
      iframe.height = '600px';

      // Xóa nội dung cũ trong div
      const pdfViewer = document.getElementById('pdfViewer');
      pdfViewer.innerHTML = '';
      pdfViewer.appendChild(iframe);
    }
  }
}
</script>
<style>
</style>