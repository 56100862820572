<template>
  <div class="page page-request" v-loading="loading.fullScreen" element-loading-text="Loading..."
       element-loading-spinner="el-icon-loading" element-loading-background="rgba(255, 255, 255, 0.8)">
    <div class="page__content">
      <el-row class="dts-content" :gutter="10">
        <el-col :lg="5" :md="4" :sm="3"></el-col>
        <el-col class="bg-smoke text-center" :lg="14" :md="16" :sm="18" :xs="24">
          <el-row :gutter="24" style="margin-top: 30px">
            <el-col class="text-center">
              <div class="img">
                <img src="/images/default-avatar.png" width="200px" alt="Ảnh đại diện">
              </div>
              <h3>{{ user.displayName }}</h3>
              <p class="mt-2">
                <el-tooltip content="Cập nhật thông tin" placement="top">
                  <el-button @click.prevent="showUpdate" size="mini" type="warning"><i
                      class="el-icon-edit" style="font-size: 14px"></i>
                  </el-button>
                </el-tooltip>
                <el-tooltip content="Đổi mật khẩu" placement="top">
                  <el-button @click.prevent="showUpdatePass" size="mini" type="danger"><i
                      class="el-icon-key" style="font-size: 14px"></i>
                  </el-button>
                </el-tooltip>
              </p>
            </el-col>
          </el-row>
          <el-row :gutter="24" style="margin-bottom: 30px">
            <el-col :sm="12" :xs="24" class="text-left">
              <el-card shadow="always">
                <div class="iconInfo">
                  <i class="el-icon-user"></i>
                </div>
                <div class="textInfo">
                  <span>Mã đơn vị</span>
                  <p>{{ infoAdmin.maDonVi}}</p>
                  <p v-if="!infoAdmin.maDonVi">Chưa cập nhật</p>
                </div>
              </el-card>
            </el-col>
            <el-col :sm="12" :xs="24" class="text-left">
              <el-card shadow="always">
                <div class="iconInfo">
                  <i class="el-icon-message"></i>
                </div>
                <div class="textInfo">
                  <span>Email</span>
                  <p>{{ infoAdmin.email }}</p>
                  <p v-if="!infoAdmin.email">Chưa cập nhật</p>
                </div>
              </el-card>
            </el-col>
            <el-col :sm="12" :xs="24" class="text-left">
              <el-card shadow="always">
                <div class="iconInfo">
                  <i class="el-icon-location"></i>
                </div>
                <div class="textInfo">
                  <span>Địa chỉ</span>
                  <p>{{ infoAdmin.diaChi }}</p>
                  <p v-if="!infoAdmin.diaChi">Chưa cập nhật</p>
                </div>
              </el-card>
            </el-col>
            <el-col :sm="12" :xs="24" class="text-left">
              <el-card shadow="always">
                <div class="iconInfo">
                  <i class="el-icon-phone"></i>
                </div>
                <div class="textInfo">
                  <span>Số điện thoại</span>
                  <p>{{ infoAdmin.soDienThoai }}</p>
                  <p v-if="!infoAdmin.soDienThoai">Chưa cập nhật</p>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-dialog title="Chỉnh sửa thông tin" width="60%"  :visible.sync="show_update"
                     class="updateInfoTruong"
                     :close-on-press-escape="false" :close-on-click-modal="false"
          >
            <el-row :gutter="24">
              <el-col :span="12">
                <label>Email</label>
                <el-input clearable v-model="infoUpdate.email" placeholder="Nhập"></el-input>
              </el-col>
              <el-col :span="12">
                <label>Địa chỉ</label>
                <el-input clearable v-model="infoUpdate.diaChi" placeholder="Nhập "></el-input>
              </el-col>
              <el-col :span="12">
                <label>Số điện thoại</label>
                <el-input clearable v-model="infoUpdate.soDienThoai" placeholder="Nhập "></el-input>
              </el-col>
            </el-row>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="show_update = false">Hủy</el-button>
                <el-button size="mini" type="warning" @click.prevent="xacNhanChinhSua()">Chỉnh sửa</el-button>
            </span>
          </el-dialog>
          <el-dialog title="Đổi mật khẩu" width="50%" :visible.sync="show_update_pass"
                     class="updateInfoTruong"
                     :close-on-press-escape="false" :close-on-click-modal="false"
          >
            <el-row :gutter="24">
              <el-col :span="12">
                <label>Mật khẩu mới</label>
                <el-input clearable v-model="matKhau.moi" show-password placeholder="Nhập"></el-input>
              </el-col>
              <el-col :span="12">
                <label>Xác nhận mật khẩu mới</label>
                <el-input clearable v-model="matKhau.nhapLai" show-password placeholder="Nhập "></el-input>
              </el-col>
            </el-row>
            <span slot="footer" class="dialog-footer text-center" style="text-align: center !important;" >
                <el-button size="mini" @click="show_update_pass = false">Hủy</el-button>
                <el-button size="mini" type="danger" @click.prevent="xacNhanThayDoiMatKhau()">Đổi mật khẩu</el-button>
            </span>
          </el-dialog>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
// import replaceColor from 'replace-color';
import api from '../../_helpers/api';
import constant from '../../_helpers/constant_api';
import {
  mapActions,
  mapState
} from 'vuex';
import constant_api from "../../_helpers/constant_api";
export default {
  name: "infoAdmin",
  data() {
    return {
      infoAdmin: {},
      show_update:false,
      show_update_pass:false,
      infoUpdate:{},
      matKhau:{
        moi:'',
        nhapLai:''
      },
      loading: {
        fullScreen: false
      },
    }
  },
  watch: {},
  computed: {
    ...mapState('account',
        [
          'user',
        ]),

  },
  mounted() {
    console.log('infoAdmin')
    console.log(this.infoAdmin)
    this.layThongTinTaiKhoan();
  },
  methods: {
    xacNhanThayDoiMatKhau(){
      console.log('xacNhanThayDoiMatKhau')
      if (!this.matKhau.moi || this.matKhau.moi == '') {
        this.thongBao('error', 'Vui lòng nhập mật khẩu mới');
        return
      }
      if (!this.matKhau.nhapLai || this.matKhau.nhapLai == '') {
        this.thongBao('error', 'Xác nhận mật khẩu mới');
        return
      }
      if (this.matKhau.moi != this.matKhau.nhapLai) {
        this.thongBao('error', 'Mật khẩu nhập lại không khớp');
        return;
      }
      let url = constant_api.taiKhoan.doiMatKhau;
      let params = {
        email: this.user.username,
        password: this.matKhau.moi
      }

      this.$confirm('Xác nhận thay đổi mật khẩu?', 'Thông báo', {
        confirmButtonText: 'Đổi mật khẩu',
        cancelButtonText: 'Hủy',
        confirmButtonClass: 'el-button--danger'
      })
          .then(_ => {
            this.loading.fullScreen = true;
            api.post(url, params).then(
                response => {
                  console.log("Res:")
                  console.log(response)
                  if (response.data.code == 200) {
                    this.thongBao('success', response.data.msg)
                    this.show_update_pass = false;
                  } else {
                    this.thongBao("error", response.data.msg)
                  }
                  this.loading.fullScreen = false;
                }
            )
          })
          .catch(_ => {
          });
    },
    xacNhanChinhSua(){
      let url = constant.taiKhoan.update;
      this.infoAdmin = {};
      let params = {
        email:this.infoUpdate.email,
        soDienThoai:this.infoUpdate.soDienThoai,
        diaChi:this.infoUpdate.diaChi,
      }

      this.$confirm('Xác nhận chỉnh sửa thông tin?', 'Thông báo', {
        confirmButtonText: 'Chỉnh sửa',
        cancelButtonText: 'Hủy',
        confirmButtonClass: 'el-button--warning'
      })
          .then(_ => {
            this.loading.fullScreen = true;
            api.post(url, params).then(
                response => {
                  this.loading.fullScreen = false;
                  console.error(response)
                  if (response.data.code == 200) {
                    this.layThongTinTaiKhoan();
                  } else {
                    this.thongBao("error", "Không lấy được thông tin giáo viên")
                  }
                }
            )
          })
          .catch(_ => {
          });
    },
    layThongTinTaiKhoan(){
      this.show_update = false;
      this.loading.fullScreen = true;
      let url = constant.taiKhoan.info;
      this.infoAdmin = {};
      api.get(url, {}).then(
          response => {
            this.loading.fullScreen = false;
            console.error(response)
            if (response.data.code == 200) {
              this.infoAdmin = response.data.data
            } else {
              this.thongBao("error", "Không lấy được thông tin giáo viên")
            }
          }
      )
    },
    showUpdate(){
      this.show_update = true;
      this.infoUpdate = JSON.parse(JSON.stringify(this.infoAdmin))
    },
    showUpdatePass(){
      this.show_update_pass = true;
    },
    thongBao(t, e) {
      let msg = "";
      let cl = "";
      if (e) {
        msg = e;
      }
      let type = "success";
      if (t) {
        type = t
      }
      if (type == "success") {
        cl = "dts-noty-success"
      }
      if (type == "warning") {
        cl = "dts-noty-warning"
      }
      if (type == "error") {
        cl = "dts-noty-error"
      }
      if (type == "info") {
        cl = "dts-noty-info"
      }
      this.$message({
        customClass: cl,
        showClose: true,
        message: msg,
        type: t,
        duration: 3000
      });
    },
  }
}
</script>

<style scope="scoped">
.infoAccount label {
  width: auto;
}

.img > img {
  width: 200px;
}

p {
  padding: 0;
  margin: 0;
}

.el-card__body {
  padding: 15px;
  position: relative
}

.textInfo {
  padding-left: 50px;
}

.textInfo > p {
  color: black;
}

.iconInfo {
  position: absolute
}

.iconInfo > i {
  font-size: 35px;
}

th {
  word-break: break-word;
}

</style>