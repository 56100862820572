<template>
  <div class="page page-file file detailHocBa">
    <div v-loading.fullscreen.lock="fullscreenLoading"
         element-loading-text="Đang đồng bộ..."
         element-loading-spinner="el-icon-loading"
         element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="page__title">
        <h1 class="title">Đồng bộ dữ liệu</h1>
      </div>
      <div class="box-upload-file">
        <el-form :rules="rulesSearch" ref="searchForm" :model="searchForm" :hide-required-asterisk="true">

          <el-row :gutter="24">
            <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="4">
              <el-form-item class="d-inline-block" prop="namHoc" label="Năm học">
                <eselect style="width:100%" collapseTags v-model="searchForm.namHoc"
                         @change="chonNamHoc"
                         placeholder="Chọn" filterable :data="list_nam_hoc"
                         :fields="['name','value']"/>
              </el-form-item>
            </el-col>
            <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="4">
              <el-form-item class="d-inline-block" prop="capHoc" label="Cấp học">
                <eselect :disabled="list_cap_hoc.length==1" style="width:100%" collapseTags
                         v-model="searchForm.capHoc"
                         @change="chonKhoiHoc()"
                         placeholder="Chọn" filterable
                         :data="list_cap_hoc" :fields="['name','value']"/>
              </el-form-item>
            </el-col>
            <!--                        <el-col v-if="user && user.role==8" :xs="12" :sm="8" :md="6" :lg="4" :xl="4">-->
            <!--                            <el-form-item class="d-inline-block" prop="maSo" label="Sở giáo dục">-->
            <!--                                <eselect style="width:100%" collapseTags v-model="searchForm.maSo"-->
            <!--                                         @change="layDanhSachPhongGiaoDuc"-->
            <!--                                         :placeholder="'Sở giáo dục'" filterable :data="list_so"-->
            <!--                                         :fields="['name','value']"/>-->
            <!--                            </el-form-item>-->
            <!--                        </el-col>-->
            <el-col v-if="user && user.role==8" :xs="12" :sm="8" :md="6" :lg="4" :xl="4">
              <el-form-item class="d-inline-block" prop="maPhong" label="Phòng giáo dục">
                <eselect style="width:100%" collapseTags v-model="searchForm.maPhong"
                         @change="layDanhSachTruongHoc"
                         :placeholder="'Phòng giáo dục'" filterable :data="list_phong"
                         :fields="['name','value']"/>
              </el-form-item>
            </el-col>
            <el-col v-if="user && user.role==8" :xs="12" :sm="8" :md="6" :lg="4" :xl="4">
              <el-form-item class="d-inline-block" prop="maTruong" label="Trường học">
                <eselect style="width:100%" collapseTags v-model="searchForm.maTruong"
                         :placeholder="'Trường học'"
                         filterable :data="list_truong" :fields="['name','value']"/>
              </el-form-item>
            </el-col>
            <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="4">
              <el-form-item class="d-inline-block" prop="typeSync" label="Loại đồng bộ">
                <eselect style="width:100%" collapseTags
                         v-model="searchForm.typeSync"
                         @change="chonLoaiDongBo()"
                         :placeholder="'Loại đồng bộ'"
                         filterable :data="typeSync" :fields="['NAME','VALUE']"/>
              </el-form-item>
            </el-col>
            <el-col v-if="searchForm.typeSync==4||searchForm.typeSync==3||searchForm.typeSync==2||searchForm.typeSync==14" :xs="12" :sm="8"
                    :md="6" :lg="4" :xl="4">
              <el-form-item class="d-inline-block" prop="grade" label="Khối học">
                <eselect style="width:100%" collapseTags v-model="searchForm.grade"
                         @change="khoiHocChange"
                         placeholder="Chọn" filterable :data="optionGrade"
                         :fields="['label','value']"/>
              </el-form-item>
            </el-col>
            <el-col v-if="searchForm.typeSync==4||searchForm.typeSync==3||searchForm.typeSync==14" :xs="12" :sm="8" :md="6" :lg="4" :xl="4">
              <el-form-item class="d-inline-block" prop="class" label="Lớp học">
                <eselect style="width:100%" multiple v-model="searchForm.class"
                         placeholder="Chọn"
                         filterable :data="danh_sach_lop_hoc" :fields="['tenLop','maLop']"/>
              </el-form-item>
            </el-col>
            <el-col v-if="searchForm.typeSync==2" :xs="12" :sm="8" :md="6" :lg="4" :xl="4">
              <el-form-item class="d-inline-block" label="Lớp học">
                <eselect style="width:100%" multiple v-model="searchForm.class"
                         placeholder="Chọn"
                         filterable :data="danh_sach_lop_hoc" :fields="['tenLop','maLop']"/>
              </el-form-item>
            </el-col>
            <el-col v-if="searchForm.typeSync==4&&false" :xs="12" :sm="8" :md="6" :lg="4" :xl="4">
              <label style="color: transparent;display: block">Đồng bộ điểm</label>
              <el-checkbox v-model="searchForm.dongBoDiem">Đồng bộ điểm</el-checkbox>
            </el-col>
            <!--                        <el-col v-if="trang_thai_chot && user.role!=8" :xs="12" :sm="8" :md="6" :lg="6" :xl="4">-->
            <!--                            <el-form-item class="d-inline-block" prop="maHs">-->
            <!--                                <el-input style="width:100%" v-model="searchForm.maHs"-->
            <!--                                          placeholder="Nhập"></el-input>-->
            <!--                            </el-form-item>-->
            <!--                        </el-col>-->
          </el-row>
          <div>
            <template v-if="user && user.role == 7">
              <el-form-item class="d-inline-block" prop="pgd">
                <eselect :data="listPGD" filterable @change="getListTruong" custom-style="width: 370px"
                         v-model="searchForm.pgd" placeholder="Chọn phòng giáo dục"
                         :fields="['tenDonVi','maDonVi']"></eselect>
              </el-form-item>
              <el-form-item class="d-inline-block" prop="pgd">
                <eselect :data="listSchool" filterable multiple collapse-tags
                         custom-style="width: 370px"
                         v-model="searchForm.maTruong" placeholder="Chọn trường"
                         :fields="['tenTruongHoc','maTruongHoc']">
                </eselect>
              </el-form-item>
            </template>
            <template v-if="user && user.role == 6">
              <el-form-item class="d-inline-block" prop="pgd">
                <eselect disabled :data="listPGD" filterable @change="getListTruong"
                         custom-style="width: 370px"
                         v-model="searchForm.pgd" placeholder="Chọn phòng giáo dục"
                         :fields="['tenDonVi','maDonVi']"></eselect>
              </el-form-item>
              <el-form-item class="d-inline-block" prop="pgd">
                <eselect :data="listSchool" filterable multiple collapse-tags
                         custom-style="width: 370px"
                         v-model="searchForm.maTruong" placeholder="Chọn trường"
                         :fields="['tenTruongHoc','maTruongHoc']">
                </eselect>
              </el-form-item>
            </template>
          </div>
          <el-form-item style="margin-top: 20px">
            <el-form-item class="text-center">
              <el-button type="primary" @click="submitForm('searchForm')">Đồng bộ</el-button>
            </el-form-item>
          </el-form-item>

        </el-form>
      </div>
    </div>
    <div class="file--title">
      <h5 class="title">Lịch sử đồng bộ</h5>
    </div>
    <div class="page__content">
      <el-table border fit :lazy="true" :data="historySync ? historySync.list : []" width="100%"
                ref="listGiaoVien">
        <el-table-column label="STT" style="width: 50px" width="80" type="index" class-name="text-center"
                         :index="indexMethod">
        </el-table-column>
        <el-table-column label="Loại hành động" property="tenLoaiDongBo" header-align="center"
                         align="left"></el-table-column>
        <el-table-column label="Năm học" property="strNamHoc" align="center"></el-table-column>
        <!--                <el-table-column label="Sở GD" property="tenSo" class-name="text-center"></el-table-column>-->
        <!--                <el-table-column label="Phòng GD" property="tenPhong" class-name="text-center"></el-table-column>-->
        <el-table-column label="Mã trường" property="maTruong" class-name="text-center"></el-table-column>
        <el-table-column label="Trường" property="tenTruong" header-align="center" align="center"></el-table-column>
        <el-table-column label="Thời gian" class-name="text-center">
          <template slot-scope="scope">
            {{ new Date(scope.row.createdDate) | moment("H:mm:ss DD/MM/YYYY") }}
          </template>
        </el-table-column>
        <el-table-column label="Kết quả" header-align="center" align="left">
          <template slot-scope="scope">
            <div v-if="scope.row.ketQua.length>=50">
              <p class="resultText"> {{ scope.row.ketQua }}</p>
              <a class="" href="#" @click.prevent="showResult(scope.row.ketQua)"><i
                  class="showMore"><small>chi tiết</small></i></a>
            </div>
            <div v-else>
              {{ scope.row.ketQua }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Thực hiện" property="nguoiDongBo" header-align="center" align="left"></el-table-column>
        <el-table-column label="Hành động" class-name="text-center">
          <template slot-scope="scope">
            <div v-if="scope.row.loaiDongBo==4">
              <el-button v-if="scope.row.maLops&&scope.row.maLops.length" size="mini" type="primary"
                         @click="taiXuongDuLieu(scope.row)">Tải xuống
              </el-button>
            </div>
            <div v-else>
              <!--                            <el-button  size="mini" type="primary" disabled>Tải xuống-->
              <!--                            </el-button>-->
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="text-right mt-2">
        <pagination v-if="historySync && historySync.total" :value="paginate.current"
                    :page-count="getTotalPage(historySync.total)" :click-handler="getHistory"
                    :prev-text="'Trước'"
                    :next-text="'Sau'" :container-class="'el-pager'" :pageClass="'number'">

        </pagination>
      </div>
    </div>
    <div>

      <el-dialog title="Tải xuống file dữ liệu" center :visible.sync="hien_thi_tai_file"
                 :closeOnPressEscape="false"
                 :closeOnClickModal="false"
      >
        <el-table border fit :data="danh_sach_dong_bo_theo_lop">
          <el-table-column type="index" align="center" label="STT" width="150"></el-table-column>
          <el-table-column property="tenLop" align="center" label="Tên lớp" width="200"></el-table-column>
          <el-table-column label="Hành động" align="center" class-name="text-center">
            <template slot-scope="scope">
              <a :href="scope.row.path">
                <el-button size="mini" type="primary">
                  Tải xuống
                </el-button>
              </a>
            </template>
          </el-table-column>
        </el-table>
        <div slot="footer" class="dialog-footer">
          <el-button @click="hien_thi_tai_file = false" type="default" size="mini">Đóng</el-button>
        </div>
      </el-dialog>

      <el-dialog title="Kết quả đồng bộ" width="40%" center :visible.sync="show_text">
        <div><p v-for="text in textShow">{{ text }}</p></div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  mapActions,
  mapState
} from 'vuex';
import ESelect from "../components/ESelect";
import constant from "../constant";
import dialog from "../dialog";
import Pagination from "../components/Pagination";
import SelectNamHoc from "../components/SelectNamHoc";
import ChonCapHoc from "../components/ChonCapHoc";
import constant_api from '../_helpers/constant_api';
import api from '../_helpers/api';
import LZString from "lz-string";
import ExcelJS from "exceljs";
import XLSX from "xlsx";

export default {
  name: "DongBoDuLieu",
  metaInfo: {
    title: 'Đồng bộ dữ liệu',
    titleTemplate: '',
    meta: [{
      vmid: '',
      name: '',
      content: ''
    }]
  },
  computed: {
    ...mapState('account',
        [
          'user',
          'list_nam_hoc',
          'profile',
          'nam_hoc_hien_tai',
          'list_cap_hoc',
        ]),

    ...mapState("configKyModule", [
      "danhSachSo",
    ]),
    ...mapState('school', [
      "dongBo",
      "listPGD",
      "listSchool",
      "historySync"

    ])
  },
  components: {
    "eselect": ESelect,
    Pagination,
    SelectNamHoc,
    ChonCapHoc,
  },
  data() {
    return {
      row_header: 0,
      trang_thai_chot: null,
      list_so: [],
      list_phong: [],
      list_truong: [],
      paginate: {
        start: 0,
        limit: 5,
        current: 1,
      },
      show_text: false,
      textShow: '',
      danh_sach_dong_bo_theo_lop: [],
      hien_thi_tai_file: false,
      fullscreenLoading: false,
      text_load: 'Đang đồng bộ',
      searchForm: {
        education: '',
        typeSync: '',
        dongBoDiem: false,
        maHs: '',
        hocKy: '',
        maTruong: '',
        pgd: '',
        namHoc: '',
        class: [],
        grade: '',
      },
      typeSync: [],
      optionGrade: [],
      danh_sach_lop_hoc: [],

      khoiCap1: [{
        value: 1,
        label: 'Khối 1'
      },
        {
          value: 2,
          label: 'Khối 2'
        },
        {
          value: 3,
          label: 'Khối 3'
        },
        {
          value: 4,
          label: 'Khối 4'
        },
        {
          value: 5,
          label: 'Khối 5'
        },
      ],
      khoiCap2: [{
        value: 6,
        label: 'Khối 6'
      },
        {
          value: 7,
          label: 'Khối 7'
        },
        {
          value: 8,
          label: 'Khối 8'
        },
        {
          value: 9,
          label: 'Khối 9'
        },
      ],
      khoiCap3: [{
        value: 10,
        label: 'Khối 10'
      },
        {
          value: 11,
          label: 'Khối 11'
        },
        {
          value: 12,
          label: 'Khối 12'
        },

      ],
      rulesSearch: {
        typeSync: [{
          required: true,
          message: 'Bạn chưa chọn loại đồng bộ',
          trigger: 'change'
        }],
        maSo: [{
          required: true,
          message: 'Bạn chưa chọn sở',
          trigger: 'change'
        }],
        hocKy: [{
          required: true,
          message: 'Bạn chưa chọn học kỳ',
          trigger: 'change'
        }],
        education: [{
          required: true,
          message: 'Bạn chưa chọn cấp học học',
          trigger: 'change'
        }],
        namHoc: [{
          required: true,
          message: 'Chưa chọn năm học',
          trigger: 'change'
        }],
        maHs: [{
          required: true,
          message: 'Vui lòng nhập mã học sinh',
          trigger: 'change'
        }],
        class: [{
          required: true,
          message: 'Vui lòng chọn lớp học',
          trigger: 'change'
        }],
      }
    }
  },
  methods: {
    resetForm(formName) {
      this.$refs[formName].clearValidate();
    },
    chonLoaiDongBo() {
      console.log('chonLoaiDongBo')
      if (this.searchForm.typeSync == 2) {
        this.resetForm('searchForm');
      }
    },
    showResult(text) {
      this.textShow = text.split(".")
      this.show_text = true;
    },
    creatFile(data, tenLop) {
      console.error("creatFile")
      let dataSheet = JSON.parse(LZString.decompressFromBase64(data));
      let wb = new ExcelJS.Workbook();
      dataSheet.forEach(item => {
        let sheet = wb.addWorksheet(item.sheetName);
        let r;
        let dataBuild = item.data;
        dataBuild.forEach((row, index) => {
          if (index < this.row_header) {
            r = sheet.addRow(row);
            r.font = {
              name: 'Times New Roman',
              size: 12,
              bold: true
            }
          } else {
            r = sheet.addRow(row);
            r.font = {
              name: 'Times New Roman',
              size: 12,
              bold: false
            }
          }
          if (sheet['!rows'] && sheet['!rows'][index])
            r.height = sheet['!rows'][index].hpx;
          r.alignment = {
            horizontal: "center",
            vertical: "middle",
            wrapText: true
          };
          r.eachCell((cell) => {
            if (index < 1) {
              cell.fill = {
                bgColor: {
                  indexed: 64
                },
                fgColor: {
                  theme: 0,
                  tint: -
                      0.0499893185216834
                },
                pattern: "solid",
                type: "pattern"
              };
            }
            cell.border = {
              top: {
                style: 'thin'
              },
              left: {
                style: 'thin'
              },
              bottom: {
                style: 'thin'
              },
              right: {
                style: 'thin'
              }
            }
          });
        })
        if (sheet.columns) {
          console.warn('123')
          console.log(sheet.columns)
          sheet.columns.forEach((col, index) => {
            let dataMax = 0;
            const column = col;
            for (let j = 1; j < column.values
                .length; j += 1) {
              if (column.values[j] && column.values[j].length) {
                const columnLength = column.values[j]
                    .length;
                if (columnLength > dataMax) {
                  dataMax = columnLength;
                }
              }
            }
            column.width = dataMax < 6 ? 6 : (dataMax +
                1);
          });
        }

      })
      var d = new Date();
      var n = d.getTime();
      let fileName = 'FileDuLieu_Lop' + tenLop + '_' + n;
      wb.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = fileName + '.xlsx';
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    },
    taiXuongDuLieu(e) {
      if (!e.maLops || e.maLops == null || e.maLops.length == 0) {
        this.thongBao('error', 'Vui lòng thử lại sau')
        return;
      }
      console.error('taiXuongDuLieu')
      this.text_load = 'Đang tải dữ liệu....'
      this.fullscreenLoading = true;
      let url = constant_api.school.taiDuLieuHocSinh;
      let params = {
        maLops: e.maLops,
        namHoc: e.namHoc,
      }
      api.post(url, params).then(response => {
        if (response.data.code == 200) {
          let list_data = response.data.data;
          this.danh_sach_dong_bo_theo_lop = JSON.parse(list_data);
          this.hien_thi_tai_file = true;
        } else {
          this.thongBao('error', 'Vui lòng thử lại sau ít phút.')
        }
        this.fullscreenLoading = false;
      }).catch((e) => {
        this.fullscreenLoading = false;
        // this.thongBao('success', 'Hệ thống đang cập nhật dữ liệu. Vui lòng chờ trong ít phút.');
      })
    },
    ...mapActions('teacher', []),
    getlistLopHoc() {
      this.getListLop({
        namHoc: this.searchForm.namHoc,
        start: 0,
        limit: 999,
        khoiHoc: this.searchForm.grade
      })
    },
    khoiHocChange() {
      console.error('khoiHocChange')
      this.searchForm.class = [];
      this.getlistLopHoc();
    },
    getListLop(params) {
      let url = constant_api.giaovien.listLopChuNhiem;
      if (this.user && this.user.role == 4) {
        params.maTruong = this.user.maTruong
      }
      this.fullscreenLoading = true;
      api.get(url, params).then(
          response => {
            this.fullscreenLoading = false;
            console.log("Res:")
            console.log(response)
            this.list_so = [];
            if (response.data.code == 200) {
              this.danh_sach_lop_hoc = response.data.data.list
            } else {
              this.thongBao("error", 'Không lấy được dữ liệu')
            }
          }
      )
    },
    getCurrentMonth() {
      let d = new Date();
      return d.getMonth();
    },
    chonNamHoc() {
      if (this.user.role != 8) {
        this.getTrangThaiChot();
      }
    },
    MoChotDuLieu() {
      this.$confirm('Xác nhận mở chốt dữ liệu?', 'Thông báo', {
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy',
      })
          .then(_ => {
            let params = {
              namHoc: parseInt(this.searchForm.namHoc),
            };
            let url = constant_api.hocba.moChot;
            this.fullscreenLoading = true;
            api.post(url, params).then(
                response => {
                  this.fullscreenLoading = false;
                  console.log("Res:")
                  console.log(response)
                  if (response.data.code == 200) {
                    this.thongBao('success', "Mở dữ liệu thành công");
                    this.getTrangThaiChot();
                  } else {
                    this.thongBao('error', response.data.msg)
                  }
                }
            )
          })
          .catch(_ => {
          });
    },
    ChotDuLieu() {
      this.$confirm('Xác nhận chốt dữ liệu?', 'Thông báo', {
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy',
      })
          .then(_ => {

            let params = {
              namHoc: parseInt(this.searchForm.namHoc),
            };
            let url = constant_api.hocba.chot;
            this.fullscreenLoading = true;
            api.post(url, params).then(
                response => {
                  this.fullscreenLoading = false;
                  console.log("Res:")
                  console.log(response)
                  if (response.data.code == 200) {
                    this.thongBao('success', "Chốt dữ liệu thành công")
                    this.getTrangThaiChot();
                  } else {
                    this.thongBao('error', response.data.msg)
                  }
                }
            )
          })
          .catch(_ => {
          });
    },
    getDanhSachSo() {
      let params = {};
      let url = constant_api.configKy.getDanhSachSo;
      this.fullscreenLoading = true;
      api.get(url, params).then(
          response => {
            this.fullscreenLoading = false;
            console.log("Res:")
            console.log(response)
            this.list_so = [];
            if (response.data.code == 200) {
              let dulieu = response.data.data;
              let ds_so = [];
              for (let i = 0; i < dulieu.length; i++) {
                let obj = {
                  name: dulieu[i].tenDonVi + " - [" + dulieu[i].maDonVi + "]",
                  value: dulieu[i].maDonVi
                }
                ds_so.push(obj)
              }
              this.list_so = ds_so;
            } else {
              this.thongBao("error", 'Không lấy được dữ liệu')
            }
          }
      )
    },
    setCapHoc() {
      console.log("setCapHoc")
      console.log(this.list_cap_hoc);
      this.searchForm.capHoc = this.list_cap_hoc[0].value;
      this.chonKhoiHoc();
    },
    getTrangThaiChot() {
      let params = {
        namHoc: parseInt(this.searchForm.namHoc),
      };
      let url = constant_api.hocba.layTrangThaiChot;
      this.fullscreenLoading = true;
      api.get(url, params).then(
          response => {
            this.fullscreenLoading = false;
            console.log("Res:")
            console.log(response)
            if (response.data.code == 200) {
              this.trang_thai_chot = response.data.data;
            } else {
              this.trang_thai_chot = true;
            }
          }
      )
    },
    ...mapActions('school', [
      "getHistorySync",
      "getListPGD",
    ]),
    layDanhSachTruongHoc() {
      this.searchForm.maTruong = '';
      this.list_truong = [];
      this.getListSchool({
        maPhong: this.searchForm.maPhong
      })
    },
    layDanhSachPhongGiaoDuc() {
      this.searchForm.maPhong = '';
      this.searchForm.maTruong = '';
      this.list_phong = [];
      this.list_truong = [];
      let params = {
        maSo: this.searchForm.maSo
      };
      let url = constant_api.school.listPgd;
      if (this.searchForm.maSo) {
        this.fullscreenLoading = true;
        api.get(url, params).then(
            response => {
              this.fullscreenLoading = false;
              console.log("Res:")
              console.log(response)
              if (response.data.code == 200) {
                let dulieu = response.data.data;
                let ds_phong = [];
                for (let i = 0; i < dulieu.length; i++) {
                  let obj = {
                    name: dulieu[i].tenDonVi + " - [" + dulieu[i].maDonVi + "]",
                    value: dulieu[i].maDonVi
                  }
                  ds_phong.push(obj)
                }
                this.list_phong = ds_phong;
              } else {
                this.thongBao("error", 'Không lấy được dữ liệu')
              }
            }
        )
      }
    },
    getListSchool(e) {
      this.fullscreenLoading = true;
      let url = constant_api.school.listSchool;
      api.get(url, e).then(
          response => {
            this.fullscreenLoading = false;
            console.log("Res:")
            console.log(response)
            if (response.data.code == 200) {
              let dulieu = response.data.data;
              let ds_truong = [];
              for (let i = 0; i < dulieu.length; i++) {
                let obj = {
                  name: dulieu[i].tenTruongHoc + " - [" + dulieu[i].maTruongHoc + "]",
                  value: dulieu[i].maTruongHoc
                }
                ds_truong.push(obj)
              }
              this.list_truong = ds_truong;
            } else {
              this.thongBao("error", 'Không lấy được dữ liệu')
            }
          }
      )
    },
    getListTruong() {
      if (!this.searchForm.pgd) {
        dialog.showDialog('Thông báo', 'Chưa chọn phòng giáo dục');
        return;
      }
      this.searchForm.maTruong = [];
      this.list_truong = [];
      this.getListSchool({
        maPhong: this.searchForm.pgd
      })
    },

    indexMethod(index) {
      return (this.paginate.start + index) + 1;
    },

    getHistory(select) {
      let start = (select - 1) * this.paginate.limit ? (select - 1) * this.paginate.limit : 0;
      this.paginate.start = start;
      this.paginate.current = select;
      this.getHistorySync({
        start: this.paginate.start,
        limit: this.paginate.limit,
        maTruong: this.user.maTruong,
        // maPhong: this.user.maPhong,
        maSo: this.user.maSo,
      });

    },
    getTotalPage(number) {
      return Math.ceil(number / (this.paginate.limit));
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let maTruong = [];
          if (this.user.role == 4 || this.user.role == 1 || this.user.role == 2) {
            maTruong = [this.user.maTruong]
          } else {
            maTruong = [this.searchForm.maTruong]
          }
          this.fullscreenLoading = true;
          let params = {
            loaiDongBo: this.searchForm.typeSync,
            namHoc: this.searchForm.namHoc,
            maTruongs: maTruong,
            maSo: this.user.maSo,
            dongBoDiem: this.searchForm.dongBoDiem?1:0,
            maPhong: this.searchForm.maPhong,
            nguoiDongBo: this.user.displayName,
            capHoc: this.searchForm.capHoc,
            maHSs: []
          };
          if (this.searchForm.maHs) {
            params.maHSs = [this.searchForm.maHs];
          }
          if (!this.trang_thai_chot) {
            params.maHSs = [];
          }
          if (this.user.role == 8) {
            params.maSo = this.searchForm.maSo;
          }
          if (this.searchForm.class) {
            params.maLops = this.searchForm.class;
            params.maPhong = this.user.maPhong
          }

          this.dongBoDuLieu(params);
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    dongBoDuLieu(params) {
      let url = constant_api.school.dongBo;
      // if (this.searchForm.typeSync == 4) {
      //     url = constant_api.school.dongBoHocBa;
      // }
      this.fullscreenLoading = true;
      api.post(url, params).then(
          response => {
            console.log("Res:")
            console.log(response)
            if (response.data.code == 200) {
              // this.thongBao('success', "Đồng bộ dữ liệu thành công");
              this.thongBao('success', response.data.msg)
            } else {
              this.thongBao('error', response.data.msg)
            }
            this.getHistorySync({
              start: this.paginate.start,
              limit: this.paginate.limit,
              maTruong: this.user.maTruong,
              // maPhong: this.user.maPhong,
              maSo: this.user.maSo,
            })
            this.fullscreenLoading = false;
          }
      ).catch((error) => {
        // this.thongBao('success', 'Hệ thống đang cập nhật dữ liệu. Vui lòng chờ trong ít phút.');
        this.fullscreenLoading = false;
        this.getHistorySync({
          start: this.paginate.start,
          limit: this.paginate.limit,
          maTruong: this.user.maTruong,
          // maPhong: this.user.maPhong,
          maSo: this.user.maSo,
        })
      });
    },
    getFullYear() {
      let d = new Date();
      return d.getFullYear();
    },
    chonKhoiHoc(){
      console.log('chonKhoiHoc')
      this.optionGrade = [];
      this.searchForm.grade = '';
      this.searchForm.class = [];
      if(this.searchForm.capHoc==1){this.optionGrade = this.khoiCap1}
      else if(this.searchForm.capHoc==2){this.optionGrade = this.khoiCap2}
      else if(this.searchForm.capHoc==3){this.optionGrade = this.khoiCap3}
      else{
        this.getKhoiHoc();
      }
    },
    getKhoiHoc() {
      console.log('getKhoiHoc')
      let arr = [];
      this.optionGrade = [];
      for (let i = 0; i < this.list_cap_hoc.length; i++) {
        if (this.list_cap_hoc[i].value == 1) {
          arr = arr.concat(this.khoiCap1)
        }
        if (this.list_cap_hoc[i].value == 2) {
          arr = arr.concat(this.khoiCap2)
        }
        if (this.list_cap_hoc[i].value == 3) {
          arr = arr.concat(this.khoiCap3)
        }
      }
      this.optionGrade = arr;
    },
    thongBao(t, e) {
      let msg = "";
      let cl = "";
      if (e) {
        msg = e;
      }
      let type = "success";
      if (t) {
        type = t
      }
      if (type == "success") {
        cl = "dts-noty-success"
      }
      if (type == "warning") {
        cl = "dts-noty-warning"
      }
      if (type == "error") {
        cl = "dts-noty-error"
      }
      if (type == "info") {
        cl = "dts-noty-info"
      }
      this.$message({
        dangerouslyUseHTMLString: true,
        customClass: cl,
        showClose: true,
        message: msg,
        type: t,
        duration: 3000
      });
    },
  },
  beforeMount() {
    if (this.user.role == 6) {
      this.getListPGD({
        maSo: this.user.maSo
      });
      this.searchForm.pgd = this.user.maPhong;
      this.getListTruong();
    }
    if (this.user.role == 7) {
      this.getListPGD({
        maSo: this.user.maSo
      });

    }
  },
  mounted() {
    console.log("Mounted Đồng bộ dữ liệu")
    this.getDanhSachSo();
    this.searchForm.namHoc = this.nam_hoc_hien_tai;
    this.setCapHoc();
    this.getKhoiHoc();
    if (this.user.role == 4) {
      this.typeSync = constant.DONG_BO_TRUONG;
    } else if (this.user.role == 7) {
      this.typeSync = constant.DONG_BO_SO;
    } else if (this.user.role == 8) {
      this.typeSync = constant.DONG_BO_ADMIN;
    } else if (this.user.role == 1 || this.user.role == 2) {
      this.typeSync = constant.DONG_BO_GIAO_VIEN;
      this.searchForm.typeSync = 4;
    } else {
      this.typeSync = constant.DONG_BO_PHONG;
    }
    if(this.user.maSo==79||this.user.maSo=='08'){
      this.typeSync.push(
          {
            VALUE : 14,
            NAME : 'Sổ điểm',

          })
    }
    this.getHistorySync({
      start: this.paginate.start,
      limit: this.paginate.limit,
      maTruong: this.user.maTruong,
      // maPhong: this.user.maPhong,
      maSo: this.user.maSo,
    })
    if (this.user.role != 8) {
      this.getTrangThaiChot()
    }
  },
  watch: {
    listSchool(newVal) {
      if (newVal) {
        let dulieu = newVal;
        let ds_truong = [];
        for (let i = 0; i < dulieu.length; i++) {
          let obj = {
            name: dulieu[i].tenTruongHoc + " - [" + dulieu[i].maTruongHoc + "]",
            value: dulieu[i].maTruongHoc
          }
          ds_truong.push(obj)
        }
        this.list_truong = ds_truong;
      }
    },
    dongBo(val) {
      if (val) {
        this.fullscreenLoading = false;
        dialog.showDialog('Thông báo', val.msg);
        this.getHistorySync({
          start: this.paginate.start,
          limit: this.paginate.limit,
          maTruong: this.user.maTruong,
          // maPhong: this.user.maPhong,
          maSo: this.user.maSo,
        })
      }
    }
  }
}
</script>

<style scoped>
.el-form-item {
  width: 100%
}

.resultText {
  white-space: nowrap;
  /*width: 100px;*/
  overflow: hidden;
  text-overflow: ellipsis;
  /*border: 1px solid #000000;*/
}

.showMore {
  background: #409EFF;
  padding: 1px 5px;
  border-radius: 3px;
  color: white;
  font-weight: 700;
}
</style>