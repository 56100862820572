<template>
  <div class="page">
    <div class="page__title d-flex align-items-center justify-content-between"
         v-loading.fullscreen.lock="loadingScreen">
      <h1 class="title">Tổng quan</h1>
    </div>
    <div class="box-upload-file">
      <el-row :gutter="24">
        <el-col :xs="12" :sm="8" :md="6" :lg="3" :xl="3">
          <label>Năm học</label>
          <eselect style="width: 100%" collapseTags v-model="dataSearch.namHoc" :placeholder="'Chọn'"
                   filterable :data="list_nam_hoc" :fields="['name','value']"/>
        </el-col>
        <el-col v-if="user.role!=4" :xs="12" :sm="8" :md="6" :lg="6" :xl="6">
          <label>Đơn vị</label>
          <eselect :disabled="user.role!=7" style="width: 100%" collapseTags @change="chonDonVi()"
                   v-model="dataSearch.donVi"
                   :placeholder="'Chọn'"
                   filterable :data="list_don_vi" :fields="['name','value']"/>
        </el-col>
        <el-col :xs="12" :sm="8" :md="6" :lg="3" :xl="3">
          <label>Cấp học</label>
          <eselect :disabled="list_cap_hoc.length==1" style="width: 100%" @change="chonDonVi()" collapseTags
                   v-model="dataSearch.capHoc"
                   :placeholder="'Chọn'"
                   filterable :data="list_cap_hoc" :fields="['name','value']"/>
        </el-col>
        <el-col v-if="user.role!=4" :xs="12" :sm="8" :md="6" :lg="6" :xl="6">
          <label>Trường học</label>
          <eselect style="width: 100%" collapseTags v-model="dataSearch.truongHoc" :placeholder="'Chọn'"
                   filterable :data="list_truong_hoc" :fields="['name','value']"/>
        </el-col>
        <el-col :span="6">
          <div style="color: transparent">Tìm kiếm</div>
          <el-button class="m-0" type="primary" @click.p.prevent="getAllDataChart()">Tìm kiếm</el-button>
          <el-button class="m-0" type="primary" @click.p.prevent="tongHopBaoCao()">Tổng hợp báo cáo</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="page__header mt-4">
      <el-row :gutter="24">
        <el-col :span="24">
          <h4 style="color: #102e6a; margin: 0">Biểu đồ cập nhật dữ liệu</h4>
        </el-col>
        <el-col :sm="12" :md="12" :lg="12">
          <div class="grid-content">
            <div class="dts-moibieudo grid-content bg-purple-light">
              <div class="dts-card-header">
                <span class="dts-card-title">Trạng thái cập nhật dữ liệu học bạ</span>
                <span class="dts-right">
                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="getTrangThaiCapNhatDuLieuHocBa"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>
              </div>
              <div class="dts-card-body" v-loading="loading.trangThaiCapNhatDuLieuHocBa"
                   element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
                   element-loading-background="rgba(255, 255, 255, 0.8)">
                <div v-if="duLieu.trangThaiCapNhatDuLieuHocBa.du_lieu">
                  <BieuDoCotDistributed
                      :dulieu="duLieu.trangThaiCapNhatDuLieuHocBa"></BieuDoCotDistributed>
                </div>
                <div class="text-center" v-else>
                  <p>Biểu đồ chưa có dữ liệu</p>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :sm="12" :md="12" :lg="12">
          <div class="grid-content">
            <div class="dts-moibieudo grid-content bg-purple-light">
              <div class="dts-card-header">
                <span class="dts-card-title">Trạng thái chốt dữ liệu học bạ</span>
                <span class="dts-right">
                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="getTrangThaiChotDuLieuHocBa"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>
              </div>
              <div class="dts-card-body" v-loading="loading.trangThaiChotDuLieuHocBa"
                   element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
                   element-loading-background="rgba(255, 255, 255, 0.8)">
                <div v-if="duLieu.trangThaiChotDuLieuHocBa.du_lieu">
                  <BieuDoCotDistributed
                      :dulieu="duLieu.trangThaiChotDuLieuHocBa"></BieuDoCotDistributed>
                </div>
                <div class="text-center" v-else>
                  <p>Biểu đồ chưa có dữ liệu</p>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col class="mt-5" :sm="12" :md="12" :lg="12">
          <div class="grid-content">
            <div class="dts-moibieudo grid-content bg-purple-light">
              <div class="dts-card-header">
                <span class="dts-card-title">Trạng thái đăng tải chữ ký</span>
                <span class="dts-right">
                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="getTrangThaiDangTaiChuKy"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>
              </div>
              <div class="dts-card-body" v-loading="loading.trangThaiDangTaiChuKy"
                   element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
                   element-loading-background="rgba(255, 255, 255, 0.8)">
                <div v-if="duLieu.trangThaiDangTaiChuKy.du_lieu">
                  <BieuDoCotDistributed
                      :dulieu="duLieu.trangThaiDangTaiChuKy">
                  </BieuDoCotDistributed>
                </div>
                <div class="text-center" v-else>
                  <p>Biểu đồ chưa có dữ liệu</p>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col class="mt-5" :span="24">
          <h4 style="color: #102e6a; margin: 0">Biểu đồ ký học bạ</h4>
        </el-col>
        <el-col :sm="12" :md="12" :lg="12" v-if="dataSearch.capHoc!=1">
          <div class="grid-content">
            <div class="dts-moibieudo grid-content bg-purple-light">
              <div class="dts-card-header">
                <span class="dts-card-title">Tỷ lệ giáo viên bộ môn ký học bạ</span>
                <span class="dts-right">
                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="getTyLeGiaoVienBoMonkyHocBa"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>
              </div>
              <div class="dts-card-body" v-loading="loading.tyLeGiaoVienBoMonKyHocBa"
                   element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
                   element-loading-background="rgba(255, 255, 255, 0.8)">
                <div v-if="duLieu.tyLeGiaoVienBoMonKyHocBa.du_lieu">
                  <BieuDoCotDistributedSw
                      :dulieu="duLieu.tyLeGiaoVienBoMonKyHocBa"></BieuDoCotDistributedSw>
                </div>
                <div class="text-center" v-else>
                  <p>Biểu đồ chưa có dữ liệu</p>
                </div>

              </div>
            </div>
          </div>
        </el-col>
        <el-col :sm="12" :md="12" :lg="12">
          <div class="grid-content">
            <div class="dts-moibieudo grid-content bg-purple-light">
              <div class="dts-card-header">
                <span class="dts-card-title">Số lượng học bạ ký theo giáo viên chủ nhiệm</span>
                <span class="dts-right">
                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="getSoLuongHocBaKyTheoGvcn"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>
              </div>
              <div class="dts-card-body" v-loading="loading.soLuongHocBaKyTheoGvcn"
                   element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
                   element-loading-background="rgba(255, 255, 255, 0.8)">
                <div v-if="duLieu.soLuongHocBaKyTheoGvcn.du_lieu">
                  <BieuDoCotDistributedSw
                      :dulieu="duLieu.soLuongHocBaKyTheoGvcn"></BieuDoCotDistributedSw>
                </div>
                <div class="text-center" v-else>
                  <p>Biểu đồ chưa có dữ liệu</p>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :class="{'mt-5':dataSearch.capHoc!=1}" :sm="12" :md="12" :lg="12">
          <div class="grid-content">
            <div class="dts-moibieudo grid-content bg-purple-light">
              <div class="dts-card-header">
                <span class="dts-card-title">Số lượng học bạ ký theo Hiệu trưởng</span>
                <span class="dts-right">
                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="getSoLuongHocBaKyTheoHieuTruong"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>
              </div>
              <div class="dts-card-body" v-loading="loading.soLuongHocBaKyTheoHieuTruong"
                   element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
                   element-loading-background="rgba(255, 255, 255, 0.8)">
                <div v-if="duLieu.soLuongHocBaKyTheoHieuTruong.du_lieu">
                  <BieuDoCotDistributedSw
                      :dulieu="duLieu.soLuongHocBaKyTheoHieuTruong"></BieuDoCotDistributedSw>
                </div>
                <div class="text-center" v-else>
                  <p>Biểu đồ chưa có dữ liệu</p>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="page__container">
      <el-dialog title="Biểu đồ chi tiết" top="5vh" center custom-class="thongTinLopHoc"
                 :visible.sync="show_bieu_do_chi_tiet">
        <el-row :gutter="24">
          <el-col :span="24">
            <div class="grid-content">
              <div class="dts-moibieudo grid-content bg-purple-light">
                <div class="dts-card-header">
                  <span class="dts-card-title">Biểu đồ chi tiết trạng thái cập nhật học bạ </span>
                  <span class="dts-right">
                                    <el-tooltip content="Lấy lại dữ liệu" placement="top">
                                        <a href="#" @click.prevent="getTrangThaiDangTaiChuKy()"><i
                                            class="el-icon-refresh"></i></a>
                                    </el-tooltip>
                                </span>
                </div>
                <div class="dts-card-body" v-loading="loading.trangThaiDangTaiChuKy"
                     element-loading-text="Loading..." element-loading-spinner="el-icon-loading"
                     element-loading-background="rgba(255, 255, 255, 0.8)">
                  <div>
                    <BieuDoCotSw
                        :dulieu="duLieu.trangThaiDangTaiChuKy"></BieuDoCotSw>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import constant_api from "../_helpers/constant_api";
import api from "../_helpers/api";
import {mapActions, mapState} from "vuex";
import ApexCharts from 'vue-apexcharts';
import BieuDoCotDistributed from "./Dashboard/BieuDoCot";
import BieuDoCotDistributedSw from "./Dashboard/BieuDoCot2";
import BieuDoCotSw from "./Dashboard/BieuDoCotScorllWidth";
import constant from "../_helpers/constant_api";
import ESelectVue from "./Ui/ESelect";
import BieuDoTrangThaiTheoMon from "./Dashboard/BieuDoTrangThaiTheoMon";

export default {
  name: "ThongKeBaoCao",
  metaInfo: {
    title: 'Tổng quan',
    meta: [
      {vmid: 'description', name: 'description', content: ''}
    ],
  },
  components: {
    apexchart: ApexCharts,
    BieuDoCotDistributed: BieuDoCotDistributed,
    BieuDoCotDistributedSw: BieuDoCotDistributedSw,
    BieuDoCotSw: BieuDoCotSw,
    BieuDoTrangThaiTheoMon,
    'eselect': ESelectVue,
  },
  data() {
    return {
      show_bieu_do_chi_tiet: false,
      loadingScreen: false,
      list_don_vi: [],
      list_truong_hoc: [],
      dataSearch: {
        namHoc: '',
        donVi: '',
        capHoc: '',
        truongHoc: ''
      },
      loading: {
        trangThaiDangTaiChuKy: false,
        trangThaiCapNhatDuLieuHocBa: false,
        trangThaiChotDuLieuHocBa: false,
        soLuongHocBaKyTheoBoMon: false,
        trangThaiThongKeDongDauHieuTruong: false,
        soLuongHocBaKyTheoGvcn: false,
        soLuongHocBaKyTheoHieuTruong: false,
        duLieuBieuDoTrangThaiHocBaTheoMonHoc: false,
      },
      duLieu: {
        tyLeGiaoVienBoMonKyHocBa: {
          list_lop: [],
          du_lieu: []
        },
        duLieuBieuDoTrangThaiHocBaTheoMonHoc: {},
        trangThaiDangTaiChuKy: {
          "list_lop": [
            "Đã duyệt",
            "Chưa duyệt",
            "Chưa cập nhật",
          ],
          "du_lieu": [
            {
              "name": "",
              "data": [
                5,
                8,
                3,
              ]
            },
          ]
        },
        trangThaiCapNhatDuLieuHocBa: {},
        soLuongHocBaKyTheoBoMon: {},
        trangThaiChotDuLieuHocBa: {},
        soLuongHocBaKyTheoGvcn: {},
        trangThaiThongKeDongDauHieuTruong: {},
        soLuongHocBaKyTheoHieuTruong: {},
        demoChiTietDuLieu: {
          "list_lop": [
            "Lớp 6A",
            "Lớp 6B",
            "Lớp 6C",
            "Lớp 6D",
            "Lớp 7A",
            "Lớp 7B",
            "Lớp 7C",
            "Lớp 7D",
          ],
          "du_lieu": [
            {
              "name": "Đã cập nhật",
              "data": [
                5,
                8,
                3,
                4,
                7,
                6,
                4,
                6
              ]
            },
            {
              "name": "Chưa cập nhật",
              "data": [
                2,
                9,
                7,
                6,
                1,
                9,
                8,
                4
              ]
            }
          ]
        }
      }
    }
  },
  computed: {
    ...mapState('account', [
      'user',
      'nam_hoc_hien_tai',
      'list_nam_hoc',
      'list_cap_hoc',
    ]),
  },
  mounted() {
    console.error('Thông tin:')
    console.error(this.user)
    this.dataSearch.namHoc = this.nam_hoc_hien_tai;
    this.getDanhSachDonVi();
    if (this.user.role == 4) {
      this.dataSearch.truongHoc = this.user.maTruong
    }
    if (this.list_cap_hoc.length == 1) {
      this.dataSearch.capHoc = this.list_cap_hoc[0].value
    }
    if (this.user.role == 6) {
      this.dataSearch.donVi = this.user.maPhong;
    }
    this.getAllDataChart();
  },
  methods: {
    showClick(e) {
      console.log('showClick')
      console.log(e)
      this.loadingScreen = true;
      setTimeout(() => {
        this.loadingScreen = false;
        this.show_bieu_do_chi_tiet = true;
      }, 500)
    },
    chonDonVi() {
      this.dataSearch.truongHoc = '';
      this.list_truong_hoc = [];
      this.getDanhSachTruongHoc();
    },
    getDanhSachTruongHoc() {
      let params = {
        maPhong: this.dataSearch.donVi,
        capHoc: this.dataSearch.capHoc,
      }
      let url = constant.danhMuc.danhSachTruong;
      this.list_truong_hoc = [];
      this.loadingScreen = true;
      api.get(url, params).then(
          response => {
            if (response.data.code == 200) {
              let data = response.data.data;
              for (let i = 0; i < data.length; i++) {
                this.list_truong_hoc.push(
                    {
                      name: data[i].tenTruongHoc + ' - [' + data[i].maTruongHoc + ']',
                      value: data[i].maTruongHoc
                    }
                )
              }

            } else {
            }
            console.error(this.list_truong_hoc)
            this.loadingScreen = false;
          }
      )
    },
    getDanhSachDonVi() {
      let params = {
        maSo: this.user.maSo,
      }
      let url = constant.danhMuc.danhSachDonVi;
      this.list_don_vi = [];
      this.loadingScreen = true;
      api.get(url, params).then(
          response => {
            if (response.data.code == 200) {
              let data = response.data.data;
              for (let i = 0; i < data.length; i++) {
                this.list_don_vi.push(
                    {
                      name: data[i].tenDonVi.replace("Giáo dục và Đào tạo", "GD&ĐT") + ' - [' + data[i].maDonVi + ']',
                      value: data[i].maDonVi
                    }
                )
              }

            } else {
            }
            console.error(this.list_don_vi)
            this.loadingScreen = false;
          }
      )
    },
    getAllDataChart() {
      this.getTrangThaiDangTaiChuKy();
      this.getTrangThaiCapNhatDuLieuHocBa();
      this.getTrangThaiChotDuLieuHocBa();
      this.getTyLeGiaoVienBoMonkyHocBa();
      this.getSoLuongHocBaKyTheoBoMon();
      this.getSoLuongHocBaKyTheoGvcn();
      this.getSoLuongHocBaKyTheoHieuTruong();
      // this.getTrangThaiThongKeDongDauHieuTruong();
    },
    tongHopBaoCao() {
      console.log('Tổng hợp báo cáoc')
      let url = constant.thongKeBaoCao.tongHopBaoCao;
      let params = {
        namHoc:this.dataSearch.namHoc
      }
      this.loadingScreen = true;
      api.get(url, params).then(
          response => {
            if (response.data.code == 200) {
              this.thongBao('success',response.data.msg)
            } else {
              this.thongBao('error',response.data.msg)
            }
            this.loadingScreen = false;
          }
      )
    },
    thongBao(t, e) {
      let msg = "";
      let cl = "";
      if (e) {
        msg = e;
      }
      let type = "success";
      if (t) {
        type = t
      }
      if (type == "success") {
        cl = "dts-noty-success"
      }
      if (type == "warning") {
        cl = "dts-noty-warning"
      }
      if (type == "error") {
        cl = "dts-noty-error"
      }
      if (type == "info") {
        cl = "dts-noty-info"
      }
      this.$message({
        customClass: cl,
        showClose: true,
        message: msg,
        type: t,
        duration: 3000
      });
    },
    getTrangThaiThongKeDongDauHieuTruong() {
      this.loading.trangThaiThongKeDongDauHieuTruong = true;
      let params = {
        namHoc: this.dataSearch.namHoc,
        maTruong: this.dataSearch.truongHoc,
        maPhong: this.dataSearch.donVi,
        maSo: this.user.maSo,
        capHoc: this.dataSearch.capHoc
      }
      let url = constant.thongKeBaoCao.trangThaiThongKeDongDauHieuTruong;
      this.duLieu.trangThaiThongKeDongDauHieuTruong = {}
      this.loadingScreen = true;
      api.get(url, params).then(
          response => {
            if (response.data.code == 200) {
              let duLieu = response.data.data.data;
              let tieuDe = response.data.data.tieuDes;
              this.duLieu.trangThaiThongKeDongDauHieuTruong = {
                list_lop: tieuDe,
                du_lieu: duLieu
              }
            } else {
            }
            this.loading.trangThaiThongKeDongDauHieuTruong = false;
            this.loadingScreen = false;
          }
      )
    },
    getSoLuongHocBaKyTheoGvcn() {
      console.log('soLuongHocBaKyTheoGvcn')
      this.loading.soLuongHocBaKyTheoGvcn = true;
      let params = {
        namHoc: this.dataSearch.namHoc,
        maTruong: this.dataSearch.truongHoc,
        maPhong: this.dataSearch.donVi,
        maSo: this.user.maSo,
        capHoc: this.dataSearch.capHoc
      }
      let url = constant.thongKeBaoCao.soLuongHocBaKyTheoGvcn;
      this.duLieu.soLuongHocBaKyTheoGvcn = {}
      this.loadingScreen = true;
      api.get(url, params).then(
          response => {
            if (response.data.code == 200) {
              let duLieu = response.data.data.data;
              let tieuDe = response.data.data.tieuDes;
              this.duLieu.soLuongHocBaKyTheoGvcn = {
                list_lop: tieuDe,
                du_lieu: duLieu
              }
            } else {
            }
            this.loading.soLuongHocBaKyTheoGvcn = false;
            this.loadingScreen = false;
          }
      )
    },
    getSoLuongHocBaKyTheoHieuTruong() {
      this.loading.soLuongHocBaKyTheoHieuTruong = true;
      let params = {
        namHoc: this.dataSearch.namHoc,
        maTruong: this.dataSearch.truongHoc,
        maPhong: this.dataSearch.donVi,
        maSo: this.user.maSo,
        capHoc: this.dataSearch.capHoc
      }
      let url = constant.thongKeBaoCao.soLuongHocBaKyTheoHieuTruong;
      this.duLieu.soLuongHocBaKyTheoHieuTruong = {}
      this.loadingScreen = true;
      api.get(url, params).then(
          response => {
            if (response.data.code == 200) {
              let duLieu = response.data.data.data;
              let tieuDe = response.data.data.tieuDes;
              this.duLieu.soLuongHocBaKyTheoHieuTruong = {
                list_lop: tieuDe,
                du_lieu: duLieu
              }
            } else {
            }
            this.loading.soLuongHocBaKyTheoHieuTruong = false;
            this.loadingScreen = false;
          }
      )
    },
    getSoLuongHocBaKyTheoBoMon() {
      this.loading.duLieuBieuDoTrangThaiHocBaTheoMonHoc = true;
      let params = {
        namHoc: this.dataSearch.namHoc,
        maTruong: this.dataSearch.truongHoc,
        maPhong: this.dataSearch.donVi,
        maSo: this.user.maSo,
        capHoc: this.dataSearch.capHoc
      }
      let url = constant.thongKeBaoCao.soLuongHocBaKyTheoBoMon;
      this.duLieu.duLieuBieuDoTrangThaiHocBaTheoMonHoc = {}
      this.loadingScreen = true;
      api.get(url, params).then(
          response => {
            if (response.data.code == 200) {
              let duLieu = response.data.data.data;
              let tieuDe = response.data.data.tieuDes;
              this.duLieu.duLieuBieuDoTrangThaiHocBaTheoMonHoc = {
                list_lop: tieuDe,
                du_lieu: duLieu
              }
            } else {
            }
            this.loading.duLieuBieuDoTrangThaiHocBaTheoMonHoc = false;
            this.loadingScreen = false;
          }
      )
    },
    getTrangThaiChotDuLieuHocBa() {
      this.loading.trangThaiChotDuLieuHocBa = true;
      let params = {
        namHoc: this.dataSearch.namHoc,
        maTruong: this.dataSearch.truongHoc,
        maPhong: this.dataSearch.donVi,
        maSo: this.user.maSo,
        capHoc: this.dataSearch.capHoc
      }
      let url = constant.thongKeBaoCao.trangThaiChotHocBa;
      this.duLieu.trangThaiChotDuLieuHocBa = {}
      this.loadingScreen = true;
      api.get(url, params).then(
          response => {
            if (response.data.code == 200) {
              let duLieu = response.data.data.data;
              let tieuDe = response.data.data.tieuDes;
              this.duLieu.trangThaiChotDuLieuHocBa = {
                list_lop: tieuDe,
                du_lieu: duLieu
              }
            } else {
            }
            this.loading.trangThaiChotDuLieuHocBa = false;
            this.loadingScreen = false;
          }
      )
    },
    getTyLeGiaoVienBoMonkyHocBa() {
      this.loading.tyLeGiaoVienBoMonKyHocBa = true;
      let params = {
        namHoc: this.dataSearch.namHoc,
        maTruong: this.dataSearch.truongHoc,
        maPhong: this.dataSearch.donVi,
        maSo: this.user.maSo,
        capHoc: this.dataSearch.capHoc
      }
      let url = constant.thongKeBaoCao.soLuongHocBaKyTheoBoMon;
      this.duLieu.tyLeGiaoVienBoMonKyHocBa = {}
      this.loadingScreen = true;
      api.get(url, params).then(
          response => {
            if (response.data.code == 200) {
              let duLieu = response.data.data.data;
              let tieuDe = response.data.data.tieuDes;
              this.duLieu.tyLeGiaoVienBoMonKyHocBa = {
                list_lop: tieuDe,
                du_lieu: duLieu
              }
            } else {
            }
            this.loading.tyLeGiaoVienBoMonKyHocBa = false;
            this.loadingScreen = false;
          }
      )
    },
    getTrangThaiCapNhatDuLieuHocBa() {
      this.loading.trangThaiCapNhatDuLieuHocBa = true;
      let params = {
        namHoc: this.dataSearch.namHoc,
        maTruong: this.dataSearch.truongHoc,
        maPhong: this.dataSearch.donVi,
        maSo: this.user.maSo,
        capHoc: this.dataSearch.capHoc
      }
      let url = constant.thongKeBaoCao.trangThaiCapNhatDuLieuHocBa;
      this.duLieu.trangThaiCapNhatDuLieuHocBa = {}
      this.loadingScreen = true;
      api.get(url, params).then(
          response => {
            if (response.data.code == 200) {
              let duLieu = response.data.data.data;
              let tieuDe = response.data.data.tieuDes;
              this.duLieu.trangThaiCapNhatDuLieuHocBa = {
                list_lop: tieuDe,
                du_lieu: duLieu
              }
            } else {
            }
            this.loading.trangThaiCapNhatDuLieuHocBa = false;
            this.loadingScreen = false
          }
      )
    },
    getTrangThaiDangTaiChuKy() {
      this.loading.trangThaiDangTaiChuKy = true;
      let params = {
        namHoc: this.dataSearch.namHoc,
        maTruong: this.dataSearch.truongHoc,
        maPhong: this.dataSearch.donVi,
        maSo: this.user.maSo,
        capHoc: this.dataSearch.capHoc
      }
      let url = constant.thongKeBaoCao.trangThaiDangTaiChuKy;
      this.duLieu.trangThaiDangTaiChuKy = {}
      this.loadingScreen = true;
      api.get(url, params).then(
          response => {
            if (response.data.code == 200) {
              let duLieu = response.data.data.data;
              let tieuDe = response.data.data.tieuDes;
              this.duLieu.trangThaiDangTaiChuKy = {
                list_lop: tieuDe,
                du_lieu: duLieu
              }
              // this.duLieu.trangThaiDangTaiChuKy = this.duLieu.demoChiTietDuLieu;
            } else {
            }
            this.loading.trangThaiDangTaiChuKy = false;
            this.loadingScreen = false;
          }
      )
    },
  }
}
</script>

<style scoped>
.el-col {
  padding-top: 15px;
}
</style>