<template>
  <div class="page">
    <div class="page__title d-flex align-items-center justify-content-between">
      <h1 class="title">Nhập kết quả học tập</h1>
      <el-tooltip class="item" effect="dark" content="Hướng dẫn nhập dữ liệu" placement="top">
        <a href="/files/huong_dan_nhap_du_lieu.docx"> Hướng dẫn nhập dữ liệu</a>
      </el-tooltip>
    </div>

    <div class="page__header">
      <el-row :gutter="20">
        <el-col :xs="12" :sm="6" :md="4" :lg="4">
            <label>Năm học</label>
          <eselect style="width:100%" collapseTags v-model="namHoc" placeholder="Chọn" filterable
                   :data="list_nam_hoc" :fields="['name','value']"/>
        </el-col>
        <el-col :xs="12" :sm="6" :md="4" :lg="4">
            <label>Cấp học</label>
          <eselect style="width:100%" collapseTags v-model="capHoc" placeholder="Chọn" filterable
                   :data="list_cap_hoc" :fields="['name','value']"/>
        </el-col>
      </el-row>

    </div>

    <div v-if="capHoc && (capHoc == 2 || capHoc == 3)">
      <div class="page__header">
        <div class="page__title align-items-center justify-content-between">
          <h2 class="title">Điểm tổng kết môn</h2>
          <small><i> (Truy cập Học bạ số -> Nhập kết quả học tập -> Tải file mẫu Điểm tổng kết môn)</i></small>
<!--          <p v-if="user.qlnt==1"><i style="font-size:12px">(Truy cập vnedu -> Tổng kết -> Chọn khối -> Chọn học kỳ -> Xuất ra excel toàn khối)</i></p>-->
<!--          <p v-if="user.qlnt==2"><i style="font-size:12px">(Truy cập moet -> Học sinh -> Kết quả học tập rèn luyện -> Nhập tổng kết kết quả học tập từ excel -> Chọn khối -> Chọn học kỳ -> Tải file mẫu kèm dữ liệu)</i></p>-->
        </div>
<!--        <el-row :gutter="20">-->
<!--          <el-col :xs="12" :sm="6" :md="4" :lg="4">-->
<!--            <label>Phương thức nhập (<i style="color: red;">*</i>)</label>-->
<!--            <eselect class="mb-2" style="width:111.5%" collapseTags v-model="typeDiemTongKet" filterable-->
<!--                   :data="this.user.qlnt==1? listPhuongThucNhapVnEdu : listPhuongThucNhapMoet" :fields="['name','value']" />-->
<!--          </el-col>-->
<!--      </el-row>-->
      <ImportForNhanXet
            :year="namHoc"
            :cap-hoc="capHoc"
            :fileNameError="'danh_sach_loi_nhap_diem_tong_ket_mon'"
            :require-nam-hoc="true"
            :require-cap-hoc="true"
            :requireImportType="true"
            :data-type="11"
            :requireLstHocKy="true"
            :text-download="'Tải file nhập Điểm tổng kết môn'"
            :require-khoi-hoc="getRequireKhoiHoc()"
            :uri-dowload="downloadDiemTongKet"
            :source="sourceDiemTongKet"
            :requireKhoiHoc="true"
            :hideClass="false"
            :path="typeDiemTongKet === 1? path : pathDiemTongKetChung"
            :disabledDownload="typeDiemTongKet!= 2"
            :importType="typeDiemTongKet"
            name-file="diem-tong-ket"></ImportForNhanXet>
        <!-- <ImportManySheet
            :require-cap-hoc="true"
            :require-nam-hoc="true"
            :requireImportType="true"
            :year="namHoc"
            :capHoc="capHoc"
            :require-khoi-hoc="true"
            :uri-dowload="downloadDiemTongKet"
            :template-download="template"pathSampleFile
            :source="source"
            :hideClass="false"
            :path="typeDiemTongKet === 1? path : pathDiemTongKetChung"
            :importType="typeDiemTongKet"></ImportManySheet> -->
      </div>

      <!--            <div class="page__header">-->
      <!--                <div class="page__title d-flex align-items-center justify-content-between ">-->
      <!--                    <h2 class="title">Hạnh kiểm</h2>-->
      <!--                </div>-->
      <!--                <ImportManySheet-->
      <!--                    :require-cap-hoc="true"-->
      <!--                    :require-nam-hoc="true"-->
      <!--                    :year="namHoc"-->
      <!--                    :cap-hoc="capHoc"-->
      <!--                    :template-download="template"-->
      <!--                    :source="source"-->
      <!--                    :path="path2"></ImportManySheet>-->
      <!--            </div>-->

      <div class="page__header">
        <h5 class="title">Điểm thi lại, học lại</h5>
        <small><i> (Truy cập Học bạ số -> Nhập kết quả học tập -> Tải file mẫu Điểm thi lại, học lại)</i></small>
<!--        <p><i style="font-size:12px">(Truy cập Học bạ số -> Nhập dữ liệu -> Nhập kết quả học tập -> Rèn luyện hè -> Tải dữ liệu học sinh)</i></p>-->
<!--        <el-row :gutter="20">-->
<!--          <el-col :xs="12" :sm="6" :md="4" :lg="4">-->
<!--            <label>Phương thức nhập (<i style="color: red;">*</i>)</label>-->
<!--            <eselect class="mb-2" style="width:111.5%%" :disabled="typeRenLuyenHe>1" collapseTags placeholder="Chọn" v-model="typeRenLuyenHe" filterable-->
<!--                   :data="this.user.qlnt==1? listPhuongThucNhapVnEdu : listPhuongThucNhapMoet" :fields="['name','value']" />-->
<!--          </el-col>-->
<!--        </el-row>-->
        <ImportForNhanXet
            :year="namHoc"
            :cap-hoc="capHoc"
            :require-nam-hoc="true"
            :fileNameError="'danh_sach_loi_nhap_ren_luyen_he'"
            :require-cap-hoc="true"
            :requireImportType="true"
            :text-download="'Tải file nhập Điểm thi lại, học lại'"
            :data-type="12"
            :requireKhoiHoc="true"
            :hideClass="false"
            :uri-dowload="dowloadRenLuyenHe" :source="sourceRenLuyenHe" :path="typeRenLuyenHe === 1? pathRenLuyenHe : pathRenLuyenHeChung"
            name-file="ren-luyen-he" :importType="typeRenLuyenHe"></ImportForNhanXet>
      </div>

      <div class="page__header">
        <h5 class="title">Nghề Phổ Thông</h5>
        <small><i> (Truy cập Học bạ số -> Nhập kết quả học tập -> Tải file mẫu Nghề phổ thông)</i></small>
<!--        <p><i style="font-size:12px">(Truy cập Học bạ số -> Nhập dữ liệu -> Nhập kết quả học tập -> Nghề phổ thông -> Tải dữ liệu học sinh)</i></p>-->
<!--        <el-row :gutter="20">-->
<!--          <el-col :xs="12" :sm="6" :md="4" :lg="4">-->
<!--            <label>Phương thức nhập (<i style="color: red;">*</i>)</label>-->
<!--            <eselect class="mb-2" :disabled="typeNghePT>=2" style="width:111.5%" collapseTags placeholder="Chọn" v-model="typeNghePT" filterable-->
<!--                   :data="this.user.qlnt==1? listPhuongThucNhapVnEdu : listPhuongThucNhapMoet" :fields="['name','value']" />-->
<!--          </el-col>-->
<!--        </el-row>-->
        <ImportForNhanXet
            :year="namHoc"
            :cap-hoc="capHoc"
            :require-nam-hoc="true"
            :require-cap-hoc="true"
            :requireKhoiHoc="true"
            :hideClass="false"
            :text-download="'Tải file nhập Nghề phổ thông'"
            :data-type="13"
            :fileNameError="'danh_sach_loi_nhap_nghe_pho_thong'"
            :requireImportType="true"
            :uri-dowload="dowloadNghePT" :source="sourceNghePT" :path="typeNghePT === 1? pathNghePT : pathNghePTChung"
            name-file="nghe-pt" :importType="typeNghePT"></ImportForNhanXet>
      </div>

      <div v-show="capHoc==3&&(user.maSo==96||user.maSo==62)" class="page__header">
        <h5 class="title">Nhận xét môn GDCD</h5>
        <small><i> (Truy cập Học bạ số -> Nhập kết quả học tập -> Tải file mẫu Nhận xét môn GDCD)</i></small>
<!--        <p><i style="font-size:12px">(Truy cập Học bạ số -> Nhập dữ liệu -> Nhập kết quả học tập -> Nhận xét GDCD -> Tải dữ liệu học sinh)</i></p>-->
<!--        <el-row :gutter="20">-->
<!--          <el-col :xs="12" :sm="6" :md="4" :lg="4">-->
<!--            <label>Phương thức nhập (<i style="color: red;">*</i>)</label>-->
<!--            <eselect class="mb-2" style="width:111.5%" :disabled="typeNhanXetGDCD>1" collapseTags placeholder="Chọn" v-model="typeNhanXetGDCD" filterable-->
<!--                   :data="this.user.qlnt==1? listPhuongThucNhapVnEdu : listPhuongThucNhapMoet" :fields="['name','value']" />-->
<!--          </el-col>-->
<!--        </el-row>-->
        <ImportForNhanXet
            :year="namHoc"
            :isGdcd="true"
            :cap-hoc="capHoc"
            :fileNameError="'danh_sach_loi_nhap_nhan_xet_gdcd'"
            :require-nam-hoc="true"
            :require-cap-hoc="true"
            :requireKhoiHoc="true"
            :hideClass="false"
            :text-download="'Tải file nhập Nhận xét môn GDCD'"
            :requireImportType="true"
            :uri-dowload="typeNhanXetGDCD === 1? dowloadNhanXetGdcd : downloadNhanXetGdcdChung" 
            :source="sourceNhanXetGDCD" :path="typeNhanXetGDCD === 1? pathNhanXetGDCD : pathNhanXetGDCDChung"
            name-file="nx-gdcd" :importType="typeNhanXetGDCD" ></ImportForNhanXet>
        
      </div>
    </div>

    <div v-if="capHoc && capHoc == 1">
      <!--            <div class="page__header">-->
      <!--                <div class="page__title d-flex align-items-center justify-content-between">-->
      <!--                    <h2 class="title">Sổ nhận xét</h2>-->
      <!--                </div>-->
      <!--                <ImportManySheetC1-->
      <!--                    :require-cap-hoc="true"-->
      <!--                    :require-nam-hoc="true"-->
      <!--                    :year="namHoc"-->
      <!--                    :cap-hoc="capHoc"-->
      <!--                    :template-download="template"-->
      <!--                    :source="source"-->
      <!--                    :path="pathSoNhanXet"></ImportManySheetC1>-->
      <!--            </div>-->

      
      <div class="page__header">
        <div class="page__title align-items-center justify-content-between">
          <h1 class="title">Sổ nhận xét</h1>

          <small><i> (Truy cập Học bạ số -> Nhập kết quả học tập -> Tải file mẫu Sổ nhận xét)</i></small>
<!--          <p v-if="user.qlnt==1"><i style="font-size:12px">(Truy cập vnedu -> Sổ nhận xét -> Nhập xuất -> Xuất nhập khác -> Xuất các môn của lớp)</i></p>-->
<!--          <p v-if="user.qlnt==2"><i style="font-size:12px">(Truy cập Học bạ số -> Nhập dữ liệu -> Nhập kết quả học tập -> Sổ nhận xét -> Tải dữ liệu học sinh)</i></p>-->
        </div>
<!--        <el-row :gutter="20">-->
<!--          <el-col :xs="12" :sm="6" :md="4" :lg="4">-->
<!--            <label>Phương thức nhập (<i style="color: red;">*</i>)</label>-->
<!--            <eselect class="mb-2" style="width:111.5%" :disabled="this.user.qlnt==2" collapseTags placeholder="Chọn" v-model="typeSoNhanXet" filterable-->
<!--                   :data="this.user.qlnt==1? listPhuongThucNhapVnEdu:listPhuongThucNhapMoet" :fields="['name','value']" />-->
<!--          </el-col>-->
<!--        </el-row>-->
        <ImportForNhanXet
            :year="namHoc"
            :cap-hoc="capHoc"
            :require-nam-hoc="true"
            :require-cap-hoc="true"
            :data-type="5"
            :fileNameError="'danh_sach_loi_nhap_so_nhan_xet'"
            :requireImportType="true"
            :uri-dowload="downloadSoNhanXetChung"
            :source="source"
            :requireKhoiHoc="true"
            :text-download="'Tải file nhập Sổ nhận xét'"
            :hideClass="false"
            :disabledDownload="false"
            :path="typeSoNhanXet === 1? pathSoNhanXet : pathSoNhanXetChung"
            name-file="so-nhan-xet" :importType="typeSoNhanXet"></ImportForNhanXet>
          <!-- <ImportForNhanXet
            v-if="importType === 2"
            :year="namHoc"
            :cap-hoc="capHoc"
            :require-nam-hoc="true"
            :require-cap-hoc="true"
            :uri-dowload="dowloadSoNhanXet"
            :source="source"
            :path="pathSoNhanXetChung"
            :disabledDownload="user.qlnt!=2"
            name-file="so-nhan-xet" :importType="importType"></ImportForNhanXet> -->
      </div>
      <div class="page__header" v-if="user.qlnt==2">
        <div class="page__title align-items-center justify-content-between">
          <h1 class="title">Điểm tổng kết</h1>
          <small><i> (Truy cập Học bạ số -> Nhập kết quả học tập -> Tải file Điểm tổng kết)</i></small>
<!--          <p v-if="user.qlnt==2"><i style="font-size:12px">(Truy cập moet -> Học sinh -> Kết quả học tập rèn luyện -> Nhập tổng kết kết quả học tập từ excel -> Tải file mẫu)</i></p>-->
        </div>
<!--        <el-row :gutter="20">-->
<!--          <el-col :xs="12" :sm="6" :md="4" :lg="4">-->
<!--            <label>Phương thức nhập (<i style="color: red;">*</i>)</label>-->
<!--            <eselect class="mb-2" style="width:111.5%" collapseTags placeholder="Chọn" v-model="typeDiemTongKetC1Moet" filterable-->
<!--                   :data="this.user.qlnt==1? listPhuongThucNhapVnEdu: listPhuongThucNhapMoet" :fields="['name','value']" />-->
<!--          </el-col>-->
<!--        </el-row>-->
        <ImportForNhanXet
            :year="namHoc"
            :cap-hoc="capHoc"
            :require-nam-hoc="true"
            :data-type="7"
            :text-download="'Tải file nhập Điểm tổng kết'"
            :require-cap-hoc="true"
            :requireKhoiHoc="true"
            :fileNameError="'danh_sach_loi_nhap_diem_tong_ket'"
            :requireImportType="true"
            :require-khoi-hoc="getRequireKhoiHoc()"
            :uri-dowload="downloadDiemTongKetC1"
            :source="sourceDiemTongKet"
            :path="typeDiemTongKetC1Moet == 1? pathDiemtTongKetC1 : pathDiemTongKetC1Chung"
            :disabledDownload="typeDiemTongKetC1Moet != 2"
            :importType="typeDiemTongKetC1Moet"
            :hideClass="false"
            name-file="diem-tong-ket"></ImportForNhanXet>
      </div>
      <div v-if="user.maSo!=89" class="page__header">
        <div class="page__title align-items-center justify-content-between">
          <h1 class="title">Năng lực - Phẩm chất</h1>
          <small><i> (Truy cập Học bạ số -> Nhập kết quả học tập -> Tải file Năng lực - Phẩm chất)</i></small>
<!--          <p v-if="user.qlnt==1"><i style="font-size:12px">(Truy cập vnedu -> START -> Quản lý nhà trường -> Tổng kết học tập -> Phẩm chất năng lực ghi học bạ -> Nhập xuất -> Xuất mẫu điền ra excel)</i></p>-->
<!--          <p v-if="user.qlnt==2"><i style="font-size:12px">(Truy cập Học bạ số -> Nhập dữ liệu -> Nhập kết quả học tập -> Sổ nhận xét -> Tải dữ liệu học sinh)</i></p>-->
        </div>
<!--        <el-row :gutter="20">-->
<!--          <el-col :xs="12" :sm="6" :md="4" :lg="4">-->
<!--            <label>Phương thức nhập (<i style="color: red;">*</i>)</label>-->
<!--            <eselect class="mb-2" :disabled="user.qlnt==2&&typeNLPC==2" style="width:111.5%" collapseTags v-model="typeNLPC" filterable-->
<!--                     :data="this.user.qlnt==1? listPhuongThucNhapVnEdu:listPhuongThucNhapMoet" :fields="['name','value']" />-->
<!--          </el-col>-->
<!--        </el-row>-->
        <ImportForNhanXet
            :year="namHoc"
            :cap-hoc="capHoc"
            :require-nam-hoc="true"
            :data-type="6"
            :text-download="'Tải file nhập Năng lực - Phẩm chất'"
            :requireKhoiHoc="true"
            :hideClass="false"
            :fileNameError="'danh_sach_loi_nhap_nang_luc_pham_chat'"
            :require-cap-hoc="true"
            :disabledDownload="typeNLPC!=2"
            :requireImportType="true"
            :uri-dowload="typeNLPC == 1? dowloadNangLucPhamChat : downloadNangLucPhamChatChung"
            :source="sourceNLPC"
            :path="typeNLPC === 1? pathPhamChatNangLuc: pathPhamChatNangLucChung"
            name-file="nang-luc-pham-chat" :importType="typeNLPC"></ImportForNhanXet>
      </div>
      <div v-else class="page__header">
        <div class="page__title align-items-center justify-content-between">
          <h1 class="title">Năng lực - Phẩm chất</h1>
          <small><i> (Truy cập Học bạ số -> Nhập kết quả học tập -> Tải file Năng lực - Phẩm chất)</i></small>
        </div>
        <ImportForNhanXet
            :year="namHoc"
            :text-download="'Tải file nhập Năng lực - Phẩm chất'"
            :data-type="6"
            :cap-hoc="capHoc"
            :require-nam-hoc="true"
            :fileNameError="'danh_sach_loi_nhap_nang_luc_pham_chat'"
            :require-cap-hoc="true"
            :disabledDownload="typeNLPC!=2"
            :requireKhoiHoc="true"
            :hideClass="false"
            :requireImportType="false"
            :uri-dowload="typeNLPC == 1? dowloadNangLucPhamChat : downloadNangLucPhamChatChung"
            :source="sourceNLPC"
            :path="pathPhamChatNangLucAnGiang"
            name-file="nang-luc-pham-chat" :importType="typeNLPC"></ImportForNhanXet>
      </div>
      <div class="page__header" v-if="user.qlnt==1">
        <div class="page__title align-items-center justify-content-between">
          <h1 class="title">Điểm tổng kết</h1>
          <small><i> (Truy cập Học bạ số -> Nhập kết quả học tập -> Tải file Điểm tổng kết)</i></small>

<!--          <p v-if="user.qlnt==1"><i style="font-size:12px">(Truy cập vnedu -> Tổng kết -> Chọn học kỳ 2 -> Nhập xuất -> Xuất ra excel toàn khối)</i></p>-->
<!--          <p v-if="user.qlnt==2"><i style="font-size:12px">(Truy cập moet -> Học sinh -> Kết quả học tập rèn luyện -> Nhập tổng kết kết quả học tập từ excel -> Tải file mẫu)</i></p>-->
        </div>
<!--        <el-row :gutter="20">-->
<!--          <el-col :xs="12" :sm="6" :md="4" :lg="4">-->
<!--            <label>Phương thức nhập (<i style="color: red;">*</i>)</label>-->
<!--            <eselect class="mb-2" style="width:111.5%" collapseTags v-model="typeDiemTongKetC1" filterable-->
<!--                   :data="this.user.qlnt==1? listPhuongThucNhapVnEdu:listPhuongThucNhapMoet" :fields="['name','value']" />-->
<!--          </el-col>-->
<!--        </el-row>-->
        <ImportForNhanXet
            :year="namHoc"
            :cap-hoc="capHoc"
            :data-type="7"
            :text-download="'Tải file nhập Điểm tổng kết'"
            :require-nam-hoc="true"
            :require-cap-hoc="true"
            :fileNameError="'danh_sach_loi_nhap_diem_tong_ket'"
            :requireImportType="true"
            :requireKhoiHoc="true"
            :hideClass="false"
            :require-khoi-hoc="getRequireKhoiHoc()"
            :uri-dowload="downloadDiemTongKetC1"
            :source="sourceDiemTongKet"
            :path="typeDiemTongKetC1 === 1? pathDiemtTongKetC1 : pathDiemTongKetC1Chung"
            :disabledDownload="typeDiemTongKetC1 != 2"
            :importType="typeDiemTongKetC1"
            name-file="diem-tong-ket"></ImportForNhanXet>
      </div>

      <div class="page__header">
        <div class="page__title align-items-center justify-content-between">
          <h1 class="title">Chiều cao - Cân nặng</h1>
          <small><i> (Truy cập Học bạ số -> Nhập kết quả học tập -> Tải file mẫu Chiều cao - Cân nặng)</i></small>
<!--          <p v-if="user.qlnt==1"><i style="font-size:12px">(Truy cập vnedu -> Start -> Quản lý nhà trường -> Sổ theo dõi sức khỏe, y tế -> Xuất excel toàn bộ)</i></p>-->
<!--          <p v-if="user.qlnt==2"><i style="font-size:12px">(Truy cập moet -> Học sinh -> Quản lý sức khỏe học sinh -> Nhập sức khỏe học sinh -> Xuất excel Theo lớp)</i></p>-->
        </div>
<!--        <el-row :gutter="20">-->
<!--          <el-col :xs="12" :sm="6" :md="4" :lg="4">-->
<!--            <label>Phương thức nhập (<i style="color: red;">*</i>)</label>-->
<!--            <eselect class="mb-2" style="width:111.5%" collapseTags v-model="typeChieuCaoCanNang" filterable-->
<!--                   :data="this.user.qlnt==1? listPhuongThucNhapVnEdu:listPhuongThucNhapMoet" :fields="['name','value']" />-->
<!--          </el-col>-->
<!--        </el-row>-->
        <ImportForNhanXet
            :year="namHoc"
            :data-type="8"
            :cap-hoc="capHoc"
            :text-download="'Tải file nhập Chiều cao - Cân nặng'"
            :require-nam-hoc="true"
            :require-cap-hoc="true"
            :fileNameError="'danh_sach_loi_nhap_chieu_cao_can_nang'"
            :requireImportType="true"
            :uri-dowload="typeChieuCaoCanNang === 1? dowloadChieuCaoCanNang : downloadChieuCaoCanNangChung" 
            :source="sourceChieuCaoCanNang"
            :requireKhoiHoc="true"
            :hideClass="false"
            :disabledDownload="typeChieuCaoCanNang != 2"
            :path="typeChieuCaoCanNang === 1? pathChieuCaoCanNang : pathChieuCaoCanNangChung"
            :importType="typeChieuCaoCanNang"
            name-file="chieu-cao-can-nang"></ImportForNhanXet>
      </div>

      <div class="page__header">
        <h5 class="title">Số ngày nghỉ</h5>
        <small><i> (Truy cập Học bạ số -> Nhập kết quả học tập -> Tải file mẫu Số ngày nghỉ)</i></small>
<!--        <p><i style="font-size:12px">(Truy cập Học bạ số -> Nhập dữ liệu -> Nhập kết quả học tập -> Số ngày nghỉ -> Tải dữ liệu học sinh)</i></p>-->
<!--        <el-row :gutter="20">-->
<!--          <el-col :xs="12" :sm="6" :md="4" :lg="4">-->
<!--            <label>Phương thức nhập (<i style="color: red;">*</i>)</label>-->
<!--            <eselect class="mb-2" style="width:111.5%" :disabled="typeSoNgayNghi==2" collapseTags v-model="typeSoNgayNghi" filterable-->
<!--                   :data="this.user.qlnt==1? listPhuongThucNhapVnEdu:listPhuongThucNhapMoet" :fields="['name','value']" />-->
<!--          </el-col>-->
<!--        </el-row>-->
        <ImportForNhanXet
            :require-cap-hoc="true"
            :require-nam-hoc="true"
            :text-download="'Tải file nhập Số ngày nghỉ'"
            :requireImportType="true"
            :fileNameError="'danh_sach_loi_nhap_so_ngay_nghi'"
            :year="namHoc"
            :cap-hoc="capHoc"
            :data-type="9"
            :requireKhoiHoc="true"
            :hideClass="false"
            :uri-dowload="dowloadNgayNghi" :source="sourceNgayNghi" 
            :path="typeSoNgayNghi === 1? pathNgayNghi : pathNgayNghiChung"
            :importType="typeSoNgayNghi"
            name-file="ngay-nghi"></ImportForNhanXet>
      </div>
    </div>

  </div>
</template>

<script>
// import constant_api from "../../_helpers/constant_api";
// import ImportForNhanXet from "./ImportForNhanXet";

import ImportManySheet from "./ImportChung.vue";
import ImportForNhanXet from "./ImportChung";

import constant_api from "../../_helpers/constant_api";
import ESelect from "../Ui/ESelect";
import {mapState} from "vuex";
import ImportGeneral from "./ImportChung.vue";
import ImportForNhanXetMoet from "./ImportChung.vue";


export default {
  name: "ImportKetQuaHocTap",
  metaInfo: {
    title: 'Nhập kết quả học tập',
    meta: [
      {vmid: 'description', name: 'description', content: ''}
    ],
  },
  components: {
    ImportForNhanXetMoet,
    ImportGeneral,
    ImportManySheet,
    ImportForNhanXet,
    'eselect': ESelect,
  },
  computed: {
    ...mapState('account',
        [
          "user",
          'list_nam_hoc',
          'list_cap_hoc',
          'nam_hoc_hien_tai',
        ]),

  },
  methods: {
      getRequireKhoiHoc(){
          if(this.user.qlnt==1){
              return true
          }else{
              return false
          }
      },
    getListCapHoc() {
      if (this.user && this.user.capHoc) {
        let ch = this.user.capHoc.toString();
        let cap_hoc = ch.split('');
        this.capHoc = parseInt(cap_hoc[0]);
      }
    }
  },
  mounted() {
    this.getListCapHoc();
    this.namHoc = this.nam_hoc_hien_tai;
      console.log('Thông tin đăng nhập')
      console.log(this.user)
      if(this.user.qlnt==2){
          this.typeSoNhanXet = 2;
          this.typeNLPC = 2;
          this.typeSoNgayNghi = 2;
      }
    this.typeSoNgayNghi = 2;
    this.typeRenLuyenHe = 2;
    this.typeNghePT = 2;
    this.typeNhanXetGDCD = 2;
  },
  data() {
    return {
      namHoc: '',
      capHoc: '',

      dowloadNghePT: constant_api.download.nghePT,
      dowloadRenLuyenHe: constant_api.download.renLuyenHe,
      dowloadNhanXetGdcd: constant_api.download.nhanXetGdcd,
      downloadNhanXetGdcdChung: constant_api.download.nhanXetGdcdChung,

      template: [
        {label: 'VNEDU', type: 1, url: ''},

      ],

      source: [
        {
          type: 1,
          label: 'VNEDU',
          rowHeader: 1
        }, {
          type: 2,
          label: 'MOET',
          rowHeader: 1
        }
      ],

      sourceNghePT: [
        {
          type: 1,
          label: 'VNEDU',
          rowHeader: 1
        }, {
          type: 2,
          label: 'MOET',
          rowHeader: 1
        }
      ],
      sourceNhanXetGDCD:[
        {
          type: 1,
          label: 'VNEDU',
          rowHeader: 1
        }, {
          type: 2,
          label: 'MOET',
          rowHeader: 1
        }],
      sourceRenLuyenHe: [
        {
          type: 1,
          label: 'VNEDU',
          rowHeader: 1
        }, {
          type: 2,
          label: 'MOET',
          rowHeader: 1
        }
      ],

      sourceNLPC: [
        {
          type: 1,
          label: 'VNEDU',
          rowHeader: 2
        }, {
          type: 2,
          label: 'MOET',
          rowHeader: 1
        }
      ],

      sourceDiemTongKet: [
        {
          type: 1,
          label: 'VNEDU',
          rowHeader: 3
        },
        {
          type: 2,
          label: 'MOET',
          rowHeader: 3
        }
      ],
      sourceNgayNghi: [
        {
          type: 1,
          label: 'VNEDU',
          rowHeader: 1
        }, {
          type: 2,
          label: 'MOET',
          rowHeader: 1
        }
      ],
      sourceChieuCaoCanNang: [
        {
          type: 1,
          label: 'VNEDU',
          rowHeader: 7
        },
        {
          type: 2,
          label: 'MOET',
          rowHeader: 9
        }
      ],
      dowloadNangLucPhamChat: constant_api.download.nangLucPhamChat,
      downloadNangLucPhamChatChung: constant_api.download.nangLucPhamChatChung,
      dowloadSoNhanXet: constant_api.download.soNhanXet,
      downloadSoNhanXetChung: constant_api.download.soNhanXetChung,
      downloadDiemTongKetC1: constant_api.download.diemTongKetC1,
      downloadDiemTongKet: constant_api.download.diemTongKetMonHoc,
      dowloadChieuCaoCanNang: constant_api.download.chieuCaoCanNang,
      downloadChieuCaoCanNangChung: constant_api.download.chieuCaoCanNangChung,
      path: constant_api.import.diemTongKetMonHoc,
      pathDiemTongKetChung: constant_api.import.diemTongKetMonHocChung,
      path2: constant_api.import.hanhKiem,
      pathNghePT: constant_api.import.nghePT,
      pathNghePTChung: constant_api.import.nghePTChung,
      pathRenLuyenHe: constant_api.import.renLuyenHe,
      pathRenLuyenHeChung: constant_api.import.renLuyenHeChung,
      pathNhanXetGDCD: constant_api.import.nhanXetGdcd,
      pathNhanXetGDCDChung: constant_api.import.nhanXetGdcdChung,
      pathSoNhanXet: constant_api.import.soNhanXet,
      pathSoNhanXetChung: constant_api.import.soNhanXetChung,
      pathPhamChatNangLuc: constant_api.import.phamChatNangLuc,
      pathPhamChatNangLucAnGiang: constant_api.import.phamChatNangLucAnGiang,
      pathPhamChatNangLucChung: constant_api.import.phamChatNangLucChung,
      pathDiemtTongKetC1: constant_api.import.diemtTongKetC1,
      pathDiemTongKetC1Chung: constant_api.import.diemTongKetC1Chung,
      pathNgayNghi: constant_api.import.soNgayNghi,
      pathNgayNghiChung: constant_api.import.soNgayNghiChung,
      dowloadNgayNghi: constant_api.download.soNgayNghi,
      pathChieuCaoCanNang: constant_api.import.chieuCaoCanNang,
      pathChieuCaoCanNangChung: constant_api.import.chieuCaoCanNangChung,
      typeSoNhanXet: '',
      typeDiemTongKetC1: '',
      typeDiemTongKetC1Moet: '',
      typeNLPC: '',
      typeChieuCaoCanNang: '',
      typeSoNgayNghi: '',
      typeDiemTongKet: '',
      typeRenLuyenHe: '',
      typeNghePT: '',
      typeNhanXetGDCD: '',
      listPhuongThucNhapVnEdu : [
                    {
                      'name':'Mẫu VNEDU',
                      'value': 1
                    }, 
                    {
                      'name': 'Mẫu Học bạ số',
                      'value': 2
                    }
                   ],
      listPhuongThucNhapMoet : [
                    {
                      'name':'Mẫu MOET',
                      'value': 1
                    }, 
                    {
                      'name': 'Mẫu Học bạ số',
                      'value': 2
                    }
                   ]

    }
  }
}
</script>

<style scoped>

.title {
  margin-bottom: 0;
}
</style>