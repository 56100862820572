<template>
  <el-dialog title="Xác nhận ký" class="otp__form" :destroy-on-close="true" :close-on-click-modal="false" center
             :before-close="closeOtpModal" :visible.sync="hien_thi" append-to-body>
    <el-row :gutter="24" class="text-center" ref="formOtp">
      <el-alert v-if="(kyHocBaStatus !='success' && kyHocBaMes)" :title="kyHocBaMes" center type="error">
      </el-alert>
      <p class="text-center">
        {{ messInfo }}
      </p>
      <p v-if="!messInfo" class="text-center">
        Nhập mã OTP
      </p>
      <el-col :span="24" style="padding-bottom:15px">
        <input type="number" style="font-size:15px" v-on:keyup.enter.prevent="XacNhanKy"
               class="login__otp form-control text-center" name="otp" v-model="otpCode">
      </el-col>
      <el-col :span="24">
        <div class="text-center">
          <!-- <el-popconfirm title="Xác nhận ký?" confirm-button-text="Đồng ý" cancel-button-text="Hủy" @onConfirm="demoFunction">
            <el-button type="primary" round="round" slot="reference" class="d-flex w-100 align-items-center justify-content-center text-uppercase">Xác nhận ký</el-button>
          </el-popconfirm> -->
          <el-button :disabled="!otpCode" @click.prevent="XacNhanKy" style="margin-bottom:15px"
                     class="d-flex w-100 align-items-center justify-content-center text-uppercase"
                     type="primary"
                     round="round">
            Xác nhận ký
          </el-button>
          <div class="" v-if="loiKy.code==501">
            Mã OTP đã hết hiệu lực?
            <span>
              <el-link @click.prevent="layOtpKy()" class="text-uppercase" :underline="false" v-if="!getOtpClicked">Gửi
                lại
              </el-link>
              <el-link class="text-uppercase" v-else :underline="false" type="danger" disabled>Gửi lại ({{ timeleft }}
                giây)</el-link>
            </span>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-dialog class="alert-otp" title="Thông báo" center :before-close="closeDialogAlert"
               :close-on-click-modal="false"
               :visible.sync="showDialogAlert" append-to-body>
      <div class="text-center mb-2">
        {{ kyHocBaMes }}
      </div>
      <div class="text-center">
        <el-button type="primary" @click="closeDialogAlert">Đóng</el-button>
      </div>
    </el-dialog>
  </el-dialog>
</template>

<script>
import {
  mapActions,
  mapMutations,
  mapState
} from 'vuex'
import dialog from "../dialog";
import util from "../util";
import constant from '../_helpers/constant_api';
import msgType from '../_helpers/constant_msg_type';
import api from '../_helpers/api';
import websocket from "../websocket";
import {date} from "../../../0";


export default {
  name: "FormOtp",
  props: [
    "showDialogOtp",
    "listHocBa",
    "duLieu",
    "dataMon",
    "type"
  ],
  data() {
    return {
      loiKy: {},
      hien_thi: false,
      showDialogAlert: false,
      timeleft: 0,
      getOtpClicked: false,
      otpCode: '',
      timeOut: '',
      messInfo: ''
    }
  },

  computed: {
    ...mapState("teacher", [
      "getOtpMes",
      "kyHocBaMes",
      "kyHocBaMesError",
      "getOtpPhone",
      "kyHocBaStatus"
    ]),
    ...mapState('account', [
      'user'
    ])
  },

  methods: {

    thongBao(t, e) {
      let msg = "";
      let cl = "";
      if (e) {
        msg = e;
      }
      let type = "success";
      if (t) {
        type = t
      }
      if (type == "success") {
        cl = "dts-noty-success"
      }
      if (type == "warning") {
        cl = "dts-noty-warning"
      }
      if (type == "error") {
        cl = "dts-noty-error"
      }
      if (type == "info") {
        cl = "dts-noty-info"
      }
      this.$message({
        customClass: cl,
        showClose: true,
        message: msg,
        type: t,
        duration: 3000
      });
    },
    kyHocBa(e) {
    },
    ...mapActions("teacher", [
      "getOtpKy",
      "clearDataKy"

    ]),
    ...mapMutations("teacher", [
      "setOtpCode"
    ]),
    layOtpKy() {
        console.error('layOtpKy')
      this.timeleft = 30;
      this.getOtpClicked = true;
      console.log("Lấy OTP ký");
        console.error(this.type)
        let typeGet = 0;
        if(this.type==msgType.type.sendOtpBiaHocBa){//ký bìa
            //Ký bìa học bạ
            typeGet = msgType.type.sendOtpBiaHocBa //lấy otp ký bìa
        }

      this.messInfo = ''
        console.error('type lấy otp:' + typeGet)
      websocket.sendMessage({
        msgType: typeGet
      });
    },
    hidePhoneNumber(data) {
      if (data)
        return util.hideNumberPhone(data);
    },
    getOtp() {
      this.timeleft = 30;
      this.getOtpClicked = true;
      this.getOtpKy();
    },
    closeOtpModal() {
      clearTimeout(this.timeOut)
      this.$emit('closeDialogOtp')
    },
    closeDialogAlert() {
      this.showDialogAlert = false
      this.clearDataKy();
      this.$emit('closeDialogOtp')
      // this.$emit('success')
    },
    onReceiveMessage(e) {
      let data = e.data;
        // console.error('onReceiveMessage form otp')
        // console.error(data)
        // console.error('other: ' + data.other)
        // if (data.other === 1) {
        //     if (data.code == 200) {
        //         this.hien_thi = false;
        //         this.$emit('closeDialogOtp')
        //         localStorage.setItem("OtpCode", this.otpCode);
        //         localStorage.setItem("messInfoOtpCode", data.msg);
        //         this.messInfo = data.msg;
        //     } else {
        //         this.loiKy = data;
        //         this.messInfo = data.msg;
        //     }
        // }
        if(data.other==3){
            if(data.code==200){
                this.thongBao('success',data.msg)
                this.hien_thi = false;
                this.$emit('success')
            }else{
                this.loiKy = data;
                this.messInfo = data.msg;
            }
        }
        if(data.other==1){
            if(data.code==200){
                this.thongBao('success',data.msg)
                this.hien_thi = false;
                this.$emit('success')
            }else{
                this.loiKy = data;
                this.messInfo = data.msg;
            }
        }
      if(data.other==0||data.other==2){
          if(data.code==200){
              localStorage.setItem("OtpCode", this.otpCode);
              localStorage.setItem("messInfoOtpCode", data.msg);
              this.messInfo = data.msg;
          }else{
              this.messInfo = data.msg;
          }
      }
    },

    XacNhanKy(done) {

      console.log("Dữ liệu học bạ cần ký");
      console.log(this.duLieu)
      this.$confirm('Xác nhận ký?', 'Thông báo', {
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy',
      })
          .then(_ => {
            console.log("ĐỒng ý ký")
            let data = [];
            let typeKy = null;
            if (this.type) {
              typeKy = this.type;
            }
            if (this.listHocBa) {
              this.listHocBa.map(item => {
                let hocba = {
                  id: item
                }
                data.push(hocba);
                return item
              });

            }
            if (this.dataMon) {
              data.push(this.dataMon)
            }
            var msgType = 1;//Ký học bạ
            if(this.type==2){
                //Ký bìa học bạ
                msgType = 3;
            }
            let params = {
              code: this.otpCode,
              reqs: data,
              type: this.duLieu.loaiHocBa,
              maLop: this.duLieu.maLop,
              maGVCN: this.duLieu.maGVCN,
              msgType: msgType
            }
            console.log("Dữ liệu ký")
            console.log(params)
            websocket.sendMessage(params);

          })
          .catch(_ => {
          });
    },
    conFirmKyOld() {
      console.log(this.listHocBa, this.dataMon)
      let data = [];
      if (this.listHocBa) {
        this.listHocBa.map(item => {
          let hocba = {
            id: item
          }
          data.push(hocba);
          return item
        });

      }
      if (this.dataMon) {
        data.push(this.dataMon)
      }
      let params = {
        code: this.otpCode,
        reqs: data
      }
      if (this.type) {
        params.type = this.type;
      }
      // this.setOtpCode(this.otpCode);
      this.kyHocBa(params);
    }

  },
  mounted() {
    window.addEventListener('message', this.onReceiveMessage, false);
    let messInfo = localStorage.getItem("messInfoOtpCode");
    console.log("Tin nhắn cũ" + messInfo)
    let otpCode = localStorage.getItem("OtpCode");
    console.log("otpCode cũ" + otpCode)
    if (otpCode) {
      this.otpCode = otpCode;
    } else {
      this.otpCode = null
    }
    if (messInfo) {
      this.messInfo = messInfo;
    }
  },
  watch: {
    showDialogOtp(value) {
        console.error('showDialogOtp:')
        console.error(value)
      if (value) {
          console.log('Xe, xe,')
        this.hien_thi = true;
          console.error(this.otpCode)
        if (!this.otpCode||this.otpCode==null) {
            console.log('Lấy OTP mới:')
          this.layOtpKy();
        }else{
            console.log('Có OTP rồi')
            console.log(this.otpCode)
        }
        this.timeleft = 30;
        this.getOtpClicked = true;
      } else {
        this.hien_thi = false;
      }
    },
    timeleft(value) {
      if (value > 0) {
        this.timeOut = setTimeout(() => {
          this.timeleft--;
        }, 1000);
      } else {
        this.timeleft = 0;
        this.getOtpClicked = false;
      }
    },
  }
}
</script>

<style scoped>

</style>