<template>
    <div>
        <div v-loading.fullscreen.lock="loading.status" :element-loading-text="loading.text"
             element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">

        </div>
    </div>
</template>
<script>
import {mapState, mapActions, mapMutations} from 'vuex';
import util from "../util";
import api from "../_helpers/api";
import constant from "../_helpers/constant_api";

export default {
    name: "LoginSmCa",
    components: {},
    computed: {
    },

    data() {
        return {
            code: "",
            loading:{
                status:true,
                text:'Vui lòng đợi trong giây lát...'
            }

        }
    },

    mounted() {
        this.code = this.$route.query.code;
        console.log("mount login smart ca")
        if (this.code) {
            this.getAcssetToken();
        } else {
            console.log("Không có code")
        }
    },
    methods: {
        getAcssetToken(){
            let uri = constant.login.getAcssetTokenVnpt;
            let params = {
                code: this.code
            }
            api.post(uri, params).then(
                response => {
                    if(response&&response.data.code==200){
                        let dataTokenVnptCa = {
                            timeStart:Date.now(),
                            data:response.data.data
                        };
                        dataTokenVnptCa = JSON.stringify(dataTokenVnptCa)
                        localStorage.setItem('dataTokenVnptCa', dataTokenVnptCa)
                        let routeCheck = localStorage.getItem('routerLoginSmartCa');
                        console.error(routeCheck)
                        this.$router.push({name: routeCheck});
                    }else{
                        this.thongBao('error','Có lỗi. Vui lòng thử lại sau.')
                        this.$router.push({name: 'DashBoard'});
                    }
                }
            );
        },

        thongBao(t, e) {
            let msg = "";
            let cl = "";
            if (e) {
                msg = e;
            }
            let type = "success";
            if (t) {
                type = t
            }
            if (type == "success") {
                cl = "dts-noty-success"
            }
            if (type == "warning") {
                cl = "dts-noty-warning"
            }
            if (type == "error") {
                cl = "dts-noty-error"
            }
            if (type == "info") {
                cl = "dts-noty-info"
            }
            this.$message({
                customClass: cl,
                showClose: true,
                message: msg,
                type: t,
                duration: 3000
            });
        },
        closeDialog() {
            this.clearAlert();
        }

    },

    watch: {
        message(val) {
            console.log('alert:', val)
            if (val) {
                this.showDialog = true;
            }
        }
    }
}

</script>

<style scoped>

</style>
