<template>
  <div>
    <el-row :gutter="24">
      <el-col :span="24" class="reset">
        <small style="padding-right:5px;">Hiển thị </small>
        <el-select size="mini" style="width:100px !important" v-model="soLuong" @change="onSizeChange" placeholder="Chọn">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <small style="padding-left:5px;">bản ghi/trang.</small>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: [
      'show'
  ],

  watch: {
  },

  data() {
    return {
      soLuong:100,
      options: [{
        value: 10,
        label: '10'
      },{
        value: 25,
        label: '25'
      },
        {
          value: 50,
          label: '50'
        },
        {
          value: 100,
          label: '100'
        },
        {
          value: 200,
          label: '200'
        },
        {
          value: 500,
          label: '500'
        },
        // {
        //   value: 1000,
        //   label: '1000'
        // },
      ],
      currentPage:1,
      start: 0,
      to: 25
    }
  },

  mounted() {
    if(this.show){
      this.soLuong=this.show;
    }
  },

  methods: {
    onSizeChange() {
      console.log("Thay đổi kích thước hiển thị")
      this.currentPage = 1;
      let ret = {
        soluong:this.soLuong
      };
      this.$emit("chonXongSoLuong", ret);
    },
  }

}

</script>

<style scoped>
p{
  padding-top:5px;
}

</style>
