var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true, lock: true },
        },
      ],
      staticClass: "page page-file file detailHocBa",
      attrs: {
        id: "detailHocBa",
        "element-loading-text": "Đang xử lý..",
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "rgba(0, 0, 0, 0.8)",
      },
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "box-upload-file" },
        [
          _c(
            "el-form",
            {
              ref: "searchForm",
              attrs: {
                rules: _vm.rulesSearch,
                model: _vm.searchForm,
                "hide-required-asterisk": true,
              },
            },
            [
              _c(
                "el-row",
                {
                  attrs: { gutter: 24 },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.submitSearch("searchForm")
                    },
                  },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "d-inline-block",
                          attrs: { prop: "education", label: "Cấp học" },
                        },
                        [
                          _c("eselect", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              disabled: _vm.list_cap_hoc.length == 1,
                              collapseTags: "",
                              placeholder: "Chọn",
                              filterable: "",
                              data: _vm.list_cap_hoc,
                              fields: ["name", "value"],
                            },
                            on: { change: _vm.capHocChange },
                            model: {
                              value: _vm.searchForm.education,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "education", $$v)
                              },
                              expression: "searchForm.education",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "d-inline-block",
                          attrs: { prop: "year", label: "Năm học" },
                        },
                        [
                          _c("eselect", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              collapseTags: "",
                              placeholder: "Chọn",
                              filterable: "",
                              data: _vm.list_nam_hoc,
                              fields: ["name", "value"],
                            },
                            on: { change: _vm.namHocChange },
                            model: {
                              value: _vm.searchForm.year,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "year", $$v)
                              },
                              expression: "searchForm.year",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "d-inline-block",
                          attrs: { label: "Khối học" },
                        },
                        [
                          _c("eselect", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              collapseTags: "",
                              placeholder: "Chọn",
                              filterable: "",
                              data: _vm.optionGrade,
                              fields: ["label", "value"],
                            },
                            on: { change: _vm.khoiHocChange },
                            model: {
                              value: _vm.searchForm.grade,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "grade", $$v)
                              },
                              expression: "searchForm.grade",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "d-inline-block",
                          attrs: { label: "Lớp học" },
                        },
                        [
                          _c("eselect", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              collapseTags: "",
                              placeholder: "Chọn",
                              filterable: "",
                              data: _vm.danh_sach_lop_hoc,
                              fields: ["tenLop", "maLop"],
                            },
                            model: {
                              value: _vm.searchForm.class,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "class", $$v)
                              },
                              expression: "searchForm.class",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "d-inline-block",
                          attrs: { label: "Trạng thái" },
                        },
                        [
                          _c("eselect", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              collapseTags: "",
                              placeholder: "Trạng thái học sinh",
                              data: [
                                { label: "Đang học", value: 1 },
                                { label: "Chuyển trường", value: 3 },
                                { label: "Bỏ học", value: 4 },
                              ],
                              fields: ["label", "value"],
                            },
                            model: {
                              value: _vm.searchForm.trangThaiHocSinh,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.searchForm,
                                  "trangThaiHocSinh",
                                  $$v
                                )
                              },
                              expression: "searchForm.trangThaiHocSinh",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "d-inline-block",
                          attrs: { label: "Từ khoá" },
                        },
                        [
                          _c("el-input", {
                            attrs: { clearable: "", placeholder: "Nhập" },
                            model: {
                              value: _vm.searchForm.student,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "student", $$v)
                              },
                              expression: "searchForm.student",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "text-align": "center" },
                      attrs: { xs: 24, sm: 24, md: 6, lg: 24, xl: 3 },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "d-inline-block" },
                        [
                          _c("div", { staticStyle: { color: "transparent" } }, [
                            _vm._v("Tìm kiếm"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "m-0",
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.submitSearch("searchForm")
                                },
                              },
                            },
                            [_vm._v("Tìm kiếm\n            ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 24 } }, [
            _c(
              "div",
              { staticClass: "text-right" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "mb-1",
                    attrs: { type: "primary", size: "small" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.checkShowUploadBia(1)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "el-icon-upload2" }),
                    _vm._v(" Upload file dữ liệu\n        "),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticClass: "mb-1",
                    attrs: { type: "primary", size: "small" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.getHashUploadFileScan()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "el-icon-upload2" }),
                    _vm._v(" Upload file scan học bạ\n        "),
                  ]
                ),
                _vm._v(" "),
                _vm.user.qlnt != 0
                  ? _c(
                      "el-button",
                      {
                        staticClass: "mb-1",
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.updateMutileImage },
                      },
                      [
                        _c("i", { staticClass: "el-icon-upload2" }),
                        _vm._v(" Cập nhật nhiều ảnh\n        "),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { ref: "page_content", staticClass: "page__content" }, [
        _c(
          "div",
          { staticClass: "listNew mb-5" },
          [
            _c(
              "el-table",
              {
                ref: "listFile",
                attrs: {
                  border: "",
                  fit: "",
                  lazy: true,
                  data: _vm.listHs ? _vm.listHs.list : [],
                  width: "100%",
                  "max-height": _vm.maxHeight,
                },
                on: { "selection-change": _vm.handleSelectionChange },
              },
              [
                _c("el-table-column", {
                  staticStyle: { width: "50px" },
                  attrs: {
                    label: "STT",
                    width: "50",
                    type: "index",
                    "class-name": "text-center",
                    index: _vm.indexMethod,
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    "header-align": "center",
                    width: "80",
                    height: "80",
                    label: "Ảnh",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            { staticClass: "avatar" },
                            [
                              _c("img", {
                                attrs: {
                                  src: scope.row.avatar
                                    ? scope.row.avatar + "?t=" + _vm.getTime()
                                    : _vm.defaultAvatar,
                                  alt: "",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    content: "Xóa ảnh",
                                    placement: "top",
                                  },
                                },
                                [
                                  scope.row.removeAvatar
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "remove-avatar",
                                          attrs: { href: "" },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.removeAvatar(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "ion ion-ios-close",
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    "header-align": "center",
                    label: "Mã học sinh",
                    property: "maHS",
                    align: "center",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    "header-align": "center",
                    label: "Số đăng bộ",
                    property: "soDangBo",
                    align: "center",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    "header-align": "center",
                    label: "Họ và tên",
                    property: "tenHS",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    "header-align": "center",
                    label: "Lớp",
                    property: "tenLop",
                    align: "center",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    "header-align": "center",
                    label: "Trạng thái học sinh",
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.getTrangThaiHocSinh(scope.row.trangThai)
                              ),
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    "header-align": "center",
                    label: "Trạng thái học bạ",
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", {
                            domProps: {
                              innerHTML: _vm._s(_vm.checkHocBa(scope.row)),
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    "header-align": "center",
                    label: "Hành động",
                    "class-name": "",
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            { staticClass: "btn__table_action" },
                            [
                              _c(
                                "el-upload",
                                {
                                  ref: "upload",
                                  staticClass:
                                    "upload-demo d-inline-block mr-1",
                                  attrs: {
                                    "show-file-list": false,
                                    action: "/",
                                    "on-change": _vm.uploadAvatar,
                                    accept: ".png,.jpg,.jpeg",
                                    "auto-upload": false,
                                  },
                                },
                                [
                                  _vm.user.qlnt != 0
                                    ? _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            content: "Tải lên ảnh học sinh",
                                            placement: "top",
                                          },
                                        },
                                        [
                                          _c("el-button", {
                                            attrs: {
                                              size: "mini",
                                              type: "primary",
                                              icon: "el-icon-upload",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                _vm.hocSinhSelect = scope.row
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    content: "Xem chi tiết",
                                    placement: "top",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      size: "mini",
                                      type: "primary",
                                      icon: "el-icon-view",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showDialogInfoHocBa(
                                          scope.row,
                                          scope.$index
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    content: "Chỉnh sửa thông tin",
                                    placement: "top",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    staticStyle: { "margin-left": "4px" },
                                    attrs: {
                                      size: "mini",
                                      type: "primary",
                                      icon: "el-icon-edit-outline",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.hienThiCapNhatNgayVaoTruong(
                                          scope.row
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              false
                                ? _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        content: "Đồng bộ dữ liệu",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c("el-button", {
                                        staticStyle: { "margin-left": "4px" },
                                        attrs: {
                                          size: "mini",
                                          type: "success",
                                          icon: "el-icon-sort",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.dongBoDuLieu(scope.row)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.listHs && _vm.listHs.total
              ? _c(
                  "div",
                  { staticClass: "mt-2" },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c("chonSoLuong", {
                              on: {
                                chonXongSoLuong: function ($event) {
                                  return _vm.ChonSoLuongBanGhi($event)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { staticClass: "text-right", attrs: { span: 16 } },
                          [
                            _c("small", { staticClass: "pd-r-10" }, [
                              _c("i", [
                                _vm._v(
                                  "Danh sách có " +
                                    _vm._s(_vm.listHs.total) +
                                    " học bạ "
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("pagination", {
                              attrs: {
                                value: _vm.paginate.current,
                                "page-count": _vm.getTotalPage(
                                  _vm.listHs.total
                                ),
                                "click-handler": _vm.getHocBa,
                                "prev-text": "Trước",
                                "next-text": "Sau",
                                "container-class": "el-pager",
                                pageClass: "number",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "info-hocba",
          attrs: {
            title: "Thông tin học bạ",
            "append-to-body": "",
            visible: _vm.showInfoHocba,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showInfoHocba = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              attrs: {
                fit: "",
                border: "",
                lazy: true,
                data: _vm.listHocBa,
                width: "100%",
              },
            },
            [
              _c("el-table-column", {
                staticStyle: { width: "50px" },
                attrs: {
                  label: "STT",
                  width: "50",
                  type: "index",
                  "class-name": "text-center",
                  index: _vm.indexMethod,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "Họ và tên",
                  property: "hoten",
                  "class-name": "text-center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "Lớp",
                  property: "tenLop",
                  width: "70",
                  "class-name": "text-center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "GVCN",
                  property: "tenGVCN",
                  "class-name": "text-center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "Năm học",
                  property: "",
                  "class-name": "text-center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.namHoc) +
                            " - " +
                            _vm._s(scope.row.namHoc + 1) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Hành động", "class-name": "text-center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "btn__table_action" },
                          [
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  content: "Xem học bạ",
                                  placement: "top",
                                },
                              },
                              [
                                _c("el-button", {
                                  staticClass: "mb-1",
                                  attrs: { icon: "ion ion-md-eye", plain: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.viewFile(scope.row)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            scope.row.nguonDuLieu
                              ? _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: "Sửa học bạ",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c("el-button", {
                                      staticClass: "mb-1",
                                      attrs: {
                                        type: "primary",
                                        icon: "el-icon-edit-outline",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.suaHocBa(scope.row)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialogCreate()
                    },
                  },
                },
                [_vm._v("Đóng")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("ThongTinHocBa", {
        attrs: { show: _vm.thongTinHocBa, id_hoc_ba: _vm.idHocBa },
        on: {
          close: function ($event) {
            _vm.thongTinHocBa = false
          },
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "add-hocba",
          attrs: {
            title: "Thêm học bạ",
            visible: _vm.showAddHocBa,
            "append-to-body": "",
            "before-close": _vm.closeDialogCreate,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showAddHocBa = $event
            },
          },
        },
        [
          _c("them-hoc-ba", {
            on: {
              close: function ($event) {
                return _vm.closeDialogCreate()
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "edit-hocba",
          attrs: {
            title: "Sửa học bạ",
            visible: _vm.showEditHocBa,
            "append-to-body": "",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showEditHocBa = $event
            },
          },
        },
        [
          _c("sua-hoc-ba", {
            on: {
              close: function ($event) {
                return _vm.closeDialogEdit()
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Tải lên ảnh học sinh",
            "append-to-body": "",
            center: "",
            "custom-class": "add-mutile-image",
            visible: _vm.showUpdateMutile,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showUpdateMutile = $event
            },
          },
        },
        [
          _c(
            "el-upload",
            {
              ref: "uploadMutile",
              staticClass: "upload-demo text-center",
              attrs: {
                drag: "",
                action: "/",
                "file-list": _vm.fileList,
                "on-error": _vm.showError,
                "on-change": _vm.uploadMutilAvatarChange,
                "on-remove": _vm.uploadMutilAvatarChange,
                accept: ".png,.jpg,.jpeg",
                "auto-upload": false,
                multiple: "",
              },
            },
            [
              _c("i", { staticClass: "el-icon-upload" }),
              _vm._v(" "),
              _c("div", { staticClass: "el-upload__text" }, [
                _vm._v("Kéo thả hoặc "),
                _c("em", [_vm._v("click ở đây")]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _vm._v(
                    "\n        Bạn hãy chọn tất cả ảnh của lớp (định dạng png,jpg). Lưu ý: đặt tên file là mã\n        học sinh."
                  ),
                  _c("br"),
                  _vm._v(
                    "\n        Hệ thống sẽ tự động upload tất cả ảnh học sinh có tên file trùng với mã học sinh\n        tương ứng.\n      "
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.uploadMutilAvatar($event)
                    },
                  },
                },
                [_vm._v("Tải ảnh lên")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.titleUploadFile,
            "append-to-body": "",
            center: "",
            "custom-class": "add-mutile-image",
            visible: _vm.showUploadBia,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showUploadBia = $event
            },
          },
        },
        [
          _c(
            "el-upload",
            {
              ref: "uploadBia",
              staticClass: "upload-demo text-center",
              attrs: {
                drag: "",
                action: "/",
                "file-list": _vm.fileList,
                "on-change": _vm.uploadFile,
                "on-remove": _vm.uploadFile,
                accept: _vm.typeUpload == 1 ? ".pdf,.xlsx,.xls" : ".zip,.zar",
                "auto-upload": false,
              },
            },
            [
              _c("i", { staticClass: "el-icon-upload" }),
              _vm._v(" "),
              _c("div", { staticClass: "el-upload__text" }, [
                _vm._v("Kéo thả hoặc "),
                _c("em", [_vm._v("click ở đây")]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _vm.typeUpload == 1
                    ? _c("span", [
                        _vm._v(
                          "\n          Qúy thầy cô vui lòng tải file excel mẫu xuống và nhập lại file lên hệ thống!\n          "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n          Lưu ý: Có thể nhập cả lớp hoặc từng học sinh để nhập vào file.\n        "
                        ),
                      ])
                    : _c("span", [
                        _vm._v("\n          Hướng dẫn nhập file scan: "),
                        _c("br"),
                        _vm._v(
                          "\n          Bước 1: Đặt tên file scan là mã học sinh tương ứng mã Học bạ số (HS1010101999)\n          "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n          Bước 2: Nén file scan thành file zip "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n          Bước 3: Nhập file zip lên Học bạ số "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n          Lưu ý: Trong TH tải một học sinh lên có thể không cần nén file zip dùng trực\n          tiếp file pdf đã scan\n        "
                        ),
                      ]),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "text-center mt-3" },
            [
              _vm.typeUpload == 1
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        disabled: _vm.disabledDownLoad,
                        size: "small",
                        type: "primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.downLoadTemplate(1)
                        },
                      },
                    },
                    [_vm._v("\n        Tải xuống file mẫu\n      ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    disabled: _vm.checkStatusImportFile(),
                    type: "primary",
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.uploadBiaHocBa($event)
                    },
                  },
                },
                [_vm._v("Tải lên dữ liệu\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "File Scan trang học bạ",
            "append-to-body": "",
            center: "",
            "custom-class": "add-mutile-image",
            visible: _vm.showUploadHocBa,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showUploadHocBa = $event
            },
          },
        },
        [
          _c(
            "el-upload",
            {
              ref: "uploadFileHocBa",
              staticClass: "upload-demo text-center",
              attrs: {
                drag: "",
                action: "/",
                "on-change": _vm.uploadMutilAvatarChange,
                "on-remove": _vm.uploadMutilAvatarChange,
                accept: ".pdf",
                "auto-upload": false,
                multiple: "",
              },
            },
            [
              _c("i", { staticClass: "el-icon-upload" }),
              _vm._v(" "),
              _c("div", { staticClass: "el-upload__text" }, [
                _vm._v("Kéo thả hoặc "),
                _c("em", [_vm._v("click ở đây")]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _vm._v(
                    "\n        Bạn hãy chọn tất cả file học bạ của lớp (định dạng pdf). Lưu ý: đặt tên file là\n        mã học sinh."
                  ),
                  _c("br"),
                  _vm._v(
                    "\n        Hệ thống sẽ tự động upload tất cả file học sinh có tên file trùng với mã học\n        sinh tương ứng."
                  ),
                  _c("br"),
                  _vm._v(
                    "\n        Lưu ý: Nếu hệ thống đã sinh file pdf, upload từ ngoài vào hệ thống sẽ thay bằng\n        file upload.\n      "
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.uploadFileHocBa($event)
                    },
                  },
                },
                [_vm._v("Tải lên")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "popupPhanQuyenBoMon",
          attrs: {
            title: "Phân quyền sửa học bạ",
            "append-to-body": "",
            center: "",
            visible: _vm.showPermissionEdit,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showPermissionEdit = $event
            },
          },
        },
        [
          _c(
            "el-tabs",
            { attrs: { type: "card" } },
            [
              _vm.user.capHoc > 1
                ? _c("el-tab-pane", { attrs: { label: "Giáo viên bộ môn" } }, [
                    _c(
                      "div",
                      { staticClass: "page" },
                      [
                        _c("h4", [_vm._v("Danh sách giáo viên bộ môn")]),
                        _vm._v(" "),
                        _c(
                          "el-table",
                          {
                            staticClass: "table-custom",
                            staticStyle: { width: "100%" },
                            attrs: {
                              data: _vm.lstMonDetail.ketQuaHocTaps,
                              border: "",
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                prop: "tenMonHoc",
                                label: "Tên môn học",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                "header-align": "center",
                                prop: "tenGiaoVien",
                                label: "Tên giáo viên",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { align: "center", label: "Trạng thái" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              scope.row.trangThaiXacNhan == 1
                                                ? "Đã ký"
                                                : "Chưa ký"
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1390762697
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                prop: "ghiChu",
                                label: "Ghi chú",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { align: "center", label: "Hành động" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              content: "Phân quyền",
                                              effect: "dark",
                                              placement: "top",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  size: "small",
                                                  type: "danger",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.phanQuyenBoMon(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-edit-outline",
                                                }),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                4140997507
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("el-tab-pane", { attrs: { label: "Giáo viên chủ nhiệm" } }, [
                _c(
                  "div",
                  { staticClass: "text-center" },
                  [
                    _c("div", [
                      _c("label", [_vm._v("Giáo viên chủ nhiệm:")]),
                      _vm._v(
                        " " +
                          _vm._s(_vm.lstMonDetail.tenGVCN) +
                          " - Lớp :\n            " +
                          _vm._s(_vm.lstMonDetail.tenLop) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-input", {
                      attrs: {
                        type: "textarea",
                        rows: 4,
                        placeholder: "Nhập lý do",
                      },
                      model: {
                        value: _vm.lyDoGVCN,
                        callback: function ($$v) {
                          _vm.lyDoGVCN = $$v
                        },
                        expression: "lyDoGVCN",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "mt-2",
                        attrs: { type: "primary" },
                        on: { click: _vm.submitPermissionGVCN },
                      },
                      [_vm._v("Phân quyền")]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-tab-pane", { attrs: { label: "Hiệu trưởng" } }, [
                _c(
                  "div",
                  { staticClass: "text-center" },
                  [
                    _c("div", [
                      _c("label", [_vm._v("Hiệu trưởng:")]),
                      _vm._v(" " + _vm._s(_vm.lstMonDetail.tenHieuTruong)),
                    ]),
                    _vm._v(" "),
                    _c("el-input", {
                      attrs: {
                        type: "textarea",
                        rows: 4,
                        placeholder: "Nhập lý do",
                      },
                      model: {
                        value: _vm.lydoHieuTruong,
                        callback: function ($$v) {
                          _vm.lydoHieuTruong = $$v
                        },
                        expression: "lydoHieuTruong",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "mt-2",
                        attrs: { type: "primary" },
                        on: { click: _vm.submitPermissionHieuTruong },
                      },
                      [_vm._v("Phân quyền\n          ")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Phân quyền cho bộ môn",
            "append-to-body": "",
            center: "",
            visible: _vm.showPopUpBoMon,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showPopUpBoMon = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("div", [
                _c("label", [_vm._v("Tên giáo viên:")]),
                _vm._v(
                  " " + _vm._s(_vm.dataBomon.tenGiaoVien) + " -\n        "
                ),
                _c("label", [_vm._v("Môn học:")]),
                _vm._v(
                  "\n        " + _vm._s(_vm.dataBomon.tenMonHoc) + "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("el-input", {
                attrs: { type: "textarea", rows: 5, placeholder: "Nhập lý do" },
                model: {
                  value: _vm.lyDoBoMon,
                  callback: function ($$v) {
                    _vm.lyDoBoMon = $$v
                  },
                  expression: "lyDoBoMon",
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "text-center mt-3" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.submitPermissionBoMon($event)
                        },
                      },
                    },
                    [_vm._v("Xác nhận")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Chỉnh sửa thông tin",
            "close-on-click-modal": false,
            "before-close": _vm.closeDialogFormUpdate,
            "close-on-press-escape": false,
            "append-to-body": "",
            center: "",
            visible: _vm.show_update_ngay_vao_truong,
          },
          on: {
            "update:visible": function ($event) {
              _vm.show_update_ngay_vao_truong = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "updateHocSinh",
                  attrs: {
                    model: _vm.infoUpdate,
                    rules: _vm.updateHocSinhRules,
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("label", [_vm._v("Tên học sinh")]),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.infoUpdate.tenHS,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.infoUpdate, "tenHS", $$v)
                                  },
                                  expression: "infoUpdate.tenHS",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("label", [_vm._v("Mã học sinh")]),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.infoUpdate.maHS,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.infoUpdate, "maHS", $$v)
                                  },
                                  expression: "infoUpdate.maHS",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("label", [
                            _vm._v("Địa danh ký "),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { prop: "tenDiaDanhKyBia" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "Nhập địa danh ký",
                                  clearable: "",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleInputChange(
                                      "tenDiaDanhKyBia"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.infoUpdate.tenDiaDanhKyBia,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.infoUpdate,
                                      "tenDiaDanhKyBia",
                                      $$v
                                    )
                                  },
                                  expression: "infoUpdate.tenDiaDanhKyBia",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("label", [
                            _vm._v("Ngày ký lý lịch học sinh "),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { prop: "ngayKyBia" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  format: "dd/MM/yyyy",
                                  "value-format": "dd/MM/yyyy",
                                  type: "date",
                                  placeholder: "dd/mm/yyyy",
                                },
                                model: {
                                  value: _vm.infoUpdate.ngayKyBia,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.infoUpdate, "ngayKyBia", $$v)
                                  },
                                  expression: "infoUpdate.ngayKyBia",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("label", [_vm._v("Hậu tố sổ đăng bộ")]),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "Nhập hậu tố sổ đăng bộ",
                                  clearable: "",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleInputChange(
                                      "tenDiaDanhKyBia"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.infoUpdate.hauToSoDangBo,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.infoUpdate,
                                      "hauToSoDangBo",
                                      $$v
                                    )
                                  },
                                  expression: "infoUpdate.hauToSoDangBo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("label", [_vm._v("Trạng thái")]),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            [
                              _c("eselect", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  filterable: "",
                                  collapseTags: "",
                                  placeholder: "Trạng thái học sinh",
                                  data: [
                                    { label: "Đang học", value: 1 },
                                    { label: "Chuyển trường", value: 3 },
                                    { label: "Bỏ học", value: 4 },
                                  ],
                                  fields: ["label", "value"],
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.sua_trang_thai = true
                                  },
                                },
                                model: {
                                  value: _vm.infoUpdate.trangThai,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.infoUpdate, "trangThai", $$v)
                                  },
                                  expression: "infoUpdate.trangThai",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "text-center mt-5" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "default" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.closeDialogFormUpdate($event)
                        },
                      },
                    },
                    [_vm._v("Hủy")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        disabled: !this.can_hoi_truoc_khi_thoat,
                        type: "primary",
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.chinhSuaNgayKyBia("updateHocSinh")
                        },
                      },
                    },
                    [_vm._v("Lưu")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("dialog-alert", {
        attrs: {
          title: "Thông báo",
          message: _vm.messageAlert,
          "show-dialog": _vm.showDialogAlert,
        },
        on: { closeDialog: _vm.closeDialogAlert },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "custom-class": "noPadding",
            title: "Thông tin học bạ",
            fullscreen: "",
            visible: _vm.xem_file_hoc_ba,
          },
          on: {
            "update:visible": function ($event) {
              _vm.xem_file_hoc_ba = $event
            },
          },
        },
        [
          _vm.mauMoi
            ? _c(
                "div",
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "box-upload-file",
                            staticStyle: { overflow: "scroll" },
                          },
                          [
                            _vm.searchForm.grade < 6
                              ? _c(
                                  "div",
                                  [
                                    _vm.chiTietHocBa
                                      ? _c("viewC1", {
                                          attrs: {
                                            chuKy: true,
                                            cauHinh: { isAnh: false },
                                            fileName: _vm.searchForm.student
                                              ? _vm.searchForm.student
                                              : _vm.searchForm.maLop
                                              ? _vm.searchForm.maLop
                                              : "Học Bạ Học Sinh",
                                            item: _vm.chiTietHocBa,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  [
                                    _vm.chiTietHocBa
                                      ? _c("viewC23", {
                                          attrs: {
                                            fileName: _vm.searchForm.student
                                              ? _vm.searchForm.student
                                              : _vm.searchForm.maLop
                                              ? _vm.searchForm.maLop
                                              : "Học Bạ Học Sinh",
                                            item: _vm.chiTietHocBa,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.mauMoi
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.detailHocBaPdf,
                      expression: "detailHocBaPdf",
                    },
                  ],
                  staticClass: "viewHocBa",
                  staticStyle: { overflow: "scroll", height: "95vh" },
                },
                [
                  _c("iframe", {
                    staticStyle: { width: "100%" },
                    attrs: { src: _vm.detailHocBaPdf, height: "100%" },
                  }),
                ]
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "page__title d-flex align-items-center justify-content-between",
      },
      [_c("h1", { staticClass: "title" }, [_vm._v("Quản lý học bạ")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }