<template>
  <div class="page">
    <div class="page__title d-flex align-items-center justify-content-between">
      <h1 class="title">Nhập dữ liệu bổ sung </h1>
      <el-tooltip class="item" effect="dark" content="Hướng dẫn nhập dữ liệu" placement="top">
        <a class="link" href="/files/huong_dan_nhap_du_lieu.docx"> Hướng dẫn nhập dữ liệu</a>
      </el-tooltip>
    </div>

    <div class="page__header">
      <el-row :gutter="20">
        <el-col :xs="12" :sm="6" :md="4" :lg="4">
          <label>Năm học</label>
          <eselect style="width:100%" collapseTags v-model="namHoc" placeholder="Chọn" filterable
                   :data="list_nam_hoc" :fields="['name','value']"/>
        </el-col>
        <el-col :xs="12" :sm="6" :md="4" :lg="4">
          <label>Cấp học</label>
          <eselect style="width:100%" collapseTags v-model="capHoc" placeholder="Chọn" filterable
                   :data="list_cap_hoc" :fields="['name','value']"/>
        </el-col>
      </el-row>

    </div>
    <div  v-if="user.maSo!=79"  class="page__header">
      <h5 class="title">Nhập dữ liệu mã Moet</h5>
      <small><i>(Truy cập Học bạ số -> Nhập dữ liệu bổ sung -> Tải file Nhập dữ liệu mã Moet)</i></small>

      <ImportGeneral :require-cap-hoc="true" :fileHasData="true"
                     :linkFileHasData="typeGiaoVien === 1? linkFileHasData : linkFileHasDataChung"
                     :fileNameError="'danh_sach_loi_nhap_giao_vien'"
                     :requireKhoiHoc="true"
                     :hideClass="false"
                     :pathSampleFile="typeGiaoVien === 1? pathSampleFile : pathSampleFileChung"
                     :requireNamHoc="true" :year="namHoc" :cap-hoc="capHoc"
                     :template-download="template" :source="source"
                     :requireImportType="true"
                     :data-type="17"
                     :text-download="'Tải file nhập dữ liệu mã Moet'"
                     :path="typeGiaoVien === 1? path :pathChung"
                     :disabledDownload="typeGiaoVien!=2&&false"
                     :importType="typeGiaoVien"></ImportGeneral>
    </div>
    <div v-if="capHoc!=''&&user.maSo!=79" class="page__header">
      <h5 class="title">Nhận xét của giáo viên chủ nhiệm</h5>
      <small><i> (Truy cập Học bạ số -> Nhập dữ liệu bổ sung -> Tải file Nhận xét của Giáo viên chủ nhiệm)</i></small>
      <!--      <p><i style="font-size:12px">(Truy cập Học bạ số -> Nhập dữ liệu -> Nhận xét giáo viên chủ nhiệm -> Tải dữ liệu học sinh)</i></p>-->
      <!--      <el-row :gutter="20">-->
      <!--        <el-col :xs="12" :sm="6" :md="4" :lg="4">-->
      <!--            <label>Phương thức nhập</label>-->
      <!--          <eselect class="mb-2" style="width:111.5%" :disabled="typeNXGvcn>=2" collapseTags v-model="typeNXGvcn" filterable-->
      <!--                   :data="listPhuongThucNhap" :fields="['name','value']" />-->
      <!--        </el-col>-->
      <!--      </el-row>-->
      <ImportGeneral :require-cap-hoc="true" :require-nam-hoc="true" :requireImportType="true" :year="namHoc" :cap-hoc="capHoc"
                     :fileNameError="'danh_sach_loi_nhap_nhan_xet_gvcn'"
                     :requireKhoiHoc="true"
                     :hideClass="false"
                     :text-download="'Tải file nhập Nhận xét của GVCN'"
                     :data-type="15"
                     :uri-dowload="dowloadGVCN" :source="source" :path="typeNXGvcn === 1? pathNhanXetGVCN : pathNhanXetGVCNChung"
                     :importType="2" name-file="nx-gvcn"></ImportGeneral>
    </div>
    <div  v-if="user.maSo!=79"  class="page__header">
      <h5 class="title">Nhập dữ liệu Năng lực - Phẩm chất</h5>
      <small><i> (Truy cập Học bạ số -> Nhập dữ liệu bổ sung -> Tải file Năng lực - Phẩm chất)</i></small>

      <ImportGeneral :require-cap-hoc="true" :fileHasData="true"
                     :linkFileHasData="typeGiaoVien === 1? linkFileHasData : linkFileHasDataChung"
                     :fileNameError="'danh_sach_loi_nhap_giao_vien'"
                     :requireKhoiHoc="true"
                     :hideClass="false"
                     :pathSampleFile="typeGiaoVien === 1? pathSampleFile : pathSampleFileChung"
                     :requireNamHoc="true" :year="namHoc" :cap-hoc="capHoc"
                     :template-download="template" :source="source"
                     :requireImportType="true"
                     :data-type="6"
                     :text-download="'Tải file nhập dữ liệu Năng lực - Phẩm chất'"
                     :path="typeGiaoVien === 1? path :pathChung"
                     :disabledDownload="false"
                     :importType="typeGiaoVien"></ImportGeneral>
    </div>
<!--    Chiều cao ca nặng-->
    <div  v-if="user.maSo!=79"  class="page__header">
      <h5 class="title">Nhập dữ liệu Chiều cao - Cân nặng</h5>
      <small><i>(Truy cập Học bạ số -> Nhập dữ liệu bổ sung -> Tải file mẫu Chiều cao - Cân nặng)</i></small>

      <ImportGeneral :require-cap-hoc="true" :fileHasData="true"
                     :linkFileHasData="typeGiaoVien === 1? linkFileHasData : linkFileHasDataChung"
                     :fileNameError="'danh_sach_loi_nhap_giao_vien'"
                     :requireKhoiHoc="true"
                     :hideClass="false"
                     :pathSampleFile="typeGiaoVien === 1? pathSampleFile : pathSampleFileChung"
                     :requireNamHoc="true" :year="namHoc" :cap-hoc="capHoc"
                     :template-download="template" :source="source"
                     :requireImportType="true"
                     :data-type="8"
                     :text-download="'Tải file nhập dữ liệu Chiều cao - Cân nặng'"
                     :path="typeGiaoVien === 1? path :pathChung"
                     :disabledDownload="false"
                     :importType="typeGiaoVien"></ImportGeneral>
    </div>
<!--    SỐ ngày nghỉ-->

    <div  v-if="user.maSo!=79"  class="page__header">
      <h5 class="title">Nhập dữ liệu Số ngày nghỉ</h5>
      <small><i>(Truy cập Học bạ số -> Nhập dữ liệu bổ sung -> Tải file mẫu Số ngày nghỉ)</i></small>

      <ImportGeneral :require-cap-hoc="true" :fileHasData="true"
                     :linkFileHasData="typeGiaoVien === 1? linkFileHasData : linkFileHasDataChung"
                     :fileNameError="'danh_sach_loi_nhap_giao_vien'"
                     :requireKhoiHoc="true"
                     :hideClass="false"
                     :pathSampleFile="typeGiaoVien === 1? pathSampleFile : pathSampleFileChung"
                     :requireNamHoc="true" :year="namHoc" :cap-hoc="capHoc"
                     :template-download="template" :source="source"
                     :requireImportType="true"
                     :data-type="9"
                     :text-download="'Tải file nhập dữ liệu Số ngày nghỉ'"
                     :path="typeGiaoVien === 1? path :pathChung"
                     :disabledDownload="false"
                     :importType="typeGiaoVien"></ImportGeneral>
    </div>
<!--    -->

    <div  v-if="user.maSo!=79"  class="page__header">
      <h5 class="title">Nhập dữ liệu Khen thưởng</h5>
      <small><i>(Truy cập Học bạ số -> Nhập dữ liệu bổ sung -> Tải file khen thưởng)</i></small>

      <ImportGeneral :require-cap-hoc="true" :fileHasData="true"
                     :linkFileHasData="typeGiaoVien === 1? linkFileHasData : linkFileHasDataChung"
                     :fileNameError="'danh_sach_loi_nhap_giao_vien'"
                     :requireKhoiHoc="true"
                     :hideClass="false"
                     :pathSampleFile="typeGiaoVien === 1? pathSampleFile : pathSampleFileChung"
                     :requireNamHoc="true" :year="namHoc" :cap-hoc="capHoc"
                     :template-download="template" :source="source"
                     :requireImportType="true"
                     :data-type="capHoc==1?10:14"
                     :text-download="'Tải file nhập dữ liệu Khen thưởng'"
                     :path="typeGiaoVien === 1? path :pathChung"
                     :disabledDownload="false"
                     :importType="typeGiaoVien"></ImportGeneral>
    </div>
<!--   Điểm tổng kết-->

    <div  v-if="user.maSo!=79"  class="page__header">
      <div class="page__title align-items-center justify-content-between">
        <h1 class="title">Điểm tổng kết</h1>
        <small><i> (Truy cập Học bạ số -> Nhập dữ liệu bổ sung -> Tải file Điểm tổng kết)</i></small>

        <!--          <p v-if="user.qlnt==1"><i style="font-size:12px">(Truy cập vnedu -> Tổng kết -> Chọn học kỳ 2 -> Nhập xuất -> Xuất ra excel toàn khối)</i></p>-->
        <!--          <p v-if="user.qlnt==2"><i style="font-size:12px">(Truy cập moet -> Học sinh -> Kết quả học tập rèn luyện -> Nhập tổng kết kết quả học tập từ excel -> Tải file mẫu)</i></p>-->
      </div>
      <!--        <el-row :gutter="20">-->
      <!--          <el-col :xs="12" :sm="6" :md="4" :lg="4">-->
      <!--            <label>Phương thức nhập (<i style="color: red;">*</i>)</label>-->
      <!--            <eselect class="mb-2" style="width:111.5%" collapseTags v-model="typeDiemTongKetC1" filterable-->
      <!--                   :data="this.user.qlnt==1? listPhuongThucNhapVnEdu:listPhuongThucNhapMoet" :fields="['name','value']" />-->
      <!--          </el-col>-->
      <!--        </el-row>-->
      <ImportForNhanXet
          :year="namHoc"
          :cap-hoc="capHoc"
          :data-type="7"
          :text-download="'Tải file nhập Điểm tổng kết'"
          :require-nam-hoc="true"
          :require-cap-hoc="true"
          :fileNameError="'danh_sach_loi_nhap_diem_tong_ket'"
          :requireImportType="true"
          :requireKhoiHoc="true"
          :hideClass="false"
          :require-khoi-hoc="getRequireKhoiHoc()"
          :uri-dowload="downloadDiemTongKetC1"
          :source="sourceDiemTongKet"
          :path="typeDiemTongKetC1 === 1? pathDiemtTongKetC1 : pathDiemTongKetC1Chung"
          :disabledDownload="typeDiemTongKetC1 != 2"
          :importType="typeDiemTongKetC1"
          name-file="diem-tong-ket"></ImportForNhanXet>
    </div>
<!--    Sổ nhận xét-->
    <div  v-if="user.maSo!=79"  class="page__header">
      <div class="page__title align-items-center justify-content-between">
        <h1 class="title">Sổ nhận xét</h1>

        <small><i> (Truy cập Học bạ số -> Nhập dữ liệu bổ sung -> Tải file mẫu Sổ nhận xét)</i></small>
      </div>
      <ImportForNhanXet
          :year="namHoc"
          :cap-hoc="capHoc"
          :require-nam-hoc="true"
          :require-cap-hoc="true"
          :data-type="5"
          :fileNameError="'danh_sach_loi_nhap_so_nhan_xet'"
          :requireImportType="true"
          :uri-dowload="downloadSoNhanXetChung"
          :source="source"
          :requireKhoiHoc="true"
          :text-download="'Tải file nhập Sổ nhận xét'"
          :hideClass="false"
          :disabledDownload="false"
          :path="typeSoNhanXet === 1? pathSoNhanXet : pathSoNhanXetChung"
          name-file="so-nhan-xet" :importType="typeSoNhanXet"></ImportForNhanXet>
    </div>

    <!--    Sổ nhận xét-->
    <div class="page__header" v-if="user.maSo ==79">
      <div class="page__title align-items-center justify-content-between">
        <h1 class="title">Sổ đăng bộ</h1>

        <small><i> (Truy cập Học bạ số -> Nhập dữ liệu bổ sung -> Tải file mẫu Sổ đăng bộ)</i></small>
      </div>
      <ImportForNhanXet
          :year="namHoc"
          :cap-hoc="capHoc"
          :require-nam-hoc="true"
          :require-cap-hoc="true"
          :data-type="18"
          :fileNameError="'danh_sach_loi_nhap_so_dang_bo'"
          :requireImportType="true"
          :uri-dowload="downloadSoNhanXetChung"
          :source="source"
          :requireKhoiHoc="true"
          :text-download="'Tải file nhập Sổ đăng bộ'"
          :hideClass="false"
          :disabledDownload="false"
          :path="typeSoNhanXet === 1? pathSoNhanXet : pathSoNhanXetChung"
          name-file="so-nhan-xet" :importType="typeSoNhanXet"></ImportForNhanXet>
    </div>

    <!--    Rèn luyện trong hè-->
    <div class="page__header" >
      <div class="page__title align-items-center justify-content-between">
        <h1 class="title">Kết quả rèn luyện trong kỳ nghỉ hè</h1>

        <small><i> (Truy cập Học bạ số -> Nhập dữ liệu bổ sung -> Tải file mẫu KQRL trong kỳ nghỉ hè)</i></small>
      </div>
      <ImportForNhanXet
          :year="namHoc"
          :cap-hoc="capHoc"
          :require-nam-hoc="true"
          :require-cap-hoc="true"
          :data-type="19"
          :fileNameError="'danh_sach_loi_ket_qua_ren_luyen_trong_ky_nghi_he'"
          :requireImportType="true"
          :uri-dowload="downloadSoNhanXetChung"
          :source="source"
          :requireKhoiHoc="true"
          :text-download="'Tải file nhập KQRL trong kỳ nghỉ hè'"
          :hideClass="false"
          :disabledDownload="false"
          :path="typeSoNhanXet === 1? pathSoNhanXet : pathSoNhanXetChung"
          name-file="so-nhan-xet" :importType="typeSoNhanXet"></ImportForNhanXet>
    </div>
  </div>

</template>

<script>
// import ImportGeneral from "./ImportGeneral";
import ImportGeneral from "./ImportChung.vue";
import constant_api from "../../_helpers/constant_api";
import ESelect from "../Ui/ESelect";
import {
  mapState
} from "vuex";
import ImportForNhanXet from "./ImportChung.vue";

export default {
  name: "ImportCanBo",
  metaInfo: {
    title: 'Nhập giáo viên',
    meta: [{
      vmid: 'description',
      name: 'description',
      content: ''
    }],
  },
  components: {
    ImportForNhanXet,
    ImportGeneral,
    'eselect': ESelect,
  },
  data() {
    return {
      template: [{
        label: 'VNEDU',
        type: 1,
        url: ''
      },

      ],
      typeNXGvcn:2,
      dowloadGVCN: constant_api.download.nhanXetGVCN,
      source: [{
        type: 1,
        label: 'VNEDU',
        rowHeader: 1
      }, {
        type: 2,
        label: 'MOET',
        rowHeader: 1
      },],
      path: constant_api.import.giaoVien,
      pathChung: constant_api.import.giaoVienChung,
      linkFileHasData: constant_api.download.linkFileHasData.giaoVienChung,
      linkFileHasDataChung: constant_api.download.linkFileHasData.giaoVienChung,
      pathNhanXetGVCN: constant_api.import.nhanXetGVCN,
      pathNhanXetGVCNChung: constant_api.import.nhanXetGVCNChung,
      pathDiemtTongKetC1: constant_api.import.diemtTongKetC1,
      pathDiemTongKetC1Chung: constant_api.import.diemTongKetC1Chung,
      downloadDiemTongKetC1: constant_api.download.diemTongKetC1,
      downloadSoNhanXetChung: constant_api.download.soNhanXetChung,
      pathSoNhanXet: constant_api.import.soNhanXet,
      pathSoNhanXetChung: constant_api.import.soNhanXetChung,
      typeSoNhanXet: '',
      sourceDiemTongKet: [
        {
          type: 1,
          label: 'VNEDU',
          rowHeader: 3
        },
        {
          type: 2,
          label: 'MOET',
          rowHeader: 3
        }
      ],
      typeDiemTongKetC1: '',
      typeDiemTongKetC1Moet: '',
      namHoc: '',
      capHoc: '',
      pathSampleFile: '',
      pathSampleFileChung: '',
      typeGiaoVien: '',
      listPhuongThucNhapVnEdu: [
        {
          'name': 'Mẫu VNEDU',
          'value': 1
        },
        {
          'name': 'Mẫu Học bạ số',
          'value': 2
        }
      ],
      listPhuongThucNhapMoet: [
        {
          'name': 'Mẫu MOET',
          'value': 1
        },
        {
          'name': 'Mẫu Học bạ số',
          'value': 2
        }
      ]
    }
  },
  methods: {

    getRequireKhoiHoc(){
      if(this.user.qlnt==1){
        return true
      }else{
        return false
      }
    },
    getFullYear() {
      let d = new Date();
      return d.getFullYear();
    },
    getListCapHoc() {
      if (this.user && this.user.capHoc) {
        let ch = this.user.capHoc.toString();
        let cap_hoc = ch.split('');
        this.capHoc = parseInt(cap_hoc[0]);
      }
    }
  },
  mounted() {
    this.getListCapHoc();
    this.namHoc = this.nam_hoc_hien_tai;
    if (this.user) {
      if (this.user.qlnt == 1) {
        this.pathSampleFile = '/files/vnedu/giao_vien_vnedu.xls';
        this.pathSampleFileChung = '/files/common/giao_vien.xlsx';
      }
      if (this.user.qlnt == 2) {
        this.pathSampleFile = '/files/moet/giao_vien_moet.xls';
        this.pathSampleFileChung = '/files/common/giao_vien.xlsx';
      }

    }
  },
  computed: {
    ...mapState('account',
        [
          "user",
          'list_nam_hoc',
          'nam_hoc_hien_tai',
          'list_cap_hoc',
        ]),

  },
}
</script>

<style scoped>
.title {
  margin-bottom: 0;
}

</style>