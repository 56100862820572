var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true, lock: true },
        },
      ],
      staticClass: "page page-file file detailHocBa",
      attrs: {
        id: "detailHocBa",
        "element-loading-text": "Loading..",
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "rgba(0, 0, 0, 0.8)",
      },
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "box-upload-file" },
        [
          _c(
            "el-form",
            {
              ref: "searchForm",
              attrs: {
                rules: _vm.rulesSearch,
                model: _vm.searchForm,
                "hide-required-asterisk": true,
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "d-inline-block",
                          attrs: { prop: "education", label: "Cấp học" },
                        },
                        [
                          _c("eselect", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              disabled: _vm.list_cap_hoc.length == 1,
                              collapseTags: "",
                              placeholder: "Chọn",
                              filterable: "",
                              data: _vm.list_cap_hoc,
                              fields: ["name", "value"],
                            },
                            on: { change: _vm.capHocChange },
                            model: {
                              value: _vm.searchForm.education,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "education", $$v)
                              },
                              expression: "searchForm.education",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "d-inline-block",
                          attrs: { prop: "year", label: "Năm học" },
                        },
                        [
                          _c("eselect", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              collapseTags: "",
                              placeholder: "Chọn",
                              filterable: "",
                              data: _vm.list_nam_hoc,
                              fields: ["name", "value"],
                            },
                            on: { change: _vm.namHocChange },
                            model: {
                              value: _vm.searchForm.year,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "year", $$v)
                              },
                              expression: "searchForm.year",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "d-inline-block",
                          attrs: { label: "Khối học" },
                        },
                        [
                          _c("eselect", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              collapseTags: "",
                              placeholder: "Chọn",
                              filterable: "",
                              data: _vm.optionGrade,
                              fields: ["label", "value"],
                            },
                            on: { change: _vm.khoiHocChange },
                            model: {
                              value: _vm.searchForm.grade,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "grade", $$v)
                              },
                              expression: "searchForm.grade",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "d-inline-block",
                          attrs: { label: "Lớp học", prop: "class" },
                        },
                        [
                          _c("eselect", {
                            ref: "class",
                            staticStyle: { width: "100%" },
                            attrs: {
                              collapseTags: "",
                              placeholder: "Chọn",
                              filterable: "",
                              data: _vm.danh_sach_lop_hoc,
                              fields: ["tenLop", "maLop"],
                            },
                            model: {
                              value: _vm.searchForm.class,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "class", $$v)
                              },
                              expression: "searchForm.class",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "d-inline-block",
                          attrs: { label: "Trạng thái" },
                        },
                        [
                          _c("eselect", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              collapseTags: "",
                              placeholder: "Trạng thái học sinh",
                              data: [
                                { label: "Đang học", value: 1 },
                                { label: "Chuyển trường", value: 3 },
                                { label: "Bỏ học", value: 4 },
                              ],
                              fields: ["label", "value"],
                            },
                            model: {
                              value: _vm.searchForm.trangThaiHocSinh,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.searchForm,
                                  "trangThaiHocSinh",
                                  $$v
                                )
                              },
                              expression: "searchForm.trangThaiHocSinh",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "d-inline-block",
                          attrs: { label: "Từ khoá" },
                        },
                        [
                          _c("el-input", {
                            attrs: { clearable: "", placeholder: "Nhập" },
                            model: {
                              value: _vm.searchForm.student,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "student", $$v)
                              },
                              expression: "searchForm.student",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "text-align": "center" },
                      attrs: { xs: 24, sm: 24, md: 6, lg: 24, xl: 3 },
                    },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "d-inline-block" },
                        [
                          _c("div", { staticStyle: { color: "transparent" } }, [
                            _vm._v("Tìm kiếm"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "m-0",
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.submitSearch("searchForm")
                                },
                              },
                            },
                            [_vm._v("Tìm kiếm\n            ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 24 } }, [
            _c(
              "div",
              { staticClass: "text-right" },
              [
                false
                  ? _c(
                      "el-button",
                      {
                        staticClass: "mb-1",
                        attrs: { type: "success", plain: "", size: "small" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.checkShowUploadBia($event)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "el-icon-printer" }),
                        _vm._v(" Scan bìa học bạ\n        "),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.user.qlnt != 0 && false
                  ? _c(
                      "el-button",
                      {
                        staticClass: "mb-1",
                        attrs: { type: "success", size: "small" },
                        on: { click: _vm.updateMutileImage },
                      },
                      [
                        _c("i", { staticClass: "el-icon-upload2" }),
                        _vm._v(" Cập nhật nhiều ảnh\n        "),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { ref: "page_content", staticClass: "page__content" }, [
        _c(
          "div",
          { staticClass: "listNew mb-5" },
          [
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { staticClass: "text-right", attrs: { span: 24 } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          disabled: _vm.listHocSinhCheckBia.length == 0,
                          type: "warning",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.chuanBiNopLenBo(4)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n            Nộp\n            hồ sơ Bìa lên sở\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          disabled: _vm.listHocSinhCheck.length == 0,
                          type: "success",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.chuanBiNopLenBo(5)
                          },
                        },
                      },
                      [_vm._v("Nộp\n            hồ sơ KQHT lên sở\n          ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-table",
              {
                ref: "listFile",
                attrs: {
                  border: "",
                  fit: "",
                  lazy: true,
                  data: _vm.listHs ? _vm.listHs.list : [],
                  width: "100%",
                  "max-height": _vm.maxHeight,
                },
                on: { "selection-change": _vm.handleSelectionChange },
              },
              [
                _c("el-table-column", {
                  staticStyle: { width: "50px" },
                  attrs: {
                    label: "STT",
                    width: "50",
                    type: "index",
                    "class-name": "text-center",
                    index: _vm.indexMethod,
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    "header-align": "center",
                    width: "80",
                    height: "80",
                    label: "Ảnh",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            { staticClass: "avatar" },
                            [
                              _c("img", {
                                attrs: {
                                  src: scope.row.avatar
                                    ? scope.row.avatar + "?t=" + _vm.getTime()
                                    : _vm.defaultAvatar,
                                  alt: "",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    content: "Xóa ảnh",
                                    placement: "top",
                                  },
                                },
                                [
                                  scope.row.removeAvatar
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "remove-avatar",
                                          attrs: { href: "" },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.removeAvatar(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "ion ion-ios-close",
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    "header-align": "center",
                    label: "Mã học sinh",
                    property: "maHS",
                    align: "center",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    "header-align": "center",
                    label: "Số đăng bộ",
                    property: "soDangBo",
                    align: "center",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    "header-align": "center",
                    label: "Họ và tên",
                    property: "tenHS",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    "header-align": "center",
                    label: "Lớp",
                    width: "100",
                    property: "tenLop",
                    align: "center",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    "header-align": "center",
                    label: "Trạng thái học sinh",
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.getTrangThaiHocSinh(scope.row.trangThai)
                              ),
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _vm.show_nop
                  ? _c("el-table-column", {
                      attrs: {
                        "header-align": "center",
                        label: "Trạng thái nộp bìa",
                        align: "left",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "p",
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getTrangThaiNop(
                                          scope.row.trangThaiBia
                                        )
                                      ) + "\n              "
                                    ),
                                    scope.row.moTaTrangThaiNopBia
                                      ? _c(
                                          "el-tooltip",
                                          { attrs: { placement: "top" } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                attrs: { slot: "content" },
                                                slot: "content",
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row
                                                        .moTaTrangThaiNopBia
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("i", {
                                              staticClass: "el-icon-info",
                                              staticStyle: {
                                                cursor: "pointer",
                                                color: "blue",
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1270088886
                      ),
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    "header-align": "center",
                    label: "Trạng thái nộp học bạ",
                    align: "left",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "p",
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.getTrangThaiNop(scope.row.trangThaiKQHT)
                                  ) +
                                  "\n              "
                              ),
                              scope.row.moTaTrangThaiNopHocBa
                                ? _c(
                                    "el-tooltip",
                                    { attrs: { placement: "top" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "content" },
                                          slot: "content",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.moTaTrangThaiNopHocBa
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("i", {
                                        staticClass: "el-icon-info",
                                        staticStyle: {
                                          cursor: "pointer",
                                          color: "blue",
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                false
                  ? _c("el-table-column", {
                      attrs: {
                        "header-align": "center",
                        width: "420px",
                        label: "Hành động",
                        "class-name": "",
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "btn__table_action d-flex" },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          content: "Xem chi tiết",
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c("el-button", {
                                          attrs: {
                                            size: "mini",
                                            type: "primary",
                                            icon: "el-icon-view",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showDialogInfoHocBa(
                                                scope.row,
                                                scope.$index
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    scope.row.loaiNopKqht == -1 ||
                                    scope.row.loaiNopKqht == 0
                                      ? _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              content: "Nộp hồ sơ lên sở",
                                              placement: "top",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticStyle: {
                                                  "margin-left": "4px",
                                                },
                                                attrs: {
                                                  size: "mini",
                                                  c: "",
                                                  type: "success",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.nopHoSoLenBo(
                                                      [scope.row.maHS],
                                                      5
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  Nộp dữ liệu KQHT\n                "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    scope.row.loaiNopKqht == 1
                                      ? _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              content: "Nộp hồ sơ lên sở",
                                              placement: "top",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticStyle: {
                                                  "margin-left": "4px",
                                                },
                                                attrs: {
                                                  size: "mini",
                                                  type: "success",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.nopHoSoLenBo(
                                                      [scope.row.maHS],
                                                      5
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  Nộp dữ liệu KQHT\n                "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    scope.row.loaiNopKqht == 3
                                      ? _c(
                                          "el-tooltip",
                                          { attrs: { placement: "top" } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                attrs: { slot: "content" },
                                                slot: "content",
                                              },
                                              [
                                                scope.row.trangThaiKQHT === null
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "Nộp hiệu chỉnh dữ liệu KQHT."
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                scope.row.trangThaiKQHT === 0
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "Đang trong quá trình xử lý."
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                scope.row.trangThaiKQHT === 1
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "Hồ sơ đã được duyệt."
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                scope.row.trangThaiKQHT === 2
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "Hồ sơ bị từ duyệt."
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                scope.row.lyDoTuChoiKQHT
                                                  ? _c("span", [
                                                      _c("br"),
                                                      _vm._v(
                                                        _vm._s(
                                                          scope.row
                                                            .lyDoTuChoiKQHT
                                                        )
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-button",
                                              {
                                                staticStyle: {
                                                  "margin-left": "4px",
                                                },
                                                attrs: {
                                                  disabled:
                                                    scope.row.trangThaiKQHT ===
                                                    0,
                                                  size: "mini",
                                                  type: "success",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.nopHieuChinh(
                                                      [scope.row.maHS],
                                                      5
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  Nộp hiệu chỉnh KQHT\n                "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    scope.row.loaiNopKqht == 2
                                      ? _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              content:
                                                "Nộp bổ sung hồ sơ lên sở",
                                              placement: "top",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticStyle: {
                                                  "margin-left": "4px",
                                                },
                                                attrs: {
                                                  size: "mini",
                                                  type: "success",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.nopBoSung(
                                                      [scope.row.maHS],
                                                      5
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  Nộp bổ sung KQHT\n                "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    scope.row.lyDoTuChoiKQHT
                                      ? _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              content: "Xem chi tiết",
                                              placement: "top",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                attrs: { slot: "content" },
                                                slot: "content",
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row.lyDoTuChoiKQHT
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "i",
                                              {
                                                staticClass: "el-icon-info",
                                                staticStyle: {
                                                  color: "blue",
                                                  "font-size": "20px",
                                                  cursor: "pointer",
                                                  "margin-left": "-5px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      color: "transparent",
                                                    },
                                                  },
                                                  [_vm._v("x")]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    scope.row.loaiNopBia == -1 ||
                                    scope.row.loaiNopBia == 0
                                      ? _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              content: "Nộp hồ sơ lên sở",
                                              placement: "top",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticStyle: {
                                                  "margin-left": "4px",
                                                },
                                                attrs: {
                                                  disabled: "",
                                                  size: "mini",
                                                  type: "warning",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.nopHoSoLenBo(
                                                      [scope.row.maHS],
                                                      4
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  Nộp dữ liệu bìa\n                "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    scope.row.loaiNopBia == 1
                                      ? _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              content: "Nộp hồ sơ lên sở",
                                              placement: "top",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticStyle: {
                                                  "margin-left": "4px",
                                                },
                                                attrs: {
                                                  size: "mini",
                                                  type: "warning",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.nopHoSoLenBo(
                                                      [scope.row.maHS],
                                                      4
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  Nộp dữ liệu bìa\n                "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    scope.row.loaiNopBia == 2
                                      ? _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              content:
                                                "Nộp bổ sung hồ sơ lên sở",
                                              placement: "top",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticStyle: {
                                                  "margin-left": "4px",
                                                },
                                                attrs: {
                                                  size: "mini",
                                                  type: "warning",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.nopBoSung(
                                                      [scope.row.maHS],
                                                      4
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  Nộp bổ sung bìa\n                "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    scope.row.loaiNopBia == 3
                                      ? _c(
                                          "el-tooltip",
                                          { attrs: { placement: "top" } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                attrs: { slot: "content" },
                                                slot: "content",
                                              },
                                              [
                                                scope.row.trangThaiBia === null
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "Nộp hiệu chỉnh dữ liệu bìa."
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                scope.row.trangThaiBia === 0
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "Đang trong quá trình xử lý."
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                scope.row.trangThaiBia === 1
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "Hồ sơ đã được duyệt."
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                scope.row.trangThaiBia === 2
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "Hồ sơ bị từ duyệt."
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                scope.row.lyDoTuChoiBia
                                                  ? _c("span", [
                                                      _c("br"),
                                                      _vm._v(
                                                        _vm._s(
                                                          scope.row
                                                            .lyDoTuChoiBia
                                                        )
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-button",
                                              {
                                                staticStyle: {
                                                  "margin-left": "4px",
                                                },
                                                attrs: {
                                                  size: "mini",
                                                  disabled:
                                                    scope.row.trangThaiBia ===
                                                    0,
                                                  type: "warning",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.nopHieuChinh(
                                                      [scope.row.maHS],
                                                      4
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  Nộp hiệu chỉnh bìa\n                "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    scope.row.lyDoTuChoiBia
                                      ? _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              content: "Xem chi tiết",
                                              placement: "top",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                attrs: { slot: "content" },
                                                slot: "content",
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row.lyDoTuChoiBia
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "i",
                                              {
                                                staticClass: "el-icon-info",
                                                staticStyle: {
                                                  color: "blue",
                                                  "font-size": "20px",
                                                  cursor: "pointer",
                                                  "margin-left": "-5px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      color: "transparent",
                                                    },
                                                  },
                                                  [_vm._v("x")]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2645540010
                      ),
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-table-column",
                  { attrs: { label: "Chọn nộp", align: "center" } },
                  [
                    _vm.show_nop
                      ? _c("el-table-column", {
                          staticStyle: { width: "100px" },
                          attrs: {
                            width: "100",
                            align: "center",
                            "header-align": "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "header",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-checkbox",
                                      {
                                        on: {
                                          change:
                                            _vm.ChonToanBoHocBaNopHoSoLenBoBia,
                                        },
                                        model: {
                                          value: _vm.CheckAllBia,
                                          callback: function ($$v) {
                                            _vm.CheckAllBia = $$v
                                          },
                                          expression: "CheckAllBia",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticStyle: { color: "white" } },
                                          [_vm._v("Bìa")]
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-checkbox", {
                                      attrs: {
                                        disabled: scope.row.checkStatusNopBia,
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.chonMoiHocBaNopHoSoBia(
                                            scope.row
                                          )
                                        },
                                      },
                                      model: {
                                        value: scope.row.checkChonBia,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "checkChonBia",
                                            $$v
                                          )
                                        },
                                        expression: "scope.row.checkChonBia",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1774781230
                          ),
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("el-table-column", {
                      staticStyle: { width: "100px" },
                      attrs: {
                        width: "100",
                        align: "center",
                        "header-align": "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "header",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-checkbox",
                                {
                                  on: {
                                    change: _vm.ChonToanBoHocBaNopHoSoLenBo,
                                  },
                                  model: {
                                    value: _vm.CheckAllKQHT,
                                    callback: function ($$v) {
                                      _vm.CheckAllKQHT = $$v
                                    },
                                    expression: "CheckAllKQHT",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "white" } },
                                    [_vm._v("KQHT")]
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-checkbox", {
                                attrs: {
                                  disabled: scope.row.checkStatusNopKqht,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.chonMoiHocBaNopHoSo(scope.row)
                                  },
                                },
                                model: {
                                  value: scope.row.checkChonKqht,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "checkChonKqht", $$v)
                                  },
                                  expression: "scope.row.checkChonKqht",
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.listHs && _vm.listHs.total
              ? _c(
                  "div",
                  { staticClass: "mt-2" },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c("chonSoLuong", {
                              on: {
                                chonXongSoLuong: function ($event) {
                                  return _vm.ChonSoLuongBanGhi($event)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { staticClass: "text-right", attrs: { span: 16 } },
                          [
                            _c("small", { staticClass: "pd-r-10" }, [
                              _c("i", [
                                _vm._v(
                                  "Danh sách có " +
                                    _vm._s(_vm.listHs.total) +
                                    " học bạ "
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("pagination", {
                              attrs: {
                                value: _vm.paginate.current,
                                "page-count": _vm.getTotalPage(
                                  _vm.listHs.total
                                ),
                                "click-handler": _vm.getHocBa,
                                "prev-text": "Trước",
                                "next-text": "Sau",
                                "container-class": "el-pager",
                                pageClass: "number",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "info-hocba",
          attrs: {
            title: "Thông tin học bạ",
            "append-to-body": "",
            visible: _vm.showInfoHocba,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showInfoHocba = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              attrs: {
                fit: "",
                border: "",
                lazy: true,
                data: _vm.listHocBa,
                width: "100%",
              },
            },
            [
              _c("el-table-column", {
                staticStyle: { width: "50px" },
                attrs: {
                  label: "STT",
                  width: "50",
                  type: "index",
                  "class-name": "text-center",
                  index: _vm.indexMethod,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "Họ và tên",
                  property: "hoten",
                  "class-name": "text-center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "Lớp",
                  property: "tenLop",
                  width: "70",
                  "class-name": "text-center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "GVCN",
                  property: "tenGVCN",
                  "class-name": "text-center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "Năm học",
                  property: "",
                  "class-name": "text-center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.namHoc) +
                            " - " +
                            _vm._s(scope.row.namHoc + 1) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Hành động", "class-name": "text-center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "btn__table_action" },
                          [
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  content: "Xem học bạ",
                                  placement: "top",
                                },
                              },
                              [
                                _c("el-button", {
                                  staticClass: "mb-1",
                                  attrs: { icon: "ion ion-md-eye", plain: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.viewFile(scope.row.id)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            scope.row.nguonDuLieu
                              ? _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: "Sửa học bạ",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c("el-button", {
                                      staticClass: "mb-1",
                                      attrs: {
                                        type: "primary",
                                        icon: "el-icon-edit",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.suaHocBa(scope.row)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialogCreate()
                    },
                  },
                },
                [_vm._v("Đóng")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("ThongTinHocBa", {
        attrs: { show: _vm.thongTinHocBa, id_hoc_ba: _vm.idHocBa },
        on: {
          close: function ($event) {
            _vm.thongTinHocBa = false
          },
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "add-hocba",
          attrs: {
            title: "Thêm học bạ",
            visible: _vm.showAddHocBa,
            "append-to-body": "",
            "before-close": _vm.closeDialogCreate,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showAddHocBa = $event
            },
          },
        },
        [
          _c("them-hoc-ba", {
            on: {
              close: function ($event) {
                return _vm.closeDialogCreate()
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "edit-hocba",
          attrs: {
            title: "Sửa học bạ",
            visible: _vm.showEditHocBa,
            "append-to-body": "",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showEditHocBa = $event
            },
          },
        },
        [
          _c("sua-hoc-ba", {
            on: {
              close: function ($event) {
                return _vm.closeDialogEdit()
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Tải lên ảnh học sinh",
            "append-to-body": "",
            center: "",
            "custom-class": "add-mutile-image",
            visible: _vm.showUpdateMutile,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showUpdateMutile = $event
            },
          },
        },
        [
          _c(
            "el-upload",
            {
              ref: "uploadMutile",
              staticClass: "upload-demo text-center",
              attrs: {
                drag: "",
                action: "/",
                "file-list": _vm.fileList,
                "on-error": _vm.showError,
                "on-change": _vm.uploadMutilAvatarChange,
                "on-remove": _vm.uploadMutilAvatarChange,
                accept: ".png,.jpg,.jpeg",
                "auto-upload": false,
                multiple: "",
              },
            },
            [
              _c("i", { staticClass: "el-icon-upload" }),
              _vm._v(" "),
              _c("div", { staticClass: "el-upload__text" }, [
                _vm._v("Kéo thả hoặc "),
                _c("em", [_vm._v("click ở đây")]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _vm._v(
                    "\n        Bạn hãy chọn tất cả ảnh của lớp (định dạng png,jpg). Lưu ý: đặt tên file là mã học sinh."
                  ),
                  _c("br"),
                  _vm._v(
                    "\n        Hệ thống sẽ tự động upload tất cả ảnh học sinh có tên file trùng với mã học sinh tương ứng.\n      "
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.uploadMutilAvatar($event)
                    },
                  },
                },
                [_vm._v("Tải ảnh lên")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "File Scan trang bìa học bạ",
            "append-to-body": "",
            center: "",
            "custom-class": "add-mutile-image",
            visible: _vm.showUploadBia,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showUploadBia = $event
            },
          },
        },
        [
          _c(
            "el-upload",
            {
              ref: "uploadBia",
              staticClass: "upload-demo text-center",
              attrs: {
                drag: "",
                action: "/",
                "on-change": _vm.uploadMutilAvatarChange,
                "on-remove": _vm.uploadMutilAvatarChange,
                accept: ".pdf,.zip,.zar",
                "auto-upload": false,
                multiple: "",
              },
            },
            [
              _c("i", { staticClass: "el-icon-upload" }),
              _vm._v(" "),
              _c("div", { staticClass: "el-upload__text" }, [
                _vm._v("Kéo thả hoặc "),
                _c("em", [_vm._v("click ở đây")]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _vm._v(
                    "\n        Bạn hãy chọn tất cả file của lớp (định dạng pdf). Lưu ý: đặt tên file là mã học sinh."
                  ),
                  _c("br"),
                  _vm._v(
                    "\n        Hệ thống sẽ tự động upload tất cả file học sinh có tên file trùng với mã học sinh tương ứng."
                  ),
                  _c("br"),
                  _vm._v(
                    "\n        Lưu ý: Nếu hệ thống đã sinh file pdf, upload từ ngoài vào hệ thống sẽ thay bằng file upload.\n      "
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "success" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.uploadBiaHocBa($event)
                    },
                  },
                },
                [_vm._v("Tải lên")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "File Scan trang học bạ",
            "append-to-body": "",
            center: "",
            "custom-class": "add-mutile-image",
            visible: _vm.showUploadHocBa,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showUploadHocBa = $event
            },
          },
        },
        [
          _c(
            "el-upload",
            {
              ref: "uploadFileHocBa",
              staticClass: "upload-demo text-center",
              attrs: {
                drag: "",
                action: "/",
                "on-change": _vm.uploadMutilAvatarChange,
                "on-remove": _vm.uploadMutilAvatarChange,
                accept: ".pdf",
                "auto-upload": false,
                multiple: "",
              },
            },
            [
              _c("i", { staticClass: "el-icon-upload" }),
              _vm._v(" "),
              _c("div", { staticClass: "el-upload__text" }, [
                _vm._v("Kéo thả hoặc "),
                _c("em", [_vm._v("click ở đây")]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _vm._v(
                    "\n        Bạn hãy chọn tất cả file học bạ của lớp (định dạng pdf). Lưu ý: đặt tên file là mã học sinh."
                  ),
                  _c("br"),
                  _vm._v(
                    "\n        Hệ thống sẽ tự động upload tất cả file học sinh có tên file trùng với mã học sinh tương ứng."
                  ),
                  _c("br"),
                  _vm._v(
                    "\n        Lưu ý: Nếu hệ thống đã sinh file pdf, upload từ ngoài vào hệ thống sẽ thay bằng file upload.\n      "
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.uploadFileHocBa($event)
                    },
                  },
                },
                [_vm._v("Tải lên")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "popupPhanQuyenBoMon",
          attrs: {
            title: "Phân quyền sửa học bạ",
            "append-to-body": "",
            center: "",
            visible: _vm.showPermissionEdit,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showPermissionEdit = $event
            },
          },
        },
        [
          _c(
            "el-tabs",
            { attrs: { type: "card" } },
            [
              _vm.user.capHoc > 1
                ? _c("el-tab-pane", { attrs: { label: "Giáo viên bộ môn" } }, [
                    _c(
                      "div",
                      { staticClass: "page" },
                      [
                        _c("h4", [_vm._v("Danh sách giáo viên bộ môn")]),
                        _vm._v(" "),
                        _c(
                          "el-table",
                          {
                            staticClass: "table-custom",
                            staticStyle: { width: "100%" },
                            attrs: {
                              data: _vm.lstMonDetail.ketQuaHocTaps,
                              border: "",
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                prop: "tenMonHoc",
                                label: "Tên môn học",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                "header-align": "center",
                                prop: "tenGiaoVien",
                                label: "Tên giáo viên",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { align: "center", label: "Trạng thái" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              scope.row.trangThaiXacNhan == 1
                                                ? "Đã ký"
                                                : "Chưa ký"
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3913243529
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                prop: "ghiChu",
                                label: "Ghi chú",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { align: "center", label: "Hành động" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              content: "Phân quyền",
                                              effect: "dark",
                                              placement: "top",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  size: "small",
                                                  type: "danger",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.phanQuyenBoMon(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "el-icon-edit",
                                                }),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3730942446
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("el-tab-pane", { attrs: { label: "Giáo viên chủ nhiệm" } }, [
                _c(
                  "div",
                  { staticClass: "text-center" },
                  [
                    _c("div", [
                      _c("label", [_vm._v("Giáo viên chủ nhiệm:")]),
                      _vm._v(
                        " " +
                          _vm._s(_vm.lstMonDetail.tenGVCN) +
                          " - Lớp :\n            " +
                          _vm._s(_vm.lstMonDetail.tenLop) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-input", {
                      attrs: {
                        type: "textarea",
                        rows: 4,
                        placeholder: "Nhập lý do",
                      },
                      model: {
                        value: _vm.lyDoGVCN,
                        callback: function ($$v) {
                          _vm.lyDoGVCN = $$v
                        },
                        expression: "lyDoGVCN",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "mt-2",
                        attrs: { type: "primary" },
                        on: { click: _vm.submitPermissionGVCN },
                      },
                      [_vm._v("Phân quyền")]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-tab-pane", { attrs: { label: "Hiệu trưởng" } }, [
                _c(
                  "div",
                  { staticClass: "text-center" },
                  [
                    _c("div", [
                      _c("label", [_vm._v("Hiệu trưởng:")]),
                      _vm._v(" " + _vm._s(_vm.lstMonDetail.tenHieuTruong)),
                    ]),
                    _vm._v(" "),
                    _c("el-input", {
                      attrs: {
                        type: "textarea",
                        rows: 4,
                        placeholder: "Nhập lý do",
                      },
                      model: {
                        value: _vm.lydoHieuTruong,
                        callback: function ($$v) {
                          _vm.lydoHieuTruong = $$v
                        },
                        expression: "lydoHieuTruong",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "mt-2",
                        attrs: { type: "primary" },
                        on: { click: _vm.submitPermissionHieuTruong },
                      },
                      [_vm._v("Phân quyền\n          ")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Phân quyền cho bộ môn",
            "append-to-body": "",
            center: "",
            visible: _vm.showPopUpBoMon,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showPopUpBoMon = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("div", [
                _c("label", [_vm._v("Tên giáo viên:")]),
                _vm._v(" " + _vm._s(_vm.dataBomon.tenGiaoVien) + " - "),
                _c("label", [_vm._v("Môn học:")]),
                _vm._v(
                  "\n        " + _vm._s(_vm.dataBomon.tenMonHoc) + "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("el-input", {
                attrs: { type: "textarea", rows: 5, placeholder: "Nhập lý do" },
                model: {
                  value: _vm.lyDoBoMon,
                  callback: function ($$v) {
                    _vm.lyDoBoMon = $$v
                  },
                  expression: "lyDoBoMon",
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "text-center mt-3" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.submitPermissionBoMon($event)
                        },
                      },
                    },
                    [_vm._v("Xác nhận")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Chỉnh sửa thông tin",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "append-to-body": "",
            center: "",
            visible: _vm.show_update_ngay_vao_truong,
          },
          on: {
            "update:visible": function ($event) {
              _vm.show_update_ngay_vao_truong = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("label", [_vm._v("Tên học sinh")]),
                      _vm._v(" "),
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.infoUpdate.tenHS,
                          callback: function ($$v) {
                            _vm.$set(_vm.infoUpdate, "tenHS", $$v)
                          },
                          expression: "infoUpdate.tenHS",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("label", [_vm._v("Mã học sinh")]),
                      _vm._v(" "),
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.infoUpdate.maHS,
                          callback: function ($$v) {
                            _vm.$set(_vm.infoUpdate, "maHS", $$v)
                          },
                          expression: "infoUpdate.maHS",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "mt-3", attrs: { span: 12 } },
                    [
                      _c("label", [
                        _vm._v("Địa danh ký "),
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-input", {
                        attrs: {
                          maxlength: "50",
                          placeholder: "Nhập địa danh ký",
                          "show-word-limit": "",
                          clearable: "",
                        },
                        model: {
                          value: _vm.infoUpdate.tenDiaDanhKyBia,
                          callback: function ($$v) {
                            _vm.$set(_vm.infoUpdate, "tenDiaDanhKyBia", $$v)
                          },
                          expression: "infoUpdate.tenDiaDanhKyBia",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "mt-3", attrs: { span: 12 } },
                    [
                      _c("label", [
                        _vm._v("Ngày ký lý lịch học sinh "),
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          format: "dd/MM/yyyy",
                          "value-format": "dd/MM/yyyy",
                          type: "date",
                          placeholder: "dd/mm/yyyy",
                        },
                        model: {
                          value: _vm.infoUpdate.ngayKyBia,
                          callback: function ($$v) {
                            _vm.$set(_vm.infoUpdate, "ngayKyBia", $$v)
                          },
                          expression: "infoUpdate.ngayKyBia",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "text-center mt-5" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "default" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          _vm.show_update_ngay_vao_truong = false
                        },
                      },
                    },
                    [_vm._v("Đóng")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "warning" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.chinhSuaNgayKyBia()
                        },
                      },
                    },
                    [_vm._v("Chỉnh sửa")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("dialog-alert", {
        attrs: {
          title: "Thông báo",
          message: _vm.messageAlert,
          "show-dialog": _vm.showDialogAlert,
        },
        on: { closeDialog: _vm.closeDialogAlert },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.hieuChinh.title,
            visible: _vm.show_nop_hieu_chinh,
            "before-close": _vm.handleCloseNopHieuChinh,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.show_nop_hieu_chinh = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  { staticClass: "demo-input-suffix" },
                  [
                    _c("span", { staticClass: "demo-input-label" }, [
                      _vm._v("Nội dung hiệu chỉnh"),
                    ]),
                    _vm._v(" "),
                    _c("el-input", {
                      attrs: {
                        type: "textarea",
                        rows: 2,
                        maxlength: "500",
                        "show-word-limit": "",
                        placeholder: "Nhập nội dung hiệu chỉnh",
                        "suffix-icon": "el-icon-check",
                      },
                      model: {
                        value: _vm.hieuChinh.noiDung,
                        callback: function ($$v) {
                          _vm.$set(_vm.hieuChinh, "noiDung", $$v)
                        },
                        expression: "hieuChinh.noiDung",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.show_nop_hieu_chinh = false
                    },
                  },
                },
                [_vm._v("Đóng")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    disabled: !_vm.hieuChinh.noiDung,
                    type: _vm.hieuChinh.loai == 4 ? "warning" : "success",
                  },
                  on: { click: _vm.nopHieuChinhLenBo },
                },
                [_vm._v(_vm._s(_vm.hieuChinh.title))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "page__title d-flex align-items-center justify-content-between",
      },
      [_c("h1", { staticClass: "title" }, [_vm._v("Nộp học bạ")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }