<template>
<div class="page page-404">
</div>
</template>

<script>
    export default {
        name: "page-404",
        mounted() {
          this.chuyenHuong();
        },

        methods:{
          chuyenHuong(){
            let url = this.$route.query.url;
            window.open(url,'blank')
            setTimeout(()=>{
              window.open(this.$route.meta.previousRoute,'_self')
            },100)
          },
        }
    }
</script>

<style scoped>

</style>
