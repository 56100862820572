var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true, lock: true },
        },
      ],
      staticClass: "page page-file file",
    },
    [
      _c("div", [
        _c("div", { staticClass: "file--title" }, [
          _c("h5", { staticClass: "title" }, [_vm._v("Danh sách học bạ")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "file-actions" },
            [
              _c(
                "el-button",
                {
                  staticClass: "mb-1",
                  attrs: {
                    type: "info",
                    icon: "el-icon-office-building",
                    plain: "",
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.chuyenTruongClick($event)
                    },
                  },
                },
                [_vm._v("Chuyển trường")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "mb-1",
                  attrs: {
                    type: "danger",
                    icon: "el-icon-s-cooperation",
                    plain: "",
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.rutHocBaClick($event)
                    },
                  },
                },
                [_vm._v("Rút học bạ")]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "page__content" }, [
          _c(
            "div",
            { staticClass: "listNew mb-5" },
            [
              _c(
                "el-table",
                {
                  ref: "listFile",
                  attrs: {
                    border: "",
                    stripe: "",
                    fit: "",
                    lazy: true,
                    data: _vm.listHocBa.list,
                    width: "100%",
                  },
                },
                [
                  _c("el-table-column", {
                    staticStyle: { width: "50px" },
                    attrs: {
                      label: "STT",
                      property: "title",
                      width: "50",
                      type: "index",
                      "class-name": "text-center",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Họ tên", property: "hoten" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Lớp", property: "tenLop" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "GVCN", property: "tenGVCN" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "Trường",
                      property: "tenTruong",
                      width: "300",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Hành động", "class-name": "text-center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              { staticClass: "btn__table_action" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "mb-1",
                                    attrs: {
                                      type: "info",
                                      icon: "ion ion-md-eye",
                                      plain: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.viewFile(scope.row.id)
                                      },
                                    },
                                  },
                                  [_vm._v(" Xem")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "page__content" },
          [
            _c(
              "el-table",
              {
                ref: "listRequest",
                attrs: {
                  border: "",
                  stripe: "",
                  fit: "",
                  lazy: true,
                  data: _vm.listRequest.list,
                  width: "100%",
                },
              },
              [
                _c("el-table-column", {
                  staticStyle: { width: "50px" },
                  attrs: {
                    label: "STT",
                    property: "title",
                    width: "50",
                    type: "index",
                    "class-name": "text-center",
                    index: _vm.indexMethod,
                  },
                }),
                _vm._v("Phân lớp\n\n                "),
                _c("el-table-column", {
                  attrs: { label: "Loại yêu cầu" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.tenTruongYC
                            ? _c("div", [
                                _vm._v(
                                  "\n                            Chuyển trường\n                        "
                                ),
                              ])
                            : _c("div", [
                                _vm._v(
                                  "\n                            Rút học bạ\n                        "
                                ),
                              ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "Trường yêu cầu",
                    property: "tenTruongYC",
                    width: "400",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "Trạng thái trường đang học",
                    property: "moTaTrangThaiTruongHS",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "Trạng thái trường yêu cầu",
                    property: "moTaTrangThaiTruongYC",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.tenTruongYC
                            ? _c("div", [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(scope.row.moTaTrangThaiTruongYC) +
                                    "\n                        "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "Ngày yêu cầu" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm._f("moment")(
                                  new Date(scope.row.createdDate),
                                  "DD/MM/YYYY"
                                )
                              ) +
                              "\n                    "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "text-right mt-2" },
              [
                _vm.listRequest.total
                  ? _c("pagination", {
                      attrs: {
                        "page-count": _vm.getTotalPage(_vm.listRequest.total),
                        "click-handler": _vm.getListRequests,
                        "prev-text": "Trước",
                        "next-text": "Sau",
                        "container-class": "el-pager",
                        pageClass: "number",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "popupChuyenTruong",
          attrs: {
            title: "Chuyển trường",
            visible: _vm.showChuyenTruong,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showChuyenTruong = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  attrs: { "label-position": "right", "label-width": "180px" },
                  model: {
                    value: _vm.formChuyenTruong,
                    callback: function ($$v) {
                      _vm.formChuyenTruong = $$v
                    },
                    expression: "formChuyenTruong",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Tỉnh/Thành phố" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "Chọn Tỉnh/TP" },
                          on: { change: _vm.provinceChange },
                          model: {
                            value: (_vm.formChuyenTruong.provinceId =
                              _vm.user.tinh.maTinh),
                            callback: function ($$v) {
                              _vm.$set(
                                (_vm.formChuyenTruong.provinceId =
                                  _vm.user.tinh),
                                "maTinh",
                                $$v
                              )
                            },
                            expression:
                              "formChuyenTruong.provinceId = user.tinh.maTinh",
                          },
                        },
                        _vm._l(_vm.provinces, function (item) {
                          return _c("el-option", {
                            key: item.maTinh,
                            attrs: { label: item.tenTinh, value: item.maTinh },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Cấp học" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { disabled: "", placeholder: "Chọn cấp học" },
                          on: { change: _vm.capHocChange },
                          model: {
                            value: (_vm.formChuyenTruong.capHoc =
                              _vm.user.capHoc),
                            callback: function ($$v) {
                              _vm.$set(
                                (_vm.formChuyenTruong.capHoc = _vm.user),
                                "capHoc",
                                $$v
                              )
                            },
                            expression: "formChuyenTruong.capHoc = user.capHoc",
                          },
                        },
                        _vm._l(_vm.optionEducation, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Trường chuyển đến" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            placeholder: "Chọn trường chuyển đến",
                          },
                          model: {
                            value: _vm.formChuyenTruong.truongHocId,
                            callback: function ($$v) {
                              _vm.$set(_vm.formChuyenTruong, "truongHocId", $$v)
                            },
                            expression: "formChuyenTruong.truongHocId",
                          },
                        },
                        _vm._l(_vm.listTruong, function (item) {
                          return _c("el-option", {
                            key: item.maTruongHoc,
                            attrs: {
                              label: item.tenTruongHoc,
                              value: item.maTruongHoc,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showChuyenTruong = false
                    },
                  },
                },
                [_vm._v("Thoát")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmChuyenTruong },
                },
                [_vm._v("Xác nhận")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "file--title" }, [
      _c("h5", { staticClass: "title" }, [_vm._v("Danh sách yêu cầu")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }