<template>
    <div class="logo">

        <div class="name mr-3">
            <el-link class="userinfo d-flex align-items-center" @click="openPage()" :underline="false">
                <div class="d-flex align-items-center">
                    <img src="/images/img2.png" alt="logo">
                    <span class="name--display"> {{ this.thongtin.displayName }} </span>
                </div>
            </el-link>
        </div>
        <div class="logout ml-auto">
            <el-tooltip   effect="light" content="Đăng xuất">
            <a href="#" @click.prevent="checkLogOut">
                <font-awesome-icon :icon="['fas', 'sign-out-alt']" class="icon alt"/>
            </a>
            </el-tooltip>
        </div>
    </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
    name: "UserInfo",
    data() {
        return {
            thongtin: {},
            list_menu: [],
        }
    },
    methods: {
        ...mapActions('account', [
            "logout",
            "checkToken"
        ]),
        ...mapActions('until', [
            "menuTogether",
            "setCurentPath"
        ]),

        checkLogOut() {
            let userSso = localStorage.getItem('userSso')
            if (!userSso || userSso == 'false' || userSso == false) {
                console.error('Đăng xuất tk thường:')
                this.logout();
            } else {
                console.error('Đăng xuất sso')
                let domain = window.location.protocol +'//'+ window.location.hostname;
                let id_token = localStorage.getItem('id_token')
                let state = this.ranDomString(5)
                let post_logout_redirect_uri = domain+'/login'
                let config = {
                    id_token_hint: id_token,
                    state: state,
                    post_logout_redirect_uri: post_logout_redirect_uri,
                }
                let base_url = 'https://id.nentanggiaoduc.edu.vn/connect/endsession?'
                let url_logout = base_url + this.objectToQueryString(config);
                window.location.href = url_logout

            }
        },
        ranDomString(length) {
            var result = '';
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for (var i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() *
                    charactersLength));
            }
            return result;
        },
        objectToQueryString(obj) {
            let str = [];
            for (let p in obj)
                if (obj.hasOwnProperty(p)) {
                    str.push(p + "=" + obj[p]);
                }
            return str.join("&");
        },
        replaceAll(sentence, regx, replaceBy) {
            return sentence.replace(regx, replaceBy);
        },

        openPage() {
          console.log('openPage')
          console.log(this.thongtin.role)
            let path = '/thong-tin-tai-khoan';
            let id = '';
            if (this.list_menu.length > 0) {
                this.list_menu.map(menu => {

                    if (menu.menuUrl === path) {
                        id = menu.id;
                    }
                    if (menu.children) {
                        menu.children.map(item => {
                            if (item.menuUrl === path) {
                                id = item.id;
                            }
                            return item;
                        })
                    }
                    return menu;
                })

                if (this.replaceAll(this.$route.path, /["/"]/g, "") !== this.replaceAll(path, /["/"]/g, "")) {
                    this.setCurentPath(id);
                    this.$router.push(path)
                }
                this.$emit('loadPage');
            }
        },

        loadPage() {
            this.$emit('loadPage');
        }
    },
    beforeMount() {
        this.thongtin = JSON.parse(localStorage.getItem("user"));
        this.list_menu = this.thongtin.danhSachMenu;


    }
}
</script>

<style scoped>
.logout svg {
    width: 26px;
    height: 26px;
    fill: white;
    color: white;
}

.cls-1 {
    fill: none;
    stroke: #fff;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
}
</style>