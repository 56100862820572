<template>
  <div class="hocbaDetail">
    <div data="page">
      <template v-if="!detailHocBa">
        <div class="text-center">
          Học sinh chưa có học bạ hoặc không tồn tại học bạ này
        </div>
      </template>
      <template v-else>
        <div class="info d-flex justify-content-between">
          <div>Họ và tên: {{ detailHocBa.hoten }}</div>
          <div>Lớp: {{ detailHocBa.tenLop }}</div>
          <div>Năm học: {{ detailHocBa.namHoc }}-{{ detailHocBa.namHoc+1 }}</div>
        </div>
        <div class="listMon">
          <table class="table table-bordered">
            <thead>
            <tr>
              <th rowspan="2" class="text-center">Môn học /<br> Hoạt động GD</th>
              <th colspan="3" class="text-center">Điểm trung bình hoặc xếp loại các môn</th>
              <th rowspan="2" class="text-center" style="max-width: 200px" >Điểm hoặc xếp loại sau KT lại (nếu có)
              </th>
              <th rowspan="2" class="text-center">
                Trạng thái
              </th>
              <th rowspan="2" class="text-center">
                Giáo viên
              </th>
              <!--                            <th rowspan="2" class="text-center">-->
              <!--                                Hành động-->
              <!--                            </th>-->
            </tr>
            <tr>
              <td class="text-center" >H Kỳ I</td>
              <td class="text-center" >H Kỳ II</td>
              <td class="text-center" >CN</td>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item,index) in kq_hoc_tap" :key="item.id">
              <td class="text-left">
                {{ item.tenMonHoc }}
              </td>
              <td>
                {{ item.diemHocKyI }}
              </td>
              <td>
                {{ item.diemHocKyII }}
              </td>
              <td>
                {{ item.diemCaNam }}
              </td>
              <td>
                {{ item.diemThiLai }}
              </td>
              <td class="text-left">
                {{ item.trangThaiXacNhan ? 'Đã ký' : 'Chưa ký' }}
              </td>

              <td class="text-left">
                <p class="text-break">{{ item.tenGV }}</p>
              </td>
              <!--                            <td>-->
              <!--                                &lt;!&ndash;                                <el-link&ndash;&gt;-->
              <!--                                &lt;!&ndash;                                    class="mr-2"&ndash;&gt;-->
              <!--                                &lt;!&ndash;                                    v-if="!item.trangThaiXacNhan && user.capHoc != 1 && checkEdit(detailHocBa)"&ndash;&gt;-->
              <!--                                &lt;!&ndash;                                    @click="showEdit(item)"&ndash;&gt;-->
              <!--                                &lt;!&ndash;                                    icon="el-icon-edit"&ndash;&gt;-->
              <!--                                &lt;!&ndash;                                    title="Sửa điểm"> Sửa điểm</el-link>&ndash;&gt;-->
              <!--                                <el-link-->
              <!--                                    v-if="!item.trangThaiXacNhan && detailHocBa.kiemTraThoiHanKy && detailHocBa.kieuKy == 1"-->
              <!--                                    @click="openFormOtp(item)" icon="el-icon-edit" title="Ký"> Ký môn OTP-->
              <!--                                </el-link>-->

              <!--                                <el-link-->
              <!--                                    v-if="!item.trangThaiXacNhan && detailHocBa.kiemTraThoiHanKy && detailHocBa.kieuKy == 2"-->
              <!--                                    @click="kySoClick(item)" icon="el-icon-edit" title="Ký"> Ký môn số-->
              <!--                                </el-link>-->
              <!--                            </td>-->
            </tr>
            </tbody>
          </table>

        </div>
      </template>
    </div>
    <el-dialog
        class="popupEditDiem"
        title="Sửa điểm"
        append-to-body
        modal-append-to-body
        :visible.sync="showPopUp" center>
      <div>
        <el-form
            :rules="rulesForm"
            ref="editForm"
            :model="formEdit"
            :append-to-body="true"
            :hide-required-asterisk="true">
          <div class="row">
            <div class="col-md-3">
              <el-form-item class="d-inline-block" prop="diemHocKyI">
                <label>Điểm TB học kỳ I</label>
                <el-input v-model="formEdit.diemHocKyI" placeholder="Điểm TB học kỳ 1"></el-input>
              </el-form-item>
            </div>
            <div class="col-md-3">
              <el-form-item class="d-inline-block" prop="diemHocKyII">
                <label>Điểm TB học kỳ II</label>
                <el-input v-model="formEdit.diemHocKyII" placeholder="Điểm TB học kỳ 2"></el-input>
              </el-form-item>
            </div>
            <div class="col-md-3">
              <el-form-item class="d-inline-block" prop="diemCaNam">
                <label>Điểm TB cả năm</label>
                <el-input v-model="formEdit.diemCaNam" placeholder="Điểm TB cả năm"></el-input>
              </el-form-item>
            </div>
            <div class="col-md-3">

            </div>
            <div class="col-md-6">
              <el-form-item class="d-inline-block">
                <label>Điểm hoặc xếp loại sau KT lại (nếu có)</label>
                <el-input v-model="formEdit.diemThiLai"
                          placeholder="Điểm hoặc xếp loại sau KT lại"></el-input>
              </el-form-item>
            </div>
          </div>
        </el-form>

      </div>
      <span slot="footer" class="dialog-footer">
                <el-button @click="closePopup">Thoát</el-button>
                <el-button type="primary" @click.prevent="editDiem('editForm')">Sửa điểm</el-button>
            </span>
    </el-dialog>
    <FormOtp @closeDialogOtp="chitietCloseDialogOtp" @success="successOtp" :data-mon="hocbaDatas"
             :show-dialog-otp="showDialogOtp"></FormOtp>
    <FormConfirm
        dialog-class="confirm--ky"
        title="Xác nhận ký"
        message="Bạn muốn ký môn học này?"
        @close="closeConfirm"
        @confirm="confirmKy"
        :show-confirm="showConfirm">

    </FormConfirm>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import dialog from "../../dialog";
import FormOtp from "../../components/FormOtp";
import FormConfirm from "../../components/FormConfirm";

export default {
  props: [
    'id'
  ],
  name: "ChiTietHocBa",

  components: {
    FormConfirm,
    FormOtp
  },

  computed: {
    ...mapState('hocba', [
      "detailHocBa"
    ]),
    ...mapState('account', [
      'user'
    ])
  },

  data() {
    return {
      showConfirm: false,
      rulesForm: {
        diemHocKyI: [
          {required: true, message: 'Trường này là bắt buộc', trigger: 'change'}
        ],
        diemHocKyII: [
          {required: true, message: 'Trường này là bắt buộc', trigger: 'change'}
        ],
        diemCaNam: [
          {required: true, message: 'Trường này là bắt buộc', trigger: 'change'}
        ]
      },
      kq_hoc_tap: [],
      showPopUp: false,
      formEdit: {
        maMonHoc: '',
        tenMonHoc: '',
        diemHocKyI: '',
        diemHocKyII: '',
        diemCaNam: '',
        diemThiLai: '',
        trangThaiXacNhan: ''
      },
      hocbaDatas: null,
      showDialogOtp: false,
    }
  },
  methods: {
    ...mapActions('hocba', [
      "saveTempKq",
      "getDetail",
    ]),
    ...mapActions('teacher', [
      "kySoHocBa"
    ]),
    closeConfirm() {
      this.showConfirm = false;
    },
    confirmKy() {
      let data = [];
      data.push(this.hocbaDatas)
      this.kySoHocBa({
        reqs: data
      })

      this.showConfirm = false;
    },
    checkEdit(item) {
      let role = this.user.role;
      if (role == 1) {
        if (item.nguonDuLieu) {
          return true
        }
      } else if (role == 2) {
        if (item.nguonDuLieu) {
          return true;
        }
      }
      return false;
    },
    getHocBa() {
      this.getDetail({
        id: this.id
      });
      this.showDialogOtp = false;
    },
    successOtp() {
      this.getHocBa();
    },
    chitietCloseDialogOtp() {
      this.showDialogOtp = false;
    },
    showEdit(data) {
      this.formEdit = data
      this.showPopUp = true
    },
    closePopup() {
      this.showPopUp = false;
      this.formEdit = {
        maMonHoc: '',
        tenMonHoc: '',
        diemHocKyI: '',
        diemHocKyII: '',
        diemCaNam: '',
        diemThiLai: '',
      }
    },
    editDiem(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let kq = this.kq_hoc_tap;
          kq.map((item) => {
            if (item.maMonHoc == this.formEdit.maMonHoc) {
              item.diemHocKyI = this.formEdit.diemHocKyI;
              item.diemHocKyII = this.formEdit.diemHocKyII;
              item.diemCaNam = this.formEdit.diemCaNam;
              item.diemThiLai = this.formEdit.diemThiLai;
            }
            return item;
          })
          this.kq_hoc_tap = kq;
          // this.detailHocBa.ketQuaHocTaps = this.kq_hoc_tap;
          this.saveTempKq(kq);
          this.showPopUp = false;
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    openFormOtp(item) {
      let data = {
        id: this.detailHocBa.id,
        maMonHoc: item.maMonHoc,
        ketQuaHocTaps: [item]
      }
      this.hocbaDatas = data;
      this.showDialogOtp = true;
    },
    kySoClick(item) {
      this.hocbaDatas = null;
      let data = {
        id: this.detailHocBa.id,
        ketQuaHocTaps: [item],
        maMonHoc: item.maMonHoc,
      }
      this.hocbaDatas = data;
      this.showConfirm = true;
    },
  },
  created() {

  },
  beforeMount() {

  },
  mounted() {

    // this.kq_hoc_tap = (this.detailHocBa && this.detailHocBa.ketQuaHocTaps) ? this.detailHocBa.ketQuaHocTaps : [];
  },
  watch: {
    detailHocBa(val) {
      if (val) {
        this.kq_hoc_tap = val.ketQuaHocTaps;
      }
    },
    kySoStatus(val) {

    },
    kyHocBaStatus(val) {
      if (val) {


      }
    }
  }
}
</script>

<style scoped>

</style>
