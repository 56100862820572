var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.fullscreenLoading,
            expression: "fullscreenLoading",
          },
        ],
        staticClass: "page page-file file detailHocBa",
        attrs: {
          "element-loading-text": "Hệ thống đang tải dữ liệu học bạ",
          "element-loading-spinner": "el-icon-loading",
          "element-loading-background": "rgba(255, 255, 255, 0.8)",
        },
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "box-upload-file" },
          [
            _c(
              "el-form",
              { ref: "searchForm", attrs: { "hide-required-asterisk": true } },
              [
                _c(
                  "div",
                  { staticClass: "mb-3" },
                  [
                    [
                      _c(
                        "el-form",
                        {
                          ref: "searchForm",
                          attrs: {
                            rules: _vm.rulesSearch,
                            model: _vm.searchForm,
                            "hide-required-asterisk": true,
                          },
                        },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 24 } },
                            [
                              _c(
                                "el-col",
                                {
                                  attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "d-inline-block",
                                      attrs: { prop: "year", label: "Năm học" },
                                    },
                                    [
                                      _c("eselect", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          collapseTags: "",
                                          placeholder: "Chọn",
                                          filterable: "",
                                          data: _vm.list_nam_hoc,
                                          fields: ["name", "value"],
                                        },
                                        on: { change: _vm.namHocChange },
                                        model: {
                                          value: _vm.searchForm.year,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchForm,
                                              "year",
                                              $$v
                                            )
                                          },
                                          expression: "searchForm.year",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.user.role != 4 &&
                              _vm.user.role != 1 &&
                              _vm.user.role != 9 &&
                              _vm.user.role != 3
                                ? _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xs: 12,
                                        sm: 8,
                                        md: 6,
                                        lg: 6,
                                        xl: 6,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticClass: "d-inline-block",
                                          attrs: {
                                            prop: "maDonVi",
                                            label: "Đơn vị",
                                          },
                                        },
                                        [
                                          _c("eselect", {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              disabled: _vm.user.role != 7,
                                              collapseTags: "",
                                              placeholder: "Chọn",
                                              filterable: "",
                                              data: _vm.list_don_vi,
                                              fields: ["name", "value"],
                                            },
                                            on: { change: _vm.capHocChange },
                                            model: {
                                              value: _vm.searchForm.maDonVi,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.searchForm,
                                                  "maDonVi",
                                                  $$v
                                                )
                                              },
                                              expression: "searchForm.maDonVi",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "d-inline-block",
                                      attrs: {
                                        prop: "education",
                                        label: "Cấp học",
                                      },
                                    },
                                    [
                                      _c("eselect", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          disabled:
                                            _vm.optionEducation.length == 1,
                                          collapseTags: "",
                                          placeholder: "Chọn",
                                          filterable: "",
                                          data: _vm.optionEducation,
                                          fields: ["name", "value"],
                                        },
                                        on: { change: _vm.capHocChange },
                                        model: {
                                          value: _vm.searchForm.education,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchForm,
                                              "education",
                                              $$v
                                            )
                                          },
                                          expression: "searchForm.education",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.user.role != 4 &&
                              _vm.user.role != 1 &&
                              _vm.user.role != 9 &&
                              _vm.user.role != 3
                                ? _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xs: 12,
                                        sm: 8,
                                        md: 6,
                                        lg: 6,
                                        xl: 6,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticClass: "d-inline-block",
                                          attrs: {
                                            prop: "education",
                                            label: "Trường học",
                                          },
                                        },
                                        [
                                          _c("eselect", {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              collapseTags: "",
                                              placeholder: "Chọn",
                                              filterable: "",
                                              data: _vm.list_truong_hoc,
                                              fields: ["name", "value"],
                                            },
                                            model: {
                                              value: _vm.searchForm.maTruongHoc,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.searchForm,
                                                  "maTruongHoc",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "searchForm.maTruongHoc",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "d-inline-block",
                                      attrs: {
                                        prop: "grade",
                                        label: "Khối học",
                                      },
                                    },
                                    [
                                      _c("eselect", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          collapseTags: "",
                                          placeholder: "Chọn",
                                          filterable: "",
                                          data: _vm.optionGrade,
                                          fields: ["label", "value"],
                                        },
                                        on: { change: _vm.khoiHocChange },
                                        model: {
                                          value: _vm.searchForm.grade,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchForm,
                                              "grade",
                                              $$v
                                            )
                                          },
                                          expression: "searchForm.grade",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "d-inline-block",
                                      attrs: { label: "Lớp học" },
                                    },
                                    [
                                      _c("eselect", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          collapseTags: "",
                                          placeholder: "Chọn",
                                          filterable: "",
                                          data: _vm.danh_sach_lop_hoc,
                                          fields: ["name", "value"],
                                        },
                                        on: { change: _vm.LopHocChange },
                                        model: {
                                          value: _vm.searchForm.maLop,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchForm,
                                              "maLop",
                                              $$v
                                            )
                                          },
                                          expression: "searchForm.maLop",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: { xs: 24, sm: 8, md: 6, lg: 4, xl: 3 },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "d-inline-block",
                                      attrs: { label: "Học sinh" },
                                    },
                                    [
                                      _c("eselect", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          collapseTags: "",
                                          placeholder: "Chọn học sinh",
                                          filterable: "",
                                          data: _vm.danh_sach_hoc_sinh,
                                          fields: ["tenHS", "maHS"],
                                        },
                                        model: {
                                          value: _vm.searchForm.student,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchForm,
                                              "student",
                                              $$v
                                            )
                                          },
                                          expression: "searchForm.student",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                  ],
                  2
                ),
                _vm._v(" "),
                _c("el-divider", { attrs: { "content-position": "left" } }, [
                  _c("span", { staticStyle: { "font-size": "20px" } }, [
                    _vm._v("Cấu hình in"),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 12, md: 6 } },
                      [
                        _c("eselect", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            multiple: "",
                            collapseTags: "",
                            placeholder: "Chọn cấu hình in",
                            filterable: "",
                            data: _vm.ds_cau_hinh_in,
                            fields: ["name", "value"],
                          },
                          model: {
                            value: _vm.dsCauHinhIn,
                            callback: function ($$v) {
                              _vm.dsCauHinhIn = $$v
                            },
                            expression: "dsCauHinhIn",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticClass: "formInHocBa",
                        attrs: { xs: 24, sm: 8, md: 6, lg: 4, xl: 3 },
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "d-inline-block ",
                            staticStyle: { "margin-bottom": "0" },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { clearable: "", placeholder: "Chọn" },
                                model: {
                                  value: _vm.isAnSoTrang,
                                  callback: function ($$v) {
                                    _vm.isAnSoTrang = $$v
                                  },
                                  expression: "isAnSoTrang",
                                },
                              },
                              _vm._l(_vm.ds_tuy_chon_trang_in, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.name,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.dsCauHinhIn.includes("inTrang")
                      ? _c(
                          "el-col",
                          {
                            staticClass: "formInHocBa",
                            attrs: { xs: 24, sm: 8, md: 6, lg: 4, xl: 4 },
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "d-inline-block ",
                                staticStyle: { "margin-bottom": "0" },
                              },
                              [
                                _c(
                                  "el-input",
                                  {
                                    attrs: {
                                      type: "number",
                                      placeholder: "Trang in cụ thể",
                                      min: 1,
                                      max: 99,
                                    },
                                    model: {
                                      value: _vm.trangInCuThe,
                                      callback: function ($$v) {
                                        _vm.trangInCuThe = $$v
                                      },
                                      expression: "trangInCuThe",
                                    },
                                  },
                                  [
                                    _c("template", { slot: "prepend" }, [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "black" } },
                                        [_vm._v("In riêng trang")]
                                      ),
                                    ]),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  {
                    staticStyle: { "margin-bottom": "0" },
                    attrs: { gutter: 24 },
                  },
                  [
                    _c(
                      "el-col",
                      {
                        staticClass: "mt-3 text-center",
                        attrs: { xs: 24, span: 24 },
                      },
                      [
                        _c(
                          "el-form-item",
                          { staticClass: "d-inline-block" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.submitSearch("searchForm", 1)
                                  },
                                },
                              },
                              [_vm._v("Xem")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.submitSearch("searchForm", 2)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.searchForm.student
                                        ? "Xuất excel"
                                        : "Xuất excel cả lớp"
                                    ) +
                                    "\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.mauMoi
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "success" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.taiFileKySo(1)
                                      },
                                    },
                                  },
                                  [_vm._v("Xem file ký số")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "success" },
                                on: {
                                  click: function ($event) {
                                    return _vm.taiFileKySo(2)
                                  },
                                },
                              },
                              [_vm._v("Tải file ký số")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "page__content" }, [
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _vm.detailHocBaPdf
                ? _c("el-pagination", {
                    attrs: {
                      "page-size": 1,
                      "current-page":
                        _vm.thongTinXemFilePdfHocBa.hoc_sinh_hien_tai,
                      layout: "prev, pager, next",
                      total: _vm.danh_sach_hoc_sinh.length,
                    },
                    on: {
                      "current-change": _vm.chonTrangXemHocBa,
                      "update:currentPage": function ($event) {
                        return _vm.$set(
                          _vm.thongTinXemFilePdfHocBa,
                          "hoc_sinh_hien_tai",
                          $event
                        )
                      },
                      "update:current-page": function ($event) {
                        return _vm.$set(
                          _vm.thongTinXemFilePdfHocBa,
                          "hoc_sinh_hien_tai",
                          $event
                        )
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "text-right" },
            [
              _vm.detailHocBaPdf
                ? _c(
                    "el-button",
                    {
                      staticStyle: { "margin-bottom": "10px" },
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.exportPDF("searchForm")
                        },
                      },
                    },
                    [_vm._v("\n            Tải PDF cả lớp\n          ")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm.mauMoi
            ? _c(
                "div",
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "box-upload-file",
                            staticStyle: { overflow: "scroll" },
                          },
                          [
                            _vm.chiTietHocBa
                              ? _c("viewPfdV2", {
                                  attrs: { item: _vm.chiTietHocBa },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.mauMoi
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.detailHocBaPdf,
                      expression: "detailHocBaPdf",
                    },
                  ],
                  staticClass: "viewHocBa",
                  staticStyle: { overflow: "scroll" },
                },
                [
                  _c("iframe", {
                    staticStyle: { width: "100%" },
                    attrs: { src: _vm.detailHocBaPdf, height: "100%" },
                  }),
                ]
              )
            : _vm._e(),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      [
        _c(
          "el-dialog",
          {
            staticStyle: { "text-align": "center" },
            attrs: { title: "Thông báo", visible: _vm.hienThiThongBaoTongHop },
            on: {
              "update:visible": function ($event) {
                _vm.hienThiThongBaoTongHop = $event
              },
            },
          },
          [
            _c("div", [
              _c("p", { staticStyle: { color: "black" } }, [
                _vm._v(
                  "Học bạ đã được tổng hợp. Bạn có thể xem file đã tổng hợp hoặc tổng hợp\n            mới"
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c("el-button", { on: { click: _vm.dongTongHopMoi } }, [
                  _vm._v("Xem"),
                ]),
                _vm._v(" "),
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.tongHopMoi } },
                  [_vm._v("Tổng hợp mới")]
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "page__title d-flex align-items-center justify-content-between",
      },
      [_c("h1", { staticClass: "title" }, [_vm._v("In học bạ")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }