<template>
  <div
    class="page page-user-info"
    v-loading="loading.fullScreen"
    element-loading-text="Loading..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(255, 255, 255, 0.8)"
  >
    <div class="d-flex align-items-center justify-content-between">
      <div class="page__title d-flex align-items-center justify-content-between">
        <h1 class="title">Thông tin nhà trường</h1>
      </div>
    </div>
    <el-row class="dts-content" :gutter="10">
      <el-col v-if="show_import_bigdata" :span="24" class="text-center">
        <el-upload
          ref="uploadBia"
          class="upload-demo text-center"
          drag
          action="/"
          :file-list="fileList"
          :on-change="uploadFile"
          :on-remove="uploadFile"
          :accept="'.pdf,.xlsx,.xls,.zip,.zar'"
          :auto-upload="false"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">Kéo thả hoặc <em>click ở đây</em></div>
        </el-upload>
        <div v-if="phanTramUpLoad && phanTramUpLoad != 0">
          <el-progress
            style="width: 50%; margin: 0 auto"
            :percentage="phanTramUpLoad"
          ></el-progress>
        </div>
        <div class="text-center mt-3">
          <el-button
            size="small"
            type="primary"
            :disabled="phanTramUpLoad != 0 && phanTramUpLoad < 100 ? true : false"
            @click.prevent="uploadMutilFile"
            >Tải lên dữ liệu
          </el-button>
        </div>
      </el-col>
      <el-col :lg="2" :md="2"></el-col>
      <el-col class="bg-smoke text-center" :lg="20" :md="20" :sm="24" :xs="24">
        <el-row :gutter="24">
          <el-col class="text-center">
            <div class="img d-flex align-items-center justify-content-center">
              <div class="icon">
                <i class="el-icon-school"></i>
              </div>
            </div>
            <h3>{{ infoSchool.tenTruongHoc }}</h3>
            <p class="mt-2">
              <el-tooltip content="Cập nhật thông tin" placement="top">
                <el-button @click.prevent="showUpdate" size="mini" type="primary"
                  ><i class="el-icon-edit-outline" style="font-size: 14px"></i>
                </el-button>
              </el-tooltip>
              <el-tooltip content="Đổi mật khẩu" placement="top">
                <el-button @click.prevent="showUpdatePass" size="mini" type="primary"
                  ><i class="el-icon-key" style="font-size: 14px"></i>
                </el-button>
              </el-tooltip>
            </p>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :sm="12" :xs="24" class="text-left">
            <el-card shadow="always">
              <div class="iconInfo">
                <i class="el-icon-user"></i>
              </div>
              <div class="textInfo">
                <span>Mã trường</span>
                <p>{{ infoSchool.maTruongHoc }}</p>
              </div>
            </el-card>
          </el-col>
          <el-col :sm="12" :xs="24" class="text-left">
            <el-card shadow="always">
              <div class="iconInfo">
                <i class="el-icon-coordinate"></i>
              </div>
              <div class="textInfo" style="display: flex">
                <p style="padding-right: 10px">
                  <span>Ảnh dấu</span>
                </p>
                <div v-if="infoSchool.pathDau" style="padding-right: 15px">
                  <img
                    @click="hien_thi_anh_dau = true"
                    style="width: 50px; height: 50px"
                    :src="infoSchool.pathDau"
                    alt="Ảnh dấu"
                  />
                </div>
                <div style="position: absolute; right: 15px; bottom: 15px">
                  <el-tooltip content="Tải ảnh dấu" placement="top">
                    <el-button @click.prevent="beforeUpload" type="primary" size="mini"
                      ><i class="el-icon-upload"></i
                    ></el-button>
                  </el-tooltip>
                  <el-tooltip content="Xem trước" placement="top">
                    <el-button @click.prevent="xemAnhKy" type="primary" size="mini"
                      ><i class="el-icon-coordinate"></i
                    ></el-button>
                  </el-tooltip>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :sm="12" :xs="24" class="text-left">
            <el-card shadow="always">
              <div class="iconInfo">
                <i class="el-icon-phone-outline"></i>
              </div>
              <div class="textInfo">
                <span>Số điện thoại</span>
                <p>{{ infoSchool.sdtLienHe }}</p>
                <p v-if="!infoSchool.sdtLienHe">Chưa cập nhật</p>
              </div>
            </el-card>
          </el-col>
          <el-col :sm="12" :xs="24" class="text-left">
            <el-card shadow="always">
              <div class="iconInfo">
                <i class="el-icon-message"></i>
              </div>
              <div class="textInfo">
                <span>Email</span>
                <p>{{ infoSchool.emailLienHe }}</p>
                <p v-if="!infoSchool.emailLienHe">Chưa cập nhật</p>
              </div>
            </el-card>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :sm="12" :xs="24" class="text-left">
            <el-card shadow="always">
              <div class="iconInfo">
                <i class="el-icon-map-location"></i>
              </div>
              <div class="textInfo">
                <span>Tỉnh/Thành Phố</span>
                <p>{{ infoSchool.tenTinh }}</p>
              </div>
            </el-card>
          </el-col>
          <el-col :sm="12" :xs="24" class="text-left">
            <el-card shadow="always">
              <div class="iconInfo">
                <i class="el-icon-location-information"></i>
              </div>
              <div class="textInfo">
                <span>Quận/Huyện</span>
                <p>{{ infoSchool.tenHuyen }}</p>
              </div>
            </el-card>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :sm="12" :xs="24" class="text-left">
            <el-card shadow="always">
              <div class="iconInfo">
                <i class="el-icon-location-outline"></i>
              </div>
              <div class="textInfo">
                <span>Phường/Xã</span>
                <p v-if="infoSchool.tenXa">{{ infoSchool.tenXa }}</p>
                <p v-else>&nbsp;</p>
              </div>
            </el-card>
          </el-col>
          <el-col :sm="12" :xs="24" class="text-left">
            <el-card shadow="always">
              <div class="iconInfo">
                <i class="el-icon-cloudy"></i>
              </div>
              <div class="textInfo">
                <div>
                  <span
                    >Serial Number
                    <el-tooltip
                      v-if="infoSchool.moTaTrangThaiNopCTS || infoSchool.trangThaiNopCTS"
                      class="item"
                      effect="dark"
                      :content="infoSchool.moTaTrangThaiNopCTS"
                      placement="top"
                    >
                      <i style="font-size: 18px"
                        ><small style="color: blue; cursor: pointer"
                          >({{ getTrangThaiNopCTS(infoSchool.trangThaiNopCTS) }})</small
                        ></i
                      >
                    </el-tooltip>
                  </span>
                  <el-row :gutter="24">
                    <el-col :span="18" style="margin: 0">
                      <p
                        v-if="
                          infoSchool.serialNumber &&
                          infoSchool.serialNumber != ' ' &&
                          infoSchool.serialNumber != ''
                        "
                        style="
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                        "
                      >
                        {{ infoSchool.serialNumber }} - [{{
                          infoSchool.moTaTrangThaiNopCTS
                        }}]
                      </p>
                      <p v-else>Chưa cập nhật - [{{ infoSchool.moTaTrangThaiNopCTS }}]</p>
                    </el-col>
                    <el-col :span="6">
                      <div style="display: flex">
                        <el-tooltip
                          v-if="infoSchool.nopCTSlenSo && false"
                          class="item"
                          effect="dark"
                          content="Nộp thông tin CTS lên Sở"
                          placement="top"
                        >
                          <i
                            @click="NopLenSo"
                            style="padding-right: 10px; color: blue; font-size: 22px"
                            class="el-icon-upload float-right cursor-pointer"
                          ></i>
                        </el-tooltip>
                        <el-tooltip
                          v-if="infoSchool.serialNumber"
                          class="item"
                          effect="dark"
                          content="Cập nhật thông tin chứng thư số"
                          placement="top"
                        >
                          <i
                            @click="capNhatThongTinCTS"
                            style="color: blue; font-size: 22px"
                            class="el-icon-edit-outline float-right cursor-pointer"
                          ></i>
                        </el-tooltip>
                        <el-tooltip
                          v-else
                          class="item"
                          effect="dark"
                          content="Thêm thông tin tài khoản"
                          placement="top"
                        >
                          <i
                            @click="capNhatThongTinCTS"
                            style="color: blue; font-size: 22px"
                            class="el-icon-circle-plus-outline float-right cursor-pointer"
                          ></i>
                        </el-tooltip>
                        <el-tooltip
                          v-if="!checkMenuUrlExists()"
                          class="item"
                          effect="dark"
                          content="Cập nhật trạng thái"
                          placement="top"
                        >
                          <i
                            @click="capNhatTrangThai"
                            style="padding-left: 10px; color: blue; font-size: 22px"
                            class="el-icon-refresh float-right cursor-pointer"
                          ></i>
                        </el-tooltip>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </el-card>
          </el-col>
          <el-col :sm="12" :xs="24" class="text-left" v-if="false">
            <el-card shadow="always">
              <div class="iconInfo">
                <img
                  style="width: 45px"
                  src="https://demo-ca.hocba.edu.vn/images/icon_usb_token.png"
                  alt=""
                />
              </div>
              <div class="textInfo">
                <span>Số Serial Chứng Thư Số</span>
                <p v-if="infoSchool.serialNumberUsbToken != ' '">
                  {{ infoSchool.serialNumberUsbToken }}
                </p>
                <p v-else>&nbsp;Chưa cập nhật</p>
              </div>
            </el-card>
          </el-col>
        </el-row>
        <el-row :gutter="24" class="mb-2">
          <el-col :sm="12" :xs="24" class="text-left">
            <el-card shadow="always">
              <div class="iconInfo">
                <img style="width: 45px" src="/images/icon_mysign.png" alt="" />
              </div>
              <div class="textInfo">
                <span>Tài khoản SmartCA Viettel</span>
                <p v-if="infoSchool.usernameCAVT != ''">{{ infoSchool.usernameCAVT }}</p>
                <p v-else>&nbsp;Chưa cập nhật</p>
              </div>
            </el-card>
          </el-col>
          <el-col :sm="12" :xs="24" class="text-left">
            <el-card shadow="always">
              <div class="iconInfo">
                <img style="width: 45px" src="/images/icon_vnpt_ca.png" alt="" />
              </div>
              <div class="textInfo">
                <span>Tài khoản SmartCA VNPT</span>
                <p
                  v-if="
                    infoSchool.usernameCAVNPT != '' && infoSchool.usernameCAVNPT != ' '
                  "
                >
                  {{ infoSchool.usernameCAVNPT }}
                </p>
                <p v-else>&nbsp;Chưa cập nhật</p>
              </div>
            </el-card>
          </el-col>
          <!--          Hiển thị cạp nhật thông tin chứng thư số-->
          <el-dialog
            title="Cập nhật thông tin chứng thư số"
            width="65%"
            center
            :visible.sync="hien_thi_cap_nhat_cts"
            custom-class="thongTinKyTheoMonHoc"
            :close-on-press-escape="false"
            :close-on-click-modal="false"
            :before-close="closeDialogUpload"
            class="uploadSignature"
          >
            <el-row :gutter="24">
              <el-col :span="24">
                <el-table fit border :data="danh_sach_chung_thu_so">
                  <!--                    <el-table-column label="STT" width="50" align="center" type="index">-->
                  <!--                    </el-table-column>-->
                  <el-table-column header-align="center" label="Số serial">
                    <template slot-scope="scope">
                      <el-input
                        :disabled="true"
                        type="text"
                        v-model="scope.row.serialNumber"
                      ></el-input>
                    </template>
                  </el-table-column>
                  <!--              <el-table-column property="status" label="Trạng thái" align="center"-->
                  <!--                               header-align="center">-->
                  <!--                <template slot-scope="scope">-->
                  <!--                  <el-input type="text" v-model="scope.row.status"></el-input>-->
                  <!--                </template>-->
                  <!--              </el-table-column>-->
                  <el-table-column
                    property="validFrom"
                    header-align="center"
                    align="center"
                    label="Ngày hiệu lực"
                  >
                    <template slot-scope="scope">
                      <el-input
                        :disabled="true"
                        type="text"
                        v-model="scope.row.validFrom"
                      ></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column
                    property="validTo"
                    header-align="center"
                    align="center"
                    label="Ngày hết hiệu lực"
                  >
                    <template slot-scope="scope">
                      <el-input
                        :disabled="true"
                        type="text"
                        v-model="scope.row.validTo"
                      ></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column
                    property="nhaPhatHanh"
                    header-align="center"
                    align="center"
                    label="Nhà phát hành"
                  >
                    <template slot-scope="scope">
                      <el-select
                        v-model="scope.row.nhaPhatHanh"
                        :disabled="true"
                        filterable
                        placeholder="Chọn nhà phát hành"
                      >
                        <el-option
                          v-for="item in list_nha_phat_hanh"
                          :key="item.value"
                          :label="item.name"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                  <el-table-column
                    align="center"
                    width="200"
                    header-align="center"
                    label="Hành động"
                  >
                    <template slot-scope="scope">
                      <div>
                        <el-button
                          size="mini"
                          @click.prevent="suDungCTS(scope.row)"
                          type="primary"
                          >Cập nhật</el-button
                        >
                        <!--                        <el-button size="mini" @click.prevent="NopLenSo(scope.row)" type="success">Nộp</el-button>-->
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </el-col>
            </el-row>
            <span slot="footer" class="dialog-footer">
              <el-button size="mini" @click="hien_thi_cap_nhat_cts = false"
                >Hủy</el-button
              >
            </span>
          </el-dialog>
        </el-row>
      </el-col>
    </el-row>

    <iframe
      height="1"
      width="1"
      style="display: contents"
      :src="infoSchool.pathDau"
    ></iframe>
    <el-dialog
      title="Tải lên dấu"
      center
      :visible.sync="showPopUp"
      custom-class="thongTinKyTheoMonHoc"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :before-close="closeDialogUpload"
      class="uploadSignature"
    >
      <crop-image
        v-if="showPopUp"
        :style-box="'width: 250px; height: 250px;'"
        :option="optionCrope"
        @goStep="goStep"
        :showCrop="showPopUp"
        @uploadLuon="uploadLuon"
        @getBase64="getBase64"
      ></crop-image>
      <span slot="footer" class="dialog-footer">
        <el-button
          size="mini"
          @click="
            showPopUp = false;
            step = 1;
          "
          >Hủy</el-button
        >
        <el-button
          size="mini"
          :disabled="!imageDau"
          v-if="step == 3"
          type="primary"
          @click="uploadDau"
          >Xác nhận</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="Cập nhật thông tin trường"
      center
      width="60%"
      top="5vh"
      :visible="showPopUpInfo"
      class="updateInfoTruong"
      @close="dongCapNhat('ruleForm', 'showPopUpInfo', 'confirm_ruleForm')"
    >
      <el-form
        v-if="infoSchool"
        :model="ruleForm"
        :rules="rulesUpdateForm"
        ref="ruleForm"
        class="demo-ruleForm"
      >
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="Tên trường" prop="tenTruongHoc">
              <el-input
                @change="
                  handleInputChange('ruleForm', 'tenTruongHoc', 'confirm_ruleForm')
                "
                clearable
                v-model="ruleForm.tenTruongHoc"
                placeholder="Nhập"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Email" prop="emailLienHe">
              <el-input
                @change="handleInputChange('ruleForm', 'emailLienHe', 'confirm_ruleForm')"
                clearable
                v-model="ruleForm.emailLienHe"
                placeholder="Nhập "
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              v-if="user.qlnt !== 0 || true"
              label="Tỉnh/Thành Phố"
              prop="tenTinh"
            >
              <el-input
                @change="handleInputChange('ruleForm', 'tenTinh', 'confirm_ruleForm')"
                clearable
                v-model="ruleForm.tenTinh"
                placeholder="Nhập"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Số điện thoại" prop="sdtLienHe">
              <el-input
                @change="handleInputChange('ruleForm', 'sdtLienHe', 'confirm_ruleForm')"
                clearable
                v-model="ruleForm.sdtLienHe"
                placeholder="Nhập"
              >
              </el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item
              v-if="user.qlnt !== 0 || true"
              label="Quận/Huyện"
              prop="tenHuyen"
            >
              <el-input
                @change="handleInputChange('ruleForm', 'tenHuyen', 'confirm_ruleForm')"
                clearable
                v-model="ruleForm.tenHuyen"
                placeholder="Nhập"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="Số Serial Chứng Thư Số" prop="serialNumberUsbToken">
              <el-input  @change="handleInputChange('serialNumberUsbToken')" disabled clearable v-model="ruleForm.serialNumberUsbToken" placeholder="Nhập">
              </el-input>
            </el-form-item>
          </el-col> -->

          <el-col :span="12">
            <el-form-item label="Tài khoản SmartCA Viettel" prop="usernameCAVT">
              <el-input
                @change="
                  handleInputChange('ruleForm', 'usernameCAVT', 'confirm_ruleForm')
                "
                clearable
                v-model="ruleForm.usernameCAVT"
                placeholder="Nhập"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item v-if="user.qlnt !== 0 || true" label="Phường/Xã" prop="tenXa">
              <el-input
                @change="handleInputChange('ruleForm', 'tenXa', 'confirm_ruleForm')"
                clearable
                v-model="ruleForm.tenXa"
                placeholder="Nhập"
              >
              </el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="Tài khoản SmartCA VNPT" prop="usernameCAVNPT">
              <el-input
                @change="
                  handleInputChange('ruleForm', 'usernameCAVNPT', 'confirm_ruleForm')
                "
                clearable
                v-model="ruleForm.usernameCAVNPT"
                placeholder="Nhập"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Ngày thành lập">
              <el-input
                disabled
                clearable
                v-model="ruleForm.ngayThanhLap"
                placeholder="Nhập"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          size="mini"
          @click="dongCapNhat('ruleForm', 'showPopUpInfo', 'confirm_ruleForm')"
        >
          Hủy
        </el-button>
        <el-button
          :disabled="!confirm_ruleForm"
          size="mini"
          type="primary"
          @click="updateInfoSubmit('ruleForm')"
        >
          Lưu
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="Đổi mật khẩu"
      center
      width="50%"
      :visible="showPopUpPass"
      class="updateInfoTruong"
      @close="dongCapNhat('matKhau', 'showPopUpPass', 'confirm_matKhau')"
    >
      <el-form :model="matKhau" :rules="rulesChangePassword" ref="matKhau">
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item prop="moi">
              <label>Nhập mật khẩu mới <span class="error">*</span></label>
              <InputPassword
                v-model="matKhau.moi"
                @change="handleInputChange('matKhau', 'moi', 'confirm_matKhau')"
                placeholder="Nhập"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="nhapLai">
              <label>Xác nhận mật khẩu mới <span class="error">*</span></label>
              <InputPassword
                v-model="matKhau.nhapLai"
                @change="handleInputChange('matKhau', 'nhapLai', 'confirm_matKhau')"
                placeholder="Nhập"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          size="mini"
          @click="dongCapNhat('matKhau', 'showPopUpPass', 'confirm_matKhau')"
        >
          Hủy
        </el-button>
        <el-button
          :disabled="!confirm_matKhau"
          size="mini"
          type="primary"
          @click.prevent="xacNhanThayDoiMatKhau()"
        >
          Lưu
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="Ảnh dấu của trường"
      width="40%"
      center
      custom-class="thongTinKyTheoMonHoc"
      :visible.sync="hien_thi_anh_dau"
    >
      <div class="text-center">
        <img
          style="width: 50%; height: auto"
          :src="infoSchool.pathDau + '?t=' + getTime()"
          alt="Ảnh dấu"
        />
      </div>
    </el-dialog>
    <el-dialog
      title="Xem trước ảnh con dấu khi đóng dấu"
      width="60%"
      center
      custom-class="thongTinKyTheoMonHoc"
      :visible.sync="hien_thi_anh_ky"
    >
      <div class="text-center" style="height: 60vh">
        <p style="text-align: left; color: blue">
          Hình ảnh con dấu tải lên dưới đây sẽ tương quan so với con dấu ở học bạ khi đóng
          dấu. Quý thầy cô vui lòng cân nhắc tải lại lên nếu con dấu quá nhỏ hoặc chưa đạt
          tiêu chuẩn!
        </p>
        <iframe :src="linkAnh" style="width: 100%; height: 90%"></iframe>
      </div>
    </el-dialog>

    <el-dialog
      width="50%"
      top="5vh"
      custom-class="thongTinKyTheoMonHoc"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      center
      title="Hướng dẫn"
      :visible.sync="show_huong_dan_upload"
    >
      <el-row :gutter="24">
        <el-col :span="24">
          <video v-if="show_huong_dan_upload" width="100%" controls>
            <source
              src="https://cdnhocbadientu.csdl.edu.vn/media/huong_dan_upload_anh_dau.mp4"
              type="video/mp4"
            />
          </video>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showUpLoad" type="primary" size="mini">Đã hiểu</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="Cập nhật thông tin Chứng thư số"
      center
      :visible.sync="show_cap_nhat_cts"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      width="40%"
      :before-close="handleClose"
    >
      <el-row :gutter="24">
        <el-col :span="12">
          <label>Chọn loại chứng thư số </label>
          <eselect
            style="width: 100%"
            collapseTags
            v-model="loaiChungThuSo"
            size="mini"
            placeholder="Chọn"
            filterable
            :data="danh_sach_loai_chung_thu_so"
            :fields="['name', 'value']"
          />
        </el-col>
        <el-col :span="12">
          <label style="color: transparent; display: block">Cập nhật </label>
          <el-button :disabled="!loaiChungThuSo" type="primary" @click="checkLoaiCTS()"
            >Lưu</el-button
          >
        </el-col>
        <el-col :span="24" v-if="false">
          <p>Chọn loại Chứng thư số:</p>
          <el-button
            type="primary"
            style="padding: 5px; margin: 8px 3px"
            @click.prevent="layChungThuSo()"
          >
            <img
              style="width: 30px; height: 20px"
              :src="getPathImg('/icon_mysign.png')"
              alt=""
            />
            CTS MySign
          </el-button>
          <el-button
            type="primary"
            style="padding: 5px; margin: 8px 3px"
            @click.prevent="layCTSUSB('VIETTEL')"
          >
            <img
              style="width: 30px; height: 20px"
              :src="getPathImg('/icon_usb_token.png')"
              alt=""
            />
            CTS UsbToken VIETTEL
          </el-button>
          <el-button
            type="primary"
            style="padding: 5px; margin: 8px 3px"
            @click.prevent="layCTSUSB('BAN_CO_YEU')"
          >
            <img
              style="width: 30px; height: 20px"
              :src="getPathImg('/icon_usb_token.png')"
              alt=""
            />
            CTS UsbToken Ban Cơ Yếu
          </el-button>
          <el-button
            type="primary"
            style="padding: 5px; margin: 8px 3px"
            @click.prevent="layCTSUSB('BKAV')"
          >
            <img
              style="width: 30px; height: 20px"
              :src="getPathImg('/icon_usb_token.png')"
              alt=""
            />
            CTS UsbToken BKAV
          </el-button>
          <el-button
            type="primary"
            style="padding: 5px; margin: 8px 3px"
            @click.prevent="layCTSUSB('VNPT')"
          >
            <img
              style="width: 30px; height: 20px"
              :src="getPathImg('/icon_usb_token.png')"
              alt=""
            />
            CTS UsbToken VNPT
          </el-button>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="show_cap_nhat_cts = false">Hủy</el-button>
      </span>
    </el-dialog>
    <div>
      <layThongTinCTS
        @done="layLaiThongTinCTS()"
        @close="layCTS = false"
        :nph="nhaPhatHanh"
        v-if="layCTS"
      ></layThongTinCTS>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import CropImage from "../../components/CropImage";
import dialog from "../../dialog";
import api from "../../_helpers/api";
import constant from "../../_helpers/constant_api";
import constant_api from "../../_helpers/constant_api";
import layThongTinCTS from "../layThongTinCTSV2.vue";
import eselect from "../../components/ESelect.vue";
import InputPassword from "../Ui/InputPassword.vue";
import util from "../../util";
export default {
  name: "QuanLyChung",
  metaInfo: {
    title: "Quản lý chung",
    meta: [
      {
        vmid: "description",
        name: "description",
        content: "",
      },
    ],
  },
  components: {
    eselect,
    layThongTinCTS,
    CropImage,
    InputPassword,
  },
  computed: {
    ...mapState("account", ["user"]),
  },
  methods: {
    dongCapNhat(formName, popUpName, confirmKey) {
      console.log('dongCapNhat')
      console.log(this[confirmKey])
      if (this[confirmKey]) {
        this.$confirm(
          "Các thông tin vừa nhập sẽ bị mất khi đóng trang. Thầy cô có muốn tiếp tục chỉnh sửa, kiểm tra và lưu lại thông tin đã nhập?",
          "Thông báo",
          {
            confirmButtonText: "Rời khỏi trang",
            cancelButtonText: "Tiếp tục chỉnh sửa",
            confirmButtonClass: "el-button--danger",
            closeOnClickModal: false,
          }
        )
          .then((_) => {
            this.resetForm(formName, confirmKey);
            this[popUpName] = false;
          })
          .catch((_) => {});
      } else {
        this[popUpName] = false;
        this.resetForm(formName, confirmKey);
      }
    },

    handleInputChange(formName, field, confirmKey) {
      if (this[formName] && this[formName].hasOwnProperty(field)) {
        this[formName][field] = this[formName][field]?.trim();
     
      }
    },
    validatePasswordWrapper(rule, value, callback) {
      const errorMsg = util.validatePassword(value, rule.field, this.matKhau.moi);
      if (errorMsg) {
        return callback(new Error(errorMsg));
      }
      callback();
    },
    resetForm(formName, confirmKey) {
      this[confirmKey] = false;
      if (this.$refs[formName]) {
        this.$refs[formName].resetFields();

        Object.keys(this.$refs[formName].fields).forEach((field) => {
          this.$refs[formName].clearValidate([field]);
        });
        if (formName == "matKhau") {
          this.matKhau = { moi: "", nhapLai: "" };
        }
      }
    },
    checkLoaiCTS() {
      if (this.loaiChungThuSo == 1) {
        this.layChungThuSo();
      } else if (this.loaiChungThuSo == 2) {
        this.layCTSUSB("VIETTEL");
      } else if (this.loaiChungThuSo == 3) {
        this.layCTSUSB("VNPT");
      } else if (this.loaiChungThuSo == 4) {
        this.layCTSUSB("BKAV");
      } else if (this.loaiChungThuSo == 5) {
        this.layCTSUSB("BAN_CO_YEU");
      } else if (this.loaiChungThuSo == 6) {
        this.layCTSUSB("EASYCA");
      } else if (this.loaiChungThuSo == 7) {
        this.layCTSUSB("EFY-CA");
      } else if (this.loaiChungThuSo == 8) {
        this.layCTSUSB("FastCA");
      } else if (this.loaiChungThuSo == 9) {
        this.layCTSUSB("FPT");
      } else if (this.loaiChungThuSo == 10) {
        this.layCTSUSB("MISA-CA");
      }
    },
    getTrangThaiNopCTS(stt) {
      let str = "";
      if (stt == -1) {
        str = "Chưa nộp CTS lên sở";
      } else if (stt == 0) {
        str = "Từ chối xác nhận trên Sở";
      } else if (stt == 1) {
        str = "Đã được Sở phê duyệt";
      } else if (stt == 2) {
        str = "Chờ xử lý trên Sở";
      } else {
      }
      return str;
    },
    getPathImg(img) {
      let result =
        window.location.protocol + "//" + window.location.hostname + "/images/" + img;
      return result;
    },
    handleClose() {
      this.show_cap_nhat_cts = false;
    },
    layLaiThongTinCTS() {
      this.layCTS = false;
      this.layThongTinNhaTruong(this.user.maTruong);
    },
    NopLenSo() {
      console.log("suDungCTS");
      let url = constant_api.giaovien.nopCTSLenSo;
      let dataSend = {};
      console.log(dataSend);
      this.$confirm("Xác nhận nộp thông tin chứng thư số?", "Thông báo", {
        confirmButtonText: "Nộp",
        cancelButtonText: "Hủy",
        confirmButtonClass: "el-button--primary",
      })
        .then((_) => {
          this.loading.fullScreen = true;
          api.post(url, dataSend).then((response) => {
            console.log("Res:");
            console.log(response);
            if (response.data.code == 200) {
              this.thongBao("success", response.data.msg);
              this.layThongTinNhaTruong(this.user.maTruong);
            } else {
              this.thongBao("error", response.data.msg);
            }
            this.loading.fullScreen = false;
          });
        })
        .catch((_) => {});
    },
    layCTSUSB(nph) {
      this.nhaPhatHanh = nph;
      console.log("layCTSUSB");
      this.layCTS = true;
    },
    suDungCTS(item) {
      console.log("suDungCTS");
      console.log(item);
      let url = constant_api.giaovien.nopCTSGV;
      let params = JSON.parse(JSON.stringify(item));
      let dataSend = {
        certBO: params,
        type: this.infoSchool.typeSign,
      };
      console.log(dataSend);
      this.$confirm("Xác nhận cập nhật thông tin chứng thư số?", "Thông báo", {
        confirmButtonText: "Lưu",
        cancelButtonText: "Hủy",
        confirmButtonClass: "el-button--primary",
      })
        .then((_) => {
          this.loading.fullScreen = true;
          api.post(url, dataSend).then((response) => {
            console.log("Res:");
            console.log(response);
            if (response.data.code == 200) {
              this.thongBao("success", response.data.msg);
              // this.layChungThuSo()
              this.layThongTinNhaTruong(this.user.maTruong);
            } else {
              this.thongBao("error", response.data.msg);
            }
            this.loading.fullScreen = false;
          });
        })
        .catch((_) => {});
    },
    capNhatThongTinCTS() {
      this.show_cap_nhat_cts = true;
      // if(this.infoSchool.typeSign==4){
      //   this.layCTSUSB();
      // } else{
      //   this.layChungThuSo()
      // }
    },
    capNhatTrangThai() {
      let url = "/hocbadientu-sync-service/api/v2/edu/profile/hoi-trang-thai-cts";
      this.loading.fullScreen = true;
      api.get(url, {}).then((response) => {
        console.log("Res hỏi trạng thái:");
        console.log(response);
        if (response.data.code == 200) {
          this.thongBao("success", response.data.msg);
          this.layThongTinNhaTruong(this.user.maTruong);
        } else {
          this.thongBao("error", response.data.msg);
        }
        this.loading.fullScreen = false;
      });
    },
    layChungThuSo() {
      this.loading.fullScreen = true;
      let url = constant.giaovien.layCTS;
      this.danh_sach_chung_thu_so = [];
      api
        .get(url, {
          typeSign: this.infoSchool.typeSign,
        })
        .then((response) => {
          this.loading.fullScreen = false;
          console.log("Res lấy CTS giáo viên trả về:");
          console.log(response.data.data);
          if (response.data.code == 200) {
            this.danh_sach_chung_thu_so = response.data.data.certBOS;
            let check = response.data.data.isRegistered
              ? response.data.data.isRegistered
              : false;
            this.hien_thi_cap_nhat_cts = !check;
          } else {
            this.thongBao("error", "Không lấy được thông tin giáo viên");
          }
        });
    },
    uploadFile(file, fileList) {
      this.phanTramUpLoad = 0;
      console.error("uploadFile");
      const fileZip = event.target.files[0];
      console.log(fileZip);
      const chunkSize = 5 * 1024 * 1024; // Kích thước của mỗi phần (1MB)
      const chunks = Math.ceil(fileZip.size / chunkSize); // Số lượng phần
      this.infoUploadFileZip.fileZip = fileZip;
      this.infoUploadFileZip.chunks = chunks;
      this.infoUploadFileZip.name = fileZip.name;
      this.infoUploadFileZip.chunkSize = chunkSize;
      console.log("infoUploadFileZip:");
      console.log(this.infoUploadFileZip);

      let reader = new FileReader();
      reader.readAsDataURL(file.raw);
      reader.onload = (e) => {
        let data;
        if (typeof e.target.result === "object") {
          data = window.URL.createObjectURL(new Blob([e.target.result]));
        } else {
          data = e.target.result;
        }
        console.log("Data file đã chọn (base64):");
        console.log(data);
        this.base64Upload = data;
      };
    },
    async uploadMutilFile() {
      console.log("uploadMutilFile");
      this.phanTramUpLoad = 0;
      if (this.infoUploadFileZip.fileZip == null) {
        this.thongBao("error", "Chưa có file nào được chọn");
        return;
      }
      let fileZip = this.infoUploadFileZip.fileZip;
      let chunks = this.infoUploadFileZip.chunks;
      let nameFile = this.infoUploadFileZip.name;
      let responUpload = null;
      var chunkSize = 1 * 1024 * 1024; // Kích thước chunk: 5MB
      var totalChunks = Math.ceil(this.base64Upload.length / chunkSize);
      let currentChunk = 0;
      console.error("tổng:" + totalChunks);

      while (currentChunk < totalChunks) {
        const start = currentChunk * chunkSize;
        const end = Math.min(start + chunkSize, this.base64Upload.length);
        const chunk = this.base64Upload.slice(start, end);
        console.log("base64 mỗi lần up:");
        console.log(chunk);
        let uri = constant_api.test.upFile;
        await api
          .post(uri, { file: chunk, nameFile: nameFile })
          .then((response) => {
            this.phanTramUpLoad = parseInt(
              ((currentChunk / totalChunks) * 100).toFixed(0)
            );
            console.log("Phần trăm:" + this.phanTramUpLoad);
            console.log("Đang upload:" + currentChunk + "/" + totalChunks);
            console.log(response);
            let cr = currentChunk + 1;
            if (cr == totalChunks) {
              responUpload = response;
              console.log("responUpload:");
              setTimeout(() => {
                this.phanTramUpLoad = 0;
              }, 500);
              console.log(responUpload);
            }
            currentChunk++;
          })
          .catch((e) => {});
      }
      console.error("responUpload:");
      console.error(responUpload);
      if (responUpload && responUpload.code == 200) {
        this.thongBao("success", "Upload file thành công");
      } else {
        this.thongBao("error", responUpload.msg);
      }

      this.loading.fullScreen = false;
      this.$refs.uploadBia.clearFiles();
      this.infoUploadFileZip.fileZip = null;
      this.infoUploadFileZip.chunks = null;
      this.infoUploadFileZip.chunkSize = null;
    },

    xacNhanThayDoiMatKhau() {
      this.$refs.matKhau.validate((valid) => {
        if (valid) {
          let url = constant_api.taiKhoan.doiMatKhau;
          let params = {
            email: this.user.username,
            password: this.matKhau.moi,
          };

          this.$confirm("Xác nhận thay đổi mật khẩu?", "Thông báo", {
            confirmButtonText: "Lưu",
            cancelButtonText: "Hủy",
            confirmButtonClass: "el-button--primary",
          })
            .then((_) => {
              this.fullscreenLoading = true;
              api.post(url, params).then((response) => {
                console.log("Res:");
                console.log(response);
                if (response.data.code == 200) {
                  this.thongBao("success", response.data.msg);
                  this.matKhau.moi = "";
                  this.matKhau.nhapLai = "";
                  this.showPopUpPass = false;
                  this.resetForm('matKhau','confirm_matKhau')
                } else {
                  this.thongBao("error", response.data.msg);
                }
                this.fullscreenLoading = false;
              });
            })
            .catch((_) => {});
        }
      });
    },
    showUpdate() {
      console.log('showUpdate')
      this['confirm_ruleForm'] = false;
      console.log("Thông tin chỉnh sửa:");
      console.log(this.infoSchool);
      this.ruleForm = JSON.parse(JSON.stringify(this.infoSchool));
      this.original_ruleForm = JSON.parse(JSON.stringify(this.infoSchool));
      this.ruleForm.emailLienHe = this.infoSchool.emailLienHe;
      this.ruleForm.sdtLienHe = this.infoSchool.sdtLienHe;
      this.showPopUpInfo = true;
    },
    showUpdatePass() {
      this.showPopUpPass = true;
    },
    getTime() {
      var d = new Date();
      var n = d.getTime();
      return n;
    },
    xemAnhKy() {
      this.loading.fullScreen = true;
      let url = constant.taiKhoan.xemTruocChuKyVaDau;
      api.post(url, {}).then((response) => {
        console.log("Res upload chữ ký trả về::");
        console.log(response);
        if (response.data.code == 200) {
          console.log("***");
          console.log("xem trước ảnh ký: con dâus");
          this.linkAnh = response.data.data.data;
          this.hien_thi_anh_ky = true;
          console.log(this.linkAnh);
        } else {
          this.loading.fullScreen = false;
          this.thongBao("error", response.data.msg);
        }
        this.loading.fullScreen = false;
      });
      console.log("Xem ảnh ký");
    },

    beforeUpload() {
      console.log("trước khi upload");
      this.show_huong_dan_upload = true;
    },
    showUpLoad() {
      console.log("Chiển thị");
      this.show_huong_dan_upload = false;
      this.imageDau = null;
      this.showPopUp = true;
      console.log(this.imageDau);
    },
    chinhSuaThongTin(e) {

      this.resetForm('matKhau','confirm_matKhau')
      this.resetForm('ruleForm', 'confirm_ruleForm')
      this['confirm_ruleForm'] = false;
      console.log("Thông tin chỉnh sửa:");
      console.log(e);
      let url = "";
      if (e.type == 1) {
        url = constant.school.updateInfoChuKy;
      } else {
        url = constant.school.updateInfoTruong;
      }
      api.post(url, e).then((response) => {
        console.log(response);
        if (response.data.code == 200) {
          console.log(this['confirm_ruleForm'])
          console.log(this['confirm_matKhau'])
          this.resetForm('matKhau','confirm_matKhau')
          this.resetForm('ruleForm', 'confirm_ruleForm')
          this.showPopUp = false;
          this.showPopUpInfo = false;
          this.resetForm('ruleForm', 'confirm_ruleForm')
          this.thongBao("success", "Cập nhật thông tin thành công.");
          console.log("Lấy lại thông tin giáo viên");
          if (e.type == 1) {
            this.xemAnhKy();
          }
          this.layThongTinNhaTruong(this.user.maTruong);
        } else {
          this.loading.fullScreen = false;
          this.thongBao("error", response.data.msg);
        }
      });
    },
    updateInfoSubmit(formName) {
      console.log('updateInfoSubmit')
      console.log(this.ruleForm)
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm("Xác nhận cập nhật thông tin?", "Thông báo", {
            confirmButtonText: "Lưu",
            cancelButtonText: "Hủy",
            confirmButtonClass: "el-button--primary",
          })
            .then((_) => {
              this.chinhSuaThongTin({
                type: 2,
                id: this.infoSchool.id,
                emailLienHe: this.ruleForm.emailLienHe,
                serialNumberUsbToken: this.ruleForm.serialNumberUsbToken,
                sdtLienHe: this.ruleForm.sdtLienHe,
                tenTinh: this.ruleForm.tenTinh,
                tenHuyen: this.ruleForm.tenHuyen,
                tenXa: this.ruleForm.tenXa,
                usernameCAVT: this.ruleForm.usernameCAVT,
                usernameCAVNPT: this.ruleForm.usernameCAVNPT,
                tenTruongHoc: this.ruleForm.tenTruongHoc,
              });
              setTimeout(()=>{},500)
            })
            .catch((_) => {});
        } else {
          console.log(valid);
          console.log(this.ruleForm);
          console.log("error submit!!");
          return false;
        }
      });
    },

    layThongTinNhaTruong(matruong) {
      console.log("layThongTinNhaTruong");
      this.loading.fullScreen = true;
      this.hien_thi_cap_nhat_cts = false;
      this.show_cap_nhat_cts = false;
      let url = constant.school.infoSchool;
      api
        .get(url, {
          maTruong: matruong,
        })
        .then((response) => {
          this.loading.fullScreen = false;
          console.log("Res upload thông tin nhà trường trả về:");
          console.log(response);
          if (response.data.code == 200) {
            this.infoSchool = response.data.data;
            this.infoSchool.usernameCAVT = this.infoSchool.usernameCAVT
              ? this.infoSchool.usernameCAVT.trim()
              : "";
            this.infoSchool.usernameCAVNPT = this.infoSchool.usernameCAVNPT
              ? this.infoSchool.usernameCAVNPT.trim()
              : "";
            var d = new Date();
            var n = d.getTime();
            this.infoSchool.pathDau = this.infoSchool.pathDau + "?t=" + n;
            this.ruleForm.emailLienHe = this.infoSchool.emailLienHe;
            this.ruleForm.emailLienHe = this.infoSchool.sdtLienHe;
          } else {
            this.thongBao("error", "Không lấy được dữ liệu.");
          }
        });
    },
    uploadLuon(data) {
      this.imageDau = data;
      this.uploadDau();
    },
    getBase64(data) {
      console.log("getBase64" + data);
      this.imageDau = data;
    },

    closeDialogUpload() {
      this.step = 1;
      this.showPopUp = false;
      this.hien_thi_cap_nhat_cts = false;
      // this.show_cap_nhat_cts = false;
    },
    goStep(step) {
      this.step = step;
    },
    uploadDau() {
      if (!this.imageDau || this.imageDau == "") {
        this.thongBao("error", "Vui lòng xoá nền ảnh trước khi xác nhận");
        return;
      } else {
        this.chinhSuaThongTin({
          type: 1,
          id: this.infoSchool.id,
          data: this.imageDau,
        });
      }
    },

    thongBao(t, e) {
      let msg = "";
      let cl = "";
      if (e) {
        msg = e;
      }
      let type = "success";
      if (t) {
        type = t;
      }
      if (type == "success") {
        cl = "dts-noty-success";
      }
      if (type == "warning") {
        cl = "dts-noty-warning";
      }
      if (type == "error") {
        cl = "dts-noty-error";
      }
      if (type == "info") {
        cl = "dts-noty-info";
      }
      this.$message({
        customClass: cl,
        showClose: true,
        message: msg,
        type: t,
        duration: 3000,
      });
    },
    checkMenuUrlExists() {
      const isMenuUrlPresent = this.user.danhSachMenu.some(
        (menu) => menu.menuUrl == "nop-ho-so-hoc-ba-v2"
      );
      return isMenuUrlPresent;
    },
  },
  data() {
    return {
      input4: "",
      nhaPhatHanh: "",
      layCTS: false,
      phanTramUpLoad: 0,
      show_import_bigdata: false,
      fileList: [],
      base64Upload: null,
      infoUploadFileZip: {
        fileZip: null,
        chunks: null,
        chunkSize: null,
        name: null,
      },
      dataFormScanBia: [],

      show_huong_dan_upload: false,
      loading: {
        fullScreen: false,
      },
      linkAnh: "",
      danh_sach_loai_chung_thu_so: [
        { name: "CTS MySign", value: 1 },
        { name: "CTS USBToken VIETTEL", value: 2 },
        { name: "CTS USBToken VNPT", value: 3 },
        { name: "CTS USBToken BKAV", value: 4 },
        { name: "CTS USBToken Ban Cơ Yếu", value: 5 },
      ],
      loaiChungThuSo: "",
      show_cap_nhat_cts: false,
      infoSchool: {},
      list_nha_phat_hanh: [
        { name: "VNPT", value: "VNPT" },
        { name: "BKAV", value: "BKAV" },
        { name: "VIETTEL", value: "VIETTEL" },
        { name: "Ban cơ yếu Chính phủ", value: "BAN_CO_YEU" },
      ],
      hien_thi_cap_nhat_cts: false,
      danh_sach_chung_thu_so: [],
      hien_thi_anh_ky: false,
      hien_thi_anh_dau: false,

      confirm_ruleForm: false,
      ruleForm: {
        emailLienHe: "",
        sdtLienHe: "",
      },
      original_ruleForm: {},
      rulesUpdateForm: {
        emailLienHe: [
          // {
          //     required: true,
          //     message: 'Vui lòng nhập email',
          //     trigger: ["blur", "change"],
          // },
          {
            pattern: /^([a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: "Vui lòng nhập Email đúng định dạng",
            trigger: ["blur", "change"],
          },
          {
            max: 255,
            message: "Email không được vượt quá 255 ký tự",
            trigger: ["blur", "change"],
          },
        ],
        sdtLienHe: [
          // {
          //     required: true,
          //     message: 'Vui lòng nhập số điện thoại',
          //     trigger: ["blur", "change"],
          // },
          {
            pattern: /^[0-9]*$/,
            message: "Số điện thoại chỉ được chứa ký tự số [0-9]",
            trigger: ["blur", "change"],
          },
          {
            max: 11,
            message: "Số điện thoại không được vượt quá 11 ký tự",
            trigger: ["blur", "change"],
          },
        ],
        tenTruongHoc: [
          {
            required: true,
            message: "Vui lòng nhập tên trường",
            trigger: ["blur", "change"],
          },
          {
            max: 250,
            message: "Tên trường không được vượt quá 250 ký tự",
            trigger: ["blur", "change"],
          },
        ],
        tenTinh: [
          {
            required: true,
            message: "Vui lòng nhập thông tin Tỉnh/Thành Phố",
            trigger: ["blur", "change"],
          },
          {
            max: 250,
            message: "Thông tin Tỉnh/Thành Phố không được vượt quá 250 ký tự",
            trigger: ["blur", "change"],
          },
        ],
        tenHuyen: [
          {
            required: true,
            message: "Vui lòng nhập thông tin Quận/Huyện",
            trigger: ["blur", "change"],
          },
          {
            max: 250,
            message: "Thông tin Quận/Huyện không được vượt quá 250 ký tự",
            trigger: ["blur", "change"],
          },
        ],
        tenXa: [
          {
            required: true,
            message: "Vui lòng nhập thông tin Phường/Xã",
            trigger: ["blur", "change"],
          },
          {
            max: 250,
            message: "Thông tin Phường/Xã không được vượt quá 250 ký tự",
            trigger: ["blur", "change"],
          },
        ],
      },
      rulesChangePassword: {
        moi: [{ validator: this.validatePasswordWrapper, trigger: ["blur", "change"] }],
        nhapLai: [
          { validator: this.validatePasswordWrapper, trigger: ["blur", "change"] },
        ],
      },
      showPopUpInfo: false,
      showPopUpPass: false,
      confirm_matKhau: false,
      matKhau: {
        moi: "",
        nhapLai: "",
      },
      original_matKhau: {
        moi: "",
        nhapLai: "",
      },

      imageDau: "",
      step: 1,
      showPopUp: false,
      optionCrope: {
        img: "",
        size: 1,
        full: true,
        mode: "300px",
        outputType: "png",
        canMove: true,
        fixedBox: true,
        original: false,
        canMoveBox: false,
        autoCrop: true,
        autoCropWidth: 300,
        autoCropHeight: 300,
        centerBox: false,
        high: true,
        max: 99999,
        fixed: true,
        fixedNumber: [1, 1],
      },
    };
  },
  mounted() {
    console.log("Mount info cán bộL");
    console.log(this.user);
    console.log("danhSachMenu", this.user.danhSachMenu);
    this.show_import_bigdata = localStorage.getItem("importBigData");
    this.layThongTinNhaTruong(this.user.maTruong);
    if (this.user.maSo != 25) {
      //Phú thọ chỉ sử dụng 4 loại usb token
      let them = [
        { name: "CTS USBToken EASY CA", value: 6 },
        { name: "CTS USBToken EFY CA", value: 7 },
        { name: "CTS USBToken Fast CA", value: 8 },
        { name: "CTS USBToken FPT CA", value: 9 },
        { name: "CTS USBToken MISA CA", value: 10 },
      ];
      this.danh_sach_loai_chung_thu_so = [...this.danh_sach_loai_chung_thu_so, ...them];
    }
  },
  watch: {
    imageDau: function (val) {
      console.log("THay đổi");
      console.log(val);
    },
    ruleForm: {
      handler(newVal) {
        this.confirm_ruleForm = util.hasChangesToConfirmBeforeClose(
          newVal,
          this.original_ruleForm
        );
      },
      deep: true,
    },
    matKhau: {
      handler(newVal) {
        this.confirm_matKhau = util.hasChangesToConfirmBeforeClose(
          newVal,
          this.original_matKhau
        );
      },
      deep: true,
    },
  },
};
</script>

<style scoped="scoped">
input {
  width: 100%;
}

.border {
  border: 2px double slategray;
}
::v-deep .el-card__body {
  height: 90px;
}
::v-deep .el-input__suffix {
  align-items: center;
  display: flex;
  cursor: pointer;
}
</style>
