var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.status.loggingIn,
          expression: "status.loggingIn",
        },
      ],
      staticClass: "page page-login",
      attrs: {
        "element-loading-text": "Loading...",
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "rgba(0, 0, 0, 0.8)",
      },
    },
    [
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          {
            staticClass: "row vh-100 align-items-center justify-content-center",
          },
          [
            _c("div", { staticClass: "col-md-4" }),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-4" }, [
              _c("img", { attrs: { src: "/images/bg1.png", alt: "bg" } }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "login-panel card" },
                [
                  _vm._m(0),
                  _vm._v(" "),
                  !_vm.showLogin
                    ? _c("div", { staticClass: "card-body login__form pt-0" }, [
                        _c(
                          "div",
                          {
                            staticClass: "row",
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "selectOptionLogin" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass:
                                      "btn btn-lg btn-block btn-login",
                                    attrs: { type: "danger" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.linkToSSO()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "Đăng nhập SSO hệ sinh thái giáo dục\n                "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "selectOptionLogin" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass:
                                      "btn btn-lg btn-block btn-login",
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.showSSOHCM()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "Đăng nhập SSO HCM\n                "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "selectOptionLogin" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass:
                                      "btn btn-lg btn-block btn-login",
                                    attrs: { type: "success" },
                                    on: {
                                      click: function ($event) {
                                        _vm.showLogin = true
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "Đăng nhập với tài khoản thường\n                "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "text-center", attrs: { span: 24 } },
                    [
                      _c("div", { staticStyle: { "padding-top": "10px" } }, [
                        _c(
                          "table",
                          {
                            staticClass: "login-footer",
                            staticStyle: { margin: "0 auto" },
                          },
                          [
                            _c("tbody", [
                              _c("tr", [
                                _c("td", [
                                  _c("div", { staticClass: "support-text" }, [
                                    _c("div", { staticClass: "purple" }, [
                                      _c("strong", [
                                        _vm._v(
                                          "TƯ VẤN VÀ HỖ TRỢ\n                          "
                                        ),
                                        _c(
                                          "strong",
                                          { staticClass: "orange f-16" },
                                          [_vm._v("18008000")]
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "grey" }, [
                                      _vm._v("Nhánh 2 (miễn phí)"),
                                    ]),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.showLogin
                    ? _c(
                        "div",
                        { staticClass: "card-body login__form pt-0" },
                        [
                          _c(
                            "el-form",
                            {
                              ref: "formLogin",
                              attrs: { model: _vm.formLogin, rules: _vm.rules },
                            },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _vm.alert.message
                                    ? _c("el-alert", {
                                        staticClass:
                                          "animated flipInX text-center",
                                        staticStyle: { "line-height": "1" },
                                        attrs: {
                                          title: _vm.alert.message,
                                          type: _vm.alert.type,
                                          closable: false,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { prop: "email" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      name: "email",
                                      placeholder: "Tài khoản",
                                      title: "Tài khoản",
                                    },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.submitForm("formLogin")
                                      },
                                    },
                                    model: {
                                      value: _vm.formLogin.email,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formLogin, "email", $$v)
                                      },
                                      expression: "formLogin.email",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { prop: "password" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      name: "password",
                                      "show-password": "",
                                      type: "password",
                                      placeholder: "Password",
                                      title: "Password",
                                    },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.submitForm("formLogin")
                                      },
                                    },
                                    model: {
                                      value: _vm.formLogin.password,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formLogin, "password", $$v)
                                      },
                                      expression: "formLogin.password",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.capCha
                                ? _c(
                                    "el-form-item",
                                    { attrs: { prop: "capChaValue" } },
                                    [
                                      _c(
                                        "el-row",
                                        {
                                          staticStyle: { "margin-bottom": "0" },
                                          attrs: { gutter: 0 },
                                        },
                                        [
                                          _c(
                                            "el-col",
                                            {
                                              directives: [
                                                {
                                                  name: "loading",
                                                  rawName: "v-loading",
                                                  value: !_vm.capCha,
                                                  expression: "!capCha",
                                                },
                                              ],
                                              attrs: { span: 8 },
                                            },
                                            [
                                              _c("img", {
                                                staticStyle: {
                                                  width: "100%",
                                                  height: "40px",
                                                  border: "1px solid black",
                                                  "border-radius": "4px",
                                                },
                                                attrs: {
                                                  src: _vm.capCha,
                                                  alt: "Capcha",
                                                },
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 16 } },
                                            [
                                              _c(
                                                "el-input",
                                                {
                                                  staticClass:
                                                    "input-with-select",
                                                  attrs: {
                                                    name: "capChaValue",
                                                    placeholder: "Nhập capcha",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.formLogin.capChaValue,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.formLogin,
                                                        "capChaValue",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "formLogin.capChaValue",
                                                  },
                                                },
                                                [
                                                  _c("el-button", {
                                                    attrs: {
                                                      slot: "append",
                                                      icon: "el-icon-refresh",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        return _vm.refreshCapCha(
                                                          $event
                                                        )
                                                      },
                                                    },
                                                    slot: "append",
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("el-form-item", { staticClass: "m-0" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-content-between",
                                  },
                                  [
                                    _c("el-checkbox", {
                                      attrs: { label: "Nhớ mật khẩu" },
                                      model: {
                                        value: _vm.formLogin.rememberPassword,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formLogin,
                                            "rememberPassword",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formLogin.rememberPassword",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass:
                                          "btn btn-lg btn-block btn-login",
                                        staticStyle: {
                                          "background-color": "#00A65A",
                                        },
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.submitForm("formLogin")
                                          },
                                        },
                                      },
                                      [_vm._v("Đăng nhập\n                  ")]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticClass: "text-center m-0",
                                    staticStyle: { color: "#0C9AE3" },
                                  },
                                  [_vm._v("Hoặc")]
                                ),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-md-12" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "btn btn-lg btn-block",
                                    attrs: { type: "danger" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.linkToSSO()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Đăng nhập SSO\n                "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c(
                  "el-dialog",
                  {
                    attrs: {
                      title: "Đăng nhập tài khoản SSO",
                      "close-on-click-modal": false,
                      "close-on-press-escape": false,
                      "append-to-body": "",
                      center: "",
                      visible: _vm.show_login_sso_hcm,
                    },
                    on: {
                      "update:visible": function ($event) {
                        _vm.show_login_sso_hcm = $event
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "el-row",
                          { attrs: { gutter: 24 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c("label", [_vm._v("Đơn vị")]),
                                _vm._v(" "),
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      filterable: "",
                                      clearable: "",
                                      placeholder: "Chọn đơn vị",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.chonDonVi()
                                      },
                                    },
                                    model: {
                                      value: _vm.maPhongHCM,
                                      callback: function ($$v) {
                                        _vm.maPhongHCM = $$v
                                      },
                                      expression: "maPhongHCM",
                                    },
                                  },
                                  _vm._l(_vm.list_phong, function (item) {
                                    return _c("el-option", {
                                      key: item.maDonVi,
                                      attrs: {
                                        label:
                                          item.tenDonVi +
                                          " - [" +
                                          item.maDonVi +
                                          "]",
                                        value: item.maDonVi,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c("label", [_vm._v("Trường học")]),
                                _vm._v(" "),
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      filterable: "",
                                      clearable: "",
                                      placeholder: "Chọn trường học",
                                    },
                                    model: {
                                      value: _vm.maTruongSSOHCM,
                                      callback: function ($$v) {
                                        _vm.maTruongSSOHCM = $$v
                                      },
                                      expression: "maTruongSSOHCM",
                                    },
                                  },
                                  _vm._l(_vm.list_truong, function (item) {
                                    return _c("el-option", {
                                      key: item.maTruong,
                                      attrs: {
                                        label:
                                          item.tenTruong +
                                          " - [" +
                                          item.maTruong +
                                          "]",
                                        value: item.maTruong,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "text-center mt-3" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "danger", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    _vm.show_login_sso_hcm = false
                                  },
                                },
                              },
                              [_vm._v("Đóng\n              ")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  size: "mini",
                                  disabled: !_vm.maTruongSSOHCM,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.linkToSSOHCM($event)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "Đăng\n                nhập\n              "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "card-header pb-0 border-0",
        staticStyle: { "background-color": "transparent" },
      },
      [
        _c(
          "h3",
          {
            staticClass: "panel-title text-center font-weight-bold",
            staticStyle: { "font-size": "20px", color: "#21BEF4" },
          },
          [_vm._v("Đăng nhập")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }