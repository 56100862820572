import {
    userService
} from "../_services";
import {
    router
} from "../_router";
import util from "../util";

var list_cap_hoc = [];
let d = new Date();
let curentYear = d.getFullYear();
let curentMonth = d.getMonth() + 1;
if (curentMonth >= 9) {
} else {
    curentYear -= 1;
}
const user = JSON.parse(localStorage.getItem('user'));
const cap_mot = {
    name: 'Cấp 1',
    value: 1
};
const cap_hai = {
    name: 'Cấp 2',
    value: 2
};
const cap_ba = {
    name: 'Cấp 3',
    value: 3
};
const gdtx = {
    name: 'GDTX',
    value: 6
};
if (user) {
    if(user.capHoc){
        let ch = user.capHoc.toString();
        let cap_hoc = ch.split('');
        list_cap_hoc = [];
        for (let i = 0; i < cap_hoc.length; i++) {
            if (cap_hoc[i] == 1) {
                list_cap_hoc.push(cap_mot)
            }
            if (cap_hoc[i] == 2) {
                list_cap_hoc.push(cap_hai)
            }
            if (cap_hoc[i] == 3) {
                list_cap_hoc.push(cap_ba)
            }
            if (cap_hoc[i] == 6) {
                list_cap_hoc.push(gdtx)
            }
        }
    }else{
        localStorage.clear();
        window.location.href = '/';
    }
}
const list_nam_hoc = [
    {name: "2009-2010", value: 2008},
    {name: "2010-2011", value: 2010},
    {name: "2011-2012", value: 2011},
    {name: "2012-2013", value: 2012},
    {name: "2013-2014", value: 2013},
    {name: "2014-2015", value: 2014},
    {name: "2015-2016", value: 2015},
    {name: "2016-2017", value: 2016},
    {
        name: "2017-2018",
        value: 2017
    },
    {
        name: "2018-2019",
        value: 2018
    },
    {
        name: "2019-2020",
        value: 2019
    },
    {
        name: "2020-2021",
        value: 2020
    },
    {
        name: "2021-2022",
        value: 2021
    },
    {
        name: "2022-2023",
        value: 2022
    },
    {
        name: "2023-2024",
        value: 2023
    },
    {
        name: "2024-2025",
        value: 2024
    },
    {
        name: "2025-2026",
        value: 2025
    },
    {
        name: "2026-2027",
        value: 2026
    },
]
console.log('last update: 16:00:00 9/05/2023')
let dev = JSON.parse(JSON.stringify(localStorage.getItem('devLog')));
if (dev != 'open') {
    // console.log = function () {
    // };
    // console.warn = function () {
    // };
    // console.error = function () {
    // };
}
const state = user ? {
    status: {
        loggedIn: true
    },
    user,
    profile: null,
    list_nam_hoc: list_nam_hoc,
    nam_hoc_hien_tai: curentYear,
    list_cap_hoc: list_cap_hoc,
} : {
    status: {
        loggedIn: false
    },
    user: null,
    capCha: null,
    list_nam_hoc: list_nam_hoc,
    nam_hoc_hien_tai: curentYear,
    list_cap_hoc: list_cap_hoc,
};
const getters = {};
const actions = {
    reGetCapCha({
                    commit
                }, data) {
        let email = data.email
        userService.getCapCha(email).then(
            response => {
                if (response.data.code == 200) {
                    commit('setCapCha', response.data.data);
                }
            }
        ).catch(function (e) {
            commit('setCapCha', null);
        });
    },
    login({
              dispatch,
              commit
          }, {
              email,
              password,
              rememberPassword,
              capChaValue
          }) {
        commit('loginRequest', {
            email
        });
        let remember = {
            email: '',
            password: ''
        };
        if (rememberPassword) {
            remember.email = email;
            remember.password = password;
            localStorage.setItem('rememberCheck', 1);
        } else {
            remember.email = '';
            remember.password = '';
            localStorage.setItem('rememberCheck', 0);
        }
        localStorage.setItem('remember', JSON.stringify(remember));
        commit('setCapCha', null);
        userService.login(email, password, capChaValue)
            .then(
                response => {
                    console.log("response đăng nhập");
                    console.log(response);
                    let msg = "";
                    if (response.data && response.data.code == 200) {
                        console.log("Thành công")
                        localStorage.setItem('userSso', false);
                        localStorage.setItem('user', JSON.stringify(response.data.data));
                        // localStorage.setItem('token', response.data.data.token);
                        util.setCookie('token', response.data.data.token, 60)
                        sessionStorage.setItem("token", response.data.data.token);
                        let token1   = sessionStorage.getItem("token");
                        console.log('token session:'+token1)
                        // Vue.http.headers.common['Authorization'] = 'Bearer ' + response.body.token
                        console.log('Đăng nhập thành công')
                        console.log('Thông tin đăng nhập:')
                        console.log(response.data.data)
                        commit('loginSuccess', response.data.data);
                        dispatch('alert/clear', null, {
                            root: true
                        });
                        if(true){
                            window.location.href = '/thong-tin-tai-khoan';
                            // router.push({
                            //     name: 'ThongTinTaiKhoan'
                            // });
                        }
                        else if (response.data.data.role == 5) {
                            router.push({
                                name: 'DanhSachHocBa'
                            });
                        } else if (response.data.data.role == 8) {
                            router.push({
                                name: 'CauHinhNguoiDung'
                            });
                        } else if (response.data.data.role == 7 || response.data.data.role == 6 || response.data.data.role == 4) {
                            router.push({
                                name: 'ThongKeBaoCao'
                            });
                        } else {
                            router.push({
                                name: 'DashBoard'
                            });
                        }
                    } else if (response.data && response.data.other != null && response.data.other != "") {
                        commit('setCapCha', response.data.other);
                        commit('loginFailure')
                        dispatch('alert/error', response.data.msg ? response.data.msg : 'Đang bảo trì hệ thống', {
                            root: true
                        });
                    } else {
                        console.log("Đăng nhập lỗi")
                        commit('loginFailure');
                        dispatch('alert/error', response.data.msg ? response.data.msg : 'Đang bảo trì hệ thống', {
                            root: true
                        });
                    }
                }
            ).catch(function (error) {
            commit('loginFailure', error);
            dispatch('alert/error', 'Hệ thống bận vui lòng thử lại sau', {
                root: true
            });
        });
    },
    loginOtp({
                 dispatch,
                 commit
             }, data = {
        maHocSinh: '',
        otpCode: '',
        capChaValue: ''
    }) {
        var mhs = data.maHocSinh;
        userService.confirmOtp(data.maHocSinh, data.otpCode, data.capChaValue).then(
            response => {
                console.log(response);
                let msg = "";
                if (response.data && response.data.code == 200) {
                    localStorage.setItem('user', JSON.stringify(response.data.data));
                    // localStorage.setItem('token', response.data.data.token);
                    util.setCookie('token', response.data.data.token, 60)
                    dispatch('alert/clear', null, {
                        root: true
                    });
                    commit('loginSuccess', response.data.data);
                    if(true){

                        window.location.href = '/thong-tin-tai-khoan';
                        // router.push({
                        //     name: 'ThongTinTaiKhoan'
                        // });
                    }
                    else if (response.data.data.role == 5) {
                        router.push({
                            name: 'DanhSachHocBa'
                        });
                    } else if (response.data.data.role == 8) {
                        router.push({
                            name: 'CauHinhNguoiDung'
                        });
                    } else if (response.data.data.role == 7 || response.data.data.role == 6 || response.data.data.role == 4) {
                        router.push({
                            name: 'ThongKeBaoCao'
                        });
                    } else {
                        router.push({
                            name: 'DashBoard'
                        });
                    }
                } else if (response.data && response.data.other != null && response.data.other != "") {
                    commit('setCapCha', response.data.other);
                    dispatch('alert/error', response.data.msg ? response.data.msg : 'Đang bảo trì hệ thống', {
                        root: true
                    });
                } else {
                    // localStorage.removeItem('token');
                    util.deleteCookie('token');
                    localStorage.removeItem('user');
                    commit('loginFailure');
                    dispatch('alert/error', response.data.msg, {
                        root: true
                    });
                }
            }
        ).catch(function (e) {
            commit('loginFailure', error);
            dispatch('alert/error', 'Hệ thống bận vui lòng thử lại sau', {
                root: true
            });
        });
    },
    getOtp({
               dispatch,
               commit
           }, maHocSinh) {
        userService.getOtp(maHocSinh).then(
            response => {

                if (response.data && response.data.code == 200) {
                    commit('loginRequest');
                    dispatch('alert/error', '', {
                        root: true
                    });
                } else {
                    dispatch('alert/error', response.data.msg, {
                        root: true
                    });
                    commit('loginFailure');
                }
            }
        ).catch(function (e) {
            console.log(e);
        });

    },
    logout({
               commit
           }) {
        userService.logout();
        // localStorage.removeItem('token')
        localStorage.clear();
        util.deleteCookie('token');
        commit('logout');
        // router.push({ name: 'Login' });
        window.location.href = '/';
    },
    clearData({
                  commit
              }) {
        localStorage.clear();
        commit('logout');
        router.push({
            name: 'Login'
        });
    },
    getProfile({
                   commit
               }) {
        userService.getProfile().then(
            response => {
                console.log(response);
                if (response.data && response.data.code == 200) {
                    commit('getProfile', response.data.data);
                } else {

                }
            }
        ).catch(function (error) {
            console.log(error);
            // commit();
        });
    },
    checkToken({
                   commit
               }) {
        // let token = localStorage.getItem('token');
        let token = util.getCookie('token');
        let user = JSON.parse(localStorage.getItem('user'));
        if (token && user) {
            if (user.role == 5) {
                userService.checkTokenHS().then(
                    response => {
                        if (response.data && response.data.code == 200) {

                        } else {
                            // localStorage.removeItem('token');
                            util.deleteCookie('token');
                            localStorage.removeItem('user');
                            router.push({
                                name: 'LoginOtp'
                            });
                        }
                    }
                ).catch(function (e) {
                    // localStorage.removeItem('token');
                    util.deleteCookie('token');
                    localStorage.removeItem('user');
                    router.push({
                        name: 'LoginOtp'
                    });
                });
            } else {
                userService.checkTokenGV().then(
                    response => {
                        if (response.data && response.data.code == 200) {

                        } else {
                            // localStorage.removeItem('token');
                            util.deleteCookie('token');
                            localStorage.removeItem('user');
                            router.push({
                                name: 'Login'
                            });
                        }
                    }
                ).catch(function (e) {
                    // localStorage.removeItem('token');
                    util.deleteCookie('token');
                    localStorage.removeItem('user');
                    router.push({
                        name: 'Login'
                    });
                });
            }
        }
    }
};
const mutations = {
    loginRequest(state, user) {
        state.status = {
            loggingIn: true
        };
        state.user = user;
    },
    getOtp(state, otpCode) {
        state.status = {
            getOtp: true
        };
    },
    loginSuccess(state, user) {
        console.log("loginSuccess")
        console.log(user)
        if(user.capHoc){
            let ch = user.capHoc.toString();
            let cap_hoc = ch.split('');
            list_cap_hoc = [];
            for (let i = 0; i < cap_hoc.length; i++) {
                if (cap_hoc[i] == 1) {
                    list_cap_hoc.push(cap_mot)
                }
                if (cap_hoc[i] == 2) {
                    list_cap_hoc.push(cap_hai)
                }
                if (cap_hoc[i] == 3) {
                    list_cap_hoc.push(cap_ba)
                }
                if (cap_hoc[i] == 6) {
                    list_cap_hoc.push(gdtx)
                }
            }
            state.status = {
                loggedIn: true
            };
            state.list_cap_hoc = list_cap_hoc;
            state.user = user;
        }else{
            localStorage.clear();
            window.location.href = '/';
        }
    },
    loginFailure(state) {
        state.status = {};
        state.user = null;
    },
    setCapCha(state, data) {
        let cc = null;
        if (data) {
            cc = "data:image/png;base64, " + data;
        }
        state.capCha = cc;
    },
    logout(state) {
        state.list_cap_hoc = [];
        state.status = {};
        state.user = null;
    },
    getProfile(state, data) {
        state.profile = data;
    }
};
export const account = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};