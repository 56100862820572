<template>
    <div id="detailHocBa" class="page page-file file detailHocBa"
         element-loading-background="rgba(0, 0, 0, 0.8)"
         v-loading.fullscreen.lock="fullscreenLoading">
        <div class="page__title d-flex align-items-center justify-content-between">
            <h1 class="title">Danh sách học sinh</h1>

        </div>
        <div class="box-upload-file">
            <el-form :rules="rulesSearch" ref="searchForm" :model="searchForm" :hide-required-asterisk="true">

                <el-row :gutter="24">
                    <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
                        <el-form-item class="d-inline-block" prop="year">
                            <eselect style="width:100%" collapseTags v-model="searchForm.year" placeholder="Chọn"
                                     @change="onYearChange"
                                     filterable :data="list_nam_hoc" :fields="['name','value']"/>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
                        <el-form-item class="d-inline-block" prop="education">
                            <eselect style="width:100%" disabled collapseTags v-model="searchForm.education"
                                     @change="capHocChange" placeholder="Chọn" filterable :data="optionEducation"
                                     :fields="['label','value']"/>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
                        <el-form-item class="d-inline-block">
                            <eselect style="width:100%" collapseTags v-model="searchForm.grade" @change="khoiHocChange"
                                     placeholder="Chọn" filterable :data="optionGrade"
                                     :fields="['label','value']"/>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
                        <el-form-item class="d-inline-block">
                            <eselect style="width:100%" collapseTags v-model="searchForm.class" placeholder="Chọn"
                                     filterable :data="danh_sach_lop_hoc" :fields="['tenLop','maLop']"/>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
                        <el-form-item class="d-inline-block">
                            <el-input style="width:100%" v-model="searchForm.maHS"
                                      placeholder="Mã/Tên học sinh"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="8" :md="6" :lg="4" :xl="3" class="text-center">
                        <el-button type="primary" @click="submitSearch('searchForm')">Tìm kiếm</el-button>
                    </el-col>
                </el-row>
            </el-form>
        </div>

        <div class="page__content">
            <div class="listNew mb-5">
                <el-table border fit :lazy="true" :data="danh_sach_hoc_sinh ? danh_sach_hoc_sinh : []"
                          element-loading-text="Loading..."
                          element-loading-spinner="el-icon-loading"
                          element-loading-background="rgba(0, 0, 0, 0.8)"
                          width="100%" ref="listFile" v-loading="loading">

                    <el-table-column label="STT" style="width: 50px" width="50" type="index" class-name="text-center"
                                     :index="indexMethod">
                    </el-table-column>
                    <el-table-column label="Mã học sinh" width="170" align="center" property="maHS"></el-table-column>
                    <el-table-column label="Họ tên" header-align="center" width="220" align="left" property="tenHS">
                    </el-table-column>
                    <el-table-column label="Lớp" property="tenLop" align="center" class="text-center"
                                     style="text-align: center"></el-table-column>
                    <el-table-column label="GVCN" property="tenGVCN" align="center">
                    </el-table-column>
                    <el-table-column label="Trạng thái" align="center">
                        <template slot-scope="scope">
                            <div v-if="scope.row.trangThai==1">Đang học</div>
                            <div v-if="scope.row.trangThai==0">Mới chuyển đến</div>
                            <div v-else></div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="150" label="Hành động" class-name="">
                        <template slot-scope="scope">
                            <div class="btn__table_action">
                                <el-tooltip content="Xem học bạ" placement="top">
                                    <el-button v-if="scope.row.view" data-option="" size="mini"
                                               @click.prevent="xemHocBa(scope.row)"
                                               type="primary"
                                               icon="el-icon-view">
                                    </el-button>
                                    <el-button v-else disabled data-option="" size="mini"
                                               type="primary"
                                               icon="el-icon-view">
                                    </el-button>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div  v-if="total" class="d-flex align-items-center justify-content-between">
                    <template>
                        <small>
                            <i>Danh sách có {{ total }} học sinh</i>
                        </small>
                    </template>
                    <div class="text-right mt-2">
                        <pagination v-if="danh_sach_hoc_sinh" :page-count="getTotalPage(total)" v-model="paginate.page"
                                    :initial-page="paginate.page" :click-handler="getHocBa" :prev-text="'Trước'"
                                    :next-text="'Sau'"
                                    :container-class="'el-pager'" :pageClass="'number'">

                        </pagination>
                    </div>
                </div>

            </div>
        </div>
    </div>

</template>

<script>
import websocket from "../websocket";
import {
    mapActions,
    mapState
} from 'vuex';
import Pagination from "../components/Pagination";
import DialogAlert from "../components/DialogAlert";
import ChonKhoiHoc from "../components/ChonKhoiHoc";
import CapHocKhoiHoc from "../components/CapHocKhoiHoc";
import ThongTinHocBa from "../pages/HocBa/ThongTinHocBa";
import ESelectVue from "./Ui/ESelect";
import constant from '../constant';
import constant_api from '../_helpers/constant_api';
import api from '../_helpers/api';


export default {
    name: "DanhSachHocSinh",
    metaInfo: {
        title: 'Danh sách học sinh',
        titleTemplate: '',
        meta: [{
            vmid: 'description',
            name: 'description',
            content: ''
        }]
    },
    computed: {
        ...mapState('teacher', [
            "listLop",
            "listHocBa",
        ]),
        ...mapState('account', [
            'user',
            'list_nam_hoc'
        ])
    },
    components: {
        DialogAlert,
        Pagination,
        ChonKhoiHoc,
        CapHocKhoiHoc,
        'eselect': ESelectVue,
    },

    data() {

        return {
            danh_sach_hoc_sinh: [],
            loading: false,
            maxHeight: window.innerHeight,
            paginate: {
                start: 0,
                limit: 100,
                page: 1,
            },
            total: 0,
            fullscreenLoading: false,
            optionEducation: [{
                value: 1,
                label: 'Cấp 1'
            },
                {
                    value: 2,
                    label: 'Cấp 2'
                },
                {
                    value: 3,
                    label: 'Cấp 3'
                },
                {value: 15, label: 'Liên cấp'},
                {value: 145, label: 'Liên cấp'},
                {value: 14, label: 'Liên cấp'},
                {value: 13, label: 'Liên cấp'},
                {value: 451, label: 'Liên cấp'},
                {value: 23, label: 'Liên cấp'},
                {value: 236, label: 'Liên cấp'},
                {value: 36, label: 'Liên cấp'},
                {value: 452, label: 'Liên cấp'},
                {value: 1245, label: 'Liên cấp'},
                {value: 12, label: 'Liên cấp'},
                {value: 123, label: 'Liên cấp'},
                {value: 12345, label: 'Liên cấp'},
                {value: 1235, label: 'Liên cấp'},
                {value: 6, label: 'Trung tâm GDTX'},
            ],
            optionGrade: [],
            danh_sach_lop_hoc: [],
            khoiCap1: [{
                value: 1,
                label: 'Khối 1'
            },
                {
                    value: 2,
                    label: 'Khối 2'
                },
                {
                    value: 3,
                    label: 'Khối 3'
                },
                {
                    value: 4,
                    label: 'Khối 4'
                },
                {
                    value: 5,
                    label: 'Khối 5'
                },
            ],
            khoiCap2: [{
                value: 6,
                label: 'Khối 6'
            },
                {
                    value: 7,
                    label: 'Khối 7'
                },
                {
                    value: 8,
                    label: 'Khối 8'
                },
                {
                    value: 9,
                    label: 'Khối 9'
                },
            ],
            khoiCap3: [{
                value: 10,
                label: 'Khối 10'
            },
                {
                    value: 11,
                    label: 'Khối 11'
                },
                {
                    value: 12,
                    label: 'Khối 12'
                },

            ],
            optionStudent: [{
                value: '',
                label: 'Học sinh'
            },
                {
                    value: 1,
                    label: 'HS 1'
                },
                {
                    value: 2,
                    label: 'HS 2'
                },
                {
                    value: 3,
                    label: 'HS 3'
                },
            ],
            optionStatus: [{
                value: '',
                label: 'Trạng thái'
            },
                {
                    value: 1,
                    label: 'Đã ký'
                },
                {
                    value: 2,
                    label: 'Đã ký duyệt'
                },
                {
                    value: 3,
                    label: 'Chưa ký'
                },
            ],
            searchForm: {
                year: '',
                education: '',
                grade: '',
                class: '',
                student: '',
                status: '',
                maHS: ''
            },
            rulesSearch: {
                year: [{
                    required: true,
                    message: 'Bạn chưa chọn năm học',
                    trigger: 'change'
                }],
                grade: [{
                    required: true,
                    message: 'Chưa chọn khối học',
                    trigger: 'change'
                }],
                class: [{
                    required: true,
                    message: 'Chưa chọn lớp học',
                    trigger: 'change'
                }],
            }
        }
    },

    methods: {
        xemHocBa(e) {
            this.fullscreenLoading = true;
            console.log("Xem học bạ:")
            console.log(e)
            let params = {
                maLop: e.maLop,
                maHS: e.maHS,
                isBia: false,
                isLoad: true,
                isDau: false,
                isKQHT: false
            };
            let url = constant_api.hocsinh.xemHocBaHocSinh;

            api.get(url, params).then(
                response => {
                    this.fullscreenLoading = false;
                    console.log("Res:")
                    console.log(response)
                    if (response.data.code == 200) {
                        let linkPfd = response.data.data + '#toolbar=0';
                        this.openFancyBox(linkPfd)
                    } else {
                        this.thongBao("error", 'Không lấy được dữ liệu')
                    }
                }
            )
        },
        openFancyBox(url) {
            console.log("url" + url)
            $.fancybox.open({
                src: url,
                type: 'iframe',
            });
        },
        getListHocSinh(e) {
            console.log("Lấy danh sách học sinh:")
            console.log(e);
            let url = constant_api.hocsinh.danhSachHocSinhTheoLop;
            // this.fullscreenLoading = true;
            this.loading = true;
            api.get(url, e).then(
                response => {
                    this.loading = false;
                    // this.fullscreenLoading = false;
                    console.log("Res:")
                    console.log(response)
                    if (response.data.code == 200) {
                        this.danh_sach_hoc_sinh = response.data.data.list;
                        this.total = response.data.data.total;
                    } else {
                        this.thongBao("error", response.data.msg)
                    }
                }
            )
        },
        getCurrentMonth() {
            let d = new Date();
            return d.getMonth();
        },
        ...mapActions('teacher', [
            "getListLop",
            "clear",
            "updateHocBa",
            "kySoHocBa",
            "getReport",

        ]),

        indexMethod(index) {
            return (this.paginate.start + index) + 1;
        },
        getKhoiHoc() {
            let list_cap_hoc = this.user.capHoc;
            list_cap_hoc = list_cap_hoc.toString();
            list_cap_hoc = list_cap_hoc.split("");
            let danh_sach_khoi = [];
            for (let i = 0; i < list_cap_hoc.length; i++) {
                if (list_cap_hoc[i] == 1) {
                    danh_sach_khoi = danh_sach_khoi.concat(this.khoiCap1)
                }
                if (list_cap_hoc[i] == 2) {
                    danh_sach_khoi = danh_sach_khoi.concat(this.khoiCap2)
                }
                if (list_cap_hoc[i] == 3) {
                    danh_sach_khoi = danh_sach_khoi.concat(this.khoiCap3)
                }
            }
            this.optionGrade = danh_sach_khoi;
        },

        submitSearch(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.loading = true;
                    this.getHocBa(1)
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        getHocBa(select) {
            this.showDialogOtp = false;
            this.paginate.start = (select - 1) * this.paginate.limit ? (select - 1) * this.paginate.limit : 0;
            this.paginate.page = select;
            this.getListHocSinh({
                start: this.paginate.start,
                limit: this.paginate.limit,
                maLop: this.searchForm.class,
                namHoc: this.searchForm.year ? this.searchForm.year : '',
                capHoc: this.searchForm.education,
                khoiHoc: this.searchForm.grade,
                maHS: this.searchForm.maHS,
            });
        },
        onYearChange(){

            this.searchForm.student = '';
            this.searchForm.maHS = '';
            this.searchForm.status = '';
            this.searchForm.grade = '';
            this.searchForm.class = '';
            this.danh_sach_lop_hoc = []
        },
        capHocChange() {
            this.searchForm.student = '';
            this.searchForm.maHS = '';
            this.searchForm.status = '';
            this.searchForm.grade = ''
            if (!this.searchForm.education) {
                this.optionGrade = this.khoiCap1.concat(this.khoiCap2, this.khoiCap3)
                return;
            } else {
                if (this.searchForm.education == 1) {
                    this.optionGrade = this.khoiCap1;
                } else if (this.searchForm.education == 2) {
                    this.optionGrade = this.khoiCap2;
                } else {
                    this.optionGrade = this.khoiCap3;
                }
            }
        },
        khoiHocChange() {
            this.getlistLopHoc();
            this.searchForm.class = '';

        },
        getTotalPage(number) {
            return Math.ceil(number / (this.paginate.limit));
        },
        getlistLopHoc() {
            let url = constant_api.giaovien.listLop;
            let params = {
                start: 0,
                limit: 999,
                namHoc : this.searchForm.year,
                khoiHoc: this.searchForm.grade
            }
            this.fullscreenLoading = true;
            api.get(url, params).then(
                response => {
                    this.fullscreenLoading = false;
                    console.log("Res:")
                    console.log(response)
                    if (response.data.code == 200) {
                        this.danh_sach_lop_hoc = response.data.data.list;
                    } else {
                        this.thongBao("error", 'Không lấy được danh sách lớp')
                    }
                }
            )
        },

        thongBao(t, e) {
            let msg = "";
            let cl = "";
            if (e) {
                msg = e;
            }
            let type = "success";
            if (t) {
                type = t
            }
            if (type == "success") {
                cl = "dts-noty-success"
            }
            if (type == "warning") {
                cl = "dts-noty-warning"
            }
            if (type == "error") {
                cl = "dts-noty-error"
            }
            if (type == "info") {
                cl = "dts-noty-info"
            }
            this.$message({
                customClass: cl,
                showClose: true,
                message: msg,
                type: t,
                duration: 3000
            });
        },
        getFullYear() {
            let d = new Date();
            return d.getFullYear();
        },
    },

    beforeMount() {
    },
    mounted() {

        this.searchForm.year = this.getFullYear();
        this.searchForm.education = this.user.capHoc;

        let currentMonth = this.getCurrentMonth() + 1;
        if (currentMonth >= 9) {
        } else {
            this.searchForm.year -= 1;
        }
        this.getKhoiHoc();

        this.submitSearch('searchForm')
    },
    watch: {},

}
</script>

<style scoped>
.btn__table_action button {
    padding: 8px 10px;
}

.el-col {
    border-radius: 4px;
}

.bg-purple-dark {
    background: #99a9bf;
}

.bg-purple {
    background: #d3dce6;
}

.bg-purple-light {
    background: #e5e9f2;
}

.grid-content {
    border-radius: 4px;
    min-height: 36px;
}

.el-form-item {
    width: 100%
}

p {
    padding: 0;
    margin: 0;
}

.dangKy {
    color: #409EFF;
}

.chuaKy {
    color: #E6A23C;
}

.daKy {
    color: #479e24;
}

.el-dialog.thongTinKyTheoMonHoc {
    min-width: 375px;
}

.el-dialog.el-dialog--center.thongTinKyTheoMonHoc {
    min-width: 375px;
}
</style>