import VueRouter from "vue-router";
import guest from "./middleware/guest";
import auth from "./middleware/auth";
import Error from "../pages/404";
import pageMaintain from "../pages/maintain.vue";
import khaoSat from "../pages/khaoSat";
import Login from "../pages/Login";
import LoginOtp from "../pages/LoginOtp";
import Logout from "../pages/Logout";
import DanhSachHocBa from "../pages/DanhSachHocBa";
import LoginSSO from "../pages/LoginSSO";
import LoginSmCa from "../pages/LoginSmCa";
import CheckCode from "../pages/CheckCode";
import CheckCodeK12 from "../pages/CheckCodeK12";
import redirecttosso from "../pages/redirecttosso";
import DashBoard from "../pages/DashBoard";
import DanhSachHocSinh from "../pages/DanhSachHocSinh";
import DanhSachHocSinh2 from "../pages/DanhSachHocSinh2";
import PhanCongKy from "../pages/PhanCongKy";
import CauHinhNguoiDung from "../pages/CauHinhNguoiDung";
import CauHinhMauHocBa from "../pages/CauHinhMauHocBa";
import KyDauHocBa from "../pages/KyDauHocBa";
import DongDauBia from "../pages/DongDauBia";
import DongDauBiaV2 from "../pages/DongDauBiaV2";
import kyFilePdf from "../pages/kyFilePdf.vue";
import DongDauKetQuaHocTap from "../pages/DongDauKetQuaHocTap";
import DongDauSoDiem from "../pages/DongDauSoDiem";
import DanhSachYeuCauNop from "../pages/DanhSachYeuCauNop";
import DanhSachYeuCauRut from "../pages/DanhSachYeuCauRut";
import ChiTietHocBa from "../pages/GiaoVien/ChiTietHocBa";
import QuanLyHocBa from "../pages/QuanLyHocBa";
import NopHoSoHocBa from "../pages/NopHoSoHocBa";
import ThemHocBa from "../pages/ThemHocBa";
import DanhSachYeuCauChuyenTruong from "../pages/DanhSachYeuCauChuyenTruong";
import SuaHocBa from "../pages/SuaHocBa";
import Clear from "../pages/Clear";
import QuanLyGiaoVien from "../pages/QuanLyGiaoVien";
import NopChungThuSo from "../pages/NopChungThuSo";
import QuangLyChung from "../pages/QuangLyChung";
import test from "../pages/test";
import DongBoDuLieu from "../pages/DongBoDuLieu";
import QuanLyTaiKhoan from "../pages/quanLyTaiKhoan.vue";
import ThongTinHocSinh from "../pages/ThongTinHocSinh";
import QuanLyMenu from "../pages/QuanLyMenu";
import ThemCauHinh from "../pages/CauHinhKy/ThemCauHinh";
import DanhSachCauHinh from "../pages/CauHinhKy/DanhSachCauHinh";
import ThongKeKy from "../pages/BaoCao/ThongKeKy";
import ThongKeKyLai from "../pages/BaoCao/ThongKeKyLai";
import DetailAccount from "../pages/Users/Detail";
import InHocBa from "../pages/HocBa/InHocBa";
import QuanLyNamHoc from "../pages/QuanLyNamHoc";
import ToolMoTruong from "../pages/ToolMoTruong";
import QuanLyKyThay from "../pages/QuanLyKyThay";
import ImportCanBo from "../pages/Import/ImportCanBo";
import ImportLop from "../pages/Import/ImportLop";
import ImportHocSinh from "../pages/Import/ImportHocSinh";
import ImportKetQuaHocTap from "../pages/Import/ImportKetQuaHocTap";
import ThongKeTruyCap from "../pages/ThongKeTruyCap";
import PhanCongKyBiaVaTieuDe from "../pages/PhanCongKyBiaVaTieuDe.vue";
import ThongKeBaoCao from "../pages/ThongKeBaoCao";
import BaoCaoTrangThai from "../pages/BaoCaoTrangThai";
import BaoCaoDuLieuHocBa from "../pages/BaoCao/duLieuHocBa";
import CauHinhSoDiem from "../pages/CauHinhSoDiem.vue";
import duLieuGiaoVien from "../pages/BaoCao/duLieuGiaoVien";
import baoCaoKy from "../pages/BaoCao/baoCaoKyV2.vue";
import ImportNhanXetKhenThuong from "../pages/Import/ImportNhanXetKhenThuong";
import LichSuNhapDuLieu from "../pages/Import/LichSuNhapDuLieu";
import ViewLog from "../pages/ViewLog";
import BaoCaoKy from "../pages/BaoCaoKy";
import DanhSachLop from "../pages/LopHoc/DanhSachLop";
import DanhSachLopV2 from "../pages/LopHoc/DanhSachLopV2";
import hocSinhChuyenLop from "../pages/LopHoc/chuyenLop";
import quanLyGoiCuoc from "../pages/quanLyGoiCuoc";
import KetChuyenLenLop from "../pages/LopHoc/KetChuyenLenLop";
import util from "../util";
import ChotDuLieuHocBa from "../pages/ChotDuLieuHocBa";
import KyBiaHocBa from "../pages/KyBiaHocBa";
import KyHieuChinhHoSo from "../pages/KyBiaHieuChinhHoSo.vue";
import DongDauHieuChinh from "../pages/DongDauHieuChinhHoSo.vue";
import LichSuNopHoSo from "../pages/LichSuNopHoSo.vue";
import KyQuaTrinhHocTap from "../pages/KyQuaTrinhHocTap";
import KySoDiem from "../pages/KySoDiem.vue";
import KyQuaTrinhHocTapThiLai from "../pages/KyQuaTrinhHocTap-ThiLai.vue";
import DongDauBiaKqht from "../pages/DongDauBiaKqht.vue";

/**
 * Role
 * 3 - Hiệu trưởng
 * 2 - Giáo viên chủ nhiệm
 * 1 - Giáo viên bộ môn
 * 4 - Cán bộ
 * 5 - Học sinh
 * 6 - Phòng GD
 * 7 - Sở GD
 *
 **/
const routes = [{
    path: '/',
    name: 'CallbackSso',
    component: LoginSSO,
    meta: {
        layout: 'none',
    }
},
    {
        path: '/smca-login',
        name: 'LoginSmCa',
        component: LoginSmCa,
        meta: {
            layout: 'none',
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            layout: 'none',
            middleware: [
                guest
            ],
            // role: [9,1,2,3,4]
        }
    }, {
        path: '/redirecttosso',
        name: 'redirecttosso',
        component: redirecttosso,
        meta: {
            layout: 'none',
            middleware: [
                guest
            ],
            // role: [9,1,2,3,4]
        }
    }, {
        path: '/smas',
        name: 'CheckCode',
        component: CheckCode,
        meta: {
            layout: 'none',
        },
    }, {
        path: '/login-k12',
        name: 'CheckCodeK12',
        component: CheckCodeK12,
        meta: {
            layout: 'none',
        },
    },
    // {
    //     path: '/callback-sso',
    //     name: 'CallbackSso',
    //     component: LoginSSO,
    //     meta: {
    //         layout: 'none',
    //     }
    // },
    {
        path: '/cau-hinh-ky-hoc-ba',
        name: 'CauHinhKy',
        component: DanhSachCauHinh,
        meta: {
            layout: 'default',
            middleware: [
                auth,
            ],
            role: [9, 6, 7]
        }
    },
    {
        path: '/cau-hinh-mau-hoc-ba',
        name: 'CauHinhMauHocBa',
        component: CauHinhMauHocBa,
        meta: {
            layout: 'default',
            middleware: [
                auth,
            ],
            role: [9, 6, 7]
        }
    },
    {
        path: '/dong-dau-hoc-ba',
        name: 'KyDauHocBa',
        component: KyDauHocBa,
        meta: {
            layout: 'default',
            middleware: [
                auth,
            ],
            role: [9, 4, 3]
        }
    },
    {
        path: '/dong-dau-bia',
        name: 'DongDauBia',
        component: DongDauBia,
        meta: {
            layout: 'default',
            middleware: [
                auth,
            ],
            role: [3]
        }
    },
    {
        path: '/dong-dau-bia-v2',
        name: 'DongDauBiaV2',
        component: DongDauBiaV2,
        meta: {
            layout: 'default',
            middleware: [
                auth,
            ],
            role: [1, 3, 9]
        }
    },
    // {
    //     path: '/dong-dau-bia-v2',
    //     name: 'kyFilePdf',
    //     component: kyFilePdf,
    //     meta: {
    //         layout: 'default',
    //         middleware: [
    //             auth,
    //         ],
    //         role: [1,3,9]
    //     }
    // },
    {
        path: '/dong-dau-kqht',
        name: 'DongDauKetQuaHocTap',
        component: DongDauKetQuaHocTap,
        meta: {
            layout: 'default',
            middleware: [
                auth,
            ],
            role: [1, 3, 9]
        }
    },
    {
        path: '/dong-dau-so-diem',
        name: 'DongDauSoDiem',
        component: DongDauSoDiem,
        meta: {
            layout: 'default',
            middleware: [
                auth,
            ],
            role: [1, 3, 9]
        }
    },
    {
        path: '/thong-ke-truy-cap',
        name: 'ThongKeTruyCap',
        component: ThongKeTruyCap,
        meta: {
            layout: 'default',
            middleware: [
                auth,
            ],
        }
    },
    {
        path: '/phan-cong-ky-tieu-de-bia',
        name: 'PhanCongKyBiaVaTieuDe',
        component: PhanCongKyBiaVaTieuDe,
        meta: {
            layout: 'default',
            middleware: [
                auth,
            ],
        }
    },
    {
        path: '/import-giao-vien',
        name: 'ImportCanBo',
        component: ImportCanBo,
        meta: {
            layout: 'default',
            middleware: [
                auth,
            ],
        }
    },
    {
        path: '/import-hoc-sinh',
        name: 'ImportHocSinh',
        component: ImportHocSinh,
        meta: {
            layout: 'default',
            middleware: [
                auth,
            ],
        }
    },
    {
        path: '/import-lop-hoc',
        name: 'ImportLop',
        component: ImportLop,
        meta: {
            layout: 'default',
            middleware: [
                auth,
            ],
        }
    },
    {
        path: '/import-ket-qua-hoc-tap',
        name: 'ImportKetQuaHocTap',
        component: ImportKetQuaHocTap,
        meta: {
            layout: 'default',
            middleware: [
                auth,
            ],
        }
    },
    {
        path: '/import-nhan-xet-khen-thuong',
        name: 'ImportNhanXetKhenThuong',
        component: ImportNhanXetKhenThuong,
        meta: {
            layout: 'default',
            middleware: [
                auth,
            ],
        }
    },
    {
        path: '/chot-du-lieu-hoc-ba',
        name: 'ChoDuLieuHocBa',
        component: ChotDuLieuHocBa,
        meta: {
            layout: 'default',
            middleware: [
                auth,
            ],
        }
    },
    {
        path: '/quan-ly-menu',
        name: 'QuanLyMenu',
        component: QuanLyMenu,
        meta: {
            layout: 'default',
            middleware: [
                auth,
            ],
            role: [9, 8]
        }
    },
    {
        path: '/bao-cao-ky-hoc-ba',
        name: 'ThongKeKy',
        component: ThongKeKy,
        meta: {
            layout: 'default',
            middleware: [
                auth,
            ],
            role: [9, 6, 7]
        }
    },
    {
        path: '/bao-cao-ky-lai-hoc-ba',
        name: 'ThongKeKyLai',
        component: ThongKeKyLai,
        meta: {
            layout: 'default',
            middleware: [
                auth,
            ],
            role: [9, 6, 7]
        }
    },
    {
        path: '/dong-bo-du-lieu',
        name: 'DongBoDuLieu',
        component: DongBoDuLieu,
        meta: {
            layout: 'default',
            middleware: [
                auth,
            ],
            role: [9, 4, 6, 7, 8, 1, 2]
        }
    },
    {
        path: '/quan-ly-tai-khoan',
        name: 'QuanLyTaiKhoan',
        component: QuanLyTaiKhoan,
        meta: {
            layout: 'default',
            middleware: [
                auth,
            ],
            role: [9, 4, 6, 7, 8, 1, 2]
        }
    },
    {
        path: '/in-hoc-ba',
        name: 'InHocBa',
        component: InHocBa,
        meta: {
            layout: 'default',
            middleware: [
                auth,
            ],
            role: [1, 9, 3, 4, 6, 7]
        }
    },
    {
        path: '/test',
        name: 'Test',
        component: DetailAccount,
        meta: {
            layout: 'none',
        }
    },
    {
        path: '/login-otp',
        name: 'LoginOtp',
        component: LoginOtp,
        meta: {
            layout: 'none',
        }
    },
    {
        path: '/dashboard',
        name: 'DashBoard',
        component: DashBoard,
        meta: {
            layout: 'default',
            middleware: [
                auth
            ],
            role: [9, 1, 2, 3, 4, 6, 7]
        }
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Logout,
        meta: {
            layout: 'default',
            middleware: [
                auth
            ],
            role: [9, 1, 2, 3, 4, 5, 6, 7, 8]
        }
    },
    {
        path: '/danh-sach-hoc-ba-theo-nam-hoc',
        name: 'DanhSachHocSinh',
        component: DanhSachHocSinh,
        meta: {
            layout: 'default',
            middleware: [
                auth,
            ],
            role: [9, 1, 2, 3, 5, 6, 7]
        }
    },
    {
        path: '/danh-sach-hoc-sinh',
        name: 'DanhSachHocSinh2',
        component: DanhSachHocSinh2,
        meta: {
            layout: 'default',
            middleware: [
                auth,
            ],
            role: [9, 1, 2, 3, 5, 6, 7]
        }
    },
    {
        path: '/ky-bia-hoc-ba',
        name: 'KyBiaHocBa',
        component: KyBiaHocBa,
        meta: {
            layout: 'default',
            middleware: [
                auth,
            ],
        }
    },
    {
        path: '/ky-hieu-chinh-ho-so',
        name: 'KyHieuChinhHoSo',
        component: KyHieuChinhHoSo,
        meta: {
            layout: 'default',
            middleware: [
                auth,
            ],
        }
    },
    {
        path: '/dong-dau-hieu-chinh',
        name: 'DongDauHieuChinh',
        component: DongDauHieuChinh,
        meta: {
            layout: 'default',
            middleware: [
                auth,
            ],
        }
    },
    {
        path: '/ky-qtht',
        name: 'KyQuaTrinhHocTap',
        component: KyQuaTrinhHocTap,
        meta: {
            layout: 'default',
            middleware: [
                auth,
            ],
        }
    },
    {
        path: '/ky-so-diem',
        name: 'KySoDiem',
        component: KySoDiem,
        meta: {
            layout: 'default',
            middleware: [
                auth,
            ],
        }
    },
    {
        path: '/dong-dau-bia-qtht',
        name: 'DongDauBiaKqht',
        component: DongDauBiaKqht,
        meta: {
            layout: 'default',
            middleware: [
                auth,
            ],
        }
    },
    {
        path: '/phan-cong-ky',
        name: 'PhanCongKy',
        component: PhanCongKy,
        meta: {
            layout: 'default',
            middleware: [
                auth,
            ],
            role: [9, 1, 2, 3, 4, 5, 6, 7, 8]
        }
    },
    {
        path: '/cau-hinh-nguoi-dung',
        name: 'CauHinhNguoiDung',
        component: CauHinhNguoiDung,
        meta: {
            layout: 'default',
            middleware: [
                auth,
            ],
            role: [9, 8]
        }
    },
    {
        path: '/thong-tin-hoc-sinh',
        name: 'ThongTinHocSinh',
        component: ThongTinHocSinh,
        meta: {
            layout: 'default',
            middleware: [
                auth,
            ],
            role: [9, 4, 2, 3]
        }
    },
    {
        path: '/thong-tin-tai-khoan',
        name: 'ThongTinTaiKhoan',
        component: DetailAccount,
        meta: {
            layout: 'default',
            middleware: [
                auth,
            ],
            role: [9, 1, 2, 3, 4, 5, 7, 6, 8]
        }
    },
    {
        path: '/danh-sach-hoc-ba',
        name: 'DanhSachHocBa',
        component: DanhSachHocBa,
        meta: {
            layout: 'default',
            middleware: [
                auth,
            ],
            role: [9, 5, 6, 7]
        }
    },
    {
        path: '/danh-sach-yeu-cau-nop',
        name: 'DanhSachYeuCauNop',
        component: DanhSachYeuCauNop,
        meta: {
            layout: 'default',
            middleware: [
                auth
            ],
            role: [9, 3]
        }
    },
    {
        path: '/danh-sach-yeu-cau-rut',
        name: 'DanhSachYeuCauRut',
        component: DanhSachYeuCauRut,
        meta: {
            layout: 'default',
            middleware: [
                auth
            ],
            role: [9, 3]
        }
    },
    {
        path: '/danh-sach-yeu-cau-chuyen-truong',
        name: 'DanhSachYeuCauChuyenTruong',
        component: DanhSachYeuCauChuyenTruong,
        meta: {
            layout: 'default',
            middleware: [
                auth
            ],
            role: [9, 3]
        }
    },
    {
        path: '/quan-ly-hoc-ba',
        name: 'QuanLyHocBa',
        component: QuanLyHocBa,
        meta: {
            layout: 'default',
            middleware: [
                auth
            ],
            role: [9, 4, 1]
        }
    },
    {
        path: '/nop-ho-so-hoc-ba',
        name: 'NopHoSoHocBa',
        component: NopHoSoHocBa,
        meta: {
            layout: 'default',
            middleware: [
                auth
            ],
            role: [9, 4, 1]
        }
    },
    {
        path: '/quan-ly-giao-vien',
        name: 'QuanLyGiaoVien',
        component: QuanLyGiaoVien,
        meta: {
            layout: 'default',
            middleware: [
                auth
            ],
            role: [9, 4, 7]
        }
    },
    {
        path: '/dang-ky-chung-thu-so',
        name: 'NopChungThuSo',
        component: NopChungThuSo,
        meta: {
            layout: 'default',
            middleware: [
                auth
            ],
            role: [9, 4, 7]
        }
    },
    {
        path: '/quan-ly-chung',
        name: 'QuanLyChung',
        component: QuangLyChung,
        meta: {
            layout: 'default',
            middleware: [
                auth
            ],
            role: [9, 4]
        }
    },
    {
        path: '/them-hoc-ba',
        name: 'ThemHocBa',
        component: ThemHocBa,
        meta: {
            layout: 'default',
            middleware: [
                auth
            ],
            role: [9, 4]
        }
    },
    {
        path: '/chi-tiet-hoc-ba/:id',
        name: 'ChiTietHocBa',
        component: ChiTietHocBa,
        meta: {
            layout: 'none',
        }
    },
    {
        path: '/sua-thong-tin-hoc-ba',
        name: 'SuaHocBa',
        component: SuaHocBa,
        meta: {
            layout: 'default',
            middleware: [
                auth
            ],
            role: [9, 4]
        }
    },
    {
        path: '/clear-data',
        name: 'Clear',
        component: Clear,
        meta: {
            layout: 'default',
        }
    },
    // {
    //     path : '/sign-usb',
    //     name : 'SignUsb',
    //     meta: { layout: 'none' },
    //     component: SignUsb,
    // },
    {
        path: '/quan-ly-ky-thay',
        name: 'QuanLyKyThay',
        component: QuanLyKyThay,
        meta: {
            layout: 'default',
            middleware: [
                auth
            ],
            role: [9, 1, 2, 3, 4, 6, 7]
        }
    },
    {
        path: '/thoi-gian-nam-hoc',
        name: 'QuanLyNamHoc',
        component: QuanLyNamHoc,
        meta: {
            layout: 'default',
            middleware: [
                auth
            ],
            role: [9, 1, 2, 3, 4, 6, 7]
        }
    },
    {
        path: '/tool-mo-truong',
        name: 'ToolMoTruong',
        component: ToolMoTruong,
        meta: {
            layout: 'default',
            middleware: [
                auth
            ],
            role: [1, 2, 3, 4, 5, 6, 7, 8, 9]
        }
    },
    {
        path: '/view-log',
        name: 'ViewLog',
        component: ViewLog,
        meta: {
            layout: 'default',
            middleware: [
                auth
            ],
            role: [9, 1, 2, 3, 4, 6, 7, 8]
        }
    },
    {
        path: '/bao-cao-ky',
        name: 'BaoCaoKy',
        component: BaoCaoKy,
        meta: {
            layout: 'default',
            middleware: [
                auth
            ],
            role: [9, 1, 2, 3, 4, 6, 7, 8]
        }
    },
    {
        path: '/danh-sach-lop',
        name: 'DanhSachLop',
        component: DanhSachLop,
        meta: {
            layout: 'default',
            middleware: [
                auth
            ],
            role: [9, 1, 2, 3, 4, 6, 7, 8]
        }
    },
    {
        path: '/danh-sach-lop-v2',
        name: 'KyQuaTrinhHocTapThiLai',
        component: KyQuaTrinhHocTapThiLai,
        meta: {
            layout: 'default',
            middleware: [
                auth
            ],
            role: [9, 1, 2, 3, 4, 6, 7, 8]
        }
    },
    {
        path: '/ket-chuyen-len-lop',
        name: 'KetChuyenLenLop',
        component: KetChuyenLenLop,
        meta: {
            layout: 'default',
            middleware: [
                auth
            ],
            role: [1, 2, 3, 4, 6, 7, 8, 9]
        }
    },
    {
        path: '/lich-su-nhap-du-lieu',
        name: 'LichSuNhapDuLieu',
        component: LichSuNhapDuLieu,
        meta: {
            layout: 'default',
            middleware: [
                auth
            ],
            role: [9, 1, 2, 3, 4, 6, 7, 8]
        }
    },

    {
        path: '/lich-su-nop-ho-so',
        name: 'LichSuNopHoSo',
        component: LichSuNopHoSo,
        meta: {
            layout: 'default',
            middleware: [
                auth
            ],
            role: [9, 1, 2, 3, 4, 6, 7, 8]
        }
    },

    {
        path: '/thong-ke-bao-cao',
        name: 'ThongKeBaoCao',
        component: ThongKeBaoCao,
        meta: {
            layout: 'default',
            middleware: [
                auth
            ],
            role: [9, 1, 2, 3, 4, 6, 7, 8]
        }
    },
    {
        path: '/bao-du-lieu-hoc-ba',
        name: 'BaoCaoDuLieuHocBa',
        component: BaoCaoDuLieuHocBa,
        meta: {
            layout: 'default',
            middleware: [
                auth
            ],
            role: [9, 1, 2, 3, 4, 6, 7, 8]
        }
    },
    {
        path: '/chot-so-diem',
        name: 'CauHinhSoDiem',
        component: CauHinhSoDiem,
        meta: {
            layout: 'default',
            middleware: [
                auth
            ],
            role: [9, 1, 2, 3, 4, 6, 7, 8]
        }
    },
    {
        path: '/bao-cao-du-lieu-giao-vien',
        name: 'duLieuGiaoVien',
        component: duLieuGiaoVien,
        meta: {
            layout: 'default',
            middleware: [
                auth
            ],
            role: [9, 1, 2, 3, 4, 6, 7, 8]
        }
    },
    {
        path: '/bao-du-lieu-ky',
        name: 'baoCaoKy',
        component: baoCaoKy,
        meta: {
            layout: 'default',
            middleware: [
                auth
            ],
            role: [9, 1, 2, 3, 4, 6, 7, 8]
        }
    },
    {
        path: '/bao-cao-trang-thai',
        name: 'BaoCaoTrangThai',
        component: BaoCaoTrangThai,
        meta: {
            layout: 'default',
            middleware: [
                auth
            ],
            role: [9, 1, 2, 3, 4, 6, 7, 8]
        }
    },
    {
        path: '/chuyen-lop',
        name: 'hocSinhChuyenLop',
        component: hocSinhChuyenLop,
        meta: {
            layout: 'default',
            middleware: [
                auth
            ],
            role: [9, 1, 2, 3, 4, 6, 7, 8]
        }
    },
    {
        path: '/goi-cuoc',
        name: 'quanLyGoiCuoc',
        component: quanLyGoiCuoc,
        meta: {
            layout: 'default',
            middleware: [
                auth
            ],
            role: [9, 1, 2, 3, 4, 6, 7, 8]
        }
    },
    {
        path: '/khao-sat',
        name: 'khaoSat',
        component: khaoSat,
        meta: {
            layout: 'default'
        }
    },
    {
        path: '404',
        name: 'page404',
        component: Error,
        meta: {
            layout: 'none'
        }
    },
    {
        path: '/maintain',
        name: 'pageMaintain',
        component: pageMaintain,
        meta: {
            layout: 'none'
        }
    },
    {
        path: '*',
        name: 'Error',
        component: Error,
        meta: {
            layout: 'none'
        }
    }
];
export const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior() {
        document.getElementById('app').scrollIntoView();
    }
});
router.beforeEach((to, from, next) => {
    to.meta.previousRoute = from.path;
    // redirect to login page if not logged in and trying to access a restricted page
    const role = to.meta.role ? to.meta.role : '';
    const roleUser = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).role : '';
    const token = util.getCookie('token');
    if (token && to.name != 'khaoSat'&& to.name != 'pageMaintain' && to.name != 'page404' && to.name != 'CallbackSso' && to.name != 'Login' && to.name != 'CheckCode' && to.name != 'CheckCodeK12' && to.name != 'LoginOtp' && to.name != 'LoginSmCa' && to.name != 'redirecttosso') {
        let thongtin = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem("user")) : null;
        console.log("Thông tin");
        console.log(thongtin)
        let menu = thongtin ? thongtin.danhSachMenu : [];
        let lstPath = util.getListPath(menu);
        let current = to.path.replace(/["/"]/g, "");
        if (!lstPath.includes(current)) {
            return next({
                name: 'page404'
            })
        }
    }
    if (role && role.length && (to.name != 'Login' && to.name != 'page404' && to.name != 'CallbackSso' && to.name != 'CheckCode' && to.name != 'CheckCodeK12' && to.name != 'LoginOtp' && to.name != 'khaoSat')) {
        if (roleUser && !role.includes(roleUser)) {
            // role not authorised so redirect to home page
            return next({
                name: 'Login'
            })
        }
    }
    next();
});