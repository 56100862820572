<template>
  <div v-loading.fullscreen.lock="loading" :element-loading-text="loadingText"
       element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)"></div>
</template>

<script>
import websocket from "../websocket";
import api from '../_helpers/api';
import msgType from "../_helpers/constant_msg_type";
import {
  mapState,
  mapActions
} from 'vuex';
import dialog from "../dialog";
import {
  Message
} from 'element-ui';
import constant_api from "../_helpers/constant_api";

export default {
  name: "KyHocBaUsbAllV2",
  props: [
    'layCts',
    'nph'

  ],
  computed: {
    ...mapState('teacher', [
      'pdfSignature'
    ]),
  },
  data: () => {
    return {
      list_res_sign_hash: [],
      EdocPluginSocket: '',
      signatureBase64: '',
      loadingText: 'Đang xử lý',
      loading: false,
      typeKyDuLieu: '',
      SerialNumberSend: ''
    }
  },
  methods: {
    signUsb() {
      this.loadingText = 'Đang lấy thông tin chứng thư số...';
      this.loading = true;
      let that = this;
      console.log('B1: getCertificateInfo')
      this.EdocPluginSocket.getCertificateInfo(function (response) {
        console.log('getCertificateInfo:')
        console.log(response)
        if (response.Status == 0) {
          that.thongBao('error', response.Message)
          that.loading = false;
          that.huyKy();
          return;
        } else if (response.Status == -105) {
          that.thongBao('error', response.Message)
          that.loading = false;
          that.$confirm('Xác nhận tải và cài đặt ứng dụng?', 'Thông báo', {
            confirmButtonText: 'Đồng ý',
            cancelButtonText: 'Hủy',
          })
              .then(_ => {
                console.log("Tải ứng dụng:")
                window.location.href = '/images/EDOC-CA Plugin Setup.msi';
              })
              .catch(_ => {
                console.log('Không tải ứng dụng')
              });
          return;
        } else {
        }

        console.log("B2")
        var certChainBase64 = new String(response.Data.Base64);
        if (certChainBase64 == undefined || certChainBase64.length < 500) {
          console.log('case 1')
          that.thongBao('error', 'Chọn CTS không thành công')
          that.loading = false;
          return false;
        } else {
          console.log('case 2')
          console.log('certChainBase64:')
          console.log(response.Data)
          let item = {
            validFrom: response.Data.NotBefore,
            validTo: response.Data.NotAfter,
            nhaPhatHanh: that.nph,
            serialNumber: response.Data.Serial,
            status: 'invalid',
            subject: response.Data.SubjectCN,
          }
          that.suDungCTS(item)
          that.loading = false;
        }
      });

    },

    suDungCTS(item) {
      console.log('suDungCTS')
      console.log(item)
      let url = constant_api.giaovien.nopCTSGV;
      let params = JSON.parse(JSON.stringify(item))
      let dataSend = {
        certBO: params,
        type: 4
      }
      console.log(dataSend)
      this.$confirm('Xác nhận cập nhật thông tin chứng thư số?', 'Thông báo', {
        confirmButtonText: 'Cập nhật',
        cancelButtonText: 'Hủy',
        confirmButtonClass: 'el-button--primary'
      })
          .then(_ => {
            console.log('Gửi dữ liệu cập nhật')
            api.post(url, dataSend).then(
                response => {
                  console.log("Res:")
                  console.log(response)
                  if (response.data.code == 200) {
                    this.thongBao('success', response.data.msg)
                    // this.layChungThuSo()
                  } else {
                    this.thongBao("error", response.data.msg)
                  }
                  this.loading = false;

                  this.$emit('done')
                }
            )
          })
          .catch(_ => {
          });
    },

    thongBao(t, e) {
      let msg = "";
      let cl = "";
      if (e) {
        msg = e;
      }
      let type = "success";
      if (t) {
        type = t
      }
      if (type == "success") {
        cl = "dts-noty-success"
      }
      if (type == "warning") {
        cl = "dts-noty-warning"
      }
      if (type == "error") {
        cl = "dts-noty-error"
      }
      if (type == "info") {
        cl = "dts-noty-info"
      }
      this.$message({
        customClass: cl,
        showClose: true,
        message: msg,
        type: t,
        duration: 3000
      });
    },
  },

  watch: {
    layCts(val) {
      if (val) {
        console.log("Lấy chứng thư số")
        this.signUsb()
      }
    }

  },
  beforeDestroy() {
    console.log('beforeDestroy')
    window.removeEventListener('message', this.onReceiveMessage)
  },
  beforeRouteLeave(to, from, next) {
    console.error('beforeRouteLeave')
    window.removeEventListener('message', this.onReceiveMessage)
    next();
  },
  mounted() {
    console.error('Mounted lấy thông tin CTS:')
    window.addEventListener('message', this.onReceiveMessage, false);
    var that = this;
    this.EdocPluginSocket = {
      sendMessageToPlugin: function (serviceName, data, callBack) {
        const socket = new WebSocket('ws://localhost:5987/' + serviceName);
        // Connection opened
        socket.addEventListener('open', function (event) {
          console.log("Open")
          socket.send(data);
        });
        socket.addEventListener('message', function (event) {
          console.log("message")
          callBack(JSON.parse(event.data));
        });

        // Listen for close connection
        socket.addEventListener('close', function (event) {
          console.log('close')
          var response = {
            Status: -105,
            Message: "Chưa cài đặt hoặc chưa chạy EDOC-CA Plugin."
          }
          if (event.code == 1006) {
            callBack(response);
          }
        });
      },

      sendMessageToPluginAsync: async function (serviceName, data) {
        // Create WebSocket connection.
        return new Promise((resolve, reject) => {
          const socket = new WebSocket('ws://localhost:5987/' + serviceName);
          // Connection opened
          socket.addEventListener('open', function (event) {
            console.log("Open")
            socket.send(data);
          });

          // Listen for messages
          socket.addEventListener('message', function (event) {
            console.log("message")

            resolve(JSON.parse(event.data));
          });

          // Listen for close connection
          socket.addEventListener('close', function (event) {
            console.log('close')
            var response = {
              Status: -105,
              Message: "Chưa cài đặt hoặc chưa chạy EDOC-CA Plugin."
            }
            if (event.code == 1006) {
              resolve(response);
            }
          });
        })
      },

      getCertificateInfo: function (callBack) {
        var functionName = 'get-cert-info';
        console.log("getCertificateInfo")
        that.EdocPluginSocket.sendMessageToPlugin(functionName, null, callBack);
      },
      signHash: async function (hashBase64, serialNumber) {
        var functionName = 'sign-hash';
        var data = {
          DataHash: hashBase64,
          SerialNumber: serialNumber
        }
        console.log("data signHash ")
        console.log(data)
        const respon = await that.EdocPluginSocket.sendMessageToPluginAsync(functionName, JSON.stringify(data));
        console.log("respon signHash")
        console.log(respon)
        return respon.Data
      }
    }
    setTimeout(() => {
      console.log('Lấy thông tin CTS')
      this.signUsb()
    }, 500)
  }
}
</script>

<style scoped>

</style>