<template>
  <div class="page page-file file detailHocBa">
    <div class="page__title d-flex align-items-center justify-content-between">
      <h1 class="title">Nhập dữ liệu lớp học</h1>
      <el-tooltip class="item" effect="dark" content="Hướng dẫn nhập dữ liệu" placement="top">
        <a class="link" href="/files/huong_dan_nhap_du_lieu.docx"> Hướng dẫn nhập dữ liệu</a>
      </el-tooltip>
    </div>

    <div class="box-upload-file">
      <el-row :gutter="20">
        <el-col :xs="12" :sm="6" :md="4" :lg="4">
            <label>Năm học</label>
          <eselect style="width:100%" collapseTags v-model="namHoc" placeholder="Chọn" filterable
                   :data="list_nam_hoc" :fields="['name','value']" />
        </el-col>
        <el-col :xs="12" :sm="6" :md="4" :lg="4">
            <label>Cấp học</label>
          <eselect style="width:100%" collapseTags v-model="capHoc" placeholder="Chọn" filterable
                   :data="list_cap_hoc" :fields="['name','value']" />
        </el-col>
      </el-row>
    </div>

    <div class="page__header">
      <h5 class="title">Dữ liệu lớp học</h5>
      <small><i> (Truy cập Học bạ số -> Nhập dữ liệu lớp học -> Tải file mẫu nhập lớp học)</i></small>
<!--      <p v-if="user.qlnt==1"><i style="font-size:12px">(Truy cập vnedu -> Quản lý lớp học -> Nhập xuất -> Xuất ra excel danh sách lớp)</i></p>-->
<!--      <p v-if="user.qlnt==2"><i style="font-size:12px">(Truy cập moet -> Lớp học -> Nhập lớp học từ excel -> Tải file mẫu )</i></p>-->
<!--      <el-row :gutter="20">-->
<!--        <el-col :xs="12" :sm="6" :md="4" :lg="4">-->
<!--            <label>Phương thức nhập(<i style="color: red;">*</i>)</label>-->
<!--            <eselect class="mb-2" size="small" style="width:100%" collapseTags placeholder="Chọn" v-model="typeLopHoc" filterable-->
<!--                   :data="this.user.qlnt==1? listPhuongThucNhapVnEdu : listPhuongThucNhapMoet" :fields="['name','value']" />-->
<!--        </el-col>-->
<!--      </el-row>-->
      <ImportGeneral  :pathSampleFile="typeLopHoc == 1? pathSampleFile.lopHoc : pathSampleFile.lopHocChung"
                      :data-type="2"
                      :requireKhoiHoc="false"
                      :hideClass="true"
                      :text-download="'Tải file nhập lớp học'"
                      :fileNameError="'danh_sach_loi_nhap_lop_hoc'" :fileHasData="true" :linkFileHasData="typeLopHoc === 1? linkFileHasData : linkFileHasDataChung" :require-cap-hoc="true" :require-nam-hoc="true" :cap-hoc="capHoc" :year="namHoc"
                      :template-download="template" :source="sourceLopHoc" :requireImportType="true" :path="typeLopHoc === 1? path : pathChung"
                      :disabledDownload="typeLopHoc!=2&&false"
                      :importType="typeLopHoc"></ImportGeneral>
    </div>

    <div v-if="false">
      <div v-if="user.qlnt==1" class="page__header">
        <h5 class="title">Phân công giảng dạy</h5>
        <small><i> (Danh sách lớp học -> Chọn cấp học -> Tại cột GVCN chọn GV được phân công -> Tại cột Phân công thực hiện phân công giảng dạy)</i></small>
<!--        <p v-if="user.qlnt==1"><i style="font-size:12px">(Truy cập vnedu -> PC giảng dạy -> Nhập xuất -> Xuất mẫu nhập)</i></p>-->
<!--        <el-row :gutter="20">-->
<!--          <el-col :xs="12" :sm="6" :md="4" :lg="4">-->
<!--            <label>Phương thức nhập(<i style="color: red;">*</i>)</label>-->
<!--            <eselect class="mb-2" size="small" style="width:100%" collapseTags placeholder="Chọn" :disabled="user.qlnt==1" v-model="typePcgd" filterable-->
<!--                     :data="this.user.qlnt==1? listPhuongThucNhapVnEdu : listPhuongThucNhapMoet" :fields="['name','value']" />-->
<!--          </el-col>-->
<!--        </el-row>-->
        <ImportGeneral :require-nam-hoc="true" :require-cap-hoc="true" :cap-hoc="capHoc" :year="namHoc"
                       :importType="typePcgd"
                       :data-type="4"
                       :text-download="'Tải file nhập phân công giảng dạy'"
                       :requireKhoiHoc="false"
                       :hideClass="true"
                       :disabledDownload="true" :template-download="template" :source="source" :path="path2">
        </ImportGeneral>
      </div>
    </div>
  </div>

</template>

<script>
// import ImportGeneral from "./ImportGeneral";
import ImportGeneral from "./ImportChung.vue";
import constant_api from "../../_helpers/constant_api";
import {
  mapState
} from "vuex";
import ESelect from "../../components/ESelect";

export default {
  name: "ImportLopHoc",
  metaInfo: {
    title: 'Nhập dữ liệu lớp học',
    meta: [{
      vmid: 'description',
      name: 'description',
      content: ''
    }],
  },
  components: {
    ImportGeneral,
    'eselect': ESelect,
  },
  computed: {
    ...mapState('account',
        [
          "user",
          'list_nam_hoc',
          'list_cap_hoc',
          'nam_hoc_hien_tai',
        ]),

  },
  data() {
    return {
      linkFileHasData: constant_api.download.linkFileHasData.lopHoc,
      linkFileHasDataChung: constant_api.download.linkFileHasData.lopHocChung,
      pathSampleFile: {
        lopHoc:'',
        lopHocChung: '',
      },
      namHoc: '',
      capHoc: '',
      fullscreenLoading: false,
      template: [{
        label: 'VNEDU',
        type: 1,
        url: ''
      },

      ],
      source: [{
        type: 1,
        label: 'VNEDU',
        rowHeader: 11
      },{
        type: 2,
        label: 'MOET',
        rowHeader: 1
      }

      ],
      sourceLopHoc: [{
        type: 1,
        label: 'VNEDU',
        rowHeader: 1
      },{
        type: 2,
        label: 'MOET',
        rowHeader: 1
      }

      ],
      path: constant_api.import.lopHoc,
      pathChung: constant_api.import.lopHocChung,
      path2: constant_api.import.phanCongGiangDay,
      typeLopHoc: '',
      typePcgd: '',
      listPhuongThucNhapVnEdu : [
                    {
                      'name':'Mẫu VNEDU',
                      'value': 1
                    }, 
                    {
                      'name': 'Mẫu Học bạ số',
                      'value': 2
                    }
                   ],
      listPhuongThucNhapMoet : [
                    {
                      'name':'Mẫu MOET',
                      'value': 1
                    }, 
                    {
                      'name': 'Mẫu Học bạ số',
                      'value': 2
                    }
                   ]
    }
  },
  methods: {
    getFullYear() {
      let d = new Date();
      return d.getFullYear();
    },
    getListCapHoc() {
      if (this.user && this.user.capHoc) {
        let ch = this.user.capHoc.toString();
        let cap_hoc = ch.split('');
        this.capHoc = parseInt(cap_hoc[0]);
      }
    }
  },
  mounted() {
    this.getListCapHoc();
    this.namHoc = this.nam_hoc_hien_tai;
    if(this.user){
      this.pathSampleFile.lopHocChung = '/files/common/lop_hoc.xlsx';
      if(this.user.qlnt==1){
        this.pathSampleFile.lopHoc='/files/vnedu/lop_hoc_vnedu.xls';
        this.typePcgd = 1;
      }
      if(this.user.qlnt==2){
        this.pathSampleFile.lopHoc='/files/moet/lop_hoc_moet.xls';
      }
    }
  }
}
</script>

<style scoped>
.title {
  margin-bottom: 0;
}

</style>