<template>
  <div class="page page-request" v-loading="loading.fullScreen" element-loading-text="Loading..."
       element-loading-spinner="el-icon-loading" element-loading-background="rgba(255, 255, 255, 0.8)">
    <!-- <div class="file--title">
        <h2 class="title">Thông tin giáo viên</h2>
    </div> -->
    <div class="page__content infoAccount" v-if="infoGiaoVien">

      <iframe height="1" width="1" style="display:contents" :src="infoGiaoVien.linkChuKy"></iframe>
      <el-row class="dts-content" :gutter="10">
        <el-col :lg="5" :md="4" :sm="3"></el-col>
        <el-col class="bg-smoke text-center" :lg="14" :md="16" :sm="18" :xs="24">
          <el-row :gutter="24">
            <el-col class="text-center">
              <div class="img">
                <img src="/images/default-avatar.png" width="200px" alt="Ảnh đại diện">
              </div>
              <h3>{{ infoGiaoVien.tenGV }}</h3>
              <p class="mt-2">
                <el-tooltip content="Đổi mật khẩu" placement="top">
                  <el-button @click.prevent="showUpdatePass" size="mini" type="danger"><i
                      class="el-icon-key" style="font-size: 14px"></i>
                  </el-button>
                </el-tooltip>
              </p>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :sm="12" :xs="24" class="text-left">
              <el-card shadow="always">
                <div class="iconInfo">
                  <i class="el-icon-user"></i>
                </div>
                <div class="textInfo">
                  <span>Mã giáo viên</span>
                  <p>{{ infoGiaoVien.maGV }}</p>
                </div>
              </el-card>
            </el-col>
            <el-col :sm="12" :xs="24" class="text-left">
              <el-card shadow="always">
                <div class="iconInfo">
                  <i class="el-icon-phone-outline"></i>
                </div>
                <div class="textInfo">
                  <div v-if="!updateSdt">
                    <span>Số điện thoại</span>
                    <p v-if="infoGiaoVien.soDienThoai">{{ infoGiaoVien.soDienThoai }}
                      <el-tooltip class="item" effect="dark" content="Chỉnh sửa số điện thoại"
                                  placement="top">
                        <i @click="showChinhSuaSdt"
                           class="el-icon-edit-outline float-right cursor-pointer"></i>
                      </el-tooltip>
                    </p>
                    <p v-else>Chưa cập nhật
                      <el-tooltip class="item" effect="dark"
                                  content="Thêm thông tin số điện thoại" placement="top">
                        <i @click="showChinhSuaSdt"
                           class="el-icon-circle-plus-outline float-right cursor-pointer"></i>
                      </el-tooltip>
                    </p>
                  </div>
                  <div v-else>
                    <span>Số điện thoại</span>
                    <el-input placeholder="Nhập sdt..." size="mini"
                              v-model="infoGiaoVien.soDienThoai">
                      <el-button slot="append" @click.prevent="thayDoiSdt" icon="el-icon-check">
                      </el-button>
                    </el-input>
                  </div>

                </div>
              </el-card>
            </el-col>
            <el-col :sm="12" :xs="24" class="text-left">
              <el-card shadow="always">
                <div class="iconInfo">
                  <i class="el-icon-message"></i>
                </div>
                <div class="textInfo">
                  <span>Email</span>
                  <p v-if="infoGiaoVien.email">{{ infoGiaoVien.email }}</p>
                  <p v-else>Chưa cập nhật</p>
                </div>
              </el-card>
            </el-col>

            <el-col :sm="12" :xs="24" class="text-left">
              <el-card shadow="always">
                <div class="iconInfo">
                  <i class="el-icon-edit"></i>
                </div>
                <div class="textInfo" style="display:flex;">
                  <p style="padding-right:10px"><span>Chữ ký</span></p>
                  <div v-if="infoGiaoVien.linkChuKy" style="padding-right:15px">
                    <el-image style="width:110px;height:45px"
                              @click.prevent="xemAnh(infoGiaoVien.linkChuKy)"
                              v-show="infoGiaoVien.linkChuKy" :src="infoGiaoVien.linkChuKy">
                    </el-image>
                  </div>
                  <div style="position: absolute;right: 15px; bottom: 15px">
                    <!-- <el-tooltip v-if="infoGiaoVien.trangThaiChuKy==-1" content="Xác nhận chữ ký"
                        placement="top">
                        <el-button @click.prevent="chotChuKy" type="success" size="mini"><i
                                class="el-icon-check"></i></el-button>
                    </el-tooltip> -->
                    <el-tooltip v-if="infoGiaoVien.trangThaiChuKy!=1" content="Tải lên chữ ký"
                                placement="top">
                      <el-button @click.prevent="beforeUpload" type="primary" size="mini"><i
                          class="el-icon-upload"></i></el-button>
                    </el-tooltip>

                    <el-tooltip content="Xem trước" placement="top">
                      <el-button @click.prevent="xemAnhKy" type="primary" size="mini"><i
                          class="el-icon-coordinate"></i></el-button>
                    </el-tooltip>
                  </div>
                  <!--                                    <div v-if="infoGiaoVien.trangThaiChuKy==-1&&false"-->
                  <!--                                        style="position:absolute; right:65px;bottom:15px">-->
                  <!--                                        <el-tooltip content="Xác nhận chữ ký" placement="top">-->
                  <!--                                            <el-button @click.prevent="chotChuKy" type="success" size="mini"><i-->
                  <!--                                                    class="el-icon-check"></i></el-button>-->
                  <!--                                        </el-tooltip>-->
                  <!--                                    </div>-->
                  <!--                                    <div v-if="infoGiaoVien.trangThaiChuKy!=1"-->
                  <!--                                        style="position:absolute; right:15px;bottom:15px">-->
                  <!--                                        <el-tooltip content="Cập nhật chữ ký" placement="top">-->
                  <!--                                            <el-button @click.prevent="uploadChuKy" type="primary" size="mini"><i-->
                  <!--                                                    class="el-icon-upload"></i></el-button>-->
                  <!--                                        </el-tooltip>-->
                  <!--                                      <el-tooltip content="Xem ảnh ký" placement="top">-->
                  <!--                                        <el-button @click.prevent="xemAnhKy" type="primary" size="mini"><i-->
                  <!--                                            class="el-icon-coordinate"></i></el-button>-->
                  <!--                                      </el-tooltip>-->
                  <!--                                    </div>-->

                </div>
              </el-card>
            </el-col>

            <el-col v-if="showSmartCA" :sm="12" :xs="24" class="text-left">
              <el-card shadow="always">
                <div class="iconInfo">
                  <i class="el-icon-cloudy"></i>
                </div>
                <div class="textInfo">
                  <div v-if="!updateSMCA">
                    <span>Tài khoản Viettel SmartCA</span>
                    <p v-if="infoGiaoVien.usernameCAVT">
                      {{ infoGiaoVien.usernameCAVT }}
                      <el-tooltip class="item" effect="dark"
                                  content="Chỉnh sửa tài khoản Viettel SmartCa"
                                  placement="top">
                        <i @click="showChinhSuaSMCA"
                           class="el-icon-edit-outline float-right cursor-pointer"></i>
                      </el-tooltip>
                    </p>
                    <p v-else>Chưa cập nhật
                      <el-tooltip class="item" effect="dark"
                                  content="Thêm thông tin tài khoản" placement="top">
                        <i @click="showChinhSuaSMCA"
                           class="el-icon-circle-plus-outline float-right cursor-pointer"></i>
                      </el-tooltip>
                    </p>
                  </div>
                  <div v-else>
                    <span>Tài khoản Viettel SmartCA</span>
                    <el-input placeholder="Nhập thông tin..." size="mini"
                              v-model="infoGiaoVien.usernameCAVT">
                      <el-button slot="append" @click.prevent="thayDoiSMCA" icon="el-icon-check">
                      </el-button>
                    </el-input>
                  </div>

                </div>
              </el-card>
            </el-col>
            <el-col :sm="12" :xs="24" class="text-left">
              <el-card shadow="always">
                <div class="iconInfo">
                  <i class="el-icon-cloudy"></i>
                </div>
                <div class="textInfo">
                  <div>
                    <span>Serial Number</span>
                    <el-row :gutter="24">
                      <el-col :span="22" style="margin: 0">
                        <p v-if="infoGiaoVien.serialNumber" style="
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;">
                          {{ infoGiaoVien.serialNumber }}
                        </p>
                        <p v-else>Chưa cập nhật
                        </p>
                      </el-col>
                      <el-col v-if="false" :span="2">
                        <el-tooltip v-if="infoGiaoVien.serialNumber" class="item" effect="dark"
                                    content="Chỉnh sửa thông tin serial number"
                                    placement="top">
                          <i @click="layChungThuSo"
                             class="el-icon-edit-outline float-right cursor-pointer"></i>
                        </el-tooltip>
                        <el-tooltip v-else class="item" effect="dark"
                                    content="Thêm thông tin tài khoản" placement="top">
                          <i @click="layChungThuSo"
                             class="el-icon-circle-plus-outline float-right cursor-pointer"></i>
                        </el-tooltip>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </el-card>
            </el-col>
            <el-col v-if="hien_thi_cap_nhat_cts" :sm="24" :xs="24" class="text-left">
                  <el-table fit border :data="danh_sach_chung_thu_so">
<!--                    <el-table-column label="STT" width="50" align="center" type="index">-->
<!--                    </el-table-column>-->
                    <el-table-column header-align="center" label="Số serial">
                      <template slot-scope="scope">
                        <el-input type="text" v-model="scope.row.serialNumber"></el-input>
                      </template>
                    </el-table-column>
                    <el-table-column property="status" label="Trạng thái" align="center"
                                     header-align="center">
                      <template slot-scope="scope">
                        <el-input type="text" v-model="scope.row.status"></el-input>
                      </template>
                    </el-table-column>
                    <el-table-column property="validFrom" header-align="center" align="center" label="Ngày hiệu lực"
                                     >
                      <template slot-scope="scope">
                        <el-input type="text" v-model="scope.row.validFrom"></el-input>
                      </template>
                    </el-table-column>
                    <el-table-column property="validTo" header-align="center" align="center" label="Ngày hết hiệu lực" >
                      <template slot-scope="scope">
                        <el-input type="text" v-model="scope.row.validTo"></el-input>
                      </template>
                    </el-table-column>
                    <el-table-column property="nhaPhatHanh" header-align="center" align="center" label="Nhà phát hành">
                      <template slot-scope="scope">
                        <el-input type="text" v-model="scope.row.nhaPhatHanh"></el-input>
                      </template>
                    </el-table-column>
                    <el-table-column align="center" header-align="center" label="Hành động">
                      <template slot-scope="scope">
                        <el-button size="mini" @click.prevent="suDungCTS(scope.row)" type="primary">Sử dụng</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>

    <el-dialog title="Ảnh chữ ký" width="30%" center :visible.sync="hienThiAnh">
      <div class="demo-image__lazy">
        <el-image :src="linkAnh" style="width:100%" title="Ảnh chữ ký" lazy></el-image>
      </div>
    </el-dialog>

    <el-dialog title="Xem trước ảnh khi ký" width="60%" custom-class="anhKhiKyGiaoVien" center
               :visible.sync="hien_thi_anh_ky">
      <div class="text-center" style="height: 50vh">
        <p style="text-align:left;color: blue">Hình ảnh con dấu tải lên dưới đây sẽ tương quan so với con dấu ở học bạ khi đóng dấu. Qúy thầy cô vui lòng cân nhắc tải lại lên nếu con dấu quá nhỏ hoặc chưa đạt tiêu chuẩn!</p>
        <iframe :src="linkAnh" style="width: 100%; height: 100%"></iframe>
        <table v-if="false" class="border table table-bordered">
          <tr>
            <th rowspan="2">
              <p><b>Môn học / Hoạt động GD</b></p>
            </th>
            <th colspan="3">
              <p><b>Điểm trung bình hoặc xếp loại môn</b></p>
            </th>
            <th rowspan="2">
              <p><b>Điểm hoặc xếp loại KT sau thi lại (nếu có)</b></p>
            </th>
            <th rowspan="2">
              <p><b>Giáo viên bộ môn ký xác nhận ĐTB môn hoặc hoặc xếp loại sửa chữa (nếu có),(Ký & ghi rõ
                họ tên)</b></p>
            </th>
          </tr>
          <tr>
            <th>
              <p>HK I</p>
            </th>
            <th>
              <p>HK II</p>
            </th>
            <th>
              <p>CN</p>
            </th>
          </tr>
          <tr>
            <td>
              <p>[Môn học]</p>
            </td>
            <td>
              <p>[Điểm]</p>
            </td>
            <td>
              <p>[Điểm]</p>
            </td>
            <td>
              <p>[Điểm]</p>
            </td>
            <td>
              <p></p>
            </td>
            <td style="align-self: center;display: flex" class="text-left"><img :src="linkAnh"
                                                                                style="width: 150px;height: 40px"
                                                                                alt="Ảnh chữ ký">
              <p style="align-self: center">{{ infoGiaoVien.tenGV }}</p>
            </td>
          </tr>
        </table>
      </div>
    </el-dialog>
    <el-dialog title="Xem trước chữ ký" top="5vh" width="30%" custom-class="thongTinKyTheoMonHoc" center
               :visible.sync="showChuKy">
      <div class="text-center">
        <p>
          <b>HỌC BẠ ĐIÊN TỬ</b></p>
        <p><b>XEM TRƯỚC VỊ TRÍ DẤU - CHỮ KÝ</b></p>
        <div class="pt-3">
          <i>Địa danh ký, ngày........., tháng.........,năm..........</i>
          <p><b>HIỆU TRƯỞNG HOẶC PHÓ HIỆU TRƯỞNG</b></p>
          <i> (Ký, ghi rõ họ tên và đóng dấu)</i>
        </div>
        <div>
          <div style="width: 165px; height: 165px; margin: 0 auto; position: relative">
            <img style="position: absolute;left: 94px; bottom: 18px;height: 97.5px; width: 210px"
                 :src="infoGiaoVien.linkChuKy" alt="">
            <img :src="getLinkDauNhaTruong()" style="width: 100%; height: 100%; " alt="">
            <!--                        <div style="position: absolute;left: 94px; bottom: 18px;height: 97.5px; width: 210px">-->
            <!--                            <p>Họ tên hiệu trưởng</p>-->
            <!--                        </div>-->
          </div>
          <p><b>{{ infoGiaoVien.tenGV }}</b></p>
        </div>
      </div>
      <div class="text-left pt-2">
        <p><i><b>Ghi chú:</b> Để điều chỉnh vị trí chữ ký, Quý Thầy-Cô quay trở lại upload chữ ký, tải lại chữ ký di
          chuyển ảnh
          chữ ký kéo sang bên trái khung hình.</i></p>
      </div>
    </el-dialog>
    <el-dialog title="Tải lên chữ ký" custom-class="thongTinKyTheoMonHoc" center :before-close="closeDialogUpload"
               :visible.sync="dialogUploadSignatureVisible" class="uploadSignature">
      <div id="createSign">
        <crop-image v-if="dialogUploadSignatureVisible" :style-box="styleBox" @goStep="goStep" :step="step"
                    @uploadLuon="uploadLuon" :option="optionCrope" :showCrop="dialogUploadSignatureVisible"
                    @getBase64="getBase64"></crop-image>
      </div>
      <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="dialogUploadSignatureVisible = false;step = 1;">Hủy</el-button>
                <el-button v-if="step == 3" size="small" type="primary" @click="uploadSignature">Xác nhận</el-button>
            </span>
    </el-dialog>

    <el-dialog custom-class="thongTinKyTheoMonHoc" center title="Tài liệu hướng dẫn"
               :close-on-click-modal="false" :close-on-press-escape="false" :visible.sync="show_huong_dan">
      <el-row :gutter="24">
        <el-col :span="24">
          <ol class="taiLieu">
            <li><a href="https://google.com" target="_blank" title="Tài liệu hướng dẫn">Tài liệu hướng
              dẫn</a></li>
            <li><a href="https://youtube.com" target="_blank" title="Video hướng dẫn">Video hướng dẫn</a>
            </li>
            <li><a href="https://facebook.com" target="_blank" title="Ảnh chữ ký mẫu">
              <img src="images/huong_dan_upload_anh.png">
            </a>
            </li>
          </ol>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
                <el-button @click="show_huong_dan = false" size="mini">Đóng</el-button>
            </span>
    </el-dialog>
    <el-dialog width="50%" top="5vh" custom-class="thongTinKyTheoMonHoc" center title="Hướng dẫn"
               :close-on-click-modal="false" :close-on-press-escape="false"
               :visible.sync="show_huong_dan_upload">
      <el-row :gutter="24">
        <el-col :span="6">
          <h5>Ảnh mẫu:</h5>
          <img src="/images/huongdan.png" width="100%" height="auto" alt="Ảnh hướng dẫn">
        </el-col>
        <el-col :span="18">
          <h5>Lưu ý:</h5>
          <ul>
            <li><p><b>Dùng bút mực xanh nét to.</b></p></li>
            <li><p><b>Ký trên giấy trắng không có dòng kẻ.</b></p></li>
            <li><p><b>Chụp ảnh chữ ký rõ nét.</b></p></li>
            <li><p><b>Khi tải ảnh chữ ký thực hiện xóa nền trước khi xác nhận.</b></p></li>
          </ul>
        </el-col>
        <el-col :span="24">
          <video v-if="show_huong_dan_upload" width="100%" controls>
            <source src="https://cdnhocbadientu.csdl.edu.vn/media/huong_dan_upload_chu_ky.mp4"
                    type="video/mp4">
          </video>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
                <!-- <el-button @click="show_huong_dan_upload = false" size="mini">Đóng</el-button> -->
                <el-button @click="uploadChuKy" type="success" size="mini">Đã hiểu</el-button>
            </span>
    </el-dialog>

    <el-dialog title="Đổi mật khẩu" width="50%" :visible.sync="show_update_pass"
               class="updateInfoTruong"
               :close-on-press-escape="false" :close-on-click-modal="false"
    >
      <el-row :gutter="24">
        <el-col :span="12">
          <label>Mật khẩu mới</label>
          <el-input clearable v-model="matKhau.moi" show-password placeholder="Nhập"></el-input>
        </el-col>
        <el-col :span="12">
          <label>Xác nhận mật khẩu mới</label>
          <el-input clearable v-model="matKhau.nhapLai" show-password placeholder="Nhập "></el-input>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer text-center" style="text-align: center !important;" >
                <el-button size="mini" @click="show_update_pass = false">Hủy</el-button>
                <el-button size="mini" type="danger" @click.prevent="xacNhanThayDoiMatKhau()">Đổi mật khẩu</el-button>
            </span>
    </el-dialog>

  </div>
</template>

<script>
// import replaceColor from 'replace-color';
import api from '../../_helpers/api';
import constant from '../../_helpers/constant_api';
import CropImage from "../../components/CropImage";
import DialogAlert from "../../components/DialogAlert";
import {
  VueCropper
} from 'vue-cropper';
import {
  mapActions,
  mapState
} from 'vuex';
import constant_api from "../../_helpers/constant_api";

export default {
  name: "InfoGiaoVien",
  data() {
    return {
      hien_thi_anh_ky: false,
      showSmartCA: false,
      updateSdt: false,
      updateSMCA: false,
      showChuKy: false,
      danh_sach_chung_thu_so:[],
      linkAnh: '',
      hien_thi_cap_nhat_cts: false,
      show_huong_dan_upload: false,
      hienThiAnh: false,
      show_update_pass:false,
      matKhau:{},
      styleBox: 'width: 400px; height: 250px;',
      infoGiaoVien: {},
      loading: {
        fullScreen: false
      },
      show_huong_dan: false,
      dialogUploadSignatureVisible: false,
      imageSignature: '',
      step: 1,
      option: {
        img: 'base64',
        size: 1,
        outputType: 'png'
      },
      optionCrope: {
        img: '',
        size: 1,
        full: true,
        outputType: 'png',
        canMove: true,
        fixedBox: true,
        original: false,
        canMoveBox: false,
        autoCrop: true,
        autoCropWidth: 200,
        autoCropHeight: 150,
        centerBox: true,
        high: true,
        max: 99999,
        fixed: true,
        fixedNumber: [20, 9]
      },
    }
  },
  components: {
    VueCropper,
    CropImage,
    DialogAlert
  },
  watch: {},
  mounted() {
    let width = this.getWidth();
    console.log(width);
    if (width < 1200) {

      let height = 250;
      this.styleBox = 'width: 100%; height: ' + height + 'px;';
    } else {
      this.styleBox = 'width: 400px; height: 250px;';
    }
    this.layThongTinGiaoVien();

  },
  methods: {
    suDungCTS(item){
      console.log('suDungCTS')
      console.log(item)
      let url = constant_api.giaovien.nopCTSGV;
      let params = JSON.parse(JSON.stringify(item))
      let dataSend = {
        certBO:params,
        type:this.infoGiaoVien.typeSign
      }
      console.log(dataSend)
      this.$confirm('Xác nhận cập nhật thông tin chứng thư số?', 'Thông báo', {
        confirmButtonText: 'Cập nhật',
        cancelButtonText: 'Hủy',
        confirmButtonClass: 'el-button--primary'
      })
          .then(_ => {
            this.loading.fullScreen = true;
            api.post(url, dataSend).then(
                response => {
                  console.log("Res:")
                  console.log(response)
                  if (response.data.code == 200) {
                    this.thongBao('success', response.data.msg)
                    // this.layChungThuSo()
                    this.layThongTinGiaoVien()
                  } else {
                    this.thongBao("error", response.data.msg)
                  }
                  this.loading.fullScreen = false;
                }
            )
          })
          .catch(_ => {
          });
    },
    xacNhanThayDoiMatKhau(){
      console.log('xacNhanThayDoiMatKhau')
      if (!this.matKhau.moi || this.matKhau.moi == '') {
        this.thongBao('error', 'Vui lòng nhập mật khẩu mới');
        return
      }
      if (!this.matKhau.nhapLai || this.matKhau.nhapLai == '') {
        this.thongBao('error', 'Xác nhận mật khẩu mới');
        return
      }
      if (this.matKhau.moi != this.matKhau.nhapLai) {
        this.thongBao('error', 'Mật khẩu nhập lại không khớp');
        return;
      }
      let url = constant_api.taiKhoan.doiMatKhau;
      let params = {
        email: this.infoGiaoVien.email,
        password: this.matKhau.moi
      }
      this.$confirm('Xác nhận đổi mật khẩu?', 'Thông báo', {
        confirmButtonText: 'Đổi mật khẩu',
        cancelButtonText: 'Hủy',
        confirmButtonClass: 'el-button--danger'
      })
          .then(_ => {
            this.loading.fullScreen = true;
            api.post(url, params).then(
                response => {
                  console.log("Res:")
                  console.log(response)
                  if (response.data.code == 200) {
                    this.thongBao('success', response.data.msg)
                    this.show_update_pass = false;
                  } else {
                    this.thongBao("error", response.data.msg)
                  }
                  this.loading.fullScreen = false;
                }
            )
          })
          .catch(_ => {
          });
    },
    showUpdatePass() {
      this.show_update_pass = true;
    },
    thayDoiSMCA() {
      this.$confirm('Xác nhận thay đổi thông tin tài khoản Viettel SmartCA?', 'Thông báo', {
        confirmButtonText: 'Xác nhận',
        cancelButtonText: 'Hủy',
      })
          .then(_ => {
            this.loading.fullScreen = true;
            let params = {
              usernameCAVT: this.infoGiaoVien.usernameCAVT,
              id: this.infoGiaoVien.id,
            };
            let url = constant.giaovien.upDateInfo;
            api.post(url, params).then(
                response => {
                  this.loading.fullScreen = false;
                  console.log(response)
                  if (response.data.code == 200) {
                    this.thongBao("success", "Thay đổi thông tin thành công.")
                    this.layThongTinGiaoVien();
                  } else {
                    this.thongBao("error", response.data.msg)
                  }
                }
            )
          })
          .catch(_ => {
          });
    },
    thayDoiSdt() {

      this.$confirm('Xác nhận thay đổi thông tin số điện thoại?', 'Thông báo', {
        confirmButtonText: 'Xác nhận',
        cancelButtonText: 'Hủy',
      })
          .then(_ => {
            this.loading.fullScreen = true;
            let params = {
              soDienThoai: this.infoGiaoVien.soDienThoai,
              id: this.infoGiaoVien.id,
            };
            let url = constant.giaovien.upDateInfo;
            api.post(url, params).then(
                response => {
                  this.loading.fullScreen = false;
                  console.log(response)
                  if (response.data.code == 200) {
                    this.thongBao("success", "Thay đổi thông tin số điện thoại thành công.")
                    this.layThongTinGiaoVien();
                  } else {
                    this.thongBao("error", response.data.msg)
                  }
                }
            )
          })
          .catch(_ => {
          });
    },
    showChinhSuaSMCA() {
      console.log("Chỉnh sửa updateSMCA")
      this.updateSMCA = true;
    },
    showChinhSuaSdt() {
      console.log("Chỉnh sửa sdt giáo viên")
      this.updateSdt = true;
    },
    xemAnh(e) {
      this.linkAnh = e + '?t=' + this.getTime();
      console.log(this.linkAnh)
      console.log("linkAnh")
      this.hienThiAnh = true;
    },
    getTime() {
      var d = new Date();
      var n = d.getTime();
      return n;
    },
    getWidth() {
      return Math.max(
          document.body.scrollWidth,
          document.documentElement.scrollWidth,
          document.body.offsetWidth,
          document.documentElement.offsetWidth,
          document.documentElement.clientWidth
      );
    },

    getHeight() {
      return Math.max(
          document.body.scrollHeight,
          document.documentElement.scrollHeight,
          document.body.offsetHeight,
          document.documentElement.offsetHeight,
          document.documentElement.clientHeight
      );
    },

    layChungThuSo(){
      this.loading.fullScreen = true;
      this.updateSdt = false;
      this.updateSMCA = false;
      let url = constant.giaovien.layCTS;
      this.danh_sach_chung_thu_so = [];
      api.get(url, {
        typeSign:this.infoGiaoVien.typeSign
      }).then(
          response => {
            this.loading.fullScreen = false;
            console.log("Res lấy CTS giáo viên trả về:")
            console.log(response.data.data)
            if (response.data.code == 200) {
              this.danh_sach_chung_thu_so = response.data.data.certBOS;
              let check = response.data.data.isRegistered? response.data.data.isRegistered:false;
              this.hien_thi_cap_nhat_cts = !check
            } else {
              this.thongBao("error", "Không lấy được thông tin giáo viên")
            }
          }
      )
    },
    layThongTinGiaoVien() {
      this.hien_thi_cap_nhat_cts = false;
      this.loading.fullScreen = true;
      this.updateSdt = false;
      this.updateSMCA = false;
      let url = constant.giaovien.info;
      api.get(url, {}).then(
          response => {
            this.loading.fullScreen = false;
            console.log("Res upload thông tin giáo viên trả về:")
            console.log(response)
            if (response.data.code == 200) {
              this.infoGiaoVien = response.data.data;
              console.error('Thông tin: ')
              let type = this.infoGiaoVien.typeSign;
              console.error(type)
              if (type.toString().includes('5')) {
                this.showSmartCA = true;
              }
              var d = new Date();
              var n = d.getTime();
              this.infoGiaoVien.pathChuKy = this.infoGiaoVien.pathChuKy + '?t=' + n;
              this.infoGiaoVien.linkChuKy = this.infoGiaoVien.linkChuKy + '?t=' + n;
              // this.layChungThuSo();
            } else {
              this.thongBao("error", "Không lấy được thông tin giáo viên")
            }
          }
      )
    },
    chotChuKy() {
      this.$confirm('Xác nhận?', 'Thông báo', {
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy',
      })
          .then(_ => {
            this.loading.fullScreen = true;
            let url = constant.giaovien.duyetChuKy;
            api.post(url, {}).then(
                response => {
                  this.loading.fullScreen = false;
                  console.log("Res chotChuKy giáo viên trả về:")
                  console.log(response)
                  if (response.data.code == 200) {
                    this.thongBao("success", "Chốt chữ ký thành công")
                    this.layThongTinGiaoVien();
                  } else {
                    this.thongBao("error", response.data.msg)
                  }
                }
            )
          })
          .catch(_ => {
          });
    },

    ...mapActions("teacher", [
      "duyetChuKyCacGiaoVienDaChon",
    ]),
    thongBao(t, e) {
      let msg = "";
      let cl = "";
      if (e) {
        msg = e;
      }
      let type = "success";
      if (t) {
        type = t
      }
      if (type == "success") {
        cl = "dts-noty-success"
      }
      if (type == "warning") {
        cl = "dts-noty-warning"
      }
      if (type == "error") {
        cl = "dts-noty-error"
      }
      if (type == "info") {
        cl = "dts-noty-info"
      }
      this.$message({
        customClass: cl,
        showClose: true,
        message: msg,
        type: t,
        duration: 3000
      });
    },
    HuongDanUpLoad() {
      this.show_huong_dan = true;
    },
    // ...mapActions('teacher', [
    //     "uploadFileSignature",
    // ]),
    getBase64(data) {
      this.imageSignature = data;
    },
    uploadLuon(data) {
      this.imageSignature = data;
      this.uploadSignature();
    },
    goStep(step) {
      this.step = step;
    },
    beforeUpload() {
      console.log('trước khi upload');
      this.show_huong_dan_upload = true;
    },
    uploadChuKy() {
      console.log("Upload chữ ký giáo viên:")
      this.show_huong_dan_upload = false;
      console.log(JSON.stringify(this.infoGiaoVien));
      this.step = 1;
      this.dialogUploadSignatureVisible = true
    },
    xemAnhKy() {
      this.loading.fullScreen = true;
      let url = constant.taiKhoan.xemTruocChuKyVaDau;
      api.post(url, {}).then(
          response => {
            console.log("Res upload chữ ký trả về::")
            console.log(response)
            if (response.data.code == 200) {
              console.log("***")
              console.log('xem trước ảnh ký: con dâus')
              this.linkAnh = response.data.data.data;
              this.hien_thi_anh_ky = true;
              console.log(this.linkAnh)
            } else {
              this.loading.fullScreen = false;
              this.thongBao("error", response.data.msg)
            }
            this.loading.fullScreen = false;
          }
      )
      return
      if (this.infoGiaoVien.chucVu == 1 || this.infoGiaoVien.chucVu == 2) {
        this.showChuKy = true;
      } else {
        this.linkAnh = this.infoGiaoVien.linkChuKy + '?t=' + this.getTime();
        this.hien_thi_anh_ky = true;
      }
    },
    getLinkDauNhaTruong() {
      if (this.infoGiaoVien.linkChuKyDau) {
        return this.infoGiaoVien.linkChuKyDau
      } else {
        return '/images/icon_dau_mac_dinh.png'
      }
    },
    closeDialogUpload() {
      this.step = 1;
      this.dialogUploadSignatureVisible = false;
    },

    uploadSignature() {
      if (!this.imageSignature) {
        this.thongBao('error', 'Vui lòng xoá nền ảnh trước khi xác nhận');
        return;
      }
      let data = {
        data: this.imageSignature,
        id: this.infoGiaoVien.id,
      }
      this.uploadFileSignature(data);
    },
    showThongTinChuKy() {
      if (this.infoGiaoVien.chucVu == 1 || this.infoGiaoVien.chucVu == 2) {
        this.showChuKy = true;
      }
    },
    uploadFileSignature(e) {
      console.log("Đẩy dữ liệu lên sever:")
      console.log(e)
      this.$confirm('Xác nhận tải ảnh chữ ký?', 'Thông báo', {
        confirmButtonText: 'Xác nhận',
        cancelButtonText: 'Hủy',
      })
          .then(_ => {
            console.log("Đẩy lên")
            this.dialogUploadSignatureVisible = false;
            this.loading.fullScreen = true;
            let url = constant.giaovien.uploadfile;
            api.post(url, e).then(
                response => {
                  console.log("Res upload chữ ký trả về::")
                  console.log(response)
                  if (response.data.code == 200) {
                    this.thongBao("success", "Cập nhật ảnh chữ ký thành công.")
                    console.log("Lấy lại thông tin giáo viên")
                    // this.infoGiaoVien = {};
                    this.layThongTinGiaoVien();
                    this.xemAnhKy();
                    // this.showThongTinChuKy();
                  } else {
                    this.loading.fullScreen = false;
                    this.thongBao("error", response.data.msg)
                  }
                }
            )
          })
          .catch(_ => {
          });
    }
  }
}
</script>

<style scope="scoped">
.infoAccount label {
  width: auto;
}

.img > img {
  width: 200px;
}

p {
  padding: 0;
  margin: 0;
}

.el-card__body {
  padding: 15px;
  position: relative
}

.textInfo {
  padding-left: 50px;
}

.textInfo > p {
  color: black;
}

.iconInfo {
  position: absolute
}

.iconInfo > i {
  font-size: 35px;
}

th {
  word-break: break-word;
}
</style>