<template>
    <div class="page page-request page-file" v-loading.fullscreen.lock="fullscreenLoading">
        <div class="box-upload-file">
            <el-form :rules="rulesSearch" ref="searchForm" :model="searchForm" :hide-required-asterisk="true">
                <div class="d-flex flex-wrap">
                    <div class="d-inline-block pl-3 pr-3 pb-3">
                        <h5 class="font-weight-bold m-0">Tìm kiếm yêu cầu</h5>
                    </div>
                </div>

                <el-row :gutter="24">
                    <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
                        <el-form-item class="d-inline-block" prop="education">
                            <eselect style="width:100%" disabled collapseTags v-model="searchForm.education"
                                @change="capHocChange" placeholder="Chọn" filterable :data="optionEducation"
                                :fields="['label','value']" />
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
                        <el-form-item class="d-inline-block" prop="year">
                            <eselect style="width:100%" collapseTags v-model="searchForm.year" placeholder="Chọn"
                                     @change="onYearChange"
                                filterable :data="list_nam_hoc" :fields="['name','value']" />
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
                        <el-form-item class="d-inline-block" prop="grade">
                            <eselect style="width:100%" collapseTags v-model="searchForm.grade"
                                placeholder="Chọn" filterable :data="optionGrade" :fields="['label','value']" />
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
                        <el-form-item style="width: 100%" class="d-inline-block">
                            <el-select v-model="searchForm.status" placeholder="Trạng thái">
                                <el-option v-for="item in optionStatus" :label="item.label" :key="item.value"
                                    :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
                        <el-form-item class="d-inline-block">
                            <el-button type="primary" @click="getListRequest(1)">Tìm kiếm</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <div class="file--title">
            <div class="file--title">
                <h5 class="title">Danh sách yêu cầu nộp</h5>
                <div class="" v-if="listRequestNop.total"><i>({{'Có tất cả '+listRequestNop.total+ ' yêu cầu'}})</i>
                </div>
            </div>

        </div>

        <div class="page__content">
            <div class="listNew mb-5">

                <el-table border stripe fit :lazy="true" :data="listRequestNop.list" width="100%" @selection-change=""
                    ref="listFile">
                    <el-table-column label="STT" property="title" width="50" type="index" class-name="text-center">
                    </el-table-column>
                    <el-table-column header-align="center" label="Tên học sinh" width="210" property="userNameYC">
                    </el-table-column>
                    <el-table-column header-align="center" label="Lớp" property="lop" width="60"></el-table-column>
                    <!--                    <el-table-column label="GVCN" property="tenGVCN"></el-table-column>-->
                    <el-table-column header-align="center" label="Trường đang học" property="tenTruongHS" width="">
                        <template slot-scope="scope">
                            {{scope.row.tenTruongHS}}
                            <el-popover popper-class="info-contact" placement="top-start" title="Thông tin liên hệ"
                                width="300" trigger="hover">
                                <div>
                                    <div><strong>Email: {{scope.row.emailLienHeTruongHS}}</strong></div>
                                    <div><strong>Số điện thoại: {{scope.row.sdtLienHeTruongHS}}</strong></div>
                                </div>
                                <span class="cursor-pointer" slot="reference"><span class="el-icon-info"></span></span>
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" label="Trường yêu cầu" property="tenTruongYC" width="">
                        <template slot-scope="scope">
                            {{scope.row.tenTruongYC}}
                            <el-popover popper-class="info-contact" placement="top-start" title="Thông tin liên hệ"
                                width="300" trigger="hover">
                                <div>
                                    <div><strong>Email:</strong> {{scope.row.emailLienHeTruongYC}}</div>
                                    <div><strong>Số điện thoại:</strong> {{scope.row.sdtLienHeTruongYC}}</div>
                                </div>
                                <span class="cursor-pointer" slot="reference"><span class="el-icon-info"></span></span>
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" label="Trạng thái trường đang học"
                        property="moTaTrangThaiTruongHS" width="130"></el-table-column>
                    <el-table-column header-align="center" label="Trạng thái trường yêu cầu"
                        property="moTaTrangThaiTruongYC" width="130"></el-table-column>
                    <el-table-column header-align="center" label="Ngày yêu cầu" width="120">
                        <template slot-scope="scope">
                            {{ new Date(scope.row.createdDate) | moment("DD/MM/YYYY") }}
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" label="Hành động" class-name="" width="200">
                        <template slot-scope="scope">
                            <el-tooltip content="Xem học bạ" placement="top">
                                <el-button size="small" @click="viewFile(scope.row.idHocBa)" class="mb-1" type="info"
                                    icon="ion ion-md-eye" circle></el-button>
                            </el-tooltip>
                            <el-tooltip content="Chấp thuận " placement="top">
                                <el-button size="small" @click="accept(scope.row)" class="mb-1" type="primary"
                                    icon="el-icon-check" circle></el-button>
                            </el-tooltip>
                            <el-tooltip content="Từ chối " placement="top">
                                <el-button size="small" @click="reject(scope.row)" class="mb-1" type="danger"
                                    icon="el-icon-minus" circle></el-button>
                            </el-tooltip>
                            <el-tooltip v-if="scope.row.trangThaiXLTruongYC == 1" content="Phân lớp " placement="top">
                                <el-button @click="openDialogPhanLop(scope.row)" size="small" class="mb-1"
                                    type="success" icon="el-icon-s-claim" circle></el-button>
                            </el-tooltip>

                        </template>
                    </el-table-column>
                </el-table>
                <div class="text-right mt-2">
                    <pagination v-if="listRequestNop.total" :page-count="getTotalPage(listRequestNop.total)"
                        :click-handler="getListRequest" :prev-text="'Trước'" :next-text="'Sau'"
                        :container-class="'el-pager'" :pageClass="'number'">
                        >
                    </pagination>
                </div>
            </div>

        </div>

        <el-dialog class="popupChuyenTruong" :title="popUp.title" :visible.sync="popupRequest" center>
            <div>
                <el-form v-model="formRequest" label-position="right" label-width="180px">
                    <el-form-item label="Ghi chú">
                        <el-input type="textarea" v-model="formRequest.ghiChu"></el-input>
                        <div v-if="errorMessage.ghiChu" class="el-form-item__error">
                            {{errorMessage.ghiChu}}
                        </div>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closePopup">Thoát</el-button>
                <el-button type="primary" @click.prevent="confirmRut">{{this.popUp.btnText}}</el-button>
            </span>
        </el-dialog>

        <phan-lop :selectItem="selectItem" :showDialogPhanLop="showDialogPhanLop" @closeModal="closeDialogPhanLop">
        </phan-lop>
    </div>
</template>

<script>
    import {
        mapActions,
        mapState
    } from 'vuex';
    import Pagination from "../components/Pagination";
    import dialog from "../dialog";
    import PhanLop from "../components/PhanLop";
    import SelectNamHoc from "../components/SelectNamHoc";
    import ChonKhoiHoc from "../components/ChonKhoiHoc";
    import ESelectVue from "./Ui/ESelect";

    export default {
        name: "DanhSachYeuCauNop",
        metaInfo: {
            title: 'Danh sách yêu cầu nộp',
            meta: [{
                vmid: 'description',
                name: 'description',
                content: ''
            }]
        },
        components: {
            Pagination,
            PhanLop,
            ChonKhoiHoc,

            'eselect': ESelectVue,
            SelectNamHoc
        },

        computed: {
            ...mapState('teacher', [
                "listRequestNop",
                "requestStatus"
            ]),
            ...mapState('account', [
                'user',
                'list_nam_hoc'
            ]),
            ...mapState("hocba", [
                "fileDetail",
                "fileStatus"
            ])
        },

        methods: {

            ...mapActions('teacher', [
                "getListRequestNop",
                "acceptRequestNop",
                "rejectRequestNop",
                "clear"
            ]),
            ...mapActions('until', [
                "getProvince",
                "getListTruong",
            ]),
            ...mapActions("hocba", [
                "getFile"
            ]),

            viewFile(id) {
                this.fullscreenLoading = true;
                this.getFile({
                    maHocBa: id
                })
            },
            onYearChange(){
                this.searchForm.student = '';
                this.searchForm.status = '';
                this.searchForm.grade = ''
            },
            getCurrentMonth() {
                let d = new Date();
                return d.getMonth();
            },
            capHocChange() {
                this.searchForm.student = '';
                this.searchForm.status = '';
                this.searchForm.grade = ''
                if (!this.searchForm.education) {
                    this.optionGrade = this.khoiCap1.concat(this.khoiCap2, this.khoiCap3)
                    return;
                } else {
                    if (this.searchForm.education == 1) {
                        this.optionGrade = this.khoiCap1;
                    } else if (this.searchForm.education == 2) {
                        this.optionGrade = this.khoiCap2;
                    } else if (this.searchForm.education == 3) {
                        this.optionGrade = this.khoiCap3;
                    } else if (this.searchForm.education == 12) {
                        this.optionGrade = this.khoiCap1.concat(this.khoiCap2)
                    } else if (this.searchForm.education == 23) {
                        this.optionGrade = this.khoiCap2.concat(this.khoiCap3)
                    } else {
                        this.optionGrade = this.khoiCap1.concat(this.khoiCap2, this.khoiCap3)
                    }
                }
            },

            getTotalPage(number) {
                return Math.ceil(number / (this.paginate.limit));
            },

            getListRequest(select) {
                let start = (select - 1) * this.paginate.limit ? (select - 1) * this.paginate.limit : 0;
                this.paginate.start = start
                this.getListRequestNop({
                    start: start,
                    khoiHoc: this.searchForm.grade,
                    limit: this.paginate.limit,
                    status: this.searchForm.status
                })
            },
            accept(dataSelect) {
                this.dataSelect = dataSelect
                this.errorMessage.ghiChu = '';
                this.formRequest.ghiChu = '';
                this.popUp = {
                    title: 'Tiếp nhận chuyển trường',
                    btnText: 'Tiếp nhận'
                }
                this.requestAccept = 1;
                this.popupRequest = true;
            },
            reject(dataSelect) {
                this.dataSelect = dataSelect
                this.errorMessage.ghiChu = '';
                this.formRequest.ghiChu = '';
                this.popUp = {
                    title: 'Từ chối tiếp nhận chuyển trường',
                    btnText: 'Từ chối'
                }
                this.requestAccept = 2;
                this.popupRequest = true;
            },
            closePopup() {
                this.formRequest.ghiChu = '';
                this.popupRequest = false;
                this.errorMessage.ghiChu = '';
            },
            confirmRut() {

                if (this.requestAccept == 1) {
                    this.acceptRequestNop({
                        id: this.dataSelect.id,
                        ghiChu: this.formRequest.ghiChu,
                        trangThai: this.requestAccept
                    });
                } else if (this.requestAccept == 2) {
                    if (!this.formRequest.ghiChu) {
                        this.errorMessage.ghiChu = 'Chưa thêm ghi chú'
                        return;
                    }

                    this.rejectRequestNop({
                        id: this.dataSelect.id,
                        ghiChu: this.formRequest.ghiChu,
                        trangThai: this.requestAccept
                    });
                }
            },
            onHide() {
                this.clear();
                this.getListRequest();
                this.popupRequest = false;
            },
            getKhoiHoc() {
                if (this.user.capHoc == 1) {
                    this.optionGrade = this.khoiCap1;
                } else if (this.user.capHoc == 2) {
                    this.optionGrade = this.khoiCap2;
                } else if (this.user.capHoc == 3) {
                    this.optionGrade = this.khoiCap3;
                } else {
                    this.optionGrade = this.khoiCap1.concat(this.khoiCap2, this.khoiCap3)
                }
            },
            openFancyBox(url) {
                $.fancybox.open({
                    src: url,
                    type: 'iframe',
                });
            },
            openDialogPhanLop(item) {
                this.selectItem = item;
                this.showDialogPhanLop = true;
            },
            closeDialogPhanLop() {
                this.showDialogPhanLop = false;
            },
            getFullYear() {
                let d = new Date();
                return d.getFullYear();
            },
        },

        data() {
            return {
                selectItem: null,
                showDialogPhanLop: false,
                fullscreenLoading: false,
                errorMessage: {
                    ghiChu: ''
                },
                dataSelect: '',
                formRequest: {
                    id: '',
                    ghiChu: '',

                },
                popUp: {
                    title: '',
                    btnText: '',
                },
                popupRequest: false,
                requestAccept: 0,
                paginate: {
                    start: 0,
                    limit: 20,
                },
                optionEducation: [{
                        value: 1,
                        label: 'Cấp 1'
                    },
                    {
                        value: 2,
                        label: 'Cấp 2'
                    },
                    {
                        value: 3,
                        label: 'Cấp 3'
                    },
          {value: 6,label: 'Trung tâm GDTX'},
          {value: 15,label: 'Liên cấp'},
          {value: 145,label: 'Liên cấp'},
          {value: 14,label: 'Liên cấp'},
          {value: 13,label: 'Liên cấp'},
          {value: 451,label: 'Liên cấp'},
          {value: 23,label: 'Liên cấp'},
          {value: 236,label: 'Liên cấp'},
          {value: 36,label: 'Liên cấp'},
          {value: 452,label: 'Liên cấp'},
          {value: 1245,label: 'Liên cấp'},
          {value: 12,label: 'Liên cấp'},
          {value: 123,label: 'Liên cấp'},
          {value: 12345,label: 'Liên cấp'},
          {value: 1235,label: 'Liên cấp'},
                ],
                optionGrade: [],
                optionGradeDefault: [{
                    value: '',
                    label: 'Chọn khối'
                }, ],
                khoiCap1: [{
                        value: 1,
                        label: 'Khối 1'
                    },
                    {
                        value: 2,
                        label: 'Khối 2'
                    },
                    {
                        value: 3,
                        label: 'Khối 3'
                    },
                    {
                        value: 4,
                        label: 'Khối 4'
                    },
                    {
                        value: 5,
                        label: 'Khối 5'
                    },
                ],
                khoiCap2: [{
                        value: 6,
                        label: 'Khối 6'
                    },
                    {
                        value: 7,
                        label: 'Khối 7'
                    },
                    {
                        value: 8,
                        label: 'Khối 8'
                    },
                    {
                        value: 9,
                        label: 'Khối 9'
                    },
                ],
                khoiCap3: [{
                        value: 10,
                        label: 'Khối 10'
                    },
                    {
                        value: 11,
                        label: 'Khối 11'
                    },
                    {
                        value: 12,
                        label: 'Khối 12'
                    },

                ],
                optionClass: [{
                        value: '',
                        label: 'Chọn lớp'
                    },
                    {
                        value: 1,
                        label: 'Lớp 1'
                    },
                    {
                        value: 2,
                        label: 'Lớp 2'
                    },
                    {
                        value: 3,
                        label: 'Lớp 3'
                    },
                ],
                optionStudent: [{
                        value: '',
                        label: 'Học sinh'
                    },
                    {
                        value: 1,
                        label: 'HS 1'
                    },
                    {
                        value: 2,
                        label: 'HS 2'
                    },
                    {
                        value: 3,
                        label: 'HS 3'
                    },
                ],
                optionStatus: [{
                        value: '',
                        label: 'Chọn trạng thái'
                    },
                    {
                        value: 0,
                        label: 'Chờ xác nhận'
                    },
                    {
                        value: 1,
                        label: 'Đồng ý'
                    },
                    {
                        value: 2,
                        label: 'Từ chối'
                    },
                ],
                searchForm: {
                    year: '',
                    education: '',
                    grade: '',
                    class: '',
                    student: '',
                    status: '',
                },
                rulesSearch: {
                    year: [
                        // {required : true, message : 'Bạn chưa chọn năm học',trigger: 'blur'}
                        {
                            required: true,
                            message: 'Bạn chưa chọn năm học',
                            trigger: 'change'
                        }
                    ],
                    education: [{
                        required: true,
                        message: 'Bạn chưa chọn cấp học',
                        trigger: 'change'
                    }],
                    grade: [{
                        required: true,
                        message: 'Bạn chưa chọn khối học',
                        trigger: 'change'
                    }],
                    class: [{
                        required: true,
                        message: 'Bạn chưa chọn lớp học',
                        trigger: 'change'
                    }],
                    student: [{
                        required: true,
                        message: 'Bạn chưa chọn học sinh',
                        trigger: 'change'
                    }],
                    status: [{
                        required: true,
                        message: 'Bạn chưa chọn trạng thái',
                        trigger: 'change'
                    }]
                },

            }
        },

        beforeMount() {
            this.searchForm.year = this.getFullYear();
            let currentMonth = this.getCurrentMonth()+1;
            if (currentMonth >= 9) {} else {
                this.searchForm.year -= 1;
            }

            this.getListRequest()
        },
        mounted() {
            // this.fullscreenLoading = true;
            this.searchForm.education = this.user.capHoc;
            this.optionGrade = this.khoiCap1.concat(this.khoiCap2, this.khoiCap3);
            this.getKhoiHoc();
        },
        watch: {
            requestStatus(val) {
                if (val) {
                    dialog.showDialog('Thông báo', val, this.onHide());
                }
            },
            fileDetail(val) {
                if (this.fileStatus == 2) {
                    this.fullscreenLoading = false;
                    let linkPfd = this.fileDetail + '#toolbar=0';
                    this.openFancyBox(linkPfd)
                }
                if (this.fileStatus == 1) {
                    this.fullscreenLoading = false;
                    dialog.showDialog('Thông báo', this.fileDetail)
                }
            },
        }
    }
</script>

<style scoped>
    .btn__table_action button {
        padding: 8px 10px;
    }
</style>