var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading.fullscreen.lock",
            value: _vm.loadingScreen,
            expression: "loadingScreen",
            modifiers: { fullscreen: true, lock: true },
          },
        ],
        staticClass:
          "page__title d-flex align-items-center justify-content-between",
      },
      [_c("h1", { staticClass: "title" }, [_vm._v("Tổng quan")])]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "box-upload-file" },
      [
        _c(
          "el-row",
          { attrs: { gutter: 24 } },
          [
            _c(
              "el-col",
              { attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 } },
              [
                _c("label", [_vm._v("Năm học")]),
                _vm._v(" "),
                _c("eselect", {
                  staticStyle: { width: "100%" },
                  attrs: {
                    collapseTags: "",
                    placeholder: "Chọn",
                    filterable: "",
                    data: _vm.list_nam_hoc,
                    fields: ["name", "value"],
                  },
                  model: {
                    value: _vm.dataSearch.namHoc,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataSearch, "namHoc", $$v)
                    },
                    expression: "dataSearch.namHoc",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.user.role != 4
              ? _c(
                  "el-col",
                  { attrs: { xs: 12, sm: 8, md: 6, lg: 6, xl: 6 } },
                  [
                    _c("label", [_vm._v("Đơn vị")]),
                    _vm._v(" "),
                    _c("eselect", {
                      staticStyle: { width: "100%" },
                      attrs: {
                        disabled: _vm.user.role != 7,
                        collapseTags: "",
                        placeholder: "Chọn",
                        filterable: "",
                        data: _vm.list_don_vi,
                        fields: ["name", "value"],
                      },
                      on: {
                        change: function ($event) {
                          return _vm.chonDonVi()
                        },
                      },
                      model: {
                        value: _vm.dataSearch.donVi,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataSearch, "donVi", $$v)
                        },
                        expression: "dataSearch.donVi",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "el-col",
              { attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 } },
              [
                _c("label", [_vm._v("Cấp học")]),
                _vm._v(" "),
                _c("eselect", {
                  staticStyle: { width: "100%" },
                  attrs: {
                    disabled: _vm.list_cap_hoc.length == 1,
                    collapseTags: "",
                    placeholder: "Chọn",
                    filterable: "",
                    data: _vm.list_cap_hoc,
                    fields: ["name", "value"],
                  },
                  on: {
                    change: function ($event) {
                      return _vm.chonDonVi()
                    },
                  },
                  model: {
                    value: _vm.dataSearch.capHoc,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataSearch, "capHoc", $$v)
                    },
                    expression: "dataSearch.capHoc",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.user.role != 4
              ? _c(
                  "el-col",
                  { attrs: { xs: 12, sm: 8, md: 6, lg: 6, xl: 6 } },
                  [
                    _c("label", [_vm._v("Trường học")]),
                    _vm._v(" "),
                    _c("eselect", {
                      staticStyle: { width: "100%" },
                      attrs: {
                        collapseTags: "",
                        placeholder: "Chọn",
                        filterable: "",
                        data: _vm.list_truong_hoc,
                        fields: ["name", "value"],
                      },
                      model: {
                        value: _vm.dataSearch.truongHoc,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataSearch, "truongHoc", $$v)
                        },
                        expression: "dataSearch.truongHoc",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "el-col",
              { attrs: { span: 6 } },
              [
                _c("div", { staticStyle: { color: "transparent" } }, [
                  _vm._v("Tìm kiếm"),
                ]),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticClass: "m-0",
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "p",
                            undefined,
                            $event.key,
                            undefined
                          )
                        ) {
                          return null
                        }
                        $event.preventDefault()
                        return _vm.getAllDataChart()
                      },
                    },
                  },
                  [_vm._v("Tìm kiếm")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticClass: "m-0",
                    attrs: { type: "success" },
                    on: {
                      click: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "p",
                            undefined,
                            $event.key,
                            undefined
                          )
                        ) {
                          return null
                        }
                        $event.preventDefault()
                        return _vm.tongHopBaoCao()
                      },
                    },
                  },
                  [_vm._v("Tổng hợp báo cáo")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "page__header" },
      [
        _c(
          "el-row",
          { attrs: { gutter: 24 } },
          [
            _c("el-col", { attrs: { span: 24 } }, [
              _c("h4", { staticStyle: { color: "#102e6a", margin: "0" } }, [
                _vm._v("Biểu đồ cập nhật dữ liệu"),
              ]),
            ]),
            _vm._v(" "),
            _c("el-col", { attrs: { sm: 12, md: 12, lg: 12 } }, [
              _c("div", { staticClass: "grid-content" }, [
                _c(
                  "div",
                  { staticClass: "dts-moibieudo grid-content bg-purple-light" },
                  [
                    _c("div", { staticClass: "dts-card-header" }, [
                      _c("span", { staticClass: "dts-card-title" }, [
                        _vm._v("Trạng thái cập nhật dữ liệu học bạ"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "dts-right" },
                        [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: "Lấy lại dữ liệu",
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.getTrangThaiCapNhatDuLieuHocBa(
                                        $event
                                      )
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "el-icon-refresh" })]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading.trangThaiCapNhatDuLieuHocBa,
                            expression: "loading.trangThaiCapNhatDuLieuHocBa",
                          },
                        ],
                        staticClass: "dts-card-body",
                        attrs: {
                          "element-loading-text": "Loading...",
                          "element-loading-spinner": "el-icon-loading",
                          "element-loading-background":
                            "rgba(255, 255, 255, 0.8)",
                        },
                      },
                      [
                        _vm.duLieu.trangThaiCapNhatDuLieuHocBa.du_lieu
                          ? _c(
                              "div",
                              [
                                _c("BieuDoCotDistributed", {
                                  attrs: {
                                    dulieu:
                                      _vm.duLieu.trangThaiCapNhatDuLieuHocBa,
                                  },
                                }),
                              ],
                              1
                            )
                          : _c("div", { staticClass: "text-center" }, [
                              _c("p", [_vm._v("Biểu đồ chưa có dữ liệu")]),
                            ]),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("el-col", { attrs: { sm: 12, md: 12, lg: 12 } }, [
              _c("div", { staticClass: "grid-content" }, [
                _c(
                  "div",
                  { staticClass: "dts-moibieudo grid-content bg-purple-light" },
                  [
                    _c("div", { staticClass: "dts-card-header" }, [
                      _c("span", { staticClass: "dts-card-title" }, [
                        _vm._v("Trạng thái chốt dữ liệu học bạ"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "dts-right" },
                        [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: "Lấy lại dữ liệu",
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.getTrangThaiChotDuLieuHocBa(
                                        $event
                                      )
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "el-icon-refresh" })]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading.trangThaiChotDuLieuHocBa,
                            expression: "loading.trangThaiChotDuLieuHocBa",
                          },
                        ],
                        staticClass: "dts-card-body",
                        attrs: {
                          "element-loading-text": "Loading...",
                          "element-loading-spinner": "el-icon-loading",
                          "element-loading-background":
                            "rgba(255, 255, 255, 0.8)",
                        },
                      },
                      [
                        _vm.duLieu.trangThaiChotDuLieuHocBa.du_lieu
                          ? _c(
                              "div",
                              [
                                _c("BieuDoCotDistributed", {
                                  attrs: {
                                    dulieu: _vm.duLieu.trangThaiChotDuLieuHocBa,
                                  },
                                }),
                              ],
                              1
                            )
                          : _c("div", { staticClass: "text-center" }, [
                              _c("p", [_vm._v("Biểu đồ chưa có dữ liệu")]),
                            ]),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "el-col",
              { staticClass: "mt-5", attrs: { sm: 12, md: 12, lg: 12 } },
              [
                _c("div", { staticClass: "grid-content" }, [
                  _c(
                    "div",
                    {
                      staticClass: "dts-moibieudo grid-content bg-purple-light",
                    },
                    [
                      _c("div", { staticClass: "dts-card-header" }, [
                        _c("span", { staticClass: "dts-card-title" }, [
                          _vm._v("Trạng thái đăng tải chữ ký"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "dts-right" },
                          [
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  content: "Lấy lại dữ liệu",
                                  placement: "top",
                                },
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.getTrangThaiDangTaiChuKy(
                                          $event
                                        )
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "el-icon-refresh" })]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loading.trangThaiDangTaiChuKy,
                              expression: "loading.trangThaiDangTaiChuKy",
                            },
                          ],
                          staticClass: "dts-card-body",
                          attrs: {
                            "element-loading-text": "Loading...",
                            "element-loading-spinner": "el-icon-loading",
                            "element-loading-background":
                              "rgba(255, 255, 255, 0.8)",
                          },
                        },
                        [
                          _vm.duLieu.trangThaiDangTaiChuKy.du_lieu
                            ? _c(
                                "div",
                                [
                                  _c("BieuDoCotDistributed", {
                                    attrs: {
                                      dulieu: _vm.duLieu.trangThaiDangTaiChuKy,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _c("div", { staticClass: "text-center" }, [
                                _c("p", [_vm._v("Biểu đồ chưa có dữ liệu")]),
                              ]),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("el-col", { staticClass: "mt-5", attrs: { span: 24 } }, [
              _c("h4", { staticStyle: { color: "#102e6a", margin: "0" } }, [
                _vm._v("Biểu đồ ký học bạ"),
              ]),
            ]),
            _vm._v(" "),
            _vm.dataSearch.capHoc != 1
              ? _c("el-col", { attrs: { sm: 12, md: 12, lg: 12 } }, [
                  _c("div", { staticClass: "grid-content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "dts-moibieudo grid-content bg-purple-light",
                      },
                      [
                        _c("div", { staticClass: "dts-card-header" }, [
                          _c("span", { staticClass: "dts-card-title" }, [
                            _vm._v("Tỷ lệ giáo viên bộ môn ký học bạ"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "dts-right" },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    content: "Lấy lại dữ liệu",
                                    placement: "top",
                                  },
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.getTyLeGiaoVienBoMonkyHocBa(
                                            $event
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-refresh",
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loading.tyLeGiaoVienBoMonKyHocBa,
                                expression: "loading.tyLeGiaoVienBoMonKyHocBa",
                              },
                            ],
                            staticClass: "dts-card-body",
                            attrs: {
                              "element-loading-text": "Loading...",
                              "element-loading-spinner": "el-icon-loading",
                              "element-loading-background":
                                "rgba(255, 255, 255, 0.8)",
                            },
                          },
                          [
                            _vm.duLieu.tyLeGiaoVienBoMonKyHocBa.du_lieu
                              ? _c(
                                  "div",
                                  [
                                    _c("BieuDoCotDistributedSw", {
                                      attrs: {
                                        dulieu:
                                          _vm.duLieu.tyLeGiaoVienBoMonKyHocBa,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _c("div", { staticClass: "text-center" }, [
                                  _c("p", [_vm._v("Biểu đồ chưa có dữ liệu")]),
                                ]),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("el-col", { attrs: { sm: 12, md: 12, lg: 12 } }, [
              _c("div", { staticClass: "grid-content" }, [
                _c(
                  "div",
                  { staticClass: "dts-moibieudo grid-content bg-purple-light" },
                  [
                    _c("div", { staticClass: "dts-card-header" }, [
                      _c("span", { staticClass: "dts-card-title" }, [
                        _vm._v("Số lượng học bạ ký theo giáo viên chủ nhiệm"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "dts-right" },
                        [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: "Lấy lại dữ liệu",
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.getSoLuongHocBaKyTheoGvcn(
                                        $event
                                      )
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "el-icon-refresh" })]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading.soLuongHocBaKyTheoGvcn,
                            expression: "loading.soLuongHocBaKyTheoGvcn",
                          },
                        ],
                        staticClass: "dts-card-body",
                        attrs: {
                          "element-loading-text": "Loading...",
                          "element-loading-spinner": "el-icon-loading",
                          "element-loading-background":
                            "rgba(255, 255, 255, 0.8)",
                        },
                      },
                      [
                        _vm.duLieu.soLuongHocBaKyTheoGvcn.du_lieu
                          ? _c(
                              "div",
                              [
                                _c("BieuDoCotDistributedSw", {
                                  attrs: {
                                    dulieu: _vm.duLieu.soLuongHocBaKyTheoGvcn,
                                  },
                                }),
                              ],
                              1
                            )
                          : _c("div", { staticClass: "text-center" }, [
                              _c("p", [_vm._v("Biểu đồ chưa có dữ liệu")]),
                            ]),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "el-col",
              {
                class: { "mt-5": _vm.dataSearch.capHoc != 1 },
                attrs: { sm: 12, md: 12, lg: 12 },
              },
              [
                _c("div", { staticClass: "grid-content" }, [
                  _c(
                    "div",
                    {
                      staticClass: "dts-moibieudo grid-content bg-purple-light",
                    },
                    [
                      _c("div", { staticClass: "dts-card-header" }, [
                        _c("span", { staticClass: "dts-card-title" }, [
                          _vm._v("Số lượng học bạ ký theo Hiệu trưởng"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "dts-right" },
                          [
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  content: "Lấy lại dữ liệu",
                                  placement: "top",
                                },
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.getSoLuongHocBaKyTheoHieuTruong(
                                          $event
                                        )
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "el-icon-refresh" })]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loading.soLuongHocBaKyTheoHieuTruong,
                              expression:
                                "loading.soLuongHocBaKyTheoHieuTruong",
                            },
                          ],
                          staticClass: "dts-card-body",
                          attrs: {
                            "element-loading-text": "Loading...",
                            "element-loading-spinner": "el-icon-loading",
                            "element-loading-background":
                              "rgba(255, 255, 255, 0.8)",
                          },
                        },
                        [
                          _vm.duLieu.soLuongHocBaKyTheoHieuTruong.du_lieu
                            ? _c(
                                "div",
                                [
                                  _c("BieuDoCotDistributedSw", {
                                    attrs: {
                                      dulieu:
                                        _vm.duLieu.soLuongHocBaKyTheoHieuTruong,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _c("div", { staticClass: "text-center" }, [
                                _c("p", [_vm._v("Biểu đồ chưa có dữ liệu")]),
                              ]),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "page__container" },
      [
        _c(
          "el-dialog",
          {
            attrs: {
              title: "Biểu đồ chi tiết",
              top: "5vh",
              center: "",
              "custom-class": "thongTinLopHoc",
              visible: _vm.show_bieu_do_chi_tiet,
            },
            on: {
              "update:visible": function ($event) {
                _vm.show_bieu_do_chi_tiet = $event
              },
            },
          },
          [
            _c(
              "el-row",
              { attrs: { gutter: 24 } },
              [
                _c("el-col", { attrs: { span: 24 } }, [
                  _c("div", { staticClass: "grid-content" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "dts-moibieudo grid-content bg-purple-light",
                      },
                      [
                        _c("div", { staticClass: "dts-card-header" }, [
                          _c("span", { staticClass: "dts-card-title" }, [
                            _vm._v(
                              "Biểu đồ chi tiết trạng thái cập nhật học bạ "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "dts-right" },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    content: "Lấy lại dữ liệu",
                                    placement: "top",
                                  },
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.getTrangThaiDangTaiChuKy()
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-refresh",
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loading.trangThaiDangTaiChuKy,
                                expression: "loading.trangThaiDangTaiChuKy",
                              },
                            ],
                            staticClass: "dts-card-body",
                            attrs: {
                              "element-loading-text": "Loading...",
                              "element-loading-spinner": "el-icon-loading",
                              "element-loading-background":
                                "rgba(255, 255, 255, 0.8)",
                            },
                          },
                          [
                            _c(
                              "div",
                              [
                                _c("BieuDoCotSw", {
                                  attrs: {
                                    dulieu: _vm.duLieu.trangThaiDangTaiChuKy,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }