<template>
    <div>
<!--        <div class="text-center"><h4>{{name || 'Tên báo cáo'}}</h4></div>-->
<!--        <div v-if="!dataView" class="text-center">Chưa có dữ liệu báo cáo</div>-->
        <table border="1">
            <thead>
            <tr v-for="(row, rowIndex) in headerData" :key="`header-${rowIndex}`">
                <th
                        v-for="(colIndex) in Array.from({ length: dataLength }, (_, index) => index)"
                        :data-info="`header-cell-${rowIndex}-${colIndex}`"
                        :data-rowspan="getRowSpan(rowIndex, colIndex)"
                        :key="`header-cell-${rowIndex}-${colIndex}`"
                        :colspan="getColSpan(rowIndex, colIndex)"
                        :rowspan="getRowSpan(rowIndex, colIndex)"
                        v-if="!isMergedCell(rowIndex, colIndex) && !isMergedRow(rowIndex, colIndex)"
                >
                    {{ row[colIndex] || '' }}
                </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(row, rowIndex) in tableData" :key="`row-${rowIndex}`">
                <td

                        v-for="(colIndex) in Array.from({ length: dataLength }, (_, index) => index)"
                        :key="`cell-${rowIndex}-${colIndex}`"
                        :colspan="getColSpan(rowIndex + headerRows, colIndex)"
                        :rowspan="getRowSpan(rowIndex + headerRows, colIndex)"
                        v-if="!isMergedCell(rowIndex + headerRows, colIndex) && !isMergedRow(rowIndex + headerRows, colIndex)"
                >
                    {{ row[colIndex] || '' }}
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    props:['dataView','name'],
    watch:{
        dataView(val){
            if (val){
                console.log('data', val);
                this.data = val.data
                this.headerRows = val.data[0].header.length
                this.merge = val.merge || []
                this.dataLength = 0;
                if (this.data[0].success && this.data[0].success.length){
                    this.data[0].success.forEach(item=>{
                        if (item.length && item.length > this.dataLength) this.dataLength = item.length
                    })
                }
                // this.merge = []
            }
        }
    },
    data() {
        return {

            headerRows: 2, // Số hàng tiêu đề
            dataLength: 10, // Số hàng tiêu đề
            data: null,
            merge: [

            ]
        };
    },
    computed: {
        headerData() {
            if (this.data && this.data[0]){
                return this.data[0].header.map((row, rowIndex) => {
                    return row.map((cell, colIndex) => {
                        return cell;
                    });
                });
            }
            return  []

        },
        tableData() {
            if (this.data && this.data[0]){
                return this.data[0].success;
            }
            return  []

        }
    },
    methods: {
        isMergedCell(rowIndex, colIndex) {
            return this.merge.some(
                merge =>
                    rowIndex == ( merge.firstRow - 1) &&
                    rowIndex == (merge.lastRow - 1) &&
                    colIndex > merge.firstCol &&
                    colIndex <= merge.lastCol
            );
        },
        isMergedRow(rowIndex, colIndex) {
            return this.merge.some(
                merge =>
                    rowIndex > ( merge.firstRow - 1) &&
                    rowIndex <= (merge.lastRow - 1) &&
                    colIndex == merge.firstCol &&
                    colIndex == merge.lastCol
            );
        },
        getColSpan(rowIndex, colIndex) {
            for (const merge of this.merge) {
                if (rowIndex === (merge.firstRow - 1) && (rowIndex === (merge.lastRow - 1)) && colIndex === merge.firstCol) {
                    return merge.lastCol - merge.firstCol + 1;
                }
            }
            return 1;
        },
        getRowSpan(rowIndex, colIndex) {
            for (const merge of this.merge) {
                if (rowIndex === (merge.firstRow - 1) && colIndex === merge.firstCol) {
                    return merge.lastRow - merge.firstRow + 1;
                }
            }
            return 1;
        }
    }
};
</script>

<style>
table {
    width: 100%;
    border-collapse: collapse;
}
th, td {
    padding: 8px;
    text-align: center;
}
</style>
