export default {
    hocsinh: {
        getHocBa: '/hocbadientu-service/api/hoc-sinh/danh-sach-hoc-ba',
        request: '/hocbadientu-service/api/hoc-sinh/yeu-cau',
        listRequest: '/hocbadientu-service/api/hoc-sinh/danh-sach-yeu-cau',
        getListHS: '/hocbadientu-service/api/hoc-sinh/danh-sach-hoc-sinh',
        // getListHSNopHoSo: '/hocbadientu-service/api/hoc-sinh/danh-sach-hoc-sinh-nop-ho-so',
        getListHSNopHoSo: '/hocbadientu-sync-service/api/v2/edu/profile/danh-sach-hoc-sinh-nop-ho-so',
        getListHSKetChuyen: '/hocbadientu-service/api/hoc-sinh/danh-sach-hoc-sinh-ket-chuyen-cua-lop',
        getListLop: '/hocbadientu-service/api/giao-vien/danh-sach-lop',
        getListSoDiem: '/hocbadientu-view-service/api/so-diem/xem-so-diem-theo-ma-lop',
        chotSoDiem: '/hocbadientu-view-service/api/so-diem/chot-so-diem-theo-ma-lop',
        huyChotSoDiem: '/hocbadientu-view-service/api/so-diem/huy-chot-so-diem-theo-ma-lop',
        getListLopGvcn: '/hocbadientu-service/api/giao-vien/danh-sach-lop-hoc-gvcn',
        getListHocSinh: '/hocbadientu-service/api/hoc-sinh/thong-tin-hoc-sinh',
        listHocBa: '/hocbadientu-service/api/hoc-sinh/lay-hoc-ba',
        info: '/hocbadientu-service/api/hoc-sinh/thong-tin-ca-nhan',
        danhSachHocSinhTheoLop: '/hocbadientu-service/api/hoc-sinh/danh-sach-hoc-sinh-theo-lop',
        xemHocBaHocSinh: '/hocbadientu-view-service/api/hoc-ba/xem-hoc-ba-theo-ma-lop',
        uploadAvatar: '/hocbadientu-service/api/hoc-sinh/image',
        removeAvatar: '/hocbadientu-service/api/hoc-sinh/remove-image',
        uploadMultile: '/hocbadientu-service/api/hoc-sinh/images',
        uploadMultiFileKQHTPdf: '/hocbadientu-service/api/hoc-ba/upload/files',
        uploadMultiFileBiaPdf: '/hocbadientu-service/api/hoc-ba/upload/file-bias',
        uploadScanBiaHocBa: '/api/du-lieu-hoc-ba/du-lieu-hoc-ba-scan',
        getHashUpLoadFileScan: '/hocbadientu-sync-service/api/du-lieu-hoc-ba/get-hash',
        uploadDuLieuMauScan: '/hocbadientu-sync-service/api/du-lieu-hoc-ba/imp-du-lieu-hoc-ba',
        suaTrangThai: '/hocbadientu-service/api/hoc-sinh/chinh-sua-trang-thai-hoc-sinh',
        capNhatNgayKyBia: '/hocbadientu-service/api/hoc-sinh/cap-nhat-thoi-gian-vao-truong',
        xoaDuLieuHocSinh:'/hocbadientu-service/api/hoc-sinh/xoa-du-lieu-hoc-sinh',
        downLoadTemplateMauHocSinhScan:'/hocbadientu-sync-service/api/du-lieu-hoc-ba/tai-du-lieu-mau',
    },
    bieudo: {
        trangThaiTheoLop: '/hocbadientu-service/api/giao-vien/thong-ke-theo-giao-vien',
        trangThaiTheoGiaoVien: '/hocbadientu-service/api/giao-vien/thong-ke-trang-thai-gvcn',
        trangThaiTheoMonHoc: '/hocbadientu-service/api/giao-vien/thong-ke-theo-mon-hoc',
        trangThaiTheoLopMonHoc: '/hocbadientu-service/api/giao-vien/thong-ke-theo-lop-hoc',
        tongQuan: '/hocbadientu-service/api/giao-vien/thong-ke-tong-quan',
        tongQuanHieuTruong: '/hocbadientu-service/api/giao-vien/thong-ke-trang-thai-hieu-truong',
        trangThaiHocBaTheoLop: '/hocbadientu-service/api/giao-vien/thong-ke-chi-tiet-trang-thai-hieu-truong',
        chiTietTrangThaiHocBaTheoGVCN: '/hocbadientu-service/api/giao-vien/thong-ke-chi-tiet-hoc-ba-hoc-sinh',
        hocBaHieuTruong: '/hocbadientu-service/api/giao-vien/thong-ke-hoc-ba-hoc-sinh',
        hocBaGvcn: '/hocbadientu-service/api/giao-vien/thong-ke-hb-hs-theo-gvcn',
        trangThaiHocBaHocSinhTheoCap: '/hocbadientu-service/api/so-giao-duc/thong-ke-hoc-ba-hoc-sinh',
        trangThaiHocBaHocSinhTheoGvcn: '/hocbadientu-service/api/giao-vien/thong-ke-chi-tiet-trang-thai-hoc-ba-gvcn',
        tongQuanTrangThaiHocBaHieuTruongTheoCap: '/hocbadientu-service/api/so-giao-duc/thong-ke-trang-thai-hoc-ba-hieu-truong',
        tongQuanTrangThaiKyLaiHocBa: '/hocbadientu-service/api/so-giao-duc/tong-quan-ky-lai-hoc-ba',
        tongQuanHocSinhTheoSoPhong: '/hocbadientu-service/api/so-giao-duc/tong-quan-hoc-sinh',
        tongQuanHocBaTheoSoPhong: '/hocbadientu-service/api/so-giao-duc/tong-quan-hoc-ba',
        tongQuanTrangThaiHocBaGVCNTheoCap: '/hocbadientu-service/api/so-giao-duc/thong-ke-trang-thai-hoc-ba-gvcn',
        trangThaiHocBaTheoMonTheoSoPhong: '/hocbadientu-service/api/so-giao-duc/thong-ke-theo-mon-hoc',
        thongKeHocSinhThiLaiGvbn: '/hocbadientu-service/api/giao-vien/thong-ke-hs-thi-lai',
        thongKeHocSinhThiLaiGvcn: '/hocbadientu-service/api/giao-vien/thong-ke-hs-thi-lai-theo-gvcn',
        thongKeTrangThaiHocSinhGvcn: '/hocbadientu-service/api/giao-vien/thong-ke-hoc-sinh-theo-gvcn',
        chiTietThongKeThaiHocSinhGvcn: '/hocbadientu-service/api/giao-vien/thong-ke-chi-tiet-hoc-sinh',
        chiTietThongKeHocSinhThiLaiGvcn: '/hocbadientu-service/api/giao-vien/thong-ke-chi-tiet-hs-thi-lai',
        thongKeTrangThaiHocSinhHieuTruong: '/hocbadientu-service/api/giao-vien/thong-ke-hoc-sinh-theo-truong-hoc',
        thongKeHocSinhThiLaiHieuTruong: '/hocbadientu-service/api/giao-vien/thong-ke-hs-thi-lai-theo-hieu-truong',
        chiTietThongKeTrangThaiHocSinhHieuTruong: '/hocbadientu-service/api/giao-vien/thong-ke-chi-tiet-hoc-sinh',
        chiTietThongKeHocSinhThiLaiHieuTruong: '/hocbadientu-service/api/giao-vien/thong-ke-chi-tiet-hs-thi-lai',
        trangThaiHocBaTheoLopGVCNToanTruong: '/hocbadientu-service/api/giao-vien/thong-ke-trang-thai-hoc-ba-gvcn',
        thongKeHocLuc: '/hocbadientu-service/api/giao-vien/thong-ke-hoc-luc',
        thongKeHanhKiem: '/hocbadientu-service/api/giao-vien/thong-ke-hanh-kiem',
        thongKeHocLucTheoSoPhong: '/hocbadientu-service/api/so-giao-duc/thong-ke-hoc-luc',
        thongKeHanhKiemTheoSoPhong: '/hocbadientu-service/api/so-giao-duc/thong-ke-hanh-kiem',
        thongKeUploadChuKy: '/hocbadientu-service/api/giao-vien/thong-ke-chu-ky',
        thongKeKQGD: '/hocbadientu-service/api/giao-vien/thong-ke-danh-gia-kqgd',
        thongKeKQGDTheoSoPhong: '/hocbadientu-service/api/so-giao-duc/thong-ke-danh-gia-kqdg',
    },
    thongKeBaoCao: {
        trangThaiCapNhatHocBa: '/hocbadientu-report/api/v1/bieu-do/cap-nhat-du-lieu-hoc-ba',
        trangThaiChotDuLieuHocBa: '/hocbadientu-report/api/v1/bieu-do/cap-nhat-du-lieu-hoc-ba',
        trangThaiThongKeKyGvcn: '/hocbadientu-report/api/v1/bieu-do/thong-ke-gvcn-ky',
        trangThaiThongKeKyHieuTruong: '/hocbadientu-report/api/v1/bieu-do/thong-ke-hieu-truong-ky',
        trangThaiThongKeDongDauHieuTruong: '/hocbadientu-report/api/v1/bieu-do/thong-ke-hieu-truong-dong-dau',
        trangThaiCapNhatDuLieuHocBa: '/hocbadientu-report/api/v1/bieu-do/cap-nhat-du-lieu-hoc-ba',
        trangThaiChotHocBa: '/hocbadientu-report/api/v1/bieu-do/chot-du-lieu-hoc-ba',
        tyLeGiaoVienBoMonKyHocBa: '/hocbadientu-report/api/v1/bieu-do/chot-du-lieu-hoc-ba',
        soLuongHocBaKyTheoGvcn: '/hocbadientu-report/api/v1/bieu-do/thong-ke-gvcn-ky',
        soLuongHocBaKyTheoBoMon: '/hocbadientu-report/api/v1/bieu-do/thong-ke-giao-vien-bo-mon-ky-hoc-ba',
        soLuongHocBaKyTheoHieuTruong: '/hocbadientu-report/api/v1/bieu-do/thong-ke-hieu-truong-ky',
        bieuDoTyLeGiaoVienBoMonKyHocBa: '/hocbadientu-report/api/v1/bieu-do/thong-ke-chu-ky-giao-vien',
        trangThaiDangTaiChuKy: '/hocbadientu-report/api/v1/bieu-do/thong-ke-chu-ky-giao-vien',
        tongHopBaoCao: '/hocbadientu-sync-service/api/du-lieu/cap-nhat-bao-cao-hoc-ba',
    },
    configKy: {
        layDanhSachCauHinhTheoPhong: '/hocbadientu-service/api/admin/danh-sach-truong',
        layDanhSachCauHinhTheoSo: '/hocbadientu-service/api/admin/danh-sach-phong-gd',
        danhSachCauHinh: '/hocbadientu-service/api/admin/danh-sach-cau-hinh-so',
        themMoiCauHinhSo: '/hocbadientu-service/api/admin/tao-cau-hinh-su-dung',
        chinhSuaKieuKy: '/hocbadientu-service/api/admin/sua-cau-hinh-su-dung',
        xoaCauHinhSo: '/hocbadientu-service/api/admin/xoa-cau-hinh-su-dung',
        getDanhSachSo: '/hocbadientu-service/api/admin/danh-sach-so-gd',
        getConfigKy: '/hocbadientu-service/api/so-giao-duc/lay-cau-hinh-thoi-gian',
        createConfigKy: '/hocbadientu-service/api/so-giao-duc/tao-cau-hinh-thoi-gian',
        updateConfigKy: '/hocbadientu-service/api/so-giao-duc/cap-nhat-cau-hinh-thoi-gian',
        updateConfig2: '/hocbadientu-service/api/so-giao-duc/cap-nhat-cau-hinh-thoi-gian-phong-truong',
        checkAccountVgca: '/hocbadientu-ws-service/api/ws/sign-in-vgca',
        getListCerVgca: '/hocbadientu-ws-service/api/ws/get-certificates-bcy',
    },
    report: {
        getReport: '/hocbadientu-service/api/so-giao-duc/lay-bao-cao-tinh-trang-ky',
        getReportPhong: '/hocbadientu-service/api/so-giao-duc/lay-bao-cao-tinh-trang-ky-theo-phong',
        getReportKyLai: '/hocbadientu-service/api/so-giao-duc/lay-bao-cao-tinh-trang-ky-lai',
        getReportKyLaiPhong: '/hocbadientu-service/api/so-giao-duc/lay-bao-cao-tinh-trang-ky-lai-theo-phong',
        duLieuHocBa: '/hocbadientu-report/api/v1/xuat-file-excel/bao-cao-so-luong-hoc-ba',
        duLieuGiaoVien: '/hocbadientu-report/api/v1/xuat-file-excel/bao-cao-thong-ke-giao-vien',
        duLieuKy: '/hocbadientu-report/api/v1/xuat-file-excel/bao-cao-so-luong-ky-hoc-ba',
    },
    login: {
        login: '/hocbadientu-service/api/login',
        loginOtp: '/hocbadientu-service/api/send-otp',
        confirmOtp: '/hocbadientu-service/api/confirm-otp',
        checkTokenHS: '/hocbadientu-service/api/checkTokenHS',
        checkTokenGV: '/hocbadientu-service/api/checkTokenGV',
        getCapcha: '/hocbadientu-service/api/captcha',
        getCodeSSO: '/api/code-verifier-challenge',
        getAcssetTokenVnpt: '/hocbadientu-service/api/access-token-smartca-vnpt',
        reFreshTokenVnpt: '/hocbadientu-service/api/refresh-token-smartca-vnpt',
    },
    truong: {
        getTinh: '/hocbadientu-service/api/truong/danh-sach-tinh',
        listTruong: '/hocbadientu-service/api/truong/danh-sach-truong',
        getListKy: '/hocbadientu-service/api/truong/danh-sach-lop',
        getListHocBa: '/hocbadientu-service/api/giao-vien/danh-sach-hoc-ba-theo-lop',
        danhSachHieuTruong: '/hocbadientu-service/api/giao-vien/danh-sach-hieu-truong',
        capNhatHieuTruong: '/hocbadientu-service/api/giao-vien/cap-nhat-hieu-truong-hoc-ba',
        getListLopPcgd: '/hocbadientu-service/api/truong/danh-sach-lop-phan-cong',
        getListLopKetChuyen: '/hocbadientu-service/api/truong/danh-sach-lop-ket-chuyen',
        ketChuyenDulieuLop: '/hocbadientu-service/api/hoc-sinh/ket-chuyen-hoc-sinh',
        huyKetChuyenDulieuLop: '/hocbadientu-service/api/truong/huy-ket-chuyen-len-lop',
        chuyenLopTheoDanhSachHocSinh: '/hocbadientu-service/api/hoc-sinh/chuyen-lop',
        phanCongKyBiaVaTieuDe: '/hocbadientu-service/api/truong/lay-danh-sach-phan-cong-ky-bia-va-tieu-de-ky',
        capNhatPhanCongKyBiaVaTieuDe: '/hocbadientu-service/api/truong/cap-nhat-phan-cong-ky-bia-va-tieu-de-ky',

    },
    hocba: {
        detail: '/hocbadientu-service/api/hoc-ba/lay-hoc-ba',
        getFile: '/hocbadientu-view-service/api/hoc-ba/xem-hoc-ba',
        hocBaDetail: '/hocbadientu-service/api/hoc-ba/xem-hoc-ba-theo-cap-hoc',
        createHocBaTieuHoc: '/hocbadientu-service/api/hoc-ba/tao-hoc-ba-tieu-hoc',
        createHocBaTrungHoc: '/hocbadientu-service/api/hoc-ba/tao-hoc-ba-trung-hoc',
        updateHocBaTieuHoc: '/hocbadientu-service/api/hoc-ba/update-hoc-ba-tieu-hoc',
        updateHocBaTrungHoc: '/hocbadientu-service/api/hoc-ba/update-hoc-ba-trung-hoc',
        listHocba: '/hocbadientu-service/api/hoc-ba/danh-sach-hoc-ba',
        layDiemTongKet: '/hocbadientu-service/api/giao-vien/danh-sach-ket-qua-hoc-tap',
        detailPdf: '/hocbadientu-view-service/api/hoc-ba/xem-hoc-ba-theo-ma-lop',
        detailPdfJSON: '/hocbadientu-view-service/api/hoc-ba/get-json-hoc-ba',
        detailPdfV2: '/hocbadientu-view-service/api/hoc-ba/get-json-hoc-ba',
        detailExcel: '/hocbadientu-view-service/api/hoc-ba/hoc-ba-excel',
        detailFileKySo: '/hocbadientu-view-service/api/hoc-ba/xem-file-ky-so-hoc-ba-theo-ma-lop',
        detailFileKySoZip: '/hocbadientu-service/api/hoc-ba/file-ky-so-hoc-ba',
        getExcel: '/hocbadientu-view-service/api/hoc-ba/hoc-ba-theo-ma-lop-excel',
        danhSachMau: '/hocbadientu-service/api/so-giao-duc/danh-sach-mau-hoc-ba',
        apDungMau: '/hocbadientu-service/api/so-giao-duc/chon-mau-hoc-ba',
        layTrangThaiChot: '/hocbadientu-service/api/hoc-ba/trang-thai-chot-hoc-ba',
        moChot: '/hocbadientu-service/api/hoc-ba/mo-du-lieu-hoc-ba',
        chot: '/hocbadientu-service/api/hoc-ba/chot-du-lieu-hoc-ba',
        danhSachTaiKhoan: '/hocbadientu-userservice/api/v1/user-service/get-account',
        danhSachLoiChotHocBa: '/hocbadientu-service/api/hoc-ba/danh-sach-loi-chot-hoc-ba',
    },
    giaovien: {
        nopCTSLenSo:'/hocbadientu-ws-service/api/ws/register-digital-signature',
        nopCTSGV:'/hocbadientu-ws-service/api/ws/update-digital-signature',
        layCTS:'/hocbadientu-ws-service/api/ws/get-certificates',
        upDateInfo: '/hocbadientu-service/api/giao-vien/cap-nhat-thong-tin-ca-nhan',
        addInfo: '/hocbadientu-service/api/giao-vien/them-giao-vien',
        getRole: '/hocbadientu-service/api/giao-vien/position',
        xemBaoCao: '/hocbadientu-service/api/giao-vien/bao-cao',
        listHocbaBak: '/hocbadientu-service/api/giao-vien/danh-sach-hoc-ba',
        listHocba: '/hocbadientu-service/api/giao-vien/danh-sach-hoc-ba-dong-dau',
        listHocBaDongDauBia: '/hocbadientu-service/api/giao-vien/danh-sach-dong-dau-bia-hoc-ba',
        listHocBaDongDauKqht: '/hocbadientu-service/api/giao-vien/danh-sach-dong-dau-kqht',
        listLop: '/hocbadientu-service/api/giao-vien/danh-sach-lop',
        listLopChuNhiem: '/hocbadientu-service/api/giao-vien/danh-sach-lop-chu-nhiem',
        listLopNxGdcd: '/hocbadientu-service/api/giao-vien/danh-sach-lop-gdcd',
        listRequestNop: '/hocbadientu-service/api/giao-vien/danh-sach-yeu-cau-nop',
        listRequestRut: '/hocbadientu-service/api/giao-vien/                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     -sach-yeu-cau-rut',
        updateHocBa: '/hocbadientu-service/api/giao-vien/cap-nhat-hoc-ba',
        kyHocBa: '/hocbadientu-service/api/giao-vien/ky-hoc-ba',
        pheDuyetNop: '/hocbadientu-service/api/giao-vien/phe-duyet-yeu-cau-nop',
        pheDuyetRut: '/hocbadientu-service/api/giao-vien/phe-duyet-yeu-cau-rut',
        getOtp: '/hocbadientu-service/api/giao-vien/send-otp',
        phanLop: '/hocbadientu-service/api/giao-vien/phan-lop',
        uploadHocBa: '/hocbadientu-service/api/hoc-ba/upload/file',
        kySoHocBa: '/hocbadientu-service/api/giao-vien/ky-hoc-ba-not-ca',
        getHashUsb: '/hocbadientu-service/api/giao-vien/hash-file',
        kyUsbHocBa: '/hocbadientu-service/api/giao-vien/ky-usb-token',
        listGiaoVien: '/hocbadientu-service/api/giao-vien/danh-sach-giao-vien',
        xoaGiaoVien: '/hocbadientu-service/api/giao-vien/xoa-giao-vien',
        uploadfile: '/hocbadientu-service/api/giao-vien/image',
        updateSignature: '/hocbadientu-service/api/giao-vien/cap-nhat-chu-ky',
        info: '/hocbadientu-service/api/giao-vien/thong-tin-ca-nhan',
        duyetChuKy: '/hocbadientu-service/api/giao-vien/duyet-chu-ky',
        danhSachPhanCongKyHieuTruong: '/hocbadientu-service/api/giao-vien/danh-sach-phan-cong-ky-thay-hieu-truong',
        // phanCongKyThayHieuTruong: '/hocbadientu-service/api/giao-vien/phan-cong-ky-thay-hieu-truong',
        phanCongKyThayHieuTruong: '/hocbadientu-service/api/v2/giao-vien/phan-cong-ky-thay-hieu-truong',
        thongKeTruyCap: '/hocbadientu-service/api/giao-vien/thong-ke-lich-su-truy-cap',
        lichSuHoatDong: '/hocbadientu-service/api/giao-vien/lich-su-hoat-dong',
        dsLopTheoKhoi: '/hocbadientu-service/api/giao-vien/danh-sach-lop-theo-khoi',
        capNhatGvcn: '/hocbadientu-service/api/giao-vien/cap-nhat-gvcn',
        dsMonHocLop: '/hocbadientu-service/api/giao-vien/danh-sach-mon-hoc-lop',
        dsMonHocPhanCongGiangDay: '/hocbadientu-service/api/giao-vien/danh-sach-phan-cong-giang-day',
        xepMonHocChoLop: '/hocbadientu-service/api/giao-vien/xep-mon-hoc-lop',
        xepMonPhanCongGiangDay: '/hocbadientu-service/api/giao-vien/phan-cong-giang-day',
        chinhSuaPhanCongGiangDay: '/hocbadientu-service/api/giao-vien/phan-cong-giang-day-theo-lop',
        chinhSuaPhanCongThiLai: '/hocbadientu-service/api/giao-vien/phan-cong-ky-thi-lai-theo-lop',
        nopCTS: '/hocbadientu-ws-service/api/ws/register-digital-signature',

    },
    school: {
        listPgd: '/hocbadientu-service/api/truong/danh-sach-phong',
        listSchool: '/hocbadientu-service/api/truong/danh-sach-truong',
        infoSchool: '/hocbadientu-service/api/truong/get',
        updateInfoTruong: '/hocbadientu-service/api/truong/update',
        updateInfoChuKy: '/hocbadientu-service/api/truong/image',
        dongBo: '/hocbadientu-service/api/hoc-ba/dong-bo-du-lieu',
        // nopDuLieuLenBo:'/hocbadientu-sync-service/api/connect-edu-profile/upload-file',
        nopDuLieuLenBo:'/hocbadientu-sync-service/api/v2/edu/profile/upload-file',
        checkTrangThaiNopHoSoLenBo:'/hocbadientu-sync-service/api/connect-edu-profile/get-trang-thai',
        dongBoHocBa: '/hocbadientu-sync-service/api/du-lieu/dong-bo-du-lieu-theo-lop',
        historySync: '/hocbadientu-service/api/hoc-ba/lich-su-dong-bo-du-lieu',
        lichSuNopHoSoLenBo:'/hocbadientu-sync-service/api/connect-edu-profile/get-lich-su',
        // taiDuLieuHocSinh:'/hocbadientu-sync-service/api/tai-du-lieu/du-lieu-hoc-sinh',
        taiDuLieuHocSinh: '/hocbadientu-sync-service/api/v2/tai-du-lieu/du-lieu-dong-bo'
    },
    sso: {
        url: '/hocbadientu-service/api/truong/danh-sach-phong',
        login: '/hocbadientu-service/api/receive-sso-token',
        getInfo: 'https://sso-edu.viettel.vn/api/Core/OAuth/User/me',
        detect: 'https://sso-edu.viettel.vn/api/Core/OAuth/AuthCode/accessToken'
    },
    test: {
        url: '/hocbadientu-service/api/truong/danh-sach-tinh',
        upFile: '/hocbadientu-sync-service/api/v2/import/data/test-file',
    },
    menu: {
        findAll: '/hocbadientu-service/api/sys/sysMenu/findAll',
        create: '/hocbadientu-service/api/sys/sysMenu/create',
        update: '/hocbadientu-service/api/sys/sysMenu/update',
        delete: '/hocbadientu-service/api/sys/sysMenu/delete',
    },
    taiKhoan:{
        update:'/hocbadientu-service/api/so-giao-duc/cap-nhat-thong-tin-don-vi-giao-duc',
        info:'/hocbadientu-service/api/so-giao-duc/lay-thong-tin-don-vi',
        doiMatKhau:'/hocbadientu-userservice/api/v1/user-service/reset-password',
        khoaTaiKhoan:'/hocbadientu-userservice/api/v1/user-service/khoa-tai-khoan',
        moKhoaTaiKhoan:'/hocbadientu-userservice/api/v1/user-service/mo-khoa-tai-khoan',
        xemTruocChuKyVaDau:'/hocbadientu-view-service/api/hoc-ba/xem-truoc-chu-ky-va-dau',
    },
    vaitro: {
        listForPage: '/hocbadientu-service/api/sys/sysRole/listForPage',
        create: '/hocbadientu-service/api/sys/sysRole/create',
        delete: '/hocbadientu-service/api/sys/sysRole/delete',
        findMenuRoleByMaSo: '/hocbadientu-service/api/sys/sysMenu/findMenuRoleByMaSo',
        createMenuRole: '/hocbadientu-service/api/sys/sysMenu/createMenuRole',
        viewLog: '/hocbadientu-service/system/view-log'
    },
    firebase: {
        sendToken: '/hocbadientu-push-service/api/notify/receive-token-firebase'
    },
    import: {
        giaoVien: '/hocbadientu-sync-service/api/du-lieu/giao-vien',
        giaoVienChung: '/hocbadientu-sync-service/api/du-lieu/chung/giao-vien',
        hocSinh: '/hocbadientu-sync-service/api/du-lieu/hoc-sinh',
        // hocSinhChung: '/hocbadientu-sync-service/api/du-lieu/chung/hoc-sinh',
        hocSinhChung: '/hocbadientu-sync-service/api/v2/import/data/validate-hoc-sinh',
        hocSinhChuaMaMoet: '/hocbadientu-sync-service/api/du-lieu/hoc-sinh-moet',
        lopHoc: '/hocbadientu-sync-service/api/du-lieu/lop-hoc',
        lopHocChung: '/hocbadientu-sync-service/api/du-lieu/chung/lop-hoc',
        phanCongGiangDay: '/hocbadientu-sync-service/api/du-lieu/phan-cong-giang-day',
        hanhKiem: '/hocbadientu-sync-service/api/du-lieu/hanh-kiem',
        diemTongKetMonHoc: '/hocbadientu-sync-service/api/du-lieu/diem-tong-ket-mon-hoc',
        diemTongKetMonHocChung: '/hocbadientu-sync-service/api/du-lieu/chung/diem-tong-ket-mon-hoc',
        nhanXetKhenThuong: '/hocbadientu-sync-service/api/du-lieu/khen-thuong',
        nhanXetKhenThuongChung: '/hocbadientu-sync-service/api/du-lieu/chung/khen-thuong',
        nhanXetHieuTruong: '/hocbadientu-sync-service/api/du-lieu/nhan-xet-hieu-truong',
        nhanXetHieuTruongChung: '/hocbadientu-sync-service/api/du-lieu/chung/nhan-xet-hieu-truong',
        nhanXetHocSinh: '/hocbadientu-sync-service/api/du-lieu/so-dang-bo',
        nhanXetGVCN: '/hocbadientu-sync-service/api/du-lieu/nhan-xet-gvcn',
        nhanXetGVCNChung: '/hocbadientu-sync-service/api/du-lieu/chung/nhan-xet-gvcn',
        nghePT: '/hocbadientu-sync-service/api/du-lieu/nghe-pho-thong',
        nghePTChung: '/hocbadientu-sync-service/api/du-lieu/chung/nghe-pho-thong',
        renLuyenHe: '/hocbadientu-sync-service/api/du-lieu/ren-luyen-he',
        renLuyenHeChung: '/hocbadientu-sync-service/api/du-lieu/chung/ren-luyen-he',
        nhanXetGdcd: '/hocbadientu-sync-service/api/du-lieu/nhan-xet-gdcd',
        nhanXetGdcdChung: '/hocbadientu-sync-service/api/du-lieu/chung/nhan-xet-gdcd',
        soNhanXet: '/hocbadientu-sync-service/api/du-lieu/so-diem-mon-c1',
        soNhanXetChung: '/hocbadientu-sync-service/api/du-lieu/chung/so-diem-mon-c1',
        phamChatNangLuc: '/hocbadientu-sync-service/api/du-lieu/pham-chat-nang-luc',
        phamChatNangLucAnGiang: '/hocbadientu-sync-service/api/du-lieu-dac-biet/imp-nang-luc-an-giang',
        phamChatNangLucChung: '/hocbadientu-sync-service/api/du-lieu/chung/pham-chat-nang-luc',
        diemtTongKetC1: '/hocbadientu-sync-service/api/du-lieu/diem-tong-ket-mon-hoc-c1',
        diemTongKetC1Chung: '/hocbadientu-sync-service/api/du-lieu/chung/diem-tong-ket-mon-hoc-c1',
        chieuCaoCanNang: '/hocbadientu-sync-service/api/du-lieu/chieu-cao-can-nang',
        chieuCaoCanNangChung: '/hocbadientu-sync-service/api/du-lieu/chung/chieu-cao-can-nang',
        soNgayNghi: '/hocbadientu-sync-service/api/du-lieu/ngay-nghi',
        soNgayNghiChung: '/hocbadientu-sync-service/api/du-lieu/chung/ngay-nghi',
        lichSuNhapDuLieu: '/hocbadientu-sync-service/api/du-lieu/lich-su-nhap-du-lieu',
        moTruongBangFile: '/hocbadientu-service/api/admin/mo-cau-hinh-truong-bang-file',
    },
    download: {
        khenThuong: '/hocbadientu-sync-service/api/v2/tai-du-lieu/chung/khen-thuong',
        nghePT: '/hocbadientu-sync-service/api/v2/tai-du-lieu/nghe-pho-thong',
        renLuyenHe: '/hocbadientu-sync-service/api/v2/tai-du-lieu/ren-luyen-he',
        nhanXetGdcd: '/hocbadientu-sync-service/api/v2/tai-du-lieu/nhan-xet-gdcd',
        nhanXetGVCN: '/hocbadientu-sync-service/api/v2/tai-du-lieu/nhan-xet-gvcn',
        nhanXetHieuTruong: '/hocbadientu-sync-service/api/v2/tai-du-lieu/nhan-xet-hieu-truong',
        chieuCaoCanNang: '/hocbadientu-sync-service/api/v2/tai-du-lieu/chieu-cao-can-nang',
        soNgayNghi: '/hocbadientu-sync-service/api/v2/tai-du-lieu/ngay-nghi',
        soDangBoHocSinh: '/hocbadientu-sync-service/api/v2/tai-du-lieu/so-dang-bo',
        nangLucPhamChat: '/hocbadientu-sync-service/api/v2/tai-du-lieu/nang-luc-pham-chat',
        soNhanXet: '/hocbadientu-sync-service/api/v2/tai-du-lieu/so-nhan-xet',
        diemTongKetC1: '/hocbadientu-sync-service/api/v2/tai-du-lieu/chung/diem-tong-ket-c1',
        diemTongKetMonHoc: '/hocbadientu-sync-service/api/v2/tai-du-lieu/chung/diem-tong-ket-mon-hoc',
        renLuyenHeChung: '/hocbadientu-sync-service/api/v2/tai-du-lieu/chung/ren-luyen-he',
        nhanXetGdcdChung: '/hocbadientu-sync-service/api/v2/tai-du-lieu/chung/nhan-xet-gdcd',
        chieuCaoCanNangChung: '/hocbadientu-sync-service/api/v2/tai-du-lieu/chung/chieu-cao-can-nang',
        nangLucPhamChatChung: '/hocbadientu-sync-service/api/v2/tai-du-lieu/chung/nang-luc-pham-chat',
        soNhanXetChung: '/hocbadientu-sync-service/api/v2/tai-du-lieu/chung/so-nhan-xet',
        linkFileHasData: {
            giaoVien:'/hocbadientu-sync-service/api/v2/tai-du-lieu/giao-vien',
            giaoVienChung: '/hocbadientu-sync-service/api/v2/tai-du-lieu/chung/giao-vien',
            lopHoc:'/hocbadientu-sync-service/api/v2/tai-du-lieu/lop-hoc',
            lopHocChung: '/hocbadientu-sync-service/api/v2/tai-du-lieu/chung/lop-hoc',
            hocSinh:'/hocbadientu-sync-service/api/v2/tai-du-lieu/hoc-sinh',
            hocSinhChung: '/hocbadientu-sync-service/api/v2/tai-du-lieu/chung/hoc-sinh'
        }
    },
    generate: {
        // huyChotDuLieu : '/hocbadientu-generate-service/api/generate/huy-ky',
        huyChotDuLieu: '/hocbadientu-generate-service/api/v2/generate/huy-ky-so-hoc-ba',
        huyChotDuLieuBia: '/hocbadientu-generate-service/api/v2/generate/huy-ky-so-bia-hoc-ba',
        huyChotDuLieuQTHT: '/hocbadientu-generate-service/api/v2/generate/huy-chot-qua-trinh-hoc-tap',
        lichSuHuyChotDuLieuQTHT: '/hocbadientu-generate-service/api/v2/generate/danh-sach-huy-chot-hoc-ba',
        khoiPhuc: '/hocbadientu-generate-service/api/v2/generate/khoi-phuc-huy-chot-hoc-ba',
        chotDuLieu: '/hocbadientu-generate-service/api/generate/chot-du-lieu',
        getHistory: '/hocbadientu-service/api/hoc-ba/danh-sach-loi-gen-file',
        chotDuLieuHocBa: '/hocbadientu-service/api/hoc-ba/danh-sach-ket-chuyen-ky-so-hoc-ba',
        chotDuLieuBia: '/hocbadientu-service/api/hoc-ba/danh-sach-ket-chuyen-ky-so-bia-hoc-ba',
        chotDuLieuQTHT: '/hocbadientu-service/api/hoc-ba/danh-sach-ket-chuyen-ky-so-qtht',
        huyChotHocBaHocSinh: '/hocbadientu-generate-service/api/v2/generate/huy-ky-so-hoc-ba',
        huyChotQTHT: '/hocbadientu-service/api/hoc-ba/danh-sach-huy-ket-chuyen-ky-so-qtht',
        xemHocBaTheoMaHs: '/hocbadientu-view-service/api/hoc-ba/xem-hoc-ba-theo-danh-sach-hoc-sinh',
        xemBiaHocBaTheoMaHs: '/hocbadientu-view-service/api/hoc-ba/xem-bia-hoc-ba',
        chotHocBaHocSinh: '/hocbadientu-generate-service/api/v2/generate/ket-chuyen-ky-so-hoc-ba',
        chotHocBaHocSinhV2: '/hocbadientu-generate-service/api/v2.1/generate/ket-chuyen-ky-so-hoc-ba',
        chotBiaHocBaHocSinh: '/hocbadientu-generate-service/api/v2/generate/ket-chuyen-ky-so-bia-hoc-ba',
        chotQTHTHocBaHocSinh: '/hocbadientu-generate-service/api/v2/generate/chot-qua-trinh-hoc-tap',
    },
    lopHoc: {
        xoaLopHoc: '/hocbadientu-service/api/giao-vien/xoa-lop-hoc',
    },
    danhMuc: {
        danhSachDonVi: '/hocbadientu-service/api/truong/danh-sach-phong-theo-so',
        danhSachTruong: '/hocbadientu-service/api/truong/danh-sach-truong-hoc',
        danhSachLopTheoTruong: '/hocbadientu-service/api/truong/danh-sach-lop-cua-truong'
    }
};
