<template>
    <div id="detailHocBa" class="page page-file file detailHocBa" v-loading.fullscreen.lock="fullscreenLoading">
        <div class="box-upload-file">
            <el-form :rules="rulesSearch" ref="searchForm" :model="searchForm" :hide-required-asterisk="true">
                <div class="d-flex flex-wrap">
                    <div class="d-inline-block pl-3 pr-3 pb-3">
                        <h5 class="font-weight-bold m-0">Danh sách học sinh</h5>
                        <p class="m-0">Tìm kiếm danh sách</p>
                    </div>
                    <div>

                    </div>
                </div>
                <el-form-item style="width: 100px" class="d-inline-block" prop="education">
                    <el-select disabled @change="capHocChange" v-model="searchForm.education = this.user.capHoc"
                               placeholder="Cấp học">
                        <el-option v-for="item in optionEducation" :label="item.label" :key="item.value"
                                   :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item class="d-inline-block" prop="year">
                    <!--                    <el-date-picker style="width: 150px" v-model="searchForm.year" type="year" placeholder="Năm học"-->
                    <!--                                    value-format="yyyy">-->
                    <!--                    </el-date-picker>-->
                    <SelectNamHoc v-model="searchForm.year"/>
                </el-form-item>
                <el-form-item style="width: 130px" class="d-inline-block" prop="grade">
                    <el-select @change="khoiHocChange" v-model="searchForm.grade" placeholder="Chọn khối (*)">
                        <el-option v-for="item in optionGrade" :label="item.label" :key="item.value"
                                   :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item style="width: 130px" class="d-inline-block" prop="class">
                    <el-select v-model="searchForm.class" placeholder="Chọn lớp (*)">
                        <el-option v-for="item in listLop.list" :label="item.tenLop" :key="item.maLop"
                                   :value="item.maLop"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item class="d-inline-block">
                    <el-input v-model="searchForm.student" placeholder="Nhập"></el-input>
                </el-form-item>
                <el-form-item class="d-inline-block">
                    <el-input v-model="searchForm.maHS" placeholder="Nhập"></el-input>
                </el-form-item>
                <el-form-item style="width: 130px" class="d-inline-block" prop="status">
                    <el-select v-model="searchForm.status" placeholder="Trạng thái">
                        <el-option v-for="item in optionStatus" :label="item.label" :key="item.value"
                                   :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item class="d-inline-block">
                    <el-button type="primary" @click="submitSearch('searchForm')">Tìm kiếm</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="file--title">
            <h5 class="title">Danh sách học sinh
                <template v-if="listHocSinh && listHocSinh.total">
                    <small>
                        <i>(Danh sách có {{ listHocSinh.total }} học sinh )</i>
                    </small>
                </template>
            </h5>
        </div>
        <div v-if="" class="page__content">
            <div class="listNew mb-5">
                <el-table border fit stripe :lazy="true" :data="listHocSinh ? listHocSinh.list : []" width="100%"
                          @selection-change="handleSelectionChange" ref="listFile">
                    <el-table-column type="selection" width="50">
                    </el-table-column>
                    <el-table-column label="STT" style="width: 50px" width="50" type="index" class-name="text-center"
                                     :index="indexMethod">
                    </el-table-column>
                    <el-table-column label="Ảnh học sinh">
                        <template slot-scope="scope">
                            <el-image :src="scope.row.image"></el-image>
                        </template>
                    </el-table-column>
                    <el-table-column label="Mã học sinh" property="maHS" width="160"></el-table-column>
                    <el-table-column label="Tên học sinh" property="hoten"></el-table-column>
                    <el-table-column label="Lớp" property="tenLop" width="70" class="text-center"
                                     style="text-align: center"></el-table-column>
                    <el-table-column label="GVCN" property="tenGVCN"></el-table-column>
                    <el-table-column label="Ngày sinh" property="ngaySinh" width="150"></el-table-column>
                    <el-table-column label="Trạng thái học sinh" width="" align="center">
                        <template slot-scope="scope">
                            <div v-if="scope.row.hocSinh==1">Mới chuyển đến</div>
                            <div v-if="scope.row.hocSinh==0">Đang học</div>
                            <div v-else></div>
                        </template>
                    </el-table-column>

                    <el-table-column v-if="user.role == 1" label="Trạng thái ký"
                                     property="moTaTrangThaiGV"></el-table-column>
                    <el-table-column v-if="user.role == 2" label="Trạng thái ký"
                                     property="moTaTrangThaiGVCN"></el-table-column>
                    <el-table-column v-if="user.role == 3" label="Trạng thái ký"
                                     property="moTaTrangThaiHieuTruong"></el-table-column>
                    <el-table-column label="Hành động" class-name="">
                        <template slot-scope="scope">
                            <!--                            <div class="btn__table_action">-->
                            <!--                                <el-tooltip v-if="scope.row.role==2||user.role != 1" content="Xem học bạ"-->
                            <!--                                            placement="top">-->
                            <!--                                    <el-button data-option="" @click="viewFile(scope.row.id)" href="javascript:;"-->
                            <!--                                               class="mb-1 viewDetailHocBa" type="primary" icon="el-icon-search" circle>-->
                            <!--                                    </el-button>-->
                            <!--                                </el-tooltip>-->

                            <!--                                &lt;!&ndash;                            <el-tooltip v-if="checkEdit(scope.row)" content="Sửa điểm" placement="top">&ndash;&gt;-->
                            <!--                                &lt;!&ndash;                                <el-button data-option="" @click="openFancybox(scope.row.id)" href="javascript:;" class="mb-1 viewDetailHocBa" type="warning" icon="el-icon-edit-outline" circle>&ndash;&gt;-->
                            <!--                                &lt;!&ndash;                                </el-button>&ndash;&gt;-->
                            <!--                                &lt;!&ndash;                            </el-tooltip>&ndash;&gt;-->
                            <!--                                <el-tooltip v-if="(user.role == 2 || user.role == 1) && user.capHoc !=1"-->
                            <!--                                            content="Xem điểm" placement="top">-->
                            <!--                                    <el-button data-option="" @click="openFancybox(scope.row.id)" href="javascript:;"-->
                            <!--                                               class="mb-1 viewDetailHocBa" type="warning" icon="el-icon-search" circle>-->
                            <!--                                    </el-button>-->
                            <!--                                </el-tooltip>-->
                            <!--                                <el-tooltip v-if="(user.role == 2 || user.role == 1) && user.capHoc !=1 "-->
                            <!--                                            content="Ký môn OTP" placement="top">-->
                            <!--                                    <el-button @click="openFormOtp(scope.row.id)" class="mb-1" type="danger"-->
                            <!--                                               icon="el-icon-edit" circle></el-button>-->
                            <!--                                </el-tooltip>-->
                            <!--                                <el-tooltip v-if="checkDuyet(scope.row)" content="Ký học bạ OTP" placement="top">-->
                            <!--                                    <el-button @click="openFormOtp(scope.row.id,2)" class="mb-1" type="success"-->
                            <!--                                               icon="el-icon-check" circle>-->
                            <!--                                    </el-button>-->
                            <!--                                </el-tooltip>-->
                            <!--                                <el-tooltip v-if="(user.role == 2 || user.role == 1) && user.capHoc !=1"-->
                            <!--                                            content="Ký môn số" placement="top">-->
                            <!--                                    <el-button @click="kySo(scope.row.id,user.role)" class="mb-1" type="info"-->
                            <!--                                               icon="el-icon-edit-outline" circle>-->
                            <!--                                    </el-button>-->
                            <!--                                </el-tooltip>-->
                            <!--                                <el-tooltip v-if="checkDuyet(scope.row)" content="Ký học bạ số" placement="top">-->
                            <!--                                    <el-button @click="kySo(scope.row.id,user.role)" class="mb-1" type="primary"-->
                            <!--                                               icon="el-icon-check" circle>-->
                            <!--                                    </el-button>-->
                            <!--                                </el-tooltip>-->
                            <!--                                <SignUsb v-if="checkDuyet(scope.row)" :hocba-id="scope.row.id"-->
                            <!--                                         :lst-id="listHocBaCheck"></SignUsb>-->

                            <!--                            </div>-->
                        </template>
                    </el-table-column>
                </el-table>
                <div class="text-right mt-2">
                    {{listHocSinh}}
                    <pagination v-if="listHocSinh" :page-count="getTotalPage(listHocSinh.total)"
                                :click-handler="getHocSinh" :prev-text="'Trước'" :next-text="'Sau'"
                                :container-class="'el-pager'" :pageClass="'number'">

                    </pagination>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import ChiTietHocBa from "./GiaoVien/ChiTietHocBa";
    import {mapActions, mapState} from 'vuex';
    import Pagination from "../components/Pagination";
    import DialogAlert from "../components/DialogAlert";
    import SelectNamHoc from "../components/SelectNamHoc";

    export default {
        name: "ThongTinHocSinh",
        metaInfo: {
            title: 'Danh sách học sinh',
            titleTemplate: '',
            meta: [{
                vmid: 'description',
                name: 'description',
                content: ''
            }]
        },
        computed: {
            ...mapState('hocsinh', [
                "listHocSinh"
            ]),
            ...mapState('teacher', [
                "listLop",
            ]),
            ...mapState('account', [
                'user'
            ])
        },
        components: {
            DialogAlert,
            Pagination,
            SelectNamHoc
        },

        data() {
            return {
                showDialogAlertUsb: false,
                showDialogAlert: false,
                typeKy: null,
                hocbaDatas: [],
                showDialogOtp: false,
                listHocBaCheck: [],
                viewPopup: ChiTietHocBa,
                idEdit: '',
                showEditDiem: false,
                paginate: {
                    start: 0,
                    limit: 100,
                },
                data: [1, 2, 3, 4, 5],
                fullscreenLoading: false,
                optionEducation: [
                    {
                        value: 1,
                        label: 'Cấp 1'
                    },
                    {
                        value: 2,
                        label: 'Cấp 2'
                    },
                    {
                        value: 3,
                        label: 'Cấp 3'
                    },
          {value: 15,label: 'Liên cấp'},
          {value: 145,label: 'Liên cấp'},
          {value: 14,label: 'Liên cấp'},
          {value: 13,label: 'Liên cấp'},
          {value: 451,label: 'Liên cấp'},
          {value: 23,label: 'Liên cấp'},
          {value: 236,label: 'Liên cấp'},
          {value: 36,label: 'Liên cấp'},
          {value: 452,label: 'Liên cấp'},
          {value: 1245,label: 'Liên cấp'},
          {value: 12,label: 'Liên cấp'},
          {value: 123,label: 'Liên cấp'},
          {value: 12345,label: 'Liên cấp'},
          {value: 1235,label: 'Liên cấp'},
                ],
                optionGrade: [],
                khoiCap1: [
                    {
                        value: 1,
                        label: 'Khối 1'
                    },
                    {
                        value: 2,
                        label: 'Khối 2'
                    },
                    {
                        value: 3,
                        label: 'Khối 3'
                    },
                    {
                        value: 4,
                        label: 'Khối 4'
                    },
                    {
                        value: 5,
                        label: 'Khối 5'
                    },
                ],
                khoiCap2: [{
                    value: 6,
                    label: 'Khối 6'
                },
                    {
                        value: 7,
                        label: 'Khối 7'
                    },
                    {
                        value: 8,
                        label: 'Khối 8'
                    },
                    {
                        value: 9,
                        label: 'Khối 9'
                    },
                ],
                khoiCap3: [
                    {
                        value: 10,
                        label: 'Khối 10'
                    },
                    {
                        value: 11,
                        label: 'Khối 11'
                    },
                    {
                        value: 12,
                        label: 'Khối 12'
                    },

                ],
                optionStudent: [{
                    value: '',
                    label: 'Học sinh'
                },
                    {
                        value: 1,
                        label: 'HS 1'
                    },
                    {
                        value: 2,
                        label: 'HS 2'
                    },
                    {
                        value: 3,
                        label: 'HS 3'
                    },
                ],
                optionStatus: [
                    {
                        value: '',
                        label: 'Trạng thái'
                    },
                    {
                        value: 1,
                        label: 'Đã ký'
                    },
                    {
                        value: 2,
                        label: 'Đã ký duyệt'
                    },
                    {
                        value: 3,
                        label: 'Chưa ký'
                    },
                ],
                searchForm: {
                    year: '',
                    education: '',
                    grade: '',
                    class: '',
                    student: '',
                    status: '',
                    maHS: ''
                },
                rulesSearch: {
                    // year : [
                    //     // {required : true, message : 'Bạn chưa chọn năm học',trigger: 'blur'}
                    //     {required : true, message : 'Bạn chưa chọn năm học',trigger: 'change'}
                    // ],
                    // education : [
                    //     {required : true, message : 'Bạn chưa chọn cấp học',trigger: 'change'}
                    // ],
                    grade: [{
                        required: true,
                        message: 'Chưa chọn khối học',
                        trigger: 'change'
                    }],
                    class: [{
                        required: true,
                        message: 'Chưa chọn lớp học',
                        trigger: 'change'
                    }],
                    // student : [
                    //     {required : true, message : 'Bạn chưa chọn học sinh',trigger: 'change'}
                    // ],
                    // status : [
                    //     {required : true, message : 'Bạn chưa chọn trạng thái',trigger: 'change'}
                    // ]
                }
            }
        },

        methods: {
            ...mapActions('hocsinh', [
                "getListHocSinh",
            ]),

            indexMethod(index) {
                return (this.paginate.start + index) + 1;
            },
            toggleSelection(rows) {
                if (rows) {
                    rows.forEach(row => {
                        this.$refs.listFile.toggleRowSelection(row);
                    });
                } else {
                    this.$refs.listFile.clearSelection();
                }
            },
            getKhoiHoc() {
                if (this.user.capHoc == 1) {
                    this.optionGrade = this.khoiCap1;
                } else if (this.user.capHoc == 2) {
                    this.optionGrade = this.khoiCap2;
                } else if (this.user.capHoc == 3) {
                    this.optionGrade = this.khoiCap3;
                } else {
                    this.optionGrade = this.khoiCap1.concat(this.khoiCap2, this.khoiCap3)
                }
            },
            closeDialogOtp() {
                this.toggleSelection();
                this.getListHocSinh({
                    start: this.paginate.start,
                    limit: this.paginate.limit,
                    maLop: this.searchForm.class,
                    namHoc: this.searchForm.year ? this.searchForm.year : '',
                    capHoc: this.searchForm.education,
                    khoiHoc: this.searchForm.grade,
                    tenHS: this.searchForm.student,
                    maHS: this.searchForm.maHS,
                    trangThai: this.searchForm.status
                });
                this.showDialogOtp = false;
            },

            handleSelectionChange(val) {
                this.listHocBaCheck = val;
            },

            detailHocBaClose() {
                this.saveTempKq(null);
                this.showEditDiem = false;
            },

            submitSearch(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.getHocSinh(1)
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            getHocSinh(select) {
                this.showDialogOtp = false;
                let start = (select - 1) * this.paginate.limit ? (select - 1) * this.paginate.limit : 0;
                this.paginate.start = start;
                this.getListHocSinh({
                    start: this.paginate.start,
                    limit: this.paginate.limit,
                    maLop: this.searchForm.class,
                    namHoc: this.searchForm.year ? this.searchForm.year : '',
                    capHoc: this.searchForm.education,
                    khoiHoc: this.searchForm.grade,
                    tenHS: this.searchForm.student,
                    maHS: this.searchForm.maHS,
                    trangThai: this.searchForm.status
                });
            },
            success() {
                this.getListHocSinh({
                    start: this.paginate.start,
                    limit: this.paginate.limit,
                    maLop: this.searchForm.class,
                    namHoc: this.searchForm.year ? this.searchForm.year : '',
                    capHoc: this.searchForm.education,
                    khoiHoc: this.searchForm.grade,
                    tenHS: this.searchForm.student,
                    maHS: this.searchForm.maHS,
                    trangThai: this.searchForm.status
                });
                this.showDialogOtp = false;
            },
            capHocChange() {
                this.searchForm.student = '';
                this.searchForm.maHS = '';
                this.searchForm.status = '';
                this.searchForm.grade = ''
                if (!this.searchForm.education) {
                    this.optionGrade = this.khoiCap1.concat(this.khoiCap2, this.khoiCap3)
                    return;
                } else {
                    if (this.searchForm.education == 1) {
                        this.optionGrade = this.khoiCap1;
                    } else if (this.searchForm.education == 2) {
                        this.optionGrade = this.khoiCap2;
                    } else {
                        this.optionGrade = this.khoiCap3;
                    }
                }
            },
            khoiHocChange() {
                this.getlistLopHoc();
                this.searchForm.class = '';

            },

            getTotalPage(number) {
                return Math.ceil(number / (this.paginate.limit));
            },

            getlistLopHoc() {
                this.getListLop({
                    start: 0,
                    limit: 999,
                    khoiHoc: this.searchForm.grade,
                })
            },

            viewFile(id) {
                this.fullscreenLoading = true;
                this.getFile({
                    maHocBa: id
                })
            },

            openFancybox(id) {
                this.idEdit = id;
                this.getDetail({
                    id: id
                })
                this.showEditDiem = true;
            },
            openFancyBox(url) {
                $.fancybox.open({
                    src: url,
                    type: 'iframe',
                });
            },
            checkEdit(item) {
                let role = this.user.role;
                if (role == 1) {
                    if (item.nguonDuLieu) {
                        return true
                    }
                } else if (role == 2) {
                    if (item.nguonDuLieu) {
                        return true;
                    }
                }
                return false;
            },
            closeDialogAlert() {
                this.getListHocSinh({
                    start: this.paginate.start,
                    limit: this.paginate.limit,
                    maLop: this.searchForm.class,
                    namHoc: this.searchForm.year ? this.searchForm.year : '',
                    capHoc: this.searchForm.education,
                    khoiHoc: this.searchForm.grade,
                    tenHS: this.searchForm.student,
                    maHS: this.searchForm.maHS,
                    trangThai: this.searchForm.status
                });
                this.showDialogAlert = false;
                this.showDialogAlertUsb = false;
            },
            getFullYear() {
                let d = new Date();
                return d.getFullYear();
            },
        },

        beforeMount() {
            if (this.user.role == 1) {
                this.optionStatus = [
                    {
                        value: '',
                        label: 'Trạng thái'
                    },
                    {
                        value: 0,
                        label: 'Chưa ký'
                    },
                    {
                        value: 1,
                        label: 'Đã ký'
                    },
                ];
            }
            if (this.user.role == 2) {
                this.optionStatus = [
                    {
                        value: '',
                        label: 'Trạng thái'
                    },
                    {
                        value: 2,
                        label: 'Giáo viên bộ môn đang ký'
                    },
                    {
                        value: 3,
                        label: 'Giáo viên bộ môn đã ký'
                    },
                    {
                        value: 4,
                        label: 'Giáo viên chủ nhiệm đã ký'
                    },
                ];
            }
            if (this.user.role == 3) {
                this.optionStatus = [
                    {
                        value: '',
                        label: 'Trạng thái'
                    },
                    {
                        value: 4,
                        label: 'Giáo viên chủ nhiệm đã ký'
                    },
                    {
                        value: 5,
                        label: 'Giáo viên chủ nhiệm chưa ký'
                    },
                    {
                        value: 6,
                        label: 'Hiệu trưởng đã ký'
                    },
                ];
            }
        },
        mounted() {
            // this.fullscreenLoading = true;
            this.searchForm.year = this.getFullYear();
            this.getListHocSinh({
                start: 0,
                limit: 0,
                maLop: this.searchForm.class,
                namHoc: this.searchForm.year ? this.searchForm.year : '',
                capHoc: this.searchForm.education,
                khoiHoc: this.searchForm.grade,
                tenHS: this.searchForm.student,
                trangThai: this.searchForm.status
            });

            this.optionGrade = this.khoiCap1.concat(this.khoiCap2, this.khoiCap3);
            this.getKhoiHoc();
        },
        watch: {

            fileDetail(val) {
                if (this.fileStatus == 2) {
                    this.fullscreenLoading = false;
                    this.openFancyBox(this.fileDetail)
                }
            },
        },

    }
</script>

<style scoped>
    .btn__table_action button {
        padding: 8px 10px;
    }
</style>
