<template>
  <div class="page page-login" v-loading="status.loggingIn" element-loading-text="Loading..."
       element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="container">
      <div class="row vh-100 align-items-center justify-content-center">
        <div class="col-md-4"></div>
        <div class="col-md-4">
          <img src="/images/bg1.png" alt="bg">
          <div class="login-panel card">
            <div class="card-header pb-0 border-0" style="background-color: transparent;">
              <h3 class="panel-title text-center font-weight-bold"
                  style="font-size: 20px; color: #21BEF4;">Đăng nhập</h3>
              <!--                        <p class="m-0 text-center" style="font-size: 14px;">Nhập tài khoản được cấp để đăng nhập hệ thống</p>-->
            </div>
            <div v-if="!showLogin" class="card-body login__form pt-0">
              <div class="row" style="margin-top:15px">
                <div class="selectOptionLogin">
                  <el-button class="btn btn-lg btn-block btn-login" @click.prevent="linkToSSO()"
                             type="danger">Đăng nhập SSO hệ sinh thái giáo dục
                  </el-button>
                </div>
                <div class="selectOptionLogin">
                  <el-button class="btn btn-lg btn-block btn-login" @click="showLogin = true"
                             style="background-color: #007bff;"
                             type="success">Đăng nhập với tài khoản thường
                  </el-button>
                </div>
<!--                <div class="selectOptionLogin">-->
<!--                  <el-button class="btn btn-lg btn-block btn-login" @click="redirectToLoginOtp"-->
<!--                             style="background-color: #00A65A;"-->
<!--                             type="success">Đăng nhập OTP-->
<!--                  </el-button>-->
<!--                </div>-->
              </div>
            </div>
            <el-col :span="24" class="text-center">
              <div style="padding-top:10px">
                <table class="login-footer" style="margin:0 auto">
                  <tbody>
                  <tr>
                    <td>
                      <div class="support-text">
                        <div class="purple">
                          <strong>TƯ VẤN VÀ HỖ TRỢ
                            <strong class="orange f-16">18008000</strong>
                          </strong>
                        </div>
                        <div class="grey">Nhánh 2 (miễn phí)</div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </el-col>
            <div class="card-body login__form pt-0" v-if="showLogin">
              <el-form ref="formLogin" :model="formLogin" :rules="rules">
                <el-form-item>
                  <el-alert style="line-height: 1;" class="animated flipInX text-center"
                            v-bind:title="alert.message" v-if="alert.message" v-bind:type="alert.type"
                            :closable="false">
                  </el-alert>
                </el-form-item>
                <el-form-item prop="email">
                  <el-input name="email" v-model="formLogin.email"
                            @keyup.enter.native="submitForm('formLogin')" placeholder="Tài khoản"
                            title="Tài khoản"></el-input>
                </el-form-item>
                <el-form-item prop="password">
                  <el-input name="password" show-password type="password"
                            @keyup.enter.native="submitForm('formLogin')" v-model="formLogin.password"
                            placeholder="Password" title="Password"></el-input>
                </el-form-item>
                <el-form-item v-if="capCha" prop="capChaValue">
                  <el-row :gutter="0" style="margin-bottom:0">
                    <el-col v-loading="!capCha" :span="8">
                      <img :src="capCha"
                           style="width:100%;height:40px;border:1px solid black;border-radius:4px"
                           alt="Capcha"/>
                    </el-col>
                    <el-col :span="16">
                      <el-input name="capChaValue" placeholder="Nhập capcha"
                                v-model="formLogin.capChaValue" class="input-with-select">
                        <el-button slot="append" @click.prevent="refreshCapCha"
                                   icon="el-icon-refresh"></el-button>
                      </el-input>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-form-item class="m-0">
                  <div class="d-flex justify-content-between">
                    <el-checkbox label="Nhớ mật khẩu" v-model="formLogin.rememberPassword">
                    </el-checkbox>
                  </div>
                  <div>
                    <el-button class="btn btn-lg btn-block btn-login"
                               style="background-color: #00A65A;" type="primary"
                               @click="submitForm('formLogin')">Đăng nhập
                    </el-button>
                  </div>
                  <p class="text-center m-0" style="color: #0C9AE3;">Hoặc</p>
                </el-form-item>
              </el-form>
              <div class="row">
<!--                <div class="col-md-6">-->
<!--                  <el-link :href="'/login-otp'" class="d-block" :underline="false">-->
<!--                    <el-button class="btn btn-lg btn-block btn-login" type="primary">Đăng nhập OTP-->
<!--                    </el-button>-->
<!--                  </el-link>-->
<!--                </div>-->
                <div class="col-md-12">
                  <el-button @click.prevent="linkToSSO()" class="btn btn-lg btn-block" type="danger">
                    Đăng nhập SSO
                  </el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4"></div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../_helpers/api';
import constant_api from '../_helpers/constant_api';
import {
  mapState,
  mapActions
} from 'vuex';

export default {
  name: "Login",
  metaInfo: {
    title: 'Học bạ số',
    titleTemplate: '%s | Đăng nhập',
    meta: [{
      vmid: 'description',
      name: 'description',
      content: ''
    }]
  },
  computed: {
    ...mapState('account', ["status", 'user', 'capCha']),
    ...mapState({
      alert: state => state.alert
    }),
  },
  methods: {
    redirectToLoginOtp() {
      let uri = "login-otp"
      window.location.href = uri;
    },
    linkToSSO2() {
      let uri = "https://sso-edu.viettel.vn/?page=Account.App.login&userId=5f9a6bb662aa4a0d003eceb2"
      window.location.href = uri;
    },
    ...mapActions('account', ['login', 'reGetCapCha']),
    ...mapActions({
      clearAlert: 'alert/clear'
    }),
    refreshCapCha() {
      if (this.formLogin.email) {
        this.reGetCapCha({
          email: this.formLogin.email
        })
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitted = true;
          const {
            email,
            password,
            rememberPassword,
            capChaValue,
          } = this.formLogin;
          if (email && password) {
            this.login({
              email,
              password,
              rememberPassword,
              capChaValue
            })
          }
        } else {
          return false;
        }
      });
    },
    objectToQueryString(obj) {
      let str = [];
      for (let p in obj)
        if (obj.hasOwnProperty(p)) {
          str.push(p + "=" + obj[p]);
        }
      return str.join("&");
    },
    generateString(length) {
      let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }

      return result;
    },

    thongBao(t, e) {
      let msg = "";
      let cl = "";
      if (e) {
        msg = e;
      }
      let type = "success";
      if (t) {
        type = t
      }
      if (type == "success") {
        cl = "dts-noty-success"
      }
      if (type == "warning") {
        cl = "dts-noty-warning"
      }
      if (type == "error") {
        cl = "dts-noty-error"
      }
      if (type == "info") {
        cl = "dts-noty-info"
      }
      this.$message({
        customClass: cl,
        showClose: true,
        message: msg,
        type: t,
        duration: 3000
      });
    },
    linkToSSO() {
      let url = constant_api.login.getCodeSSO;
      console.log('url:' + url)
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      api.get(url, {}).then(
          response => {
            console.log("Res:")
            console.log(response)
            var redirect_uri = location.protocol + '//' + location.host;
            console.log(redirect_uri)
            if (response.data.code == 200) {
              loading.close();
              let code_challenge = response.data.data.code_challenge;
              let code_verifyer = response.data.data.code_verifier;
              localStorage.setItem('code_verifyer', code_verifyer)
              let params = {
                response_type: 'code',
                redirect_uri: redirect_uri,
                client_id: 'hocbadientu_client',
                state: this.generateString(5),
                scope: 'openid profile offline_access esmartup',
                code_challenge: code_challenge,
                code_challenge_method: 'S256',
              };
              console.log(params)
              let query = this.objectToQueryString(params);
              let urlLogin = 'https://id.nentanggiaoduc.edu.vn/connect/authorize?' + query;
              console.log("urlLogin")
              console.log(JSON.stringify(urlLogin))
              loading.close();
              window.location.href = urlLogin;
            } else {
              this.thongBao('error', response.data.msg)
              loading.close();
            }
          }
      )
    }
  },
  data() {
    return {
      checkLogin: false,
      showLogin: false,
      rememberPassword: true,
      isLogin: false,
      submitted: false,
      capChaValue: "",
      formLogin: {
        email: '',
        password: '',
        rememberPassword: true,
        capChaValue: ""
      },
      rules: {
        email: [{
          required: true,
          message: 'Email là bắt buộc.',
          trigger: 'change'
        },
          // { type: 'email', message: 'Không đúng định dạng email', trigger: ['blur', 'change'] },
        ],
        password: [{
          required: true,
          message: 'Mật khẩu là bắt buộc.',
          trigger: 'change'
        },
          {
            min: 8,
            message: 'Mật khẩu chứa ít nhất 8 ký tự.',
            trigger: 'change'
          },
          {
            max: 20,
            message: 'Mật khẩu chứa tối đa 12 ký tự.',
            trigger: 'change'
          },
        ],
        capChaValue: [{
          required: true,
          message: 'Mã captcha là bắt buộc.',
          trigger: 'change'
        },
          // { type: 'email', message: 'Không đúng định dạng email', trigger: ['blur', 'change'] },
        ],
      }
    }
  },
  mounted() {
    this.checkLogin = this.$route.query.check;
    console.log("check" + this.checkLogin)
    if (this.checkLogin) {
      this.showLogin = true;
    }
    sessionStorage.clear()
    window.localStorage.removeItem('userSso');
    window.localStorage.removeItem('id_token');
    window.localStorage.removeItem('user');
    window.localStorage.removeItem('uriConnectWebsocket');
    window.localStorage.removeItem('messInfoOtpCode');
    window.localStorage.removeItem('OtpCode');
    window.localStorage.removeItem('dataTokenVnptCa');
    window.localStorage.removeItem('routerLoginSmartCa');
    window.localStorage.removeItem('kieuKyHocBaGiaoVien');
    window.localStorage.removeItem('kieuKyBiaGiaoVien');
    if (localStorage.getItem('remember') && localStorage.getItem('remember') != '' && localStorage.getItem(
        'remember') != null) {
      this.formLogin.email = (JSON.parse(localStorage.getItem('remember'))).email;
      this.formLogin.password = (JSON.parse(localStorage.getItem('remember'))).password;
    }
    if (localStorage.getItem('rememberCheck') != null) {
      this.formLogin.rememberPassword = true;
    } else {
      this.formLogin.rememberPassword = false;
    }
  }
}
</script>

<style scoped>
.selectOptionLogin {
  width: 100%;
  padding: 5px 15px;
}


.icon-support {
  width: 40px;
  height: 50px;
  background-size: cover;
  background-image: url('https://smas.edu.vn/Content/images/icon-support.png');
}

.icon {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 5px;
  vertical-align: middle;
}

.support-text {
  display: inline-block;
  vertical-align: middle;
  padding-left: 15px;
}

.purple {
  color: #027185;
}

.orange {
  color: #F5A623;
}
.viettel-group{
  color:white;
  /* font-weight:bold */
  font-size:14px
}
</style>