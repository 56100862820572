var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true, lock: true },
        },
      ],
      staticClass: "page page-file file detailHocBa thayHieTruong",
      attrs: { id: "detailHocBa" },
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "box-upload-file" },
        [
          _c(
            "el-row",
            {
              attrs: { gutter: 24 },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.getDuLieu(1)
                },
              },
            },
            [
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c("label", [_vm._v("Năm học")]),
                  _vm._v(" "),
                  _c("eselect", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      collapseTags: "",
                      placeholder: "Chọn",
                      filterable: "",
                      data: _vm.list_nam_hoc,
                      fields: ["name", "value"],
                    },
                    model: {
                      value: _vm.searchForm.year,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchForm, "year", $$v)
                      },
                      expression: "searchForm.year",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("div", { staticStyle: { color: "transparent" } }, [
                    _vm._v("Tìm kiếm"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "m-0",
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.getDuLieu(1)
                        },
                      },
                    },
                    [_vm._v("Tìm kiếm")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          false
            ? _c(
                "el-form",
                {
                  ref: "searchForm",
                  attrs: {
                    inline: "",
                    "label-position": "top",
                    rules: _vm.rulesSearch,
                    model: _vm.searchForm,
                    "hide-required-asterisk": true,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "d-inline-block",
                      attrs: { label: "Năm học", prop: "year" },
                    },
                    [
                      _c("eselect", {
                        staticStyle: { width: "150px" },
                        attrs: {
                          collapseTags: "",
                          placeholder: "Chọn",
                          filterable: "",
                          data: _vm.list_nam_hoc,
                          fields: ["name", "value"],
                        },
                        model: {
                          value: _vm.searchForm.year,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "year", $$v)
                          },
                          expression: "searchForm.year",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-top": "40px" },
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submitSearch("searchForm")
                            },
                          },
                        },
                        [_vm._v("Tìm kiếm\n        ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "page__content" }, [
        _c(
          "div",
          { staticClass: "listNew mb-5" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                ref: "listFile",
                attrs: {
                  "max-height": _vm.maxHeight,
                  border: "",
                  fit: "",
                  lazy: true,
                  data: _vm.danh_sach_phan_cong_ky
                    ? _vm.danh_sach_phan_cong_ky
                    : [],
                  width: "100%",
                },
              },
              [
                _c("el-table-column", {
                  staticStyle: { width: "50px" },
                  attrs: {
                    label: "STT",
                    width: "50",
                    type: "index",
                    "class-name": "text-center",
                    index: _vm.indexMethod,
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "Mã giáo viên",
                    width: "150",
                    align: "center",
                    property: "maGV",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  staticClass: "text-center",
                  attrs: {
                    label: "Tên giáo viên",
                    property: "tenGV",
                    "header-align": "center",
                    align: "left",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "Chức vụ",
                    width: "150",
                    "header-align": "center",
                    align: "left",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("p", [
                            _vm._v(_vm._s(_vm.getTenChucVu(scope.row.chucVu))),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "Tên trường",
                    width: "150",
                    align: "center",
                    property: "tenTruong",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  staticClass: "text-center",
                  attrs: {
                    label: "Tiêu đề ký thay KQHT",
                    property: "tieuDeKyThayKqht",
                    "header-align": "center",
                    align: "left",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  staticClass: "text-center",
                  attrs: {
                    label: "Tiêu đề ký phê duyệt",
                    property: "tieuDeKyPheDuyet",
                    "header-align": "center",
                    align: "left",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "Danh sách khối ký KQHT",
                    "header-align": "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value: scope.row.khoiHocDaChon,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "khoiHocDaChon", $$v)
                                },
                                expression: "scope.row.khoiHocDaChon",
                              },
                            },
                            _vm._l(scope.row.khoiHocs, function (item) {
                              return _c(
                                "el-checkbox",
                                {
                                  key: item.number,
                                  attrs: { label: item.number },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.name) +
                                      "\n              "
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "Danh sách ký Quá trình học tập",
                    "header-align": "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value: scope.row.khoiHocKyBiaDaChon,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "khoiHocKyBiaDaChon", $$v)
                                },
                                expression: "scope.row.khoiHocKyBiaDaChon",
                              },
                            },
                            _vm._l(scope.row.khoiHocs, function (item) {
                              return _c(
                                "el-checkbox",
                                {
                                  key: item.number,
                                  attrs: { label: item.number },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.name) +
                                      "\n              "
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    width: "150",
                    label: "Hành động",
                    "class-name": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            { staticClass: "btn__table_action" },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    content: "Phân công ký",
                                    placement: "top",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      "data-option": "",
                                      size: "mini",
                                      type: "primary",
                                      icon: "el-icon-circle-plus",
                                      disabled: _vm.isDisabledPhanCongKy(
                                        scope.row
                                      ),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.PhanCongKy(scope.row)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    content: "Chỉnh sửa tiêu đề ký",
                                    placement: "top",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      size: "mini",
                                      type: "primary",
                                      icon: "el-icon-edit-outline",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.hienThiChinhSuaTieuDe(
                                          scope.row
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-between align-items-center",
              },
              [
                _vm.total
                  ? [
                      _c("small", [
                        _c("i", [
                          _vm._v(
                            "Danh sách có " + _vm._s(_vm.total) + " cán bộ"
                          ),
                        ]),
                      ]),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-dialog",
              {
                attrs: {
                  title: "Chỉnh sửa tiêu đề ký",
                  width: "50%",
                  center: "",
                  "custom-class": "thongTinKyTheoMonHoc",
                  "append-to-body": "",
                  visible: _vm.show_update,
                  "show-close": true,
                  "close-on-click-modal": false,
                  "close-on-press-escape": false,
                  "before-close": _vm.closeDialogFormUpdate,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.show_update = $event
                  },
                },
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.thongTinTieuDeChinhSua,
                      rules: _vm.rules,
                    },
                  },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 24 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Tên trường:",
                                  prop: "tenTruong",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "text",
                                    placeholder: "Nhập...",
                                    clearable: "",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.handleInputChange("tenTruong")
                                    },
                                  },
                                  model: {
                                    value: _vm.thongTinTieuDeChinhSua.tenTruong,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.thongTinTieuDeChinhSua,
                                        "tenTruong",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "thongTinTieuDeChinhSua.tenTruong",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("el-col", { attrs: { span: 12 } }, [
                          _c(
                            "div",
                            { staticClass: "custom-textarea inline" },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Tiêu đề ký thay:",
                                    prop: "tieuDeKyThayKqht",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      placeholder: "Nhập...",
                                      rows: 1,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.handleInputChange(
                                          "tieuDeKyThayKqht"
                                        )
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.thongTinTieuDeChinhSua
                                          .tieuDeKyThayKqht,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.thongTinTieuDeChinhSua,
                                          "tieuDeKyThayKqht",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "thongTinTieuDeChinhSua.tieuDeKyThayKqht",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm.thongTinTieuDeChinhSua.tieuDeKyThayKqht
                                    ? _c("i", {
                                        staticClass:
                                          "el-icon-circle-close clear-icon",
                                        on: {
                                          click: function ($event) {
                                            return _vm.clearInput(
                                              "tieuDeKyThayKqht"
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-col", { attrs: { span: 12 } }, [
                          _c(
                            "div",
                            { staticClass: "custom-textarea" },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Tiêu đề phê duyệt:",
                                    prop: "tieuDeKyPheDuyet",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      placeholder: "Nhập...",
                                      rows: 1,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.handleInputChange(
                                          "tieuDeKyPheDuyet"
                                        )
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.thongTinTieuDeChinhSua
                                          .tieuDeKyPheDuyet,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.thongTinTieuDeChinhSua,
                                          "tieuDeKyPheDuyet",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "thongTinTieuDeChinhSua.tieuDeKyPheDuyet",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm.thongTinTieuDeChinhSua.tieuDeKyPheDuyet
                                    ? _c("i", {
                                        staticClass:
                                          "el-icon-circle-close clear-icon",
                                        on: {
                                          click: function ($event) {
                                            return _vm.clearInput(
                                              "tieuDeKyPheDuyet"
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini" },
                        on: { click: _vm.closeDialogFormUpdate },
                      },
                      [_vm._v("Hủy")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          disabled: !_vm.can_hoi_truoc_khi_thoat,
                          size: "mini",
                          type: "primary",
                        },
                        on: { click: _vm.submitFormUpdate },
                      },
                      [_vm._v("\n            Lưu\n          ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "page__title d-flex align-items-center justify-content-between",
      },
      [_c("h1", { staticClass: "title" }, [_vm._v("Làm thay Hiệu trưởng")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }