<template>
    <div>
        <el-steps :active="active">
            <el-step title="Bước 1" icon="el-icon-upload"></el-step>
            <el-step title="Bước 2" icon="el-icon-edit"></el-step>
            <el-step title="Bước 3" icon="el-icon-picture"></el-step>
        </el-steps>

        <!--        <el-button size="mini" style="margin-top: 12px;" @click="next">Tiếp theo</el-button>-->
        <el-row v-show="active === 1">
            <el-col :span="11"></el-col>
            <el-col :span="2">
                <!--                <div class="test-button">-->
                <!--                    <label style="background:#28a745" class="btn" for="uploads"><i class="el-icon-upload"></i></label>-->
                <!--                    <input type="file" id="uploads" ref="inputFile" style="position:absolute; clip:rect(0 0 0 0);"-->
                <!--                           accept="image/png, image/jpeg, image/gif, image/jpg" @change="uploadImg($event, 1)">-->
                <!--                </div>-->
            </el-col>
            <el-col :span="11">
                <el-row :gutter="24">
                    <el-col :span="24" class="text-right">
                        <ol class="taiLieu">
                            <!--              <li><a href="#" target="_blank" title="Tài liệu hướng dẫn">Tài liệu HD</a></li>-->
                            <!--              <li><a href="#" target="_blank" title="Video hướng dẫn">Video HD</a></li>-->
                            <li><a href="images/huong_dan_upload_anh.png" target="_blank" title="Ảnh chữ ký mẫu">Ảnh
                                    hướng dẫn</a>
                            </li>
                        </ol>
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="24" class="text-center">
                <el-upload :show-file-list="false" :auto-upload="false" class="upload-demo" drag action="/"
                    :on-change="uploadFile">
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">Kéo thả file ở đây hoặc <em>click để upload</em></div>
                    <div class="el-upload__tip" slot="tip">Chỉ upload file định dạng ảnh</div>
                </el-upload>

            </el-col>
        </el-row>

        <div v-if="active === 2" class="cut" :style="styleBox">
            <vue-cropper ref="cropper" :img="option.img" :output-size="option.size" :output-type="option.outputType"
                :info="true" :full="option.full" :fixed="option.fixed" :fixed-number="option.fixedNumber"
                :can-move="option.canMove" :can-move-box="option.canMoveBox" :fixed-box="option.fixedBox"
                :original="option.original" :mode="option.mode" :auto-crop="option.autoCrop"
                :auto-crop-width="option.autoCropWidth" :auto-crop-height="option.autoCropHeight"
                :center-box="option.centerBox" @real-time="realTime" :high="option.high" @img-load="imgLoad"
                mode="cover" :max-img-size="option.max" @crop-moving="cropMoving"></vue-cropper>
            <div class="mt-3">
                <el-row :gutter="20">
                    <el-col :span="15">
                        <el-radio v-model="typeImage" label="1">Ảnh chưa xoá nền</el-radio>
                        <el-radio v-model="typeImage" label="2">Ảnh đã xoá nền</el-radio>
                    </el-col>
                    <el-col :span="9">
                        <el-button size="mini" :disabled="typeImage!=1&&typeImage!=2" v-if="typeImage==1" @click="next"
                            type="primary">Tiếp theo</el-button>
                        <el-button size="mini" :disabled="typeImage!=1&&typeImage!=2" v-if="typeImage==2"
                            @click="emitDataImg" type="primary">Xác nhận</el-button>
                        <el-button size="mini" :disabled="typeImage!=1&&typeImage!=2" v-if="!typeImage" type="primary">
                            Chọn loại ảnh</el-button>
                    </el-col>
                </el-row>
            </div>
        </div>

        <div v-show="active === 3" class="step3">
            <div id="boxCanvas1" class="box1 bd " :class="{ flashBorder: alertNeed }">
                <div>
                    <canvas id="signId" @click="canvasClick">
                    </canvas>
                </div>
            </div>
            <div class="box2 ">
                <div class="mb-2 btn_remove">
                    <el-button type="primary" size="mini" @click.prevent="deleteBackground">Xóa nền
                    </el-button>
                </div>
                <div>
                    <div class="display-color"><span class="mr-2">Mã màu : {{chooseColor}}</span> <span
                            :style="'border: 1px solid #666; display:inline-block;width: 50px; height: 20px;'+'background-color:'+chooseColor"></span>
                    </div>
                </div>
            </div>
            <div id="boxCanvas2" class="box3 bd">
                <div v-loading="loading" element-loading-text="Đang xoá nền..."
                    element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)"
                    :style="{'display':'inline-block','width': previews.w + 'px', 'height': previews.h + 'px',  'overflow': 'hidden', 'margin-tops': '5px'}">
                    <img :src="dataBase64_remove" :style="dataBase64" alt="prev">
                </div>

            </div>

        </div>


    </div>
</template>

<script>
    import {
        VueCropper
    } from 'vue-cropper';
    import ESelect from "../pages/Ui/ESelect";
    // import { ColorPicker } from 'vue-color-kit'
    // import 'vue-color-kit/dist/vue-color-kit.css'

    import replaceColor from 'replace-color';
    import ImageChooseColor from "./ImageChooseColor";

    export default {
        name: "CropImage",
        props: [
            'showCrop',
            'option',
            'styleBox',
            'step',
        ],
        watch: {
            showCrop(val) {
                if (val) {
                    // this.$refs.inputFile.reset();
                    this.previews = {};
                    this.dataBase64_remove = '';
                    this.dataBase64 = '';
                    this.option.img = '';
                    this.refreshCrop();
                }
            }
        },
        data() {
            return {
                typeImage: '1',
                list_type_image: [{
                        name: 'Chưa xoá nền',
                        value: 1
                    },
                    {
                        name: 'Đã xoá nền',
                        value: 2
                    },
                ],
                alertNeed: false,
                active: 1,
                checkReplace: false,
                loading: false,
                model: false,
                modelSrc: '',
                crap: false,
                previews: {},
                chooseColor: '#409EFF',
                show: true,
                suckerCanvas: null,
                suckerArea: [],
                isSucking: false,
                dataBase64: '',
                dataBase64_remove: '',
            }
        },
        components: {
            ImageChooseColor,
            VueCropper,
            'eselect': ESelect,
        },
        methods: {
            emitDataImg() {
                this.$confirm('Xác nhận tải ảnh lên?', 'Thông báo', {
                        confirmButtonText: 'Xác nhận',
                        cancelButtonText: 'Hủy',
                    })
                    .then(_ => {
                        this.$emit('uploadLuon', this.dataBase64)
                    })
                    .catch(_ => {});

            },
            uploadFile(file) {
                let num = 1;
                let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

                if (!allowedExtensions.exec(file.name)) {
                    this.$alert('Vui lòng chỉ tải lên file định dạng ảnh', 'Thông báo', {
                        confirmButtonText: 'Đóng',
                        type: 'error',
                        callback: action => {}
                    });

                    return false;
                }

                let reader = new FileReader()
                reader.readAsDataURL(file.raw);
                reader.onload = (e) => {
                    // console.log(e.target.result);
                    // console.log(window.URL.createObjectURL(new Blob([e.target.result])));
                    // console.log(e.data);
                    let data;
                    if (typeof e.target.result === 'object') {
                        // 把Array Buffer转化为blob 如果是base64不需要
                        data = window.URL.createObjectURL(new Blob([e.target.result]))
                    } else {
                        data = e.target.result
                    }
                    if (num === 1) {
                        this.option.img = data
                    } else if (num === 2) {
                        this.example2.img = data
                    }
                    this.active = 2;
                    this.$emit('goStep', this.active)
                }
                // 转化为base64
                // reader.readAsDataURL(file)
                // 转化为blob
                // reader.readAsArrayBuffer(file)

                // console.log(file)
            },

            next() {
                if (this.active++ > 2) this.active = 3;
                this.$emit('goStep', this.active)
            },

            changeColor(color) {
                if (color) {
                    this.chooseColor = color.hex;
                }
            },
            thongBao(t, e) {
                let msg = "";
                let cl = "";
                if (e) {
                    msg = e;
                }
                let type = "success";
                if (t) {
                    type = t
                }
                if (type == "success") {
                    cl = "dts-noty-success"
                }
                if (type == "warning") {
                    cl = "dts-noty-warning"
                }
                if (type == "error") {
                    cl = "dts-noty-error"
                }
                if (type == "info") {
                    cl = "dts-noty-info"
                }
                this.$message({
                    customClass: cl,
                    showClose: true,
                    message: msg,
                    type: t,
                    duration: 3000
                });
            },
            deleteBackground() {
                console.log(this.chooseColor)
                if (this.chooseColor == '#409EFF') {
                    this.alertNeed = true;
                    this.thongBao('error', 'Chọn màu nền trên ảnh gốc rồi thực hiện thao tác xoá nền.');
                    setTimeout(() => this.alertNeed = false, 5000);
                    return;
                }
                this.loading = true;
                this.checkReplace = false;
                replaceColor({
                        image: this.dataBase64,
                        colors: {
                            type: 'hex',
                            targetColor: this.chooseColor,
                            replaceColor: '#00000000'
                        },
                        deltaE: 10
                    })
                    .then((jimpObject) => {

                        jimpObject.getBase64Async('image/png').then(data => {
                            console.log("datadatadatadatadata" + data)
                            this.$emit('getBase64', data)
                            this.dataBase64_remove = data;
                            this.loading = false;
                            this.checkReplace = true;
                        })

                    })
                    .catch((err) => {
                        console.log(err)
                        this.loading = false;
                    })
            },

            openSucker(isOpen) {
                if (isOpen) {
                    // ... canvas be created
                    this.suckerCanvas = document.getElementById("signId");

                } else {
                    this.suckerCanvas && this.suckerCanvas.remove
                }
            },
            startCrop() {
                // start
                this.crap = true
                this.$refs.cropper.startCrop()
            },
            stopCrop() {
                //  stop
                this.crap = false
                this.$refs.cropper.stopCrop()
            },
            clearCrop() {
                // clear
                this.$refs.cropper.clearCrop()
            },
            refreshCrop() {
                // clear
                this.$refs.cropper.refresh()
            },

            finish(type) {
                // 输出
                // var test = window.open('about:blank')
                // test.document.body.innerHTML = '图片生成中..'
                if (type === 'blob') {
                    this.$refs.cropper.getCropBlob((data) => {
                        console.log(data);
                        var img = window.URL.createObjectURL(data)
                        this.model = true
                        this.modelSrc = img
                    })
                } else {
                    this.$refs.cropper.getCropData((data) => {
                        this.model = true
                        this.modelSrc = data
                    })
                }
            },
            // 实时预览函数
            realTime(data) {
                this.previews = data
                let dataPreview = data;
                this.$refs.cropper.getCropData((data) => {
                    let canvas = document.getElementById("signId");
                    let boxCanvas1 = document.getElementById("boxCanvas1");
                    let boxCanvas2 = document.getElementById("boxCanvas2");

                    if (canvas) {
                        let ctx = canvas.getContext("2d");

                        let image = new Image();
                        image.onload = function () {
                            canvas.width = dataPreview.w;
                            canvas.height = dataPreview.h;
                            boxCanvas1.style.height = (dataPreview.h + 4) + 'px'
                            boxCanvas2.style.height = (dataPreview.h + 4) + 'px'
                            ctx.drawImage(image, 0, 0, dataPreview.w, dataPreview.h);
                        };
                        image.src = data;
                        this.dataBase64 = data;
                        this.dataBase64_remove = data;
                        // this.$emit('getBase64', data);
                    }
                    // do something
                    // this.$emit('getBase64', data)
                })
            },

            canvasClick(e) {
                let x, y;
                if (e.offsetX) {
                    x = e.offsetX;
                    y = e.offsetY;
                } else if (e.layerX) {
                    x = e.layerX;
                    y = e.layerY;
                }

                let canvas = document.getElementById("signId");

                let p = canvas.getContext('2d').getImageData(x, y, 1, 1).data;

                this.chooseColor = this.rgbToHex(p[0], p[1], p[2]);
            },

            rgbToHex(r, g, b) {
                return "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
            },

            componentToHex(c) {
                let hex = c.toString(16);
                return hex.length == 1 ? "0" + hex : hex;
            },

            finish2(type) {
                this.$refs.cropper2.getCropData((data) => {
                    this.model = true
                    this.modelSrc = data
                })
            },
            finish3(type) {
                this.$refs.cropper3.getCropData((data) => {
                    this.model = true
                    this.modelSrc = data
                })
            },

            uploadImg(e, num) {
                //上传图片
                // this.option.img
                var file = e.target.files[0]
                if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
                    alert('Không đúng định dạng ảnh')
                    return false
                }
                var reader = new FileReader()
                reader.onload = (e) => {
                    let data
                    if (typeof e.target.result === 'object') {
                        // 把Array Buffer转化为blob 如果是base64不需要
                        data = window.URL.createObjectURL(new Blob([e.target.result]))
                    } else {
                        data = e.target.result
                    }
                    if (num === 1) {
                        this.option.img = data
                    } else if (num === 2) {
                        this.example2.img = data
                    }
                }
                // 转化为base64
                // reader.readAsDataURL(file)
                // 转化为blob
                reader.readAsArrayBuffer(file)
            },
            imgLoad(msg) {
                console.log(msg)
            },
            cropMoving(data) {
                console.log(data, '截图框当前坐标')
            }
        },
        mounted() {
            this.active = this.step ? this.step : 1;
            // console.log(window['vue-cropper'])
        }
    }
</script>


<style scoped>
    .cut {
        margin: 30px auto;
    }

    .c-item {
        max-width: 800px;
        margin: 10px auto;
        margin-top: 20px;
    }

    .content {
        margin: auto;
        max-width: 1200px;
        margin-bottom: 100px;
    }

    .test-button {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
    }

    .btn {
        display: inline-block;
        line-height: 1;
        white-space: nowrap;
        cursor: pointer;
        background: #fff;
        border: 1px solid #c0ccda;
        color: #1f2d3d;
        text-align: center;
        box-sizing: border-box;
        outline: none;
        margin: 20px 10px 0px 0px;
        padding: 9px 15px;
        font-size: 14px;
        border-radius: 4px;
        color: #fff;
        background-color: #50bfff;
        border-color: #50bfff;
        transition: all .2s ease;
        text-decoration: none;
        user-select: none;
    }

    .des {
        line-height: 30px;
    }

    code.language-html {
        padding: 10px 20px;
        margin: 10px 0px;
        display: block;
        background-color: #333;
        color: #fff;
        overflow-x: auto;
        font-family: Consolas, Monaco, Droid, Sans, Mono, Source, Code, Pro, Menlo, Lucida, Sans, Type, Writer, Ubuntu, Mono;
        border-radius: 5px;
        white-space: pre;
    }

    .show-info {
        margin-bottom: 50px;
    }

    .show-info h2 {
        line-height: 50px;
    }

    /*.title, .title:hover, .title-focus, .title:visited {
color: black;
}*/

    .title {
        display: block;
        text-decoration: none;
        text-align: center;
        line-height: 1.5;
        margin: 20px 0px;
        background-image: -webkit-linear-gradient(left, #3498db, #f47920 10%, #d71345 20%, #f7acbc 30%, #ffd400 40%, #3498db 50%, #f47920 60%, #d71345 70%, #f7acbc 80%, #ffd400 90%, #3498db);
        color: transparent;
        -webkit-background-clip: text;
        background-size: 200% 100%;
        animation: slide 5s infinite linear;
        font-size: 40px;
    }

    .test {
        height: 500px;
    }

    .model {
        position: fixed;
        z-index: 10;
        width: 100vw;
        height: 100vh;
        overflow: auto;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.8);
    }

    .model-show {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 100vh;
    }

    .model img {
        display: block;
        margin: auto;
        max-width: 80%;
        user-select: none;
        background-position: 0px 0px, 10px 10px;
        background-size: 20px 20px;
        background-image: linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%, #eee 100%), linear-gradient(45deg, #eee 25%, white 25%, white 75%, #eee 75%, #eee 100%);
    }

    .c-item {
        display: block;
        user-select: none;
    }

    a {
        font-size: 15px;
        color: blue;
        text-decoration: revert;
    }

    li {
        list-style-type: none;
    }

    @keyframes slide {
        0% {
            background-position: 0 0;
        }

        100% {
            background-position: -100% 0;
        }
    }

    @-webkit-keyframes blink {
        50% {
            border-color: #ff0000;
        }
    }

    .flashBorder {
        border: 4px solid blue;
        text-align: center;
        -webkit-animation: blink .3s step-end infinite alternate;
    }

    .bd {

        border: 2px solid seagreen;
    }
</style>