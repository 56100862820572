<template>
  <div id="detailHocBa" class="page page-file file detailHocBa" v-loading.fullscreen.lock="fullscreenLoading">
    <div class="box-upload-file">
      <el-row :gutter="24">
        <el-col v-if="this.user.role==8"  :xs="12" :sm="5" :md="4" :lg="4">
          <label>Đơn vị sở</label>
          <eselect collapseTags
                   style="width: 100%"
                   v-model="searchForm.maSo" :placeholder="'Chọn'" @change="layDanhSachPhong()" filterable
                   :data="danh_sach_so"
                   :fields="['name','value']"/>
        </el-col>
        <el-col v-if="this.user.role==7||this.user.role==8"  :xs="12" :sm="5" :md="4" :lg="4">
          <label>Đơn vị</label>
          <eselect collapseTags
                   style="width: 100%"
                   v-model="searchForm.donVi" :placeholder="'Chọn'" @change="getTruongHoc()" filterable
                   :data="danh_sach_phong"
                   :fields="['name','value']"/>
        </el-col>
        <el-col :xs="12" :sm="5" :md="4" :lg="4">
          <label>Cấp học</label>
          <eselect collapseTags
                   :disabled="list_cap_hoc.length<2"
                   @change="getTruongHoc()"
                   v-model="searchForm.capHoc" :placeholder="'Chọn'" filterable :data="list_cap_hoc"
                   :fields="['name','value']"/>
        </el-col>
        <el-col v-if="this.user.role!=4" :xs="12" :sm="6" :md="4" :lg="4">
          <label>Trường học</label>
          <eselect collapseTags
                   v-model="searchForm.truongHoc" :placeholder="'Chọn'" filterable :data="danh_sach_truong"
                   :fields="['name','value']"/>
        </el-col>
        <el-col :xs="12" :sm="6" :md="4" :lg="4">
          <label>Tên đăng nhập</label>
          <el-input style="width:100%" auto-complete="off" v-model="searchForm.tenDangNhap" clearable
                    placeholder="Nhập...">
          </el-input>
        </el-col>
        <el-col :xs="12" :sm="6" :md="4" :lg="4">
          <label style="color: transparent">Tìm kiếm</label>
          <el-button @click.prevent="getData()" style="display: block" type="primary">Tìm kiếm</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="page__title d-flex align-items-center justify-content-between">
      <h1 class="title">Danh sách tài khoản</h1>
    </div>
    <el-table
        :data="danh_sach_tai_khoan"
        border
        width="100%"
        v-loading="loading"
        element-loading-text="Loading..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <el-table-column
          header-align="center"
          align="center"
          label="STT"
          type="index"
            :index="indexMethod">
        </el-table-column>
        <el-table-column
            header-align="center"
            align="left" property="tenHienThi"
            label="Tên tài khoản">
        </el-table-column>
        <el-table-column
            header-align="center"
            align="left" property="username"
            label="Tên đăng nhập">
        </el-table-column>
        <el-table-column label="Vai trò" header-align="center" align="center">
          <template slot-scope="scope">
            {{ getNameRole(scope.row.role) }} - [{{ scope.row.role }}]
          </template>
        </el-table-column>
        <el-table-column label="Trạng thái" header-align="center" align="center">
          <template slot-scope="scope">
          <div v-if="scope.row.trangThai==1">
            <el-button size="mini" type="success">Đang hoạt động</el-button>
          </div>
          <div v-else>
            <el-button size="mini" type='warning'>Ngừng hoạt động</el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          header-align="center"

          label="Hành động">
        <template slot-scope="scope">
          <el-tooltip v-if="scope.row.trangThai==1" class="item" effect="dark" content="Khoá tài khoản" placement="top">
            <el-button @click.prevent="khoaTaiKhoan(scope.row)" size="mini" icon="el-icon-lock"
                       type="warning"></el-button>
          </el-tooltip>
          <el-tooltip v-if="scope.row.trangThai!=1" class="item" effect="dark" content="Mở khoá tài khoản"
                      placement="top">
            <el-button @click.prevent="khoaTaiKhoan(scope.row)" size="mini" icon="el-icon-unlock"
                       type="success"></el-button>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="Đổi mật khẩu" placement="top">
            <el-button @click.prevent="doiMatKhau(scope.row)" size="mini" icon="el-icon-key"
                       type="danger"></el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

<!--    <div class="text-right mt-2">-->
<!--      <pagination v-if="paginate.total" :value="paginate.current"-->
<!--                  :page-count="getTotalPage(paginate.total)" :click-handler="getData" :prev-text="'Trước'"-->
<!--                  :next-text="'Sau'" :container-class="'el-pager'" :pageClass="'number'">-->

<!--      </pagination>-->
<!--    </div>-->
    <div class="mt-05">
      <el-row :gutter="20">
        <el-col :span="8">
          <chonSoLuong :show="10" @chonXongSoLuong="ChonSoLuongBanGhi($event)"></chonSoLuong>
        </el-col>
        <el-col :span="16" class="text-right">
          <small class="pd-r-10">
            <i>Danh sách có {{ paginate.total }} bản ghi </i>
          </small>
          <pagination v-if="paginate.total" :value="paginate.current"
                      :page-count="getTotalPage(paginate.total)" :click-handler="getData" :prev-text="'Trước'"
                      :next-text="'Sau'" :container-class="'el-pager'" :pageClass="'number'">

          </pagination>
        </el-col>
      </el-row>
    </div>
    <el-dialog title="Đổi mật khẩu" width="50%" center :visible.sync="show_update"
               class="updateMatKhau"
               :closeOnPressEscape="false"
               :closeOnClickModal="false"
    >
      <div>
        <el-row :gutter="24">
          <el-col :span="12">
            <label>Mật khẩu mới</label>
            <el-input placeholder="Mật khẩu mới"  auto-complete="off" v-model="matKhau.moi" clearable show-password
                      maxlength="50"
                      show-word-limit></el-input>
          </el-col>
          <el-col :span="12">
            <label>Xác nhận mật khẩu mới</label>
            <el-input placeholder="Nhập lại khẩu mới" v-model="matKhau.nhapLai" clearable show-password
                      maxlength="50"
                      show-word-limit></el-input>
          </el-col>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: right !important;">
        <el-button size="mini" @click="show_update = false" type="default">Đóng</el-button>
        <el-button size="mini" @click="xacNhanThayDoiMatKhau" type="danger">Đổi mật khẩu</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from "../_helpers/api";
import uri from "../api"
import constant from "../_helpers/constant_api";
import Pagination from "../components/Pagination";
import SelectNamHoc from "../components/SelectNamHoc";
import {mapActions, mapState} from 'vuex';
import constant_api from "../_helpers/constant_api";
import eselect from "../components/ESelect.vue";
import chonSoLuong from "./Ui/ChonSoLuong";

export default {
  name: "QuanLyNamHoc",
  metaInfo: {
    title: 'Quản lý tài khoản',
  },
  components: {
    eselect,
    Pagination,
    chonSoLuong,
    SelectNamHoc
  },

  computed: {
    ...mapState('account', [
      'user',
      'list_nam_hoc',
      'nam_hoc_hien_tai',
      'list_cap_hoc',
    ])
  },
  watch: {},
  data() {
    return {
      fullscreenLoading: false,
      searchForm: {
        maSo:'',
        donVi: '',
        capHoc: '',
        tenDangNhap: '',
        truongHoc: ''
      },
      loading: false,
      list_data: [],
      danh_sach_phong: [],
      danh_sach_so: [],
      danh_sach_truong: [],
      danh_sach_tai_khoan: [],
      paginate: {
        start: 0,
        limit: 10,
        current: 1,
        total: 0
      },
      dataUpdate: {},
      show_update: false,
      matKhau: {
        moi: '',
        nhapLai: '',
        email: ''
      }
    }
  },
  mounted() {
    if (this.list_cap_hoc.length == 1) {
      this.searchForm.capHoc = this.user.capHoc
    }
    console.log('mount quản lý tài khoản:')
    if (this.user.role == 7) {
      console.log('Tk sở')
      this.layDanhSachPhong();
    }
    if (this.user.role == 6) {
      console.log('Tk phòng')
      this.searchForm.donVi = this.user.maPhong;
    }
    if (this.user.role == 4) {
      console.log('tk trường')
      this.searchForm.truongHoc = this.user.maTruong;
    }
    if(this.user.role==8){
      this.layDanhSachSo();
    }
    this.getData(1)

  },
  methods: {
    ChonSoLuongBanGhi(e) {
      console.log('ChonSoLuongBanGhi')
      console.log(e)
      this.paginate.limit = e.soluong;
      this.getData(1);
    },
    xacNhanThayDoiMatKhau() {
      console.log('xacNhanThayDoiMatKhau')
      if (!this.matKhau.moi || this.matKhau.moi == '') {
        this.thongBao('error', 'Vui lòng nhập mật khẩu mới');
        return
      }
      if (!this.matKhau.nhapLai || this.matKhau.nhapLai == '') {
        this.thongBao('error', 'Xác nhận mật khẩu mới');
        return
      }
      if (this.matKhau.moi != this.matKhau.nhapLai) {
        this.thongBao('error', 'Mật khẩu nhập lại không khớp');
        return;
      }
      let url = constant_api.taiKhoan.doiMatKhau;
      let params = {
        email: this.dataUpdate.username,
        password: this.matKhau.moi
      }

      this.$confirm('Xác nhận thay đổi mật khẩu?', 'Thông báo', {
        confirmButtonText: 'Thay đổi',
        cancelButtonText: 'Hủy',
        confirmButtonClass: 'el-button--danger'
      })
          .then(_ => {
            this.fullscreenLoading = true;
            api.post(url, params).then(
                response => {
                  console.log("Res:")
                  console.log(response)
                  if (response.data.code == 200) {
                    this.thongBao('success', response.data.msg)
                    this.getData(1)
                  } else {
                    this.thongBao("error", response.data.msg)
                  }
                  this.fullscreenLoading = false;
                }
            )
          })
          .catch(_ => {
          });
    },
    khoaTaiKhoan(item) {
      this.$confirm(item.trangThai != 1 ? 'Xác nhận mở khoá tài khoản?' : 'Xác nhận khoá tài khoản?', 'Thông báo', {
        confirmButtonText: item.trangThai == 1 ? 'Khoá tài khoản' : 'Mở khoá',
        cancelButtonText: 'Hủy',
        confirmButtonClass: item.trangThai == 1 ? 'el-button--danger' : 'el-button--success',
      })
          .then(_ => {
            let params = {
              username: item.username,
              role: item.role
            };
            let url = item.trangThai == 1 ? constant_api.taiKhoan.khoaTaiKhoan : constant_api.taiKhoan.moKhoaTaiKhoan;
            this.fullscreenLoading = true;
            api.post(url, params).then(
                response => {
                  this.fullscreenLoading = false;
                  console.log("Res:")
                  console.log(response)
                  if (response.data.code == 200) {
                    this.thongBao('success', response.data.msg)
                    this.getData(1)
                  } else {
                    this.thongBao("error", response.data.msg)
                  }
                }
            )
          })
          .catch(_ => {
          });
    },
    doiMatKhau(item) {
      console.log('đổi mật khẩu:')
      console.log(item)
      this.dataUpdate = JSON.parse(JSON.stringify(item))
      this.show_update = true;
    },
    getNameRole(role) {
      let result = '';
      if (role == 1) {
        result = 'Hiệu trưởng'
      }
      if (role == 2) {
        result = 'Phó hiệu trưởng'
      }
      if (role == 8) {
        result = 'Tổ trưởng chuyên môn'
      }
      if (role == 9) {
        result = 'Tổ phó chuyên môn'
      }
      if (role == 16) {
        result = 'Giám đốc'
      }
      if (role == 17) {
        result = 'Phó giám đốc'
      }
      if (role == 14) {
        result = 'Nhân viên thư viện'
      }
      if (role == 15) {
        result = 'Nhân viên thiết bị'
      }
      if (role == 3) {
        result = 'Nhân viên y tế'
      }
      if (role == 6) {
        result = 'Phòng giáo dục'
      }
      if (role == 4) {
        result = 'Trường học'
      }
      if (role == 5) {
        result = 'Nhân viên kỹ thuật nghiệp vụ'
      }
      if (role == 10) {
        result = 'Nhân viên kế toán'
      }
      if (role == 11) {
        result = 'Nhân viên văn thư'
      }
      if (role == 7) {
        result = 'Sở giáo dục'
      }
      if (role == 18) {
        result = 'Trợ giảng ngôn ngữ L1'
      }
      if (role == 19) {
        result = 'Trợ giảng ngôn ngữ L2'
      }
      if (role == 12) {
        result = 'Nhân viên cấp dưỡng'
      }
      if (role == 13) {
        result = 'Nhân viên bảo mẫu'
      }
      if (role == 20) {
        result = 'Nhân viên thủ quỹ'
      }
      if (role == 21) {
        result = 'Nhân viên công nghệ thông tin'
      }
      if (role == 22) {
        result = 'Nhân viên hỗ trợ GD khuyết tật'
      }
      if (role == 23) {
        result = 'Nhân viên giáo vụ'
      }
      if (role == 31) {
        result = 'Nấu ăn'
      }
      if (role == 26) {
        result = 'Trưởng phòng'
      }
      if (role == 27) {
        result = 'Phó trưởng phòng'
      }
      if (role == 32) {
        result = 'Tổ trưởng'
      }
      if (role == 33) {
        result = 'Tổ phó'
      }
      if (role == 34) {
        result = 'Chuyên viên'
      }
      if (role == 35) {
        result = 'Nhân viên lái xe'
      }
      if (role == 36) {
        result = 'Cấp THPT'
      }
      if (role == 28) {
        result = 'Cấp THCS'
      }
      if (role == 29) {
        result = 'Cấp Tiểu học'
      }
      if (role == 30) {
        result = 'Cấp mầm non'
      }
      if (role == 40) {
        result = 'Giáo viên'
      }
      if (role == 41) {
        result = 'Giáo viên chủ nhiệm'
      }


      return result
    },
    getData(select) {
      console.log('getData:' + select)
      let start = (select - 1) * this.paginate.limit ? (select - 1) * this.paginate.limit : 0;
      this.paginate.start = start;
      this.paginate.current = select;
      let url = constant_api.hocba.danhSachTaiKhoan;
      let params = {
        maSo: this.user.maSo,
        capHoc: this.searchForm.capHoc,
        maPhong: this.searchForm.donVi,
        maTruong: this.searchForm.truongHoc,
        tenTaiKhoan: this.searchForm.tenDangNhap,
        start: this.paginate.start,
        limit: this.paginate.limit
      }
      if(this.user.role==8){
        params.maSo = this.searchForm.maSo
      }
      this.show_update = false;
      this.danh_sach_tai_khoan = [];
      this.paginate.total = 0;
      this.fullscreenLoading = true;
      api.get(url, params).then(
          response => {
            console.log("Res:")
            console.log(response)
            if (response.data.code == 200) {
              this.danh_sach_tai_khoan = response.data.data.data;
              this.paginate.total = response.data.data.total;
            } else {
              this.thongBao("error", 'Không lấy được dữ liệu')
            }
            this.fullscreenLoading = false;
            console.log('Danh sách tài khoản:')
            console.log(this.danh_sach_tai_khoan)
          }
      )
    },
    getTruongHoc() {
      this.searchForm.truongHoc = '';
      console.log('lấy danh sách trường')
      let url = constant_api.school.listSchool;
      let params = {
        maPhong: this.searchForm.donVi,
        capHoc: this.searchForm.capHoc
      }
      this.danh_sach_truong = [];
      api.get(url, params).then(
          response => {
            console.log("Res:")
            console.log(response)
            if (response.data.code == 200) {
              let dulieu = response.data.data;
              for (let i = 0; i < dulieu.length; i++) {
                let obj = {
                  name: dulieu[i].tenTruongHoc + " - [" + dulieu[i].maTruongHoc + "]",
                  value: dulieu[i].maTruongHoc
                }
                this.danh_sach_truong.push(obj)
              }
            } else {
              this.thongBao("error", 'Không lấy được dữ liệu')
            }
            console.log('Danh sách trường:')
            console.log(this.danh_sach_truong)
          }
      )
    },
    layDanhSachSo() {
      let url = constant.configKy.getDanhSachSo;
      this.danh_sach_so = [];
      api.get(url, {}).then(
          response => {
            console.log("res lấy ds sở:")
            console.log(response)
            if (response.data.code == 200) {
              let dulieu = response.data.data
              let ds_so = [];
              for (let i = 0; i < dulieu.length; i++) {
                let obj = {
                  name: dulieu[i].tenDonVi + " - [" + dulieu[i].maDonVi + "]",
                  value: dulieu[i].maDonVi
                }
                ds_so.push(obj)
              }
              this.danh_sach_so = ds_so;
            } else {}
          }
      )
    },
    layDanhSachPhong() {
      this.searchForm.donVi = '';
      console.log('layDanhSachPhong')
      let url = constant_api.school.listPgd;
      let params = {
        maSo: this.user.maSo
      }
      if (this.user.role==8){
        params.maSo = this.searchForm.maSo
      }
      this.danh_sach_phong = [];
      api.get(url, params).then(
          response => {
            console.log("Res:")
            console.log(response)
            if (response.data.code == 200) {
              let dulieu = response.data.data;
              for (let i = 0; i < dulieu.length; i++) {
                let obj = {
                  name: dulieu[i].tenDonVi + " - [" + dulieu[i].maDonVi + "]",
                  value: dulieu[i].maDonVi
                }
                this.danh_sach_phong.push(obj)
              }
            } else {
              this.thongBao("error", 'Không lấy được dữ liệu')
            }
            console.log('Danh sách phòng:')
            console.log(this.danh_sach_phong)
          }
      )
    },
    confirmDel(id, year) {
      this.$confirm('Xác nhận xoá thông tin năm học ' + year + '-' + (year + 1) + '?', 'Thông báo', {
        confirmButtonText: 'Xoá năm học',
        cancelButtonText: 'Hủy',
        confirmButtonClass: 'el-button--danger',
      })
          .then(_ => {
            console.log('Then')
          })
          .catch(_ => {
          });
    },
    indexMethod(index) {
      return (this.paginate.start + index) + 1;
    },
    thongBao(t, e) {
      let msg = "";
      let cl = "";
      if (e) {
        msg = e;
      }
      let type = "success";
      if (t) {
        type = t
      }
      if (type == "success") {
        cl = "dts-noty-success"
      }
      if (type == "warning") {
        cl = "dts-noty-warning"
      }
      if (type == "error") {
        cl = "dts-noty-error"
      }
      if (type == "info") {
        cl = "dts-noty-info"
      }
      this.$message({
        customClass: cl,
        showClose: true,
        message: msg,
        type: t,
        duration: 3000
      });
    },
    getTotalPage(number) {
      return Math.ceil(number / (this.paginate.limit));
    },
  },
}
</script>

<style scoped>
.el-dialog__title{
  text-align: left !important;
}
</style>
