var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c(
      "div",
      {
        staticClass:
          "page__title d-flex align-items-center justify-content-between",
      },
      [
        _c("h1", { staticClass: "title" }, [_vm._v("Nhập dữ liệu bổ sung ")]),
        _vm._v(" "),
        _c(
          "el-tooltip",
          {
            staticClass: "item",
            attrs: {
              effect: "dark",
              content: "Hướng dẫn nhập dữ liệu",
              placement: "top",
            },
          },
          [
            _c(
              "a",
              {
                staticClass: "link",
                attrs: { href: "/files/huong_dan_nhap_du_lieu.docx" },
              },
              [_vm._v(" Hướng dẫn nhập dữ liệu")]
            ),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "page__header" },
      [
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [
            _c(
              "el-col",
              { attrs: { xs: 12, sm: 6, md: 4, lg: 4 } },
              [
                _c("label", [_vm._v("Năm học")]),
                _vm._v(" "),
                _c("eselect", {
                  staticStyle: { width: "100%" },
                  attrs: {
                    collapseTags: "",
                    placeholder: "Chọn",
                    filterable: "",
                    data: _vm.list_nam_hoc,
                    fields: ["name", "value"],
                  },
                  model: {
                    value: _vm.namHoc,
                    callback: function ($$v) {
                      _vm.namHoc = $$v
                    },
                    expression: "namHoc",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-col",
              { attrs: { xs: 12, sm: 6, md: 4, lg: 4 } },
              [
                _c("label", [_vm._v("Cấp học")]),
                _vm._v(" "),
                _c("eselect", {
                  staticStyle: { width: "100%" },
                  attrs: {
                    collapseTags: "",
                    placeholder: "Chọn",
                    filterable: "",
                    data: _vm.list_cap_hoc,
                    fields: ["name", "value"],
                  },
                  model: {
                    value: _vm.capHoc,
                    callback: function ($$v) {
                      _vm.capHoc = $$v
                    },
                    expression: "capHoc",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _vm.user.maSo != 79
      ? _c(
          "div",
          { staticClass: "page__header" },
          [
            _c("h5", { staticClass: "title" }, [
              _vm._v("Nhập dữ liệu mã Moet"),
            ]),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _c("ImportGeneral", {
              attrs: {
                "require-cap-hoc": true,
                fileHasData: true,
                linkFileHasData:
                  _vm.typeGiaoVien === 1
                    ? _vm.linkFileHasData
                    : _vm.linkFileHasDataChung,
                fileNameError: "danh_sach_loi_nhap_giao_vien",
                requireKhoiHoc: true,
                hideClass: false,
                pathSampleFile:
                  _vm.typeGiaoVien === 1
                    ? _vm.pathSampleFile
                    : _vm.pathSampleFileChung,
                requireNamHoc: true,
                year: _vm.namHoc,
                "cap-hoc": _vm.capHoc,
                "template-download": _vm.template,
                source: _vm.source,
                requireImportType: true,
                "data-type": 17,
                "text-download": "Tải file nhập dữ liệu mã Moet",
                path: _vm.typeGiaoVien === 1 ? _vm.path : _vm.pathChung,
                disabledDownload: _vm.typeGiaoVien != 2 && false,
                importType: _vm.typeGiaoVien,
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.capHoc != "" && _vm.user.maSo != 79
      ? _c(
          "div",
          { staticClass: "page__header" },
          [
            _c("h5", { staticClass: "title" }, [
              _vm._v("Nhận xét của giáo viên chủ nhiệm"),
            ]),
            _vm._v(" "),
            _vm._m(1),
            _vm._v(" "),
            _c("ImportGeneral", {
              attrs: {
                "require-cap-hoc": true,
                "require-nam-hoc": true,
                requireImportType: true,
                year: _vm.namHoc,
                "cap-hoc": _vm.capHoc,
                fileNameError: "danh_sach_loi_nhap_nhan_xet_gvcn",
                requireKhoiHoc: true,
                hideClass: false,
                "text-download": "Tải file nhập Nhận xét của GVCN",
                "data-type": 15,
                "uri-dowload": _vm.dowloadGVCN,
                source: _vm.source,
                path:
                  _vm.typeNXGvcn === 1
                    ? _vm.pathNhanXetGVCN
                    : _vm.pathNhanXetGVCNChung,
                importType: 2,
                "name-file": "nx-gvcn",
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.user.maSo != 79
      ? _c(
          "div",
          { staticClass: "page__header" },
          [
            _c("h5", { staticClass: "title" }, [
              _vm._v("Nhập dữ liệu Năng lực - Phẩm chất"),
            ]),
            _vm._v(" "),
            _vm._m(2),
            _vm._v(" "),
            _c("ImportGeneral", {
              attrs: {
                "require-cap-hoc": true,
                fileHasData: true,
                linkFileHasData:
                  _vm.typeGiaoVien === 1
                    ? _vm.linkFileHasData
                    : _vm.linkFileHasDataChung,
                fileNameError: "danh_sach_loi_nhap_giao_vien",
                requireKhoiHoc: true,
                hideClass: false,
                pathSampleFile:
                  _vm.typeGiaoVien === 1
                    ? _vm.pathSampleFile
                    : _vm.pathSampleFileChung,
                requireNamHoc: true,
                year: _vm.namHoc,
                "cap-hoc": _vm.capHoc,
                "template-download": _vm.template,
                source: _vm.source,
                requireImportType: true,
                "data-type": 6,
                "text-download": "Tải file nhập dữ liệu Năng lực - Phẩm chất",
                path: _vm.typeGiaoVien === 1 ? _vm.path : _vm.pathChung,
                disabledDownload: false,
                importType: _vm.typeGiaoVien,
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.user.maSo != 79
      ? _c(
          "div",
          { staticClass: "page__header" },
          [
            _c("h5", { staticClass: "title" }, [
              _vm._v("Nhập dữ liệu Chiều cao - Cân nặng"),
            ]),
            _vm._v(" "),
            _vm._m(3),
            _vm._v(" "),
            _c("ImportGeneral", {
              attrs: {
                "require-cap-hoc": true,
                fileHasData: true,
                linkFileHasData:
                  _vm.typeGiaoVien === 1
                    ? _vm.linkFileHasData
                    : _vm.linkFileHasDataChung,
                fileNameError: "danh_sach_loi_nhap_giao_vien",
                requireKhoiHoc: true,
                hideClass: false,
                pathSampleFile:
                  _vm.typeGiaoVien === 1
                    ? _vm.pathSampleFile
                    : _vm.pathSampleFileChung,
                requireNamHoc: true,
                year: _vm.namHoc,
                "cap-hoc": _vm.capHoc,
                "template-download": _vm.template,
                source: _vm.source,
                requireImportType: true,
                "data-type": 8,
                "text-download": "Tải file nhập dữ liệu Chiều cao - Cân nặng",
                path: _vm.typeGiaoVien === 1 ? _vm.path : _vm.pathChung,
                disabledDownload: false,
                importType: _vm.typeGiaoVien,
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.user.maSo != 79
      ? _c(
          "div",
          { staticClass: "page__header" },
          [
            _c("h5", { staticClass: "title" }, [
              _vm._v("Nhập dữ liệu Số ngày nghỉ"),
            ]),
            _vm._v(" "),
            _vm._m(4),
            _vm._v(" "),
            _c("ImportGeneral", {
              attrs: {
                "require-cap-hoc": true,
                fileHasData: true,
                linkFileHasData:
                  _vm.typeGiaoVien === 1
                    ? _vm.linkFileHasData
                    : _vm.linkFileHasDataChung,
                fileNameError: "danh_sach_loi_nhap_giao_vien",
                requireKhoiHoc: true,
                hideClass: false,
                pathSampleFile:
                  _vm.typeGiaoVien === 1
                    ? _vm.pathSampleFile
                    : _vm.pathSampleFileChung,
                requireNamHoc: true,
                year: _vm.namHoc,
                "cap-hoc": _vm.capHoc,
                "template-download": _vm.template,
                source: _vm.source,
                requireImportType: true,
                "data-type": 9,
                "text-download": "Tải file nhập dữ liệu Số ngày nghỉ",
                path: _vm.typeGiaoVien === 1 ? _vm.path : _vm.pathChung,
                disabledDownload: false,
                importType: _vm.typeGiaoVien,
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.user.maSo != 79
      ? _c(
          "div",
          { staticClass: "page__header" },
          [
            _c("h5", { staticClass: "title" }, [
              _vm._v("Nhập dữ liệu Khen thưởng"),
            ]),
            _vm._v(" "),
            _vm._m(5),
            _vm._v(" "),
            _c("ImportGeneral", {
              attrs: {
                "require-cap-hoc": true,
                fileHasData: true,
                linkFileHasData:
                  _vm.typeGiaoVien === 1
                    ? _vm.linkFileHasData
                    : _vm.linkFileHasDataChung,
                fileNameError: "danh_sach_loi_nhap_giao_vien",
                requireKhoiHoc: true,
                hideClass: false,
                pathSampleFile:
                  _vm.typeGiaoVien === 1
                    ? _vm.pathSampleFile
                    : _vm.pathSampleFileChung,
                requireNamHoc: true,
                year: _vm.namHoc,
                "cap-hoc": _vm.capHoc,
                "template-download": _vm.template,
                source: _vm.source,
                requireImportType: true,
                "data-type": _vm.capHoc == 1 ? 10 : 14,
                "text-download": "Tải file nhập dữ liệu Khen thưởng",
                path: _vm.typeGiaoVien === 1 ? _vm.path : _vm.pathChung,
                disabledDownload: false,
                importType: _vm.typeGiaoVien,
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.user.maSo != 79
      ? _c(
          "div",
          { staticClass: "page__header" },
          [
            _vm._m(6),
            _vm._v(" "),
            _c("ImportForNhanXet", {
              attrs: {
                year: _vm.namHoc,
                "cap-hoc": _vm.capHoc,
                "data-type": 7,
                "text-download": "Tải file nhập Điểm tổng kết",
                "require-nam-hoc": true,
                "require-cap-hoc": true,
                fileNameError: "danh_sach_loi_nhap_diem_tong_ket",
                requireImportType: true,
                requireKhoiHoc: true,
                hideClass: false,
                "require-khoi-hoc": _vm.getRequireKhoiHoc(),
                "uri-dowload": _vm.downloadDiemTongKetC1,
                source: _vm.sourceDiemTongKet,
                path:
                  _vm.typeDiemTongKetC1 === 1
                    ? _vm.pathDiemtTongKetC1
                    : _vm.pathDiemTongKetC1Chung,
                disabledDownload: _vm.typeDiemTongKetC1 != 2,
                importType: _vm.typeDiemTongKetC1,
                "name-file": "diem-tong-ket",
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.user.maSo != 79
      ? _c(
          "div",
          { staticClass: "page__header" },
          [
            _vm._m(7),
            _vm._v(" "),
            _c("ImportForNhanXet", {
              attrs: {
                year: _vm.namHoc,
                "cap-hoc": _vm.capHoc,
                "require-nam-hoc": true,
                "require-cap-hoc": true,
                "data-type": 5,
                fileNameError: "danh_sach_loi_nhap_so_nhan_xet",
                requireImportType: true,
                "uri-dowload": _vm.downloadSoNhanXetChung,
                source: _vm.source,
                requireKhoiHoc: true,
                "text-download": "Tải file nhập Sổ nhận xét",
                hideClass: false,
                disabledDownload: false,
                path:
                  _vm.typeSoNhanXet === 1
                    ? _vm.pathSoNhanXet
                    : _vm.pathSoNhanXetChung,
                "name-file": "so-nhan-xet",
                importType: _vm.typeSoNhanXet,
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.user.maSo == 79
      ? _c(
          "div",
          { staticClass: "page__header" },
          [
            _vm._m(8),
            _vm._v(" "),
            _c("ImportForNhanXet", {
              attrs: {
                year: _vm.namHoc,
                "cap-hoc": _vm.capHoc,
                "require-nam-hoc": true,
                "require-cap-hoc": true,
                "data-type": 18,
                fileNameError: "danh_sach_loi_nhap_so_dang_bo",
                requireImportType: true,
                "uri-dowload": _vm.downloadSoNhanXetChung,
                source: _vm.source,
                requireKhoiHoc: true,
                "text-download": "Tải file nhập Sổ đăng bộ",
                hideClass: false,
                disabledDownload: false,
                path:
                  _vm.typeSoNhanXet === 1
                    ? _vm.pathSoNhanXet
                    : _vm.pathSoNhanXetChung,
                "name-file": "so-nhan-xet",
                importType: _vm.typeSoNhanXet,
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "page__header" },
      [
        _vm._m(9),
        _vm._v(" "),
        _c("ImportForNhanXet", {
          attrs: {
            year: _vm.namHoc,
            "cap-hoc": _vm.capHoc,
            "require-nam-hoc": true,
            "require-cap-hoc": true,
            "data-type": 19,
            fileNameError: "danh_sach_loi_ket_qua_ren_luyen_trong_ky_nghi_he",
            requireImportType: true,
            "uri-dowload": _vm.downloadSoNhanXetChung,
            source: _vm.source,
            requireKhoiHoc: true,
            "text-download": "Tải file nhập KQRL trong kỳ nghỉ hè",
            hideClass: false,
            disabledDownload: false,
            path:
              _vm.typeSoNhanXet === 1
                ? _vm.pathSoNhanXet
                : _vm.pathSoNhanXetChung,
            "name-file": "so-nhan-xet",
            importType: _vm.typeSoNhanXet,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("small", [
      _c("i", [
        _vm._v(
          "(Truy cập Học bạ số -> Nhập dữ liệu bổ sung -> Tải file Nhập dữ liệu mã Moet)"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("small", [
      _c("i", [
        _vm._v(
          " (Truy cập Học bạ số -> Nhập dữ liệu bổ sung -> Tải file Nhận xét của Giáo viên chủ nhiệm)"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("small", [
      _c("i", [
        _vm._v(
          " (Truy cập Học bạ số -> Nhập dữ liệu bổ sung -> Tải file Năng lực - Phẩm chất)"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("small", [
      _c("i", [
        _vm._v(
          "(Truy cập Học bạ số -> Nhập dữ liệu bổ sung -> Tải file mẫu Chiều cao - Cân nặng)"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("small", [
      _c("i", [
        _vm._v(
          "(Truy cập Học bạ số -> Nhập dữ liệu bổ sung -> Tải file mẫu Số ngày nghỉ)"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("small", [
      _c("i", [
        _vm._v(
          "(Truy cập Học bạ số -> Nhập dữ liệu bổ sung -> Tải file khen thưởng)"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "page__title align-items-center justify-content-between" },
      [
        _c("h1", { staticClass: "title" }, [_vm._v("Điểm tổng kết")]),
        _vm._v(" "),
        _c("small", [
          _c("i", [
            _vm._v(
              " (Truy cập Học bạ số -> Nhập dữ liệu bổ sung -> Tải file Điểm tổng kết)"
            ),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "page__title align-items-center justify-content-between" },
      [
        _c("h1", { staticClass: "title" }, [_vm._v("Sổ nhận xét")]),
        _vm._v(" "),
        _c("small", [
          _c("i", [
            _vm._v(
              " (Truy cập Học bạ số -> Nhập dữ liệu bổ sung -> Tải file mẫu Sổ nhận xét)"
            ),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "page__title align-items-center justify-content-between" },
      [
        _c("h1", { staticClass: "title" }, [_vm._v("Sổ đăng bộ")]),
        _vm._v(" "),
        _c("small", [
          _c("i", [
            _vm._v(
              " (Truy cập Học bạ số -> Nhập dữ liệu bổ sung -> Tải file mẫu Sổ đăng bộ)"
            ),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "page__title align-items-center justify-content-between" },
      [
        _c("h1", { staticClass: "title" }, [
          _vm._v("Kết quả rèn luyện trong kỳ nghỉ hè"),
        ]),
        _vm._v(" "),
        _c("small", [
          _c("i", [
            _vm._v(
              " (Truy cập Học bạ số -> Nhập dữ liệu bổ sung -> Tải file mẫu KQRL trong kỳ nghỉ hè)"
            ),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }