<template>
  <div>
    <div v-loading="fullscreenLoading" element-loading-text="Hệ thống đang tải dữ liệu học bạ"
         element-loading-spinner="el-icon-loading"
         element-loading-background="rgba(255, 255, 255, 0.8)" class="page page-file file detailHocBa">
      <div class="page__title d-flex align-items-center justify-content-between">
        <h1 class="title">In học bạ</h1>
      </div>
      <div class="box-upload-file">
        <el-form ref="searchForm" :hide-required-asterisk="true">
          <div class="mb-3">
            <template>
              <el-form :rules="rulesSearch" ref="searchForm" :model="searchForm"
                       :hide-required-asterisk="true">
                <el-row :gutter="24">
                  <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
                    <el-form-item class="d-inline-block" prop="year" label="Năm học">
                      <eselect style="width:100%" collapseTags v-model="searchForm.year"
                               @change="namHocChange"
                               placeholder="Chọn"
                               filterable :data="list_nam_hoc" :fields="['name','value']"/>
                    </el-form-item>
                  </el-col>
                  <el-col v-if="user.role!=4&&user.role!=1&&user.role!=9&&user.role!=3" :xs="12"
                          :sm="8" :md="6" :lg="6" :xl="6">
                    <el-form-item class="d-inline-block" prop="maDonVi" label="Đơn vị">
                      <eselect :disabled="user.role!=7" style="width: 100%" collapseTags
                               v-model="searchForm.maDonVi"
                               @change="capHocChange"
                               :placeholder="'Chọn'"
                               filterable :data="list_don_vi" :fields="['name','value']"/>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
                    <el-form-item class="d-inline-block" prop="education" label="Cấp học">
                      <eselect :disabled="optionEducation.length==1" style="width: 100%"
                               collapseTags v-model="searchForm.education"
                               @change="capHocChange"
                               :placeholder="'Chọn'"
                               filterable :data="optionEducation" :fields="['name','value']"/>
                    </el-form-item>
                  </el-col>
                  <el-col v-if="user.role!=4&&user.role!=1&&user.role!=9&&user.role!=3" :xs="12"
                          :sm="8" :md="6" :lg="6" :xl="6">
                    <el-form-item class="d-inline-block" prop="education" label="Trường học">
                      <eselect style="width: 100%" collapseTags v-model="searchForm.maTruongHoc"
                               :placeholder="'Chọn'"
                               filterable :data="list_truong_hoc" :fields="['name','value']"/>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
                    <el-form-item class="d-inline-block" prop="grade" label="Khối học">
                      <eselect style="width:100%" collapseTags v-model="searchForm.grade"
                               @change="khoiHocChange"
                               placeholder="Chọn" filterable :data="optionGrade"
                               :fields="['label','value']"/>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
                    <el-form-item class="d-inline-block" label="Lớp học">
                      <eselect style="width: 100%" collapseTags v-model="searchForm.maLop"
                               @change="LopHocChange"
                               :placeholder="'Chọn'"
                               filterable :data="danh_sach_lop_hoc" :fields="['name','value']"/>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="8" :md="6" :lg="4" :xl="3">
                    <el-form-item class="d-inline-block" label="Học sinh">
                      <eselect style="width:100%" collapseTags v-model="searchForm.student"
                               :placeholder="'Chọn học sinh'" filterable
                               :data="danh_sach_hoc_sinh"
                               :fields="['tenHS','maHS']"/>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </template>
          </div>
          <el-divider content-position="left"><span style="font-size: 20px">Cấu hình in</span></el-divider>
          <el-row :gutter="24">
            <el-col :xs="24" :sm="12" :md="6">
              <eselect style="width:100%" multiple collapseTags v-model="dsCauHinhIn"
                       placeholder="Chọn cấu hình in"
                       filterable :data="ds_cau_hinh_in" :fields="['name','value']"/>
            </el-col>
            <el-col :xs="24" :sm="8" :md="6" :lg="4"
                    :xl="3" class="formInHocBa">
              <el-form-item style="margin-bottom:0" class="d-inline-block ">
                <el-select v-model="isAnSoTrang" clearable placeholder="Chọn">
                  <el-option
                      v-for="item in ds_tuy_chon_trang_in"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="8" :md="6" :lg="4" v-if="dsCauHinhIn.includes('inTrang')"
                    :xl="4" class="formInHocBa">
              <el-form-item style="margin-bottom:0" class="d-inline-block ">
                <el-input v-model="trangInCuThe" type="number" placeholder="Trang in cụ thể" :min="1" :max="99">
                  <template slot="prepend">
                    <span style="color: black">In riêng trang</span>
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24" style="margin-bottom:0">
            <el-col :xs="24" class="mt-3 text-center" :span="24">
              <el-form-item class="d-inline-block">
                <el-button type="primary" @click="submitSearch('searchForm',1)">Xem</el-button>
                <el-button type="primary" @click="submitSearch('searchForm',2)">
                  {{ searchForm.student ? 'Xuất excel' : 'Xuất excel cả lớp' }}
                </el-button>

                <el-button v-if="mauMoi" type="success" @click="taiFileKySo(1)">Xem file ký số</el-button>
                <el-button type="success" @click="taiFileKySo(2)">Tải file ký số</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="page__content">
        <div class="text-center">
          <el-pagination
              v-if="detailHocBaPdf"
              :page-size="1"
              @current-change="chonTrangXemHocBa"
              :current-page.sync="thongTinXemFilePdfHocBa.hoc_sinh_hien_tai"
              layout="prev, pager, next"
              :total="danh_sach_hoc_sinh.length">
          </el-pagination>
        </div>
        <div class="text-right">
          <el-button style="margin-bottom: 10px" v-if="detailHocBaPdf" type="primary" @click="exportPDF('searchForm')">
            Tải PDF cả lớp
          </el-button>
        </div>
        <div v-if="mauMoi">
          <el-row :gutter="24">
            <el-col :span="24">
              <div class="box-upload-file" style="overflow: scroll">
<!--                <viewHocBaC1 v-if="chiTietHocBa" :item="chiTietHocBa"></viewHocBaC1>-->
                <viewPfdV2 v-if="chiTietHocBa" :item="chiTietHocBa"></viewPfdV2>
              </div>
            </el-col>
          </el-row>
        </div>
        <div v-if="!mauMoi" class="viewHocBa" style="overflow: scroll" v-show="detailHocBaPdf">
          <iframe :src="detailHocBaPdf" style="width:100%;" height="100%"></iframe>
        </div>
      </div>
    </div>
    <div>
      <el-dialog title="Thông báo" style="text-align:center" :visible.sync="hienThiThongBaoTongHop">
        <div>
          <p style="color: black;">Học bạ đã được tổng hợp. Bạn có thể xem file đã tổng hợp hoặc tổng hợp
            mới</p>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dongTongHopMoi">Xem</el-button>
          <el-button @click="tongHopMoi" type="primary">Tổng hợp mới</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {
  mapActions,
  mapState
} from 'vuex';
import viewHocBaC1 from './viewHocBa/c1.vue';
import viewPfd from './viewHocBa/viewPdf.vue';
import viewPfdV2 from './viewHocBa/viewPdfV2.vue';
import api from '../../_helpers/api';
import constant from '../../_helpers/constant_api';
import ESelect from "../../components/ESelect";
import SelectNamHoc from "../../components/SelectNamHoc";
import ChonKhoiHoc from "../../components/ChonKhoiHoc";
import hocBaTieuHoc from "./hocBaTieuHoc.vue";
import Pagination from "../../components/Pagination";
import constant_api from '../../_helpers/constant_api';

export default {
  name: "InHocBa",
  metaInfo: {
    title: 'In học bạ',
    meta: [{
      vmid: 'description',
      name: 'description',
      content: ''
    }]
  },
  components: {
    "eselect": ESelect,
    SelectNamHoc,
    Pagination,
    ChonKhoiHoc,
    viewHocBaC1,
    viewPfd,
    viewPfdV2,
    hocBaTieuHoc,
  },
  computed: {
    ...mapState('teacher', [
      "listLop",
    ]),
    ...mapState('account', [
      'list_nam_hoc',
      'list_cap_hoc',
      'nam_hoc_hien_tai'
    ]),
    ...mapState('hocsinh', [
      "listHs",
    ]),
  },
  data: () => {
    return {
      trangInCuThe: 1,
      thongTinXemFilePdfHocBa: {
        hoc_sinh_hien_tai: 0,
        count: 0,
      },
      user: {},
      isLoad: false,
      detailHocBaPdf: "",
      chiTietHocBa: null,
      optionGrade: [],
      hienThiThongBaoTongHop: false,
      innerVisible: false,
      isAnh: true,
      isDau: false,
      isBia: false,
      isKQHT: false,
      isQTHT: true,
      inNamCu: false,
      inDienChinhSach: false,
      isAnSoTrang: 0,
      mauMoi:false,
      fullscreenLoading: false,
      listPGD: [],
      danh_sach_hoc_sinh: [],
      dsCauHinhIn: ['isQTHT', 'isAnh'],
      ds_cau_hinh_in: [
        {name: 'In riêng trang bìa', value: 'isBia'},
        {name: 'In riêng trang KQHT', value: 'isKQHT'},
        {name: 'In không dấu', value: 'isDau'},
        {name: 'In ảnh', value: 'isAnh'},
        {name: 'In quá trình học tập', value: 'isQTHT'},
        {name: 'In thông tin năm cũ', value: 'inNamCu'},
        {name: 'In diện chính sách', value: 'inDienChinhSach'},
        {name: 'Ẩn chữ ký', value: 'isAnChuKy'},
        {name: 'In chẵn trang', value: 'isInChanTrang'},
        {name: 'In riêng bìa cứng', value: 'isInRiengBia'},
        {name: 'Ẩn chữ ký hiệu trưởng', value: 'isAnAnhHieuTruong'},
        {name: 'Ẩn chữ ký GVCN', value: 'isAnAnhGVCN'},
        {name: 'Ẩn nhận xét hiệu trưởng', value: 'isAnNhanXet'},
        {name: 'In riêng thông tin học sinh', value: 'isInRiengThongTinHocSinh'},
        {name: 'In học bạ trắng', value: 'isInHocBaTrang'},
        {name: 'In trang cụ thể', value: 'inTrang'},
      ],
      ds_tuy_chon_trang_in: [
        {name: 'Không đánh dấu trang', value: -1},
        {name: 'Đánh dấu trang từ: 0', value: 0},
        {name: 'Đánh dấu trang từ: 1', value: 1},
        {name: 'Đánh dấu trang từ: 2', value: 2},
        {name: 'Đánh dấu trang từ: 3', value: 3},
        {name: 'Đánh dấu trang từ: 4', value: 4},
        {name: 'Đánh dấu trang từ: 5', value: 5},
        {name: 'Đánh dấu trang từ: 6', value: 6},
        {name: 'Đánh dấu trang từ: 7', value: 7},
        {name: 'Đánh dấu trang từ: 8', value: 8},
        {name: 'Đánh dấu trang từ: 9', value: 9},
        {name: 'Đánh dấu trang từ: 10', value: 10},
        {name: 'Đánh dấu trang từ: 11', value: 11},
        {name: 'Đánh dấu trang từ: 12', value: 12},
        {name: 'Đánh dấu trang từ: 13', value: 13},
        {name: 'Đánh dấu trang từ: 14', value: 14},
        {name: 'Đánh dấu trang từ: 15', value: 15},
        {name: 'Đánh dấu trang từ: 16', value: 16},
        {name: 'Đánh dấu trang từ: 17', value: 17},
        {name: 'Đánh dấu trang từ: 18', value: 18},
        {name: 'Đánh dấu trang từ: 19', value: 19},
        {name: 'Đánh dấu trang từ: 20', value: 20},
      ],
      list_don_vi: [],
      list_truong_hoc: [],
      danh_sach_lop_hoc: [],
      searchForm: {
        maDonVi: '',
        lopHoc: '',
        maLop: '',
        year: '',
        education: '',
        grade: '',
        class: '',
        student: '',
        status: '',
        maTruongHoc: '',
      },
      khoiCap1: [{
        value: 1,
        label: 'Khối 1'
      },
        {
          value: 2,
          label: 'Khối 2'
        },
        {
          value: 3,
          label: 'Khối 3'
        },
        {
          value: 4,
          label: 'Khối 4'
        },
        {
          value: 5,
          label: 'Khối 5'
        },
      ],
      khoiCap2: [{
        value: 6,
        label: 'Khối 6'
      },
        {
          value: 7,
          label: 'Khối 7'
        },
        {
          value: 8,
          label: 'Khối 8'
        },
        {
          value: 9,
          label: 'Khối 9'
        },
      ],
      khoiCap3: [{
        value: 10,
        label: 'Khối 10'
      },
        {
          value: 11,
          label: 'Khối 11'
        },
        {
          value: 12,
          label: 'Khối 12'
        },
      ],
      paginate: {
        start: 0,
        limit: 1,
        page: 1,
      },
      optionEducation: [{
        value: '1',
        label: 'Cấp 1'
      },
        {
          value: '2',
          label: 'Cấp 2'
        },
        {
          value: '3',
          label: 'Cấp 3'
        },
        {value: '15', label: 'Liên cấp'},
        {value: '145', label: 'Liên cấp'},
        {value: '14', label: 'Liên cấp'},
        {value: '13', label: 'Liên cấp'},
        {value: '451', label: 'Liên cấp'},
        {value: '23', label: 'Liên cấp'},
        {value: '236', label: 'Liên cấp'},
        {value: '36', label: 'Liên cấp'},
        {value: '452', label: 'Liên cấp'},
        {value: '1245', label: 'Liên cấp'},
        {value: '12', label: 'Liên cấp'},
        {value: '123', label: 'Liên cấp'},
        {value: '12345', label: 'Liên cấp'},
        {value: '1235', label: 'Liên cấp'},
        {value: '6', label: 'Trung tâm GDTX'},
      ],
      rulesSearch: {
        // year : [
        //     // {required : true, message : 'Bạn chưa chọn năm học',trigger: 'blur'}
        //     {required : true, message : 'Bạn chưa chọn năm học',trigger: 'change'}
        // ],
        // education : [
        //     {required : true, message : 'Bạn chưa chọn cấp học',trigger: 'change'}
        // ],
        // grade: [{
        //   required: true,
        //   message: 'Bạn chưa chọn khối học',
        //   trigger: 'change'
        // }],
        class: [{
          required: true,
          message: 'Bạn chưa chọn lớp học',
          trigger: 'change'
        }],
        // student : [
        //     {required : true, message : 'Bạn chưa chọn học sinh',trigger: 'change'}
        // ],
        // status : [
        //     {required : true, message : 'Bạn chưa chọn trạng thái',trigger: 'change'}
        // ]
      },
    }
  },
  mounted() {
    console.log("Mount In học bạ")
    this.searchForm.year = this.nam_hoc_hien_tai
    let user = localStorage.getItem("user");
    this.user = JSON.parse(user);
    console.error('Thông tin người dùng:')
    console.error(this.user)
    console.log("Năm học hiện tại là:" + this.searchForm.year)
    if (this.user.role == 4) {
      this.searchForm.maDonVi = this.user.maPhong;
      this.searchForm.maTruongHoc = this.user.maTruong
    }
    if (this.user.role == 6) {
      this.searchForm.maDonVi = this.user.maPhong
      this.getListSchool();
    }
    this.layDanhSachDonVi();
    this.optionEducation = JSON.parse(JSON.stringify(this.list_cap_hoc));
    if (this.list_cap_hoc.length == 1) {
      this.searchForm.education = this.list_cap_hoc[0].value
    } else {
      // let obj = {
      //     name: 'Liên cấp',
      //     value: this.user.capHoc
      // }
      // this.optionEducation.push(obj);
      // this.searchForm.education = this.user.capHoc;
    }
    this.getKhoiHoc();
    let params = {
      "maLop": "LH_7900001_2023_1001446035",
      "maHS": "HS1017574922",
      "isLoad": false,
      "userId": "7900001",
      "isDau": false,
      "isAnh": true,
      "isBia": false,
      "isQTHT": true,
      "isKQHT": false,
      "inNamCu": false,
      "isAnChuKy": false,
      "isInChanTrang": false,
      "isInRiengBia": false,
      "inDienChinhSach": false,
      "isAnAnhGVCN": false,
      "isAnNhanXet": false,
      "isInRiengThongTinHocSinh": false,
      "isInHocBaTrang": false,
      "inTrang": null,
      "isAnAnhHieuTruong": false,
      "isAnSoTrang": 0
    }
    // this.layChiTietFilePDF(params)
  },
  watch: {
    listHs(val) {
      this.danh_sach_hoc_sinh = [];
      if (val) {
        this.danh_sach_hoc_sinh = val.list;
      }
    },
  },
  methods: {
    chonHocSinh() {
      console.log('Chọn học sinh');
      console.log(this.searchForm.student)
      console.log(this.danh_sach_hoc_sinh)
      if (this.searchForm.student) {
        let indexStudent = 1;
        for (let i = 0; i < this.danh_sach_hoc_sinh.length; i++) {
          if (this.danh_sach_hoc_sinh[i].maHS == this.searchForm.student) {
            indexStudent = i + 1;
          }
        }
        console.log(indexStudent)
        this.thongTinXemFilePdfHocBa.hoc_sinh_hien_tai = indexStudent;
      } else {
        this.thongTinXemFilePdfHocBa.hoc_sinh_hien_tai = 1;
      }
    },
    chonTrangXemHocBa(val) {
      console.error('chonTrangXemHocBa')
      this.searchForm.student = this.danh_sach_hoc_sinh[(val - 1)].maHS;
      this.submitSearch('searchForm', 1)
    },
    getTotalPage(number) {
      return Math.ceil(number / (this.paginate.limit));
    },
    layDanhSachDonVi() {
      let params = {
        maSo: this.user.maSo,
      }
      let url = constant.danhMuc.danhSachDonVi;
      this.list_don_vi = [];
      this.fullscreenLoading = true;
      api.get(url, params).then(
          response => {
            if (response.data.code == 200) {
              let data = response.data.data;
              for (let i = 0; i < data.length; i++) {
                this.list_don_vi.push(
                    {
                      name: data[i].tenDonVi.replace("Giáo dục và Đào tạo", "GD&ĐT") + ' - [' + data[i].maDonVi + ']',
                      value: data[i].maDonVi
                    }
                )
              }
            } else {
            }
            console.error(this.list_don_vi)
            this.fullscreenLoading = false;
          }
      )
    },
    getKhoiHoc() {
      console.error("getKhoiHoc")
      console.error(this.searchForm.education)
      if (!this.searchForm.education) {
        let arr = [];
        this.optionGrade = [];
        for (let i = 0; i < this.list_cap_hoc.length; i++) {
          if (this.list_cap_hoc[i].value == 1) {
            arr = arr.concat(this.khoiCap1)
          }
          if (this.list_cap_hoc[i].value == 2) {
            arr = arr.concat(this.khoiCap2)
          }
          if (this.list_cap_hoc[i].value == 3) {
            arr = arr.concat(this.khoiCap3)
          }
        }
        this.optionGrade = arr;
        return;
      } else {
        if (this.searchForm.education == 1) {
          this.optionGrade = this.khoiCap1;
        } else if (this.searchForm.education == 2) {
          this.optionGrade = this.khoiCap2;
        } else if (this.searchForm.education == 3) {
          this.optionGrade = this.khoiCap3;
        } else {
          let arr = [];
          for (let i = 0; i < this.list_cap_hoc.length; i++) {
            if (this.list_cap_hoc[i].value == 1) {
              arr = arr.concat(this.khoiCap1)
            }
            if (this.list_cap_hoc[i].value == 2) {
              arr = arr.concat(this.khoiCap2)
            }
            if (this.list_cap_hoc[i].value == 3) {
              arr = arr.concat(this.khoiCap3)
            }
          }
          this.optionGrade = arr;
        }
      }
    },
    dongTongHopMoi() {
      this.isLoad = false;
      this.hienThiThongBaoTongHop = false;
      console.log(this.detailHocBaPdf)
    },
    ChonCauHinhInTrangBia() {
      // this.isDau = false;
      // this.isKQHT = false;
    },
    ChonCauHinhInDau() {
      // this.isBia = false;
      // this.isKQHT = false;
    },
    ChonCauHinhInKQHT() {
      // this.isBia = false;
      // this.isDau = false;
    },
    getCurrentMonth() {
      let d = new Date();
      return d.getMonth();
    },
    tongHopMoi() {
      this.isLoad = true;
      this.hienThiThongBaoTongHop = false;
      this.submitSearch('searchForm');
    },
    thongBao(t, e) {
      let msg = "";
      let cl = "";
      if (e) {
        msg = e;
      }
      let type = "success";
      if (t) {
        type = t
      }
      if (type == "success") {
        cl = "dts-noty-success"
      }
      if (type == "warning") {
        cl = "dts-noty-warning"
      }
      if (type == "error") {
        cl = "dts-noty-error"
      }
      if (type == "info") {
        cl = "dts-noty-info"
      }
      this.$message({
        customClass: cl,
        showClose: true,
        message: msg,
        type: t,
        duration: 3000
      });
    },
    getUrlPDF(e) {
      this.fullscreenLoading = true;
      let url = constant.hocba.detailPdf;

      api.post(url, e).then(
          response => {
            this.fullscreenLoading = false;

            console.log(response)
            if (response.data.code == 200) {
              let url = response.data.data;
              if (url) {
                const link = document.createElement('a');
                link.href = url;
                link.target = '_blank';
                link.download = 'hocba_all.pdf';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }

            } else {
              this.thongBao('error', response.data.msg)
            }
          }
      )
    },
    exportPDF(formName) {
      if (!this.searchForm.maLop || this.searchForm.maLop == '') {
        this.thongBao('error', 'Vui lòng bổ sung thông tin lớp học');
        return;
      }
      let params = {
        maLop: this.searchForm.maLop,
        maHS: '',
        isLoad: this.isLoad,
        userId: this.user.maTruong,
        isDau: this.dsCauHinhIn.includes('isDau'),
        isAnh: this.dsCauHinhIn.includes('isAnh'),
        isBia: this.dsCauHinhIn.includes('isBia'),
        isQTHT: this.dsCauHinhIn.includes('isQTHT'),
        isKQHT: this.dsCauHinhIn.includes('isKQHT'),
        inNamCu: this.dsCauHinhIn.includes('inNamCu'),
        isAnChuKy: this.dsCauHinhIn.includes('isAnChuKy'),
        isInChanTrang: this.dsCauHinhIn.includes('isInChanTrang'),
        isInRiengBia: this.dsCauHinhIn.includes('isInRiengBia'),
        inDienChinhSach: this.dsCauHinhIn.includes('inDienChinhSach'),
        isAnAnhGVCN: this.dsCauHinhIn.includes('isAnAnhGVCN'),
        isAnNhanXet: this.dsCauHinhIn.includes('isAnNhanXet'),
        isInRiengThongTinHocSinh: this.dsCauHinhIn.includes('isInRiengThongTinHocSinh'),
        isInHocBaTrang: this.dsCauHinhIn.includes('isInHocBaTrang'),
        inTrang: this.dsCauHinhIn.includes('inTrang') ? this.trangInCuThe : null,
        isAnAnhHieuTruong: this.dsCauHinhIn.includes('isAnAnhHieuTruong'),
        isAnSoTrang: this.isAnSoTrang,
      }

      if (this.searchForm.grade >= 6 && this.searchForm.grade <= 12) {
        params.isAnh = this.dsCauHinhIn.includes('isAnh')
      } else {
        delete params.isAnh;
      }
      this.getUrlPDF(params)
      // this.$refs[formName].validate((valid) => {
      //   if (valid) {
      //     console.log("Gọi hàm")
      //
      //   } else {
      //     console.log('error submit!!');
      //     return false;
      //   }
      // });

    },
    submitSearch(formName, step) {
      console.log('Tìm')
      console.log(this.searchForm);
      if (!this.searchForm.maLop || this.searchForm.maLop == '') {
        this.thongBao('error', 'Vui lòng bổ sung thông tin lớp học');
        return;
      }
      this.chonHocSinh();
      let params = {
        maLop: this.searchForm.maLop,
        maHS: this.searchForm.student,
        isLoad: this.isLoad,
        userId: this.user.maTruong,
        isDau: this.dsCauHinhIn.includes('isDau'),
        isAnh: this.dsCauHinhIn.includes('isAnh'),
        isBia: this.dsCauHinhIn.includes('isBia'),
        isQTHT: this.dsCauHinhIn.includes('isQTHT'),
        isKQHT: this.dsCauHinhIn.includes('isKQHT'),
        inNamCu: this.dsCauHinhIn.includes('inNamCu'),
        isAnChuKy: this.dsCauHinhIn.includes('isAnChuKy'),
        isInChanTrang: this.dsCauHinhIn.includes('isInChanTrang'),
        isInRiengBia: this.dsCauHinhIn.includes('isInRiengBia'),
        inDienChinhSach: this.dsCauHinhIn.includes('inDienChinhSach'),
        isAnAnhGVCN: this.dsCauHinhIn.includes('isAnAnhGVCN'),
        isAnNhanXet: this.dsCauHinhIn.includes('isAnNhanXet'),
        isInRiengThongTinHocSinh: this.dsCauHinhIn.includes('isInRiengThongTinHocSinh'),
        isInHocBaTrang: this.dsCauHinhIn.includes('isInHocBaTrang'),
        inTrang: this.dsCauHinhIn.includes('inTrang') ? this.trangInCuThe : null,
        isAnAnhHieuTruong: this.dsCauHinhIn.includes('isAnAnhHieuTruong'),
        isAnSoTrang: this.isAnSoTrang,
      }

      // if (params.maHS == '' && step == 1 && this.danh_sach_hoc_sinh.length > 0) {
      //   this.searchForm.student = this.danh_sach_hoc_sinh[0].maHS
      //   params.maHS = this.danh_sach_hoc_sinh[0].maHS;
      // }
      if (this.searchForm.grade >= 6 && this.searchForm.grade <= 12) {
        params.isAnh = this.dsCauHinhIn.includes('isAnh')
      } else {
        delete params.isAnh;
      }
      if (step == 1) {
        this.layChiTietFilePDF(params)
      } else {
        this.xuatDuLieuFileExcel(params)
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log("Gọi hàm")
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    taiFileKySo(type) {
      console.log('Tìm')
      console.log(this.searchForm);
      if (!this.searchForm.maLop || this.searchForm.maLop == '') {
        this.thongBao('error', 'Vui lòng bổ sung thông tin lớp học');
        return;
      }
      let params = {
        maLop: this.searchForm.maLop,
        maHS: this.searchForm.student,
        isLoad: this.isLoad,
        isDau: this.dsCauHinhIn.includes('isDau'),
        isBia: this.dsCauHinhIn.includes('isBia'),
        isQTHT: this.dsCauHinhIn.includes('isQTHT'),
        isKQHT: this.dsCauHinhIn.includes('isKQHT'),
        isAnChuKy: this.dsCauHinhIn.includes('isAnChuKy'),
        isInChanTrang: this.dsCauHinhIn.includes('isInChanTrang'),
        isInRiengBia: this.dsCauHinhIn.includes('isInRiengBia'),
        inDienChinhSach: this.dsCauHinhIn.includes('inDienChinhSach'),
        isAnAnhHieuTruong: this.dsCauHinhIn.includes('isAnAnhHieuTruong'),
        isAnAnhGVCN: this.dsCauHinhIn.includes('isAnAnhGVCN'),
        isAnNhanXet: this.dsCauHinhIn.includes('isAnNhanXet'),
        isInRiengThongTinHocSinh: this.dsCauHinhIn.includes('isInRiengThongTinHocSinh'),
        isInHocBaTrang: this.dsCauHinhIn.includes('isInHocBaTrang'),
        inTrang: this.dsCauHinhIn.includes('inTrang') ? this.trangInCuThe : null,
        isAnSoTrang: this.isAnSoTrang,
      }
      if (this.searchForm.grade >= 6 && this.searchForm.grade <= 12) {
        params.isAnh = this.isAnh;
      } else {
        delete params.isAnh;
      }
      if (type == 1) {
        this.layChiTietFileKySo(params)
      } else {
        this.layChiTietFileKySoZip(params)
      }
    },
    getFullYear() {
      let d = new Date();
      return d.getFullYear();
    },
    layChiTietFileKySo(e) {
      console.log('layChiTietFileKySo')
      console.log(e);
      this.fullscreenLoading = true;
      let url = constant.hocba.detailFileKySo;
      api.get(url, e).then(
          response => {
            console.log("Res file ký số:")
            console.log(response.data)
            if (response.data.code == 200) {
              window.open(response.data.data, '_blank').focus();
              this.fullscreenLoading = false;
            } else {
              this.thongBao('error', response.data.msg)
              this.fullscreenLoading = false;
            }
          }
      )
    },
    layChiTietFileKySoZip(e) {
      console.log('layChiTietFileKySo')
      console.log(e);
      this.fullscreenLoading = true;
      let url = constant.hocba.detailFileKySoZip;
      api.get(url, e).then(
          response => {
            this.fullscreenLoading = false;
            console.log("Res file ký số zip:")
            console.log(response)
            if (response.data.code == 200) {
              window.open(response.data.data, '_blank').focus();
              this.fullscreenLoading = false;
            } else {
              this.thongBao('error', response.data.msg)
              this.fullscreenLoading = false;
            }
          }
      )
    },
    xuatDuLieuFileExcel(e) {
      console.log(e);
      this.fullscreenLoading = true;
      let url = constant.hocba.detailExcel;
      this.detailHocBaPdf = null;
      this.chiTietHocBa = null;
      api.post(url, e).then(
          response => {
            console.log("Res chi tiết:")
            console.log(response)
            if (response.data.code == 200) {
              let urlExcel = response.data.data;
              window.location.href = urlExcel
            } else {
              this.thongBao('error', response.data.msg)
            }
            this.fullscreenLoading = false;
          }
      )
    },
    layChiTietFilePDF(e) {
      console.log(e);
      this.fullscreenLoading = true;
      var url;
      if((this.searchForm.year>=2023&&[1,2,3,4].includes(this.searchForm.grade))||(this.searchForm.year>=2024&&[1,2,3,4,5].includes(this.searchForm.grade))){
      // Mẫu mới
        console.log('Mẫu mới')
         url = constant.hocba.detailPdfJSON;
        this.mauMoi = true;
      }else{
        console.log('Mẫu cũ')
        this.mauMoi = false;
         url = constant.hocba.detailPdf;
      }
      this.detailHocBaPdf = null;
      this.chiTietHocBa = null;
      api.post(url, e).then(
          response => {
            this.fullscreenLoading = false;
            console.log("Res chi tiết xem học bạ:")
            console.log(response)
            if (response.data.code == 200) {
              if(this.mauMoi){
                this.chiTietHocBa = response.data.data;
              }else{
                this.detailHocBaPdf = response.data.data;
              }
              if (response.data.other == 1) {
                this.hienThiThongBaoTongHop = true;
              }
            } else {
              this.thongBao('error', response.data.msg)
            }
          }
      )
    },
    namHocChange() {
      this.searchForm.student = '';
      this.searchForm.status = '';
      this.searchForm.grade = ''
      this.searchForm.maLop = ''
    },
    capHocChange() {
      this.searchForm.student = '';
      this.searchForm.status = '';
      this.searchForm.grade = '';
      this.searchForm.maLop = '';
      if (this.user.role != 4) {
        this.searchForm.maTruongHoc = '';
      }
      this.listLop.list = [];
      this.getKhoiHoc();
      this.getListSchool();
    },
    khoiHocChange() {
      this.searchForm.student = '';
      this.searchForm.maLop = '';
      this.getlistLopHoc();
    },
    LopHocChange() {
      console.log('Chọn lớp học')
      this.searchForm.student = '';
      this.getlistHocSinh();
    },
    getListSchool() {
      let params = {
        maPhong: this.searchForm.maDonVi,
        capHoc: this.searchForm.education,
      }
      let url = constant.danhMuc.danhSachTruong;
      this.list_truong_hoc = [];
      this.loadingScreen = true;
      api.get(url, params).then(
          response => {
            if (response.data.code == 200) {
              let data = response.data.data;
              console.error('Kết quả lấy trường:')
              for (let i = 0; i < data.length; i++) {
                this.list_truong_hoc.push(
                    {
                      name: data[i].tenTruongHoc + ' - [' + data[i].maTruongHoc + ']',
                      value: data[i].maTruongHoc
                    }
                )
              }
            } else {
            }
            console.error(this.list_truong_hoc)
            this.loadingScreen = false;
          }
      )
    },
    getlistHocSinh() {
      console.log('Lấy danh sách học sinh')
      let params = {
        start: 0,
        limit: 100,
        maLop: this.searchForm.maLop,
        // namHoc: this.searchForm.year ? this.searchForm.year : '',
        // capHoc: this.searchForm.education,
        // khoiHoc: this.searchForm.grade,
        // maSo: this.user.maSo,
        // maPhong: this.user.maPhong,
        // maTruong: this.user.maTruong,
        // isInHocBa: true,
      }
      this.getListHs(params);
    },
    ...mapActions('hocsinh', [
      "getListHs",
    ]),
    ...mapActions("hocba", [
      "getDetailPdf",
    ]),
    ...mapActions('teacher', [
      "getListLop",
    ]),
    getlistLopHoc() {
      let params = {
        start: 0,
        limit: 999,
        khoiHoc: this.searchForm.grade,
        namHoc: this.searchForm.year,
        maTruong: this.searchForm.maTruongHoc,
      }
      let url = constant.danhMuc.danhSachLopTheoTruong;
      this.danh_sach_lop_hoc = [];
      this.loadingScreen = true;
      api.get(url, params).then(
          response => {
            console.log('response lấy lớp:')
            console.log(response)
            if (response.data.code == 200) {
              let data = response.data.data;
              console.log(data)
              for (let i = 0; i < data.length; i++) {
                if(data[i].maLop&&data[i].tenLop){
                  this.danh_sach_lop_hoc.push(
                      {
                        name: data[i].tenLop,
                        value: data[i].maLop
                      }
                  )
                }
              }
            } else {
            }
            console.error(this.list_truong_hoc)
            this.loadingScreen = false;
          }
      )
      // this.getListLop({
      //     start: 0,
      //     limit: 999,
      //     khoiHoc: this.searchForm.grade,
      //     namHoc: this.searchForm.year,
      //     lopHoc : this.searchForm.maLop
      // })
    },
  }
}
</script>
<style scoped>
.width20 {
  padding-right: 4%
}

.viewHocBa {
  width: 100%;
  height: 100vh;
}

.lableCheckBox {
  padding-left: 6px;
  font-size: 16px;
}

input[type="radio"],
input[type="checkbox"] {
  transform: scale(1.5);
}

.el-form-item {
  width: 100%
}

.el-input-group__append {
  width: 45% !important;
}
</style>