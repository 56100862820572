<template>
  <el-input
    :type="showPassword ? 'text' : 'password'"
    clearable
    v-model="modelValue"
    :placeholder="placeholder"
    @change="handleChange"
  >
    <font-awesome-icon
      slot="suffix"
      :icon="showPassword ? 'eye-slash' : 'eye'"
      @click="togglePasswordVisibility"
    />
  </el-input>
</template>

<script>
export default {
  name: "InputPassword",
  props: {
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Nhập",
    },
  },
  data() {
    return {
      showPassword: false,
    };
  },
  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    handleChange() {
      this.$emit("change");
    },
  },
};
</script>

<style scoped>
::v-deep .el-input__suffix {
  align-items: center;
  display: flex;
  cursor: pointer;
}
</style>
