<template>
    <el-dialog
        title="Phân lớp"
        class="modalPhanlop"
        :destroy-on-close="true"
        center
        :before-close="closePhanLopModal"
        :visible.sync="showDialogPhanLop"
        append-to-body>

        <el-form v-model="formPhanLop" ref="formPhanLop" label-width="120px">

            <el-form-item label="Họ và tên">
                <el-input
                    disabled
                    placeholder="Họ và tên"
                    v-model="formPhanLop.nameHS">
                </el-input>
            </el-form-item>
            <el-form-item label="Khối học">
                <el-input
                    disabled
                    placeholder="Khối học"
                    v-model="formPhanLop.khoiHoc">
                </el-input>
            </el-form-item>
            <el-form-item label="Lớp">
                <el-select v-model="formPhanLop.lop" placeholder="Chọn lớp">
                    <el-option v-for="item in listLop.list" :label="item.tenLop" :key="item.maLop"
                               :value="item.maLop"></el-option>
                </el-select>
                <div v-if="error.lop" class="el-form-item__error">
                    {{ error.lop }}
                </div>
            </el-form-item>
            <div class="text-center mt-2">
                <el-button type="primary" round="round" @click.prevent="confirmPhanLop('formPhanLop')">Xác nhận
                </el-button>
            </div>
        </el-form>


    </el-dialog>
</template>

<script>

import {mapActions, mapState} from 'vuex'
import dialog from "../dialog";

export default {
    name: "PhanLop",
    props: [
        "showDialogPhanLop",
        "selectItem"
    ],
    data() {
        return {
            error: {
                lop: ''
            },
            capHoc: '',
            formPhanLop: {
                khoiHoc: '',
                lop: '',
                nameHS: ''
            }
        }
    },

    computed: {
        ...mapState('teacher', [
            "listLop",
            "phanLop"
        ]),
    },

    methods: {
        ...mapActions('teacher', [
            "getListLop",
            "clear",
            "actionPhanLop"
        ]),
        closePhanLopModal() {
            this.$emit('closeModal');
        },
        getListLopHoc() {
            this.getListLop({
                start: 0,
                limit: 999,
                khoiHoc: this.selectItem.khoiHoc,
            })
        },
        confirmPhanLop() {
            if (!this.formPhanLop.lop) {
                this.error.lop = 'Lớp chưa được chọn';
                return;
            }
            this.error.lop = '';
            this.actionPhanLop({
                "id": this.selectItem.id,
                "maLop": this.formPhanLop.lop
            })
        }

    },
    mounted() {
    },
    watch: {
        showDialogPhanLop(val) {
            this.lop = '';
            this.getListLopHoc();
            if (val) {
                this.formPhanLop.khoiHoc = this.selectItem.khoiHoc;
                this.formPhanLop.nameHS = this.selectItem.userNameYC;
            }
        },
        phanLop(val) {
            if (val) {
                dialog.showDialog('Thông báo', val.message, () => {

                })
                this.$emit('closeModal');

            }
        }
    }

}
</script>

<style scoped>

</style>
