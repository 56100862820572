<template>
  <div class="page page-file file detailHocBa">
    <div v-loading.fullscreen.lock="fullscreenLoading"
         element-loading-text="Đang tải dữ liệu..."
         element-loading-spinner="el-icon-loading"
         element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="page__title">
        <h1 class="title">Cấu hình ký bìa</h1>
      </div>
      <div class="page__header">
        <el-row :gutter="20">
          <el-col :xs="12" :sm="12" :md="6" :lg="4">
            <label>Năm học:</label>
            <eselect style="width:100%" collapseTags v-model="searchForm.namHoc" placeholder="Chọn"
                     filterable :data="list_nam_hoc" :fields="['name','value']"/>
          </el-col>
          <el-col :xs="12" :sm="12" :md="6" :lg="4">
            <label>Cấp học:</label>
            <eselect :disabled="list_cap_hoc.length==1" style="width:100%" collapseTags
                     v-model="searchForm.capHoc"
                     placeholder="Chọn" filterable :data="list_cap_hoc" :fields="['name','value']"/>
          </el-col>
          <el-col :xs="12" :sm="12" :md="6" :lg="4">
            <div style="color: transparent">Tìm kiếm</div>
            <el-button class="m-0" type="primary" @click="getData()">Tìm kiếm
            </el-button>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="file--title">
      <h5 class="title">Danh sách cấu hình</h5>
    </div>
    <div class="page__content">
      <el-table border fit :lazy="true" :data="list_data ? list_data : []" width="100%"
                ref="listGiaoVien">
        <el-table-column label="STT" style="width: 50px" width="80" type="index" class-name="text-center"
                         :index="indexMethod">
        </el-table-column>
        <el-table-column label="Năm học" header-align="center"
                         align="center">
          <template slot-scope="scope">
            <div><p>{{ scope.row.namHoc }}-{{ scope.row.namHoc + 1 }}</p></div>
          </template>
        </el-table-column>
        <el-table-column label="Cấp học" property="capHoc" align="center"></el-table-column>
        <el-table-column label="Khối học" property="khoiHoc" class-name="text-center"></el-table-column>

        <el-table-column label="Người ký" header-align="center"
                         align="left">
          <template slot-scope="scope">
            <div v-if="scope.row.giaoVienPhanCong">
              <p v-if="scope.row.giaoVienPhanCong.tenGV">Họ tên: {{ scope.row.giaoVienPhanCong.tenGV }}</p>
              <p v-if="scope.row.giaoVienPhanCong.chucVu">Chức vụ:
                {{ scope.row.giaoVienPhanCong.chucVu == 1 ? 'Hiệu trưởng' : 'Phó hiệu trưởng' }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Tiêu đề ký" property="tieuDeXacNhan" class-name="text-center"></el-table-column>
        <el-table-column label="Hậu tố sổ đăng bộ" property="hauToSoDangBo" class-name="text-center"></el-table-column>
<!--        <el-table-column label="Tên trường" property="tenTruong" class-name="text-center"></el-table-column>-->
        <el-table-column label="Hành động" header-align="center"
                         align="center">
          <template slot-scope="scope">
            <div>
              <el-button size="mini" @click.prevent="showUpdate(scope.row)" type="warning">Chỉnh sửa
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog title="Cập nhật thông tin trang bìa" width="50%" center custom-class="thongTinKyTheoMonHoc"
               append-to-body :show-close="true"
               :close-on-click-modal="false" :close-on-press-escape="false"
               :visible.sync="show_update">
      <el-row :gutter="24">
        <el-col :span="12">
          <label>Hậu tố sổ đăng bộ:
          </label>
          <el-input
              type="text" placeholder="Nhập..."
              v-model="infoKy.hauToSoDangBo"></el-input>
        </el-col>
<!--        <el-col :span="12">-->
<!--          <label>Tên trường:-->
<!--          </label>-->
<!--          <el-input-->
<!--              type="text" placeholder="Nhập..."-->
<!--              v-model="infoKy.tenTruong"></el-input>-->
<!--        </el-col>-->
        <el-col :span="12" >
          <label>Người ký: <span style="color:red">*</span>
          </label>

          <eselect style="width:100%" collapseTags v-model="infoKy.giaoVien" placeholder="Chọn"
                   filterable :data="list_giao_vien_ky" :fields="['name','value']"/>
        </el-col>
        <el-col :span="12" style="padding-top: 15px">
          <label>Tiêu đề ký: <span style="color:red">*</span>
            <el-tooltip placement="top">
              <div slot="content">Ghi rõ: Chức danh ký hoặc ký thay<br/>
                Ví dụ: Hiệu trưởng
                <br>
                KT Hiệu trưởng
                <br>
                Phó hiệu trưởng
              </div>
              <small><i style="color: blue">Hướng dẫn</i></small>
            </el-tooltip>
          </label>
          <el-input
              type="textarea" class="d-flex" style="height: 40px !important;" placeholder="Nhập..."
              v-model="infoKy.tieuDeXacNhan"></el-input>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="show_update = false">Đóng</el-button>
                <el-button size="mini" type="warning" @click.prevent="confirmUpdateData()">Chỉnh sủa</el-button>
          </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  mapActions,
  mapState
} from 'vuex';
import ESelectVue from "./Ui/ESelect.vue";
import constant from "../constant";
import dialog from "../dialog";
import Pagination from "../components/Pagination";
import ChonCapHoc from "../components/ChonCapHoc";
import constant_api from '../_helpers/constant_api';
import api from '../_helpers/api';

export default {
  name: "CauHinhKyBia",
  metaInfo: {
    title: 'Cấu hình ký bìa',
    titleTemplate: '',
    meta: [{
      vmid: '',
      name: '',
      content: ''
    }]
  },
  computed: {
    ...mapState('account',
        [
          'user',
          'list_nam_hoc',
          'profile',
          'nam_hoc_hien_tai',
          'list_cap_hoc',
        ]),
  },
  components: {
    Pagination,
    'eselect': ESelectVue,
    ChonCapHoc,
  },
  data() {
    return {
      infoKy: {
        giaoVien: '',
        tieuDeXacNhan: ''
      },
      giaoVienKy: '',
      fullscreenLoading: false,
      list_data: [],
      paginate: {
        start: 0,
        limit: 5,
        current: 1,
        total: 0
      },
      searchForm: {
        namHoc: '',
        capHoc: ''
      },
      dataUpdate: {},
      show_update: false,
      list_giao_vien_ky: [],
    }
  },
  mounted() {
    this.searchForm.namHoc = this.nam_hoc_hien_tai;
    if (this.list_cap_hoc.length == 1) {
      this.searchForm.capHoc = this.list_cap_hoc[0].value
    }
    this.getData();
  },
  methods: {
    confirmUpdateData() {
      console.log('confirmUpdateData')
      console.log(this.infoKy.tieuDeXacNhan)
      this.infoKy.tieuDeXacNhan = this.infoKy.tieuDeXacNhan.trim();
      if (!this.infoKy.giaoVien || !this.infoKy.tieuDeXacNhan||this.infoKy.tieuDeXacNhan=='') {
        this.thongBao('error', 'Vui lòng nhập đủ thông tin Người ký và Tiêu đề ký.')
      } else {
        let params = {
          "maGV": this.infoKy.giaoVien,
          "namHoc": this.dataUpdate.namHoc,
          "hauToSoDangBo": this.infoKy.hauToSoDangBo,
          "tenTruong": this.infoKy.tenTruong,
          "tieuDeXacNhan": this.infoKy.tieuDeXacNhan,
          "khoiHoc": this.dataUpdate.khoiHoc,
          "capHoc": this.dataUpdate.capHoc
        }
        console.log('Thông tin cập nhật')
        console.log(params)
        try {
          let uri = constant_api.truong.capNhatPhanCongKyBiaVaTieuDe;
          this.fullscreenLoading = true;
          api.post(uri, params).then(
              response => {
                if (response.data.code == 200) {
                  this.thongBao('success', response.data.msg)
                  this.getData();
                } else {
                  this.thongBao('error', response.data.msg)
                }
                this.fullscreenLoading = false;
              }
          ).catch((e) => {
            this.loading = false;
            this.fullscreenLoading = false;
          })

        } catch (e) {
          this.thongBao('error', 'Vui lòng thao tác lại.')
        }
      }
    },
    getChucVu(role) {
      var res = parseInt(role);
      switch (res) {
        case 1:
          res = 'Hiệu trưởng';
          break;
        case 2:
          res = 'Phó hiệu trưởng';
          break;
        case 16:
          res = 'Giám đốc';
          break;
        case 17:
          res = 'Phó giám đốc';
          break;
        default:
          res = role;
      }
      return res;
    },
    showUpdate(item) {
      this.dataUpdate = JSON.parse(JSON.stringify(item));
      this.list_giao_vien_ky = [];
      if (this.dataUpdate.giaoVienTiemNang && this.dataUpdate.giaoVienTiemNang) {
        this.infoKy.giaoVien = this.dataUpdate.giaoVienPhanCong ? this.dataUpdate.giaoVienPhanCong.maGV : ''
        this.infoKy.tieuDeXacNhan = this.dataUpdate.tieuDeXacNhan
        let arr_gv = this.dataUpdate.giaoVienTiemNang;
        console.error('arr_gv')
        console.error(arr_gv)
        for (let i = 0; i < arr_gv.length; i++) {
          console.log(arr_gv[i])
          let obj = {
            name: arr_gv[i].tenGV + ' - [' + this.getChucVu(arr_gv[i].chucVu) + ']',
            value: arr_gv[i].maGV
          }
          this.list_giao_vien_ky.push(obj);
        }
        this.show_update = true;
      } else {
        this.thongBao('error', 'Không có giáo viên có thể ký. Vui lòng thử lại sau.')
      }
    },
    getTotalPage(number) {
      return Math.ceil(number / (this.paginate.limit));
    },
    getData() {
      try {
        let uri = constant_api.truong.phanCongKyBiaVaTieuDe;
        this.fullscreenLoading = true;
        let params = {
          namHoc: this.searchForm.namHoc,
          capHoc: this.searchForm.capHoc,
          khoiHoc: ''
        }
        this.show_update = false;
        api.get(uri, params).then(
            response => {
              if (response.data.code == 200) {
                this.list_data = response.data.data
                console.error('Danh sách:')
                console.error(this.list_data)
              } else {
                this.thongBao('error', response.data.msg)
              }
              this.fullscreenLoading = false;
            }
        ).catch((e) => {
          this.loading = false;
          this.fullscreenLoading = false;
        })

      } catch (e) {
        this.thongBao('error', 'Vui lòng thao tác lại.')
      }
    },
    indexMethod(index) {
      return (this.paginate.start + index) + 1;
    },
    thongBao(t, e) {
      let msg = "";
      let cl = "";
      if (e) {
        msg = e;
      }
      let type = "success";
      if (t) {
        type = t
      }
      if (type == "success") {
        cl = "dts-noty-success"
      }
      if (type == "warning") {
        cl = "dts-noty-warning"
      }
      if (type == "error") {
        cl = "dts-noty-error"
      }
      if (type == "info") {
        cl = "dts-noty-info"
      }
      this.$message({
        customClass: cl,
        showClose: true,
        message: msg,
        type: t,
        duration: 3000
      });
    },
  },
}
</script>

<style scoped>
.el-form-item {
  width: 100%
}

.resultText {
  white-space: nowrap;
  /*width: 100px;*/
  overflow: hidden;
  text-overflow: ellipsis;
  /*border: 1px solid #000000;*/
}

.showMore {
  background: #409EFF;
  padding: 1px 5px;
  border-radius: 3px;
  color: white;
  font-weight: 700;
}
</style>