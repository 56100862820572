var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex w-100 header__nav" }, [
    _c("div", { staticClass: "d-flex align-items-center w-100" }, [
      _c(
        "div",
        { staticClass: "menu-icon-mobile" },
        [
          _c(
            "el-link",
            {
              attrs: { underline: false },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.setMenuMobile(true)
                },
              },
            },
            [_c("i", { staticClass: "ion ion-md-menu" })]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "right ml-auto" }, [
        _c(
          "div",
          { staticStyle: { display: "none" } },
          [
            _c(
              "el-tooltip",
              {
                staticClass: "item",
                attrs: {
                  effect: "dark",
                  content: "Tính năng đang trong quá trình phát triển",
                  placement: "top",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "notify" },
                  [
                    _c(
                      "el-badge",
                      {
                        staticClass: "item",
                        staticStyle: { padding: "0 5px" },
                        attrs: { "is-dot": "" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "notify--square cursor-pointer" },
                          [
                            _c("i", {
                              staticClass: "el-icon-message-solid",
                              attrs: { type: "primary" },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.shouldShowInstruct
          ? _c("div", { staticClass: "instruct" }, [
              _c(
                "a",
                {
                  attrs: {
                    href: "https://docs.google.com/document/d/1SiqD4grynkkEd6tOFLHU8kG7OQtXT189/edit",
                    target: "_blank",
                  },
                },
                [_vm._v("\n          Hướng dẫn sử dụng\n        ")]
              ),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo" }, [
      _c("img", { attrs: { src: "/images/logo.png", alt: "logo" } }),
      _vm._v("\n      Học bạ số\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }