var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true, lock: true },
        },
      ],
      staticClass: "page page-file file detailHocBa",
      attrs: { id: "danhSachLop" },
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "box-upload-file" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 12, md: 8, lg: 4 } },
                [
                  _c("label", [_vm._v("Năm học")]),
                  _vm._v(" "),
                  _c("eselect", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      collapseTags: "",
                      placeholder: "Chọn",
                      filterable: "",
                      data: _vm.list_nam_hoc,
                      fields: ["name", "value"],
                    },
                    model: {
                      value: _vm.namHoc,
                      callback: function ($$v) {
                        _vm.namHoc = $$v
                      },
                      expression: "namHoc",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 12, md: 8, lg: 4 } },
                [
                  _c("label", [_vm._v("Cấp học")]),
                  _vm._v(" "),
                  _c("eselect", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      disabled: _vm.list_cap_hoc.length == 1,
                      placeholder: "Chọn",
                      filterable: "",
                      data: _vm.list_cap_hoc,
                      fields: ["name", "value"],
                    },
                    on: { change: _vm.capHocChange },
                    model: {
                      value: _vm.capHoc,
                      callback: function ($$v) {
                        _vm.capHoc = $$v
                      },
                      expression: "capHoc",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 12, md: 8, lg: 4 } },
                [
                  _c("label", [_vm._v("Khối học")]),
                  _vm._v(" "),
                  _c("eselect", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      collapseTags: "",
                      placeholder: "Chọn",
                      filterable: "",
                      data: _vm.danh_sach_khoi_hoc,
                      fields: ["label", "value"],
                    },
                    on: {
                      change: function ($event) {
                        return _vm.chonKhoiHoc()
                      },
                    },
                    model: {
                      value: _vm.khoiHoc,
                      callback: function ($$v) {
                        _vm.khoiHoc = $$v
                      },
                      expression: "khoiHoc",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 12, md: 8, lg: 6 } },
                [
                  _c("div", { staticStyle: { color: "transparent" } }, [
                    _vm._v("Tìm kiếm"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "m-0",
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.layDanhSachLopHoc($event)
                        },
                      },
                    },
                    [_vm._v("Tìm kiếm\n                    ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "page__content" }, [
        _c(
          "div",
          { staticClass: "listNew mb-5" },
          [
            _c(
              "el-row",
              { attrs: { gutter: 20 } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          "max-height": _vm.maxHeightTable,
                          fit: "",
                          lazy: "",
                          data: _vm.danh_sach_lop,
                          border: "",
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            fixed: "",
                            align: "center",
                            type: "index",
                            label: "STT",
                            width: "50",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            fixed: "",
                            prop: "tenLop",
                            width: "100",
                            label: "Tên lớp cũ",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            fixed: "",
                            prop: "siSoLopHocCu",
                            width: "100",
                            label: "Sĩ số",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            fixed: "",
                            prop: "dienLenLop",
                            width: "150",
                            label: "Thuộc diện lên lớp",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            fixed: "",
                            prop: "lopKetChuyen",
                            label: "Tên lớp kết chuyển",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            fixed: "",
                            prop: "siSoLopHocMoi",
                            label: "Số học sinh kết chuyển",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            fixed: "",
                            label: "Hành động",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            disabled: !scope.row.lopHocMois,
                                            size: "mini",
                                            type: "success",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.showKetChuyenHocSinh(
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                            Kết chuyển\n                                        "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type: "warning",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.huyKetChuyen(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                            Huỷ\n                                            kết chuyển\n                                        "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "el-dialog",
            {
              staticClass: "hisory-huychot",
              attrs: {
                align: "center",
                title: "Chuyển lớp",
                top: "5",
                width: "50%",
                "close-on-press-escape": false,
                "close-on-click-modal": false,
                visible: _vm.show_danh_sach_hoc_sinh_ket_chuyen,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.show_danh_sach_hoc_sinh_ket_chuyen = $event
                },
              },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "div",
                    { staticClass: "text-left" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 24 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { xs: 18, sm: 12, md: 12, lg: 12 } },
                            [
                              _c("label", [_vm._v("Lớp học chuyển đến")]),
                              _vm._v(" "),
                              _c("eselect", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  collapseTags: "",
                                  placeholder: "Chọn",
                                  filterable: "",
                                  data: _vm.danh_sach_lop_hoc_chuyen_den,
                                  fields: ["tenLop", "maLop"],
                                },
                                model: {
                                  value: _vm.dataChuyenLop.lopMoi,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataChuyenLop, "lopMoi", $$v)
                                  },
                                  expression: "dataChuyenLop.lopMoi",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "div",
                                { staticStyle: { color: "transparent" } },
                                [_vm._v("Tìm kiếm")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "mb-1",
                                  attrs: {
                                    disabled:
                                      !_vm.listHocSinhCheckHuyChot ||
                                      _vm.listHocSinhCheckHuyChot.length == 0,
                                    type: "success",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.ketChuyenDanhSachHocSinh()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " Kết chuyển\n                                "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      attrs: {
                        border: "",
                        fit: "",
                        "max-height": _vm.maxHeightHuyChot,
                        lazy: true,
                        data: _vm.danh_sach_hoc_sinh,
                        width: "100%",
                      },
                    },
                    [
                      _c("el-table-column", {
                        staticStyle: { width: "50px" },
                        attrs: {
                          width: "50",
                          align: "center",
                          "header-align": "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "header",
                            fn: function (scope) {
                              return [
                                _c("el-checkbox", {
                                  on: { change: _vm.ChonToanBoHocSinh },
                                  model: {
                                    value: _vm.CheckAllStudent,
                                    callback: function ($$v) {
                                      _vm.CheckAllStudent = $$v
                                    },
                                    expression: "CheckAllStudent",
                                  },
                                }),
                              ]
                            },
                          },
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-checkbox", {
                                  on: {
                                    change: function ($event) {
                                      return _vm.chonMoiHocBaHuyChot(scope.row)
                                    },
                                  },
                                  model: {
                                    value: scope.row.check,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "check", $$v)
                                    },
                                    expression: "scope.row.check",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "STT",
                          type: "index",
                          width: "50",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "Mã học sinh",
                          property: "maHS",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          "header-align": "center",
                          label: "Tên học sinh",
                          property: "tenHS",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "page__title d-flex align-items-center justify-content-between",
      },
      [_c("h1", { staticClass: "title" }, [_vm._v("Chuyển lớp")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }