<template>
    <div id="detailHocBa" class="page page-file file detailHocBa thayHieTruong"
         v-loading.fullscreen.lock="fullscreenLoading">
        <div class="page__title d-flex align-items-center justify-content-between">
            <h1 class="title">Làm thay Hiệu trưởng</h1>

        </div>
        <div class="box-upload-file">
            <el-row :gutter="24">
                <el-col :span="4">
                    <label>Năm học</label>
                    <eselect style="width: 100%" collapseTags v-model="searchForm.year" placeholder="Chọn"
                             filterable
                             :data="list_nam_hoc" :fields="['name','value']"/>
                </el-col>
                <el-col :span="6">
                    <div style="color: transparent">Tìm kiếm</div>
                    <el-button class="m-0" type="primary" @click="getDuLieu(1)">Tìm kiếm</el-button>
                </el-col>
            </el-row>
            <el-form v-if="false" inline label-position="top" :rules="rulesSearch" ref="searchForm" :model="searchForm"
                     :hide-required-asterisk="true">
                <el-form-item class="d-inline-block" label="Năm học" prop="year">
                    <eselect style="width:150px" collapseTags v-model="searchForm.year" placeholder="Chọn"
                             filterable
                             :data="list_nam_hoc" :fields="['name','value']"/>
                </el-form-item>
                <el-form-item>
                    <el-button style="margin-top: 40px" type="primary" @click="submitSearch('searchForm')">Tìm kiếm
                    </el-button>
                </el-form-item>
            </el-form>
        </div>

        <div class="page__content">
            <div class="listNew mb-5">
                <el-table
                        :max-height="maxHeight"
                        border fit :lazy="true" :data="danh_sach_phan_cong_ky ? danh_sach_phan_cong_ky : []"
                        width="100%" ref="listFile" v-loading="loading">

                    <el-table-column label="STT" style="width: 50px" width="50" type="index" class-name="text-center"
                                     :index="indexMethod">
                    </el-table-column>
                    <el-table-column label="Mã giáo viên" width="150" align="center" property="maGV"></el-table-column>
                    <el-table-column label="Tên giáo viên" property="tenGV" header-align="center" align="left"
                                     class="text-center"></el-table-column>
                    <el-table-column label="Chức vụ" width="150" header-align="center" align="left">
                        <template slot-scope="scope">
                            <p>{{ getTenChucVu(scope.row.chucVu) }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="Tên trường" width="150" align="center" property="tenTruong">
                    </el-table-column>
                    <el-table-column label="Tiêu đề ký thay KQHT" property="tieuDeKyThayKqht" header-align="center"
                                     align="left"
                                     class="text-center">
                    </el-table-column>
                    <el-table-column label="Tiêu đề ký phê duyệt" property="tieuDeKyPheDuyet" header-align="center"
                                     align="left"
                                     class="text-center"></el-table-column>
                    <el-table-column label="Danh sách khối ký KQHT" header-align="center">
                        <template slot-scope="scope">
                            <el-checkbox-group v-model="scope.row.khoiHocDaChon">
                                <el-checkbox v-for="item in scope.row.khoiHocs" :label="item.number" :key="item.number">
                                    {{ item.name }}
                                </el-checkbox>
                            </el-checkbox-group>
                        </template>
                    </el-table-column>
                    <el-table-column label="Danh sách ký Quá trình học tập" header-align="center">
                        <template slot-scope="scope">
                            <el-checkbox-group v-model="scope.row.khoiHocKyBiaDaChon">
                                <el-checkbox v-for="item in scope.row.khoiHocs" :label="item.number" :key="item.number">
                                    {{ item.name }}
                                </el-checkbox>
                            </el-checkbox-group>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="150" label="Hành động" class-name="">
                        <template slot-scope="scope">
                            <div class="btn__table_action">
                                <el-tooltip content="Phân công ký" placement="top">
                                    <el-button data-option="" size="mini" @click="PhanCongKy(scope.row)" type="primary"
                                               icon="el-icon-circle-plus">
                                    </el-button>
                                </el-tooltip>
                                <el-tooltip content="Chỉnh sửa tiêu đề ký" placement="top">
                                    <el-button size="mini" @click="hienThiChinhSuaTieuDe(scope.row)" type="warning"
                                               icon="el-icon-edit">
                                    </el-button>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="d-flex justify-content-between align-items-center">
                    <template v-if="total">
                        <small>
                            <i>Danh sách có {{ total }} cán bộ</i>
                        </small>
                    </template>
                    <!--                    <div class="text-right mt-2">-->
                    <!--                        <pagination v-if="danh_sach_phan_cong_ky" :page-count="getTotalPage(total)" v-model="paginate.page"-->
                    <!--                                    :initial-page="paginate.page" :click-handler="getDuLieu" :prev-text="'Trước'"-->
                    <!--                                    :next-text="'Sau'"-->
                    <!--                                    :container-class="'el-pager'" :pageClass="'number'">-->

                    <!--                        </pagination>-->
                    <!--                    </div>-->
                </div>

            </div>

            <div>
                <el-dialog title="Cập nhật thông tin trang KQHT" width="50%" center custom-class="thongTinKyTheoMonHoc"
                           append-to-body :show-close="true"
                           :close-on-click-modal="false" :close-on-press-escape="false"
                           :visible.sync="show_update">
                    <el-row :gutter="24">
                      <el-col :span="12">

                        <label>Tên trường:
                          <span style="color: red">*</span>
                        </label>
                        <el-input
                            type="text" class="d-flex" style="height: 40px !important;"
                            placeholder="Nhập..." v-model="thongTinTieuDeChinhSua.tenTruong"></el-input>
                      </el-col>
                      <el-col :span="12">

                        <label>Tiêu đề ký thay:
                          <span style="color: red">*</span>
                          <el-tooltip placement="top">
                            <div slot="content">Ghi rõ: Chức danh ký hoặc ký thay<br/>
                              Ví dụ: Hiệu trưởng
                              <br>Nếu Phó hiệu trưởng ký thay ghi rõ tiêu đề:
                              <br>
                              Vị dụ: Xác nhận của Hiệu trưởng
                              <br>
                              KT. HIỆU TRƯỞNG
                              <br>
                              PHÓ HIỆU TRƯỞNG
                            </div>
                            <small><i style="color: blue">Hướng dẫn</i></small>
                          </el-tooltip>
                        </label>
                        <el-input
                            type="textarea" class="d-flex" style="height: 40px !important;"
                            placeholder="Nhập..." v-model="thongTinTieuDeChinhSua.tieuDeKyThayKqht"></el-input>
                      </el-col>
                        <el-col :span="12">
                            <label>Tiêu đề phê duyệt:
                                <span style="color: red">*</span>
                                <el-tooltip placement="top">
                                    <div slot="content">Ghi rõ: Chức danh ký hoặc ký thay<br/>
                                        Ví dụ: Hiệu trưởng
                                        <br>Nếu Phó hiệu trưởng ký thay ghi rõ tiêu đề:
                                        <br>
                                        Vị dụ: Xác nhận của Hiệu trưởng
                                        <br>
                                        KT. HIỆU TRƯỞNG
                                        <br>
                                        PHÓ HIỆU TRƯỞNG
                                    </div>
                                    <small><i style="color: blue">Hướng dẫn</i></small>
                                </el-tooltip>
                            </label>
                            <el-input
                                    type="textarea" class="d-flex" style="height: 40px !important;"
                                    placeholder="Nhập..." v-model="thongTinTieuDeChinhSua.tieuDeKyPheDuyet"></el-input>
                        </el-col>
                    </el-row>
                    <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="show_update = false">Đóng</el-button>
                <el-button size="mini" type="warning" @click.prevent="confirmUpdateData()">Chỉnh sủa</el-button>
          </span>
                </el-dialog>
            </div>
        </div>
    </div>

</template>

<script>
import websocket from "../websocket";
import {
    mapActions,
    mapState
} from 'vuex';
import Pagination from "../components/Pagination";
import dialog from "../dialog";
import DialogAlert from "../components/DialogAlert";
import ChonKhoiHoc from "../components/ChonKhoiHoc";
import CapHocKhoiHoc from "../components/CapHocKhoiHoc";
import ThongTinHocBa from "../pages/HocBa/ThongTinHocBa";
import ESelectVue from "./Ui/ESelect";
import constant from '../constant';
import constant_api from '../_helpers/constant_api';
import api from '../_helpers/api';


export default {
    name: "DanhSachHocSinh",
    metaInfo: {
        title: 'Làm thay Hiệu trưởng',
        titleTemplate: '',
        meta: [{
            vmid: 'description',
            name: 'description',
            content: ''
        }]
    },
    computed: {
        ...mapState('account', [
            'user',
            'list_nam_hoc'
        ]),
        ...mapState('until', [
            'window',
        ])
    },
    components: {
        DialogAlert,
        Pagination,
        ChonKhoiHoc,
        CapHocKhoiHoc,
        'eselect': ESelectVue,
    },

    data() {

        return {
            danh_sach_phan_cong_ky: [],
            loading: false,
            maxHeight: window.innerHeight,
            paginate: {
                start: 0,
                limit: 100,
                page: 1,
            },
            total: 0,
            fullscreenLoading: false,
            searchForm: {
                year: '',
            },
            rulesSearch: {
                year: [{
                    required: true,
                    message: 'Bạn chưa chọn năm học',
                    trigger: 'change'
                }],
            },
            thongTinTieuDeChinhSua: {},
            show_update: false,
        }
    },
    methods: {
        confirmUpdateData() {
            console.log('confirmUpdateData')
            if (!this.thongTinTieuDeChinhSua.tieuDeKyThayKqht || this.thongTinTieuDeChinhSua.tieuDeKyThayKqht == '' || !this.thongTinTieuDeChinhSua.tieuDeKyPheDuyet || this.thongTinTieuDeChinhSua.tieuDeKyPheDuyet == '') {
                this.thongBao('error', 'Vui lòng nhập đủ thông tin Tiêu đề ký thay và Tiêu đề phê duyệt')
                return;
            }
            var e = JSON.parse(JSON.stringify(this.thongTinTieuDeChinhSua))
            console.log(e);
            let duLieu = e;
            let khoiHocDaChon = duLieu.khoiHocDaChon;
            for (let i = 0; i < duLieu.khoiHocs.length; i++) {
                duLieu.khoiHocs[i].check = false;
                for (let j = 0; j < khoiHocDaChon.length; j++) {
                    if (duLieu.khoiHocs[i].number == khoiHocDaChon[j]) {
                        duLieu.khoiHocs[i].check = true;
                    }
                }
            }
            let khoiHocKyBiaDaChon = duLieu.khoiHocKyBiaDaChon;
            for (let i = 0; i < duLieu.khoiHocs.length; i++) {
                duLieu.khoiHocs[i].checkKyBia = false;
                for (let j = 0; j < khoiHocKyBiaDaChon.length; j++) {
                    if (duLieu.khoiHocs[i].number == khoiHocKyBiaDaChon[j]) {
                        duLieu.khoiHocs[i].checkKyBia = true;
                    }
                }
            }
            let checkKhoi = false;
            for (let i = 0; i < duLieu.khoiHocs.length; i++) {
                if (duLieu.khoiHocs[i].checkKyBia || duLieu.khoiHocs[i].check) {
                    checkKhoi = true;
                }
            }
            console.log("Sau khi map:")
            console.log(duLieu)
            let msg = 'Xác nhận phân công ký'
            this.$confirm(msg, 'Thông báo', {
                confirmButtonText: 'Phân công',
                cancelButtonText: 'Hủy',
                confirmButtonClass: 'el-button--primary',
            })
                .then(_ => {
                    let url = constant_api.giaovien.phanCongKyThayHieuTruong;
                    this.fullscreenLoading = true;
                    api.post(url, duLieu).then(
                        response => {
                            this.fullscreenLoading = false;
                            console.log("Res:")
                            console.log(response)
                            if (response.data.code == 200) {
                                this.thongBao("success", "Phân công ký thành công")
                                this.getDuLieu(1);
                                this.submitSearch('searchForm');
                            } else {
                                this.thongBao("error", response.data.msg)
                            }
                        }
                    )
                })
                .catch(_ => {
                });
        },
        hienThiChinhSuaTieuDe(e) {
            console.log('hienThiChinhSuaTieuDe')
            console.log(e)
            this.thongTinTieuDeChinhSua = JSON.parse(JSON.stringify(e))
            this.show_update = true;
        },
        PhanCongKy(e) {
            console.log("Thêm của:")
            console.log(e);
            let duLieu = e;
            let khoiHocDaChon = duLieu.khoiHocDaChon;
            for (let i = 0; i < duLieu.khoiHocs.length; i++) {
                duLieu.khoiHocs[i].check = false;
                for (let j = 0; j < khoiHocDaChon.length; j++) {
                    if (duLieu.khoiHocs[i].number == khoiHocDaChon[j]) {
                        duLieu.khoiHocs[i].check = true;
                    }
                }
            }
            let khoiHocKyBiaDaChon = duLieu.khoiHocKyBiaDaChon;
            for (let i = 0; i < duLieu.khoiHocs.length; i++) {
                duLieu.khoiHocs[i].checkKyBia = false;
                for (let j = 0; j < khoiHocKyBiaDaChon.length; j++) {
                    if (duLieu.khoiHocs[i].number == khoiHocKyBiaDaChon[j]) {
                        duLieu.khoiHocs[i].checkKyBia = true;
                    }
                }
            }
            let checkKhoi = false;
            for (let i = 0; i < duLieu.khoiHocs.length; i++) {
                if (duLieu.khoiHocs[i].checkKyBia || duLieu.khoiHocs[i].check) {
                    checkKhoi = true;
                }
            }
            console.log("Sau khi map:")
            console.log(duLieu)
            let msg = 'Xác nhận phân công ký'
            this.$confirm(msg, 'Thông báo', {
                confirmButtonText: 'Phân công',
                cancelButtonText: 'Hủy',
                confirmButtonClass: 'el-button--primary',
            })
                .then(_ => {
                    let url = constant_api.giaovien.phanCongKyThayHieuTruong;
                    this.fullscreenLoading = true;
                    api.post(url, duLieu).then(
                        response => {
                            this.fullscreenLoading = false;
                            console.log("Res:")
                            console.log(response)
                            if (response.data.code == 200) {
                                this.thongBao("success", "Phân công ký thành công")
                                this.submitSearch('searchForm');
                            } else {
                                this.thongBao("error", response.data.msg)
                            }
                        }
                    )
                })
                .catch(_ => {
                });
        },
        getTenChucVu(e) {
            let result = "";
            if (e == 1) {
                result = "Hiệu trưởng";
            } else if (e == 2) {
                result = "Phó hiệu trưởng";
            } else if (e == 2) {
                result = "Phó hiệu trưởng";
            } else if (e == 16) {
                result = "Giám đốc";
            } else if (e == 17) {
                result = "Phó giám đốc";
            } else {
                e = e;
            }
            return result;
        },
        getDanhSachPhanCongKy(e) {
            console.log("Lấy danh sách học sinh:")
            console.log(e);
            let url = constant_api.giaovien.danhSachPhanCongKyHieuTruong;
            this.fullscreenLoading = true;
            this.loading = true;
            api.get(url, e).then(
                response => {
                    this.loading = false;
                    this.fullscreenLoading = false;
                    console.log("Res:")
                    console.log(response)
                    if (response.data.code == 200) {
                        let danh_sach_phan_cong_ky = [];
                        let du_lieu = response.data.data;
                        for (let i = 0; i < du_lieu.length; i++) {
                            let obj = {
                                chucVu: du_lieu[i].chucVu,
                                khoiHocs: du_lieu[i].khoiHocs,
                                maGV: du_lieu[i].maGV,
                                namHoc: du_lieu[i].namHoc,
                                tenGV: du_lieu[i].tenGV,
                                tieuDeKyPheDuyet: du_lieu[i].tieuDeKyPheDuyet,
                                tenTruong: du_lieu[i].tenTruong,
                                tieuDeKyThayKqht: du_lieu[i].tieuDeKyThayKqht,
                                khoiHocDaChon: [],
                                khoiHocKyBiaDaChon: [],
                            }
                            let khoi_hoc_da_chon = [];
                            let khoi_hoc_ky_bia_da_chon = [];
                            if (du_lieu[i].khoiHocs.length > 0) {
                                for (let j = 0; j < du_lieu[i].khoiHocs.length; j++) {
                                    if (du_lieu[i].khoiHocs[j].check == true) {
                                        khoi_hoc_da_chon.push(du_lieu[i].khoiHocs[j].number)
                                    }
                                }
                            }
                            if (du_lieu[i].khoiHocs.length > 0) {
                                for (let j = 0; j < du_lieu[i].khoiHocs.length; j++) {
                                    if (du_lieu[i].khoiHocs[j].checkKyBia == true) {
                                        khoi_hoc_ky_bia_da_chon.push(du_lieu[i].khoiHocs[j].number)
                                    }
                                }
                            }
                            obj.khoiHocDaChon = khoi_hoc_da_chon;
                            obj.khoiHocKyBiaDaChon = khoi_hoc_ky_bia_da_chon;
                            danh_sach_phan_cong_ky.push(obj)
                        }
                        this.danh_sach_phan_cong_ky = danh_sach_phan_cong_ky;
                        this.total = this.danh_sach_phan_cong_ky.length;
                        // this.total = response.data.data.total;
                        // this.thongBao('success', 'Lấy dữ liệu thành công')
                    } else {
                        // this.thongBao("error", 'Không lấy được danh sách dữ liệu')
                    }
                }
            )
        },
        getCurrentMonth() {
            let d = new Date();
            return d.getMonth();
        },
        indexMethod(index) {
            return (this.paginate.start + index) + 1;
        },
        submitSearch(formName) {
            if (this.$refs[formName]) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.loading = true;
                        this.getDuLieu(1)
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            }

        },
        getDuLieu(select) {
            this.show_update = false;
            this.showDialogOtp = false;
            this.paginate.start = (select - 1) * this.paginate.limit ? (select - 1) * this.paginate.limit : 0;
            this.paginate.page = select;
            this.getDanhSachPhanCongKy({
                start: this.paginate.start,
                limit: this.paginate.limit,
                namHoc: this.searchForm.year ? this.searchForm.year : '',
            });
        },
        getTotalPage(number) {
            return Math.ceil(number / (this.paginate.limit));
        },

        thongBao(t, e) {
            let msg = "";
            let cl = "";
            if (e) {
                msg = e;
            }
            let type = "success";
            if (t) {
                type = t
            }
            if (type == "success") {
                cl = "dts-noty-success"
            }
            if (type == "warning") {
                cl = "dts-noty-warning"
            }
            if (type == "error") {
                cl = "dts-noty-error"
            }
            if (type == "info") {
                cl = "dts-noty-info"
            }
            this.$message({
                customClass: cl,
                showClose: true,
                message: msg,
                type: t,
                duration: 3000
            });
        },
        getFullYear() {
            let d = new Date();
            return d.getFullYear();
        },
        getHeightTable() {
            if (this.window.height && this.window.height > 950) {
                return this.window.height - 100
            }
            if (this.window.height && this.window.height > 850) {
                return 800
            }
            if (this.window.height && this.window.height > 750) {
                return 700
            }
            return 650
        },
    },

    beforeMount() {
    },
    mounted() {
        this.maxHeight = this.getHeightTable()
        this.searchForm.year = this.getFullYear();
        this.searchForm.education = this.user.capHoc;

        let currentMonth = this.getCurrentMonth() + 1;
        if (currentMonth >= 9) {
        } else {
            this.searchForm.year -= 1;
        }
        this.getDuLieu(1);
    },
    created() {

    },
    watch: {},

}
</script>

<style scoped>
</style>