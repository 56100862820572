<template>
  <div class="page">
    <div class="page__title d-flex align-items-center justify-content-between"
         v-loading.fullscreen.lock="loadingScreen">
      <h1 class="title">Danh sách sổ điểm</h1>
    </div>
    <div class="box-upload-file">
      <el-row :gutter="24">
        <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3">
          <label>Năm học</label>
          <eselect style="width: 100%" collapseTags v-model="dataSearch.namHoc" :placeholder="'Chọn'"
                   filterable :data="list_nam_hoc" :fields="['name','value']"/>
        </el-col>
        <el-col :xs="12" :sm="8" :md="6" :lg="3" :xl="3">
          <label>Cấp học</label>
          <eselect :disabled="list_cap_hoc.length==1" style="width: 100%" @change="chonDonVi()" collapseTags v-model="dataSearch.capHoc"
                   :placeholder="'Chọn'"
                   filterable :data="list_cap_hoc" :fields="['name','value']"/>
        </el-col>
        <el-col :xs="12" :sm="8" :md="6" :lg="3" :xl="3">
          <label>Khối học</label>
          <eselect style="width:100%" collapseTags v-model="dataSearch.khoiHoc" placeholder="Chọn" filterable
                   @change="chonKhoiHoc()"
                   :data="danh_sach_khoi_hoc" :fields="['label','value']"/>
        </el-col>
        <el-col :xs="12" :sm="8" :md="6" :lg="3" :xl="3">
          <label>Lớp học</label>
          <eselect style="width:100%" collapseTags v-model="dataSearch.lopHoc" placeholder="Chọn" filterable
                   :data="danh_sach_lop_hoc" :fields="['tenLop','maLop']"/>
        </el-col>
        <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="4">
          <label>Giai đoạn</label>
          <eselect style="width:100%" collapseTags v-model="dataSearch.giaiDoan" placeholder="Chọn" filterable
                   :data="list_giai_doan" :fields="['name','value']"/>
        </el-col>
        <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="4">
          <label>Cấu hình</label>
          <eselect style="width:100%" collapseTags v-model="dataSearch.cauHinh" multiple placeholder="Chọn" filterable
                   :data="list_cau_hinh" :fields="['name','value']"/>
        </el-col>
        <el-col :xs="12" :sm="8" :md="6" :lg="3" :xl="3" class=" lg">
          <div style="color: transparent">Tìm kiếm</div>
          <el-button class="m-0" type="primary" @click.prevent="getlistSoDiem()">Xem</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="page__container " style="margin-top:25px " v-if="dataFileSoDiem.url">
      <div class="page__content">
        <div class="listNew mb-5">
          <el-row :gutter="24">
            <el-col class="text-right">

<!--              <el-checkbox v-if="!dataFileSoDiem.chot" v-model="isKyGVBM">Ký GVBM</el-checkbox>-->
              <el-button v-if="!dataFileSoDiem.chot" @click="chotDuLieuSoDiem(1)" size="mini" type="primary">  Chốt dữ liệu sổ điểm</el-button>
              <el-button v-else size="mini" @click="chotDuLieuSoDiem(2)" type="primary">  Hủy chốt dữ liệu sổ điểm</el-button>
            </el-col>
            <el-col :span="24">
              <div class="box-upload-file" style="overflow: scroll">
                <iframe :src="dataFileSoDiem.url" style="width:100%; height:80vh"></iframe>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapActions,
  mapState
} from 'vuex';
import api from "../_helpers/api";
import constant from "../_helpers/constant_api";
import ESelectVue from "./Ui/ESelect";
import constant_api from "../_helpers/constant_api";
import viewHocBaC1 from "./HocBa/viewHocBa/c1.vue";

export default {
  name: "BaoCaoDuLieuHocBa",
  metaInfo: {
    title: 'Danh sách sổ điểm',
    meta: [
      {vmid: 'description', name: 'description', content: ''}
    ],
  },
  components: {
    viewHocBaC1,
    'eselect': ESelectVue,
  },
  data() {
    return {
      maxHeightTable: window.innerHeight,
      list_don_vi: [],
      danh_sach_khoi_hoc: [],
      list_data: [],
      danh_sach_lop_hoc: [],
      list_giai_doan:[
        // {name:'Giữa kỳ 1',value:1},
        // {name:'Cuối kỳ 1',value:2},
        // {name:'Giữa kỳ 2',value:3},
        // {name:'Cuối kỳ 2',value:4},
        {name:'Cả năm',value:''},
      ],
      list_cau_hinh:[
        {name:'Ẩn chữ ký',value:'1'},
        {name:'Ẩn chữ ký hiệu trưởng',value:'2'},
        {name:'Ẩn dấu',value:'3'},
      ],
      loadingScreen: false,
      linkViewReport: '',
      dataFileSoDiem:{},
      isKyGVBM:false,
      linkDownloadReport: '',
      dataSearch: {
        namHoc: '',
        cauHinh: [],
        capHoc: '',
        giaiDoan: '',
        khoiHoc: '',
        lopHoc: '',
      },
      paginate: {
        start: 0,
        limit: 100,
        page: 1,
      },
      khoiCap1: [{
        value: 1,
        label: 'Khối 1'
      },
        {
          value: 2,
          label: 'Khối 2'
        },
        {
          value: 3,
          label: 'Khối 3'
        },
        {
          value: 4,
          label: 'Khối 4'
        },
        {
          value: 5,
          label: 'Khối 5'
        },
      ],
      khoiCap2: [{
        value: 6,
        label: 'Khối 6'
      },
        {
          value: 7,
          label: 'Khối 7'
        },
        {
          value: 8,
          label: 'Khối 8'
        },
        {
          value: 9,
          label: 'Khối 9'
        },
      ],
      khoiCap3: [{
        value: 10,
        label: 'Khối 10'
      },
        {
          value: 11,
          label: 'Khối 11'
        },
        {
          value: 12,
          label: 'Khối 12'
        },

      ],
    }
  },
  computed: {
    ...mapState('account', [
      'user',
      'nam_hoc_hien_tai',
      'list_nam_hoc',
      'list_cap_hoc',
    ]),
  },
  mounted() {
    console.error('Thông tin:')
    console.error(this.user)
    console.error(this.list_cap_hoc)
    console.log('Cấp học')
    if(this.list_cap_hoc.length==1){
      this.dataSearch.capHoc = this.list_cap_hoc[0].value
    }
    this.dataSearch.namHoc = this.nam_hoc_hien_tai;
    this.mapDanhSachKhoi()
    this.getlistLopHoc();
  },
  methods: {
    chotDuLieuSoDiem(stt){

      if (!this.dataSearch.lopHoc) {
        this.thongBao('error', 'Vui lòng bổ sung thông tin lớp học.')
        return;
      }
      let params = {
        maLop: this.dataSearch.lopHoc,
        isKyGVBM:this.isKyGVBM?true:true
      }
      console.log(params)
      try {
        let uri = constant_api.hocsinh.chotSoDiem;
        if (stt==2){
          uri = constant_api.hocsinh.huyChotSoDiem;
        }
        console.log('uri: ' + uri)
        this.loadingScreen = true;
        api.post(uri, params).then(
            response => {
              console.log("Chốt sổ điểm trả về:")
              console.log(response.data);
              if (response.data.code == 200) {
                this.thongBao('success',response.data.msg)
                this.getlistSoDiem();
              } else {
                this.thongBao('error', response.data.msg)
              }
              this.loadingScreen = false;
            }
        ).catch((e) => {
          this.loadingScreen = false;
        })

      } catch (e) {
        this.thongBao('error', 'Vui lòng thao tác lại.')
      }
    },

    mapDanhSachKhoi() {
      let arr = [];
      this.danh_sach_khoi_hoc = [];
      for (let i = 0; i < this.list_cap_hoc.length; i++) {
        if (this.list_cap_hoc[i].value == 1) {
          arr = arr.concat(this.khoiCap1)
        }
        if (this.list_cap_hoc[i].value == 2) {
          arr = arr.concat(this.khoiCap2)
        }
        if (this.list_cap_hoc[i].value == 3) {
          arr = arr.concat(this.khoiCap3)
        }
      }
      this.danh_sach_khoi_hoc = arr;
    },
    capHocChange() {
      this.dataSearch.khoiHoc = '';
      if (!this.dataSearch.capHoc) {
        this.mapDanhSachKhoi();
        return;
      } else {
        if (this.dataSearch.capHoc == 1) {
          this.danh_sach_khoi_hoc = this.khoiCap1;
        } else if (this.dataSearch.capHoc == 2) {
          this.danh_sach_khoi_hoc = this.khoiCap2;
        } else {
          this.danh_sach_khoi_hoc = this.khoiCap3;
        }
      }
    },
    getTime() {
      var d = new Date();
      var n = d.getTime();
      return n;
    },
    indexMethod(index) {
      return (this.paginate.start + index) + 1;
    },
    thongBao(t, e) {
      let msg = "";
      let cl = "";
      if (e) {
        msg = e;
      }
      let type = "success";
      if (t) {
        type = t
      }
      if (type == "success") {
        cl = "dts-noty-success"
      }
      if (type == "warning") {
        cl = "dts-noty-warning"
      }
      if (type == "error") {
        cl = "dts-noty-error"
      }
      if (type == "info") {
        cl = "dts-noty-info"
      }
      this.$message({
        dangerouslyUseHTMLString: true,
        customClass: cl,
        showClose: true,
        message: msg,
        type: t,
        duration: 3000
      });
    },
    chonKhoiHoc() {
      this.dataSearch.lopHoc = '';
      this.getlistLopHoc();
    },
    getlistSoDiem(){
      if (!this.dataSearch.lopHoc) {
        this.thongBao('error', 'Vui lòng bổ sung thông tin lớp học.')
        return;
      }
      let params = {
        isAnChuKy: true,
        isAnChuKyHieuTruong: true,
        isAnDau: true,
        giaiDoan: this.dataSearch.giaiDoan,
        maLop: this.dataSearch.lopHoc,
      }
      console.log(params)
      try {
        let uri = constant_api.hocsinh.getListSoDiem;
        console.log('uri: ' + uri)
        this.loadingScreen = true;
        api.post(uri, params).then(
            response => {
              console.log("Lấy sổ điểm trả về:")
              console.log(response.data);
              if (response.data.code == 200) {
                this.dataFileSoDiem = response.data.data;
                console.log('dataFileSoDiem')
                this.thongBao('success',response.data.msg)
                console.log(this.dataFileSoDiem)
              } else {
                this.thongBao('error',response.data.msg)
              }
              this.loadingScreen = false;
            }
        ).catch((e) => {
          this.loadingScreen = false;
        })

      } catch (e) {
        this.thongBao('error', 'Vui lòng thao tác lại.')
      }
    },
    getlistLopHoc() {
      console.log('lấy danh sách lớp')
      if (!this.dataSearch.namHoc) {
        this.thongBao('error', 'Vui lòng bổ sung thông tin năm học.')
        return;
      }
      console.log(this.user)
      let params = {
        start: 0,
        limit: 999,
        khoiHoc: this.dataSearch.khoiHoc,
        namHoc: this.dataSearch.namHoc,
        maTruong: this.user.maTruong,
      }
      console.log(params)

      try {
        let uri = constant_api.hocsinh.getListLop;
        console.log('uri: ' + uri)
        this.loadingScreen = true;
        this.danh_sach_lop_hoc = [];
        api.get(uri, params).then(
            response => {
              console.log("Lấy ds học bạ trả về:")
              console.log(response.data.data.list);
              if (response.data.code == 200) {
                let arr = response.data.data.list;
                for (let i = 0; i < arr.length; i++) {
                  if(arr[i].maLop){
                    this.danh_sach_lop_hoc.push(arr[i]);
                  }
                }
                // this.danh_sach_lop_hoc = response.data.data.list;
                if (this.danh_sach_lop_hoc.length == 0) {
                  this.thongBao('error', 'Không tìm thấy danh sách lớp')
                }
              } else {
                this.thongBao('error', 'Không tìm thấy danh sách lớp')
              }
              this.loadingScreen = false;
            }
        ).catch((e) => {
          this.loadingScreen = false;
        })

      } catch (e) {
        this.thongBao('error', 'Vui lòng thao tác lại.')
      }
    },
  }
}
</script>

<style scoped>

</style>