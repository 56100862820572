<template>
    <div id="app">
        <component :is="layout">
            <transition name="fade" mode="out-in">
                <router-view :key="$route.fullPath"/>
            </transition>
        </component>

        <el-dialog title="Thông báo" width="30%" center :visible.sync="show_redirect">
            <div class="demo-image__lazy">
                <p>{{ textMes }}</p>
                <el-row :gutter="24">
                    <el-row :span="24" class="text-center pt-3">
                        <el-button @click.prevent="RedirectPageInfo()" type="success">Cập nhật</el-button>
                    </el-row>
                </el-row>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    mapState,
    mapMutations,
    mapActions
} from 'vuex';
import websocket from "../websocket";
import util from "../util";
import {router} from "../_router";

// import {
//   getMessaging,
//   getToken,
//   onMessage
// } from "firebase/messaging";

export default {
    name: "App",
    data() {
        return {
            show_redirect: false,
            connection: null,
            message: [],
            textMes: '',
            url: '',
            list_menu: [],
        }
    },
    computed: {
        ...mapState('account', [
            'user'
        ]),
        layout() {
            // none-layout will be used if the meta.layout tag is not set
            // computed may not be best place in vue lifecycle for this but it works ok
            return `${this.$route.meta.layout || 'none'}-layout`;
        }
    },
    watch: {
        user(val) {
            if (val) {
                // console.log("mounted app.vue")
                let wsk = BASE_URL_WEBSOCKET;
                let token = util.getCookie('token');
                if (this.user) {
                    if (token) {
                        // console.log("Kết nối với websocket")
                        this.connection = websocket.connectWebSocket(wsk);
                    }
                }
            } else {
                // console.log("Ngắt kết nối với websocket")
                websocket.close();
                this.connection = null;
            }
        }
    },
    mounted() {
        console.log("mounted app.vue")
        console.error(this.$route.fullPath)
        window.addEventListener('message', this.onReceiveMessage, false);
        if (this.user) {
            let token = util.getCookie('token');
            if (token) {
                console.log("Kết nối với websocket")
                let wsk = BASE_URL_WEBSOCKET;
                this.connection = websocket.connectWebSocket(wsk);
            }
        }
    },
    methods: {
        ...mapActions('account', [
            'logout',
        ]),
        RedirectPageInfo() {
            this.show_redirect = false;
            router.push({
                name: 'ThongTinTaiKhoan'
            });
        },
        showRedirect() {
            this.show_redirect = true;
        },
        onReceiveMessage(e) {
            // console.log("Response onReceiveMessage:")
            // console.log(e.data)
            let data = e.data;
            if (data.code == 314) {
                this.textMes = data.msg
                this.showRedirect();
            }
            let other = data.other;//Thông tin check type socket
            let danh_sach_other_ky = [-2, -1, 3, 7, 8,10,11, 12,13, 14, 15, 20, 21,22,23,24,25,26,27,28,29, 30,31,32,33,34,35,36,50,51,15];
            //other: -1: kết nối; -2: kết nối lại; 0,1: ký OTP; 2: Ký sim CA; 3: getHash của 1; 4: ký 1 bản ghi của USBToken; 5: getHashUSB Vinaphone; 6: Ký USB Vina;7: getHash ký bìa;
            //8: Ký bìa; 9: ký học bạ sim CA; 10: getHash nhiều bìa vinaphone; 11: ký nhiều bìa vinaphone; 12: getHash nhiều bìa; 13: ký danh sách bìa; 14,15: lấy hash & ký danh sách học bạ
            // 16: ký danh sách học bạ sim CA; 17:
            let otherInDanhSachKy = danh_sach_other_ky.indexOf(other);
            // if (other && otherInDanhSachKy == -1) {//chỉ thông báo các thông báo không nằm trong danh sách ký
            //     console.log("thông báo line 95 App.vue");
            //     if (data.code == 200) {
            //         this.thongBao('success', data.msg)
            //     } else {
            //         this.thongBao('error', data.msg)
            //     }
            // }

            if (data.other === -2&&false) {
                if (data.code == 301) {
                    websocket.close();
                    this.$alert('Phiên làm việc đã kết thúc. Để tiếp tục sử dụng, vui lòng đăng nhập lại!', 'Thông báo', {
                        confirmButtonText: 'Xác nhận',
                        type: 'warning',
                        center: true,
                        callback: action => {
                            this.logout()
                        }
                    });
                } else if (data.code == 304) {
                    websocket.close();
                    this.$alert('Tài khoản của bạn đã được đăng nhập trên thiết bị khác. Để tiếp tục sử dụng, vui lòng đăng nhập lại!', 'Thông báo', {
                        type: 'warning',
                        center: true,
                        confirmButtonText: 'Xác nhận',
                        callback: action => {
                            this.logout()
                        }
                    });
                } else if (data.code == 302) {
                    websocket.close();
                    this.$alert('Đã xảy ra lỗi, vui lòng đăng nhập lại!', 'Thông báo', {
                        type: 'warning',
                        center: true,
                        confirmButtonText: 'Xác nhận',
                        callback: action => {
                            this.logout()
                        }
                    });
                }
            }
        },
        thongBao(t, e) {
            let msg = "";
            let cl = "";
            if (e) {
                msg = e;
            }
            let type = "success";
            if (t) {
                type = t
            }
            if (type == "success") {
                cl = "dts-noty-success"
            }
            if (type == "warning") {
                cl = "dts-noty-warning"
            }
            if (type == "error") {
                cl = "dts-noty-error"
            }
            if (type == "info") {
                cl = "dts-noty-info"
            }
            console.error('Thông báo: ' + msg )
            this.$message({
                dangerouslyUseHTMLString: true,
                customClass: cl,
                showClose: true,
                message: msg,
                type: t,
                duration: 3000
            });
        },

    }
}
</script>

<style scoped>

</style>