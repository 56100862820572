<template>

  <el-container
      :class="'wrapper '+ (menuOpen ? 'menu-open':'menu-close') + ' ' + (menuMobileOpen ? 'mobile-open':'mobile-close')"
      v-loading.fullscreen.lock="spinning"
      element-loading-text="Đang tải dữ liệu..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="sidebar desktop">
      <el-aside class="navbar__full" style="width: 250px">
        <div class="menu-together" style="position: fixed;top: 60px;left: 225px;">
          <el-link :underline="false" @click.prevent="menuTogether(!menuOpen)">
                    <span class="circle-1">
                        <span class="circle-2 ">
                          <i v-if="menuOpen" class="el-icon-d-arrow-left"></i>
                          <i v-else class="el-icon-d-arrow-right"></i>
                        </span>
                    </span>
          </el-link>
        </div>
        <user-info @loadPage="loadLaiTrang"></user-info>
        <desktop :open="true" @loadPage="loadLaiTrang"></desktop>

      </el-aside>
      <el-aside class="navbar__icon" style="width: 78px">
        <div class="menu-together" style="position: fixed;top: 60px;left: 53px;">
          <el-link :underline="false" @click.prevent="menuTogether(!menuOpen)">
                    <span class="circle-1">
                        <span class="circle-2 ">
                          <i v-if="menuOpen" class="el-icon-d-arrow-left"></i>
                          <i v-else class="el-icon-d-arrow-right"></i>
                        </span>
                    </span>
          </el-link>
        </div>
        <user-info @loadPage="loadLaiTrang"></user-info>
        <desktop :open="false" @loadPage="loadLaiTrang"></desktop>
      </el-aside>
    </div>
    <div class="sidebar mobile">
      <div class="overlay" @click.self="openMobile(false)">
        <el-link :underline="false" @click.prevent="openMobile(false)" class="mobile--close">
          <i class="el-icon-circle-close"></i>
        </el-link>
      </div>
      <el-aside class="navbar__full" style="width: 250px">
        <user-info @loadPage="loadLaiTrangMobile"></user-info>
        <desktop :open="true" @loadPage="loadLaiTrangMobile"></desktop>
      </el-aside>
    </div>

    <el-container class="wrapper-content">
      <el-header class="header">
        <Header/>
      </el-header>
      <el-row :gutter="24" v-if="false">
        <el-col :span="24">
          <marquee style="padding-top: 15px" behavior="" direction="">
            <b style="color: blue">Thông báo:</b> <span style="color: red">Nội dung file kí XML khối 1.2.3.4 có cập nhật lại trường dữ liệu đảm bảo tra cứu dữ liệu sau này
            tiện lợi hơn, Quý thầy cô vui lòng kí lại học bạ đã được ký trước thời điểm 20h00 ngày 24/05/2024. Tính năng
            ký sẽ được mở lại vào lúc 20h00 ngày 24/05/2024. Trân trọng cảm ơn!</span>
          </marquee>
        </el-col>
      </el-row>
      <transition
          name="fade"
          mode="out-in"
      >
        <slot v-if="showContent"/>
      </transition>
      <el-footer style="background: whitesmoke">
        <el-col :gutter="20">
          <el-col :span="24" class="text-center">
            <span class="copy-right">
            © Copyright 2022. Hệ thống Học bạ số. Phát triển bởi Viettel
            </span>
            <div>
              <table class="login-footer" style="margin:0 auto">
                <tbody>
                <tr>
                  <td>
                    <div class="support-text">
                      <div class="purple">
                        <strong><span class="fw-400">TƯ VẤN VÀ HỖ TRỢ</span>
                          <strong class="orange f-16">18008000</strong>
                        </strong>
                        <i class="grey">Nhánh 2 (miễn phí)</i>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </el-col>
        </el-col>
      </el-footer>
      <div>
        <el-dialog title="Đổi mật khẩu" width="50%" :visible.sync="show_update_pass"
                   class="updateInfoTruong"
                   :show-close="false"
                   :close-on-press-escape="false" :close-on-click-modal="false">
          <el-row :gutter="24">
            <el-col :span="24" style="margin-bottom: 30px">
              <p><b style="color: blue">Thông báo:</b> <span style="color: red">Bạn đang sử dụng mật khẩu mặc định. Vui lòng thay đổi mật khẩu để đảm bảo an toàn thông tin.</span>
              </p>
            </el-col>
            <el-col :span="12">
              <label>Mật khẩu mới</label>
              <el-input clearable v-model="matKhau.moi" show-password placeholder="Nhập"></el-input>
            </el-col>
            <el-col :span="12">
              <label>Xác nhận mật khẩu mới</label>
              <el-input clearable v-model="matKhau.nhapLai" show-password placeholder="Nhập "></el-input>
            </el-col>
          </el-row>
          <span slot="footer" class="dialog-footer">
<!--                <el-button size="mini" @click="show_update_pass = false">Hủy</el-button>-->
                <el-button size="mini" type="warning" @click.prevent="xacNhanThayDoiMatKhau()">Lưu</el-button>
            </span>
        </el-dialog>
      </div>
    </el-container>
  </el-container>
</template>

<script>

import Header from "../components/Header";
import NavbarMenu from "../components/NavbarMenu";
import Mobile from "./navbar/Mobile";
import Desktop from "./navbar/Desktop";
import UserInfo from "./navbar/UserInfo";
import {mapActions, mapState, mapMutations} from "vuex";
import firebase from "firebase";
import constant_api from "../_helpers/constant_api";
import api from '../_helpers/api';

export default {
  name: "App",
  components: {
    UserInfo,
    Header,
    NavbarMenu,
    Mobile,
    Desktop
  },
  computed: {
    ...mapState('until', [
      "menuOpen",
      "menuMobileOpen"
    ])
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    ...mapActions('until', [
      "menuTogether",
      "openMobile"
    ]),

    ...mapMutations('until', [
      "setWindow",
    ]),
    handleResize() {
      // console.log('window.innerWidth')
      // console.log(window.innerWidth)
      this.setWindow({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    },
    loadLaiTrang() {
      this.showContent = false
      this.spinning = true;
      setTimeout(() => {
        this.showContent = true;
      }, 500);
      setTimeout(() => {
        this.spinning = false;
      }, 1300);
    },
    loadLaiTrangMobile() {
      this.showContent = false
      this.spinning = true;
      setTimeout(() => {
        this.showContent = true;
      }, 500);
      setTimeout(() => {
        this.spinning = false;
        this.openMobile(false)
      }, 1300);
    },
    onCollapse(collapsed, type) {
      console.log(collapsed, type);
    },
    onBreakpoint(broken) {
      console.log(broken);
    },
    handleClick(e) {
      console.log('click', e);
    },
    titleClick(e) {
      console.log('titleClick', e);
    },
    thayDoiTrangThaiMenu() {
      this.isCollapse = !this.isCollapse;
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },

    thongBao(t, e) {
      let msg = "";
      let cl = "";
      if (e) {
        msg = e;
      }
      let type = "success";
      if (t) {
        type = t
      }
      if (type == "success") {
        cl = "dts-noty-success"
      }
      if (type == "warning") {
        cl = "dts-noty-warning"
      }
      if (type == "error") {
        cl = "dts-noty-error"
      }
      if (type == "info") {
        cl = "dts-noty-info"
      }
      this.$message({
        customClass: cl,
        showClose: true,
        message: msg,
        type: t,
        duration: 3000
      });
    },
    ...mapActions('account', [
      "logout",
    ]),
    checkLogOut() {
      console.log('checkLogOut')
      let domain = window.location.hostname;
      console.error('domain:' + domain)
      let userSso = localStorage.getItem('userSso')
      if (!userSso || userSso == 'false' || userSso == false) {
        console.error('Đăng xuất tk thường:')
        localStorage.clear();
        this.logout();
      } else {
        console.error('Đăng xuất sso')
        let id_token = localStorage.getItem('id_token')
        let state = this.ranDomString(5)
        let post_logout_redirect_uri = domain + '/login'
        let config = {
          id_token_hint: id_token,
          state: state,
          post_logout_redirect_uri: post_logout_redirect_uri,
        }
        let base_url = 'https://id.nentanggiaoduc.edu.vn/connect/endsession?'
        let url_logout = base_url + this.objectToQueryString(config);
        console.error('url_logout')
        console.error(url_logout)
        window.location.href = url_logout
      }
    },
    xacNhanThayDoiMatKhau() {
      console.log('xacNhanThayDoiMatKhau')
      if (!this.matKhau.moi || this.matKhau.moi == '') {
        this.thongBao('error', 'Vui lòng nhập mật khẩu mới');
        return
      }
      if (!this.matKhau.nhapLai || this.matKhau.nhapLai == '') {
        this.thongBao('error', 'Xác nhận mật khẩu mới');
        return
      }
      if (this.matKhau.moi != this.matKhau.nhapLai) {
        this.thongBao('error', 'Mật khẩu nhập lại không khớp');
        return;
      }
      let url = constant_api.taiKhoan.doiMatKhau;
      let params = {
        email: this.thongtin.username,
        password: this.matKhau.moi
      }

      this.$confirm('Xác nhận thay đổi mật khẩu?', 'Thông báo', {
        confirmButtonText: 'Đổi mật khẩu',
        cancelButtonText: 'Hủy',
        confirmButtonClass: 'el-button--danger'
      })
          .then(_ => {
            api.post(url, params).then(
                response => {
                  console.log("Res:")
                  console.log(response)
                  if (response.data.code == 200) {
                    this.thongBao('success', 'Thay đổi mật khẩu thành công. Vui lòng đăng nhập lại.')
                    this.show_update_pass = false;
                    setTimeout(() => {
                      this.checkLogOut();
                    }, 3000)
                  } else {
                    this.thongBao("error", response.data.msg)
                  }
                }
            )
          })
          .catch(_ => {
          });
    },
    layThongTinDonVi(){

      this.spinning = true;
      api.post('/hocbadientu-service/api/so-giao-duc/lay-thong-tin-don-vi', {}).then(response => {
        console.error('api demo')
        console.error(response.data)
        if (response.data.code != 503) {
          if (this.thongtin.doiMatKhau) {
            this.show_update_pass = true;
          }
          setTimeout(() => {
            this.showContent = true;
          }, 500);
          setTimeout(() => {
            this.spinning = false;
          }, 1500);

          firebase.initializeApp({
            apiKey: "AIzaSyDlxWrc7UYp3qHrXkXeYubjfoGVSESyyUE",
            authDomain: "hocbadientu-d87a8.firebaseapp.com",
            projectId: "hocbadientu-d87a8",
            storageBucket: "hocbadientu-d87a8.appspot.com",
            messagingSenderId: "921619850528",
            appId: "1:921619850528:web:711a3e9818234fd6596345",
            measurementId: "G-WXZ39861LE"
          });

          // // Initialize Firebase
          // const firebase = initializeApp(firebaseConfig);
          // console.log('firebase')
          // console.log(firebase)
          const messaging = firebase.messaging();
          // console.log('messaging')
          // console.log(messaging)

          Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
              // console.log('Notification permission granted.');
              messaging.getToken({vapidKey: 'BPc-VY9dbUmlzYmybmEd0R_oNILfPwh1uZ3_6uBJg3gjEi1FkvFNk4J5m4aj25vltpj_UdLYqAfvvzmDwGCfrFs'}).then((currentToken) => {
                if (currentToken) {
                  let url = constant_api.firebase.sendToken;
                  let params = {
                    token: currentToken
                  }
                  api.post(url, params).then(data => {
                    // console.log(data)
                  }).catch(e => {

                  })
                } else {
                  console.log('No registration token available. Request permission to generate one.');
                }
              }).catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
              });

              messaging.onMessage(function (payload) {
                console.log("payload");
                console.log(payload);
                const notificationOption = {
                  body: payload.notification.body,
                  icon: payload.notification.icon
                };

                if (Notification.permission === "granted") {
                  var notification = new Notification(payload.notification.title, notificationOption);

                  notification.onclick = function (ev) {
                    ev.preventDefault();
                    window.open(payload.notification.click_action, '_blank');
                    notification.close();
                  }
                }

              });

            } else {
              console.log('Unable to get permission to notify.');
            }
          });
        } else {
          console.error('Bảo trì')
          this.$router.push({
            name: 'pageMaintain'
          });
        }
      }).catch(e => {
      })
    },
  },
  data() {
    return {
      show_update_pass: false,
      matKhau: {
        moi: '',
        nhapLai: ''
      },
      isCollapse: false,
      showContent: false,
      collapsed: false,
      showLogo: false,
      thongtin: {},
      current: ['mail'],
      openKeys: ['sub1'],
      spinning: false,
      delayTime: 500,
    }
  },
  watch: {
    collapsed(val) {
      console.log("Thay đổi:" + val)
      if (val) {
        this.showLogo = true;
      } else {
        setTimeout(() => {
          this.showLogo = false;
        }, 300);
      }
    }
  },
  mounted() {
    console.log('mount app vue')
    this.thongtin = JSON.parse(localStorage.getItem("user"));
    this.layThongTinDonVi();


  }
}
</script>

<style scoped>


.el-aside {
  color: #333;
}

i.el-icon-arrow-right {
  display: none;
}

#components-layout-demo-responsive .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
  color: white
}

.ant-layout-sider-trigger {
  background: #bcbfc3 !important
}


.icon-support {
  width: 40px;
  height: 50px;
  background-size: cover;
  background-image: url('https://smas.edu.vn/Content/images/icon-support.png');
}

.icon {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 5px;
  vertical-align: middle;
}
::v-deep .el-footer {
   height: 90px !important;
   padding: 15px 20px;
}
.support-text {
  display: inline-block;
  vertical-align: middle;
  padding-left: 15px;
}
.copy-right {
  color: #404F8D;
 
}

.purple {
  color: #4FADE1;
}
.fw-400 {
  font-weight: 400;
}
.orange {
  color: #5087E1;
}

.viettel-group {
  color: white;
  /* font-weight:bold */
  font-size: 14px
}
</style>