var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "page page-file file detailHocBa",
      attrs: { id: "detailHocBa" },
    },
    [
      _c(
        "div",
        { staticClass: "box-upload-file" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "searchForm",
              attrs: {
                rules: _vm.rulesSearch,
                model: _vm.searchForm,
                "hide-required-asterisk": true,
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "d-inline-block",
                          attrs: { prop: "year", label: "Năm học" },
                        },
                        [
                          _c("eselect", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              collapseTags: "",
                              placeholder: "Chọn",
                              filterable: "",
                              data: _vm.list_nam_hoc,
                              fields: ["name", "value"],
                            },
                            model: {
                              value: _vm.searchForm.year,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "year", $$v)
                              },
                              expression: "searchForm.year",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "d-inline-block",
                          attrs: { prop: "education", label: "Cấp học" },
                        },
                        [
                          _c("eselect", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              disabled: "",
                              collapseTags: "",
                              placeholder: "Chọn",
                              filterable: "",
                              data: _vm.optionEducation,
                              fields: ["label", "value"],
                            },
                            on: { change: _vm.capHocChange },
                            model: {
                              value: _vm.searchForm.education,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "education", $$v)
                              },
                              expression: "searchForm.education",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "d-inline-block",
                          attrs: { prop: "grade", label: "Khối học" },
                        },
                        [
                          _c("eselect", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              collapseTags: "",
                              placeholder: "Chọn",
                              filterable: "",
                              data: _vm.optionGrade,
                              fields: ["label", "value"],
                            },
                            model: {
                              value: _vm.searchForm.grade,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "grade", $$v)
                              },
                              expression: "searchForm.grade",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xs: 12, sm: 8, md: 6, lg: 4, xl: 3 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "d-inline-block",
                          attrs: { prop: "type", label: "Loại phân công" },
                        },
                        [
                          _c("eselect", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              collapseTags: "",
                              placeholder: "Loại phân công",
                              filterable: "",
                              data: [
                                { type: 2, label: "GVCN" },
                                { type: 1, label: "GVBM" },
                              ],
                              fields: ["label", "type"],
                            },
                            model: {
                              value: _vm.searchForm.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "type", $$v)
                              },
                              expression: "searchForm.type",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      staticClass: "text-left",
                      attrs: { xs: 24, sm: 8, md: 6, lg: 4, xl: 3 },
                    },
                    [
                      _c("div", { staticStyle: { color: "transparent" } }, [
                        _vm._v("Tìm kiếm"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "m-0",
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submitSearch("searchForm")
                            },
                          },
                        },
                        [_vm._v("Tìm kiếm\n                    ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "page__header" }, [
        _vm._m(1),
        _vm._v(" "),
        _c("div", { staticClass: "page__content table-custom" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "listNew mb-5",
              attrs: {
                "element-loading-text": "Loading...",
                "element-loading-spinner": "el-icon-loading",
                "element-loading-background": "rgba(0, 0, 0, 0.8)",
              },
            },
            [
              _vm.typeShow === 2
                ? _c("div", { staticClass: "table-responsive" }, [
                    _c("table", { staticClass: "table table-bordered" }, [
                      _vm._m(2),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        [
                          _vm.lstKyThay.length
                            ? [
                                _vm._l(_vm.lstKyThay, function (o, i) {
                                  return [
                                    _c("tr", { staticClass: "text-center" }, [
                                      _c("td", { attrs: { scope: "row" } }, [
                                        _vm._v(_vm._s(_vm.indexMethod(i))),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          " " +
                                            _vm._s(o.namHoc) +
                                            " - " +
                                            _vm._s(o.namHoc + 1)
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [_vm._v(_vm._s(o.tenLop))]),
                                      _vm._v(" "),
                                      _c("td", [_vm._v(_vm._s(o.tenChuNhiem))]),
                                      _vm._v(" "),
                                      _c("td", [_vm._v(_vm._s(o.tenGvcn))]),
                                      _vm._v(" "),
                                      _c("td", [_vm._v(_vm._s(o.lyDoKyThay))]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _c(
                                          "div",
                                          { staticClass: "btn__table_action" },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  size: "mini",
                                                  type: "primary",
                                                  icon: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.showPhanCong(o)
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                Phân công ký\n                                            "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  size: "mini",
                                                  type: "danger",
                                                  icon: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removePhanCong(
                                                      o.maLop
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                Huỷ phân công ký\n                                            "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]),
                                  ]
                                }),
                              ]
                            : [_vm._m(3)],
                        ],
                        2
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.typeShow === 1
                ? _c("div", { staticClass: "table-responsive" }, [
                    _c("table", { staticClass: "table table-bordered" }, [
                      _vm._m(4),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        [
                          _vm.lstKyThay.length
                            ? [
                                _vm._l(_vm.lstKyThay, function (o, i) {
                                  return _vm._l(
                                    o.lstGiangDay,
                                    function (item, index) {
                                      return _c(
                                        "tr",
                                        { staticClass: "text-center" },
                                        [
                                          index === 0
                                            ? _c(
                                                "td",
                                                {
                                                  attrs: {
                                                    scope: "row",
                                                    rowspan:
                                                      o.lstGiangDay.length,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm.indexMethod(i)
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          index === 0
                                            ? _c(
                                                "td",
                                                {
                                                  attrs: {
                                                    rowspan:
                                                      o.lstGiangDay.length,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(o.namHoc) +
                                                      " -\n                                        " +
                                                      _vm._s(o.namHoc + 1) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          index === 0
                                            ? _c(
                                                "td",
                                                {
                                                  attrs: {
                                                    rowspan:
                                                      o.lstGiangDay.length,
                                                  },
                                                },
                                                [_vm._v(_vm._s(o.tenLop))]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          index === 0
                                            ? _c(
                                                "td",
                                                {
                                                  attrs: {
                                                    rowspan:
                                                      o.lstGiangDay.length,
                                                  },
                                                },
                                                [_vm._v(_vm._s(o.tenChuNhiem))]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(item.tenMonHoc) +
                                                "\n                                    "
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(item.tenGV) +
                                                "\n                                    "
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(item.tenGiaoVien) +
                                                "\n                                    "
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(item.lyDoKyThay) +
                                                "\n                                    "
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _c(
                                              "div",
                                              { staticClass: "text-center" },
                                              [
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass: "item",
                                                    attrs: {
                                                      effect: "dark",
                                                      content: "Xóa ký thay",
                                                      placement: "right",
                                                    },
                                                  },
                                                  [
                                                    _c("el-button", {
                                                      attrs: {
                                                        type: "danger",
                                                        icon: "el-icon-delete",
                                                        size: "mini",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.removePhanCong(
                                                            o.maLop,
                                                            item.maGiaoVien,
                                                            item.maMonHoc
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          index === 0
                                            ? _c(
                                                "td",
                                                {
                                                  attrs: {
                                                    rowspan:
                                                      o.lstGiangDay.length,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "btn__table_action",
                                                    },
                                                    [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            size: "mini",
                                                            type: "primary",
                                                            icon: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.showPhanCong(
                                                                o
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                Phân công\n                                            "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            size: "mini",
                                                            type: "danger",
                                                            icon: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.removePhanCong(
                                                                o.maLop
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                Xóa\n                                            "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    }
                                  )
                                }),
                              ]
                            : [_vm._m(5)],
                        ],
                        2
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "text-right mt-2" },
            [
              _vm.lstKyThay.length
                ? _c("pagination", {
                    attrs: {
                      "page-count": _vm.getTotalPage(_vm.total),
                      "initial-page": _vm.paginate.page,
                      "click-handler": _vm.getPhanCongKy,
                      "prev-text": "Trước",
                      "next-text": "Sau",
                      "container-class": "el-pager",
                      pageClass: "number",
                    },
                    model: {
                      value: _vm.paginate.page,
                      callback: function ($$v) {
                        _vm.$set(_vm.paginate, "page", $$v)
                      },
                      expression: "paginate.page",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "custom-class": "updateInfoTruong",
            center: "",
            title: _vm.titleKyThay,
            visible: _vm.showPopUpPhanCong,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showPopUpPhanCong = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.resetForm("ruleForm")
                      _vm.showPopUpPhanCong = false
                    },
                  },
                },
                [_vm._v("Đóng")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("ruleForm")
                    },
                  },
                },
                [_vm._v("Phân công ký")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.phanCongForm,
                rules:
                  _vm.typeShow === 2
                    ? _vm.rulesPhanCongGVCN
                    : _vm.rulesPhanCongGVBM,
                "label-width": "150px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Chọn giáo viên", prop: "giaoVien" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: true },
                      on: { change: _vm.onChangeGiaoVien },
                      model: {
                        value: _vm.phanCongForm.giaoVien,
                        callback: function ($$v) {
                          _vm.$set(_vm.phanCongForm, "giaoVien", $$v)
                        },
                        expression: "phanCongForm.giaoVien",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "--Chọn giáo viên--", value: "" },
                      }),
                      _vm._v(" "),
                      _vm._l(_vm.lstGiaoVien, function (gv, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { value: gv.maGV, label: gv.tenGV },
                        })
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.typeShow === 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "Môn học ký thay", prop: "lstMonHoc" } },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.phanCongForm.lstMonHoc,
                            callback: function ($$v) {
                              _vm.$set(_vm.phanCongForm, "lstMonHoc", $$v)
                            },
                            expression: "phanCongForm.lstMonHoc",
                          },
                        },
                        _vm._l(_vm.lstMonHoc, function (item, index) {
                          return _c(
                            "el-checkbox",
                            { key: index, attrs: { label: item.maMonHoc } },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(item.tenMonHoc) +
                                  "\n                    "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "Lý do", prop: "lyDo" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 4, maxRows: 8 },
                      placeholder: "Nhập lý do",
                    },
                    model: {
                      value: _vm.phanCongForm.lyDo,
                      callback: function ($$v) {
                        _vm.$set(_vm.phanCongForm, "lyDo", $$v)
                      },
                      expression: "phanCongForm.lyDo",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex flex-wrap" }, [
      _c("div", { staticClass: "d-inline-block pl-3 pr-3 pb-3" }, [
        _c("h5", { staticClass: "font-weight-bold m-0" }, [
          _vm._v("Phân công ký thay"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "file--title" }, [
      _c("h5", { staticClass: "title" }, [_vm._v("Danh sách ký thay")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c(
          "th",
          { staticClass: "text-center", attrs: { scope: "col", rowspan: "2" } },
          [_vm._v("STT")]
        ),
        _vm._v(" "),
        _c(
          "th",
          { staticClass: "text-center", attrs: { scope: "col", rowspan: "2" } },
          [_vm._v("Năm học")]
        ),
        _vm._v(" "),
        _c(
          "th",
          { staticClass: "text-center", attrs: { scope: "col", rowspan: "2" } },
          [_vm._v("Lớp")]
        ),
        _vm._v(" "),
        _c(
          "th",
          { staticClass: "text-center", attrs: { scope: "col", rowspan: "2" } },
          [_vm._v("GVCN")]
        ),
        _vm._v(" "),
        _c(
          "th",
          { staticClass: "text-center", attrs: { scope: "col", rowspan: "2" } },
          [_vm._v("Giáo viên ký thay")]
        ),
        _vm._v(" "),
        _c(
          "th",
          { staticClass: "text-center", attrs: { scope: "col", rowspan: "2" } },
          [_vm._v("Lý do")]
        ),
        _vm._v(" "),
        _c(
          "th",
          { staticClass: "text-center", attrs: { scope: "col", rowspan: "2" } },
          [_vm._v("Hành động")]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", { attrs: { colspan: "20" } }, [
        _c("p", { staticClass: "text-center" }, [_vm._v("Chưa có dữ liệu")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c(
          "th",
          { staticClass: "text-center", attrs: { scope: "col", rowspan: "2" } },
          [_vm._v("STT")]
        ),
        _vm._v(" "),
        _c(
          "th",
          { staticClass: "text-center", attrs: { scope: "col", rowspan: "2" } },
          [_vm._v("Năm học")]
        ),
        _vm._v(" "),
        _c(
          "th",
          { staticClass: "text-center", attrs: { scope: "col", rowspan: "2" } },
          [_vm._v("Lớp")]
        ),
        _vm._v(" "),
        _c(
          "th",
          { staticClass: "text-center", attrs: { scope: "col", rowspan: "2" } },
          [_vm._v("GVCN")]
        ),
        _vm._v(" "),
        _c(
          "th",
          { staticClass: "text-center", attrs: { scope: "col", colspan: "5" } },
          [_vm._v("Ký thay giáo viên bộ môn")]
        ),
        _vm._v(" "),
        _c(
          "th",
          { staticClass: "text-center", attrs: { scope: "col", rowspan: "2" } },
          [_vm._v("Hành động")]
        ),
      ]),
      _vm._v(" "),
      _c("tr", [
        _c("th", { staticClass: "text-center", attrs: { scope: "col" } }, [
          _vm._v("Môn học"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-center", attrs: { scope: "col" } }, [
          _vm._v("Giáo viên giảng dạy"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-center", attrs: { scope: "col" } }, [
          _vm._v("Giáo viên ký thay"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-center", attrs: { scope: "col" } }, [
          _vm._v("Lý do"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-center", attrs: { scope: "col" } }, [
          _vm._v("Hành động"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", { attrs: { colspan: "20" } }, [
        _c("p", { staticClass: "text-center" }, [_vm._v("Chưa có dữ liệu")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }