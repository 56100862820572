<template>
  <div class="page">
    <div class="page__title d-flex align-items-center justify-content-between">
      <h1 class="title">Nhập giáo viên </h1>
      <el-tooltip class="item" effect="dark" content="Hướng dẫn nhập dữ liệu" placement="top">
        <a href="/files/huong_dan_nhap_du_lieu.docx"> Hướng dẫn nhập dữ liệu</a>
      </el-tooltip>
    </div>

    <div class="page__header">
      <el-row :gutter="20">
        <el-col :xs="12" :sm="6" :md="4" :lg="4">
          <label>Năm học</label>
          <eselect style="width:100%" collapseTags v-model="namHoc" placeholder="Chọn" filterable
                   :data="list_nam_hoc" :fields="['name','value']"/>
        </el-col>
        <el-col :xs="12" :sm="6" :md="4" :lg="4">
          <label>Cấp học</label>
          <eselect style="width:100%" collapseTags v-model="capHoc" placeholder="Chọn" filterable
                   :data="list_cap_hoc" :fields="['name','value']"/>
        </el-col>
      </el-row>

    </div>

    <div class="page__header">
      <h5 class="title">Dữ liệu giáo viên</h5>
      <small><i> (Truy cập Học bạ số -> Nhập giáo viên -> Tải file mẫu nhập giáo viên)</i></small>
      <!--            <p v-if="user.qlnt==2"><i style="font-size:12px">(Truy cập moet -> Nhân sự -> Hồ sơ nhân sự -> Nhập danh-->
      <!--                sách nhân sự từ excel-->
      <!--                -> Tải file kèm dữ liệu mẫu-->
      <!--                )</i></p>-->
      <!--            <el-row :gutter="20">-->
      <!--                <el-col :xs="12" :sm="6" :md="4" :lg="4">-->
      <!--                    <label>Phương thức nhập (<i style="color: red;">*</i>)</label>-->
      <!--                    <eselect class="mb-2" style="width:100%" collapseTags placeholder="Chọn" v-model="typeGiaoVien"-->
      <!--                             filterable-->
      <!--                             :data="this.user.qlnt == 1? listPhuongThucNhapVnEdu : listPhuongThucNhapMoet"-->
      <!--                             :fields="['name','value']"/>-->
      <!--                </el-col>-->
      <!--            </el-row>-->
      <ImportGeneral :require-cap-hoc="true" :fileHasData="true"
                     :linkFileHasData="typeGiaoVien === 1? linkFileHasData : linkFileHasDataChung"
                     :fileNameError="'danh_sach_loi_nhap_giao_vien'"
                     :requireKhoiHoc="false"
                     :hideClass="true"
                     :pathSampleFile="typeGiaoVien === 1? pathSampleFile : pathSampleFileChung"
                     :requireNamHoc="true" :year="namHoc" :cap-hoc="capHoc"
                     :template-download="template" :source="source"
                     :requireImportType="true"
                     :data-type="3"
                     :text-download="'Tải file nhập giáo viên'"
                     :path="typeGiaoVien === 1? path :pathChung"
                     :disabledDownload="typeGiaoVien!=2&&false"
                     :importType="typeGiaoVien"></ImportGeneral>
    </div>

  </div>

</template>

<script>
// import ImportGeneral from "./ImportGeneral";
import ImportGeneral from "./ImportChung.vue";
import constant_api from "../../_helpers/constant_api";
import ESelect from "../Ui/ESelect";
import {
  mapState
} from "vuex";

export default {
  name: "ImportCanBo",
  metaInfo: {
    title: 'Nhập giáo viên',
    meta: [{
      vmid: 'description',
      name: 'description',
      content: ''
    }],
  },
  components: {
    ImportGeneral,
    'eselect': ESelect,
  },
  data() {
    return {
      template: [{
        label: 'VNEDU',
        type: 1,
        url: ''
      },

      ],
      source: [{
        type: 1,
        label: 'VNEDU',
        rowHeader: 1
      }, {
        type: 2,
        label: 'MOET',
        rowHeader: 1
      },],
      path: constant_api.import.giaoVien,
      pathChung: constant_api.import.giaoVienChung,
      linkFileHasData: constant_api.download.linkFileHasData.giaoVienChung,
      linkFileHasDataChung: constant_api.download.linkFileHasData.giaoVienChung,
      namHoc: '',
      capHoc: '',
      pathSampleFile: '',
      pathSampleFileChung: '',
      typeGiaoVien: '',
      listPhuongThucNhapVnEdu: [
        {
          'name': 'Mẫu VNEDU',
          'value': 1
        },
        {
          'name': 'Mẫu Học bạ số',
          'value': 2
        }
      ],
      listPhuongThucNhapMoet: [
        {
          'name': 'Mẫu MOET',
          'value': 1
        },
        {
          'name': 'Mẫu Học bạ số',
          'value': 2
        }
      ]
    }
  },
  methods: {
    getFullYear() {
      let d = new Date();
      return d.getFullYear();
    },
    getListCapHoc() {
      if (this.user && this.user.capHoc) {
        let ch = this.user.capHoc.toString();
        let cap_hoc = ch.split('');
        this.capHoc = parseInt(cap_hoc[0]);
      }
    }
  },
  mounted() {
    this.getListCapHoc();
    this.namHoc = this.nam_hoc_hien_tai;
    if (this.user) {
      if (this.user.qlnt == 1) {
        this.pathSampleFile = '/files/vnedu/giao_vien_vnedu.xls';
        this.pathSampleFileChung = '/files/common/giao_vien.xlsx';
      }
      if (this.user.qlnt == 2) {
        this.pathSampleFile = '/files/moet/giao_vien_moet.xls';
        this.pathSampleFileChung = '/files/common/giao_vien.xlsx';
      }

    }
  },
  computed: {
    ...mapState('account',
        [
          "user",
          'list_nam_hoc',
          'nam_hoc_hien_tai',
          'list_cap_hoc',
        ]),

  },
}
</script>

<style scoped>
.title {
  margin-bottom: 0;
}
</style>