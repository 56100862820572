<template>
  <div class="page" v-loading.fullscreen.lock="fullscreenLoading" element-loading-text="Hệ thống đang xử lý yêu cầu">
    <div class="page__title d-flex align-items-center justify-content-between">
      <h1 class="title">Chốt học bạ</h1>
    </div>
    <div class="page__header">
      <el-form :rules="rulesSearch" ref="searchForm" label-position="top" :model="searchForm"
               :hide-required-asterisk="true">
        <el-row :gutter="20" @keyup.enter.native="submitSearch('searchForm')">
          <el-col :xs="12" :sm="12" :md="6" :lg="4">
            <el-form-item label="Năm học">
              <ESelect style="width:100%" collapseTags v-model="searchForm.year" placeholder="Chọn"
                       filterable :data="list_nam_hoc" :fields="['name','value']"/>
            </el-form-item>
          </el-col>
          <el-col :xs="12" :sm="12" :md="6" :lg="4">
            <el-form-item label="Cấp học">
              <ESelect :disabled="list_cap_hoc.length==1" style="width:100%" collapseTags
                       v-model="searchForm.capHoc" @change="getKhoiHoc"
                       placeholder="Chọn" filterable :data="list_cap_hoc" :fields="['name','value']"/>
            </el-form-item>
          </el-col>
          <el-col :xs="12" :sm="12" :md="6" :lg="4">
            <el-form-item label="Khối học">
              <ESelect style="width:100%" collapseTags v-model="searchForm.khoiHoc" placeholder="Chọn"
                       filterable :data="optionGrade" @change="layDanhSachLop()" :fields="['label','value']"/>
            </el-form-item>
          </el-col>
          <!--          <el-col :xs="12" :sm="12" :md="6" :lg="4">-->
          <!--            <el-form-item label="Lớp học">-->
          <!--              <ESelect style="width:100%" collapseTags v-model="searchForm.lopHoc" placeholder="Chọn"-->
          <!--                       filterable :data="danh_sach_lop_hoc" :fields="['tenLop','maLop']"/>-->
          <!--            </el-form-item>-->
          <!--          </el-col>-->
          <el-col :xs="12" :sm="12" :md="6" :lg="4">
            <div style="color: transparent">Tìm kiếm</div>
            <el-button class="m-0" type="primary" @click="submitSearch('searchForm')">Tìm kiếm
            </el-button>
          </el-col>
        </el-row>
        <div style="display:none">
          <div style="display: inline-block">
            <el-form-item class="d-inline-block" prop="year">
              <ESelect style="width:150px" collapseTags v-model="searchForm.year" placeholder="Chọn"
                       filterable :data="list_nam_hoc" :fields="['name','value']"/>
            </el-form-item>
          </div>
          <div style="display: inline-block">
            <el-form-item class="d-inline-block">
              <ESelect style="width:120px" collapseTags v-model="searchForm.capHoc" @change="getKhoiHoc"
                       placeholder="Chọn" filterable :data="list_cap_hoc"
                       :fields="['name','value']"/>
            </el-form-item>
          </div>
          <div style="display: inline-block">
            <el-form-item class="d-inline-block">
              <ESelect style="width:120px" collapseTags v-model="searchForm.khoiHoc"
                       placeholder="Chọn" filterable :data="optionGrade"
                       :fields="['label','value']"/>
            </el-form-item>
          </div>
          <div class="text-center" style="display: inline-block">
            <el-button type="primary" @click="submitSearch('searchForm')">Tìm kiếm</el-button>
          </div>
        </div>
      </el-form>
    </div>
    <div class="page__header">
      <div class="table-responsive tableFixHead" :style="'max-height: '+maxHeightTable+'px'">
        <table class="table table-bordered table-custom text-center" style="min-width: 850px; overflow: scroll">
          <thead>
          <tr>
            <th>STT</th>
            <th>Khối học</th>
            <th>Lớp</th>
            <th style="min-width:275px">Chốt dữ liệu</th>
            <th style="min-width:275px">Huỷ chốt dữ liệu</th>
            <!--            <th style="min-width:170px">Lịch sử huỷ chốt</th>-->
            <!--            <th style="">File lỗi khi chốt</th>-->
          </tr>
          </thead>
          <tbody>
          <template v-if="!dataTable.length">
            <tr>
              <td colspan="10">
                <div class="text-center">Chưa có dữ liệu</div>
              </td>
            </tr>
          </template>
          <template v-else>
            <template v-for="(item,i) in dataTable">
              <tr v-for="(lop,index) in item.lopHocs">
                <td v-if="index === 0" style="vertical-align: middle"
                    :rowspan="item.lopHocs.length">
                  {{ i + 1 }}
                </td>
                <td v-if="index === 0" style="vertical-align: middle"
                    :rowspan="item.lopHocs.length">
                  {{ item.name }}
                </td>
                <td>
                  {{ lop.tenLop }}
                </td>
                <td>
                  <div>
                    <template>
                      <el-button style="background: #18afa8"
                                 v-if="(item.namHoc-lop.khoiHoc)<2019||lop.khoiHoc>=6||item.namHoc<=2022||user.maSo=='08'"
                                 size="small" type="primary" @click.prevent="chotBia(lop,'qtht')">
                        Chốt QTHT
                      </el-button>
                      <el-button
                          v-if="(item.namHoc-lop.khoiHoc)<2019||lop.khoiHoc>=6||item.namHoc<=2022||user.maSo=='08'"
                          size="small" type="primary" @click.prevent="chotBia(lop,'bia')">Chốt
                        bìa
                      </el-button>
                    </template>
                    <el-button size="small" type="primary"
                               @click.prevent="chotHocBa(lop,'hocba')">
                      {{
                        ((item.namHoc - lop.khoiHoc) < 2019 || lop.khoiHoc >= 6 || item.namHoc <= 2022) ? 'Chốt KQHT' : 'Chốt học bạ'
                      }}
                      <!--                      Chốt Học bạ-->
                    </el-button>
                  </div>
                </td>
                <td>
                  <div>
                    <el-button size="small" type="danger"
                               style="background: #ff8233"
                               v-if="(item.namHoc-lop.khoiHoc)<2019||lop.khoiHoc>=6||item.namHoc<=2022||user.maSo=='08'"
                               @click.prevent="showHuyChotHocBa(lop,'qtht')">Huỷ chốt QTHT
                    </el-button>
                    <el-button size="small" type="warning"
                               v-if="(item.namHoc-lop.khoiHoc)<2019||lop.khoiHoc>=6||item.namHoc<=2022||user.maSo=='08'"
                               @click.prevent="showHuyChotBiaHocBa(lop)">Huỷ chốt bìa
                    </el-button>
                    <el-button size="small" type="danger"
                               @click.prevent="showHuyChotHocBa(lop,'hocba')">
                      {{
                        ((item.namHoc - lop.khoiHoc) < 2019 || lop.khoiHoc >= 6 || item.namHoc <= 2022) ? 'Hủy chốt KQHT' : 'Hủy chốt học bạ'
                      }}
                    </el-button>
                  </div>
                </td>
                <!--                <td>-->
                <!--                  <div>-->
                <!--                    <el-button size="small"-->
                <!--                               @click.prevent="showLichSuHuyChotDuLieu(lop)">Lịch sử huỷ chốt Học bạ-->
                <!--                    </el-button>-->
                <!--                  </div>-->
                <!--                </td>-->
                <!--                <td>-->
                <!--                  <div>-->
                <!--                    <el-button size="small"-->
                <!--                               @click.prevent="taiXuongFileLoiKhiChot(lop)">Tải xuống-->
                <!--                    </el-button>-->
                <!--                  </div>-->
                <!--                </td>-->
              </tr>
            </template>
          </template>
          </tbody>
        </table>
      </div>
    </div>
    <el-dialog align="center" :title="titleClass" top="5" :visible.sync="showHistory" class="hisory-xuly">
      <div>
        <div class="file--title text-left">
          <el-button class="mb-1 mr-auto" @click.prevent="ChotLaiDuLieu(1)" size="small"
                     type="primary"> Chốt lại bìa
          </el-button>
          <el-button class="mb-1 mr-auto" size="small" @click.prevent="ChotLaiDuLieu(2)"
                     type="primary"> Chốt lại học bạ
          </el-button>
        </div>
        <el-table border fit :max-height="maxHeight" :lazy="true" :data="listHistory" width="100%">
          <el-table-column
              style="width: 50px"
              width="50"
              align="center" header-align="center">
            <template slot="header" slot-scope="scope">
              <el-checkbox @change="ChonToanBoHocBaChotLai" v-model="CheckAll"></el-checkbox>
            </template>
            <template slot-scope="scope">
              <el-checkbox @change="chonMoiHocBaChotLai(scope.row)"
                           v-model="scope.row.check"></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column align="center" label="STT" type="index" width="50">
          </el-table-column>
          <el-table-column align="center" label="Mã học sinh" property="maHS" width="150"></el-table-column>
          <el-table-column header-align="center" label="Tên học sinh" property="tenHS" width="200">
          </el-table-column>
          <el-table-column header-align="center" label="Lỗi tạo bìa" :min-width="280">
            <template slot-scope="scope">
              <div style="white-space: pre; max-height: 200px;overflow-x: hidden;overflow-y: auto"
                   v-html="scope.row.moTaLoiBiaHocBa"></div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="Thời gian bìa" :min-width="100">
            <template slot-scope="scope">
              <p v-if="scope.row.thoiGianCapNhatBia">
                {{ new Date(scope.row.thoiGianCapNhatBia) | moment("H:mm:ss DD/MM/YYYY") }}</p>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label="Lỗi tạo học bạ" :min-width="520">
            <template slot-scope="scope">
              <div style="white-space: pre;max-height: 200px;overflow-x: hidden;overflow-y: auto"
                   v-html="scope.row.moTaLoiHocBa"></div>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label="Thời gian học bạ" :min-width="100">
            <template slot-scope="scope">
              <p v-if="scope.row.thoiGianCapNhatHocBa">
                {{ new Date(scope.row.thoiGianCapNhatHocBa) | moment("H:mm:ss DD/MM/YYYY") }}</p>
            </template>
          </el-table-column>
          <el-table-column align="center" :min-width="120" label="Hành động">
            <template slot-scope="scope">
              <template v-if="scope.row.moTaLoiBiaHocBa">
                <el-button
                    v-if="scope.row.khoiHoc == 1 || scope.row.khoiHoc == 6 || scope.row.khoiHoc == 10"
                    size="small" type="primary" @click.prevent.once="chotBiaHS(scope.row)">Chốt bìa
                </el-button>
              </template>
              <template v-if="scope.row.moTaLoiHocBa">
                <el-button size="small" type="primary" @click.prevent.once="choHocBaHS(scope.row)">Chốt
                  học bạ
                </el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <!--    Huỷ chốt-->
    <el-dialog align="center" :title="TextChotHocBa" top="5" width="50%" class="hisory-huychot"
               :close-on-press-escape="false" :close-on-click-modal="false"
               :visible.sync="showHuyChot">
      <div>
        <el-row :gutter="20">
          <el-col :span="6">
            <div class="file--title text-left">
              <el-button v-if="typeHuyChotDuLieu=='hocba'" class="mb-1 mr-auto"
                         :disabled="listHocSinhCheckHuyChot.length==0"
                         @click.prevent="checkHuyChotDuLieu(2)" size="small"
                         type="danger"> Huỷ chốt Học bạ
              </el-button>
              <el-button v-if="typeHuyChotDuLieu=='qtht'" class="mb-1 mr-auto"
                         :disabled="listHocSinhCheckHuyChot.length==0"
                         style="background: #ff8233"
                         @click.prevent="checkHuyChotDuLieu(4)" size="small"
                         type="danger"> Huỷ chốt QTHT
              </el-button>
            </div>
          </el-col>
          <el-col :span="18">
            <div class="text-right pt-2">
              <span><span style="color: blue">Lưu ý:</span> <span style="color: red">Khi thực hiện thao tác huỷ, tất cả dữ liệu đã ký các thầy cô sẽ phải ký lại từ đầu.</span></span>
            </div>
          </el-col>
        </el-row>
        <el-table border fit :max-height="maxHeightHuyChot" :lazy="true" :data="listHuyChot" width="100%">
          <el-table-column
              style="width: 50px"
              width="50"
              align="center" header-align="center">
            <template slot="header" slot-scope="scope">
              <el-checkbox @change="ChonToanBoHocBaHuyChot" v-model="CheckAll"></el-checkbox>
            </template>
            <template slot-scope="scope">
              <el-checkbox @change="chonMoiHocBaHuyChot(scope.row)"
                           v-model="scope.row.check"></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column align="center" label="STT" type="index" width="50">
          </el-table-column>
          <el-table-column align="center" label="Mã học sinh" property="maHS"></el-table-column>
          <el-table-column header-align="center" label="Tên học sinh" property="tenHS">
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <el-dialog align="center" :title="TextChotHocBa" top="5" width="50%" class="hisory-huychot"
               :close-on-press-escape="false" :close-on-click-modal="false"
               :visible.sync="showHuyChotBia">
      <div>
        <el-row :gutter="20">
          <el-col :span="6">
            <div class="file--title text-left">
              <el-button class="mb-1 mr-auto" :disabled="listHocSinhCheckHuyChot.length==0"
                         @click.prevent="checkHuyChotDuLieu(3)" size="small"
                         type="danger"> Huỷ chốt bìa
              </el-button>
            </div>
          </el-col>
          <el-col :span="18" style="padding-top: 10px">
            <div class="file--title text-right">
              <p><b style="color: blue">Lưu ý:</b> <span style="color: red"> Những học sinh đã ký bìa sẽ không được huỷ chốt.</span>
              </p>
            </div>
          </el-col>
        </el-row>
        <el-table border fit :max-height="maxHeightHuyChot" :lazy="true" :data="listHuyChot" width="100%">
          <el-table-column
              style="width: 50px"
              width="50"
              align="center" header-align="center">
            <template slot="header" slot-scope="scope">
              <el-checkbox @change="ChonToanBoHocBaHuyChot" v-model="CheckAll"></el-checkbox>
            </template>
            <template slot-scope="scope">
              <el-checkbox @change="chonMoiHocBaHuyChot(scope.row)"
                           v-model="scope.row.check"></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column align="center" label="STT" type="index" width="50">
          </el-table-column>
          <el-table-column align="center" label="Mã học sinh" property="maHS"></el-table-column>
          <el-table-column header-align="center" label="Tên học sinh" property="tenHS">
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <el-dialog align="center" :title="TextChotHocBa" top="5" width="50%" class="hisory-huychot"
               :close-on-press-escape="false" :close-on-click-modal="false"
               :visible.sync="showLichSuHuyChot">
      <div>
        <el-table border fit :max-height="maxHeightHuyChot" :lazy="true" :data="danh_sach_lich_su_huy_chot"
                  width="100%">
          <el-table-column align="center" label="STT" type="index" width="50">
          </el-table-column>
          <el-table-column align="center" label="Tài khoản huỷ chốt" property="createdBy"></el-table-column>
          <el-table-column align="center" label="Dữ liệu huỷ chốt" property="tenLoaiHuyChot"></el-table-column>
          <el-table-column align="center" label="Số lượng huỷ chốt" property="soLuongHuyChot"></el-table-column>
          <el-table-column align="center" label="Thời gian huỷ chốt" property="ngayHuyChotString"></el-table-column>
          <el-table-column
              width="120"
              align="center" header-align="center">
            <template slot="header" slot-scope="scope">
              Hành động
            </template>
            <template slot-scope="scope">
              <el-button size="small"
                         @click="khoiPhucBanGhi(scope.row)"
                         type="primary">Khôi phục
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <!--        Chốt học bạ-->
    <div v-if="showChotHocBa">
      <el-dialog align="center" :title="TextChotHocBa" top="5vh" width="85%" class="hisory-huychot"
                 :close-on-press-escape="false" :close-on-click-modal="false"
                 :before-close="closeModal()"
                 :visible.sync="showChotHocBa">
        <div>
          <el-row :gutter="20">
            <el-col :span="24">
              <div class="file--title text-left">
                <el-button class="mb-1 mr-auto" v-if="showHocBa&&typeChotDuLieu=='hocba'"
                           :disabled="listHocSinhCheckChot.length==0"
                           style=" margin-right: 20px !important;"
                           @click.prevent="ChotDuLieuHocBa(false)"
                           size="small"
                           type="primary">Chốt Học bạ
                </el-button>
                <el-button class="mb-1 mr-auto" v-if="typeChotDuLieu=='bia'"
                           style=" margin-right: 20px !important;"
                           :disabled="listHocSinhCheckChot.length==0"
                           @click.prevent="ChotDuLieuBiaHocBa()" size="small"
                           type="primary">Chốt bìa
                </el-button>
                <el-button class="mb-1 mr-auto" v-if="typeChotDuLieu=='qtht'&&!showHocBa"
                           style="background: #18afa8; margin-right: 20px !important;"
                           :disabled="listHocSinhCheckChot.length==0"
                           @click.prevent="ChotDuLieuBiaHocBa()" size="small"
                           type="primary">
                  Chốt QTHT
                </el-button>
                <el-switch v-model="xemTruocKhiChot">
                </el-switch>
                Xem học bạ trước khi chốt
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col v-if="showHocBa" :span="24">
              <el-table border fit :max-height="maxHeightHuyChot" :lazy="true" :data="listChotHocBa"
                        width="100%">
                <el-table-column
                    style="width: 50px"
                    width="50"
                    align="center" header-align="center">
                  <template slot="header" slot-scope="scope">
                    <el-checkbox :disabled="khongChoChonTatCa"  @change="ChonToanBoHocBaChot(false)"
                                 v-model="CheckAllChotHocBa"></el-checkbox>
                  </template>
                  <template slot-scope="scope">
                    <el-checkbox @change="chonMoiHocBaChot(scope.row)"
                                 :disabled="scope.row.thanhCong"
                                 v-model="scope.row.check"></el-checkbox>
                  </template>
                </el-table-column>
                <el-table-column align="center" label="STT" type="index" width="50">
                </el-table-column>
                <el-table-column width="130" align="center" label="Mã học sinh"
                                 property="maHS"></el-table-column>
                <el-table-column width="250" header-align="center" label="Tên học sinh"
                                 property="tenHS"></el-table-column>
                <el-table-column width="120" header-align="center" label="Trạng thái"
                                 property="trangThai"></el-table-column>
                <el-table-column v-if="showColGdcd" align="center" label="Hiển thị GDCD trong học bạ">
                  <el-table-column
                      width="120"
                      align="center" header-align="center">
                    <template slot="header" slot-scope="scope">
                      <el-checkbox style="color:white" @change="ChonToanBoAnhHienThiKyIHocBaChot"
                                   v-model="CheckAllHienThi1Ky">1 kỳ
                      </el-checkbox>
                    </template>
                    <template slot-scope="scope">
                      <el-checkbox @change="chonHienThi1Ky(scope.row)"
                                   :disabled="scope.row.thanhCong"
                                   v-model="scope.row.hienThi1K"></el-checkbox>
                    </template>
                  </el-table-column>
                  <el-table-column
                      width="120"
                      align="center" header-align="center">
                    <template slot="header" slot-scope="scope">
                      <el-checkbox style="color:white"
                                   @change="ChonToanBoAnhHienThiKyIIIHocBaChot"
                                   v-model="CheckAllHienThi3Ky">Cả năm
                      </el-checkbox>
                    </template>
                    <template slot-scope="scope">
                      <el-checkbox @change="chonHienThi3Ky(scope.row)"
                                   :disabled="scope.row.thanhCong"
                                   v-model="scope.row.hienThi3K"></el-checkbox>
                    </template>
                  </el-table-column>
                </el-table-column>
                <el-table-column
                    width="120"
                    align="center" header-align="center">
                  <template slot="header" slot-scope="scope">
                    Miễn giảm
                  </template>
                  <template slot-scope="scope">
                    <el-checkbox @change="ChonMienGiam(scope.row)"
                                 :disabled="scope.row.thanhCong"
                                 v-model="scope.row.mienGiam"></el-checkbox>
                  </template>
                </el-table-column>
                <el-table-column
                    label="Nội dung"
                    align="left" header-align="center">
                  <template slot-scope="scope">
                    <!--                            <p v-html="convertLoi(scope.row.moTaLoiHocBa)"></p>-->
                    <p v-html="scope.row.moTaLoiHocBa"></p>
                  </template>
                </el-table-column>
                <el-table-column width="130" align="center" label="Thời gian">
                  <template slot-scope="scope">
                    <div v-if="scope.row.thoiGian">{{ scope.row.thoiGian }}</div>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
            <el-col v-if="showBia" :span="24">
              <el-table border fit :max-height="maxHeightHuyChot" :lazy="true" :data="listChotHocBa"
                        width="100%">
                <el-table-column
                    style="width: 50px"
                    width="50"
                    align="center" header-align="center">
                  <template slot="header" slot-scope="scope">
                    <el-checkbox @change="ChonToanBoHocBaChot(false)"
                                 v-model="CheckAllChotHocBa"></el-checkbox>
                  </template>
                  <template slot-scope="scope">
                    <el-checkbox @change="chonMoiHocBaChot(scope.row)"
                                 :disabled="scope.row.thanhCong"
                                 v-model="scope.row.check"></el-checkbox>
                  </template>
                </el-table-column>
                <el-table-column align="center" label="STT" type="index" width="50">
                </el-table-column>
                <el-table-column align="center" label="Mã học sinh"
                                 property="maHS"></el-table-column>
                <el-table-column header-align="center" label="Tên học sinh"
                                 property="tenHS"></el-table-column>
                <el-table-column header-align="center" label="Trạng thái"
                                 property="trangThai"></el-table-column>
                <el-table-column
                    v-if="showColImg"
                    width="120"
                    align="center" header-align="center">
                  <template slot="header" slot-scope="scope">
                    Miễn giảm ảnh
                    <br>
                    <el-checkbox style="color:white" @change="ChonToanBoAnhHocBaChot"
                                 v-model="CheckAllAnhChotHocBa"></el-checkbox>
                  </template>
                  <template slot-scope="scope">
                    <el-checkbox @change="ChonCoAnh(scope.row)" :disabled="scope.row.thanhCong"
                                 v-model="scope.row.anhHS"></el-checkbox>
                  </template>
                </el-table-column>
                <el-table-column
                    v-if="typeChotDuLieu!='qtht'"
                    label="Nội dung"
                    align="left" header-align="center">
                  <template slot-scope="scope">
                    <p v-html="scope.row.moTaLoiBiaHocBa"></p>
                  </template>
                </el-table-column>
                <el-table-column align="center" label="Thời gian">
                  <template slot-scope="scope">
                    <div v-if="scope.row.thoiGian">{{ scope.row.thoiGian }}</div>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="12" class="text-left">
              <p><i>Danh sách có <b>{{ listChotHocBa.length }}</b> học sinh</i></p>
            </el-col>
          </el-row>
        </div>
        <el-dialog align="center" :title="TextChotHocBa" fullscreen class="hisory-huychot" append-to-body
                   :visible.sync="showViewPdf">
          <div>
            <div class="viewHocBa" style="position: relative; height: 90vh; overflow: scroll; border: 1px solid black">
              <marquee style="position: absolute;top: -25px" behavior="" direction=""><b
                  style="color: red">Lưu ý:</b> <span style="color: blue">Quý thầy cô vui lòng kiểm tra kỹ trước khi thực hiện chốt/huỷ chốt dữ liệu. Trân trọng cảm ơn.</span>
              </marquee>
              <div v-if="mauMoi">
                <viewC1
                    :chuKy="true"
                    :fileName="searchForm.student?searchForm.student:searchForm.maLop?searchForm.maLop:'Học Bạ Học Sinh'"
                    v-if="chiTietHocBa"
                    :khoiHoc="searchForm.khoiHoc" :item="chiTietHocBa"></viewC1>
              </div>
              <div v-else>
                <iframe :src="linkViewPdf" style="width:100%;height: 87vh"></iframe>
              </div>
            </div>
          </div>
          <el-row :gutter="24">
            <el-col :span="24" class="text-center">
              <el-pagination
                  :page-size="1"
                  @current-change="chonTrangXemHocBa"
                  :current-page.sync="thongTinXemFilePdfHocBa.hoc_sinh_hien_tai"
                  layout="prev, pager, next"
                  :total="thongTinXemFilePdfHocBa.danh_sach_hoc_sinh.length">
              </el-pagination>
              <el-button v-if="showHocBa&&showBia==false" size="mini" @click="chotDuLieuHocBaStep2"
                         type="primary">Chốt Học bạ
              </el-button>
              <el-button v-if="showBia&&showHocBa==false&&typeChotDuLieu=='bia'" size="mini"
                         @click="chotDuLieuHocBaStep2Bia"
                         type="primary">Chốt bìa
              </el-button>
              <el-button style="background: #18afa8"
                         v-if="showBia&&showHocBa==false&&typeChotDuLieu=='qtht'" size="mini"
                         @click="chotDuLieuHocBaStep2Bia"
                         type="primary">Chốt QTHT
              </el-button>
              <el-button v-if="show_huy_chot" size="mini" @click="huyChotDuLieuHocBaStep2" type="danger">
                Huỷ chốt
                dữ liệu
              </el-button>
            </el-col>
          </el-row>
        </el-dialog>
      </el-dialog>
    </div>
    <div v-if="show_huy_chot">
      <el-dialog align="center" :title="TextChotHocBa" top="5vh" width="70%" class="hisory-huychot"
                 :close-on-press-escape="false" :close-on-click-modal="false"
                 :before-close="closeModal()"
                 :visible.sync="show_huy_chot">
        <div>
          <el-row :gutter="20">
            <el-col :span="6">
              <div class="file--title text-left">
                <el-button class="mb-1 mr-auto"
                           :disabled="listHocSinhCheckChot.length==0"
                           @click.prevent="ChotDuLieuHocBa(true)"
                           size="small"
                           type="danger">Huỷ chốt dữ liệu
                </el-button>
              </div>
            </el-col>
            <el-col :span="18">
              <div class="file--title text-left mt-3">
                <span><span style="color: blue">Lưu ý:</span> <span style="color: red">Khi thực hiện thao tác huỷ, tất cả dữ liệu đã ký các thầy cô sẽ phải ký lại từ đầu.</span></span>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="24">
              <el-table border fit :max-height="maxHeightHuyChot" :lazy="true" :data="listChotHocBa"
                        width="100%">
                <el-table-column
                    style="width: 50px"
                    width="50"
                    align="center" header-align="center">
                  <template slot="header" slot-scope="scope">
                    <el-checkbox @change="ChonToanBoHocBaChot(true)"
                                 v-model="CheckAllChotHocBa"></el-checkbox>
                  </template>
                  <template slot-scope="scope">
                    <el-checkbox @change="chonMoiHocBaChot(scope.row)"
                                 v-model="scope.row.check"></el-checkbox>
                  </template>
                </el-table-column>
                <el-table-column align="center" label="STT" type="index" width="50">
                </el-table-column>
                <el-table-column width="130" align="center" label="Mã học sinh"
                                 property="maHS"></el-table-column>
                <el-table-column width="250" header-align="center" label="Tên học sinh"
                                 property="tenHS"></el-table-column>
                <el-table-column width="120" header-align="center" label="Trạng thái"
                                 property="trangThai"></el-table-column>
                <el-table-column
                    width="120"
                    align="center" header-align="center">
                  <template slot="header" slot-scope="scope">
                    Miễn giảm
                  </template>
                  <template slot-scope="scope">
                    <el-checkbox @change="ChonMienGiam(scope.row)"
                                 v-model="scope.row.mienGiam"></el-checkbox>
                  </template>
                </el-table-column>
                <el-table-column
                    label="Nội dung"
                    align="left" header-align="center">
                  <template slot-scope="scope">
                    <!--                            <p v-html="convertLoi(scope.row.moTaLoiHocBa)"></p>-->
                    <p v-html="scope.row.moTaLoiHocBa"></p>
                  </template>
                </el-table-column>
                <el-table-column width="130" align="center" label="Thời gian">
                  <template slot-scope="scope">
                    <div v-if="scope.row.thoiGian">{{ scope.row.thoiGian }}</div>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="12" class="text-left">
              <p><i>Danh sách có <b>{{ listChotHocBa.length }}</b> học sinh</i></p>
            </el-col>
          </el-row>
        </div>
        <el-dialog align="center" title="Chốt học bạ" fullscreen class="hisory-huychot" append-to-body
                   :visible.sync="showViewPdf">
          <div>
            <div class="viewHocBa">
              <iframe :src="linkViewPdf" style="width:100%;" height="100%"></iframe>
            </div>
          </div>
          <el-row :gutter="24">
            <el-col :span="24" class="text-center">
              <el-button v-if="showHocBa&&showBia==false" size="mini" @click="chotDuLieuHocBaStep2"
                         type="primary">Chốt dữ liệu
              </el-button>
              <el-button v-if="showBia&&showHocBa==false" size="mini" @click="chotDuLieuHocBaStep2Bia"
                         type="primary">Chốt dữ liệu
              </el-button>
              <el-button v-if="show_huy_chot" size="mini" @click="huyChotDuLieuHocBaStep2" type="danger">
                Huỷ chốt
                dữ liệu
              </el-button>
            </el-col>
          </el-row>
        </el-dialog>
      </el-dialog>
    </div>
    <!--        Xem-->
  </div>
</template>
<script>
import ESelect from "./Ui/ESelect";
import {
  mapState
} from "vuex";
import constant_api from "../_helpers/constant_api";
import api from "../_helpers/api";
import dialog from "../dialog";
import {
  hocbaService
} from "../_services";
import constant from "../_helpers/constant_api";
import constant2 from "../_helpers/constant_api";
import viewC1 from "./HocBa/viewHocBa/viewC1.vue";
import viewC23 from "./HocBa/viewHocBa/viewC2-3.vue";
import viewPdfC23 from "./HocBa/viewHocBa/viewC2-3.vue";

export default {
  name: "ChotDuLieuHocBa",
  metaInfo: {
    title: 'Chốt học bạ',
    titleTemplate: '',
    meta: [{
      vmid: 'description',
      name: 'description',
      content: ''
    }]
  },
  components: {
    viewPdfC23,
    viewC23, viewC1,
    ESelect,
  },
  computed: {
    ...mapState('account', [
      'user',
      'list_nam_hoc',
      'nam_hoc_hien_tai',
    ]),
    ...mapState('until', [
      'window',
    ])
  },
  methods: {
    layDanhSachLop() {
      this.fullscreenLoading = true;
      let url = constant.giaovien.listLop;
      let params = {
        namHoc: this.searchForm.year,
        start: 0,
        limit: 999,
        khoiHoc: this.searchForm.khoiHoc
      }
      this.danh_sach_lop_hoc = [];
      api.get(url, params).then(
          response => {
            console.log("Danh sách lớp học trả về:")
            console.log(response)
            if (response.data.code == 200) {
              this.danh_sach_lop_hoc = response.data.data.list
            } else {
              this.thongBao("error", response.data.msg)
            }
            this.fullscreenLoading = false;
          }
      )
    },
    taiXuongFileLoiKhiChot(item) {
      console.log('taiXuongFileLoiKhiChot')
      console.log(item)
      let params = {
        maLop: item.maLop
      }
      let url = constant_api.hocba.danhSachLoiChotHocBa
      api.post(this.thongTinXemFilePdfHocBa.pathApi, params).then(response => {
        this.fullscreenLoading = false;
        if (response.data.code === 200) {
          this.linkViewPdf = response.data.data;
        } else {
          this.thongBao('error', response.data.msg)
        }
      }).catch((e) => {
        this.fullscreenLoading = false;
        this.thongBao('error', "Lỗi kết nối API");
      })
    },
    chonTrangXemHocBa(val) {
      console.error('chonTrangXemHocBa')
      console.error(val)
      console.error(this.thongTinXemFilePdfHocBa)
      console.log(this.thongTinXemFilePdfHocBa.danh_sach_hoc_sinh[(val - 1)])
      let dt = this.thongTinXemFilePdfHocBa.danh_sach_hoc_sinh[(val - 1)];

      let params = {
        "id": dt.id,
        "maLop": dt.maLop,
        "maHS": dt.maHS,
        "isLoad": false,
        "userId": this.user.maTruong,
        "isDau": false,
        "isBia": false,
        "isQTHT": true,
        "isInAnh": true,
        "isAnh": true,
        "isKQHT": false,
        "inNamCu": false,
        "isAnChuKy": false,
        "isInChanTrang": false,
        "isInRiengBia": false,
        "inDienChinhSach": false,
        "isAnAnhGVCN": false,
        "isAnNhanXet": false,
        "isInRiengThongTinHocSinh": false,
        "isInHocBaTrang": false,
        "inTrang": null,
        "isAnAnhHieuTruong": false,
        "isAnSoTrang": 0
      }
      this.layChiTietFilePDF(params);
      // api.post(this.thongTinXemFilePdfHocBa.pathApi, [params]).then(response => {
      //   this.fullscreenLoading = false;
      //   if (response.data.code === 200) {
      //     this.linkViewPdf = response.data.data;
      //   } else {
      //     this.thongBao('error', response.data.msg)
      //   }
      // }).catch((e) => {
      //   this.fullscreenLoading = false;
      //   this.thongBao('error', "Lỗi kết nối API");
      // })
    },
    getHeightTable() {
      if (this.window.height && this.window.height > 950) {
        return this.window.height - 100
      }
      if (this.window.height && this.window.height > 850) {
        return 800
      }
      if (this.window.height && this.window.height > 750) {
        return 700
      }
      return 650
    },
    closeModal() {
      // this.showBia = false;
      // this.showHocBa = false;
    },
    showHuyChotBiaHocBa(e) {
      console.log('showHuyChotBiaHocBa')
      console.log(e);
      this.CheckAll = false;
      this.maLop = e.maLop;
      this.TextChotHocBa = 'Huỷ chốt bìa lớp ' + e.tenLop;
      this.fullscreenLoading = true;
      let url = constant_api.generate.getHistory;
      let params = {
        maLop: e.maLop
      }
      this.listHuyChot = []
      api.get(url, params).then(response => {
        console.log("Huỷ chốt trả về:")
        console.log(response)
        if (response.data.code === 200) {
          let du_lieu = response.data.data;
          for (let i = 0; i < du_lieu.length; i++) {
            du_lieu[i].check = false;
            if (du_lieu[i].chotBia && du_lieu[i].chotBia == true) {
              this.listHuyChot.push(du_lieu[i])
            }
          }
          if (this.listHuyChot.length == 0) {
            this.thongBao('error', 'Chưa có bìa học bạ nào được chốt.')
          } else {
            this.showHuyChotBia = true;
          }
        } else {
          this.thongBao('error', response.data.msg)
        }
        this.fullscreenLoading = false;
      }).catch((e) => {
        this.fullscreenLoading = false;
        this.thongBao('error', "Lỗi kết nối API");
      })
    },
    showLichSuHuyChotDuLieu(lop) {
      console.log('showLichSuHuyChotDuLieu')
      console.log(lop)
      let url = constant_api.generate.lichSuHuyChotDuLieuQTHT;
      let params = {
        maLop: lop.maLop
      }
      this.danh_sach_lich_su_huy_chot = [];
      this.fullscreenLoading = true;
      api.get(url, params).then(response => {
        console.log("Huỷ chốt trả về:")
        console.log(response)
        if (response.data.code == 200) {
          let dt = response.data.data;
          console.log(dt)
          this.showLichSuHuyChot = true;
          this.danh_sach_lich_su_huy_chot = dt;
        } else {
          this.thongBao('error', response.data.data.msg)
        }
        this.fullscreenLoading = false;
      }).catch((e) => {
        this.fullscreenLoading = false;
        this.thongBao('error', "Lỗi kết nối API");
      })
    },
    showHuyChotHocBa(e, type) {
      console.log('Huỷ chốt học bạ => ký lại toàn bộ:')
      console.log(e);
      this.CheckAll = false;
      let url;
      this.typeHuyChotDuLieu = type;
      this.maLop = e.maLop;
      if (type == 'hocba') {
        this.TextChotHocBa = 'Huỷ Chốt Học bạ lớp ' + e.tenLop;
        url = constant_api.generate.getHistory;
      }
      if (type == 'qtht') {
        this.TextChotHocBa = 'Huỷ Chốt QTHT lớp ' + e.tenLop;
        url = constant_api.generate.huyChotQTHT;
      }
      this.fullscreenLoading = true;
      let params = {
        maLop: e.maLop
      }
      api.get(url, params).then(response => {
        console.log("Huỷ chốt trả về:")
        console.log(response)
        if (response.data.code === 200) {
          let du_lieu = response.data.data;
          for (let i = 0; i < du_lieu.length; i++) {
            du_lieu[i].check = false;
          }
          this.listHuyChot = du_lieu
          this.showHuyChot = true;
        } else {
          this.thongBao('error', response.data.msg)
        }
        this.fullscreenLoading = false;
      }).catch((e) => {
        this.fullscreenLoading = false;
        this.thongBao('error', "Lỗi kết nối API");
      })
    },
    ChotLaiDuLieu(e) {
      this.maplistHocSinhCheckChotLai();
      let listHs = [];
      if (e == 1) {
        for (let i = 0; i < this.listHocSinhCheck.length; i++) {
          if (true || this.listHocSinhCheck[i].moTaLoiBiaHocBa != null) {//không check lỗi
            listHs.push(this.listHocSinhCheck[i].maHS);
          }
        }
      }
      if (e == 2) {
        for (let i = 0; i < this.listHocSinhCheck.length; i++) {
          // if (this.listHocSinhCheck[i].moTaLoiHocBa != null) {
          listHs.push(this.listHocSinhCheck[i].id);
          // }
        }
      }
      console.log("Danh sách cần chốt lại:")
      console.log(listHs);
      if (listHs && listHs.length > 0) {
        this.chotDuLieu(listHs, e);
      } else {
        let msgType = '';
        if (e == 1) {
          msgType = 'tạo bìa.'
        }
        if (e == 2) {
          msgType = 'tạo học bạ.'
        }
        let msgNoti = 'Vui lòng chỉ chọn bản ghi có lỗi trong quá trình ' + msgType;
        this.thongBao('error', msgNoti)
      }
    },
    convertLoi(text) {
      text = JSON.stringify(text);
      text.replace('\\n-', '<br/>-')
      return text
    },
    ChotDuLieuBiaHocBa() {
      console.error('ChotDuLieuBiaHocBa')
      console.error(this.listHocSinhCheckChot)
      let params = [];
      for (let i = 0; i < this.listHocSinhCheckChot.length; i++) {
        if (this.listHocSinhCheckChot[i].thanhCong == false) {
          let obj = {
            maHS: this.listHocSinhCheckChot[i].maHS,
            maLop: this.listHocSinhCheckChot[i].maLop,
            anhHS: this.listHocSinhCheckChot[i].anhHS
          }
          params.push(obj)
        }
      }
      if (this.xemTruocKhiChot) {
        console.log('Xem trước khi chốt')
        let url = constant_api.generate.xemBiaHocBaTheoMaHs;
        this.thongTinXemFilePdfHocBa.danh_sach_hoc_sinh = params;
        this.thongTinXemFilePdfHocBa.count = params.length;
        this.thongTinXemFilePdfHocBa.hoc_sinh_hien_tai = 1;
        this.thongTinXemFilePdfHocBa.pathApi = url;
        this.fullscreenLoading = true;
        api.post(url, [params[0]]).then(response => {
          this.fullscreenLoading = false;
          if (response.data.code === 200) {
            console.error('Lấy link:')
            console.error(response.data)
            this.linkViewPdf = response.data.data;
            console.error('Link: ' + this.linkViewPdf)
            this.showViewPdf = true;
          } else {
            this.thongBao('error', response.data.msg)
          }
        }).catch((e) => {
          this.fullscreenLoading = false;
          this.thongBao('error', "Lỗi kết nối API");
        })
      } else {
        console.log('không cần xem trước khi chốt')
        if (this.showHocBa && this.showBia == false) {
          console.log('Case 1')
          this.chotDuLieuHocBaStep2();
        }
        if (this.showBia && this.showHocBa == false) {
          console.log('Case 2')
          this.chotDuLieuHocBaStep2Bia();
        }
        if (this.show_huy_chot) {
          console.log('Case 3')
          this.huyChotDuLieuHocBaStep2();
        }
      }

      // Chốt luôn

      // if(this.showHocBa&&this.showBia==false){
      //   this.chotDuLieuHocBaStep2();
      // }
      // if(this.showBia&&this.showHocBa==false){
      //   this.chotDuLieuHocBaStep2Bia();
      // }
      // if(this.show_huy_chot){
      //   this.huyChotDuLieuHocBaStep2();
      // }

      // Xem trước khi chốt
      // let url = constant_api.generate.xemBiaHocBaTheoMaHs;
      // this.thongTinXemFilePdfHocBa.danh_sach_hoc_sinh = params;
      // this.thongTinXemFilePdfHocBa.count = params.length;
      // this.thongTinXemFilePdfHocBa.hoc_sinh_hien_tai = 1;
      // this.thongTinXemFilePdfHocBa.pathApi = url;
      // api.post(url, [params[0]]).then(response => {
      //   this.fullscreenLoading = false;
      //   if (response.data.code === 200) {
      //     console.error('Lấy link:')
      //     console.error(response.data)
      //     this.linkViewPdf = response.data.data;
      //     console.error('Link: ' + this.linkViewPdf)
      //     this.showViewPdf = true;
      //   } else {
      //     this.thongBao('error', response.data.msg)
      //   }
      // }).catch((e) => {
      //   this.fullscreenLoading = false;
      //   this.thongBao('error', "Lỗi kết nối API");
      // })
    },
    ChotDuLieuHocBa(checkSuccess) {
      console.error('Chốt dữ liệu')
      console.error(this.listHocSinhCheckChot)
      let maLop = this.listHocSinhCheckChot[0].maLop
      let maHSs = [];
      if (checkSuccess) {
        for (let i = 0; i < this.listHocSinhCheckChot.length; i++) {
          let obj = {
            maLop: maLop,
            id: this.listHocSinhCheckChot[i].id,
            maHS: this.listHocSinhCheckChot[i].maHS,
            hienThi3K: this.listHocSinhCheckChot[i].hienThi3K,
            hienThi1K: this.listHocSinhCheckChot[i].hienThi1K,
          }
          maHSs.push(obj)
        }
      } else {
        for (let i = 0; i < this.listHocSinhCheckChot.length; i++) {
          if (this.listHocSinhCheckChot[i].thanhCong == false) {
            // maHSs.push(this.listHocSinhCheckChot[i].maHS)
            let obj = {
              maLop: maLop,
              maHS: this.listHocSinhCheckChot[i].maHS,
              id: this.listHocSinhCheckChot[i].id,
              hienThi3K: this.listHocSinhCheckChot[i].hienThi3K,
              hienThi1K: this.listHocSinhCheckChot[i].hienThi1K,
            }
            maHSs.push(obj)
          }
        }
      }
      console.error('maHSs xem học bạ theo mã:')
      console.error(maHSs)
      if (this.xemTruocKhiChot) {
        console.log('Xem trước khi chốt')
        let url = constant_api.generate.xemHocBaTheoMaHs;
        this.thongTinXemFilePdfHocBa.danh_sach_hoc_sinh = maHSs;
        this.thongTinXemFilePdfHocBa.count = maHSs.length;
        this.thongTinXemFilePdfHocBa.hoc_sinh_hien_tai = 1;
        this.thongTinXemFilePdfHocBa.pathApi = url;
        this.fullscreenLoading = true;

        let params = {
          "id": maHSs[0].id,
          "maLop": maLop,
          "maHS": maHSs[0].maHS,
          "isLoad": false,
          "userId": this.user.maTruong,
          "isDau": false,
          "isBia": false,
          "isQTHT": true,
          "isInAnh": true,
          "isAnh": true,
          "isKQHT": false,
          "inNamCu": false,
          "isAnChuKy": false,
          "isInChanTrang": false,
          "isInRiengBia": false,
          "inDienChinhSach": false,
          "isAnAnhGVCN": false,
          "isAnNhanXet": false,
          "isInRiengThongTinHocSinh": false,
          "isInHocBaTrang": false,
          "inTrang": null,
          "isAnAnhHieuTruong": false,
          "isAnSoTrang": 0
        }
        this.layChiTietFilePDF(params);
        // api.post(url, [maHSs[0]]).then(response => {
        //   this.fullscreenLoading = false;
        //   if (response.data.code === 200) {
        //     console.error('Lấy link:')
        //     console.error(response.data)
        //     this.linkViewPdf = response.data.data;
        //     console.error('Link: ' + this.linkViewPdf)
        //     this.showViewPdf = true;
        //   } else {
        //     this.thongBao('error', response.data.msg)
        //   }
        // }).catch((e) => {
        //   this.fullscreenLoading = false;
        //   this.thongBao('error', "Lỗi kết nối API");
        // })
      } else {
        console.log('không cần xem trước khi chốt')
        if (this.showHocBa && this.showBia == false) {
          console.log('Case 1')
          this.chotDuLieuHocBaStep2();
        }
        if (this.showBia && this.showHocBa == false) {
          console.log('Case 2')
          this.chotDuLieuHocBaStep2Bia();
        }
        if (this.show_huy_chot) {
          console.log('Case 3')
          this.huyChotDuLieuHocBaStep2();
        }
      }
    },
    layChiTietFilePDF(e) {
      console.log('layChiTietFilePDF')
      console.log(e);
      this.fullscreenLoading = true;
      var url;
      if ((this.searchForm.year >= 2023 && [1, 2, 3, 4].includes(this.searchForm.khoiHoc)) || (this.searchForm.year >= 2024 && [1, 2, 3, 4, 5].includes(this.searchForm.khoiHoc))) {
        // Mẫu mới
        console.log('Mẫu mới cấp 1')
        url = constant2.hocba.detailPdfJSON;
        this.mauMoi = true;
      } else {
        if (this.searchForm.khoiHoc == 5) {
          console.log('Mẫu cũ cấp 1 - khối 5')
          this.mauMoi = false;
          url = constant2.hocba.detailPdf;
        } else {
          console.log('Mẫu cũ cấp 2,3')
          // url = constant2.hocba.detailPdfJSON;
          // this.mauMoi = true;
          this.mauMoi = false;
          url = constant2.hocba.detailPdf;
        }
      }
      this.detailHocBaPdf = null;
      this.chiTietHocBa = null;
      api.post(url, e).then(
          response => {
            this.fullscreenLoading = false;
            console.log("Res chi tiết xem học bạ:")
            console.log(response)
            if (response.data.code == 200) {
              if (this.mauMoi) {
                this.chiTietHocBa = response.data.data;
              } else {
                this.detailHocBaPdf = response.data.data;
                this.linkViewPdf = response.data.data;
              }
              this.showViewPdf = true;
            } else {
              this.thongBao('error', response.data.msg)
            }
          }
      )
    },

// <el-button v-if="showHocBa&&showBia==false" size="mini" @click="chotDuLieuHocBaStep2"
// type="success">Chốt dữ liệu
// </el-button>
// <el-button v-if="showBia&&showHocBa==false" size="mini" @click="chotDuLieuHocBaStep2Bia"
// type="success">Chốt dữ liệu
// </el-button>
// <el-button v-if="show_huy_chot" size="mini" @click="huyChotDuLieuHocBaStep2" type="danger">
//     Huỷ chốt
// dữ liệu
// </el-button>
    huyChotDuLieuHocBaStep2() {
      console.error('huỷ Chốt dữ liệu')
      console.error(this.listHocSinhCheckChot)
      let maLop = this.listHocSinhCheckChot[0].maLop
      let HocBas = [];
      for (let i = 0; i < this.listHocSinhCheckChot.length; i++) {
        let obj = {
          maHS: this.listHocSinhCheckChot[i].maHS,
          mienGiam: this.listHocSinhCheckChot[i].mienGiam
        }
        HocBas.push(obj)
      }
      console.error('Học bạ s')
      console.error(HocBas)
      let params = {
        maLop: maLop,
        hocBas: HocBas,
      }
      this.fullscreenLoading = true;
      let url = constant_api.generate.huyChotHocBaHocSinh;
      api.post(url, params).then(response => {
        this.fullscreenLoading = false;
        if (response.data.code === 200) {
          console.error('Lấy link:')
          console.error(response.data)
          this.show_huy_chot = false
          this.showViewPdf = false;
          this.thongBao('success', response.data.msg)
        } else {
          this.show_huy_chot = false;
          let obj = {
            maLop: this.maLopChot,
            tenLop: this.tenLopChot,
          }
          this.showHuyChotHocBaV2(obj)
          this.thongBao('error', response.data.msg)
        }
      }).catch((e) => {
        this.fullscreenLoading = false;
        this.thongBao('error', "Lỗi kết nối API");
      })
    },
    chotDuLieuHocBaStep2() {
      console.error('Chốt dữ liệu')
      console.error(this.listHocSinhCheckChot)
      let maLop = this.listHocSinhCheckChot[0].maLop
      let HocBas = [];
      for (let i = 0; i < this.listHocSinhCheckChot.length; i++) {
        if (this.listHocSinhCheckChot[i].thanhCong == false) {
          let obj = {
            maHS: this.listHocSinhCheckChot[i].maHS,
            mienGiam: this.listHocSinhCheckChot[i].mienGiam,
            hienThi1K: this.listHocSinhCheckChot[i].hienThi1K,
            hienThi3K: this.listHocSinhCheckChot[i].hienThi3K,
          }
          HocBas.push(obj)
        }
      }
      console.error('Học bạ s')
      console.error(HocBas)
      let params = {
        maLop: maLop,
        hocBas: HocBas,
      }
      let url = constant_api.generate.chotHocBaHocSinhV2;
      this.$confirm('Thầy/Cô có chắc chắn muốn chốt học bạ không? Hãy đảm bảo tất cả nội dung trong các học bạ được chốt đã đúng, vì sau khi kí rồi mà phát hiện sai bất kì thông tin nào sẽ phải hủy và kí lại hết từ đầu chứ không sửa lại được!', 'Thông báo', {
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy',
      })
          .then(_ => {
            this.fullscreenLoading = true;
            api.post(url, params).then(response => {
              console.log('Res chốt:')
              console.log(response)
              if (response.data.code === 200) {
                console.log('case success')
                setTimeout(() => {
                  this.fullscreenLoading = false;
                  console.error('Lấy link:')
                  console.error(response.data)
                  this.showChotHocBa = false
                  this.showViewPdf = false;
                  this.thongBao('success', response.data.msg)
                }, (response.data.time??3) * 1000)
              } else {
                console.log('case error')
                if (response.data.data) {
                  console.log('Cos data lỗi')
                  console.log(response.data.time)
                  setTimeout(() => {
                    console.log('setTimeout')
                    this.fullscreenLoading = false;
                    this.$confirm(response.data.msg + ' Tải xuống file báo lỗi?', 'Thông báo', {
                      confirmButtonText: 'Tải xuống',
                      cancelButtonText: 'Hủy',
                    }).then(_ => {
                      window.open(response.data.data, "_blank");
                    }).catch(_ => {
                    });
                  }, (response.data.time??3)*1000)
                } else {
                  console.log('Không có data lỗi')
                  this.thongBao('error', response.data.msg)
                  this.fullscreenLoading = false;
                }
                this.showViewPdf = false;
                this.showChotHocBa = false
              }
            }).catch((e) => {
              this.fullscreenLoading = false;
              this.thongBao('error', "Lỗi kết nối API");
            })
          })
          .catch(_ => {
          });
    },
    chotDuLieuHocBaStep2Bia() {
      console.error('Chốt dữ liệu')
      console.error(this.listHocSinhCheckChot)
      let maLop = this.listHocSinhCheckChot[0].maLop
      let HocBas = [];
      for (let i = 0; i < this.listHocSinhCheckChot.length; i++) {
        if (this.listHocSinhCheckChot[i].thanhCong == false) {
          let obj = {
            maHS: this.listHocSinhCheckChot[i].maHS,
            anhHS: !this.listHocSinhCheckChot[i].anhHS
          }
          HocBas.push(obj)
        }
      }
      console.error('Học bạ s')
      console.error(HocBas)
      let params = {
        maLop: maLop,
        hocSinhs: HocBas,
      }
      let url = '';
      if (this.typeChotDuLieu == 'bia') {
        url = constant_api.generate.chotBiaHocBaHocSinh;
      } else {
        url = constant_api.generate.chotQTHTHocBaHocSinh;
      }
      this.$confirm('Thầy/Cô có chắc chắn muốn chốt học bạ không? Hãy đảm bảo tất cả nội dung trong các học bạ được chốt đã đúng, vì sau khi kí rồi mà phát hiện sai bất kì thông tin nào sẽ phải hủy và kí lại hết từ đầu chứ không sửa lại được!', 'Thông báo', {
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy',
      })
          .then(_ => {
                this.fullscreenLoading = true;
                api.post(url, params).then(response => {
                  console.log('response chốt')
                  console.log(response)
                  if (response.data.code === 200) {
                    console.log('success')
                    setTimeout(()=>{
                      console.error('Lấy link:')
                      console.error(response.data)
                      this.showChotHocBa = false
                      this.fullscreenLoading = false;
                      this.showViewPdf = false;
                      this.thongBao('success', response.data.msg)
                    },(response.data.time??3)*1000)
                  } else {
                    console.log('Chốt lỗi')
                    setTimeout(()=>{
                      this.fullscreenLoading = false;
                      this.showViewPdf = false;
                      this.showChotHocBa = false
                      if (response.data.data) {
                        console.log('có data lỗi')
                        this.$confirm(response.data.msg + ' Tải xuống file báo lỗi?', 'Thông báo', {
                          confirmButtonText: 'Tải xuống',
                          cancelButtonText: 'Hủy',
                        }).then(_ => {
                          window.open(response.data.data, "_blank");
                        }).catch(_ => {
                        });
                      } else {
                        console.log('không có data lỗi')
                        this.thongBao('error', response.data.msg)
                      }
                    },(response.data.time??3)*1000)
                  }
                }).catch((e) => {
                  console.log(e)
                  this.fullscreenLoading = false;
                  this.thongBao('error', "Lỗi kết nối API");
                })
              }
          )
          .catch(_ => {
          });
    },
    huyChotDuLieu(arr, type) {
      console.error('huyChotDuLieu')
      let msg = 'Khi thực hiện thao tác huỷ chốt Học bạ, tất cả dữ liệu đã ký các thầy cô sẽ phải ký lại từ đầu.'
      let msgtext = 'Huỷ chốt Học bạ'
      let msgtype = 'el-button--danger'
      if (type == 3) {
        msgtext = 'Huỷ chốt bìa'
        msgtype = 'el-button--warning'
        msg = 'Khi thực hiện thao tác hủy bìa học bạ, quý thầy cô phải cập nhật lại thông tin học sinh đã hủy lại từ đầu!';
      }
      if (type == 4) {
        msgtext = 'Huỷ chốt QTHT'
        msgtype = 'el-button--warning'
        msg = 'Khi thực hiện thao tác hủy chốt QTHT, quý thầy cô phải cập nhật lại thông tin học sinh đã hủy lại từ đầu!';
      }
      this.$confirm(msg, 'Cảnh báo', {
        confirmButtonText: msgtext,
        cancelButtonText: 'Hủy',
        confirmButtonClass: msgtype,
        // callback: action => {
        //     console.error('callback đóng')
        //     return;
        //     //nhấn enter thì đóng confirm
        // }
      })
          .then(_ => {
            this.fullscreenLoading = true;
            let url = constant_api.generate.huyChotDuLieu;
            let params = {
              namHoc: this.searchForm.year,
              type: type,
              maHSs: arr,
              maLops: [this.maLop]
            }
            if (type == 3) {
              //Huỷ chốt bìa
              url = constant_api.generate.huyChotDuLieuBia;
              // delete params.maLops
              delete params.type
            }
            if (type == 4) {
              //Huỷ chốt QTHT
              url = constant_api.generate.huyChotDuLieuQTHT;
              params.maLop = params.maLops[0]
              // delete params.maLops
              delete params.type
            }
            api.post(url, params).then(data => {
              this.fullscreenLoading = false;
              if (data.data.code === 200) {
                this.thongBao('success', data.data.msg)
                this.showHuyChot = false;
                this.showHuyChotBia = false;
                this.getListLop()
              } else {
                this.thongBao('error', data.data.msg)
              }
            }).catch((e) => {
              this.fullscreenLoading = false;
              this.thongBao('error', "Lỗi kết nối API");
            })
          })
          .catch(_ => {
          });
    }
    ,
    chotDuLieu(arr, type) {
      let msg = '';
      if (type == 1) {
        msg = 'Xác nhận chốt lại dữ liệu của ' + arr.length + '/' + this.listHocSinhCheck.length + ' bản ghi đã chọn?';
      }
      if (type == 2) {
        msg = 'Xác nhận chốt lại dữ liệu của ' + arr.length + ' bản ghi đã chọn? Lưu ý: Chỉ chốt lại những học sinh mà hệ thống chưa sinh file.';
      }
      this.$confirm(msg, 'Thông báo', {
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy',
      })
          .then(_ => {
            this.fullscreenLoading = true;
            let url = constant_api.generate.chotDuLieu;
            let params = {
              namHoc: this.searchForm.year,
              type: type,
              maHSs: arr,
              maLops: [this.maLop]
            }
            api.post(url, params).then(data => {
              this.fullscreenLoading = false;
              if (data.data.code === 200) {
                this.thongBao('success', data.data.msg)
                this.showHistory = false;
                this.getListLop()
              } else {
                this.thongBao('error', data.data.msg)
              }
            }).catch((e) => {
              this.fullscreenLoading = false;
              this.thongBao('error', "Lỗi kết nối API");
            })
          })
          .catch(_ => {
          });
    }
    ,
    ChonToanBoHocBaChotLai() {
      if (this.CheckAll) {
        for (let i = 0; i < this.listHistory.length; i++) {
          this.listHistory[i].check = true;
        }
      } else {
        for (let i = 0; i < this.listHistory.length; i++) {
          this.listHistory[i].check = false;
        }
      }
    }
    ,
    chonMoiHocBaChotLai(e) {
      console.log(e.check)
      console.log("CHeck")
      let newCheck = e.check;
      for (let i = 0; i < this.listHistory.length; i++) {
        if (this.listHistory[i].id == e.id) {
          this.listHistory[i].check = newCheck;
        }
      }
      this.maplistHocSinhCheckChotLai();
    }
    ,
    maplistHocSinhCheckChotLai() {
      let data = [];
      for (let i = 0; i < this.listHistory.length; i++) {
        if (this.listHistory[i].check == true) {
          data.push(this.listHistory[i])
        }
      }
      this.listHocSinhCheck = data;
      console.log("Danh sách học bạ check:")
      console.log(this.listHocSinhCheck)
      if (this.listHocSinhCheck.length == this.listHistory.length) {
        this.CheckAll = true;
      } else {
        this.CheckAll = false;
      }
    }
    ,
//Chốt
    ChonToanBoHocBaChot(checkSuccess) {
      console.error('ChonToanBoHocBaChot')
      let listChotHocBa = JSON.parse(JSON.stringify(this.listChotHocBa))
      if (this.CheckAllChotHocBa) {
        console.error('1-1')
        if (!checkSuccess) {
          for (let i = 0; i < listChotHocBa.length; i++) {
            if (this.listChotHocBa[i].thanhCong == false) {
              listChotHocBa[i].check = true;
              this.listHocSinhCheckChot.push(listChotHocBa[i])
            }
          }
        } else {
          for (let i = 0; i < listChotHocBa.length; i++) {
            listChotHocBa[i].check = true;
            this.listHocSinhCheckChot.push(listChotHocBa[i])
          }
        }
      } else {
        for (let i = 0; i < listChotHocBa.length; i++) {
          listChotHocBa[i].check = false;
        }
        this.listHocSinhCheckChot = [];
      }
      this.listChotHocBa = JSON.parse(JSON.stringify(listChotHocBa))
    }
    ,
    ChonToanBoAnhHienThiKyIHocBaChot() {
      console.error('ChonToanBoAnhHienThiKyIHocBaChot')
      let listChotHocBa = JSON.parse(JSON.stringify(this.listChotHocBa))
      if (this.CheckAllHienThi1Ky) {
        this.CheckAllHienThi3Ky = false;
        for (let i = 0; i < listChotHocBa.length; i++) {
          if (this.listChotHocBa[i].thanhCong == false) {
            listChotHocBa[i].hienThi1K = true;
            listChotHocBa[i].hienThi3K = false;
          }
        }
      } else {
        for (let i = 0; i < listChotHocBa.length; i++) {
          if (this.listChotHocBa[i].thanhCong == false) {
            listChotHocBa[i].hienThi1K = false;
            listChotHocBa[i].hienThi3K = false;
          }
        }
      }
      this.listChotHocBa = JSON.parse(JSON.stringify(listChotHocBa))
    }
    ,
    ChonToanBoAnhHienThiKyIIIHocBaChot() {
      console.error('ChonToanBoAnhHienThiKyIHocBaChot')
      let listChotHocBa = JSON.parse(JSON.stringify(this.listChotHocBa))
      if (this.CheckAllHienThi3Ky) {
        this.CheckAllHienThi1Ky = false;
        for (let i = 0; i < listChotHocBa.length; i++) {
          if (this.listChotHocBa[i].thanhCong == false) {
            listChotHocBa[i].hienThi1K = false;
            listChotHocBa[i].hienThi3K = true;
          }
        }
      } else {
        for (let i = 0; i < listChotHocBa.length; i++) {
          if (this.listChotHocBa[i].thanhCong == false) {
            listChotHocBa[i].hienThi1K = false;
            listChotHocBa[i].hienThi3K = false;
          }
        }
      }
      this.listChotHocBa = JSON.parse(JSON.stringify(listChotHocBa))
    }
    ,
    ChonToanBoAnhHocBaChot() {
      console.error('ChonToanBoAnhHocBaChot')
      let listChotHocBa = JSON.parse(JSON.stringify(this.listChotHocBa))
      if (this.CheckAllAnhChotHocBa) {
        console.error('1-1')
        for (let i = 0; i < listChotHocBa.length; i++) {
          if (this.listChotHocBa[i].thanhCong == false) {
            listChotHocBa[i].anhHS = true;
          }
        }
      } else {
        for (let i = 0; i < listChotHocBa.length; i++) {
          if (this.listChotHocBa[i].thanhCong == false) {
            listChotHocBa[i].anhHS = false;
          }
        }
      }
      this.listChotHocBa = JSON.parse(JSON.stringify(listChotHocBa))
      this.maplistHocSinhCheckChot();
    }
    ,
//Huỷ chốt
    ChonToanBoHocBaHuyChot() {
      if (this.CheckAll) {
        for (let i = 0; i < this.listHuyChot.length; i++) {
          this.listHuyChot[i].check = true;
        }
      } else {
        for (let i = 0; i < this.listHuyChot.length; i++) {
          this.listHuyChot[i].check = false;
        }
      }
      this.maplistHocSinhCheckHuyChot();
    }
    ,
    khoiPhucBanGhi(item) {
      console.log('khoiPhucBanGhi')
      console.log(item)
      this.$confirm('Xác nhận khôi phục dữ liệu từ đợt huỷ chốt này?', 'Thông báo', {
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy',
      })
          .then(_ => {
            this.fullscreenLoading = true;
            let url = constant_api.generate.khoiPhuc;
            let params = {
              id: item.id
            }
            api.post(url, params).then(data => {
              this.fullscreenLoading = false;
              if (data.data.code === 200) {
                this.thongBao('success', data.data.msg)
                this.showLichSuHuyChot = false;
              } else {
                this.thongBao('error', data.data.msg)
              }
            }).catch((e) => {
              this.fullscreenLoading = false;
              this.thongBao('error', "Lỗi kết nối API");
            })
          })
          .catch(_ => {
          });
    }
    ,
    chonMoiHocBaHuyChot(e) {
      console.log(e.check)
      console.log("CHeck")
      let newCheck = e.check;
      for (let i = 0; i < this.listHuyChot.length; i++) {
        if (this.listHuyChot[i].id == e.id) {
          this.listHuyChot[i].check = newCheck;
        }
      }
      this.maplistHocSinhCheckHuyChot();
    }
    ,
    ChonMienGiam(e) {
      console.log("ChonMienGiam")
      console.error(e)
      console.error('Danh sách đã chọn')
      console.error(this.listHocSinhCheckChot)
      let listChotHocBa = JSON.parse(JSON.stringify(this.listChotHocBa))
      let newCheck = e.mienGiam;
      console.error('Giá trị mới: ' + newCheck)
      for (let i = 0; i < listChotHocBa.length; i++) {
        if (listChotHocBa[i].id == e.id) {
          this.listChotHocBa[i].mienGiam = JSON.parse(JSON.stringify(newCheck));
        }
      }
      for (let j = 0; j < this.listHocSinhCheckChot.length; j++) {
        console.error('Vào for ' + newCheck)
        console.error('Check ' + e.id + ' : ' + this.listHocSinhCheckChot[j].id)
        if (this.listHocSinhCheckChot[j].id == e.id) {
          console.error('Bằng nhau:')
          this.listHocSinhCheckChot[j].mienGiam = JSON.parse(JSON.stringify(newCheck));
        }
      }
      console.error('Danh sách check chốt:')
      console.error(this.listHocSinhCheckChot)
      this.listChotHocBa = JSON.parse(JSON.stringify(listChotHocBa))
    }
    ,
    chonHienThi1Ky(e) {
      console.error('chonHienThi1Ky')
      console.error(e)
      let listChotHocBa = JSON.parse(JSON.stringify(this.listChotHocBa))
      let newCheck = e.hienThi1K;
      for (let i = 0; i < listChotHocBa.length; i++) {
        if (listChotHocBa[i].id == e.id) {
          listChotHocBa[i].hienThi1K = newCheck;
          listChotHocBa[i].hienThi3K = false;
        }
      }
      this.listChotHocBa = JSON.parse(JSON.stringify(listChotHocBa))
    }
    ,
    chonHienThi3Ky(e) {
      console.error('chonHienThi3Ky')
      console.error(e)
      let listChotHocBa = JSON.parse(JSON.stringify(this.listChotHocBa))
      let newCheck = e.hienThi3K;
      for (let i = 0; i < listChotHocBa.length; i++) {
        if (listChotHocBa[i].id == e.id) {
          listChotHocBa[i].hienThi3K = newCheck;
          listChotHocBa[i].hienThi1K = false;
        }
      }
      this.listChotHocBa = JSON.parse(JSON.stringify(listChotHocBa))
    }
    ,
    ChonCoAnh(e) {
      console.log("ChonCoAnh")
      let listChotHocBa = JSON.parse(JSON.stringify(this.listChotHocBa))
      let newCheck = e.anhHS;
      for (let i = 0; i < listChotHocBa.length; i++) {
        if (listChotHocBa[i].id == e.id) {
          this.listChotHocBa[i].anhHS = newCheck;
        }
      }
      this.listChotHocBa = JSON.parse(JSON.stringify(listChotHocBa))
      this.maplistHocSinhCheckChot();
    }
    ,
    chonMoiHocBaChot(e) {
      console.log("chonMoiHocBaChot")
      console.log(e.check)
      let listChotHocBa = JSON.parse(JSON.stringify(this.listChotHocBa))
      let newCheck = !e.check;
      for (let i = 0; i < listChotHocBa.length; i++) {
        if (listChotHocBa[i].id == e.id) {
          console.error(newCheck + '<==')
          this.listChotHocBa[i].check = newCheck;
        }
      }
      console.error('listChotHocBa')
      console.error(listChotHocBa)
      this.listChotHocBa = JSON.parse(JSON.stringify(listChotHocBa))
      this.maplistHocSinhCheckChot();
    }
    ,
    maplistHocSinhCheckChot() {
      let data = [];
      for (let i = 0; i < this.listChotHocBa.length; i++) {
        if (this.listChotHocBa[i].check == true) {
          data.push(this.listChotHocBa[i])
        }
      }
      console.error('Data chốt:')
      console.error(data)
      this.listHocSinhCheckChot = JSON.parse(JSON.stringify(data));
      console.log("Danh sách học bạ huỷ chốt check:")
      console.log(this.listHocSinhCheckHuyChot)
      if (this.listHocSinhCheckChot.length == this.listChotHocBa.length) {
        this.CheckAllChotHocBa = true;
      } else {
        this.CheckAllChotHocBa = false;
      }
    }
    ,
    maplistHocSinhCheckHuyChot() {
      let data = [];
      for (let i = 0; i < this.listHuyChot.length; i++) {
        if (this.listHuyChot[i].check == true) {
          data.push(this.listHuyChot[i])
        }
      }
      this.listHocSinhCheckHuyChot = data;
      console.log("Danh sách học bạ huỷ chốt check:")
      console.log(this.listHocSinhCheckHuyChot)
      if (this.listHocSinhCheckHuyChot.length == this.listHuyChot.length) {
        this.CheckAll = true;
      } else {
        this.CheckAll = false;
      }
    }
    ,
    checkHuyChotDuLieu(e) {
      this.maplistHocSinhCheckHuyChot();
      let listHs = [];
      if (e == 1 || e == 3 || e == 4) {
        for (let i = 0; i < this.listHocSinhCheckHuyChot.length; i++) {
          listHs.push(this.listHocSinhCheckHuyChot[i].maHS);
        }
      }
      if (e == 2) {
        for (let i = 0; i < this.listHocSinhCheckHuyChot.length; i++) {
          listHs.push(this.listHocSinhCheckHuyChot[i].id);
        }
      }
      console.log("Danh sách cần chốt lại:")
      console.log(listHs);
      if (listHs && listHs.length > 0) {
        this.huyChotDuLieu(listHs, e);
      }
    }
    ,
//Hết huỷ chốt
    getCurrentMonth() {
      let d = new Date();
      return d.getMonth();
    }
    ,
    buttonHistoryClick(item) {
      this.fullscreenLoading = true;
      this.maLop = item.maLop;
      this.titleClass = 'Lịch sử xử lý lớp ' + item.tenLop
      hocbaService.getHistory({
        maLop: item.maLop
      }).then(data => {
        if (data.data.code === 200) {
          let du_lieu = data.data.data;
          for (let i = 0; i < du_lieu.length; i++) {
            du_lieu[i].check = false;
          }
          this.listHistory = du_lieu
        }
        this.fullscreenLoading = false;
      }).catch((e) => {
        console.log(e)
      })
      this.showHistory = true;
    }
    ,
    chotBia(item, type) {
      this.showBia = true;
      this.showHocBa = false;
      this.mauMoi = false;
      console.error('chotHocBa')
      console.error(item)
      this.khoiHocXem = item.khoiHoc;
      if (item.khoiHoc >= 6 && type == 'bia') {
        this.showColImg = true;
      } else {
        this.showColImg = false;
      }
      this.listHocSinhCheckChot = [];
      this.TextChotHocBa = 'Chốt dữ liệu'
      this.typeChotDuLieu = type;
      let url = constant_api.generate.chotDuLieuBia;
      if (type == 'bia') {
        this.TextChotHocBa = "Chốt bìa lớp " + item.tenLop;
      }
      if (type == 'qtht') {
        this.TextChotHocBa = "Chốt quá trình học tập lớp " + item.tenLop;
        url = constant_api.generate.chotDuLieuQTHT;
      }
      this.fullscreenLoading = true;
      this.CheckAllChotHocBa = false;
      this.maLopChot = item.maLop;
      this.tenLopChot = item.tenLop;
      let params = {
        maLop: item.maLop
      }
      api.get(url, params).then(response => {
        this.fullscreenLoading = false;
        console.log('response')
        console.log(response)
        if (response.data.code === 200) {
          console.error(response.data)
          this.listChotHocBa = response.data.data;
          for (let i = 0; i < this.listChotHocBa.length; i++) {
            this.listChotHocBa[i].check = false;
            this.listChotHocBa[i].anhHS = false;
          }
          console.error('khongChoChonTatCa:')
          this.khongChoChonTatCa = !(this.listChotHocBa.some(obj => obj.thanhCong == false))
          this.showChotHocBa = true;
        } else {
          this.thongBao('error', response.data.msg)
        }
      }).catch((e) => {
        this.fullscreenLoading = false;
        this.thongBao('error', "Lỗi kết nối API");
      })
    }
    ,
    showHuyChotHocBaV2(item) {
      this.showBia = false;
      this.showHocBa = false;
      this.showViewPdf = false;
      this.show_huy_chot = false;
      console.error('chotHocBa')
      console.error(item)
      this.listHocSinhCheckChot = [];
      this.TextChotHocBa = "Huỷ chốt học bạ lớp " + item.tenLop;
      this.fullscreenLoading = true;
      this.CheckAllChotHocBa = false;
      let url = constant_api.generate.chotDuLieuHocBa;
      this.maLopChot = item.maLop;
      this.tenLopChot = item.tenLop;
      let params = {
        maLop: item.maLop
      }
      api.get(url, params).then(response => {
        this.fullscreenLoading = false;
        if (response.data.code === 200) {
          console.error(response.data)
          this.listChotHocBa = response.data.data;
          for (let i = 0; i < this.listChotHocBa.length; i++) {
            this.listChotHocBa[i].check = false;
            this.listChotHocBa[i].mienGiam = false;
          }
          this.show_huy_chot = true;
          this.showBia = false
          this.showHocBa = false
        } else {
          this.thongBao('error', response.data.msg)
        }
      }).catch((e) => {
        this.fullscreenLoading = false;
        this.thongBao('error', "Lỗi kết nối API");
      })
    }
    ,
    chotHocBa(item, type) {
      console.error('chotHocBa')
      console.error(item)
      this.typeChotDuLieu = type;
      this.showBia = false;
      this.showColGdcd = false;
      this.showHocBa = true;
      this.khoiHocXem = item.khoiHoc;
      this.listHocSinhCheckChot = [];
      this.TextChotHocBa = "Chốt Học bạ lớp " + item.tenLop;
      console.log('khoiHocXem:', this.khoiHocXem)
      this.showColGdcd = item.gdcd;
      this.fullscreenLoading = true;
      this.CheckAllChotHocBa = false;
      let url = constant_api.generate.chotDuLieuHocBa;
      this.maLopChot = item.maLop;
      this.tenLopChot = item.tenLop;
      let params = {
        maLop: item.maLop
      }
      api.get(url, params).then(response => {
        this.fullscreenLoading = false;
        console.log('response')
        console.log(response)
        if (response.data.code === 200) {
          console.error(response.data)
          this.listChotHocBa = response.data.data;
          this.khongChoChonTatCa = !(this.listChotHocBa.some(obj => obj.thanhCong === false))
          for (let i = 0; i < this.listChotHocBa.length; i++) {
            this.listChotHocBa[i].check = false;
            this.listChotHocBa[i].mienGiam = false;
            this.listChotHocBa[i].hienThi1K = false;
            this.listChotHocBa[i].hienThi3K = false;
            this.CheckAllHienThi3Ky = false;
            this.CheckAllHienThi1Ky = false;
          }
          this.showChotHocBa = true;
        } else {
          this.thongBao('error', response.data.msg)
        }
      }).catch((e) => {
        this.fullscreenLoading = false;
        this.thongBao('error', "Lỗi kết nối API");
      })
    }
    ,
    chotHocBaBak(item) {
      this.$confirm('Xác nhận chốt học bạ?', 'Thông báo', {
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy',
      })
          .then(_ => {
            this.fullscreenLoading = true;
            let url = constant_api.generate.chotDuLieu;
            let params = {
              namHoc: this.searchForm.year,
              type: 2,
              maLops: [item.maLop]
            }
            api.post(url, params).then(data => {
              this.fullscreenLoading = false;
              if (data.data.code === 200) {
                this.thongBao('success', data.data.msg)
                this.getListLop()
              } else {
                this.thongBao('error', data.data.msg)
              }
            }).catch((e) => {
              this.fullscreenLoading = false;
              this.thongBao('error', "Lỗi kết nối API");
            })
          })
          .catch(_ => {
          });
    }
    ,
    chotBiaBak(item) {
      this.$confirm('Xác nhận chốt bìa?', 'Thông báo', {
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy',
      })
          .then(_ => {
            this.fullscreenLoading = true;
            let url = constant_api.generate.chotDuLieu;
            let params = {
              namHoc: this.searchForm.year,
              type: 1,
              maLops: [item.maLop]
            }
            api.post(url, params).then(data => {
              this.fullscreenLoading = false;
              if (data.data.code === 200) {
                this.thongBao('success', data.data.msg)
                this.getListLop()
              } else {
                this.thongBao('error', data.data.msg)
              }
            }).catch((e) => {
              this.fullscreenLoading = false;
              this.thongBao('error', "Lỗi kết nối API");
            })
          })
          .catch(_ => {
          });
    }
    ,
    choHocBaHS(hocsinh) {
      console.error('Chốt học bạ học sinh')
      this.fullscreenLoading = true;
      let url = constant_api.generate.chotDuLieu;
      let params = {
        namHoc: this.searchForm.year,
        type: 2,
        maLops: [hocsinh.maLop],
        maHS: hocsinh.id
      }
      api.post(url, params).then(data => {
        this.fullscreenLoading = false;
        if (data.data.code === 200) {
          this.thongBao('success', data.data.msg)
          this.getListLop()
        } else {
          this.thongBao('error', data.data.msg)
        }
      }).catch((e) => {
        this.fullscreenLoading = false;
        this.thongBao('error', "Lỗi kết nối API");
      })
    }
    ,
    chotBiaHS(hocsinh) {
      this.$confirm('Xác nhận chốt bìa?', 'Thông báo', {
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy',
      })
          .then(_ => {
            this.fullscreenLoading = true;
            let url = constant_api.generate.chotDuLieu;
            let params = {
              namHoc: this.searchForm.year,
              type: 1,
              maLops: [hocsinh.maLop],
              maHS: hocsinh.maHS
            }
            api.post(url, params).then(data => {
              this.fullscreenLoading = false;
              if (data.data.code === 200) {
                this.thongBao('success', data.data.msg)
                this.getListLop()
              } else {
                this.thongBao('error', data.data.msg)
              }
            }).catch((e) => {
              this.fullscreenLoading = false;
              this.thongBao('error', "Lỗi kết nối API");
            })
          })
          .catch(_ => {
          });
    }
    ,
    chotLstHocba(item) {
      let maLop = [];
      if (item.lopHocs) {
        item.lopHocs.forEach(lop => {
          maLop.push(lop.maLop)
        })
      }
      if (!maLop.length)
        return;
      this.fullscreenLoading = true;
      let url = constant_api.generate.chotDuLieu;
      let params = {
        type: 2,
        maLops: maLop
      }
      api.post(url, params).then(data => {
        this.fullscreenLoading = false;
        if (data.data.code === 200) {
          this.thongBao('success', data.data.msg)
          this.getListLop()
        } else {
          this.thongBao('error', data.data.msg)
        }
      }).catch((e) => {
        this.fullscreenLoading = false;
        this.thongBao('error', "Lỗi kết nối API");
      })
    }
    ,
    chotListBia(item) {
      let maLop = [];
      if (item.lopHocs) {
        item.lopHocs.forEach(lop => {
          maLop.push(lop.maLop)
        })
      }
      if (!maLop.length)
        return;
      this.fullscreenLoading = true;
      let url = constant_api.generate.chotDuLieu;
      let params = {
        type: 1,
        maLops: maLop
      }
      api.post(url, params).then(data => {
        this.fullscreenLoading = false;
        if (data.data.code === 200) {
          this.thongBao('success', data.data.msg)
          this.getListLop()
        } else {
          this.thongBao('error', data.data.msg)
        }
      }).catch((e) => {
        this.fullscreenLoading = false;
        this.thongBao('error', "Lỗi kết nối API");
      })
    }
    ,
    getListLop() {
      let uri = constant_api.giaovien.dsLopTheoKhoi;
      let params = {
        namHoc: this.searchForm.year,
        capHoc: this.searchForm.capHoc,
        // maLop: this.searchForm.lopHoc,
        khoiHoc: this.searchForm.khoiHoc
      };
      this.fullscreenLoading = true;
      api.get(uri, params).then((data) => {
        if (data.data.code == 200) {
          console.log('data:')
          console.log(data)
          this.dataTable = [];
          if (data.data.data.length) {
            this.dataTable = data.data.data
            // this.thongBao('success','Lấy dữ liệu thành công')
          } else {
            this.thongBao('error', 'Chưa có dữ liệu.')
          }
        } else {
          this.thongBao('error', data.data.data.msg)
        }
        this.fullscreenLoading = false;
      }).catch((e) => {
        this.fullscreenLoading = false;
        this.$message({
          customClass: 'dts-noty-error',
          showClose: true,
          message: 'Lỗi kết nối',
          type: 'error',
          duration: 3000
        });
      })
    }
    ,
    submitSearch(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getListLop();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
    ,
    thongBao(t, e) {
      let msg = "";
      let cl = "";
      if (e) {
        msg = e;
      }
      let type = "success";
      if (t) {
        type = t
      }
      if (type == "success") {
        cl = "dts-noty-success"
      }
      if (type == "warning") {
        cl = "dts-noty-warning"
      }
      if (type == "error") {
        cl = "dts-noty-error"
      }
      if (type == "info") {
        cl = "dts-noty-info"
      }
      this.$message({
        dangerouslyUseHTMLString: true,
        customClass: cl,
        showClose: true,
        message: msg,
        type: t,
        duration: 3000
      });
    }
    ,
    getListCapHoc() {
      let capHoc = []
      if (this.user.capHoc == 1) {
        capHoc = [{
          name: "Cấp 1",
          value: 1
        }]
      } else if (this.user.capHoc == 2) {
        capHoc = [{
          name: "Cấp 2",
          value: 2
        }]
      } else if (this.user.capHoc == 3) {
        capHoc = [{
          name: "Cấp 3",
          value: 3
        }]
      } else if (this.user.capHoc == 36) {
        capHoc = [{
          name: "Cấp 3",
          value: 3
        }]
      } else if (this.user.capHoc == 12) {
        capHoc = [{
          name: "Cấp 1",
          value: 1
        }, {
          name: "Cấp 2",
          value: 2
        }]
      } else if (this.user.capHoc == 23) {
        capHoc = [{
          name: "Cấp 2",
          value: 2
        }, {
          name: "Cấp 3",
          value: 3
        }]
      } else if (this.user.capHoc == 15) {
        capHoc = [{
          name: "Cấp 1",
          value: 1
        }]
      } else if (this.user.capHoc == 145) {
        capHoc = [{
          name: "Cấp 1",
          value: 1
        }]
      } else if (this.user.capHoc == 14) {
        capHoc = [{
          name: "Cấp 1",
          value: 1
        }]
      } else if (this.user.capHoc == 451) {
        capHoc = [{
          name: "Cấp 1",
          value: 1
        }]
      } else if (this.user.capHoc == 452) {
        capHoc = [{
          name: "Cấp 2",
          value: 2
        }]
      } else if (this.user.capHoc == 1245) {
        capHoc = [{
          name: "Cấp 1",
          value: 1
        }, {
          name: "Cấp 2",
          value: 2
        }]
      } else if (this.user.capHoc == 236) {
        capHoc = [{
          name: "Cấp 2",
          value: 2
        }, {
          name: "Cấp 3",
          value: 3
        }]
      } else if (this.user.capHoc == 12345) {
        capHoc = [{
          name: "Cấp 1",
          value: 1
        }, {
          name: "Cấp 2",
          value: 2
        }, {
          name: "Cấp 3",
          value: 3
        }]
      } else if (this.user.capHoc == 1235) {
        capHoc = [{
          name: "Cấp 1",
          value: 1
        }, {
          name: "Cấp 2",
          value: 2
        }, {
          name: "Cấp 3",
          value: 3
        }]
      } else {
        capHoc = [{
          name: "Cấp 1",
          value: 1
        }, {
          name: "Cấp 2",
          value: 2
        }, {
          name: "Cấp 3",
          value: 3
        }]
      }
      this.list_cap_hoc = capHoc;
    }
    ,
    getFullYear() {
      let d = new Date();
      return d.getFullYear();
    }
    ,
    getKhoiHoc() {
      this.searchForm.khoiHoc = '';
      this.optionGrade = [];
      if (this.searchForm.capHoc == 1) {
        this.optionGrade = this.khoiCap1;
      } else if (this.searchForm.capHoc == 2) {
        this.optionGrade = this.khoiCap2;
      } else if (this.searchForm.capHoc == 3) {
        this.optionGrade = this.khoiCap3;
      } else if (this.searchForm.capHoc == 12) {
        this.optionGrade = this.khoiCap1.concat(this.khoiCap2)
      } else if (this.searchForm.capHoc == 23) {
        this.optionGrade = this.khoiCap3.concat(this.khoiCap3)
      } else {
        this.optionGrade = this.khoiCap1.concat(this.khoiCap2, this.khoiCap3)
      }
    }
    ,
  },
  data() {
    return {
      maxHeightTable: window.innerHeight,
      maxHeight: window.innerHeight,
      maxHeightHuyChot: window.innerHeight,
      khongChoChonTatCa: true,
      listHuyChot: [],
      listChotHocBa: [],
      listHistory: [],
      dataTable: [],
      loading: false,
      maLop: '',
      titleClass: '',
      CheckAllHienThi1Ky: false,
      CheckAllHienThi3Ky: false,
      CheckAllAnhChotHocBa: false,
      CheckAllChotHocBa: false,
      fullscreenLoading: false,
      danh_sach_lich_su_huy_chot: [],
      xemTruocKhiChot: false,
      thongTinXemFilePdfHocBa: {
        danh_sach_hoc_sinh: [],
        hoc_sinh_hien_tai: 0,
        count: 0,
        pathApi: ''
      },
      maLopChot: '',
      tenLopChot: '',
      linkViewPdf: '',
      showViewPdf: false,
      showHuyChot: false,
      showHuyChotBia: false,
      showHistory: false,
      showLichSuHuyChot: false,
      showChotHocBa: false,
      show_huy_chot: false,
      TextChotHocBa: 'Chốt học bạ',
      showHocBa: false,
      showColGdcd: false,
      showBia: true,
      typeChotDuLieu: 1,
      typeHuyChotDuLieu: 1,
      showColImg: false,
      optionGrade: [],
      danh_sach_lop_hoc: [],
      khoiCap1: [{
        value: 1,
        label: 'Khối 1'
      },
        {
          value: 2,
          label: 'Khối 2'
        },
        {
          value: 3,
          label: 'Khối 3'
        },
        {
          value: 4,
          label: 'Khối 4'
        },
        {
          value: 5,
          label: 'Khối 5'
        },
      ],
      khoiCap2: [{
        value: 6,
        label: 'Khối 6'
      },
        {
          value: 7,
          label: 'Khối 7'
        },
        {
          value: 8,
          label: 'Khối 8'
        },
        {
          value: 9,
          label: 'Khối 9'
        },
      ],
      khoiCap3: [{
        value: 10,
        label: 'Khối 10'
      },
        {
          value: 11,
          label: 'Khối 11'
        },
        {
          value: 12,
          label: 'Khối 12'
        },
      ],
      list_cap_hoc: [],
      chiTietHocBa: null,
      detailHocBaPdf: null,
      mauMoi: false,
      searchForm: {
        year: '',
        capHoc: '',
        khoiHoc: '',
      },
      rulesSearch: {
        year: [{
          required: true,
          message: 'Bạn chưa chọn năm học',
          trigger: 'change'
        }],
      },
      khoiHocXem: '',
      listHocSinhCheck: [],
      listHocSinhCheckHuyChot: [],
      listHocSinhCheckChot: [],
      CheckAll: false,
    }
  }
  ,
  mounted() {
    this.maxHeight -= 150;
    this.maxHeightHuyChot -= 200;
    this.maxHeightTable = this.getHeightTable();
    this.searchForm.year = this.nam_hoc_hien_tai;
    this.getListCapHoc();
    if (this.list_cap_hoc && this.list_cap_hoc.length == 1) {
      this.searchForm.capHoc = this.list_cap_hoc[0].value;
    }
    this.getKhoiHoc();
  }
}
</script>
<style scoped>
.tableFixHead {
  overflow: auto;
}

.tableFixHead thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

.el-button {
  margin-top: 5px;
  margin-bottom: 5px;
}

.viewHocBa {
  width: 100%;
  height: calc(100vh - 130px);
  overflow: scroll !important;
}

.el-checkbox {
  color: white;
}

button[disabled] {
  opacity: 0.5 !important;
  color: black;
}
</style>