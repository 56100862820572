<template>
  <div class="page page-file file detailHocBa">
    <div
      v-loading.fullscreen.lock="fullscreenLoading"
      element-loading-text="Đang tải dữ liệu..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="page__title">
        <h1 class="title">Cấu hình ký bìa</h1>
      </div>
      <div class="page__header">
        <el-row :gutter="20" @keyup.enter.native="getData()">
          <el-col :xs="12" :sm="12" :md="6" :lg="4">
            <label>Năm học:</label>
            <eselect
              style="width: 100%"
              collapseTags
              v-model="searchForm.namHoc"
              placeholder="Chọn"
              filterable
              :data="list_nam_hoc"
              :fields="['name', 'value']"
            />
          </el-col>
          <el-col :xs="12" :sm="12" :md="6" :lg="4">
            <label>Cấp học:</label>
            <eselect
              :disabled="list_cap_hoc.length == 1"
              style="width: 100%"
              collapseTags
              v-model="searchForm.capHoc"
              placeholder="Chọn"
              filterable
              :data="list_cap_hoc"
              :fields="['name', 'value']"
            />
          </el-col>
          <el-col :xs="12" :sm="12" :md="6" :lg="4">
            <div style="color: transparent">Tìm kiếm</div>
            <el-button class="m-0" type="primary" @click="getData()">Tìm kiếm </el-button>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="file--title">
      <h5 class="title">Danh sách cấu hình</h5>
    </div>
    <div class="page__content">
      <el-table
        border
        fit
        :lazy="true"
        :data="list_data ? list_data : []"
        width="100%"
        ref="listGiaoVien"
      >
        <el-table-column
          label="STT"
          style="width: 50px"
          width="80"
          type="index"
          class-name="text-center"
          :index="indexMethod"
        >
        </el-table-column>
        <el-table-column label="Năm học" header-align="center" align="center">
          <template slot-scope="scope">
            <div>
              <p>{{ scope.row.namHoc }}-{{ scope.row.namHoc + 1 }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="Cấp học"
          property="capHoc"
          align="center"
        ></el-table-column>
        <el-table-column
          label="Khối học"
          property="khoiHoc"
          class-name="text-center"
        ></el-table-column>

        <el-table-column label="Người ký" header-align="center" align="left">
          <template slot-scope="scope">
            <div v-if="scope.row.giaoVienPhanCong">
              <p v-if="scope.row.giaoVienPhanCong.tenGV">
                Họ tên: {{ scope.row.giaoVienPhanCong.tenGV }}
              </p>
              <p v-if="scope.row.giaoVienPhanCong.chucVu">
                Chức vụ: {{ getTenChucVu(scope.row.giaoVienPhanCong.chucVu) }}
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="Tiêu đề ký"
          property="tieuDeXacNhan"
         header-align="center" align="left"
        ></el-table-column>
        <el-table-column
          label="Hậu tố sổ đăng bộ"
          property="hauToSoDangBo"
         header-align="center" align="left"
        ></el-table-column>
        <!--        <el-table-column label="Tên trường" property="tenTruong" class-name="text-center"></el-table-column>-->
        <el-table-column label="Hành động" header-align="center" align="center">
          <template slot-scope="scope">
            <div>
              <el-tooltip content="Chỉnh sửa thông tin trang bìa" placement="top">
                <el-button
                  size="mini"
                  @click.prevent="showUpdate(scope.row)"
                  type="primary"
                  icon="el-icon-edit-outline"
                >
                </el-button>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog
      title="Chỉnh sửa thông tin trang bìa"
      width="50%"
      center
      custom-class="thongTinKyTheoMonHoc"
      append-to-body
      :visible.sync="show_update"
      :show-close="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="closeDialogFormUpdate"
    >
      <el-form ref="infoKy" :model="infoKyUpdate" :rules="ruleInfoKy">
        <el-row :gutter="24">
          <el-col :span="12">
            <label>Hậu tố sổ đăng bộ: </label>
            <el-form-item>
              <el-input
                type="text"
                placeholder="Nhập..."
                clearable
                v-model="infoKyUpdate.hauToSoDangBo"
                @change="handleInputChange('hauToSoDangBo')"
              ></el-input>
            </el-form-item>

            <!--          <el-input-->
            <!--              type="textarea" class="d-flex" style="height: 40px !important;" placeholder="Nhập..."-->
            <!--              v-model="infoKy.hauToSoDangBo"></el-input>-->
          </el-col>
          <!--        <el-col :span="12">-->
          <!--          <label>Tên trường:-->
          <!--          </label>-->
          <!--          <el-input-->
          <!--              type="text" placeholder="Nhập..."-->
          <!--              v-model="infoKy.tenTruong"></el-input>-->
          <!--        </el-col>-->
          <el-col :span="12">
            <label>Người ký: <span style="color: red">*</span> </label>
            <!--                    <el-form-item prop="giaoVien">-->
            <!--                        <eselect style="width:100%" collapseTags v-model="infoKy.giaoVien" placeholder="Chọn"-->
            <!--                                 filterable :data="list_giao_vien_ky" :fields="['name','value']"/>-->
            <el-form-item prop="giaoVien">
              <el-select
                filterable
                v-model="infoKyUpdate.giaoVien"
                placeholder="Chọn"
                clearable
              >
                <el-option label="Chọn" value=""></el-option>
                <el-option
                  v-for="(item, index) in list_giao_vien_ky"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <!--                    </el-form-item>-->
          </el-col>
          <el-col :span="12" style="">
            <label
              >Tiêu đề ký: <span style="color: red">*</span>
              <el-tooltip placement="top">
                <div slot="content">
                  Ghi rõ: Chức danh ký hoặc ký thay<br />
                  Ví dụ: Hiệu trưởng
                  <br />
                  KT Hiệu trưởng
                  <br />
                  Phó hiệu trưởng
                </div>
                <small><i style="color: blue">Hướng dẫn</i></small>
              </el-tooltip>
            </label>
            <el-form-item prop="tieuDeXacNhan">
              <div class="custom-textarea">
                <el-input
                  @change="handleInputChange('tieuDeXacNhan')"
                  type="textarea"
                  v-model="infoKyUpdate.tieuDeXacNhan"
                  placeholder="Nhập..."
                  :rows="1"
                >
                </el-input>

                <i
                  v-if="infoKyUpdate.tieuDeXacNhan"
                  class="el-icon-circle-close clear-icon"
                  @click="clearInput('tieuDeXacNhan')"
                ></i>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="closeDialogFormUpdate">Hủy</el-button>
        <el-button
          :disabled="!can_hoi_truoc_khi_thoat"
          size="mini"
          type="primary"
          @click.prevent="confirmUpdateData('infoKy')"
          >Lưu</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ESelectVue from "./Ui/ESelect.vue";
import constant from "../constant";
import dialog from "../dialog";
import Pagination from "../components/Pagination";
import ChonCapHoc from "../components/ChonCapHoc";
import constant_api from "../_helpers/constant_api";
import api from "../_helpers/api";
import util from "../util";
export default {
  name: "CauHinhKyBia",
  metaInfo: {
    title: "Cấu hình ký bìa",
    titleTemplate: "",
    meta: [
      {
        vmid: "",
        name: "",
        content: "",
      },
    ],
  },
  computed: {
    ...mapState("account", [
      "user",
      "list_nam_hoc",
      "profile",
      "nam_hoc_hien_tai",
      "list_cap_hoc",
    ]),
  },
  components: {
    Pagination,
    eselect: ESelectVue,
    ChonCapHoc,
  },
  data() {
    return {
      ruleInfoKy: {
        giaoVien: [
          { required: true, message: "Chưa chọn giáo viên", trigger: ["change", "blur"] },
        ],
        tieuDeXacNhan: [
          {
            required: true,
            message: "Chưa nhập tiêu đề ký",
            trigger: ["change", "blur"],
          },
          {
            max: 50,
            message: "Tiêu đề ký không vượt qua 50 ký tự",
            trigger: ["change", "blur"],
          },
        ],
      },
      infoKy: {
        hauToSoDangBo: "",
        giaoVien: "",
        tieuDeXacNhan: "",
      },
      infoKyUpdate: {
        hauToSoDangBo: "",
        giaoVien: "",
        tieuDeXacNhan: "",
      },
      original_infoKyUpdate: {
        hauToSoDangBo: "",
        giaoVien: "",
        tieuDeXacNhan: "",
      },
      can_hoi_truoc_khi_thoat: false,

      giaoVienKy: "",
      fullscreenLoading: false,
      list_data: [],
      paginate: {
        start: 0,
        limit: 5,
        current: 1,
        total: 0,
      },
      searchForm: {
        namHoc: "",
        capHoc: "",
      },
      dataUpdate: {},
      show_update: false,
      list_giao_vien_ky: [],
    };
  },
  mounted() {
    this.searchForm.namHoc = this.nam_hoc_hien_tai;
    if (this.list_cap_hoc.length == 1) {
      this.searchForm.capHoc = this.list_cap_hoc[0].value;
    }
    this.getData();
  },
  watch: {
    infoKyUpdate: {
      handler(newVal) {
        this.can_hoi_truoc_khi_thoat = util.hasChangesToConfirmBeforeClose(
          newVal,
          this.original_infoKyUpdate
        );
      },
      deep: true,
    },
  },
  methods: {
    resetForm() {
      this.$refs.infoKy.resetFields();
      this.$refs.infoKy.clearValidate();
      this.can_hoi_truoc_khi_thoat = false;
    },
    closeDialogFormUpdate() {
      if (this.can_hoi_truoc_khi_thoat) {
        this.$confirm(
          "Các thông tin vừa nhập sẽ bị mất khi đóng trang. Thầy cô có muốn tiếp tục chỉnh sửa, kiểm tra và lưu lại thông tin đã nhập?",
          "Thông báo",
          {
            confirmButtonText: "Rời khỏi trang",
            cancelButtonText: "Tiếp tục chỉnh sửa",
            confirmButtonClass: "el-button--danger",
            closeOnClickModal: false,
          }
        )
          .then(() => {
            this.resetForm();
            this.show_update = false;
          })
          .catch((_) => {
            
          });
      } else {
        this.resetForm();
        this.show_update = false;
      }
    },
    handleInputChange(field) {
      this.infoKyUpdate[field] = this.infoKyUpdate[field]?.trim();
      this.can_hoi_truoc_khi_thoat = true;
    },
    clearInput(field) {
      this.infoKyUpdate[field] = "";
      
    },
    getTenChucVu(role) {
      let str = "";
      if (role == 1) {
        str = "Hiệu trưởng";
      }
      if (role == 2) {
        str = "Phó hiệu trưởng";
      }
      if (role == 16) {
        str = "Giám đốc";
      }
      if (role == 17) {
        str = "Phó giám đốc";
      }
      return str;
    },
    confirmUpdateData(formName) {
      console.log("confirmUpdateData");
      console.log(this.infoKy.tieuDeXacNhan);

      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            maGV: this.infoKyUpdate.giaoVien,
            namHoc: this.dataUpdate.namHoc,
            hauToSoDangBo: this.infoKyUpdate.hauToSoDangBo,
            tenTruong: this.infoKy.tenTruong,
            tieuDeXacNhan: this.infoKyUpdate.tieuDeXacNhan,
            khoiHoc: this.dataUpdate.khoiHoc,
            capHoc: this.dataUpdate.capHoc,
          };
          console.log("Thông tin cập nhật");
          console.log(params);
          try {
            let uri = constant_api.truong.capNhatPhanCongKyBiaVaTieuDe;
            this.fullscreenLoading = true;
            api
              .post(uri, params)
              .then((response) => {
                if (response.data.code == 200) {
                  this.thongBao("success", response.data.msg);
                  this.resetForm()
                  this.getData();
                } else {
                  this.thongBao("error", response.data.msg);
                }
                this.fullscreenLoading = false;
              })
              .catch((e) => {
                this.loading = false;
                this.fullscreenLoading = false;
              });
          } catch (e) {
            this.thongBao("error", "Vui lòng thao tác lại.");
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getChucVu(role) {
      var res = parseInt(role);
      switch (res) {
        case 1:
          res = "Hiệu trưởng";
          break;
        case 2:
          res = "Phó hiệu trưởng";
          break;
        case 16:
          res = "Giám đốc";
          break;
        case 17:
          res = "Phó giám đốc";
          break;
        default:
          res = role;
      }
      return res;
    },
    showUpdate(item) {
      console.log("showUpdate");
      console.log(item);
      let dataUpdate = JSON.parse(JSON.stringify(item));
      this.dataUpdate = JSON.parse(JSON.stringify(item));
      this.infoKy = JSON.parse(JSON.stringify(this.dataUpdate));
      this.list_giao_vien_ky = [];
      if (this.dataUpdate.giaoVienTiemNang && this.dataUpdate.giaoVienTiemNang) {
        const giaoVien =
          dataUpdate.giaoVienPhanCong && dataUpdate.giaoVienPhanCong.maGV
            ? dataUpdate.giaoVienPhanCong.maGV
            : "";
        this.infoKyUpdate.giaoVien = giaoVien;
        this.original_infoKyUpdate.giaoVien = giaoVien;

        const hauToSoDangBo = dataUpdate.hauToSoDangBo || "";
        this.infoKyUpdate.hauToSoDangBo = hauToSoDangBo;
        this.original_infoKyUpdate.hauToSoDangBo = hauToSoDangBo;

        const tieuDeXacNhan = dataUpdate.tieuDeXacNhan || "";
        this.infoKyUpdate.tieuDeXacNhan = tieuDeXacNhan;
        this.original_infoKyUpdate.tieuDeXacNhan = tieuDeXacNhan;

        // this.infoKy.tieuDeXacNhan = this.dataUpdate.tieuDeXacNhan
        // this.infoKy.hauToSoDangBo = JSON.parse(JSON.stringify(this.dataUpdate.hauToSoDangBo))
        console.log("infoKy");
        console.log(this.infoKy);
        let arr_gv = this.dataUpdate.giaoVienTiemNang;
        console.error("arr_gv");
        console.error(arr_gv);
        for (let i = 0; i < arr_gv.length; i++) {
          console.log(arr_gv[i]);
          let obj = {
            name: arr_gv[i].tenGV + " - [" + this.getChucVu(arr_gv[i].chucVu) + "]",
            value: arr_gv[i].maGV,
          };
          this.list_giao_vien_ky.push(obj);
        }
        this.show_update = true;
      } else {
        this.thongBao("error", "Không có giáo viên có thể ký. Vui lòng thử lại sau.");
      }
    },
    getTotalPage(number) {
      return Math.ceil(number / this.paginate.limit);
    },
    getData() {
      try {
        let uri = constant_api.truong.phanCongKyBiaVaTieuDe;
        this.fullscreenLoading = true;
        let params = {
          namHoc: this.searchForm.namHoc,
          capHoc: this.searchForm.capHoc,
          khoiHoc: "",
        };
        this.show_update = false;
        api
          .get(uri, params)
          .then((response) => {
            if (response.data.code == 200) {
              this.list_data = response.data.data;
              console.error("Danh sách:");
              console.error(this.list_data);
            } else {
              this.thongBao("error", response.data.msg);
            }
            this.fullscreenLoading = false;
          })
          .catch((e) => {
            this.loading = false;
            this.fullscreenLoading = false;
          });
      } catch (e) {
        this.thongBao("error", "Vui lòng thao tác lại.");
      }
    },
    indexMethod(index) {
      return this.paginate.start + index + 1;
    },
    thongBao(t, e) {
      let msg = "";
      let cl = "";
      if (e) {
        msg = e;
      }
      let type = "success";
      if (t) {
        type = t;
      }
      if (type == "success") {
        cl = "dts-noty-success";
      }
      if (type == "warning") {
        cl = "dts-noty-warning";
      }
      if (type == "error") {
        cl = "dts-noty-error";
      }
      if (type == "info") {
        cl = "dts-noty-info";
      }
      this.$message({
        customClass: cl,
        showClose: true,
        message: msg,
        type: t,
        duration: 3000,
      });
    },
  },
};
</script>

<style scoped>
.el-form-item {
  width: 100%;
}

.resultText {
  white-space: nowrap;
  /*width: 100px;*/
  overflow: hidden;
  text-overflow: ellipsis;
  /*border: 1px solid #000000;*/
}

.showMore {
  background: #409eff;
  padding: 1px 5px;
  border-radius: 3px;
  color: white;
  font-weight: 700;
}
.custom-textarea {
  position: relative;
  display: flex;
}

.clear-icon {
  position: absolute;
  right: 20px;
  top: 10px;
  cursor: pointer;
  color: #c0c4cc;
}
</style>
