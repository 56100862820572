export default {
    getListPath(menu){
        let lstPath = [];
        if (menu.length){
            menu.map(item => {
                lstPath.push(item.menuUrl);
                if (item.children && item.children.length){
                    let arr = this.getListPath(item.children);
                    lstPath = lstPath.concat(arr);

                }
                return item;
            })
        }

       lstPath.push("thong-tin-tai-khoan");

        return lstPath;
    },

    setCookie(name,value,minute) {
        var expires = "";
        if (minute) {
            var date = new Date();
            date.setTime(date.getTime() + (minute*60*1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "")  + expires + "; path=/";
    },
    deleteCookie(name) {
        document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    },
    getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    },

    generateStatus(status) {
        let listStatus = [
            {val: 1, text: 'Hoạt động'},
            {val: 2, text: 'Vô hiệu hóa'},
        ];
        let result;
        listStatus.map((r) => {
            if (r.val == status) {
                return result = r.text;
            }
        });

        return result;
    },
    regexChar(char,data){
        let str = data.toString();
        return str.includes(char);
    },
    generateRole(role) {
        let listRole = [
            {val: 1, text: 'Admin'},
            {val: 2, text: 'Khu vực'},
            {val: 3, text: 'Đơn vị đo kiểm'},
            {val: 4, text: 'Toàn quốc'},
        ];
        let result;
        listRole.map((r) => {
            if (r.val == role) {
                return result = r.text;
            }
        });

        return result;
    },
    generateArea(area) {
        let listArea = [
            {val: 1, text: 'Miền Bắc'},
            {val: 2, text: 'Miền Trung'},
            {val: 3, text: 'Miền Nam'},
        ];
        let result;
        listArea.map((r) => {
            if (r.val == area) {
                return result = r.text;
            }
        });

        return result;
    },
    generateNetwork(network) {
        let listNetwork = [
            {val: 1, text: 'Viettel'},
            {val: 2, text: 'Vinaphone'},
            {val: 3, text: 'MobiFone'},
            {val: 4, text: 'Vietnamobile'},
            {val: 5, text: 'GMobile'},
            {val: 6, text: 'Đài phát thanh, đài truyền hình'},
        ];
        let result;
        listNetwork.map((r) => {
            if (r.val == network) {
                return result = r.text;
            }
        });

        return result;
    },

    hideNumberPhone(phone) {
        let str = '';
        str = phone.split('');
        str[4] = '*';
        str[5] = '*';
        str[6] = '*';
        str = str.join('');
        return str;
    }
}
