import util from "../util";
export function authHeader() {
    let token = util.getCookie('token')
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && token) {
        return { 
            'accessToken': token,
            'token': token,
            'role': user.role,
            'userAgent':navigator.userAgent
        };
    } else {
        return {
            'token': "Anonymous",
            'userAgent':navigator.userAgent
        };
    }
}
