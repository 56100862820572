<template>
    <div></div>
</template>

<script>
import websocket from "../websocket";
import {
    mapState,
    mapActions
} from 'vuex';
import dialog from "../dialog";

export default {
    name: "KyHocBaUsb",
    props: [
        'duLieu',
        'thuchienky',
    ],
    computed: {
        ...mapState('teacher', [
            'pdfSignature'
        ]),
    },
    data: () => {
        return {
            VtPluginSocket: ''
        }
    },
    methods: {
        onReceiveMessage(e) {
            let data = e.data;
            if (data.other === 7) {
                console.log("Thông tin ký trả về từ websocket:")
                console.log(data)

                if (data.code == 200) {
                    this.signHash(data.data.hashBase64);
                    this.close();
                } else {
                    this.$emit('error')
                }
            }
        },
        ...mapActions('teacher', [
            'kyUsbHocBa',
            'clearDataKy'
        ]),
        initPlugin() {
            if (this.VtPluginSocket != undefined) {
                if (this.VtPluginSocket.initPlugin()) {
                    return true;
                } else {
                    this.thongBao('error', 'Khởi tạo session không thành công')
                    this.close();
                    return false;
                }
            } else {
                this.thongBao('error', 'Không tồn tại đối tượng VtPluginSocket')
                this.close();
                return false;
            }
            return false;
        },
        showErrorMessage(message) {
            if (this.VtPluginSocket != undefined) {
                let errorCode = this.VtPluginSocket.getLastErrorCode();
                if (errorCode != undefined || errorCode != null || errorCode != "") {
                    let errorMessage = this.VtPluginSocket.ERROR_CODE[errorCode];
                    if (errorMessage != undefined || errorMessage != null || errorMessage != "") {
                        if (message == undefined || message == null || message == "") {
                            // dialog.showDialog('Thông báo', "Lỗi: " + errorMessage + " (" + errorCode + ")");
                            this.$alert("Lỗi: " + errorMessage + " (" + errorCode + ")", 'Thông báo', {
                                confirmButtonText: 'Đóng',
                                callback: action => {
                                    this.$emit('error')
                                }
                            });

                        } else {
                            // dialog.showDialog('Thông báo', message + "\nLỗi: " + errorMessage + " (" + errorCode +
                            //     ")");
                            this.$alert(message + "\nLỗi: " + errorMessage + " (" + errorCode + ")", 'Thông báo', {
                                confirmButtonText: 'Đóng',
                                callback: action => {
                                    this.$emit('error')
                                }
                            });
                        }

                    }
                    if (errorCode == "100103") {
                        this.initPlugin();
                    }
                    return;
                }
                this.$alert('Không thể lấy được mã lỗi', 'Thông báo', {
                    confirmButtonText: 'Đóng',
                    callback: action => {
                        this.$emit('error')
                    }
                });
            } else {
                this.$alert('Không tồn tại đối tượng VtPluginSocket', 'Thông báo', {
                    confirmButtonText: 'Đóng',
                    callback: action => {
                        this.$emit('error')
                    }
                });
            }
        },

        signUsb() {
            console.log("Mount signUsb:")
            console.log(this.VtPluginSocket);
            if (this.VtPluginSocket == undefined) {
                this.thongBao('error', 'Không tồn tại đối tượng VtPluginSocket')
                this.close();
                return;
            }
            if (this.VtPluginSocket.hSession == "") {
                if (!this.initPlugin()) {
                    return;
                }
            }
            // //Bước 1. Lấy CTS mà người dùng chọn
            console.log('B1')
            let certUserBase64 = this.VtPluginSocket.getCert();
            if (certUserBase64 == "") {
                this.showErrorMessage("Chọn Chứng thư số không thành công");
                this.$emit('error')
                return false;
            }
            //Bước 2. Đẩy CTS này lên Server để tạo Hash
            console.log('B2')

            let dataHash = {
                id: this.duLieu.id,
                maLop: this.duLieu.maLop,
                certChainBase64: certUserBase64,
                msgType: 7
            };
            console.log('data' + dataHash)
            websocket.sendMessage(dataHash);
            return;

        },

        thongBao(t, e) {
            let msg = "";
            let cl = "";
            if (e) {
                msg = e;
            }
            let type = "success";
            if (t) {
                type = t
            }
            if (type == "success") {
                cl = "dts-noty-success"
            }
            if (type == "warning") {
                cl = "dts-noty-warning"
            }
            if (type == "error") {
                cl = "dts-noty-error"
            }
            if (type == "info") {
                cl = "dts-noty-info"
            }
            this.$message({
                customClass: cl,
                showClose: true,
                message: msg,
                type: t,
                duration: 3000
            });
        },

        signHash(hash) {
            if (this.VtPluginSocket == undefined) {
                // dialog.showDialog('Thông báo', 'Không tồn tại đối tượng VtPluginSocket')
                this.$alert('Không tồn tại đối tượng VtPluginSocket', 'Thông báo', {
                    confirmButtonText: 'Đóng',
                    callback: action => {
                        this.$emit('error')
                    }
                });
                return;
            }
            if (this.VtPluginSocket.hSession == "") {
                if (!this.initPlugin()) {
                    return;
                }
            }

            let signatureBase64 = this.VtPluginSocket.signHash(hash);

            console.log(signatureBase64)
            if (signatureBase64 == null || signatureBase64 == undefined || signatureBase64.trim().length == 0) {
                this.showErrorMessage("Sign Hash không thành công");
                return false;
            }

            this.kyHocBa(signatureBase64);

        },

        kyHocBa(signatureBase64) {
            let data;
            data = {
                id: this.duLieu.id,
                signatureBase64: signatureBase64,
                pdfSignature: this.pdfSignature,
                type: this.duLieu.loaiHocBa,
                maLop: this.duLieu.maLop,
                maGVCN: this.duLieu.maGVCN,
                msgType: 8
            };
            websocket.sendMessage(data);
            // this.kyUsbHocBa(data);
        },
        close() {
            console.log("Close")
            this.$emit('close');
        }
    },

    watch: {
        thuchienky: function (val) {
            if (val) {
                console.log("Thực hiện thao tác ký trong ký học bạ usb:" + val)
                this.signUsb();
            }
        }
    },

    mounted() {
        this.clearDataKy();
        window.addEventListener('message', this.onReceiveMessage, false);
        this.VtPluginSocket = {
            hSession: "",
            initPlugin: function () {
                let LibList_MACOS = "viettel-ca_v5.dylib;viettel-ca_v4.dylib";
                let LibList_WIN = "viettel-ca_v5.dll;viettel-ca_v4.dll;viettel-ca_v2.dll";
                let functionName = "getSession";
                let paramNameArray = ['liblist'];
                let paramValueArray = [LibList_WIN + ";" + LibList_MACOS];
                let response = this.ajaxFunction(functionName, paramNameArray, paramValueArray);
                console.log(response);
                if (response != "") {
                    this.hSession = response;
                    return true;
                }
                return false;
            },
            getCert: function () {
                let certUserBase64 = "";
                if (this.hSession == undefined || this.hSession == null || this.hSession == "") {
                    return certUserBase64;
                }
                let functionName = "getCertificate";
                let paramNameArray = ['sessionID'];
                let paramValueArray = [this.hSession];
                let response = this.ajaxFunction(functionName, paramNameArray, paramValueArray);
                if (response != "") {
                    certUserBase64 = response;
                }
                return certUserBase64;
            },
            signHash: function (base64Hash) {
                let signatureBase64 = "";
                if (this.hSession == undefined || this.hSession == null || this.hSession == "" ||
                    base64Hash == undefined || base64Hash == null || base64Hash == "") {
                    return signatureBase64;
                }
                let functionName = "signHash";
                let paramNameArray = ['sessionID', 'HashVal', 'HashOpt'];
                let paramValueArray = [this.hSession, this.convertBase64ToHexa(base64Hash),
                    0
                ]; //HashOpt: loai ma hash (0: SHA-1; 1:MD5)
                let response = this.ajaxFunction(functionName, paramNameArray, paramValueArray);
                if (response != "") {
                    signatureBase64 = response;
                }
                return signatureBase64;
            },
            getLastErrorCode: function () {
                let errorCode = "";
                let functionName = "getLastErr";
                let response = this.ajaxFunction(functionName);
                if (response != "") {
                    errorCode = response;
                }
                return errorCode;
            },
            ajaxFunction: function (functionName, paramNameArray, paramValueArray) {
                //validate
                let response = "";
                if (functionName == undefined || functionName == null || functionName.trim() == "") {
                    return response;
                }
                let xmlhttp;
                if (window.XMLHttpRequest) {
                    // code for IE7+, Firefox, Chrome, Opera, Safari
                    xmlhttp = new XMLHttpRequest();
                } else {
                    // code for IE6, IE5
                    xmlhttp = new ActiveXObject("Microsoft.XMLHTTP");
                }
                xmlhttp.onreadystatechange = function () {
                    if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
                        response = xmlhttp.responseText;
                    }
                }
                let method = "POST";
                let url = "http://localhost:14007/" + functionName;
                let async = false; //Gui yeu cau chay dong bo
                xmlhttp.open(method, url, async);
                xmlhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
                if (paramNameArray == undefined || paramNameArray == null || !Array.isArray(
                    paramNameArray) || paramNameArray.length == 0 ||
                    paramValueArray == undefined || paramValueArray == null || !Array.isArray(
                        paramValueArray) || paramValueArray.length == 0 ||
                    paramNameArray.length != paramValueArray.length) {
                    try {
                        xmlhttp.send();
                    } catch (err) {
                        return response;
                    }
                } else {
                    let data = "";
                    for (let i = 0; i < paramNameArray.length; i++) {
                        //data += encodeURIComponent(paramNameArray[i]) + "=" + encodeURIComponent(paramValueArray[i]) + "&";
                        data += paramNameArray[i] + "=" + paramValueArray[i] + "&";
                    }
                    if (data.length > 0) {
                        data = data.substring(0, data.length - 1); //Loai bo ky tu & cuoi cung
                    }
                    try {
                        xmlhttp.send(data);
                    } catch (e) {
                        // dialog.showDialog('Thông báo','Không tồn tại đối tượng VtPluginSocket');
                        return response;
                    }
                }
                return response;
            },
            dec2hex: function (d) {
                let hD = '0123456789ABCDEF';
                let h = hD.substr(d & 15, 1);
                while (d > 15) {
                    d >>= 4;
                    h = hD.substr(d & 15, 1) + h;
                }
                return h;
            },
            base64_decode: function (stringBase64) {
                let keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/="
                let output = new Array();

                let chr1, chr2, chr3;
                let enc1, enc2, enc3, enc4;
                let i = 0;
                let orig_input = stringBase64;
                stringBase64 = stringBase64.replace(/[^A-Za-z0-9\+\/\=]/g, "");
                if (orig_input != stringBase64)
                    alert("Warning! Characters outside Base64 range in input string ignored.");
                if (stringBase64.length % 4) {
                    alert("Error: Input length is not a multiple of 4 bytes.");
                    return "";
                }

                let j = 0;
                while (i < stringBase64.length) {

                    enc1 = keyStr.indexOf(stringBase64.charAt(i++));
                    enc2 = keyStr.indexOf(stringBase64.charAt(i++));
                    enc3 = keyStr.indexOf(stringBase64.charAt(i++));
                    enc4 = keyStr.indexOf(stringBase64.charAt(i++));
                    chr1 = (enc1 << 2) | (enc2 >> 4);
                    chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
                    chr3 = ((enc3 & 3) << 6) | enc4;
                    output[j++] = chr1;
                    if (enc3 != 64)
                        output[j++] = chr2;
                    if (enc4 != 64)
                        output[j++] = chr3;
                }
                return output;
            },
            convertBase64ToHexa: function (stringBase64) {
                let output = this.base64_decode(stringBase64);
                let separator = "";
                let hexText = "";
                for (let i = 0; i < output.length; i++) {
                    hexText = hexText + separator + (output[i] < 16 ? "0" : "") + this.dec2hex(output[i]);
                }
                return hexText;
            },
            ERROR_CODE: {
                100100: 'Lỗi chọn CTS',
                100101: 'Lỗi Plugin',
                100102: 'CTS không hợp lệ',
                100103: 'Session không hợp lệ',
                100104: 'CTS hết hạn',
                100200: 'Dữ liệu lỗi',
                100201: 'Không tìm thấy CTS',
                100202: 'CTS không hợp lệ',
                100203: 'Lỗi xảy ra trong quá trình ký',
                100204: 'Tràn bộ nhớ',
                100205: 'Session không hợp lệ',
                100300: 'Chữ ký không đúng định dạng',
                100301: 'Lỗi phân tích CTS',
                100302: 'Chữ ký không hợp lệ',
                100303: 'Session không hợp lệ',
            }
        };

    }
}
</script>

<style scoped>

</style>